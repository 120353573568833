import { useEffect, useState } from "react"
import { Bar } from "recharts"
import { useContextState } from "../../../../../../hooks/theme"
import { dashboardServiceFetch } from "../../../../../../utils/constants/callService"

const useBody = () => {
  const [data, setData] = useState<Record<string, string | number>[]>([])
  const [loading, setLoading] = useState(false)
  const separator = 'cut'
  const { colors } = useContextState()
  const indicatorsBars = [
    <Bar
      maxBarSize={50}
      key={`created`}
      dataKey={`created`}
      name={`Folios de reporte creados`}
      fill={colors.color16}
    />,
    <Bar
      maxBarSize={50}
      key={`attended`}
      dataKey={`attended`}
      name={`Folios de reporte por atender`}
      fill={colors.color16}
    />,
    <Bar
      maxBarSize={50}
      key={`solved`}
      dataKey={`solved`}
      name={`Folios de reporte solucionados`}
      fill={colors.color19}
    />,
  ]

  const getCreatedFolios = async () => {
    setLoading(true)
    const response = await dashboardServiceFetch.indicatorsReportFoliosCreated()
    const body = {
      name: `Solicitados${separator}Folios de reporte creado vs. solucionados`,
      solved: response.amountCompleted,
      created: response.amountCreated
    }
    setData(prev => {
      const copyPrev = structuredClone(prev)
      copyPrev[0] = body
      return copyPrev
    })
    setLoading(false)
  }

  const getAttendedFolios = async () => {
    setLoading(true)
    const response = await dashboardServiceFetch.indicatorsReportFoliosAssigned()
    const body = {
      name: `Por Atender${separator}Folios de reporte por atender vs. solucionados`,
      solved: response.amountCompleted,
      attended: response.amountAssigned
    }
    setData(prev => {
      const copyPrev = structuredClone(prev)
      copyPrev[1] = body
      return copyPrev
    })
    setLoading(false)
  }

  const getData = async () => {
    getCreatedFolios()
    getAttendedFolios()
  }

  useEffect(() => {
    getData()
  }, [])
  return { loading, data, indicatorsBars, separator, getData}
}

export { useBody }
