import { Color } from '../../../../utils/theme/types/color'

export const useStyles = (colors: Color) => {
  const height = window.innerHeight >= 700

  return {
    container: {
      height: "100%",
      width: '100%',
      display: "flex",
      flexWrap: 'wrap',
      gap: "15px",
      justifyContent: 'center',
      padding: '15px 0px'
    },
    title: {
      fontSize: '36px',
      textAlign: 'center',
      lineHeight: 1,
      color: `${colors.mediumDark}ee`

    },
    body: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'unset',
      gap: height ? '20px' : '20px',
      // height: '100%',
      overflow: 'auto',
      padding: '0px 60px'
    },
    fields: {
      width: '100%',
      display: 'flex',
      flexDirection: "column",
      gap: height ? '20px' : '20px',
    },
    description: {
      fontSize: '12px',
      fontWeight: '200',
      textAlign: 'center',
      lineHeight: '1.4'
    },
    inputs: {
      '& .MuiOutlinedInput-root': {
        borderRadius: '20px',
        boxShadow: `0 0 10px 2px ${colors.color3}66`,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
        boxShadow: `0 0 10px 2px ${colors.color3}`,
      }
    },
    checkAndButton: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between',
    },
    check: {
      flex: '.8',
      marginRight: 0,
      paddingRight: '8px',
      '& .MuiTypography-root': {
        fontSize: '14px',
      },
    },
    button: {
      flex: '.2',
    },
    rest: {
    },
    imgContain: {
      // width: '160px',
      // height: '40px'
    },
    header: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: height ? '20px' : '20px',
      width: '100%'
    },
  }
}
