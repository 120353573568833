import { Navigate, Route, Routes } from 'react-router-dom'
import { Main } from '../pages'

const Localization = () => {
  return (
    <Routes>
      {/* localization */}
      <Route index element={<Navigate to="main" />} />
      <Route path="main" element={<Main />} />
    </Routes>
  )
}

export default Localization
