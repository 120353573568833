import { Status } from "../../../../../../utils/enums";
import { dashboardColor } from "../../initial";
// [Status.WAITING, Status.TO_BE_STARTED, Status.FINISHED, Status.INCOMPLETE, Status.EXPIRED, Status.CANCEL]
const statusList = [
    {
        id: 1,
        status: Status.WAITING,
        color: dashboardColor[1],
        percentage: 100
    },
    {
        id: 2,
        status: Status.TO_BE_STARTED,
        color: dashboardColor[2],
        percentage: 100
    },
    {
        id: 3,
        status: Status.FINISHED,
        color: dashboardColor[3],
        percentage: 100
    },
    {
        id: 4,
        status: Status.INCOMPLETE,
        color: dashboardColor[4],
        percentage: 100
    },
    {
        id: 5,
        status: Status.EXPIRED,
        color: dashboardColor[5],
        percentage: 100
    },
    {
        id: 6,
        status: Status.CANCEL,
        color: dashboardColor[6],
        percentage: 100
    },
] 

export { statusList }
