import { Box, Icon, IconButton, Typography } from '@mui/material'
import { useRef } from 'react'
import { useContextState } from '../../hooks/theme'
import { icons } from '../../utils/icons'
import { onChange } from '../../utils/types'
import { useStyles } from './styles'

const AddImage = ({
  icon,
  name,
  getFile,
  image,
  label,
  objectFit,
  size = {
    width: 100,
    height: 100
  },
  accept = '.png, .jpg, .jpeg',
  typeAcceptArray = ['image/png', 'image/jpeg', 'image/jpg'],
  removeRadius,
}: {
  icon: any
  name: string
  getFile: onChange
  label?: string
  image?: string
  objectFit?: 'contain' | 'cover'
  size?: { width: number | string; height: number | string; }
  accept?: string
  typeAcceptArray?: string[]
  removeRadius?: boolean
}) => {
  const ref = useRef<HTMLInputElement | null>(null)
  const { colors } = useContextState()
  const styles = useStyles({colors, removeRadius, objectFit})

  const convertLink = (value: any) => (value ? (!value?.name ? value : URL.createObjectURL(value)) : null)

  const handleFile = (e: any) => {
    const file = e?.target?.files[0]

    if (file?.type && typeAcceptArray?.includes(file?.type)) {
      getFile({
        target: {
          name: name,
          value: file,
        },
      })
    } else {
      getFile({
        target: {
          name: name,
          value: null,
        },
      })
    }

    if (ref?.current?.value) ref.current.value = ''
  }

  return (
    <Box sx={styles.relative} key={name}>
      {image && (
        <IconButton
          onClick={() => {
            handleFile(null)
          }}
          sx={styles.iconClose}
        >
          {icons('close')}
        </IconButton>
      )}

      {label && !image && !removeRadius && (
        <Typography sx={styles.iconText} variant="caption">
          {label}
        </Typography>
      )}
      <IconButton
        sx={{
          '&.MuiIconButton-root': {
            ...styles.iconButton,
            ...size,
            ...(image && styles.existFile),
          },
        }}
        onClick={() => ref?.current?.click()}
      >
        {image ? (
          <>
            <Box component="img" sx={styles.img} alt="image" src={convertLink(image)} />
          </>
        ) : (
          <>
            <Icon sx={styles.iconSvg}>{icon}</Icon>
            {label && !image && removeRadius && (
              <Typography sx={styles.iconText2} variant="caption">
                {label}
              </Typography>
            )}
          </>
        )}
      </IconButton>
      <input accept={accept} type="file" hidden={true} ref={ref} onChange={handleFile} />
    </Box>
  )
}

export default AddImage
