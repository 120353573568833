import { useContextState } from "../../../../../../../../../hooks/theme"

const useStyles = () => {
  const { colors } = useContextState()

  return {
    text: {
      fontSize: '1.2rem',
      color: colors.color19,
      textAlign: "center"
    },
    warning: {
      height: '1.2em',
      fontSize: '2rem',
      '& .MuiSvgIcon-root': {
        fontSize: 'inherit',
      },
      color: colors.color19,
    },
  }
}

export { useStyles }
