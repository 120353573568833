export const useStyles = () => ({
  constainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    '& img': {
      objectFit: 'cover',
    },
    borderRadius: '50%',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
})
