import { useContextState } from "../../../../../../hooks/theme"

const useStyles = () => {
  const { colors } = useContextState()
  return {
    containerTooltip: {
      background: `${colors.color14}`,
      boxShadow: `0px 0px 4px ${colors.color13}`,
      display: 'flex',
      flexWrap: 'wrap',
      rowGap: '5px',
      maxWidth: '300px',
      padding: '20px',
      maxHeight: '600px',
      overflowY: 'auto',
    },
    containerTitle: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: '5px'
    },
    percentageStatus: ({ color }: { color: string }) => ({
      background: color,
      width: '10px',
      height: '10px',
      borderRadius: '50%',
    }),
    titleTooltip: {
      fontSize: '1rem',
      width: '100%',
    },
    mainName: ({ colors }: { colors: string }) => ({
      fontSize: '1rem',
      color: colors,
      width: '100%',
      fontWeight: '600',
      lineHeight: '1.5rem',
    }),
    detail: ({ colors }: { colors: string }) => ({
      fontSize: '.8rem',
      color: colors,
      width: '100%',
      paddingLeft: '10px',
      lineHeight: '.8rem',
    }),
  }
}

export { useStyles }
