import { useTheme } from '@mui/material'
import { useContextState } from '../../../../../../hooks/theme'

const useStyles = () => {
  const { colors } = useContextState()
  const { breakpoints } = useTheme()
  return {
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      rowGap: '10px',
      boxShadow: `0px 0px 4px ${colors.color13}`,
      borderRadius: '5px',
      padding: '20px',
      position: 'relative',
    },
    actionIndicator: {
      position: 'absolute',
      top: '0',
      right: '10px',
      '& .MuiIconButton-root': {
        fontSize: '1rem',
        '& span': {
          fontSize: '11px',
          marginRight: '2px',
        },
        '& .MuiSvgIcon-root': {
          fontSize: '1em',
        },
      },
    },
    containerActionPlan: {
      width: 'calc(100% - 200px)',
      [breakpoints.between(1340, 1450)]: {
        width: 'calc(100% - 160px)',
      },
      [breakpoints.between(1200, 1340)]: {
        width: 'calc(100% - 130px)',
      },
    },
    title: {
      fontSize: '1.2rem',
      color: colors.color13,
      fontWeight: '600',
    },
    label: {
      color: colors.color13,
    },
    containerTime: {
      width: '200px',
      [breakpoints.between(1340, 1450)]: {
        width: '160px',
      },
      [breakpoints.between(1200, 1340)]: {
        width: '130px',
      },
    },
    time: {
      fontSize: '2.2rem',
      [breakpoints.between(1340, 1450)]: {
        fontSize: '1.8rem',
      },
      [breakpoints.between(1200, 1340)]: {
        fontSize: '1.4rem',
      },
      fontWeight: '600',
      color: colors.color13,
      textAlign: 'end',
    },
  }
}

export { useStyles }
