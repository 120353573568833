import { Color } from '../../../../../utils/theme/types/color'

export const useStyles = (color: Color) => ({
  container: {
    minHeight: '300px',
    margin: 'auto',
    marginTop: '115px',
    width: '800px',
    position: 'relative',
    borderRadius: '10px',
    padding: '30px',
    boxShadow: `0 0 3px ${color.color13}`,
    '& .MuiGrid-root': {
      justifyContent: 'center',
    },
  },
  optionsContainer: {
    width: '100%',
    display: 'flex',
    maxHeight: '400px',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingTop: '20px',
  },
  headerLeft: {
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    position: 'absolute',
    top: '-50px',
    left: '0',
    '& .MuiIconButton-root': {
      color: color.color4,
      fontWeight: '600',
    },
  },
  footer: {
    width: '100%',
    position: 'absolute',
    bottom: '-50px',
    left: '0',
  },
  containerItems: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '20px',
    '& .MuiGrid-root': {
      width: 'calc(90% - 20px)',
    },
    '& .delete': {
      '&.MuiIconButton-root': {
        color: color.color19,
      },
    },
  },
  options: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '20px',
    marginBottom: '20px',
  },
  optionsAdd: {
    width: '10%',
    marginTop: '5px',
    '& .MuiIconButton-root': {
      width: '50%',
      color: color.color4,
      fontSize: '1.7rem',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
  },
  loader: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
