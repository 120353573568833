import { Color } from '../../../utils/theme/types/color'

export const useStyles = (color: Color, open: boolean, position: number) => {
  return {
    img: {
      width: '60px',
      height: '60px',
      cursor: 'pointer',
      // backgroundColor: color.primary,
      borderRadius: '5px',
      margin: '0 15px',
    },
    img2: {
      width: '180px',
      // height: '60px',
      cursor: 'pointer',
      margin: '0 10px',
    },
    drawer: {
      '& .MuiDrawer-paper': {
        boxShadow: `0 0 2px ${color.color10}`,
        boxSizing: 'border-box',
        alignItems: 'center',
        paddingTop: '20px',
      },
      '&.MuiDrawer-modal': {
        background: 'none',
      },
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    menu: {
      marginTop: '30px',
      display: 'flex',
      gap: 1,
      width: '100%',
      flexDirection: 'column',
    },
    iconButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    asideMenu: {
      width: '100%',
      position: 'relative',
      '& .MuiIcon-root': {
        overflow: 'initial',
      },
    },
    label: {
      fontSize: '12px'
    },
    asideMenuContainer: {
      height: '35px',
      display: 'flex',
      justifyContent: open ? 'flex-start' : 'center',
      alignItems: 'center',
      cursor: 'pointer',
      marginLeft: open ? '15px' : 0,
      gap: '10px',
      width: !open ? '100%' : 'auto',
      '& .MuiIcon-root': {
        marginTop: '-10px',
      },
      '& .MuiSvgIcon-root': {
        color: color.color10,
      },
      '& svg': {
        color: color.color10,
        '&.fa-sm': {
          verticalAlign: '0'
        }
      }
    },
    bar: {
      height: '40px',
      position: 'absolute',
      left: '0px',
      // top: position * 40,
      transition: '.2s',
      borderLeft: `4px solid ${color.color11}`,
    },
    containerItem: {
      display: 'flex',
      justifyContent: open ? 'flex-start' : 'center',
      alignItems: 'center',
      position: 'relative',
      flexWrap: 'wrap',
      '& .MuiSvgIcon-root': {
        color: color.color10,
      },
    },
    childrens: {
      paddingRight: open ? '10px' : 0,
      paddingLeft: open ? '35px' : 0,
    },
    arrowBack: {
      cursor: 'pointer',
      marginTop: '-10px',
    },
    active: {
      '& svg': {
        color: color.color11,
      },
      '& .MuiSvgIcon-root': {
        color: color.color11,
      },
      '& .MuiTypography-root': {
        color: color.color11,
      },
    },
  }
}
