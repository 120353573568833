import { Box, LinearProgress, LinearProgressProps, Typography } from "@mui/material";
import { useLinearProgressWithLabelStyle } from "./linearProgressWithLabelStyles";

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    const styles = useLinearProgressWithLabelStyle()
    return (
        <Box sx={styles.container}>
            <Box sx={styles.progressBarContainer}>
                <LinearProgress variant="determinate" sx={styles.progressBar} {...props} />
            </Box>
            <Box sx={styles.value}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export { LinearProgressWithLabel }