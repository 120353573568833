import { useContextState } from "../../../../../../hooks/theme"

const useStyles = () => {
  const { colors: color } = useContextState()

  return ({
    headerInputs: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
    },
    titleHeaders: {
      lineHeight: '1rem',
      color: color.color9,
      fontSize: '1.4rem',
      marginBottom: '5px',
      fontWeight: '500'
    },
    card: {
      width: '100%',
      minHeight: '300px',
      height: '100%',
      padding: '20px',
      borderRadius: '12px',
      boxShadow: `0 0 4px 0px ${color.color13}`,
      position: 'relative',
    },
    unitTitle: {
      fontSize: '14px',
      fontWeight: '600',
      '& .blue': {
        color: color.color16,
        fontWeight: '700',
      },
      '& .red': {
        color: color.color19,
        fontWeight: '700',
      },
    },
    grid: {
      padding: '10px 20px',
      borderRadius: '5px',
      '& .MuiSvgIcon-root': {
        fontSize: '2rem',
        color: color.color5
      },
      '& .MuiTypography-root': {
        fontSize: '1.2rem',
        color: color.color5
      },
      '&.improve': {
        background: color.color8,
        '& .MuiTypography-root': {
        }
      },
      '&.worst': {
        background: color.color31,
        '& .MuiTypography-root': {
        }
      },
    },
  })
}

export { useStyles }
