import { Color } from "../../utils/theme/types/color";


export const useStyles = ({colors, removeRadius, objectFit}:{colors: Color; removeRadius?: boolean; objectFit?: 'contain' | 'cover'}) => ({
  iconButton: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    position: 'relative',
    borderRadius: !removeRadius ? '50%' : '0',
    background: removeRadius ? colors.color27 : 'none'
  },
  iconSvg: {
    width: '100%',
    height: '100%',
    '&.MuiIcon-root': {
      fontSize: '1.8em',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
  },
  relative: {
    position: 'relative',
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  iconClose: {
    '&.MuiIconButton-root': {
      borderRadius: '50%',
      width: 10,
      height: 10,
      position: 'absolute',
      top: removeRadius ? "-15px" : "-3px",
      right: removeRadius ? "-15px" : "-3px",
      fontSize: '1em',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
  },
  iconText: {
    '&.MuiTypography-root': {
      width: '100%',
      fontSize: '13px',
      fontWeight: '400',
      color: colors.color4,
      textAlign: 'center'
    },
  },
  iconText2: {
    '&.MuiTypography-root': {
      width: '100%',
      fontSize: '12px',
      fontWeight: '400',
      color: colors.color22,
      textAlign: 'center',
      position: 'absolute',
      bottom: 10,
    },
  },
  img: {
    objectFit: objectFit ?? 'cover',
    width: '100%',
    height: '100%',
    borderRadius: !removeRadius ? '50%' : '0',
  },
  existFile: {
    padding: 0,
    border: !removeRadius ? `1px solid ${colors.color13}` : 'none',
  },
})
