import { Autocomplete, Box, Chip, TextField } from "@mui/material"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { MultiSelectProps } from "./types"
import { Item } from "../../../utils/types/generals"
import { CustomPaper } from "../../CustomPaper"
import { DinamicFormRefValues } from "../types"
import { useState } from "react"
import { useStyles } from "./styles"
import { ChipsList } from "../../chipsList"

const filterItemsByParent = ({ items, parentId, parentKey, parentItem }:
  { items: Item[]; parentId?: string, parentKey?: string; parentItem?: Item[] }): Item[] => {
  if (!!parentId && !!parentKey) {
    return !parentItem?.length ? [] : items?.filter(item => parentItem.some(parent => parent.value == item?.[parentId])) ?? []
  }
  return items
}

const isEmpty = (value: DinamicFormRefValues | Item[]) => {
  return value !== undefined
}
const MultiSelect = (props: MultiSelectProps) => {
  const { t } = useTranslation()
  const [isFocus, setIsFocus] = useState(false)
  const styles = useStyles()

  const handleDelete = (option: any) => {
    let values = props.getData(props.name) as Item[] ?? []
    values = values.filter((e) => e.value != option.value)
    const childKey = props.selectOptions?.child?.childKey

    if (!!childKey) {
      const itemChil = filterItemsByParent({ parentId: props.selectOptions?.child?.parentId, parentKey: props.name, items: props.getData(childKey) as Item[], parentItem: values })
      props.setValue(childKey, itemChil)
    }
    if (!!props.onChange) {
      props.setValue(props.name, values)
      props.onChange({ [props.name]: values, current: props.name })
    }
  }

  return <Controller
    key={props.id}
    name={props.name}
    control={props.control}
    rules={{ required: { value: !!props.validations?.required?.value, message: `${t('general.validate.template.theField')} ${t(`general.tags.${props.name}`)} ${t('general.validate.template.isRequired')}` } }}
    render={({ field }) => {
      const parentId = props.selectOptions?.parent?.parentId
      const parentKey = props.selectOptions?.parent?.parentKey
      const childKey = props.selectOptions?.child?.childKey
      const isEmptySelectFirst = props.selectOptions?.isEmptySelectFirst

      let parentItem: Item[] = []
      if (!!parentId && !!parentKey) {
        parentItem = props.getData(parentKey) as Item[]
      }

      let valueAutoComplete: Item[] | null = field.value as Item[] ?? []

      const allItems = filterItemsByParent({
        items: props.item || [],
        parentItem,
        parentId,
        parentKey,
      })
      let newValue: Item[] | null = valueAutoComplete
      return (
        <Box sx={styles.containrMultiselect}>
          <Autocomplete
            {...field}
            sx={{ ...(!isFocus ? styles.input : {}) }}
            key={props.id}
            noOptionsText={t('general.noOptions')?.toString()}
            options={allItems}
            disabled={!!props.disabled}
            getOptionLabel={(option) => option.label ?? ''}
            isOptionEqualToValue={(option, value) => option.id == value.id}
            value={newValue}
            multiple
            limitTags={0}
            disableCloseOnSelect
            filterSelectedOptions
            onFocus={() => {
              setIsFocus(true)
            }}
            onChange={(_, value) => {
              field.onChange(value)
              if (!!childKey) {
                props.setValue(childKey, filterItemsByParent({ parentId: props.selectOptions?.child?.parentId, parentKey: props.name, items: props.getData(childKey) as Item[], parentItem: value }))
              }
              if (!!props.onChange) {
                props.onChange({ [props.name]: value, current: props.name })
              }

            }}
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => <Chip sx={isFocus ? styles.chip : styles.chipUnFocus} variant="outlined" label={option.label} {...getTagProps({ index })} />)
            }}
            onBlur={() => {
              setIsFocus(false)
              if (!!isEmptySelectFirst && !field.value) {
                const firstItem = props.item?.[0]
                props.setValue(props.name, [firstItem])
                if (!!props.onChange) { props.onChange({ [props.name]: [firstItem] ?? [], current: props.name }) }
              } else {
                if (field.value == undefined) {
                  props.setValue(props.name, null)
                  if (!!props.onChange) { props.onChange({ [props.name]: null, current: props.name }) }
                }
              }
            }}
            PaperComponent={CustomPaper}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${props.label}${!!props.validations?.required?.value ? ' *' : ''}`}
                error={!!isEmpty(field?.value) && !!props.errors[props.name]?.message && !isEmptySelectFirst}
                helperText={!!(isEmpty(field?.value) && !!props.errors[props.name]?.message && !props.hiddenError) && props.errors[props.name]?.message}
              />
            )}
          />


          {<ChipsList value={props.getData(props.name) as Item[] ?? []} handleDelete={handleDelete} />}
        </Box>
      )
    }
    }
  />
}

export { MultiSelect }