import { ReactNode, useState, useEffect } from 'react'
import { Box, MenuItem, Select, FormControl, InputLabel, FormHelperText } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { Props } from './props'
import { useStyles } from './styles'

const CustomSelect = ({ isRequired, sx, label, name, items, onChange, selected = '', errors, ...rest }: Props) => {
  const [value, setValue] = useState('')
  const styles = useStyles()
  const error = errors?.[name]?.translate ?? errors?.[name]
  const handleChange = (event: SelectChangeEvent, child?: ReactNode) => {
    setValue(`${event.target.value}`)
    onChange(event, child)
  }

  useEffect(() => {
    setValue(selected ? `${selected}` : '')
  }, [selected])
  return (
    <Box sx={sx} key={name}>
      {/* <FormControl sx={styles.formControl} error={error} > */}
      <FormControl sx={styles.formControl}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>

        <Select
          sx={styles.select}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          autoComplete="off"
          // onClose={(e) => {
          //   if (!e?.target?.dataset?.value && !value && isRequired) {
          //     handleChange({
          //       target: {
          //         name,
          //         value: '',
          //       },
          //     })
          //   }
          // }}
          value={value}
          name={name}
          label={label}
          onChange={handleChange}
          {...rest}
        >
          {items?.map((item, index) => (
            <MenuItem key={index} value={item?.value?.value ?? item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </Box>
  )
}

export default CustomSelect
