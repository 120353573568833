const useReportFolioByAnswer = () => {
    return {
        containerReportFolio: {
          width: '450px',
          padding: '0px 20px'
        },
        closeSettings: {
          fontSize: '1.2rem',
          '& .MuiSvgIcon-root': {
            fontSize: '1em',
          },
        },
    }
}

export { useReportFolioByAnswer }