import { Box, Typography } from '@mui/material'
import { useContext } from 'react'
import { GlobalContext } from '../../../../../../contexts/Global'
import { useStyles } from './styles'

export const ModelOne = ({ detail, mark, description, children = null }: { detail?: string; mark?: string; description?: string; children?: JSX.Element | null }) => {
  const { colors } = useContext(GlobalContext)
  const styles = useStyles({ colors })

  return (
    <Box sx={styles.container}>
      {!children ? (
        <Typography>
          {detail}
          {!!mark && <span className="bold">{mark}</span>}
        </Typography>
      ) : (
        children
      )}

      {!!description && <Typography className="bold">{description}</Typography>}
    </Box>
  )
}
