import { Box, Grid, Typography } from '@mui/material'
import { useBody } from './body'
import { useStyles } from './styles'
import { Line } from 'recharts'
import { CardIndicator } from '../../components/cardIndicator'
import { useTranslation } from 'react-i18next'
import { DinamicForm } from '../../../../../../components/dinamicForm'
import { inputs } from './constants/input'
import { useContextState } from '../../../../../../hooks/theme'
import Loader from '../../../../../../components/loader'
import { LinearGraphic } from '../../components/linearGrapgic'
import { Props } from './typing/types'
import { Item } from '../../../../../../utils/types/generals'
import { format, getWeek, parse, startOfYear } from 'date-fns'
import { Constants } from '../../../../../../utils/constants'
import { es } from 'date-fns/locale'
import { RedirectedIds } from '../../../../typing'
import { WeekLabel } from '../../components/weekLabel'

const Indicators = (props: Props) => {
  const {
    refreshCards,
    changeGraph,
    onChange,
    filterByHistory,
    dates,
    getFilterData,
    addLastPointOfTheGraphic,
    inputs: inputsUnits,
    loaders,
    isActive,
    graph,
    unitData,
    porcentage,
    programmingData,
    actionPlanData,
  } = useBody(props)
  const styles = useStyles()
  const { colors } = useContextState()
  const { t } = useTranslation()
  const graphicList = addLastPointOfTheGraphic()
  const wizardLinear = () => {
    const childrens = [
      <Line
        key="programming"
        type="linear"
        strokeWidth={2}
        dataKey="programming"
        name={t('dashboard.kpi.programmed')}
        stroke={colors.color1}
        activeDot={{ r: 8 }}
      // stroke="url(#solids)" legendType="none"
      />,
      <Line
        key="noProgramming"
        type="linear"
        strokeWidth={2}
        dataKey="noProgramming"
        name={t('dashboard.kpi.noProgrammed')}
        stroke={colors.color34}
        activeDot={{ r: 8 }}
      />,
      <Line
        key="total"
        type="linear"
        strokeWidth={2}
        dataKey="total"
        name={t('dashboard.kpi.total')}
        stroke={colors.color10}
        activeDot={{ r: 8 }}
      />,
      <Line
        key="paused"
        type="linear"
        strokeWidth={2}
        dataKey="paused"
        name={t('dashboard.kpi.inProgress')}
        stroke={colors.color34}
        activeDot={{ r: 8 }}
      />,
      <Line
        key="noStarted"
        type="linear"
        strokeWidth={2}
        dataKey="noStarted"
        name={t('dashboard.kpi.noStarted')}
        stroke={colors.color2}
        activeDot={{ r: 8 }}
      />,
      <Line
        key="finished"
        type="linear"
        strokeWidth={2}
        dataKey="finished"
        name={t('dashboard.kpi.finished')}
        stroke={colors.color4}
        activeDot={{ r: 8 }}
      />,
      <Line
        key="rejected"
        type="linear"
        strokeWidth={2}
        dataKey="rejected"
        name={t('dashboard.kpi.rejected')}
        stroke={colors.secondary}
        activeDot={{ r: 8 }}
      />,
      <Line
        key="canceled"
        type="linear"
        strokeWidth={2}
        dataKey="canceled"
        name={t('dashboard.kpi.canceled')}
        stroke={colors.primary}
        activeDot={{ r: 8 }}
      />,
      <Line
        key="expired"
        type="linear"
        strokeWidth={2}
        dataKey="expired"
        name={t('dashboard.kpi.expired')}
        stroke={colors.color8}
        activeDot={{ r: 8 }}
      />,
    ]
    const intervaleValue = filterByHistory[graph].filterData?.intervale?.value
    const index = intervaleValue == '1d' || !intervaleValue ? 0 : 1
    if (graph === 'units') {
      return childrens.slice(0, 1)
    } else if (graph === 'programming') {
      return childrens.slice(2 + index, 6)
    }

    return childrens.slice(2 + index, 9)
  }
  const isWeeke = filterByHistory[graph]?.filterData?.intervale?.value === '1w'

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item lg={4.1} md={12} sm={12} xs={12}>
          <Grid container spacing={3} >
            <Grid sx={styles.customeGrid} item lg={12}>
              <div id={RedirectedIds.INDICATORS_UNITS} />
              <CardIndicator
                items={{
                  class: props.zoneAndClass?.class as Item[]
                }}
                titleModal={t('dashboard.indicators.modal.title.unit')}
                getFilterData={(data) => { getFilterData({ data, graph: 'units' }) }}
                inputs={inputsUnits}
                lastUpdate={`${t('dashboard.data.week')}${`: `}${dates.unitWeek} - ${dates.unitDate}`}
                isActive={isActive.units}
                isLoading={loaders.units}
                title={t('dashboard.kpi.titles.activedUnits')}
                list={Object.values(unitData)}
                value={`${porcentage.units.toLocaleString('en-US')}`}
                clicTimeline={() => {
                  changeGraph({ type: 'units' })
                }}
                refresh={() => {
                  refreshCards({ name: 'units' })
                }}
              />
            </Grid>

            <Grid item lg={12} sx={styles.customeGrid}>
              <div id={RedirectedIds.INDICATORS_PROGRAMATIONS} />
              <CardIndicator
                titleModal={t('dashboard.indicators.modal.title.programmed')}
                items={{
                  class: props.zoneAndClass?.class as Item[]
                }}
                getFilterData={(data) => { getFilterData({ data, graph: 'programming' }) }}
                inputs={inputsUnits}
                lastUpdate={`${t('dashboard.data.week')}${`: `}${dates.programmingWeek} - ${dates.programmingDate}`}
                isActive={isActive.programming}
                isLoading={loaders.programming}
                title={t('dashboard.kpi.titles.programmed')}
                list={Object.values(programmingData)}
                value={`${porcentage.programming.toLocaleString('en-US')}`}
                clicTimeline={() => {
                  changeGraph({ type: 'programming' })
                }}
                refresh={() => {
                  refreshCards({ name: 'programming' })
                }}
              />
            </Grid>

            <Grid item lg={12} sx={styles.customeGrid}>
              <div id={RedirectedIds.INDICATORS_ACTION_PLAN} />
              <CardIndicator
                titleModal={t('dashboard.indicators.modal.title.actionPlan')}
                items={{
                  class: props.zoneAndClass?.class as Item[]
                }}
                getFilterData={(data) => { getFilterData({ data, graph: 'actionPlan' }) }}
                inputs={inputsUnits}
                lastUpdate={`${t('dashboard.data.week')}${`: `}${dates.actionPLanWeek} - ${dates.actionPLanDate}`}
                isActive={isActive.actionPlan}
                isLoading={loaders.actionPlan}
                title={t('dashboard.kpi.titles.actionPlan')}
                list={Object.values(actionPlanData)}
                value={`${porcentage.actionPlan.toLocaleString('en-US')}`}
                clicTimeline={() => {
                  changeGraph({ type: 'actionPlan' })
                }}
                refresh={() => {
                  refreshCards({ name: 'actionPlan' })
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={7.9} md={12} sm={12} xs={12}>
          <Box sx={styles.containerGraph}>
            <Box sx={styles.detailGraph}>
              <Box sx={styles.headerIndicator}>
                <Typography sx={styles.titleIndicator}>
                  {t(`dashboard.indicators.titles.${graph}`)}
                </Typography>

                <Box sx={styles.maxField}>
                  <DinamicForm
                    legacyErrors
                    errors={filterByHistory[graph].errors}
                    input={Object.values({
                      ...inputs,
                      from: {
                        ...inputs?.from,
                        rest: {
                          ...filterByHistory[graph]?.filterData?.intervale?.value === '1w' ? {
                            shouldDisableDate: (date: Date) => new Date(date.valueOf())?.getDay?.() !== 1 || new Date(date.valueOf()) > filterByHistory[graph]?.filterData?.to,
                            textField: {
                              disabled: true
                            }
                          } : {
                            shouldDisableDate: (date: Date) => new Date(date.valueOf()) > filterByHistory[graph]?.filterData?.to,

                          }
                        }
                      }
                    })}
                    values={filterByHistory[graph]?.filterData}
                    onChange={onChange}
                  />{' '}
                </Box>
              </Box>
            </Box>

            <Box sx={styles.graph}>
              {!!loaders?.table ? (
                <Loader />
              ) : !!graphicList?.length ? (
                <LinearGraphic
                  brush
                  xHeader={isWeeke ? { component: ({ value }) => <WeekLabel value={value} /> } : undefined}
                  customeX={{
                    y: isWeeke ? 15 : 0,
                    transform: '',
                    component: ({ value }) => {
                      return value ? format(parse(value, Constants.dateFormat, new Date()), Constants.humanFormatDiminutiveShort, { locale: es }) : ``
                    }
                  }}
                  customeTitle={({ value }) => {
                    const getDate = parse(value, Constants.dateFormat, new Date())
                    const dayIsMonday = startOfYear(new Date()).getDay() == 1
                    let week = getWeek(new Date(getDate), { weekStartsOn: 1 })
                    if (!dayIsMonday) { week = week - 1 }

                    return (
                      <Box>
                        {filterByHistory[graph]?.filterData?.intervale?.value === '1w' && (
                          <Typography
                            component="p"
                            id="CustomTooltip"
                            sx={{
                              fontSize: '16px',
                              width: '100%',
                            }}
                          >
                            {t('dashboard.data.week')}{`: `}{week}
                          </Typography>
                        )}

                        <Typography
                          component="p"
                          id="CustomTooltip"
                          sx={{
                            fontSize: '16px',
                            width: '100%',
                          }}
                        >
                          {format(getDate, Constants.humanFormatShort, { locale: es })}
                        </Typography>
                      </Box>
                    )
                  }}
                  datakey='name'
                  graphicHistory={graphicList}
                  linears={wizardLinear()}
                  int
                />
              ) : (
                <Typography sx={styles.noData}>
                  {t('reportFolio.detail.general.noInformation')}
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

export { Indicators }
