import { useState, useEffect } from 'react'
import { InputModel } from '../../../../../utils/types'
import { Item } from '../../../../../utils/types/generals'
import { Roles } from '../../../../../utils/enums'

const useInput = ({ items }: { items?: { [x: string]: Item[] } }) => {
  const [input, setInput] = useState<{ [x: string]: InputModel }>({
    companies: {
      label: 'general.tags.companies',
      translate: true,
      name: 'companies',
      type: 'filter-select',
      items: items?.companies ?? [],
      value: null,
      notAllowedRoles: [
        Roles.AGENT, 
        Roles.STORE, 
        Roles.AUDIT,
        Roles.GERENT,
        Roles.PARTNER,
        Roles.SUPPORT,
        Roles.SUPERVISOR,
      ],
    },
    users: {
      label: 'general.tags.users',
      translate: true,
      name: 'users',
      type: 'filter-select',
      items: items?.users ?? [],
      value: null,
    },
    userType: {
      label: 'general.tags.userType',
      translate: true,
      name: 'userType',
      type: 'filter-select',
      items: items?.userType ?? [],
      value: null,
    },
    divisions: {
      label: 'general.tags.division',
      translate: true,
      name: 'divisions',
      type: 'filter-select',
      items: items?.divisions ?? [],
      value: null,
    },
    roles: {
      label: 'general.tags.roles',
      translate: true,
      name: 'roles',
      type: 'filter-select',
      items: items?.status ?? [],
      value: null,
      notAllowedRoles: [Roles.AGENT, Roles.STORE],
    },
    areas: {
      label: 'general.tags.area',
      translate: true,
      name: 'areas',
      type: 'filter-select',
      items: items?.area ?? [],
      value: null,
    },
    departments: {
      label: 'general.tags.department',
      translate: true,
      name: 'departments',
      type: 'filter-select',
      items: [],
      value: null,
    },
  })

  // const disabledInputs = (data: { inputs: string[]; value: boolean }) => {
  //   let copyInput: {
  //     [x: string]: InputModel
  //   } = removeAllReference(input)

  //   data?.inputs?.forEach((item) => {
  //     copyInput[item] = {
  //       ...copyInput[item],
  //       rest: {
  //         ...copyInput[item]?.rest ?? {},
  //         disabled: data.value,
  //       },
  //     }
  //   })

  //   setInput((prev) => ({
  //     ...prev,
  //     ...copyInput,
  //   }))
  // }

  useEffect(() => {
    if (!!Object.values(items ?? {})?.length) {
      let body: Record<string, InputModel> = {}
      const inputCopy = structuredClone(input)
      Object.entries(inputCopy).forEach(([key, itemInputs]) => {
        body = { ...body, [key]: { ...itemInputs, items: items?.[key] } }
      })
      setInput(body)
    }
    // eslint-disable-next-line
  }, [items])

  return { input }
}

export { useInput }
