import { Box, Button, IconButton, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useStyles } from './styles'
import StepperCreate from '../../../../components/stepperCreate'
import { useTranslation } from 'react-i18next'
import { icons } from '../../../../utils/icons'
import { useContextState } from '../../../../hooks/theme'
import { useEffect, useRef, useState } from 'react'
import { FiltersDrawer } from '../../../../components/filtersDrawer'
import { DinamicForm } from '../../../../components/dinamicForm'
import { ObjectGeneric } from '../../../../utils/types/generals'
import { useColumns, useSteps } from './hooks/tables'
import SimpleModal from '../../../../components/modal/simpleModal'
import { validateDateTime } from '../../../../utils/methods/validateDateRange'
import { CurrentStep } from '../components'
import { useGetDAta } from './hooks/getDAta'
import { useFilter } from './hooks/useFilter'
import { DetailKeyValue } from '../../../../components'
import { useInput } from './hooks/input'
import { format } from 'date-fns'
import { Constants } from '../../../../utils/constants'
import { userScheduleServiceFetch } from '../../../../utils/constants/callService'
import { FormatCreateAndUpdate } from './typing'
import { AssignScheduleErrors } from '../../../../service/userSchedule/response'
import { userCannotSelectcUsers } from './methods/validateByUser'

const UserScheduleDetail = () => {
  const refTable = useRef<{ removeChecks?: (payload: { value: boolean }) => void }>({})
  const { id } = useParams()
  const styles = useStyles()
  const { list, isLoadingEndpoints, listItems, userAndQuestionnaireDetail } =
    useGetDAta({ id })
  const navigate = useNavigate()
  const { stepsList, titles, footers } = useSteps({ id })
  const { t } = useTranslation()
  const { colors } = useContextState()
  const [steps, setSteps] = useState(!!id || userCannotSelectcUsers() ? 1 : 0)
  const [errors, setErrors] = useState<ObjectGeneric>({})
  const [data, setData] = useState<ObjectGeneric>({})
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false)
  const [useScheduled, setUserScheduled] = useState<
    Record<string, ObjectGeneric>
  >({})
  const [selectSchedule, setSelectSchedule] = useState<
    Record<string, (number | string)[]>
  >({})
  const {
    columns,
    currentUser,
    userSelectes,
    handleCloseScheduleUser,
    handlTableItemsHaveErrors,
  } = useColumns({
    useScheduled,
  })
  const {
    handleFilter,
    handleCheanFilter,
    row,
    filterData,
    counter,
    disabledChecks,
    removeDisabled,
    handledSent,
  } = useFilter({
    steps,
    lists: list,
    selectSchedule,
    useScheduled,
  })
  
  const { input } = useInput({ listItems, steps })
  const [openModal, setOpenModal] = useState<{
    open: boolean
    title: string
    description: string
    removeCancel?: boolean
    method?: () => void
    children?: JSX.Element
  }>({
    open: false,
    title: t('reportFolio.snackbar.areYouSureonDeleteThisFile'),
    description: '',
  })
  
  const handleSelect = ({ checks }: { checks: (number | string)[] }) => {
    if (steps > 1) {
      let selectedWithSchedule: (number | string)[] = []
      
      checks.forEach((item) => {
        if (!!useScheduled[item]) {
          selectedWithSchedule.push(item)
        }
      })
      removeDisabled({ arr: selectedWithSchedule })
      setSelectSchedule((prev) => ({
        ...prev,
        [steps]: selectedWithSchedule,
      }))
    } else {
      let newScheduled:string[] = []
      selectSchedule[2]?.forEach((item: any) => {
        const splitItem = item.split('-')
        if(!checks.some(itemCHeck => itemCHeck == splitItem[steps])) {
          newScheduled.push(item)
        }
      })
      
      setSelectSchedule((prev) => ({
        ...prev,
        [steps]: checks,
        2: prev?.[2]?.length ? prev[2].filter(item => !newScheduled.includes(item.toString())) : []
      }))
    }
  }

  const goBack = () => {
    setOpenModal((prev) => ({
      ...prev,
      title: t('general.exit'),
      open: true,
      children: undefined,
      method: () => {
        navigate('/dashboard/schedule-user/list')
      },
    }))
  }

  const handleCloseModal = () => {
    setOpenModal((prev) => ({
      ...prev,
      open: false,
      children: undefined,
    }))
    setIsLoadingConfirm(false)
  }

  const handleSteps = (step: number) => {
    handleCloseScheduleUser()
    if ([3].includes(step + (steps ?? 0))) {
      areYouSureOfScheduled()
    } else {
      setSteps((prev) => prev + step)
    }
  }

  const areYouSureOfScheduled = () => {
    setOpenModal((prev) => ({
      ...prev,
      title: t(`schedule.modal.submit.${id ? 'titleUpdate' : 'title'}`),
      description: t(`schedule.modal.submit.${id ? 'descriptionUpdate' : 'description'}`),
      removeCancel: undefined,
      open: true,
      children: undefined,
      method: () => {
        confirmSchedule()
      },
    }))
  }

  const confirmSchedule = () => {
    handleCloseScheduleUser()
    const notAllItemsAreMArked = row?.some(
      (item: any) => !selectSchedule[steps]?.includes(item?.id ?? '')
    )
    let title = ''
    
    if (notAllItemsAreMArked) {
      title = t('useScheduled.detail.errors.someScheduleWereNotSelected')
    }

    if (!title) {
      submitSchedule({ sentAll: true })
    } else {
      setOpenModal((prev) => ({
        ...prev,
        title: title,
        description: !!title
          ? t('useScheduled.detail.errors.doYouWantToCreateTheSchedule')
          : '',
        removeCancel: undefined,
        open: true,
        children: undefined,
        method: () => {
          submitSchedule()
        },
      }))
    }
  }

  const createOrEditSubmit = async ({
    body,
    copySelectedSchedule,
    sentAll,
  }: {
    body: FormatCreateAndUpdate[]
    copySelectedSchedule: (string | number)[]
    sentAll?: boolean
  }) => {
    let response: AssignScheduleErrors
    setIsLoadingConfirm(true)
    if (!id) {
      response = await userScheduleServiceFetch.createrUserSchedule({
        body,
      })
    } else {
      response = await userScheduleServiceFetch.updateUserSchedule({
        body: body[0],
        id,
      })
    }
    
    setIsLoadingConfirm(false)
    let errors: Record<string, string[]> = {}

    if (['01', '02'].includes(response.code) && response.code != '404') {
      const responseData = response?.data instanceof Array ? response?.data : [response?.data]
      responseData?.forEach((item) => {
        const userAndQuestionnareIds = `${item.userAssignId}-${item.questionnaireMainId}`
        if (!!copySelectedSchedule?.length) {
          copySelectedSchedule = copySelectedSchedule.filter(
            (itemSchedule) => itemSchedule != userAndQuestionnareIds
          )
        }

        errors = {
          ...errors,
          [userAndQuestionnareIds]: item.errors.map(
            (itemErrors) => itemErrors.code
          ),
        }
      })
      
      handledSent({ sent: copySelectedSchedule as string[] })
      setSelectSchedule((prev) => ({
        ...prev,
        [steps]: prev[steps]?.filter(
          (item) =>
            !copySelectedSchedule.some((itemSelected) => itemSelected == item)
        ),
      }))

      handlTableItemsHaveErrors({ id: errors })
    } else {
      handledSent({ sent: copySelectedSchedule as string[] })
      setSelectSchedule((prev) => ({
        ...prev,
        [steps]: prev[steps]?.filter(
          (item) =>
            !copySelectedSchedule.some((itemSelected) => itemSelected == item)
        ),
      }))
    }

    if (!Object.values(errors)?.length && response.code != '404') {
      setOpenModal((prev) => ({
        ...prev,
        title: t(
          `useScheduled.detail.errors.${!!id ? 'scheduleWereUpdated' : 'scheduleWereCreated'
          }`
        ),
        description: '',
        removeCancel: true,
        open: true,
        children: undefined,
        method: () => {
          if (!!sentAll) {
            navigate('/dashboard/schedule-user/list/')
          } else {
            handleCloseModal()
          }
        },
      }))
    } else {
      const isUpdate = !!id ? 'wasAnErrorUpdated' : 'wasAnErrorCreated'
      setOpenModal((prev) => ({
        ...prev,
        title: t(`useScheduled.detail.errors.${response?.code == '404' ? isUpdate : 'someScheduledWereNotCreated'}`),
        description: '',
        removeCancel: true,
        open: true,
        children: undefined,
        method: () => {
          handleCloseModal()
        },
      }))
    }
  }
  
  const submitSchedule = async (payload?: { sentAll?: boolean }) => {
    let copySelectedSchedule = structuredClone(selectSchedule[steps])
    let scheduledExpired: {
      title: string
      titleExpired: string
      expired: {
        user: string
        questionnaire: string
      }[]
    } = {
      title: '',
      titleExpired: '',
      expired: [],
    }

    setIsLoadingConfirm(true)
    let body: {
      dateStart: string
      dateEnd: string
      questionnaireMainId: number
      userAssignId: number
      notified: number
      notifyAdmin: number
    }[] = []

    selectSchedule[steps]?.forEach((item) => {
      const scheduledOsExpired =
        useScheduled[item]?.to <= Date.now() 

      if (!!scheduledOsExpired) {
        scheduledExpired.title = t(
          'useScheduled.detail.errors.someDatesAreExpired'
        )
        const schedule: any = row?.find(
          (itemSchedule: any) => itemSchedule?.id == item
        )
        copySelectedSchedule = copySelectedSchedule.filter(
          (itemSelected) => itemSelected != item
        )
        scheduledExpired.expired.push({
          user: schedule?.user,
          questionnaire: schedule?.questionnaireName,
        })
      }
      const userAndQuestionaire = item.toString().split('-')

      body.push({
        dateStart: useScheduled[item]?.from
          ? format(useScheduled[item]?.from, Constants.dateEndpoint)
          : '',
        dateEnd: useScheduled[item]?.to
          ? format(useScheduled[item]?.to, Constants.dateEndpoint)
          : '',
        notified: !!useScheduled[item]?.notified ? 1 : 0,
        notifyAdmin: !!useScheduled[item]?.notifyAdmin ? 1 : 0,
        questionnaireMainId: parseInt(userAndQuestionaire[1]),
        userAssignId: parseInt(userAndQuestionaire[0]),
      })
    })
    if (!!body?.length) {
      if (!!scheduledExpired?.expired?.length) {
        setOpenModal((prev) => ({
          ...prev,
          title: scheduledExpired.title,
          description: t('useScheduled.detail.errors.doYouWantToCreateTheSchedule'),
          open: true,
          // removeCancel: true,
          method: () => {
            createOrEditSubmit({ body, copySelectedSchedule })
          },
          children: !!scheduledExpired?.expired?.length ? (
            <ul style={styles.ul}>
              {scheduledExpired?.expired?.map((item) => (
                <li>
                  <DetailKeyValue
                    sxContainer={styles.containerUl}
                    valueSize="12px"
                    captionSize="10px"
                    value={item.user}
                    caption={item.questionnaire}
                  />
                </li>
              ))}
            </ul>
          ) : undefined,
        }))
      } else {
        createOrEditSubmit({
          body,
          copySelectedSchedule,
          sentAll: payload?.sentAll,
        })
      }
    } else {
      setOpenModal((prev) => ({
        ...prev,
        title: t('useScheduled.detail.errors.wrongDates'),
        description: '',
        removeCancel: true,
        open: true,
        children: undefined,
        method: () => {
          handleCloseModal()
        },
      }))
    }
  }
  const disableAssginedSchedule = () => {
    const existErrors = Object.values(errors ?? {})?.some((item) => !!item)
    const from = data?.from
    const to = data?.to

    return !(from?.valueOf?.() && to?.valueOf?.()) || !!existErrors
  }

  const handleDisabled = (payload?: { schedule: boolean }) => {
    const isAnyThingSelected = !!selectSchedule[steps]?.length

    const disabledBySteps: Record<string, () => boolean> = {
      2: () => {
        return !selectSchedule[steps]?.some((item) => useScheduled[item])
      },
    }

    if (!!payload?.schedule) {
      return !!disabledBySteps?.[steps]?.()
    }

    return !isAnyThingSelected || !!disabledBySteps?.[steps]?.() || (!!id && !!isLoadingEndpoints?.questionnaires)
  }

  const onChange = ({
    target,
    keyboard,
  }: {
    target: { name: string; value: any }
    keyboard?: string
  }) => {
    const { name, value } = target
    const from = name === 'from' ? value : data?.from
    const to = name === 'to' ? value : data?.to

    setErrors((prev) => ({
      ...prev,
      from:
        from < Date.now() ? t('useScheduled.detail.errors.minimus0minute') : '',
      to:
        to < from + 250000
          ? t('useScheduled.detail.errors.minimus5minute')
          : '',
    }))

    if (keyboard) {
      const reponse = validateDateTime(keyboard)
      setErrors((prev) => ({
        ...prev,
        [name]: !reponse
          ? t('useScheduled.detail.errors.invalidaDtae')
          : prev?.[name] ?? '',
      }))
    }

    if (name === 'from') {
      if (!data?.to || data?.to <= value) {
        const to = data?.to ?? value + 300000

        setData((prev) => ({
          ...prev,
          to: to < value ? value + 300000 : to,
        }))

        setErrors((prev) => ({
          ...prev,
          to: '',
        }))
      }
    }

    setData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  useEffect(() => {
    const idUser = userSelectes?.id
    setErrors({})
    if (!!idUser) {
      const dataLocal = useScheduled[idUser]
      if (!!dataLocal) {
        setData((prev) => ({
          ...prev,
          ...dataLocal,
        }))
      } else {
        setData((prev) => ({
          ...prev,
          notified: false,
          notifyAdmin: false,
          from: Date.now() + 50000,
          to: Date.now() + 750000,
        }))
      }
      if (dataLocal?.to < Date.now()) {
        setErrors((prev) => ({
          ...prev,
          to: dataLocal?.to < Date.now() ? t('useScheduled.detail.errors.currentDateIsNotValidate') : t('useScheduled.detail.errors.minimus5minute'),
        }))
      }
    }
  }, [userSelectes.id])

  const keepSaveSchedule = () => {
    const from = data?.from
    const to = data?.to

    if (from < Date.now()) {
      setErrors((prev) => ({
        ...prev,
        from: t('useScheduled.detail.errors.minimus0minute'),
      }))
    }

    if (to < from + 250000) {
      setErrors((prev) => ({
        ...prev,
        to: t('useScheduled.detail.errors.minimus5minute'),
      }))
    }

    if (from >= Date.now() && to > from + 250000) {
      let disabledRemovedList:(string | number)[] = []
      let allUserSchedule = {}
      if(userSelectes.id == 'all') {
        for(const item of row as {id: string}[]){
          disabledRemovedList.push(item?.id)
          allUserSchedule = {
            ...allUserSchedule,
            [item.id]: data
          }
        }
      } else {
        disabledRemovedList = [userSelectes.id]
        allUserSchedule = {
          ...useScheduled,
          [userSelectes.id]: data
        }
      }
      removeDisabled({ arr: disabledRemovedList })
      refTable?.current && refTable?.current?.removeChecks?.({ value: false })
      setUserScheduled(allUserSchedule)

      handleCloseScheduleUser()
    }
  }

  const backButton = () => {
    const button = (
      <Button onClick={() => handleSteps(-1)}>{t('general.return')}</Button>
    )
    const box = <Box className="back" />

    if (!id && !userCannotSelectcUsers()) {
      return !!steps ? button : box
    }
    return steps > 1 ? button : box
  }

  useEffect(() => {
    const userId = userAndQuestionnaireDetail.user
    const questionnaireId = userAndQuestionnaireDetail.questionnaire
    if (!!id && !!userId && !!questionnaireId) {
      const schedule = JSON.parse(
        (userAndQuestionnaireDetail.userAndQuestionnaire as string) ?? ''
      )
      const from = new Date(schedule?.from).getTime()
      const finishFrom = from < Date.now() + 50000 ? Date.now() + 50000 : from
      const to = new Date(schedule?.to).getTime()
      const finishTo = to < finishFrom + 750000 ? finishFrom + 750000 : to

      setUserScheduled((prev) => ({
        ...prev,
        [`${userId}-${questionnaireId}`]: {
          from: finishFrom,
          to: finishTo,
          notifyAdmin: schedule?.notifyAdmin,
          notified: schedule?.notified,
        },
      }))

      setSelectSchedule((prev) => ({
        ...prev,
        0: [userId],
        1: [questionnaireId],
      }))
    }
  }, [id, userAndQuestionnaireDetail])

  useEffect(() => {
    if (!id && userCannotSelectcUsers() && list?.users?.[0]?.id?.toString()) {
      setSelectSchedule((prev) => ({
        ...prev,
        0: [list?.users?.[0]?.id?.toString() ?? ''],
      }))
    }
  }, [list])
  
  const disabledUserWithoutPermissions = () => {
    return disabledChecks?.[steps] ?? []
  }
  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Box sx={styles.headerLeft}>
          <IconButton onClick={() => goBack()}>
            {icons('arrow-left-fill')}
          </IconButton>

          <Typography>
            {!id
              ? t('schedule.assigned.newPlanification')
              : t('schedule.assigned.editPlanification')}
          </Typography>
        </Box>

        <Box sx={styles.stepper}>
          <StepperCreate
            color={colors}
            steps={
              stepsList
                ?.filter((item) => !!item)
                .map((item) => ({
                  label: item.label,
                  step: `Paso ${item.step}`,
                })) as {
                  label: string
                  step: string
                }[]
            }
            activeStep={!!id || userCannotSelectcUsers() ? steps - 1 : steps}
          />
        </Box>
      </Box>

      <Box sx={styles.buttonsContainer}>
        {backButton()}

        {(steps ?? 0) < 2 && !id && (
          <Typography className="message">
            {steps === 0 && !id
              ? t('schedule.assigned.doYouNeedSelectedAlLeastOneU')
              : t('schedule.assigned.doYouNeedSelectedAlLeastOneQ')}
          </Typography>
        )}

        <Box sx={styles.flex}>
          <Button
            disabled={handleDisabled()}
            className="next"
            variant="contained"
            onClick={() => {
              if (!id || (!!id && !isLoadingEndpoints?.questionnaires)) {
                handleSteps(1)
              }
            }}
          >
            {[2, 3].includes(steps ?? 0)
              ? t(`schedule.buttons.${id ? 'updateScheduled' : 'addScheduled'}`)
              : t('general.continue')}
          </Button>
        </Box>
      </Box>

      <Box sx={styles.body}>
        <FiltersDrawer
          cleanFilter={handleCheanFilter}
          count={counter}
          right={0}
          top={30}
        >
          <Box sx={styles.section}>
            <DinamicForm
              errors={errors}
              input={Object.values(input[steps] ?? {})}
              values={filterData[steps] ?? {}}
              onChange={(e) => {
                handleFilter({ e })
              }}
              filter={true}
            />
          </Box>
        </FiltersDrawer>

        <Box sx={styles.headerTitle}>
          <Typography>{titles[steps + 1]}</Typography>

          <IconButton
            sx={(styles.clean, (counter || 0) > 0 ? null : styles.hide)}
            onClick={() => {
              handleCheanFilter()
            }}
          >
            {icons('clean')}
          </IconButton>
        </Box>

        <CurrentStep
          id={id}
          ref={refTable}
          disabledChecks={disabledUserWithoutPermissions()}
          isLoadingEndpoints={isLoadingEndpoints}
          columns={columns}
          rows={row}
          data={data}
          currentUser={currentUser}
          diabledSaveSchedule={disableAssginedSchedule()}
          errors={errors}
          handleCloseScheduleUser={handleCloseScheduleUser}
          keepSaveSchedule={keepSaveSchedule}
          handleSelect={handleSelect}
          onChange={onChange}
          selectSchedule={selectSchedule[steps]}
          steps={steps}
          userSelectes={userSelectes}
        />
      </Box>

      <Box sx={styles.buttonsContainer}>
        {backButton()}

        {(steps ?? 0) < 2 && !!footers?.[steps] && (
          <Typography className="message">{footers?.[steps] ?? ''}</Typography>
        )}

        <Button
          disabled={handleDisabled()}
          className="next"
          variant="contained"
          onClick={() => handleSteps(1)}
        >
          {[2, 3].includes(steps ?? 0)
            ? t(`schedule.buttons.${id ? 'updateScheduled' : 'addScheduled'}`)
            : t('general.continue')}
        </Button>
      </Box>

      <SimpleModal
        open={openModal.open}
        close={handleCloseModal}
        children={openModal.children}
        title={openModal.title}
        description={openModal.description}
        buttonText={t('general.confirm')}
        cancelText={!!openModal?.removeCancel ? undefined : t('general.cancel')}
        color={colors}
        back={!!openModal?.removeCancel ? undefined : handleCloseModal}
        loadingButton
        disableButton={isLoadingConfirm}
        isLoading={isLoadingConfirm}
        next={() => {
          openModal?.method?.()
        }}
      />
    </Box>
  )
}

export { UserScheduleDetail }
