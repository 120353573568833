import { TFunction, useTranslation } from "react-i18next"
import { InputModel } from "../../../../utils/types"
import { GridColDef } from "@mui/x-data-grid"
import StatusComponent from "../../../../components/status"
import { getStatus } from "../../../../hooks/status"
import { Color } from "../../../../utils/theme/types/color"
import { Status } from "../../../../utils/enums"
import { intervaleList } from "../../../../utils/constants/intervaleList"
import { Box, Typography } from "@mui/material"
import { getNumberWeek } from "../../../../utils/methods/toDate"

const useModelInput = () => {
    const { t } = useTranslation()
    return {
        intervale: {
            label: 'general.tags.intervale',
            translate: true,
            name: 'intervale',
            type: 'select-search',
            items: intervaleList,
            value: null,
            wrapChildren: ({ children, id }) => {
                return (
                    <Box key={id}>
                        <Typography sx={{
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#707070',
                            marginBottom: '20px',
                        }}>{t('general.intervale.intervale')}</Typography>

                        {children}
                    </Box>
                )
            }
        },
        from: {
            label: 'general.tags.from',
            translate: true,
            name: 'from',
            type: 'date',
            value: null,
            rest: {
                max: 'to',
            },
            size: {
                xl: 6,
                lg: 6,
                md: 6,
                sm: 6,
                xs: 6,
            },
            wrapChildren: ({ children, id }) => {
                return (
                    <Box key={id}>
                        <Typography sx={{
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#707070',
                            marginBottom: '20px',
                        }}>{t('general.filters')}</Typography>

                        {children}
                    </Box>
                )
            }
        },
        to: {
            label: 'general.tags.to',
            translate: true,
            name: 'to',
            type: 'date',
            value: null,
            rest: {
                min: 'from',
            },
            size: {
                xl: 6,
                lg: 6,
                md: 6,
                sm: 6,
                xs: 6,
            },
            itemChildren: {
                alignSelf: "end"
            }
        },
        unidad_id: {
            label: 'general.tags.unit',
            translate: true,
            name: 'unidad_id',
            type: 'filter-select',
            items: [],
            value: null,
        },
        area: {
            label: 'general.tags.area',
            translate: true,
            name: 'area',
            type: 'filter-select',
            items: [],
            value: null,
        },
        department: {
            label: 'general.tags.department',
            translate: true,
            name: 'department',
            type: 'filter-select',
            items: [],
            value: [],
        },
        questionnaire: {
            label: 'general.tags.questionnaire',
            translate: true,
            name: 'questionnaire',
            type: 'filter-select',
            items: [],
            value: [],
        },
        questionnaireStatus: {
            label: 'general.tags.questionnaireStatus',
            translate: true,
            name: 'questionnaireStatus',
            type: 'filter-select',
            items: [],
            value: [],
        },
    } as { [x: string]: InputModel }
}

const columsTranslate = ({ t, colors, intervale }: { t: TFunction<"translation", undefined>; colors: Color, intervale: string }): GridColDef[] => {
    let column = {
        '1d': ([
            {
                field: 'area',
                headerName: t('dashboard.data.area'),
                flex: 1,
            },
            {
                field: 'departamento',
                headerName: t('general.tags.department'),
                flex: 1,
            },
            {
                field: 'fecha',
                headerName: t('general.date'),
                flex: 1,
            },
            {
                field: 'usuario_asignado',
                headerName: t('general.tags.user'),
                flex: 1,
            },
            {
                field: 'unidad',
                headerName: t('general.tags.unit'),
                flex: 1,
            },
            {
                field: 'cuestionario',
                headerName: t('general.tags.questionnaire'),
                flex: 1,
            },
            {
                field: 'status',
                headerName: t('general.tags.status'),
                renderCell: (row: any) => {
                    const statusQuestionnaire = getStatus(row.row.estatus_id as Status, colors, t)
                    return (
                        <StatusComponent
                            color={statusQuestionnaire?.color}
                            msg={row.estatus_id == Status.WAITING ? "En progreso" : statusQuestionnaire?.text}
                            size="small"
                        />
                    )
                },
                flex: 1,
            },
        ]),
        '1w': ([
            {
                field: 'area',
                headerName: t('dashboard.data.area'),
                flex: 1,
            },
            {
                field: 'departamento',
                headerName: t('general.tags.department'),
                flex: 1,
            },
            {
                field: 'semana',
                headerName: t('general.week'),
                flex: 1,
                renderCell: (row: any) => {
                    return row.row.fecha_date ? `${t('general.week')} ${getNumberWeek(new Date(row.row.fecha_date))}` : "-"
                },
            },
            {
                field: 'usuario_asignado',
                headerName: t('general.tags.user'),
                flex: 1,
            },
            {
                field: 'unidad',
                headerName: t('general.tags.unit'),
                flex: 1,
            },
            {
                field: 'cuestionario',
                headerName: t('general.tags.questionnaire'),
                flex: 1,
            },
            {
                field: 'status',
                headerName: t('general.tags.status'),
                renderCell: (row: any) => {
                    const statusQuestionnaire = getStatus(row.row.estatus_id as Status, colors, t)
                    return (
                        <StatusComponent
                            color={statusQuestionnaire?.color}
                            msg={row.estatus_id == Status.WAITING ? "En progreso" : statusQuestionnaire?.text}
                            size="small"
                        />
                    )
                },
                flex: 1,
            },
        ])
    }
    return intervale == '1w' ? column["1w"] : column["1d"]
}

const statusList = [Status.WAITING, Status.TO_BE_STARTED, Status.FINISHED, Status.INCOMPLETE, Status.EXPIRED, Status.CANCEL]
export { useModelInput, statusList, columsTranslate }