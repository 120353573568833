import { Color } from "../../../utils/theme/types/color";

export const useStyles = ({errors, colors}:{errors?:any, colors:Color}) => ({
  image: {
    width: '25px',
    height: '25px',
  },
  icon: {
    padding: 0,
    marginLeft: '8px',
    '& .MuiSvgIcon-root': {
      color: !!errors ? colors.color28 : colors.color16
    }
  },
  containerPreview: {
    position: 'relative',
    "& .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline": {
      borderColor: !!errors ? colors.color28 : "none"
    },
  },
  close: {
    position: 'absolute',
    top: '-20px',
    right: '-20px',
    fontSize: '1rem',
    '& .MuiSvgIcon-root': {
      fontSize: '.8em'
    }
  }
})
