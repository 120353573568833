import { Color } from '../../../../utils/theme/types/color'

export const useStyles = (color: Color) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
  },
  menuIcons: {
    marginLeft: '5px',
    fontSize: '18px',
    '& .MuiSvgIcon-root': {
      fontSize: '18px',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
    position: 'relative',
  },
  title: {
    fontSize: '20px',
    fontWeight: '600',
    color: color.color13,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    '&.flex-j-e': {
      justifyContent: 'end',
    },
  },
  loader: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  section: {
    marginBottom: '32px',
  },
  clean: {
    fontSize: '2.4rem',
    color: color.color4,
    padding: '6px',
    '& .MuiSvgIcon-root': {
      fontSize: '1em',
    },
  },
  hide: {
    visibility: 'hidden',
  },
})
