import { useContextState } from '../../../../hooks/theme'

export const useStyles = () => {
  const { colors } = useContextState()
  return {
    title: {
      fontSize: '20px',
      fontWeight: '600',
      color: colors.color13,
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      marginRight: '10px'
    },
    containerStatus: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    baseMenu: {
      '& .MuiPopover-paper.MuiPopover-paper.MuiPopover-paper.MuiPopover-paper.MuiPopover-paper.MuiPopover-paper.MuiPopover-paper':
        {
          boxShadow: `1px 1px ${colors.color13}30`,
        },
    },
    label: {
      wordBreak: 'break-word',
      fontSize: '1rem',
      color: colors.color13,
      width: 'max-content'
    },
    viewMore: {
      fontSize: '1rem',
      color: colors.color16,
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '20px',
      position: 'relative',
    },
    bodyText: {
      color: colors.color10,
      fontWeigt: '800',
      fontSize: '12px',
      wordBreak: 'break-word',
      maxHeight: '40px',
      textAlign: 'center',
    },
    container: {
      width: '100%',
      height: '100%',
      paddingTop: '50px',
      paddingRight: '40px',
      paddingBottom: '10px',
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'relative',
    },
    containerItems: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      columnGap: '5px',
      justifyContent: 'space-between',
      padding: '0 5px',
      '& .MuiIcon-root': {
        fontSize: '1.2rem',
        '& .MuiSvgIcon-root': {
          fontSize: '1em',
        },
      },
    },
    containerIconTooltip: {
      height: '15px',
    },
    containerTooltip: {
      padding: '5px',
      width: 'fit-content',
      minWidth: '150px',
    },
    fitContent: {
      width: 'fit-content',
    },
    titleQuestion: {
      fontSize: '14px',
      marginBottom: '5px',
      width: 'fit-content',
      wordBreak: 'break-all',
    },
    containerTooltipAsk: {
      width: '100%',
      display: 'flex',
      columnGap: '10px',
    },
    listAnswers: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
      '&.flex-j-e': {
        justifyContent: 'end',
      },
    },
    titleTooltip: {
      minWidth: 'fit-content',
      height: '100%',
    },
    items: {
      paddingLeft: '5px',
    },
    pdf: {
      display: 'flex',
      justifyContent: 'center',
      '& .MuiIconButton-root': {
        '&.notLoad': {
          color: colors.color4,
        },
        '&.isLoad': {
          cursor: 'default',
        },
      },
    },
    textItem: {
      fontSize: '12px',
      fontWeight: '400',
      width: '100%',
      wordBreak: 'break-all',
    },
    clean: {
      fontSize: '2rem',
      padding: '6px',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    section: {
      marginBottom: '32px',
    },
    hide: {
      visibility: 'hidden',
    },
  }
}
