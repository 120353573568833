import { Box, Typography } from '@mui/material'
import { useStyles } from './styles'
import { Status } from '../../utils/enums'

interface Props {
  status: Status
  title: string
  email: string
  date: string
  index?: any
}
const DetailCard = ({ status, title, email, date, index }: Props) => {
  const styles = useStyles(status)

  return (
    <Box key={index ?? 0} sx={styles.container}>
      <Typography>{title ?? ''}</Typography>
      <Typography>{email ?? ''}</Typography>
      <Typography>{date ?? ''}</Typography>
    </Box>
  )
}

export default DetailCard
