import { reportFolioServices } from '../../../../../../service/reportFolio'
import { httpFetch } from '../../../../../../utils/crud'
import { getYear } from 'date-fns'
import { Item } from '../../../../../../utils/types/generals'
import {
  areaServices,
  departmentService,
  storeServices,
  divisionService,
  userServiceFetch,
} from './constants/states'
import { Roles } from '../../../../../../utils/enums'
import { getUserRole } from '../../../../../../service/auth'
import { userTypeServiceFetch } from '../../../../../../utils/constants/callService'

const getData = async (data?: { query?: string }) => {
  const reportFolioService = reportFolioServices(httpFetch)
  const reportFolioList = await reportFolioService.reportFolioList({
    query: data?.query ?? 'size=20&page=1',
  })

  if (!!reportFolioList.errors) {
    return { rows: [] }
  }

  let years: Item[] = []

  const rows = reportFolioList?.items?.map((item) => {
    const year = getYear(new Date(item.createTime)).toString()

    if (!years.some((el) => el?.value == year)) {
      years = [
        ...years,
        {
          label: year,
          value: year,
        },
      ]
    }
    const store = {
      name: item?.storeName ?? '',
      address: item?.storeAddress ?? '',
    }
    const area = item?.areaName ?? ''
    const department = item?.departmentName ?? ''
    const createByUser = item?.creatorFullName ?? ''

    return {
      priorityHeader: item?.priorityName,
      unitHeader: `${area ? `${area} / ` : ''}${department ? `${department} / ` : ''
        }${store?.name ? `${store?.name} - ` : ''}${store?.address ? `${store?.address} - ` : ''
        }${createByUser ?? ''}`,
      statusHeader: item?.statusDescription,
      id: item?.id,
      priority: item?.priorityId,
      foliosAmount: item?.folio,
      unit: JSON.stringify({
        title: area,
        department: department,
        content: `${store?.name ?? ''}${!!store?.name && !!store?.address ? ' - ' : ''
          }${store?.address ?? ''}`,
        user: `${createByUser}`,
      }),
      requestDate: item?.createTime,
      solveDate: item?.solutionDate,
      timeElapsed: item?.solutionTime,
      status: {
        value: item?.statusId,
        reportUrl: item?.reportUrl,
      },
      usersAssigned: item?.usersAssigned,
      canResponse: item?.canResponse,
    }
  })

  return {
    rows,
    years,
    totalPages: reportFolioList?.totalPages ?? 1,
    totalItems: reportFolioList?.totalItems ?? 0,
  }
}

const getStore = async () => {
  const role = getUserRole()
  let allStores

  switch (role) {
    case Roles.ADMIN:
      allStores = await storeServices.getAllStores()
      break
    default:
      allStores = await storeServices.getStoresFilter()
      break
  }

  if (!!allStores?.errors) return []

  return allStores?.map((item) => ({
    label: `${item.keyCode} - ${item.name}`,
    value: item.id,
  })) as Item[]
}

const getArea = async () => {
  const allAreas = await areaServices.getAllAreas()

  if (!!allAreas?.errors) return []

  return allAreas.map((item) => ({
    label: item.name,
    value: item.id,
  })) as Item[]
}

const getDivisions = async () => {
  const role = getUserRole()
  let allDivisions

  switch (role) {
    case Roles.ADMIN:
      allDivisions = await divisionService.getAllDivision()
      break
    default:
      allDivisions = await divisionService.getDivisionsFilter()
      break
  }

  if (!!allDivisions?.errors) return []

  return allDivisions.map((item) => ({
    label: item.description ?? item.name,
    value: item.id,
  })) as Item[]
}

const getDepartments = async () => {
  const allDepartments = await departmentService.getAllDepartments()

  if (!!allDepartments?.errors) return []

  return allDepartments.map((item) => ({
    label: item.name,
    value: item.id,
    areaId: item.areaId,
  })) as Item[]
}

const getUsers = async () => {
  const role = getUserRole()
  let allUsers

  switch (role) {
    case Roles.AGENT:
    case Roles.PARTNER:
    case Roles.SUPPORT:
    case Roles.STORE:
      allUsers = null
      break
    case Roles.SUPERVISOR:
    case Roles.GERENT:
    case Roles.ADMIN:
      allUsers = await userServiceFetch.userFilter()
      break
    default:
      allUsers = await userServiceFetch.usersByStatus()
      break
  }

  if (!!allUsers?.errors) return []

  return allUsers?.map((item) => ({
    label: `${item.firstName} - ${item.lastName}`,
    value: item.id,
  })) as Item[]
}

const getUserTypes = async () => {
  const role = getUserRole()
  let allUsersType

  switch (role) {
    case Roles.SUPERVISOR:
    case Roles.GERENT:
    case Roles.SUPPORT:
      allUsersType = await userTypeServiceFetch.userTypeListFilter()
      break
    case Roles.AGENT:
    case Roles.STORE:
    case Roles.PARTNER:
    case Roles.AUDIT:
      allUsersType = null
      break
    default:
      allUsersType = await userTypeServiceFetch.userTypeList()
      break
  }

  if (!!allUsersType?.errors) return []

  return (allUsersType?.map((item: any) => ({
    label: item.name,
    value: item.id,
  })) as Item[])
}

export {
  getData,
  getStore,
  getArea,
  getDepartments,
  getUsers,
  getUserTypes,
  getDivisions,
}
