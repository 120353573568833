import { format } from "date-fns";
import { percentage } from "../../../../../../../utils/validate/validate";
import { porcentageAreasAndDepartmentsColors } from "../../methods/statusByPercentage";
import { keysProgramationGraphic, progressAgregation } from "./contants";
import { Constants } from "../../../../../../../utils/constants";
import { Item } from "../../../../../../../utils/types/generals";
import { History } from "../../../../../../../service/dashboard/response";
import { Status } from "../../../../../../../utils/enums";

const progressBarArea = ({ areasList, questionnaire, status }: { areasList: Item[]; questionnaire?: Item[]; status?: Item[] }) => {
  let filters: {
    [x: string]: {
      [x: string]: (string | Status)[] | string;
    };
  }[] = [{
    terms: {
      area_id: areasList?.map((item) => item.value) ?? [],
    }
  }]

  if (!!questionnaire?.length) { filters.push({ terms: { cuestionario_id: questionnaire.map((item) => item.value), }, }) }
  if (!!status?.length) {
    let newStatusList: Status[] = status.map((item) => item.value)
    if (newStatusList.some(item => item == Status.WAITING)) {
      newStatusList = [...newStatusList, Status.ACTIVE, Status.PAUSE, Status.REAGENDED, Status.STARTED]
    }

    filters.push({ terms: { estatus_id: newStatusList, }, })
  }

  return {
    size: 1,
    aggs: {
      areas: {
        terms: {
          field: 'area_id',
          size: areasList?.length ?? 0
        },
        aggregations: progressAgregation,
      },
    },
    query: {
      bool: {
        filter: filters,
      },
    },
  }
}

const progressBarDepartment = ({ area_id, departmentList, questionnaire, status, }: { area_id: string; departmentList: Item[]; questionnaire?: Item[]; status?: Item[] }) => {

  let filters: {
    [x: string]: {
      [x: string]: (string | Status)[] | string;
    };
  }[] = [{
    term: {
      area_id: area_id,
    }
  }]

  if (!!departmentList?.length) { filters.push({ terms: { departamento_id: departmentList.map((item) => item.value), }, }) }
  if (!!questionnaire?.length) { filters.push({ terms: { cuestionario_id: questionnaire.map((item) => item.value), }, }) }
  if (!!status?.length) {
    let newStatusList: Status[] = status.map((item) => item.value)
    if (newStatusList.some(item => item == Status.WAITING)) {
      newStatusList = [...newStatusList, Status.ACTIVE, Status.PAUSE, Status.REAGENDED, Status.STARTED]
    }

    filters.push({ terms: { estatus_id: newStatusList, }, })
  }

  return {
    size: 1,
    query: {
      bool: {
        filter: filters
      },
    },
    aggs: {
      areas: {
        terms: {
          field: 'departamento_id',
        },
        aggregations: progressAgregation,
      },
    },
  }
}

const graphicArea = ({ areasList, interval, end, start, detail }: { areasList?: Item[]; interval?: "1w" | "1d" | "1m"; start: string, end: string, detail?: boolean }) => {
  return {
    size: !!detail ? 10000 : 1,
    query: {
      bool: {
        must: [
          {
            "range": {
              [interval == '1w' ? 'fecha_inicio_semana' : 'fecha']: {
                "gte": start,
                "lte": end
              }
            }
          },
        ],
        filter: {
          terms: {
            area_id: areasList?.map((item) => item.value) ?? [],
          },
        },
      },
    },
    ...!detail && {
      aggregations: {
        registros_diarios: {
          date_histogram: {
            field: interval == '1w' ? 'fecha_inicio_semana' : 'fecha',
            calendar_interval: interval ?? '1d',
          },
          aggs: {
            areas: {
              terms: {
                field: 'area_id',
              },
              aggs: progressAgregation
            },
          },
        },
      }
    },
  }
}

const graphicDepartments = ({ end, start, interval, area_id, departmentList, detail }: { departmentList: Item[]; area_id: string; interval?: "1w" | "1d" | "1m"; start: string, end: string; detail?: boolean }) => {
  return {
    size: !!detail ? 10000 : 1,
    query: {
      bool: {
        must: [
          {
            "range": {
              [interval == '1w' ? 'fecha_inicio_semana' : 'fecha']: {
                "gte": start,
                "lte": end
              }
            }
          },
        ],
        filter: [
          {
            term: {
              area_id: area_id,
            },
          },
          {
            terms: {
              departamento_id: departmentList.map((item) => item.value),
            },
          },
        ],
      },
    },
    ...!detail && {
      aggregations: {
        registros_diarios: {
          date_histogram: {
            field: interval == '1w' ? 'fecha_inicio_semana' : 'fecha',
            calendar_interval: interval ?? '1d',
          },
          aggs: {
            areas: {
              terms: {
                field: 'departamento_id',
              },
              aggs: progressAgregation
            },
          },
        },
      }
    },
  }
}

const formatGraphicAreaData = ({ response, areasList }: { response: History; areasList?: Item[] }) => {
  /**
   * @description data to bar graphic the index gonna be refer to each bar in the grahic
   */
  let data: any = []
  /**
   * @description data to tooltipby dates
   */
  let dataByDates: Record<string, any> = {}
  let areasString: {
    id: string
    name: string
  }[] = []
  response?.aggregations?.registros_diarios?.buckets?.forEach((item) => {
    const dateAgregation = item.key_as_string.replace(/[zZ]/g, '').toString()
    const dateAgregationFormated = format(
      new Date(dateAgregation),
      Constants.dateFormat
    )
    let tmtData: any = {
      date: dateAgregationFormated,
    }
    dataByDates = {
      ...dataByDates,
      [dateAgregationFormated ?? ""]: {
        title: dateAgregationFormated,
        data: {}
      }
    }
    item.areas.buckets.forEach((itemAreas) => {
      const key = itemAreas.key.toString()
      const canceled = itemAreas.cancelado.value,
        expired = itemAreas.vencido.value,
        finished = itemAreas.finalizado.value,
        noStarted = itemAreas.por_iniciar.value,
        progress = itemAreas.en_progreso.value,
        incompleto = itemAreas.incompleto.value
      const total = canceled + expired + finished + noStarted + progress + incompleto
      const percentageCanceled = percentage({ a: canceled, b: total, })
      const percentageExpirted = percentage({ a: expired, b: total, })
      const percentageFinished = percentage({ a: finished, b: total, })
      const percentageNoStarted = percentage({ a: noStarted, b: total, })
      const percentageProgress = percentage({ a: progress, b: total, })
      const percentageImpomplete = percentage({ a: incompleto, b: total, })
      const label = areasList?.find((itemArea) => itemArea.value == key)?.label
      
      const colorsPercentages = porcentageAreasAndDepartmentsColors({
        canceled: parseFloat(percentageCanceled),
        expired: parseFloat(percentageExpirted),
        finished: parseFloat(percentageFinished),
        incompleto: parseFloat(percentageImpomplete),
        noStarted: parseFloat(percentageNoStarted),
        progress: parseFloat(percentageProgress),
        total,
      })
      if (!areasString.some((itemStrings) => itemStrings.id == key)) {
        areasString.push({ id: key, name: label ?? '', })
      }
      dataByDates = {
        ...dataByDates,
        [dateAgregationFormated ?? ""]: {
          ...dataByDates?.[dateAgregationFormated ?? ""] ?? {},
          data: {
            ...dataByDates?.[dateAgregationFormated ?? ""]?.data ?? {},
            [key]: {
              title: label,
              data: {
                canceled: {
                  label: `${canceled} (${percentageCanceled}%)`,
                  status: colorsPercentages.canceled
                },
                expired: {
                  label: `${expired} (${percentageExpirted}%)`,
                  status: colorsPercentages.expired
                },
                finished: {
                  label: `${finished} (${percentageFinished}%)`,
                  status: colorsPercentages.finished
                },
                noStarted: {
                  label: `${noStarted} (${percentageNoStarted}%)`,
                  status: colorsPercentages.noStarted
                },
                inProgress: {
                  label: `${progress} (${percentageProgress}%)`,
                  status: colorsPercentages.progress
                },
                inclomplete: {
                  label: `${incompleto} (${percentageImpomplete}%)`,
                  status: colorsPercentages.incompleto
                },
                total: {
                  label: total
                },
              },
            }
          }
        }
      }
      tmtData = {
        ...tmtData,
        [`${key}-${keysProgramationGraphic.canceled}`]: canceled,
        [`${key}-${keysProgramationGraphic.expired}`]: expired,
        [`${key}-${keysProgramationGraphic.finished}`]: finished,
        [`${key}-${keysProgramationGraphic.noStarted}`]: noStarted,
        [`${key}-${keysProgramationGraphic.progress}`]: progress,
        [`${key}-${keysProgramationGraphic.incompleto}`]: incompleto,
        [`${key}-${keysProgramationGraphic.total}`]: total,
      }
    })

    data.push(tmtData)
  })
  return { data, areasString, dataByDates }
}

export {
  graphicArea,
  graphicDepartments,
  formatGraphicAreaData,
  progressBarDepartment,
  progressBarArea
}