import { useCallback, useEffect, useRef, useState } from "react"
import { InputModel } from "../../../../utils/types"
import { ObjectGeneric } from "../../../../utils/types/generals"
import { questionnaire } from "../../../../utils/constants/callService"
import { useTranslation } from "react-i18next"
import { Box, IconButton, Typography } from "@mui/material"
import { icons } from "../../../../utils/icons"
import { format } from "date-fns"
import { Constants } from "../../../../utils/constants"
import { getStatus } from "../../../../hooks/status"
import { useContextState } from "../../../../hooks/theme"
import { useStyles } from "./styles"
import { formData, formatFilter, inputFormat, useInput, useInputsCreate } from "./constants"
import { getPreviousYears, monthYear } from "../../../../utils/constants/date_month"
import { getArea, getDepartments } from "../../../../utils/methods/geServiceWithItemsFormat"
import { CreateTypes, ItemsInput, RedirectTypes } from "./typing/types"
import { DynamicFormRefValues } from "../../../../components/dynamicFormRef/types"
import { setLocalStorage } from "../../../../utils/crud"
import { QuestionnaireLocale } from "./typing/enums"
import { Status, Types } from "../../../../utils/enums"
import { returnQuery, returnQueryWithPagination } from "../../../../utils/methods/returnQuery"
import { useFilter } from "../../../../hooks/filter"
import { useDownload } from "../../../../hooks/download/useDownload"
import { FilterProps } from "../../../../hooks/filter/typing"
import { removeAllReference } from "../../../../utils/methods/removedReference"
import { statusService } from "../../../../service"

const useBody = () => {
  const refTime = useRef<NodeJS.Timeout | null>(null)
  const { t } = useTranslation()
  const [loading, setLoading] = useState({
    table: false,
    header: false,
    copy: false
  })
  const [loadingCopy, setLoadCopy] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    type: Types.SUCCESS,
    message: ''
  })
  const [modal, setModal] = useState<{
    open: boolean
    title: string
    description: string
    confirm: () => void
    cancel?: () => void
  }>({
    open: false,
    title: '',
    description: '',
    confirm: () => { }
  })
  const [total, setTotal] = useState(0)
  const [totalCopy, setTotalCopy] = useState(0)
  const [allDepartments, setAllDepartments] = useState<any[]>([])
  const [itemsSearch, setItemsSearch] = useState<ItemsInput>({})
  const externalValidation: FilterProps['externalValidation'] = ({
    name,
    body,
    value: values,
    setFilterData,
  }) => {
    let copyBody: Record<string, any> = removeAllReference(body)

    if (name === 'areas') {
      const { departments: departmentsData } = copyBody
      const newDepartments = departmentsData?.filter((e: any) =>
        values.find((e2: any) => e2.value == e.areaId)
      )
      let arrayChilds: any[] = []

      values?.forEach((e: any) => {
        const id = e.value
        const childs = allDepartments.filter((child) => child.areaId == id)
        arrayChilds.push(...childs)
      })
      setItemsSearch((prev) => ({
        ...prev,
        departments: arrayChilds,
      }))
      setFilterData((prev) => ({
        ...prev,
        departments: newDepartments,
      }))

      copyBody = {
        ...copyBody,
        departments: newDepartments,
      }
    }

    return copyBody
  }

  const paginationMain = useFilter({
    formatFilter,
    externalValidation
  })

  const paginationCopy = useFilter({
    formatFilter,
  })
  const downloadMain = useDownload({
    docName: t('Questionarie.title'),
    query: returnQueryWithPagination({
      filter: paginationMain.filterString,
      sort: paginationMain.query.sort,
    }, formData),
    downloadFuntion: questionnaire.downloadTemplate,
  })
  const [newQuestionnaire, setNewQuestionnaire] = useState<CreateTypes | null>(null)
  const styles = useStyles()
  const { colors } = useContextState()
  const [inputs, setInputs] = useState<Record<string, InputModel>>(inputFormat)
  const [data, setData] = useState<ObjectGeneric>({})
  const [erros, setErrors] = useState({})
  const [counter, setCounter] = useState<number | null>(null)
  const { inputCreate } = useInputsCreate({ departments: allDepartments, areas: inputs?.areas?.items ?? [] })
  const input = useInput(itemsSearch)
  const [table, setTable] = useState<{ row: any[]; rowCopy: any[] }>({
    row: [],
    rowCopy: [],
  })
  const column = [
    {
      id: 'id',
      label: t('general.tags.id'),
      sortable: true,
    },
    {
      id: 'area',
      label: t('general.tags.area'),
      sortable: true,
      column: () => {
        return (
          <Typography sx={styles.headetText}>
            {t('general.tags.area')} - {t('general.tags.department')}
          </Typography>
        )
      },
      renderCell: ({ row }: any) => {
        if (!row.area && !row.department) return <Typography sx={styles.tableText}>N/A</Typography>

        return (
          <Box sx={styles.containerArea}>
            <Typography sx={styles.tableText}>{row.area ?? ""}{' - '}{row.department ?? ""}</Typography>
          </Box>
        )
      },
    },
    {
      id: 'quest_name',
      label: t('general.tags.questionnaire'),
      sortable: true,
    },
    {
      id: 'createTime',
      label: t('general.tags.createTime'),
    },
    {
      id: 'action_plan',
      label: t('general.tags.action_plan'),
      sortable: true,
    },
    {
      id: 'totalPoints',
      label: t('general.tags.totalPoints'),
      sortable: true,
    },
    {
      id: 'action',
      label: t('general.tags.action'),
      renderCell: ({ row }: any) => {
        return (
          <IconButton
            sx={[styles.delete, styles.actionIcon]}
            onClick={(e) => {
              e.stopPropagation()
              deleteItemTable({ id: row.id })
            }}
          >
            {icons('delete')}
          </IconButton>
        )
      },
    },
  ]


  const handleInitialValues = useCallback(async () => {
    let valideStatus = [] as {
      value: string | number
      label: string
    }[]

    const response = await statusService().statusList()

    response?.forEach((item: any) => {
      if ([Status.ACTIVE, Status.WITH_DRAW, Status.BLOCKED].includes(item.id)) {
        valideStatus.push({
          value: item.id,
          label: item.description,
        })
      }
    })

    setItemsSearch((prev) => ({
      ...prev,
      createYear: getPreviousYears(),
      month: monthYear.map((e) => ({
        ...e,
        value: e.value + 1,
      })),
      status: valideStatus,
      actionPlanType: [
        { label: 'Por Cuestionario', value: 'false' },
        { label: 'Por Ítem', value: 'true' },
      ],
    }))
    // eslint-disable-next-line
  }, [t])

  const closeModal = () => { setModal(prev => ({ ...prev, open: false })) }
  const closeSnackbar = () => { setOpenSnackbar(prev => ({ ...prev, open: false })) }
  const closeCreateModal = () => { setNewQuestionnaire(null) }

  const openCreateModal = ({ type }: { type: CreateTypes }) => {
    getCopyTable()
    setNewQuestionnaire(type)
  }

  const deleteItemTable = async ({ id }: { id: string }) => {
    setModal(prev => ({
      ...prev,
      open: true,
      title: t('Questionarie.modal.delete.title'),
      description: t('Questionarie.modal.delete.description'),
      cancel: () => { closeModal() },
      confirm: () => { deleteConfirmation({ id }) }
    }))
  }

  const deleteConfirmation = async ({ id }: { id: string }) => {
    const response = await questionnaire.deleteQuestionnarie(id)

    if (!response?.errors) {
      closeModal()
      setOpenSnackbar(prev => ({
        ...prev,
        open: true,
        type: Types.SUCCESS,
        message: t(`Questionarie.modal.delete.success.title`)
      }))
      getQuestionnaires()
    } else {
      setOpenSnackbar(prev => ({
        ...prev,
        open: true,
        type: Types.ERROR,
        message: t(`Questionarie.modal.delete.error.title`)
      }))
    }
  }

  const cleanFilter = () => {
    setData({})
    setErrors({})
    setTable(prev => ({
      ...prev,
      row: prev.rowCopy
    }))
  }

  const getAreas = async () => {
    const items = await getArea()

    setItemsSearch((prev) => ({
      ...prev,
      areas: items,
    }))
    setInputs((prev) => ({
      ...prev,
      areas: {
        ...prev.areas,
        items
      },
    }))
  }

  const getDepartment = async () => {
    const response = await getDepartments()

    setAllDepartments(response)
  }

  const getQuestionnaires = async () => {
    setLoading(prev => ({
      ...prev,
      table: true
    }))
    const response = await questionnaire.getTableQuestionnaires({ query: returnQuery({ ...paginationMain.query, filter: paginationMain.query.filter?.replaceAll('+', '%2B') }, formData) })
    setTotal(response.totalPages)
    const row = !response.errors
      ? response?.items?.map((item) => {

        return {
          id: item.id,
          area: item.area,
          department: item.department,
          quest_name: item.name,
          createTime: item.createTime && format(new Date(item.createTime), Constants.dateFormatGeneral),
          action_plan: item.actionPlanByItem,
          totalPoints: parseFloat(item.totalPoints),
          status: getStatus(item.statusId, colors, t),
        }
      })
      : []
    setTable(prev => ({
      ...prev,
      row,
    }))
    setLoading(prev => ({
      ...prev,
      table: false
    }))
  }


  const getCopyTable = async () => {
    setLoading(prev => ({ ...prev, copy: true }))
    const responseQuestionnaries = await questionnaire.getTableQuestionnaires({ query: returnQuery({ ...paginationCopy.query, filter: paginationCopy.query.filter?.replaceAll('+', '%2B') }, formData) })
    setTotalCopy(responseQuestionnaries.totalPages)
    setTable(prev => ({
      ...prev,
      rowCopy: !responseQuestionnaries.errors
        ? responseQuestionnaries?.items?.map((item) => {

          return {
            id: item.id,
            quest_name: item.name,
            area: item.area,
            department: item.department,
            createTime: item.createTime && format(new Date(item.createTime), Constants.dateFormatGeneral),
            action_plan: item.actionPlanByItem,
            totalPoints: parseFloat(item.totalPoints),
            status: getStatus(item.statusId, colors, t),
          }
        })
        : [],
    }))
    setLoading(prev => ({ ...prev, copy: false }))
  }

  const createCopy = async ({ id }: { id: string }) => {
    setLoadCopy(true)
    const response = await questionnaire.copyByOtherQuestionnarie({ id })

    if (!response.errors) {
      setModal(prev => ({
        ...prev,
        open: true,
        title: t(`Questionarie.modal.success.copy.title`),
        description: t(`Questionarie.modal.success.copy.description`),
        cancel: undefined,
        confirm: () => {
          window.open(`/dashboard/questionnarie/edit/${response.id}`, 'blank')
          closeModal()
        }
      }))
    } else {
      setOpenSnackbar({
        ...openSnackbar,
        message: t('Questionarie.snackbar.copy.error'),
        type: Types.ERROR,
        open: true,
      })
    }
    setLoadCopy(false)
  }

  const redirect = (value: RedirectTypes, payload: DynamicFormRefValues) => {
    if (value == 'create') {
      setLocalStorage({ body: payload, name: QuestionnaireLocale.QUESTIONNAIRE })
      window.open(`/dashboard/questionnarie/${value}`, 'blank')
      closeCreateModal()
    } else if (value == 'copy') {
      createCopy({ id: payload.id as string })
    } else {
      window.open(`/dashboard/questionnarie/edit/${payload.id}`, 'blank')
    }
  }

  useEffect(() => {
    getQuestionnaires()
    getAreas()
    getDepartment()
    handleInitialValues()
  }, [])


  useEffect(() => {
    setCounter(null)
    let count = 0
    for (let key in data) {
      if (data[key] instanceof Array) {
        if (data[key]?.length > 0) count++
      } else if (data[key] != null && data[key] != '') count++
    }

    setCounter(count)
  }, [data])

  useEffect(() => {
    if (refTime.current) { clearTimeout(refTime.current) }

    refTime.current = setTimeout(() => {
      getQuestionnaires()
    }, 300)
  }, [paginationMain.query.all])


  useEffect(() => {
    if (refTime.current) { clearTimeout(refTime.current) }

    refTime.current = setTimeout(() => {
      if(newQuestionnaire === 'copy'){
        getCopyTable()
      }
    }, 300)
  }, [paginationCopy.query.all])

  return {
    openSnackbar, loading, table, paginationCopy, paginationMain, total,
    totalCopy, loadingCopy,
    downloadMain, data, erros, counter, inputs, column, newQuestionnaire, input, inputCreate, modal, closeModal, deleteConfirmation, closeSnackbar, redirect, closeCreateModal, openCreateModal, cleanFilter
  }
}

export { useBody }
