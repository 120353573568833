import { Box, Button, IconButton } from '@mui/material'
import { useRef } from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import { handleDoc, handlePdf } from '../../utils/methods/downloadFiles'
import { DocumentFormat, ObjectGeneric } from '../../utils/types/generals'
import { useStyles } from './style'

interface PropsDownloadFiles {
  row: any[]
  column: any[]
  name?: string
  format?: DocumentFormat
  customeHeader?: any[]
  valueByArray?: {
    headers: ObjectGeneric
    values: ObjectGeneric[]
  }
  excludeExcel?: any
  excludePdfDoc?: any
  removeMargin?: boolean
  csvText?: any
}
const DownloadFiles = ({ row, column, name, format, customeHeader, valueByArray, excludeExcel, excludePdfDoc, removeMargin, csvText }: PropsDownloadFiles) => {
  const column2 = customeHeader ?? column
  const columnHeaderByArray = Object.values(valueByArray?.headers ?? {})
  const { t } = useTranslation()
  const styles = useStyles({ removeMargin })
  const refCsv = useRef<any>(null)
  const columnClean: any[] = [...column2, ...columnHeaderByArray]?.filter((item: any) => item.id !== 'action')?.map((item: any) => item.label)
  const headers = column2
    ?.filter((item: any) => item.id !== 'action')
    ?.map((item: any) => ({
      label: item.label,
      key: item.id,
    }))
  let max = 0
  let extraHeader: any[] = []
  let extraBody: any[] = []
  let extraRowWord: any[] = []

  valueByArray?.values?.forEach((item: any, index: number) => {
    let body = {}
    item?.forEach((item2, index2) => {
      let bodyWord = {}
      if (index2 > max) {
        max = index2
      }
      columnHeaderByArray?.forEach((item3) => {
        body = {
          ...body,
          [`${item3?.id}${index2 > 0 ? index2 : ''}`]: item2[item3?.id],
        }
        bodyWord = {
          ...bodyWord,
          [`${item3?.id}`]: item2[item3?.id],
        }
      })
      if (index2 > 0) {
        extraRowWord = [...extraRowWord, bodyWord]
      } else {
        extraRowWord = [...extraRowWord, { ...row[index], ...bodyWord }]
      }
    })
    extraBody = [...extraBody, body]
  })

  new Array(max + 1)?.fill(1)?.forEach((item, index) => {
    columnHeaderByArray?.forEach((itemHeader) => {
      extraHeader = [
        ...extraHeader,
        {
          key: `${itemHeader?.id}${index > 0 ? index : ''}`,
          label: itemHeader?.label,
        },
      ]
    })
  })
  const rowClean = (data?: { extraCSV?: any[]; extraWord?: any[] }) => {
    const newRow = !((data?.extraWord?.length ?? 0) > 0) ? row : data?.extraWord || []

    return newRow?.map((item: any, index: number) => {
      let obje = {}

      Object.entries({ ...item, ...(data?.extraCSV?.[index] ?? []) })
        .filter(([key]) => key !== 'action')
        ?.forEach(([key, value]: any) => {
          const existValue = value?.text || value?.label || value
          obje = {
            ...obje,
            [key]: existValue || existValue == 0 ? existValue : 'N/A',
          }
        })
      return obje
    })
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.hidden}>
      {row.length > 0 ? (
        <CSVLink data={rowClean({ extraCSV: extraBody })} headers={[...headers.filter((e) => !excludeExcel?.includes(e.key)), ...extraHeader]} filename={name}>
          <IconButton ref={refCsv}></IconButton>
        </CSVLink>
        ) : (
        <CSVLink data={[[t('general.notDataTable')]]} filename={name}>
          <IconButton ref={refCsv}></IconButton>
        </CSVLink>
      )}
      </Box>
      <Box>
        <Button
          onClick={() => {
            refCsv?.current?.click?.()
          }}
        >
          {csvText ? `${t('general.download')} ${csvText}` : `${t('general.download')} CSV`}
        </Button>
        <Button
          onClick={() => {
            const newRow = !(extraRowWord?.length > 0) ? row : extraRowWord || []
            handlePdf(
              columnClean.filter((e) => !excludePdfDoc?.includes(e)),
              newRow.map((item: any) =>
                [...headers, ...columnHeaderByArray].map((itemC: any) => {
                  const existValue = item[itemC.key]?.text || item[itemC.key]?.label || item[itemC.key]
                  const allText = existValue || existValue == 0 ? existValue : 'N/A'

                  return typeof allText === 'string'
                    ? allText
                        ?.split(' ')
                        ?.map((item: any) => {
                          if (typeof item === 'string' && item.length > 25) {
                            return item
                              .split('')
                              .map((items: any, index: number) => {
                                if (index % 25 === 0 && index !== 0) {
                                  return items + '\n'
                                }
                                return items
                              })
                              .join('')
                          }
                          return item
                        })
                        ?.join(' ')
                    : allText
                })
              ),
              name,
              format,
              t
            )
          }}
        >
          {t('general.download')} PDF
        </Button>
        <Button
          onClick={() => {
            handleDoc(columnClean, rowClean({ extraWord: extraRowWord }), [...column2, ...columnHeaderByArray], name , t)
          }}
        >
          {t('general.download')} WORD
        </Button>
      </Box>
    </Box>
  )
}

export default DownloadFiles
