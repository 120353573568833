import { createContext, useReducer } from 'react'
import { globalReducer } from '../../providers/globalContext'
import { initialState } from './initialState'
import { InitialState } from './types/initialState'
import { Props } from './types/props'

export const GlobalContext = createContext({} as InitialState)

export const ContextProvider: React.FC<Props> = ({ children, colors }) => {
  const [state, dispatch] = useReducer(globalReducer, initialState['state'])

  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
        colors: colors,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
