import { Color } from "../types/color";

export const light: Color = {
  primary: '#5807D3',
  secondary: '#f1c40f',
  color1: '#3A87D9',
  color2: '#9F31F6',
  color3: '#00000029',
  color4: '#24A9E2',
  color5: '#ffffff',
  color6: '#000066',
  color7: '#3356C0',
  color8: '#31C462',
  color9: '#686868',
  color10: '#828282',
  color11: '#0062FF',
  color12: '#EC6666',
  color13: '#707070',
  color14: '#F9FAFF',
  color15: '#B1B1F733',
  color16: '#158BC8',
  color17: '#00000029',
  color18: '#7E84A3',
  color19: '#CC0000',
  color20: '#1C1A1A',
  color21: '#131523',
  color22: '#313131',
  color23: '#15223214',
  color24: '#7024E2',
  color25: '#EDEDED',
  color26: '#2B3445',
  color27: '#00000029',
  color28: '#d32f2f',
  color29: '#E87F10',
  color30: '#D93AD4',
  color31: '#ec6666',
  color32: '#aaaaaa',
  color33: '#595959',
  color34: '#E88C10',
  color35: '#E7EB00',
  color36: '#24A9E205',
  color37: '#930001',
  color38: '#31FF11',
  mediumDark: '#242021',
  orangeMedium: '#F7BF4F',
  backgroundDark: '#333333',
  smallDark: '#181818',
  red: '#ff0000'
}
