import { LoadingButton } from '@mui/lab'
import { Box, Button } from '@mui/material'
import { Props } from './props'
import { useStyles } from './styles'

const FooterButtons = (props: Props) => {
  const styles = useStyles()
  return (
    <Box sx={styles.buttonsContainer}>
      {!!props.leftButton ? <Button onClick={() => props.onClick(-1)}>{props.leftButton}</Button> : <Box className="back" />}
      <LoadingButton loading={!!props.loading} disabled={props.disabled} className="next" variant="contained" onClick={() => props.onClick(1)}>
        {props.rightButton}
      </LoadingButton>
    </Box>
  )
}

export default FooterButtons
