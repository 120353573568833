import { FormatCreateAndUpdate } from '../../pages/userSchedule/pages/detail/typing'
import { Http } from '../../utils/types'
import { GetBlob } from '../../utils/types/generals'
import {
  AssignScheduleDetail,
  AssignScheduleErrors,
  AssignScheduleMoreErrors,
} from './response'

const UserServiceSchedule = (client: Http) => ({
  getList: async (data?: {
    query?: string
  }): Promise<AssignScheduleMoreErrors> =>
    await client.get(
      `user-questionnaire${data?.query ? '?' + data?.query : ''}`
    ),
  getDetail: async ({ id }: { id: string }): Promise<AssignScheduleDetail> =>
    await client.get(`user-questionnaire/${id}`),
  createrUserSchedule: async ({
    body,
  }: {
    body: FormatCreateAndUpdate[]
  }): Promise<AssignScheduleErrors> =>
    await client.post('user-questionnaire', body),
  updateUserSchedule: async ({
    body,
    id,
  }: {
    body: FormatCreateAndUpdate
    id: string
  }): Promise<AssignScheduleErrors> =>
    await client.put(`user-questionnaire/${id}`, body),
  downloadReport: async (data: {
    type: `pdf` | 'word' | 'excel'
    query?: string
  }): Promise<GetBlob> =>
    await client.getBlob?.(
      `user-questionnaire/generate-file?type=${data.type}${
        data?.query ? `${data?.query}` : ''
      }`
    ),
  closeUserSchedule: async ({ id }: { id: string }) =>
    await client.put(`user-questionnaire/close/${id}`),
})

export { UserServiceSchedule }
