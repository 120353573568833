import { Box, Typography } from "@mui/material"
import { useStyles } from "./styles"

const DescriptionColor = (props: { color: string; title: string }) => {
  const styles = useStyles()

  return (
    <Box sx={styles.container}>
      <Box sx={styles.color({ color: props.color })} />

      <Typography>{props.title}{`:`}</Typography>

      <Typography sx={styles.textColor({ color: props.color })}>{props.color}</Typography>
    </Box>
  )
}

export { DescriptionColor }
