import { TFunction } from 'react-i18next'
import { zonesService } from '../../../../service/zones'
import { ResponseZones } from '../../../../service/zones/response'
import { httpFetch } from '../../../../utils/crud'
import { RequestErrors, Types } from '../../../../utils/enums'
import { handleAllErrors } from '../../../../utils/methods/handleErrors'
import { Color } from '../../../../utils/theme/types/color'
import { ObjectGeneric } from '../../../../utils/types/generals'
import { isRequired, searchInModeLower } from '../../../../utils/validate/validate'
import { format, getYear } from 'date-fns'
import { Constants } from '../../../../utils/constants'
import { es } from 'date-fns/locale'

export const getList = async (colors: Color, t: TFunction<'translation', undefined>) => {
  const data = await zonesService(httpFetch).getAllZones()

  const column: any[] = [
    {
      id: 'name',
      label: t('general.tags.name'),
      sortable: true,
      minWidth:100

    },
    {
      id: 'description',
      label: t('general.tags.description'),
      sortable: true,
      minWidth:100

    },
    {
      id: 'createTime',
      label: t('general.tags.createTime'),
      sortable: true,
      minWidth:100

    },
  ]
  const fatherIds: number[] = []
  const row = !data?.errors
    ? data?.map((item) => {
        fatherIds.push(item?.id)
        return {
          id: item?.id,
          description: item.description,
          name: item.name,
          zoneInitId: item.zoneInitId,
          zoneFather: item.zoneParent,
          createTime: {
            label: item.create_time && format(new Date(item.create_time), Constants.dateFormatGeneral),
            value: item.create_time ? new Date(item.create_time).getTime() : 0,
            type: 'date',
          },
          create_time: item.create_time,
          createYear: item.create_time ? getYear(new Date(item.create_time)) : '',
          month: item.create_time ? format(new Date(item.create_time), 'LLLL', { locale: es }) : '',
          monthNumber: item.create_time ? new Date(item.create_time).getMonth() : '',
          createDate: item.create_time ? format(new Date(item.create_time), Constants.dateFormat2) : '',
          createHour: item.create_time ? format(new Date(item.create_time), Constants.hourFormat) : '',
          udpateDate: item.update_time ? format(new Date(item.update_time), Constants.dateFormat2) : '',
          udpateHour: item.update_time ? format(new Date(item.update_time), Constants.hourFormat) : '',
        }
      })
    : []
  const cleanRow = row.map((item) => {
    let zoneInitId: number | null = null
    if (item.zoneInitId) {
      if (fatherIds.includes(item?.zoneInitId)) {
        zoneInitId = item?.zoneInitId
      }
    }

    return {
      ...item,
      zoneInitId,
    }
  })

  return { column, row: cleanRow } as {
    column: any[]
    row: {
      id: number | null
      name: string | null
      description: string | null
      zoneInitId: number | null
    }[]
  }
}

export const filterRow = (value: { [x: string]: any }, list: any[]) => {
  const from = value.from?.valueOf()
  const to = value.to?.valueOf()
  return list?.filter((item) => {
    return (
      (from ? new Date(from).setHours(0, 0, 0, 0) <= Date.parse(item?.create_time) : true) &&
      (to ? new Date(to).setHours(23, 59, 59, 999) >= Date.parse(item?.create_time) : true) &&
      (value?.month instanceof Array ? (value?.month.length > 0 ? value?.month.find((e: any) => e.value === item.monthNumber) : true) : true) &&
      (value?.createYear instanceof Array ? (value?.createYear.length > 0 ? value?.createYear.find((e: any) => e.value === item.createYear) : true) : true) &&
      ((value?.search ? searchInModeLower(item?.id, value?.search) : true) ||
        (value?.search ? searchInModeLower(item?.description, value?.search) : true) ||
        (value?.search ? searchInModeLower(item?.name, value?.search) : true) ||
        (value?.search ? searchInModeLower(item?.zoneInitId, value?.search) : true) ||
        (value?.search ? searchInModeLower(item?.createYear, value?.search) : true) ||
        (value?.search ? searchInModeLower(item?.month, value?.search) : true))
    )
  })
}

export const handleErrors = (values: any, t: any, ignore?: string[]) => {
  let error = {} as ObjectGeneric
  if (values?.description !== null) {
    if (isRequired(values?.description)) error.description = t('general.validations.required')
  }
  if (values?.name !== null) {
    if (isRequired(values?.name)) error.name = t('general.validations.required')
  }

  return error
}

export const submit = async (data: ObjectGeneric, t: TFunction<'translation', undefined>, id: number | undefined) => {
  let snackbarBody = {
    open: false,
    type: '',
    message: '',
  }
  let body = {} as any
  Object.entries(data).forEach(([key, value]) => {
    const dataToSubmit = ['name', 'description', 'zoneInitId']
    if (dataToSubmit.includes(key)) {
      body = {
        ...body,
        [key]: value ?? null,
      }
    }
  })

  let response: ResponseZones

  if (id) {
    response = await zonesService(httpFetch).updateZones(body, id)
  } else {
    response = await zonesService(httpFetch).createZones(body)
  }

  if (response.errors) {
    snackbarBody = {
      open: true,
      type: Types.ERROR as string,
      message: handleAllErrors(response?.errors?.[0]?.code as RequestErrors, t, `zones.snackbar.${id ? 'update' : 'create'}.error`),
    }
  } else {
    snackbarBody = {
      open: true,
      type: Types.SUCCESS as string,
      message: t(`zones.snackbar.${id ? 'update' : 'create'}.success`),
    }
  }

  return {
    snackbarBody,
    response,
  }
}
