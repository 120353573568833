import { Roles } from '../../utils/enums'
import { Http } from '../../utils/types'
import { GetBlob } from '../../utils/types/generals'
import { getUserRole } from '../auth'
import { DivisionsAndTypesLoacation, UserTypeAll, UserTypePagination } from './reponse'

export const UserType = (client: Http) => ({
  userTypeList: async (): Promise<UserTypeAll | any> => {
    switch (getUserRole()) {
      case Roles.ADMIN:
        return await client.get(`user-types`)
      default:
        return await client.get(`hierarchy/filters/user-types`)
    }
  },
  userTypePagination: async (data?: { query?: string }):Promise<UserTypePagination> => await client.get(`user-types/detailed${data?.query ? '?' + data?.query : ''}`),
  userTypeSupervisorList: async (): Promise<DivisionsAndTypesLoacation> => {
    return await client.get(`hierarchy/filters/location`)
  },
  userTypeListFilter: async (): Promise<any> => {
    return await client.get(`report-folio/filters/user-types`)
  },
  createUserType: async (body: any) => await client.post('user-types', body),
  updateUserType: async (body: any, id: number) => await client.put(`user-types/${id}`, body),
  downloadDocument: async (data: {
    type: `pdf` | 'word' | 'excel'
    query?: string
  }): Promise<GetBlob> =>
    await client.getBlob?.(
      `user-types/generate-file?type=${data.type}${data?.query ? `${data?.query}` : ''
      }`
    ),
  deleteUserType: async (id: number) => await client.delete(`user-types/${id}`),
})
