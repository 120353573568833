import { SxGeneral } from '../../../../../../utils/types/generals'

const useStyles = (): SxGeneral => {
  return {
    stepsIndicatorContainer: {
      marginTop: '10px',
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      columnGap: '5px',
      '& .MuiPaginationItem-root': {
        margin: '0px'
      }
    },
    videos: {
      width: '100%',
      height: '200px',
      '& video': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    images: {
      width: '100%',
      height: '200px',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
  }
}

export { useStyles }
