import { useState } from "react"
import { useFilter } from "../../../../../../hooks/filter"
import { FilterProps } from "../../../../../../hooks/filter/typing"
import { Item } from "../../../../../../utils/types/generals"
import { useRefs } from "../../../../../../hooks/useReactHookModified"

const useBody = (payload: {items:Record<string, Item[]>}) => {
  const { refsByKey, setRef } = useRefs()
  const [items, setItems] = useState<Record<string, Item[]>>({})
  const externalValidation: FilterProps['externalValidation'] = ({ name, value, body, setFilterData }) => {
    if (name === 'zones') {
      const { class: classes, } = body
      const newZonezClass = classes?.filter((itemClass: Item) => value?.some((itemZone: any) => itemZone?.value == itemClass?.father))

      setItems((prev) => ({
        ...prev,
        class: value?.length <= 0 ? [] : payload.items.class?.filter((itemClass) => value?.some((itemZone: any) => itemZone?.value == itemClass?.father)),
      }))

      setFilterData((prev) => ({
        ...prev,
        class: newZonezClass,
      }))

      body = {
        ...body,
        class: newZonezClass,
      }
    }

    return body
  }
  const filters = useFilter({ formatFilter: {}, externalValidation })
  const [modal, setModal] = useState({
    open: false
  })

  const openFiltersModal = () => { setModal(prev => ({ ...prev, open: true })) }
  const closeFiltersModal = () => { setModal(prev => ({ ...prev, open: false })) }
  
  const filterButton = () => {
    closeFiltersModal()
  }

  return {
    filters,
    modal,
    items,
    refsByKey,
    setRef,
    openFiltersModal,
    closeFiltersModal,
    filterButton
  }
}

export { useBody }
