import { Dispatch, SetStateAction } from 'react'
import { Menu, MenuItem, ListItemIcon, Typography } from '@mui/material'
export type ItemMenu = {
  label?: string
  icon?: any
  value?: any
  children?: JSX.Element
}
export type BaseMenuProps = {
  anchorEl: null | HTMLElement
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>
  menuItems?: ItemMenu[]
  click?: (item: ItemMenu) => void
  sx?: any
}

const BaseMenu = ({ anchorEl, setAnchorEl, menuItems, click,  sx }: BaseMenuProps) => {
  const open = Boolean(anchorEl)
  const handleClose = (item: any) => {
    if(click) {click(item)}
    setAnchorEl(null)
  }

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      sx={sx}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {menuItems &&
        menuItems?.map((item, index) => (
          <MenuItem key={index} onClick={() => handleClose(item)}>
            {item?.children ?? (
              <>
                <Typography>{item.label ?? ''}</Typography>
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
              </>
            )}
          </MenuItem>
        ))}
    </Menu>
  )
}

export default BaseMenu
