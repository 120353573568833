import { Http } from '../../utils/types'
import { GetBlob, ObjectGeneric } from '../../utils/types/generals'
import { AllReportFolios, Attending, DetailReportFolios } from './response'

export const reportFolioServices = (client: Http) => ({
  reportFolioList: async (data?: { query?: string }): Promise<AllReportFolios> => await client.get(`report-folio/dashboard${data?.query ? '?' + data?.query : ''}`),
  downloadFolio: async (data: { type: `pdf` | 'word' | 'excel'; query?: string }): Promise<GetBlob> => await client.getBlob?.(`report-folio/generate-file?type=${data.type}${data?.query ? `${data?.query}` : ''}`),
  reportFolioById: async ({ id }: { id: number }): Promise<DetailReportFolios> => await client.get(`report-folio/${id}`),
  generateReportFolio: async ({ id }: { id: number }): Promise<{ reportUrl: string }> => await client.post(`report-folio/generate-folio-report/${id}`, {}),
  rejectReportFolio: async ({ id, body }: { id: number; body: ObjectGeneric }): Promise<any> => await client.post(`report-folio/${id}/reject`, body),
  answerReportFolio: async ({ id, body }: { id: number; body: ObjectGeneric }): Promise<any> => await client.post(`report-folio/${id}/answer`, body),
  approveReportFolio: async ({ id }: { id: number }): Promise<any> => await client.post(`report-folio/${id}/approve`, {}),
  reassignReportFolio: async (body: ObjectGeneric): Promise<any> => await client.post(`report-folio/reassign`, body),
  ratingReportFolio: async ({ id, body }: { id: number; body: ObjectGeneric }): Promise<any> => await client.post(`report-folio/${id}/responseSatisfactionSurvey`, body),
  resendReportFolio: async ({ id, body }: { id: number; body: ObjectGeneric }): Promise<any> => await client.post(`report-folio/${id}/resend-report`, body),
  getAllConfigs: async (data?: { query?: string }): Promise<any> => await client.get(`report-folio/all-configs-general${data?.query ? '?' + data?.query : ''}`),
  createConfig: async (body: ObjectGeneric): Promise<any> => await client.post('report-folio/create-config-general', body),
  updateConfig: async (body: ObjectGeneric, id: number | string): Promise<any> => await client.put(`report-folio/config-general/${id}`, body),
  deleteConfig: async (id?: any) => await client.delete(`report-folio/config/${id}`),
  someOneIsGivingASolution: async(id:string): Promise<Attending> => await client.post(`report-folio/mark-seen/${id}`),
  getConfigById: async (id?: number) => await client.get(`report-folio/config/${id}`),
  getDepartments: async (params: { areaId?: any; configReportFolioId?: any }) => {
    const queryString = Object.keys(params).length > 0 ? '?' + new URLSearchParams(params).toString() : ''
    return await client.get(`report-folio/departments${decodeURIComponent(queryString)}`)
  },
  saveComment: async ({ id, body }: { id: number; body: ObjectGeneric }): Promise<any> => await client.post(`report-folio/${id}/comments`, body),
})
