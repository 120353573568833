import { Box, Grid, IconButton, Typography } from "@mui/material"
import BaseModal from "../../../../../../components/modal/baseModal"
import { useTranslation } from "react-i18next"
import { icons } from "../../../../../../utils/icons"
import ListDetail from "../../../../../../components/listDetail"
import CustomAccordion from "../../../../../../components/accordion"
import { PropsDetailModal } from "./type"
import { useContextState } from "../../../../../../hooks/theme"
import { useDetailModalStyles } from "./detailModalStyles"
import { DetailKeyValue } from "../../../../../../components"
import { DateWithActions } from "../../../../../../components/dateWithActions"
import { String } from "aws-sdk/clients/cloudwatchevents"

const DetailModal = (props: PropsDetailModal) => {
    const { t } = useTranslation()
    const { colors } = useContextState()
    const styles = useDetailModalStyles()
    const amountSectionIsNull = props?.questionnaire?.amountSection == null
    const amountQuestionIsNull = props?.questionnaire?.amountQuestionAnswers == null
    const reportUrlEsixst = !!props?.questionnaire?.reportUrl && props?.questionnaire.reportDate
    const lastReportUrlEsixst = !!props?.questionnaire?.lastReportUrl && props?.questionnaire?.lastReportDate
    return (
        <BaseModal open={props.open} sx={styles.modal2}>
            <Grid container spacing={3}>
                <Grid item xl={12} lg={12} md={12}>
                    <Grid container spacing={2} sx={styles.headerModal}>
                        {/* Detalle de disponibilidad*/}
                        <Grid item md={11}>
                            <Typography sx={styles.subTitle}>
                                {t('schedule.assigned.detailPlanification')}
                            </Typography>
                        </Grid>

                        <Grid item md={1}>
                            <IconButton
                                onClick={() => {
                                    props.closeModal()
                                }}
                            >
                                {icons('simple-close')}
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Box sx={styles.content}>
                        <ListDetail
                            colors={colors}
                            notCount
                            notFoundMessage={`${t('general.ninformationNotFound')}`}
                            title={t('general.directSuperiors')}
                            size={{
                                xl: 6,
                                lg: 6,
                                md: 6,
                            }}
                            list={
                                props.directParents ?? []
                            }
                            maxHeight={200}
                        />

                        <ListDetail
                            colors={colors}
                            notCount
                            notFoundMessage={`${t('general.ninformationNotFound')}`}
                            title={t('general.createdByUser')}
                            size={{
                                xl: 6,
                                lg: 6,
                                md: 6,
                            }}
                            list={
                                props.createdByUser ?? []
                            }
                            maxHeight={200}
                        />

                        <ListDetail
                            colors={colors}
                            notCount
                            notFoundMessage={`${t('general.ninformationNotFound')}`}
                            title={t('general.tags.userInformation')}
                            size={{
                                xl: 6,
                                lg: 6,
                                md: 6,
                            }}
                            list={
                                props?.userAssigned ? [props?.userAssigned] : []
                            }
                        />


                        <Typography sx={styles.sectionTitle}>{t('general.tags.questionnairesInformation')}</Typography>

                        <Box sx={styles.containerAccordion}>
                            <CustomAccordion
                                expandIcon={null}
                                defaultExpanded
                                expanded={true}
                                title={(
                                    <Box sx={styles.titleAccordion}>
                                        <Box>
                                            <Typography sx={styles.titleAccordion2}>{(props?.questionnaire?.title ?? "") as string}</Typography>

                                            <Typography
                                                sx={styles.date}
                                            >
                                                {
                                                    t('general.dateFromTo')
                                                        .replace('{start}', props?.questionnaire?.from ?? '')
                                                        .replace('{end}', props?.questionnaire?.to ?? '')
                                                }
                                            </Typography>
                                        </Box>

                                        {(props?.questionnaire?.status?.element) ?? null}
                                    </Box>
                                )}
                                description={(
                                    <Grid container spacing={2}>

                                        <Grid item xs={12}>
                                            {
                                                (!amountSectionIsNull && !amountQuestionIsNull) ? (
                                                    <Box sx={styles.containerDetails}>
                                                        {!amountSectionIsNull && <DetailKeyValue
                                                            sxContainer={styles.details}
                                                            align="center"
                                                            caption={amountSectionIsNull ? t('general.oldSections') : t('general.sections')}
                                                            value={`${props?.questionnaire?.amountSection}/${props?.questionnaire?.section}`}
                                                        />}

                                                        {!amountQuestionIsNull && <DetailKeyValue
                                                            sxContainer={styles.details}
                                                            align="center"
                                                            caption={amountQuestionIsNull ? t('general.oldQuestions') : t('general.question')}
                                                            value={`${props?.questionnaire?.amountQuestionAnswers}/${props?.questionnaire?.amountQuestion}`}
                                                        />}
                                                        <DetailKeyValue
                                                            sxContainer={styles.details}
                                                            align="center"
                                                            caption={t('general.tags.result')}
                                                            value={props?.questionnaire?.percentage}
                                                        />
                                                    </Box>
                                                ) : (<Typography sx={styles.text2}>{t('general.tags.result')}: {props?.questionnaire?.percentage}</Typography>)
                                            }
                                        </Grid>


                                        {
                                            reportUrlEsixst && (
                                                <Grid item xs={6.5}>
                                                    <DateWithActions
                                                        date={props?.questionnaire?.reportDate}
                                                        title="Reporte"
                                                        url={props?.questionnaire?.reportUrl as string}
                                                        resendMail
                                                        visibilityProps={{
                                                            onClick: (e) => {
                                                                e.stopPropagation()
                                                                props.optionMenu({
                                                                    item: {
                                                                        label: t('schedule.modal.displayQuestionnaireReport'),
                                                                        value: 3,
                                                                        questionnaireId: props?.questionnaire?.reportUrl
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                        resendProps={{
                                                            onClick: () => {
                                                                props.optionMenu({
                                                                    item: {
                                                                        label: "", value: 4,
                                                                        questionnaireId: props.detail.id
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            )
                                        }

                                        {
                                            lastReportUrlEsixst && (
                                                <Grid item xs={6.5}>
                                                    <DateWithActions
                                                        date={props.questionnaire?.lastReportDate}
                                                        title="Último reporte"
                                                        url={props?.questionnaire?.lastReportUrl as string}
                                                        visibilityProps={{
                                                            onClick: (e) => {
                                                                e.stopPropagation()
                                                                window.open(props?.questionnaire?.lastReportUrl as String, 'blank')
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                )}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </BaseModal>
    )
}

export { DetailModal }