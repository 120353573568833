import { useContextState } from "../../../../../../hooks/theme"

const useStyles = () => {
  const { colors } = useContextState()

  return {
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxShadow: `0px 0px 4px ${colors.color13}`,
      borderRadius: '5px',
      padding: '20px',
      gap: '10px',
      position: 'relative'
    },
    information: {
      width: 'calc(100% - 100px)'
    },
    title: {
      fontSize: '1.4rem',
      color: colors.color13,
      fontWeight: '600'
    },
    subTitle: {
      fontSize: '.8rem',
      color: colors.color13,
    },
    progress: {
      width: '100px',
      height: '100px',
      '& .MuiCircularProgress-root': {
        width: 'inherit',
        height: 'inherit',
      }
    },
    actionIndicator: {
      position: 'absolute',
      top: '0',
      right: '10px',
      '& .MuiIconButton-root': {
        fontSize: '1rem',
        '& span': {
          fontSize: '11px',
          marginRight: '2px',
        },
        '& .MuiSvgIcon-root': {
          fontSize: '1em',
        },
      },
    },
  }
}

export { useStyles }
