import { Box, IconButton, Typography } from "@mui/material"
import { icons } from "../../../../../../utils/icons"
import { FacebookCircularProgress } from "../facebookIndicator"
import { useStyles } from "./styles"
import Loader from "../../../../../../components/loader"

const ScoreCard = (props: { loading: boolean; refresh: () => void, title: string; subtitle: string; value: number }) => {
  const styles = useStyles()
  return (
    <Box sx={styles.container}>
      {
        !props.loading ? (
          <>
            <Box sx={styles.actionIndicator}>
              <IconButton
                onClick={() => {
                  props.refresh()
                }}
              >
                {icons('refresh')}
              </IconButton>
            </Box>

            <Box sx={styles.information}>
              <Typography sx={styles.title}>{props.title}</Typography>

              <Typography sx={styles.subTitle}>{props.subtitle}</Typography>
            </Box>

            <Box sx={styles.progress}><FacebookCircularProgress style={{ width: '100px', height: '100px' }} variant="determinate" value={props.value} /></Box>

          </>
        )
          :
          <Loader />
      }
    </Box>
  )
}

export { ScoreCard }
