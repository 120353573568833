import { ModalChangeUserDataPayload } from "./types"

const openModalUpdateUserData = (payload: ModalChangeUserDataPayload) => {
  const body = {
    open: true,
    value: payload.value,
    name: payload.name,
    id: payload.id,
    label: payload.t(`general.tags.${payload.name}`),
    title: payload.t(`general.tags.emailEdition`),
    cancel: payload.closeModalEdit,
  }
  if (payload.name == 'email') {
    payload.setEmail(payload.value)
    payload.setOpenModalEdit(prev => ({
      ...prev,
      ...body,
      comfirm: payload.handleConfirmEmail
    }))
  } else {
    payload.setOpenModalEdit(prev => ({
      ...prev,
      ...body,
      value: '',
      title: payload.t(`general.tags.passwordEdition`),
      cancel: payload.closeModalEdit,
      comfirm: payload.confirmUpdatePassword
    }))
  }
}

export { openModalUpdateUserData }
