import {
  Box,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { CustomTable } from '../../../../../components'
import { IOSSwitch } from '../../../../../components/dinamicForm/switch'
import { ButtonsDrawer } from '../../../../../components/buttons/buttonDrawer'
import { WizardUserScheduleProps } from './typing'
import { useContextState } from '../../../../../hooks/theme'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Constants } from '../../../../../utils/constants'
import dayjs from 'dayjs'
import { Ref, forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { RefTable } from '../../../../../components/table/types'

const CurrentStep = forwardRef((props: WizardUserScheduleProps, ref: Ref<{ removeChecks?: (payload: { value: boolean }) => void }>) => {
  const refTable = useRef<RefTable>(null)
  const { colors } = useContextState()
  const styles = useStyles()
  const { t } = useTranslation()
  
  useImperativeHandle(ref, () => ({
    removeChecks: ({ value }: { value: boolean }) => {
      if (refTable?.current) {
        refTable?.current?.allChecksAreSelected?.({ value })
      }
    }
  }))

  const objectSteps: Record<string, JSX.Element> = {
    0: (
      <CustomTable
        ref={refTable}
        loader={!!props.isLoadingEndpoints?.users}
        dontRefresh
        tableChecks
        defaultChecks={props.selectSchedule ?? []}
        onClick={props.handleSelect}
        column={props.columns.column1}
        row={props.rows ?? []}
        color={colors}
        selected={-1}
      />
    ),
    1: (
      <CustomTable
        ref={refTable}
        tableRadio={!!props.id}
        loader={!!props.isLoadingEndpoints?.questionnaires}
        dontRefresh
        tableChecks
        defaultChecks={props.selectSchedule ?? []}
        onClick={props.handleSelect}
        column={props.columns.column2}
        row={props.rows ?? []}
        color={colors}
        selected={-1}
      />
    ),
    2: (
      <Grid container spacing={4}>
        <Grid item xs={!!props.userSelectes?.id ? 7.5 : 12}>
          <CustomTable
            ref={refTable}
            disabledChecks={props.disabledChecks}
            dontRefresh
            tableChecks
            defaultChecks={props.selectSchedule ?? []}
            onClick={props.handleSelect}
            column={props.columns.column3}
            row={props.rows ?? []}
            color={colors}
            selected={-1}
          />
        </Grid>

        {!!props.userSelectes?.id && (
          <Grid item xs={4.5} sx={styles.cancelBtn}>
            <Typography sx={styles.scheduleTitle}>
              {t(`schedule.assigned.${props.userSelectes?.id == 'all' ? 'availabilityAllQuestionnaire' : 'availabilityQuestionnaire'}`)
                .replace("{questionnaireName}", props?.currentUser?.questionnaire)
                .replace("{userName}", props?.currentUser?.user)
              }
            </Typography>

            <Box>
              <Typography sx={styles.subTitle}>
                {t('schedule.assigned.from')}
              </Typography>

              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="es"
              >
                <DateTimePicker
                  ampm
                  disablePast
                  inputFormat={Constants.dateFormatDateTimePicker}
                  minDateTime={dayjs(Date.now())}
                  renderInput={(rest) => (
                    <TextField
                      {...rest}
                      name="from"
                      helperText={props.errors?.from ?? ''}
                      error={props.errors?.from ?? ''}
                    />
                  )}
                  label={`${t('general.startDate')} *`}
                  value={props.data.from ?? null}
                  onChange={(newValue, keyboard) => {
                    props.onChange({
                      target: {
                        name: 'from',
                        value: newValue?.valueOf(),
                      },
                      keyboard,
                    })
                  }}
                />
              </LocalizationProvider>
            </Box>

            <Box>
              <Typography sx={styles.subTitle}>
                {t('schedule.assigned.to')}
              </Typography>

              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="es"
              >
                <DateTimePicker
                  ampm
                  disablePast
                  inputFormat={Constants.dateFormatDateTimePicker}
                  renderInput={(rest) => (
                    <TextField
                      {...rest}
                      name="to"
                      helperText={props.errors?.to ?? ''}
                      error={props.errors?.to ?? ''}
                    />
                  )}
                  minDateTime={dayjs((props.data?.from ?? Date.now()) + 250000)}
                  label={`${t('general.endDate')} *`}
                  value={props.data.to ?? null}
                  onChange={(newValue, keyboard) => {
                    props.onChange({
                      target: {
                        name: 'to',
                        value: newValue?.valueOf(),
                      },
                      keyboard,
                    })
                  }}
                />
              </LocalizationProvider>
            </Box>

            <Box my={2.5}>
              <FormControlLabel
                sx={styles.formLabel}
                control={
                  <IOSSwitch
                    name="notified"
                    checked={!!props.data.notified}
                    onChange={({ target }) => {
                      props.onChange({
                        target: {
                          name: target.name,
                          value: target.checked,
                        },
                      })
                    }}
                    sx={{ m: 1 }}
                  />
                }
                label={t('schedule.assigned.sendAlert')}
              />
            </Box>

            <Box my={2.5}>
              <FormControlLabel
                sx={styles.formLabel}
                control={
                  <IOSSwitch
                    name="notifyAdmin"
                    checked={!!props.data.notifyAdmin}
                    onChange={({ target }) => {
                      props.onChange({
                        target: {
                          name: target.name,
                          value: target.checked,
                        },
                      })
                    }}
                    sx={{ m: 1 }}
                  />
                }
                label={t('schedule.assigned.sendAlertToAdmin')}
              />
            </Box>

            <ButtonsDrawer
              acceptProps={{
                sx: {
                  fontSize: '12px',
                  height: 'fit-content',
                  maxWidth: '210px',
                  wordBreak: 'break-word',

                }
              }}
              cancelProps={{
                sx: {
                  padding: '0',
                  '&.cancel-drawer': {
                    fontSize: '12px',
                  },
                }
              }}
              accept={{
                title: t(`useScheduled.detail.buttons.${props.userSelectes?.id == 'all' ? 'applySelectedToAll' : 'applySelected'}`),
                disabled: props.diabledSaveSchedule,
                onClick: () => {
                  props.keepSaveSchedule()
                },
              }}
              cancel={{
                title: t('useScheduled.detail.buttons.cancel'),
                onClick: () => {
                  props.handleCloseScheduleUser()
                },
              }}
            />
          </Grid>
        )}
      </Grid>
    ),
  }

  useEffect(() => {
    if (refTable?.current) {
      refTable?.current?.resetPagination?.()
    }
  }, [props.steps])
  return objectSteps[props.steps] ?? <></>
})

export { CurrentStep }
