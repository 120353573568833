import { Box } from '@mui/material'
import React from 'react'
import { Color } from '../../utils/theme/types/color'
import { useStyles } from './styles'

export interface Props {
    open?:boolean
    hidden?:boolean
    colors:Color
    bodyL:JSX.Element
    bodyR?:JSX.Element
}
const BodyLayout = (props:Props) => {
  const styles = useStyles(props)
  return (
    <Box sx={styles.container}>
      <Box className="crud-filter-container" sx={styles.table}>{props.bodyL}</Box>
      {(!props?.hidden && !!props?.bodyR) && <Box sx={styles.userDetail}>{props.bodyR}</Box>}
    </Box>
  )
}

export default BodyLayout
