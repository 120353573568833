import profile from '../../assets/images/userDefault.png'
import profileHeader from '../../assets/svg/profile-header.svg'
import profileBtn from '../../assets/images/userDefault.png'
import interception from '../../assets/images/logo.png'
import store from '../../assets/images/storeDefault.png'
import camera from '../../assets/images/camera.png'
import fire from '../../assets/svg/fire.svg'
import bell from '../../assets/svg/bell.svg'
import bellPriority from '../../assets/svg/bell-priority.svg'
import loupe from '../../assets/svg/loupe.svg'
import logo from '../../assets/svg/check-plus-k.svg'
import avatar from '../../assets/images/Avatar.png'

export const images = {
    logo,
    profile,
    profileBtn,
    profileHeader,
    interception,
    store,
    camera,
    fire,
    bell,
    loupe,
    bellPriority,
    avatar
}