import { TFunction } from 'react-i18next'
import { CRUD } from '../../../../utils/enums'
import { images } from '../../../../utils/images'
import { InputModel } from '../../../../utils/types'

export const initialFilterValues = {}

export const useFilterDetail = (t: TFunction<'translation', undefined>) => {
  return {
    search: {
      label: t('stores.filter.inputs.search'),
      name: 'search',
    },
    status: {
      label: t('stores.filter.inputs.firstSelect'),
      name: 'status',
    },
  }
}
export const initialValues = {
  picture: {
    label: 'Cargar imagen',
    translate: true,
    name: 'picture',
    type: 'picture',
    value: null,
    rest: {
      customImage: images.store,
    },
    size: {
      xl: 5,
      lg: 5,
      md: 6,
      sm: 6,
    },
  },
  name: {
    label: 'general.tags.store_name',
    translate: true,
    customLabel: 'general.tags.store_name',
    name: 'name',
    validate: {
      required: true,
    },
    rest: {
      type: 'alphaNumeric',
      inputProps: {
        maxLength: 45,
      },
    },
    value: null,
  },
  email: {
    label: 'general.tags.email',
    translate: true,
    name: 'email',
    validate: {
      required: true,
      email: true,
    },
    value: null,
  },
  companyId: {
    label: 'general.tags.company',
    translate: true,
    name: 'companyId',
    validate: {
      required: true,
    },
    type: 'select-search',
    items: [],
    value: null,
  },
  userManageId: {
    label: 'general.tags.userManageId',
    translate: true,
    name: 'userManageId',
    type: 'select-search',
    items: [],
    value: null,
  },
  score: {
    label: 'general.tags.score',
    translate: true,
    name: 'score',
    validate: {
      required: true,
      number: true,
    },
    rest: {
      type: 'number',
      min: 0,
      max: 100,
    },
    value: null,
  },
  phone: {
    label: 'general.tags.phone',
    translate: true,
    name: 'phone',
    type: 'phone',
    value: '+52',
    validate: {
      phone: true,
    },
  },
  states: {
    label: 'general.tags.states',
    translate: true,
    name: 'states',
    type: 'select-search',
    items: [],
    validate: {
      required: true,
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
    },
    value: null,
  },
  city: {
    label: 'general.tags.cities',
    translate: true,
    name: 'city',
    type: 'select-search',
    items: [],
    validate: {
      required: true,
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
    },
    value: null,
    customeErrors: {
      required: 'general.validate.required.cities',
    },
  },
  status: {
    label: 'general.tags.status',
    translate: true,
    name: 'status',
    type: 'select-search',
    validate: {
      required: true,
    },
    value: null,
  },
  address: {
    label: 'general.tags.address',
    translate: true,
    name: 'address',
    value: null,
    validate: {
      required: true,
    },
  },
  latitude: {
    label: 'general.tags.latitude',
    translate: true,
    name: 'latitude',
    value: null,
    validate: {
      required: true,
    },
    rest: {
      type: 'chordenada',
    },
    size: {
      lg: 6,
    },
  },
  longitude: {
    label: 'general.tags.longitude',
    translate: true,
    name: 'longitude',
    validate: {
      required: true,
    },
    rest: {
      type: 'chordenada',
    },
    value: null,
    size: {
      lg: 6,
    },
  },
  keyCode: {
    label: 'general.tags.keyCode',
    translate: true,
    name: 'keyCode',
    validate: {
      required: true,
    },
    rest: {
      type: 'onlyNumberAndCharacter',
    },
    value: null,
  },
  divisions: {
    label: 'general.tags.divisions',
    translate: true,
    name: 'divisions',
    type: 'select-search',
    items: [],
    value: null,
    size: {
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
    },
  },
  fatherZone: {
    label: 'general.tags.fatherZone',
    translate: true,
    name: 'fatherZone',
    type: 'select-search',
    items: [],
    value: null,
    size: {
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
    },
  },
  class: {
    label: 'general.tags.class',
    translate: true,
    name: 'class',
    type: 'select-search',
    items: [],
    value: null,
    size: {
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
    },
  },
} as { [x: string]: InputModel }

export const initialMassiveUpload = {
  companyId: {
    label: 'general.tags.company',
    translate: true,
    name: 'companyId',
    validate: {
      required: true,
    },
    type: 'select-search',
    items: [],
    value: null,
  },
  divisions: {
    label: 'general.tags.divisions',
    translate: true,
    name: 'divisions',
    type: 'select-search',
    items: [],
    value: null,
    size: {
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
    },
  },
  fatherZone: {
    label: 'general.tags.fatherZone',
    translate: true,
    name: 'fatherZone',
    type: 'select-search',
    items: [],
    value: null,
    size: {
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
    },
  },
  userManageId: {
    label: 'general.tags.userManageId',
    translate: true,
    name: 'userManageId',
    type: 'select-search',
    items: [],
    value: null,
  },
  class: {
    label: 'general.tags.class',
    translate: true,
    name: 'class',
    type: 'select-search',
    items: [],
    value: null,
    size: {
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
    },
  },
} as { [x: string]: InputModel }

export const initialData = {
  name: null,
  email: null,
  companyId: null,
  zipCodeId: null,
  states: null,
  city: null,
  status: null,
  picture: null,
  phone: null,
  address: null,
  latitude: '0',
  longitude: '0',
  score: '0',
  userManageId: null,
  keyCode: null,
  divisions: null,
  fatherZone: null,
  class: null,
}

export const initialTable = {
  column: [],
  row: [],
}

export const gender = [
  {
    value: '1',
    label: 'Masculino',
  },
  {
    value: '0',
    label: 'Femenino',
  },
]

export const username = [
  {
    value: '1',
    label: 'Teléfono',
  },
  {
    value: '0',
    label: 'Correo electrónico',
  },
]

export const useMenuItems = (styles: any) => [
  {
    value: CRUD.EDIT,
    label: 'Editar unidad',
    // icon: <Icon sx={styles.menuIcons}>{icons('edit')}</Icon>,
  },
  {
    value: CRUD.DELETE,
    label: 'Eliminar unidad',
  },
]

export const input = {
  search: {
    label: 'general.search',
    translate: true,
    name: 'search',
    type: 'text',
    value: null,
  },
  createYear: {
    label: 'general.filterDate.year',
    translate: true,
    name: 'createYear',
    type: 'filter-select',
    items: [],
    value: null,
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  month: {
    label: 'general.filterDate.month',
    translate: true,
    name: 'month',
    type: 'filter-select',
    items: [],
    value: null,
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  from: {
    label: 'general.tags.from',
    translate: true,
    name: 'from',
    type: 'date',
    value: null,
    rest: {
      max: 'to',
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  to: {
    label: 'general.tags.to',
    translate: true,
    name: 'to',
    type: 'date',
    value: null,
    rest: {
      min: 'from',
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  divisions: {
    label: 'general.tags.division',
    translate: true,
    name: 'divisions',
    type: 'filter-select',
    items: [],
    value: null,
  },
  zones: {
    label: 'general.tags.zone',
    translate: true,
    name: 'zones',
    type: 'filter-select',
    items: [],
    value: null,
  },
  class: {
    label: 'general.tags.class',
    translate: true,
    name: 'class',
    type: 'filter-select',
    items: [],
    value: null,
    size: {
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
    },
  },
  users: {
    label: 'general.tags.userManage',
    translate: true,
    name: 'users',
    type: 'filter-select',
    items: [],
    value: null,
  },
} as { [x: string]: InputModel }

export const accept = ['png', 'jpg', 'jpeg']
