import { useEffect, useState } from 'react'
import { InputModel } from '../../../../../utils/types'
import { Item } from '../../../../../utils/types/generals'
import { questionnairesInput, scheduleInput, usersInput } from '../constants'

const useInput = (payload: {
  listItems: Record<string, Item[]>
  steps: string | number
}) => {
  const [input, setIput] = useState<Record<string, Record<string, InputModel>>>(
    {}
  )

  useEffect(() => {
    const format: Record<string, Record<string, InputModel>> = {
      0: {
        ...usersInput,
        userType: {
          ...usersInput.userType,
          items: payload.listItems.userTypes ?? [],
        },
      },
      1: {
        ...questionnairesInput,
      },
      2: {
        ...scheduleInput,
        users: {
          ...scheduleInput.users,
          items: payload.listItems.users ?? [],
        },
        questionnaires: {
          ...scheduleInput.questionnaires,
          items: payload.listItems.questionnaires ?? [],
        },
      },
    }
    setIput((prev) => ({
      ...prev,
      [payload.steps]: format[payload.steps],
    }))
  }, [payload.steps, payload.listItems])
  return { input }
}

export { useInput }
