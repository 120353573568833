import { Roles } from "../enums";

const getDirectManagement = (role: Roles) => {
  switch (role) {
    case Roles.PARTNER: return [Roles.STORE]
    case Roles.STORE: return [Roles.SUPPORT, Roles.AUDIT, Roles.AGENT]
    case Roles.SUPPORT:
    case Roles.AUDIT:
    case Roles.AGENT: return [Roles.SUPERVISOR]
    case Roles.SUPERVISOR: return [Roles.GERENT]
    default: return []
  }
}

export { getDirectManagement }