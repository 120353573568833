import { useState, useEffect, useCallback } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CustomTable, SnackSimple } from '../../../../components'
import { Types } from '../../../../utils/enums'
import { Item, ObjectGeneric, onChange, Table } from '../../../../utils/types/generals'
import { initialData, initialTable, initialValues, modelOption, option, input } from './initialState'
import { filterRow, getList, handleErrors, submit } from './methods'
import Form from './components/form'
import { useContextState } from '../../../../hooks/theme'
import { useStyles } from './style'
import { ModelOption } from './types'
import { icons } from '../../../../utils/icons'
import { httpFetch } from '../../../../utils/crud'
import { OptionGroupService } from '../../../../service/optionGroup'
import { OptionGroupList } from '../../../../service/optionGroup/reponse'
import SimpleModal from '../../../../components/modal/simpleModal'
import { handleAllErrors } from '../../../../utils/methods/handleErrors'
import { FiltersDrawer } from '../../../../components/filtersDrawer'
import { DinamicForm } from '../../../../components/dinamicForm'
import { InputModel } from '../../../../utils/types/inputModel'
import { monthYear } from '../../../../utils/constants/date_month'
import { ValidateDateRange } from '../../../../utils/methods/validateDateRange'
import DownloadFiles from '../../../../components/donwloadFiles'

const OptionGroup = () => {
  const { t } = useTranslation()
  const { colors } = useContextState()
  const [goForm, setGoForm] = useState(false)
  const styles = useStyles(colors, goForm)
  const [id, setId] = useState<number | null>(null)

  const [counter, setCounter] = useState<number | null>(null)
  const [erros, setErrors] = useState<ObjectGeneric>({})
  const [inputs, setInputs] = useState<{ [x: string]: InputModel }>(input)

  const [data, setData] = useState<ObjectGeneric>(initialData)
  const [btnLoading, setBtnLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [flag, setFlag] = useState(false)
  const [allErrors, setAllErrors] = useState<ObjectGeneric>({})
  const [table, setTable] = useState<Table>(initialTable)
  const [tableCopy, setTableCopy] = useState<Table>(initialTable)
  const [openDeleteModal, setOpenDeleteModal] = useState({
    open: false,
    id: null,
  })
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    openModal: false,
    message: '',
    type: Types.SUCCESS as string,
  })
  const [dataFilter, setDataFilter] = useState<ObjectGeneric>({})
  useEffect(() => {
    setCounter(null)
    let count = 0

    for (let key in dataFilter) {
      const ele = dataFilter[key]
      if (key === 'search' || key === 'month' || key === 'from' || key === 'createYear' || key === 'to') {
        if (ele !== null && ele !== '' && ele.length !== 0) count++
      }
    }

    setCounter(count)
  }, [dataFilter])

  const handleCloseSnackbar = (value: boolean) => {
    setOpenSnackbar((prev) => ({ ...prev, open: value }))
  }
  const crudChange: onChange = (e, id): void => {
    const { name, value } = e?.target
    let body = { ...data }

    if (!!id && typeof id === 'number') {
      body = {
        ...body,
        options: body?.options?.map((item: any) => {
          if (item.id === id) {
            return {
              ...item,
              [name]: value,
            }
          } else {
            return item
          }
        }),
      }
    } else {
      body = {
        ...body,
        [name]: value,
      }
    }

    setData(body)
    setAllErrors(handleErrors(body, t))
  }

  const addOption = (id?: number) => {
    if (!id) {
      setData((prev) => ({
        ...prev,
        options: [...prev.options, { ...modelOption, id: prev.optionId + 1 }],
        optionId: prev.optionId + 1,
      }))
    } else {
      if (data.options?.length > 2) {
        setData((prev) => ({
          ...prev,
          options: prev.options?.filter((item: ModelOption) => item.id !== id),
        }))
      }
    }
  }

  const handleSubmit = async (removeId?: boolean) => {
    setBtnLoading(true)
    const response = await submit(data, t, removeId ? null : id)
    if (!response?.response?.response.errors) {
      getAll()
    } else {
      setData(response?.response?.copyData)
    }
    setOpenSnackbar(() => ({ ...response.snackbarBody }))
    setBtnLoading(false)
  }

  const handleDelete = async () => {
    if (openDeleteModal.id) {
      const response: any = await OptionGroupService(httpFetch).deleteOptionGroupById(openDeleteModal.id)

      const existError = response?.errors
      setOpenSnackbar((prev) => ({
        ...prev,
        open: true,
        message: existError ? handleAllErrors(existError?.[0]?.code ?? '', t, 'optionGroup.modal.delete.error') : t('optionGroup.modal.delete.success'),
        type: existError ? Types.ERROR : Types.SUCCESS,
      }))
      closeDeleteModal()
      if (!existError) {
        getAll()
      }
    }
  }
  const getItem = async (id: number) => {
    setIsLoading(true)
    const response: OptionGroupList = await OptionGroupService(httpFetch).getOptionGroupById(id)

    if (!response?.errors) {
      setId(id)
      setData({
        title: response.title,
        options:
          response.options?.length > 0
            ? response.options?.map((item, index) => ({
              description: item.description,
              iconUrl: item.iconUrl,
              id: index + 1,
            }))
            : initialData.options,
        optionId: response.options?.length || 2,
      })
      handleGoFrom(true)
    }
    setIsLoading(false)
  }

  const getAll = useCallback(async () => {
    setIsLoading(true)
    const responseStores = await getList(colors, t)
    let createYears: Item[] = []
    responseStores?.row?.forEach((item: any) => {
      if (!createYears.some((el) => el?.value === item?.createYear)) {
        createYears = [
          ...createYears,
          {
            label: item?.createYear,
            value: item?.createYear,
          },
        ]
      }
    })
    setInputs((prev) => ({
      ...prev,
      createYear: {
        ...prev.createYear,
        items: createYears,
      },
      month: {
        ...prev.month,
        items: monthYear,
      },
    }))
    const row = responseStores.row.map((item: any) => ({
      ...item,
      action: {
        children: (
          <Box sx={styles.constainerAction}>
            <IconButton
              sx={styles.edit}
              onClick={(e) => {
                e.stopPropagation()
                getItem(item.id)
              }}
            >
              {icons('edit')}
            </IconButton>
            <IconButton
              sx={styles.delete}
              onClick={(e) => {
                e.stopPropagation()
                setOpenDeleteModal({
                  open: true,
                  id: item.id,
                })
              }}
            >
              {icons('delete')}
            </IconButton>
          </Box>
        ),
      },
    }))

    setTable({
      ...responseStores,
      row,
    })
    setTableCopy({
      ...responseStores,
      row,
    })
    setIsLoading(false)
    setData(initialData)
    handleGoFrom(false)
    setDataFilter({})
    // eslint-disable-next-line
  }, [colors, t])

  const getData = useCallback(async () => {
    setFlag(true)
    getAll()
  }, [getAll])

  const handleGoFrom = (go: boolean) => {
    setGoForm(go)
    if (!go) {
      setId(null)
      setData(initialData)
      setAllErrors({})
      setErrors({})
      setOpenSnackbar((prev) => ({
        ...prev,
        openModal: false,
      }))
    }
  }

  const closeDeleteModal = () => {
    setOpenDeleteModal({
      id: null,
      open: false,
    })
  }
  const handleDisabled = () => !data.title || !!data.options?.find((item: ModelOption) => !item.description)
  useEffect(() => {
    if (!flag) getData()
  }, [getData, flag])

  const cleanFilter = () => {
    setDataFilter({})
    setErrors({})
    setTable({ ...table, row: tableCopy.row })
  }

  const onFilterChange: onChange = ({ target }, blur) => {
    const { name, value } = target

    const { endDate, startDate } = ValidateDateRange({ name, value, blur, from: dataFilter?.from, to: dataFilter?.to })

    if (!['from', 'to'].includes(name) || !(blur as { blur: boolean })?.blur) {
      const body = {
        ...dataFilter,
        [name]: value,
      }

      setDataFilter(body)
      setTable({ ...table, row: filterRow(body, tableCopy.row) })
    }

    if (startDate && endDate) {
      if (startDate > endDate) {
        setErrors((prev) => ({
          ...prev,
          [name]: t('general.wrongDate'),
        }))
      } else {
        setErrors((prev) => ({
          ...prev,
          from: null,
          to: null,
        }))
      }
    }
    if (!value) {
      setErrors((prev) => ({
        ...prev,
        [name]: null,
      }))
    }
  }
  return (
    <Box sx={styles.container}>
      <SnackSimple close={handleCloseSnackbar} open={openSnackbar.open} type={openSnackbar.type} message={openSnackbar.message} time={3000} />

      <SimpleModal
        open={openSnackbar.openModal}
        description=""
        close={() => {
          handleGoFrom(false)
        }}
        title={openSnackbar.message}
        buttonText={t('general.yes')}
        cancelText={t('general.no')}
        color={colors}
        back={() => {
          handleGoFrom(false)
        }}
        loadingButton
        isLoading={btnLoading}
        next={() => {
          handleSubmit(true)
        }}
      />
      <SimpleModal
        open={openDeleteModal.open}
        description={t('optionGroup.modal.delete.description')}
        close={closeDeleteModal}
        title={t('optionGroup.modal.delete.title')}
        buttonText={t('general.delete')}
        cancelText={t('general.cancel')}
        color={colors}
        back={closeDeleteModal}
        loadingButton
        isLoading={btnLoading}
        next={() => {
          handleDelete()
        }}
      />
      {!goForm ? (
        <Box sx={{ position: 'relative', paddingRight: '50px' }}>
          <FiltersDrawer cleanFilter={cleanFilter} count={counter} right={0} top={0}>
            <Box sx={styles.section}>
              <DinamicForm errors={erros} input={Object.values(inputs)} values={dataFilter} onChange={onFilterChange} filter={true} />
            </Box>
          </FiltersDrawer>

          <Box sx={styles.header}>
            <Typography sx={styles.title}>{t('optionGroup.filter.title')}</Typography>

            <Box sx={styles.flex}>
              <DownloadFiles
                row={table.row}
                column={table.column}
                name={t('optionGroup.filter.title')}
                format={{
                  orientation: 'landscape',
                  size: 'a4',
                }}
                csvText={'EXCEL'}
                removeMargin
                customeHeader={[
                  {
                    id: 'year',
                    label: 'Año',
                  },
                  {
                    id: 'month',
                    label: 'Mes',
                  },
                  {
                    id: 'weekYear',
                    label: 'Semana',
                  },
                  {
                    id: 'optionNumber',
                    label: 'Número de opciones',
                  },
                  {
                    id: 'title',
                    label: 'Tipo de respuestas',
                  },
                  {
                    id: 'createDate',
                    label: 'Fecha de creación',
                  },
                  {
                    id: 'createHour',
                    label: 'Hora de creación',
                  },
                  {
                    id: 'udpateDate',
                    label: 'Fecha de actualización',
                  },
                  {
                    id: 'udpateHour',
                    label: 'Hora de actualización',
                  },
                ]}
              />

              <IconButton
                sx={(styles.clean, (counter || 0) > 0 ? null : styles.hide)}
                onClick={() => {
                  cleanFilter()
                }}
              >
                {icons('clean')}
              </IconButton>

              <IconButton
                sx={styles.clean}
                className='add'
                onClick={() => {
                  handleGoFrom(true)
                }}
              >
                {icons('add')}
              </IconButton>
            </Box>
          </Box>

          <CustomTable
            loader={isLoading}
            color={colors}
            column={table.column}
            row={table.row}
            selected={-1}
          />
        </Box>
      ) : (
        <Form
          t={t}
          id={id}
          color={colors}
          input={Object.values(initialValues)}
          goBack={() => {
            handleGoFrom(false)
          }}
          data={data}
          btnLoading={btnLoading}
          loading={isLoading}
          crudChange={crudChange}
          options={Object.values(option)}
          addOption={addOption}
          allErrors={allErrors}
          disabled={handleDisabled()}
          submit={(send) => {
            if (send > 0) {
              handleSubmit()
            }
          }}
        />
      )}
    </Box>
  )
}

export default OptionGroup
