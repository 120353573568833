import { Box, IconButton, InputAdornment, Typography } from '@mui/material'
import { useContextState } from '../../hooks/theme'
import { icons } from '../../utils/icons'
import BaseInput from '../dinamicForm/baseInput'
import CustomSelect from '../dinamicForm/select'
import { Props } from './props'
import { useStyles } from './styles'

const Filter = ({ title, filterTitle, firstSelect, secondSelect, refresh, add, load, filter, type = 1,sx2}: Props) => {
  const { colors } = useContextState()
  const styles = useStyles(colors, type)

  return (
    <Box className="filterContainer-main" sx={styles.container}>
      <Typography sx={styles.title}>{title ?? ''}</Typography>
      {type === 2 ? (
        <Box sx={styles.containerDetailTwo}>
          {filter && (
          <BaseInput
            sx={{...styles.widthInput,...sx2}}
            name={filter.name}
            value={filter.value}
            onChange={(e: any) => {
              filter?.onChange?.(e)
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{icons('search')}</InputAdornment>,
            }}
          />
        )}
        </Box>
      ) : (
      <Box sx={styles.containerDetail} className="filterContainer">
        {firstSelect && (
          <CustomSelect
            sx={[styles.widthInput, !!firstSelect.value && styles.labelAll]}
            name={firstSelect.name}
            label={firstSelect.label}
            onChange={(e: any) => {
              firstSelect?.onChange?.(e)
            }}
            items={firstSelect.items}
            selected={firstSelect.value}
          />
        )}

        {secondSelect && (
          <CustomSelect
            sx={[styles.widthInput, !!secondSelect.value && styles.labelAll]}
            name={secondSelect.name}
            label={secondSelect.label}
            onChange={(e: any) => {
              secondSelect?.onChange?.(e)
            }}
            items={secondSelect.items}
            selected={secondSelect.value}
          />
        )}

        {filter && (
          <BaseInput
            sx={{ ...styles.widthInput, ...filter.sx }}
            name={filter.name}
            value={filter.value}
            onChange={(e: any) => {
              filter?.onChange?.(e)
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{icons('search')}</InputAdornment>,
            }}
          />
        )}
        {refresh && (
          <IconButton
            sx={[styles.removePadding, styles.refresh]}
            onClick={() => {
              refresh.onClick()
            }}
          >
            {refresh.icon}
          </IconButton>
        )}
        {add && (
          <IconButton
            sx={[styles.removePadding, styles.add]}
            onClick={() => {
              add.onClick()
            }}
          >
            {add.icon}
          </IconButton>
        )}
        {load && (
          <IconButton
            sx={[styles.removePadding, styles.load]}
            onClick={() => {
              load.onClick()
            }}
          >
            {load.icon}
          </IconButton>
        )}
      </Box>
      )}
    </Box>
  )
}

export default Filter
