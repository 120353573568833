import { Box, Icon } from "@mui/material"
import { iconByPriority } from "../../../../utils"
import { usePriorityStyles } from "./priorityStyles"
import { Priority } from "../../../../typing"

const PriorityItem = (props: any) => {
    const styles = usePriorityStyles()
    return (
        <Box sx={styles.containerFolioAmount}>
          <Icon sx={styles.iconAlert}>
            <img
              src={
                iconByPriority[
                  (props?.current as Priority) || Priority.URGENT
                ]?.icon
              }
              alt="iconAlert"
            />
          </Icon>
        </Box>
      )
}

export { PriorityItem }
