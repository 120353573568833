import { Box, TextField, IconButton } from '@mui/material'
import { Constants } from '../../../utils/constants'
import { onChange } from '../../../utils/types'
import { isCharacter, isNumber, isDecimal, isAlphaNumeric, isChordenada, isOnlyNumberAndCharacters } from '../../../utils/validate/validate'
import { PropsBaseInput } from './props'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { icons } from '../../../utils/icons'
import { DinamicTypes } from '../../../utils/types/generals'

const BaseInput = ({ sx, sxInput, sxError, value, onChange, errors, name, type, pushError, typeField, variant, multiline, disabled, ...rest }: PropsBaseInput) => {
  const { t } = useTranslation()
  const validateTyping = (e: any, isBlur?: boolean) => {
    onChange(e, { blur: !!isBlur })
  }

  const handleMinMax = (value: PropsBaseInput['value'], e: any, isBlur?: boolean) => {
    const accept = rest?.min >= 0 ? value === '' : value === '-' || value === ''
    if ((!!rest?.max && (!!rest?.min || !!(rest?.min === 0))) || accept) {
      if ((value <= rest?.max && value >= rest?.min) || accept) validateTyping(e, isBlur)
    } else {
      if ((!!rest?.max && value <= rest?.max) || accept) validateTyping(e, isBlur)
      else if (((!!rest?.min || !!(rest?.min === 0)) && value >= rest?.min) || accept) validateTyping(e, isBlur)
      else if ((!rest?.max && !rest?.min) || accept) validateTyping(e, isBlur)
    }
  }

  const handleChange: onChange = (e, isBlur?: boolean) => {
    if (e.target.value !== ' ') {
      const { value } = e?.target
      if (type === 'text') isCharacter(value, Constants.specialCharacters, Constants.specialCharacters) && validateTyping(e, isBlur)
      else if (type === 'alphaNumeric') isAlphaNumeric(value, Constants.alphaNumeric, Constants.alphaNumeric) && validateTyping(e, isBlur)
      else if (type === 'onlyNumberAndCharacter') {
        if (isOnlyNumberAndCharacters(e?.target?.value) || value === '-' || value === '') {
          handleMinMax(e?.target?.value, e, isBlur)
        }
      }
      else if (type === 'number') {
        if (isNumber(value) || value === '-' || value === '') {
          handleMinMax(e?.target?.value, e, isBlur)
        }
      } else if (type === 'decimal') {
        if (isDecimal(value) || value === '-') {
          handleMinMax(e?.target?.value, e, isBlur)
        }
      } else if (type === 'chordenada') {
        if (isChordenada(value)) {
          validateTyping(e, isBlur)
        }
      } else {
        validateTyping(e, isBlur)
      }
    }
  }
  const [show, setShow] = useState(false)

  const handleShow = (type?: DinamicTypes) => {
    if (type === 'password') {
      return show ? 'text' : 'password'
    }
    return type
  }

  // useEffect(() => {
  //   setText(value?.[name]?.value)
  // }, [value, name])
  return (
    <Box sx={sx} key={name} {...(!!pushError && !!(errors?.[name]?.translate ?? errors?.[name]) && { mb: -3 })}>
      <TextField
        disabled={disabled}
        sx={sxInput}
        name={name}
        multiline={multiline}
        rows={2}
        maxRows={4}
        variant={variant}
        label={value?.[name]?.label}
        value={value?.[name]?.value ?? ''}
        onChange={handleChange}
        onBlur={(e) => {
          handleChange(e, true)
        }}
        helperText={(errors?.[name]?.translate ? t(errors?.[name]?.translate) : errors?.[name]) ?? ''}
        error={errors?.[name]?.translate ?? errors?.[name]}
        type={handleShow(typeField)}
        {...(typeField === 'password' && {
          InputProps: {
            endAdornment: <IconButton onClick={() => setShow(!show)}>{icons(show ? 'visibility-off' : 'visibility')}</IconButton>,
          },
        })}
        {...rest}
      />
      {/* <ErrorMessage error={errors?.[name]?.translate ?? errors?.[name]} message={errors?.[name]?.translate ? t(errors?.[name]?.translate): errors?.[name]}/> */}
    </Box>
  )
}

export default BaseInput
