import { useNavigate } from "react-router-dom";
import { createRef, useEffect, useState } from "react"
import { getArea, getDepartments, getDivisions, getRoles, getStore, getUserTypes } from "../../../../utils/methods/geServiceWithItemsFormat"
import { Item, ObjectGeneric } from "../../../../utils/types/generals"
import { useParams } from "react-router"
import { answers, questions, sections } from "./constants/models"
import { EvidenceFormat, General, ModalState, PermissionsData, PointsType, QuestionList, ReportFolioData, Sections, TypeMode } from "./typing/types"
import { questionnaire } from "../../../../utils/constants/callService"
import { DynamicFormRefValues, DynamicRefRef } from "../../../../components/dynamicFormRef/types"
import { useRefs } from "../../../../hooks/useReactHookModified"
import { EvidenceData, EvidenceDataHook } from "../../../../hooks/evidences/typyng"
import { useTranslation } from "react-i18next"
import { getLocalStorage, setLocalStorage } from "../../../../utils/crud"
import { QuestionnaireLocale } from "../main/typing/enums"
import { useEvidencesType } from "../../../../hooks/evidences/useEvidences"
import { isDecimal } from "../../../../utils/validate/validate"
import { ReorderRef } from "../../components/reorder/props"
import { InputModel } from "../../../../utils/types"
import { transformEvidencesNumber } from "../../../../utils/methods/evidences"
import { EvidencesTypeValueEnum } from "../../../../utils/enums/evidences"
import { removeAllReference } from "../../../../utils/methods/removedReference"
import { fields } from "../../constants"
import { Roles, Types } from "../../../../utils/enums"
import { ActionPlan, QuestionEvidence } from "../../../../service/questionarie/response"
import { manageData, openFolios } from "./methods";
import { getActionPlans } from "./methods/actionPlan";
import { validateData, validateErrors } from "./methods/validate";
import { handleSubmit } from "./methods/submit";

const state = getLocalStorage(QuestionnaireLocale.QUESTIONNAIRE)
const useBody = () => {
  const navigate = useNavigate();
  const { t } = useTranslation()
  const { refsByKey, setRef } = useRefs<DynamicRefRef>()
  const ref = createRef<ReorderRef>()
  const { id } = useParams()
  const [items, setItems] = useState<Record<string, Item[]>>({})
  const [dataHeader, setDataHeader] = useState<DynamicFormRefValues | undefined>({
    ...state,
    option: state?.option ?? '0',
    totalPoints: state?.totalPoints ?? 1
  })
  const [sectionIds, setSectionIds] = useState({
    section: 1,
    question: 1,
    answers: 1
  })
  const [errors, setErrors] = useState<ObjectGeneric>({})
  const [totalPoint, setTotalPoints] = useState<{
    total: number;
    [x: string]: PointsType | number
  }>({ total: 0 })
  const [modal, setModal] = useState<ModalState>({ open: false, title: '', description: '' })
  const [mode, setMode] = useState<TypeMode>('edit')
  const [evidencesModal, setEvidencesModal] = useState<EvidenceFormat>({ data: {}, evidences: {}, evidencesQuestionGeneralActive: {}, counts: {} })
  const evidencesQuestions = useEvidencesType({})
  const [reportFolioByAnswer, setReportFolioByAnswer] = useState<ReportFolioData>({ id: null })
  const [totalSections, setTotalSections] = useState<{ id: string; name?: string; errors?: number }[]>([{ id: '1' }])
  const [questionsList, setQuestionList] = useState<QuestionList>({})
  const [sectionsLength, setSectionsLength] = useState(1)
  const [currentSection, setCurrentSection] = useState('1')
  const [models, setModels] = useState<Record<string, Sections>>({})
  const [groupTypeItems, setGroupTypeItems] = useState<Item[]>([])
  const [itemsManage, setItemsManage] = useState<Record<string, Item[]>>({})
  const [snackbar, setSnackbar] = useState({
    open: false,
    type: Types.SUCCESS,
    message: ''
  })

  const [order, setOrder] = useState<{ sections: Record<string, number>; questions: Record<string, number> }>({
    sections: {},
    questions: {}
  })
  const [groupOptionsItems, setGroupOptionsItems] = useState<Item[]>([])
  const [loadings, setLoadings] = useState({
    permission: false,
    main: false,
    next: false
  })
  const [quetionnaireCanBeEdited, setQuestionnaiereCanBeEdited] = useState(true)
  const [permissionsData, setPermissionsData] = useState<PermissionsData>()
  const [itemFilter, setItemFilters] = useState({
    stores: false,
    userType: false,
    area: false,
    department: false,
    divisions: false
  })
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [input, setInput] = useState<{ [x: string]: InputModel }>({
    search: {
      label: 'general.search',
      translate: true,
      name: 'search',
      type: 'text',
      value: null,
    },
    role: {
      label: 'general.tags.role',
      translate: true,
      name: 'role',
      type: 'filter-select',
      items: [],
      value: null,
    },
    userType: {
      label: 'general.tags.userType',
      translate: true,
      name: 'userType',
      type: 'filter-select',
      items: [],
      value: null,
    }
  })


  const itemIsLoader = ({ name, value }: { name: string; value: boolean }) => {
    setItemFilters(prev => ({ ...prev, [name]: value }))
  }


  const handleOpenModalPermissions = (data?: { open?: boolean }) => {
    setModal((prev) => ({
      ...prev,
      open: !!data?.open,
      title: t(`Questionarie.modal.permission.${id ? 'update' : 'create'}`),
    }))
  }

  const disabledSubmitQuestionnaire = submitDisabled
  const disabledPermissionUsers = (): boolean => {
    return !id && !!disabledSubmitQuestionnaire
  }


  const updatePermissions = (payload: PermissionsData) => {
    if (id && !quetionnaireCanBeEdited) {
      const description = !payload?.thereAreChanged ? t(`Questionarie.manageUser.submit.modal.noChanges`) : t(`Questionarie.manageUser.submit.modal.description`)
      setModal({
        open: true,
        description: description,
        title: t(`Questionarie.manageUser.submit.modal.title`),
        ...!payload?.thereAreChanged ? {
          confirm: closeModal
        } :
          {
            cancel: closeModal,
            confirm: () => { confirmSubmitAgentPermissions(payload) }
          },
      })
    } else {
      submit(payload)
    }
  }


  const confirmSubmitAgentPermissions = async (payload: PermissionsData) => {
    setLoadings(prev => ({ ...prev, next: true }))
    if (!!payload?.thereAreChanged && id) {
      const response = await nextQuestionnarie(id, { response: true, payload })

      if (response == true) {
        closeModal()
      }
    }
    setLoadings(prev => ({ ...prev, next: false }))
  }

  // cambiar tipo de data
  const [data, setData] = useState<Record<string, any>>({})
  const getAreas = async () => {
    const items = await getArea()

    setItemsManage(prev => ({ ...prev, area: items }))
    setItems((prev) => ({
      ...prev,
      areas: items,
    }))
  }

  const getDepartment = async () => {
    const items = await getDepartments()
    setItems((prev) => ({
      ...prev,
      departments: items,
    }))
    setItemsManage(prev => ({ ...prev, departments: items }))
  }


  const defaultFoliosLimit = {
    maxFolios: false,
    maxFoliosAmount: 1
  }
  const defaultFoliosReject = {
    maxRejects: false,
    maxRejectsAmount: 1,
    enableSurvey: false,
    enableSurveyRequired: false,
    satisfactionQuestion: t('reportFolio.detail.approve.title')
  }

  const openEvidencesModal = ({ answerId, questId, global }: { questId?: string; answerId?: string; global?: boolean }) => {
    if (questId && !global) {
      const dataBody = evidencesModal.data?.[`${questId}-${answerId}`]
      setEvidencesModal(prev => ({
        ...prev,
        id: {
          answerId,
          questId
        },
        data: {
          ...prev.data,
          [`${questId}-${answerId}`]: {
            ...prev.data?.[`${questId}-${answerId}`] ?? {},
            data: dataBody?.data || { maxReject: '1' }
          }
        }
      }))
    } else if (!!global) {
      const dataBody = evidencesModal.data?.global

      setEvidencesModal(prev => ({
        ...prev,
        id: {
          global: "global"
        },
        data: {
          ...prev.data,
          global: {
            ...prev.data?.global ?? {},
            data: dataBody?.data || { maxReject: '1' }
          }
        }
      }))
    }
  }

  const closeEvidencesModal = ({ evidenceData, data, dataFolios, evidenceDataFolios }:
    {
      evidenceData: EvidenceData;
      data: Record<string, boolean | number | string>
      evidenceDataFolios?: EvidenceData;
      dataFolios?: Record<string, boolean | number | string>
    }) => {
    let description = {
      title: data.title,
      description: data.description
    }

    let limit = !!dataFolios?.maxFolios ? {
      maxFolios: dataFolios?.maxFolios ?? false,
      maxFoliosAmount: dataFolios?.maxFoliosAmount ?? 1,
    } : undefined

    delete data.title
    delete data.description
    delete dataFolios?.maxFolios
    delete dataFolios?.maxFoliosAmount

    const newData = {
      evidence: evidenceData,
      data,
      description,
      limitFolio: limit,
      rejectsFolio: dataFolios,
      evidenceFolio: evidenceDataFolios,
      activeFolios: !!dataFolios
    }
    const copyErrors = removeAllReference(errors ?? {})
    const questionId = evidencesModal?.id?.questId
    const answerId = evidencesModal?.id?.answerId
    const globalId = evidencesModal?.id?.global
    const answer = copyErrors?.sections?.[currentSection]?.question?.[questionId ?? ""]?.answer
    const actionPlanGlobal = copyErrors?.actionPlan

    let id = ''
    if (questionId || answerId) {
      id = `${questionId}-${answerId}`
    } else if (globalId) {
      id = globalId
    }
    const newErrors = getActionPlans({ data: newData, t })?.errors
    const answerOld = errors?.sections?.[currentSection]?.question?.[questionId ?? ""]?.answer

    if (actionPlanGlobal) {
      copyErrors.actionPlan = newErrors.count
    }
    if (answer?.actionPlan) {
      const oldCount = answerOld.actionPlan.count
      copyErrors.sections[currentSection].count = copyErrors.sections[currentSection].count - (oldCount - newErrors.count)
      copyErrors.sections[currentSection].question[questionId ?? ""].answer.actionPlan = newErrors
    }
    if (answer?.[answerId ?? ""]?.actionPlan) {
      const oldCount = answerOld[answerId ?? ""].actionPlan.count
      copyErrors.sections[currentSection].count = copyErrors.sections[currentSection].count - (oldCount - newErrors.count)
      copyErrors.sections[currentSection].question[questionId ?? ""].answer[answerId ?? ""].actionPlan = newErrors
    }

    setErrors(copyErrors)

    setEvidencesModal(prev => ({
      ...prev,
      id: undefined,
      data: {
        ...prev.data,
        [id]: newData
      }
    }))
  }

  const getGroupTypes = async () => {
    setLoadings(prev => ({ ...prev, main: true }))
    const response = await questionnaire.getQuestionType()
    let optionGroupItems: Item[] | undefined
    if (!response?.errors) {
      optionGroupItems = response?.map(item => ({
        label: item.description ?? "",
        value: item.id.toString() ?? "",
        id: item.id?.toString()
      }))
      setGroupTypeItems(optionGroupItems)
    }
  }

  const getOptionGroup = async () => {
    const response = await questionnaire.getOptionGroup()
    let optionGroupItems: Item[] | undefined
    if (!response?.errors) {
      optionGroupItems = response?.map(item => ({
        label: item.title ?? "",
        value: item.id.toString() ?? "",
        id: item.id?.toString(),
        options: item.options
      }))
      setGroupOptionsItems(optionGroupItems)
    }
    getData({ optionGroup: optionGroupItems })
  }
  const closeModal = () => {
    setModal(prev => ({
      ...prev,
      open: false,
      icon: undefined,
      cancelText: undefined,
      confirmText: undefined,
      cancel: undefined,
      confirm: undefined
    }))
  }

  const openEvidenceDrawe = (payload: { questId: string; answerId?: string; disabled?: boolean }) => {
    if (payload?.disabled) {
      const questId = payload?.questId
      const answerId = payload?.answerId
      setModal(prev => ({
        ...prev,
        open: true,
        title: t('general.confirmation'),
        description: payload?.answerId ? t('Questionarie.modal.activeByOptions') : t('Questionarie.modal.activeByQuestion'),
        cancel: () => { closeModal() },
        confirm: () => {
          let newCount = {}

          for (const key of Object.keys(evidencesModal.counts ?? {})) {
            if (key.includes(questId)) {
              newCount = {
                ...newCount,
                [key]: undefined
              }
            }
          }

          setEvidencesModal((prev => ({
            ...prev,
            evidences: {
              ...prev.evidences,
              ...!!answerId && {
                general: {
                  ...prev.evidences.general ?? {},
                  [`${questId}`]: undefined
                },
              },
              ...!answerId && {
                answers: {
                  ...prev.evidences.answers ?? {},
                  [`${questId}`]: undefined
                }
              }
            },

            counts: {
              ...prev.counts,
              ...newCount
            },
            evidencesQuestionGeneralActive: {
              ...prev.evidencesQuestionGeneralActive,
              [`${payload.questId}`]: undefined
            }
          })))
          evidencesQuestions.cleanAll()
          closeModal()
        }
      }))
    } else {
      setEvidencesModal((prev => ({
        ...prev,
        EvidencesId: { questId: payload.questId, answerId: payload?.answerId }
      })))
    }
  }

  const saveEvidences = (data: EvidenceData) => {
    const questId = evidencesModal.EvidencesId?.questId
    const answerId = evidencesModal.EvidencesId?.answerId

    evidencesQuestions.saveEvidence(data, (count) => {
      setEvidencesModal(prev => ({
        ...prev,
        counts: {
          ...prev.counts,
          [`${questId}${answerId ? `-${answerId}` : ""}`]: count
        },
      }))
    })

    setEvidencesModal((prev => ({
      ...prev,
      evidences: {
        ...prev.evidences,
        ...!answerId && {
          general: {
            ...prev.evidences.general ?? {},
            [`${questId}`]: data
          },
        },
        ...!!answerId && {
          answers: {
            ...prev.evidences.answers,
            [`${questId}`]: {
              ...prev.evidences.answers?.[`${questId}`] ?? {},
              [answerId]: data
            }
          }
        }
      },
      EvidencesId: undefined,
      evidencesQuestionGeneralActive: {
        ...prev.evidencesQuestionGeneralActive,
        [`${questId}`]: !answerId
      }
    })))
    evidencesQuestions.cleanAll()
  }
  const addData = ({ name, idQuestion, idAnswers, value }: { name: string; idQuestion?: string; idAnswers?: string; value: number | string | Item }) => {
    if (idQuestion) {
      if (idAnswers) {
        if (['value', 'switchOption'].includes(name)) {
          const isWitch = name == 'switchOption'
          const oldValue = data?.[currentSection]?.[idQuestion]?.answer?.[idAnswers]?.value
          const switchOption = !!(isWitch ? value : data?.[currentSection]?.[idQuestion]?.answer?.[idAnswers]?.switchOption)
          const valueAnswer = !isWitch ? value : data?.[currentSection]?.[idQuestion]?.answer?.[idAnswers]?.value

          if ((isWitch || valueAnswer >= '0' || valueAnswer == '')) {
            let newTotal = 0
            const localeValue = !!isDecimal(valueAnswer as string) ? (valueAnswer || '0') : '0'
            let arrayValues: [string, number | {
              value: number | string;
              active: boolean;
            }][] = Object.entries({
              ...totalPoint,
              [idAnswers]: {
                value: localeValue,
                active: switchOption
              }
            } ?? {})
            for (const [key, item] of arrayValues) {
              if (key !== 'total' && !!(item as PointsType).active) {
                newTotal = newTotal + parseFloat((item as PointsType).value.toString())
              }
            }
            if (newTotal <= parseFloat(dataHeader?.totalPoints?.toString() || "0")) {
              setTotalPoints(prev => ({
                ...prev,
                total: parseFloat(newTotal.toFixed(2)),
                [idAnswers]: {
                  value: parseFloat(localeValue as string || '0').toFixed(2),
                  active: switchOption
                }
              }))

              setData(prev => ({
                ...prev,
                [currentSection]: {
                  ...prev[currentSection] ?? {},
                  [idQuestion]: {
                    ...(prev?.[currentSection]?.[idQuestion] ?? {}) as any,
                    answer: {
                      ...(prev?.[currentSection]?.[idQuestion]?.answer ?? {}) as any,
                      [idAnswers]: {
                        ...(prev?.[currentSection]?.[idQuestion]?.answer?.[idAnswers] ?? {}) as any,
                        [name]: value
                      }
                    }
                  } as General,
                }
              }))
            } else {
              setModal(prev => ({
                ...prev,
                open: true,
                title: t('Questionarie.modal.score.title'),
                description: `${t('Questionarie.modal.score.description')} ${dataHeader?.totalPoints ?? 0}`,
                confirm: () => {
                  closeModal()
                  refsByKey[`${currentSection}-${idQuestion}-${idAnswers}`]?.setValues({ name, value: isWitch ? false : oldValue })
                }
              }))
            }
          }
        } else {
          setData(prev => ({
            ...prev,
            [currentSection]: {
              ...prev[currentSection] ?? {},
              [idQuestion]: {
                ...(prev?.[currentSection]?.[idQuestion] ?? {}) as any,
                answer: {
                  ...(prev?.[currentSection]?.[idQuestion]?.answer ?? {}) as any,
                  [idAnswers]: {
                    ...(prev?.[currentSection]?.[idQuestion]?.answer?.[idAnswers] ?? {}) as any,
                    [name]: value
                  }
                }
              } as General,
            }
          }))
        }
      } else {
        let answersData = {}
        if (name == 'questionText') {
          setQuestionList(prev => ({
            ...prev,
            [currentSection]: {
              ...prev?.[currentSection] ?? {},
              questions: prev?.[currentSection]?.questions?.map((item, index) => {
                if (item.value == idQuestion) {
                  return {
                    ...item,
                    label: value as string || `Pregunta #${index}`
                  }
                }
                return item
              }) ?? []
            }
          }))
        } else if (['questionOption', 'questionTemplate'].includes(name)) {
          const key = sectionIds.answers.toString()

          const model = models[currentSection]?.questions?.[idQuestion]?.answers
          const isOption = name == 'questionOption' ? (value as Item)?.value : data?.[currentSection]?.[idQuestion]?.questionOption?.value
          const isTemplate = name == 'questionTemplate' ? value : data?.[currentSection]?.[idQuestion]?.questionTemplate

          let answersModel = {}
          const dataAnswer = data?.[currentSection]?.[idQuestion]?.answer
          let answersModelArray = Object.entries(model ?? {})
          let countSection = 1

          if (isOption != 3 && answersModelArray?.length < 2) {
            answersModelArray.push([key, answers({ actionPlanActiveId: '1', answerId: '1', switchOptionId: '1', valueId: '1', actionId: '1' })])
            setSectionIds(prev => ({
              ...prev,
              answers: parseInt(prev.answers.toString()) + 1
            }))
          }
          const isAnswers = answersModelArray?.slice(0, 2)?.map(([key]) => ({
            description: typeof dataAnswer?.[key]?.answer == "string" ? dataAnswer?.[key]?.answer ?? "" : dataAnswer?.[key]?.answer?.text,
            iconUrl: typeof dataAnswer?.[key]?.answer != "string" ? dataAnswer?.[key]?.answer?.file : ""
          }))
          if (isTemplate || !!isAnswers?.length) {
            const options = (isTemplate as Item)?.options ?? isAnswers
            const isAffective = isOption == '4'

            for (const item of options) {
              const key = answersModelArray?.[countSection - 1]?.[0]
              const newKey = !!key ? key : countSection + sectionIds.answers
              let newValue: any = ""
              if (!!isAffective) {
                newValue = { text: item.description, file: item.iconUrl }
              } else {
                newValue = item?.description ?? ""
              }
              if (key) {
                refsByKey[`${currentSection}-${idQuestion}-${key}`]?.setValues({ name: "answer", value: newValue })
              }
              refsByKey[`${currentSection}-${idQuestion}-${key}`]?.cleanError('answer')
              answersData = {
                ...answersData ?? {},
                [newKey]: {
                  ...data?.[currentSection]?.[idQuestion]?.answer?.[newKey] ?? {},
                  answer: newValue,
                  value: dataAnswer?.[key]?.value ?? '0'
                }
              }
              answersModel = {
                ...answersModel,
                [newKey]: answers({ answerDisable: !!isTemplate, removeActionPlan: dataHeader?.option !== '0' })
              }

              countSection += 1
            }

            setSectionIds(prev => ({
              ...prev,
              answers: parseInt(prev.answers.toString()) + parseInt(countSection.toString())
            }))
          }

          setModels(prev => ({
            ...prev, [
              currentSection]: {
              ...prev[currentSection],
              questions: {
                ...prev[currentSection].questions,
                [idQuestion]: {
                  ...prev[currentSection].questions?.[idQuestion] ?? {},
                  answers: answersModel
                }
              }
            }
          }))
          setData(prev => ({
            ...prev,
            [currentSection]: {
              ...prev[currentSection] ?? {},
              [idQuestion]: {
                ...(prev?.[currentSection]?.[idQuestion] ?? {}) as any,
                answer: answersData
              } as General,
            }
          }))
        }
        setData(prev => ({
          ...prev,
          [currentSection]: {
            ...prev[currentSection] ?? {},
            [idQuestion]: {
              ...(prev?.[currentSection]?.[idQuestion] ?? {}) as any,
              [name]: value,
            } as General,
          }
        }))
      }
    } else {
      if (name == 'sectionName') {
        setTotalSections(prev => prev.map(item => {
          if (item.id == currentSection) {
            return {
              ...item,
              name: value as string
            }
          }

          return item
        }))
      }
      setData(prev => ({
        ...prev,
        [currentSection]: {
          ...prev[currentSection] ?? {},
          [name]: value,
        }
      }))
    }
    // setErrors(copyErrors)
  }
  const questiononEvidencesToEvidenceData = (evidence: QuestionEvidence[] = []): EvidenceData => {
    let newEvidences: EvidenceData = {}
    for (const item of evidence) {
      if (item?.paramsConfiguration) {
        switch (item.evidenceTypeId) {
          case EvidencesTypeValueEnum.IMAGE:
          case EvidencesTypeValueEnum.CAMERA:
          case EvidencesTypeValueEnum.DOCUMENT:
          case EvidencesTypeValueEnum.VIDEO_GALLERY:
            newEvidences = {
              ...newEvidences,
              [transformEvidencesNumber[item.evidenceTypeId as EvidencesTypeValueEnum]]: {
                "active": true,
                "required": item.required,
                ...!!item?.paramsConfiguration && {
                  "maxSize": {
                    "label": `${item?.paramsConfiguration?.sizes} Mb`,
                    "value": item?.paramsConfiguration?.sizes
                  },
                  "maxAmount": item?.paramsConfiguration?.amounts
                }
              }
            }

            for (const itemTypes of (item?.paramsConfiguration?.types ?? [])) {
              newEvidences[transformEvidencesNumber[item.evidenceTypeId as EvidencesTypeValueEnum]] = {
                ...newEvidences[transformEvidencesNumber[item.evidenceTypeId as EvidencesTypeValueEnum]],
                [itemTypes]: true
              }
            }
            break;
          case EvidencesTypeValueEnum.VIDEO:
            newEvidences = {
              ...newEvidences,
              [transformEvidencesNumber[item.evidenceTypeId as EvidencesTypeValueEnum]]: {
                "active": true,
                "required": item.required,
                "maxSize": {
                  "label": `${item?.paramsConfiguration?.sizes} Mb`,
                  "value": item?.paramsConfiguration?.sizes
                },
                "maxAmount": item?.paramsConfiguration?.amounts
              }
            }
            break;
          case EvidencesTypeValueEnum.SIGN:
            newEvidences = {
              ...newEvidences,
              [transformEvidencesNumber[item.evidenceTypeId as EvidencesTypeValueEnum]]: {
                "active": true,
                "required": item.required,
                "maxAmount": item?.paramsConfiguration?.amounts
              }
            }
            break;
          case EvidencesTypeValueEnum.COMMENT:
            newEvidences = {
              ...newEvidences,
              [transformEvidencesNumber[item.evidenceTypeId as EvidencesTypeValueEnum]]: {
                "active": true,
                "required": item.required,
                "maxAmount": item?.paramsConfiguration?.amounts,
                maxCharacters: item?.paramsConfiguration?.sizes
              }
            }
            break;

        }
      }
    }

    return newEvidences
  }

  const goBack = () => {
    setModal(prev => ({
      ...prev,
      open: true,
      title: t('general.exit'),
      description: t('general.lostChange'),
      cancel: closeModal,
      confirm() {
        navigate('/dashboard/questionnarie')
        localStorage.removeItem(QuestionnaireLocale.QUESTIONNAIRE)
      },
    }))
  }

  const configurationActionPLan = ({ answerId, actionPlan, questionId, questionOption }: { actionPlan: ActionPlan; questionId: number; answerId: number; questionOption: number }) => {
    const id = questionOption == 2 ? `${questionId}-multiple` : `${questionId}-${answerId}`
    let body: Record<string, {
      evidence: EvidenceData;
      data: Record<string, string | number | boolean>;
      description: Record<string, string | number | boolean>
      evidenceFolio?: EvidenceData;
      limitFolio?: Record<string, string | number | boolean>
      rejectsFolio?: Record<string, string | number | boolean>
      activeFolios?: boolean
    }> = {
      [id]: {
        evidence: questiononEvidencesToEvidenceData(actionPlan?.actionPlanEvidences),
        data: {
          maxReject: actionPlan?.maxRejections,
          sendEmail: actionPlan?.sendEmail
        },
        description: {
          "title": actionPlan?.templateName,
          "description": actionPlan?.description
        },
        ...!!actionPlan?.configReportFolio && {
          evidenceFolio: questiononEvidencesToEvidenceData(actionPlan?.configReportFolio.configActionPlanReportFolioEvidenceTypes),
          limitFolio: {
            "maxFolios": !!actionPlan?.configReportFolio.max,
            "maxFoliosAmount": actionPlan?.configReportFolio.max
          },
          rejectsFolio: {
            "maxRejects": !!actionPlan?.configReportFolio.maxRejects,
            "maxRejectsAmount": actionPlan?.configReportFolio.maxRejects,
            "enableSurvey": !!actionPlan?.configReportFolio.ratingQuestion,
            "enableSurveyRequired": actionPlan?.configReportFolio.isRatingQuestionRequired,
            "satisfactionQuestion": actionPlan?.configReportFolio.ratingQuestion
          },
          activeFolios: !!actionPlan?.configReportFolio,
        },
      }
    }

    for (const field of fields) {
      const actionPlanLocale = actionPlan?.actionPlanItems?.find((itemPlan: any) => itemPlan.question === t?.(`general.questions.${field}`))
      body = {
        ...body,
        [id]: {
          ...body?.[id] ?? {},
          data: {
            ...body?.[id].data ?? {},
            [`${field}-check`]: !!actionPlanLocale,
            [`${field}-switch`]: !!actionPlanLocale?.required,
          }
        }
      }
    }

    return body
  }

  const getData = async (externalData?: { optionGroup?: Item[] }) => {
    setLoadings(prev => ({ ...prev, main: true }))
    if (!id) {
      setDataHeader(prev => ({
        ...prev,
        option: state?.option ?? '0',
      }))
      const key = sectionIds.section.toString()
      setModels(prev => ({ ...prev, [key]: sections }))
      setCurrentSection(key)
      setSectionsLength(1)
      setTotalSections([{ id: key }])
      setQuestionList(prev => ({
        ...prev,
        [key]: {
          questions: [{ label: `Pregunta #1`, value: '1' }],
        }
      }))
      setSectionIds(prev => ({
        ...prev,
        section: parseInt(prev.section.toString()) + 1,
        question: parseInt(prev.question.toString()) + 1,
        answers: parseInt(prev.answers.toString()) + 2
      }))
    } else {
      const response = await questionnaire.getQuestionarieById(id)
      setQuestionnaiereCanBeEdited(!!response?.isEditable)
      const optionHeader = response.actionPlanByItem ? "0" : "1"
      setLocalStorage({
        body: {
          "quest_name": response.name,
          "description": response.description,
          "instruction": response.instruction,
          "areas": {
            "id": 62,
            "label": "Area Martes",
            "value": 62
          },
          "departments": {
            "id": 15,
            "label": "Departamento financiero",
            "value": 15,
            "areaId": 62
          },
          "totalPoints": response.totalPoints,
          "option": response.actionPlanByItem ? "0" : "1",
        }, name: QuestionnaireLocale.QUESTIONNAIRE
      })
      let newEvidencesData: EvidenceFormat = { counts: {}, data: {}, evidences: {}, evidencesQuestionGeneralActive: {} }
      let reportFolioConfig: ReportFolioData = { id: null }
      let sectionId = 1, questionId = 1, answerId = 1;
      let totalPoints = 0
      let objectAnswerPoints: Record<string, PointsType> = {}
      let model: Record<string, Sections> = {}
      let newData: Record<string, any> = {}
      let totalList: {
        id: string;
        name?: string | undefined;
        errors?: number | undefined;
      }[] = []
      let questionList: QuestionList = {}
      const questionnaireSections = response.questionnaireSections
      const actionPlans = response.questionaireActionPlan
      if (!response.actionPlanByItem) {
        newEvidencesData = {
          ...newEvidencesData,
          data: {
            global: configurationActionPLan({ actionPlan: response?.questionaireActionPlan?.[0]?.actionPlan, answerId: 1, questionId: 1, questionOption: 1 })['1-1']
          }
        }
      }
      for (const index in questionnaireSections) {
        const item = questionnaireSections[index]
        const actionPLanOfTHeQuestion = actionPlans?.find(itemAP => itemAP.questionnaireId == item.questionnaireMainId)
        totalList.push({ id: sectionId.toString(), name: item.title })
        model = {
          ...model,
          [sectionId]: {
            ...sections,
            questions: {}
          }
        }

        newData = {
          ...newData,
          [sectionId]: {
            sectionName: item?.title,
            sectionDescription: item?.description, PageTransitionEvent
          }
        }
        for (const indexQ in item.steps) {
          const itemQ = item.steps[indexQ]

          questionId = itemQ.id
          questionList = {
            ...questionList,
            [sectionId]: {
              questions: [...questionList?.[sectionId]?.questions ?? [], { label: itemQ.question.title, value: questionId }],
            }
          }
          const questionOption = itemQ.question.typeQuestionId
          newData[sectionId] = {
            ...newData[sectionId],
            [questionId]: {
              helpText: itemQ.question.textHelp,
              questionText: itemQ.question.title,
              answerText: itemQ.question.description,
              questionOption: { id: questionOption, value: questionOption, label: itemQ.question.typeQuestion.description },
              questionTemplate: externalData?.optionGroup?.find(item => item.value == itemQ.question.optionGroupId)
            }
          }

          model[sectionId] = {
            ...model[sectionId],
            questions: {
              ...model[sectionId]?.questions ?? {},
              [questionId]: {
                ...questions({ first: parseInt(sectionIds.answers.toString()) + 1, second: parseInt(sectionIds.answers.toString()) + 2 }),
                answers: {}
              }
            }
          }

          if (itemQ.question?.questionEvidence?.length) {
            newEvidencesData = {
              ...newEvidencesData,
              counts: {
                ...newEvidencesData.counts,
                [questionId]: itemQ.question?.questionEvidence?.length
              },
              evidencesQuestionGeneralActive: {
                ...newEvidencesData.evidencesQuestionGeneralActive,
                [questionId]: true
              },
              evidences: {
                ...newEvidencesData.evidences,
                general: {
                  [questionId]: questiononEvidencesToEvidenceData(itemQ.question?.questionEvidence)
                }
              }
            }
          }

          for (const indexA in itemQ.question.questionOptions) {
            const itemA = itemQ.question.questionOptions[indexA]
            if (itemA?.questionOptionEvidence?.length) {
              newEvidencesData = {
                ...newEvidencesData,
                counts: {
                  ...newEvidencesData.counts,
                  [`${questionId}-${questionOption == 2 ? 'multiple' : answerId}`]: itemA?.questionOptionEvidence.length
                },
                evidencesQuestionGeneralActive: {
                  ...newEvidencesData.evidencesQuestionGeneralActive,
                  [questionId]: false
                },
                evidences: {
                  ...newEvidencesData.evidences,
                  answers: {
                    ...newEvidencesData.evidences.answers,
                    [questionId]: {
                      ...newEvidencesData?.evidences?.answers?.[questionId] ?? {},
                      [questionOption == 2 ? 'multiple' : answerId]: questiononEvidencesToEvidenceData(itemA?.questionOptionEvidence)
                    }
                  }
                }
              }
            }

            if (!!itemA.actionPlan) {
              newEvidencesData = {
                ...newEvidencesData,
                data: {
                  ...newEvidencesData?.data ?? {},
                  ...configurationActionPLan({ actionPlan: itemA.actionPlan, answerId, questionId, questionOption })
                },
              }
            }

            if (!!itemA.questionOptionsConfigReportFolio) {
              reportFolioConfig = {
                ...reportFolioConfig,
                data: {
                  ...reportFolioConfig?.data ?? {},
                  [`${questionId}-${questionOption == 2 ? 'multiple' : answerId}`]: {
                    evidencesData: {
                      evidencesData: questiononEvidencesToEvidenceData(itemA.questionOptionsConfigReportFolio?.configActionPlanReportFolioEvidenceTypes),
                      anchorEl: null,
                    },
                    default: {
                      limitFolios: {
                        "maxFolios": !!itemA.questionOptionsConfigReportFolio?.max,
                        "maxFoliosAmount": itemA.questionOptionsConfigReportFolio?.max
                      },
                      rejectsFolio: {
                        "maxRejects": !!itemA.questionOptionsConfigReportFolio?.maxRejects,
                        "maxRejectsAmount": itemA.questionOptionsConfigReportFolio?.maxRejects,
                        "enableSurvey": !!itemA.questionOptionsConfigReportFolio?.ratingQuestion,
                        "enableSurveyRequired": itemA.questionOptionsConfigReportFolio?.isRatingQuestionRequired,
                        "satisfactionQuestion": itemA.questionOptionsConfigReportFolio?.ratingQuestion
                      },
                    }
                  }
                }
              }
            }
            model[sectionId].questions[questionId] = {
              ...model[sectionId].questions[questionId],
              answers: {
                ...model?.[sectionId]?.questions?.[questionId]?.answers ?? {},
                [answerId]: answers({ removeActionPlan: optionHeader != '0', actionPlanActiveId: '1', answerId: '1', switchOptionId: '1', valueId: '1', actionId: '1', answerDisable: !!itemQ.question?.optionGroupId })
              }
            }
            let action: Item | null = null
            if (itemA.stepId || itemA.stepId == 0) {
              const nextQuestion = item.steps.find(step => step.position == itemA.stepId)

              action = {
                "label": nextQuestion?.question?.title ?? "",
                "value": nextQuestion?.id
              }
            }
            newData[sectionId][questionId] = {
              ...newData[sectionId][questionId],
              answer: {
                ...newData[sectionId][questionId].answer ?? {},
                [answerId]: {
                  "answer": itemQ.question?.typeQuestionId == 4 ? { text: itemA.option?.description, file: itemA.option?.iconUrl } : itemA.option?.description,
                  "value": itemA.score,
                  ...optionHeader == '0' && { "actionPlanActive": !!itemA.actionPlan || !!actionPLanOfTHeQuestion?.actionPlan },
                  "switchOption": !!itemA.correctOption,
                  reportFolio: !!itemA.questionOptionsConfigReportFolio,
                  ...!!action && { action: action }
                }
              }
            }

            if (!!itemA.correctOption) {
              totalPoints = totalPoints + parseFloat(itemA.score)
              objectAnswerPoints = {
                ...objectAnswerPoints,
                [answerId]: {
                  value: parseFloat(itemA.score as string || '0').toFixed(2),
                  active: true
                }

              }
            }
            answerId = answerId + 1;
          }

        }
        sectionId = sectionId + 1;
      }
      setEvidencesModal(prev => ({
        ...prev,
        ...newEvidencesData
      }))
      setReportFolioByAnswer(reportFolioConfig)
      const header = {
        "quest_name": response.name ?? "",
        "description": response.description ?? "",
        "instruction": response.instruction ?? "",
        ...!!response.areaId && {
          "areas": {
            label: response.area?.name ?? "",
            value: response.areaId,
            id: response.areaId
          }
        },
        ...!!response.departmentId && {
          "departments": {
            label: response.department?.name ?? "",
            value: response.departmentId,
            id: response.departmentId
          }
        },
        "totalPoints": response.totalPoints,
        "option": response.actionPlanByItem ? '0' : '1'
      }

      setDataHeader(() => ({ ...header as DynamicFormRefValues }))
      setLocalStorage({ body: header, name: QuestionnaireLocale.QUESTIONNAIRE })
      setTotalPoints(prev => ({ ...prev, total: totalPoints, ...objectAnswerPoints }))
      setData(newData)
      setCurrentSection("1")
      setTotalSections(totalList)
      setSectionsLength(questionnaireSections?.length)
      setQuestionList(questionList)
      setModels(model)
      setSectionIds(prev => ({
        ...prev,
        section: sectionId,
        question: questionId + 1,
        answers: answerId
      }))
    }
    setLoadings(prev => ({ ...prev, main: false }))
  }
  const changeSection = ({ id }: { id: string }) => {
    setCurrentSection(id)
  }

  const addSections = () => {
    const key = sectionIds.section.toString()
    const keyQ = parseInt(sectionIds.question.toString()) + 1
    setModels(prev => ({
      ...prev, [key]: {
        ...sections,
        questions: {
          [sectionIds.question + 1]: questions({ first: parseInt(sectionIds.answers.toString()) + 1, second: parseInt(sectionIds.answers.toString()) + 2 })
        }
      }
    }))
    setQuestionList(prev => ({
      ...prev,
      [key]: {
        ...prev?.[key] ?? {},
        questions: [...prev?.[key]?.questions ?? [], { label: `Pregunta #${(prev?.[key]?.questions?.length ?? 0) + 1}`, value: keyQ }],
      }
    }))
    setSectionsLength(totalSections?.length + 1)
    setTotalSections(prev => [...prev, { id: key }])
    setSectionIds(prev => ({
      ...prev,
      section: parseInt(prev.section.toString()) + 1,
      question: keyQ + 1,
      answers: parseInt(prev.answers.toString()) + 2
    }))
  }

  const addQuestion = () => {
    const key = parseInt(sectionIds.question.toString()) + 1

    setQuestionList(prev => ({
      ...prev,
      [currentSection]: {
        ...prev?.[currentSection] ?? {},
        questions: [...prev?.[currentSection]?.questions ?? [], { label: `Pregunta #${prev?.[currentSection]?.questions?.length + 1}`, value: key }],
      }
    }))

    setModels(prev => ({
      ...prev, [
        currentSection]: {
        ...prev[currentSection],
        questions: {
          ...prev?.[currentSection]?.questions ?? {},
          [key]: questions({ first: parseInt(sectionIds.answers.toString()) + 1, second: parseInt(sectionIds.answers.toString()) + 2 })
        }
      }
    }))
    setSectionIds(prev => ({
      ...prev,
      question: key + 1,
      answers: parseInt(prev.answers.toString()) + 2
    }))
  }

  const addAnswer = ({ idQuestion }: { idQuestion: string }) => {
    const key = sectionIds.answers.toString()

    setModels(prev => ({
      ...prev, [
        currentSection]: {
        ...prev[currentSection],
        questions: {
          ...prev[currentSection].questions,
          [idQuestion]: {
            ...prev[currentSection].questions?.[idQuestion] ?? {},
            answers: {
              ...prev[currentSection].questions?.[idQuestion].answers ?? {},
              [key]: answers({ actionPlanActiveId: '1', answerId: '1', switchOptionId: '1', valueId: '1', actionId: '1' })
            }
          }
        }
      }
    }))

    setSectionIds(prev => ({
      ...prev,
      answers: parseInt(prev.answers.toString()) + 1
    }))
  }

  const removeAnswerQuestionOrSection = ({ idSection, idQuestion, idAnswer }: { idSection?: string; idQuestion?: string; idAnswer?: string }) => {
    const modelCopy = { ...models }
    const dataCopy = { ...data }
    if (!!idQuestion && !!idAnswer) {
      const totalPointItem = (totalPoint[idAnswer] as PointsType)
      delete modelCopy?.[idSection ?? currentSection]?.questions?.[idQuestion]?.answers?.[idAnswer]
      delete dataCopy?.[idSection ?? currentSection]?.[idQuestion]?.answer?.[idAnswer]

      if (totalPointItem?.active) {
        const point = parseFloat((totalPoint[idAnswer] as PointsType)?.value.toString())
        delete totalPoint?.[idAnswer]
        totalPoint.total -= point
      }
      setData(dataCopy)
      setTotalPoints(totalPoint)
      setModels(modelCopy)
    } else if (!!idQuestion) {
      // DELETE QUESTION
      setModal(prev => ({
        ...prev,
        open: true,
        title: t('Questionarie.modal.question.title'),
        description: t('Questionarie.modal.question.description'),
        cancel: closeModal,
        confirm: () => {
          setLoadings(prev => ({ ...prev, main: true }))
          let pointsToRest = 0
          for (const [key, value] of Object.entries(dataCopy[idSection ?? currentSection] ?? {})) {
            for (const [keyA, valueA] of Object.entries(((value as any)?.answer ?? {}))) {
              const totalPointItem = (totalPoint[keyA] as PointsType)
              const point = parseFloat(totalPointItem?.value.toString())
              if (key == idQuestion) {
                if (totalPointItem?.active) {
                  pointsToRest += point
                  delete totalPoint?.[keyA]
                }
              }
              if ((valueA as any)?.action?.value == idQuestion) {
                if (dataCopy?.[idSection ?? currentSection]?.[key]?.answer?.[keyA]?.action) {
                  refsByKey[`${currentSection}-${key}-${keyA}`]?.setValues({ name: 'action', value: null })
                  delete dataCopy[idSection ?? currentSection][key].answer[keyA].action
                }
              }
            }
          }

          let newQuestionList: Item[] = []
          let count = 1
          totalPoint.total -= pointsToRest
          for (const item of questionsList?.[idSection ?? currentSection]?.questions) {
            if (item.value != idQuestion) {
              newQuestionList.push({
                ...item,
                label: item?.label ?? `Pregunta #${count}`
              })
              count += 1
            }
          }

          setQuestionList(prev => ({
            ...prev,
            [idSection ?? currentSection]: {
              ...prev?.[idSection ?? currentSection] ?? {},
              questions: newQuestionList,
            }
          }))
          delete modelCopy?.[idSection ?? currentSection]?.questions?.[idQuestion]
          delete dataCopy?.[idSection ?? currentSection]?.[idQuestion]
          setTotalPoints(totalPoint)
          setData(dataCopy)
          setModels(modelCopy)
          setLoadings(prev => ({ ...prev, main: false }))

          closeModal()
        }
      }))
    } else {
      // DELETE SECTTION
      setModal(prev => ({
        ...prev,
        open: true,
        title: t('Questionarie.modal.section.title'),
        description: t('Questionarie.modal.section.description'),
        cancel: closeModal,
        confirm: () => {
          closeModal()
          if (idSection == currentSection) {
            const totalSectionsCurrent = totalSections.findIndex(item => item.id == idSection)
            let current = '0'
            if (totalSectionsCurrent == 0) {
              current = totalSections[1].id
            } else if (totalSectionsCurrent == totalSections?.length - 1) {
              current = totalSections[totalSectionsCurrent - 1].id
            } else {
              current = totalSections[totalSectionsCurrent + 1].id
            }
            setCurrentSection(current)
          }

          let pointsToRest = 0
          if (idSection) {
            for (const [key, value] of Object.entries(dataCopy[idSection] ?? {})) {
              for (const [keyA] of Object.entries(((value as any)?.answer ?? {}))) {
                const totalPointItem = (totalPoint[keyA] as PointsType)
                const point = parseFloat(totalPointItem?.value.toString())
                if (totalPointItem?.active) {
                  pointsToRest += point
                }
                delete totalPoint?.[keyA]
                if (dataCopy?.[idSection]?.[key]?.answer?.[keyA]?.action) {
                  refsByKey[`${currentSection}-${key}-${keyA}`]?.setValues({ name: 'action', value: null })
                  delete dataCopy[idSection][key].answer[keyA].action
                }
              }
            }
          }
          totalPoint.total -= pointsToRest

          setTotalSections(prev => prev.filter(item => item.id != idSection))
          setSectionsLength(prev => prev - 1)

          delete modelCopy?.[idSection ?? currentSection]
          delete dataCopy?.[idSection ?? currentSection]
          setData(dataCopy)
          setTotalPoints(totalPoint)
          setModels(modelCopy)
        }
      }))
    }
  }

  const handleHeaderData = ({ name, value, blur }: { name: string; value: string; blur?: boolean }) => {
    if (name == 'totalPoints' && parseFloat(value) < parseFloat((totalPoint?.total.toString() ?? '0'))) {
      if (blur) {
        setModal(prev => ({
          ...prev,
          open: true,
          title: t('Questionarie.modal.score.notMinus'),
          description: ``,
          confirm: () => {
            closeModal()
            refsByKey[`totalPoints`]?.setValues({ name, value: totalPoint?.total.toString() ?? '1' })
          }
        }))
      }
    } else {
      let valuesLocal = value
      if (name == 'totalPoints') {
        if (value == '' && !!blur) {
          valuesLocal = totalPoint?.total.toString() ?? '1'
        }
        if (parseFloat(value) > 100) {
          valuesLocal = (dataHeader?.totalPoints ?? '0') as string
        }

        refsByKey[`totalPoints`]?.reset({ totalPoints: valuesLocal })
      }
      setDataHeader(prev => ({
        ...prev,
        [name]: valuesLocal
      }))
    }
  }

  const handleOrderMode = (value: TypeMode) => {
    setMode(value)
  }

  const getAllUnits = async () => {
    if (!itemFilter.stores) {
      const { items } = await getStore()
      setItemsManage(prev => ({ ...prev, stores: items }))
      itemIsLoader({ name: 'stores', value: true })
    }
  }

  const getAllDivisions = async () => {
    if (!itemFilter.divisions) {
      const { items } = await getDivisions()
      setItemsManage(prev => ({ ...prev, divisions: items }))
      itemIsLoader({ name: 'divisions', value: true })
    }
  }
  const getAllUserTypes = async () => {
    if (!itemFilter.userType) {
      const { items } = await getUserTypes()
      setItemsManage(prev => ({ ...prev, userType: items }))
      setInput(prev => ({
        ...prev,
        userType: {
          ...prev.userType,
          items: items ?? [],
        },
      }))
      itemIsLoader({ name: 'userType', value: true })
    }

  }

  const getAllRoles = async () => {
    const { items } = await getRoles({ skip: [Roles.ADMIN] })

    setInput(prev => ({
      ...prev,
      role: {
        ...prev.role,
        items: items ?? [],
      },
    }))
    setItemsManage(prev => ({ ...prev, role: items }))
  }

  const getItems = () => {
    getAllUnits()
    getAllUserTypes()
    getAllRoles()
    getAllDivisions()
  }
  const goToLinkingUserToQuestionnaires = () => {
    const validations = validateData({ data, models, currentSection, evidencesModal, order, refsByKey, t, totalPoint, dataHeader, reportFolio: reportFolioByAnswer?.data })
    if (validations.existSomeError) {
      setModal({
        open: true,
        icon: "warning",
        description: t(`Questionarie.manageUser.submit.modal.settingPermissions`),
        title: '',
        confirmText: t('general.cancel'),
        confirm: () => {
          closeModal()
        }
      })
    } else {
      getItems()
      setMode('permissions')
    }
  }



  const deleteItemTable = async () => {
    if (id) {
      setModal(prev => ({
        ...prev,
        open: true,
        title: t('Questionarie.modal.delete.title'),
        description: t('Questionarie.modal.delete.description'),
        cancel: () => { closeModal() },
        confirm: () => { deleteConfirmation({ id }) }
      }))
    }
  }

  const deleteConfirmation = async ({ id }: { id: string }) => {
    setLoadings(prev => ({ ...prev, next: true }))
    const response = await questionnaire.deleteQuestionnarie(id)

    if (!response?.errors) {
      closeModal()
      setSnackbar(prev => ({
        ...prev,
        open: true,
        type: Types.SUCCESS,
        message: t(`Questionarie.modal.delete.success.title`)
      }))
      navigate('/dashboard/questionnarie/main')
    } else {
      setSnackbar(prev => ({
        ...prev,
        open: true,
        type: Types.ERROR,
        message: t(`Questionarie.modal.delete.error.title`)
      }))
    }
    setLoadings(prev => ({ ...prev, next: true }))
  }

  const submit = async (payload?: PermissionsData) => {
    handleSubmit({
      restartCurrentSection: () => {
        const listSection = totalSections?.sort((a, b) => (order.sections[a.id] ?? 0) - (order.sections[b.id] ?? 0))
        setCurrentSection(listSection[0].id.toString())
      },
      setData,
      closeModal,
      reportFolioData: reportFolioByAnswer.data,
      reportFolio: reportFolioByAnswer.data,
      currentSection,
      data,
      evidencesModal,
      id,
      models,
      navigate,
      nextQuestionnarie,
      order,
      payload,
      permissionsData,
      refsByKey,
      setErrors,
      setLoadings,
      setModal,
      setSubmitDisabled,
      t,
      totalPoint,
      dataHeader,
    })
  }

  const openReportFolios = (payload: {
    questId?: string;
    answerId?: string
  }) => {
    const id = `${payload.questId}-${payload.answerId}`
    openFolios({ id, setReportFolioByAnswer, t })
  }

  const getReportFolioByAnswer = (payload: {
    evidence: EvidenceDataHook;
    body: Record<string, string | number | boolean>;
  }) => {
    manageData({
      ...payload,
      setReportFolioByAnswer,
      id: reportFolioByAnswer.id
    })
  }

  const closeReportFolioByAnswer = () => {
    setReportFolioByAnswer(prev => ({ ...prev, id: null }))
  }
  const nextQuestionnarie = async (id: string, data?: { response?: boolean; payload: PermissionsData | undefined }) => {
    let news: ObjectGeneric[] = []
    let deletes: string[] = []

    const modelPermissions = ({ key, keyCheck, value }: { key: string; keyCheck: string; value: { [x: string]: boolean } }) => {
      return {
        access: value?.[`access${keyCheck}`] ? 1 : 0,
        report: value?.[`report${keyCheck}`] ? 1 : 0,
        emailNotification: value?.[`emailNotification${keyCheck}`] ? 1 : 0,
        userId: key,
      }
    }
    const ckeckUsers = data?.payload?.checksUser
    setLoadings(prev => ({ ...prev, permission: true }))

    Object.entries(data?.payload?.users ?? {}).forEach(([key, item]) => {
      if (!!item && Object.values(ckeckUsers?.[key] ?? {})?.find((itemPermissions) => !!itemPermissions) && (data?.payload?.usersOld[key] === undefined || !!data?.payload?.usersOld[key])) {
        news.push({
          id: key,
          questionnaireMain: modelPermissions({ keyCheck: 'Q', key, value: ckeckUsers?.[key] }),
          reportFolio: modelPermissions({ keyCheck: 'F', key, value: ckeckUsers?.[key] })
        })
      }
    })

    Object.entries(data?.payload?.usersOld ?? {}).forEach(([key, item]) => {
      if (!item) {
        deletes.push(key)
      }
    })

    const permissionsFormated = {
      modelId: id,
      news,
      deletes
    }

    const response = await questionnaire.createPermissions(permissionsFormated)
    setLoadings(prev => ({ ...prev, permission: false }))
    if (!response.errors) {
      if (!!data?.response) {
        const typeResponse = !['SUCCESS', 'SUCCESS_REMOVE_PERMISSIONS'].includes(response.code)

        setSnackbar({
          open: true,
          message: t(`Questionarie.manageUser.submit.snackbar.update.${typeResponse ? 'error' : 'success'}`),
          type: typeResponse ? Types.ERROR : Types.SUCCESS,
        })
      }
      setPermissionsData(undefined)
      return true
    } else {
      return response
    }
  }
  useEffect(() => {
    if (errors?.sections) {
      const validations = validateData({ data, models, currentSection, evidencesModal, order, refsByKey, t, totalPoint, dataHeader, reportFolio: reportFolioByAnswer.data })
      setErrors(validations.errors)
    }
  }, [data, reportFolioByAnswer.data])
  const closeSnackbar = () => { setSnackbar(prev => ({ ...prev, open: false })) }
  useEffect(() => {
    getAreas()
    getDepartment()
    getGroupTypes()
    getOptionGroup()
  }, [])

  useEffect(() => {
    if (errors?.sections && mode == 'edit') {
      validateErrors({ currentSection, models, refsByKey })
    }
  }, [currentSection, mode])
  return {
    items,
    totalSections,
    currentSection,
    sectionsLength,
    models,
    data,
    questionsList,
    groupTypeItems,
    groupOptionsItems,
    evidencesModal,
    defaultFoliosLimit,
    defaultFoliosReject,
    dataHeader,
    evidencesQuestions,
    modal,
    totalPoint,
    refsByKey,
    mode,
    order,
    ref,
    input,
    id,
    permissionsData,
    loadings,
    quetionnaireCanBeEdited,
    snackbar,
    errors,
    itemsManage,
    reportFolioByAnswer,
    closeReportFolioByAnswer,
    getReportFolioByAnswer,
    openReportFolios,
    setOrder,
    deleteItemTable,
    goBack,
    closeSnackbar,
    submit,
    updatePermissions,
    disabledPermissionUsers,
    handleOpenModalPermissions,
    setPermissionsData,
    goToLinkingUserToQuestionnaires,
    handleOrderMode,
    closeModal,
    openEvidenceDrawe,
    saveEvidences,
    handleHeaderData,
    openEvidencesModal,
    closeEvidencesModal,
    setRef,
    addData,
    addSections,
    addAnswer,
    addQuestion,
    changeSection,
    removeAnswerQuestionOrSection
  }
}

export { useBody }
