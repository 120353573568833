export enum RequestErrors {
    ERR_NOT_FOUND = "ERR_NOT_FOUND",
    ERR_UPDATING = "ERR_UPDATING",
    ERR_CANNOT_DELETE_ASSIGNED = "ERR_CANNOT_DELETE_ASSIGNED",
    ERR_ALREADY_EXISTS = "ERR_ALREADY_EXISTS",
    ERR_EMAIL = "ERR_EMAIL",
    ERR_NOT_USER_MANAGES = "ERR_NOT_USER_MANAGES",
    ERR_VALIDATION_CODE = "ERR_VALIDATION_CODE",
    ERR_NOT_USER_ASSINGS = "ERR_NOT_USER_ASSINGS",
    ERR_CANNOT_CREATE_VISITS = "ERR_CANNOT_CREATE_VISITS",
    ERR_OPTION_GROUP_IN_USE = "ERR_OPTION_GROUP_IN_USE",
    REQUIRE_VALIDATE = "REQUIRE_VALIDATE",
    ERR_NOT_FINALIZED = "ERR_NOT_FINALIZED",
    ERR_USER_WITHOUT_PERMISSIONS = "ERR_USER_WITHOUT_PERMISSIONS",
    ERR_NO_ACTIVE_USER = "ERR_NO_ACTIVE_USER",
    ERR_SOME_QUESTIONNAIRE_IS_STARTED = "ERR_SOME_QUESTIONNAIRE_IS_STARTED",
    ERR_DELETE_QUESTIONNAIRE_MAIN = "ERR_DELETE_QUESTIONNAIRE_MAIN",
    ACC_NO_REQUIRE_VALIDATE = "ACC_NO_REQUIRE_VALIDATE",
    EVIDENCE_NOT_FOUND = "EVIDENCE_NOT_FOUND",
    VALIDATION_ERROR = "VALIDATION_ERROR",
    ERR_NOT_ANSWERED = "ERR_NOT_ANSWERED",
    ERR_REPORT_FOLIO_REJECTED = "ERR_REPORT_FOLIO_REJECTED",
    ERR_VALIDATION_FILES = "ERR_VALIDATION_FILES",
    ERR_NO_PERMISSIONS = "ERR_NO_PERMISSIONS",
    ERR_VALID_DATE_START = "ERR_VALID_DATE_START",
    ERR_USER_QUESTIONNAIRE_NOT_TO_BE_STARTED = "ERR_USER_QUESTIONNAIRE_NOT_TO_BE_STARTED",
    ERR_USER_NOT_ALLOWED_REASSIGN = "ERR_USER_NOT_ALLOWED_REASSIGN",
    ERR_ALREADY_ANSWERING = "ERR_ALREADY_ANSWERING",
    ERR_ALREADY_ANSWERED = "ERR_ALREADY_ANSWERED",
    ERR_USERNAME_EXIST = "ERR_USERNAME_EXIST",
    REQUIRE_CHANGE_PASS = "REQUIRE_CHANGE_PASS",
    INVALID_USERNAME_TYPE = "INVALID_USERNAME_TYPE"
}