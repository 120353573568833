import { useEffect, useState } from 'react'
import { Props, TypeOfGraphs } from './typing/types'
import { dataIndicator } from '../../components/cardIndicator'
import { graphicData, refresh } from './methods/callEndpoints'
import { PercentageStatus } from './typing/enums'
import {
  actionPlanStatus,
  programmingStatus,
  unitStatus,
} from '../methods/statusByPercentage'
import { useFilter } from '../../../../../../hooks/filter'
import {
  Item,
  ObjectGeneric,
  onChangeEvent,
} from '../../../../../../utils/types/generals'
import { useInputs } from './hooks/inpusSelects'
import { format, startOfWeek } from 'date-fns'
import { Constants } from '../../../../../../utils/constants'
import { defaultDates } from './constants/input'
import { percentage } from '../../../../../../utils/validate/validate'
import { Box, Typography } from '@mui/material'
import { useContextState } from '../../../../../../hooks/theme'
import { useStyles } from './styles'
import { percentageColors } from '../methods/statusColors'
import { useTranslation } from 'react-i18next'
import { Query } from '../../../../../reportFolio/pages/dashboard/typing'
import { formatUnits } from './methods/formatResponse'

const useBody = (payloas: Props) => {
  const { t } = useTranslation()
  const [graph, setGraph] = useState<TypeOfGraphs>('units')
  const [currentValueExisted, setCurrentValueExisted] = useState({
    units: false,
    programming: false,
    actionPlan: false,
  })
  const { colors } = useContextState()
  const styles = useStyles()
  const [items, setItems] = useState<Record<string, Item[]>>({})
  const { inputs } = useInputs({ items })
  const [filterData, setFilterData] = useState<Record<TypeOfGraphs, ObjectGeneric>>({ actionPlan: {}, programming: {}, units: {} })
  const [graphicHistory, setGraphicHistory] = useState<
    {
      here?: boolean
      name: string
      programming?: number
      noProgramming?: number
      total: number
      paused?: number
      noStarted?: number
      finished?: number
      canceled?: number
    }[]
  >([])
  const [dataGeneral, setDataGeneral] = useState<ObjectGeneric>({})
  const filtersU = useFilter({ formatFilter: {}, defaultValues: defaultDates })
  const filtersP = useFilter({ formatFilter: {}, defaultValues: defaultDates })
  const filtersA = useFilter({ formatFilter: {}, defaultValues: defaultDates })

  const filterByHistory = {
    units: filtersU,
    programming: filtersP,
    actionPlan: filtersA,
  }
  const [loaders, setLoaders] = useState({
    units: true,
    programming: true,
    actionPlan: true,
    table: true,
  })
  const [isActive, setIsActive] = useState({
    units: true,
    programming: false,
    actionPlan: false,
  })
  const [porcentage, setPorcentage] = useState({
    units: 0,
    programming: 0,
    actionPlan: 0,
  })
  const [dates, setDates] = useState({
    unitDate: '',
    unitWeek: '',
    programmingDate: '',
    programmingWeek: '',
    actionPLanDate: '',
    actionPLanWeek: '',
  })
  const [unitData, setUnitData] = useState<Record<string, dataIndicator>>({
    programmed: {
      id: 'programmed',
      amount: 0,
      color: PercentageStatus.RED,
      type: 'dark',
      percentage: '0%',
      title: t('dashboard.kpi.programmed'),
    },
    notProgrammed: {
      id: 'notProgrammed',
      amount: 0,
      color: PercentageStatus.GREEN,
      type: 'dark',
      percentage: '0%',
      title: t('dashboard.kpi.noProgrammed'),
    },
  })
  const [programmingData, setProgrammingData] = useState<
    Record<string, dataIndicator>
  >({
    paused: {
      id: 'paused',
      amount: 0,
      color: PercentageStatus.GREEN,
      type: 'dark',
      percentage: '0%',
      title: t('dashboard.kpi.inProgress'),
    },
    noStarted: {
      id: 'noStarted',
      amount: 0,
      color: PercentageStatus.GREEN,
      type: 'dark',
      percentage: '0%',
      title: t('dashboard.kpi.noStarted'),
    },
    finished: {
      id: 'finished',
      amount: 0,
      color: PercentageStatus.RED,
      type: 'dark',
      percentage: '0%',
      title: t('dashboard.kpi.finished'),
    },
  })
  const [actionPlanData, setActionPlanData] = useState<
    Record<string, dataIndicator>
  >({
    noStarted: {
      id: 'noStarted',
      amount: 0,
      color: PercentageStatus.GREEN,
      type: 'dark',
      percentage: '0%',
      title: t('dashboard.kpi.noStarted'),
    },
    paused: {
      id: 'paused',
      amount: 0,
      color: PercentageStatus.GREEN,
      type: 'dark',
      percentage: '0%',
      title: t('dashboard.kpi.inProgress'),
    },
    finished: {
      id: 'finished',
      amount: 0,
      color: PercentageStatus.GREEN,
      type: 'dark',
      percentage: '0%',
      title: t('dashboard.kpi.finished'),
    },
    rejected: {
      id: 'rejected',
      amount: 0,
      color: PercentageStatus.RED,
      type: 'dark',
      percentage: '0%',
      title: t('dashboard.kpi.rejected'),
    },
    expired: {
      id: 'expired',
      amount: 0,
      color: PercentageStatus.GREEN,
      type: 'dark',
      percentage: '0%',
      title: t('dashboard.kpi.expired'),
    },
    canceled: {
      id: 'canceled',
      amount: 0,
      color: PercentageStatus.GREEN,
      type: 'dark',
      percentage: '0%',
      title: t('dashboard.kpi.canceled'),
    },
  })

  const handleTableLoading = ({ value }: { value: boolean }) => {
    setLoaders((prev) => ({ ...prev, table: value }))
  }

  const getHistory = async ({
    name,
  }: {
    name: 'units' | 'programming' | 'actionPlan'
  }) => {
    const units = filterData?.[name]?.store?.length
      ? filterData?.[name].store.map((item: Item) => item.value)
      : (payloas?.units?.all as string[])

    const divisions = filterData?.[name]?.divisions?.map((item: Item) => item.value)
    const zones = filterData?.[name]?.zones?.map((item: Item) => item.value)
    const classes = filterData?.[name]?.class?.map((item: Item) => item.value)

    handleTableLoading({ value: true })
    const { data, currentValueExisted } = await graphicData({
      units: units ?? [],
      classes,
      divisions,
      zones,
      t
    })[name]({
      interval: filterByHistory[name].filterData?.intervale?.value,
      date: {
        start: filterByHistory[name].filterData.from,
        end: filterByHistory[name].filterData.to,
      },
    })
    setGraphicHistory(data)
    setCurrentValueExisted((prev) => ({
      ...prev,
      [name]: currentValueExisted,
    }))
    handleTableLoading({ value: false })
  }

  const addLastPointOfTheGraphic = () => {
    let graphicCopy: {
      here?: boolean | undefined;
      name: string;
      programming?: number | string | undefined;
      noProgramming?: number | string | undefined;
      total: number | string;
      paused?: number | string | undefined;
      noStarted?: number | string | undefined;
      finished?: number | string | undefined;
      canceled?: number | string | undefined;
    }[] = []
    const graphicHistoryLegth = graphicHistory?.length

    if (!currentValueExisted[graph] && filterByHistory[graph].filterData?.intervale?.value == '1w') {
      const currentDate = format(new Date(), Constants.dateFormat)
      if (graph === 'units') {
        const programmed = unitData.programmed.amount
        const noProgrammed = unitData.notProgrammed.amount
        const total = porcentage.units
        const statusProgrammes = unitStatus(
          parseFloat(percentage({ a: programmed, b: total }))
        ).programming()
        const colorProgrammed = percentageColors[statusProgrammes]
        const statusNoProgrammes = unitStatus(
          parseFloat(percentage({ a: noProgrammed, b: total }))
        ).noProgramming()
        const colorNoProgrammed = percentageColors[statusNoProgrammes]
        const dayAdded = formatUnits({
          name: currentDate,
          colorNoProgrammed,
          colorProgrammed,
          customeLabels: {
            noProgramming: `${noProgrammed} (${percentage({ a: noProgrammed, b: total, })}%)`,
            programming: `${programmed} (${percentage({ a: programmed, b: total, })}%)`,
          },
          noProgrammed: `${t('dashboard.kpi.noProgrammed')}: ${noProgrammed} (${percentage({ a: noProgrammed, b: total, })}%)`,
          percentages: {
            programming: unitData.programmed.amount?.toString() ?? '',
            noProgramming: unitData.notProgrammed.amount?.toString() ?? '',
            total: porcentage.units?.toString() ?? '',
          },
          programmed: `${t('dashboard.kpi.programmed')}: ${programmed} (${percentage({
            a: programmed,
            b: total,
          })}%)`,
          total: `${t('dashboard.kpi.total')}: ${total}`,
          values: {
            total: total.toString()
          },
          styles
        })

        if (!!graphicHistoryLegth) {
          graphicHistory?.forEach(item => {
            if (!!item.here) {
              graphicCopy.push(dayAdded)
            } else { graphicCopy.push(item) }
          })
        } else {
          graphicCopy.push(dayAdded)
        }
        return graphicCopy
      } else if (graph === 'programming') {
        const paused = programmingData.paused.amount
        const noStarted = programmingData.noStarted.amount
        const finished = programmingData.finished.amount
        const total = porcentage.programming

        const statusProgrammes = unitStatus(
          parseFloat(percentage({ a: paused, b: total }))
        ).noProgramming()
        const colorPaused = percentageColors[statusProgrammes]
        const dayAdded = {
          name: currentDate,
          customeSettings: {
            total,
            types: {
              paused: '2',
              noStarted: '2',
              finished: '1',
            },
            remove: {
              total: true,
            },
          },
          customeData: {
            paused: (
              <Box sx={{ width: '100%' }}>
                <Typography
                  sx={{
                    color: colors.color13,
                    width: '100%',
                    lineHeight: '2',
                  }}
                >
                  {t('dashboard.kpi.total')}: {total}
                </Typography>

                <Box sx={styles.containerTitle}>
                  <Typography
                    sx={{
                      color: colors.color13,
                      width: '100%',
                    }}
                  >
                    {t('dashboard.kpi.inProgress')}:
                    {`${paused} (${percentage({
                      a: paused,
                      b: total,
                    })}%)`}
                  </Typography>

                  <Box
                    sx={styles.percentageStatus({
                      color: colorPaused.color,
                    })}
                  />
                </Box>
              </Box>
            ),
            total: <></>,
          },
          customeLabels: {
            noStarted: `${noStarted} (${percentage({
              a: noStarted,
              b: total,
            })}%)`,
            finished: `${finished} (${percentage({
              a: finished,
              b: total,
            })}%)`,
          },
          paused: paused,
          noStarted: noStarted,
          finished: finished,
          total: total,
        }

        if (!!graphicHistoryLegth) {
          graphicHistory?.forEach(item => {
            if (!!item.here) {
              graphicCopy.push(dayAdded)
            } else { graphicCopy.push(item) }
          })
        } else {
          graphicCopy.push(dayAdded)
        }
        return graphicCopy
      } else if (graph === 'actionPlan') {
        const paused = actionPlanData.paused.amount
        const noStarted = actionPlanData.noStarted.amount
        const finished = actionPlanData.finished.amount
        const canceled = actionPlanData.canceled.amount
        const total = porcentage.actionPlan

        const statusProgrammes = unitStatus(
          parseFloat(percentage({ a: paused, b: total }))
        ).noProgramming()
        const colorPaused = percentageColors[statusProgrammes]
        const dayAdded = {
          name: currentDate,
          customeSettings: {
            total,
            types: {
              paused: '2',
              noStarted: '2',
              finished: '1',
              rejected: '2',
              canceled: '2',
              expired: '2',
            },
            remove: {
              total: true,
            },
          },
          customeData: {
            paused: (
              <Box sx={{ width: '100%' }}>
                <Typography
                  sx={{
                    color: colors.color13,
                    width: '100%',
                    lineHeight: '2',
                  }}
                >
                  {t('dashboard.kpi.total')}: {total}
                </Typography>

                <Box sx={styles.containerTitle}>
                  <Typography
                    sx={{
                      color: colors.color13,
                      width: '100%',
                    }}
                  >
                    {t('dashboard.kpi.inProgress')}:
                    {`${paused} (${percentage({
                      a: paused,
                      b: total,
                    })}%)`}
                  </Typography>

                  <Box
                    sx={styles.percentageStatus({
                      color: colorPaused.color,
                    })}
                  />
                </Box>
              </Box>
            ),
            total: <></>,
          },
          customeLabels: {
            noStarted: `${noStarted} (${percentage({
              a: noStarted,
              b: total,
            })}%)`,
            finished: `${finished} (${percentage({
              a: finished,
              b: total,
            })}%)`,
            canceled: `${canceled} (${percentage({
              a: canceled,
              b: total,
            })}%)`,
          },
          paused: paused,
          noStarted: noStarted,
          finished: finished,
          canceled: canceled,
          total: total,
        }
        if (!!graphicHistoryLegth) {
          graphicHistory?.forEach(item => {
            if (!!item.here) {
              graphicCopy.push(dayAdded)
            } else { graphicCopy.push(item) }
          })
        } else {
          graphicCopy.push(dayAdded)
        }
        return graphicCopy
      }
    }
    return graphicHistory
  }

  const options: Record<TypeOfGraphs, () => void> = {
    units: () => {
      getHistory({ name: 'units' })
    },
    programming: () => {
      getHistory({ name: 'programming' })
    },
    actionPlan: () => {
      getHistory({ name: 'actionPlan' })
    },
  }

  const getUnitsData = async () => {
    const units = filterData.units.store?.length
      ? filterData.units.store.filter((item: Item) => item.value)?.map((item: Item) => item.value)
      : (payloas.units.all as string[])

    const divisions = filterData?.units?.divisions?.map((item: Item) => item.value)
    const zones = filterData.units.zones?.map((item: Item) => item.value)
    const classes = filterData?.units?.class?.map((item: Item) => item.value)
    setLoaders((prev) => ({ ...prev, units: true }))
    const response = await refresh({ units: units ?? [], classes, divisions, zones }).units()
    const colorProgramming = unitStatus(
      parseInt(response.percentageProgramming)
    ).programming()
    const colorNoProgramming = unitStatus(
      parseInt(response.percentageNoProgramming)
    ).noProgramming()

    setDates((prev) => ({
      ...prev,
      unitWeek: response.semana.toString(),
      unitDate: response.updateDate ?? '',
    }))
    setPorcentage((prev) => ({
      ...prev,
      units: response.total,
    }))

    setUnitData((prev) => ({
      ...prev,
      programmed: {
        ...prev.programmed,
        color: colorProgramming,
        amount: response.programming,
        percentage: `${response.percentageProgramming} %`,
      },
      notProgrammed: {
        ...prev.notProgrammed,
        color: colorNoProgramming,
        amount: response.noProgramming,
        percentage: `${response.percentageNoProgramming} %`,
      },
    }))
    setLoaders((prev) => ({ ...prev, units: false }))
  }

  const getProgrammingData = async () => {
    const units = filterData.programming.store?.length
      ? filterData.programming.store.filter((item: Item) => item.value)?.map((item: Item) => item.value)
      : (payloas.units.all as string[])
    const divisions = filterData?.programming?.divisions?.map((item: Item) => item.value)
    const zones = filterData.programming.zones?.map((item: Item) => item.value)
    const classes = filterData?.programming?.class?.map((item: Item) => item.value)
    setLoaders((prev) => ({ ...prev, programming: true }))
    const response = await refresh({ units: units ?? [], classes, divisions, zones }).programming()
    const colorPaused = programmingStatus(parseInt(response.percentagePaused)).paused()
    const colorNoStarted = programmingStatus(
      parseInt(response.percentageNoStarted)
    ).noStarted()
    const colorFinished = programmingStatus(
      parseInt(response.percentageFinished)
    ).finished()

    setPorcentage((prev) => ({
      ...prev,
      programming: response.total,
    }))
    setDates((prev) => ({
      ...prev,
      programmingWeek: response.semana.toString(),
      programmingDate: response.updateDate ?? '',
    }))
    setProgrammingData((prev) => ({
      ...prev,
      paused: {
        ...prev.paused,
        color: colorPaused,
        amount: response.paused,
        percentage: `${response.percentagePaused} %`,
      },
      noStarted: {
        ...prev.noStarted,
        color: colorNoStarted,
        amount: response.noStarted,
        percentage: `${response.percentageNoStarted} %`,
      },
      finished: {
        ...prev.finished,
        color: colorFinished,
        amount: response.finished,
        percentage: `${response.percentageFinished} %`,
      },
    }))
    setLoaders((prev) => ({ ...prev, programming: false }))
  }

  const getActionPlanData = async () => {
    const units = filterData.actionPlan.store?.length
      ? filterData.actionPlan.store.filter((item: Item) => item.value)?.map((item: Item) => item.value)
      : (payloas.units.all as string[])
    const divisions = filterData?.actionPlan?.divisions?.map((item: Item) => item.value)
    const zones = filterData.actionPlan.zones?.map((item: Item) => item.value)
    const classes = filterData?.actionPlan?.class?.map((item: Item) => item.value)
    setLoaders((prev) => ({ ...prev, actionPlan: true }))
    const response = await refresh({ units: units ?? [], classes, divisions, zones }).actionPlan()
    const colorPaused = actionPlanStatus(parseInt(response.percentagePaused)).paused()
    const colorNoStarted = actionPlanStatus(
      parseInt(response.percentageNoStarted)
    ).noStarted()
    const colorFinished = actionPlanStatus(
      parseInt(response.percentageFinished)
    ).finished()
    const colorRejected = actionPlanStatus(
      parseInt(response.percentageRejectd)
    ).canceled()
    const colorCanceled = actionPlanStatus(
      parseInt(response.percentageCanceled)
    ).canceled()
    const colorExpired = actionPlanStatus(
      parseInt(response.percentageExpired)
    ).canceled()

    setPorcentage((prev) => ({
      ...prev,
      actionPlan: response.total,
    }))

    setDates((prev) => ({
      ...prev,
      actionPLanWeek: response.semana.toString(),
      actionPLanDate: response.updateDate ?? '',
    }))
    setActionPlanData((prev) => ({
      ...prev,
      paused: {
        ...prev.paused,
        color: colorPaused,
        amount: response.paused,
        percentage: `${response.percentagePaused} %`,
      },
      canceled: {
        ...prev.canceled,
        color: colorCanceled,
        amount: response.canceled,
        percentage: `${response.percentageCanceled} %`,
      },
      expired: {
        ...prev.expired,
        color: colorExpired,
        amount: response.expired,
        percentage: `${response.percentageExpired} %`,
      },
      noStarted: {
        ...prev.noStarted,
        color: colorNoStarted,
        amount: response.noStarted,
        percentage: `${response.percentageNoStarted} %`,
      },
      rejected: {
        ...prev.rejected,
        color: colorRejected,
        amount: response.rejectd,
        percentage: `${response.percentageRejectd} %`,
      },
      finished: {
        ...prev.finished,
        color: colorFinished,
        amount: response.finished,
        percentage: `${response.percentageFinished} %`,
      },
    }))
    setLoaders((prev) => ({ ...prev, actionPlan: false }))
  }

  const changeGeneralSelects = ({ target }: onChangeEvent) => {
    const { name, value } = target

    setDataGeneral((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const onChange = ({ target }: onChangeEvent, blur: any) => {
    if (!blur?.blur) {
      if (target.name == 'intervale' && target.value?.value == '1w') {
        filterByHistory[graph].onChange({
          target: {
            name: 'from',
            value: filterByHistory[graph]?.filterData?.from ? startOfWeek(new Date(filterByHistory[graph]?.filterData?.from), { weekStartsOn: 1 }) : null
          }
        })
      }

      filterByHistory[graph].onChange({ target })
    }
  }
  const changeGraph = (payload: { type: TypeOfGraphs }) => {
    if (payload.type != graph) {
      let copyActive = structuredClone(isActive)

      Object.keys(copyActive).forEach((item) => {
        copyActive = {
          ...copyActive,
          [item]: payload.type == (item as TypeOfGraphs),
        }
      })

      options[payload.type]()
      setIsActive(copyActive)
      setGraph(payload.type)
    }
  }

  const refreshCards = ({ name }: { name: TypeOfGraphs }) => {
    const refreshMethod: Record<TypeOfGraphs, () => void> = {
      units: getUnitsData,
      programming: getProgrammingData,
      actionPlan: getActionPlanData,
    }
    refreshMethod[name]()
    if (name === graph) {
      options[graph]()
    }
  }

  const getFilterData = ({ data, graph }: { data: Query; graph: TypeOfGraphs }) => {
    setFilterData(prev => ({
      ...prev,
      [graph]: data
    }))
  }

  useEffect(() => {
    if ((!!(payloas.units?.all as Item[])?.length && !!payloas.units?.loading)) {
      getUnitsData()
      getProgrammingData()
      getActionPlanData()
    }
  }, [payloas.units?.all])

  useEffect(() => {
    if (!!filterData.units.store || !!filterData.units.divisions || !!filterData.units.zones || !!filterData.units.class) {
      if (graph === 'units') {
        options[graph]()
      }
      getUnitsData()
    }
  }, [filterData.units])

  useEffect(() => {
    if (!!filterData.programming.store || !!filterData.programming.divisions || !!filterData.programming.zones || !!filterData.programming.class) {
      if (graph === 'programming') {
        options[graph]()
      }
      getProgrammingData()
    }
  }, [filterData.programming])

  useEffect(() => {
    if (!!filterData.actionPlan.store || !!filterData.actionPlan.divisions || !!filterData.actionPlan.zones || !!filterData.actionPlan.class) {
      if (graph === 'actionPlan') {
        options[graph]()
      }
      getActionPlanData()
    }
  }, [filterData.actionPlan])

  useEffect(() => {
    if (!!(payloas.units?.all as Item[])?.length && !!payloas.units?.loading) {
      options[graph]()
    }
  }, [payloas.units?.all, filterByHistory.units.filterData, filterByHistory.programming.filterData, filterByHistory.actionPlan.filterData])

  useEffect(() => {
    setItems((prev) => ({
      ...prev,
      store: (payloas?.units?.items ?? []) as Item[],
      zones: payloas?.zoneAndClass?.zones as Item[],
      divisions: payloas?.divisions?.items as Item[],
      class: payloas?.zoneAndClass?.class as Item[],
    }))

  }, [payloas?.zoneAndClass, payloas?.divisions, payloas.units?.all])

  return {
    dates,
    loaders,
    unitData,
    porcentage,
    programmingData,
    actionPlanData,
    graph,
    isActive,
    filterByHistory,
    inputs,
    dataGeneral,
    graphicHistory,
    filterData,
    getFilterData,
    onChange,
    refreshCards,
    addLastPointOfTheGraphic,
    changeGeneralSelects,
    changeGraph,
    getUnitsData,
    getProgrammingData,
    getActionPlanData,
  }
}

export { useBody }
