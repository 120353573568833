import { Route, Routes } from "react-router-dom"
import { CalendarProvider } from "../provider"
import Calendar from "../pages/main"

const CalendarRoutes = () => {
    return (
        <CalendarProvider>
            <Routes>
                <Route path="/" element={<Calendar />} />
                {/* aun hay que implementar estas rutas, hay que hacer pruebas antes de incorporarlas, la logica aun esta en la vista principal de calendario */}
                {/* <Route path="/create" element={<CalendarForm />} />
                <Route path="/edit/:id" element={<CalendarForm />} /> */}
            </Routes>
        </CalendarProvider>
    )
}

export { CalendarRoutes }