import { Box, Grid, Icon, IconButton, Tooltip, Typography } from '@mui/material'
import { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BaseMenu, CustomTable, SnackSimple } from '../../../../components'
import { DinamicForm } from '../../../../components/dinamicForm'
import { FiltersDrawer } from '../../../../components/filtersDrawer'
import Loader from '../../../../components/loader'
import { useContextState } from '../../../../hooks/theme'
import { icons } from '../../../../utils/icons'
import { Item, onChange } from '../../../../utils/types/generals'
import { ItemsInput, useColumn, useInput } from './intialStates'
import { getAll } from './methods'
import { useStyles } from './styles'
import { actionPlansServices } from '../../../../service/actionPlans'
import { httpFetch } from '../../../../utils/crud'
import { FilterProps } from '../../../../hooks/filter/typing'
import { getDivisions, getQuestionnaires, getStore, getUserTypes, getUsers, getZones } from '../../../../utils/methods/geServiceWithItemsFormat'
import { Roles } from '../../../../utils/enums'
import { getStatus } from '../../../../hooks/status'
import { returnQuery, returnQueryWithPagination } from '../../../../utils/methods/returnQuery'
import { ModelDownloadButtons } from '../../../../components/donwloadFiles/model'
import { useDownload } from '../../../../hooks/download/useDownload'
import { getUserRole } from '../../../../service/auth'
import { IOSSwitch } from '../../../../components/dinamicForm/switch'
import { ActionPlanContext } from '../../context'
import { formData, formatFilter, menuItems, statusList } from './contants'

const ActionPlans = () => {
  const { t } = useTranslation()
  const { colors } = useContextState()
  const [itemsSearch, setItemsSearch] = useState<ItemsInput>({})
  const inputs = useInput(itemsSearch)
  const [rows, setRows] = useState<any[]>([])
  const { column, closeMenu, detail, optionMenu } = useColumn(t, rows?.length)
  const styles = useStyles()
  const [classList, setClassList] = useState<Item[]>([])
  const [loadingTable, setLoadingTable] = useState(false)
  const externalValidation: FilterProps['externalValidation'] = ({ name, value, body, setFilterData }) => {
    if (name === 'zone') {
      const { zonesClass } = body
      const newZonezClass = zonesClass?.filter((itemClass: Item) => value?.some((itemZone: any) => itemZone?.value == itemClass?.father))

      setItemsSearch((prev) => ({
        ...prev,
        zonesClass: value?.length <= 0 ? [] : classList?.filter((itemClass) => value?.some((itemZone: any) => itemZone?.value == itemClass?.father)),
      }))

      setFilterData((prev) => ({
        ...prev,
        zonesClass: newZonezClass,
      }))

      body = {
        ...body,
        zonesClass: newZonezClass,
      }
    }

    return body
  }
  const { filterData, actionPlanOfStores, doNotHaveAcces, setActionPlanOfStores, setFilterPayload } = useContext(ActionPlanContext)
  const queryWithPagination = returnQueryWithPagination({ filter: filterData.filterString, sort: filterData.query.sort })
  const [tableDate, setTableDate] = useState({
    totalPages: 1,
    totalItems: 0,
    currentPages: 1,
  })

  const onChange: onChange = (e, blur) => {
    filterData.onChange(e, blur)
  }

  const cleanFilter = () => {
    filterData.cleanFilter()
  }

  const getData = useCallback(async (data?: { queries?: string; reloadData?: boolean }) => {
    setLoadingTable(true)
    getAll(t, colors, { ...data, queries: `by_store=${!!actionPlanOfStores}${!!data?.queries ? `&${data.queries}` : ''}` })
      .then((response) => {
        const { row, currentPage, totalItems, totalPages } = response
        setTableDate({
          currentPages: currentPage,
          totalItems,
          totalPages,
        })
        const newRow = row.map((item) => {
          return {
            ...item,
            reportUrl: {
              value: item?.reportUrl,
            },
            item: {
              children: !!item?.isByItem ? (
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <Typography sx={styles.textItem}>{item?.isByItem ? t('general.yes') : t('general.no')}</Typography>
                  </Grid>

                  {item?.item?.answers?.length && (
                    <Grid sx={styles.containerIconTooltip} item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Tooltip
                        title={
                          <Box>
                            <Box sx={styles.containerTooltipAsk}>
                              <Box sx={styles.titleTooltip}>
                                <Typography sx={styles.titleQuestion}>{t('general.ask')}: </Typography>
                              </Box>
                              <Box>
                                <Typography sx={styles.titleQuestion}>{item?.item?.question.title}</Typography>
                              </Box>
                            </Box>
                            <Box sx={styles.containerTooltipAsk}>
                              <Box sx={styles.titleTooltip}>
                                <Typography sx={styles.textItem}>{t('general.answers')}: </Typography>
                              </Box>

                              <Box sx={styles.listAnswers}>
                                {item?.item?.answers?.map((itemO) => (
                                  <Typography sx={styles.textItem}>{itemO?.description}</Typography>
                                ))}
                              </Box>
                            </Box>
                          </Box>
                        }
                      >
                        <Icon sx={styles.viewMore}>{icons('add')}</Icon>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Typography sx={styles.bodyText}>{t('general.no')}</Typography>
              ),
            },
          }
        })
        setRows(newRow)
      })
      .finally(() => {
        setLoadingTable(false)
      })
    // eslint-disable-next-line
  }, [actionPlanOfStores])

  const getStatusByService = () => {
    setItemsSearch((prev) => ({
      ...prev,
      status: statusList.map((item) => {
        const newStatus = getStatus(item, colors, t)
        return {
          label: newStatus.text,
          value: item,
        }
      }),
    }))
  }

  const getDivision = async () => {
    const divisions = await getDivisions()
    setItemsSearch((prev) => ({
      ...prev,
      divisions: divisions.items,
    }))
  }

  const getStoreService = async () => {
    const { items } = await getStore()
    setItemsSearch((prev) => ({
      ...prev,
      stores: items,
    }))
  }

  const getUserService = async () => {
    const role = getUserRole()
    if (role === Roles.AGENT) return

    const { items } = await getUsers({ skip: [Roles.ADMIN] })
    setItemsSearch((prev) => ({
      ...prev,
      user: items,
    }))
  }

  const getUserTypeService = async () => {
    const { items } = await getUserTypes()
    setItemsSearch((prev) => ({
      ...prev,
      userType: items,
    }))
  }

  const getQuestionnaire = async () => {
    const questionnaire = await getQuestionnaires()
    setItemsSearch((prev) => ({
      ...prev,
      questionnaire: questionnaire.items,
    }))
  }

  const getZonesAndClass = async () => {
    const questionnaire = await getZones()
    setClassList(questionnaire.childZones)
    setItemsSearch((prev) => ({
      ...prev,
      zone: questionnaire.fatherZones,
    }))
    const newZonezClass = questionnaire.childZones?.filter((itemClass: Item) => filterData.filterData.zone?.some((itemZone: any) => itemZone?.value == itemClass?.father))
    setItemsSearch((prev) => ({
      ...prev,
      zonesClass: newZonezClass ?? [],
    }))
  }

  const {
    download,
    openSnackbar: snackbarDownloadModal,
    downloadCSV,
    downloadPDF,
    downloadWORD,
    closeOpenSnakbar,
  } = useDownload({
    docName: t('general.tags.actionPlan'),
    query: `&by_store=${actionPlanOfStores}${queryWithPagination ?? ''}`,
    downloadFuntion: actionPlansServices(httpFetch).getRegister,
  })

  useLayoutEffect(() => {
    getDivision()
    getStatusByService()
    getStoreService()
    getUserService()
    getQuestionnaire()
    getZonesAndClass()
    getUserTypeService()
  }, [])

  useEffect(() => {
    getData({ queries: returnQuery(filterData.query, formData) })
    //eslint-disable-next-line
  }, [filterData.query.all, actionPlanOfStores])

  useLayoutEffect(() => {
    setFilterPayload({
      formatFilter,
      externalValidation
    })
  }, [classList])
  return (
    <Box sx={styles.container}>
      <SnackSimple close={closeOpenSnakbar} open={snackbarDownloadModal.open} type={snackbarDownloadModal.type} message={snackbarDownloadModal.message} />

      {/* HEADER */}
      <Box sx={[styles.header]}>
        <Typography sx={styles.title}>{t('actionPlan.title')}</Typography>

        <Box sx={styles.flex}>
          {
            !!doNotHaveAcces && (
              <Box sx={styles.titleContainer}>
                <Typography sx={styles.label}>{t(`actionPlan.actionPlanOf.users`)}</Typography>

                <IOSSwitch
                  name="notified"
                  checked={!!actionPlanOfStores}
                  onChange={({ target }) => {
                    const { checked } = target
                    filterData.handlePagination({ page: 1, pageSize: filterData.paginationModel.pageSize })
                    setActionPlanOfStores(checked)
                  }}
                  sx={{ m: 1 }}
                />

                <Typography sx={styles.label}>{t(`actionPlan.actionPlanOf.stores`)}</Typography>
              </Box>

            )
          }

          <ModelDownloadButtons csv={downloadCSV} pdf={downloadPDF} word={downloadWORD} disableCSV={download?.excel} disablePDF={download?.pdf} disableWORD={download?.word} />

          <IconButton
            sx={(styles.clean, (filterData.counter || 0) > 0 ? null : styles.hide)}
            onClick={() => {
              cleanFilter()
            }}
          >
            {icons('clean')}
          </IconButton>
        </Box>
      </Box>

      {/* FLOATS */}
      <FiltersDrawer cleanFilter={cleanFilter} count={filterData.counter} right={0} top={50}>
        <Box sx={styles.section}>
          <DinamicForm errors={filterData.errors} input={Object.values(inputs)} values={filterData.filterData} onChange={onChange} filter={true} />
        </Box>
      </FiltersDrawer>

      <BaseMenu
        sx={[styles.baseMenu, detail.positionMenu]}
        anchorEl={detail.anchor}
        setAnchorEl={closeMenu}
        menuItems={
          detail?.reportUrl
            ? menuItems
            : menuItems?.filter((itemMenu) => itemMenu.value !== 2)
        }
        click={(item) => {
          optionMenu({ item: item as Item })
        }}
      />

      {!loadingTable ? (
        <CustomTable
          order={
            filterData.query?.sort && {
              count: filterData.query?.sort?.count,
              key: filterData.query?.sort?.field,
              order: filterData.query?.sort?.sort,
            }
          }
          sortable={(field, sort, count) => {
            filterData.sortTable(field && sort ? { field: field, sort: sort, count } : undefined)
          }}
          serverSidePagination={{
            page: (filterData.paginationModel.page ?? 0) + 1,
            size: filterData.paginationModel?.pageSize ?? 20,
            totalPages: tableDate.totalPages,
          }}
          handleChange={(page: number, pageSize: number) => {
            filterData.handlePagination({ page, pageSize })
          }}
          alignStatus="start"
          column={column}
          row={rows}
          color={colors}
          selected={-1}
        />
      ) : (
        <Loader />
      )}
    </Box>
  )
}

export default ActionPlans
