import { useEffect, useState, useCallback } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useStyles } from './style'
import { CrudTableWithFilterProps, Table } from './types'
import Filter from '../../filter'
import { CustomTable } from '../../table'
import InformationCard from '../../informationCard'
import { ObjectGeneric, onChangeEvent } from '../../../utils/types/generals'
import { icons } from '../../../utils/icons'
import { disabled } from '../../../utils/validate/validate'
import { initialFilterValues } from '../../../pages/users/pages/userType/initialState'

const CrudTableWithFilter = ({
  arrow,
  format,
  informativeCard,
  selector,
  setInformation,
  filterComponent,
  filterDescription,
  tableData,
  initialFilterData,
  filterRow,
  handleSubmit,
  printMenuData,
  initialValue,
  values,
  setValues,
  btnLoading,
  skipValidate,
  crudCreate,
  isLoading,
  dontRefresh,
  closeInformation,
  hidden,
  customSelected,
  customDisabled,
  tableChecks,
  tableRadio,
  defaultChecks,
  listenCreateClick,
  exportFiles,
  customeHeader,
  alignStatus,
  nameDownloadFile,
}: CrudTableWithFilterProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const styles = useStyles(!selector ? open : true, !!selector, !hidden?.informativeCard)
  const [table, setTable] = useState<Table>({ column: [], row: [] })
  const [rowCopy, setRowCopy] = useState<any[]>([])
  const [filterValues, setFilterValue] = useState<ObjectGeneric>(initialFilterData)
  const [crud, setCrud] = useState<boolean>(true)
  const [crudDetail, setCrudDetail] = useState<any>({
    title: '',
    add: '',
    cancel: t('userType.informationCard.buttons.cancel'),
  })
  const [selected, setSelected] = useState<number | null>()
  // Filter Mthods
  const filterChange = ({ target }: onChangeEvent) => {
    const { name, value } = target
    const body = { ...filterValues, [name]: value }
    setFilterValue(body)

    setTable({
      ...table,
      row: filterRow(body, rowCopy),
    })
  }
  
  const handleCleanFilter = () => {
    setFilterValue(initialFilterValues)
    setTable({
      ...table,
      row: rowCopy,
    })
  }

  // Others
  const handleInformation = (e: any, index?: number | string | null) => {
    setCrud(false)
    setOpen(true)
    setInformation(e)
    setSelected(index as number)
  }

  const onClickMenuItem = (item: any) => {
    const response = printMenuData?.(item)
    if (response?.detail) {
      setCrudDetail({
        title: response?.title,
        add: response?.add,
        cancel: t('userType.informationCard.buttons.cancel'),
      })
    } else if (response?.static) {
      setCrud(false)
      setOpen(true)
    } else if (response) {
      setCrud(true)
      setCrudDetail({
        title: response?.title ?? '',
        add: response?.add ?? '',
        cancel: t('userType.informationCard.buttons.cancel'),
      })
    } else {
      setOpen(false)
      setSelected(-1)
    }
  }

  const getData = useCallback(async () => {
    handleCleanFilter()
    setRowCopy(tableData.row)
    setTable(tableData)
    // eslint-disable-next-line
  }, [tableData])

  const handleClose = () => {
    setOpen(false)
    setSelected(-1)
  }

  const handleCreate = () => {
    setCrudDetail({
      title: crudCreate?.title ?? '',
      add: crudCreate?.btn ?? '',
      cancel: t('userType.informationCard.buttons.cancel'),
    })
    setCrud(true)
    setInformation(null)
    if (setValues && initialValue) setValues(initialValue)
    setOpen(true)
    setSelected(-1)
    listenCreateClick?.()
  }

  useEffect(() => {
    if (!dontRefresh) {
      getData()
    }
    !!closeInformation && setOpen(false)
  }, [getData, closeInformation, dontRefresh])

  // if (isLoading) return <Loader />
  return (
    <Box sx={styles.container}>
      <Box className="crud-filter-container" sx={styles.table}>
        <Filter
          type={filterComponent.type}
          title={filterComponent.title}
          filterTitle={filterComponent.filterTitle}
          sx2={filterComponent.sx}
          filter={
            !hidden?.filters
              ? filterDescription?.filter && {
                  name: filterDescription.filter.name,
                  value: {
                    search: {
                      value: filterValues[filterDescription.filter.name],
                      label: filterDescription.filter?.label,
                    },
                  },
                  sx: filterDescription?.sx,
                  onChange: filterChange,
                }
              : undefined
          }
          firstSelect={
            !hidden?.filters
              ? filterDescription?.firstSelect && {
                  label: filterDescription?.firstSelect.label,
                  name: filterDescription?.firstSelect.name,
                  items: filterDescription?.firstSelect.item,
                  value: filterValues[filterDescription.firstSelect.name],
                  onChange: filterChange,
                }
              : undefined
          }
          secondSelect={
            !hidden?.filters
              ? filterDescription?.secondSelect && {
                  label: filterDescription?.secondSelect.label,
                  name: filterDescription?.secondSelect.name,
                  items: filterDescription?.secondSelect.item,
                  value: filterValues[filterDescription.secondSelect.name],
                  onChange: filterChange,
                }
              : undefined
          }
          refresh={
            !hidden?.clean
              ? {
                  onClick: () => {
                    handleCleanFilter()
                  },
                  icon: icons('clean'),
                }
              : undefined
          }
          add={
            !hidden?.add
              ? {
                  onClick: selector?.onCreate ?? handleCreate,
                  icon: icons('add'),
                }
              : undefined
          }
        />
        <CustomTable
          alignStatus={alignStatus}
          customeHeader={customeHeader}
          format={format}
          name={nameDownloadFile ?? filterComponent.title}
          exportFiles={exportFiles}
          arrow={arrow}
          dontRefresh={dontRefresh}
          column={table.column}
          row={table.row}
          tableChecks={tableChecks}
          tableRadio={tableRadio}
          defaultChecks={defaultChecks}
          onClick={handleInformation}
          selected={customSelected ?? selected}
          setSelected={setSelected}
          loader={isLoading}
        />
      </Box>
      {!hidden?.informativeCard && (
        <Box sx={styles.userDetail}>
          {selector ? (
            selector.child
          ) : (
            <InformationCard
              {...informativeCard}
              title={crudDetail?.title}
              crud={crud}
              click={(e) => {
                printMenuData && onClickMenuItem(e)
              }}
              accept={
                crud && values
                  ? {
                      title: crudDetail?.add,
                      onClick: handleSubmit,
                      disabled: customDisabled ?? disabled(values as any, skipValidate),
                      loading: !!btnLoading,
                    }
                  : undefined
              }
              cancel={{
                title: crudDetail?.cancel,
                onClick: handleClose,
              }}
            />
          )}
        </Box>
      )}
    </Box>
  )
}

export default CrudTableWithFilter
