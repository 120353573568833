import { Autocomplete, Chip, TextField } from '@mui/material'
import { Props } from './props'
import { useState, SyntheticEvent, useEffect } from 'react'
import { Item } from '../../../utils/types/generals'
import { useTranslation } from 'react-i18next'
import { CustomPaper } from '../../CustomPaper'

const styles = {
  caption: {
    width: '100%',
    wordBreak: 'break-all',
  },
  chip: {
    width: '90%',
    height: 'fit-content',
    wordBreak: 'break-all',
    display: 'flex',
    justifyContent: 'space-between',
    background: '#00000011',
    '& .MuiChip-label': {
      textOverflow: 'clip',
      whiteSpace: 'break-spaces',
    },
  },
  chipUnFocus: {
    background: '#00000011',
  },
  input: {
    '& .MuiOutlinedInput-root': {
      height: '53px',
      display: 'flex',
      flexDirection: 'column',
    },
    '& .MuiAutocomplete-tag': {
      maxWidth: 'calc(100% - 22px)',
    },
  },
}
const MultiSelect = (props: Props) => {
  const [value, setValue] = useState<Item[]>([])
  const { t } = useTranslation()
  const [isFocus, setIsFocus] = useState(false)
  const handleChange = (_: SyntheticEvent<Element, Event>, value: any[]) => {
    setValue(value)
    props.onChange({
      target: {
        name: props.name,
        value: value,
      },
    })
  }
  const filterItems = () => {
    let newList: Item[] = []

    props.items?.forEach((item: any) => {
      if (!value?.find((itemValue: any) => itemValue?.value === item?.value)) {
        newList = [...newList, item]
      }
    })

    return newList
  }

  useEffect(() => {
    setValue(props.selected ?? [])
  }, [props.selected])
  return (
    <Autocomplete
      key={props.name}
      sx={{ ...props.sx, ...(!isFocus ? styles.input : {}) }}
      noOptionsText={t('general.noOptions')}
      multiple
      options={filterItems() ?? []}
      getOptionLabel={(option) => option.label ?? ''}
      value={value}
      limitTags={1}
      PaperComponent={CustomPaper}
      disableCloseOnSelect
      autoComplete={false}
      filterSelectedOptions
      onFocus={() => {
        setIsFocus(true)
      }}
      onBlur={() => {
        setIsFocus(false)
      }}
      onChange={handleChange}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => <Chip sx={isFocus ? styles.chip : styles.chipUnFocus} variant="outlined" label={option.label} {...getTagProps({ index })} />)
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={props.name}
          label={props.label}
          helperText={(props?.errors?.[props?.name]?.translate ? t(props?.errors?.[props?.name]?.translate) : props?.errors?.[props?.name]) ?? ''}
          error={props?.errors?.[props?.name]?.translate ?? props?.errors?.[props?.name]}
        />
      )}
    />
  )
}

export default MultiSelect
