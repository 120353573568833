import { useState, useEffect, useCallback, lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { BaseMenu, CustomTable, SnackSimple } from '../../../../components'
import { Roles, Status, Types } from '../../../../utils/enums'
import { Item, ObjectGeneric, onChange, onChangeEvent, Table } from '../../../../utils/types/generals'
import { initialData, initialFilterValues, initialTable, stepsArray, stepsArrayEdit, inputModel, useMenu, filesValidate, menuItems, resendReportForm, resendReportFormEmails } from './initialState'
import { disabled, filterRow, getList, getQuestionnarie, getStores, handleErrors, submit } from './methods'
import { useStyles } from './style'
import { useContextState } from '../../../../hooks/theme'
import { httpFetch } from '../../../../utils/crud'
import { Box, Button, IconButton, Paper, SxProps, Typography } from '@mui/material'
import { icons } from '../../../../utils/icons'
import { ScheduleService } from '../../../../service/schedule'
import SimpleModal from '../../../../components/modal/simpleModal'
import BaseModal from '../../../../components/modal/baseModal'
import { format } from 'date-fns'
import { Constants } from '../../../../utils/constants'
import { DinamicForm } from '../../../../components/dinamicForm'
import Loader from '../../../../components/loader'
import SimpleFile from '../../../../components/dinamicForm/simpleFile'
import { ItemMenu } from '../../../../components/baseMenu'
import { FiltersDrawer } from '../../../../components/filtersDrawer'
import { FileDownload } from '@mui/icons-material'
import { ErrorsCode } from './enums/enums'
import { ModelDownloadButtons } from '../../../../components/donwloadFiles/model'
import { useDownload } from '../../../../hooks/download/useDownload'
import { useGetData } from './hooks/getData'
import { useFilter } from '../../../../hooks/filter'
import { returnQuery, returnQueryWithPagination } from '../../../../utils/methods/returnQuery'
import { FilterProps } from '../../../../hooks/filter/typing'
import { getStatus } from '../../../../hooks/status'
import StatusComponent from '../../../../components/status'
import { handleAllErrors } from '../../../../utils/methods/handleErrors'
import { removeAllReference } from '../../../../utils/methods/removedReference'
import { formData, formatFilter } from './constants/filter'
import { canAccess } from '../../../../utils/validate/canaccess'
import { LoadingButton } from '@mui/lab'
import { isEmailSeparateToCome } from '../../../../utils/validate/validate'
import { actionPlanFetch } from '../../../../utils/constants/callService'
import { AssociatedAgents, PropsAssign } from '../form/components/steps/assignDate/porps'
import { DetailModal } from './components'

const AgentStepsPreview = lazy(() => import('../form/components/steps').then(({ AgentSteps }) => ({ default: AgentSteps })));

const Calendar = () => {
  const { t } = useTranslation()
  const menu = useMenu({ t })
  const { colors } = useContextState()
  const [alreadyLoad, setAlreadyLoad] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [data, setData] = useState<ObjectGeneric>({
    from: Date.now() + 60000,
    to: Date.now() + 1860000,
    frequency: null,
    title: null,
    notified: false,
    send: false,
  })
  const [selected, setSelected] = useState<{ stores: number[]; questionnaire: number[] }>({ stores: [], questionnaire: [] })
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false)
  const [flag, setFlag] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [allErrors, setAllErrors] = useState<ObjectGeneric>({})
  const [dataUpload, setDataUpload] = useState<Blob | undefined>()
  const [loadUpload, setLoadUpload] = useState(false)
  const [table, setTable] = useState<Table>(initialTable)
  const styles = useStyles(colors, !!allErrors?.dataUpload)
  const [tableStores, setTableStores] = useState<Table>(initialTable)
  const [tableQuestionnarie, setTableQuestionnarie] = useState<Table>(initialTable)
  const [openModalCSV, setOpenModalCSV] = useState(false)
  const [steps, setSteps] = useState<number | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [errorUsersQuestionnaries, setErrorUsersQuestionnaries] = useState<any>()
  const [openPreview, setOpenPreview] = useState(false)
  const [errorMassiveLoad, setErrorMassiveLoad] = useState<any>(null)
  const [detailAssigned, setDetailAssigned] = useState<PropsAssign['detail'] & { questionnairesUrls?: Record<string, any> }>({})
  const { childZones, departments, input, getData, handleItemsByExternal, disabledInputs } = useGetData()
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
    type: Types.SUCCESS as string,
  })
  const [detail, setDetail] = useState<{ id: number; reportUrl: string; positionMenu?: Record<string, unknown>; anchor: null | HTMLButtonElement; menu: ItemMenu[] }>({
    id: 0,
    reportUrl: '',
    positionMenu: {},
    anchor: null,
    menu: menuItems,
  })
  const [resendReport, setResendReport] = useState<{
    title: string
    open: boolean
    close: () => void,
    send: (data: { data: ObjectGeneric }) => void,
    values: ObjectGeneric
  }>({
    title: t('reportFolio.modal.sendReport.titleVisit'),
    open: false,
    close: () => { },
    send: () => { },
    values: {}
  })

  const [disabledModal, setDisabledModal] = useState({
    resendButton: true,
    resendButtonLoading: false,
  })

  const [openModalExit, setOpenModalExit] = useState(false)
  const [openModalError, setOpenModalError] = useState(false)
  const [errorMsg, setErrorMsg] = useState<{ code: string; message: string; title: string }>({ code: '', message: '', title: t('general.atention') })
  const [openModal, setOpenModal] = useState<{
    open: boolean
    title: string
    description: string
    submit?: boolean
    id?: number
    isClose?: boolean
  }>({
    submit: true,
    open: false,
    title: t('schedule.modal.submit.title'),
    description: t('schedule.modal.submit.description'),
  })
  const existId = openModal.id || openModal.id === 0
  const [errorsResend, setErrorsResend] = useState<string[]>([])
  const externalValidation: FilterProps['externalValidation'] = ({ name, value, body, setFilterData }) => {
    let copyBody: Record<string, any> = removeAllReference(body)

    if (copyBody?.keyCodeId) {
      copyBody = {
        ...copyBody,
        keyCodeId: (copyBody?.keyCodeId as Item[])?.map((item) => ({
          ...item,
          value: item.keyCode,
        })),
      }
    }
    if (name === 'zoneId') {
      const newChildZone = copyBody?.childZone?.filter((e: any) => value.find((e2: any) => e2.value == e.father))

      handleItemsByExternal({ name: 'childZone', value: value?.length <= 0 ? [] : childZones?.filter((itemClass) => value?.some((itemZone: any) => itemZone?.value == itemClass?.father)) })

      setFilterData((prev) => ({
        ...prev,
        childZone: newChildZone,
      }))

      copyBody = {
        ...copyBody,
        childZone: newChildZone,
      }
    } else if (name === 'areas') {
      const { areas, departments: departmentsData } = copyBody
      const newDepartments = departmentsData?.filter((e: any) => areas.find((e2: any) => e2.value == e.areaId))
      let arrayChilds: any[] = []

      areas?.forEach((e: any) => {
        const id = e.value
        const childs = departments.filter((child) => child.areaId == id)
        arrayChilds.push(...childs)
      })

      handleItemsByExternal({ name: 'departments', value: arrayChilds })
      setFilterData((prev) => ({
        ...prev,
        departments: newDepartments,
      }))

      copyBody = {
        ...copyBody,
        departments: newDepartments,
      }
    } else if (name === 'percentage') {
      if (parseInt(value[0]) === 0 && parseInt(value[1]) === 100) {
        delete copyBody.percentage
      }
    } else if (name === 'filterByCurrentWeek') {
      disabledInputs({ inputs: ['createYear', 'month', 'from', 'to'], value })
    }

    const currentWeekValue = name === 'filterByCurrentWeek' ? value : body?.filterByCurrentWeek

    if (!!currentWeekValue) {
      delete copyBody.createYear
      delete copyBody.month
      delete copyBody.from
      delete copyBody.to
    }
    return copyBody
  }
  const { counter, errors, filterData, paginationModel, query, filterString, sortTable, handlePagination, onChange, cleanFilter } = useFilter({
    externalValidation,
    formatFilter,
  })

  const {
    download,
    openSnackbar: snackbarDownloadModal,
    downloadCSV,
    downloadPDF,
    downloadWORD,
    closeOpenSnakbar,
  } = useDownload({
    docName: t('schedule.filter.title'),
    query: returnQueryWithPagination({ filter: filterString, sort: query.sort }, formData),
    downloadFuntion: ScheduleService(httpFetch).getRegister,
  })

  const optionMenu = ({ item }: { item: Item; questionnaireOption?: { id: string; } }) => {
    const menu: { [x: string]: () => void } = {
      '1': () => {
        setOpenModal((prev) => ({
          ...prev,
          id: detail.id,
        }))
        handleEdit(detail.id)
      },
      '2': () => {
        setOpenModal({
          ...openModal,
          title: t('schedule.modal.wantClose'),
          description: t('schedule.modal.descriptionClose'),
          id: detail.id,
          submit: false,
          open: true,
          isClose: true,
        })
      },
      '3': () => {
        if (detail.reportUrl || item?.questionnaireId) {
          window.open(detailAssigned?.questionnairesUrls?.[item?.questionnaireId]?.reportUrl ?? detail.reportUrl)
        }
      },
      '4': () => {
        const title = t(`reportFolio.modal.sendReport.${item?.questionnaireId ? 'titleQuestionnaire' : 'titleVisit'}`)
        setResendReport(prev => ({
          ...prev,
          title,
          open: true,
          close: () => {
            setDisabledModal((prev) => ({
              ...prev,
              resendButton: true,
              resendButtonLoading: false,
            }))
            closeResendModal()
          },
          send: ({ data }: { data: ObjectGeneric }) => {
            submitResendReport({ id: detail.id.toString(), data, questionnaireOption: { id: item?.questionnaireId } })
          }
        }))
      },
      '5': () => {
        getItem(detail.id, true)
      },
    }

    menu[item?.value]?.()
  }
  const handleCheanFilter = () => {
    disabledInputs({ inputs: ['createYear', 'month', 'from', 'to'], value: false })
    cleanFilter()
  }
  const closeMenu = () => {
    setDetail((prev) => ({
      ...prev,
      anchor: null,
    }))
  }
  const column: any[] = [
    {
      id: 'division',
      label: t('general.tags.division'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'zone',
      label: t('general.tags.zone'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'class',
      label: t('general.tags.class'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'keyCode',
      label: t('general.tags.id'),
      sortable: true,
      minWidth: 70,
    },
    {
      id: 'store_name',
      label: t('general.tags.store_name'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'agent',
      label: t('general.tags.assignedUser'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'available_from',
      label: t('general.tags.available_from'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'available_to',
      label: t('general.tags.available_to'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'status',
      label: t('general.tags.status'),
      minWidth: 100,
    },
    {
      id: 'action',
      label: t('general.tags.action'),
      minWidth: 50,
      renderCell: (params: any) => {
        const id = params?.row?.id
        return (
          <Box sx={styles.containerStatus}>
            <IconButton
              onClick={(e) => {
                let menu: ItemMenu[] = []
                const status = params?.row?.status?.value
                if (status == Status.FINISHED) {
                  menu = menuItems?.filter((itemMenu) => [3, 4, 5].includes(itemMenu.value))
                } else if ([Status.CLOSE, Status.CANCEL].includes(status)) {
                  menu = menuItems?.filter((itemMenu) => [5].includes(itemMenu.value))
                } else if (status == Status.INCOMPLETE) {
                  menu = menuItems?.filter((itemMenu) => [2, 5].includes(itemMenu.value))
                } else {
                  menu = menuItems?.filter((itemMenu) => ![3, 4].includes(itemMenu.value))
                }

                setDetail({
                  id,
                  reportUrl: params?.row?.reportUrl,
                  positionMenu:
                    table?.row?.length < Constants.numberOfRows
                      ? {
                        top: 0,
                        left: '-30px',
                      }
                      : {
                        top: `${e.clientY}px`,
                        left: `${e.clientX - 80}px`,
                      },
                  anchor: e.currentTarget,
                  menu: menu,
                })
              }}
            >
              {icons('moreHoriz')}
            </IconButton>
          </Box>
        )
      },
    },
  ]

  const handleCloseSnackbar = (value: boolean) => setOpenSnackbar({ ...openSnackbar, open: value })

  const crudChange = (e: onChangeEvent): void => {
    const { name, value } = e.target

    let body = { ...data }
    if (name !== 'stores') {
      body = {
        ...data,
        [name]: value,
        ...(name === 'frequency' ? { every: null } : {}),
        ...(name === 'from' && value >= data.to ? { to: value + 330000 } : {}),
      } as ObjectGeneric
    } else {
      const valueStores: number[] = body?.[name]?.[value.id] ?? []

      let valueStoreById: number[] = []

      if (!openModal.id) {
        valueStoreById = valueStores?.some((item: number) => item === value.checkId) ? valueStores?.filter((item) => item !== value.checkId) : [...valueStores, value.checkId]
      } else {
        valueStoreById = [value.checkId]
      }

      body = {
        ...body,
        [name]: {
          ...body[name],
          [value.id]: valueStoreById,
        },
      }
    }

    if (!body.frequency) {
      body = {
        ...body,
        every: null,
      }
    }

    setData(() => body)
    setAllErrors(handleErrors(body, t))
  }
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleCreate()
  }
  const handleSubmit = () => {
    setData(() => ({ ...data, send: true }))
    setOpenModal((prev) => ({
      ...prev,
      submit: true,
      open: true,
      title: t(`schedule.modal.submit.${openModal.id ? 'titleUpdate' : 'title'}`),
      description: t(`schedule.modal.submit.${openModal.id ? 'descriptionUpdate' : 'description'}`),
    }))
  }

  const handleConfirm = async () => {
    setIsLoadingConfirm(true)
    const response = await submit(data, selected, t, openModal.id)

    if (!response?.response?.errors) {
      const { code, data } = response?.response

      const message = t(`general.messages.errors.visits.create.${code}`)
      if (code === ErrorsCode.SomeOneUserNotHavePermissions) {
        setErrorMsg((prev) => ({ ...prev, code, message }))
        setOpenModalError(true)

        const questionnaires = detailAssigned?.questionnaire?.list || []
        let users: any[] = []
        detailAssigned?.stores?.list.forEach((e: any) => {
          users.push(...e.associatedAgents)
        })

        let usersByQuestionnaires: any[] = []
        data.forEach((e1: any) => {
          const name = users.find((e2: any) => e2.id === e1.userAssing).fullName

          let questionnairesWithError: any[] = []
          e1.questionnairesMainIds.forEach((id: any) => {
            const name = questionnaires.find((e: any) => e.id === id)?.title
            questionnairesWithError.push(name)
          })
          const str = `${name}: ${questionnairesWithError.join(',')}`
          usersByQuestionnaires.push(str)
        })
        setErrorUsersQuestionnaries(usersByQuestionnaires)
      }
      if (code === ErrorsCode.AllUsersHavePermissions || !code) {
        setOpenSnackbar(response.snackbarBody)
        getDetail({ reloadItems: true })
        cleanFilter()
      }

      if (code) {
        setErrorMsg((prev) => ({ ...prev, code, message }))
      }
    } else {
      const { code } = response?.response?.errors?.[0]

      if ((code as string) === ErrorsCode.AllTheUserNotHavePermissions) {
        const message = t(`general.messages.errors.visits.create.${code}`)
        setErrorMsg((prev: any) => ({ ...prev, code, message }))
        setOpenModalError(true)
      } else {
        setOpenSnackbar(response.snackbarBody)
        setData(() => ({ ...data, send: false }))
        let body = { ...data }
        setAllErrors(handleErrors(body, t))
      }
    }

    setIsLoadingConfirm(false)
    setOpenModal((prev) => ({
      ...openModal,
      submit: false,
      open: false,
      isClose: false,
    }))
  }

  const handleCloseModal = (removedId?: boolean) => {
    if (!removedId) {
      setData(() => ({ ...data, send: false }))
      let body = { ...data }
      setAllErrors(handleErrors(body, t))
    }
    setOpenModal({
      ...openModal,
      ...(!!removedId || !!openModal.isClose ? { id: undefined } : {}),
      submit: false,
      open: false,
      isClose: false,
    })
  }

  const handleEdit = (id: number) => {
    getItem(id)
    setOpenModal((prev) => ({
      ...prev,
      submit: false,
      open: false,
    }))
  }

  const handleCloseScheduled = async () => {
    if (openModal.id) {
      setIsLoadingConfirm(true)
      const response = await ScheduleService(httpFetch).closeSchedule(openModal.id)

      if (!response.errors) {
        setOpenSnackbar(prev => ({
          ...prev,
          open: true,
          message: 'La visita se cerró de manera exitosa, los cambios serán reflejados en un momento.'
        }))

        setOpenModal((prev) => ({
          ...openModal,
          submit: false,
          open: false,
        }))
        getDetail({
          query: returnQuery(query, formData),
          goBack: true,
        })
      } else {
        setOpenSnackbar(prev => ({
          ...prev,
          open: true,
          message: 'Ocurrió un error al cerrar la visita'
        }))
      }
      setIsLoadingConfirm(false)
    }
  }

  const getItem = (e: any, notCreate?: boolean) => {
    if (!notCreate) {
      setIsLoading(true)
      handleCreate()
    }
    ScheduleService(httpFetch)
      .getScheduleById(e)
      .then((response) => {
        let body = {
          ...data,
          agentAssigned: response.userAssignId,
          supervisor: response.userManage ? response.userManage : null,
          agent: response.userAssign ? `${response.userAssign.firstName} ${response.userAssign.lastName}` : null,
          from: new Date(response.dateStart).getTime(),
          to: new Date(response.dateEnd).getTime(),
          title: response.title,
          notified: response.notified === 1,
          description: response.description,
        }
        let questionnairesUrls: Record<string, any> = {}

        setSelected({
          stores: response.store?.id ? [response.store?.id] : [],
          questionnaire: response.assignedQuestionnaires ? [...new Set(response.assignedQuestionnaires.map((item: any) => {
            const questionnaire = item?.questionnaireMain || item?.questionnaire

            return questionnaire?.id
          }))] : [],
        })
        setDetailAssigned({
          stores: {
            title: t('schedule.assigned.selectedStores'),
            list: [
              {
                title: {
                  element: <Typography sx={[styles.titleList, styles.detailTitle]}>{response.store?.name ?? 'N/A'} - {response.store?.keyCode}</Typography>,
                  size: {
                    xl: 12,
                    lg: 12,
                    md: 12,
                    sm: 12,
                    xs: 12,
                  },
                },
                phone: {
                  element: <Typography sx={[styles.titleList]}>{`Telf: ${response.store?.phone ?? 'N/A'}`}</Typography>,
                  size: {
                    xl: 4,
                    lg: 4,
                    md: 4,
                    sm: 4,
                    xs: 4,
                  },
                },
                email: {
                  element: <Typography sx={[styles.titleList]}>{t('general.tags.email')}:{` ${response.store?.email ?? 'N/A'}`}</Typography>,
                  size: {
                    xl: 8,
                    lg: 8,
                    md: 8,
                    sm: 8,
                    xs: 8,
                  },
                },
                address: {
                  element: <Typography sx={[styles.titleList]}>{t('general.tags.address')}:{` ${response.store?.address ?? 'N/A'}`}</Typography>,
                  size: {
                    xl: 12,
                    lg: 12,
                    md: 12,
                    sm: 12,
                    xs: 12,
                  },
                },
                id: response.store?.id,
                associatedAgents: [],
              },
            ],
          },
          questionnaire: {
            title: t('schedule.assigned.selectedQuestionnaires'),
            list:
              response.assignedQuestionnaires?.map((item) => {
                const percentage: string[] | undefined = item?.resultQuestionnaire?.split?.('/')
                const resultQuestionnaire = percentage ? (parseInt(percentage?.[0]) / parseInt(percentage?.[1])) * 100 : null
                const status = getStatus(item?.statusId, colors, t)
                const startDate = item?.dateStart ? format(new Date(item?.dateStart), Constants.dateFormatGeneral) : ''
                const endDate = item?.dateEnd ? format(new Date(item?.dateEnd), Constants.dateFormatGeneral) : ''
                const reportStartDate = item?.reportDate ? format(new Date(item?.reportDate), Constants.dateFormatGeneral) : ''
                const lastReportDate = item?.lastDateReportFile ? format(new Date(item?.lastDateReportFile), Constants.dateFormatGeneral) : ''
                const questionnaireMain = item?.questionnaireMain || item?.questionnaire
                questionnairesUrls = {
                  ...questionnairesUrls,
                  [item?.id ?? ""]: {
                    reportUrl: item.reportUrl,
                    latReportUrl: item.lastReportFile,
                  }
                }
                return {
                  title: questionnaireMain?.name || 'N/A',
                  status: {
                    element: <StatusComponent msg={status.text} color={status.color} />,
                  },
                  ask: questionnaireMain?.sumQuestions ? `${t('general.answeredQuestions')}: ${item.amountQuestionsAnswered}/${questionnaireMain?.sumQuestions}` : 'N/A',
                  section: questionnaireMain?.sectionCount,
                  amountSection: item.amountSectionsCompleted,
                  amountQuestionAnswers: item.amountQuestionsAnswered,
                  percentage: resultQuestionnaire || resultQuestionnaire == 0 ? `${resultQuestionnaire}%` : 'Sin resultado',
                  date: {
                    element: (
                      <Box sx={styles.dateContainer}>
                        <Typography sx={styles.text2}>{`${t('general.startDate')}: ${startDate}`}</Typography>
                        <Typography sx={styles.text2}>{`${t('general.endDate2')}: ${endDate}`}</Typography>
                      </Box>
                    ),
                  },
                  amountQuestion: questionnaireMain?.sumQuestions,
                  from: startDate,
                  to: endDate,
                  reportStartDate,
                  lastReportDate,
                  id: item?.id,
                }
              }) ?? [],
          },
          questionnairesUrls
        } as any)

        setData(body)
        setAllErrors(handleErrors(body, t))
      })
      .catch((data) => {
        console.error(`error get Schedule: ${data}`)
      })
      .finally(() => {
        if (notCreate) {
          setOpenPreview(true)
        }
      })
  }

  const getDetail = useCallback(
    async (data?: { query?: string; goBack?: boolean; reloadItems?: boolean }) => {
      setOpenModalError(false)
      if (data?.goBack) {
        setLoadingTable(true)
      }
      setSteps(null)

      const responseStores = await getList(colors, t, data, alreadyLoad)

      if (!alreadyLoad || !!data?.reloadItems) {
        setAlreadyLoad(true)
      }

      setTotalPages(responseStores?.totalPages || 0)
      setTable({
        column: [],
        row: responseStores?.row,
      })
      setIsLoading(false)
      setLoadingTable(false)
      if (!data?.goBack) {
        goBack()
      }
      setIsLoading(false)
    },
    // eslint-disable-next-line
    [colors, t, alreadyLoad]
  )

  const getAllData = useCallback(async () => {
    setFlag(true)
    setIsLoading(true)
    setLoadingTable(true)
    getData()

    getDetail({
      goBack: true,
    })
    // setLoadingTable(false)
  }, [getDetail])

  const handleCreate = async (cancelLoading?: boolean) => {
    setAllErrors({
      ...allErrors,
      dataUpload: null,
    })
    setIsLoading(true)
    setSteps(0)
    getStores(t)
      .then((data: any) => {
        if (!!data?.errors) {
          setSteps(null)
        }
        setTableStores(data)
        if (!cancelLoading) {
          setIsLoading(false)
        }
      })
      .catch(() => {
        setSteps(null)
      })
    setTableQuestionnarie(await getQuestionnarie(t))
  }

  const handleSteps = (step: number) => {
    const currentStep = (steps ?? 0) + step
    if (currentStep === 2 || (!!openModal.id && currentStep === 1)) {
      setDetailAssigned({
        stores: {
          title: t('schedule.assigned.selectedStores'),
          list:
            tableStores?.row
              ?.filter((item: any) => selected.stores.includes(item?.id))
              ?.map((item: any) => {
                setData((prev) => {
                  const agentAssigned = openModal.id ? [prev.agentAssigned] : item?.associatedAgents?.map((item: any) => item.id) ?? []
                  return {
                    ...prev,
                    stores: {
                      ...(prev.stores ?? {}),
                      [item.id]: prev.stores?.[item.id]?.length < item.associatedAgents?.length && prev.stores?.[item.id]?.length > 0 ? prev.stores[item.id] : agentAssigned,
                    },
                  }
                })
                return {
                  title: item.name ?? 'N/A',
                  address: item.address ?? 'N/A',
                  phone: item.phone ?? 'N/A',
                  id: item.id,
                  associatedAgents: item.associatedAgents,
                }
              }) ?? [],
        },
        questionnaire: {
          title: t('schedule.assigned.selectedQuestionnaires'),
          size: {
            xl: 6,
            lg: 6,
            md: 6,
            sm: 6,
            xs: 6,
          },
          list:
            tableQuestionnarie?.row
              ?.filter((item: any) => selected.questionnaire.includes(item.id))
              ?.map((item: any) => ({
                title: item.quest_name ?? 'N/A',
                ask: item.ask ? `${item.ask} ${t('general.asks')}` : 'N/A',
                id: item.id,
              })) ?? [],
        },
      })
    }
    if (currentStep >= 3 || (currentStep > 1 && openModal.id)) {
      handleSubmit()
    } else {
      if (currentStep === 2) {
        setData((prev) => ({
          ...prev,
          from: Date.now() + 5000,
          to: Date.now() + 305000,
        }))
        setAllErrors(() =>
          handleErrors(
            {
              title: null,
              frequency: null,
              every: null,
              from: Date.now() + 5000,
              to: Date.now() + 305000,
            },
            t
          )
        )
      }
      setSteps((prev) => (prev ?? 0) + step)
    }
  }

  const handleDeleteDetail = (id: number | string, name: string) => {
    setDetailAssigned((prev) => ({
      ...prev,
      [name]: {
        ...detailAssigned[name],
        list: detailAssigned?.[name]?.list?.filter((item: any) => item?.id !== id) ?? [],
      },
    }))
    setSelected((prev) => ({
      ...prev,
      [name]: prev?.[name as 'questionnaire' | 'stores']?.filter((item) => item !== id),
    }))

    delete data.stores[id]

    let body = { ...data, [name]: { ...data.stores } }
    setData(() => body)
  }

  const deleteChekedTrue = (id: number | string, name: string) => {
    let body = { ...data }
    body = { ...body, [name]: { ...body[name], [id]: [] } }
    setData(() => body)
    setAllErrors(handleErrors(body, t))
  }

  const deleteCheckedFalse = (id: number | string, name: string, associatedAgents: AssociatedAgents[]) => {
    let body = { ...data }
    let agents = associatedAgents.map((items) => items.id)

    body = { ...body, [name]: { ...body[name], [id]: agents } }
    setData(() => body)
    setAllErrors(handleErrors(body, t))
  }

  const handleDisabled = () => {
    if ([2, 3].includes(steps ?? 0) || (openModal.id && (steps ?? 0) > 0)) return disabled(data) || Object.values(allErrors).some((item: any) => item)
    else if (steps === 1 || (openModal.id && steps === 0)) return selected.questionnaire?.length <= 0
    else if (steps === 0) return selected.stores?.length <= 0
  }

  const handleUploadFile: onChange = ({ target }) => {
    const { value } = target

    if (filesValidate.correctTypeFile.includes(value?.type)) {
      setDataUpload(value)
      setAllErrors({
        ...allErrors,
        dataUpload: null,
      })
    } else {
      setDataUpload(undefined)
      setAllErrors({
        ...allErrors,
        dataUpload: t('general.validations.wrongFile'),
      })
    }
  }

  const handleUpload = async () => {
    setErrorMassiveLoad(null)
    if (dataUpload) {
      setLoadUpload(true)
      const formData = new FormData()
      formData.append('file', dataUpload as Blob)
      const response = await ScheduleService(httpFetch).loadSchedule(formData)

      const responseJson = await response.json()
      const { code } = responseJson

      if (response?.status === 200) {
        if (code === '01') {
          setAllErrors({
            ...allErrors,
            dataUpload: t('general.invalidateTemplate'),
          })
        }
        if (code === ErrorsCode.AllUsersHavePermissions) {
          setOpenModalCSV(false)
          setDataUpload(undefined)
          setOpenSnackbar({
            open: true,
            type: Types.SUCCESS,
            message: t(`general.successBatch`),
          })
        }
        if (code === '03') {
          setDataUpload(undefined)
          setErrorMassiveLoad(responseJson)
        }
        if (code === '04') {
          setAllErrors({
            ...allErrors,
            dataUpload: t('general.fileEmptyError'),
          })
        }
      } else if (response?.status === 400) {
        if (code === '01') {
          setAllErrors({
            ...allErrors,
            dataUpload: t('general.invalidateTemplate'),
          })
        }
        if (code === '04') {
          setAllErrors({
            ...allErrors,
            dataUpload: t('general.fileEmptyError'),
          })
        }
      }
      setLoadUpload(false)
    }
  }

  const downloadLocale = (viewDocument?: string) => {
    window.open(viewDocument ?? `${process.env.REACT_APP_TEMPLATE_XLSX}?d=${Date.now()}`, 'blank')
  }

  const handleMenu = (item: ItemMenu) => {
    switch (item?.value) {
      case 2:
        handleCreate()
        break
      case 1:
        setOpenModalCSV(true)
        break
      default:
        break
    }
  }

  const handleCloseModalUpload = () => {
    setOpenModalCSV(false)
    setDataUpload(undefined)
    setAllErrors({
      ...allErrors,
      dataUpload: null,
    })
  }

  /*======RESEND REPORT======== 
    ==========START============
  */
  const closeResendModal = () => {
    setResendReport(prev => ({ ...prev, open: false, values: {}, close: () => { }, send: () => { } }))
    setErrorsResend([])
  }

  const submitResendReport = ({ id, data, questionnaireOption }: { id: string; data: ObjectGeneric; questionnaireOption?: { id?: string } }) => {
    setDisabledModal((prev) => ({
      ...prev,
      resendButtonLoading: true,
    }))

    const emails = data?.externalEmails
      ?.split(',')
      ?.filter((item: string) => !!item)

    let body: ObjectGeneric = {
      user_assign: !!data?.user_assign,
      users_supervisors: !!data?.users_supervisors,
      users_store: !!data?.users_store,
      user_manage_store: !!data?.user_manage_store,
      email_store: !!data?.email_store,
    }

    if (!!emails?.length) body['external_emails'] = emails

    const localeId = questionnaireOption?.id ?? id
    const endpointResend = questionnaireOption?.id ? actionPlanFetch.sendReportQuestionnaire : actionPlanFetch.sendReport

    endpointResend(localeId, body)
      .then((response) => {
        if (!response?.errors) {
          closeResendModal()
          setOpenSnackbar({
            open: true,
            type: Types.SUCCESS,
            message: t('general.reportSuccess'),
          })
        } else {
          if (['00', '01', '02'].includes(response.code)) {
            if (response.code == '00') {
              closeResendModal()
              setOpenSnackbar({
                open: true,
                type: Types.SUCCESS,
                message: t('general.reportSuccess'),
              })
            } else if (response.code == '01') {
              let listErrors = []

              if (response.data?.userAssign) { listErrors.push(t(`reportFolio.modal.sendReport.userWhoResponded`)) }
              if (response.data?.usersSupervisors) { listErrors.push(t(`reportFolio.modal.sendReport.userSupervisor`)) }
              if (response.data?.usersStore) { listErrors.push(t(`reportFolio.modal.sendReport.userStore`)) }
              if (response.data?.userManageStore) { listErrors.push(t(`reportFolio.modal.sendReport.agentStore`)) }
              if (response.data?.emailStore) { listErrors.push(t(`reportFolio.modal.sendReport.emailStore`)) }
              if (!!response.data?.externalEmails?.length) { listErrors.push(`${t(`reportFolio.modal.sendReport.externalEmails`)}: ${response.data?.externalEmails?.join(', ')}`) }

              setErrorsResend(listErrors)
            } else if (response.code == '02') {
              setOpenSnackbar({
                open: true,
                type: Types.ERROR,
                message: t('general.reportError'),
              })
            }
          } else if (!!response.errors) {
            setOpenSnackbar({
              open: true,
              type: Types.ERROR,
              message: handleAllErrors(response?.errors[0].code, t, t('general.reportError')),
            })
          }
        }
      })
      .finally(() => {
        setDisabledModal((prev) => ({
          ...prev,
          resendButton: true,
          resendButtonLoading: false,
        }))
      })
  }

  const handleSendReportData: onChange = ({ target }) => {
    const { name, value } = target
    setErrorsResend([])
    const body: ObjectGeneric = {
      ...structuredClone(resendReport.values),
      [name]: value,
    }

    /* START EMAIL VALIDATE */
    const emailValue = name === 'externalEmails' ? value : body?.externalEmails
    let emailIsSeparate = false
    emailIsSeparate = !(!emailValue || isEmailSeparateToCome(emailValue))
    /* END EMAIL VALIDATE */


    setDisabledModal((prev) => ({
      ...prev,
      resendButton:
        !Object.values(body ?? {})?.some((item) => !!item) || emailIsSeparate,
    }))

    setResendReport((prev) => ({
      ...prev,
      values: {
        ...prev?.values ?? {},
        [name]:
          name === 'externalEmails'
            ? value?.replace(/(\r\n|\n|\r|\s)/gm, '')
            : value,
      }
    }))
  }

  /*======RESEND REPORT======== 
    ==========END============
  */

  const goBack = (data?: { doNotCleanTheFilters?: boolean }) => {
    setSteps(null)
    setData(initialData)
    setAllErrors({})
    setSelected({ stores: [], questionnaire: [] })
    setDetailAssigned({})
    handleCloseModal(true)
    setOpenPreview(false)
    setOpenModalExit(false)
    setOpenModal((prev) => ({
      ...prev,
      id: undefined,
    }))
    if (!data?.doNotCleanTheFilters) {
      setAllErrors((prev) => ({
        ...prev,
        from: null,
        to: null,
      }))
    }
  }

  useEffect(() => {
    if (!flag) getAllData()
  }, [getAllData, flag])

  useEffect(() => {
    if (flag) {
      getDetail({
        query: returnQuery(query, formData),
        goBack: true,
      })
    }
    //eslint-disable-next-line
  }, [query.all])

  return (
    <Box sx={styles.container}>
      <SnackSimple close={handleCloseSnackbar} open={openSnackbar.open} type={openSnackbar.type} message={openSnackbar.message} />

      <SnackSimple close={closeOpenSnakbar} open={snackbarDownloadModal.open} type={snackbarDownloadModal.type} message={snackbarDownloadModal.message} />

      <DetailModal
        agent={data.agent}
        description={data.description}
        from={data.from}
        to={data.to}
        goBack={goBack}
        open={openPreview}
        optionMenu={optionMenu}
        questionnaireList={detailAssigned?.questionnaire?.list}
        reportUrl={detail.reportUrl}
        storeList={detailAssigned?.stores?.list}
        supervisor={data.supervisor}
        title={data.title}
        questionnairesUrls={detailAssigned?.questionnairesUrls}
        storeSize={detailAssigned?.stores?.size}
      />
      <BaseModal sx={styles.resendModal as SxProps} open={resendReport?.open}>
        <Box style={styles.resendReportContainer as any}>
          <IconButton
            sx={styles.close}
            onClick={() => {
              resendReport.close()
            }}
          >
            {icons('simple-close')}
          </IconButton>

          <Typography sx={styles.titleSend}>{resendReport.title}</Typography>

          <DinamicForm input={Object.values(resendReportForm)} values={resendReport.values} onChange={handleSendReportData} spacing={{ row: 0 }} />

          <Typography sx={styles.titleSend}>{t('reportFolio.modal.sendReport.emails')}</Typography>

          <DinamicForm input={Object.values(resendReportFormEmails)} values={resendReport.values} onChange={handleSendReportData} />
          {
            !!errorsResend?.length && (
              <Box sx={styles.containerErrorsResend}>
                <Typography>{t(`reportFolio.modal.sendReport.01`)}:</Typography>

                <ul>
                  {
                    errorsResend?.map((item, index) => (
                      <li><Typography>* {item}</Typography></li>
                    ))
                  }
                </ul>
              </Box>
            )
          }
          <Box sx={styles.resendButton}>
            <LoadingButton
              loading={!!disabledModal.resendButtonLoading}
              disabled={!!disabledModal?.resendButton}
              type="submit"
              onClick={() => {
                resendReport.send({ data: resendReport.values })
              }}
              sx={styles.button}
              variant="contained"
            >
              {t('reportFolio.buttons.resend')}
            </LoadingButton>
          </Box>
        </Box>
      </BaseModal>

      <SimpleModal
        sx={styles.simpleModal}
        open={openModalCSV}
        isLoading={loadUpload}
        title={!loadUpload && !errorMassiveLoad ? t('general.loadMassiveScheduled') : undefined}
        loadingButton
        disableButton={!dataUpload ? true : false}
        close={() => {
          handleCloseModalUpload()
        }}
        children={
          <Box sx={styles.uploadFile}>
            {!loadUpload && !errorMassiveLoad && (
              <>
                <Box sx={styles.wFull}>
                  <Typography sx={styles.link}>
                    <span className="circle">&#x2B24;</span>Se proporciona la plantilla para la carga de la agenda: <br />
                    <span
                      onClick={() => {
                        downloadLocale()
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      Descargar Plantilla
                    </span>
                  </Typography>
                </Box>

                <Box sx={styles.file}>
                  <SimpleFile
                    errors={allErrors}
                    notImage
                    onChange={handleUploadFile}
                    item={{
                      label: 'general.tags.loadCsv',
                      translate: true,
                      name: 'dataUpload',
                      value: null,
                      validate: {
                        required: true,
                      },
                      rest: {
                        accept: filesValidate.accept,
                        simpleFile: true,
                      },
                    }}
                    values={dataUpload}
                    customeIcon="add"
                  />
                </Box>
              </>
            )}

            {loadUpload && (
              <>
                <Box sx={styles.wFull}>
                  <Typography variant="body1" sx={styles.infoTitle}>
                    Comprobación de <br /> Carga Masiva de Agenda
                  </Typography>
                </Box>
                <Box sx={styles.wFull}>
                  <Typography sx={styles.infoText}>Estamos haciendo la carga masiva, por favor espere. Este proceso puede tomar unos minutos</Typography>
                </Box>

                <Loader />
              </>
            )}

            {!loadUpload && errorMassiveLoad && (
              <>
                <Box sx={styles.wFull}>
                  <Typography variant="body1" sx={[styles.infoTitle, styles.left]}>
                    Resultados de <br /> Carga Masiva de Agendas
                  </Typography>
                </Box>
                <Box sx={styles.wFull}>
                  <Typography sx={[styles.infoText, styles.left]}>A continuación se detallan los resultados de la carga masiva del archivo de agendas enviado.</Typography>
                </Box>

                <Box sx={styles.errorInfo}>
                  <Box sx={styles.green}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>{errorMassiveLoad?.itemsCorrect}</Typography>
                    <Typography sx={{ fontSize: 12 }}>Agendas cargadas</Typography>
                  </Box>
                  <Box sx={styles.red}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>{errorMassiveLoad?.itemsIncorrect}</Typography>
                    <Typography sx={{ fontSize: 12 }}>Registros con error</Typography>
                  </Box>
                </Box>

                <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                  <Typography sx={{ fontSize: 12, color: '#707070', fontWeight: 600 }}>RESOLUCIÓN DE CONFLICTOS</Typography>

                  <Box
                    onClick={() => {
                      const link = document.createElement('a')
                      link.href = errorMassiveLoad.urlError
                      link.click()
                    }}
                    sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '5px 0 20px 0', cursor: 'pointer' }}
                  >
                    <FileDownload sx={styles.downloadIcon} />
                    <Typography sx={styles.download}>Descargar registros no cargados</Typography>
                  </Box>

                  <Box sx={styles.file}>
                    <SimpleFile
                      errors={allErrors}
                      notImage
                      onChange={handleUploadFile}
                      item={{
                        label: 'general.tags.loadCsv',
                        translate: true,
                        name: 'dataUpload',
                        value: null,
                        validate: {
                          required: true,
                        },
                        rest: {
                          accept: filesValidate.accept,
                          simpleFile: true,
                        },
                      }}
                      values={dataUpload}
                      customeIcon="add"
                    />

                    <Button
                      onClick={() => {
                        handleUpload()
                      }}
                      variant="contained"
                      disabled={!dataUpload ? true : false}
                    >
                      REINTENTAR
                    </Button>
                  </Box>
                </Paper>
              </>
            )}
          </Box>
        }
        buttonText={t('general.importData')}
        cancelText={t('general.cancel')}
        color={colors}
        back={() => {
          handleCloseModalUpload()
        }}
        next={handleUpload}
        removeButtons={loadUpload || errorMassiveLoad}
      />

      <SimpleModal
        open={openModalExit}
        close={() => {
          setOpenModalExit(false)
        }}
        title={t('general.exit')}
        description={t('general.lostChange')}
        buttonText={t('general.confirm')}
        cancelText={t('general.cancel')}
        color={colors}
        back={() => {
          setOpenModalExit(false)
        }}
        next={goBack}
      />

      <SimpleModal
        open={openModalError}
        close={() => {
          if (errorMsg.code == ErrorsCode.SomeOneUserNotHavePermissions) {
            getDetail({ reloadItems: true })
          } else {
            setOpenModalError(false)
          }
        }}
        title={errorMsg.title}
        errorMsg={errorMsg.message}
        children={
          errorMsg.code != ErrorsCode.AllTheUserNotHavePermissions ? (
            <Box sx={styles.errorList}>
              <ul>
                {errorUsersQuestionnaries?.map((item: any, index: any) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </Box>
          ) : (
            <></>
          )
        }
        buttonText={t('general.confirm')}
        cancelText={t('general.cancel')}
        color={colors}
        back={
          errorMsg.code !== ErrorsCode.SomeOneUserNotHavePermissions
            ? () => {
                /* errorMsg.code != '01' &&  */ setOpenModalError(false)
              // errorMsg.code == '01' && getDetail({ reloadItems: true })
            }
            : undefined
        }
        next={() => {
          if (errorMsg.code == ErrorsCode.SomeOneUserNotHavePermissions) {
            getDetail({ reloadItems: true })
          } else {
            setOpenModalError(false)
          }
        }}
      />

      <SimpleModal
        open={openModal.open}
        close={handleCloseModal}
        title={openModal.title}
        description={openModal.description}
        buttonText={t('general.confirm')}
        cancelText={t('general.cancel')}
        color={colors}
        back={handleCloseModal}
        loadingButton
        isLoading={isLoadingConfirm}
        next={existId && !openModal.submit ? handleCloseScheduled : handleConfirm}
      />
      {!steps && steps !== 0 ? (
        <Box sx={styles.containerTable}>
          {/* HEADER */}
          <Box sx={[styles.header, styles.actions]}>

            <Box>
              <Typography sx={styles.headerTitle}>{t('schedule.filter.title')}</Typography>
              <Typography sx={styles.lastUpdate}>{t("schedule.subtitle")}<span>{t("schedule.5minutes")}</span></Typography>
            </Box>

            <Box sx={styles.flex}>
              <ModelDownloadButtons csv={downloadCSV} pdf={downloadPDF} word={downloadWORD} disableCSV={download?.excel} disablePDF={download?.pdf} disableWORD={download?.word} />

              <IconButton
                sx={(styles.clean, (counter || 0) > 0 ? null : styles.hide)}
                onClick={() => {
                  handleCheanFilter()
                }}
              >
                {icons('clean')}
              </IconButton>

              <IconButton className="add" onClick={handleClick}>
                {icons('add')}
              </IconButton>

              {
                !canAccess([Roles.AUDIT]) && (
                  <IconButton
                    className="openModal"
                    onClick={() => {
                      setOpenModalCSV(true)
                      setErrorMassiveLoad(false)
                      setLoadUpload(false)
                    }}
                  >
                    {icons('list')}
                  </IconButton>
                )
              }

              <BaseMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} menuItems={menu} click={handleMenu} />
            </Box>
          </Box>

          {/* FLOATS */}
          <FiltersDrawer cleanFilter={handleCheanFilter} count={counter} right={0} top={50}>
            <Box sx={styles.section}>
              <DinamicForm errors={errors} input={Object.values(input)} values={filterData} onChange={onChange} filter={true} />
            </Box>
          </FiltersDrawer>

          <BaseMenu
            sx={[styles.baseMenu, detail.positionMenu]}
            anchorEl={detail.anchor}
            setAnchorEl={closeMenu}
            menuItems={detail.menu}
            click={(item) => {
              optionMenu({ item: item as Item })
            }}
          />

          {/* TABLE */}
          {!loadingTable ? (
            <CustomTable
              order={
                query?.sort && {
                  count: query?.sort?.count,
                  key: query?.sort?.field,
                  order: query?.sort?.sort,
                }
              }
              sortable={(field, sort, count) => {
                sortTable(field && sort ? { field: field, sort: sort, count } : undefined)
              }}
              serverSidePagination={{ page: paginationModel.page + 1, size: paginationModel.pageSize, totalPages }}
              handleChange={(page: number, pageSize: number) => {
                handlePagination({ page, pageSize })
              }}
              column={column}
              row={table.row}
              color={colors}
              selected={-1}
            />
          ) : (
            <Loader />
          )}
          {/* </Grid> */}
        </Box>
      ) : (
        <Suspense fallback={<Loader />}>
          <AgentStepsPreview
            goBack={() => {
              setOpenModalExit(true)
            }}
            handleSteps={handleSteps}
            color={colors}
            stepsArray={!openModal.id ? stepsArray : stepsArrayEdit}
            activeStep={steps}
            steps={steps}
            disabled={handleDisabled()}
            id={openModal.id}
            step1={{
              steps: steps,
              filterRow,
              initialFilterValues,
              id: openModal.id,
              t,
              table: tableStores,
              isLoading,
              header: {
                title: t('schedule.filter.filterStore.title'),
                search: t('schedule.filter.filterStore.search'),
              },
              handleInformation: (e) => {
                setSelected({
                  ...selected,
                  stores: e.checks,
                })
              },
              defaultChecks: selected.stores,
            }}
            step2={{
              filterRow,
              initialFilterValues,
              id: openModal.id,
              t,
              header: {
                title: t('schedule.filter.filterQuestionnaire.title'),
                search: t('schedule.filter.filterQuestionnaire.search'),
              },
              table: tableQuestionnarie,
              isLoading,
              handleInformation: (e) => {
                setSelected({
                  ...selected,
                  questionnaire: e.checks,
                })
              },
              defaultChecks: selected.questionnaire,
            }}
            step3={{
              send: data.send,
              isLoading,
              id: openModal.id,
              errors: allErrors,
              deleteItem: handleDeleteDetail,
              onChange: crudChange,
              checkedDelete: deleteChekedTrue,
              checkedAdd: deleteCheckedFalse,
              input: Object.values(inputModel),
              detail: detailAssigned,
              availability: {
                from: data.from ?? null,
                to: data.to ?? null,
                frequency: data.frequency ?? null,
                every: data.every ?? null,
                title: data.title ?? null,
                description: data.description ?? null,
                notified: data.notified ?? false,
                stores: data.stores,
              },
            }}
          />
        </Suspense>
      )}
    </Box>
  )
}

export default Calendar
