import { Roles } from '../../utils/enums'
import { Http } from '../../utils/types'
import { ObjectGeneric } from '../../utils/types/generals'
import { getUserRole } from '../auth'
import { GetAllZones, ResponseZones } from './response'

export const zonesService = (client: Http) => ({
  getAllZones: async (): Promise<GetAllZones> => {
    switch (getUserRole()) {
      case Roles.AGENT:
        return await client.get('zones/store-associate')
      case Roles.STORE:
      case Roles.PARTNER:
        return []
      default:
        return await client.get('zones')
    }
  },
  getZoneById: async(id:string) => await client.get(`zones/${id}`),
  createZones: async (body: ObjectGeneric): Promise<ResponseZones> => await client.post('zones', body),
  updateZones: async (body: ObjectGeneric, id: number): Promise<ResponseZones> => await client.put(`zones/${id}`, body),
  deleteZones: async (id: number): Promise<ResponseZones> => await client.delete(`zones/${id}`),
})
