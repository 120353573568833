import { Roles } from '../../../utils/enums'
import { Items } from './types'
const onlyAdmin = [Roles.AGENT, Roles.STORE, Roles.SUPERVISOR, Roles.PARTNER, Roles.SUPPORT, Roles.AUDIT, Roles.GERENT]
export const useListItem = (): Items[] => {
  return [
    {
      label: 'Dashboard KPI',
      icon: 'bar-chart',
      path: 'dashboard',
      access: [Roles.ADMIN, Roles.SUPERVISOR, Roles.PARTNER, Roles.SUPPORT, Roles.AUDIT, Roles.GERENT, Roles.STORE, Roles.AGENT],
    },   
    {
      label: 'Reportes',
      icon: 'table',
      path: '',
      access: [Roles.ADMIN],
      children: [
        {
          label: 'Programaciones',
          icon: 'horizontal-line',
          path: 'dashboard/reports/programmed',
          notAllowedRoles: onlyAdmin,
        },
        {
          label: 'Por áreas y departamentos',
          icon: 'horizontal-line',
          path: 'dashboard/reports/programation',
          notAllowedRoles: onlyAdmin,
        },
      ],
    },
    {
      label: 'Organigrama',
      icon: 'tree',
      path: '',
      access: [Roles.ADMIN],
      children: [
        {
          label: 'Área',
          icon: 'horizontal-line',
          path: 'dashboard/organization-chart/area',
          notAllowedRoles: onlyAdmin,
        },
        {
          label: 'Departamento',
          icon: 'horizontal-line',
          path: 'dashboard/organization-chart/departments',
          notAllowedRoles: onlyAdmin,
        },
      ],
    },
    {
      label: 'Usuario',
      icon: 'user',
      path: '',
      access: [Roles.ADMIN],
      children: [
        {
          label: 'Registro de usuarios',
          icon: 'horizontal-line',
          path: 'dashboard/user/register',
          notAllowedRoles: onlyAdmin,
        },
        {
          label: 'Tipo de usuario',
          icon: 'horizontal-line',
          path: 'dashboard/user/user-type',
          notAllowedRoles: onlyAdmin,
        },
      ],
    },
    {
      label: 'Unidad',
      icon: 'store',
      path: '',
      access: [Roles.ADMIN, Roles.SUPPORT, Roles.AUDIT],
      children: [
        {
          label: 'Registro de unidad',
          icon: 'horizontal-line',
          path: 'dashboard/unit/store',
        },
        {
          label: 'Divisiones',
          icon: 'horizontal-line',
          path: 'dashboard/unit/division',
          notAllowedRoles: onlyAdmin,
        },
        {
          label: 'Zonas',
          icon: 'horizontal-line',
          path: 'dashboard/unit/zone', 
          notAllowedRoles: onlyAdmin,
        },
      ],
    },
    {
      label: 'Cuestionario',
      icon: 'paper',
      path: 'dashboard/questionnarie/main',
      access: [Roles.ADMIN],
      extraPaths: ['questionnarie/'],
    },
    {
      label: 'Agenda',
      icon: 'calendar',
      path: 'dashboard/calendar',
      access: [Roles.ADMIN, Roles.AGENT, Roles.SUPERVISOR, Roles.AUDIT, Roles.GERENT],
    },
    {
      label: 'Agenda de usuarios',
      icon: 'schedule-user',
      path: 'dashboard/schedule-user/list',
      extraPaths: ['schedule-user/list'],
      access: [
        Roles.ADMIN,
        Roles.SUPERVISOR,
        Roles.AGENT,
        Roles.STORE,
        Roles.GERENT,
        Roles.AUDIT,
        Roles.SUPPORT,
      ],
    },
    {
      label: 'Planes de acción',
      icon: 'action-plan',
      path: 'dashboard/action-plan',
      extraPaths: ['action-plan/'],
      access: [Roles.ADMIN, Roles.SUPERVISOR,  Roles.SUPPORT, Roles.AGENT, Roles.STORE, Roles.PARTNER, Roles.GERENT, Roles.AUDIT],
    },
    {
      label: 'Folios de reporte',
      icon: 'source',
      path: '',
      access: [Roles.ADMIN, Roles.SUPERVISOR, Roles.AGENT, Roles.STORE, Roles.PARTNER, Roles.SUPPORT, Roles.AUDIT, Roles.GERENT],
      children: [
        {
          label: 'Dashboard',
          icon: 'horizontal-line',
          path: 'dashboard/report-folio/dashboard',
        },
        {
          label: 'Configuración General',
          icon: 'horizontal-line',
          path: 'dashboard/report-folio/settings',
          notAllowedRoles: onlyAdmin,
        },
      ],
    },
    {
      label: 'Configuración',
      icon: 'setting',
      path: '',
      access: [Roles.ADMIN],
      children: [
        {
          label: 'Administradores',
          icon: 'horizontal-line',
          path: 'dashboard/setting/admin-users',
          notAllowedRoles: onlyAdmin,
        },
        {
          label: 'Grupo de opciones',
          icon: 'horizontal-line',
          path: 'dashboard/setting/option-group',
          notAllowedRoles: onlyAdmin,
        },
        {
          label: 'Compañías',
          icon: 'horizontal-line',
          path: 'dashboard/setting/companies',
          notAllowedRoles: onlyAdmin,
        },
      ],
    },
    {
      label: 'Localización',
      icon: 'map',
      path: 'dashboard/localization/main',
      // extraPaths: ['action-plan/'],
      access: [Roles.ADMIN, Roles.SUPERVISOR, Roles.AGENT, Roles.GERENT, Roles.STORE],
    },
  ]
}
