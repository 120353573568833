import { Paper, Grid, Typography, Box } from '@mui/material'
import { useStyles } from './styles'
import { Props } from './types'
import { useDimensions } from '../../../../hooks/dimensions'

const listHeader = [
  'www.checkplus.com', '|',
  'soporte@checkplus.com', '|',
  'Contáctenos: 1800 1234 1234'
]

const AuthCard = ({ children, secondChildren, footer, }: Props) => {
  const { dimensions } = useDimensions()
  const width = dimensions.width >= 1100
  const styles = useStyles(width)
  return (
    <Paper sx={styles.container}>
      <Box sx={styles.floatText}>
        {
          listHeader?.map((item, index) => <Typography>{item}</Typography>)
        }
      </Box>


      <Grid container sx={styles.gridItem}>
        {!!width && (
          <Grid item xl={6} lg={secondChildren ? 6 : 12} md={0} sm={0} xs={0} sx={[styles.gridItem, styles.images]}>
            {children}
          </Grid>
        )}
        {secondChildren && (
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12} sx={[styles.infContainer, styles.gridItem]}>
            {secondChildren}
            
            <Box sx={styles.footer} onClick={() => footer?.navigate?.()}>
              <Typography variant="caption">{footer?.label}</Typography>
            </Box>
          </Grid>
        )}
      </Grid>

    </Paper>
  )
}

export default AuthCard
