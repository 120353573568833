import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { SnackSimple } from '../../../../components'
import { useContextState } from '../../../../hooks/theme'
import { UserService } from '../../../../service'
import { CRUD, Types } from '../../../../utils/enums'
import { InformativeCard, InputModel } from '../../../../utils/types'
import { ObjectGeneric, onChange, onChangeEvent, Table } from '../../../../utils/types/generals'
import { initialData, initialTable, initialValues, input, useMenuItems } from './initialState'
import { filterRow, getAreaList, handleErrors, submit } from './methods'
import { useStyles } from './style'
import Form from './components/form'
import { generalDisabled, isValidateDate } from '../../../../utils/validate/validate'
import { httpFetch } from '../../../../utils/crud'
import General from '../../../../components/modules/general'
import { icons } from '../../../../utils/icons'
import { FiltersDrawer } from '../../../../components/filtersDrawer'
import { Box, Grid, Icon, IconButton, Tooltip, Typography } from '@mui/material'
import { DinamicForm } from '../../../../components/dinamicForm'
import { areaService } from '../../../../service/area'
import SimpleModal from '../../../../components/modal/simpleModal'
import DownloadFiles from '../../../../components/donwloadFiles'

const Area = () => {
  const { t } = useTranslation()
  const { colors } = useContextState()
  const styles = useStyles(colors)
  const [information, setInformation] = useState<InformativeCard | null>(null)
  const [data, setData] = useState<ObjectGeneric>({})
  const [btnLoading, setBtnLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [flag, setFlag] = useState(false)
  const [allErrors, setAllErrors] = useState<any>({})
  const [table, setTable] = useState<Table>(initialTable)
  const detailToInclude = ['email', 'phone', 'name', 'createTime', 'responsible']
  const [inputValues, setInputValues] = useState(initialValues)

  const [counter, setCounter] = useState<number | null>(null)
  const [erros, setErrors] = useState<ObjectGeneric>({})
  const [inputs, setInputs] = useState<{ [x: string]: InputModel }>(input)
  const [dataFilter, setDataFilter] = useState<ObjectGeneric>({})
  const [openModalDelete, setOpenModalDelete] = useState(false)

  const [row, setRow] = useState<Table['row']>([])
  const [selected, setSelected] = useState<number>(-1)
  const [open, setOpen] = useState(false)
  const [crud, setCrud] = useState(false)
  const [drawerDetail, setDrawerDetail] = useState({
    title: '',
    accept: '',
    cancel: '',
  })

  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
    type: Types.SUCCESS as string,
  })

  const menuList = useMenuItems(styles)
  const [menu, setMenu] = useState<
    {
      label: string
      value?: any
    }[]
  >(menuList)

  const handleCloseSnackbar = (value: boolean) => setOpenSnackbar({ ...openSnackbar, open: value })

  const crudChange = async (e: onChangeEvent | string, extra: any): Promise<void> => {
    if (typeof e !== 'string') {
      const { name, value } = e?.target

      let body = {
        ...data,
        [name]: value?.value ?? value,
      }

      setData((prev) => ({ ...prev, ...body }))
      setAllErrors(handleErrors(body, t))
    }
  }

  const handleSubmit = async () => {
    setBtnLoading(true)
    const response = await submit(data, t, information?.id)
    if (!response?.response?.errors) {
      getAllAreas()
      cleanFilter()
      getDepartments()
    }
    setOpenSnackbar(response.snackbarBody)
    setBtnLoading(false)
  }

  const handleInformation = (e: any, index: number) => {
    setSelected(index)
    openDrawer(true)

    let data: any[] = []
    setAllErrors({})
    if (e) {
      Object.entries(e ?? {}).forEach(([key, value]: any) => {
        if (!['id', 'department', 'departments'].includes(key)) {
          data = [
            ...data,
            {
              label: t(`general.tags.${key}`),
              value: value?.label ?? value?.text ?? (typeof value === 'string' || !value ? value : null),
              selected: value?.value ?? value,
              name: key,
              copy: key === 'email',
            },
          ]
        }
      })
      setMenu(menuList?.filter((item) => item.value !== e.status?.value))
    }

    setInformation(
      e
        ? {
          data,
          tmpData: data,
          status: {
            color: e.status.color,
            text: e.status.text,
            value: e.status?.value,
          },
          id: e.id,
          isAreaWithConfig: e.isAreaWithConfig,
        }
        : null
    )

    setData(initialData)
  }

  const handleMenu = async (item: any) => {
    if (item.value === CRUD.EDIT) {
      setCrud(true)
      setBtnLoading(true)

      areaService(httpFetch)
        .getAreaById(information?.id as number)
        .then((resp) => {
          let body = {}
          information?.tmpData?.forEach((element) => {
            body = {
              ...body,
              [element.name as string]: element?.selected ?? element.value,
            }
          })

          const { userManage, departments } = resp

          setData((prev) => ({
            ...prev,
            ...body,
            responsible: userManage?.id,
            departments: departments?.map((e: any) => ({
              value: e.id,
              label: e.name,
            })),
          }))

          let responsibles = inputValues.responsible.items
          responsibles?.push({ value: resp.userManage?.id, label: `${resp?.userManage?.firstName ?? ''} ${resp?.userManage?.lastName ?? ''}` })

          let departmentsList = inputValues.departments.items
          resp?.departments?.forEach((e: any) => departmentsList?.push({ value: e.id, label: e.name }))

          setInputValues((prev) => ({
            ...prev,
            responsible: {
              ...prev.responsible,
              items: responsibles,
            },
            departments: {
              ...prev.departments,
              items: departmentsList,
            },
          }))
        })
        .finally(() => {
          setBtnLoading(false)
        })
    } else if (item.value === CRUD.DELETE) {
      setOpenModalDelete(true)
    }
  }

  const handleDelete = () => {
    if (information?.id) {
      setBtnLoading(true)
      areaService(httpFetch)
        .deleteArea(information.id)
        .then((response: any) => {
          if (!response.errors) {
            getAllAreas()
            getDepartments()
            setOpenSnackbar({
              open: true,
              type: Types.SUCCESS as string,
              message: t(`area.snackbar.delete.success`),
            })
          } else {
            let obj = {
              open: true,
              type: Types.ERROR as string,
              message: t(`area.snackbar.delete.error`),
            }
            const { code } = response.errors[0]
            if (code == 'ERR_DELETE_QUESTIONNAIRE_MAIN') obj.message = t(`area.snackbar.delete.errorCode.ERR_DELETE_QUESTIONNAIRE_MAIN`)

            setOpenSnackbar(obj)
          }
        })
        .catch(() => {
          setOpenSnackbar({
            open: true,
            type: Types.ERROR as string,
            message: t(`area.snackbar.delete.error`),
          })
        })
        .finally(() => {
          setBtnLoading(false)
          setOpenModalDelete(false)
        })
    }
  }

  const getAllAreas = useCallback(async () => {
    resetValues()
    const responseAllAreas = await getAreaList(colors, t)

    let departmentList: any[] = []
    const newRow = responseAllAreas.row.map((item) => {
      item.departments?.forEach((e1: any) => {
        if (!departmentList.some((e2) => e2?.label === e1?.name)) {
          departmentList.push({ value: e1.id, label: e1.name })
        }
      })

      return {
        ...item,
        department: {
          children:
            item?.departments?.length > 0 ? (
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6} sx={{ color: '#828282' }}>
                  <Typography sx={styles.textItem}>{item?.departments.length ? <span>{item?.departments[0].name}</span> : null}</Typography>
                </Grid>

                {!!(item?.departments.length && item?.departments.length > 1) && (
                  <Grid sx={styles.containerIconTooltip} item xl={1} lg={1} md={1} sm={1} xs={1}>
                    <Tooltip
                      title={
                        <Box>
                          <Box sx={styles.containerTooltipAsk}>
                            <Box sx={styles.listAnswers}>
                              {item?.departments?.map((itemO: any) => (
                                <Typography sx={styles.textItem}>{itemO?.name}</Typography>
                              ))}
                            </Box>
                          </Box>
                        </Box>
                      }
                    >
                      <Icon sx={styles.viewMore}>{icons('add')}</Icon>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Typography sx={styles.bodyText}>{t('general.noData')}</Typography>
            ),
        },
      }
    })

    setInputs((prev) => ({
      ...prev,
      states: {
        ...prev.states,
        items: departmentList,
      },
    }))

    setTable({ column: responseAllAreas.column, row: newRow })
    setRow(newRow)
    setIsLoading(false)
    setFlag(true)
    // eslint-disable-next-line
  }, [colors, t])

  const getDepartments = async () => {
    const responseDepartments = await areaService(httpFetch).getAllDepartments()
    const responseUsers = await UserService(httpFetch).usersByStatus()

    const user = responseUsers?.map((e: any) => ({
      value: e.id,
      label: `${e.curp} - ${e.firstName} ${e.lastName}`,
    }))

    setInputValues((prev) => ({
      ...prev,
      departments: {
        ...prev.departments,
        items: responseDepartments.map((e: any) => ({
          value: e.id,
          label: e.name,
        })),
      },
      responsible: {
        ...prev.responsible,
        items: user,
      },
    }))

    setInputs((prev) => ({
      ...prev,
      users: {
        ...prev.users,
        items: user,
      },
    }))
  }

  const getAllData = useCallback(async () => {
    setIsLoading(true)
    getAllAreas()
    getDepartments()
  }, [getAllAreas])

  const onChange: onChange = ({ target }, blur) => {
    const { name, value } = target

    let startDate = 0
    let endDate = 0

    if (['from', 'to'].includes(name)) {
      if (!(blur as { blur: boolean })?.blur) {
        startDate = name === 'from' ? value?.valueOf() : dataFilter?.from?.valueOf()
        endDate = name === 'to' ? value?.valueOf() : dataFilter.to?.valueOf()
      } else {
        if (!isValidateDate(value)) {
          const splitValue = value?.split('/')?.reverse()?.join('-')
          const newValue = new Date(`${splitValue} 00:00:00`)?.getTime()
          startDate = name === 'from' ? newValue : dataFilter.from?.valueOf()
          endDate = name === 'to' ? newValue : dataFilter.to?.valueOf()
        }
      }
    }

    if (['from', 'to'].includes(name) && value !== '' && (blur as { blur: boolean })?.blur) {
      setErrors((prev) => ({
        ...prev,
        [name]: isValidateDate(value) ? t('general.invalidateDate') : null,
      }))
    } else {
      const body = {
        ...dataFilter,
        [name]: value,
      }

      setDataFilter(body)
      setRow(filterRow(body, table.row))
    }

    if (startDate && endDate) {
      if (startDate > endDate) {
        setErrors((prev) => ({
          ...prev,
          [name]: t('general.wrongDate'),
        }))
      } else {
        setErrors((prev) => ({
          ...prev,
          from: null,
          to: null,
        }))
      }
    }

    if (!value) {
      setErrors((prev) => ({
        ...prev,
        [name]: null,
      }))
    }
  }

  const resetValues = () => {
    setCrud(false)
    setOpen(false)
    setData(initialData)
    setBtnLoading(false)
    setSelected(-1)
  }
  const openDrawer = (edit?: boolean) => {
    setDrawerDetail({
      title: t(`area.informationCard.${edit ? 'edit' : 'create'}`),
      accept: t(`area.informationCard.buttons.${edit ? 'update' : 'add'}`),
      cancel: t('userType.informationCard.buttons.cancel'),
    })
    if (!edit) {
      setData(initialData)
    }
    setInformation(null)
    setAllErrors({})
    setCrud(!edit)
    setOpen(true)
  }

  const closeDrawer = () => {
    setOpen(false)
    setCrud(false)
    setSelected(-1)
  }

  const cleanFilter = () => {
    setDataFilter({})
    setErrors({})
    setRow(table.row)
  }

  useEffect(() => {
    setCounter(null)
    let count = 0
    for (let key in dataFilter) {
      if (dataFilter[key] instanceof Array) {
        if (dataFilter[key]?.length > 0) count++
      } else if (dataFilter[key] != null && dataFilter[key] !== '') count++
    }

    setCounter(count)
  }, [dataFilter])

  const handleCreate = async () => {
    setBtnLoading(true)
    openDrawer()
    setBtnLoading(false)
  }

  useEffect(() => {
    if (!flag) getAllData()
  }, [getAllData, flag])
  // if (isLoading) return <Loader />
  return (
    <>
      <SnackSimple close={handleCloseSnackbar} open={openSnackbar.open} type={openSnackbar.type} message={openSnackbar.message} />

      <SimpleModal
        loadingButton
        isLoading={btnLoading}
        open={openModalDelete}
        close={() => {
          setOpenModalDelete(false)
        }}
        title={t('area.modal.delete.title')}
        description={information?.isAreaWithConfig ? 'El área posee configuraciones de folio general. Al borrarla se perderán los cambios.' : ''}
        buttonText={t('general.confirm')}
        cancelText={t('general.cancel')}
        color={colors}
        back={() => {
          setOpenModalDelete(false)
        }}
        next={handleDelete}
      />

      <General
        newHeader={
          <Box sx={styles.header}>
            <Typography sx={styles.title}>{'Áreas'}</Typography>

            <Box sx={styles.flex}>
              <DownloadFiles
                row={row}
                column={table.column}
                name={'Áreas'}
                csvText={'EXCEL'}
                removeMargin
                customeHeader={[
                  {
                    id: 'createTime',
                    label: t('general.tags.createTime'),
                  },
                  {
                    id: 'name',
                    label: t('general.tags.area'),
                  },
                  {
                    id: 'departmentsPDF',
                    label: t('general.tags.state'),
                  },
                  {
                    id: 'email',
                    label: t('general.tags.email'),
                  },
                  {
                    id: 'phone',
                    label: t('general.tags.number'),
                  },
                  {
                    id: 'status',
                    label: t('general.tags.status'),
                  },
                ]}
              />

              <IconButton
                sx={(styles.clean, (counter || 0) > 0 ? null : styles.hide)}
                onClick={() => {
                  cleanFilter()
                }}
              >
                {icons('clean')}
              </IconButton>

              <IconButton
                sx={styles.clean}
                className='add'
                onClick={() => {
                  handleCreate()
                }}
              >
                {icons('add')}
              </IconButton>
            </Box>
          </Box>
        }
        open={open}
        rightDrawer={{
          ...information,
          showImage: false,
          data: information?.data?.filter((item) => detailToInclude.includes(item?.name ?? '')),
          form: <Form btnLoading={btnLoading} data={data} input={Object.values(inputValues)} crudChange={crudChange} allErrors={allErrors} />,
          menuItems: menu,
          crud,
          title: drawerDetail.title,
          accept: {
            title: drawerDetail.accept,
            disabled: generalDisabled(inputValues, data) || btnLoading,
            onClick: handleSubmit,
          },
          cancel: {
            title: drawerDetail.cancel,
            onClick: closeDrawer,
          },
          clickMenu: handleMenu,
        }}
        table={{
          column: table.column,
          row: row,
          title: t('general.tags.areas'),
          onClick: handleInformation,
          loadingTable: isLoading,
          selected,
        }}
        filtersDrawer={
          <>
            <FiltersDrawer cleanFilter={cleanFilter} count={counter} right={-50} top={0}>
              <Box sx={styles.section}>
                <DinamicForm errors={erros} input={Object.values(inputs)} values={dataFilter} onChange={onChange} filter={true} />
              </Box>
            </FiltersDrawer>

            <Box sx={{ position: 'absolute', right: '40px', top: '60px' }}>
              <IconButton
                sx={(styles.clean, (counter || 0) > 0 ? null : styles.hide)}
                onClick={() => {
                  cleanFilter()
                }}
              >
                {icons('clean')}
              </IconButton>
            </Box>
          </>
        }
      />
    </>
  )
}

export default Area
