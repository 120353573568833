import { Color } from "../types/color";
import { button } from "./button";
import { checkbox } from "./checkbox";
import { linearProgress } from "./linearProgress";
import  {textfield}  from "./textfield";
import { tooltip } from "./tooltip";

export const components = (color: Color) => ({
    ...textfield(color),
    ...button(color),
    ...linearProgress(color),
    ...checkbox(color),
    ...tooltip()
})
