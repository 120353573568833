import { Box, Grid, IconButton, Typography } from '@mui/material'
import { icons } from '../../../../utils/icons'
import { useStyles } from './styles'
import { Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Props, ReorderRef } from './props'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { ImportExport } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { DinamicFormRefModel } from '../../../../components/dynamicFormRef/types'
import { SectionModel } from '../../pages/detail/typing/types'

const OrderQuestionnarie = forwardRef((props: Props, ref: Ref<ReorderRef>) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const sections = Object.entries(props.models ?? {})
  const [order, setOrder] = useState<{ sections: Record<string, number>; questions: Record<string, number> }>({
    sections: {},
    questions: {}
  })

  useImperativeHandle(ref, () => ({
    getOrder: () => order
  }))

  const onDragEnd = (result: any, elements: [string, {
    options: Record<string, DinamicFormRefModel | null>;
    questionDescription: Record<string, DinamicFormRefModel | null>;
    answers: SectionModel;
  }][], key: string) => {
    if (!result.destination) {
      return
    }
    const newIndex = result.destination.index
    const id = `${key}-${result.draggableId}`
    let questionaryLocale: Record<string, number> = {}
    for (const index in elements) {
      const [keyQ] = elements[index]
      if (result.draggableId != keyQ) {
        const parseIndex = parseInt(index)
        if (newIndex < parseIndex || (result.source.index > index && newIndex == parseIndex)) {
          questionaryLocale = {
            ...questionaryLocale,
            [`${key}-${keyQ}`]: parseIndex + 1
          }
        } else if (newIndex > parseIndex || (result.source.index < index && newIndex == parseIndex)) {
          questionaryLocale = {
            ...questionaryLocale,
            [`${key}-${keyQ}`]: parseIndex - 1
          }
        }
      }
    }

    setOrder(prev => ({
      ...prev,
      questions: {
        ...prev.questions,
        [id]: result.destination.index,
        ...questionaryLocale
      }
    }))
  }

  const orderSection = (move: number, key: string, index: number) => {
    const auxSection = sections[index + move][0]
    setOrder(prev => ({
      ...prev,
      sections: {
        ...prev.sections,
        [key]: index + move,
        [auxSection]: (order.sections?.[auxSection] ?? 1) - move
      }
    }))
  }

  useEffect(() => {
    if (Object.values(props?.order?.sections ?? {})?.length || Object.values(props?.order?.questions ?? {})?.length) {
      setOrder(props.order)
    } else {
      for (const index in sections) {
        const [key, value] = sections[index]
        const questionArrarys = Object.entries(value?.questions ?? {})
        setOrder(prev => ({
          ...prev,
          sections: {
            ...prev.sections,
            [key]: parseInt(index)
          }
        }))

        for (const index2 in questionArrarys) {
          const [key2] = questionArrarys[index2]
          setOrder(prev => ({
            ...prev,
            questions: {
              ...prev.questions,
              [`${key}-${key2}`]: parseInt(index2)
            }
          }))
        }
      }
    }
  }, [props?.order])
  return (
    <Box sx={styles.container}>
      <Box sx={styles.table}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {sections?.sort(([keySorta], [keySortb]) => (order.sections[keySorta] ?? 0) - (order.sections[keySortb] ?? 0)).map(([key, item], index) => {
              const questionArrarys = Object.entries(item?.questions ?? {})
              return (
                <Box key={index} sx={styles.listAnswers}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="body1" sx={styles.sectionName}>
                        {props.data?.[key]?.sectionName || `Sección ${index + 1}`}
                      </Typography>

                      <Typography variant="body1" sx={styles.sectionDesc}>
                        {props.data?.[key]?.sectionDescription}
                      </Typography>
                    </Box>

                    {sections?.length > 1 && (
                      <Box sx={styles.indexSection}>
                        <IconButton className={`icon ${index == 0 ? 'disabled' : ''}`} onClick={() => { orderSection(-1, key, index) }}>
                          {icons('arrow-up')}
                        </IconButton>

                        <Typography className="indexBox" variant="body1">
                          {index + 1}
                        </Typography>

                        <IconButton className={`icon ${index == sections?.length - 1 ? 'disabled' : ''}`} onClick={() => { orderSection(1, key, index) }}>
                          {icons('arrow-down')}
                        </IconButton>
                      </Box>
                    )}
                  </Box>

                  {!!questionArrarys?.length && (
                    <DragDropContext
                      onDragEnd={(e) => {
                        onDragEnd(e, questionArrarys, key)
                      }}
                    >
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            {questionArrarys.sort(([keySorta], [keySortb]) => (order.questions[`${key}-${keySorta}`] ?? 0) - (order.questions[`${key}-${keySortb}`] ?? 0))?.map(([key2, questions], index) => {
                              return (
                                <Draggable key={key2} draggableId={key2} index={index} >
                                  {(provided,) => (
                                    <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{...provided.draggableProps.style, position: 'static'}} key={index} sx={styles.listAnswers}>
                                      <Box className="questionRow">
                                        <p>{props?.data?.[key]?.[key2]?.questionText || `${t('general.ask')} #${index + 1}`}</p>
                                        {<ImportExport sx={{ color: 'rgba(0, 0, 0, 0.5)' }}></ImportExport>}
                                      </Box>
                                    </Box>
                                  )}
                                </Draggable>
                              )
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  )}
                </Box>
              )
            })}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
})

export default OrderQuestionnarie
