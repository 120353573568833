import { useContextState } from "../../../../../../../../hooks/theme"

const useDateStyles = () => {
    const { colors } = useContextState()
    return {
        textItem: {
            fontSize: '12px',
            fontWeight: '400',
            width: '100%',
            color: colors.color10,
            wordBreak: 'break-word',
        },
    }
}

export { useDateStyles }
