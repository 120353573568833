import { useContextState } from "../../../hooks/theme"

const useStyles = ({errors}: {errors:boolean}) => {
  const { colors } = useContextState()

  return {

    image: {
      width: '25px',
      height: '25px',
    },
    icon: {
      padding: 0,
      marginLeft: '8px',
      '& .MuiSvgIcon-root': {
        color: !!errors ? colors.color28 : colors.color13
      }
    },
    close: {
      position: 'absolute',
      top: '-22px',
      right: '-22px',
      fontSize: '1rem',
      '& .MuiSvgIcon-root': {
        fontSize: '1rem',
        color: colors.color13
      }
    }
  }
}

export { useStyles }
