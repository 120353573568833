import { Routes, Route, Navigate,  } from 'react-router-dom'
import { LogIn } from '../pages/auth/logIn'
import { Box } from '@mui/material'
import { useContextState } from '../hooks/theme'
import { ChangePassword } from '../pages/auth/resetPassword'
import { ConfirmEmail } from '../pages/auth/confirmEmail'
import LogOut from '../pages/auth/logOut'
import { ConfirmUser } from '../pages/auth/confirmUser'

export const RoutesPublic = () => {
  const { colors } = useContextState()
  const styles = {
    container: {
      backgroundImage: `linear-gradient(${colors.color1}, ${colors.primary}, ${colors.color2})`,
      // height: '100vh',
    },
  }

  return (
    <Box sx={styles.container}>
      <Routes>
        <Route index element={<Navigate to="login" />} />
        <Route path="login" element={<LogIn />} />
        <Route path="logOut" element={<LogOut />} />
        <Route path="reset-password" element={<ChangePassword />} />
        <Route path="confirm-user" element={<ConfirmUser />} />
        <Route path="send-code" element={<ConfirmEmail />} />
      </Routes>
    </Box>
  )
}
