import { Box, Checkbox, FormControlLabel, Grid, Radio, Typography } from "@mui/material"
import { useDetailTypeOfResponse } from "./detailTypeOfResponse"
import { DetailTypeOfResponseProps } from "./type"
import { Images } from "../image"
import { useMemo } from "react"

const size = {
    1: {
        xs: 12,
    },
    2: {
        xl: 6,
        lg: 6,
        md: 12,
        sm: 12,
        xs: 12,
    },
    3: {
        xl: 4,
        lg: 4,
        md: 6,
        sm: 12,
        xs: 12,
    }
}

const DetailTypeOfResponse = (props: DetailTypeOfResponseProps) => {
    const styles = useDetailTypeOfResponse()
    const answerLength = props?.answersList?.length
    const chosendSize = useMemo(() => {
        switch (answerLength) {
            case 1: return size[1]
            case 2: return size[2]
            default: return size[3]
        }
    }, [answerLength])
    
    switch (props?.type) {
        case 2:
            return (
                <Grid container sx={styles.optionsContainer}>
                    {props?.answersList?.map((item) => (
                        <Grid key={item.id} item {...chosendSize}>
                            <FormControlLabel key={item.id} control={<Checkbox disableFocusRipple disableTouchRipple disableRipple checked={!!item.value} />} label={item.label} />
                        </Grid>
                    ))}
                </Grid>
            )
        case 3: return props?.answersList?.map((item) => (<Typography sx={styles.free} key={item.id}>{item.label}</Typography>))
        case 1:
        case 4:
            return (
                <Grid container sx={styles.optionsContainer}>
                    {props?.answersList?.map((item) => (
                        <Grid key={item.id} item {...chosendSize}>
                            <FormControlLabel
                                key={item.id}
                                control={<Radio disableFocusRipple disableTouchRipple disableRipple checked={!!item.value} />}
                                label={
                                    <Box sx={styles.iconContainer}>
                                        {
                                            (!!item.iconUrl && props?.type == 4) && (
                                                <Images src={item.iconUrl} sx={styles.icon} />
                                            )
                                        }

                                        <Typography>{item.label}</Typography>
                                    </Box>
                                }
                            />
                        </Grid>
                    ))}
                </Grid>
            )
        default:
            return <></>
    }
}

export { DetailTypeOfResponse }