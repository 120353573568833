import { Grid } from "@mui/material";
import { DynamicFormRefProps } from "../../../../../components/dynamicFormRef/types";

const sectionDescription = ({ sectionNameId, sectionDescriptionId }: { sectionNameId: string; sectionDescriptionId: string }): DynamicFormRefProps['model'] => ({
  sectionName: {
    id: `sectionName-${sectionNameId}`,
    label: "Nombre de la seccion",
    name: "sectionName",
    type: "text",
    validations: {
      required: { value: true }
    },
    wrapper: (payload) => (
      <Grid
        item
        xs={12}
        children={payload.children}
        key={payload.id}
      />
    )
  },
  sectionDescription: {
    id: `sectionDescription-${sectionDescriptionId}`,
    label: "Descripcion de la seccion",
    name: "sectionDescription",
    type: 'text',
    rows: 2,
    maxRows: 2,
    multiline: true,
    wrapper: (payload) => (
      <Grid
        item
        xs={12}
        children={payload.children}
        key={payload.id}
      />
    )
  }
})

const options = ({ optionId, templateId }: { optionId: string; templateId: string }): DynamicFormRefProps['model'] => ({
  questionOption: {
    id: `questionOption-${optionId}`,
    label: "Opciones de preguntas",
    name: "questionOption",
    type: "selectSimple",
    validations: {
      required: { value: true, }
    },
    selectOptions: {
      isEmptySelectFirst: true
    },
    items: [],
    hiddenError: true,
    wrapper: (payload) => (
      <Grid
        item
        xs={6}
        children={payload.children}
        key={payload.id}
      />
    )
  },
  questionTemplate: {
    id: `questionTemplate-${templateId}`,
    label: "Plantillas de respuestas",
    name: "questionTemplate",
    type: "selectSimple",
    items: [],
    wrapper: (payload) => (
      <Grid
        item
        xs={6}
        children={payload.children}
        key={payload.id}
      />
    )
  }
})

const questionDescription = ({ questionTextId, helpTextId, answerTextId }: { answerTextId?: string; questionTextId?: string; helpTextId?: string }): DynamicFormRefProps['model'] => ({
  questionText: {
    id: `questionText-${questionTextId ?? 1}`,
    label: "Texto de la pregunta",
    name: "questionText",
    type: "text",
    validations: {
      required: { value: true }
    },
    wrapper: (payload) => (
      <Grid
        item
        xs={12}
        children={payload.children}
        key={payload.id}
      />
    )
  },
  helpText: {
    id: `helpText-${helpTextId ?? 1}`,
    label: "Texto de ayuda",
    name: "helpText",
    type: 'text',
    rows: 2,
    maxRows: 2,
    multiline: true,
    wrapper: (payload) => (
      <Grid
        item
        xs={12}
        children={payload.children}
        key={payload.id}
      />
    )
  },
  answerText: {
    id: `answerText-${answerTextId ?? 1}`,
    label: "Texto de la respuesta",
    name: "answerText",
    type: 'text',
    rows: 3,
    maxRows: 3,
    multiline: true,
    validations: { required: { value: true } },
    wrapper: (payload) => (
      <Grid
        item
        xs={12}
        children={payload.children}
        key={payload.id}
      />
    )
  }
})

const answers = ({ actionPlanActiveId, answerId, switchOptionId, valueId, actionId, answerDisable, removeActionPlan, removeReportFolio }:
  {
    actionId?: string;
    answerId?: string;
    valueId?: string;
    switchOptionId?: string;
    actionPlanActiveId?: string
    answerDisable?: boolean
    removeActionPlan?: boolean
    removeReportFolio?: boolean
  }): DynamicFormRefProps['model'] => ({
    answer: {
      id: `answer-${answerId ?? 1}`,
      label: "Respuesta",
      name: "answer",
      type: 'text',
      disabled: !!answerDisable,
      validations: { required: { value: true } },
      inputOptions: {
        textFieldProps: {
          inputProps: {
            maxLength: 300
          }
        }
      },
      wrapper: (payload) => (
        <Grid
          item
          xs={3}
          children={payload.children}
          key={payload.id}
        />
      )
    },
    value: {
      id: `value-${valueId ?? 1}`,
      label: "Valor pts",
      name: "value",
      type: 'text',
      validations: { required: { value: true }, decimal: { value: true }, max: { value: 100 }, min: { value: 0 } },
      wrapper: (payload) => (
        <Grid
          item
          xs={2}
          children={payload.children}
          key={payload.id}
        />
      )
    },
    action: {
      id: `action-${actionId ?? 1}`,
      label: "Acciones",
      name: "action",
      type: "selectSimple",
      items: [],
      wrapper: (payload) => (
        <Grid
          item
          xs={3}
          children={payload.children}
          key={payload.id}
        />
      )
    },
    switchOption: {
      id: `switchOption-${switchOptionId ?? 1}`,
      label: "Opción correcta",
      name: "switchOption",
      type: 'switch',
      wrapper: (payload) => (
        <Grid
          item
          alignSelf="center"
          xs={3}
          children={payload.children}
          key={payload.id}
        />
      )
    },
    ...!removeReportFolio && {
      reportFolio: {
        id: `reportFolio`,
        label: "Activa reporte de folio",
        name: "reportFolio",
        type: 'switch',
        wrapper: (payload) => (
          <Grid
            item
            alignSelf="center"
            xs={4}
            children={payload.children}
            key={payload.id}
          />
        )
      },
    },
    ...!removeActionPlan && {
      actionPlanActive: {
        id: `actionPlanActive-${actionPlanActiveId ?? 1}`,
        label: "Activa opción de plan de acción",
        name: "actionPlanActive",
        type: 'switch',
        wrapper: (payload) => (
          <Grid
            item
            alignSelf="center"
            xs={5}
            children={payload.children}
            key={payload.id}
          />
        )
      },
    },
  })

const questions = ({ first, second }: { first: number; second: number }) => ({
  options: options({ optionId: '1', templateId: '1' }),
  questionDescription: questionDescription({ helpTextId: "1", questionTextId: "1" }),
  answers: {
    [first]: answers({ actionPlanActiveId: '1', answerId: '1', switchOptionId: '1', valueId: '1', actionId: '1' }),
    [second]: answers({ actionPlanActiveId: '1', answerId: '1', switchOptionId: '1', valueId: '1', actionId: '1' }),
  }
})

const sections = {
  errosAmount: 0,
  sectionDescription: sectionDescription({ sectionNameId: '1', sectionDescriptionId: '1' }),
  // QUESTION SECTION
  questions: {
    1: questions({ first: 1, second: 2 }),
  }
}

export { sections, sectionDescription, options, questionDescription, answers, questions }
