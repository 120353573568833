import * as React from 'react'
import Drawer from '@mui/material/Drawer'
import { useStyles } from './styles'
import { IconButton, Typography, Box, Badge, Button } from '@mui/material'
import { icons } from '../../utils/icons'

type Anchor = 'top' | 'left' | 'bottom' | 'right'

export const FiltersDrawer = ({ children, cleanFilter, count, right, top, m }: any) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const styles = useStyles()

  return (
    <div>
      {(['right'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Badge badgeContent={count} color="primary" sx={[styles.iconFilter, { right, top }]}>
            <IconButton onClick={toggleDrawer(anchor, true)}>{icons('filter')}</IconButton>
          </Badge>

          <Drawer
            PaperProps={{
              sx: { width: '35%', padding: '20px' },
            }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {children}

            <Box sx={styles.actions} {...m ?? {}}>
              <Box>
                {count > 0 && (
                  <Typography variant="body1" sx={styles.cleanFilters} onClick={cleanFilter}>
                    Limpiar filtros
                  </Typography>
                )}
              </Box>

              <Button onClick={toggleDrawer(anchor, false)} variant="contained">
                Ocultar filtros
              </Button>
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  )
}
