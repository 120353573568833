import { format } from 'date-fns'
import { getStatus } from '../../../../../../../hooks/status'
import { departmentService } from '../../../../../../../utils/constants/callService'
import { Constants } from '../../../../../../../utils/constants'
import { Roles, Status } from '../../../../../../../utils/enums'
import { useContextState } from '../../../../../../../hooks/theme'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Item } from '../../../../../../../utils/types/generals'
import { getArea, getUsers } from '../../../../../../../utils/methods/geServiceWithItemsFormat'

const useGetData = () => {
  const { colors } = useContextState()
  const { t } = useTranslation()
  const [loadingTable, setLoadingTable] = useState(false)
  const [items, setItems] = useState<Record<string, Item[]>>({})
  const [table, setTable] = useState<
    {
      id?: number
      email?: string
      phone?: string
      name?: string
      createTime: {
        label: string
        value: number
        type: string
      }
      status: {
        value: Status
        color: string
        text: string
      }
      area: {
        label?: string,
        value?: string | number
      }
      // users: User[] | undefined
    }[]
  >([])

  const getData = () => {
    requestTable()
    getAreasServices()
    getUserServices()
  }

  const requestTable = async () => {
    setLoadingTable(true)

    const department = await departmentService.getAllDepartments()

    setTable(
      !department?.errors
        ? department?.map((item) => {
            let status: Status = item?.status?.id || 1

            return {
              id: item.id,
              email: item?.email,
              phone: item?.phone ?? '',
              name: item.name,
              createTime: {
                label: item.createTime ? format(new Date(item.createTime), Constants.dateFormatGeneral) : 'N/A',
                value: item.createTime ? new Date(item.createTime).getTime() : 0,
                type: 'date',
              },
              status: getStatus(status, colors, t),
              area: {
                label: item.area?.name ?? 'N/A',
                value: item.areaId
              },
              // users: item.users,
            }
          })
        : []
    )

    setLoadingTable(false)
  }

  const getAreasServices = async () => {
    const areas = await getArea()
    setItems((prev) => ({ ...prev, areas }))
  }

  const getUserServices = async () => {
    const {items} = await getUsers({ skip: [Roles.ADMIN] })
    setItems((prev) => ({ ...prev, users: items }))
  }

  useEffect(() => {
    getData()
    //eslint-disable-next-line
  }, [])

  return { table, items, loadingTable, requestTable }
}

export { useGetData }
