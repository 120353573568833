import { useBody } from "./body"
import { ScoreCard } from "../../components/scoreCard"
import { Grid } from "@mui/material"

const ProgrammedUnits = () => {
  const { data, loading, text, getData } = useBody()

  return (
    <>
      <Grid item xs={12}>
        <ScoreCard loading={loading.programmed} refresh={() => { getData({ programmed: true }) }} subtitle={text.subTitleProgrammed} title={'Unidades programadas'} value={data.valueProgrammed} />
      </Grid>

      <Grid item xs={12}>
        <ScoreCard loading={loading.questionnaire} refresh={() => { getData({ questionnaire: true }) }} subtitle={text.subtitleQuestionnaire} title={'Ejecución de cuestionarios'} value={data.valueQuestionnaire} />
      </Grid>
    </>
  )
}

export { ProgrammedUnits }
