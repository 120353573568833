import { Box, Typography, IconButton, SxProps } from '@mui/material'
import { useStyles } from './styles'
import { useContext, useEffect } from 'react'
import { GlobalContext } from '../../../../../../contexts/Global'
import { useTranslation } from 'react-i18next'
import { FiltersDrawer } from '../../../../../../components/filtersDrawer'
import { DinamicForm } from '../../../../../../components/dinamicForm'
import { icons } from '../../../../../../utils/icons'
import { Item, ObjectGeneric } from '../../../../../../utils/types/generals'
import List from './modules/list'
import { useGetData } from './hooks/useGetData'
import { menuItems, reportFolioServices, resendReportForm, resendReportFormEmails } from './constants/states'
import { Query } from '../../typing'
import BaseModal from '../../../../../../components/modal/baseModal'
import { LoadingButton } from '@mui/lab'
import { BaseMenu, SnackSimple } from '../../../../../../components'
import { ModelDownloadButtons } from '../../../../../../components/donwloadFiles/model'
import { useDownload } from '../../../../../../hooks/download/useDownload'
import { returnQuery, returnQueryWithPagination } from '../../../../../../utils/methods/returnQuery'

const toggleSelection = '1'
export const Main = () => {
  const { colors } = useContext(GlobalContext)
  const { t } = useTranslation()
  const styles = useStyles({ color: colors })
  const bodyState =
    useGetData()
  const { download, openSnackbar, downloadCSV, downloadPDF, downloadWORD, closeOpenSnakbar } = useDownload({
    docName: t('reportFolio.dashboard.header.title'),
    query: returnQueryWithPagination({ filter: bodyState.filterData.filterString, sort: bodyState.filterData.query.sort }),
    downloadFuntion: reportFolioServices.downloadFolio,
  })

  const updateTable = (data: Query) => {
    bodyState.requestTable({ query: returnQuery(data) })
  }

  const handleSwitch = () => {
    const options: ObjectGeneric = {
      1: (
        <List
          headers={bodyState.headers}
          name={t('reportFolio.dashboard.header.title')}
          sortModel={bodyState.filterData.query.sort ? bodyState.filterData.query.sort : undefined}
          loading={bodyState.loading}
          sortTable={bodyState.filterData.sortTable}
          handlePagination={bodyState.filterData.handlePagination}
          paginationModel={bodyState.filterData.paginationModel}
          total={bodyState.total}
          color={colors}
          table={{ row: bodyState.row, column: bodyState.columns }}
        />
      ),
    }
    return options[toggleSelection] ?? <></>
  }

  useEffect(() => {
    updateTable(bodyState.filterData.query)
  }, [bodyState.filterData.query.all])
  return (
    <Box key={'dashboard-reportFolio'} sx={styles.container}>
      {/* HEADER */}
      <Box sx={styles.header}>
        <Typography sx={styles.title}>{t('reportFolio.dashboard.header.title')}</Typography>

        <Box sx={styles.flex}>
          <ModelDownloadButtons csv={downloadCSV} pdf={downloadPDF} word={downloadWORD} disableCSV={download?.excel} disablePDF={download?.pdf} disableWORD={download?.word} />

          <IconButton
            sx={(styles.clean, (bodyState.filterData.counter || 0) > 0 ? null : styles.hide)}
            onClick={() => {
              bodyState.filterData.cleanFilter()
            }}
          >
            {icons('clean')}
          </IconButton>
        </Box>
      </Box>

      {/* MAIN COMPONENTS */}
      {handleSwitch()}


      <BaseMenu
        sx={[styles.baseMenu, bodyState.detail.positionMenu]}
        anchorEl={bodyState.anchorEl}
        setAnchorEl={bodyState.setAnchorEl}
        menuItems={
          bodyState.detail?.reportUrl
            ? menuItems
            : menuItems?.filter((itemMenu) => itemMenu.value !== 2)
        }
        click={(item) => {
          bodyState.optionMenu({ item: item as Item })
        }}
      />
      {/* Float Components */}
      <BaseModal sx={styles.modal as SxProps} open={bodyState.resendReport?.open}>
        <Box style={styles.resendReportContainer as any}>
          <IconButton
            sx={styles.close}
            onClick={() => {
              bodyState.closeResendModal()
            }}
          >
            {icons('simple-close')}
          </IconButton>

          <Typography sx={styles.titleSend}>{t('reportFolio.modal.sendReport.title')}</Typography>

          <DinamicForm input={Object.values(resendReportForm)} values={bodyState.resendReport} onChange={bodyState.handleSendReportData} spacing={{ row: 0 }} />

          <Typography sx={styles.titleSend}>{t('reportFolio.modal.sendReport.emails')}</Typography>

          <DinamicForm input={Object.values(resendReportFormEmails)} values={bodyState.resendReport} onChange={bodyState.handleSendReportData} />

          <Box sx={styles.resendButton}>
            <LoadingButton
              loading={!!bodyState.disabled.resendButtonLoading}
              disabled={!!bodyState.disabled?.resendButton}
              type="submit"
              onClick={() => {
                bodyState.submitResendReport()
              }}
              sx={styles.button}
              variant="contained"
            >
              {t('reportFolio.buttons.resend')}
            </LoadingButton>
          </Box>
        </Box>
      </BaseModal>

      <FiltersDrawer cleanFilter={bodyState.filterData.cleanFilter} count={bodyState.filterData.counter} right={0} top={50}>
        <Box sx={styles.section}>
          <DinamicForm
            errors={bodyState.filterData.errors}
            input={Object.values(bodyState.inputFilter)}
            values={bodyState.filterData.filterData}
            onChange={bodyState.filterData.onChange}
            filter={true}
          />
        </Box>
      </FiltersDrawer>

      <SnackSimple close={closeOpenSnakbar} open={openSnackbar.open} type={openSnackbar.type} message={openSnackbar.message} />

      <SnackSimple close={bodyState.closeSnackbar} open={bodyState.snackbar.open} type={bodyState.snackbar.type} message={bodyState.snackbar.message} />
    </Box>
  )
}
