import { format, getYear } from 'date-fns'
import { TFunction } from 'react-i18next'
import { getStatus } from '../../../../hooks/status'
import { questionarieServise } from '../../../../service/questionarie'
import { ScheduleService } from '../../../../service/schedule'
import { ScheduledById } from '../../../../service/schedule/response'
import { Constants } from '../../../../utils/constants'
import { httpFetch } from '../../../../utils/crud'
import { RequestErrors, Roles, Status } from '../../../../utils/enums'
import { Frequency } from '../../../../utils/enums/frequency'
import { handleAllErrors } from '../../../../utils/methods/handleErrors'
import { Color } from '../../../../utils/theme/types/color'
import { Item, ObjectGeneric } from '../../../../utils/types/generals'
import { isRequired, searchInModeLower } from '../../../../utils/validate/validate'
import { es } from 'date-fns/locale'
import { monthYear } from '../../../../utils/constants/date_month'
import { getUserRole } from '../../../../service/auth'
import { storeServices } from '../../../../utils/constants/callService'

export const getList = async (colors: Color, t: TFunction<'translation', undefined>, body?: { query?: string; reloadItems?: boolean }, alreadyLoad?: boolean) => {
  const data = await ScheduleService(httpFetch).list({
    query: body?.query ?? 'size=20&page=1',
  })
  const totalPages = data.totalPages

  // let zoneIds: Item[] = []
  let keyCodeIds: Item[] = []

  let lastYear = 0
  let createYear: any[] = []

  const row: any[] = !data?.errors
    ? data?.items?.map((item) => {
        const store = item?.store
        const zones = store?.storeZone
        const zoneChildren = store?.storeClass
        const users = item?.userAssign

        if (!alreadyLoad || body?.reloadItems) {
          if (store?.keyCode && !keyCodeIds.some((item) => item?.value === store?.keyCode)) {
            keyCodeIds.push({
              label: `${store?.keyCode} - ${store?.name}`,
              value: store?.keyCode,
            })
          }
        }

        const month = format(new Date(item.createTime), 'LLLL', { locale: es })

        const currentYear = getYear(new Date(item.dateStart))

        if (currentYear > lastYear) lastYear = currentYear

        return {
          id: item?.id,
          store_name: item?.store?.name,
          idStore: item?.store?.id,
          agent: !!item?.userAssign?.firstName || !!item?.userAssign?.lastName ? `${item?.userAssign?.firstName ?? ''} ${item?.userAssign?.lastName ?? ''}` : null,
          available_from: item?.dateStart ? format(new Date(item?.dateStart).getTime(), Constants.dateFormatGeneral) : null,
          available_to: item?.dateEnd ? format(new Date(item?.dateEnd).getTime(), Constants.dateFormatGeneral) : null,
          from: new Date(item?.dateStart).getTime(),
          to: new Date(item?.dateEnd).getTime(),
          status: getStatus(item?.statusId, colors, t),
          visitStatus: item?.status?.description,
          action: null,
          division: users?.division?.description,
          reportUrl: item?.reportUrl,
          zone: zones?.name,
          class: zoneChildren?.name,
          keyCode: item?.store?.keyCode,
          cve: users?.division?.cveDivision,
          year: getYear(new Date(item.dateStart)),
          month: month ? month.charAt(0).toUpperCase() + month.slice(1) : 'N/A',
          weekYear: !!item?.weekYear ? `Semana ${item?.weekYear}` : null,
          country: 'México',
          state: item?.store?.zipCode?.state,
          city: item?.store?.zipCode?.country,
          userType: item?.userAssign?.userType?.name,
          curp: item?.userAssign?.curp,
          role: item?.userAssign?.role?.description,
          questionnaireArray: item?.assignedQuestionnaires?.map((itemQuestionnaire) => ({
            questionnaire: itemQuestionnaire?.questionnaireMain?.name,
            startDate: itemQuestionnaire?.dateStart ? format(new Date(itemQuestionnaire?.dateStart), Constants.dateFormat2) : null,
            area: itemQuestionnaire?.questionnaireMain?.area?.name,
            department: itemQuestionnaire?.questionnaireMain?.department?.name,
            startHour: itemQuestionnaire?.dateStart ? format(new Date(itemQuestionnaire?.dateStart), Constants.hourFormat) : null,
            endDate: itemQuestionnaire?.dateEnd ? format(new Date(itemQuestionnaire?.dateEnd), Constants.dateFormat2) : null,
            endHour: itemQuestionnaire?.dateEnd ? format(new Date(itemQuestionnaire?.dateEnd), Constants.hourFormat) : null,
            status: itemQuestionnaire?.status?.description,
          })),
        }
      })
    : []

  while (true) {
    createYear.push({
      label: lastYear,
      value: lastYear,
    })
    lastYear--
    if (lastYear < 2022) break
  }

  return { row, keyCodeIds, createYear, monthYear, totalPages }
}

export const getStores = async (t: TFunction<'translation', undefined>) => {
  const data = await storeServices.getAllStoresWithAgents()
  const column: any[] = [
    {
      id: 'keyCode',
      label: t('general.tags.keyCode'),
      sortable: true,
      minWidth: 70,
    },
    {
      id: 'name',
      label: t('general.tags.store_name'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'address',
      label: t('general.tags.address'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'email',
      label: t('general.tags.email'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'phone',
      label: t('general.tags.phone'),
      sortable: true,
      minWidth: 100,
    },
  ]

  const role = getUserRole()

  const row: any[] = !data?.errors
    ? data
        ?.filter((item) => item?.statusId === Status.ACTIVE && (item?.users?.length > 0 || (role && [Roles.AGENT, Roles.AUDIT].includes(role))))
        ?.map((item) => {
          return {
            id: item?.id,
            name: item?.name,
            keyCode: item.keyCode,
            email: item?.email,
            phone: item?.phone,
            address: item?.address,
            associatedAgents:
              item?.users?.map((agentItems) => ({
                fullName: `${agentItems.firstName} ${agentItems.lastName}`,
                curp: agentItems.curp,
                active: true,
                image: agentItems.picture,
                id: agentItems.id,
                role: agentItems.role
              })) ?? [],
          }
        })
    : []

  return { column, row }
}

export const getQuestionnarie = async (t: TFunction<'translation', undefined>) => {
  const data = await questionarieServise(httpFetch).getAllQuestionariesOld({type: true})
  const column: any[] = [
    {
      id: 'quest_name',
      label: t('general.tags.quest_name'),
      sortable: true,
    },
    {
      id: 'ask',
      label: t('general.tags.ask'),
      sortable: true,
    },
    {
      id: 'action_plan',
      label: t('general.tags.action_plan'),
      sortable: true,
    },
    {
      id: 'app',
      label: t('general.tags.app'),
      sortable: true,
    },
  ]

  const row: any[] = !data.errors
    ? data?.map((item: any) => {
        let action_plan = item.actionPlanByItem
        if (item.actionPlanByItem === true || item.actionPlanByItem === false) action_plan = item.actionPlanByItem ? 'Por Item' : 'Por Cuestionario'

        return {
          id: item.id,
          quest_name: item.name,
          ask: item.countQuestions,
          action_plan: action_plan,
          app: item.countQuestionnaireVisit,
        }
      })
    : []

  return { column, row }
}

export const filterMain = (value: { [x: string]: any }, list: any[]): any[] => {
  const from = value.from?.valueOf()
  const to = value.to?.valueOf()

  const startFormat = new Date(from)?.setHours(0, 0, 0, 0)
  const endFormat = new Date(to)?.setHours(23, 59, 59, 999)
  return list?.filter((item) => {
    return (
      (from ? startFormat <= item.from : true) &&
      (to ? endFormat >= (item.from ? item.from : endFormat + 10000) : true) &&
      (value?.status instanceof Array ? (value?.status.length > 0 ? value?.status.find((e: any) => e.label == item.status.text) : true) : true) &&
      (value?.divisions instanceof Array ? (value?.divisions.length > 0 ? value?.divisions.find((e: any) => e.label == item.division) : true) : true) &&
      (value?.status?.value ? item?.status?.value === value?.status?.value : true) &&
      ((value?.search ? searchInModeLower(item?.store_name, value?.search) : true) ||
        (value?.search ? searchInModeLower(item?.division, value?.search) : true) ||
        (value?.search ? searchInModeLower(item?.zone, value?.search) : true) ||
        (value?.search ? searchInModeLower(item?.class, value?.search) : true) ||
        (value?.search ? searchInModeLower(item?.keyCode, value?.search) : true) ||
        (value?.search ? searchInModeLower(item?.agent, value?.search) : true) ||
        (value?.search ? searchInModeLower(item?.status?.text, value?.search) : true))
    )
  })
}
export const filterRow = (value: { [x: string]: any }, list: any[]) => {
  return list?.filter(
    (item) =>
      (value?.status ? item?.status?.value === value?.status : true) &&
      (searchInModeLower(item?.store_name, value?.search) ||
        searchInModeLower(item?.quest_name, value?.search) ||
        searchInModeLower(item?.name, value?.search) ||
        searchInModeLower(item?.id, value?.search) ||
        searchInModeLower(item?.email, value?.search) ||
        searchInModeLower(item?.phone, value?.search) ||
        searchInModeLower(item?.address, value?.search) ||
        searchInModeLower(item?.ask, value?.search) ||
        searchInModeLower(item?.app, value?.search) ||
        searchInModeLower(item?.action_plan, value?.search) ||
        searchInModeLower(item?.keyCode, value?.search) ||
        searchInModeLower(item?.agent, value?.search) ||
        // searchInModeLower(item?.available_from, value?.search) ||
        // searchInModeLower(item?.available_to, value?.search) ||
        searchInModeLower(item?.application_date, value?.search) ||
        searchInModeLower(item?.status?.text, value?.search))
  )
}

export const handleErrors = (values: any, t: any, ignore?: string[]) => {
  let error = {} as ObjectGeneric
  if (values?.frequency !== null) {
    if (isRequired(values?.frequency)) error.frequency = 'El campo frecuencia es requerido'
  }

  if (values?.every !== null) {
    if (isRequired(values?.every)) error.every = t('general.validations.required')
  }

  if (values?.from !== null) {
    if (isNaN(values?.from)) error.from = t('general.validations.startDate')
    else if (values?.from < Date.now()) error.from = t('general.validations.minimus0minute')
  }

  if (values?.to !== null) {
    if (isNaN(values?.to)) error.to = t('general.validations.startDate')
    else if (values?.from >= values?.to || values?.to <= values?.from + 299000 || values?.to < Date.now() + 300000) error.to = t('general.validations.minimus5minute')
  }

  return error
}

export const submit = async (data: ObjectGeneric, selected: { stores: number[]; questionnaire: number[] }, t: TFunction<'translation', undefined>, id: number | undefined) => {
  let snackbarBody = {
    open: false,
    type: '',
    message: '',
  }
  let body =
    selected?.stores?.map((item) => ({
      title: data.title,
      description: data.description ?? '',
      dateStart: format(new Date(data.from <= Date.now() ? Date.now() : data.from), Constants.dateEndpoint),
      dateEnd: format(new Date(data.to <= Date.now() ? Date.now() + 300000 : data.to), Constants.dateEndpoint),
      storeId: item,
      notified: data.notified ? 1 : 0,
      frequency: data.frequency,
      every: data.every ?? null,
      questionnaires: selected.questionnaire.map((itemQ) => ({ questionnaireId: itemQ })),
      ...(data?.stores?.[item]?.length > 0 ? { userAssignId: data?.stores?.[item] } : {}),
    })) ?? []

  let response: ScheduledById
  if (id) {
    response = await ScheduleService(httpFetch).updateSchedule(body[0], id)
  } else {
    response = await ScheduleService(httpFetch).createSchedule(body)
  }

  if (response.errors) {
    snackbarBody = {
      open: true,
      type: 'error',
      message: handleAllErrors(response?.errors?.[0]?.code ?? '' as RequestErrors, t, `schedule.errors.${id ? 'updateError' : 'create'}`),
    }
  } else {
    snackbarBody = {
      open: true,
      type: 'success',
      message: t(`schedule.errors.${id ? 'updateSuccess' : 'success'}`),
    }
  }

  return {
    snackbarBody,
    response,
  }
}

export const disabled = (data: ObjectGeneric) => {
  let arrayAgentsChecket = Object.values(data?.stores ?? {})
  let agentAllChecketFalse = arrayAgentsChecket?.some((item: any) => item.length === 0)

  if ([Roles.AGENT, Roles.AUDIT].includes(getUserRole() as Roles)) agentAllChecketFalse = false

  return !(!agentAllChecketFalse && data?.from && data?.to && data?.frequency && !(data?.frequency !== Frequency.DAILY && !data.every) && data?.from > Date.now() && data?.to > data?.from)
}
