import { History } from "../../../../service/dashboard/response"
import { FilterData } from "./typing/interfaces"
import { calc30DaysAgo } from "../../../dashboard/pages/main/fragments/indicators/methods/callEndpoints"
import { TableData } from "./typing/type"
import { intervaleList } from "../../../../utils/constants/intervaleList"
import { Constants } from "../../../../utils/constants"
import { ToDate } from "../../../../utils/methods/toDate"
import { Status } from "../../../../utils/enums"
import { getStatus } from "../../../../hooks/status"
import { Color } from "../../../../utils/theme/types/color"
import { TFunction } from "react-i18next"
import { format, startOfWeek } from "date-fns"
import { es } from "date-fns/locale"

const UnitDataFilters = (payload?: TableData) => {
    let filters = []

    if (!!payload?.questionnaire?.length) {
        filters.push({ terms: { cuestionario_id: payload?.questionnaire } })
    }
    if (!!payload?.questionnaireStatus?.length) {
        let newStatusList: (Status | string)[] = payload?.questionnaireStatus.map((item) => item)
        if (newStatusList.some(item => item == Status.WAITING)) {
            newStatusList = [...newStatusList, Status.ACTIVE, Status.PAUSE, Status.REAGENDED, Status.STARTED]
        }
        filters.push({ terms: { estatus_id: newStatusList } })
    }
    if (!!payload?.area?.length) {
        filters.push({ terms: { area_id: payload?.area } })
    }
    if (!!payload?.department?.length) {
        filters.push({ terms: { departamento_id: payload?.department } })
    }
    if (!!payload?.unit?.length) {
        filters.push({ terms: { unidad_id: payload?.unit } })
    }

    if (!!payload?.to && !!payload?.from) {
        let startWeek = format(startOfWeek(ToDate(payload?.from), { weekStartsOn: 1 }), Constants.dateEndpoint2)
        let endWeek = format(payload.intervale == '1w' ? ToDate(payload?.to) : new Date(payload?.to), Constants.dateEndpoint2)
        filters.push({
            range: {
                [payload.intervale == '1w' ? 'fecha_inicio_semana' : 'fecha']: {
                    gte: payload?.from ? payload.intervale == '1w' ? startWeek : payload.from: undefined,
                    lte: payload?.to ? payload.intervale == '1w' ? endWeek : payload.to: undefined,
                },
            },
        })
    }

    return { filters }
}

const formatResponsUnitData = (response: History, colors: Color, t: TFunction<"translation", undefined>, intervale?:string) => {
    const hits =
        response?.hits?.hits.filter(item =>  !!item?._source.estatus_id)?.map((item) => {
            const source = item?._source
            const statusQuestionnaire = getStatus(source.estatus_id, colors, t)
            return {
                id: item?._id,
                area: source.area ?? "-",
                area_id: source.area_id,
                fecha: source.fecha ? format(intervale == '1w' ? new Date(source.fecha) : ToDate(source.fecha), Constants.humanFormatShort2, { locale: es }) : "",
                semana: source.semana,
                departamento: source.departamento ?? "-",
                fecha_date: source.fecha,
                departamento_id: source.departamento_id,
                estatus_id: source.estatus_id,
                unidad: source.unidad,
                usuario_asignado: source.usuario_asignado,
                por_iniciar: source.por_iniciar,
                en_progreso: source.en_progreso,
                finalizado: source.finalizado,
                incompleto: source.incompleto,
                vencido: source.vencido,
                status: statusQuestionnaire.value == Status.WAITING ? "En progreso" : statusQuestionnaire.text,
                cancelado: source.cancelado,
                cuestionario: source.cuestionario ?? "-",
                cuestionario_id: source.cuestionario_id
            }
        }) ?? []

    return { hits, }
}

const formatDataFiltered = ({ filterData }: { filterData: FilterData }) => {
    const {
        role,
        division,
        user,
        userType,
        questionnaire,
        month,
        createYear,
        area,
        department,
        zone,
        child,
        from,
        to,
        unidad_id,
        questionnaireStatus,
        percentage,
        intervale
    } = filterData
    const intervaleValue = intervale?.value ?? intervaleList[0].value
    const units = unidad_id?.map((item) => item.value)
    const roles = role?.map((item) => item.value)
    const divisions = division?.map((item) => item.value)
    const users = user?.map((item) => item.curp)
    const userTypes = userType?.map((item) => item.value)
    const questionnaires = questionnaire?.map((item) => item.value)
    const questionnaireStatusIds = questionnaireStatus?.map((item) => item.value)
    const months = month?.map((item) => item.label)
    const years = createYear?.map((item) => item.value)
    const areas = area?.map((item) => item.value)
    const departments = department?.map((item) => item.value)
    const zones = [
        ...(zone?.map((item) => item.value) ?? []),
        ...(child?.map((item) => item.value) ?? []),
    ]
    let end, start

    if (!!to || !!from) {
        const { endDate, startDate } = calc30DaysAgo({
            start: from,
            end: to,
            format: Constants.dateFormat3
        })
        end = endDate
        start = startDate
    }

    return {
        data: {
            from: start,
            to: end,
            area: areas,
            curp: users,
            department: departments,
            division: divisions,
            questionnaire: questionnaires,
            questionnaireStatus: questionnaireStatusIds,
            role: roles,
            unit: units,
            userType: userTypes,
            zone: zones,
            month: months,
            years,
            intervale: intervaleValue,
            percentage
        }
    }
}

export { UnitDataFilters, formatResponsUnitData, formatDataFiltered }
