import { useTheme } from '@mui/material'
import { Color } from '../../utils/theme/types/color'

export const useStyles = (color: Color, type?: number) => {
  const isType: boolean = type === 2
  const { breakpoints } = useTheme()
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      alignItems: 'center',
      marginBottom: '20px',
      gap: '8px',
    },
    smallFilter: {
      width: '100%',
      flexWrap: 'wrap',
      display: 'flex',
      justifyContent: 'end',
    },
    containerDetail: {
      display: 'flex',
      [breakpoints.between(0, 1100)]: {
        flexWrap: 'wrap',
      },
      columnGap: '10px',
      justifyContent: 'end',
      alignItems: 'center',
      width: '100%',
    },
    containerDetailTwo:{
      display: 'block',
      width: '100%',
    },
    labelAll: {
      '& .MuiInputLabel-root': {
        width: 'calc(100%)',
        '&.Mui-focused': {
          width: '100%',
        },
      },
    },
    widthInput: {
      '& .MuiInputLabel-root': {
        width: 'calc(100% - 45px)',
        fontSize: '15px',
        fontWeight: '400',
        '&.Mui-focused': {
          width: '100%',
        },
      },
      width: '170px',
      // maxWidth:  '300px',
      // flex: 1,
      height: '56px',
      '&.MuiTextField-root': {
        width: '160px',
      },
    },
    title: {
      flexGrow: 1,
      fontSize: '20px',
      fontWeight: '500',
    },
    tilte2: {
      flexGrow: 1,
      fontSize: '16px',
      fontWeight: '500',
      color: color.color10,
    },
    filterTitle: {
      ...(isType
        ? {
            flexGrow: 0,
            width: '50%',
            color: color.color13,
            fontSize: '13px',
          }
        : {
            fontSize: '14px',
            flexGrow: 1,
            width: 'fit-content',
          }),
      [breakpoints.between(0, 670)]: {
        width: '100%',
      },
    },
    removePadding: {
      padding: 0,
    },
    flexGrow: {
      flexGrow: 1,
      width: '10px',
    },
    refresh: {
      '& .MuiSvgIcon-root': {
        fontSize: '2rem',
      },
    },
    add: {
      '& .MuiSvgIcon-root': {
        fontSize: '2.4rem',
        color: color.color4,
      },
    },
    load: {
      '& .MuiSvgIcon-root': {
        background: color.color12,
        color: 'white',
        borderRadius: '5px',
        width: '30px',
        height: '30px',
      },
    },
  }
}
