import { useRef } from "react";

const useRefs = <T extends object>() => {
  const refsByKey = useRef<Record<string,T | null>>({})

  const setRef = (element: T | null, key: string) => {
    refsByKey.current[key] = element;
  }

  const addRef = (payload: {element: T | null; name:string}) => {
    setRef(payload.element, payload.name)
    return refsByKey.current[payload.name]
  }

  return {refsByKey: refsByKey.current, setRef, addRef};
}

export { useRefs }