import { Color } from '../../../../utils/theme/types/color'

export const useStyles = (color: Color, dataUpload?: boolean) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
  },
  containerErrorsResend: {
    marginTop: '10px',
    '& ul': {
      listStyle: "none"
    },
    '& .MuiTypography-root': {
      fontSize: '.8rem',
      color: color.color19
    }
  },
  headerTitle: {
    lineHeight: '1rem',
    color: color.color13,
    fontSize: '1.5rem',
    fontWeight: '600',
    marginBottom: '5px',
    textAlign: 'start',
  },
  titleSend: {
    fontSize: '17px',
    fontWeight: '600',
    color: color.color22,
    marginTop: '5px',
    marginBottom: '10px',
  },
  resendButton: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
  },
  headerContent: {
    display: 'flex',
    width: '100%',
    justifyContent: "end",
    background: color.color25,
    position: "relative"
  },
  titleAccordion: {
    display: 'flex',
    width: '100%',
    justifyContent: "space-between",
    position: "relative",
    paddingRight: "40px"
  },
  sectionTitle: {
    margin: '10px 0',
    fontSize: '16px',
    color: color.color10
  },
  lastUpdate: {
    lineHeight: '1rem',
    fontSize: '.8rem',
    '& span': {
      color: color.color16,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
    position: 'relative',
  },
  title: {
    fontSize: '20px',
    fontWeight: '600',
    color: color.color13,
    paddingLeft: '10px',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    '&.flex-j-e': {
      justifyContent: 'end',
    },
  },

  label: {
    fontSize: '12px',
    fontWeight: '400',
    color: color.color10,
  },
  ul: {
    paddingLeft: '20px',
    fontSize: '13px',
    color: color.color9,
    fontWeight: '500',
  },
  detail: {
    height: 'calc(100vh - 170px)',
    '& .detail-container': {
      paddingLeft: '0',
    },
  },
  ulNull: {
    fontSize: '13px',
    color: color.color9,
    fontWeight: '500',
    width: 'fit-content',
  },
  containerCrud: {
    height: '100%',
    width: '100%',
    paddingRight: '40px',
  },
  actions: {
    width: '100%',
    textAlign: 'right',
    '& .MuiIconButton-root': {
      '&.openModal svg': {
        background: color.color12,
        color: 'white',
        borderRadius: '5px',
        width: '28px',
        height: '28px',
      },
      '&.add': {
        color: color.color4,
        fontSize: '2.4rem',
        padding: '0px',
        paddingRight: '2px',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
  },
  titleGeneral: {
    width: '100%',
    fontSize: '20px',
    fontWeight: '600',
    marginBottom: '20px',
    paddingLeft: '5px',
  },
  containerTable: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative',
    padding: '50px 50px 10px 0px',
    '& .crud-filter-container': {
      paddingRight: '50px',
    },
  },
  constainerAction: {
    display: 'flex',
    columnGap: '12px',
    width: '90px',
    margin: 'auto',
  },
  table: {
    flex: 0.8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: '50px',
    paddingTop: '50px',
    width: 'inherit',
  },
  moreOptions: {
    position: 'absolute',
    top: '-2px',
    right: '-5px',
    padding: '2px'
  },
  baseMenu: {
    '& .MuiPopover-paper.MuiPopover-paper.MuiPopover-paper.MuiPopover-paper.MuiPopover-paper.MuiPopover-paper.MuiPopover-paper':
    {
      boxShadow: `1px 1px ${color.color13}30`,
    },
  },
  containerStatus: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  userDetail: {
    flex: 0.2,
  },
  subTitle: {
    fontSize: '14px',
    fontWeight: '600',
    color: color.color26,
    marginBottom: '10px',
  },
  button: {
    background: color.primary,
    '&:hover': {
      background: `${color.primary}d0`,
    }
  },
  modal: {
    width: '900px',
    minHeight: '400px',
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
    padding: '30px 0px 30px 30px',
  },
  resendModal: {
    borderRadius: 0,
    width: '500px',
  },
  resendReportContainer: {
    position: 'relative',
    padding: '30px',
  },
  detailTitle: {
    fontWeight: '700',
  },
  titleList: {
    fontSize: '.8rem',
    color: color.color13,
  },
  icons: {
    marginTop: '-8px',
    '& .MuiIconButton-root': {
      paddingLeft: '0',
      borderRadius: '10px',
      color: color.color4,
    },
    '& .MuiSvgIcon-root': {
      marginRight: '5px',
    },
    '& .MuiTypography-root': {
      fontSize: '.8rem',
    },
  },
  containerIcons: {
    display: "flex",
    alignItems: "start",
    '& .MuiIconButton-root': {
      padding: '2px',
      fontSize: '20px',
      '& .MuiSvgIcon-root': {
        fontSize: 'inherit'
      }
    },
  },
  box: {
    width: '10px',
    heigt: '10px',
  },
  close: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  actionIcon: {
    fontSize: '1.3rem',
    '& .MuiSvgIcon-root': {
      fontSize: '1em',
    },
  },
  eye: {
    color: color.color4,
    padding: 0,
  },
  edit: {
    padding: 0,
    color: color.color4,
  },
  block: {
    padding: 0,
    color: color.color19,
  },
  menuIcons: {
    marginLeft: '5px',
    fontSize: '18px',
    '& .MuiSvgIcon-root': {
      fontSize: '18px',
    },
  },
  text: {
    color: color.color13,
    fontSize: '16px',
    fontWeight: '500',
  },
  text2: {
    fontSize: '.8rem',
    color: color.color13,
  },
  titleAccordion2: {
    fontSize: '14px',
    color: color.color13,
    fontWeight: '600'
  },
  containerAccordion: {
    width: "100%",
    display: 'flex',
    flexWrap: "wrap",
    rowGap: "10px",
    maxHeight: "600px",
    overflowY: "auto",
    '& .MuiAccordion-root': {
      '&.Mui-expanded': {
        margin: 0,
      },
      background: color.color25
    }
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: "2px"
  },
  simpleModal: {
    width: '430px',
    '& .container.container': {
      margin: '0',
      marginTop: '5px',
      width: '100%',
      padding: '0',
    },
    '& .title.title': {
      borderBottom: `1px solid ${color.color13}`,
      width: '100%',
      paddingBottom: '10px',
      color: color.color13,
      textAlign: 'start',
      paddingLeft: '30px',
    },
  },
  wFull: {
    width: '100%',
    marginBottom: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '5px',
  },
  link: {
    textAlign: 'left !important',
    '& span': {
      color: color.color16,
      textDecoration: 'underline',
      '&.circle': {
        textDecoration: 'none',
        color: color.color13,
        fontSize: '6px',
        marginRight: '5px',
      },
    },

    color: color.color13,
    fontWeight: '500',
    fontSize: '12px',
    width: '100%',
  },
  infoTitle: {
    color: color.color6,
    fontWeight: '600',
    fontSize: '12px',
    '&.MuiTypography-root': {
      width: '100%',
    },
  },
  infoText: {
    color: color.color13,
    fontWeight: '500',
    fontSize: '12px',
    width: '100%',
  },
  file: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    justifyContent: 'center',
    '& .MuiIconButton-root': {
      color: color.color4,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 0,
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '5px',
    },
  },
  uploadFile: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'start',
    padding: '10px 30px 20px 30px',
  },
  modalUpload: {
    padding: '40px',
  },
  upload: {
    marginTop: '-20px',
    '& .MuiLoadingButton-root': {
      padding: '8px',
      borderRadius: '0',
      background: color.color5,
      color: color.color16,
      '&:hover': {
        background: color.color5 + 'ee',
      },
      fontSize: '1.2rem',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    '& .MuiTypography-root': {
      fontSize: '0.85rem',
      fontWeight: '500',
    },
  },
  clean: {
    '&.MuiIconButton-root': {
      fontSize: '1rem',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
  },
  textItem: {
    fontSize: '12px',
    fontWeight: '400',
  },
  section: {
    marginBottom: '32px',
  },
  hide: {
    visibility: 'hidden',
  },
  left: {
    textAlign: 'left !important',
  },
  errorInfo: {
    display: 'flex',
  },
  green: {
    color: color.color8,
    paddingRight: '10px',
    borderRight: '1px solid #00000066',
    '& p': {
      fontWeight: '600',
    },
  },
  red: {
    color: color.color28,
    paddingLeft: '10px',
    fontWeight: '600',
    '& p': {
      fontWeight: '600',
    },
  },
  download: {
    color: color.color4,
    fontSize: '11px',
    fontWeight: 'bold',
  },
  downloadIcon: {
    color: color.color4,
  },
  errorList: {
    fontSize: '12px',
    '& li': {
      wordBreak: 'break-word'
    }
  },
})
