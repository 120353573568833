import { Box, Accordion, AccordionSummary, Typography, AccordionDetails, Grid, Icon, Tab } from '@mui/material'
import { useContext, useState, useEffect, Fragment } from 'react'
import { TabContext, TabList } from '@mui/lab'
import { icons } from '../../../../../../utils/icons'
import { EvidenceCard } from '../evidenceCard'
import { useEvidences } from '../../../../../../hooks/evidences/useEvidences'
import { EvidencesTypeValueEnum } from '../../../../../../utils/enums/evidences'
import { useStyles, handleStatus } from './styles'
import { GlobalContext } from '../../../../../../contexts/Global'
import { Status } from '../../../../../../utils/enums'
import { images } from '../../../../../../utils/images'
import { Evidence } from '../../../../../../service/reportFolio/response'
import { ObjectGeneric } from '../../../../../../utils/types/generals'
import { ModelThree } from './modelThree'
import { useTranslation } from 'react-i18next'
import { useTabs } from '../../../../../../hooks/tabs/useTabs'

interface Props {
  title: string
  status?: Status.ACTIVE | Status.REJECTED | Status.AWAIT_SOLUTION | Status.RESPONDED | Status.CANCEL
  content: {
    responded: {
      title: string
      description: string
    }
    solution: {
      title: string
      rejectReason: string
      description: string
      reasonOfRejection?: string
    }
    evidences?: {
      title: string
      evidences?: Evidence[]
      evidencesRejection?: Evidence[]
    }
  }
  date?: string
}

const CustomeAccordion = (props: Props) => {
  const solveEvidences = props?.content?.evidences
  const { value: valueTab, handleChange } = useTabs({ defaultValue: 'evidences' })
  const value: 'evidences' | 'evidencesRejection' = valueTab as 'evidences' | 'evidencesRejection'
  const { evidences, existEvidences, handleChangePage, pagination } = useEvidences({ evidencesResponse: solveEvidences?.[value]})
  const { colors } = useContext(GlobalContext)
  const { t } = useTranslation()
  const styles = useStyles({ colors })
  const [choseStatus, setChoseStatus] = useState<ObjectGeneric>({})
  const dataByStatus = {
    [Status.ACTIVE]: {
      icon: <img src={images.fire} alt="" />,
      styles: handleStatus({ color: colors.color19 }),
    },
    [Status.REJECTED]: {
      icon: icons('simple-close'),
      styles: handleStatus({ color: colors.color19 }),
    },
    [Status.AWAIT_SOLUTION]: {
      icon: icons('bell-rounded'),
      styles: handleStatus({ color: colors.color5 }),
    },
    [Status.RESPONDED]: {
      icon: icons('bell-rounded'),
      styles: handleStatus({ color: colors.color5 }),
    },
    [Status.APPROVED]: {
      icon: icons('check'),
      styles: handleStatus({ color: colors.color8 }),
    },
    [Status.CANCEL]: {
      icon: icons('simple-close'),
      styles: handleStatus({ color: colors.color19 }),
    },
  }
  
  useEffect(() => {
    setChoseStatus(dataByStatus?.[props?.status ?? Status.REJECTED] || dataByStatus[Status.REJECTED])
    // eslint-disable-next-line
  }, [props?.status])

  return (
    <Box sx={styles.container}>
      <Accordion>
        <AccordionSummary sx={[styles.accordionSumary, props.status === Status.RESPONDED ? styles.solution : {}]} expandIcon={icons('arrow-right')} aria-controls="panel1a-content" id="panel1a-header">
          <Box sx={styles.titleAccordion}>
            <Box>{!!props.status && <Icon sx={[styles.icon, choseStatus?.styles ?? {}]}>{choseStatus?.icon}</Icon>}</Box>

            <Typography>{props.title}</Typography>
          </Box>
        </AccordionSummary>

        <AccordionDetails sx={styles.accordionDetails}>
          <Box sx={styles.flex}>
            <Box className="full-w">
              <Typography>{props.content.responded.title}</Typography>

              <Typography className="bold">{props.content.responded.description}</Typography>
            </Box>

            <Box className="full-w">
              <Typography>{props.content.solution.title}</Typography>

              <Typography className="bold">{props.content.solution.description}</Typography>
            </Box>

            {!!props.content.solution.reasonOfRejection &&
              <Box className="full-w">
                <Typography>{props.content.solution.rejectReason}</Typography>

                <Typography className="bold">{props.content.solution.reasonOfRejection}</Typography>
              </Box>
            }

            {(!!solveEvidences?.evidencesRejection?.length) && (
              <TabContext value={value}>
                <TabList sx={styles.TabList} onChange={handleChange}>
                  <Tab label={t('reportFolio.detail.general.solveEvidences').toUpperCase()} value={`evidences`} />
                  <Tab label={t('reportFolio.detail.general.rejectEvidences').toUpperCase()} value={`evidencesRejection`} />
                </TabList>
              </TabContext>

            )}

            {!!solveEvidences?.[value]?.length && !!existEvidences ? (
              <Box className="full-w">
                <Typography>{solveEvidences?.title ?? ''}</Typography>

                <Grid container columnSpacing={2} mt={1}>
                  {!!evidences?.images?.length && <EvidenceCard evidences={evidences.images} handleChangePage={handleChangePage} pagination={pagination.image} type={EvidencesTypeValueEnum.IMAGE} />}

                  {!!evidences?.video?.length && <EvidenceCard evidences={evidences.video} handleChangePage={handleChangePage} pagination={pagination.video} type={EvidencesTypeValueEnum.VIDEO} />}

                  {!!evidences?.documents?.length && (
                    <>
                      <Typography sx={styles.title}>{t('reportFolio.detail.general.documents')}</Typography>

                      <Box sx={[styles.documentsContainer]}>
                        {evidences?.documents?.map((itemDocuments, index) => (
                          <Box
                            key={index}
                            sx={styles.documentItems}
                            onClick={() => {
                              window.open(itemDocuments?.src, 'blank')
                            }}
                          >
                            <Typography sx={styles.documentName}>{itemDocuments?.name}</Typography>

                            <Icon>{icons('visibility')}</Icon>
                          </Box>
                        ))}
                      </Box>
                    </>
                  )}
                  {!!evidences?.comments?.length && (
                    <>
                      <Typography sx={styles.title}>{t('reportFolio.detail.general.comments')}</Typography>

                      <Box sx={[styles.comments, styles.paddingComments]}>
                        {evidences?.comments?.map((itemComments, index) => (
                          <Fragment key={index}>
                            <ModelThree title={itemComments.name ?? ''} description={''} date={''} />
                          </Fragment>
                        ))}
                      </Box>
                    </>
                  )}
                </Grid>
              </Box>
            ) : <Typography sx={styles.noData}>{props.content.evidences?.evidencesRejection?.length ? t('reportFolio.detail.general.noInformation') : ''}</Typography>
            }
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export { CustomeAccordion }
