import { Box, FormControlLabel, Button, Grid, Typography, Tooltip, Icon } from '@mui/material'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { IOSSwitch } from '../../../../../../../components/dinamicForm/switch'
import { useStyles } from '../styles'
import { TabDepartment } from '../../../typing/enums'
import { Item } from '../../../../../../../utils/types/generals'
import { icons } from '../../../../../../../utils/icons'
import { Roles } from '../../../../../../../utils/enums'

const useTables = (props: { tab: string; handleFolioSetting: (data?: Record<string, Item[]> | undefined) => void; userTable: { [x: string]: any }[] }) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const [openModal, setOpenModal] = useState<{
    open: boolean
    title: string
    description: string
    id: string | number
  }>({
    id: '',
    open: false,
    title: '',
    description: '',
  })
  const [userFolioSelected, setUserFolioSelected] = useState<
    Record<
      string,
      {
        status: boolean
        reassignFolio?: boolean
        settings?: {
          users: Item[]
          usersTypes: Item[]
          stores: Item[]
        }
      }
    >
  >({})

  const [userSelected, setUserSelected] = useState<Record<string, boolean>>({})

  const handleSelectUser = ({ id, value, skipCondition }: { id: string; value: boolean; skipCondition?: boolean }) => {
    let userSelectedCopy = structuredClone(userSelected)

    if (!!userFolioSelected?.[id]?.settings && !skipCondition) {
      setOpenModal((prev) => ({
        ...prev,
        open: true,
        id,
        title: t('department.form.modal.deleteSettingsTitle'),
        description: t('department.form.modal.deleteSettingsDescription'),
      }))
    } else {
      if (!!value) {
        setUserFolioSelected((prev) => ({
          ...prev,
          [id]: {
            ...prev[id],
            status: false,
          },
        }))

        userSelectedCopy = {
          ...userSelectedCopy,
          [id]: value,
        }
      } else {
        setUserFolioSelected((prev) => {
          const copyPrev = structuredClone(prev)
          delete copyPrev[id]
          return copyPrev
        })

        delete userSelectedCopy[id]
      }

      setUserSelected(userSelectedCopy)
    }
  }

  const setAllUsers = ({ newSelected, skip, filter, unmark }: { skip?: boolean; filter?: Record<string, any>[]; newSelected: Record<string, boolean>; unmark?: boolean }) => {
    const existSomeConfig = filter?.some((item) => !!userFolioSelected[item.id]?.settings)
    
    if (!!existSomeConfig && !skip) {
      setOpenModal((prev) => ({
        ...prev,
        open: true,
        id: 'all',
        title: t('department.form.modal.deleteSettingsTitle'),
        description: t('department.form.modal.deleteSettingsDescription'),
      }))
    } else {
      if(!!unmark) {
        setUserFolioSelected({})
      }
      setUserSelected(newSelected)
    }
  }

  const [openDrawer, setOpenDrawer] = useState<{
    setting: boolean
    user: {
      id: number | string
      name: string
      curp: string
    }
  }>({
    setting: false,
    user: {
      id: '',
      name: '',
      curp: '',
    },
  })

  const closeModal = () => {
    setOpenModal((prev) => ({
      ...prev,
      open: false,
    }))
  }

  const handleSelect = ({ id, value, skipCondition, name = 'status' }: { id: string; value: boolean; skipCondition?: boolean, name?: 'reassignFolio' | 'status' }) => {
    if (!!userFolioSelected?.[id]?.settings && !!value && !skipCondition) {
      setOpenModal((prev) => ({
        ...prev,
        open: true,
        id,
        title: t('department.form.modal.deleteSettingsTitle'),
        description: t('department.form.modal.deleteSettingsDescription'),
      }))
    } else {
      setUserFolioSelected((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          [name]: value,
        },
      }))
    }
  }

  const closeDrawer = () => {
    setOpenDrawer((prev) => ({
      ...prev,
      setting: false,
    }))
  }

  const handleOpenDrawer = ({
    user,
  }: {
    user: {
      id: number | string
      name: string
      curp: string
    }
  }) => {
    const userSelectedCopy = structuredClone(userFolioSelected)

    props.handleFolioSetting(userSelectedCopy?.[user.id]?.settings)

    setOpenDrawer((prev) => ({
      ...prev,
      setting: true,
      user,
    }))
  }

  const setSettings = ({
    settings,
    id,
  }: {
    id: string | number
    settings?: {
      users: Item[]
      usersTypes: Item[]
      stores: Item[]
    }
  }) => {
    setUserFolioSelected((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        settings,
      },
    }))
  }

  const [row, setRow] = useState<
    {
      id: number | string
      name: string
      curp: string
      role: number | string
      userType: number | string
    }[]
  >([])

  const getUsersThatCanManageFolios = () => {
    let newSelectedUser: Record<
      string,
      {
        status: boolean
        reassignFolio?: boolean
        settings?: {
          users: Item[]
          usersTypes: Item[]
          stores: Item[]
        }
      }
    > = structuredClone(userFolioSelected)

    const newRow: {
      id: number | string
      name: string
      curp: string
      role: number | string
      roleName: string
      userType: number | string
    }[] = []

    Object.entries(userSelected)?.forEach(([key, item]) => {
      const user = props?.userTable?.find((itemUsers) => itemUsers.id?.toString() == key)

      if (!!item && !!user) {
        if (newSelectedUser[key]?.reassignFolio === undefined) {
          newSelectedUser = {
            ...newSelectedUser,
            [key]: {
              ...newSelectedUser[key],
              reassignFolio: (user?.role === Roles.SUPERVISOR || user?.role == Roles.GERENT)
            },
          }
        }

        if (!newSelectedUser[key]) {
          newSelectedUser = {
            ...newSelectedUser,
            [key]: {
              ...newSelectedUser[key],
              status: false,
            },
          }
        } else {
          newSelectedUser = { ...newSelectedUser, [key]: newSelectedUser[key] }
        }

        newRow.push({
          curp: user?.curp ?? '',
          id: user?.id ?? '',
          name: user?.name ?? '',
          role: user?.role ?? '',
          roleName: user?.roleName ?? '',
          userType: user?.userType ?? '',
        })
      }
    })

    setRow(
      newRow?.sort((a) => {
        if (!!newSelectedUser?.[a.id]?.status) {
          return -1
        }
        return 1
      })
    )
    setUserFolioSelected(newSelectedUser)
  }

  const setAllSettingsFolioUser = (data: {
    skip?: boolean
    filter?: Record<string, any>[]
    userFolio: Record<
      string,
      {
        status: boolean
        settings?:
        | {
          users: Item[]
          usersTypes: Item[]
          stores: Item[]
        }
        | undefined
      }
    >
  }) => {
    const existSomeConfig = data?.filter?.some((item) => !!userFolioSelected[item.id]?.settings)

    if (!!existSomeConfig && !data.skip) {
      setOpenModal((prev) => ({
        ...prev,
        open: true,
        id: 'all',
        title: t('department.form.modal.deleteSettingsTitle'),
        description: t('department.form.modal.deleteSettingsDescription'),
      }))
    } else {
      setUserFolioSelected(data.userFolio)
    }
  }
  const columnUser = [
    {
      id: 'name',
      label: t('general.tags.nameAndLastName'),
      sortable: true,
      minWidth: 100,
      column: () => {
        return (
          <Typography sx={styles.headetText}>
            {t('general.tags.nameAndLastName')} (Rol)
          </Typography>
        )
      },
      renderCell: (params: any) => {
        const row = params.row

        return (
          <Typography sx={styles.unitContent} className="w-full">
            {row.name} {!!row.roleName ? `(${row.roleName})` : ""}
          </Typography>
        )
      }
    },
    {
      id: 'curp',
      label: t('general.tags.curp'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'action',
      label: t('department.form.tabs.folio'),
      minWidth: 100,
      renderCell: (params: any) => {
        return (
          <Box sx={styles.action}>
            <FormControlLabel
              sx={styles.label}
              control={
                <IOSSwitch
                  name="notified"
                  checked={!!userSelected?.[params?.row?.id]}
                  onChange={({ target }) => {
                    const { checked } = target
                    handleSelectUser({ id: params?.row?.id, value: checked })
                  }}
                  sx={{ m: 1 }}
                />
              }
              label={t('department.form.table.switch')}
            />
          </Box>
        )
      },
    },
  ]

  const column = [
    {
      id: 'name',
      label: t('general.tags.nameAndLastName'),
      sortable: true,
      minWidth: 100,
      column: () => {
        return (
          <Typography sx={styles.headetText}>
            {t('general.tags.nameAndLastName')} (Rol)
            <br />
            {t('general.tags.curp')}
          </Typography>
        )
      },
      renderCell: (params: any) => {
        const user = params.current
        const curp = params.row?.curp

        return (
          <Box sx={styles.containerUnit}>
            {!!user || !!curp ? (
              <>
                {!!user && (
                  <Typography sx={styles.unitContent} className="w-full">
                    {user} {!!params.row?.roleName ? `(${params.row?.roleName})`: ""}
                  </Typography>
                )}

                {!!curp && (
                  <Typography sx={styles.user} className="w-full">
                    {curp}
                  </Typography>
                )}
              </>
            ) : (
              <Typography sx={styles.user} className="w-full">
                N/A
              </Typography>
            )}
          </Box>
        )
      },
    },
    {

      id: 'action',
      label: t('department.form.tabs.folio'),
      minWidth: 100,
      renderCell: (params: any) => {
        return (
          <Box sx={styles.action}>
            <FormControlLabel
              sx={styles.label}
              control={
                <IOSSwitch
                  name="notified"
                  checked={!!userFolioSelected?.[params?.row?.id]?.status}
                  onChange={({ target }) => {
                    const { checked } = target
                    handleSelect({ id: params?.row?.id, value: checked })
                  }}
                  sx={{ m: 1 }}
                />
              }
              label={t('department.form.table.allFolios')}
            />

            <FormControlLabel
              sx={styles.label}
              control={
                <IOSSwitch
                  name="notified"
                  checked={!!userFolioSelected?.[params?.row?.id]?.reassignFolio}
                  onChange={({ target }) => {
                    const { checked } = target
                    handleSelect({ id: params?.row?.id, value: checked, name: 'reassignFolio', skipCondition: true })
                  }}
                  sx={{ m: 1 }}
                />
              }
              label={t('department.form.table.reassingFolios')}
            />
          </Box>
        )
      },
    },
    {
      id: 'store',
      label: t('general.tags.store'),
      minWidth: 100,
      contentColumn: {
        justifyContent: 'center',
      },
      renderCell: (params: any) => {
        const stores = userFolioSelected?.[params?.row?.id]?.settings?.stores

        return !!stores?.length ? (
          <Grid container justifyContent="center" alignItems="center">
            <Grid sx={styles.users} item xl={10} lg={10} md={10} sm={10} xs={10}>
              <Typography sx={styles.bodyText}>{stores[0]?.label ?? ''}</Typography>
            </Grid>
            <Grid sx={styles.containerIconTooltip} item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Tooltip
                title={
                  <>
                    <ul style={styles.ul}>
                      {stores?.map((el, index) => (
                        <li key={index}>
                          <Typography sx={styles.ulNull}>{el.label}</Typography>
                        </li>
                      ))}
                    </ul>
                  </>
                }
              >
                <Icon sx={styles.viewMore}>{icons('add')}</Icon>
              </Tooltip>
            </Grid>
          </Grid>
        ) : (
          <Typography sx={styles.bodyText}>{stores?.[0]?.label ?? 'N/A'}</Typography>
        )
      },
    },
    {
      id: 'user',
      label: t('general.tags.user'),
      minWidth: 100,
      contentColumn: {
        justifyContent: 'center',
      },
      renderCell: (params: any) => {
        const users = userFolioSelected?.[params?.row?.id]?.settings?.users

        return !!users?.length ? (
          <Grid container justifyContent="center" alignItems="center">
            <Grid sx={styles.users} item xl={10} lg={10} md={10} sm={10} xs={10}>
              <Typography sx={styles.bodyText}>{users[0]?.label ?? ''}</Typography>
            </Grid>
            <Grid sx={styles.containerIconTooltip} item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Tooltip
                title={
                  <>
                    <ul style={styles.ul}>
                      {users?.map((el, index) => (
                        <li key={index}>
                          <Typography sx={styles.ulNull}>{el.label}</Typography>
                        </li>
                      ))}
                    </ul>
                  </>
                }
              >
                <Icon sx={styles.viewMore}>{icons('add')}</Icon>
              </Tooltip>
            </Grid>
          </Grid>
        ) : (
          <Typography sx={styles.bodyText}>{users?.[0]?.label ?? 'N/A'}</Typography>
        )
      },
    },
    {
      id: 'userType',
      label: t('general.tags.userType'),
      minWidth: 100,
      contentColumn: {
        justifyContent: 'center',
      },
      renderCell: (params: any) => {
        const userType = userFolioSelected?.[params?.row?.id]?.settings?.usersTypes

        return !!userType?.length ? (
          <Grid container justifyContent="center" alignItems="center">
            <Grid sx={styles.users} item xl={10} lg={10} md={10} sm={10} xs={10}>
              <Typography sx={styles.bodyText}>{userType[0]?.label ?? ''}</Typography>
            </Grid>
            <Grid sx={styles.containerIconTooltip} item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Tooltip
                title={
                  <>
                    <ul style={styles.ul}>
                      {userType?.map((el, index) => (
                        <li key={index}>
                          <Typography sx={styles.ulNull}>{el.label}</Typography>
                        </li>
                      ))}
                    </ul>
                  </>
                }
              >
                <Icon sx={styles.viewMore}>{icons('add')}</Icon>
              </Tooltip>
            </Grid>
          </Grid>
        ) : (
          <Typography sx={styles.bodyText}>{userType?.[0]?.label ?? 'N/A'}</Typography>
        )
      },
    },
    {
      id: 'settings',
      label: t('department.form.tabs.settings'),
      minWidth: 50,
      contentColumn: {
        justifyContent: 'center',
      },
      renderCell: (params: any) => {
        return (
          <Box sx={styles.setting}>
            {!userFolioSelected?.[params?.row?.id]?.status && (
              <Button
                onClick={() => {
                  handleOpenDrawer({
                    user: {
                      id: params?.row?.id,
                      curp: params?.row?.curp,
                      name: params?.row?.name,
                    },
                  })
                }}
              >
                {t('department.form.table.setting')}
              </Button>
            )}
          </Box>
        )
      },
    },
  ]

  useEffect(() => {
    if (props.tab === TabDepartment.FOLIO) {
      getUsersThatCanManageFolios()
    }
    //eslint-disable-next-line
  }, [props?.tab])

  return { userSelected, columnUser, row, column, openDrawer, openModal, userFolioSelected, closeDrawer, setSettings, closeModal, handleSelect, handleOpenDrawer, setAllUsers, handleSelectUser, setAllSettingsFolioUser }
}

export { useTables }
