import { Badge, Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { icons } from "../../../../../../utils/icons";
import { useStyles } from "./styles";
import Loader from "../../../../../../components/loader";
import { ProgressListProps, ProgressListRef } from "./typing/interfaces";
import { DinamicForm } from "../../../../../../components/dinamicForm";
import { useTranslation } from "react-i18next";
import { Ref, forwardRef, useImperativeHandle, useState } from "react";
import BaseModal from "../../../../../../components/modal/baseModal";
import { useFilter } from "../../../../../../hooks/filter";
import { ColorItemList } from "../colorItems";
import { statusList } from "./tets";
import { CardCake } from "../cardCake";
import { Tooltip2 } from "../tooltip/type2";
import { cakeSetting } from "./constants";
import { TooltipProps } from "recharts";

const SettingTooltip = (content: TooltipProps<any, any>) => {
  const payload = content?.payload?.[0]?.payload

  if (!payload) return null
  return <Tooltip2 points={payload?.value} label={payload?.name} type={payload?.type} percentage={payload.customeLabel} />
}

const ProgressList = forwardRef((props: ProgressListProps, ref: Ref<ProgressListRef>) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const filter = useFilter({ formatFilter: {} })
  const [modal, setModal] = useState({ open: false })
  const openFiltersModal = () => { setModal(prev => ({ ...prev, open: true })) }
  const closeFiltersModal = () => { setModal(prev => ({ ...prev, open: false })) }

  const filterButton = (noData?: boolean) => {
    setModal({ open: false })
    props.filter(!noData ? filter.filterData : {}, !noData ? filter.counter : 0)
  }
  const cleanFilter = () => {
    filter.cleanFilter()
    filterButton(true)
  }
  useImperativeHandle(ref, () => ({
    cleanFilter: () => {
      filter.cleanFilter()
    }
  }))

  const selectPie = ({ id, label }: { id: string; label: string }) => {
    if (props.selectedArea) {
      props.selectedArea({ id: id, label: label });
    }
  }

  return (
    <Box sx={styles.containerIndicatorsProgress}>
      <Box sx={styles.headerProgress}>
        <Box sx={styles.header}>
          <Box>
            <Typography sx={styles.title}>{props.header.title}</Typography>

            <Typography sx={styles.dateFooter}>
              Última actualización: <span>{props.data.lastDate}</span>
            </Typography>

            {!!props.header.subtitle && (
              <Typography sx={styles.subtitle}>
                {t('general.selectedArea')}: {props.header.subtitle}
              </Typography>
            )}
          </Box>

          <Box sx={styles.containerIcons}>
            {!!props.counter &&
              <Button
                onClick={() => { cleanFilter() }}
                variant="text"
                sx={[styles.footerText, styles.cleanText]}
              >
                {t('general.cleanFilters')}
              </Button>
            }
            {/* <LoadingButton sx={styles.download} variant="contained" loading={loading} onClick={() => {
              downloadScreenshot()
            }}>{icons('download')}
            </LoadingButton> */}
            
            <Badge sx={styles.badge({})} badgeContent={props.counter}>
              <IconButton
                sx={styles.filter}
                onClick={() => {
                  openFiltersModal()
                }}
              >
                {icons('filter')}
              </IconButton>
            </Badge>

            <IconButton
              sx={styles.refresh}
              onClick={() => {
                props.refresh?.(filter.filterData);
              }}
            >
              {icons("refresh")}
            </IconButton>
          </Box>
        </Box>
      </Box>
      {/* <div id="download-screnshot" hidden style={{position: 'absolute', top: '0px', width: '100%', left: '-1000px'}}><DownLoadView ref={refc} statusList={statusList} {...props} /></div> */}
      <Box>
        <ColorItemList colorStatus={statusList} />

        <Grid sx={styles.cardContainer} container spacing={2}>
          {!!props.data.isLoading ? (
            <Loader />
          ) :
            <>
              {!!props.data.data?.length && (
                props.data.data.map(item => (
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <CardCake
                      id={item.id}
                      label={item.label}
                      total={item.total}
                      data={item.colorStatus}
                      errorMessage={t("general.noInformationSome").replace("{key}", props.header.title.toLowerCase())}
                      tooltip
                      isActive={!!(props.data.isActive == item.id) && !!props.arrow}
                      tooltipProps={{ content: SettingTooltip }}
                      eye={!!props.arrow}
                      eyeProps={{ onClick: () => { selectPie({ id: item.id, label: item.label }) } }
                      }
                      cakeSize={cakeSetting}
                    />
                  </Grid>
                ))
              )}

              {!props.data.data?.length && (<Typography sx={styles.noData}>{t("general.noInformation")}</Typography>)}
            </>
          }
        </Grid>
      </Box>

      <BaseModal sx={{ borderRadius: '0px' }} open={modal.open}>
        <Box sx={styles.containerModal}>
          <Box sx={styles.headerModal}>
            <Typography sx={styles.headerModalTitle}>{props.header.title}</Typography>
          </Box>

          <IconButton onClick={() => { closeFiltersModal() }} sx={styles.close}>{icons('simple-close')}</IconButton>

          <DinamicForm
            legacyErrors
            errors={filter.errors}
            input={Object.values(props.input)}
            values={filter.filterData}
            onChange={filter.onChange}
          />

          <Button
            variant="contained"
            onClick={() => {
              filterButton()
            }}
            sx={styles.btnFilter}>
            {t('general.filter')}
          </Button>
        </Box>
      </BaseModal>
    </Box>
  )
});

export { ProgressList };
