import { TFunction } from 'react-i18next'
import { getStatus } from '../../../../hooks/status'
import { UserService } from '../../../../service'
import { sharedAws } from '../../../../service/bucketS3/Storage'
import { httpFetch } from '../../../../utils/crud'
import { Roles, Status, Types } from '../../../../utils/enums'
import { Color } from '../../../../utils/theme/types/color'
import { InputModel } from '../../../../utils/types'
import {
  searchInModeLower,
  min,
  isPhoneNumber,
} from '../../../../utils/validate/validate'
import { ObjectGeneric } from '../../../../utils/types/generals'
import { userServiceFetch } from '../../../../utils/constants/callService'
import { handleAllErrors } from '../../../../utils/methods/handleErrors'

export const getUserList = async (
  colors: Color,
  t: TFunction<'translation', undefined>,
  body?: { queary: string }
) => {
  const data = await UserService(httpFetch).userDetail({ query: body?.queary })

  const column: any[] = [
    {
      id: 'curp',
      label: t('general.tags.curp'),
      sortable: true,
      minWidth: 120,
    },
    {
      id: 'firstName',
      label: t('general.tags.name'),
      sortable: true,
      minWidth: 120,
    },
    {
      id: 'lastName',
      label: t('general.tags.lastName'),
      sortable: true,
      minWidth: 120,
    },
    {
      id: 'gender',
      label: t('general.tags.gender'),
      sortable: true,
      minWidth: 120,
    },
    {
      id: 'role',
      label: t('general.tags.role'),
      minWidth: 120,
      // sortable: true,
    },
    {
      id: 'userTypeLabel',
      label: t('general.tags.userType'),
      minWidth: 120,
    },
    {
      id: 'email',
      label: t('general.tags.email'),
      minWidth: 120,
    },
    {
      id: 'phone',
      label: t('general.tags.phone'),
      minWidth: 120,
    },
    {
      id: 'status',
      label: t('general.tags.status'),
      minWidth: 120,
    },
  ]

  const row: any = !data?.errors
    ? data?.items?.map((item) => {
      let status: Status = item?.statusId


      let phone = item.phone ?? ''

      return {
        id: item?.id,
        curp: item?.curp,
        firstName: item?.firstName,
        role: item.role,
        lastName: item?.lastName,
        userTypeLabel: item.userType,
        user: item.email,
        gender: item?.gender >= 0 && {
          value: ((item?.gender ?? 0) + 1).toString(),
          label:
            item?.gender >= 0 &&
            t(
              `general.fields.gender.${item?.gender > 1 ? '3' : item?.gender + 1
              }`
            ),
        },
        email: item?.email,
        phone: phone,
        status: getStatus(status, colors, t),
      }
    })
    : []

  const { currentPage, totalItems, totalPages } = data
  return { column, row, currentPage, totalItems, totalPages }
}

export const filterRow = (value: { [x: string]: any }, list: any[]) => {
  return list?.filter((item) => {
    return (
      (value?.search
        ? searchInModeLower(item?.firstName, value?.search)
        : true) ||
      (value?.search
        ? searchInModeLower(item?.lastName, value?.search)
        : true) ||
      (value?.search ? searchInModeLower(item?.email, value?.search) : true) ||
      (value?.search ? searchInModeLower(item?.curp, value?.search) : true) ||
      (value?.search
        ? searchInModeLower(item?.role?.label, value?.search)
        : true) ||
      (value?.search ? searchInModeLower(item?.phone, value?.search) : true) ||
      (value?.search
        ? searchInModeLower(item?.userTypeLabel, value?.search)
        : true) ||
      (value?.search
        ? searchInModeLower(item?.gender?.label, value?.search)
        : true)
    )
  })
}

export const handleErrors = (values: any, t: any, curpExist?: boolean) => {
  let error = {} as {
    curp: string
  }

  if (values?.curp?.value !== null) {
    // if (isRequired(values?.curp?.value)) error.curp = t('general.validations.required')
    if (values?.curp?.value?.length > 0 && min(values?.curp?.value, 10))
      error.curp = `El campo CURP debe contener mínimo 10 ${t(
        'general.validations.character'
      )}`
    else if (curpExist) error.curp = t('general.validations.existedCurp')
  }
  return error
}

export const canListUnits = ({ role }: { role: Roles }) => {
  let label = ''
  if ([Roles.PARTNER, Roles.STORE].includes(role)) {
    label = `general.tags.storeList`
  } else {
    label = `general.tags.agentList`
  }
  return {
    isRoleStore: role === Roles.STORE,
    store: [Roles.PARTNER, Roles.STORE].includes(role),
    typeSelect: [Roles.PARTNER].includes(role) ? 'select' : 'multiSelect',
    label,
  }
}

export const submit = async (
  data: { [x: string]: InputModel },
  t: TFunction<'translation', undefined>,
  id: number | undefined,
  oldCurp?: string
) => {
  const username = data?.username?.value
  let snackbarBody = {
    open: false,
    type: '',
    message: '',
  }
  let body: ObjectGeneric = {}
  let partnerStore = {
    id: id,
  } as { [x: string]: any }

  Object.entries(data).forEach(([key, value]) => {
    if (value) {
      const dataToSubmit = [
        'curp',
        'firstName',
        'lastName',
        'email',
        'companyId',
        'userType',
        'role',
        'gender',
      ]
      if (dataToSubmit.includes(key) && value.value) {
        body = {
          ...body,
          [key]: value.value,
        }
      } else if (key === 'phone') {
        body = {
          ...body,
          [key]: isPhoneNumber(value.value) ? value.value : '',
        }
      } else if (key === 'accessFolioReportGeneral') {
        body = {
          ...body,
          [key]: value.value,
        }
      }
    }
  })

  let response: any

  body = {
    ...body,
    status: '1',
    ...(body?.userType || id ? { userType: body?.userType ?? null } : {}),
    division: data?.divisions?.value?.value
      ? { id: data?.divisions?.value?.value }
      : null,
    gender: parseInt(body.gender ?? 1) - 1,
    picture: data?.picture?.value?.name
      ? await sharedAws.uploadAndGetUrl({file: data.picture.value, name: data.firstName.value, otherBucket: process.env.REACT_APP_BUCKET})
      : data?.picture?.value,
  }
  let responseCurp: { curpExists: boolean } = { curpExists: false }

  if (!body.curp || body?.curp?.length < 10) {
    body = {
      ...body,
      curp: '0000000000',
    }
  } else {
    if (body?.curp !== oldCurp) {
      responseCurp = await userServiceFetch.curpExist(body?.curp)
    }
  }

  if (!responseCurp?.curpExists) {
    body = {
      ...body,
      username: username === '0' ? data.email.value : data.phone.value,
    }
    // if (!body.phone) delete body.phone

    let copyBody = structuredClone(body)
    
    if (!!body?.role) {
      copyBody = { ...copyBody, roleId: body.role }
    }

    if (!!body?.userType) {
      copyBody = { ...copyBody, userTypeId: body.userType }
    }

    copyBody = { ...copyBody, divisionId: body?.division?.id ?? null }


    delete copyBody.role
    delete copyBody.userType
    delete copyBody.division

    if (id) {
      delete body[username === '0' ? 'email' : 'phone']

      response = await UserService(httpFetch).updateUser(
        copyBody,
        id,
      )
    } else {
      response = await userServiceFetch.createUser(copyBody, false)
    }

    
    if (!!response?.errors) {
      let message = handleAllErrors(response?.errors?.[0]?.code, t, `user.snackbar.${id ? 'update' : 'create'}.error`)
      
      snackbarBody = {
        open: true,
        type: Types.ERROR as string,
        message,
      }
    } else {
      snackbarBody = {
        open: true,
        type: Types.SUCCESS as string,
        message: t(`user.snackbar.${id ? 'update' : 'create'}.success`),
      }
    }
    const valueStore = data?.partnerStore?.value

    if (!response?.errors && valueStore) {
      const isNumber = !!(typeof valueStore === 'number')
      const permissions = canListUnits({ role: body.role })

      const listUsers = !!permissions.isRoleStore
        ? data?.agents?.value
        : valueStore

      if (!permissions.store || !!permissions.isRoleStore) {
        partnerStore = {
          ...partnerStore,
          users: {
            usersChildren: listUsers?.map((item: any) => {
              return {
                userId: item.value,
                roleId: item.roleId,
              }
            }),
            userId: response?.user?.id ?? response?.data?.id ?? id,
            roleId: response?.user?.role ?? response?.data?.roleId ?? copyBody?.roleId,
          },
        }
      }

      if (permissions.store) {
        partnerStore = {
          ...partnerStore,
          id: response?.user?.id ?? response?.data?.id ?? id,
          stores: isNumber
            ? [valueStore]
            : valueStore.map((item: any) => item.value),
        }
      }

      if (
        !!partnerStore.stores?.length ||
        !!partnerStore.users?.usersChildren?.length ||
        !!id
      ) {
        let responseAssociateStores

        if (!!permissions.store) {
          responseAssociateStores = await UserService(
            httpFetch
          ).asociateUserStores({
            id: partnerStore.id ?? id,
            stores: partnerStore.stores,
          })
        }

        if (!permissions.store || permissions.isRoleStore) {
          responseAssociateStores = await UserService(httpFetch).asociateUser(
            partnerStore.users
          )
        }

        if (responseAssociateStores?.errors) {
          snackbarBody = {
            open: true,
            type: 'error',
            message: t(
              `user.snackbar.create.${!permissions.store
                ? 'errorAssociateUsers'
                : permissions.isRoleStore
                  ? 'errorAssociates'
                  : 'errorAssociate'
              }`
            ),
          }
        }
      }
    }

    return {
      snackbarBody,
      response: response.errors
        ? {
          errors: response.errors?.response?.data?.error ?? response.errors,
        }
        : response,
    }
  }
  return { curpExist: true }
}

export const formatAssociateHierarchyTable = (
  items: any[],
  t: TFunction<'translation', undefined>,
  colors: Color,
  agent?: any
) => {
  const column = [
    {
      id: 'name',
      label: t('general.tags.name'),
      sortable: true,
      minWidth: 220,
    },
    {
      id: 'role',
      label: t('general.tags.role'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'curp',
      label: t('general.tags.curp'),
      sortable: true,
      minWidth: 150,
    },
  ]
  return {
    column,
    row:
      items?.map((item: any) => {
        return {
          name: `${item.firstName} ${item.lastName}` ?? '',
          role: item.role ?? '',
          keyCode: item.keyCode ?? 'N/A',
          curp: item?.curp,
        }
      }) ?? [],
  }
}

export const formatAssociateStoreTable = (
  items: any[],
  t: TFunction<'translation', undefined>,
  colors: Color,
  isSupervisor?: boolean,
  agent?: any
) => {
  const column = isSupervisor
    ? [
      {
        id: 'name',
        label: t('general.tags.name'),
        sortable: true,
      },
      {
        id: 'curp',
        label: t('general.tags.curp'),
        sortable: true,
      },
    ]
    : [
      {
        id: 'keyCode',
        label: t('general.tags.keyCode'),
        sortable: true,
        minWidth: 100,
      },
      {
        id: 'name',
        label: t('general.tags.name'),
        sortable: true,
        minWidth: 175,
      },
      {
        id: 'gerente',
        label: t('general.tags.manager'),
        sortable: true,
        minWidth: 175,
      },
    ]
  return {
    column,
    row:
      items?.map((item: any) => {
        const gerente = `${item.userManage?.firstName ?? ''} ${item.userManage?.lastName ?? ''
          }`

        return {
          name:
            (isSupervisor
              ? `${item.agentUser?.firstName} ${item.agentUser?.lastName}`
              : item.name) ?? '',
          address: item.address ?? '',
          keyCode: item.keyCode ?? 'N/A',
          phone: item.phone ?? '',
          curp: item?.agentUser?.curp,
          status: getStatus(item?.status?.id, colors, t),
          gerente,
          agent: agent,
        }
      }) ?? [],
  }
}

export const formatSupervisorTable = (
  items: any[],
  t: TFunction<'translation', undefined>,
  colors: Color
) => {
  const column = [
    {
      id: 'name',
      label: t('general.tags.name'),
      sortable: true,
    },
    {
      id: 'curp',
      label: t('general.tags.curp'),
      sortable: true,
    },
    {
      id: 'phone',
      label: t('general.tags.phone'),
      sortable: true,
    },
    {
      id: 'email',
      label: t('general.tags.email'),
      sortable: true,
    },
    {
      id: 'userType',
      label: t('general.tags.userType'),
      sortable: true,
    },
  ]

  return {
    column,
    row:
      items?.map((item: any) => ({
        name: `${item?.firstName} ${item?.lastName}`,
        address: item.address ?? '',
        phone: item.phone ?? '',
        curp: item?.curp,
        status: getStatus(item?.status?.id, colors, t),
        email: item.email ?? 'N/A',
        userType: item.userType?.name ? item.userType?.name : 'N/A',
      })) ?? [],
  }
}

export const filterRowStore = (value: { [x: string]: any }, list: any[]) => {
  return list?.filter(
    (item) =>
      (value?.status ? item?.status?.value === value?.status : true) &&
      searchInModeLower(
        [
          item?.name,
          item?.address,
          item?.phone,
          item?.status?.text,
          item?.curp,
          item?.userType,
          item?.keyCode,
          item?.agent,
          item?.gerente,
          item?.role,
          item?.email,
        ]?.join(' '),
        value?.search
      )
  )
}
