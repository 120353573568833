import { useEffect, useState } from "react"
import { dashboardServiceFetch } from "../../../../../../utils/constants/callService"
import { Roles } from "../../../../../../utils/enums"
import { getUserRole } from "../../../../../../service/auth"

const useBody = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    time: '',
    actionPlan: 0
  })


  const getData = async () => {
    setLoading(true)

    const endpointsByRoles: Record<string, () => void> = {
      [Roles.STORE]: async () => {
        const response = await dashboardServiceFetch.indicatorsTop()
        const min = response.timeResponse ?? 0
        var days = Math.floor(min / 1440);
        var hours = Math.floor((min % 1440) / 60);
        var minutes = min % 60;

        const elapsed = `${!!days ? `${days}d ` : ''}${!!hours ? `${hours}h ` : ''}${!!minutes ? `${minutes}min` : ''}`
        setData({
          time: `${elapsed}`,
          actionPlan: response.actionPlans
        })
      },
      [Roles.AUDIT]: async () => {
        const response = await dashboardServiceFetch.responseActionPlan()
        const min = response.actionPlanApproval ?? 0
        var days = Math.floor(min / 1440);
        var hours = Math.floor((min % 1440) / 60);
        var minutes = min % 60;

        const elapsed = `${!!days ? `${days}d ` : ''}${!!hours ? `${hours}h ` : ''}${!!minutes ? `${minutes}min` : ''}`
        setData({
          time: `${elapsed}`,
          actionPlan: response.actionPlanAmountApproval
        })
      }
    }
    endpointsByRoles?.[getUserRole() ?? '']?.()

    setLoading(false)
  }

  useEffect(() => { getData() }, [])
  return { data, loading, getData }
}

export { useBody }
