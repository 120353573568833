import { useContextState } from '../../hooks/theme';
import { Constants } from '../../utils/constants';

export const useStyles = ({ item, removeColors, defaulPagination }:
  { alignStatus?: 'start' | 'end' | 'center'; item?: number; removeColors?: boolean; defaulPagination?:number }) => {
  const { colors: color } = useContextState()

  return ({
    container: {
      width: '100%',
      // paddingBottom: '10px',
      ...(!removeColors
        ? {}
        : {
          '& .MuiTable-root': {
            borderCollapse: 'separate',
            borderSpacing: '0 10px',
          },
          '& td': {
            backgroundColor: `${color.color13}01`,
            boxShadow: `0px 3px ${color.color13}30`,
            padding: '10PX',
          },
          '& td:first-child': {
            borderLeftstyle: 'solid',
            borderTopLeftRadius: '10PX',
            borderBottomLeftRadius: '10PX',
          },
          '& td:last-child': {
            borderBottomRightRadius: '10PX',
            borderTopRightRadius: '10PX',
          },
        }),
    },
    containerCenter: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    selectedPagination: {
      marginTop: '10px',
      '& .MuiSelect-select.MuiSelect-select': {
        padding: '10px 14px',
      },
      '& .MuiFormControl-root': {
        width: 'fit-content',
      },
    },
    flexGrow: {
      flexGrow: '1',
    },
    tableContainer: {
      height: ((item ?? 0) < Constants.numberOfRows || !!defaulPagination) ? `auto` : 'calc(100vh - 270px)',
      maxHeight: `calc(100vh - 270px)`,
      maxWidth: '100%',
      overflowY: 'auto',
      '& .MuiCheckbox-root': {
        padding: 0,
      },
      '& .MuiRadio-root': {
        padding: 0,
      },
      boxShadow: 'none',
      '& .MuiTableHead-root': {
        '& .header-personal-cell': {
          textOverflow: 'ellipsis',
          verticalAlign: 'middle',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          '& .MuiTableCell-root': {
            backgroundColor: '#ebebeb',
          },
        },
        '& .header-cell': {
          backgroundColor: !removeColors ? color.color9 : color.color5,
          textOverflow: 'ellipsis',
          verticalAlign: 'middle',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        },
        '& .header-cell-check': {
          width: '40px',
        },
      },
    },
    hidden: {
      display: 'none',
    },
    headExport: {
      backgroundColor: color.color9,
      '& .MuiTableCell-root': {
        padding: '5px',
        // borderBottom: 'none',
        '& .MuiIconButton-root': {
          // border: `1px solid ${color.color5}`,
          padding: '5px 10px',
          borderRadius: '10px',
          color: color.color5,
          '& .MuiSvgIcon-root': {
            fontSize: '17px',
          },
          '& .MuiTypography-root': {
            fontSize: '16px',
          },
        },
      },
    },
    headetText: {
      color: !removeColors ? color.color5 : color.color13,
      fontWeigt: '800',
      fontSize: '12px',
    },
    headerContent: {
      display: 'flex',
    },
    justifyContent: {
      justifyContent: 'center',
    },
    headerCell: {
      padding: '10px 5px',
      lineHeight: '0rem',
      '&.MuiTableCell-root': {
        verticalAlign: 'initial',
        ...!removeColors ? {} : { borderBottom: 'none' },
      },
    },
    headerSortable: {
      '& .MuiTableSortLabel-icon.MuiTableSortLabel-icon.MuiTableSortLabel-icon': {
        color: !removeColors ? `${color.color5}` : color.color13,
      },
    },
    arrowDown: {
      position: 'absolute',
      top: '8px',
      left: '0',
    },
    selected: {
      background: color.color1,
      '& .MuiTypography-root': {
        color: color.color5,
      },
      '& .MuiSvgIcon-root': {
        color: color.color5,
      },
    },
    arrowRight: {
      '& .MuiSvgIcon-root': {
        color: color.color22,
        fontSize: '1.8rem',
      },
    },
    bodyRow: !removeColors
      ? {
        cursor: 'pointer',
        '&:nth-of-type(2n + 1)': {
          background: color.color15,
        },
        '&:hover': {
          background: color.color14,
        },
      }
      : {
        background: color.color14,
        borderRadius: '50%',
      },
    flex: {
      flex: 1,
    },
    containerFiles: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
    bodyCell: {
      fontSize: '12px',
      padding: '14px 5px',
      maxWidth: '120px',
      alignItems: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
      '& .MuiCheckbox-root.Mui-disabled': {
        '& .MuiBox-root': {
          background: `${color.color13}22`,
          border: '0px solid transparent'
        }
      },
      '& .custom-status': {
        justifyContent: 'center',
        marginLeft: '10px',
      },
    },
    bodyCellArrow: {
      '&.MuiTableCell-root.MuiTableCell-root.MuiTableCell-root': {
        backgroundColor: color.color5,
        border: `2px solid ${color.color5}`,
        padding: '0',
        // position: 'inherit'
      },
    },
    removeWidth: {
      width: 0,
      position: 'inherit',
    },
    bodyText: {
      color: color.color10,
      fontWeigt: '800',
      fontSize: '12px',
      wordBreak: 'break-word',
      maxHeight: '40px',
      textAlign: 'start',
    },
    pagination: {
      '& .MuiPaginationItem-root': {
        margin: '10px 0 0 0',
      },
    },
    empty: {
      width: '100%',
      fontSize: '20px',
      fontWeight: '400',
      color: color.color13,
      textAlign: 'center',
      padding: '20px 0',
      // border: `1px solid ${color.color13}`,
      // borderRadius: '5px',
      // marginTop: '-1px'
    },
  })
}
