import { useEffect, useState } from "react"
import { graphicHistorigram, progressBars } from "./mothods/callEndpoints"
import { useFilter } from "../../../../../../hooks/filter"
import { defaultDates, inputsAreas, inputsAreasGraphic, inputsDepartments, inputsDepartmentsGraphic } from "./constants/inputs"
import { Item, ObjectGeneric, onChangeEvent } from "../../../../../../utils/types/generals"
import { TypesIndicators } from "./typing/types"
import { BarProp, BarRef } from "../../components/barGraphic/types"
import { RequestHistory } from "../indicators/typing/types"
import { Status } from "../../../../../../utils/enums"
import { getStatus } from "../../../../../../hooks/status"
import { useContextState } from "../../../../../../hooks/theme"
import { useTranslation } from "react-i18next"
import { getQuestionnaires } from "../../../../../../utils/methods/geServiceWithItemsFormat"
import { useRefs } from "../../../../../../hooks/useReactHookModified"
import { ProgressListRef } from "../../components/progressList/typing/interfaces"
import { TooltipType1Props } from "../../components"
import { startOfWeek } from "date-fns"
import { intervaleList } from "../../../../../../utils/constants/intervaleList"
import { AreaDetailProps } from "./components/modals/area/types"
import { TypesDetail } from "./components/modals/model/props"
import { detalModelTypes } from "./components/modals/model/consts"
import { DepartmentDetailProps } from "./components/modals/department/types"
import { returnQueryWithPagination } from "../../../../../../utils/methods/returnQuery"

const useBody = (payload: { items: Record<string, Item[] | boolean> }) => {
  const [itemByDate, setItemByDate] = useState<{ data: AreaDetailProps['payload'] | DepartmentDetailProps['payload']; type: TypesDetail } | undefined>()
  const { colors } = useContextState()
  const [counter, setConter] = useState<Record<string, number | null>>({ area: 0, department: 0 })
  const [dataByDates, setDataByDates] = useState<{ area: Record<string, TooltipType1Props> | null, department: Record<string, TooltipType1Props> | null }>({
    area: null,
    department: null
  })
  const ref = useRefs<ProgressListRef>()
  const barRefs = useRefs<BarRef>()
  const { t } = useTranslation()
  const [allDepartments, setAllDepartments] = useState<Item[]>([])
  const [inputs, setInputs] = useState({
    areas: inputsAreas,
    departments: inputsDepartments,
    areasGraphic: inputsAreasGraphic,
    departmentsGraphic: inputsDepartmentsGraphic
  })
  const [dataGraphic, setDataGraphic] = useState<Record<TypesIndicators, { id: string; name: string }[]>>({ areas: [], departments: [] })

  const [oldFilters, setOldFilters] = useState<ObjectGeneric>({})
  const filterAG = useFilter({
    formatFilter: {},
    defaultValues: defaultDates
  })
  const filterDG = useFilter({
    formatFilter: {},
    defaultValues: defaultDates
  })
  const filterByHistory = {
    areasGraphic: filterAG,
    departmentsGraphic: filterDG,
  }
  const [dataIndicators, setDataIndicators] = useState<Record<TypesIndicators, { data: any[]; list: any[]; lastDate: string; isActive?: string; name?: string; isLoading: boolean }>>({
    areas: {
      data: [],
      list: [],
      lastDate: '',
      isActive: '',
      isLoading: true
    },
    departments: {
      data: [],
      list: [],
      lastDate: '',
      isActive: '',
      isLoading: true
    },
  })
  const [graphicsData, setGraphicsData] = useState<Record<
    TypesIndicators, {
      isLoading: boolean
      data: BarProp['data']
    }
  >>({
    areas: {
      data: [],
      isLoading: true
    },
    departments: {
      data: [],
      isLoading: true
    }
  })
  const selectedArea = ({ id, label, type }: { id: string; label: string; type: TypesIndicators }) => {
    getProgressDepartments({ areaId: id })
    setInputs(prev => ({
      ...prev,
      departments: {
        ...prev.departments,
        departments: {
          ...prev.departments.departments,
          items: (payload.items.departments as Item[]).filter((child) => child?.areaId == id)
        }
      }
    }))
    setDataIndicators(prev => ({
      ...prev,
      [type]: {
        ...prev[type],
        isActive: id,
        name: label
      }
    }))
  }

  const filterDepartmentGraphic = () => {
    const departmentList: Item[] = filterByHistory.departmentsGraphic.filterData?.departments
    const dataDepartment = graphicsData.departments.data

    if (!!departmentList?.length) {
      return dataDepartment.filter(item => departmentList.some((itemAreas) => itemAreas.value == item.id))
    }

    return dataDepartment
  }

  const filterItemDepartmentsGraphic = (payload: ObjectGeneric) => {
    handleLoadingsGraphics({ name: 'departments', value: true })
    const area_id = payload?.areas?.value
    const listDepartments: Item[] = payload.departments
    const interval = payload?.intervale?.value
    let date = {
      start: payload.from,
      end: payload.to
    }
    getDepartmentGraphicData({ area_id, departments: listDepartments, date, interval })

    handleLoadingsGraphics({ name: 'departments', value: false })
  }

  const handleLoadings = ({ name, value }: { name: TypesIndicators; value: boolean }) => {
    setDataIndicators(prev => ({
      ...prev,
      [name]: {
        ...prev?.[name],
        isLoading: value,
      }
    }))
  }

  const handleLoadingsGraphics = ({ name, value }: { name: TypesIndicators; value: boolean }) => {
    setGraphicsData(prev => ({
      ...prev,
      [name]: {
        ...prev?.[name],
        isLoading: value,
      }
    }))
  }

  const onChange = ({ e, type, blur }: { type: 'areasGraphic' | 'departmentsGraphic'; e: onChangeEvent, blur?: any }) => {
    const { name, value } = e.target
    const filterLocalData = filterByHistory[type]
    if (type === 'departmentsGraphic') {
      filterLocalData.onChange(e, blur)
      let copyDataOfDepartmentGraphic = filterLocalData.filterData
      copyDataOfDepartmentGraphic = {
        ...copyDataOfDepartmentGraphic,
        [name]: value
      }
      if (name === 'areas') {
        const departments = value ? allDepartments.filter((child) => child?.areaId == value?.value)?.slice(0, 5) : []
        copyDataOfDepartmentGraphic = {
          ...copyDataOfDepartmentGraphic,
          departments: departments
        }

        setInputs(prev => ({
          ...prev,
          departmentsGraphic: {
            ...prev.departmentsGraphic,
            departments: {
              ...prev.departmentsGraphic.departments,
              items: departments
            }
          }
        }))
        filterLocalData.onChange({
          target: {
            name: 'departments',
            value: departments
          }
        })
      } else if (name == 'intervale' && value?.value == '1w') {
        if (!blur?.blur) {
          copyDataOfDepartmentGraphic = {
            ...copyDataOfDepartmentGraphic,
            from: copyDataOfDepartmentGraphic.from ? startOfWeek(new Date(copyDataOfDepartmentGraphic.from), { weekStartsOn: 1 }) : null
          }

          filterByHistory.departmentsGraphic.onChange({
            target: {
              name: "from",
              value: copyDataOfDepartmentGraphic.from
            }
          })
        }
      }
      
      filterItemDepartmentsGraphic(copyDataOfDepartmentGraphic)
    } else {
      filterLocalData.onChange(e, blur)

      if (type === 'areasGraphic') {
        const data = filterLocalData?.filterData
        const listAreas = name === 'areas' ? value : data?.areas
        const interval = name === 'intervale' ? value?.value : data?.intervale?.value
        let date = {
          start: name === 'from' ? value : data.from,
          end: name === 'to' ? value : data.to
        }
        const areaValue = listAreas
        if (!blur?.blur) {
          if (name == 'intervale' && value?.value == '1w') {
            date = {
              ...date,
              start: date.start ? startOfWeek(new Date(date.start), { weekStartsOn: 1 }) : null
            }
          }

          filterByHistory.areasGraphic.onChange({
            target: {
              name: "from",
              value: date.start
            }
          })
        }
        filterByHistory.areasGraphic.onChange({
          target: {
            name: 'areas',
            value: areaValue
          }
        }, blur)
        getAreaGraphicData({ areaList: areaValue, interval, date })
      }
    }
  }

  const refreshAreaGraphic = () => {
    const data = filterByHistory.areasGraphic?.filterData
    const listAreas = data?.areas
    const interval = data?.intervale?.value
    
    let date = {
      start: data.from,
      end: data.to
    }
    const areaValue = listAreas

    getAreaGraphicData({ areaList: areaValue, interval, date, })

  }
  const getProgressAreas = async (payloadArea: ObjectGeneric) => {
    const areasList = !!payloadArea?.areas?.length ? payloadArea?.areas : inputs.areas.areas.items
    const questionnaire = payloadArea?.questionnaire
    const status = payloadArea?.status
    handleLoadings({ name: 'areas', value: true })
    const { data, updateDate } = await progressBars({ questionnaire, status }).areas({ areasList, })
    let id = dataIndicators.areas?.isActive
    let label = dataIndicators.areas?.name
    const existArea = id && !!data?.some(item => item.id.toString() == id)

    if (!existArea) {
      if (ref.refsByKey?.department?.cleanFilter) { ref.refsByKey?.department?.cleanFilter() }
      id = data?.[0]?.id?.toString()
      label = data?.[0]?.label
      setInputs(prev => ({
        ...prev,
        departments: {
          ...prev.departments,
          departments: {
            ...prev.departments.departments,
            items: (payload.items.departments as Item[]).filter((child) => child?.areaId == id) as Item[]
          }
        },
      }))
      getProgressDepartments({ areaId: id })
    }

    setDataIndicators(prev => ({
      ...prev,
      areas: {
        ...prev.areas,
        data,
        list: data,
        lastDate: updateDate,
        isActive: id,
        name: label
      }
    }))

    handleLoadings({ name: 'areas', value: false })
  }

  const getProgressDepartments = async (payloadDepartment: ObjectGeneric) => {
    const questionnaire = payloadDepartment?.questionnaire
    const status = payloadDepartment?.status
    handleLoadings({ name: 'departments', value: true })
    if (!!dataIndicators.areas.isActive || payloadDepartment?.areaId) {
      const department = payloadDepartment?.departments?.length ? payloadDepartment?.departments : payload.items.departments as Item[]
      const { data, updateDate } = await progressBars({ questionnaire, status }).departments({ departmentList: department, area_id: payloadDepartment?.areaId ?? dataIndicators.areas.isActive ?? '' })
      setDataIndicators(prev => ({
        ...prev,
        departments: {
          ...prev.departments,
          data,
          list: data,
          lastDate: updateDate
        }
      }))

    }
    handleLoadings({ name: 'departments', value: false })
  }

  const getAreas = async () => {
    const response = (payload.items.areas ?? []) as Item[]
    const firstArea = response[0]
    const departmentChilds = (payload.items.departments as Item[]).filter((child) => child?.areaId == firstArea?.value)?.slice(0, 5)
    getAreaGraphicData({ areaList: response.slice(0, 5), date: { start: defaultDates.from, end: defaultDates.to } })
    getProgressAreas({ areas: response })

    filterItemDepartmentsGraphic({
      areas: firstArea,
      departments: departmentChilds,
      intervale: intervaleList[0],
      ...defaultDates
    })
    filterByHistory.departmentsGraphic.onChange({
      target: {
        name: 'areas',
        value: firstArea
      }
    })
    filterByHistory.departmentsGraphic.onChange({
      target: {
        name: 'departments',
        value: departmentChilds
      }
    })
    filterByHistory.areasGraphic.onChange({
      target: {
        name: 'areas',
        value: response.slice(0, 5)
      }
    })
    setInputs(prev => ({
      ...prev,
      areas: {
        ...prev.areas,
        areas: {
          ...prev.areas.areas,
          items: response ?? []
        }
      },
      areasGraphic: {
        ...prev.areasGraphic,
        areas: {
          ...prev.areasGraphic.areas,
          items: response ?? []
        }
      },
      departmentsGraphic: {
        ...prev.departmentsGraphic,
        areas: {
          ...prev.departmentsGraphic.areas,
          items: response ?? []
        },
        departments: {
          ...prev.departmentsGraphic.departments,
          items: departmentChilds
        }
      }
    }))
  }

  const getAreaGraphicData = async ({ areaList, date, interval, detail }: { areaList?: Item[]; detail?: boolean } & RequestHistory) => {
    handleLoadingsGraphics({ name: 'areas', value: true })
    const { data, areasString, dataByDates } = await graphicHistorigram({ detail }).areas({ areasList: areaList, date, interval }) as any

    setDataByDates(prev => ({ ...prev, area: dataByDates as any }))
    setDataGraphic(prev => ({ ...prev, areas: areasString }))
    setGraphicsData(prev => ({
      ...prev,
      areas: {
        ...prev.areas,
        data
      }
    }))
    handleLoadingsGraphics({ name: 'areas', value: false })
  }

  const getDepartmentGraphicData = async ({ area_id, departments, date, interval, detail }: { area_id: string; departments: Item[]; detail?: boolean } & RequestHistory) => {
    handleLoadingsGraphics({ name: 'departments', value: true })
    if (!!area_id) {
      const { data, departmentConverted, dataByDates } = await graphicHistorigram({ detail }).departments({ area_id, departmentList: departments || [], date, interval }) as any

      setDataByDates(prev => ({ ...prev, department: dataByDates as any }))
      setDataGraphic(prev => ({ ...prev, departments: departmentConverted }))
      setGraphicsData(prev => ({
        ...prev,
        departments: {
          ...prev.departments,
          data
        },
      }))
    } else {
      setDataGraphic(prev => ({ ...prev, departments: [] }))
      setGraphicsData(prev => ({
        ...prev,
        departments: {
          ...prev.departments,
          data: []
        },
      }))
    }
    handleLoadingsGraphics({ name: 'departments', value: false })
  }

  useEffect(() => {
    if (!payload.items.departmentsLoading && !payload.items.areasLoding) {
      getAreas()
      setAllDepartments(payload.items.departments as Item[])
    }
    //eslint-disable-next-line
  }, [payload.items.departmentsLoading, payload.items.areasLoding, payload.items.areas])

  useEffect(() => {
    const getAllStatus = async () => {
      const items = [Status.WAITING, Status.TO_BE_STARTED, Status.FINISHED, Status.INCOMPLETE, Status.EXPIRED, Status.CANCEL].map((item) => {
        const newStatus = getStatus(item, colors, t)
        return {
          label: item == Status.WAITING ? 'En progreso' : newStatus.text,
          value: item,
          id: item.toString(),
        }
      })
      setInputs(prev => ({
        ...prev,
        areas: {
          ...prev.areas,
          status: {
            ...prev.areas.status,
            items: items
          }
        },
        departments: {
          ...prev.departments,
          status: {
            ...prev.departments.status,
            items: items
          }
        }
      }))
    }

    getAllStatus()
  }, [])
  useEffect(() => {
    const getAllQuestionnaire = async () => {
      const response = await getQuestionnaires()
      setInputs(prev => ({
        ...prev,
        areas: {
          ...prev.areas,
          questionnaire: {
            ...prev.areas.questionnaire,
            items: response.items
          }
        },
        departments: {
          ...prev.departments,
          questionnaire: {
            ...prev.departments.questionnaire,
            items: response.items
          }
        }
      }))
    }

    getAllQuestionnaire()
  }, [])

  const filterArea = (payload: ObjectGeneric, counter: number | null) => {
    setConter(prev => ({ ...prev, area: counter }))
    setOldFilters(prev => ({ ...prev, area: payload }))
    getProgressAreas(payload)
  }

  const filterDepartments = (payload: ObjectGeneric, counter: number | null) => {
    setConter(prev => ({ ...prev, department: counter }))
    setOldFilters(prev => ({ ...prev, department: payload }))
    getProgressDepartments(payload)
  }
  /**
   * @description get data of area or department graphic by date and redirec to reports view
   * @param payload.date date to search 
   * @param payload.tpe use constant detalModelTypes
   */
  const getItemOfGraphic = ({ date, type }: { date: string, type: TypesDetail }) => {
    // let data: AreaDetailProps['payload'] | DepartmentDetailProps['payload'] | undefined
    let query = ""
    let expresionRegular = /(from=)[^&]+(&to=)[^&]+/;
    let nuevaCadena = ""

    if (type == detalModelTypes.area) {
      query = returnQueryWithPagination({ filter: filterByHistory.areasGraphic.filterString }).replace("&", "?")
      nuevaCadena = query.replace(expresionRegular, `$1${date}$2${date}`);
      const intervale = (filterByHistory.areasGraphic?.filterData?.intervale || intervaleList[0]).value

      nuevaCadena = `${nuevaCadena}&intervale=${intervale}`
    }

    if (type == detalModelTypes.department) {
      query = returnQueryWithPagination({ filter: filterByHistory.departmentsGraphic.filterString }).replace("&", "?")
      nuevaCadena = query.replace(expresionRegular, `$1${date}$2${date}`);
      const instervale = (filterByHistory.departmentsGraphic?.filterData?.intervale ?? intervaleList[0]).value
      nuevaCadena = `${nuevaCadena}&intervale=${instervale}`
    }
    window.open(`/dashboard/reports/programation${nuevaCadena}`, 'blank')
  }
  /**
   * @description close the detail graphic modal and clean the data in the modal and the daata in itemByDate
   */
  const closeAndCleanDetail = () => {
    setItemByDate(undefined)
  }

  /**
   * @description close the programation graphics tooltip
   */
  const closeTooltip = ({ type }: { type: TypesDetail }) => {
    if (barRefs.refsByKey[type]?.removeClick) {
      barRefs.refsByKey[type]?.removeClick()
    }
  }
  return {
    dataIndicators,
    dataByDates,
    filterByHistory,
    barRefs,
    inputs,
    graphicsData,
    dataGraphic,
    ref,
    oldFilters,
    counter,
    itemByDate,
    closeTooltip,
    closeAndCleanDetail,
    getItemOfGraphic,
    filterArea,
    filterDepartments,
    refreshAreaGraphic,
    filterItemDepartmentsGraphic,
    getProgressAreas,
    getProgressDepartments,
    filterDepartmentGraphic,
    selectedArea,
    onChange
  }
}

export { useBody }
