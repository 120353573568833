import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Icon,
  Pagination,
  Checkbox,
  Radio,
  TableContainer,
  TableSortLabel,
} from '@mui/material'
import { useState, useEffect, Fragment, forwardRef, Ref, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { icons } from '../../utils/icons'
import StatusComponent from '../status'
import { useStyles } from './styles'
import { Props, RefTable } from './types'
import DownloadFiles from '../donwloadFiles'
import CustomSelect from '../dinamicForm/select'
import { TableVirtuoso, TableComponents } from 'react-virtuoso'
import { visuallyHidden } from '@mui/utils'
import Loader from '../loader'
import { Constants } from '../../utils/constants'

export const CustomTable = forwardRef(({
  serverSidePagination,
  handleChange,
  valueByArray,
  customeHeader,
  loader,
  column,
  row,
  customeColumn,
  onClick,
  format,
  exportFiles,
  selected,
  setSelected,
  arrow,
  tableChecks,
  tableRadio,
  defaultChecks,
  dontRefresh,
  name,
  alignStatus,
  excludeExcel,
  excludePdfDoc,
  sortable,
  disabledChecks,
  order,
  removeColors,
  ...rest
}: Props, ref: Ref<RefTable>) => {
  const [idSelected, setIdSelected] = useState<{
    key: string
    order: 'DESC' | 'ASC'
    count: number
  }>({
    key: '',
    order: 'ASC',
    count: 0,
  })
  // const [order, setOrder] = useState<string[]>([])
  const [checks, setChecks] = useState<(number | string)[]>([])
  const [rowsPerPage, setRowPerPage] = useState(rest?.defaulPagination ?? 20)
  const [localRow, setLocalRow] = useState<any[]>([])
  const [allChecks, setAllChecks] = useState(false)
  const [page, setPage] = useState(0)
  const { t } = useTranslation()
  const itemsSize = localRow?.length

  useImperativeHandle(ref, () => ({
    resetPagination: () => { setPage(0) },
    allChecksAreSelected: ({ value }) => {
      setAllChecks(value)
    },
    validateChecks: () => {
      if (!!checks?.length) {
        return !localRow.find((item) => !checks?.includes(item.id))
      }
      return false
    }
  }))
  const styles = useStyles({
    alignStatus,
    item: itemsSize,
    removeColors,
    defaulPagination: rest?.defaulPagination
  })
  const handleChangePage = (_: unknown, pageChange: number) => {
    setPage(pageChange - 1)
    setSelected?.(-1)
    // setLocalRow(row)
  }

  useEffect(() => {
    if (serverSidePagination?.size) setRowPerPage(serverSidePagination?.size)
  }, [serverSidePagination])

  const handleChangePageServerSide = (_: unknown, pageChange: number) => {
    if (pageChange != (serverSidePagination?.page ?? page)) {
      setPage(pageChange)
      handleChange(pageChange, rowsPerPage)
    }
  }

  const handleChangeSizeServerSide = (size: number) => {
    handleChange(page + 1, size)
  }

  const isNumber = (str: string) => /^-?\d*\.?\d+$/.test(str)

  const sortTable = (id: string, order: 'DESC' | 'ASC') => {
    const rowCopy = [...localRow]
    let sortRow: any[]

    sortRow = rowCopy.sort((a, b) => {
      let c =
        (id !== 'status'
          ? a[id]?.type === 'date'
            ? a[id]?.value
            : a[id]?.label ?? a[id]
          : a[id].text) ?? ''
      let d =
        (id !== 'status'
          ? b[id]?.type === 'date'
            ? b[id]?.value
            : b[id]?.label ?? b[id]
          : b[id].text) ?? ''

      c = typeof c === 'string' ? c?.toUpperCase()?.trim() : c
      d = typeof d === 'string' ? d?.toUpperCase()?.trim() : d

      if (isNumber(c)) c = parseFloat(c)
      if (isNumber(d)) d = parseFloat(d)

      if (isNumber(c) && isNumber(d)) {
        c = parseFloat(c)
        d = parseFloat(d)

        if (idSelected.order === 'DESC') {
          if (c < d) return -1
          else if (c > d) return 1
          return 0
        }

        if (c > d) return -1
        else if (c < d) return 1
        return 0
      }

      if (idSelected.order === 'DESC') return c?.toString()?.localeCompare(d ?? '', 'es')
      else return d?.toString()?.localeCompare(c ?? '', 'es')
    })

    setIdSelected((prev) => ({
      key: prev?.count === 2 && prev.key === id ? '' : id,
      order: prev?.count === 2 && prev.key === id ? 'ASC' : order,
      count:
        ['', id].includes(prev.key) && prev.count < 2
          ? prev.count + 1
          : prev.key !== id
            ? 1
            : 0,
    }))
    // setIdSelected(exists ? '' : id)
    setLocalRow(sortRow)
  }

  const handleCheck = (id: number | string) => {
    let selectedChecks = [] as (string | number)[]
    if (!tableRadio) {
      if (checks.includes(id))
        selectedChecks = checks.filter((itemFilter) => itemFilter !== id)
      else selectedChecks = [...checks, id]

      setChecks(selectedChecks)
      setAllChecks(!localRow.find((item) => !selectedChecks?.includes(item.id)))
    } else {
      selectedChecks = [id]
      setChecks(selectedChecks)
    }
    onClick?.({ checks: selectedChecks })
  }

  const handleAllChecks = () => {
    let selectedChecks = []

    if (!!localRow.find((item) => ![...disabledChecks ?? []]?.some(itemDisabled => itemDisabled == item.id) && !checks.includes(item.id))) {
      selectedChecks = localRow?.map((item) => item.id)
      setChecks(selectedChecks)
      setAllChecks(true)
    } else {
      setAllChecks(false)
      setChecks([])
    }
    onClick?.({ checks: selectedChecks })
  }

  useEffect(() => {
    if (!dontRefresh) {
      setIdSelected({
        key: '',
        order: 'ASC',
        count: 0,
      })
      setPage(0)
    }
    setLocalRow(row)
    if (localRow?.length <= 0) {
      setPage(0)
    } else if (Math.ceil(localRow?.length / rowsPerPage) <= page) {
      setPage(Math.ceil(row?.length / rowsPerPage) - 1)
    }
    if (row?.length > 0 && defaultChecks) {
      const copydefaultChecks = [...(defaultChecks || [])]
      const disabledLength = disabledChecks?.length ?? 0
      const rowLength = row?.length
      if (disabledLength < rowLength) {
        setAllChecks(!row.find((item) => !copydefaultChecks?.some(itemDisabled => itemDisabled == item.id) && !copydefaultChecks?.includes(item.id)))
      }
      setChecks(defaultChecks ?? [])
    }
    // eslint-disable-next-line
  }, [row, defaultChecks])

  const VirtuosoTableComponents: TableComponents<any> = {
    Table: (props) => <Table {...props} />,
    TableHead,
    TableRow: ({ item: _item, ...props }) => {
      return (
        <TableRow
          sx={[
            (selected ?? -1) === _item.id ? styles.selected : styles.bodyRow,
          ]}
          onClick={(e) => {
            if (!tableChecks) {
              onClick?.(_item, _item.id)
            } else {
              e.stopPropagation()
              handleCheck?.(_item.id)
            }
          }}
          {...props}
        />
      )
    },
    TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
      <TableBody style={{ minHeight: 100 }} {...props} ref={ref} />
    )),
  }

  function fixedHeaderContent() {
    return (
      <>
        {!!customeColumn && (
          <TableRow className="header-cell header-personal-cell">
            {customeColumn?.map((item) => (
              <TableCell
                className="header-cell header-cell-color"
                sx={[styles.headerCell, item.headerCell]}
                key={item.id}
                colSpan={item?.span}
              >
                <Box
                  sx={[
                    (['status', 'action'].includes(item.id) ||
                      localRow?.[0]?.[item.id]?.children) &&
                      !localRow?.[0]?.[item.id]?.start
                      ? styles.justifyContent
                      : {},
                  ]}
                >
                  <>
                    {!item?.column ? (
                      <Typography sx={[styles.headetText, item.headerText]}>
                        {item.label}
                      </Typography>
                    ) : (
                      item?.column(item)
                    )}
                  </>
                </Box>
              </TableCell>
            ))}
          </TableRow>
        )}
        <TableRow className="header-cell header-cell-color">
          {!!arrow && (
            <TableCell sx={[styles.bodyCellArrow, styles.removeWidth]}>
              {' '}
            </TableCell>
          )}
          {!!tableChecks && (
            <TableCell
              sx={[styles.headerCell]}
              className="header-cell header-cell-color header-cell-check"
            >
              {!tableRadio ? (
                <Checkbox
                  onChange={(e) => {
                    e.stopPropagation()

                    if (!tableRadio) {
                      handleAllChecks()
                    }
                  }}
                  checked={allChecks}
                  defaultChecked
                />
              ) : null}
            </TableCell>
          )}
          {column.map((item) => (
            <TableCell
              className="header-cell header-cell-color"
              sx={[styles.headerCell, item.headerCell]}
              style={{ minWidth: item.minWidth }}
              key={item.id}
            >
              <Box
                sx={[
                  styles.headerContent,
                  item?.contentColumn,
                  (['status', 'action'].includes(item.id) ||
                    localRow?.[0]?.[item.id]?.children) &&
                    !localRow?.[0]?.[item.id]?.start
                    ? styles.justifyContent
                    : {},
                ]}
              >
                <>
                  <TableSortLabel
                    hideSortIcon={!item.sortable}
                    hidden={!item.sortable}
                    active={(order?.key ?? idSelected?.key) === item.id}
                    direction={
                      (order?.key ?? idSelected?.key) === item.id
                        ? ((
                          order?.order ?? idSelected?.order
                        )?.toLowerCase() as 'asc' | 'desc')
                        : 'asc'
                    }
                    sx={styles.headerSortable}
                    onClick={() => {
                      if (!!item.sortable) {
                        if (!!sortable) {
                          const count =
                            order?.key === item.id ? (order?.count ?? 0) + 1 : 0
                          const eraseSort = count >= 2

                          sortable(
                            !eraseSort ? item.id : '',
                            eraseSort ||
                              (order?.order === 'DESC' &&
                                order?.key === item.id)
                              ? 'ASC'
                              : 'DESC',
                            order?.key === item.id && !eraseSort
                              ? (order?.count ?? 0) + 1
                              : 0
                          )
                        } else {
                          sortTable(
                            item.id,
                            idSelected?.order === 'DESC' &&
                              idSelected?.key === item.id
                              ? 'ASC'
                              : 'DESC'
                          )
                        }
                      }
                    }}
                  >
                    {!item?.column ? (
                      <Typography sx={[styles.headetText, item.headerText]}>
                        {item.label}
                      </Typography>
                    ) : (
                      item?.column(item)
                    )}
                    {(order?.key ?? idSelected?.key) === item.id ? (
                      <Box component="span" sx={[visuallyHidden]}>
                        {(order?.key ?? idSelected?.key) === item.id
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </>
              </Box>
            </TableCell>
          ))}
        </TableRow>
      </>
    )
  }

  function rowContent(_index: number, row: any) {
    return (
      <Fragment>
        {!!arrow && (
          <TableCell sx={[styles.bodyCell, styles.bodyCellArrow]}>
            {selected == row.id ? (
              <Icon sx={styles.arrowRight}>{icons('arrow-right-fill')}</Icon>
            ) : (
              <Box sx={{ width: '25px' }} />
            )}
          </TableCell>
        )}

        {!!tableChecks && (
          <TableCell sx={[styles.bodyCell]}>
            {!tableRadio ? (
              <Checkbox
                disabled={!!disabledChecks?.some(itemDisabled => itemDisabled == row.id)}
                onChange={(e) => {
                  e.stopPropagation()
                  handleCheck(row.id)
                }}
                checked={checks.includes(row.id)}
              />
            ) : (
              <Radio
                onChange={(e) => {
                  e.stopPropagation()
                  handleCheck(row.id)
                }}
                checked={checks.includes(row.id)}
              />
            )}
          </TableCell>
        )}

        {column?.map((col) => (
          <TableCell
            onClick={(e) => {
              if (row?.[col.id]?.children) {
                e.stopPropagation()
              }
            }}
            sx={[styles.bodyCell, col.rowTdStyles]}
            key={col.id}
          >
            {!col?.renderCell ? (
              col.id !== 'status' ? (
                row?.[col.id]?.children ?? (
                  <Typography
                    sx={[styles.bodyText, row?.headerText]}
                    className="custom-lines"
                  >
                    {`${row?.[col.id]?.label ||
                      row?.[col.id] ||
                      (row?.[col.id] === 0 ? row?.[col.id] : 'N/A')
                      }`}
                  </Typography>
                )
              ) : (
                <StatusComponent
                  color={row[col.id]?.color}
                  msg={row[col.id]?.text}
                  size="small"
                />
              )
            ) : (
              col?.renderCell({ row, col, current: row[col.id] })
            )}
          </TableCell>
        ))}
      </Fragment>
    )
  }

  return (
    <>
      {loader && <Loader />}

      {!loader && (
        <Box className='table-parent' sx={styles.container}>
          <Box sx={styles.containerFiles}>
            <Box sx={styles.flex} className="downloadFiles">
              {!!exportFiles && (
                <DownloadFiles
                  valueByArray={valueByArray}
                  row={localRow}
                  column={column}
                  name={name}
                  format={format}
                  customeHeader={customeHeader}
                  excludeExcel={excludeExcel}
                  excludePdfDoc={excludePdfDoc}
                />
              )}
            </Box>

            {/* <Button variant='text' onClick={() => {handleShowPagination()}}>click</Button> */}
            {rest?.uploadFile}
          </Box>
          <Paper sx={styles.tableContainer} className="table-container">
            {itemsSize < Constants.numberOfRows || !!rest?.defaulPagination ? (
              <TableContainer
                component={Paper}
                className="table-container"
              >
                <Table stickyHeader={true}>
                  <TableHead>{fixedHeaderContent()}</TableHead>
                  {localRow?.length > 0 && (
                    <TableBody>
                      {localRow
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((item, index) => (
                          <TableRow
                            sx={[
                              (selected ?? -1) === item.id
                                ? styles.selected
                                : styles.bodyRow,
                            ]}
                            key={`${item.id ?? index}-item`}
                            onClick={() => {
                              if (!tableChecks) onClick?.(item, item.id)
                            }}
                          >
                            {rowContent(index, item)}
                          </TableRow>
                        ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            ) : (
              <TableVirtuoso
                data={localRow?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )}
                components={VirtuosoTableComponents}
                fixedHeaderContent={fixedHeaderContent}
                itemContent={rowContent}
              />
            )}
          </Paper>
          {localRow?.length <= 0 && (
            <Typography sx={styles.empty}>
              {t('general.messages.emptyTable')}
            </Typography>
          )}
          {localRow?.length > 20 && !serverSidePagination && (
            <Box mt={2} sx={styles.containerFiles}>
              <Box sx={styles.flex} />
              {
                !rest?.defaulPagination && (
                  <CustomSelect
                    size="small"
                    label=""
                    name=""
                    sx={styles.selectedPagination}
                    onChange={({ target }) => {
                      const { value } = target
                      setPage(0)
                      setRowPerPage(value)
                    }}
                    items={[
                      { label: '20', value: 20 },
                      { label: '50', value: 50 },
                      { label: '100', value: 100 },
                      { label: '250', value: 250 },
                      { label: '500', value: 500 },
                    ]}
                    selected={rowsPerPage}
                  />
                )
              }

              <Pagination
                sx={styles.pagination}
                count={Math.ceil(localRow?.length / rowsPerPage)}
                page={page + 1}
                onChange={handleChangePage}
                showFirstButton
                showLastButton
              />
            </Box>
          )}
          {localRow?.length >= 0 && serverSidePagination && (
            <Box mt={2} sx={styles.containerFiles}>
              <Box sx={styles.flex} />
              {
                !rest?.defaulPagination && (
                  <CustomSelect
                    size="small"
                    label=""
                    name=""
                    sx={styles.selectedPagination}
                    onChange={({ target }) => {
                      const { value } = target
                      setPage(0)
                      setRowPerPage(value)
                      handleChangeSizeServerSide(value)
                    }}
                    items={[
                      { label: '20', value: 20 },
                      { label: '50', value: 50 },
                      { label: '100', value: 100 },
                      { label: '250', value: 250 },
                      { label: '500', value: 500 },
                    ]}
                    selected={rowsPerPage}
                  />
                )
              }

              <Pagination
                sx={styles.pagination}
                defaultPage={serverSidePagination.page}
                count={serverSidePagination.totalPages || 1}
                onChange={handleChangePageServerSide}
                showFirstButton
                showLastButton
              />
            </Box>
          )}
        </Box>
      )}
    </>
  )
})
