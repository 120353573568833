const useSettingsStyles = () => {
    return {
        containerStatus: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          width: '100%',
          height: '100%',
        },

    }
}

export { useSettingsStyles }
