import { ItemMenu } from "../../../../components/baseMenu"
import { Status } from "../../../../utils/enums"

const formData: Record<string, string> = {
    divisions: 'division',
    stores: 'store',
    dateRequestion: 'create_date',
    deadline: 'max_date',
}


const menuItems: ItemMenu[] = [
    {
        label: 'Ver reporte',
        value: 1,
    },
    {
        label: 'Ver detalle',
        value: 2,
    },
]

const statusList = [Status.EXPIRED, Status.APPROVED, Status.REJECTED, Status.CANCEL, Status.RESPONDED, Status.AWAIT_SOLUTION]
const formatFilter = {
    from: 'start_date',
    to: 'end_date',
    questionnaire: 'questionnaire_ids',
    zone: 'zone_ids',
    zonesClass: 'clase_ids',
    userType: 'user_type_ids',
    user: 'user_ids',
    stores: 'key_codes',
    status: 'status_ids',
}
export { formData, formatFilter, menuItems, statusList }