import { Status } from '../../../../../../utils/enums'
import { Color } from '../../../../../../utils/theme/types/color'

const useStyles = ({ colors, size }: { colors: Color; size?: { fontSize: number; width: number | string; height: number | string } }) => {
  return {
    priority: {
      width: !size?.width ? '45px' : `${size?.width}px`,
      height: !size?.height ? '45px' : `${size?.height}px`,
      fontSize: !size?.fontSize ? '40px' : `${size?.fontSize}px`,
      '& img': {
        width: '100%',
      },
      position: 'relative',
    },
    status: {
      fontSize: '17px',
      background: colors.color5,
      borderRadius: '50%',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
        position: 'absolute',
        top: 0,
        left: 0,
      },
      position: 'absolute',
      top: 0,
      right: 0,
    },
    [Status.APPROVED]: { color: colors.color1 },
    [Status.REJECTED]: { color: colors.color19 },
  }
}

export { useStyles }
