import { Route, Routes } from "react-router"
import { Main } from "../pages/main"
import { Detail } from "../pages/detail"
import { DashboardReportFolioProvider } from "../provider"

const DashboardReportFolioRoutes = () => {
    return (
        <DashboardReportFolioProvider>
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/detail/:id" element={<Detail />} />
            </Routes>
        </DashboardReportFolioProvider>
    )
}

export { DashboardReportFolioRoutes }
