import { Http } from '../../utils/types'
import { ErrorResponse, GetBlob, ObjectGeneric } from '../../utils/types/generals'
import { ActionPlanByID, ActionPlansListResponse, ResponseResend } from './response'

export const actionPlansServices = (client: Http) => ({
  getAll: async (data?: { queries?: string }): Promise<ActionPlansListResponse> => await client.get(`action-plan/list-action-plan-implemented${data?.queries ? '?' + data?.queries : '?page=1&size=20'}`),
  getById: async (id: number): Promise<ActionPlanByID> => await client.get(`action-plan/action-plan-details/${id}`),
  approve: async (body: ObjectGeneric): Promise<ActionPlanByID> => await client.post(`action-plan/approve-action-plan`, body),
  cancel: async (body: ObjectGeneric): Promise<ActionPlanByID> => await client.post(`action-plan/cancel`, body),
  reject: async (body: ObjectGeneric): Promise<ActionPlanByID> => await client.post(`action-plan/reject-action-plan`, body),
  sendReport: async (id: string, body:ObjectGeneric): Promise<ResponseResend> => await client.post(`scheduled-visit/${id}/resend-report`, body),
  sendReportQuestionnaire: async (id: string, body:ObjectGeneric): Promise<ResponseResend> => await client.post(`questionnaires/${id}/resend-report`, body),
  sendReportScheduleUser: async (id: string, body: ObjectGeneric): Promise<{ code: string } & ErrorResponse> => await client.post(`user-questionnaire/${id}/resend-report`, body),
  generateReport: async (id: number): Promise<{ urlToReport: string } & ErrorResponse> => await client.get(`action-plan/generate-report/${id}`),
  getRegister: async (data: { type: `pdf` | 'word' | 'excel'; query?: string }): Promise<GetBlob> => await client.getBlob?.(`action-plan/generate-file?type=${data.type}${data?.query ? `${data?.query}` : ''}`),
})
