import { InputModel } from '../../../../../../utils/types'

export const initialValues = {
  companies: {
    label: 'general.tags.companies',
    translate: true,
    name: 'companies',
    type: 'filter-select',
    items: [],
    value: null,
  },
  divisions: {
    label: 'general.tags.divisions',
    translate: true,
    name: 'divisions',
    type: 'filter-select',
    items: [],
    value: null,
    size: {
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
    },
  },
  fatherZone: {
    label: 'general.tags.fatherZone',
    translate: true,
    name: 'fatherZone',
    type: 'filter-select',
    items: [],
    value: null,
    size: {
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
    },
  },
  childZone: {
    label: 'general.tags.class',
    translate: true,
    name: 'childZone',
    type: 'filter-select',
    items: [],
    value: null,
    size: {
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
    },
  },
  states: {
    label: 'general.tags.states',
    translate: true,
    name: 'states',
    type: 'filter-select',
    items: [],
    size: {
      xl: 6,
      lg: 6,
      md: 6,
    },
    value: null,
  },
  city: {
    label: 'general.tags.cities',
    translate: true,
    name: 'city',
    type: 'filter-select',
    items: [],
    size: {
      xl: 6,
      lg: 6,
      md: 6,
    },
    value: null,
  },
  userManageId: {
    label: 'general.tags.userManageId',
    translate: true,
    name: 'userManageId',
    type: 'filter-select',
    items: [],
    value: null,
  },
} as { [x: string]: InputModel }
