import { useContextState } from "../../../../hooks/theme"

const useStyles = () => {
  const { colors: color } = useContextState()
  return {
    userDetail: ({ open }: { open: boolean }) => ({
      width: 'fit-content',
      maxWidth: open ? '900px' : '450px',
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'relative',
      transition: '.3s',
      '& .MuiTypography-root': {
        '&.quest-name-header': {
          fontSize: '16px',
          color: color.color33,
          fontWeight: '500',
        },
        '&.quest_name_title': {
          fontSize: '16px',
          color: color.color33,
          fontWeight: '600',
        },
      },
      '& .containerUserDetail': {
        padding: '20px 30px',
        display: 'flex',
        flexWrap: 'wrap',
        rowGap: '10px',
      },
      '& .padding_title': {
        padding: '20px 30px 5px',
      },
      '& .p0': {
        padding: '0'
      },
      '& .p2': {
        padding: '8px'
      },
      '& .p3': {
        padding: '12px'
      },
      '& .mb2': {
        marginBottom: '8px'
      }
    }),
    activeBug: ({ open }: { open: boolean }) => ({
      background: open ? color.color4 : color.color13,
      '&:hover': {
        background: `${open ? color.color4 : color.color13}ee`
      }
    }),
    containerBug: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      columnGap: '20px',
    },
    evidencesType: {
      '& .evidences-type-clip': {
        maxWidth: '200px',
      },
      wordBreak: 'break-word',
    },
    badge: (noLimit?: boolean) => ({
      margin: '10px 0px',
      '& .MuiBadge-badge': {
        background: color.color20,
        color: color.color25,
        ...noLimit && {
          padding: '15px',
          top: '-10px',
          right: '-10px',
          width: 'max-content',
        }
      },
    }),
    containerClose: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    bug: {
      color: color.color5,
      borderRadius: '5px',
      boxShadow: `1px 1px 2px ${color.color10}`,
      '& .MuiSvgIcon-root': {
        fontSize: '1.7rem',
      },
      marginBottom: '2px',
    },
    activeClip: {
      background: color.color30,
      '&:hover': {
        background: color.color30 + 'dd',
      },
    },
    border: {
      '& li:not(:last-child)': {
        borderBottom: `1px solid ${color.color13}`,
      },
    },
    rejectError: {
      fontSize: '11px',
      color: color.color19,
    },
    borderBottom: {
      borderBottom: `1px solid ${color.color13}`,
    },
    arrowHeaderDrawerContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      padding: '10px 20px 0',
    },
    closeSettings: {
      fontSize: '1.2rem',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    shrink: {
      position: 'absolute',
      right: '20px',
      top: '10px',
    },
    arrowTitleDrawerContainer: {
      width: '100%',
      padding: '10px 20px 0',
      '& .quest_name': {
        fontSize: '18px',
        color: color.color33,
        fontWeight: '600',
        paddingBottom: '10px',
      },
    },
    actionPlanDrawer: ({ open }: { open: boolean }) => ({
      maxWidth: '450px',
      ...open && { borderRight: `1px solid ${color.color13}`, },
    }),
    warning: {
      fontSize: '12px',
      paddingBottom: '40px',
      fontStyle: 'italic',
      color: color.color19,
      marginTop: '-20px',
      '& span': { paddingRight: '5px' },
    },
    fieldRequired: {
      marginTop: '10px',
      width: '100%',
    },
    alignGrid: {
      width: '100%',
      '& .MuiGrid-root': {
        alignItems: 'center',
      },
    },
  }
}

export { useStyles }