import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { icons } from '../../utils/icons'

const CustomAccordion = ({ title, description, expandIcon, expanded, defaultExpanded, onChange }: { defaultExpanded?:boolean; onChange?: (expand: boolean) => void; title: string | JSX.Element; description: string | JSX.Element; expandIcon?: JSX.Element | null; expanded?: boolean }) => {
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      sx={{ width: "100%" }}
      {...{
        ...expanded !== undefined && { expanded }
      }
      }
      onChange={() => {
        onChange?.(!expanded)
      }}
    >
      <AccordionSummary expandIcon={expandIcon === null ? undefined : expandIcon ?? icons('arrow-down')} aria-controls="panel1a-content" id="panel1a-header">
        {typeof title === 'string' ? <Typography>{title}</Typography> : title}
      </AccordionSummary>

      <AccordionDetails>{typeof description === 'string' ? <Typography sx={{ wordBreak: 'break-word' }}>{description}</Typography> : description}</AccordionDetails>
    </Accordion>
  )
}

export default CustomAccordion
