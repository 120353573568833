import { useContextState } from "../../../../../../hooks/theme"

const useStyles = () => {
    const { colors } = useContextState()

    return {
        container: ({ isActive }: { isActive: boolean }) => ({
            padding: '20px',
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "20px",
            ...!!isActive && {
                background: colors.backgroundDark,
            }
        }),
        headerContainer: {
            width: "100%",
            display: "flex",
            gap: "10px",
            alignItems: "start"
        },
        descriptionContainer: () => ({
            width: `calc(100%)`,
        }),
        eye: ({ isActive }: { isActive: boolean }) => ({
            padding: "0px",
            ...isActive && {
                color: colors.color5
            },
            fontSize: "1.2rem",
            '& .MuiSvgIcon-root': {
                fontSize: "inherit"
            }
        }),
        label: ({ isActive }: { isActive: boolean }) => ({
            width: '100%',
            fontSize: '.7rem',
            ...isActive && {
                color: colors.color5,
            },
            fontWeight: '600'
        }),
        noData: {
          width: '100%',
          textAlign: 'center',
          fontSize: '14px',
          color: `${colors.color13}`,
        },
        subtitle:({ isActive }: { isActive: boolean }) => ({
            fontSize: ".6rem",
            color: isActive ? colors.color4 : colors.color16,
        })
    }
}

export { useStyles }
