import { useTranslation } from 'react-i18next'
import { Value } from '../types'
import { useEffect, useState } from 'react'

export const useInitialState = (payload: { tmp?: string }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState<Value>({})

  useEffect(() => {
    setValue(() => ({
      ...(!payload?.tmp
        ? {
            code: {
              name: 'code',
              label: t('login.signin.form.code'),
              value: null,
            },
          }
        : {
          currentPassword: {
            name: 'currentPassword',
            label: t('login.signin.form.currentPassword'),
            value: null,
            type: 'password',
          },
        }),
      password: {
        name: 'password',
        label: t('login.signin.form.newPassword'),
        value: null,
        type: 'password',
      },
      confirmPassword: {
        name: 'confirmPassword',
        label: t('login.signin.form.confirmPassword'),
        value: null,
        type: 'password',
      },
    }))
  }, [payload?.tmp, t])

  return {
    value,
    form: {
      description: t(`login.changePassword.${payload?.tmp ? 'descriptiopn2' : 'description'}`),
      button: t('login.signin.changePassword'),
      footer: t('login.signin.footerConfirm'),
    },
    t,
  }
}
