import { forwardRef } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { Typography } from '@mui/material'

const Alert = forwardRef(function Alert(props:any, ref:any) {
  return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />
})

const SnackSimple = ({ open, close, message, type, time = 3000 }:any) => {
  const handleClose = (_:any, reason:any) => {
    if (reason !== 'clickaway') {
      close(false)
    }
  }

  return (
    <Snackbar sx={{width: 'max-content'}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={time} onClose={handleClose}>
      <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        <Typography variant="body1">{message}</Typography>
      </Alert>
    </Snackbar>
  )
}

export default SnackSimple
