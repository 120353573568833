import { useEffect, useState } from 'react'
import { ListItem } from '../../floatList/types'
import { NotificationBell } from './components/itemNotificationBell'
import { userServiceFetch } from '../../../utils/constants/callService'
import { format } from 'date-fns'
import { Constants } from '../../../utils/constants'
import { Types } from '../../../utils/enums'

const useBody = () => {
  const [anchorEl, setAnchorEl] = useState<Record<string, null | HTMLElement>>({
    profile: null,
    bell: null,
  })
  const [listLoadingNotifications, setListLoadingNotifications] = useState<
    Record<string, boolean>
  >({})
  const [loading, setLoading] = useState<Record<string, boolean>>({
    notification: true,
  })
  const [snackbar, setSnackbar] = useState({
    open: false,
    type: Types.SUCCESS,
    message: '',
  })
  const [notifications, setNotifications] = useState<ListItem[]>([])
  const handleClick = ({
    event,
    type,
  }: {
    event: React.MouseEvent<HTMLButtonElement> | null
    type: string
  }) => {
    setAnchorEl((prev) => ({
      ...prev,
      [type]: event ? event.currentTarget : event,
    }))
  }

  const closeSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }))
  }

  const getNotifications = async () => {
    try {
      setLoading((prev) => ({
        ...prev,
        notification: true,
      }))
      const response = await userServiceFetch.notifications()

      const notifyConrted = response
        .sort((a, b) => {
          if (
            new Date(a.create_time).getTime() >
            new Date(b.create_time).getTime()
          )
            return -1
          return 1
        })
        .map((item) => ({
          id: item.id.toString() ?? '',
          children: (
            <NotificationBell
              remove={() => {
                removeNotifications({ id: [item.id.toString()] })
              }}
              date={format(
                new Date(item.create_time),
                Constants.dateFormatGeneral
              )}
              title={item.title}
              description={item.description}
              type={item.notificationTypeId}
            />
          ),
        }))

      setNotifications(notifyConrted)
    } catch (e) {
      setNotifications([])
    } finally {
      setLoading((prev) => ({
        ...prev,
        notification: false,
      }))
    }
  }

  const refreshNotifications = () => {
    getNotifications()
  }

  const removeNotifications = async ({
    id,
    all,
  }: {
    id?: string[]
    all?: boolean
  }) => {
    let data: string[] = []

    if (!!id) {
      data = id
      setListLoadingNotifications(prev => ({
        ...prev,
        [id.toString()]: true
      }))
    } else if (!!all) {
      data = notifications.map((item) => item.id)
    }
    if (!!data?.length) {
      if(!!all) {
        setLoading((prev) => ({ ...prev, notification: true }))
      }

      const response = await userServiceFetch.deleteNotifications({
        body: { id: data },
      })

      if(!!id) {
        setListLoadingNotifications(prev => ({
          ...prev,
          [id.toString()]: false
        }))
      }

      if (!response?.errors) {
        setNotifications(prev => prev.filter(item => !data?.some(itemIds => item.id == itemIds)))
        setSnackbar((prev) => ({
          ...prev,
          open: true,
          type: Types.SUCCESS,
          message: id
            ? 'La notificación se descartó exitosamente'
            : 'Todas las notificaciones se descartaron exitosamente',
        }))
      } else {
        setSnackbar((prev) => ({
          ...prev,
          open: true,
          type: Types.ERROR,
          message: id
            ? 'Ocurrió un error al descartar la notificacion'
            : 'Ocurrió un error al descartar las notificaciones',
        }))
      }
      setLoading((prev) => ({ ...prev, notification: false }))
    }
  }

  useEffect(() => {
    getNotifications()
  }, [])
  return {
    anchorEl,
    notifications,
    loading,
    snackbar,
    listLoadingNotifications,
    closeSnackbar,
    handleClick,
    refreshNotifications,
    removeNotifications,
  }
}

export { useBody }
