import { ManageData, OpenFolioByAnswer } from "./type"

const manageData = ({ body, evidence, setReportFolioByAnswer, id }: ManageData) => {
    setReportFolioByAnswer(prev => ({
        ...prev,
        id: null,
        data: {
            ...prev.data ?? {},
            [id ?? ""]: {
                default: {
                    limitFolios: {
                        maxFolios: body.maxFolios,
                        maxFoliosAmount: body.maxFoliosAmount
                    },
                    rejectsFolio: {
                        enableSurvey: body.enableSurvey,
                        enableSurveyRequired: body.enableSurveyRequired,
                        maxRejects: body.maxRejects,
                        maxRejectsAmount: body.maxRejectsAmount,
                        satisfactionQuestion: body.satisfactionQuestion,
                    }
                },
                evidencesData: evidence
            }
        }
    }))
}


const openFolios = ({ setReportFolioByAnswer, id, t }: OpenFolioByAnswer) => {
    const defaultFoliosLimit = {
        maxFolios: false,
        maxFoliosAmount: 1
      }
      const defaultFoliosReject = {
        maxRejects: false,
        maxRejectsAmount: 1,
        enableSurvey: false,
        enableSurveyRequired: false,
        satisfactionQuestion: t('reportFolio.detail.approve.title')
      }
    setReportFolioByAnswer(prev => {
        const defaultValue = prev?.data?.[id]
        const limitFolio = defaultValue?.default?.limitFolios
        const rejectsFolio = defaultValue?.default?.rejectsFolio
        return ({
            ...prev,
            id,
            data: {
                ...prev.data,
                [id]: {
                    ...defaultValue ?? {},
                    default: {
                        limitFolios: {
                            ...defaultFoliosLimit,
                            ...limitFolio ?? {},
                        },
                        rejectsFolio: {
                            ...defaultFoliosReject,
                            ...rejectsFolio ?? {},
                        }
                    }
                }
            }
        })
    })
}
export { manageData, openFolios }
