import { Http } from '../../utils/types'
import { ObjectGeneric } from '../../utils/types/generals'
import { GetAllDepartments, ResponseAmountUser, ResponseDepartment, ResponseDepartmentId } from './response'

export const departmentService = (client: Http) => ({
  getAllDepartments: async (): Promise<GetAllDepartments> => await client.get('department'),
  createDepartment: async (body: ObjectGeneric): Promise<ResponseDepartment> => await client.post('department', body),
  updateDepartment: async (body: ObjectGeneric, id: number): Promise<ResponseDepartment> => await client.put(`department/${id}`, body),
  deleteDepartment: async (id: number): Promise<ResponseDepartment> => await client.delete(`department/${id}`),
  getDepartmentById: async (id?: number): Promise<ResponseDepartmentId> => await client.get(`department/${id}`),
  getAllUsers: async (): Promise<any> => await client.get('users/not-assigned-department'),
  getCountUserJerarquia: async (query: { query: string }): Promise<ResponseAmountUser> => await client.get(`users/users-count${query.query ? `?${query.query}` : ''}`),
})
