import { EvidencesTypeValueEnum } from '../enums/evidences'

export const Constants = {
  numberOfRows: 16,
  dateFormatGeneral: 'dd-MM-yyyy hh:mm:ss aaa',
  dateFormatGeneral2: 'dd/MM/yyyy hh:mm:ss aaa',
  dateFormatGeneral3: 'dd/MM/yyyy hh:mm aaa',
  dateFormat: 'dd-MM-yyyy',
  dateFormat2: 'dd/MM/yyyy',
  dateFormat3: 'yyyy-MM-dd',
  hourFormat: 'hh:mm:ss aaa',
  dateFormatDateTimePicker: 'DD/MM/YYYY hh:mm:ss a',
  dateEndpoint: "yyyy-MM-dd'T'HH:mm:ss",
  humanFormat: "dd 'de' MMMM 'del' yyyy hh:mm:ss aaa",
  humanFormatShort: "dd 'de' MMMM 'del' yyyy",
  humanFormatShort2: "dd 'de' MMM 'del' yyyy",
  humanFormatDiminutiveShort: "dd MMM yyyy",
  dateEndpoint2: `yyyy-MM-dd'T'HH:mm:ss-${process.env.REACT_APP_GMT}`,
  specialCharacters: 'ñÑáéíóúÁÉÍÓÚ',
  alphaNumeric: '0-9ñÑáéíóúÁÉÍÓÚ',
  nameAWS: process.env.REACT_APP_NAME ?? '',
  notFoundKey: ":not_found:",
  fileType: {
    csv: 'text/csv',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  acceptFile: {
    csv: '.csv',
    xlsx: '.xlsx',
    [EvidencesTypeValueEnum.VIDEO_GALLERY]: ['wma', 'wmv', 'mp4'],
    [EvidencesTypeValueEnum.VIDEO]: ['wma', 'wmv', 'mp4'],
    [EvidencesTypeValueEnum.DOCUMENT]: ['pdf', 'csv', 'docx', 'xlsx', 'pptx', 'txt'],
    [EvidencesTypeValueEnum.IMAGE]: ['png', 'jpg', 'jpeg'],
    [EvidencesTypeValueEnum.CAMERA]: ['png', 'jpg', 'jpeg'],
  } as { [x: string]: any },
  headers: {
    multipart: {
      Accept: 'multipart/form-data',
    },
    normal: {
      'Content-Type': 'application/json',
    },
  },
  week: [
    {
      label: 'Lunes',
      value: 'monday',
    },
    {
      label: 'Martes',
      value: 'tuesday',
    },
    {
      label: 'Miercoles',
      value: 'wednesday',
    },
    {
      label: 'Jueves',
      value: 'thursday',
    },
    {
      label: 'Viernes',
      value: 'friday',
    },
    {
      label: 'Sábado',
      value: 'saturday',
    },
    {
      label: 'Domingo',
      value: 'sunday',
    },
  ],
  optionByMonth: [
    {
      label: 'Primer hábil del mes',
      value: 'start_month',
    },
    {
      label: 'Último hábil del mes',
      value: 'end_month',
    },
    {
      label: 'Día 15 del mes',
      value: '15_th',
    },
  ],
}
