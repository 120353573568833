import { Typography } from "@mui/material"
import { format } from "date-fns"
import { Constants } from "../../../../../../../../utils/constants"
import { useDateStyles } from "./dateStyles"

const DateItems = (props: any) => {
    const styles = useDateStyles()
    return (
      <Typography sx={styles.textItem}>
        {props?.current
          ? format(new Date(props?.current), Constants.dateFormatGeneral2)
          : 'N/A'}
      </Typography>
    )
  }

export { DateItems }
