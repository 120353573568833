import { useContextState } from "../../hooks/theme";

const useStyles = ({ length, loading }: { length: number; loading: boolean }) => {
  const { colors } = useContextState()
  return {
    container: ({ top, left, }: { top: number; left: number }) => ({
      position: 'absolute',
      left: (left),
      top: (top) + 100,
      width: '540px',
      maxHeight: 'calc(100vh - 100px)',
      height: (!!loading || !length) ? '150px' : (length < 6 ? `${(length * 145) + 50}px` : 'calc(100vh - 100px)'),
      boxShadow: `0px 0px 4px ${colors.color13}`,
      overflow: 'hidden',
      background: colors.color5,
      borderRadius: '5px',
      paddingTop: '0px'
    }),
    list: {
      width: '100%',
      minHeight: '100%',
      paddingLeft: '20px',
      'overflowX': 'hidden'
    },
    noData: {
      width: '100%',
      textAlign: 'center',
      fontSize: '18px',
      marginTop: "20px",
      color: `${colors.color13}`,
    },
    loader: {
      width: 'calc(100% - 40px)',
      borderRadius: '10px',
      boxShadow: `0px 0px 4px ${colors.color13}`,
    }
  }
}

export { useStyles }
