import { StatusListResponse } from './responses'

const statusList = [
    {
        "id": 1,
        "description": "Activo"
    },
    {
        "id": 2,
        "description": "Inactivo"
    },
    {
        "id": 3,
        "description": "Pausado"
    },
    {
        "id": 4,
        "description": "Cerrado"
    },
    {
        "id": 5,
        "description": "Cancelado"
    },
    {
        "id": 6,
        "description": "Reagendado"
    },
    {
        "id": 7,
        "description": "Reasignado"
    },
    {
        "id": 8,
        "description": "Finalizado"
    },
    {
        "id": 9,
        "description": "Por Iniciar"
    },
    {
        "id": 10,
        "description": "No Finalizado"
    },
    {
        "id": 11,
        "description": "Retirado"
    },
    {
        "id": 12,
        "description": "En espera"
    },
    {
        "id": 13,
        "description": "Rechazado"
    },
    {
        "id": 14,
        "description": "Bloqueado"
    },
    {
        "id": 15,
        "description": "Creado"
    },
    {
        "id": 16,
        "description": "Actualizado"
    },
    {
        "id": 17,
        "description": "Iniciado"
    },
    {
        "id": 18,
        "description": "Respondido"
    },
    {
        "id": 19,
        "description": "Aprobado"
    },
    {
        "id": 20,
        "description": "Espera Solución"
    },
    {
        "id": 21,
        "description": "Vencido"
    },
    {
        "id": 23,
        "description": "Incompleto"
    }]

export const statusService = () => ({
    statusList:():StatusListResponse => statusList,
})