import { Box, IconButton } from '@mui/material'
import { useContextState } from '../../../hooks/theme'
import { icons } from '../../../utils/icons'
import { onChange } from '../../../utils/types'
import { useStyles } from './styles'
import ColorPicker from 'material-ui-color-picker'

interface Props {
  label: string
  onChange: onChange
  name: string
  value: string
  required?: boolean
}
export const WrapperColors = ({ label, onChange, name = 'color', value, required = false }: Props) => {
  const { colors } = useContextState()
  const styles = useStyles({ colors, value })
  
  return (
    <Box position={'relative'}>
      <ColorPicker
        name='color'
        sx={styles.colorPickerCont}
        label={label}
        defaultValue={value ?? '#000'}
        TextFieldProps={{
          value: value ?? '#000'
        }}
        InputProps={{
          startAdornment: <Box className="square" sx={{ border: `1px solid ${colors.color13}`, cursor: 'pointer', width: '400px', height: "40px" }} />,
          endAdornment: <IconButton>{icons('paint')}</IconButton>,
        }}
        onChange={(e: any) => {
          if(!!e){
            if (new RegExp('^#[0-9a-fA-F]{0,8}$').test(e)) {
              onChange({
                target: {
                  name: name,
                  value: e,
                },
              })
            }else{
              const rgbaRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)$/i;
              const match = e?.match(rgbaRegex);
              const red = parseInt(match[1]);
              const green = parseInt(match[2]);
              const blue = parseInt(match[3]);
              const alpha = match[4] ? parseFloat(match[4]) : 1;
  
              const redHex = red.toString(16).padStart(2, '0');
              const greenHex = green.toString(16).padStart(2, '0');
              const blueHex = blue.toString(16).padStart(2, '0');
              const alphaHex = Math.round(alpha * 255).toString(16).padStart(2, '0');
  
              const hexColor = `#${redHex}${greenHex}${blueHex}${alphaHex}`;
              onChange({
                target: {
                  name: name,
                  value: hexColor.toUpperCase(),
                },
              })
            }
          }
        }}
      />
    </Box>
  )
}
