import { Autocomplete, Chip, TextField, } from '@mui/material'
import { Props, RefFilterSelected } from './props'
import { useState, SyntheticEvent, useEffect, useImperativeHandle, forwardRef, Ref } from 'react'
import { Item } from '../../../utils/types/generals'
import { useTranslation } from 'react-i18next'
import { CustomPaper } from '../../CustomPaper'
import { ChipsList } from '../../chipsList'

const styles = {
  caption: {
    width: '100%',
    wordBreak: 'break-all',
  },
  chip: {
    display: 'none',
    width: '90%',
    height: 'fit-content',
    wordBreak: 'break-all',
    background: '#00000011',
    '& .MuiChip-label': {
      textOverflow: 'clip',
      whiteSpace: 'break-spaces',
    },
  },
  input: {
    '& .MuiOutlinedInput-root': {
      height: '53px',
      display: 'flex',
      flexDirection: 'column',
    },
    '& .MuiAutocomplete-tag': {
      maxWidth: 'calc(100% - 22px)',
      display: 'none',
    },
  },
  chipUnFocus: {
    background: '#31c462',
    color: 'white',

    '&.MuiChip-clickable:hover': {
      backgroundColor: '#31c462',
    },
  },
}
const FilterSelect = forwardRef(({hidechips, ...props}: Props, ref: Ref<RefFilterSelected>) => {
  const [value, setValue] = useState<Item[]>([])
  const { t } = useTranslation()
  const [isFocus, setIsFocus] = useState(false)

  useImperativeHandle(ref, () => ({
    deleteChips: handleDelete
  }))

  const handleChange = (_: SyntheticEvent<Element, Event>, value: any[]) => {
    props.onChange({
      target: {
        name: props.name,
        value: value,
      },
    })
  }
  const filterItems = () => {
    let newList: Item[] = []
    props.items?.forEach((item: any) => {
      if (!value?.find((itemValue: any) => itemValue?.value === item?.value)) {
        newList = [...newList, item]
      }
    })

    return newList
  }

  const handleDelete = (option: any) => {
    let values = [...value]
    values = values.filter((e) => e.value != option.value)

    props.onChange({
      target: {
        name: props.name,
        value: values,
      },
    })
  }
  useEffect(() => {
    setValue(props.items?.filter((item) => props.selected?.some((itemSelect: Item) => itemSelect.value == item.value)) ?? [])
    // eslint-disable-next-line
  }, [props.selected])

  return (
    <>
      <Autocomplete
        {...props}
        key={props.name}
        sx={{ ...props.sx, ...(!isFocus ? styles.input : {}) }}
        noOptionsText={t('general.noOptions')}
        multiple
        options={filterItems() ?? []}
        getOptionLabel={(option) => option.label ?? ''}
        value={value}
        limitTags={1}
        PaperComponent={CustomPaper}
        disableCloseOnSelect
        autoComplete={false}
        filterSelectedOptions
        onFocus={() => {
          setIsFocus(true)
        }}
        onBlur={() => {
          setIsFocus(false)
        }}
        onChange={handleChange}
        renderTags={(value, getTagProps) => {
          return value.map((option, index) => <Chip sx={isFocus ? styles.chip : styles.chipUnFocus} variant="outlined" label={option.label} {...getTagProps({ index })} />)
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={props.name}
            label={props.label}
            helperText={!!props?.disabled ? false : (props?.errors?.[props?.name]?.translate ? t(props?.errors?.[props?.name]?.translate) : props?.errors?.[props?.name]) ?? ''}
            error={!!props?.disabled ? false : props?.errors?.[props?.name]?.translate ?? props?.errors?.[props?.name]}
          />
        )}
      />

      {!hidechips && <ChipsList value={value} handleDelete={handleDelete} />}
    </>
  )
})

export default FilterSelect
