import { Box /* , Grid */ } from '@mui/material'
// import MuiPhoneNumber from 'material-ui-phone-number'
// import { BaseInput, CustomSelect, Images } from '../../../components'
// import AddImage from '../../../components/addImage'
import { DinamicForm } from '../../../../../components/dinamicForm'
import Loader from '../../../../../components/loader'
import { useStyles } from './styles'
// import { Field } from '../../../utils/enums/fields'
// import { images } from '../../../utils/images'
// import { gender, username } from '../initialState'

const Form = ({ btnLoading, data, input, crudChange, allErrors }: any) => {
  const styles = useStyles()

  return btnLoading ? (
    <Box
      sx={styles.loader}
    >
      <Loader />
    </Box>
  ) : (
    <Box sx={styles.container}>
      <DinamicForm input={input} values={data} onChange={crudChange} errors={allErrors} />
    </Box>
  )
}

export default Form
