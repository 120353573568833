import { useState, useEffect } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ObjectGeneric, onChange } from '../../../../../../utils/types/generals'
import { isValidateDate } from '../../../../../../utils/validate/validate'
import { FiltersDrawer } from '../../../../../../components/filtersDrawer'
import SimpleModal from '../../../../../../components/modal/simpleModal'
import { DinamicForm } from '../../../../../../components/dinamicForm'
import { useContextState } from '../../../../../../hooks/theme'
import { CustomTable, SnackSimple } from '../../../../../../components'
import { icons } from '../../../../../../utils/icons'
import { useGetData } from './hooks/useGetData'
import { useInput } from './hooks/useInputs'
import { useStyles } from './style'
import Loader from '../../../../../../components/loader'
import { Types } from '../../../../../../utils/enums'
import { departmentService } from '../../../../../../utils/constants/callService'
import { handleAllErrors } from '../../../../../../utils/methods/handleErrors'
import { useNavigate } from 'react-router-dom'
import { filterTable } from './methods'
import DownloadFiles from '../../../../../../components/donwloadFiles'

const Department = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { items, table, loadingTable, requestTable } = useGetData()
  const { input } = useInput({ items })
  const [openModal, setOpenModal] = useState({
    id: 0,
    open: false,
  })
  const column: any[] = [
    {
      id: 'createTime',
      label: t('general.tags.createTime'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'area',
      label: t('general.tags.area'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'name',
      label: t('general.tags.department'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'email',
      label: t('general.tags.email'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'phone',
      label: t('general.tags.number'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'action',
      label: t('general.tags.action'),
      minWidth: 50,
      renderCell: (params: any) => {
        return (
          <Box sx={styles.action}>
            <IconButton
              sx={styles.deleteIcon}
              onClick={(e) => {
                e.stopPropagation()
                setOpenModal((prev) => ({
                  ...prev,
                  id: params?.row?.id,
                  open: true,
                }))
              }}
            >
              {icons('delete')}
            </IconButton>
          </Box>
        )
      },
    },
  ]

  const { colors } = useContextState()
  const styles = useStyles(colors)
  const [btnLoading, setBtnLoading] = useState(false)

  const [counter, setCounter] = useState<number | null>(null)
  const [erros, setErrors] = useState<ObjectGeneric>({})
  const [dataFilter, setDataFilter] = useState<ObjectGeneric>({})

  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
    type: Types.SUCCESS as string,
  })

  const handleCloseSnackbar = (value: boolean) => setOpenSnackbar({ ...openSnackbar, open: value })

  const handleCloseModal = () => setOpenModal((prev) => ({ ...prev, open: false }))

  const handleDelete = async () => {
    if (openModal.id) {
      setBtnLoading(true)
      const response = await departmentService.deleteDepartment(openModal.id)

      if (!response.errors) {
        setOpenSnackbar({
          open: true,
          type: Types.SUCCESS as string,
          message: t(`department.snackbar.delete.success`),
        })
        requestTable()
      } else {
        let obj = {
          open: true,
          type: Types.ERROR as string,
          message: handleAllErrors(response.errors[0].code, t, t(`department.snackbar.delete.error`)),
        }

        setOpenSnackbar(obj)
      }
      setBtnLoading(false)
      handleCloseModal()
    }
  }

  const onChange: onChange = ({ target }, blur) => {
    const { name, value } = target

    let startDate = 0
    let endDate = 0

    if (['from', 'to'].includes(name)) {
      if (!(blur as { blur: boolean })?.blur) {
        startDate = name === 'from' ? value?.valueOf() : dataFilter?.from?.valueOf()
        endDate = name === 'to' ? value?.valueOf() : dataFilter.to?.valueOf()
      } else {
        if (!isValidateDate(value)) {
          const splitValue = value?.split('/')?.reverse()?.join('-')
          const newValue = new Date(`${splitValue} 00:00:00`)?.getTime()
          startDate = name === 'from' ? newValue : dataFilter.from?.valueOf()
          endDate = name === 'to' ? newValue : dataFilter.to?.valueOf()
        }
      }
    }

    if (['from', 'to'].includes(name) && value !== '' && (blur as { blur: boolean })?.blur) {
      setErrors((prev) => ({
        ...prev,
        [name]: isValidateDate(value) ? t('general.invalidateDate') : null,
      }))
    } else {
      const body = {
        ...dataFilter,
        [name]: value,
      }

      setDataFilter(body)
    }

    if (startDate && endDate) {
      if (startDate > endDate) {
        setErrors((prev) => ({
          ...prev,
          [name]: t('general.wrongDate'),
        }))
      } else {
        setErrors((prev) => ({
          ...prev,
          from: null,
          to: null,
        }))
      }
    }

    if (!value) {
      setErrors((prev) => ({
        ...prev,
        [name]: null,
      }))
    }
  }

  const cleanFilter = () => {
    setDataFilter({})
    setErrors({})
  }

  useEffect(() => {
    setCounter(null)
    let count = 0
    for (let key in dataFilter) {
      if (dataFilter[key] instanceof Array) {
        if (dataFilter[key]?.length > 0) count++
      } else if (dataFilter[key] != null && dataFilter[key] !== '') count++
    }

    setCounter(count)
  }, [dataFilter])

  const handleCreate = () => {
    navigate('create')
  }

  const handleEdit = (e: any) => {
    navigate(`edit/${e.id}`)
  }

  return (
    <Box sx={styles.containerTable}>
      {/* HEADER */}
      <Box sx={[styles.header, styles.actions]}>
        <Typography sx={styles.title}>{t('general.tags.departments')}</Typography>

        <Box sx={styles.flex}>
          <DownloadFiles
            row={filterTable(dataFilter, table)}
            column={column}
            name={t('general.tags.departments')}
            csvText={'EXCEL'}
            removeMargin
          />

          <IconButton
            sx={(styles.clean, (counter || 0) > 0 ? null : styles.hide)}
            onClick={() => {
              cleanFilter()
            }}
          >
            {icons('clean')}
          </IconButton>

          <IconButton className="add" onClick={handleCreate}>
            {icons('add')}
          </IconButton>
        </Box>
      </Box>

      {/* FLOATS */}
      <FiltersDrawer cleanFilter={cleanFilter} count={counter} right={0} top={50}>
        <Box sx={styles.section}>
          <DinamicForm errors={erros} input={Object.values(input)} values={dataFilter} onChange={onChange} filter={true} />
        </Box>
      </FiltersDrawer>

      <SnackSimple close={handleCloseSnackbar} open={openSnackbar.open} type={openSnackbar.type} message={openSnackbar.message} />

      <SimpleModal
        loadingButton
        isLoading={btnLoading}
        open={openModal.open}
        close={handleCloseModal}
        title={t('department.modal.delete.title')}
        description={''}
        buttonText={t('general.confirm')}
        cancelText={t('general.cancel')}
        color={colors}
        back={handleCloseModal}
        next={handleDelete}
      />

      {/* TABLE */}
      {!loadingTable ? <CustomTable onClick={handleEdit} name={t('general.tags.departments')} column={column} row={filterTable(dataFilter, table)} color={colors} selected={-1} /> : <Loader />}
    </Box>
  )
}

export default Department
