import { LoadingButton } from "@mui/lab"
import BaseModal from "../../../../../../../../components/modal/baseModal"
import { Box, Grid, IconButton, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useStyles } from "../../styles"
import { AddUserModal, DataModal } from "./types"
import { icons } from "../../../../../../../../utils/icons"
import { StepManagement } from "../../../../../../../../components/stepManagement"
import { Step2, Step2Ref } from "../steps/step2"
import { Step1, Step1Ref } from "../steps/step1"
import { useMemo, useState } from "react"
import { useRefs } from "../../../../../../../../hooks/useReactHookModified"
import { StepManagementProps, StepManagementRef } from "../../../../../../../../components/stepManagement/types"
import SimpleModal from "../../../../../../../../components/modal/simpleModal"
import Loader from "../../../../../../../../components/loader"
import { MessageUsersAdded, MessageUsersExisted } from "../messages"

const AddUsersModal = (props: AddUserModal) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const { refsByKey, setRef } = useRefs<Step1Ref | Step2Ref | StepManagementRef>()
  const [title, setTitle] = useState(t("general.toAddUsers") as string)
  const [error, setError] = useState(false)
  const [data, setData] = useState<DataModal>({
    step1: { selects: [], },
    step2: { permissons: {} },
    userAlreadyLinked: [],
    usersAmmount: 0
  })

  const closeModalPermissions = () => {
    resetData({ loadSuccess: true, all: true })
    props.close()
  }

  const messageComponent = useMemo(() => {
    if(!data.usersAmmount && !error) return undefined
    if(error) return <MessageUsersExisted/>
    return <MessageUsersAdded usersAmmount={data.usersAmmount} return={closeModalPermissions}/>
  }, [data.usersAmmount, error])
  /**
   * @returns return the list of users filtering the users than were added to the access questionnaire list
   */
  const userList = useMemo(() => props.userList?.filter(item => !data.userAlreadyLinked?.some(itemStep => itemStep == item.id)), [props.userList, data.userAlreadyLinked])
  const [modal, setModal] = useState<{
    open: boolean
    title: string
    description: string
    confirm: () => void
    cancel?: () => void
  }>({
    open: false,
    title: '',
    description: '',
    confirm: () => { }
  })
  
  const stepChanged: StepManagementProps['changeStep'] = (payload) => {
    if(data.usersAmmount) {
      setData(prev => ({ ...prev, usersAmmount: 0 }))
    }
    if(error) {
      setError(false)
    }

    if (payload.newValue == 0) {
      setTitle(t("general.toAddUsers"))
    } else {
      setTitle(t("general.toAddPermissions"))
    }
    if (payload.oldValue == 0) {
      const ref = (refsByKey['step1'] as Step1Ref)

      if (ref?.getSelects) {
        setData(prev => ({ ...prev, step1: { ...prev.step1, selects: ref?.getSelects() ?? [] } }))
      }
    } else if (payload.oldValue == 1) {
      const ref = (refsByKey['step2'] as Step2Ref)

      if (ref?.getPermissions) {
        setData(prev => ({ ...prev, step2: { permissons: ref?.getPermissions() ?? {} } }))
      }
    }
  }

  /**
   * @param payload catch a object 
   * @object { loadSuccess: boolean; amountOfUserAdded?: number }
   * @param payload.loadSuccess if the response is true restart all the data
   * @param payload.amountOfUserAdded ammount of users than were added to access questionnaire list
   */
  const resetData = (payload: {
    loadSuccess: boolean;
    amountOfUserAdded?: number
    all?:boolean
  }) => {
    if (payload.loadSuccess) {
      const refStep2 = (refsByKey['step2'] as Step2Ref)
      const ref = (refsByKey['StepManagement'] as StepManagementRef)
      if (!payload.amountOfUserAdded) {
        const refStep1 = (refsByKey['step1'] as Step1Ref)

        if (ref?.restart) {
          ref.restart()
        }

        if (refStep1?.restart) {
          refStep1.restart()
        }
      }
      /**
      * @description cleean the locale data and save the users selectecs to dont added to the list of users
      */
      const newUserList = payload?.all ? [] : Array.from(new Set([...data.step1.selects, ...data.userAlreadyLinked]))
      setData(prev => ({
        ...prev,
        step1: { selects: [] },
        step2: { permissons: {} },
        usersAmmount: payload.amountOfUserAdded,
        userAlreadyLinked: newUserList
      }))

      if (refStep2?.restart) {
        refStep2.restart()
      }

      if (ref?.disableSteps) {
        ref.disableSteps({0: true})
      }
    } else { 
      setError(true)
    }
  }

  /**
   * @description get the permissions and the list of users selecteds
   */
  const finished = () => {
    const refStep1 = (refsByKey['step1'] as Step1Ref)
    const refStep2 = (refsByKey['step2'] as Step2Ref)
    let permissions = data.step2.permissons
    let selects = data.step1.selects

    if (refStep1?.getSelects) {
      selects = refStep1.getSelects()
    }
    if (refStep2?.getPermissions) {
      permissions = refStep2.getPermissions()
    }
    const userListLocale = userList.filter(item => selects.includes(item.id))
    /**
    * @description if the endpoint is successfull execute the resetData
    */
    props.loadData.onClick(userListLocale, permissions, resetData)
  }
  /**
   * @description close the modal of confirmation
   */
  const closeModal = () => { setModal(prev => ({ ...prev, open: false })) }

  /**
 * @description apply filters to get the list of users
 */
  const searchUsers = () => {
    const refStep1 = (refsByKey['step1'] as Step1Ref)
    const ref = (refsByKey['StepManagement'] as StepManagementRef)
    let selectsLocale: (string | number)[] = data.step1.selects

    if (!!refStep1?.getSelects) {
      selectsLocale = refStep1?.getSelects()
    }

    if (!selectsLocale?.length) {
      props.searchButton.onClick()
      if (ref?.getToStep) {
        ref.getToStep(0)
      }
    } else {
      setModal(prev => ({
        ...prev,
        open: true,
        title: t("general.userFilterWarningTitle"),
        description: t("general.userFilterWarningDescription"),
        cancel: closeModal,
        confirm: () => {
          props.searchButton.onClick()
          resetData({ loadSuccess: true })
          closeModal()
          if (ref?.getToStep) {
            ref.getToStep(0)
          }
        }
      }))
    }
  }

  return <BaseModal open={props.open} sx={{ ...styles.modal, }}
    children={(
      <Grid container sx={{ height: '100%' }}>
        <IconButton onClick={() => { closeModalPermissions() }} sx={styles.close}>{icons('simple-close')}</IconButton>

        <Grid item sx={[styles.separator, styles.modalContent({ open: true })]} xl={4} lg={4} md={12} sm={12} xs={12}
        >
          <Box sx={[styles.centerTitle, styles.separatorHeader]}>
            <Typography sx={[styles.createTitle,]}>{t("Questionarie.modal.permisssion.title")}</Typography>

            <Typography sx={styles.description}>{t("Questionarie.modal.permisssion.subtitle")}</Typography>
          </Box>

          <Box sx={styles.contentRight}>
            <Box sx={styles.content}>
              {props.rigthItem}
            </Box>

            <Box sx={styles.footerBtn}>
              <Box />

              <LoadingButton loading={!!props.searchButton.loading} disabled={props.searchButton.disabled} onClick={() => { searchUsers() }} variant='contained'>{t("general.searchUsers")}</LoadingButton>
            </Box>
          </Box>

          <SimpleModal
            open={modal.open}
            close={() => { closeModal() }}
            removeClose={!modal?.cancel}
            title={modal.title}
            description={modal.description}
            buttonText={t('general.confirm')}
            cancelText={t('general.cancel')}
            back={modal?.cancel ?? undefined}
            next={modal.confirm ?? closeModal}
          />
        </Grid>

        <Grid item xl={8} lg={8} md={12} sm={12} xs={12} >
          <Box sx={[styles.separatorHeader, styles.centerTitle]}>
            <Typography sx={styles.createTitle}>{t('general.successSearch')}</Typography>

            <Typography sx={styles.description}>
              {t('general.wereFound')} <span>{userList?.length ?? 0}</span> {t('general.userInTheSearch')}
            </Typography>
          </Box>

          <Box sx={styles.permissionTable({loading: !!props.searchButton?.loading})}>
            {
              !!props.searchButton.loading ? <Loader />: (
                <>
                  {
                    !!userList?.length && (
                      <StepManagement
                        ref={element => {
                          setRef(element, 'StepManagement')
                          return refsByKey['StepManagement']
                        }}
                        customeBack={({ returnOneStep, disabled }) => (
                          <Box sx={[styles.goBack, styles.alignItems]}>
                            {!disabled && <IconButton onClick={() => { returnOneStep() }}>{icons('arrow-left-fill')}</IconButton>}

                            <Typography>{title}</Typography>
                          </Box>
                        )}
                        changeStep={stepChanged}
                        lastStep={{
                          label: t("general.addUsers"),
                          finish: finished,
                          last: 2
                        }}
                      >
                        {
                          ({ steps, handleDisabledStep }) => {
                            if (steps == 0) {
                              return (
                                <Step1
                                  ref={element => {
                                    setRef(element, 'step1')
                                    return refsByKey['step1']
                                  }}
                                  selects={data.step1.selects}
                                  handleDisabled={handleDisabledStep}
                                  row={userList ?? []}
                                />
                              )
                            }
                            return (
                              <Step2
                                ref={element => {
                                  setRef(element, 'step2')
                                  return refsByKey['step2']
                                }}
                                message={messageComponent}
                                handleDisabled={handleDisabledStep}
                                permissons={data.step2.permissons ?? {}}
                              />
                            )
                          }
                        }
                      </StepManagement>
                    )
                  }

                  {
                    !userList?.length && (
                      <Box sx={styles.notFound}>
                        <Typography className="notFoundData">{t('general.dataNotFound')}</Typography>
                      </Box>
                    )
                  }
                </>
              )
            }
          </Box>
        </Grid>
      </Grid>
    )}
  />
}

export { AddUsersModal }