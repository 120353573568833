import { Box, Grid, IconButton, Typography } from "@mui/material"
import BaseModal from "../../../../../../components/modal/baseModal"
import ListDetail from "../../../../../../components/listDetail"
import { useTranslation } from "react-i18next"
import { icons } from "../../../../../../utils/icons"
import { useContextState } from "../../../../../../hooks/theme"
import CustomAccordion from "../../../../../../components/accordion"
import { DetailKeyValue } from "../../../../../../components"
import { format } from "date-fns"
import { Constants } from "../../../../../../utils/constants"
import { useDetailModalStyles } from "./detailModalStyles"
import { DetailModalProps } from "./types"
import { DateWithActions } from "../../../../../../components/dateWithActions"
import { useState } from "react"
const sizes = {
    value: '13px',
    caption: '12px',
}
const DetailModal = (props: DetailModalProps) => {
    const { t } = useTranslation()
    const { colors } = useContextState()
    const styles = useDetailModalStyles()
    const [expand, setExpand] = useState<string | null>(null)
    const changeAccordion = ({ expand, id }: { expand: boolean; id: string }) => {
        setExpand(!!expand ? id : null)
    }
    return (
        <BaseModal open={props.open} sx={styles.modal}>
            <Grid container spacing={3}>
                <IconButton
                    sx={styles.close}
                    onClick={() => {
                        props.goBack({ doNotCleanTheFilters: true })
                    }}
                >
                    {icons('simple-close')}
                </IconButton>
                <Grid item xl={8} lg={8} md={8}>
                    <ListDetail
                        colors={colors}
                        notFoundMessage={`${t('general.ninformationNotFound')}`}
                        notCount
                        title={t('general.tags.storeInformation')}
                        list={props.storeList ?? []}
                        size={props.storeSize}
                    />

                    <Typography sx={styles.sectionTitle}>{t('general.tags.questionnairesInformation')} ({props.questionnaireList?.length})</Typography>

                    <Box sx={styles.containerAccordion}>
                        {
                            props.questionnaireList?.map((item) => {
                                const questionnaireUrl = props.questionnairesUrls?.[item.id]

                                const amountSectionIsNull = item?.amountSection == null
                                const amountQuestionIsNull = item?.amountQuestionAnswers == null
                                const reportUrlEsixst = !!questionnaireUrl?.reportUrl && item.reportStartDate
                                const lastReportUrlEsixst = !!questionnaireUrl?.latReportUrl && item.lastReportDate
                                return (
                                    <CustomAccordion
                                        defaultExpanded
                                        expanded={expand == item.id}
                                        onChange={(value) => {
                                            changeAccordion({ expand: value, id: item.id })
                                        }}
                                        key={item.id}
                                        title={(
                                            <Box sx={styles.titleAccordion}>
                                                <Box>
                                                    <Typography sx={styles.titleAccordion2}>{item?.title ?? ""}</Typography>

                                                    <Typography
                                                        sx={styles.date}
                                                    >
                                                        {
                                                            t('general.dateFromTo')
                                                                .replace('{start}', item.from)
                                                                .replace('{end}', item.to)
                                                        }
                                                    </Typography>
                                                </Box>

                                                {item?.status?.element ?? null}
                                            </Box>
                                        )}
                                        description={(
                                            <Grid container alignItems={"center"} spacing={1}>
                                                <Grid item xs={12}>
                                                    {
                                                        (!amountSectionIsNull && !amountQuestionIsNull) ? (
                                                            <Box sx={styles.containerDetails}>
                                                                {!amountSectionIsNull && <DetailKeyValue
                                                                    sxContainer={styles.details}
                                                                    align="center"
                                                                    caption={amountSectionIsNull ? t('general.oldSections') : t('general.sections')}
                                                                    value={`${item?.amountSection}/${item?.section}`}
                                                                />}

                                                                {!amountQuestionIsNull && <DetailKeyValue
                                                                    sxContainer={styles.details}
                                                                    align="center"
                                                                    caption={amountQuestionIsNull ? t('general.oldQuestions') : t('general.question')}
                                                                    value={`${item?.amountQuestionAnswers}/${item?.amountQuestion}`}
                                                                />}

                                                                <DetailKeyValue
                                                                    sxContainer={styles.details}
                                                                    align="center"
                                                                    caption={t('general.tags.result')}
                                                                    value={item.percentage}
                                                                />
                                                            </Box>

                                                        ) : (<Typography sx={styles.text2}>{t('general.tags.result')}: {item.percentage}</Typography>)
                                                    }
                                                </Grid>
                                                {
                                                    reportUrlEsixst && (
                                                        <Grid item xs={6.5}>
                                                            <DateWithActions
                                                                date={item.reportStartDate}
                                                                id={item.id}
                                                                title="Reporte"
                                                                url={questionnaireUrl?.reportUrl}
                                                                resendMail
                                                                visibilityProps={{
                                                                    onClick: (e) => {
                                                                        e.stopPropagation()
                                                                        props.optionMenu({
                                                                            item: {
                                                                                label: t('schedule.modal.displayQuestionnaireReport'),
                                                                                value: 3,
                                                                                questionnaireId: item.id
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                                resendProps={{
                                                                    onClick: () => {
                                                                        props.optionMenu({
                                                                            item: {
                                                                                label: "", value: 4,
                                                                                questionnaireId: item.id
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>
                                                    )
                                                }

                                                {
                                                    lastReportUrlEsixst && (
                                                        <Grid item xs={6.5}>
                                                            <DateWithActions
                                                                date={item.lastReportDate}
                                                                id={item.id}
                                                                title="Último reporte"
                                                                url={questionnaireUrl?.latReportUrl}
                                                                visibilityProps={{
                                                                    onClick: (e) => {
                                                                        e.stopPropagation()
                                                                        window.open(questionnaireUrl?.latReportUrl as String, 'blank')
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>
                                                    )
                                                }
                                            </Grid>
                                        )}
                                    />
                                )
                            })
                        }
                    </Box>
                </Grid>

                <Grid sx={styles.detail} item xl={4} lg={4} md={4} mt={1.8}>
                    <Grid container spacing={2}>
                        {/* Detalle de disponibilidad*/}
                        <Grid item xl={12} lg={12} md={12}>
                            <Typography sx={styles.subTitle}>{t('schedule.assigned.detailAvailability')}</Typography>
                        </Grid>

                        {!!props.reportUrl && (
                            <Grid sx={styles.icons} item xl={12} lg={12} md={12}>
                                <Box sx={styles.containerIconsHeader}>
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            props.optionMenu({ item: { label: "", value: 4 } })

                                        }}
                                    >
                                        {icons('send-email')}
                                        <Typography>{t('schedule.modal.resendQuestionnaireReport')}</Typography>
                                    </IconButton>

                                    <IconButton
                                        disabled={!props?.reportUrl}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            if (props.reportUrl) {
                                                window.open(props.reportUrl)
                                            }
                                        }}
                                    >
                                        {icons('visibility')}

                                        <Typography>{t('schedule.modal.displayQuestionnaireReport')}</Typography>
                                    </IconButton>
                                </Box>
                            </Grid>
                        )}

                        {
                            !!props.title && (
                                <Grid item xl={12} lg={12} md={12}>
                                    <DetailKeyValue value={props.title ?? 'N/A'} valueSize={sizes.value} captionSize={sizes.caption} caption={t('general.tags.title')} />
                                </Grid>
                            )
                        }

                        {
                            !!props.description && (
                                <Grid item xl={12} lg={12} md={12}>
                                    <DetailKeyValue value={props.description ?? 'N/A'} valueSize={sizes.value} captionSize={sizes.caption} caption={t('general.tags.description')} />
                                </Grid>
                            )
                        }

                        {/* usuarios asignados */}
                        <Grid item xl={12} lg={12} md={12}>
                            <Typography sx={styles.subTitle}>{t('general.assignedUsers')}</Typography>
                            {props.supervisor && props.supervisor.length > 0 ? (
                                <>
                                    <ul style={styles.ul}>
                                        {props.supervisor?.map((item: any, index: any) => (
                                            <li key={index}>
                                                <Typography sx={styles.ulNull}>
                                                    {item.firstName ? item.firstName : 'N/A'} {item.lastName ? item.lastName : ''}
                                                </Typography>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            ) : (
                                <Typography sx={styles.ulNull}>{'N/A'}</Typography>
                            )}

                            <Typography className="detail-caption" sx={styles.label}>
                                {t('general.assignedSupervisor') ?? 'N/A'}
                            </Typography>
                        </Grid>

                        {/* agente asignados */}
                        <Grid item xl={12} lg={12} md={12}>
                            <ul style={styles.ul}>
                                <li><Typography sx={styles.ulNull}>{props.agent ?? 'N/A'}</Typography></li>
                            </ul>

                            <Typography className="detail-caption" sx={styles.label}>
                                {t('general.assignedAgent')}
                            </Typography>
                        </Grid>

                        {/* Fecha de visita */}
                        <Grid item xl={12} lg={12} md={12}>
                            <Typography sx={styles.subTitle}>{t('general.scheduleDate')}</Typography>
                            <DetailKeyValue value={props.from ? format(props.from, Constants.dateFormatGeneral) : 'N/A'} valueSize={sizes.value} captionSize={sizes.caption} caption={t('general.startDate')} />
                        </Grid>
                        <Grid item xl={12} lg={12} md={12}>
                            <DetailKeyValue value={props.to ? format(props.to, Constants.dateFormatGeneral) : 'N/A'} valueSize={sizes.value} captionSize={sizes.caption} caption={t('general.endDate')} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </BaseModal>
    )
}

export { DetailModal }