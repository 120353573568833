import { useTheme } from "@mui/material"
import { useContextState } from "../../../../../../hooks/theme"

const general = {
    flex: {
        display: 'flex',
        flexWrap: "wrap",
        height: '100%',
        width: '100%',
    }
}

const useStyles = () => {
    const { colors } = useContextState()
    const { breakpoints } = useTheme()

    return {
        percentageStatus: ({ color }: { color: string }) => ({
            background: color,
            width: '10px',
            height: '10px',
            borderRadius: '50%',
        }),
        containerTitle: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
        },
        cake: {
            width: '100%',
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
        },
        cardCake: {
            boxShadow: `0 0 4px 0px ${colors.color13}`,
            width: '100%',
            minHeight: '19rem',
            padding: '10px 30px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            rowGap: '5px',
            position: 'relative',
        },
        customeGrid: {
            [breakpoints.down(1200)]: {
                width: 'calc(100% / 2)'
            },
            [breakpoints.down(1100)]: {
                width: '50%'
            },
            [breakpoints.down(900)]: {
                width: '100%'
            }
        },
        headersCake: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .MuiTypography-root': {
                fontWeight: '600',
            },
            '& .percentage': {
                color: colors.color16,
                fontSize: '30px',
            },
            '& .percentage-error': {
                color: colors.color19,
            },
            '& .storage': {
                color: colors.color13,
                fontSize: '16px',
            },
        },
        footerCake: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .MuiBox-root': {
                display: 'flex',
                alignItems: 'center',
                '&.active': {
                    '& span': {
                        fontSize: '25px',
                        color: colors.color16,
                    },
                },
                '&.inactive': {
                    '& span': {
                        fontSize: '25px',
                        color: colors.color13,
                    },
                },
            },
        },
        units: {
            width: '100%',
            '& .MuiAutocomplete-root': {
                marginRight: '20px'
            },
            // '& .show': {
            //     marginLeft: '20px'
            // },
            // '& .MuiGrid-item': {
            //     display: 'flex',
            //     flexWrap: 'wrap'
            // }
        },
        refreshCake: {
            position: 'absolute',
            top: '0',
            right: '10px',
            '& .MuiIconButton-root': {
                width: '20px',
                height: '20px',
                fontSize: '1rem',
                '& .MuiSvgIcon-root': {
                    fontSize: '1em',
                },
            },
        },
        containerInputs: {
            display: 'flex',
            flexWrap: "wrap",
            height: '100%',
            alignItems: 'center',
            gap: '10px'
        },
        headerIndicator: {
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '10px'
        },
        maxField: {
            width: '415px',
            marginTop: '0px',
            [breakpoints.down(1430)]: {
                marginTop: '10px',
                width: '100%'
            },
            [breakpoints.down(1200)]: {
                marginTop: '0px',
                width: '415px'
            },
            [breakpoints.down(1040)]: {
                marginTop: '10px',
                width: '100%'
            },
            '& .MuiGrid-container': {
                justifyContent: 'end',
                '& .MuiOutlinedInput-input': {
                    fontSize: '.8rem'
                }
            }
        },
        dateContainer: {
            wisth: '100%',
            display: 'flex',
            justifyContent: 'end'
        },
        containerGraphCard: {
            ...general.flex,
            rowGap: '20px'
        },
        containerGraph: {
            ...general.flex,
            flexDirection: 'column',
            gap: '20px',
            paddingBottom: '20px',
            [breakpoints.between(1350, 1460)]: {
                padding: '0 0px 30px 0',
            },
            borderRadius: '12px',
            boxShadow: `0 0 4px 0px ${colors.color13}`,
        },
        noData: {
            width: '100%',
            textAlign: 'center',
            fontSize: '18px',
            color: `${colors.color13}`,
        },
        graph: {
            width: '100%',
            [breakpoints.up('lg')]: {
                flex: 1,
            },
            [breakpoints.down('lg')]: {
                height: '400px'
            },
            '& .recharts-brush-texts': {
                '& .recharts-text': {
                    fontSize: '12px',
                },
            }
        },
        body: {
        },
        intervale: {
            width: '260px'
        },
        detailGraph: {
            width: '100%',
            padding: '20px'
        },
        titleIndicator: {
            fontSize: '1.6rem',
            lineHeight: '1rem',
            color: colors.color9,
            fontWeight: '500',
            [breakpoints.between(0, 1580)]: {
                fontSize: '1.2rem',
            },
        }
    }
}

export { useStyles }