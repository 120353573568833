export const useStyles = () => {
  return {
    iconFilter: {
      position: 'absolute',
      right: '0',
      background: '#ec6666',
      borderRadius: '0',
      borderTopLeftRadius: '6px',
      borderBottomLeftRadius: '6px',
      '& button': { color: 'white' },
      '&:hover': {
        backgroundColor: '#ec666699',
      },
      '& .MuiBadge-badge': {
        top: 0,
        right: '40px',
        background: 'black',
      },
    },
    drawer: {
      width: '200px',
    },
    cleanFilters: {
      fontSize: '14px',
      cursor: 'pointer',
      color: '#158bc8',
    },
    actions: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }
}
