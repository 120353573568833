import { faFileCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Tooltip, Typography } from "@mui/material"
import { Roles } from "../../../../../../../../utils/enums"
import { useNumberOfFoliosStyles } from "./numberOfFoliosStyles"

const NumberOfFolio = (props: any) => {
  const styles = useNumberOfFoliosStyles()
  return (
    <Box sx={styles.containerStatus}>
      <Typography sx={{ ...styles.textItem, textAlign: 'center' }}>
        {!props?.current && props.role != Roles.ADMIN ? (
          <Tooltip title={'Solicitado'}>
            <FontAwesomeIcon
              style={{ fontSize: '16px' }}
              icon={faFileCircleExclamation}
            />
          </Tooltip>
        ) : null}
      </Typography>
    </Box>
  )
}

export { NumberOfFolio }
