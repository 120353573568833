const useStyles = () => {
  return {
    containrMultiselect: {
      display: "flex",
      flexDirection: "column",
      minWidth: '200px',
      width: '100%'
    },
    chip: {
      display: 'none',
      width: '90%',
      height: 'fit-content',
      wordBreak: 'break-all',
      background: '#00000011',
      '& .MuiChip-label': {
        textOverflow: 'clip',
        whiteSpace: 'break-spaces',
      },
    },
    chipUnFocus: {
      background: '#31c462',
      color: 'white',

      '&.MuiChip-clickable:hover': {
        backgroundColor: '#31c462',
      },
    },
    input: {
      '& .MuiOutlinedInput-root': {
        height: '53px',
        display: 'flex',
        flexDirection: 'column',
      },
      '& .MuiAutocomplete-tag': {
        maxWidth: 'calc(100% - 22px)',
        display: 'none',
      },
    },
  }
}

export { useStyles }
