import { TFunction } from 'react-i18next'
import { Status } from '../../utils/enums'
import { Color } from '../../utils/theme/types/color'

export const getStatus = (status: Status, color: Color, t: TFunction<'translation', undefined>, female?: boolean) => {
  let returnStatus: {
    color: string
    text: string
    value?: number
  } = {
    color: color.color8,
    text: t(`general.${female ? 'femaleStatus' : 'status'}.active`),
  }

  const statusLocale = typeof status == 'string' ? parseInt(status) : status
  
  switch (statusLocale) {
    case Status.ACTIVE:
      returnStatus = {
        color: color.color8,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.active`),
      }
      break
    case Status.INACTIVE:
      returnStatus = {
        color: color.color9,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.inactive`),
      }
      break
    case Status.PAUSE:
      returnStatus = {
        color: color.color10,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.pause`),
      }
      break
    case Status.CLOSE:
      returnStatus = {
        color: color.color13,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.close`),
      }
      break
    case Status.CANCEL:
      returnStatus = {
        color: color.color13,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.cancel`),
      }
      break
    case Status.REAGENDED:
      returnStatus = {
        color: color.color13,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.reagended`),
      }
      break
    case Status.REASSIGNED:
      returnStatus = {
        color: color.color14,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.reassigned`),
      }
      break
    case Status.FINISHED:
      returnStatus = {
        color: color.color8,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.finished`),
      }
      break
    case Status.TO_BE_STARTED:
      returnStatus = {
        color: color.color16,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.toBeStatarted`),
      }
      break
    case Status.NOT_FINISHED:
      returnStatus = {
        color: color.color19,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.notFinished`),
      }
      break
    case Status.WITH_DRAW:
      returnStatus = {
        color: color.color13,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.withDraw`),
      }
      break
    case Status.WAITING:
      returnStatus = {
        color: color.color13,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.waiting`),
      }
      break
    case Status.REJECTED:
      returnStatus = {
        color: color.color19,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.reject`),
      }
      break
    case Status.BLOCKED:
      returnStatus = {
        color: color.color19,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.blocked`),
      }
      break
    case Status.CREATED:
      returnStatus = {
        color: color.color8,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.created`),
      }
      break
    case Status.UPDATE:
      returnStatus = {
        color: color.color8,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.update`),
      }
      break
    case Status.STARTED:
      returnStatus = {
        color: color.color8,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.started`),
      }
      break
    case Status.RESPONDED:
      returnStatus = {
        color: color.color16,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.responded`),
      }
      break
    case Status.APPROVED:
      returnStatus = {
        color: color.color8,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.aproved`),
      }
      break
    case Status.AWAIT_SOLUTION:
      returnStatus = {
        color: color.color13,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.await_solution`),
      }
      break
    case Status.EXPIRED:
      returnStatus = {
        color: color.color19,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.expired`),
      }
      break
    case Status.INCOMPLETE:
      returnStatus = {
        color: color.color13,
        text: t(`general.${female ? 'femaleStatus' : 'status'}.incomplete`),
      }
      break
  }
  return {
    ...returnStatus,
    value: status,
  }
}
