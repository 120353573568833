import { useTheme } from '@mui/material'
import { useContextState } from '../../../hooks/theme'

export const useStyles = (open: boolean, selector: boolean, hidden: boolean ) => {
  const { breakpoints } = useTheme()
  const { colors: color } = useContextState()
  return ({
    container: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      width: '100%',
    },
    table: {
      flex: open && hidden ? 0.7 : 1,
      [breakpoints.between(920, 1100)]: {
        flex: open && hidden ? 0.6 : 1
      },
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '50px',
      paddingRight: !hidden ? 0 : '50px',
      width: '100%',
      transition: '.3s',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    userDetail: {
      flex: open ? 0.3 : 0,
      [breakpoints.between(920, 1100)]: {
        flex: open ? 0.4 : 0
      },
      // marginLeft: open ? '40px' : 0,
      height: '100%',
      overflowY: 'auto',
      boxShadow: !selector ? `0 0 2px ${color.color10}` : '',
      overflowX: 'hidden',
      position: 'relative',
      width: open ? 0 : -300,
      transition: '.3s',
    },
    menuIcons: {
      marginLeft: '5px',
      fontSize: '18px',
      '& .MuiSvgIcon-root': {
        fontSize: '18px',
      },
    },
    loader: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
}
