import { SVGProps } from 'react'
const Check = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21.438" height="21.438" viewBox="0 0 21.438 21.438" {...props}>
    <path
      id="Icon_awesome-check-circle"
      data-name="Icon awesome-check-circle"
      d="M22,11.281A10.719,10.719,0,1,1,11.281.563,10.719,10.719,0,0,1,22,11.281ZM10.041,16.957,17.994,9a.692.692,0,0,0,0-.978l-.978-.978a.692.692,0,0,0-.978,0L9.552,13.534,6.524,10.506a.692.692,0,0,0-.978,0l-.978.978a.692.692,0,0,0,0,.978l4.495,4.495a.692.692,0,0,0,.978,0Z"
      transform="translate(-0.563 -0.563)"
      fill="#3a87d9"
    />
  </svg>
)
export default Check
