import { Color } from '../../../utils/theme/types/color'

export const useStyles = (color: Color) => {
  return {
    title: {
      fontSize: '20px',
      fontWeight: '500',
      marginBottom: '20px',
      width: '100%',
    },
  }
}
