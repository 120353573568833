import { Box, Drawer, Grid, IconButton } from "@mui/material"
import { ReportFolio } from "../../../../components/repotFolios"
import { ReportFolioByAnswerProps } from "./typee"
import { useReportFolioByAnswer } from "./reportFolioByAnswerStyles"
import { DynamicRefRef } from "../../../../../../components/dynamicFormRef/types"
import { useRefs } from "../../../../../../hooks/useReactHookModified"
import { useEvidencesType } from "../../../../../../hooks/evidences/useEvidences"
import { icons } from "../../../../../../utils/icons"
import { useEffect } from "react"

const ReportFolioByAnswer = (props: ReportFolioByAnswerProps) => {
    const styles = useReportFolioByAnswer()
    const { refsByKey, setRef } = useRefs<DynamicRefRef>()
    const evidencesFolios = useEvidencesType({ defaultValues: props.data?.evidencesData })
    
    useEffect(() => {
        refsByKey['folioLimit']?.validate()
        refsByKey['folioReject']?.validate()
    }, [refsByKey['folioLimit'], refsByKey['folioReject']])
    return (

        <Drawer open={!!props.id} anchor="right">
            <Box sx={styles.containerReportFolio}>

                <IconButton
                    sx={styles.closeSettings}
                    onClick={() => {
                        const body = { ...refsByKey['folioLimit']?.getValues(), ...refsByKey['folioReject']?.getValues() } as Record<string, string | number | boolean>

                        props.arrowBack({
                            evidence: evidencesFolios,
                            body,
                        })
                        evidencesFolios.cleanAll()
                    }}
                >
                    {icons('arrow-left-fill')}
                </IconButton>
                <ReportFolio
                    evidencesCount={evidencesFolios.evidencesCount}
                    limitFoliosProps={{
                        ref: (element) => {
                            setRef(element, 'folioLimit')
                            return refsByKey['folioLimit']
                        },
                        removeForm: true,
                        styleSx: { width: '100%' },
                        defaultValues: props.default?.limitFolios,
                        parentWrapper: ({ children }) => <Grid spacing={2} alignItems='center' container children={children} />,
                    }}
                    rejectsFolioProps={{
                        ref: element => {
                            setRef(element, 'folioReject')
                            return refsByKey['folioReject']
                        },
                        removeForm: true,
                        defaultValues: props.default?.rejectsFolio,
                        parentWrapper: ({ children }) => <Grid spacing={2} alignItems='center' container children={children} />,
                    }}
                    openMenu={({anchor}) => {
                        evidencesFolios.openMenu({ anchor: anchor, defaultValues: props.data?.evidencesData })
                    }}
                    removeFolios={props.cancel}
                    baseMenuProps={{
                        anchorEl: evidencesFolios.anchorEl,
                        setAnchorEl: () => { evidencesFolios.closeMenu() },
                        menuItems: evidencesFolios.menu,
                        click: (item) => { evidencesFolios.clickOption({ item }) },
                    }}
                    drawerEvidencesProps={{
                        refsByKey: evidencesFolios.refsByKey,
                        defaultValues: evidencesFolios.evidencesData,
                        open: evidencesFolios.openEvidenceDrawer,
                        setRef: evidencesFolios.setRef,
                        save: evidencesFolios.saveEvidence,
                        cancel: evidencesFolios.cancelEvidence,
                    }}
                />
            </Box>
        </Drawer>
    )
}

export { ReportFolioByAnswer }