import { Box, IconButton } from '@mui/material'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { icons } from '../../../utils/icons'
import { DinamicFormProps, ObjectGeneric } from '../../../utils/types/generals'
import { Images } from '../../image'
import BaseInput from '../baseInput'
import { useRef } from 'react'
import { useStyles } from './styles'

const InputFile = ({ item, values, errors, onChange }: DinamicFormProps & { item: ObjectGeneric }) => {
  const styles = useStyles()
  const { t } = useTranslation()
  let file = useRef<HTMLInputElement | null>(null)
  const images = values?.value?.value?.name ? URL.createObjectURL(values?.value?.value) : values?.value?.value

  const handleChange = (e: any) => {
    const { target } = e

    if (values?.value?.value?.name && target?.value?.value) {
      const newName = target?.value?.name ? `${target.value.name}.${target?.value?.value?.type?.replace(/(.*)\//g, '')}` : target?.value?.value.name
      onChange({
        target: {
          ...target,
          value: {
            ...target.value,
            value: new File([target?.value?.value], newName, {
              type: target?.value?.value.type,
              lastModified: target?.value?.value.lastModified,
            }),
          },
        },
      })
    } else {
      onChange(e)
    }
    if (file?.current) file.current.value = ''
  }
  return (
    <Box>
      <Box /* className={classes.containerInputImage} */>
        <Box /* className={handleFileStyle(detailImages?.url_image && !errors.url_image)} */>
          <BaseInput
            pushError={item.pushError}
            name={values?.name ?? ''}
            value={{
              [values?.name ?? '']: {
                ...values,
                value: values?.value?.name ?? '',
                label: `${values?.translate ? t(values?.label) : values?.label} ${!!values?.validate?.required ? '*' : ''}`,
              },
            }}
            onChange={(e) => {
              handleChange({
                target: {
                  name: e.target.name,
                  value: item?.rest?.disabled
                    ? e.target.value
                    : {
                        value: values?.value?.value,
                        name: e.target.value,
                      },
                },
              })
            }}
            errors={errors}
            {...item.rest}
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={styles.icon}
                  onClick={() => {
                    if (!item?.rest?.disabled && file?.current) {
                      file.current.click()
                    }
                  }}
                >
                  {images ? <Images src={images} sx={styles.image} /> : icons('folder')}
                </IconButton>
              ),
            }}
          />
        </Box>
      </Box>

      <input
        name={values?.name ?? ''}
        type="file"
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          e?.target?.files?.[0] &&
          handleChange({
            target: {
              name: e.target.name,
              value: item?.rest?.disabled
                ? e.target.files[0]
                : {
                    value: e.target.files[0],
                    name: values?.value?.name ?? '',
                  },
            },
          })
        }
        hidden
        ref={file}
        accept={item?.rest?.accept ?? ".png"}
      />
    </Box>
  )
}

export default InputFile
