import { GetAllAreas } from '../../service/area/response'
import { UserListResponse } from '../../service/user/response'
import {
  areaServices,
  companiesServiceFetch,
  departmentService,
  divisionService,
  questionnaire,
  rolesServices,
  storeServices,
  userServiceFetch,
  userTypeServiceFetch,
  zoneService,
} from '../constants/callService'
import { Roles } from '../enums'
import { Item } from '../types/generals'

const getStore = async () => {
  const allStores = await storeServices.getAllStores()
  if (!!allStores?.errors) return { items: [], all: [] }

  return {
    items:
      allStores instanceof Array
        ? (allStores?.map((item) => ({
          label: `${item.keyCode} - ${item.name}`,
          value: item.id,
          keyCode: item.keyCode,
        })) as Item[])
        : [],
    all: allStores,
  }
}

const getArea = async (payload?: { filters: (item: GetAllAreas) => GetAllAreas }) => {
  let allAreas = await areaServices.getAllAreas()

  if (!!allAreas?.errors) return []
  if (payload?.filters) {
    allAreas = payload.filters(allAreas)
  }
  return allAreas.map((item) => ({
    id: item.id?.toString(),
    label: item.name,
    value: item.id,
  })) as Item[]
}

const getDivisions = async () => {
  const allDivisions = await divisionService.getAllDivision()

  if (!!allDivisions?.errors) return { items: [], all: [] }

  return {
    items: allDivisions.map((item) => ({
      label: item?.description ?? item.name,
      value: item.id,
    })) as Item[],
    all: allDivisions,
  }
}

const getSupervisorDivisions = async () => {
  const allDivisions = await divisionService.getAllSupervisorDivision()

  if (!!allDivisions?.errors) return { items: [], all: [] }

  return {
    items: allDivisions.map((item) => ({
      label: item.name,
      value: item.id,
    })) as Item[],
    all: allDivisions,
  }
}

const getCompanies = async () => {
  const allCompanies = await companiesServiceFetch.getAllCompanies()

  if (!!allCompanies?.errors) return []

  return allCompanies?.map((item) => ({
    label: item.name,
    value: item.id,
  })) as Item[]
}

const getDepartments = async () => {
  const allDepartments = await departmentService.getAllDepartments()

  if (!!allDepartments?.errors) return []

  return allDepartments.map((item) => ({
    id: item.id,
    label: item.name,
    value: item.id,
    areaId: item.areaId,
  })) as Item[]
}

const getUserJerarquia = async (query: { query: string }) => {
  const allUserJerarquia = await departmentService.getCountUserJerarquia(query)

  if (!!allUserJerarquia?.errors) return -1

  return allUserJerarquia?.amountUsers
}

const getRoles = async (data?: { skip?: Roles[] }) => {
  const roles = await rolesServices.rolseList()
  if (!!roles?.errors) return { items: [], all: [] }
  return {
    items: roles?.roles
      ?.filter((item) => !data?.skip?.includes(item.id))
      ?.map((item) => ({
        label: item.description,
        value: item.id,
      })) as Item[],
    all: roles?.roles,
  }
}

const getUsers = async (data?: { skip?: Roles[] }) => {
  const allUsers = await userServiceFetch.usersByStatus()

  if (!!allUsers?.errors) return { items: [], all: [] }

  let userWithRolesRemoved: UserListResponse | null = allUsers

  if (!!data?.skip?.length && !!userWithRolesRemoved) {
    if (userWithRolesRemoved?.items?.length) {
      userWithRolesRemoved = {
        ...userWithRolesRemoved,
        items: userWithRolesRemoved?.items?.filter(
          (item) => !data?.skip?.some((itemSkip) => itemSkip == item.roleId)
        ),
      }
    }

    if (userWithRolesRemoved?.users?.length) {
      userWithRolesRemoved = {
        ...userWithRolesRemoved,
        users: userWithRolesRemoved?.users?.filter(
          (item) => !data?.skip?.some((itemSkip) => itemSkip == item.roleId)
        ),
      }
    }

    if (userWithRolesRemoved instanceof Array) {
      userWithRolesRemoved = allUsers?.filter(
        (item) => !data?.skip?.some((itemSkip) => itemSkip == item.roleId)
      ) as UserListResponse
    }
  }

  return {
    items: userWithRolesRemoved?.map((item) => ({
      label: `${item.firstName} ${item.lastName} - ${item.curp}`,
      value: item.id,
    })) as Item[],
    all: userWithRolesRemoved,
  }
}

const getUsersOfTheSupervisor = async (data?: {
  skip?: Roles[]
  id?: number
}) => {
  if (data?.id) {
    const allUsers = await userServiceFetch.getAsociateUserAgentsBySupervisor(
      data?.id
    )

    if (!!allUsers?.errors) return { items: [], all: [] }

    return {
      items: allUsers?.map((item) => ({
        label: `${item?.firstName ?? ''} ${item?.lastName ?? ''} - ${item?.curp ?? ''
          }`,
        value: item.id,
      })) as Item[],
      all: allUsers,
    }
  }

  return { items: [], all: [] }
}

const getUserTypes = async () => {
  const allUsersType = await userTypeServiceFetch.userTypeList()

  if (!!allUsersType?.errors) return { items: [], all: [] }

  return {
    items: allUsersType?.map((item: any) => ({
      label: item.name,
      value: item.id,
    })) as Item[],
    all: allUsersType,
  }
}
const getUserTypesSupervisor = async () => {
  const allUsersType = await userTypeServiceFetch.userTypeSupervisorList()
  if (!!allUsersType?.errors) return { items: [], divisions: [], all: [] }

  return {
    items: allUsersType?.userTypes?.map((item) => ({
      label: item.name,
      value: item.id,
    })) as Item[] ?? [],
    divisions: allUsersType?.divisions?.map((item) => ({
      label: item.description,
      value: item.id,
    })) as Item[] ?? [],
    all: allUsersType,
  }
}

const getQuestionnaires = async () => {
  const questionaries = await questionnaire.getAllQuestionaries()
  if (!!questionaries?.errors) return { items: [], all: [] }

  return {
    items: questionaries?.map((item) => ({
      label: item.name,
      value: item.id,
      id: item.id.toString(),
    })) as Item[],
    all: questionaries,
  }
}

const getZones = async () => {
  const responseZones = await zoneService.getAllZones()
  let fatherZones: any[] = []
  let childZones: any[] = []

  responseZones?.forEach((e: any) => {
    if (!e.zoneInitId)
      fatherZones.push({
        label: e.name,
        value: e.id,
      })
    else
      childZones.push({
        label: e.name,
        value: e.id,
        father: e.zoneInitId,
      })
  })

  return { fatherZones, childZones, all: responseZones }
}

export {
  getStore,
  getArea,
  getDepartments,
  getUsers,
  getUserTypes,
  getDivisions,
  getQuestionnaires,
  getZones,
  getRoles,
  getCompanies,
  getUsersOfTheSupervisor,
  getSupervisorDivisions,
  getUserTypesSupervisor,
  getUserJerarquia,
}
