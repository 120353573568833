export const useStyles = () => ({
  container: ({autoHeight}:{autoHeight:boolean}) => ({
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
    position: 'relative',
    height: !autoHeight ? '20px' : 'auto',
  }),
  Abutton: {
    position: 'absolute',
    right: 0,
    width: { lg: 'fit-content', md: '100%', sm: '100%' },
  },
  customeBack: {
    fontSize: '14px'
  },
  customeSend: {
    padding: '5px 20px',
    width: { lg: 'fit-content', md: '100%', sm: '100%' },
    fontWeight: '500',
    fontSize: '16px'
  },
  Cbutton: {},
})
