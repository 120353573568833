import { useState } from 'react'
import { Box } from '@mui/material'
import { useContextState } from '../../../hooks/theme'
import AuthCard from '../components/authCard'
import img from '../../../assets/images/img1.png'
import AuthForm from '../components/authForm'
import { Images } from '../../../components/image'
import { Value } from '../types'
import { validate } from '../utils/validate'
import { useNavigate } from 'react-router-dom'
import { onChangeEvent } from '../../../utils/types/generals'
import { useStyles } from '../utils/styles'
import { useInitialState } from './initialStates'
import { Auth } from '../../../service/auth'
import { httpFetch, setLocalStorage } from '../../../utils/crud'
import { images } from '../../../utils/images'
import { SnackSimple } from '../../../components'
import { RequestErrors, Roles, Types } from '../../../utils/enums'
import { handleAllErrors } from '../../../utils/methods/handleErrors'

export const LogIn = () => {
  const navigate = useNavigate()
  const { colors } = useContextState()
  const [isLoading, setIsLoading] = useState(false)
  const styles = useStyles(colors)
  const { value, form, t } = useInitialState()
  const [login, setLogin] = useState<Value>(value)
  const [openSnackbar, setOpenSnackbar] = useState({
    message: '',
    open: false,
  })
  const [errors, setErrors] = useState({})
  const isValid = () =>
    Object.values(errors).length > 0 ||
    !login?.email?.value ||
    !login?.password?.value
  async function sign() {
    setIsLoading(true)
    try {
      const authFetch = await Auth(httpFetch).singIn({
        username: login.email.value,
        password: login?.password?.value,
      })
      let role = authFetch.roleId
      // role = Roles.AGENT
      if (!authFetch.errors) {
        if ([Roles.SUPERVISOR, Roles.ADMIN, Roles.AGENT, Roles.STORE, Roles.GERENT, Roles.SUPPORT, Roles.AUDIT, Roles.PARTNER].includes(authFetch?.roleId)) {
          setLocalStorage({
            body: {
              in: true,
              token: authFetch.token,
              role: role,
              id: authFetch?.id,
              picture: authFetch.picture,
              curp: authFetch.curp ?? '',
              email: authFetch.email,
              phone: authFetch.phone,
              userType: authFetch.userType,
              fullName: `${authFetch.firstName ?? ''} ${authFetch.lastName ?? ''}`,
              firstName: authFetch.firstName,
              lastName: authFetch.lastName
            },
            name: 'state',
          })

          navigate('/')
        } else {
          setOpenSnackbar({
            message: t('general.messages.errors.userNotHavePermissionToLogIn'),
            open: true,
          })
        }
      } else {
        const code = authFetch?.errors?.[0]?.code
        if (code === RequestErrors.REQUIRE_VALIDATE) {
          setOpenSnackbar({
            message: t('general.messages.errors.REQUIRE_VALIDATE'),
            open: true,
          })
          setTimeout(() => {
            navigate('/auth/confirm-user', {
              state: {
                tmp: login.password.value,
              },
            })
          }, 5000)
        } else if (code == RequestErrors.REQUIRE_CHANGE_PASS) {
          setOpenSnackbar({
            message: t('general.messages.errors.REQUIRE_CHANGE_PASS'),
            open: true,
          })
          setTimeout(() => {
            navigate('/auth/reset-password', {
              state: {
                tmp: login.password.value,
                name: login.email.value
              },
            })
          }, 5000)
        } else {
          setErrors({
            password: handleAllErrors(
              code,
              t,
              'general.messages.errors.logError'
            ),
          })
        }
      }
    } catch (error) {
      console.error('error signing up:', error)
      setErrors({ password: t('general.messages.errors.logError') })
    } finally {
      setInterval(() => {
        setIsLoading(false)
      }, 5000)
    }
  }
  const handleChange = ({ target }: onChangeEvent) => {
    setIsLoading(false)
    const { name, value } = target

    const body = {
      ...login,
      [name]: {
        ...login[name],
        value,
      },
    }

    setErrors(validate(body, t))
    setLogin(body)
  }

  return (
    <Box sx={styles.container}>
      <SnackSimple
        time={5000}
        close={() => {
          setOpenSnackbar({
            ...openSnackbar,
            open: false,
          })
        }}
        open={openSnackbar.open}
        type={Types.ERROR}
        message={openSnackbar.message}
      />
      <AuthCard
        children={<Images src={img} />}
        secondChildren={
          <AuthForm
            errors={errors}
            logo={images.interception}
            title={form.title}
            description={form.description}
            button={form.button}
            value={login}
            onChange={handleChange}
            submit={sign}
            disabled={isValid()}
            isLoading={isLoading}
          />
        }
        footer={{
          label: form.footer,
          navigate: () => {
            navigate('/auth/send-code')
          },
        }}
      />
    </Box>
  )
}
