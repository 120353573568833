import { differenceInDays, format, getWeek, getYear } from 'date-fns'
import { TFunction } from 'react-i18next'
import { getStatus } from '../../../../hooks/status'
import { actionPlansServices } from '../../../../service/actionPlans'
import { Constants } from '../../../../utils/constants'
import { httpFetch } from '../../../../utils/crud'
import { Color } from '../../../../utils/theme/types/color'
import { es } from 'date-fns/locale'
import { Status } from '../../../../utils/enums'

export const getAll = async (t: TFunction<'translation', undefined>, colors: Color, body?: { queries?: string; reloadData?: boolean }) => {
  const data = await actionPlansServices(httpFetch).getAll(body)

  const row = !data.errors
    ? data?.items?.map((item) => {
        const store = item?.store
        const action_plan = item?.actionPlan
        const generatedByOption = action_plan?.generatedByOption
        const create_time = action_plan?.startDate
        const finishDate = action_plan?.finishDate
        const questionnaire = item?.assignedQuestionnaire?.questionnaireMain
        const user = item?.userAssign
        const division = user?.division
        const zoneFather = store?.storeZone
        const zoneChild = store?.storeClass
        const formatCreate = create_time ? format(new Date(create_time), Constants.dateFormatGeneral) : t('general.noData')
        const formatFinish = finishDate ? format(new Date(finishDate), Constants.dateFormatGeneral) : t('general.noData')
        const differenceDays = create_time && finishDate ? differenceInDays(new Date(create_time), new Date(finishDate)) : null

        const month = format(new Date(action_plan.startDate), 'LLLL', { locale: es })

        return {
          id: action_plan?.id,
          divisions: division?.description,
          user: `${user?.firstName ?? ''} ${user?.lastName ?? ''}`,
          stores: store?.name,
          cve: user?.division?.cveDivision ? user?.division?.cveDivision : 'N/A',
          questionnaire: questionnaire?.name,
          zones: zoneFather?.name,
          item: generatedByOption,
          isItem: item.isActionPlanByItem ? t('general.yes') : t('general.no'),
          isByItem: !!item.isActionPlanByItem,
          dateRequestion: {
            label: formatCreate,
            value: create_time ? new Date(create_time).getTime() : 0,
            type: 'date',
          },
          deadline: {
            label: formatFinish,
            value: finishDate ? new Date(finishDate).getTime() : 0,
            type: 'date',
          },
          reportUrl: action_plan?.reportUrl,
          backlog: differenceDays !== null && differenceDays >= 0 ? differenceDays : t('general.noBacklog'),
          status: getStatus(item?.status?.id, colors, t),
          statusActionPlan: action_plan?.status?.description,
          section: generatedByOption?.question?.section?.title,
          question: generatedByOption?.question?.title,
          requestDate: action_plan?.create_time ? format(new Date(action_plan?.create_time), Constants.dateFormat) : null,
          requestHour: action_plan?.create_time ? format(new Date(action_plan?.create_time), Constants.hourFormat) : null,
          soloveDate: action_plan?.update_time && action_plan?.status?.id === Status.APPROVED ? format(new Date(action_plan?.update_time), Constants.dateFormat) : null,
          soloveHour: action_plan?.update_time && action_plan?.status?.id === Status.APPROVED ? format(new Date(action_plan?.update_time), Constants.hourFormat) : null,
          deadlineDate: action_plan?.finishDate ? format(new Date(action_plan?.finishDate), Constants.dateFormat) : null,
          deadlineHour: action_plan?.finishDate ? format(new Date(action_plan?.finishDate), Constants.hourFormat) : null,
          elapsedDays: action_plan?.elapsedDays,
          elapsedHour: action_plan?.elapsedHours,
          rejects: `${action_plan?.actionPlanAnswers?.filter((itemActionPlanAnswer) => itemActionPlanAnswer?.statusId === Status?.REJECTED)?.length ?? 0} / ${action_plan?.maxRejections}`,
          weekYear: 'Semana ' + getWeek(new Date(action_plan.startDate)),
          year: getYear(new Date(action_plan.startDate)),
          month: month ? month.charAt(0).toUpperCase() + month.slice(1) : 'N/A',
          idStore: store?.keyCode,
          country: 'México',
          state: store?.zipCode?.state,
          city: store?.zipCode?.country,
          // division: store?.storesDivision?.[0]?.division?.description,
          class: zoneChild?.name,
          role: user?.role?.description,
          userType: user?.userType?.name,
          curp: user?.curp,
        }
      })
    : []
  return {
    row,
    totalPages: data.totalPages,
    totalItems: data.totalItems,
    currentPage: data.currentPage,
  }
}
