import { Box, Button, Checkbox, FilledInput, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, Paper, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useContextState } from '../../../../../../hooks/theme'
import { ObjectGeneric, onChange, onChangeEvent } from '../../../../../../utils/types/generals'
import Form from '../../components/form'
import { initialData, initialValues } from '../main/initialState'
import { useStyles } from './styles'
import { Search } from '@mui/icons-material'
import { areaServices, reportFolioServices } from '../../../dashboard/pages/main/constants/states'
import { Types } from '../../../../../../utils/enums'
import { SnackSimple } from '../../../../../../components'
import SimpleModal from '../../../../../../components/modal/simpleModal'
import { icons } from '../../../../../../utils/icons'

export const NewConfigFolio = () => {
  const { id } = useParams()
  const { state } = useLocation()
  const { t } = useTranslation()
  const { colors } = useContextState()
  const styles = useStyles(colors)
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState({
    open: false,
    title: '',
    description: '',
  })
  const [canSubmit, setCanSubmit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<ObjectGeneric>({})
  const [inputValues, setInputValues] = useState(initialValues)
  const [arrayAreas, setArrayAreas] = useState<ObjectGeneric[]>([])
  const [arrayAreasCopy, setArrayAreasCopy] = useState<ObjectGeneric[]>([])
  const [arrayDepartamentos, setArrayDepartamentos] = useState<ObjectGeneric[]>([])
  const [arrayDepartamentosCopy, setArrayDepartamentosCopy] = useState<ObjectGeneric[]>([])
  const handleCloseSnackbar = (value: boolean) => setOpenSnackbar({ ...openSnackbar, open: value })
  const [searchString, setSearchString] = useState<string | undefined>()
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [evidences, setEvidences] = useState<any>()
  const [returnModal, setReturnModal] = useState({
    open: false,
    message: '',
    description: '',
  })

  const [aresWithOutDepartments, setAreasWithOutDepartments] = useState<Record<string, boolean>>({})
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
    type: Types.SUCCESS as string,
  })
  const openReturnModal = () => {
    setReturnModal((prev) => ({
      ...prev,
      open: true,
      message: t('reportFolio.modal.return'),
      description: t('reportFolio.modal.descriptionReturn'),
    }))
  }

  const closeReturnModal = () => {
    setReturnModal((prev) => ({
      ...prev,
      open: false,
    }))
  }

  const confirmReturn = () => {
    navigate('/dashboard/report-folio/settings')
  }

  useEffect(() => {
    if (state) {
      const { evidences, inputValues, data } = state
      setData(initialData)
      setEvidences(evidences)
      setInputValues(inputValues)
      setData(() => ({
        ...data,
        limitFoliosAmount: data.limitFolio,
        maxRejectionsAmount: data.maxRejections,
      }))
    }
  }, [state])

  useEffect(() => {
    let canSubmit = false

    arrayDepartamentos.forEach((e) => {
      if (e.checked) {
        canSubmit = true
      }
    })

    setCanSubmit(canSubmit)
  }, [arrayAreas, arrayDepartamentos])

  const crudChange = async (e: onChangeEvent | string, extra: any): Promise<void> => {
    if (typeof e !== 'string') {
      const { name, value } = e?.target

      let body = {
        ...data,
        [name]: value?.value ?? value,
      }

      setData((prev) => ({ ...prev, ...body }))

      if (name === 'limitFolio') {
        if (value === true) {
          let obj = { ...inputValues }
          let keyValues = Object.entries(obj)

          const i = keyValues.findIndex((e) => e[0] == 'limitFolio')

          keyValues.splice(i + 1, 0, [
            'limitFoliosAmount',
            {
              label: `general.tags.quantity`,
              name: `limitFoliosAmount`,
              translate: true,
              value: '1',
              validate: {
                required: true,
                number: true,
              },
              rest: {
                type: 'number',
                min: 1,
              },
              size: {
                xl: 4,
                lg: 4,
                md: 4,
                sm: 4,
                xs: 4,
              },
              customeErrors: {
                required: 'El campo cantidad es requerido',
              },
            },
          ])

          setInputValues(Object.fromEntries(keyValues))
        } else {
          let obj = { ...inputValues }
          delete obj['limitFoliosAmount']
          setInputValues(obj)
        }
      }

      if (name === 'maxRejections') {
        if (value === true) {
          let obj = { ...inputValues }
          let keyValues = Object.entries(obj)

          const i = keyValues.findIndex((e) => e[0] == 'maxRejections')

          keyValues.splice(i + 1, 0, [
            'maxRejectionsAmount',
            {
              label: `general.tags.quantity`,
              name: `maxRejectionsAmount`,
              translate: true,

              value: '1',
              validate: {
                required: true,
                number: true,
              },
              rest: {
                type: 'number',
                min: 1,
              },
              size: {
                xl: 4,
                lg: 4,
                md: 4,
                sm: 4,
                xs: 4,
              },
              customeErrors: {
                required: 'El campo cantidad es requerido',
              },
            },
          ])

          setInputValues(Object.fromEntries(keyValues))
        } else {
          let obj = { ...inputValues }
          delete obj['maxRejectionsAmount']
          setInputValues(obj)
        }
      }

      if (name === 'enableSurvey') {
        if (value === true) {
          let obj = { ...inputValues }
          let keyValues = Object.entries(obj)

          const i = keyValues.findIndex((e) => e[0] == 'enableSurvey')

          keyValues.splice(i + 1, 0, [
            'mandatorySurvey',
            {
              label: 'reportFolio.setting.mandatorySurvey',
              translate: true,
              name: 'mandatorySurvey',
              type: 'switch',
              value: false,
            },
          ])
          keyValues.splice(i + 2, 0, [
            'satisfactionQuestion',
            {
              label: 'reportFolio.setting.satisfactionQuestion',
              translate: true,
              name: 'satisfactionQuestion',
              type: 'textArea',
              value: '¿Cómo le pareció la gestión de este folio de reporte?',
              validate: {
                required: true,
              },
              customeErrors: {
                required: 'El campo pregunta de satisfacción al cliente es requerido',
              },
            },
          ])

          setInputValues(Object.fromEntries(keyValues))
        } else {
          let obj = { ...inputValues }
          delete obj['mandatorySurvey']
          delete obj['satisfactionQuestion']
          setInputValues(obj)
        }
      }
    }
  }

  const handleCancel = () => {
    navigate('/dashboard/report-folio/settings')
  }

  useEffect(() => {
    getAreas()
  }, [])

  const submit = async (data: any) => {
    setCanSubmit(false)
    setIsLoading(true)
    const areasSelected = arrayAreas.filter((e) => e.checked)
    const departmentsSelected = arrayDepartamentos.filter((e) => e.checked)

    let areasDepartments: any[] = []
    areasSelected.forEach((area) => {
      areasDepartments.push({
        areaId: area.id,
        departmentsId: departmentsSelected.filter((depar) => depar.areaId == area.id).map((e) => e.id),
      })
    })

    areasDepartments = areasDepartments.filter((e) => e.departmentsId.length > 0)
    let response

    if (!!id) {
      response = await reportFolioServices.updateConfig(
        {
          config: data,
          areasDepartments,
        },
        id
      )
    } else {
      response = await reportFolioServices.createConfig({
        config: data,
        areasDepartments,
      })
    }

    if (response?.errors)
      setOpenSnackbar({
        open: true,
        type: Types.ERROR,
        message: 'Ocurrió un error al guardar la configuración',
      })
    else
      setOpenModal((prev) => ({
        ...prev,
        open: true,
        title: 'Configuración de folio de reporte',
        description: 'La configuración del folio de reporte se guardó correctamente',
      }))

    setCanSubmit(true)
    setIsLoading(false)
  }

  const deleteConfig = () => setOpenModalDelete(true)

  const handleDelete = async () => {
    if (id) {
      setBtnLoading(true)
      const response = await reportFolioServices.deleteConfig(id)
      if (!response?.errors) {
        setOpenModal((prev) => ({
          ...prev,
          open: true,
          title: 'La configuración se eliminó exitosamente',
          description: '',
        }))
      } else {
        setOpenModal((prev) => ({
          ...prev,
          open: true,
          title: 'Ocurrió un error al eliminar la configuración',
          description: '',
        }))
      }
      setBtnLoading(false)
    }
  }

  const getAreas = async () => {
    const response = await areaServices.getAllAreas()
    let arrayAreas = response.map((e) => ({ label: e.name, checked: false, id: e.id }))

    if (state) {
      const { info } = state
      const data = info?.configReportFolioGeneral

      arrayAreas = arrayAreas.map((e) => ({
        ...e,
        checked: data?.find((e2: any) => e2.id == e.id),
      }))

      const ids = arrayAreas
        .filter((e) => e.checked)
        .map((e) => e.id)
        .join(',')

      const queryParams = { areaId: ids, configReportFolioId: state.id }
      const resp = await reportFolioServices.getDepartments(queryParams)

      if (!resp.errors) {
        resp.departmentsWithConfig.forEach((e: any) => {
          arrayDepartamentos.push({ id: e.id, name: e.name, checked: true, areaId: e.areaId })
        })
        resp.departmentsWithoutConfig.forEach((e: any) => {
          arrayDepartamentos.push({ id: e.id, name: e.name, checked: false, areaId: e.areaId })
        })
      }
      setArrayDepartamentos([...arrayDepartamentos])
      setArrayDepartamentosCopy([...arrayDepartamentos])
    }

    setArrayAreas([...arrayAreas])
    setArrayAreasCopy([...arrayAreas])
  }

  const onSearchArea: onChange = ({ target }) => {
    const { value } = target
    setArrayAreas(arrayAreasCopy.filter((e) => e.label?.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(value?.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') ?? '')))
  }

  const onSearchDepartment: onChange = ({ target }) => {
    const { value } = target
    setSearchString(value)
    setArrayDepartamentos(arrayDepartamentosCopy.filter((e) => e.name?.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(value?.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') ?? '')))
  }

  const onAreaSelection = async (event: any, id: any) => {
    const { checked } = event.target

    const i = arrayAreas.findIndex((e) => e.id === id)
    let tempArray = [...arrayAreas]
    tempArray[i].checked = checked
    setArrayAreas(tempArray)

    if (checked) {
      let queryParams: any = { areaId: id }
      if (state) queryParams.configReportFolioId = state.id
      const resp = await reportFolioServices.getDepartments(queryParams)

      const departmentWithConfig = resp?.departmentsWithConfig || []
      const departmentWithoutConfig = resp?.departmentsWithoutConfig || []

      if (!departmentWithConfig?.length && !departmentWithoutConfig?.length) {
        setAreasWithOutDepartments((prev) => ({
          ...prev,
          [id]: true,
        }))
      }

      tempArray = [...arrayDepartamentos]
      tempArray.push(...departmentWithConfig, ...departmentWithoutConfig)
      setArrayDepartamentos(tempArray.filter((e) => e.name?.toLowerCase()?.includes(searchString?.toLowerCase() ?? '')))

      tempArray = [...arrayDepartamentosCopy]
      tempArray.push(...departmentWithConfig, ...departmentWithoutConfig)
      setArrayDepartamentosCopy(tempArray)
      // if (!state) {
      //   tempArray = [...arrayDepartamentos]
      //   tempArray.push(...departmentWithConfig, ...departmentWithoutConfig)
      //   setArrayDepartamentos(tempArray)

      //   tempArray = [...arrayDepartamentosCopy]
      //   tempArray.push(...departmentWithConfig, ...departmentWithoutConfig)
      //   setArrayDepartamentosCopy(tempArray)
      // } else {
      //   tempArray = [...arrayDepartamentos]
      //   tempArray.push(...departmentWithConfig, ...departmentWithoutConfig)
      //   setArrayDepartamentos(tempArray)

      //   tempArray = [...arrayDepartamentosCopy]
      //   tempArray.push(...departmentWithConfig, ...departmentWithoutConfig)
      //   setArrayDepartamentosCopy(tempArray)
      // }
    } else {
      setAreasWithOutDepartments((prev) => ({
        ...prev,
        [id]: false,
      }))
      setArrayDepartamentos(arrayDepartamentos.filter((e) => e.areaId != id))
      setArrayDepartamentosCopy(arrayDepartamentosCopy.filter((e) => e.areaId != id))
    }
  }

  const onDepartmentSelection = (event: any, id: any) => {
    const { checked } = event.target

    const i = arrayDepartamentos.findIndex((e) => e.id === id)
    const j = arrayDepartamentosCopy.findIndex((e) => e.id === id)

    if (i > -1) {
      let tempArray = [...arrayDepartamentos]
      tempArray[i].checked = checked
      setArrayDepartamentos(tempArray)
    }

    if (j > -1) {
      let tempArray = [...arrayDepartamentosCopy]
      arrayDepartamentosCopy[j].checked = checked
      setArrayDepartamentosCopy(tempArray)
    }
  }

  let tempArea: any = null

  return (
    <>
      <SnackSimple close={handleCloseSnackbar} open={openSnackbar.open} type={openSnackbar.type} message={openSnackbar.message} />
      <Box sx={styles.container}>
        <Box sx={styles.containerHeader}>
          <IconButton
            onClick={() => {
              openReturnModal()
            }}
            sx={styles.rowIcon}
          >
            {icons('arrow-left-fill')}
          </IconButton>

          <Typography className="title">{!id ? 'Nueva Configuración de Folios de Reporte' : 'Editar Configuración de Folios de Reporte'}</Typography>
        </Box>

        <Grid container spacing={2} className="section">
          <Grid item xs={12} md={4}>
            <Paper sx={{ padding: 2, marginLeft: '2px' }}>
              <FormControl sx={{ width: '100%' }} variant="filled">
                <InputLabel htmlFor="search-area">Seleccione las áreas</InputLabel>
                <FilledInput
                  id="search-area"
                  type="text"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={onSearchArea}
                />
              </FormControl>

              {arrayAreas.map((item: any, i) => (
                <Box sx={styles.item}>
                  <FormControlLabel
                    key={item.label}
                    label={item.label}
                    control={
                      <Checkbox
                        name={item.label}
                        onChange={(e) => {
                          onAreaSelection(e, item.id)
                        }}
                        checked={item.checked}
                      />
                    }
                  />
                </Box>
              ))}
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ padding: 2, marginLeft: '2px' }}>
              {arrayDepartamentos.length < 1 && !searchString && <p>Debes seleccionar al menos un área para listar sus departamentos</p>}

              {(arrayDepartamentos.length > 0 || searchString || !!Object.values(aresWithOutDepartments ?? {})?.filter((item) => !!item)?.length) && (
                <>
                  <FormControl sx={{ width: '100%' }} variant="filled">
                    <InputLabel htmlFor="search-department">Seleccione los departamentos</InputLabel>
                    <FilledInput
                      id="search-department"
                      type="text"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      }
                      onChange={onSearchDepartment}
                    />
                  </FormControl>

                  {arrayDepartamentos.map((item: any, i) => {
                    let label = ''
                    if (item.areaId != tempArea) {
                      label = arrayAreas.find((e) => e.id == item.areaId)?.label
                      tempArea = item.areaId
                    }

                    return (
                      <>
                        {label && <Box sx={styles.label}>{label}</Box>}
                        <Box sx={styles.item}>
                          <FormControlLabel
                            key={item.id}
                            label={item.name}
                            control={
                              <Checkbox
                                name={item.name}
                                onChange={(e) => {
                                  onDepartmentSelection(e, item.id)
                                }}
                                checked={item.checked}
                              />
                            }
                          />
                        </Box>
                      </>
                    )
                  })}

                  {!searchString &&
                    Object.entries(aresWithOutDepartments ?? {})
                      ?.filter(([_, value]) => !!value)
                      ?.map(([key, value]: any, i) => {
                        let label = ''
                        label = arrayAreas.find((e) => e.id == key)?.label
                        // if (key != tempArea) {
                        //   // tempArea = key
                        // }

                        return (
                          <>
                            {label && <Box sx={styles.label}>{label}</Box>}
                            <Box sx={styles.item}>
                              <Typography sx={styles.notFound}>No se encontraron departamentos para el área</Typography>
                            </Box>
                          </>
                        )
                      })}
                </>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ padding: 2, marginLeft: '2px' }}>
              <Form
                data={data}
                input={Object.values(inputValues)}
                crudChange={crudChange}
                cancel={handleCancel}
                hideInfo
                submit={submit}
                canSubmit={canSubmit}
                isEditing={!!id}
                evidences={evidences}
                isLoading={isLoading}
                deleteConfig={deleteConfig}
              />

              <Box
                sx={{
                  textAlign: 'right',
                  paddingTop: '20px',
                }}
              >
                <Button
                  variant="text"
                  sx={{ fontSize: '12px' }}
                  onClick={() => {
                    navigate('/dashboard/report-folio/settings')
                  }}
                >
                  Cancelar
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      {/* return */}
      <SimpleModal
        open={returnModal.open}
        close={() => {
          closeReturnModal()
        }}
        title={returnModal.message}
        description={returnModal.description}
        buttonText={t('general.confirm')}
        cancelText={t('general.cancel')}
        color={colors}
        back={() => {
          closeReturnModal()
        }}
        next={() => {
          confirmReturn()
        }}
      />

      <SimpleModal
        open={openModal.open}
        close={() => {
          setOpenModal((prev) => ({
            ...prev,
            open: false,
          }))
          setCanSubmit(true)
          navigate('/dashboard/report-folio/settings')
        }}
        title={openModal.title}
        description={openModal.description}
        buttonText={t('general.confirm')}
        cancelText={t('general.cancel')}
        color={colors}
        next={() => {
          navigate('/dashboard/report-folio/settings')
        }}
      />

      <SimpleModal
        loadingButton
        isLoading={btnLoading}
        open={openModalDelete}
        close={() => {
          setOpenModalDelete(false)
        }}
        title={'¿Está seguro que desea eliminar esta configuración?'}
        description={''}
        buttonText={t('general.confirm')}
        cancelText={t('general.cancel')}
        color={colors}
        back={() => {
          setOpenModalDelete(false)
        }}
        next={handleDelete}
      />
    </>
  )
}
