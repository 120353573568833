import { useTranslation } from 'react-i18next'
import { Value } from '../types'

export const useInitialState = () => {
  const { t } = useTranslation()

  return {
    value: {
      email: {
        name: 'email',
        label: t('login.signin.form.email'),
        value: null,
      },
      password: {
        name: 'password',
        label: t('login.signin.form.password'),
        value: null,
        type: 'password'
      },
    } as Value,
    form: {
      title: t('login.signin.title'),
      description: t('login.signin.description'),
      button: t('login.signin.button'),
      footer: t('login.signin.footer')
    },
    t
  }
}
