import { format, getWeek, getYear } from 'date-fns'
import { TFunction } from 'react-i18next'
import { sharedAws } from '../../../../service/bucketS3/Storage'
import { OptionGroupService } from '../../../../service/optionGroup'
import { Constants } from '../../../../utils/constants'
import { httpFetch } from '../../../../utils/crud'
import { RequestErrors, Types } from '../../../../utils/enums'
import { Color } from '../../../../utils/theme/types/color'
import { ObjectGeneric } from '../../../../utils/types/generals'
import { isRequired, searchInModeLower } from '../../../../utils/validate/validate'
import { handleAllErrors } from '../../../../utils/methods/handleErrors'
import { es } from 'date-fns/locale'

export const getList = async (colors: Color, t: TFunction<'translation', undefined>) => {
  const data = await OptionGroupService(httpFetch).getOptionGroup()
  const column: any[] = [
    {
      id: 'title',
      label: t('general.tags.name'),
      sortable: true,
    },
    {
      id: 'optionNumber',
      label: t('general.tags.optionNumber'),
      sortable: true,
    },
    {
      id: 'createTime',
      label: t('general.tags.startDate'),
      sortable: true,
    },
    {
      id: 'action',
      label: t('general.tags.action'),
    },
  ]
  const row: any[] = !data?.errors
    ? data?.map((item) => {
      return {
        id: item?.id,
        optionNumber: item?.options ?? '0',
        title: item?.title,
        createTime: {
          label: item.create_time && format(new Date(item.create_time), Constants.dateFormatGeneral),
          value: item.create_time ? new Date(item.create_time).getTime() : 0,
          type: 'date',
        },
        create_time: item.create_time,
        monthNumber: new Date(item.create_time).getMonth(),
        year: getYear(new Date(item.create_time)),
        createYear: getYear(new Date(item.create_time)),
        month: format(new Date(item.create_time), 'LLLL', { locale: es }),
        weekYear: 'Semana ' + getWeek(new Date(item.create_time)),
        createDate: item.create_time && format(new Date(item.create_time), Constants.dateFormat2),
        createHour: item.create_time && format(new Date(item.create_time), Constants.hourFormat),
        udpateDate: format(new Date(item.update_time), Constants.dateFormat2),
        udpateHour: format(new Date(item.update_time), Constants.hourFormat),
      }
    })
    : []

  return { column, row }
}

export const filterRow = (value: { [x: string]: any }, list: any[]) => {
  const from = value.from?.valueOf()
  const to = value.to?.valueOf()
  return list?.filter((item) => {
    return (
      (from ? new Date(from).setHours(0, 0, 0, 0) <= Date.parse(item?.create_time) : true) &&
      (to ? new Date(to).setHours(23, 59, 59, 999) >= Date.parse(item?.create_time) : true) &&
      (value?.month instanceof Array ? (value?.month.length > 0 ? value?.month.find((e: any) => e.value === item.monthNumber) : true) : true) &&
      (value?.createYear instanceof Array ? (value?.createYear.length > 0 ? value?.createYear.find((e: any) => e.value === item.createYear) : true) : true) &&
      ((value?.search ? searchInModeLower(item?.title, value?.search) : true) ||
        (value?.search ? searchInModeLower(item?.optionNumber, value?.search) : true))
    )
  })
}

export const handleErrors = (values: any, t: any, ignore?: string[]) => {
  let error = {} as ObjectGeneric
  if (values.title !== null && isRequired(values.title)) error.title = t('general.validations.required')

  error.options = values.options.map((item: any) => {
    if (item.description !== null && isRequired(item.description))
      return {
        description: t('general.validations.required'),
      }
    return undefined
  })

  return error
}

export const submit = async (data: ObjectGeneric, t: TFunction<'translation', undefined>, id: number | null) => {
  let snackbarBody = {
    open: false,
    openModal: false,
    type: '',
    message: '',
  }
  let copyData = { ...data }

  const body = {
    title: data.title,
    options: await Promise.all(
      data.options.map(async (item: any) => ({
        description: item.description,
        iconUrl: item?.iconUrl?.name ?
          await sharedAws.uploadAndGetUrl({ file: item.iconUrl, name: `${new Date().getTime()}`, otherBucket: process.env.REACT_APP_BUCKET_ICON_QUESTIONNARIES }) : item?.iconUrl,
      }))
    ),
  } as any

  let response: any

  if (id) {
    response = await OptionGroupService(httpFetch).updateOptionGroup(body, id)
  } else {
    response = await OptionGroupService(httpFetch).createOptionGroup(body)
  }

  if (response.errors) {
    copyData = {
      ...copyData,
      options: copyData.options.map((item: any, index: number) => ({
        ...item,
        iconUrl: body.options?.[index]?.iconUrl ?? null,
      })),
    }
    const inUse = response?.errors?.[0]?.code === RequestErrors.ERR_OPTION_GROUP_IN_USE
    snackbarBody = {
      open: !inUse,
      openModal: inUse,
      type: Types.ERROR as string,
      message: `${handleAllErrors(response?.errors?.[0]?.code ?? '', t, `optionGroup.snackbar.${id ? 'update' : 'create'}.error`)} ${inUse ? t('general.createNew') : ''}`,
    }
  } else {
    snackbarBody = {
      open: true,
      openModal: false,
      type: Types.SUCCESS as string,
      message: t(`optionGroup.snackbar.${id ? 'update' : 'create'}.success`),
    }
  }
  return {
    snackbarBody,
    response: {
      response,
      copyData,
    },
  }
}
