import { InputModel } from '../../../../../../utils/types'

export const initialValues = {
  companies: {
    label: 'general.tags.companies',
    translate: true,
    name: 'companies',
    type: 'filter-select',
    items: [],
    value: null,
  },
  stores: {
    label: 'general.tags.stores',
    translate: true,
    name: 'stores',
    type: 'filter-select',
    items: [],
    value: null,
  },
  roles: {
    label: 'general.tags.roles',
    translate: true,
    name: 'roles',
    type: 'filter-select',
    items: [],
    value: null,
  },
  users: {
    label: 'general.tags.users',
    translate: true,
    name: 'users',
    type: 'filter-select',
    items: [],
    value: null,
  },
  userType: {
    label: 'general.tags.userType',
    translate: true,
    name: 'userType',
    type: 'filter-select',
    items: [],
    value: null,
  },
  questionnaires: {
    label: 'general.tags.questionnaires',
    translate: true,
    name: 'questionnaires',
    type: 'filter-select',
    items: [],
    value: null,
  },
} as { [x: string]: InputModel }
