import { Icon } from '@mui/material'
import { Priority } from '../../typing'
import { iconByPriority, iconsByStatus } from '../../utils'
import { useStyles } from './styles'
import { Status } from '../../../../../../utils/enums'
import { useContext } from 'react'
import { GlobalContext } from '../../../../../../contexts/Global'

const PriorityStatus = (props: { priority?: Priority; status?: Status.APPROVED | Status.REJECTED; size?: { fontSize: number; width: number | string; height: number | string } }) => {
  const { colors } = useContext(GlobalContext)
  const styles = useStyles({ colors, size: props.size })

  return (
    <Icon sx={styles.priority}>
      {!!props?.status && <Icon sx={[styles.status, styles[Status.APPROVED]]}>{iconsByStatus[props?.status].icon}</Icon>}

      <img src={iconByPriority[(props?.priority as Priority) ?? Priority.URGENT].icon} alt="" />
    </Icon>
  )
}

export { PriorityStatus }
