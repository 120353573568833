import { TFunction } from 'react-i18next'
import { CRUD, Status } from '../../../../utils/enums'
import { InputModel } from '../../../../utils/types'

const initialFilterValues = {
  role: '',
  status: '',
  search: '',
}

const storeHeaders = ({ t }: { t: TFunction<'translation', undefined> }) => {
  return [
    {
      id: 'name',
      label: t('general.tags.name'),
    },
    {
      id: 'role',
      label: t('general.tags.role'),
    },
    {
      id: 'curp',
      label: t('general.tags.curp'),
    },
  ]
}

const formData: Record<string, string> = {
  divisions: 'division',
  dateRequestion: 'create_date',
  deadline: 'max_date',
  firstName: 'first_name',
  lastName: 'last_name',
  userTypeLabel: 'user_type',
  curp: 'curp',
  gender: 'gender',
  role: 'role_ids',
  status: 'status_ids',
  stores: 'key_codes',
  to: 'end_date',
}
const supervisorHeaders = ({ t }: { t: TFunction<'translation', undefined> }) => {
  return [
    {
      id: 'keyCode',
      label: t('general.tags.keyCode'),
    },
    {
      id: 'name',
      label: t('general.tags.name'),
    },
    {
      id: 'agent',
      label: t('general.tags.agent'),
    },
    {
      id: 'gerente',
      label: t('general.tags.manager'),
    },
  ]
}

const useFilterDetail = () => {
  return {
    search: {
      label: 'Buscar usuario',
      name: 'search',
    },
    status: {
      label: 'Estado de usuario',
      name: 'status',
    },
    role: {
      label: 'Rol de usuario',
      name: 'role',
    },
  }
}

const initialValues = {
  curp: {
    label: 'CURP',
    name: 'curp',
    validate: {
      min: true,
    },
    rest: {
      min: 10,
      type: 'alphaNumeric',
    },
    value: null,
  },
  username: {
    label: 'Usuario *',
    name: 'username',
    value: '0',
  },
  email: {
    label: 'Correo electrónico',
    name: 'email',
    validate: {
      required: true,
      email: true,
    },
    value: null,
  },
  firstName: {
    label: 'Nombres *',
    name: 'firstName',
    validate: {
      required: true,
    },
    rest: {
      type: 'text',
    },
    value: null,
    customeErrors: {
      required: 'general.validate.required.firstNames',
    },
  },
  lastName: {
    label: 'Apellidos *',
    name: 'lastName',
    validate: {
      required: true,
    },
    rest: {
      type: 'text',
    },
    value: null,
    customeErrors: {
      required: 'general.validate.required.lastNames',
    },
  },
  gender: {
    label: 'Sexo *',
    name: 'gender',
    rest: {
      type: 'select-search',
    },
    validate: {
      required: true,
    },
    value: null,
  },
  phone: {
    label: 'Teléfono',
    name: 'phone',
    value: '+52',
    validate: {
      phone: true,
    },
  },
  role: {
    label: 'Rol *',
    validate: {
      required: true,
    },
    rest: {
      type: 'select-search',
    },
    name: 'role',
    value: null,
  },
  partnerStore: {
    label: 'Listado de tiendas',
    name: 'partnerStore',
    type: 'multiSelect',
    items: [],
    value: [],
  },
  companyId: {
    label: 'Compañía *',
    name: 'companyId',
    validate: {
      required: true,
    },
    customeErrors: {
      type: 2,
    },
    items: [],
    value: null,
  },
  divisions: {
    label: 'División',
    name: 'divisions',
    items: [],
    value: null,
  },
  userType: {
    label: 'Tipo de usuario *',
    name: 'userType',
    validate: {
      required: true,
    },
    items: [],
    value: null,
  },
  picture: {
    label: 'Cargar imagen',
    name: 'picture',
    value: null,
  },
  accessFolioReportGeneral: {
    label: '¿Tiene acceso a creación de folios de reporte general?',
    name: 'accessFolioReportGeneral',
    value: true,
  },
  agents: {
    items: [],
    value: [],
  },
} as unknown as { [x: string]: InputModel }

const initialTable = {
  column: [],
  row: [],
}

const gender = [
  {
    value: '2',
    label: 'Masculino',
  },
  {
    value: '1',
    label: 'Femenino',
  },
]

const username = [
  // {
  //   value: '1',
  //   label: 'Teléfono',
  // },
  {
    value: '0',
    label: 'Correo electrónico',
  },
]

const useMenuItems = (styles: any) => [
  {
    value: Status.ACTIVE,
    label: 'Activar',
  },
  {
    value: Status.BLOCKED,
    label: 'Bloquear',
  },
  {
    value: Status.WITH_DRAW,
    label: 'Retirar',
  },
  {
    value: CRUD.EDIT,
    label: 'Editar usuario',
    // icon: <Icon sx={styles.menuIcons}>{icons('edit')}</Icon>,
  },
  {
    value: CRUD.DELETE,
    label: 'Eliminar usuario',
  },
  {
    value: 'accountHistory',
    label: 'Historial de cuenta',
  },
]

const historyList = [
  {
    status: Status.ACTIVE,
    title: 'Usuario creado por Juan Parra',
    email: 'jparra@mail.com',
    date: 'DD/MM/YYYY - HH:MM:SS',
  },
  {
    status: Status.BLOCKED,
    title: 'Usuario bloqueado por Juan Parra',
    email: 'jparra@mail.com',
    date: 'DD/MM/YYYY - HH:MM:SS',
  },
  {
    status: Status.WITH_DRAW,
    title: 'Usuario retirado por Juan Parra',
    email: 'jparra@mail.com',
    date: 'DD/MM/YYYY - HH:MM:SS',
  },
]

const input = {
  search: {
    label: 'general.search',
    translate: true,
    name: 'search',
    type: 'text',
    value: null,
  },
  from: {
    label: 'general.tags.from',
    translate: true,
    name: 'from',
    type: 'date',
    value: null,
    rest: {
      max: 'to',
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  to: {
    label: 'general.tags.to',
    translate: true,
    name: 'to',
    type: 'date',
    value: null,
    rest: {
      min: 'from',
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  status: {
    label: 'general.tags.status',
    translate: true,
    name: 'status',
    type: 'filter-select',
    items: [],
    value: null,
  },
  userType: {
    label: 'general.tags.userType',
    translate: true,
    name: 'userType',
    type: 'filter-select',
    items: [],
    value: null,
  },
  role: {
    label: 'general.tags.role',
    translate: true,
    name: 'role',
    type: 'filter-select',
    items: [],
    value: null,
  },
  stores: {
    label: 'general.tags.store',
    translate: true,
    name: 'stores',
    type: 'filter-select',
    items: [],
    value: null,
  },
  division_ids: {
    label: 'general.tags.divisions',
    translate: true,
    name: 'division_ids',
    type: 'filter-select',
    items: [],
    value: null,
  },
} as { [x: string]: InputModel }

const accept = ['png', 'jpg', 'jpeg']

export { initialFilterValues, storeHeaders, supervisorHeaders, useFilterDetail, initialValues, formData, initialTable, gender, username, useMenuItems, historyList, input, accept }

export const formatFilter = {
  from: 'start_date',
  to: 'end_date',
  questionnaire: 'questionnaire_ids',
  zone: 'zone_ids',
  zonesClass: 'clase_ids',
  userType: 'user_type_ids',
  user: 'user_ids',
  stores: 'key_codes',
  status: 'status_ids',
  role: 'role_ids',
}
