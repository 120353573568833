import { useContextState } from '../../../../../../hooks/theme'

const useStyles = () => {
  const { colors } = useContextState()

  return {
    container: {
      height: '100%',
      width: '100%',
      padding: '50px 50px 0px 10px',
      position: 'relative',
    },
    fullWidth: {
      width: '100%',
    },
    clean: {
      fontSize: '2rem',
      padding: '6px',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    hide: {
      visibility: 'hidden',
    },
    drawerTitle: {
      fontSize: '12px',
      color: colors.color13,
      marginBottom: '20px',
    },
    drawerSpacing: {
      marginBottom: '40px',
    },
    drawerUser: {
      fontSize: '14px',
      color: colors.color13,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
    },
    arrow: {
      width: 'fit-content',
      '& .MuiIconButton-root': {
        color: colors.color4,
        fontWeight: '600',
      },
      '& .MuiTypography-root': {
        fontWeight: '400'
      }
    },
    headerLeft:{
      display: 'flex',
      alignItems: 'center',
    },
    btnFinish: {
      zIndex: 1000,
    },
    subTitle: {
      fontSize: '16px',
      fontWeight: '600',
      color: colors.color13,
    },
    subTitleAreaSub: {
      fontSize: '14px',
      color: colors.color13,
      marginBottom: '20px',
      '& span': {
        fontWeight: '600'
      }
    },
    subTitleArea: {
      fontSize: '16px',
      fontWeight: '600',
      color: colors.color13,
    },
    gridItems: {
      boxShadow: `0 0 4px ${colors.color13}`,
      borderRadius: '10px',
      width: '100%',
      height: 'calc(100%)',
      minHeight: 'fit-content',
      position: 'relative',
    },
    padding: {
      padding: '20px',
    },
    title: {
      fontSize: '20px',
      fontWeight: '600',
      color: colors.color13,
    },
    tabsContainer: {
      height: '100%',
      position: 'relative',
    },
    action: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap'
    },
    drawer: {
      position: 'relative',
      '& .MuiPaper-root': {
        '&:not(:last-child)': {
          marginBottom: '15px',
        },
        width: '400px',
        padding: '50px 0px 10px',
      },
    },
    viewMore: {
      fontSize: '1rem',
      color: colors.color16,
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    users: {
      textAlign: 'end',
      paddingRight: '5px',
    },
    ul: {
      paddingLeft: '20px',
      paddingRight: '5px',
      fontSize: '13px',
      color: colors.color25,
      fontWeight: '500',
      width: 'fit-content',
    },
    ulNull: {
      fontSize: '13px',
      color: colors.color25,
      wordBreak: 'break-all',
      fontWeight: '500',
      width: 'fit-content',
    },
    headetText: {
      color: colors.color5,
      fontWeigt: '800',
      fontSize: '12px',
    },
    containerUnit: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    unitContent: {
      fontSize: '12px',
      color: colors.color9,
      width: '100%',
    },
    user: {
      fontSize: '12px',
      color: colors.color9,
      width: '100%',
    },
    containerIconTooltip: {
      height: '15px',
    },
    section: {
      marginBottom: '32px',
    },
    bodyText: {
      color: colors.color10,
      fontWeigt: '800',
      fontSize: '12px',
      wordBreak: 'break-all',
      maxHeight: '40px',
      textAlign: 'center',
      // textWrap: 'nowrap',
      // overflow: 'hidden',
      // textOverflow: 'ellipsis',
      // width: '50px'
    },
    body: {
      padding: '0px 40px',
      height: 'calc(100% - 60px)',
      overflowY: 'auto',
    },
    containerFooter: {
      padding: '0px 40px',
      width: 'calc(100% - 80px)',
      position: 'absolute',
      bottom: '20px',
    },
    iconClose: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      color: colors.color13,
    },
    label: {
      '& .MuiFormControlLabel-label': {
        wordBreak: 'break-word',
        fontSize: '12px',
      },
    },
    headerActions: {
      display: 'flex',
      alignItems: 'center',
      flex: 'wrap',
      width: '100%',
      height: 'fit-content',
      justifyContent: 'end',
      // padding: '0px 25px 0px',
    },
    select: {
      color: colors.color16,
      fontWeight: '500',
      fontSize: '11px',
      padding: '0px 4px',
      cursor: 'pointer',
    },
    notFound: {
      marginTop: '20px',
      color: `${colors.color13}99`,
      fontSize: '16px',
      fontWeight: '500',
    },
    line: {
      color: colors.color16,
      lineHeight: '2.5',
      fontSize: '13px',
    },
    setting: {
      display: 'flex',
      justifyContent: 'center',
      '& .MuiButton-root': {
        fontSize: '10px',
        color: colors.color4,
      },
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
      '&.flex-j-e': {
        justifyContent: 'end',
      },
    },
    TabList: {
      width: '100%',
      position: 'absolute',
      top: '-55px',
      '& .MuiTabs-flexContainer': {
        paddingTop: '5px',
        columnGap: '10px',
      },
      '& .MuiTab-root': {
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        backgroundColor: `${colors.color10}44`,
        boxShadow: `0 0 4px ${colors.color10}`,
        color: colors.color10,
        '& .MuiTabs-scroller': {
          overflow: 'auto',
        },
        '&.Mui-selected': {
          backgroundColor: `${colors.color5}`,
          color: colors.color10,
        },
      },
      '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
      },
    },
    TabPanel: {
      '&.MuiTabPanel-root': {
        boxShadow: `0 0 4px ${colors.color13}`,
        borderRadius: '10px',
        width: '100%',
        height: '100%',
        paddingBottom: '0px',
      },
    },
  }
}

export { useStyles }
