import { EvidencesTypeValueEnum } from '../../../../../../utils/enums/evidences'
import { Grid, Box, Pagination } from '@mui/material'
import { useStyles } from './styles'
import { EvidencesCard } from '../../typing'
const EvidenceCard = ({ evidences, type, pagination, handleChangePage }: EvidencesCard) => {
  const styles = useStyles()
  const components = {
    [EvidencesTypeValueEnum.VIDEO]: (
      <Grid item xl={6} lg={6} md={6} sm={6}>
        <Box>
          <Box sx={styles.videos}>
            <video controls key={evidences?.[pagination]?.src}>
              <source src={evidences?.[pagination]?.src} type="video/mp4" />
            </video>
          </Box>

          <Box sx={styles.stepsIndicatorContainer}>
            <Pagination
              size="small"
              siblingCount={0}
              count={evidences?.length}
              page={pagination + 1}
              onChange={(_: unknown, page: number) => {
                handleChangePage({ page, type: 'video' })
              }}
              // showFirstButton
              // showLastButton
            />
          </Box>
        </Box>
      </Grid>
    ),
    [EvidencesTypeValueEnum.IMAGE]: (
      <Grid item xl={6} lg={6} md={6} sm={6}>
        <Box>
          <Box sx={styles.images}>
            <img src={evidences[pagination]?.src} alt="" />
          </Box>

          <Box sx={styles.stepsIndicatorContainer}>
            <Pagination
              size="small"
              siblingCount={0}
              count={evidences?.length}
              page={pagination + 1}
              onChange={(_: unknown, page: number) => {
                handleChangePage({ page, type: 'image' })
              }}
              // showFirstButton
              // showLastButton
            />
          </Box>
        </Box>
      </Grid>
    ),
  }
  return components[type] ?? <p>The component not found</p>
}

export { EvidenceCard }
