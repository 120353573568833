import { Color } from '../../../../../../utils/theme/types/color'

export const useStyles = (color: Color) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
  },
  actions: {
    width: '100%',
    textAlign: 'right',
    '& .MuiIconButton-root': {
      '&.openModal svg': {
        background: color.color12,
        color: 'white',
        borderRadius: '5px',
        width: '28px',
        height: '28px',
      },
      '&.add': {
        color: color.color4,
        fontSize: '2.4rem',
        padding: '0px',
        paddingRight: '2px',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
  },
  containerTable: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative',
    padding: '50px 50px 10px 0px',
    '& .crud-filter-container': {
      paddingRight: '50px',
    },
  },
  title: {
    fontSize: '20px',
    fontWeight: '600',
    color: color.color13,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    '&.flex-j-e': {
      justifyContent: 'end',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
    position: 'relative',
  },
  table: {
    flex: 0.8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: '50px',
    paddingTop: '50px',
    width: 'inherit',
  },
  userDetail: {
    flex: 0.2,
  },
  menuIcons: {
    marginLeft: '5px',
    fontSize: '18px',
    '& .MuiSvgIcon-root': {
      fontSize: '18px',
    },
  },
  text: {
    color: color.color13,
    fontSize: '16px',
    fontWeight: '500',
  },
  section: {
    marginBottom: '32px',
  },
  clean: {
    fontSize: '2.4rem',
    color: color.color4,
    padding: '6px',
    '& .MuiSvgIcon-root': {
      fontSize: '1em',
    },
  },
  hide: {
    visibility: 'hidden',
  },
  containerIconTooltip: {
    height: '15px',
  },
  containerTooltip: {
    padding: '5px',
    width: 'fit-content',
    minWidth: '150px',
  },
  containerTooltipAsk: {
    width: '100%',
    display: 'flex',
    columnGap: '10px',
  },
  textItem: {
    fontSize: '12px',
    fontWeight: '400',
    width: '100%',
    wordBreak: 'break-all',
  },
  titleTooltip: {
    minWidth: 'fit-content',
    height: '100%',
  },
  titleQuestion: {
    fontSize: '14px',
    marginBottom: '5px',
    width: 'fit-content',
    wordBreak: 'break-all',
  },
  listAnswers: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  viewMore: {
    fontSize: '1rem',
    color: color.color16,
    '& .MuiSvgIcon-root': {
      fontSize: '1em',
    },
  },
  bodyText: {
    color: color.color10,
    fontWeigt: '800',
    fontSize: '12px',
    wordBreak: 'break-word',
    maxHeight: '40px',
    textAlign: 'center',
  },
})
