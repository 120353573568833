export const useStyles = () => ({
  image: {
    width: '25px',
    height: '25px',
  },
  icon: {
    padding: 0,
    marginLeft: '8px',
  },
})
