import { useContextState } from "../../../../../../../../hooks/theme"

const useUserAssigned = () => {
    const { colors } = useContextState()
    return {
        textItem: {
            fontSize: '12px',
            fontWeight: '400',
            width: '100%',
            color: colors.color10,
            wordBreak: 'break-word',
        },
        containerIconTooltip: {
            height: '15px',
        },
        containerTooltipAsk: {
            width: '170px',
            display: 'flex',
            columnGap: '10px',
        },
        listAnswers: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textItemTooltip: {
            fontSize: '12px',
            fontWeight: '400',
            width: '100%',
            color: colors.color5,
            wordBreak: 'break-word',
        },
        viewMore: {
            fontSize: '1rem',
            color: colors.color16,
            '& .MuiSvgIcon-root': {
                fontSize: '1em',
            },
        },
    }
}

export { useUserAssigned }
