import { useTheme } from '@mui/material'
import { useContextState } from '../../../../hooks/theme'
import { useDimensions } from '../../../../hooks/dimensions'

export const useStyles = (width: boolean) => {
  const {dimensions} = useDimensions()
  const { breakpoints } = useTheme()
  const height = window.innerHeight >= 800
  const { colors } = useContextState()

  return {
    container: {
      width: '80vw',
      height: '80vh',
      position: 'relative',
      marginTop: '30px',
      borderRadius: '36px',
    },
    images: {
      display: 'block',
      borderTopLeftRadius: '36px',
      borderBottomLeftRadius: '36px',
      '& img': {
        objectFit: 'cover',
      },
      [breakpoints.between('sm', 1100)]: {
        display: 'none',
      },
    },
    infContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 3,
      height: 'calc(100% - 130px)',
      overflowY: 'auto',
      padding: height && dimensions?.width >= 1100 ? '50px 0px' : '40px 0px',
      overflow: 'auto'
    },
    gridItem: {
      overflow: 'hidden',
      '&.MuiGrid-root': {
        height: '100%',
        margin: 'auto',
        maxWidth: !width ? { md: '100%', sm: '100%' } : '100%',
      },
    },
    floatText: {
      position: 'absolute',
      top: '-40px',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      gap: '10px',
      '& .MuiTypography-root': {
        color: '#ffffff',
        fontSize: '.8rem'
      }
    },
    footer: {
      cursor: 'pointer',
      '& .MuiTypography-root': {
        color: colors.color13,
        fontSize: '15px'
      }
    },
  }
}
