import { Http } from '../../utils/types'
import { ActivedSheduled, FinishedOrPending, QuestionnaireCompleted, ScheduledVisits, The15BestStores, The15WorstStores } from './response'

export const indicatorsServices = (client: Http) => ({
  get15BestStores: async (data: {query?:string}): Promise<The15BestStores> => await client.get(`indicators/current-week/top-15-stores${data?.query ? `?${data.query}`: ''}`),
  get15WorstStoresStores: async (data: {query?:string}): Promise<The15WorstStores> => await client.get(`indicators/current-week/lowest-15-stores${data?.query ? `?${data.query}`: ''}`),
  getScheduledStores: async (): Promise<ActivedSheduled> => await client.get('indicators/dashboard/current-week/scheduled-stores'),
  getFinishedOrPendingVisits: async (): Promise<FinishedOrPending> => await client.get('indicators/current-week/visits'),
  getCompletedQuestionaires: async (): Promise<QuestionnaireCompleted> => await client.get('indicators/current-week/questionnaires'),
  getAllScheduled: async (data?: { query?: string }): Promise<ScheduledVisits> =>
    await client.get(`scheduled-visit?current_week=true${data?.query ? '&' + data?.query : ''}`),
})
