import { Box, Typography } from '@mui/material'
import { useContextState } from '../../../hooks/theme'
import { DocumentFormat, Item, ObjectGeneric, onChange } from '../../../utils/types/generals'
import { Button, InformativeCardProps } from '../../../utils/types/informationCardProps'
import BodyLayout from '../../bodyLayout'
import Filter from '../../filter'
import InformationCard from '../../informationCard'
import { CustomTable } from '../../table'
import { useStyles } from './styles'
import Loader from '../../loader'
import { DetailKeyValuRef } from '../../detailKeyValue/types'
interface Props {
  newHeader?: JSX.Element
  table: {
    loadingTable?: boolean
    title: string
    column: any[]
    row: any[]
    selected?: number | null
    onClick?: (e: any, index?: any) => void
    arrow?: boolean
    dontRefresh?: boolean
    checks?: boolean
    defaultItemsSelected?: (string | number)[]
    radio?: boolean
    document?: {
      format?: DocumentFormat
      header?: any[]
      export?: boolean
      excludeExcel?: any
      excludePdfDoc?: any
    }
    serverSidePagination?: {
      page: number
      size: number
      totalPages: number
    }
    handleChange?: any
    sortable?: (id: string, order: 'DESC' | 'ASC', count?: number) => void
    order?: {
      key: string
      order: 'DESC' | 'ASC'
      count?: number
    }
  }
  filter?: {
    onChange?: onChange
    title: string
    type?: 1 | 2 | 3
    subTitle?: string
    filterData?: ObjectGeneric
    search?: {
      name: string
      label: string
      sx?: any
    }
    firstSelect?: {
      name: string
      label: string
      items: Item[]
      sx?: any
    }
    secondSelect?: {
      name: string
      label: string
      items: Item[]
      sx?: any
    }
    refresh?: {
      onClick: () => void
      icon: any
    }
    add?: {
      onClick: () => void
      icon: any
    }
    load?: {
      onClick: () => void
      icon: any
    }
  }
  open?: boolean
  hidden?: boolean
  rightDrawer?: {
    addRef?: (payload: {
      element: DetailKeyValuRef | null;
      name: string;
  }) => DetailKeyValuRef | null
    loadindDetail?: boolean
    crud?: boolean
    showImage?: boolean
    form?: JSX.Element
    title: string
    clickMenu: (item: any) => void
    accept?: Button
    cancel?: Button
    auxiliar?: Button
    auxiliarRight?: Button
    changeEmail?: any
    autoHeight?: boolean | null | undefined
  } & InformativeCardProps
  filtersDrawer?: JSX.Element
}

const General = (props: Props) => {
  const { colors } = useContextState()
  const styles = useStyles(colors)

  return (
    <BodyLayout
      colors={colors}
      open={props.open}
      hidden={props.hidden}
      bodyR={!!props.rightDrawer?.loadindDetail ? <Box sx={{ width: '100%', height: '100%' }}><Loader /></Box> : <InformationCard {...props?.rightDrawer} addRef={props?.rightDrawer?.addRef} click={props?.rightDrawer?.clickMenu} accept={!!props.rightDrawer?.crud ? props?.rightDrawer.accept : undefined} />}
      bodyL={
        < Box sx={{ position: 'relative', width: '100%' }}>
          {props.filtersDrawer && !props.newHeader && <Typography sx={styles.title}> {props?.filter?.title}</Typography>}

          {props.filtersDrawer}
          {props.newHeader}
          {
            !!props.filter && !props.newHeader && (
              <Filter
                type={props.filter.type}
                title={!props.filtersDrawer ? props.filter.title : ''}
                filterTitle={props.filter.subTitle}
                filter={
                  props.filter.search && {
                    name: props.filter.search.name,
                    value: {
                      [props.filter.search.name]: {
                        value: props.filter?.filterData?.[props.filter.search.name],
                        label: props.filter.search.label,
                      },
                    },
                    sx: props.filter.search?.sx,
                    onChange: props.filter.onChange,
                  }
                }
                firstSelect={
                  props.filter.firstSelect && {
                    label: props.filter.firstSelect.label,
                    name: props.filter.firstSelect.name,
                    items: props.filter.firstSelect.items,
                    value: props.filter?.filterData?.[props.filter.firstSelect.name],
                    onChange: props.filter.onChange,
                  }
                }
                secondSelect={
                  props.filter.secondSelect && {
                    label: props.filter.secondSelect.label,
                    name: props.filter.secondSelect.name,
                    items: props.filter.secondSelect.items,
                    value: props.filter?.filterData?.[props.filter.secondSelect.name],
                    onChange: props.filter.onChange,
                  }
                }
                refresh={props.filter.refresh}
                add={props.filter.add}
                load={props.filter.load}
              />
            )
          }
          {
            !props?.table?.loadingTable ? (
              <CustomTable
                customeHeader={props.table.document?.header}
                format={props.table.document?.format}
                exportFiles={props.table.document?.export && !props.newHeader}
                name={props.table.title}
                arrow={props.table.arrow}
                dontRefresh={props.table.dontRefresh}
                column={props.table.column}
                row={props.table.row}
                color={colors}
                tableChecks={props.table.checks}
                tableRadio={props.table.radio}
                defaultChecks={props.table.defaultItemsSelected}
                onClick={props.table.onClick}
                selected={props.table.selected}
                excludeExcel={props.table.document?.excludeExcel}
                excludePdfDoc={props.table.document?.excludePdfDoc}
                loader={props.table.loadingTable}
                serverSidePagination={props.table.serverSidePagination}
                handleChange={props.table.handleChange}
                sortable={props.table.sortable}
                order={props.table.order}
              />
            ) : (
              <Loader />
            )
          }
        </Box >
      }
    />
  )
}

export default General
