import { Grid, } from "@mui/material"
import { useBody } from "./body"
import { ProgressList } from "../../components/progressList"
import { useContextState } from "../../../../../../hooks/theme"
import { BarList } from "../../components/barList"
import { useEffect, useMemo, useState } from "react"
import { Item } from "../../../../../../utils/types/generals"
import { RedirectedIds } from "../../../../typing"
import { formatBars } from "./mothods/formarBars"
import { keysProgramationGraphic, labelsAreaProgramationGraphic } from "./mothods/contants"
import { useTranslation } from "react-i18next"
import { TooltipType1 } from "../../components"
import { detalModelTypes } from "./components/modals/model/consts"
import { WeekLabel } from "../../components/weekLabel"
import { settingLabel } from "./constants/setting"
import { DetailModal } from "./components/modals"

const IndicatorsAreasAndDepartments = (props: { items: Record<string, Item[] | boolean> }) => {
  const { t } = useTranslation()
  const body = useBody(props)
  const { colors } = useContextState()
  const [childrens, setChildrens] = useState<Record<string, JSX.Element[]>>({ areas: [], departments: [] })
  const [listColorsByChips, setListColorsByChips] = useState<Record<string, Record<string, string>>>({ areas: {}, departments: {} })
  const colorsList: Record<string, Record<string, string>> = {
    primary: {
      1: colors.color16,
      2: colors.color19,
      3: colors.color24,
      4: colors.color8,
      5: colors.secondary,
    },
  }

  /**
   * @constant isDepartmentWeek
   * @constant isAreaWeek
   * @description cuando se selecciona el tipo de intervalo, valida si es tio semanal para configurar el label de la grafica
   */
  const isDepartmentWeek = useMemo(() => {
    const intervale = body.filterByHistory.departmentsGraphic.filterData?.intervale?.value === '1w'
    return ({ isWeek: intervale, custome: settingLabel(intervale) })
  }, [body.filterByHistory.departmentsGraphic.filterData?.intervale?.value])
  const isAreaWeek = useMemo(() => {
    const intervale = body.filterByHistory.areasGraphic.filterData?.intervale?.value === '1w'
    return ({ isWeek: intervale, custome: settingLabel(intervale) })
  }, [body.filterByHistory.areasGraphic.filterData?.intervale?.value])

  const wizardLinearAreas = () => {
    let count = 1
    let children: JSX.Element[] = []
    const are = [...body.dataGraphic.areas]
    let colorsByAreas = {}

    are.forEach(item => {
      const { barList } = formatBars({ t, color: colorsList.primary[count], id: item.id, objectOfKeys: keysProgramationGraphic, objectsOfLabels: labelsAreaProgramationGraphic })
      colorsByAreas = { ...colorsByAreas, [item.id]: colorsList.primary[count] }
      children = [...children, ...barList]
      if (count < 5) { count += 1 }
      else { count = 1 }
    })

    setListColorsByChips(prev => ({ ...prev, areas: colorsByAreas }))
    setChildrens(prev => ({ ...prev, areas: children }))
  }

  const wizardLinearDepartments = () => {
    let count = 1
    let children: JSX.Element[] = []
    const depart = [...body.dataGraphic.departments]
    let colorsByDepartments = {}
    depart.forEach(item => {
      const { barList } = formatBars({ t, color: colorsList.primary[count], id: item.id, objectOfKeys: keysProgramationGraphic, objectsOfLabels: labelsAreaProgramationGraphic })
      colorsByDepartments = { ...colorsByDepartments, [item.id]: colorsList.primary[count] }
      children = [...children, ...barList]
      if (count < 5) { count += 1 }
      else { count = 1 }
    })

    setListColorsByChips(prev => ({ ...prev, departments: colorsByDepartments }))
    setChildrens(prev => ({ ...prev, departments: children }))
  }

  useEffect(() => {
    wizardLinearAreas()
  }, [body.dataGraphic.areas])
  
  useEffect(() => {
    wizardLinearDepartments()
  }, [body.dataGraphic.departments])
  return (
    <>
      <Grid item xs={12}>
        <div id={RedirectedIds.PROGRESS_INDICATORS} />
        <Grid container spacing={3}>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <ProgressList
              key="ProgressList-area"
              refresh={() => { body.getProgressAreas(body.oldFilters.area) }}
              name="areas"
              arrow
              input={body.inputs.areas}
              header={{
                title: 'Programaciones por área'
              }}
              filter={body.filterArea}
              counter={body.counter.area}
              data={{
                ...body.dataIndicators.areas,
                data: body.dataIndicators.areas.list
              }}
              selectedArea={(payload) => { body.selectedArea({ ...payload, type: 'areas' }) }}
            />
          </Grid>

          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <ProgressList
              key="ProgressList-department"
              ref={(element) => body.ref.addRef({ element, name: 'department' })}
              refresh={() => { body.getProgressDepartments(body.oldFilters.department) }}
              name="departments"
              input={body.inputs.departments}
              header={{
                title: `Programaciones por departamento`,
                to: 'Del área:',
                subtitle: body.dataIndicators.areas.name ?? ''
              }}
              counter={body.counter.department}
              filter={body.filterDepartments}
              data={{
                ...body.dataIndicators.departments,
                data: body.dataIndicators.departments.list
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <div id={RedirectedIds.Area_Histogram} />
            <BarList
              int
              barRef={(element) => body.barRefs.addRef({ element, name: detalModelTypes.area })}
              customeTooltip={(payload: { label: string }) => {
                const data = body.dataByDates?.area?.[payload.label]
                if (!payload?.label || !data) return null
                return (
                  <TooltipType1
                    {...data}
                    containerBoxProps={{
                      onClick: (e) => {
                        e.stopPropagation()
                        const area = body.barRefs.refsByKey[detalModelTypes.area]
                        if (area?.addClick) {
                          area?.addClick()
                        }
                      }
                    }}
                    close={() => { body.closeTooltip({ type: detalModelTypes.area }) }}
                    getItemOfGraphic={(payload) => { body.getItemOfGraphic({ date: payload?.date ?? "", type: detalModelTypes.area }) }}
                    path="dashboard.kpi"
                    listColorsByChips={listColorsByChips.areas}
                  />
                )
              }}
              doesnotRemoveTurn={isAreaWeek.isWeek}
              xHeader={isAreaWeek.isWeek ? { component: ({ value }) => <WeekLabel value={value} /> } : undefined}
              customeX={isAreaWeek.custome ?? {}}
              refresh={body.refreshAreaGraphic}
              colorsByChips={listColorsByChips.areas}
              name="areas"
              title="Histórico de programaciones por área"
              titleGraphic={body.filterByHistory.areasGraphic.filterData?.areas?.label}
              list={childrens.areas}
              isLoading={!!body.graphicsData.areas.isLoading}
              data={body.graphicsData.areas.data}
              input={{
                ...body.inputs.areasGraphic,
                from: {
                  ...body.inputs.areasGraphic?.from,
                  rest: {
                    ...body.filterByHistory.areasGraphic?.filterData?.intervale?.value === '1w' ? {
                      shouldDisableDate: (date: Date) => new Date(date.valueOf())?.getDay?.() !== 1 || new Date(date.valueOf()) > body.filterByHistory.areasGraphic?.filterData?.to,
                      textField: {
                        disabled: true
                      }
                    } : {
                      shouldDisableDate: (date: Date) => new Date(date.valueOf()) > body.filterByHistory.areasGraphic?.filterData?.to,

                    }
                  }
                }
              }}
              dataFilter={{
                ...body.filterByHistory.areasGraphic,
                onChange: (e, child) => { body.onChange({ e, type: 'areasGraphic', blur: child }) }
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <div id={RedirectedIds.Department_Histogram} />
            <BarList
              int
              barRef={(element) => body.barRefs.addRef({ element, name: detalModelTypes.department })}
              xHeader={isDepartmentWeek.isWeek ? { component: ({ value }) => <WeekLabel value={value} /> } : undefined}
              customeX={isDepartmentWeek.custome ?? {}}
              customeTooltip={(payload: { label: string }) => {
                const data = body.dataByDates?.department?.[payload.label]
                if (!payload?.label || !data) return null
                return (
                  <TooltipType1
                    {...data}
                    containerBoxProps={{
                      onClick: (e) => {
                        e.stopPropagation()
                        const department = body.barRefs.refsByKey[detalModelTypes.department]
                        if (department?.addClick) {
                          department?.addClick()
                        }
                      }
                    }}
                    close={() => { body.closeTooltip({ type: detalModelTypes.department }) }}
                    getItemOfGraphic={(payload) => { body.getItemOfGraphic({ date: payload?.date ?? "", type: detalModelTypes.department }) }}
                    path="dashboard.kpi"
                    listColorsByChips={listColorsByChips.departments}
                  />
                )
              }}
              doesnotRemoveTurn={isDepartmentWeek.isWeek}
              refresh={() => { body.filterItemDepartmentsGraphic(body.filterByHistory.departmentsGraphic.filterData) }}
              colorsByChips={listColorsByChips.departments}
              title="Histórico de programaciones por departamento"
              name="departments"
              list={childrens.departments}
              isLoading={!!body.graphicsData.departments.isLoading}
              data={body.graphicsData.departments.data}
              input={{
                ...body.inputs.departmentsGraphic,
                from: {
                  ...body.inputs.departmentsGraphic?.from,
                  rest: {
                    ...body.filterByHistory.departmentsGraphic?.filterData?.intervale?.value === '1w' ? {
                      shouldDisableDate: (date: Date) => new Date(date.valueOf())?.getDay?.() !== 1 || new Date(date.valueOf()) > body.filterByHistory.departmentsGraphic?.filterData?.to,
                      textField: {
                        disabled: true
                      }
                    } : {
                      shouldDisableDate: (date: Date) => new Date(date.valueOf()) > body.filterByHistory.departmentsGraphic?.filterData?.to,

                    }
                  }
                }
              }}
              dataFilter={{
                ...body.filterByHistory.departmentsGraphic,
                onChange: (e, child) => {
                  body.onChange({ e, type: 'departmentsGraphic', blur: child })
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <DetailModal
        open={!!body.itemByDate}
        payload={body.itemByDate?.data}
        type={body.itemByDate?.type}
        close={body.closeAndCleanDetail}
      />
    </>
  )
}

export { IndicatorsAreasAndDepartments }
