import { differenceInDays, format, startOfWeek } from 'date-fns'
import { dashboardService } from '../../../../../../../utils/constants/callService'
import { percentage } from '../../../../../../../utils/validate/validate'
import { Constants } from '../../../../../../../utils/constants'
import { RequestHistory } from '../typing/types'
import { DivisionsDataHistorigram } from '../typing/enums'
import { Box } from '@material-ui/core'
import { Typography } from '@mui/material'
import { light } from '../../../../../../../utils/theme/colors/light'
import { unitStatus } from '../../methods/statusByPercentage'
import { percentageColors } from '../../methods/statusColors'
import { TFunction } from 'react-i18next'
import { es } from 'date-fns/locale'
import { formatUnits } from './formatResponse'
import { ToDate } from '../../../../../../../utils/methods/toDate'

const styles = {
  percentageStatus: ({ color }: { color: string }) => ({
    background: color,
    width: '10px',
    height: '10px',
    borderRadius: '50%',
  }),
  containerTitle: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
}
const calc30DaysAgo = (payload?: {
  start?: string | Date | number
  end?: string | Date | number
  format?: string
}) => {
  const currentDate = new Date((payload?.end ? new Date(payload?.end) : new Date()).setHours(23, 59, 59, 99))

  // Calculate the date 30 days ago
  const date30DaysAgo = new Date(currentDate)
  date30DaysAgo.setDate(currentDate.getDate() - 31)

  const startDate = format((payload?.start ? new Date(payload?.start) : date30DaysAgo).setHours(0, 0, 0, 0), payload?.format ?? Constants.dateEndpoint2)
  const endDate = format(currentDate, payload?.format ?? Constants.dateEndpoint2)
  return { startDate, endDate }
}

const refresh = ({ units, classes, divisions, zones }: {
  units: string[];
  divisions: string[]
  zones: string[]
  classes: string[]
}) => {

  let filters: {
    terms: {
      [x: string]: string[];
    };
  }[] = [{
    terms: {
      unidad_id: units,
    },
  }]

  if (!!divisions?.length) {
    filters.push({
      terms: {
        division_id: divisions,
      },
    })
  }

  if (!!zones?.length) {
    filters.push({
      terms: {
        zona_id: zones,
      },
    })
  }

  if (!!classes?.length) {
    filters.push({
      terms: {
        clase_id: classes,
      },
    })
  }
  return {
    units: async () => {
      try {
        const body = {
          size: 1,
          query: {
            bool: {
              must: filters,
            },
          },
          aggs: {
            semana: {
              terms: {
                field: 'semana',
              }
            },
            total_unidades: {
              sum: {
                field: 'total',
              },
            },
            total_programados: {
              sum: {
                field: 'programados',
              },
            },
            total_no_programados: {
              sum: {
                field: 'no_programados',
              },
            },
          },
        }

        const response = await dashboardService.activeUnits({ body })
        const aggregations = response.aggregations
        const semana = response.aggregations?.semana?.buckets?.[0]?.key
        const programming = aggregations.total_programados.value
        const noProgramming = aggregations.total_no_programados.value
        const total = programming + noProgramming

        const updateDate = format(
          new Date(response.hits.hits[0]._source.fecha),
          Constants.humanFormat, { locale: es }
        )
        const percentageProgramming = percentage({ a: programming, b: total })
        const percentageNoProgramming = percentage({
          a: noProgramming,
          b: total,
        })

        return await {
          programming,
          noProgramming,
          percentageProgramming,
          percentageNoProgramming,
          total,
          updateDate,
          semana
        }
      } catch (e) {
        return {
          programming: 0,
          noProgramming: 0,
          percentageProgramming: '0',
          percentageNoProgramming: '0',
          total: 0,
          semana: 0
        }
      }
    },
    programming: async () => {
      try {
        const body = {
          size: 1,
          query: {
            bool: {
              must: filters,
            },
          },
          aggs: {
            semana: {
              terms: {
                field: 'semana',
              }
            },
            total_programaciones: {
              sum: {
                field: 'total',
              },
            },
            total_finalizados: {
              sum: {
                field: 'finalizados',
              },
            },
            total_en_progreso: {
              sum: {
                field: 'en_progreso',
              },
            },
            total_no_iniciados: {
              sum: {
                field: 'no_iniciados',
              },
            },
          },
        }
        const response = await dashboardService.activeUnits({ body })
        const aggregations = response.aggregations

        const semana = response.aggregations?.semana?.buckets?.[0]?.key
        const paused = aggregations.total_en_progreso.value
        const noStarted = aggregations.total_no_iniciados.value
        const finished = aggregations.total_finalizados.value
        const total = paused + noStarted + finished

        const updateDate = format(
          new Date(response.hits.hits[0]._source.fecha),
          Constants.humanFormat, { locale: es }
        )
        const percentagePaused = percentage({ a: paused, b: total })
        const percentageNoStarted = percentage({ a: noStarted, b: total })
        const percentageFinished = percentage({ a: finished, b: total })

        return await {
          paused,
          noStarted,
          finished,
          percentagePaused,
          percentageNoStarted,
          percentageFinished,
          total,
          updateDate,
          semana
        }
      } catch (e) {
        return await {
          paused: 0,
          noStarted: 0,
          finished: 0,
          percentagePaused: '0',
          percentageNoStarted: '0',
          percentageFinished: '0',
          total: 0,
          semana: 0
        }
      }
    },
    actionPlan: async () => {
      try {
        const body = {
          size: 1,
          query: {
            bool: {
              must: filters,
            },
          },
          aggs: {
            semana: {
              terms: {
                field: 'semana',
              }
            },
            total_planes_accion: {
              sum: {
                field: 'total',
              },
            },
            total_en_progreso: {
              sum: {
                field: 'en_progreso',
              },
            },
            total_rechazados: {
              sum: {
                field: 'rechazados',
              },
            },
            total_finalizados: {
              sum: {
                field: 'finalizados',
              },
            },
            total_no_iniciados: {
              sum: {
                field: 'no_iniciados',
              },
            },
            total_en_cancelados: {
              sum: {
                field: 'cancelados',
              },
            },
            total_en_vencidos: {
              sum: {
                field: 'vencidos',
              },
            },
          },
        }
        const response = await dashboardService.activeActionPlan({ body })
        const aggregations = response.aggregations

        const semana = response.aggregations?.semana?.buckets?.[0]?.key
        const paused = aggregations.total_en_progreso.value
        const noStarted = aggregations.total_no_iniciados.value
        const rejectd = aggregations.total_rechazados.value
        const finished = aggregations.total_finalizados.value
        const canceled = aggregations.total_en_cancelados.value
        const expired = aggregations.total_en_vencidos.value

        const total =
          paused + noStarted + rejectd + finished + canceled + expired
        const updateDate = format(
          new Date(response.hits.hits[0]._source.fecha),
          Constants.humanFormat, { locale: es }
        )

        const percentagePaused = percentage({ a: paused, b: total })
        const percentageNoStarted = percentage({ a: noStarted, b: total })
        const percentageRejectd = percentage({ a: rejectd, b: total })
        const percentageFinished = percentage({ a: finished, b: total })
        const percentageCanceled = percentage({ a: canceled, b: total })
        const percentageExpired = percentage({ a: expired, b: total })

        return await {
          paused,
          noStarted,
          rejectd,
          finished,
          canceled,
          expired,
          percentagePaused,
          percentageNoStarted,
          percentageFinished,
          percentageRejectd,
          percentageCanceled,
          percentageExpired,
          total,
          updateDate,
          semana
        }
      } catch (e) {
        return await {
          paused: 0,
          noStarted: 0,
          finished: 0,
          canceled: 0,
          rejectd: 0,
          expired: 0,
          percentageRejectd: '0',
          percentageCanceled: '0',
          percentagePaused: '0',
          percentageNoStarted: '0',
          percentageFinished: '0',
          percentageExpired: '0',
          total: 0,
          semana: 0
        }
      }
    },
  }
}

const graphicData = ({
  units,
  divisions,
  zones,
  classes,
  t,
}: {
  units: string[]
  divisions: string[]
  zones: string[]
  classes: string[]
  t: TFunction<'translation', undefined>
}) => {
  let filters: {
    terms: {
      [x: string]: string[];
    };
  }[] = [{
    terms: {
      unidad_id: units,
    },
  }]

  if (!!divisions?.length) {
    filters.push({
      terms: {
        division_id: divisions,
      },
    })
  }

  if (!!zones?.length) {
    filters.push({
      terms: {
        zona_id: zones,
      },
    })
  }

  if (!!classes?.length) {
    filters.push({
      terms: {
        clase_id: classes,
      },
    })
  }

  return {
    units: async ({ interval, date }: RequestHistory) => {
      try {
        const { startDate, endDate } = calc30DaysAgo({
          end: date?.end,
          start: date?.start,
          format: interval != '1w' ? Constants.dateFormat3 : undefined
        })


        let startWeek = format(startOfWeek(new Date(startDate), { weekStartsOn: 1 }), Constants.dateEndpoint2)


        const start = interval == '1w' ? startWeek : startDate
        const end = endDate
        const body = {
          size: 52,
          query: {
            bool: {
              filter: [
                ...filters,
                {
                  "range": {
                    [interval == '1w' ? 'fecha_inicio_semana' : 'fecha']: {
                      "gte": start,
                      "lte": end
                    }
                  }
                }
              ]
            },
          },
          track_total_hits: true,
          aggregations: {
            registros_diarios: {
              date_histogram: {
                field: interval == '1w' ? 'fecha_inicio_semana' : 'fecha',
                calendar_interval: interval ?? '1d',
              },
              aggs: {
                total: {
                  sum: {
                    field: 'total',
                  },
                },
                programados: {
                  sum: {
                    field: 'programados',
                  },
                },
                no_programados: {
                  sum: {
                    field: 'no_programados',
                  },
                },
              },
            },
          },
        }

        const response = await dashboardService[interval == '1w' ? 'activeUnitsWeek' : 'activeUnitsHistory']({ body })
        let data: any[] = []
        let currentValueExisted = true
        const currentDate = format(new Date(), Constants.dateFormat3)
        const buckets = response?.aggregations?.registros_diarios?.buckets
        const startTime = new Date(startDate).getTime()
        const endTime = new Date(endDate).getTime()
        const currentTime = new Date().setHours(0, 0, 0, 0)

        let someDateIsCurrent = buckets?.some((item) => !!item.key_as_string.toString().includes(currentDate))
        // if (interval == '1w') {
        //   const endDate = ToDate(buckets?.[buckets.length - 1]?.key_as_string)
        //   const lastDateToTheWeek = endOfWeek(endDate, { weekStartsOn: 1 })
        //   const thisDate = ToDate(currentDate)
        //   someDateIsCurrent = isBefore(thisDate, lastDateToTheWeek) || someDateIsCurrent
        // }
        const dateRange = ((startTime <= currentTime) && (endTime >= currentTime))
        if (
          (((!someDateIsCurrent && !!dateRange)) && !!currentValueExisted)
        ) {
          currentValueExisted = false
        }
        let dateToAdd = false

        buckets?.forEach((item) => {
          const dayWithOutTimeZone = ToDate(item.key_as_string)
          const isDayBefore = differenceInDays(dayWithOutTimeZone, new Date())
          const programmed = item.programados.value
          const noProgrammed = item.no_programados.value
          const total = programmed + noProgrammed
          const statusProgrammes = unitStatus(
            parseFloat(percentage({ a: programmed, b: total }))
          ).programming()
          const statusNoProgrammes = unitStatus(
            parseFloat(percentage({ a: noProgrammed, b: total }))
          ).noProgramming()
          const colorProgrammed = percentageColors[statusProgrammes]
          const colorNoProgrammed = percentageColors[statusNoProgrammes]
          if (isDayBefore >= 0 && !currentValueExisted && !dateToAdd) {
            data.push({
              here: true
            })
            dateToAdd = true
          }


          data.push(formatUnits({
            name: format(dayWithOutTimeZone, Constants.dateFormat),
            colorNoProgrammed,
            colorProgrammed,
            customeLabels: {
              noProgramming: `${noProgrammed} (${percentage({ a: noProgrammed, b: total, })}%)`
            },
            noProgrammed: `${t('dashboard.kpi.noProgrammed')}: ${noProgrammed} (${percentage({ a: noProgrammed, b: total, })}%)`,
            percentages: {
              programming: (programmed / DivisionsDataHistorigram.ACTIVE_UNITS).toString(),
              noProgramming: (noProgrammed / DivisionsDataHistorigram.ACTIVE_UNITS).toString(),
              total: (total / DivisionsDataHistorigram.ACTIVE_UNITS).toString(),
            },
            programmed: `${t('dashboard.kpi.programmed')}: ${programmed} (${percentage({
              a: programmed,
              b: total,
            })}%)`,
            total: `${t('dashboard.kpi.total')}: ${total}`,
            values: {
              total: total.toString()
            },
            styles
          }))
        })
        if (!dateToAdd && !currentValueExisted) {
          data.push({
            here: true
          })
        }
        return { data, startDate, endDate, currentValueExisted }
      } catch (e) {
        return {
          data: [],
          startDate: '',
          endDate: '',
          currentValueExisted: true,
        }
      }
    },
    programming: async ({ interval, date }: RequestHistory) => {
      try {
        const { startDate, endDate } = calc30DaysAgo({
          end: date?.end,
          start: date?.start,
          format: interval != '1w' ? Constants.dateFormat3 : undefined
        })

        let startWeek = format(startOfWeek(new Date(startDate), { weekStartsOn: 1 }), Constants.dateEndpoint2)


        const start = interval == '1w' ? startWeek : startDate
        const end = endDate

        const body = {
          query: {
            bool: {
              filter: [
                ...filters,
                {
                  "range": {
                    [interval == '1w' ? 'fecha_inicio_semana' : 'fecha']: {
                      "gte": start,
                      "lte": end
                    }
                  }
                }
              ]
            },
          },
          size: 0,
          track_total_hits: true,
          aggregations: {
            registros_diarios: {
              date_histogram: {
                field: interval == '1w' ? 'fecha_inicio_semana' : 'fecha',
                calendar_interval: interval ?? '1d',
              },
              aggs: {
                total: {
                  sum: {
                    field: 'total',
                  },
                },
                finalizados: {
                  sum: {
                    field: 'finalizados',
                  },
                },
                en_progreso: {
                  sum: {
                    field: 'en_progreso',
                  },
                },
                no_iniciados: {
                  sum: {
                    field: 'no_iniciados',
                  },
                },
              },
            },
          },
        }
        const response = await dashboardService[interval == '1w' ? 'activeUnitsWeek' : 'activeUnitsHistory']({
          body,
        })
        let currentValueExisted = true
        const currentDate = format(new Date(), Constants.dateFormat3)
        const buckets = response?.aggregations?.registros_diarios?.buckets
        const startTime = new Date(startDate).getTime()
        const endTime = new Date(endDate).getTime()
        const currentTime = new Date().setHours(0, 0, 0, 0)
        let dateToAdd = false

        let someDateIsCurrent = buckets?.some((item) => !!item.key_as_string.toString().includes(currentDate))
        const dateRange = ((startTime <= currentTime) && (endTime >= currentTime))
        if (
          (((!someDateIsCurrent && !!dateRange)) && !!currentValueExisted)
        ) {
          currentValueExisted = false
        }
        let data = []
        buckets.forEach(
          (item) => {
            const dayWithOutTimeZone = item.key_as_string
            const isDayBefore = differenceInDays(new Date(dayWithOutTimeZone), new Date())
            const paused = item.en_progreso.value
            const noStatrted = item.no_iniciados.value
            const finished = item.finalizados.value

            const total = paused + noStatrted + finished
            const statusPaused = unitStatus(
              parseFloat(percentage({ a: paused, b: total }))
            ).noProgramming()
            const colorPaused = percentageColors[statusPaused]
            if (isDayBefore >= 0 && !currentValueExisted && !dateToAdd) {
              data.push({
                here: true
              })
              dateToAdd = true
            }
            data.push({
              name: format(ToDate(item.key_as_string), Constants.dateFormat),
              customeData: {
                paused: (
                  <Box sx={{ width: '100%' }}>
                    <Typography
                      sx={{
                        color: light.color10,
                        width: '100%',
                        lineHeight: '2',
                      }}
                    >
                      {t('dashboard.kpi.total')}: {total}
                    </Typography>

                    <Box sx={styles.containerTitle}>
                      <Typography
                        sx={{
                          color: light.color13,
                          width: '100%',
                        }}
                      >
                        {t('dashboard.kpi.inProgress')}:
                        {`${paused} (${percentage({
                          a: paused,
                          b: total,
                        })}%)`}
                      </Typography>

                      <Box
                        sx={styles.percentageStatus({
                          color: colorPaused.color,
                        })}
                      />
                    </Box>
                  </Box>
                ),
                total: <></>,
              },
              customeSettings: {
                total,
                types: {
                  paused: '2',
                  noStarted: '2',
                  finished: '1',
                },
                remove: {
                  total: true,
                },
              },
              customeLabels: {
                noStarted: `${noStatrted} (${percentage({
                  a: noStatrted,
                  b: total,
                })}%)`,
                finished: `${finished} (${percentage({
                  a: finished,
                  b: total,
                })}%)`,
              },
              total: total,
              paused: paused,
              noStarted: noStatrted,
              finished: finished,
            })
          }
        )
        if (!dateToAdd && !currentValueExisted) {
          data.push({
            here: true
          })
        }
        return { data, startDate, endDate, currentValueExisted }
      } catch (e) {
        return {
          data: [],
          startDate: '',
          endDate: '',
          currentValueExisted: true,
        }
      }
    },
    actionPlan: async ({ interval, date }: RequestHistory) => {
      try {
        const { startDate, endDate } = calc30DaysAgo({
          end: date?.end,
          start: date?.start,
          format: interval != '1w' ? Constants.dateFormat3 : undefined
        })

        let startWeek = format(startOfWeek(new Date(startDate), { weekStartsOn: 1 }), Constants.dateEndpoint2)

        const start = interval == '1w' ? startWeek : startDate
        const end = endDate
        const body = {
          query: {
            bool: {
              filter: [
                ...filters,
                {
                  "range": {
                    [interval == '1w' ? 'fecha_inicio_semana' : 'fecha']: {
                      "gte": start,
                      "lte": end
                    }
                  }
                }
              ]
            },
          },
          size: 0,
          track_total_hits: true,
          aggregations: {
            registros_diarios: {
              date_histogram: {
                field: interval == '1w' ? 'fecha_inicio_semana' : 'fecha',
                calendar_interval: interval ?? '1d',
              },
              aggs: {
                total: {
                  sum: {
                    field: 'total',
                  },
                },
                finalizados: {
                  sum: {
                    field: 'finalizados',
                  },
                },
                en_progreso: {
                  sum: {
                    field: 'en_progreso',
                  },
                },
                no_iniciados: {
                  sum: {
                    field: 'no_iniciados',
                  },
                },
                rechazados: {
                  sum: {
                    field: 'rechazados',
                  },
                },
                cancelados: {
                  sum: {
                    field: 'cancelados',
                  },
                },
                vencidos: {
                  sum: {
                    field: 'vencidos',
                  },
                },
              },
            },
          },
        }
        const response = await dashboardService[interval == '1w' ? 'activeActionPlanWeek' : 'activeActionPlanHistory']({
          body,
        })

        let currentValueExisted = true
        let dateToAdd = false
        const currentDate = format(new Date(), Constants.dateFormat3)
        const startTime = new Date(startDate).getTime()
        const endTime = new Date(endDate).getTime()
        const currentTime = new Date().setHours(0, 0, 0, 0)
        const buckets = response?.aggregations?.registros_diarios?.buckets
        let someDateIsCurrent = buckets?.some((item) => !!item.key_as_string.toString().includes(currentDate))
        const dateRange = ((startTime <= currentTime) && (endTime >= currentTime))

        // if (interval == '1w') {
        //   const endDate = ToDate(buckets?.[buckets.length - 1]?.key_as_string)
        //   const lastDateToTheWeek = endOfWeek(endDate, { weekStartsOn: 1 })
        //   const thisDate = ToDate(currentDate)
        //   someDateIsCurrent = isBefore(thisDate, lastDateToTheWeek) || someDateIsCurrent
        // }

        if (
          (((!someDateIsCurrent && !!dateRange)) && !!currentValueExisted)
        ) {
          currentValueExisted = false
        }
        let data = []
        buckets.forEach(
          (item) => {
            const dayWithOutTimeZone = item.key_as_string
            const isDayBefore = differenceInDays(new Date(dayWithOutTimeZone), new Date())
            const paused = item.en_progreso.value
            const noStatrted = item.no_iniciados.value
            const canceled = item.cancelados.value
            const expired = item.vencidos.value
            const finished = item.finalizados.value
            const rejected = item.rechazados.value

            const total =
              paused + noStatrted + canceled + expired + finished + rejected
            const statusPaused = unitStatus(
              parseFloat(percentage({ a: paused, b: total }))
            ).noProgramming()
            const colorPaused = percentageColors[statusPaused]
            if (isDayBefore >= 0 && !currentValueExisted && !dateToAdd) {
              data.push({
                here: true
              })
              dateToAdd = true
            }
            data.push({
              isADayBefore: isDayBefore == -1,
              name: format(ToDate(item.key_as_string), Constants.dateFormat),
              customeSettings: {
                total,
                types: {
                  paused: '2',
                  noStarted: '2',
                  finished: '1',
                  rejected: '2',
                  canceled: '2',
                  expired: '2',
                },
                remove: {
                  total: true,
                },
              },
              customeData: {
                paused: (
                  <Box sx={{ width: '100%' }}>
                    <Typography
                      sx={{
                        color: light.color10,
                        width: '100%',
                        lineHeight: '2',
                      }}
                    >
                      {t('dashboard.kpi.total')}: {total}
                    </Typography>

                    <Box sx={styles.containerTitle}>
                      <Typography
                        sx={{
                          color: light.color13,
                          width: '100%',
                        }}
                      >
                        {t('dashboard.kpi.inProgress')}:
                        {`${paused} (${percentage({
                          a: paused,
                          b: total,
                        })}%)`}
                      </Typography>

                      <Box
                        sx={styles.percentageStatus({
                          color: colorPaused.color,
                        })}
                      />
                    </Box>
                  </Box>
                ),
                total: <></>,
              },
              customeLabels: {
                paused: `${paused} (${percentage({
                  a: paused,
                  b: total,
                })}%)`,
                noStarted: `${noStatrted} (${percentage({
                  a: noStatrted,
                  b: total,
                })}%)`,
                finished: `${finished} (${percentage({
                  a: finished,
                  b: total,
                })}%)`,
                rejected: `${rejected} (${percentage({
                  a: rejected,
                  b: total,
                })}%)`,
                canceled: `${canceled} (${percentage({
                  a: canceled,
                  b: total,
                })}%)`,
                expired: `${expired} (${percentage({
                  a: expired,
                  b: total,
                })}%)`,
              },
              total: total,
              paused: paused,
              noStarted: noStatrted,
              finished: finished,
              rejected: rejected,
              canceled: canceled,
              expired: expired,
            })
          }
        )
        if (!dateToAdd && !currentValueExisted) {
          data.push({
            here: true
          })
        }

        return { data, startDate, endDate, currentValueExisted }
      } catch (e) {
        return {
          data: [],
          startDate: '',
          endDate: '',
          currentValueExisted: true,
        }
      }
    },
  }
}

export { refresh, graphicData, calc30DaysAgo }
