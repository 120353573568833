import { Box, IconButton, Typography } from "@mui/material"
import { useBody } from "./body"
import Loader from "../../../../../../components/loader"
import { useStyles } from "./styles"
import { icons } from "../../../../../../utils/icons"

const ActionPlanResponded = () => {
  const styles = useStyles()
  const { data, loading, getData } = useBody()
  
  return (
    <Box sx={styles.container}>
      {
        !loading ? (
          <>

            <Box sx={styles.actionIndicator}>
              <IconButton
                onClick={() => {
                  getData()
                }}
              >
                {icons('refresh')}
              </IconButton>
            </Box>

            <Box sx={styles.containerActionPlan}>
              <Typography sx={styles.title}>Resp. Planes de Acción</Typography>

              <Typography sx={styles.label}>{data.actionPlan ?? 0} planes de acción Evaluados</Typography>
            </Box>

            <Box sx={styles.containerTime}>
              <Typography sx={styles.time}>{!!data?.time ? data.time : '0m'}</Typography>
            </Box>
          </>
        ) : <Loader />
      }
    </Box>
  )
}

export { ActionPlanResponded }
