import { Box, IconButton, Typography } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { useStyles } from './styles'
import { useBody } from './body'
import { FiltersDrawer } from '../../../../components/filtersDrawer'
import { DinamicForm } from '../../../../components/dinamicForm'
import { useTranslation } from 'react-i18next'
import { icons } from '../../../../utils/icons'

const Data = () => {
  const { t } = useTranslation()
  const {
    loading,
    columns,
    row,
    input,
    errors,
    counter,
    filterData,
    refreshTable,
    onChange,
    cleanFIlters,
  } = useBody()
  const styles = useStyles()

  return (
    <Box sx={styles.container}>
      {/* FLOATS */}
      <FiltersDrawer
        cleanFilter={cleanFIlters}
        count={counter}
        right={0}
        top={50}
      >
        <Box sx={styles.section}>
          <DinamicForm
            errors={errors}
            input={Object.values(input)}
            values={filterData}
            onChange={onChange}
          />
        </Box>
      </FiltersDrawer>

      <Box sx={styles.header}>
        <Typography sx={styles.headerTitle}>
          {t('dashboard.dataSettings.title')}
        </Typography>

        <Box>
          <IconButton
            onClick={() => {
              refreshTable()
            }}
          >
            {icons('refresh')}
          </IconButton>

          {(counter || 0) > 0 && (
            <IconButton
              sx={(styles.clean, (counter || 0) > 0 ? null : styles.hide)}
              onClick={() => {
                cleanFIlters()
              }}
            >
              {icons('clean')}
            </IconButton>
          )}
        </Box>
      </Box>

      <DataGrid
        columns={columns}
        rows={row ?? []}
        getRowId={(row) => row.id}
        autoHeight
        disableVirtualization
        loading={loading}
        slots={{ toolbar: GridToolbar }}
        localeText={{
          columnsPanelTextFieldLabel: 'Buscar',
          columnsPanelTextFieldPlaceholder: 'Buscar',
          columnsPanelHideAllButton: 'Ocultar todas',
          columnsPanelShowAllButton: 'Mostrar todas',
          filterPanelColumns: 'Columnas',
          filterPanelOperator: 'Opciones',
          filterPanelInputLabel: 'Buscar',
          filterPanelInputPlaceholder: 'Buscar',
          filterPanelLogicOperator: '',
          toolbarExportCSV: 'Descargar CSV',
          toolbarExportPrint: 'Imprimir',
          toolbarColumns: 'Columnas',
          toolbarFilters: 'Filtros',
          toolbarDensity: 'Densidad',
          toolbarExport: 'Exportar',
        }}
        disableDensitySelector
        disableColumnMenu
        slotProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true },
            csvOptions: { fileName: t('dashboard.dataSettings.title') },
          },
          filterPanel: {
            logicOperators: [],
            filterFormProps: {
              operatorInputProps: {
                disabled: true,
                sx: { display: 'none' },
              },
            },
          },
          pagination: {
            labelRowsPerPage: '',
          },
        }}
      />
    </Box>
  )
}

export { Data }
