import { Box, Typography, TextField, IconButton } from '@mui/material'
import SimpleButtons from '../../../../components/buttons/simples'
import { Images } from '../../../../components/image'
import { useContextState } from '../../../../hooks/theme'
import { DinamicTypes } from '../../../../utils/types/generals'
import { useStyles } from './styles'
import { Props } from './types'
import { useState } from 'react'
import { icons } from '../../../../utils/icons'

const AuthForm = ({ logo, title, description, onChange, submit, value, button, errors, disabled, isLoading, cancel }: Props) => {
  const { colors } = useContextState()
  const styles = useStyles(colors)
  const [show, setShow] = useState<Record<string, any>>({})

  const handleShow = (type?: DinamicTypes, name?: string) => {
    if (type === 'password') {
      return show[name ?? ''] ? 'text' : 'password'
    }
    return type
  }
  return (
    <Box sx={styles.container}>
      <Images src={logo} sx={styles.imgContain} />

      <Box sx={styles.body}>
        <Box sx={styles.header}>
          {!!title && <Typography sx={styles.title}>{title}</Typography>}

          {!!description && <Typography sx={styles.description}>{description}</Typography>}
        </Box>

        <Box sx={styles.fields}>
          {Object.values(value)?.map((item, index) => (
            <TextField
              key={index}
              type={handleShow(item.type, item.name)}
              sx={styles.inputs}
              name={item.name}
              label={item?.label}
              value={item?.value ?? ''}
              onChange={onChange}
              error={errors?.[item?.name]}
              helperText={errors?.[item?.name]}
              {...(item.type === 'password' && {
                InputProps: {
                  endAdornment: (
                    <IconButton
                      onClick={() =>
                        setShow({
                          ...show,
                          [item.name]: !show[item.name],
                        })
                      }
                    >
                      {icons(!show[item.name] ? 'visibility-off' : 'visibility')}
                    </IconButton>
                  ),
                },
              })}
            />
          ))}

          <SimpleButtons
            autoHeight
            customeStyles
            cancel={cancel}
            accept={{
              label: button,
              click: submit,
              disabled: disabled,
            }}
            isLoading={isLoading}
          />
        </Box>

      </Box>

      <Box sx={styles.rest} />
    </Box>
  )
}

export default AuthForm
