import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useContextState } from '../../../../hooks/theme'
import { useStyles } from './styles'
import {
  useCallback,
  useLayoutEffect,
  useState,
  useContext,
  Fragment,
} from 'react'
import IconButton from '@mui/material/IconButton'
import { icons } from '../../../../utils/icons'
import { Indicators, IndicatorsAreasAndDepartments } from './fragments'
import { Roles } from '../../../../utils/enums/roles'
import { GlobalContext } from '../../../../contexts/Global'
import { useBody } from './body'
import { IndicatorDivisions } from './fragments/indicatorsDivisions'
import { Item } from '../../../../utils/types/generals'
import { listRedirectLeft, listRedirectRight } from './initial'
import { ActionPlans } from './fragments/actionPlans'
import { GlobalScores } from './fragments/globalScores'
import { FoliosIndicators } from './fragments/folios'
import { getUserRole } from '../../../../service/auth'
import { endOfWeek, format, startOfWeek } from 'date-fns'
import { Constants } from '../../../../utils/constants'
import { canAccess } from '../../../../utils/validate/canaccess'
import { BestStoresByStoreRole } from './fragments/top5Stores'
import { ActionPlanResponded } from './fragments/actionPlanResponed'
import { ProgrammedUnits } from './fragments/programmedUnits'
import {
  getArea,
  getDepartments,
  getQuestionnaires,
} from '../../../../utils/methods/geServiceWithItemsFormat'
// import { IndicatorReportFoliosAreas } from './fragments/reportFolios/areas'
// import { IndicatorReportFoliosDepartments } from './fragments/reportFolios/departments'
import { Top15 } from './fragments/top15'

const Dasboard = () => {
  const {
    unitIds,
    divisionsItems,
    currentDate,
    errors,
    filterData,
    zonesAndClass,
    onChange,
    query,
    ...rest
  } = useBody()
  const { colors } = useContextState()
  const styles = useStyles(colors)
  const { t } = useTranslation()
  const [items, setItems] = useState<
    Record<string, Item[] | boolean>
  >({
    areas: [],
    departments: [],
    questionnaire: [],
    areasLoding: true,
    departmentsLoding: true,
  })
  const colorsList: Record<string, Record<string, string>> = {
    primary: {
      1: colors.color16,
      2: colors.color30,
      3: colors.color24,
      4: colors.color8,
      5: colors.color31,
    },
  }
  const { state } = useContext(GlobalContext)
  const { role } = state
  const isPartnerStoreAuditOrAgent = !!canAccess([
    Roles.PARTNER,
    Roles.STORE,
    Roles.AUDIT,
    Roles.AGENT,
  ])

  const getAllAreas = async () => {
    setItems((prev) => ({
      ...prev,
      areasLoding: true
    }))
    const response = await getArea()
    let count = 1
    const areaList = response?.map((item) => {
      const color = colorsList.primary[count]
      if (count < 5) {
        count += 1
      } else {
        count = 1
      }
      return {
        ...item,
        color,
      }
    })
    setItems((prev) => ({
      ...prev,
      areas: areaList,
      areasLoding: false
    }))
  }

  const getAllQuestionnaires = async () => {
    const { items } = await getQuestionnaires()

    setItems((prev) => ({
      ...prev,
      questionnaire: items,
      areasLoding: false
    }))
  }

  const getAllDepartments = async () => {
    setItems((prev) => ({
      ...prev,
      departmentsLoading: true,
    }))
    const response = await getDepartments()

    let count = 1
    const departmentList = response?.map((item) => {
      const color = colorsList.primary[count]
      if (count < 5) {
        count += 1
      } else {
        count = 1
      }
      return {
        ...item,
        color,
      }
    })
    setItems((prev) => ({
      ...prev,
      departments: departmentList,
      departmentsLoading: false,
    }))
  }

  const getAll = useCallback(async () => {
    const methodsRoles: Record<Roles, () => void> = {
      [Roles.ADMIN]: () => {
        getAllAreas()
        getAllDepartments()
        getAllQuestionnaires()
      },
      [Roles.GERENT]: () => {
      },
      [Roles.SUPERVISOR]: () => {
      },
      [Roles.AGENT]: () => { },
      [Roles.SUPPORT]: () => { },
      [Roles.AUDIT]: () => { },
      [Roles.STORE]: () => { },
      [Roles.PARTNER]: () => { },
    }
    const role = getUserRole()
    if (!!role) {
      methodsRoles[role as Roles]()
    }
    // eslint-disable-next-line
  }, [])

  useLayoutEffect(() => {
    getAll()
  }, [getAll])

  const IndicatorFolios = (
    <Grid
      item
      xl={isPartnerStoreAuditOrAgent ? 7 : 12}
      lg={isPartnerStoreAuditOrAgent ? 7 : 12}
      md={12}
      sm={12}
      xs={12}
    >
      <FoliosIndicators />
    </Grid>
  )

  const IndicatorsMobile = () => (
    <Fragment key="IndicatorsMobile">
      <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
        <Grid container sx={{ height: 'calc(100% + 24px)' }} rowSpacing={3}>
          {!!canAccess([Roles.PARTNER, Roles.AUDIT]) && (
            <Grid item xs={12}>
              <GlobalScores />
            </Grid>
          )}

          {!!canAccess([Roles.PARTNER]) && (
            <>
              <Grid item xs={12}>
                <ActionPlans />
              </Grid>
            </>
          )}

          {!!canAccess([Roles.STORE, Roles.AUDIT]) && (
            <Grid item xs={12}>
              <ActionPlanResponded />
            </Grid>
          )}

          {!!canAccess([Roles.AGENT]) && <ProgrammedUnits />}

          {!!canAccess([Roles.STORE]) && (
            <Grid item xs={12}>
              <BestStoresByStoreRole />
            </Grid>
          )}
        </Grid>
      </Grid>

      {IndicatorFolios}
    </Fragment>
  )

  const Header = () => (
    <Grid key="header" item xl={12} lg={12} md={12} sm={12}>
      <Typography sx={styles.title}>
        Reporte General desde{' '}
        <span>
          {format(
            startOfWeek(Date.now(), { weekStartsOn: 1 }),
            Constants.dateFormat
          )}{' '}
          hasta{' '}
          {format(
            endOfWeek(Date.now(), { weekStartsOn: 1 }),
            Constants.dateFormat
          )}
        </span>
      </Typography>
    </Grid>
  )


  const menuTootle = (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      onClose={(e) => {
        rest.closeHref()
      }}
      componentsProps={{
        tooltip: {
          sx: {
            '&.MuiTooltip-tooltip.MuiTooltip-tooltip': {
              marginRight: '-20px',
              maxWidth: 'max-content',
              backgroundColor: `${colors.color9}`
            }
          }
        }
      }}
      open={rest.hrefButton}
      placement="left"
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={(
        <Grid container sx={styles.links} spacing={2}>
          <Grid item xs={6}>
            {
              listRedirectLeft?.map(item => (
                <Typography key={item} sx={styles.tooltipListText} component={'a'} href={`#${item}`}>{t(`dashboard.href.${item}`)}
                </Typography>
              ))
            }
          </Grid>

          <Grid item xs={6}>
            {
              listRedirectRight?.map(item => (
                <Typography key={item} sx={styles.tooltipListText} component={'a'} href={`#${item}`}>{t(`dashboard.href.${item}`)}
                </Typography>
              ))
            }
          </Grid>
        </Grid>
      )}
    >
      <IconButton onClick={() => {
        if (rest.hrefButton) {
          rest.closeHref()
        } else {
          rest.openHref()
        }
      }} sx={styles.menu}>{icons('menu')}</IconButton>
    </Tooltip>
  )
  const graphicsComponents: Record<Roles, JSX.Element[]> = {
    [Roles.ADMIN]: [
      <Indicators key={'Indicators'} divisions={divisionsItems} zoneAndClass={zonesAndClass} units={unitIds} />,
      <IndicatorDivisions
        key={'IndicatorDivisions'}
        loadings={{
          divisions: divisionsItems?.loading as boolean,
          units: unitIds?.loading as boolean,
        }}
        zoneAndClass={zonesAndClass}
        divisionsItems={(divisionsItems?.items || []) as Item[]}
        units={(unitIds?.items || []) as Item[]}
      />,
      <IndicatorsAreasAndDepartments items={items} key={'IndicatorsAreasAndDepartments'} />,
      // <IndicatorReportFoliosAreas key={'IndicatorReportFoliosAreas'} areas={items.areas as Item[]} />,
      // <IndicatorReportFoliosDepartments
      //   key={'IndicatorReportFoliosDepartments'}
      //   department={items.departments as Item[]}
      //   areas={items.areas as Item[]}
      // />,
      <Top15 key={'Top15'} />,
      menuTootle,
    ],
    [Roles.GERENT]: [
      <Indicators key={'Indicators'} divisions={divisionsItems} zoneAndClass={zonesAndClass} units={unitIds} />,
      <Header key="Header" />,
      <IndicatorsMobile key="IndicatorsMobile" />,
      <Top15 key={'Top15'} />,
    ],
    [Roles.SUPERVISOR]: [
      <Indicators key={'Indicators'} divisions={divisionsItems} zoneAndClass={zonesAndClass} units={unitIds} />,
      <Header key="Header" />,
      <IndicatorsMobile key="IndicatorsMobile" />,
      <Top15 key={'Top15'} />,
    ],
    [Roles.AGENT]: [<Header key="Header" />, <IndicatorsMobile key="IndicatorsMobile" />, <Top15 key={'Top15'} />],
    [Roles.SUPPORT]: [<Header key="Header" />, <IndicatorsMobile key="IndicatorsMobile" />, <Top15 key={'Top15'} />],
    [Roles.AUDIT]: [<Header key="Header" />, <IndicatorsMobile key="IndicatorsMobile" />],
    [Roles.STORE]: [<Header key="Header" />, <IndicatorsMobile key="IndicatorsMobile" />],
    [Roles.PARTNER]: [<Header key="Header" />, <IndicatorsMobile key="IndicatorsMobile" />],
  }

  const renderByRole = () => {
    if (!!role) {
      return graphicsComponents?.[role as Roles]
    }
    return (
      <Grid key="dateReporting" item xl={12} lg={12} md={12} sm={12}>
        <Typography sx={styles.title}>No Tienes Acceso</Typography>
      </Grid>
    )
  }

  return (
    <Box key="Dasboard" sx={styles.container} >
      <Grid container spacing={3}>
        {renderByRole()}
      </Grid>
    </Box>
  )
}

export default Dasboard
