import { useContextState } from "../../../../../../../hooks/theme"
import { PercentageStatus } from "../../../fragments/indicators/typing/enums"
import { percentageColors } from "../../../fragments/methods/statusColors"

const useStyles = () => {
    const { colors } = useContextState()

    return {
        container: {
            padding: '10px',
            background: colors.color25,
            display: "flex",
            alignItems: "center",
            gap: "10px",
            borderRadius: "5px"
        },
        status: ({ type }: { type: PercentageStatus }) => ({
            width: "8px",
            height: "8px",
            borderRadius: '50%',
            background: percentageColors[type].color
        }),
        containerDescription: {
        },
        subtitle: {
            fontSize: ".8rem"
        }
    }
}

export { useStyles }
