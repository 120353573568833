import { format } from 'date-fns'
import { TFunction } from 'react-i18next'
import { getStatus } from '../../../../hooks/status'
import { areaService } from '../../../../service/area'
import { Area, ResponseArea } from '../../../../service/area/response'
import { Constants } from '../../../../utils/constants'
import { httpFetch } from '../../../../utils/crud'
import { Status, Types } from '../../../../utils/enums'
import { handleAllErrors } from '../../../../utils/methods/handleErrors'

import { Color } from '../../../../utils/theme/types/color'
import { ObjectGeneric } from '../../../../utils/types/generals'
import { isEmail, isRequired, searchInModeLower, isPhoneNumber } from '../../../../utils/validate/validate'

export const getAreaList = async (colors: Color, t: TFunction<'translation', undefined>) => {
  const data = await areaService(httpFetch).getAllAreas()

  const column: any[] = [
    {
      id: 'createTime',
      label: t('general.tags.createTime'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'name',
      label: t('general.tags.area'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'department',
      label: t('general.tags.state'),
      minWidth: 100,
    },
    {
      id: 'email',
      label: t('general.tags.email'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'phone',
      label: t('general.tags.number'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'status',
      label: t('general.tags.status'),
      sortable: true,
      minWidth: 100,
    },
  ]

  const row: any[] = !data?.errors
    ? data?.map((item: Area) => {
        let status: Status = item?.statusId || 1

        return {
          id: item.id,
          email: item?.email,
          phone: !!item.phone?.includes('+52') ? item.phone : '',
          name: item.name,
          createTime: {
            label: item.createTime ? format(new Date(item.createTime), Constants.dateFormatGeneral) : 'N/A',
            value: item.createTime ? new Date(item.createTime).getTime() : 0,
            type: 'date',
          },
          departments: item.departments,
          status: getStatus(status, colors, t),
          userManage: item.userManage,
          responsible: `${item.userManage?.firstName} ${item.userManage?.lastName}`,
          departmentsPDF: item.departments?.map((e) => e.name)?.join(', '),
          isAreaWithConfig: !!item?.isAreaWithConfig,
        }
      })
    : []

  return { column, row }
}

export const filterRow = (value: { [x: string]: any }, list: any[]) => {
  const from = value.from?.valueOf()
  const to = value.to?.valueOf()

  return list.filter((item) => {
    return (
      (from ? new Date(from).setHours(0, 0, 0, 0) <= item.createTime?.value : true) &&
      (to ? new Date(to).setHours(23, 59, 59, 999) >= item.createTime?.value : true) &&
      (searchInModeLower(item?.name, value?.search) ||
        searchInModeLower(item?.email, value?.search) ||
        searchInModeLower(item?.phone, value?.search) ||
        item?.departments?.find((e: any) =>
          e.name
            ?.toString()
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(
              value?.search
                ?.toString()
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
            )
        )) &&
      (value?.users instanceof Array ? (value?.users.length > 0 ? value?.users.find((e: any) => e.label.toString().includes(`${item?.userManage?.firstName} ${item?.userManage?.lastName}`)) : true) : true) &&
      (value?.states instanceof Array ? (value?.states.length > 0 ? item.departments.some((e1: any) => value?.states.some((e2: any) => e2.value == e1.id)) : true) : true)
    )
  })
}

export const handleErrors = (values: any, t: any, ignore?: string[]) => {
  let error = {} as ObjectGeneric

  if (values?.name !== null) {
    if (isRequired(values?.name)) error.name = t('general.validations.required')
  }

  if (values?.email !== null && values?.email !== '') {
    if (isRequired(values?.email)) {
      error.email = t('general.validations.required')
    } else if (values?.email && !isEmail(values?.email)) {
      error.email = t('general.validations.wrongEmail')
    }
  }

  if (values?.phone !== null) {
    if (values?.phone?.length > 3 && !isPhoneNumber(values?.phone)) error.phone = `${t('general.validations.min')} 10 ${t('general.validations.character')}`
  }

  return error
}

export const submit = async (data: ObjectGeneric, t: TFunction<'translation', undefined>, id: number | undefined) => {
  let snackbarBody = {
    open: false,
    type: '',
    message: '',
  }

  const { name, phone, departments, responsible, email } = data
  let body = {} as any

  body = {
    name,
    description: null,
    phone,
    email: email ?? '',
    userManageId: responsible,
    statusId: 1,
    departments: departments?.map((e: any) => e.value),
  }

  let response: ResponseArea

  if (id) {
    response = await areaService(httpFetch).updateArea(body, id)
  } else {
    response = await areaService(httpFetch).createArea(body)
  }

  if (response.errors) {
    snackbarBody = {
      open: true,
      type: Types.ERROR as string,
      message: handleAllErrors(response?.errors?.[0]?.code ?? '', t, `area.snackbar.${id ? 'update' : 'create'}.error`),
    }
  } else {
    snackbarBody = {
      open: true,
      type: Types.SUCCESS as string,
      message: t(`area.snackbar.${id ? 'update' : 'create'}.success`),
    }
  }

  return {
    snackbarBody,
    response,
  }
}
