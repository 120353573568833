const cakeSetting = {
    w: 200,
    h: 120,
    noDataH: '110px',
    noDataW: '110px',
    outerRadius: 50,
    cx: 100,
    cy: 60
}

export { cakeSetting }
