import { Box, BoxProps, styled } from '@mui/material'
import { useContextState } from '../../hooks/theme'

const CardShadow = styled((props: BoxProps) => <Box {...props} />)(() => {
    const { colors } = useContextState()
    return ({
        boxShadow: `0px 0px 4px ${colors.color13}`,
        borderRadius: '20px'
    })
})

export { CardShadow }
