export const useStyles = () => {
  return ({
    containerSuccess: ({isSelected}:{isSelected: boolean}) => ({
      padding: '20px 10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& .crud-filter-container': {
        paddingTop: '10px',
      },
      '& .table-container': {
        overflowY: 'auto',
        maxHeight: '360px',
        '& table': {
          position: 'relative'
        },
      },
      ...!!isSelected && {
        '& .table-parent': {
          width: 'calc(100% + 30px)',
          marginLeft: '-30px'
        }
      }
    }),
    container: {
      padding: '10px 20px 0 20px',
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      '& .filterContainer-main': {
        paddingLeft: '25px'
      }
    },
    search: {
      width: '200px',
      marginRight: '30px'
    },
    closeSuccess: {
      position: 'absolute',
      right: '10px',
      top: '15px',
    },
    button: {
      borderRadius: 0,
      width: 'fit-content',
      alignSelf: 'end',
      marginTop: '20px'
    },
    filter: {
      width: '200px'
    },
    title: {
      fontSize: "1.4rem",
      fontWeight: '500'
    },
    header: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px'
    },
  })
}
