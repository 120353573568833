import { TFunction } from "react-i18next"
import { InputModel } from "../../../../utils/types"
import { GridColDef } from "@mui/x-data-grid"
import StatusComponent from "../../../../components/status"
import { getStatus } from "../../../../hooks/status"
import { Color } from "../../../../utils/theme/types/color"
import { Status } from "../../../../utils/enums"

const useModelInput = () => {
    return {
        createYear: {
            label: 'general.filterDate.year',
            translate: true,
            name: 'createYear',
            type: 'filter-select',
            items: [],
            value: null,
            size: {
                xl: 6,
                lg: 6,
                md: 6,
                sm: 6,
                xs: 6,
            },
        },
        month: {
            label: 'general.filterDate.month',
            translate: true,
            name: 'month',
            type: 'filter-select',
            items: [],
            value: null,
            size: {
                xl: 6,
                lg: 6,
                md: 6,
                sm: 6,
                xs: 6,
            },
        },
        from: {
            label: 'general.tags.from',
            translate: true,
            name: 'from',
            type: 'date',
            value: null,
            rest: {
                max: 'to',
            },
            size: {
                xl: 6,
                lg: 6,
                md: 6,
                sm: 6,
                xs: 6,
            },
        },
        to: {
            label: 'general.tags.to',
            translate: true,
            name: 'to',
            type: 'date',
            value: null,
            rest: {
                min: 'from',
            },
            size: {
                xl: 6,
                lg: 6,
                md: 6,
                sm: 6,
                xs: 6,
            },
        },
        area: {
            label: 'general.tags.area',
            translate: true,
            name: 'area',
            type: 'filter-select',
            items: [],
            value: null,
        },
        department: {
            label: 'general.tags.department',
            translate: true,
            name: 'department',
            type: 'filter-select',
            items: [],
            value: [],
        },
        questionnaire: {
            label: 'general.tags.questionnaire',
            translate: true,
            name: 'questionnaire',
            type: 'filter-select',
            items: [],
            value: [],
        },
        questionnaireStatus: {
            label: 'general.tags.questionnaireStatus',
            translate: true,
            name: 'questionnaireStatus',
            type: 'filter-select',
            items: [],
            value: [],
        },
        percentage: {
            label: 'general.tags.percentage',
            translate: true,
            name: 'percentage',
            type: 'percentage',
            value: null,
        },
        userType: {
            label: 'general.tags.userType',
            translate: true,
            name: 'userType',
            type: 'filter-select',
            items: [],
            value: [],
        },
        user: {
            label: 'general.tags.user',
            translate: true,
            name: 'user',
            type: 'filter-select',
            items: [],
            value: null,
        },
        role: {
            label: 'general.tags.role',
            translate: true,
            name: 'role',
            type: 'filter-select',
            items: [],
            value: [],
        },
        division: {
            label: 'general.tags.division',
            translate: true,
            name: 'division',
            type: 'filter-select',
            items: [],
            value: null,
            size: {
                xl: 12,
                lg: 12,
                md: 12,
                sm: 12,
            },
        },
        unit: {
            label: 'general.tags.unit',
            translate: true,
            name: 'unit',
            type: 'filter-select',
            items: [],
            value: null,
            size: {
                xl: 12,
                lg: 12,
                md: 12,
                sm: 12,
            },
        },
        zone: {
            label: 'general.tags.zone',
            translate: true,
            name: 'zone',
            type: 'filter-select',
            items: [],
            value: null,
            size: {
                xl: 12,
                lg: 12,
                md: 12,
                sm: 12,
            },
        },
        child: {
            label: 'general.tags.class',
            translate: true,
            name: 'child',
            type: 'filter-select',
            items: [],
            value: null,
            size: {
                xl: 12,
                lg: 12,
                md: 12,
                sm: 12,
            },
        },
    } as { [x: string]: InputModel }
}

const columsTranslate = ({ t, colors }: { t: TFunction<"translation", undefined>; colors: Color }): GridColDef[] => ([
    {
        field: 'year',
        headerName: t('dashboard.data.year'),
    },
    {
        field: 'month',
        headerName: t('dashboard.data.month'),
    },
    {
        field: 'week',
        headerName: t('dashboard.data.week'),
    },
    {
        field: 'unitId',
        headerName: t('dashboard.data.unitId'),
        width: 180,
    },
    {
        field: 'unit',
        headerName: t('dashboard.data.unit'),
        width: 180,
    },
    {
        field: 'country',
        headerName: t('dashboard.data.country'),
    },
    {
        field: 'state',
        headerName: t('dashboard.data.state'),
        width: 180,
    },
    {
        field: 'city',
        headerName: t('dashboard.data.city'),
        width: 180,
    },
    {
        field: 'zone',
        headerName: t('dashboard.data.zone'),
        width: 180,
    },
    {
        field: 'class',
        headerName: t('dashboard.data.class'),
        width: 180,
    },
    {
        field: 'division',
        headerName: t('dashboard.data.division'),
        width: 180,
    },
    {
        field: 'cve',
        headerName: t('dashboard.data.cve'),
        width: 180,
    },
    {
        field: 'role',
        headerName: t('dashboard.data.role'),
    },
    {
        field: 'userType',
        headerName: t('dashboard.data.userType'),
        width: 180,
    },
    {
        field: 'curp',
        headerName: t('dashboard.data.curp'),
        width: 180,
    },
    {
        field: 'user',
        headerName: t('dashboard.data.user'),
        width: 180,
    },
    {
        field: 'questionnaire',
        headerName: t('dashboard.data.questionnaire'),
        width: 180,
    },
    {
        field: 'area',
        headerName: t('dashboard.data.area'),
        width: 180,
    },
    {
        field: 'department',
        headerName: t('dashboard.data.department'),
        width: 180,
    },
    {
        field: 'questionnaire_status_id',
        headerName: t('dashboard.data.questionnaire_status'),
        renderCell: (row) => {
            const statusQuestionnaire = getStatus(row.value, colors, t)
            return (
                <StatusComponent
                    color={statusQuestionnaire?.color}
                    msg={statusQuestionnaire?.text}
                    size="small"
                />
            )
        },
        width: 180,
    },
    {
        field: 'start_date',
        headerName: t('dashboard.data.start_date'),
        width: 180,
    },
    {
        field: 'start_hour',
        headerName: t('dashboard.data.start_hour'),
        width: 180,
    },
    {
        field: 'end_date',
        headerName: t('dashboard.data.end_date'),
        width: 180,
    },
    {
        field: 'end_hour',
        headerName: t('dashboard.data.end_hour'),
        width: 180,
    },
    {
        field: 'application_time',
        headerName: t('dashboard.data.application_time'),
        width: 180,
    },
    {
        field: 'result',
        headerName: t('dashboard.data.result'),
    },
])

const statusList = [
    Status.TO_BE_STARTED,
    Status.STARTED,
    Status.PAUSE,
    Status.EXPIRED,
    Status.INCOMPLETE,
    Status.FINISHED,
    Status.CANCEL,
    Status.WAITING
]
export { useModelInput, statusList, columsTranslate }