import { format } from "date-fns"
import { UnitsActiveData } from "../../../../service/dashboard/response"
import { FilterData } from "./typing/interfaces"
import { Constants } from "../../../../utils/constants"
import { calc30DaysAgo } from "../../../dashboard/pages/main/fragments/indicators/methods/callEndpoints"
import { TableData } from "./typing/type"

const UnitDataFilters = (payload?: TableData) => {
    let filters = []

    if (!!payload?.month?.length) {
        filters.push({ terms: { mes: payload?.month } })
    }
    if (!!payload?.years?.length) {
        filters.push({ terms: { ano: payload?.years } })
    }
    if (!!payload?.unit?.length) {
        filters.push({ terms: { unidad_id: payload?.unit } })
    }
    if (!!payload?.zone?.length) {
        filters.push({ terms: { zona_id: payload?.zone } })
    }
    if (!!payload?.division?.length) {
        filters.push({ terms: { division_id: payload?.division } })
    }
    if (!!payload?.role?.length) {
        filters.push({ terms: { rol_id: payload?.role } })
    }
    if (!!payload?.userType?.length) {
        filters.push({ terms: { tipo_usuario_id: payload?.userType } })
    }
    if (!!payload?.curp?.length) {
        filters.push({ terms: { curp: payload?.curp } })
    }
    if (!!payload?.questionnaire?.length) {
        filters.push({ terms: { cuestionario_id: payload?.questionnaire } })
    }
    if (!!payload?.questionnaireStatus?.length) {
        filters.push({ terms: { questionario_estatus_id: payload?.questionnaireStatus } })
    }
    if (!!payload?.area?.length) {
        filters.push({ terms: { area_id: payload?.area } })
    }
    if (!!payload?.department?.length) {
        filters.push({ terms: { departamento_id: payload?.department } })
    }
    if (!!payload?.percentage?.length) {
        filters.push({
            range: {
                resultado: {
                    gte: payload?.percentage[0],
                    lte: payload?.percentage[1],
                },
            },
        })
    }

    if (!!payload?.to && !!payload?.from) {
        filters.push({
            range: {
                fecha_inicio_full: {
                    gte: payload?.from,
                    lte: payload?.to,
                },
            },
        })
    }

    return { filters }
}

const formatResponsUnitData = (response: UnitsActiveData) => {
    const hits =
        response?.hits?.hits?.map((item) => {
            const source = item?._source
            const satartHour = format(new Date(source.fecha_inicio_full), Constants.hourFormat)
            const endtHour = format(new Date(source.fecha_fin_full), Constants.hourFormat)
            return {
                id: item?._id,
                year: source?.ano,
                month: source?.mes,
                week: source?.semana,
                unitId: source?.unidad_id,
                unit: source?.unidad,
                country: source?.pais,
                state: source?.estado,
                city: source?.ciudad,
                city_id: source?.ciudad_id,
                zone_id: source?.zona_id,
                zone: source?.zona,
                class: source?.clase,
                division: source?.division,
                division_id: source?.division_id,
                cve: source?.cve,
                role: source?.rol,
                role_id: source?.rol_id,
                userType: source?.tipo_usuario,
                user_type_id: source?.tipo_usuario_id,
                curp: source?.curp,
                user: source?.usuario,
                user_id: source?.usuario_id,
                questionnaire: source?.cuestionario,
                qestionnaire_id: source?.cuestionario_id,
                questionnaire_status: source?.questionario_estatus,
                questionnaire_status_id: source?.questionario_estatus_id,
                area: source?.area,
                area_id: source?.area_id,
                department: source?.departamento,
                department_id: source?.departamento_id,
                start_date: source?.fecha_inicio,
                start_hour: satartHour,
                end_date: source?.fecha_fin,
                end_hour: endtHour,
                application_time: source?.tiempo_aplicacion,
                result: source?.resultado,
            }
        }) ?? []

    return { hits, }
}

const formatDataFiltered = ({ filterData }: { filterData: FilterData }) => {
    const {
        unit,
        role,
        division,
        user,
        userType,
        questionnaire,
        month,
        createYear,
        area,
        department,
        zone,
        child,
        from,
        to,
        questionnaireStatus,
        percentage
    } = filterData
    const units = unit?.map((item) => item.keyCode)
    const roles = role?.map((item) => item.value)
    const divisions = division?.map((item) => item.value)
    const users = user?.map((item) => item.curp)
    const userTypes = userType?.map((item) => item.value)
    const questionnaires = questionnaire?.map((item) => item.value)
    const questionnaireStatusIds = questionnaireStatus?.map((item) => item.value)
    const months = month?.map((item) => item.label)
    const years = createYear?.map((item) => item.value)
    const areas = area?.map((item) => item.value)
    const departments = department?.map((item) => item.value)
    const zones = [
        ...(zone?.map((item) => item.value) ?? []),
        ...(child?.map((item) => item.value) ?? []),
    ]
    let end, start

    if (!!to || !!from) {
        const { endDate, startDate } = calc30DaysAgo({
            start: from,
            end: to,
        })
        end = endDate
        start = startDate
    }

    return {
        data: {
            from: start,
            to: end,
            area: areas,
            curp: users,
            department: departments,
            division: divisions,
            questionnaire: questionnaires,
            questionnaireStatus: questionnaireStatusIds,
            role: roles,
            unit: units,
            userType: userTypes,
            zone: zones,
            month: months,
            years,
            percentage
        }
    }
}

export { UnitDataFilters, formatResponsUnitData, formatDataFiltered }
