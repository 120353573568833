import { Box, Drawer, Grid, Typography } from "@mui/material";
import { ButtonsDrawer } from "../../components/buttons/buttonDrawer";
import { EvidencesTypeEnum } from "../../utils/enums/evidences";
import { DynamicFormRef } from "../../components/dynamicFormRef";
import CustomAccordion from "../../components/accordion";
import { DinamicFormRefValues } from "../../components/dynamicFormRef/types";
import { useEffect, useState } from "react";
import { DrawerEvidencesProps, EvidenceData } from "./typyng";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { evidenceDefaultValues, useDrawerInputs } from "./useEvidences";

const DrawerEvidences = ({ refsByKey, setRef, ...props }: DrawerEvidencesProps) => {
  const styles = useStyles()
  const { model } = useDrawerInputs()
  const { t } = useTranslation()
  const [expand, setExpand] = useState<Record<string, boolean>>({})
  const [data, setData] = useState<EvidenceData | undefined>()
  const [existCheck, setExistCheck] = useState<Record<string, boolean>>({})
  const cancel = () => {
    setExpand({})
    props.cancel()
    setData(undefined)
  }
  const save = () => {
    let existSomeError = false
    Object.entries(expand).forEach(([key, value]) => {
      if (value) {
        const refInput = refsByKey?.[`${key}-input`]

        if (!existSomeError) {
          const inputErrors = Object.values(refInput?.getErrors() ?? {})?.length

          if (!!inputErrors || !!existCheck?.[key]) { existSomeError = true }
        }
      }
    })
    if (!existSomeError) {
      setExpand({})
      props.save(data)
      setData(undefined)
    }
  }

  const addData = ({ key, name, value }: { name: string; value: DinamicFormRefValues; key: string }) => {
    if (name == 'active') {

      const defaultValues = evidenceDefaultValues({ active: !!value })
      const newValues = { ...defaultValues?.[key as EvidencesTypeEnum] }

      refsByKey?.[`${key}-header`]?.setValues({ name: 'required', value: true })
      refsByKey?.[`${key}-check`]?.reset(newValues)
      refsByKey?.[`${key}-input`]?.reset(newValues)

      if (!!value) {
        setData(prev => ({ ...prev, [key]: { ...prev?.[key] ?? {}, ...newValues } }))
      } else {
        setExistCheck(prev => ({ ...prev, [key]: false }))
        setData(prev => ({ ...prev, [key]: undefined }))
      }
      setExpand(prev => ({ ...prev, [key]: !!value }))
    } else {
      const check = Object.values(model[key]?.check ?? {})

      if (!!check?.length) {
        const newData = { ...data, [key]: { ...data?.[key] ?? {}, [name]: value } }
        const existCheck = !Object.values(model[key]?.check ?? {})?.some(item => !!newData?.[key]?.[item?.name ?? ''])
        setExistCheck(prev => ({ ...prev, [key]: existCheck }))
      }
      setData(prev => ({ ...prev, [key]: { ...prev?.[key] ?? {}, [name]: value } }))
    }

  }

  useEffect(() => {
    if (!!props.open) {
      for (const [key, value] of Object.entries({ ...props.defaultValues } ?? {})) {
        setExpand(prev => ({ ...prev, [key]: !!value?.active }))
      }

      setData(props.defaultValues)
    }
  }, [props.defaultValues, props.open])

  return (
    <Drawer
      sx={styles.drawer}
      anchor="right"
      open={props.open}
    >
      <Typography sx={styles.titleEvidences}>Configuración de evidencias adjuntas</Typography>

      {
        Object.entries(model).map(([key, value]) => (
          <CustomAccordion
            key={key}
            expanded={!!expand[key]}
            expandIcon={null}
            title={<DynamicFormRef
              ref={element => {
                setRef(element, `${key}-header`)
                return refsByKey[`${key}-header`]
              }}
              styleSx={{ width: "100%" }}
              removeForm
              onChange={(payload) => {
                const name = payload?.current as string
                addData({ key, name, value: payload?.[name] as DinamicFormRefValues })
              }}
              defaultValues={props.defaultValues?.[key]}
              model={{
                ...value?.header,
                required: !!expand[key] ? value?.header.required : null
              }}
              parentWrapper={({ children }) => <Grid sx={styles.labels} container justifyContent="space-between" alignItems="center" children={children} />}
            />}
            description={
              (
                <>
                  {!!value?.check && (
                    <DynamicFormRef
                      ref={element => {
                        setRef(element, `${key}-check`)
                        return refsByKey[`${key}-check`]
                      }}
                      onChange={(payload) => {
                        const name = payload?.current as string
                        addData({ key, name, value: payload?.[name] as DinamicFormRefValues })
                      }}
                      removeForm
                      model={value?.check}
                      defaultValues={props.defaultValues?.[key]}
                      parentWrapper={({ children }) => (
                        <Box>
                          {
                            !!existCheck?.[key] && (
                              <Typography sx={styles.noSelected}>
                                <span>*</span> {t('general.validations.selectACheck')}
                              </Typography>
                            )
                          }

                          <Grid sx={styles.labels} container justifyContent="center" alignItems="center" children={children} />
                        </Box>
                      )}
                    />
                  )}

                  <DynamicFormRef
                    ref={element => {
                      setRef(element, `${key}-input`)
                      return refsByKey[`${key}-input`]
                    }}
                    onChange={(payload) => {
                      const name = payload?.current as string
                      addData({ key, name, value: payload?.[name] as DinamicFormRefValues })
                    }}
                    removeForm
                    model={value?.input}
                    defaultValues={props.defaultValues?.[key]}
                    parentWrapper={({ children }) => <Grid sx={styles.labels} container columnSpacing={1} justifyContent="center" alignItems="center" children={children} />}
                  />
                </>
              )
            }
          />
        ))
      }

      <ButtonsDrawer
        accept={{
          title: 'GUARDAR',
          onClick: () => { save() },
        }}
        cancel={{
          title: t('general.cancel'),
          onClick: () => { cancel() },
        }}
      />
    </Drawer>
  )
}

export { DrawerEvidences }