import { Box, IconButton, Typography } from "@mui/material"
import { useStyles } from "./styles"
import { ItemNotifyProps } from "./types"
import { icons } from "../../../../../utils/icons"

const NotificationBell = (props: ItemNotifyProps) => {
  const styles = useStyles({ type: props.type })

  return (
    <Box sx={styles.container}>
      <Box sx={styles.statusRounded} />

      <Box sx={styles.containerActions}>
        <Typography sx={styles.date}>{props.date}</Typography>

        <IconButton onClick={() => {props.remove()}} sx={styles.close}>{icons('simple-close')}</IconButton>
      </Box>

      <Typography sx={styles.title}>{props.title}</Typography>

      <Typography sx={styles.description}>{props.description}</Typography>
    </Box>
  )
}

export { NotificationBell }
