import { Box, FormControlLabel, Grid, } from '@mui/material'
import { BaseInput, CustomSelect, Images } from '../../../../../components'
import AddImage from '../../../../../components/addImage'
import MultiSelect from '../../../../../components/dinamicForm/multiSelect'
import MultiSelectSimple from '../../../../../components/dinamicForm/multiSelectSimple'
import Loader from '../../../../../components/loader'
import { Field } from '../../../../../utils/enums/fields'
import { images } from '../../../../../utils/images'
import { isNumber } from '../../../../../utils/validate/validate'
import { gender, username } from '../initialState'
import { useStyles } from './styles'
import { IOSSwitch } from '../../../../../components/dinamicForm/switch'
import { canListUnits } from '../methods'
import { useTranslation } from 'react-i18next'
import { Item } from '../../../../../utils/types/generals'

const Form = ({ btnLoading, data, crudChange, allErrors, disabledUserName, rolesList, storeList, agentList, userTypeList, companyList, divisionCompani }: any) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const permissions = canListUnits({ role: data?.role?.value })
  const listSelectAgentOrStore = {
    errors: allErrors,
    name: data?.partnerStore?.name,
    label: t(permissions.label),
    onChange: crudChange,
    items: !permissions.store ? agentList : storeList,
    selected: data?.partnerStore?.value,
  }
  
  return btnLoading ? (
    <Box sx={styles.constainer}>
      <Loader />
    </Box>
  ) : (
    <Grid container rowSpacing={2.5} columnSpacing={1}>
      <Grid container item lg={12} justifyContent="center">
        <AddImage
          icon={<Images sx={styles.img} src={images.profileBtn} />}
          image={data?.picture?.value}
          name={data?.picture?.name}
          label={data?.picture?.label}
          size={{
            width: '120px',
            height: '120px',
          }}
          getFile={crudChange}
        />
      </Grid>
      <Grid item lg={10}>
        <BaseInput
          name={data?.curp?.name}
          type="alphaNumeric"
          value={{
            curp: data?.curp,
          }}
          onChange={crudChange}
          errors={allErrors}
          inputProps={{
            maxLength: 19,
          }}
        />
      </Grid>
      <Grid item lg={12}>
        <BaseInput
          name={data?.firstName?.name}
          type="text"
          value={{
            firstName: data?.firstName,
          }}
          onChange={crudChange}
          errors={allErrors}
          inputProps={{
            maxLength: 100,
          }}
        />
      </Grid>
      <Grid item lg={12}>
        <BaseInput
          name={data?.lastName?.name}
          type="text"
          value={{
            lastName: data?.lastName,
          }}
          onChange={crudChange}
          errors={allErrors}
          inputProps={{
            maxLength: 100,
          }}
        />
      </Grid>
      <Grid item lg={12}>
        <CustomSelect disabled name={data?.username?.name} label={`${data?.username?.label}`} onChange={crudChange} items={username} selected={data?.username?.value} />
      </Grid>
      <Grid item lg={12}>
        <BaseInput
          name={data?.email?.name}
          value={{
            email: {
              value: data?.email?.value,
              label: `${data?.email?.label} ${data.username.value === '0' ? '*' : ''}`,
            },
          }}
          onChange={crudChange}
          disabled={disabledUserName === Field.EMAIL}
          errors={allErrors}
          inputProps={{
            maxLength: 150,
          }}
        />
      </Grid>
      <Grid item lg={12}>
        <BaseInput
          name={data?.phone?.name}
          value={{
            phone: {
              value: data?.phone?.value || '+52',
              label: `${data?.phone?.label} ${data.username.value === '1' ? '*' : ''}`,
            },
          }}
          onChange={({ target }) => {
            const { name, value } = target
            if (isNumber(value.replace('+', '')) && value !== '-' && value > 0) {
              if (value.includes('+52')) {
                crudChange({
                  target: {
                    name: name,
                    value: value.length <= 3 ? '+52' : value,
                  },
                })
              }
            }
          }}
          errors={allErrors}
        />
      </Grid>
      <Grid item lg={12}>
        <MultiSelectSimple errors={allErrors} name={data.companyId.name} label={data.companyId.label} onChange={crudChange} items={companyList ?? []} selected={data.companyId.value} />
      </Grid>
      <Grid item lg={12}>
        <MultiSelectSimple
          name={data.divisions.name}
          label={data.divisions.label}
          onChange={crudChange}
          items={divisionCompani ?? []}
          selected={data.divisions.value}
        />
      </Grid>
      <Grid item lg={12}>
        <MultiSelectSimple errors={allErrors} name={data.userType.name} label={data.userType.label} onChange={crudChange} items={userTypeList ?? []} selected={data.userType.value} />
      </Grid>
      <Grid item lg={6}>
        <CustomSelect errors={allErrors} isRequired name={data?.gender?.name} label={`${data?.gender?.label}`} onChange={crudChange} items={gender} selected={data?.gender?.value} />
      </Grid>
      <Grid item lg={6}>
        <CustomSelect errors={allErrors} isRequired name={data?.role?.name} label={`${data?.role?.label}`} onChange={crudChange} items={rolesList} selected={data?.role?.value} />
      </Grid>
      {!!permissions.isRoleStore && (
        <Grid item lg={12}>
          <MultiSelect errors={allErrors} selected={data?.agents?.value} onChange={crudChange} items={agentList as Item[]} name='agents' label={t(`general.tags.agentList`)}/>
        </Grid>
      )}

      {!!data?.role?.value && (
        <Grid item lg={12}>
          {permissions.typeSelect === 'select' ? <MultiSelectSimple {...listSelectAgentOrStore} /> : <MultiSelect {...listSelectAgentOrStore} />}
        </Grid>
      )}
      <Grid item lg={12}>
        <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
          control={
            <IOSSwitch
              name="accessFolioReportGeneral"
              checked={data?.accessFolioReportGeneral?.value}
              onChange={({ target }) => {
                crudChange({ target: { name: target.name, value: target.checked } })
              }}
              sx={{ m: 2 }}
            />
          }
          label={data?.accessFolioReportGeneral?.label}
        />
      </Grid>
    </Grid>
  )
}

export default Form
