const useStyles = () => {
  return {
    modal: {
      width: '90vw',
      maxWidth: "1100px",
      height: "90vh",
      maxHeight: "700px",
      position: "relative",
      padding: "00px 0px 0px"
    },
    close: {
      fontSize: '2rem'
    }
  }
}

export { useStyles }
