import { Box, Typography, Button } from '@mui/material'
import { useContext } from 'react'
import { useStyles } from './styles'
import { GlobalContext } from '../../../../../../../../contexts/Global'

const ButtonsOption = (props: { approveFolio: () => void; rejectReportFolio: () => void; title: string; approve: string; reject: string }) => {
  const { colors } = useContext(GlobalContext)
  const styles = useStyles({ color: colors })

  return (
    <Box sx={styles.buttonOptions}>
      <Typography sx={styles.optionTitle}>{props?.title}</Typography>

      <Box sx={styles.conteinrButtons}>
        <Button
          onClick={() => {
            props?.approveFolio()
          }}
          variant="contained"
          sx={[styles.buttons, styles.buttonColorB]}
        >
          {props?.approve}
        </Button>

        <Button
          onClick={() => {
            props?.rejectReportFolio()
          }}
          variant="contained"
          sx={[styles.buttons, styles.buttonColorR]}
        >
          {props?.reject}
        </Button>
      </Box>
    </Box>
  )
}

export { ButtonsOption }
