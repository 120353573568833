import { ThemeProvider } from '@mui/material'
import { Router } from './routes'
import { useTheme } from './utils/theme'
import './locale/index'
import './utils/theme/format.css'
import { Amplify } from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css';
import {configureAws} from './utils/constants/aws-amplify'
import { ContextProvider } from './contexts/Global'
import 'dayjs/locale/es';

Amplify.configure(configureAws)

function App() {
  const {theme, color} = useTheme()
  
  return (
    <ContextProvider colors={color}>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </ContextProvider>
  )
}

export default App
