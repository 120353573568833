import { Box, Grid, Icon, Tooltip, Typography } from "@mui/material"
import { icons } from "../../../../../../../../utils/icons"
import { useUserAssigned } from "./userAssignedStyles"

const UserAssigned = (props: any) => {
    const styles = useUserAssigned()
    let usersAssigned = props?.current
    return (
      <Grid
        container
        justifyContent="left"
        alignItems="center"
        columnSpacing={1}
      >
        <Grid
          item
          sx={{ color: '#828282' }}
          xl={10}
          lg={10}
          md={10}
          sm={10}
          xs={10}
        >
          <Typography sx={styles.textItem}>
            {usersAssigned.length ? (
              <span>{usersAssigned[0].assignFullName}</span>
            ) : (
              'N/A'
            )}
          </Typography>
        </Grid>
        {!!(usersAssigned.length && usersAssigned.length > 1) && (
          <Grid
            sx={styles.containerIconTooltip}
            item
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <Tooltip
              title={
                <Box>
                  <Box sx={styles.containerTooltipAsk}>
                    <Box sx={styles.listAnswers}>
                      {usersAssigned?.map((itemO: any) => (
                        <Typography sx={styles.textItemTooltip}>
                          {itemO?.assignFullName}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </Box>
              }
            >
              <Icon sx={styles.viewMore}>{icons('add')}</Icon>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    )
  }

export { UserAssigned }
