enum RedirectedIds {
  INDICATORS_UNITS = "indicators-units",
  INDICATORS_PROGRAMATIONS = "indicators-programations",
  INDICATORS_ACTION_PLAN = "indicators-action-plan",
  PROGRESS_INDICATORS = "indicators-progress",
  FOLIOS_AREAS = "folios-areas",
  FOLIOS_DEPARTMENTS = "folios-department",
  DIVISIONS = "divisions",
  TOP_15 = "top-15",
  Area_Histogram = "area-histogram",
  Department_Histogram = "department-histogram",
}

export { RedirectedIds }
