import { format, startOfWeek } from 'date-fns'
import { Constants } from '../../../../../../../utils/constants'
import { porcentageAreasAndDepartments } from '../../methods/statusByPercentage'
import { dashboardService } from '../../../../../../../utils/constants/callService'
import { Item } from '../../../../../../../utils/types/generals'
import { RequestHistory } from '../../indicators/typing/types'
import { calc30DaysAgo } from '../../indicators/methods/callEndpoints'
import { formatGraphicAreaData, graphicArea, graphicDepartments, progressBarArea, progressBarDepartment } from './formatDataEndpoints'
import { es } from 'date-fns/locale'

const progressBars = ({ questionnaire, status }: { questionnaire?: Item[]; status?: Item[] }) => ({
  areas: async ({ areasList }: { areasList: Item[] }) => {
    const body = progressBarArea({ areasList, questionnaire, status })
    const response = await dashboardService.areaActive({ body })
    const aggregations = response.aggregations
    const hits = response?.hits?.hits?.[0]?._source
    const updateDate = hits?.fecha
      ? `Semana: ${hits.semana} - ${format(
        new Date(hits.fecha),
        Constants.humanFormat,
        {locale: es}
      )}`
      : ''
    const formatedData = aggregations?.areas?.buckets?.map((item) => {
      const label = areasList.find(
        (itemArea) => itemArea.value == item.key
      )?.label

      const canceled = item.cancelado.value,
        expired = item.vencido.value,
        finished = item.finalizado.value,
        noStarted = item.por_iniciar.value,
        progress = item.en_progreso.value,
        incompleto = item.incompleto.value

      const total = canceled + expired + finished + noStarted + progress + incompleto
      const colorProgrammed = porcentageAreasAndDepartments({
        canceled,
        expired,
        finished,
        noStarted,
        progress,
        incompleto,
        total,
        status
      })
      return {
        id: item.key,
        label,
        total,
        percentageProgrammed: 0,
        percentageAction: 0,
        colorStatus: colorProgrammed.areasColors()
      }
    })

    return { data: formatedData, updateDate }
  },
  departments: async ({
    area_id,
    departmentList,
  }: {
    area_id: string
    departmentList: Item[]
    questionnaire?: Item[]
    status?: Item[]
  }) => {
    let filters: {
      [x: string]: {
        [x: string]: string[] | string;
      };
    }[] = [{
      term: {
        area_id: area_id,
      }
    }]

    if (!!departmentList?.length) { filters.push({ terms: { departamento_id: departmentList.map((item) => item.value), }, }) }
    const body = progressBarDepartment({ area_id, departmentList, questionnaire, status })

    const response = await dashboardService.areaActive({ body })
    const aggregations = response.aggregations
    const hits = response?.hits?.hits?.[0]?._source
    const updateDate = hits?.fecha
      ? `Semana: ${hits.semana} - ${format(
        new Date(hits?.fecha),
        Constants.humanFormat,
        {locale: es}
      )}`
      : ''

    const formatedData = aggregations.areas.buckets.map((item) => {
      const label =
        departmentList?.find((itemArea) => itemArea.value == item.key)?.label ??
        'N/A'

      const canceled = item.cancelado.value,
        expired = item.vencido.value,
        finished = item.finalizado.value,
        noStarted = item.por_iniciar.value,
        progress = item.en_progreso.value,
        incompleto = item.incompleto.value

      const total = canceled + expired + finished + noStarted + progress + incompleto
      const colorProgrammed = porcentageAreasAndDepartments({
        canceled,
        expired,
        finished,
        noStarted,
        progress,
        incompleto,
        total,
        status
      })
      return {
        id: item.key,
        label,
        total,
        percentageProgrammed: 0,
        percentageAction: 0,
        colorStatus: colorProgrammed.departmentsColors()
      }
    })

    return { data: formatedData, updateDate }
  },
})

const graphicHistorigram = ({ detail }: { detail?: boolean; }) => ({
  areas: async ({
    areasList,
    date,
    interval,
  }: { areasList?: Item[] } & RequestHistory) => {
    const { startDate, endDate } = calc30DaysAgo({
      end: date?.end,
      start: date?.start,
      format: interval != '1w' ? Constants.dateFormat3 : undefined
    })
    let startWeek = format(startOfWeek(new Date(startDate), { weekStartsOn: 1 }), Constants.dateEndpoint2)


    const start = interval == '1w' ? startWeek : startDate
    const end = endDate
    const body = graphicArea({ areasList, end, interval, start, detail })

    const response = await dashboardService[interval == '1w' ? 'areaWeekHistory' : `areaHistory`]({ body })
    if (!!detail) return response
    return formatGraphicAreaData({ response, areasList })
  },
  departments: async ({
    area_id,
    departmentList,
    date,
    interval,
  }: { area_id: string; departmentList: Item[] } & RequestHistory) => {
    const { startDate, endDate } = calc30DaysAgo({
      end: date?.end,
      start: date?.start,
      format: interval != '1w' ? Constants.dateFormat3 : undefined
    })

    let startWeek = format(startOfWeek(new Date(startDate), { weekStartsOn: 1 }), Constants.dateEndpoint2)

    const start = interval == '1w' ? startWeek : startDate
    const end = endDate
    const body = graphicDepartments({ area_id, departmentList, end, start, interval, detail })
    const response = await dashboardService[interval == '1w' ? 'areaWeekHistory' : `areaHistory`]({ body })
    if (!!detail) return response
    const { areasString, data, dataByDates } = formatGraphicAreaData({ response, areasList: departmentList })
    return { data, departmentConverted: areasString, dataByDates }
  },
})

export { progressBars, graphicHistorigram }
