import { LoadingButton } from '@mui/lab'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { icons } from '../../../utils/icons'
import BaseModal from '../baseModal'
import { Props } from './props'
import { useStyles } from './styles'

const SimpleModal = ({ open, close, title, description, buttonText, cancelText, next, back, loadingButton, isLoading, removeClose, children, sx, removeButtons, disableButton, icon, errorMsg }: Props) => {
  const styles = useStyles(!!back)
  return (
    <BaseModal open={open} sx={{ width: '350px', maxHeight: '90vh', overflow: 'auto', ...(sx ?? {}) }}>
      <Box sx={styles.containerSuccess}>
        {
          !removeClose && (
            <IconButton
              sx={styles.closeSuccess}
              onClick={() => {
                close()
              }}
            >
              {icons('simple-close')}
            </IconButton>
          )
        }
        <Box className="container">
          {!!icon && icon}
          {!!title && <Typography className="title">{title}</Typography>}
          {!!description && <Typography className="description">{description}</Typography>}
          {!!errorMsg && <Typography className="errorMsg">{errorMsg}</Typography>}
          {children}
        </Box>

        {!removeButtons && (
          <>
            <hr />
            <Box sx={styles.buttonContainer}>
              {!!back && (
                <Button
                  className="cancel"
                  onClick={() => {
                    back()
                  }}
                >
                  {cancelText ?? ''}
                </Button>
              )}
              {!!loadingButton ? (
                <LoadingButton
                  className="continue"
                  loading={isLoading}
                  disabled={disableButton || isLoading}
                  onClick={() => {
                    next()
                  }}
                >
                  {buttonText}
                </LoadingButton>
              ) : (
                <Button
                  className="continue"
                  onClick={() => {
                    next()
                  }}
                >
                  {buttonText}
                </Button>
              )}
            </Box>
          </>
        )}
      </Box>
    </BaseModal>
  )
}

export default SimpleModal
