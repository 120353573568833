import { useEffect, useState } from "react";
import { DynamicFormRefProps, DinamicFormRefValues, DynamicRefRef } from "../../../../components/dynamicFormRef/types";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { useEvidencesType } from "../../../../hooks/evidences/useEvidences";
import { useRefs } from "../../../../hooks/useReactHookModified";
import { EvidenceData } from "../../../../hooks/evidences/typyng";
import { fields } from "../../constants";

const useBody = (payload: { defaultValues: EvidenceData; defaultValuesFolios: EvidenceData; data?: Record<string, boolean | number | string> }) => {
  const { t } = useTranslation()
  const [folioData, setFolioData] = useState<{
    isOpen: boolean;
    active: boolean
    errors?: boolean
    data?: {
      limit: Record<string, DinamicFormRefValues>
      rejects: Record<string, DinamicFormRefValues>
    }
  }>({ isOpen: false, active: false })
  const [modal, setModal] = useState<{
    open: boolean
    title: string
    description: string
    cancel?: () => void
    confirm?: () => void
  }>({ open: false, title: '', description: '' })
  const evidencesOptions = useEvidencesType({ defaultValues: payload.defaultValues })
  const evidencesFolios = useEvidencesType({ defaultValues: payload.defaultValuesFolios })
  
  const { refsByKey, setRef } = useRefs<DynamicRefRef>()
  const styles = useStyles()
  const [generalQuestions, setGeneralQuestions] = useState<DynamicFormRefProps['model']>({})
  const [areThereSomeCheck, setAreThereSomeCheck] = useState(false)
  const description: DynamicFormRefProps['model'] = {
    title: {
      id: '1',
      label: 'Titulo',
      name: 'title',
      type: "text",
      validations: {
        required: { value: true }
      },
      inputOptions: {
        textFieldProps: {
          inputProps: {
            maxLength: 250
          }
        }
      },
      rows: 2,
      maxRows: 2,
      multiline: true
    },
    description: {
      id: '2',
      label: 'Descripccion',
      name: 'description',
      type: "text",
      rows: 2,
      maxRows: 2,
      inputOptions: {
        textFieldProps: {
          inputProps: {
            maxLength: 65000
          }
        }
      },
      multiline: true
    },
  }
  const folioModelReport: DynamicFormRefProps['model'] = {
    maxFolios: {
      id: 'maxFolios',
      label: t('reportFolio.setting.limitFolio'),
      name: 'maxFolios',
      type: 'switch',
      inputOptions: {
        child: {
          childKey: 'maxFoliosAmount'
        }
      },
      wrapper: (payload) => (
        <Grid
          item
          xs={8}
          children={(
            <Box>
              {payload.children}

              <Typography sx={styles.rejectError}>{payload?.childrenData?.error ?? ''}</Typography>
            </Box>
          )}
          key={payload.id}
        />
      )
    },
    maxFoliosAmount: {
      id: 'maxFoliosAmount',
      label: t('general.tags.maxReject'),
      name: 'maxFoliosAmount',
      type: 'text',
      validations: { required: { value: true, message: t('general.validations.minFolios') }, number: { value: true }, min: { value: 1 } },
      hiddenError: true,
      inputOptions: {
        parent: {
          parentKey: 'maxFolios',
          hidden: true
        }
      },
      wrapper: (payload) => (
        <Grid
          item
          xs={4}
          children={payload.children}
          key={payload.id}
        />
      )
    },
  }
  const folioModel: DynamicFormRefProps['model'] = {
    maxRejects: {
      id: 'maxRejects',
      label: t('reportFolio.setting.maxRejects'),
      name: 'maxRejects',
      type: 'switch',
      inputOptions: {
        child: {
          childKey: 'maxRejectsAmount'
        }
      },
      wrapper: (payload) => (
        <Grid
          item
          xs={8}
          children={(
            <Box>
              {payload.children}

              <Typography sx={styles.rejectError}>{payload?.childrenData?.error ?? ''}</Typography>
            </Box>
          )}
          key={payload.id}
        />
      )
    },
    maxRejectsAmount: {
      id: 'maxRejectsAmount',
      label: t('general.tags.maxReject'),
      name: 'maxRejectsAmount',
      type: 'text',
      hiddenError: true,
      validations: { required: { value: true, message: t('general.validations.minFolios') }, number: { value: true }, min: { value: 1 } },
      inputOptions: {
        parent: {
          parentKey: 'maxRejects',
          hidden: true
        }
      },
      wrapper: (payload) => (
        <Grid
          item
          xs={4}
          children={payload.children}
          key={payload.id}
        />
      )
    },
    enableSurvey: {
      id: 'enableSurvey',
      label: t('reportFolio.setting.enableSurvey'),
      name: 'enableSurvey',
      type: 'check',
      switchOptions: {
        child: {
          childKey: `enableSurveyRequired`
        }
      },
      wrapper: (payload) => (
        <Grid
          item
          xs={8}
          children={payload.children}
          key={payload.id}
        />
      )
    },
    enableSurveyRequired: {
      id: 'enableSurveyRequired',
      label: t(`general.validations.obligatory`),
      name: 'enableSurveyRequired',
      type: 'switch',
      switchOptions: {
        parent: {
          parentKey: `enableSurvey`
        }
      },
      wrapper: (payload) => (
        <Grid
          item
          xs={4}
          children={payload.children}
          key={payload.id}
        />
      )
    },
    satisfactionQuestion: {
      id: 'satisfactionQuestion',
      label: t(`general.questions.customerSatisfactionQuestion`),
      name: 'satisfactionQuestion',
      type: 'text',
      validations: { required: { value: true, message: t('general.validations.satisfationQuestionIsRequired') } },
      multiline: true,
      rows: 4,
      maxRows: 4,
      inputOptions: {
        parent: {
          parentKey: 'enableSurvey',
          hidden: true
        }
      },
      wrapper: (payload) => (
        <Grid
          item
          xs={12}
          children={payload.children}
          key={payload.id}
        />
      )
    },
  }
  const formatGeneralQuestion = () => {
    let newGeneralQuestions: DynamicFormRefProps['model'] = {}

    for (const item of fields) {
      newGeneralQuestions = {
        ...newGeneralQuestions,
        [`${item}-check`]: {
          id: `${item}-check`,
          label: t(`general.questions.${item}`),
          name: `${item}-check`,
          type: 'check',
          switchOptions: {
            child: {
              childKey: `${item}-switch`
            }
          },
          wrapper: (payload) => (
            <Grid
              item
              xs={6}
              children={payload.children}
              key={payload.id}
            />
          )
        },
        [`${item}-switch`]: {
          id: `${item}-switch`,
          label: t(`general.validations.obligatory`),
          name: `${item}-switch`,
          type: 'switch',
          switchOptions: {
            parent: {
              parentKey: `${item}-check`
            }
          },
          wrapper: (payload) => (
            <Grid
              item
              xs={6}
              children={payload.children}
              key={payload.id}
            />
          )
        }
      }
    }

    setGeneralQuestions(() => ({
      ...newGeneralQuestions,
      sendEmail: {
        id: 'sendEmail-1',
        label: t('general.messages.sendEmail'),
        name: 'sendEmail',
        type: 'switch',
        wrapper: (payload) => (
          <Grid
            item
            mt={2}
            xs={12}
            children={payload.children}
            key={payload.id}
          />
        )
      },
      maxReject: {
        id: 'maxReject',
        label: t('general.tags.quantity'),
        name: 'maxReject',
        type: 'text',
        hiddenError: true,
        validations: {
          required: { value: true },
          number: { value: true },
          min: { value: 1 },
          max: { value: 10 }
        },
        wrapper: (payload) => {
          return (
            <Grid
              item
              mt={2}
              xs={12}
              children={(
                <Grid container spacing={1}>
                  <Grid item xs={7.5}>
                    <Typography>{t('general.tags.maxRejections')}</Typography>

                    <Typography sx={styles.rejectError}>{payload?.error ?? ''}</Typography>
                  </Grid>

                  <Grid item xs={4.5}>{payload.children}</Grid>
                </Grid>
              )}
              key={payload.id}
            />
          )
        }
      }
    }))
  }

  const handleActivedChecks = (value: boolean) => {
    setAreThereSomeCheck(value)
  }

  const handleErrors = () => {
    const limitsE = refsByKey['folioLimit']?.getErrors()
    const limitsD = refsByKey['folioLimit']?.getValues()
    const rejectsE = refsByKey['folioReject']?.getErrors()
    const rejectsD = refsByKey['folioReject']?.getValues()
    setFolioData(prev => ({
      ...prev,
      errors: (!!limitsE?.maxFoliosAmount?.message && !!limitsD?.maxFolios) ||
        (rejectsE?.maxRejectsAmount?.message && !!rejectsD?.maxRejects) ||
        (!!rejectsE?.satisfactionQuestion?.message && !!rejectsD?.enableSurvey)
    }))
  }

  const removeFolios = () => {
    setModal(prev => ({
      ...prev,
      open: true,
      title: t('Questionarie.modal.reportFolio.cancel.title'),
      description: t('Questionarie.modal.reportFolio.cancel.description'),
      cancel: () => { closeModal() },
      confirm: () => {
        cleanFolios()
        closeModal()
      }
    }))
  }

  const cleanFolios = () => {
    evidencesFolios.cleanAll()
    setFolioData(prev => ({
      ...prev,
      active: false,
      errors: undefined,
      data: undefined,
      isOpen: false
    }))
  }
  const handleBadge = (): string | number | null => {
    if (folioData?.active) {
      if (!!folioData?.data?.limit?.maxFolios) {
        const maxAMount = folioData?.data?.limit?.maxFoliosAmount
        return !!maxAMount && parseInt(maxAMount as string) ? maxAMount as number : 0
      }
      return t('general.tags.noLimits')
    }

    return null
  }

  const openModal = () => {
    setModal(prev => ({
      ...prev,
      open: true,
      title: t(`general.confirmation`),
      description: t(`Questionarie.modal.reportFolio.description`),
      cancel: () => { closeModal() },
      confirm: () => {
        setFolioData(prev => ({ ...prev, isOpen: true, active: true }))
        closeModal()
      }
    }))
  }

  const activeFoliosSection = () => {
    setFolioData(prev => ({ ...prev, isOpen: true, active: true }))
  }
  const closeFolio = () => {
    setFolioData(prev => ({ ...prev, isOpen: false, }))
  }
  const openFolio = () => {
    setFolioData(prev => ({ ...prev, isOpen: true, }))
  }
  const closeModal = () => {
    setModal(prev => ({
      ...prev,
      open: false,
      title: '',
      description: ''
    }))
  }

  const changeFolio = ({ name, value, type }: { type: 'limit' | 'rejects'; name: string; value: DinamicFormRefValues }) => {
    setFolioData(prev => ({
      ...prev,
      data: {
        ...prev.data ?? { limit: {}, rejects: {} },
        [type]: { ...prev.data?.[type] ?? {}, [name]: value }
      }
    }))
  }

  const cleanActionPlanDrawer = () => {
    cleanFolios()
    evidencesOptions.cleanAll()
  }
  useEffect(() => {
    formatGeneralQuestion()
  }, [])

  return {
    description,
    generalQuestions,
    evidencesOptions,
    areThereSomeCheck,
    refsByKey,
    modal,
    folioData,
    folioModel,
    folioModelReport,
    evidencesFolios,
    cleanActionPlanDrawer,
    cleanFolios,
    activeFoliosSection,
    openFolio,
    removeFolios,
    handleErrors,
    handleBadge,
    closeFolio,
    changeFolio,
    closeModal,
    openModal,
    handleActivedChecks,
    setRef,
  }
}

export { useBody, fields }