import { Typography } from "@mui/material"
import { differenceInDays, intervalToDuration } from "date-fns"
import { useTimeElapsedStyles } from "./timeElapsedStyles"

const TimeElapsed = (props: any) => {
    const styles = useTimeElapsedStyles()
    let elapsed, daysInfo

    if (!!props?.current || props?.current == 0) {
        const { years, months, weeks, days, hours, minutes } =
            intervalToDuration({
                start: new Date(props.row?.requestDate),
                end: new Date(props.row?.solveDate),
            })
        elapsed = `${!!years ? `${years}a ` : ''}${!!months ? `${months}m ` : ''
            }${!!weeks ? `${weeks}s ` : ''}${!!days ? `${days}d ` : ''}${!!hours ? `${hours}h ` : ''
            }${!!minutes ? `${minutes}min` : ''}`
        const differenceDays = differenceInDays(
            new Date(props.row?.solveDate),
            new Date(props.row?.requestDate)
        )
        daysInfo = `${differenceDays} días`
        // hoursInfo = `${props?.current} horas`
    } else {
        daysInfo = `${differenceInDays(
            new Date(),
            new Date(props.row?.requestDate)
        )} días`
    }

    return (
        <>
            <Typography sx={styles.unitContent} className="w-full">
                {elapsed ?? '--'}
            </Typography>
            <Typography sx={styles.unitTitle} className="w-full">
                {daysInfo ?? '--'}
            </Typography>
        </>
    )
}

export { TimeElapsed }
