import { useEffect, useState } from 'react'
import { mapDefault } from '../constants'

const useCurrentUser = (props: {
  localization: {
    id?: number | string
    fullName?: string | null | undefined
    email?: string | null | undefined
    curp: string
    phone?: string | null | undefined
    role?: string | null | undefined
    userType?: string | null | undefined
    img?: string | undefined | null
    lat: number
    lng: number
  }[]
}) => {
  const [defaultMap, setDefaultMap] = useState(mapDefault)
  const [localLocalization, setLocalLocalization] = useState(props.localization)
  const [localizationCopy, setLocalLocalizationCopy] = useState(props.localization)
  const [selected, setSelected] = useState<Record<string, boolean>>({})
  const [allisSelected, setIsSelected] = useState(true)
  const [search, setSearch] = useState('')

  const filterUsers = ({ value }: { value: string }) => {
    setSearch(value)

    setLocalLocalizationCopy(props.localization?.filter((item) => !!item.fullName?.toLowerCase()?.includes(value?.toLowerCase())))
  }

  const lookForUser = ({ id, skip }: { id?: number | string; skip?: boolean }) => {
    const user = localizationCopy?.find((item) => item.id == id)
    const idCopy = defaultMap.id == user?.id ? undefined : user?.id
    // const lat = !!idCopy ? user?.lat : (user?.lat ?? 0) + 0.00001
    // const lng = !!idCopy ? user?.lng : (user?.lng ?? 0) + 0.00001

    if (!!idCopy) {
      if (!skip) {
        handleUsers({ id, skip: true })
      }
      setDefaultMap((prev) => ({
        ...prev,
        id: id,
        center: {
          lat: user?.lat ? user?.lat : mapDefault?.center?.lat,
          lng: user?.lng ? user?.lng : mapDefault?.center?.lng,
        },
      }))
    }
  }

  const handleUsers = ({ id, skip }: { id?: number | string; skip?: boolean }) => {
    if (id) {
      let selectedCopy = structuredClone(selected)
      const idString = id?.toString()

      if (!!selectedCopy[idString] && !skip) {
        delete selectedCopy[idString]
      } else {
        selectedCopy = {
          ...selectedCopy,
          [idString]: true,
        }
      }
      
      if (!!selectedCopy[idString] && defaultMap.id != id) {
        lookForUser({ id, skip: true })
      } else if (!selectedCopy[idString] && defaultMap.id == id) {
        setDefaultMap((prev) => ({
          ...prev,
          id: undefined,
        }))
      }
      handleLocalization({ select: selectedCopy })
      setSelected(selectedCopy)
    }
  }

  const handleLocalization = ({ select }: { select: Record<string, boolean> }) => {
    const localizationFilter = props.localization?.filter((item) => !!select[item.id?.toString() ?? ''])

    const isAllSelected = localizationFilter?.length === props.localization?.length

    setIsSelected(isAllSelected)
    setLocalLocalization(localizationFilter)
  }

  const handleSelectAll = () => {
    const list = props.localization?.map((item) => item.id?.toString() ?? '')
    const listCopy = structuredClone(list)
    const firstLoacation = props.localization?.[0]

    let body: Record<string, boolean> = {}

    listCopy?.forEach((item) => {
      body = {
        ...body,
        [item]: true,
      }
    })
    handleLocalization({ select: body })
    setSelected(body)
    setIsSelected(true)

    if (!!firstLoacation) {
      setDefaultMap((prev) => ({
        ...prev,
        id: firstLoacation.id,
        center: {
          lat: firstLoacation?.lat,
          lng: firstLoacation?.lng,
        },
      }))
    }
  }

  const handleUnselectAll = () => {
    setSelected({})
    setLocalLocalization([])
    setIsSelected(false)
    setDefaultMap((prev) => ({
      ...prev,
      id: undefined,
    }))
  }

  useEffect(() => {
    handleSelectAll()

    setLocalLocalizationCopy(props.localization)
    setLocalLocalization(props.localization)
    // eslint-disable-next-line
  }, [props.localization])

  return { localLocalization, selected, allisSelected, search, localizationCopy, defaultMap, handleUsers, handleSelectAll, handleUnselectAll, filterUsers, lookForUser }
}

export { useCurrentUser }
