import { TFunction } from 'react-i18next'
import { InputModel } from '../../../../utils/types'

export const initialFilterValues = {
  status: '',
  search: '',
}
export const formatFilter = {
  from: 'start_date',
  to: 'end_date',
  status: 'status_ids',
  createYear: "year",
  month: "month"
}

export const formData: Record<string, string> = {
  from: 'start_date',
  to: 'end_date',
  status: 'status',
  createYear: "year",
  month: "month",
}
export const useFilterDetail = (t: TFunction<'translation', undefined>) => {
  return {
    search: {
      label: t('userType.filter.inputs.search'),
      name: 'search',
    },
    status: {
      label: t('userType.filter.inputs.firstSelect'),
      name: 'status',
    },
  }
}

export const initialTypeValues = {
  name: {
    label: 'Tipo',
    name: 'name',
    value: null,
    customeErrors: {
      required: 'general.validate.required.type',
    },
    validate: {
      required: true,
    },
  },
  status: {
    label: 'Estado',
    name: 'status',
    value: null,
    type: 'select-search',
    items: [],
    validate: {
      required: true,
    },
  },
  description: {
    label: 'Descripción',
    name: 'description',
    value: null,
    validate: {
      required: true,
    },
  },
} as { [x: string]: InputModel }

export const input = {
  search: {
    label: 'general.search',
    translate: true,
    name: 'search',
    type: 'text',
    value: null,
  },
  createYear: {
    label: 'general.filterDate.year',
    translate: true,
    name: 'createYear',
    type: 'filter-select',
    items: [],
    value: null,
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  month: {
    label: 'general.filterDate.month',
    translate: true,
    name: 'month',
    type: 'filter-select',
    items: [],
    value: null,
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  from: {
    label: 'general.tags.from',
    translate: true,
    name: 'from',
    type: 'date',
    value: null,
    rest: {
      max: 'to',
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  to: {
    label: 'general.tags.to',
    translate: true,
    name: 'to',
    type: 'date',
    value: null,
    rest: {
      min: 'from',
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  status: {
    label: 'general.tags.status',
    translate: true,
    name: 'status',
    type: 'filter-select',
    items: [],
    value: null,
  },
} as { [x: string]: InputModel }
