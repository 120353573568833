import { intervaleList } from "../../../../../../../utils/constants/intervaleList";
import { InputModel } from "../../../../../../../utils/types";
import { currentWeek } from "../../methods/calcDates";

const size = {
  1: {
    lg: 3,
    md: 3,
    sm: 3,
    xl: 3,
    xs: 3
  },
  2: {
    lg: 4,
    md: 4,
    sm: 4,
    xl: 4,
    xs: 4
  },
  3: {
    lg: 6,
    md: 6,
    sm: 6,
    xl: 6,
    xs: 6
  },
  4: {
    lg: 2,
    md: 2,
    sm: 2,
    xl: 2,
    xs: 2
  },
}

const inputsAreas: Record<string, InputModel> = {
  areas: {
    label: 'general.tags.areas',
    translate: true,
    name: 'areas',
    type: 'filter-select',
    items: [],
    value: null,
  },
  questionnaire: {
    label: 'general.tags.questionnaire',
    translate: true,
    name: 'questionnaire',
    type: 'filter-select',
    items: [],
    value: null,
  },
  status: {
    label: 'general.tags.status',
    translate: true,
    name: 'status',
    type: 'filter-select',
    items: [],
    value: null,
  },
}

const inputsDepartments: Record<string, InputModel> = {
  departments: {
    label: 'general.tags.departments',
    translate: true,
    name: 'departments',
    type: 'filter-select',
    items: [],
    value: null,
  },
  questionnaire: {
    label: 'general.tags.questionnaire',
    translate: true,
    name: 'questionnaire',
    type: 'filter-select',
    items: [],
    value: null,
  },
  status: {
    label: 'general.tags.status',
    translate: true,
    name: 'status',
    type: 'filter-select',
    items: [],
    value: null,
  },
}
const inputsAreasGraphic: Record<string, InputModel> = {
  areas: {
    label: 'general.tags.areas',
    translate: true,
    name: 'areas',
    type: 'filter-select',
    rest: {
      hidechips: true,
    },
    items: [],
    value: null,
    size: size[1]
  },
  from: {
    label: 'general.tags.from',
    translate: true,
    name: 'from',
    type: 'date',
    rest: {
      max: 'to',
      maxToday: true
    },
    value: currentWeek().start.getTime(),
    size: size[1]
  },
  to: {
    label: 'general.tags.to',
    translate: true,
    name: 'to',
    type: 'date',
    rest: {
      min: 'from',
      maxToday: true
    },
    value: currentWeek().end.getTime(),
    size: size[1]
  },
  intervale: {
    label: 'general.tags.intervale',
    translate: true,
    name: 'intervale',
    type: 'select-search',
    items: intervaleList,
    value: { label: 'Diario', value: '1d' },
    size: size[1]
  },
}
const inputsDepartmentsGraphic: Record<string, InputModel> = {
  areas: {
    label: 'general.tags.areas',
    translate: true,
    name: 'areas',
    type: 'select-search',
    items: [],
    value: null,
    
    size: size[1]
  },
  departments: {
    label: 'general.tags.departments',
    translate: true,
    name: 'departments',
    type: 'filter-select',
    rest: {
      hidechips: true,
    },
    
    size: size[1],
    items: [],
    value: null,
  },
  from: {
    label: 'general.tags.from',
    translate: true,
    name: 'from',
    type: 'date',
    rest: {
      max: 'to',
    },
    value: currentWeek().start.getTime(),
    
    size: size[4],
  },
  to: {
    label: 'general.tags.to',
    translate: true,
    name: 'to',
    type: 'date',
    rest: {
      min: 'from',
    },
    value: currentWeek().end.getTime(),
    
    size: size[4],
  },
  intervale: {
    label: 'general.tags.intervale',
    translate: true,
    name: 'intervale',
    type: 'select-search',
    items: intervaleList,
    value: { label: 'Diario', value: '1d' },
    
    size: size[4],
  },
}
const defaultDates = {
  from: currentWeek().start.getTime(),
  to: currentWeek().end.getTime()
}
export { inputsAreas, inputsDepartments, inputsDepartmentsGraphic, inputsAreasGraphic, defaultDates }