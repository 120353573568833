import { useContextState } from '../../../hooks/theme'

export const useStyles = (twoButton: boolean) => {
  const { colors: color } = useContextState()

  return ({
    closeSuccess: {
      position: 'absolute',
      right: '10px',
      top: '5px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      '& .MuiButton-root': {
        width: twoButton ? '50%' : '100%',
        borderRadius: 0,
        padding: '10px 8px',
      },
    },
    containerSuccess: {
      width: '100%',
      margin: 'auto',
      padding: '10px 0 0 0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& .container': {
        margin: '20px auto',
        width: '90%',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px',
      },
      '& .MuiTypography-root': {
        width: 'fit-content',
        textAlign: 'center',
      },
      '& .title': {
        fontSize: '16px',
        color: color.color9,
        fontWeight: '800',
      },
      '& .description': {
        fontSize: '12px',
        color: color.color9,
      },
      '& .errorMsg': {
        fontSize: '12px',
        color: color.color28,
        padding: '20px 0 5px ',
      },
      '& .continue': {
        color: twoButton ? color.color19 : color.color4,
      },
      '& .cancel': {
        color: color.color4,
        borderRight: `1px solid ${color.color13}77`,
      },
    },
  })
}
