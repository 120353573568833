import { Box, IconButton, TextField, TextFieldProps } from "@mui/material"
import { ChangeEvent, forwardRef, useRef } from "react"
import { useStyles } from "./styles";
import { Images } from "../../image";
import { icons } from "../../../utils/icons";

type FileWithTextPropsCOmponent = TextFieldProps & {
  accept?: string;
  removeImage: () => void; icon?: JSX.Element;
  value: { text?: string; file?: File | string };
  onChangeFile: (e: ChangeEvent<HTMLInputElement>) => void
}

const FileWithText = forwardRef<HTMLInputElement, FileWithTextPropsCOmponent>((props, externalRef) => {
  const ref = useRef<HTMLInputElement | null>(null)
  const styles = useStyles({ errors: !!props.error })
  const images = props?.value?.file && typeof props?.value?.file !== 'string' ? URL.createObjectURL(props.value.file) : props?.value?.file
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.onChangeFile(e)
    if (ref?.current) ref.current.value = ''
  }
  return (
    <>
      <TextField
        {...props}
        ref={externalRef}
        value={props.value?.text ?? ''}
        InputProps={{
          endAdornment: (
            <IconButton
              sx={styles.icon}
              onClick={() => {
                if (!props?.disabled && ref?.current) {
                  ref.current.click()
                }
              }}
            >
              {!!images ?
                <Box>
                  {
                    !props.disabled && (
                      <IconButton onClick={(e) => {
                        e.stopPropagation()
                        if (!props.disabled) {
                          props.removeImage()
                        }
                      }} sx={styles.close}>{icons('close')}</IconButton>
                    )
                  }
                  <Images src={images as string} sx={styles.image} /></Box> : (props.icon ?? icons('folder'))}
            </IconButton>
          ),
        }}
      />

      <input
        name={props.name}
        type="file"
        onChange={handleChange}
        hidden
        ref={ref}
        accept={props.accept ?? ".png"}
      />
    </>
  )
})

export { FileWithText }
