import { Status } from "../../../../../../../../utils/enums"

const valdateStatus = ({ canceled, expired, finished, incompleto, progress, noStarted, }: {
  canceled: number
  expired: number
  finished: number
  noStarted: number
  progress: number
  incompleto: number
}) => {
  let status: Status | undefined

  if (!!canceled) status = Status.CANCEL
  if (!!expired) status = Status.EXPIRED
  if (!!finished) status = Status.FINISHED
  if (!!noStarted) status = Status.TO_BE_STARTED
  if (!!progress) status = Status.STARTED
  if (!!incompleto) status = Status.INCOMPLETE

  return status
}

export { valdateStatus }
