import { Box, BoxProps, Drawer, Icon, Typography } from '@mui/material'
import { Images } from '../../image'
import checkplusk from '../../../assets/images/check-plus-k.png'
import logo from '../../../assets/images/logo.png'
import { useStyles } from './styles'
import { useContextState } from '../../../hooks/theme'
import { icons } from '../../../utils/icons'
import { AsideProps } from './types'
import { useLocation } from 'react-router-dom'

const Item = ({ open, item, onClick, styles, active, ...rest }: { open: boolean; item: any; onClick: () => void; styles: any; active?: any } & BoxProps) => {
  return (
    <Box key={rest.key} sx={[styles.asideMenuContainer, active && styles.active]} onClick={() => onClick()}>
      {item.icon && <Icon>{icons(item.icon)}</Icon>}
      {open && <Typography sx={styles.label}>{item.label}</Typography>}
    </Box>
  )
}

const Aside = ({ open, setOpen, pathList, position, show, setShow, handleChange, variant = 'permanent' }: AsideProps) => {
  const { colors } = useContextState()
  const styles = useStyles(colors, open, position)
  const location = useLocation()
  const path = location.pathname.replace('/', '')

  return (
    <Drawer
      elevation={0}
      anchor="left"
      variant={variant}
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      sx={styles.drawer}
    >
      <>
        {open ? <Images sx={styles.img2} src={logo} onClick={() => setOpen(!open)} /> : <Images sx={styles.img} src={checkplusk} onClick={() => setOpen(!open)} />}

        <Box sx={styles.menu}>
          <Box sx={styles.asideMenu}>
            {pathList?.map((item, index) => (
              <Box key={`${item.path}-${index}`}>
                <Box
                  sx={styles.containerItem}
                  onClick={() => {
                    if (item.children && open) setShow(show === index ? -1 : index)
                  }}
                >
                  {position === index && <Box sx={styles.bar} />}
                  
                  <Item
                    item={item}
                    styles={styles}
                    onClick={() => {
                      handleChange?.(item.path, index)
                    }}
                    open={open}
                    active={position === index}
                  />
                  {item.children && open && <Icon sx={styles.arrowBack}>{icons('arrow-down')}</Icon>}
                </Box>
                
                {item.children && open && [show].includes(index) && (
                  <Box sx={styles.childrens}>
                    {item.children.map((child, childIndex) => (
                      <Item key={`${child.path}-${index}-${childIndex}`} item={child} styles={styles} onClick={() => handleChange?.(child.path, index)} open={open} active={path.includes(child.path)} />
                    ))}
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </>
    </Drawer>
  )
}

export default Aside
