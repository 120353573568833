import { Color } from '../../../../../../utils/theme/types/color'
import { Priority } from '../../typing'

const useStyles = ({ color }: { color: Color }) => {
  return {
    container: {
      width: '100%',
      height: '100%',
      paddingRight: '50px',
      paddingLeft: '30px',
      paddingTop: '50px',
      paddingBottom: '20px',
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'relative',
    },
    titleQuestion: {
      color: color.color9,
      fontSize: '16px',
      width: '100%',
    },
    closeSuccess: {
      position: 'absolute',
      right: '8px',
      top: '8px',
    },
    tabsContainer: {
      padding: '20px 20px',
    },
    TabList: {
      '& .MuiTab-root': {
        flex: 1,
        minHeight: '40px',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        backgroundColor: color.color5,
        color: color.color10,
        '&.Mui-selected': {
          backgroundColor: `${color.color10}44`,
          color: color.color10,
        },
      },
      '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
      },
    },
    TabPanel: {
      '&.MuiTabPanel-root': {
        padding: '24px 0px',
      },
    },
    goBack: {
      color: color.color16,
      fontSize: '1.5rem',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    goBackText: {
      fontSize: '16px',
      fontWeight: '500',
      marginLeft: '5px',
      color: color.color16,
    },
    flex: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      alignItems: 'center',
      '&.flex-jc-sb': {
        justifyContent: 'space-between',
      },
    },
    stepsIndicatorContainer: {
      marginTop: '10px',
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      columnGap: '5px',
    },
    videos: {
      width: '100%',
      height: '200px',
      '& video': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    images: {
      width: '100%',
      height: '200px',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    borders: {
      boxShadow: `0 0 4px ${color.color13}`,
      borderRadius: '10px',
      width: '100%',
      height: '100%',
      position: 'relative',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px',
      position: 'relative',
      '& .MuiTypography-root': {
        color: color.color5,
        fontSize: '16px',
        '&.open': {
          fontWeight: '500',
        },
        '& span': {
          fontSize: '20px',
          fontWeight: '500',
          marginLeft: '10px',
        },
      },
    },
    fire: {
      position: 'absolute',
      left: 'calc(50% - 25px)',
      bottom: '-20px',
    },
    noData: {
      width: '100%',
      textAlign: 'center',
      fontSize: '18px',
      color: `${color.color13}`,
    },
    documentsContainer: {
      width: '100%',
      maxHeight: '200px',
      overflowY: 'auto',
      padding: '10px 20px',
      display: 'flex',
      flexWrap: 'wrap',
      rowGap: '10px',
    },
    documentName: {
      width: 'calc(100% - 50px)',
      wordBreak: 'break-word',
    },
    documentItems: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      padding: '10px 10px 0px',
      boxShadow: `3px 3px ${color.color13}30`,
      borderRadius: '5px',
      '&:hover': {
        backgroundColor: `${color.color22}05`,
      },
      '& .MuiIcon-root': {
        color: color.color1,
        height: '35px',
        width: '35px',
      },
    },
    description: {
      color: color.color22,
      boxShadow: `0px 3px ${color.color13}30`,
      padding: '10px 20px',
    },
    containerDescription: {
      padding: '20px',
    },
    containerBtn: {
      padding: ' 0 20px 20px 20px',
      textAlign: 'end',
      marginTop: '-10px',
    },
    dTitle: {
      fontSize: '16px',
      fontWeight: '400',
    },
    dDescription: {
      fontSize: '18px',
      fontWeight: '600',
      wordWrap: 'break-word',
    },
    borderRight: {
      boxShadow: `3px 0px ${color.color13}30`,
    },
    rating: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      gap: '5px',
      '& .MuiTypography-root': {
        fontSize: '14px',
        color: color.color22,
        wordWrap: 'break-word',
      },
    },
    emptyStar: {
      color: color.color5,
    },
    comments: {
      width: '100%',
      maxHeight: '300px',
      overflowY: 'auto',
    },
    borderBottom: {
      marginBottom: '10px',
      boxShadow: `0px 3px ${color.color13}30`,
    },
    padding: {
      padding: '20px',
    },
    paddingComments: {
      padding: '20px 20px 20px 25px',
    },
    titleProcess: {
      padding: '18px',
      fontWeight: '600',
    },
    title: {
      padding: '20px 20px 0px 25px',
      fontWeight: '600',
    },
    rejectTitle: {
      color: color.color13,
      fontWeight: '500',
      fontSize: '18px',
    },
    containerModalButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      borderTop: `1px solid ${color.color13}77`,
      padding: '0 20px',
      '& .cancel': {
        borderRight: `1px solid ${color.color13}77`,
        color: color.color19,
        width: '50%',
        padding: '10px 10px',
        paddingLeft: '20px',
        borderRadius: '0',
        '&:hover': {
          color: color.color19,
        },
      },
      '& .confirm': {
        width: '50%',
        paddingRight: '20px',
        color: color.color4,
        '&:hover': {
          color: color.color4,
        },
      },
    },
    processFolio: {
      borderRadius: '0',
      padding: '10px 15px',
      fontWeight: '500',
      backgroundColor: color.primary,
      '&:hover': {
        backgroundColor: `${color.primary}d0`,
      },
    },
    viewFolio: {
      cursor: 'pointer',
      '&:hover': {
        background: `${color.color22}05`,
      },
      boxShadow: `3px 3px 1px -1px ${color.color13}30, 0px 3px 1px 0px ${color.color13}30, 0px 1px 3px 0px ${color.color13}30`,
      padding: '15px',
      marginBottom: '10px',
      '& .MuiTypography-root': {
        fontSize: '14px',
        color: color.color22,
      },
      '& .MuiSvgIcon-root': {
        color: `${color.color22}b0`,
      },
    },
    [Priority.URGENT]: {
      background: color.color19,
    },
    [Priority.MEDIUM]: {
      background: color.color29,
    },
    [Priority.SUGGESTION]: {
      background: color.color1,
    },
    positionR: {
      position: 'relative',
    },

    // Container Raiting
    containerRate: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: `${color.color22}e0`,
      borderRadius: '8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    closeIcon: {
      position: 'absolute',
      top: '15px',
      right: '15px',
      color: `${color.color5}a0`,
      fontSize: '30px',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    // OPTIONS
    buttonOptions: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      rowGap: '20px',
      width: '100%',
    },
    optionTitle: {
      color: color.color5,
      fontSize: '16px',
      width: '200px',
      textAlign: 'center',
      wordBreak: 'break-word',
    },
    conteinrButtons: {
      display: 'flex',
      justifyContent: 'center',
      columnGap: '20px',
      width: '100%',
    },
    buttons: {
      padding: '10px 20px',
    },
    buttonColorB: {
      backgroundColor: color.color1,
      color: color.color5,
      '&:hover': {
        backgroundColor: `${color.color1}ee`,
        color: color.color5,
      },
    },
    buttonColorR: {
      backgroundColor: color.color19,
      color: color.color5,
      '&:hover': {
        backgroundColor: `${color.color19}ee`,
        color: color.color5,
      },
    },
    // [Status.ACTIVE]: {
    //   background: color.color19,
    // },
    // [Status.REJECTED]: {
    //   background: color.color29,
    // },
    // [Status.AWAIT_SOLUTION]: {
    //   background: color.secondary,
    // },
    // [Status.RESPONDED]: {
    //   background: color.secondary,
    // },
    // [Status.APPROVED]: {
    //   background: color.color8,
    // },
    // [Status.CANCEL]: {
    //   background: color.color4,
    // },
  }
}

export { useStyles }
