import { LoadingButton } from '@mui/lab'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SnackSimple } from '../../../../../../components'
import { DinamicForm } from '../../../../../../components/dinamicForm'
import { UserService } from '../../../../../../service'
import { storesService } from '../../../../../../service/stores'
import { zipCodeService } from '../../../../../../service/zipcode'
import { httpFetch } from '../../../../../../utils/crud'
import { Roles, Types } from '../../../../../../utils/enums'
import { ObjectGeneric, onChange } from '../../../../../../utils/types/generals'
import { initialValues } from './initialState'
import { canAccess } from '../../../../../../utils/validate/canaccess'
import { companiesServiceFetch } from '../../../../../../utils/constants/callService'

export interface Props {
  companies: any[]
  states: any[]
  storeManagers: any[]
}

export const UploadForm = () => {
  const [inputValues, setInputValues] = useState(initialValues)
  const [erros,] = useState<ObjectGeneric>({})
  const [data, setData] = useState<ObjectGeneric>({})
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const [childZones, setChildZones] = useState<any[]>([])
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
    type: Types.SUCCESS as string,
  })

  const handleCloseSnackbar = (value: boolean) => setOpenSnackbar({ ...openSnackbar, open: value })

  const onChange: onChange = async ({ target }) => {
    const { name, value } = target
    let body = { [name]: value }

    if (name === 'companies') {
      body = {
        ...body,
        divisions: null,
        fatherZone: null,
        childZone: null,
      }

      const ids = value.map((u: any) => u.value).join(',')
      getDivisionsZonesUsers(ids)
    }

    if (name === 'fatherZone') {
      const arrayChilds = value?.length ? data?.childZone?.filter(child => !!value?.some(father => father.value == child.father)): []

      body = {
        ...body,
        childZone: arrayChilds,
      }
    }

    if (name === 'states') {
      const ids = value.map((u: any) => u.value).join(',')

      const responseCitites = await getCities(ids)
      if (responseCitites) {
        body = { ...body, city: null }
      }
    }

    setData({ ...data, ...body })
  }

  useEffect(() => {
    const { fatherZone } = data

    let arrayChilds: any[] = []

    fatherZone?.forEach((e: any) => {
      const id = e.value
      const childs = childZones.filter((child) => child.father == id)
      arrayChilds.push(...childs)
    })
    setInputValues(prev => ({
      ...prev,
      childZone: {
        ...initialValues.childZone,
        items: arrayChilds,
      },
    }))
  }, [data?.fatherZone])

  const getDivisionsZonesUsers = async (ids: string) => {
    if (ids) {
      const response = await UserService(httpFetch).getDivisionsZones(ids)
      const responseUsers = await UserService(httpFetch).userByIds(ids, `${Roles.STORE}`)

      const divisions = response?.divisions?.map((item: any) => ({
        label: item.description,
        value: item.id,
      }))

      let fatherZones: any[] = []
      let childZones: any[] = []

      response?.zones?.forEach((e: any) => {
        if (!e.zoneInitId)
          fatherZones.push({
            label: e.name,
            value: e.id,
          })
        else
          childZones.push({
            label: e.name,
            value: e.id,
            father: e.zoneInitId,
          })
      })

      const users = responseUsers?.map((item: any) => {
        const name = item.firstName && item.lastName ? `${item.firstName} ${item.lastName}` : item.firstName ? item.firstName : item.lastName
        return {
          label: `${name} / ${item.curp}`,
          value: item.id,
        }
      })

      setChildZones(childZones)

      setInputValues(prev => ({
        ...prev,
        divisions: {
          ...initialValues.divisions,
          items: divisions,
        },
        fatherZone: {
          ...initialValues.fatherZone,
          items: fatherZones,
        },
        userManageId: {
          ...initialValues.userManageId,
          items: users,
        },
      }))
    } 
  }

  const onClick = async () => {
    const { companies, divisions, city, userManageId, fatherZone, childZone } = data
    let zones: any[] = []

    fatherZone?.forEach((e: any) => {
      zones.push({ zone_id: e.value, zone_init_id: null })
    })

    childZone?.forEach((e: any) => {
      zones.push({ zone_id: e.value, zone_init_id: e.father })
    })

    const body = {
      companies: companies?.map((e: any) => e.value),
      divisions: divisions?.map((e: any) => e.value),
      zip_codes: city?.map((e: any) => e.value),
      zones,
      users_manage_id: userManageId?.map((e: any) => e.value),
    }

    setLoading(true)
    try {
      const responseTemplate = await storesService(httpFetch).generateTemplate(body)
      responseTemplate.blob().then((blob) => download(blob, t('MassiveUpload.templateStore')))
    } catch (e) {
      const snackbarBody = {
        open: true,
        type: Types.ERROR as string,
        message: 'Ocurrió un error al descargar la plantilla',
      }
      setOpenSnackbar(snackbarBody)
    }
    setLoading(false)
  }

  const download = (blob: any, filename: any) => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }

  const getCities = async (ids?: string) => {
    const response = ids ? await zipCodeService(httpFetch).getCities(ids) : []

    setInputValues((prev) => ({
      ...prev,
      city: {
        ...prev.city,
        items: !response?.errors
          ? response?.map((item) => ({
            label: item.country,
            value: item.id,
          }))
          : [],
      },
    }))
    return true
  }

  const getCompannies = async () => {
    const responseCompanies = await companiesServiceFetch.getAllCompanies()

    const companies = responseCompanies?.map((item) => ({
      label: item.name,
      value: item.id,
    }))
    
    setInputValues((prev) => ({
      ...prev,
      companies: {
        ...initialValues.companies,
        items: companies,
      },
    }))
  }


  const getZipCode = async () => {
    const responseStates = await zipCodeService(httpFetch).satesList()

    const states = responseStates?.map((item: any) => ({
      label: item.state,
      value: item.codState,
    }))

    setInputValues(prev => ({
      ...prev,
      states: {
        ...initialValues.states,
        items: states,
      },
    }))
  }

  const getUserManagers = async () => {
    const responseStoreManagers = await UserService(httpFetch).userByIds('', '4')
    const storeManagers = responseStoreManagers?.map((item: any) => ({
      label: `${item.firstName ?? ''} ${item.lastName ?? ''}${item.curp ? ' / ' + item.curp : ''}`,
      value: item.id,
    }))
    setInputValues(prev => ({
      ...prev,
      userManageId: {
        ...initialValues.userManageId,
        items: storeManagers,
      },
    }))

  }
  useEffect(() => {
    if (!canAccess([Roles.SUPPORT, Roles.AUDIT])) {
      getCompannies()
      getZipCode()
      getUserManagers()
    }
  }, [])

  return (
    <>
      <SnackSimple close={handleCloseSnackbar} open={openSnackbar.open} type={openSnackbar.type} message={openSnackbar.message} />
      <DinamicForm errors={erros} input={Object.values(inputValues)} values={data} onChange={onChange} />

      <LoadingButton loading={loading} sx={{ mt: 2, textTransform: 'uppercase' }} variant="contained" onClick={onClick}>
        {t('MassiveUpload.downloadTemplate')}
      </LoadingButton>
    </>
  )
}
