import { isEmail, isPassword, isRequired, min, max, isNumber } from '../../../utils/validate/validate'
import { Value } from '../types'

export const validate = (value: Value, t: any) => {
  const { email, password, username, confirmPassword, code, currentPassword } = value
  let error = {} as {
    email: string
    code: string
    username: string
    password: string
    confirmPassword: string
    currentPassword: string
  }
  // username
  if (code?.value !== null && code) {
    if (isRequired(code?.value)) error.code = t('general.validations.required')
    else if(!isNumber(code?.value)) error.code = t('general.validations.itIsNotNumber')
    else if (min(code?.value, 6)) error.code = `${t('general.validations.min')} 6 ${t('general.validations.character')}`
    else if (max(code?.value, 6)) error.code = `${t('general.validations.max')} 6 ${t('general.validations.character')}`
  }
  // username
  if (username?.value !== null && username) {
    if (isRequired(username?.value)) error.username = t('general.validations.required')
  }
  // email
  if (email?.value !== null && email) {
    if (isRequired(email?.value)) error.email = t('general.validations.required')
    else if(new RegExp('^[+]$').test(email?.value?.[0])) {
      if(!(new RegExp('^[+]+[0-9]+$').test(email?.value))) error.email = t('general.validations.wrongPhoneNumber')
      else if(min(email?.value, 11)) error.email = `${t('general.validations.min')} 11 ${t('general.validations.character')}`
      else if(max(email?.value, 18)) error.email = `${t('general.validations.max')} 17 ${t('general.validations.character')}`
    }
    else if (!isEmail(email?.value)) error.email = t('general.validations.wrongEmail')
  }

  // confirm password
  if (confirmPassword?.value !== null && confirmPassword) {
    if (isRequired(confirmPassword?.value)) error.confirmPassword = t('general.validations.required')
    else if (!isPassword(confirmPassword?.value)) error.confirmPassword = t('general.validations.wrongPassword')
    else if (min(confirmPassword?.value, 8)) error.confirmPassword = `${t('general.validations.min')} 8 ${t('general.validations.character')}`
    else if (max(confirmPassword?.value, 10)) error.confirmPassword = `${t('general.validations.max')} 10 ${t('general.validations.character')}`
    else if (confirmPassword?.value !== password?.value) error.confirmPassword = t('general.validations.confirmPassword')
  }
  // password
  if (password?.value !== null && password) {
    if (isRequired(password?.value)) error.password = t('general.validations.required')
    else if (!isPassword(password?.value)) error.password = t('general.validations.wrongPassword')
    else if (min(password?.value, 8)) error.password = `${t('general.validations.min')} 8 ${t('general.validations.character')}`
    else if (max(password?.value, 10)) error.password = `${t('general.validations.max')} 10 ${t('general.validations.character')}`
  }

  if (currentPassword?.value !== null && currentPassword) {
    if (isRequired(currentPassword?.value)) error.currentPassword = t('general.validations.required')
    else if (!isPassword(currentPassword?.value)) error.currentPassword = t('general.validations.wrongPassword')
    else if (min(currentPassword?.value, 8)) error.currentPassword = `${t('general.validations.min')} 8 ${t('general.validations.character')}`
    else if (max(currentPassword?.value, 10)) error.currentPassword = `${t('general.validations.max')} 10 ${t('general.validations.character')}`
  }

  return error
}
