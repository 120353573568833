import { Color } from '../../../utils/theme/types/color'

export const useStyles = (color: Color) => ({
  navBar: {
    backgroundImage: `linear-gradient(to right, ${color.color2}, ${color.primary}, ${color.color1})`,
    display: 'flex',
    flexDirection: 'row',
    height: '60px',
  },
  menu: {
    width: 'fit-content',
    color: color.color5,
  },
  flexGrow: {
    flexGrow: 1,
  },
  badge: {
    '& .MuiBadge-badge': {
      background: color.color19,
      color: color.color25,
    },
  },
  leftTools: {
    marginRight: '20px',
    alignSelf: 'flex-end',
    flexWrap: 'wrap',
    height: '100%',
    display: 'flex',
    gap: 2,
    flexDirection: 'row',
    alignItems: 'center',
  },
  img: {
    width: '20px',
    height: '20px',
  },
  bell: {
    color: color.color5,
    '&.MuiIconButton-root': {
      width: '30px',
      height: '30px',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '30px',
      width: 'inherit',
      height: 'inherit',
    },
  },
  flag: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    overflow: 'hidden',
    '& img': {
      objectFit: 'cover',
    },
  },
  buildContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 2,
    background: color.color7,
    padding: '10px',
    borderRadius: '4px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: '20px',
    padding: '0 10px '
  },
  headerTitle: {
    color: color.color22,
    fontSize: '1.2rem',
    fontWeight: '500'
  },
  headerButton: {
    color: color.color16,
    fontSize: '.9rem',
  },
  containerTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  refresh: {
    color: color.color22,
  }
})
