import { Color } from '../../../../utils/theme/types/color'

export const useStyles = (color: Color) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
  },
  table: {
    flex: 0.8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: '50px',
    paddingTop: '50px',
    width: 'inherit',
  },
  userDetail: {
    flex: 0.2,
  },
  menuIcons: {
    marginLeft: '5px',
    fontSize: '18px',
    '& .MuiSvgIcon-root': {
      fontSize: '18px',
    },
  },
  text: {
    color: color.color13,
    fontSize: '16px',
    fontWeight: '500'
  },
  section: {
    marginBottom: '32px',
  },
  title: {
    fontSize: '20px',
    fontWeight: '600',
    color: color.color13,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    '&.flex-j-e': {
      justifyContent: 'end',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
    position: 'relative',
  },
  clean: {
    fontSize: '2rem',
    padding: '6px',
    '& .MuiSvgIcon-root': {
      fontSize: '1em',
    },
    '&.add': {
      color: color.color4,
      fontSize: '2.4rem',
      padding: '0px',
      paddingRight: '2px',
    },
  },
  hide: {
    visibility: 'hidden',
  },
})
