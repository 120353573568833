import { Color } from '../../../../utils/theme/types/color'

export const useStyles = (color: Color, create?: boolean) => ({
  container: {
    height: '100%',
    width: '100%',
    paddingTop: '50px',
    paddingRight: !!create ? '40px' : 0,
    '& .crud-filter-container': {
      paddingRight: '50px',
    },
  },
  constainerAction: {
    display: 'flex',
    columnGap: '12px',
    justifyContent: 'center',
  },
  title: {
    fontSize: '20px',
    fontWeight: '600',
    color: color.color13,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    '&.flex-j-e': {
      justifyContent: 'end',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
    position: 'relative',
  },
  eye: {
    color: color.color4,
  },
  edit: {
    padding: 0,
    color: color.color4,
  },
  delete: {
    padding: 0,
    color: color.color19,
  },
  section: {
    marginBottom: '32px',
  },
  clean: {
    fontSize: '2rem',
    padding: '6px',
    '& .MuiSvgIcon-root': {
      fontSize: '1em',
    },
    '&.add': {
      color: color.color4,
      fontSize: '2.4rem',
      padding: '0px',
      paddingRight: '2px',
    },
  },
  hide: {
    visibility: 'hidden',
  },
})
