import { searchInModeLower } from '../../../../../../../utils/validate/validate'

const filterTable = (value: Record<string, any>, list: any[]) => {
  const from = value.from?.valueOf()
  const to = value.to?.valueOf()
  return list.filter((item) => {
    return (
      (from ? new Date(from).setHours(0, 0, 0, 0) <= item.createTime?.value : true) &&
      (to ? new Date(to).setHours(23, 59, 59, 999) >= item.createTime?.value : true) &&
      searchInModeLower([item?.area?.label, item?.name, item?.email, item?.phone]?.join(' '), value?.search) &&
      (!!value?.areas?.length ? value?.areas.some((areasItems: any) => areasItems.value == item.area?.value) : true) /*  &&
      (value?.users instanceof Array ? (value?.users.length > 0 ? value?.users.find((e1: any) => item.users.find((e2: any) => `${e2.firstName} ${e2.lastName}` == e1.label)) : true) : true) */
    )
  })
}

// submit = async (data: ObjectGeneric, t: TFunction<'translation', undefined>, id: number | undefined) => {
//   let snackbarBody = {
//     open: false,
//     type: '',
//     message: '',
//   }

//   const { name, phone, email, users } = data
//   let body = {} as any

//   body = {
//     name,
//     description: null,
//     phone,
//     email,
//     statusId: 1,
//     users: users?.map((e: any) => e.value),
//   }

//   let response: ResponseDepartment

//   if (id) {
//     response = await departmentService(httpFetch).updateDepartment(body, id)
//   } else {
//     response = await departmentService(httpFetch).createDepartment(body)
//   }

//   if (response.errors) {
//     snackbarBody = {
//       open: true,
//       type: Types.ERROR as string,
//       message: handleAllErrors(response?.errors?.[0]?.code ?? '', t, `department.snackbar.${id ? 'update' : 'create'}.error`),
//     }
//   } else {
//     snackbarBody = {
//       open: true,
//       type: Types.SUCCESS as string,
//       message: t(`department.snackbar.${id ? 'update' : 'create'}.success`),
//     }
//   }

//   return {
//     snackbarBody,
//     response,
//   }
// }


export { filterTable }
