import { FileDownload } from '@mui/icons-material'
import { Box, Grid, IconButton, Paper, Typography } from '@mui/material'
import { useState } from 'react'
import { httpFetch } from '../../utils/crud'
import { Types } from '../../utils/enums'
import { Color } from '../../utils/theme/types/color'
import { onChange } from '../../utils/types'
import { ObjectGeneric } from '../../utils/types/generals'
import SimpleFile from '../dinamicForm/simpleFile'
import Loader from '../loader'
import SimpleModal from '../modal/simpleModal'
import SnackSimple from '../snackbars/simple'
import { filesValidate } from './initialState'
import { useStyles } from './styles'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { storesService } from '../../service/stores'
import { UserService } from '../../service'
import { icons } from '../../utils/icons'

export interface Props {
  colors: Color
  children: any
  moduleName: string
  goBack: any
  uploadDone?: any
}

const MassiveUpload = ({ colors, children, moduleName, goBack, uploadDone }: Props) => {
  const [dataUpload, setDataUpload] = useState<Blob | undefined>()
  const [dataUpload2, setDataUpload2] = useState<Blob | undefined>()
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [allErrors, setAllErrors] = useState<ObjectGeneric>({})
  const [openModalCSV, setOpenModalCSV] = useState(false)
  const [errorMassiveLoad, setErrorMassiveLoad] = useState<any>(null)
  const [loadUpload, setLoadUpload] = useState(true)
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
    type: Types.SUCCESS as string,
  })
  const handleCloseSnackbar = (value: boolean) => setOpenSnackbar({ ...openSnackbar, open: value })
  const { t } = useTranslation()

  const handleUploadFile: onChange = ({ target }) => {
    const { value } = target

    if (filesValidate.correctTypeFile.includes(value?.type)) {
      if (target.name == 'dataUpload') setDataUpload(value)
      if (target.name == 'dataUpload2') setDataUpload2(value)

      setAllErrors({
        ...allErrors,
        [target.name]: null,
      })
    } else {
      setDataUpload(undefined)
      setAllErrors({
        ...allErrors,
        [target.name]: t('general.validations.wrongFile'),
      })
    }
  }

  const handleUpload = async () => {
    let current = null
    let formData = new FormData()

    if (dataUpload) {
      current = 'dataUpload'
      formData.append('', dataUpload as Blob)
      setLoading(true)
    }
    if (dataUpload2) {
      current = 'dataUpload2'
      formData.append('', dataUpload2 as Blob)
      setLoading2(true)
    }

    if (current) {
      setLoadUpload(true)

      let response, responseJson

      if (moduleName == 'unidades') response = await storesService(httpFetch).importStores(formData)
      else response = await UserService(httpFetch).importUsers(formData)

      responseJson = await response.json()

      let code
      if (responseJson?.errors) code = responseJson?.errors[0]?.code
      else code = responseJson.code

      setLoading(false)
      setLoading2(false)
      setLoadUpload(false)

      if (response?.status === 200) {
        if (code === '01') {
          setAllErrors({
            ...allErrors,
            [current]: t('general.invalidateTemplate'),
          })
        }
        if (code === '02') {
          setOpenModalCSV(false)
          setOpenSnackbar({
            open: true,
            type: Types.SUCCESS,
            message: t(`general.successBatch`),
          })

          uploadDone && uploadDone()
        }
        if (code === '03') {
          setDataUpload(undefined)
          let error = { ...responseJson }

          if (moduleName === 'unidades') {
            error.itemsCorrect = responseJson.data.successStores.length
            error.itemsIncorrect = responseJson.data.failedStores.length
          }
          if (moduleName === 'usuarios') {
            error.itemsCorrect = responseJson.data.successUsers.length
            error.itemsIncorrect = responseJson.data.failedUsers.length
          }
          setErrorMassiveLoad(error)
          setOpenModalCSV(true)
        }
        if (code === '04') {
          setAllErrors({
            ...allErrors,
            [current]: t('general.fileEmptyError'),
          })
        }
      } else if (response?.status === 400) {
        if (code === '01') {
          setAllErrors({
            ...allErrors,
            [current]: t('general.invalidateTemplate'),
          })
        }
        if (code === '04') {
          setAllErrors({
            ...allErrors,
            [current]: t('general.fileEmptyError'),
          })
        }
      }
    } else {
      setLoading(false)
      setLoading2(false)
    }
  }

  const handleCloseModalUpload = () => {
    setOpenModalCSV(false)
    setDataUpload(undefined)
    setDataUpload2(undefined)
    setAllErrors({
      ...allErrors,
      dataUpload: null,
      dataUpload2: null,
    })
  }

  // const downloadLocale = (viewDocument?: string) => {
  //   window.open(viewDocument ?? process.env.REACT_APP_TEMPLATE_XLSX, 'blank')
  // }

  const styles = useStyles(colors)
  return (
    <>
      <SnackSimple close={handleCloseSnackbar} open={openSnackbar.open} type={openSnackbar.type} message={openSnackbar.message} />
      <Box sx={styles.container}>
        <Box sx={{ position: 'relative' }}>
          <IconButton sx={{ position: 'absolute', color: '#24a9e2' }} onClick={() => goBack()}>
            {icons('arrow-left-fill')}
          </IconButton>
          <Typography className="title">{t('MassiveUpload.loadMassiveOf') + ' ' + moduleName}</Typography>
        </Box>

        <Grid container spacing={2} className="section">
          <Grid item xs={12} md={6}>
            <Paper sx={{ padding: 2, marginLeft: '2px' }}>
              <Typography className="title subtitle"> {t('MassiveUpload.downloadBaseTemplate')} </Typography>
              <Typography className="title info">{`${t('MassiveUpload.configureTemplate')} ${moduleName}`}</Typography>
              {children}
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper sx={{ padding: 2, marginLeft: '2px' }}>
              <Typography className="title subtitle">{`${t('MassiveUpload.massiveImport')} ${moduleName}`}</Typography>
              <Typography className="title info"> {`${t('MassiveUpload.attachFile')} ${moduleName}`} </Typography>

              <Box sx={styles.file}>
                <SimpleFile
                  errors={allErrors}
                  notImage
                  onChange={handleUploadFile}
                  item={{
                    label: 'general.tags.loadCsv',
                    translate: true,
                    name: 'dataUpload',
                    value: null,
                    validate: {
                      required: true,
                    },
                    rest: {
                      accept: filesValidate.accept,
                    },
                  }}
                  values={dataUpload}
                  customeIcon="add"
                />
              </Box>

              <LoadingButton loading={loading} sx={{ mt: 2 }} variant="contained" onClick={handleUpload} disabled={!dataUpload}>
                {t('MassiveUpload.importData')}
              </LoadingButton>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <SimpleModal
        sx={styles.simpleModal}
        open={openModalCSV}
        title={undefined}
        loadingButton
        disableButton={false}
        buttonText={t('general.importData')}
        cancelText={t('general.cancel')}
        color={colors}
        back={() => {
          handleCloseModalUpload()
        }}
        next={handleUpload}
        removeButtons={true}
        close={() => {
          handleCloseModalUpload()
        }}
        children={
          <Box sx={styles.uploadFile}>
            {!errorMassiveLoad && !loadUpload && (
              <>
                <Box sx={styles.wFull}>
                  <Typography variant="body1" sx={styles.infoTitle}>
                    {t('MassiveUpload.verificationOf')}
                    <br />
                    {`${t('MassiveUpload.massiveUploadOf')} ${moduleName}`}
                  </Typography>
                </Box>

                <Loader />
              </>
            )}

            {errorMassiveLoad && !loadUpload && (
              <>
                <Box sx={styles.wFull}>
                  <Typography variant="body1" sx={[styles.infoTitle, styles.left]}>
                    {t('MassiveUpload.resultsOf')}
                    <br />
                    {`${t('MassiveUpload.massiveUploadOf')} ${moduleName}`}
                  </Typography>
                </Box>
                <Box sx={styles.wFull}>
                  <Typography sx={[styles.infoText, styles.left]}>{`${t('MassiveUpload.resultsInfo')} ${moduleName} ${t('MassiveUpload.sended')}`}</Typography>
                </Box>

                <Box sx={styles.errorInfo}>
                  <Box sx={styles.green}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>{errorMassiveLoad?.itemsCorrect}</Typography>
                    <Typography sx={{ fontSize: 12, textTransform: 'capitalize' }}>{moduleName} cargadas</Typography>
                  </Box>
                  <Box sx={styles.red}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>{errorMassiveLoad?.itemsIncorrect}</Typography>
                    <Typography sx={{ fontSize: 12 }}>{t('MassiveUpload.registersWithErrors')}</Typography>
                  </Box>
                </Box>

                <Paper elevation={3} sx={{ p: 2, mt: 2, width: '100%' }}>
                  <Typography sx={{ fontSize: 12, color: '#707070', fontWeight: 600 }}>{t('MassiveUpload.conflictResolution')}</Typography>

                  <Box
                    onClick={() => {
                      const link = document.createElement('a')
                      link.href = errorMassiveLoad.data.url
                      link.click()
                    }}
                    sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '5px 0 20px 0', cursor: 'pointer' }}
                  >
                    <FileDownload sx={styles.downloadIcon} />
                    <Typography sx={styles.download}>{t('MassiveUpload.downloadUnloadedRecords')}</Typography>
                  </Box>

                  <Box sx={styles.file}>
                    <SimpleFile
                      errors={allErrors}
                      notImage
                      onChange={handleUploadFile}
                      item={{
                        label: 'general.tags.loadCsv',
                        translate: true,
                        name: 'dataUpload2',
                        value: null,
                        validate: {
                          required: true,
                        },
                        rest: {
                          accept: filesValidate.accept,
                        },
                      }}
                      values={dataUpload2}
                      customeIcon="add"
                    />
                  </Box>

                  <LoadingButton
                    loading={loading2}
                    sx={{ mt: 1 }}
                    onClick={() => {
                      handleUpload()
                    }}
                    variant="contained"
                    disabled={!dataUpload2 ? true : false}
                  >
                    {t('MassiveUpload.reintent')}
                  </LoadingButton>
                </Paper>
              </>
            )}

            {loadUpload && (
              <>
                <Box sx={styles.wFull}>
                  <Typography variant="body1" sx={styles.infoTitle}>
                    Comprobación de <br />
                    {`Carga Masiva de ${moduleName}`}
                  </Typography>
                </Box>
                <Box sx={styles.wFull}>
                  <Typography sx={styles.infoText}>Estamos haciendo la carga masiva, por favor espere. Este proceso puede tomar unos minutos</Typography>
                </Box>

                <Loader />
              </>
            )}
          </Box>
        }
      />
    </>
  )
}

export default MassiveUpload
