import { Color } from '../../../utils/theme/types/color'

export const useStyles = ({ colors, value }: { colors: Color; value: string }) => {
  return {
    inputHiddenColors: {
      border: '1px solid red',
      visibility: 'hidden',
      position: 'absolute',
      height: 30,
      width: 30,
      left: 15,
      top: 23,
    },
    colorPickerCont: {
      '& .MuiSvgIcon-root': {
        color: colors.color9,
      },
      '& .MuiInputBase-root': {
        color: colors.color9,
        '& .MuiFormControl-root': {
          marginRight: '12px',
          width: '33px',
        },
        '& .MuiOutlinedInput-input.Mui-disabled': {
          '-webkit-text-fill-color': colors.color9,
          fontWeight: '600',
        },
        '& .MuiInputBase-input': {
          height: '25px',
          width: "calc(100% - 90px)"
        },
      },
      '& .square': {
        background: value ?? '#000',
        width: '20px',
        height: '20px',
        marginRight: '8px',
        borderRadius: '50%',
      },
      width: { sx: 'auto', lg: 'auto' },
    },
  }
}
