import { useTheme } from "@mui/material";
import { Color } from "../../utils/theme/types/color";

const useStyles = (widthBar:number | undefined, color:Color) => {
  const { breakpoints } = useTheme()

  return {
    iconDisabled: {
      backgroundColor: color.color5,
      zIndex: 1,
      border: "#ccc solid 1px",
      width: "25px",
      height: "25px",
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      color: "#ccc",
    },
    iconActive: {
      background: color.color4,
      border:
        color.color4,
    },
    iconComplete: {
      border:
        color.color4 + " solid 1px",
      background: color.color4,
    },
    roundedIcon: {
      "&.MuiSvgIcon-root": {
        fontSize: "21px",
      },
    },
    roundedActive: { color: color.color5 },
    roundedComplete: { color: color.color5 },

    stepper: {
      background: "none",
      overflow: 'hidden',
      padding: "10px 0 0 0px",
      "& .MuiStepConnector-root": {
        "& .MuiStepConnector-line": {
          borderTopWidth: "2px",
        },
      },
    },
    step: {
      "&.MuiStep-root": {
        padding: 0,
        width: "auto",
      },
    },
    stepLabel: {
      "&.MuiStepLabel-root": {
        display: "block",
      },
    },
    stepEnd: {
      "&.MuiStep-root": {
        padding: 0,
        position: "relative",
        maxWidth: "27px",
      },
    },
    textStep: {
      textAlign: "start",
      display: "block",
      textTransform: "uppercase",
    },
    textLabelStep: {
      [breakpoints.down(1480)]: {
        width: "100px",
      },

      textAlign: "start",
      display: "block",
      color: color.color4,
    },
    connector: {
      "&.MuiStepConnector-root": {
        left: `-${widthBar}px`,
        right: "0",
        width: `${widthBar}px`,
        top: 13,
        position: "absolute",
      },
      "&.Mui-active": {
        borderColor: color.color4,
      },
    },
    connectorActive: {
      "& span": {
        borderColor: color.color4,
      },
    },
    connectorComplete: {
      "& span": {
        borderColor: color.color4,
      },
    },
  }
};

export default useStyles;
