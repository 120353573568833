import { Box, Typography, IconButton } from '@mui/material'
import { useContext } from 'react'
import { GlobalContext } from '../../../../../../contexts/Global'
import { useStyles } from './styles'
import { icons } from '../../../../../../utils/icons'

const ModelThree = (props: { title: string; description?: string; date?: string; delete?: () => void }) => {
  const { colors } = useContext(GlobalContext)
  const styles = useStyles({ colors })

  return (
    <Box sx={[styles.containerThree]} className="border-bottom">
      <Box sx={[styles.container, !!props?.delete && styles.deleteContainer]}>
        <Typography className="bold full-w">{props.title}</Typography>

        {(!!props.date || !!props.description) && (
          <Typography className="full-w">
            <span className="bold-blue">{props.date ?? ''}</span> {!!props.date && !!props.description ? ` - ` : ''} {props.description ?? ''}
          </Typography>
        )}
      </Box>

      {!!props?.delete && (
        <IconButton
          sx={styles.delete}
          onClick={() => {
            props?.delete?.()
          }}
        >
          {icons('delete')}
        </IconButton>
      )}
    </Box>
  )
}

export { ModelThree }
