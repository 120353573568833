import { useContextState } from "../../../../../../../../../hooks/theme"

const useStyles = () => {
  const { colors } = useContextState()

  return {
    container: {
      height: "100%",
      '& .header-separator': {
        borderBottom: `1px solid ${colors.color13}66`,
        height: "fit-content",
        paddingBottom: "16px"
      },
      '& .left-separator': {
        borderRight: `1px solid ${colors.color13}66`,
        height: "calc(100% - 75px)",
        overflowY: "auto"
      },
      '& .filter-title': {
        padding: '0px 20px',
        fontSize: "18px",
        marginBottom: "16px"
      }
    },
    formContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: 2,
      padding: "0px 16px 0px 20px"
    },
    descriptionContainer: {
      display: "flex",
      flexDirection: "column",
    },
    title: {
      fontSize: '1.2rem',
    },
    subtitle: {
      fontSize: '1rem',
      color: colors.color16
    },
    intervale: {
      fontSize: '.8rem',
      color: colors.color16
    },
    header: {
      width: "100%",
      display: 'flex',
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0px 20px 0px"
    },
    body: ({ item }: { item: number }) => ({
      width: "100%",
      '& .table-container.table-container': {
        maxHeight: "540px",
        overflowX: "unset"
      },
      padding: "0px 20px 16px 0px",
      overflowY: "auto"
    }),
    close: {
      fontSize: '2rem'
    }
  }
}

export { useStyles }
