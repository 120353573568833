import { Box, IconButton, Typography } from "@mui/material"
import { BarListProps } from "./types"
import { BarGraphic } from "../barGraphic"
import { useStyles } from "./styles"
import { useTranslation } from "react-i18next"
import { useDinamicFormRefs } from "../../../../../../hooks/refs/dinamicFormRefs"
import { icons } from "../../../../../../utils/icons"
import { DinamicForm } from "../../../../../../components/dinamicForm"
import { ChipsList } from "../../../../../../components/chipsList"
import Loader from "../../../../../../components/loader"

const BarList = (props: BarListProps) => {
  const { ref } = useDinamicFormRefs()
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <Box sx={styles.graph({ flex: !!props.flex })}>
      <Box sx={styles.headerProgress}>
        <Box sx={styles.header}>
          <Typography sx={styles.title}>{props.title}</Typography>

          <IconButton
            sx={styles.refresh}
            onClick={() => {
              props.refresh?.();
            }}
          >
            {icons("refresh")}
          </IconButton>
        </Box>

        <Box sx={styles.headerInputs}>
          <DinamicForm ref={ref} errors={props.dataFilter.errors} legacyErrors input={Object.values(props.input)} values={props.dataFilter.filterData} onChange={props.dataFilter.onChange} />
        </Box>

        <Box sx={styles.chipContainer}>
          {
            !!props.name && (
              <ChipsList colorsByChips={props.colorsByChips} value={props.dataFilter.filterData[props.name]} handleDelete={(option) => {
                if (ref.current?.filterSelected.current?.deleteChips) {
                  ref.current?.filterSelected.current?.deleteChips(option)
                }
              }} />
            )
          }
        </Box>
      </Box>

      {props?.children}

      {!!props.isLoading ? <Box sx={props?.customeStyles ?? { width: '100%', }}><Loader /> </Box> :
        (
          <Box sx={[styles.containerGraph({ existData: !!props.data?.length }), props?.customeStyles]}>
            {!!props.titleGraphic && <Typography sx={styles.titleGraph}>{props.titleGraphic}</Typography>}

            {!!props.data?.length ? (
              <BarGraphic customeX={props.customeX} doesnotRemoveTurn={props.doesnotRemoveTurn} xHeader={props.xHeader} ref={props.barRef} getItem={props.getItem} customeTooltip={props.customeTooltip} int={props.int} simpleTooltip={props.simpleTooltip} brush separator={props.separator} legend={props.legend} data={props.data} indicators={props.list} />

            ) : (
              <Typography sx={styles.noData}>{t('reportFolio.detail.general.noInformation')}</Typography>
            )}
          </Box>
        )
      }
    </Box>
  )
}

export { BarList }
