import { Box, Button, Grid, Typography } from "@mui/material"
import { useStyles } from "./styles"
import { useTranslation } from "react-i18next"
import { PercentageStatus } from "../../../fragments/indicators/typing/enums"
import { format, parse } from "date-fns"
import { Constants } from "../../../../../../../utils/constants"
import { es } from "date-fns/locale"
import { TooltipType1Props } from "./types"

const columnAmountByLength = (amount: number) => {
  let gridsSize = 12
  if (amount == 2) { gridsSize = 6 }
  if (amount > 2) { gridsSize = 4 }

  return gridsSize
}
const TooltipType1 = (props: TooltipType1Props) => {
  const styles = useStyles()
  const parseDate = (parse(props.title, Constants.dateFormat, new Date()))
  const dateFormat = format(parseDate, Constants.humanFormatShort, { locale: es })
  const { t } = useTranslation()
  const itemList = Object.entries(props.data ?? {})
  let gridsSize = columnAmountByLength(itemList.length)
  const requestData = () => {
    props.getItemOfGraphic({ date: props.title })
  }
  return (
    <Box {...props.containerBoxProps} sx={styles.containerTooltip({ itemAmount: itemList.length })}>
      <Box sx={styles.header}>
        <Typography component="p" id="CustomTooltip" sx={styles.titleTooltip}>
          {dateFormat ?? ""}
        </Typography>

        <Button onClick={requestData} variant="text" className="see-detail">{t('general.seeDetail')}</Button>
      </Box>

      <Grid container spacing={2}>
        {
          itemList.map(([key, value]) => {
            return (
              <Grid item xs={gridsSize} sx={styles.containerItems} key={`${key}-${props.title}`}>
                <Typography sx={styles.mainName({ colors: props.listColorsByChips[key] })}>{value?.title}</Typography>

                {
                  Object.entries(value?.data).map(([key2, value2]) => (
                    <Box sx={styles.containerTitle}>
                      <Typography sx={styles.detail()}>{t(`${props.path}.${key2}`)}: {value2.label}</Typography>

                      {
                        !!value2?.status && (
                          <Box
                            sx={styles.percentageStatus({
                              color: value2?.status as PercentageStatus,
                            })}
                          />
                        )
                      }
                    </Box>
                  ))
                }
              </Grid>
            )
          })
        }
      </Grid>
    </Box>
  )
}
export type { TooltipType1Props }
export { TooltipType1 }
