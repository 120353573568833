import { Box, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { BaseInput, CustomSelect, Images } from '../../../../../components'
import AddImage from '../../../../../components/addImage'
import MultiSelectSimple from '../../../../../components/dinamicForm/multiSelectSimple'
import Loader from '../../../../../components/loader'
import useGlobalErrors from '../../../../../hooks/erors'
import { Field } from '../../../../../utils/enums/fields'
import { images } from '../../../../../utils/images'
import { isNumber } from '../../../../../utils/validate/validate'
import { gender, username } from '../initialState'
import { useStyles } from './styles'

const Form = ({ btnLoading, data, crudChange, allErrors, disabledUserName, statusList, companyList, isAdminm, divisionCompani, userTypeList }: any) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const { errors } = useGlobalErrors({ data, t })
  
  return btnLoading ? (
    <Box sx={styles.constainer}>
      <Loader />
    </Box>
  ) : (
    <Grid container rowSpacing={2.5} columnSpacing={1}>
      <Grid container item lg={12} justifyContent="center">
        <AddImage
          icon={<Images sx={styles.img} src={images.profileBtn} />}
          image={data?.picture?.value ?? images.profileBtn}
          name={data?.picture?.name}
          label={data?.picture?.label}
          size={{
            width: '120px',
            height: '120px',
          }}
          getFile={crudChange}
        />
      </Grid>
      <Grid item lg={10}>
        <BaseInput
          name={data?.curp?.name}
          type="alphaNumeric"
          value={{
            curp: data?.curp,
          }}
          onChange={crudChange}
          errors={allErrors}
          inputProps={{
            maxLength: 19,
          }}
        />
      </Grid>
      <Grid item lg={12}>
        <BaseInput
          name={data?.firstName?.name}
          type="text"
          value={{
            firstName: data?.firstName,
          }}
          onChange={crudChange}
          errors={errors}
          inputProps={{
            maxLength: 100,
          }}
        />
      </Grid>
      <Grid item lg={12}>
        <BaseInput
          name={data?.lastName?.name}
          type="text"
          value={{
            lastName: data?.lastName,
          }}
          onChange={crudChange}
          errors={errors}
          inputProps={{
            maxLength: 100,
          }}
        />
      </Grid>
      <Grid item lg={6}>
        <CustomSelect isRequired errors={errors} name={data?.gender?.name} label={`${data?.gender?.label}`} onChange={crudChange} items={gender} selected={data?.gender?.value} />
      </Grid>

      <Grid item lg={6}>
        <CustomSelect disabled={!!disabledUserName} name={data?.username?.name} label={`${data?.username?.label}`} onChange={crudChange} items={username} selected={data?.username?.value} />
      </Grid>

      <Grid item lg={12}>
        <BaseInput
          name={data?.email?.name}
          value={{
            email: {
              value: data?.email?.value,
              label: `${data?.email?.label} *`,
            },
          }}
          onChange={crudChange}
          disabled={disabledUserName === Field.EMAIL}
          errors={errors}
          inputProps={{
            maxLength: 150,
          }}
        />
      </Grid>
      {!disabledUserName && (
        <Grid item lg={12}>
          <BaseInput
            name={data?.password?.name}
            value={{
              password: {
                value: data?.password?.value,
                label: `${data?.password?.label}`,
              },
            }}
            onChange={crudChange}
            errors={errors}
            inputProps={{
              maxLength: 150,
            }}
            typeField="password"
          />
        </Grid>
      )}

      <Grid item lg={12}>
        <MultiSelectSimple errors={errors} name={data.companyId.name} label={data.companyId.label} onChange={crudChange} items={companyList ?? []} selected={data.companyId.value} />
      </Grid>
      <Grid item lg={12}>
        <MultiSelectSimple
          name={data?.divisions?.name}
          label={data?.divisions?.label}
          onChange={crudChange}
          items={divisionCompani ?? []}
          selected={data?.divisions?.value}
        />
      </Grid>
      <Grid item lg={12}>
        <MultiSelectSimple errors={allErrors} name={data?.userType?.name} label={data.userType?.label} onChange={crudChange} items={userTypeList ?? []} selected={data.userType?.value} />
      </Grid>
      <Grid item lg={12}>
        <BaseInput
          name={data?.phone?.name}
          value={{
            phone: {
              value: data?.phone?.value || '+52',
              label: `${data?.phone?.label}`,
            },
          }}
          onChange={({ target }) => {
            const { name, value } = target
            if (isNumber(value.replace('+', '')) && value !== '-' && value > 0) {
              if(value.includes('+52')) {
                crudChange({
                  target: {
                    name: name,
                    value: value.length <= 3 ? '+52' : value,
                  },
                })
              }
            }
          }}
          errors={errors}
        />
      </Grid>

      <Grid item lg={12}>
        <CustomSelect errors={errors} disabled={!!isAdminm} name={data?.status?.name} label={`${data?.status?.label}`} onChange={crudChange} items={statusList} selected={data?.status?.value} />
      </Grid>
    </Grid>
  )
}

export default Form
