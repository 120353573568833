import { Status } from '../../../../../../utils/enums'
import { Color } from '../../../../../../utils/theme/types/color'
import { SxGeneral } from '../../../../../../utils/types/generals'

export const useStyles = ({ color }: { color: Color }): SxGeneral => {
  return {
    container: {
      width: '100%',
      height: '100%',
      paddingRight: '50px',
      paddingTop: '50px',
      paddingBottom: '20px',
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'relative',
      '& .w-full': {
        width: '100%',
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '20px',
    },
    title: {
      fontSize: '20px',
      fontWeight: '600',
      color: color.color13,
    },
    clean: {
      fontSize: '2rem',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    hide: {
      visibility: 'hidden',
    },
    // FOLIO
    containerFolioAmount: {
      display: 'flex',
      columnGap: '10px',
      alignItems: 'center',
    },
    foliosAmount: {
      maxWidth: '100px',
      fontSize: '14px',
      color: color.color9,
      fontWeight: '600',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
    },
    iconAlert: {
      '& .MuiIcon-root': {
        fontSize: '1.7rem',
      },
      '& img': {
        width: '100%',
      },
    },
    headetText: {
      color: color.color5,
      fontWeigt: '800',
      fontSize: '12px',
    },
    timeElapsed: {
      color: color.color5,
      fontWeigt: '800',
      fontSize: '12px',
      width: '150px'
    },
    wordBreak: {
      maxWidth: '150px',
      wordBreak: 'break-word',
      whiteSpace: 'break-spaces',
    },
    // UNIT
    containerUnit: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    unitTitle: {
      fontSize: '14px',
      fontWeight: '600',
      color: color.color9,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
    },
    unitContent: {
      fontSize: '12px',
      color: color.color10,
      width: '100%',
    },
    user: {
      fontSize: '12px',
      fontStyle: 'italic',
      color: color.color10,
      width: '100%',
    },
    // STATUS
    containerStatus: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    baseMenu: {
      '& .MuiPopover-paper.MuiPopover-paper.MuiPopover-paper.MuiPopover-paper.MuiPopover-paper.MuiPopover-paper.MuiPopover-paper': {
        boxShadow: `1px 1px ${color.color13}30`,
      },
    },
    status: {
      fontWeight: '600',
      fontSize: '14px',
      width: '100px',
      textAlign: 'center',
    },
    [Status.ACTIVE]: {
      '&.MuiTypography-root': {
        color: color.color19,
      },
    },
    [Status.REJECTED]: {
      '&.MuiTypography-root': {
        color: color.color34,
      },
    },
    [Status.AWAIT_SOLUTION]: {
      '&.MuiTypography-root': {
        color: color.color35,
      },
    },
    [Status.RESPONDED]: {
      '&.MuiTypography-root': {
        color: color.color35,
      },
    },
    [Status.APPROVED]: {
      '&.MuiTypography-root': {
        color: color.color8,
      },
    },
    [Status.CANCEL]: {
      '&.MuiTypography-root': {
        color: color.color4,
      },
    },
    moreOptions: {
      // position: 'absolute',
      // top: '-20px',
      // right: '0px',
    },
    toggleGroup: {
      borderRadius: '40px',
      overflow: 'hidden',
      border: `1px solid ${color.color20}`,
      '& .MuiToggleButton-root': {
        color: color.color20,
        '&:hover': {
          backgroundColor: color.color3,
        },
        '& .MuiTypography-root': {
          fontSize: '14px',
        },
        '&.Mui-selected': {
          color: color.color5,
          backgroundColor: color.color20,
          '&:hover': {
            backgroundColor: `${color.color20}dd`,
          },
        },
      },
    },
    section: {
      marginBottom: '32px',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
      '&.flex-j-e': {
        justifyContent: 'end',
      },
    },
    resendReportContainer: {
      position: 'relative',
      padding: '30px',
    },
    close: {
      position: 'absolute',
      top: '10px',
      right: '10px',
    },
    titleSend: {
      fontSize: '17px',
      fontWeight: '600',
      color: color.color22,
      marginTop: '5px',
      marginBottom: '10px',
    },
    modal: {
      borderRadius: 0,
      width: '500px',
    },
    resendButton: {
      marginTop: '10px',
      display: 'flex',
      justifyContent: 'end',
      width: '100%',
    },
    button: {
      background: color.primary,
      '&:hover': {
        background: `${color.primary}d0`,
      }
    },
    textItem: {
      fontSize: '12px',
      fontWeight: '400',
      width: '100%',
      color: color.color10,
      wordBreak: 'break-all',
    },
    textItemTooltip: {
      fontSize: '12px',
      fontWeight: '400',
      width: '100%',
      color: color.color5,
      wordBreak: 'break-all',
    },
    containerIconTooltip: {
      height: '15px',
    },
    containerTooltipAsk: {
      width: '170px',
      display: 'flex',
      columnGap: '10px',
    }, listAnswers: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    viewMore: {
      fontSize: '1rem',
      color: color.color16,
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
  }
}
