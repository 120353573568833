import { useContext, useState, Fragment } from 'react'
import { Box, Grid, IconButton, Typography, Tab, Icon, Button, Rating } from '@mui/material'
import { useStyles } from './styles'
import { GlobalContext } from '../../../../../../contexts/Global'
import { useNavigate, useParams } from 'react-router-dom'
import { icons } from '../../../../../../utils/icons'
import { useTranslation } from 'react-i18next'
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import { useTabs } from '../../../../../../hooks/tabs/useTabs'
import { useEvidences } from '../../../../../../hooks/evidences/useEvidences'
import { EvidenceCard, PriorityStatus } from '../../components'
import { EvidencesTypeValueEnum } from '../../../../../../utils/enums/evidences'
import { RequestErrors, Roles, Status, Types } from '../../../../../../utils/enums'
import { CustomeAccordion, ModelOne, ModelThree, ModelTwo } from '../../components/details'
import { EvidencesConfig, Priority } from '../../typing'
import { getStatus } from '../../../../../../hooks/status'
import { useGetData } from './hooks/useGetData'
import { ApproveReason, ButtonsOption, RejectReason } from './components'
import SimpleModal from '../../../../../../components/modal/simpleModal'
import Loader from '../../../../../../components/loader'
import { reportFolioServices } from '../main/constants/states'
import { CrudTableWithFilter, DetailKeyValue, SnackSimple } from '../../../../../../components'
import { StarBorder } from '@mui/icons-material'
import BaseModal from '../../../../../../components/modal/baseModal'
import { searchInModeLower } from '../../../../../../utils/validate/validate'
import { Table } from '../../../../../../utils/types'
import { httpFetch } from '../../../../../../utils/crud'
import { UserService } from '../../../../../../service'
import { CommentForm } from './components/commentForm'
import { handleAllErrors } from '../../../../../../utils/methods/handleErrors'
import { defaultConfig } from './constants'
import { DetailTypeOfResponse } from '../../../../../../components/detailTypeOfResponse'

const Detail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { colors, state } = useContext(GlobalContext)
  const { role, id: currentUserId } = state
  const { data, loading, snackbarModal, setSnackbarModal, getData, getComments } = useGetData({ id })
  const { left } = data
  const [steps, setSteps] = useState(0)
  const [changeReportFolio, setChangeReportFolio] = useState(false)
  const styles = useStyles({ color: colors })
  const { value, handleChange } = useTabs()
  const { evidences, existEvidences, pagination, handleChangePage } = useEvidences({ evidencesResponse: data?.left?.evidences })
  const [exit, setExit] = useState(false)
  const [loadingUsers, setLoadingUsers] = useState(false)
  const [addComment, setAddComment] = useState(false)
  const [apiBusy, setApiBusy] = useState(false)
  const [raitingWasFailed, setRaitingWasFailed] = useState(false)
  const [userSelected, setUserSelected] = useState<number | null>()
  const [modal, setModal] = useState({
    open: false,
    loading: false,
    data: {
      comment: '',
    },
    errors: {},
  })

  const [modalReassign, setModalReassign] = useState(false)
  const [respondReport, setRespondReport] = useState<EvidencesConfig[] | undefined>()
  const [tableData, setTableData] = useState<Table>({ column: [], row: [] })
  const [evidencesDrawer, setEvidencesDrawer] = useState<{
    open: boolean
    type: {
      equivalence: string[]
      accept: string
    }
    name?: string
    value?: Record<
      string,
      {
        value?: Record<
          string,
          {
            progress?: boolean
            progressAmount?: string
            upload?: boolean
            name: string
            url: string | File
            ref?: any
          }
        >
        seeFiles?: boolean
        expanded?: boolean
        maxCount?: number
        size?: number
        accept?: string
        minAmount?: number
        typeEvidence?: Record<
          string,
          {
            isRequired?: boolean
            type: string
            amount: number
          }
        >
      }
    >
  }>({
    open: false,
    type: {
      equivalence: [],
      accept: '',
    },
  })
  const [values, setValues] = useState<Record<string, { value: null | string; label: string }>>({
    reason: {
      value: null,
      label: '',
    },
  })
  const openModal = () => {
    if (!!raitingWasFailed || left?.status?.id === Status.APPROVED) {
      setSteps(3)
      setRaitingWasFailed(true)
    }
    setModal((prev) => ({
      ...prev,
      open: true,
    }))
  }

  const closeSnackbar = () => {
    setSnackbarModal((prev) => ({
      ...prev,
      openSuuccess: false,
      open: false,
    }))
  }

  const closeModal = () => {
    setSteps(1)
    setModal((prev) => ({
      ...prev,
      open: false,
      loading: false,
    }))
  }

  const submitted = (resp: any) => {
    setChangeReportFolio(false)
    setAddComment(false)
    if (!resp?.errors) getComments()
  }

  const rejectReportFolio = () => {
    setSteps(2)
  }

  const getReason = (data: {
    reason: string
    body: {
      evidences: {
        url: string
        originalname: string
        evidenceTypeId: number
      }[]
      comments: string[]
    }
  }) => {
    setChangeReportFolio(false)
    setModal((prev) => ({
      ...prev,
      loading: true,
    }))

    if (id) {
      let body: Record<string, boolean | string | string[] | {
        url: string;
        originalname: string;
        evidenceTypeId: number;
      }[]> = {
        isWeb: true,
        ...data?.body,
      }

      if (!!respondReport) {
        body = {
          ...body,
          description: data?.reason,
        }
        reportFolioServices
          .answerReportFolio({ id: parseInt(id), body })
          .then((response) => {
            if (!response.errors) {
              setSnackbarModal((prev) => ({
                ...prev,
                message: t('reportFolio.detail.snackbar.responded.success'),
                type: Types.SUCCESS,
                openSuuccess: true,
              }))
              setRespondReport(undefined)
              setValues({
                reason: {
                  value: null,
                  label: '',
                },
              })
              setChangeReportFolio(false)
              setEvidencesDrawer({
                open: false,
                type: {
                  equivalence: [],
                  accept: '',
                },
              })
              closeModal()
            } else {
              if ([RequestErrors.ERR_ALREADY_ANSWERING, RequestErrors.ERR_ALREADY_ANSWERED].includes(response.errors[0].code)) {
                const message = handleAllErrors(response.errors[0].code, t, 'reportFolio.detail.snackbar.responded.error', { name: response.errors[0]?.data?.nameUserAnswering })
                const isAswered = RequestErrors.ERR_ALREADY_ANSWERED == response.errors[0].code
                setSnackbarModal((prev) => ({
                  ...prev,
                  message: `¡Lo sentimos! ${isAswered ? message : ''}`,
                  description: isAswered ? undefined : message,
                  type: Types.ERROR,
                  openSuuccess: true,
                  next: () => {
                    handleSuccess()
                    setRespondReport(undefined)
                    setValues({
                      reason: {
                        value: null,
                        label: '',
                      },
                    })
                    setEvidencesDrawer({
                      open: false,
                      type: {
                        equivalence: [],
                        accept: '',
                      },
                    })
                    setChangeReportFolio(false)
                    closeModal()
                    setSnackbarModal(prev => ({ ...prev, next: undefined, description: undefined, openSuuccess: false }))
                  }
                }))
              } else {
                setSnackbarModal((prev) => ({
                  ...prev,
                  message: handleAllErrors(response.errors[0].code, t, 'reportFolio.detail.snackbar.responded.error', { name: response.errors[0]?.data?.nameUserAnswering }),
                  type: Types.ERROR,
                  open: true,
                }))
              }
            }
          })
          .catch(() => {
            setSnackbarModal((prev) => ({
              ...prev,
              message: t('reportFolio.detail.snackbar.reject.error'),
              type: Types.ERROR,
              open: true,
            }))
          })
          .finally(() => {
            setModal((prev) => ({
              ...prev,
              loading: false,
            }))
          })
      } else {
        body = {
          ...body,
          reasonOfRejection: data?.reason,
        }
        reportFolioServices
          .rejectReportFolio({ id: parseInt(id), body })
          .then((response) => {
            if (!response.errors) {
              setSnackbarModal((prev) => ({
                ...prev,
                message: t('reportFolio.detail.snackbar.reject.success'),
                type: Types.SUCCESS,
                openSuuccess: true,
              }))
              setChangeReportFolio(false)
              closeModal()
              setValues({
                reason: {
                  value: null,
                  label: '',
                },
              })
              setEvidencesDrawer({
                open: false,
                type: {
                  equivalence: [],
                  accept: '',
                },
              })
            } else {
              setSnackbarModal((prev) => ({
                ...prev,
                message: handleAllErrors(response.errors[0].code, t, 'reportFolio.detail.snackbar.reject.error'),
                type: Types.ERROR,
                open: true,
              }))
            }
          })
          .catch(() => {
            setSnackbarModal((prev) => ({
              ...prev,
              message: t('reportFolio.detail.snackbar.reject.error'),
              type: Types.ERROR,
              open: true,
            }))
          })
          .finally(() => {
            setModal((prev) => ({
              ...prev,
              loading: false,
            }))
          })
      }
    }
  }

  const filterRow = (value: { [x: string]: any }, list: any[]) => {
    return list?.filter((item) => (value?.status ? item?.status?.value === value?.status : true) && (searchInModeLower(item?.name, value?.search) || searchInModeLower(item?.curp, value?.search)))
  }

  const getUsers = async () => {
    setLoadingUsers(true)
    const data = await UserService(httpFetch).usersFolio(Number(id))
    setLoadingUsers(false)
    const column: any[] = [
      {
        id: 'curp',
        label: t('general.tags.curp'),
        sortable: true,
        minWidth: 70,
      },
      {
        id: 'name',
        label: t('general.tags.name'),
        sortable: true,
        minWidth: 100,
      },
    ]

    const row: any[] = !data?.errors
      ? data?.map((item: any) => {
        return {
          id: item?.id,
          curp: item?.curp,
          name: `${item.firstName ?? ''} ${item.lastName ?? ''}`,
        }
      })
      : []
    setTableData({ column, row })
  }

  const confirmReassing = async () => {
    setApiBusy(true)

    const responseApprove = await reportFolioServices.reassignReportFolio({
      reportFolioId: id,
      userManageId: userSelected,
    })

    if (!responseApprove?.errors) {
      setSnackbarModal((prev) => ({
        ...prev,
        message: t('reportFolio.detail.snackbar.reassign.success'),
        type: Types.SUCCESS,
        openSuuccess: true,
      }))
    } else {
      setSnackbarModal((prev) => ({
        ...prev,
        message: handleAllErrors(responseApprove.errors[0].code, t, 'reportFolio.detail.snackbar.reassign.error'),
        type: Types.ERROR,
        open: true,
      }))
    }

    setApiBusy(false)
    setModalReassign(false)
  }

  const showButton = () => {
    const raiting = left?.rating?.required && !left?.rating?.rating
    const statusResponded = left?.status?.id === Status.RESPONDED
    const statusRejected = left?.status?.id !== Status.REJECTED
    const statusAwaitSOLUTION = left?.status?.id !== Status.AWAIT_SOLUTION
    const existStoreAnswer = !!data?.right?.answers?.length
    const userId = left?.createBy.id

    let roleValidation = true
    if (role != Roles.ADMIN && userId != currentUserId) {
      roleValidation = false
    }

    return (statusResponded || raiting) && existStoreAnswer && statusRejected && statusAwaitSOLUTION && roleValidation
  }

  const raiting = (data?: { rate: number | null; reason?: string }) => {
    if (id) {
      setModal((prev) => ({
        ...prev,
        loading: true,
      }))

      reportFolioServices
        .ratingReportFolio({ id: parseInt(id), body: { rating: data?.rate, ...(data?.reason ? { ratingComment: data?.reason } : {}) } })
        .then((response) => {
          if (!response?.errors) {
            setRaitingWasFailed(false)
            setSnackbarModal((prev) => ({
              ...prev,
              message: t(`reportFolio.detail.snackbar.${raitingWasFailed ? 'raiting' : 'approve'}.success`),
              type: Types.SUCCESS,
              openSuuccess: true,
            }))

            closeModal()
          } else {
            setSnackbarModal((prev) => ({
              ...prev,
              message: t('reportFolio.detail.snackbar.raiting.error'),
              type: Types.ERROR,
              open: true,
            }))
            setModal((prev) => ({
              ...prev,
              loading: false,
            }))
            setRaitingWasFailed(true)
          }
        })
        .catch(() => {
          setSnackbarModal((prev) => ({
            ...prev,
            message: t('reportFolio.detail.snackbar.raiting.error'),
            type: Types.ERROR,
            open: true,
          }))
          setModal((prev) => ({
            ...prev,
            loading: false,
          }))
        })
        .finally(() => {
          setModal((prev) => ({
            ...prev,
            open: false,
            loading: false,
          }))
        })
    }
  }

  const approve = async (data: { rate: number | null; reason?: string; goToRaiting?: boolean }) => {
    setModal((prev) => ({
      ...prev,
      loading: true,
    }))

    if (!raitingWasFailed) {
      if (id) {
        const responseApprove = await reportFolioServices.approveReportFolio({ id: parseInt(id) })

        if (!responseApprove.errors) {
          if (!!data && !!data?.rate && !data?.goToRaiting) {
            raiting(data)
          } else {
            setSnackbarModal((prev) => ({
              ...prev,
              message: t('reportFolio.detail.snackbar.approve.success'),
              type: Types.SUCCESS,
              open: true,
            }))
            setModal((prev) => ({
              ...prev,
              loading: false,
            }))
            setRaitingWasFailed(true)
            setSteps(3)
          }
        } else {
          setSnackbarModal((prev) => ({
            ...prev,
            message: t('reportFolio.detail.snackbar.approve.error'),
            type: Types.ERROR,
            open: true,
          }))
          setModal((prev) => ({
            ...prev,
            loading: false,
          }))
        }
      }
    }
  }

  const approveFolio = async (data?: { rate: number | null; reason?: string }) => {
    if (!data && left?.rating?.required) {
      setSteps(3)
    } else {
      if (id) {
        if (!!data) {
          approve(data)
        } else {
          approve({ rate: 0, goToRaiting: true })
        }
      }
    }
  }

  const handleExit = () => {
    navigate('/dashboard/report-folio/dashboard')
    setChangeReportFolio(false)
    closeSnackbar()
  }

  const handleSuccess = () => {
    closeSnackbar()
    getData()
  }
  const handleSteps = () => {
    const itemsBySteps: { [x: number]: JSX.Element } = {
      1: (
        <ButtonsOption title={t('reportFolio.detail.buttonOptions.title')} reject={t('reportFolio.detail.general.reject')} approve={t('reportFolio.detail.general.approveAndReject')} approveFolio={approveFolio} rejectReportFolio={rejectReportFolio} />
      ),
      2: <RejectReason responded={!!respondReport} evidencesDrawer={evidencesDrawer} setEvidencesDrawer={setEvidencesDrawer} value={values} setValue={setValues} evidencesConfig={!!respondReport ? respondReport : left?.evidencesConfig} folio={left?.folio} sendReason={getReason} title={!!respondReport ? t('reportFolio.detail.responded.title') : t('reportFolio.detail.reject.title')} />,
      3: (
        <ApproveReason
          button={t('reportFolio.detail.general.send')}
          title={data?.left?.rating?.ratingQuestion ?? t('reportFolio.detail.approve.title')}
          sendReason={(data) => {
            if ((left?.rating?.required || !raitingWasFailed) && left?.status?.id !== Status.APPROVED) {
              approveFolio(data)
            } else {
              setChangeReportFolio(false)
              raiting(data)
            }
          }}
        />
      ),
    }

    return itemsBySteps[steps] ?? <></>
  }

  if (!!loading)
    return (
      <Box sx={styles.container}>
        <Loader />
      </Box>
    )

  return (
    <Box sx={styles.container}>
      <SnackSimple
        close={() => {
          closeSnackbar()
        }}
        open={snackbarModal.open}
        type={snackbarModal.type}
        message={snackbarModal.message}
      />

      <SimpleModal
        removeClose
        open={snackbarModal.openSuuccess}
        description={snackbarModal.description ?? ""}
        close={() => {
          handleSuccess()
          if (snackbarModal?.cancel) { snackbarModal.cancel() }
        }}
        title={snackbarModal.message}
        buttonText={t(`general.${!!snackbarModal.cancel ? 'continue' : 'confirm'}`)}
        color={colors}
        cancelText={t('general.cancel')}
        back={snackbarModal.cancel}
        next={() => {
          if (snackbarModal?.next) {
            snackbarModal.next()
          } else {
            handleSuccess()
          }
        }}
      />

      <BaseModal open={modalReassign} sx={{ width: '800px' }}>
        <Box style={{ padding: '0 30px 20px 30px' }}>
          <IconButton
            sx={styles.closeSuccess}
            onClick={() => {
              setModalReassign(false)
              setUserSelected(null)
              setApiBusy(false)
            }}
          >
            {icons('simple-close')}
          </IconButton>
          <CrudTableWithFilter
            dontRefresh={false}
            tableChecks
            tableRadio={true}
            customSelected={-1}
            colors={colors}
            hidden={{
              informativeCard: true,
              add: true,
            }}
            // defaultChecks={undefined}
            setInformation={(e) => {
              const { checks } = e
              setUserSelected(checks[0])
            }}
            isLoading={loadingUsers}
            filterComponent={{
              title: t('reportFolio.detail.reassign.title'),
              filterTitle: 'schedule.filter.filterTitle',
              type: 3,
            }}
            filterDescription={{
              filter: {
                name: 'search',
                label: t('reportFolio.detail.reassign.search'),
              },
            }}
            tableData={tableData}
            initialFilterData={{
              rol: '',
              status: '',
              search: '',
            }}
            filterRow={filterRow}
          />

          <Box sx={{ textAlign: 'end', marginTop: '10px' }}>
            <LoadingButton loading={apiBusy} disabled={!userSelected} variant="contained" onClick={confirmReassing}>
              {t(`general.confirm`)}
            </LoadingButton>
          </Box>
        </Box>
      </BaseModal>

      <SimpleModal
        open={exit}
        removeClose
        description=""
        close={() => {
          setExit(false)
        }}
        title={t('reportFolio.modal.return')}
        buttonText={t('general.confirm')}
        cancelText={t('general.cancel')}
        color={colors}
        back={() => {
          setExit(false)
        }}
        next={() => {
          handleExit()
        }}
      />

      {/* HEADER */}
      <Box sx={styles.flex} mb={2}>
        <IconButton
          sx={styles.goBack}
          onClick={() => {
            if (changeReportFolio) {
              setExit(true)
            } else {
              handleExit()
            }
          }}
        >
          {icons('arrow-left-fill')}
        </IconButton>

        <Typography sx={styles.goBackText}>{t('general.goBackToTheList')}</Typography>
      </Box>

      {/* CONTAINER DETAIL */}
      <Grid container columnSpacing={3}>
        {/* LEFT */}
        <Grid item lg={6} xs={6}>
          <Box sx={styles.borders}>
            {/* HEADER WITH STATUS */}
            <Box sx={[styles[left?.priority?.id as Priority], styles.header]}>
              <Typography>
                {t('reportFolio.detail.general.folioAmound')} <span>{left?.folio}</span>
              </Typography>

              <Typography className="open">{getStatus(left?.status?.id as Status, colors, t).text}</Typography>

              <Box sx={styles.fire}>
                <PriorityStatus priority={left?.priority?.id as Priority} />
              </Box>
            </Box>

            {/* BODY */}
            <Box>
              {/* DESCRIPTION */}
              <Box sx={styles.description}>
                <Typography sx={styles.dTitle}>{t('reportFolio.detail.general.description')}</Typography>

                <Typography sx={styles.dDescription}>{left?.description}</Typography>
              </Box>

              {/* Question and list of answers */}
              {left?.answers &&
                <Box sx={styles.description}>
                  <DetailKeyValue sxContainer={{padding: '0px 0px 10px'}} value={left?.answers?.question} caption='Pregunta del cuestionario'/>

                  <DetailTypeOfResponse
                    answersList={left?.answers?.answersList ?? []}
                    type={left?.answers?.type}
                  />
                </Box>
              }

              {/* DETAIL */}
              <Box>
                <Grid container sx={styles.borderBottom}>
                  <Grid item xs={6} sx={styles.borderRight}>
                    <Box sx={styles.containerDescription}>
                      <ModelOne detail={`${t('reportFolio.detail.general.createBy')}: `} mark={left?.createBy?.user ?? ''} description={left?.createBy?.date ?? ''} />

                      {!!left?.solveBy && !![Status.APPROVED, Status.REJECTED].includes(left?.status?.id) && <ModelOne detail={`${t(`reportFolio.detail.general.${Status.APPROVED == left?.status?.id ? 'solveBy' : 'rejectedBy'}`)}: `} mark={left?.solveBy?.user ?? ''} description={left?.solveBy?.date ?? ''} />}

                      {!!left?.UserAssign && <ModelOne detail={`${t('reportFolio.dashboard.table.usersAssigned')}: `} mark={left?.UserAssign} />}

                      {!!left?.bossAssigned && <ModelOne detail={`${t('reportFolio.dashboard.table.bossAssigned')}: `} mark={left?.bossAssigned} />}
                    </Box>

                    {![Status.REJECTED, Status.APPROVED].includes(left?.status?.id ?? 0) && !raitingWasFailed && (role == Roles.ADMIN || !!left?.canReassign) && (
                      <Box sx={styles.containerBtn}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            getUsers()
                            setUserSelected(null)
                            setApiBusy(false)
                            setModalReassign(true)
                          }}
                        >
                          {t(`reportFolio.detail.general.reassign`)}
                        </Button>
                      </Box>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <Box sx={styles.containerDescription}>
                      <ModelTwo title={t('reportFolio.detail.general.unit')} description={left?.unit ?? 'N/A'} />

                      <ModelTwo title={t('reportFolio.detail.general.area')} description={left?.area ?? 'N/A'} />

                      <ModelTwo title={t('reportFolio.detail.general.department')} description={left?.department ?? 'N/A'} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* Rating */}
              {!!left?.rating?.rating && (
                <Box sx={[styles.borderBottom, styles.containerDescription]}>
                  <ModelOne detail={`${t('reportFolio.detail.general.question')}: `} mark={left?.rating?.ratingQuestion ?? 'N/A'} />

                  <ModelOne
                    children={
                      <Box sx={styles.rating}>
                        <Typography>{t('reportFolio.detail.general.rating')}: </Typography>

                        <Rating size="small" readOnly name="simple-controlled" value={left?.rating?.rating ?? 0} emptyIcon={<StarBorder fontSize="inherit" sx={styles.emptyStar} />} />
                      </Box>
                    }
                  />

                  {!!left?.rating?.ratingComment && <ModelOne detail={`${t('reportFolio.detail.general.comment')}: `} mark={left?.rating?.ratingComment ?? ''} />}
                </Box>
              )}

              {/* EVIDENCES AND COMMENTS */}
              <Box sx={styles.tabsContainer}>
                <TabContext value={value}>
                  <TabList sx={styles.TabList} onChange={handleChange}>
                    <Tab label={t('reportFolio.detail.general.evidences').toUpperCase()} value={`1`} />
                    <Tab label={t('reportFolio.detail.general.comments').toUpperCase()} value={`2`} />
                  </TabList>

                  {/* EVIDENCE */}
                  <TabPanel sx={styles.TabPanel} value={`1`}>
                    {!!existEvidences ? (
                      <>
                        <Grid container columnSpacing={2}>
                          {!!evidences?.images?.length && <EvidenceCard evidences={evidences?.images} handleChangePage={handleChangePage} pagination={pagination.image} type={EvidencesTypeValueEnum.IMAGE} />}

                          {!!evidences?.video?.length && <EvidenceCard evidences={evidences?.video} handleChangePage={handleChangePage} pagination={pagination.video} type={EvidencesTypeValueEnum.VIDEO} />}

                          {!!evidences?.documents?.length && (
                            <>
                              <Typography sx={styles.title}>{t('reportFolio.detail.general.documents')}</Typography>

                              <Box sx={[styles.documentsContainer]}>
                                {evidences?.documents?.map((itemDocuments, index) => (
                                  <Box
                                    key={index}
                                    sx={styles.documentItems}
                                    onClick={() => {
                                      window.open(itemDocuments?.src, 'blank')
                                    }}
                                  >
                                    <Typography sx={styles.documentName}>{itemDocuments?.name}</Typography>

                                    <Icon>{icons('visibility')}</Icon>
                                  </Box>
                                ))}
                              </Box>
                            </>
                          )}
                          {!!evidences?.comments?.length && (
                            <>
                              <Typography sx={styles.title}>{t('reportFolio.detail.general.comments')}</Typography>

                              <Box sx={[styles.comments, styles.paddingComments]}>
                                {evidences?.comments?.map((itemComments, index) => (
                                  <Fragment key={index}>
                                    <ModelThree title={itemComments.name ?? ''} description={''} date={''} />
                                  </Fragment>
                                ))}
                              </Box>
                            </>
                          )}
                        </Grid>
                      </>
                    ) : (
                      <Typography sx={styles.noData}>{t('reportFolio.detail.general.noInformation')}</Typography>
                    )}
                  </TabPanel>

                  {/* COMMENTS */}
                  <TabPanel value={`2`} sx={styles.TabPanel}>
                    <Box sx={[styles.comments]}>
                      {!!left?.comments?.length && !addComment && (
                        <Box sx={{ display: 'flex', justifyContent: 'right', marginBottom: '10px' }}>
                          <Button
                            variant="contained"
                            onClick={() => {
                              setChangeReportFolio(true)
                              setAddComment(true)
                            }}
                          >
                            Responder
                          </Button>
                        </Box>
                      )}

                      {addComment && (
                        <CommentForm
                          id={id}
                          submitted={submitted}
                          hasComments={!!left?.comments?.length}
                          cancel={() => {
                            setChangeReportFolio(false)
                            setAddComment(false)
                          }}
                        ></CommentForm>
                      )}

                      {left?.comments?.length
                        ? left?.comments?.map((itemComments, index) => (
                          <Fragment key={index}>
                            <ModelThree title={itemComments.description ?? ''} description={itemComments?.user ?? ''} date={itemComments?.date ?? ''} />
                          </Fragment>
                        ))
                        : !addComment && <Typography sx={styles.noData}>{t('reportFolio.detail.general.noInformation')}</Typography>}

                      {!left?.comments?.length && !addComment && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                          <Button
                            variant="contained"
                            onClick={() => {
                              setChangeReportFolio(true)
                              setAddComment(true)
                            }}
                          >
                            Agregar comentario
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </TabPanel>
                </TabContext>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* RIGTH */}
        <Grid item lg={6} xs={6}>
          <Box sx={[styles.borders, styles.padding, styles.positionR]}>
            <Box sx={styles.flex} className="flex-jc-sb">
              <Typography sx={styles.titleProcess}>{t('reportFolio.detail.general.solutionFolder')}</Typography>

              {showButton() && (
                <Button
                  variant="contained"
                  sx={styles.processFolio}
                  onClick={() => {
                    setRespondReport(undefined)
                    setChangeReportFolio(true)
                    setSteps(1)
                    openModal()
                  }}
                >
                  {t(`reportFolio.detail.general.${left?.status?.id !== Status.APPROVED && !raitingWasFailed ? 'proccesFolder' : 'sendRating'}`)}
                </Button>
              )}

              {!!(left?.status?.id === Status.AWAIT_SOLUTION && !showButton() && !!left.canResponse) &&
                <Button
                  variant="contained"
                  sx={styles.processFolio}
                  onClick={() => {
                    setModal(prev => ({ ...prev, open: true }))
                    setRespondReport(defaultConfig)
                    setSteps(2)
                  }}>{t('reportFolio.detail.general.answerReportFolio')}</Button>
              }
            </Box>

            <Box
              sx={[styles.flex, styles.viewFolio]}
              className="flex-jc-sb"
              onClick={() => {
                if (!!data?.right?.reportUrl) window.open(data?.right?.reportUrl, 'blank')
                else {
                  if (!!id) {
                    reportFolioServices.generateReportFolio({ id: parseInt(id) }).then((data) => {
                      window.open(data.reportUrl, 'blank')
                    })
                  }
                }
              }}
            >
              <Typography>{t('reportFolio.detail.general.informFolio')}</Typography>

              <Icon>{icons('arrow-right')}</Icon>
            </Box>

            {data?.right?.answers?.map((item, index) => (
              <Fragment key={index}>
                <CustomeAccordion
                  status={item?.status?.id as any}
                  title={`${t('reportFolio.detail.general.solution')} - ${item?.createBy?.date}`}
                  content={{
                    responded: {
                      title: t('reportFolio.detail.general.wasSend'),
                      description: `${item?.createBy?.date} ${t('reportFolio.detail.general.by')}: ${item?.createBy?.user}`,
                    },
                    solution: {
                      title: t('reportFolio.detail.general.solution'),
                      rejectReason: t('reportFolio.detail.general.rejectReason'),
                      description: item?.description,
                      reasonOfRejection: item?.reasonOfRejection
                    },
                    evidences: {
                      title: t('reportFolio.detail.general.evidencesAttached'),
                      evidences: item?.evidences ?? [],
                      evidencesRejection: item?.evidencesRejection ?? []
                    },
                  }}
                />
              </Fragment>
            ))}

            {!!modal.open && (
              <Box sx={styles.containerRate}>
                {(steps != 3 || !left?.rating?.required) && (
                  <IconButton
                    sx={styles.closeIcon}
                    onClick={() => {
                      if (steps === 1 || steps === 2) {
                        setChangeReportFolio(false)
                      }
                      closeModal()
                    }}
                  >
                    {icons('simple-close')}
                  </IconButton>
                )}

                {!modal?.loading ? handleSteps() : <Loader />}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export { Detail }
