import { Color } from "../types/color";

export const button = (color: Color) => ({
  MuiButton: {
    styleOverrides: {
      contained: {
        backgroundColor: color.color4,
        fontWeight: '300',
        borderRadius: 8,
        padding: '5px 15px',
        boxShadow: 'none',
        ':hover': {
          backgroundColor: color.color1,
        },
      },
    },
  },
})
