import { AreaDetailProps, AreaDetailRow } from "./types"
import { useCallback, useLayoutEffect, useState } from "react"
import { graphicHistorigram } from "../../../mothods/callEndpoints"
import { format, parse } from "date-fns"
import { Constants } from "../../../../../../../../../utils/constants"
import { History } from "../../../../../../../../../service/dashboard/response"
import { useTranslation } from "react-i18next"
import { valdateStatus } from "../methods"
import { getStatus } from "../../../../../../../../../hooks/status"
import { useContextState } from "../../../../../../../../../hooks/theme"
import { es } from "date-fns/locale"
import { DinamicFormRefModel, DinamicFormRefValues } from "../../../../../../../../../components/dynamicFormRef/types"
import { GeneralBody } from "../generalBody"
import { Item } from "../../../../../../../../../utils/types/generals"
import { getNumberWeek } from "../../../../../../../../../utils/methods/toDate"

const AreaDetailByDate = (props: AreaDetailProps) => {
  const { colors } = useContextState()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [formatedDate, setFormatedDate] = useState("")
  const input: Record<string, DinamicFormRefModel> = {
    status: {
      id: "status",
      label: t('general.tags.status'),
      name: "status",
      type: "multiSelect",
      items: props.payload.status_ids,
    },
    area: {
      id: "area",
      label: t('general.questions.area'),
      name: "area",
      type: "multiSelect",
      items: props.payload.area_ids,
      selectOptions: {
        child: {
          childKey: 'department',
          parentId: "areaId",
        }
      },
    },
    department: {
      id: "department",
      label: t('general.questions.department'),
      name: "department",
      type: "multiSelect",
      items: props.payload.department_ids,
      selectOptions: {
        parent: {
          parentId: "areaId",
          parentKey: "area"
        }
      },
    },
    questionnaire: {
      id: "questionnaire",
      label: t('general.tags.questionnaire'),
      name: "questionnaire",
      type: "multiSelect",
      items: props.payload.questionnaire_ids ?? [],
    },
  }
  const [row, setRow] = useState<AreaDetailRow[]>([])
  const [rowCopy, setRowCopy] = useState<AreaDetailRow[]>([])
  const column = [
    {
      id: 'area',
      label: t('general.tags.area'),
      sortable: true,
    },
    {
      id: 'department',
      label: t('general.tags.department'),
      sortable: true,
    },
    {
      id: 'questionnaire',
      label: t('general.tags.questionnaire'),
      sortable: true,
    },
    {
      id: 'status',
      label: t('general.tags.status'),
      sortable: true,
    },
  ]
  const getDetail = useCallback(async () => {
    const intervale = props.payload.intervale.value 
    setLoading(true)
    const week = getNumberWeek(props.payload.dates)
    const weekLabel = intervale == '1w' ? `${t('general.week').toLowerCase()} ${week} - ` : ""
    const parseDate = (parse(props.payload.dates, Constants.dateFormat, new Date()))
    setFormatedDate(`${weekLabel}${format(parseDate, Constants.humanFormatShort, { locale: es })}`)
    const formatRow: AreaDetailRow[] = []
    const response = await graphicHistorigram({ detail: true }).areas({
      areasList: props.payload.area_ids,
      date: { end: parseDate.toString(), start: parseDate.toString() },
      interval: intervale,
    }) as History

    for (const hits of response.hits.hits) {
      const source = hits._source
      const canceled = source.cancelado,
        expired = source.vencido,
        finished = source.finalizado,
        noStarted = source.por_iniciar,
        progress = source.en_progreso,
        incompleto = source.incompleto
      const status = valdateStatus({ canceled, expired, finished, incompleto, noStarted, progress, })
      const itemStatus = !status ? null : getStatus(status, colors, t)
      formatRow.push({
        area: source.area,
        department: source.departamento,
        questionnaire: source.cuestionario,
        status: itemStatus,
        filter: {
          area_id: source.area_id,
          department_id: source.departamento_id,
          questionnaire_id: source.cuestionario_id,
          status_id: itemStatus?.value ?? null
        }
      })
    }
    setRowCopy(formatRow)
    setRow(formatRow)
    setLoading(false)
  }, [props.payload.area_ids, props.payload.dates, props.payload.intervale])

  const filterTable = (value: Record<string, DinamicFormRefValues>) => {
    const areas = (value.area ?? []) as Item[]
    const departments = (value.department ?? []) as Item[]
    const status = (value.status ?? []) as Item[]
    const questionnaires = (value.questionnaire ?? []) as Item[]

    const rowFIltered = rowCopy.filter(item => (
      (!areas?.length || areas.some(area => area.value == item.filter?.area_id)) &&
      (!departments?.length || departments.some(department => department.value == item.filter?.department_id)) &&
      (!status?.length || status.some(status => status.value == item.filter?.status_id)) && 
      (!questionnaires?.length || questionnaires.some(questionnaire => questionnaire.value == item.filter?.questionnaire_id))
    ))

    setRow(() => rowFIltered)
  }

  useLayoutEffect(() => {
    getDetail()
  }, [getDetail])

  return (
    <GeneralBody
      getData={filterTable}
      close={props.close}
      colunm={column}
      row={row}
      input={input}
      loading={loading}
      title={`${t("dashboard.programactionByArea")}: ${formatedDate}`}
      intervale={`${t(`general.intervale.intervale`)} ${t(`general.intervale.${props.payload.intervale.value ?? "1d"}`)}`}
    />)
}

export { AreaDetailByDate }