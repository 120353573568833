import { Box, Typography } from "@mui/material"
import { useFolioAmountStyles } from "./foliosAmountStyles"

const FoliosAmount = (props: any) => {
    const styles = useFolioAmountStyles()
    return (
      <Box sx={styles.containerFolioAmount}>
        <Typography sx={styles.foliosAmount}>
          {props.current ?? 'N/A'}
        </Typography>
      </Box>
    )
  }

export { FoliosAmount }
