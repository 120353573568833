import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { Document, convertInchesToTwip, Packer, Paragraph, Table, TableCell, TableRow, VerticalAlign,TextRun } from 'docx'
import { saveAs } from 'file-saver'
import { DocumentFormat } from '../types/generals'

export const handlePdf = (column: any[], row: any[], name?: string, format?: DocumentFormat,t?:any) => {
  let doc:any;
  if(row.length > 0){
    const table = document.createElement('table')
    doc = new jsPDF(format?.orientation || 'landscape', 'pt', format?.size ?? 'a3')
    doc.setFontSize(20)
  
    autoTable(doc, {
      html: table,
      styles: { cellWidth: 8 },
    })
    autoTable(doc, {
      head: [column],
      body: row,
    })
  }else{
    doc = new jsPDF();
    const text = t('general.notDataTable');
    doc.setFontSize(14);
    doc.text(text, 10, 10);
  }
  doc.save(`${name ?? 'table'}.pdf`)
}

export const handleDoc = (columnClean: any[], row: any[], column: any[], name?: string , t?:any) => {
  let dataRow: any[]
  let doc: any
  if(row.length > 0) {
      dataRow = [
        new TableRow({
          children: columnClean
            ?.filter((item) => item.id !== 'action')
            .map(
              (itemC: any) =>
                new TableCell({
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      text: itemC || itemC == 0 ? itemC : 'N/A',
                    }),
                  ],
                })
            ),
        }),
      ]
      dataRow = [
        ...dataRow,
        ...row.map(
          (itemR: any) =>
            new TableRow({
              children: column
                ?.filter((item) => item.id !== 'action')
                .map((itemC: any) => {
                  const existValue = itemR?.[itemC.id]?.label || itemR?.[itemC.id]
                  return new TableCell({ verticalAlign: VerticalAlign.CENTER, children: [new Paragraph(`${existValue || existValue == 0 ? existValue : 'N/A'}`)] })
                }),
            })
        ),
      ]
        doc = new Document({
        sections: [
          {
            properties: {
              column: {
                space: 708,
              },
            },
            children: [
              new Table({
                columnWidths: [convertInchesToTwip(0.69), convertInchesToTwip(0.69)],
                rows: dataRow,
              }),
            ],
          },
        ],
      })
  }else{
      doc = new Document({
    sections: [
      {
          properties: {},
          children: [
              new Paragraph({
                  children: [
                      new TextRun({
                          text: t('general.notDataTable'),
                          bold: true,
                          size: 24,
                      }),
                  ],
              }),
          ],
      },
    ],
    })
  }
  saveDocumentToFile(doc, `${name ?? 'table'}.docx`)
  function saveDocumentToFile(doc: any, fileName: string) {
    // Create new instance of Packer for the docx module

    // Create a mime type that will associate the new file with Microsoft Word
    const mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    // Create a Blob containing the Document instance and the mimeType
    Packer.toBlob(doc).then((blob: any) => {
      const docblob = blob.slice(0, blob.size, mimeType)
      // Save the file using saveAs from the file-saver package
      saveAs(docblob, fileName)
    })
  }
}

export function downloadBlob(blob: Blob, name: string) {
  // Convertir el blob en Blob URL (una URL especial que apunta a un objeto almacenado en la memoria del navegador)
  const blobUrl = URL.createObjectURL(blob)

  // Crear link de descarga y apuntar al Blob URL
  const link = document.createElement('a')
  link.href = blobUrl
  link.download = name
  document.body.appendChild(link)

  // Ejecutar el evento click del enlace creado anteriormente
  // Es necesario hacerlo de esta manera porque en Firefox link.click() no funciona
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  )

  // Eliminar el link del DOM
  document.body.removeChild(link)
}
