import { useEffect, useState } from 'react'
import { Stepper, Step, StepLabel, Typography, Box } from '@mui/material'
import { FiberManualRecord, Check } from '@mui/icons-material'
import useStyles from './style'
import QontoConnector from './conectorStyles'
import { useTranslation } from 'react-i18next'
import { Color } from '../../utils/theme/types/color'

const StepperCreate = ({ activeStep, steps = [], color }: { activeStep?: number; steps: { step: number | string; label: string }[]; color: Color }) => {
  const { t } = useTranslation()
  const [width, setWidth] = useState<number | undefined>(document.getElementById('step')?.clientWidth || 280)

  const stepper = document.getElementById('stepper')

  const observerWidth = new ResizeObserver(() => {
    return setWidth(document.getElementById('step')?.clientWidth)
  })
  if (stepper) {
    observerWidth.observe(stepper)
  }
  useEffect(() => {
    return () => setWidth(document.getElementById('step')?.clientWidth)
  }, [])

  const classes = useStyles(width, color)
  //steps icons
  const stepIcon = (props: any) => {
    const { active, completed } = props
    return (
      <Box sx={[classes.iconDisabled, !!active && classes.iconActive, !!completed && classes.iconComplete]}>
        <FiberManualRecord sx={[classes.roundedIcon, !!active && classes.roundedActive, !!completed && classes.roundedComplete]} />
      </Box>
    )
  }
  const stepFinish = (props: any) => {
    const { active, completed } = props
    return (
      <Box sx={[classes.iconDisabled, !!active && classes.iconActive, !!completed && classes.iconComplete]}>
        <Check sx={[classes.roundedIcon, !!active && classes.roundedActive, !!completed && classes.roundedComplete]} />
      </Box>
    )
  }
  return (
    <Stepper alternativeLabel activeStep={activeStep} color="secondary" connector={<QontoConnector width={width} colors={color} />} sx={classes.stepper} id="stepper">
      {steps.map((item, index) => {
        return (
          <Step sx={classes.step} key={index} id="step">
            <StepLabel sx={classes.stepLabel} StepIconComponent={stepIcon}>
              {item.step && (
                <Typography variant="caption" color="textSecondary" sx={classes.textStep}>
                  {t(item.step.toString())}
                </Typography>
              )}

              {item.label && (
                <Typography variant="caption" sx={classes.textLabelStep}>
                  {t(item.label)}
                </Typography>
              )}
            </StepLabel>
          </Step>
        )
      })}
      <Step sx={classes.stepEnd}>
        <StepLabel sx={classes.stepLabel} StepIconComponent={stepFinish}></StepLabel>
      </Step>
    </Stepper>
  )
}

export default StepperCreate
