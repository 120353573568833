import { Route, Routes } from "react-router-dom"
import ActionPlans from "../pages/main"
import Form from "../pages/form"
import { ActionPlanProvider } from "../provider"

const ActionPlanRoutes = () => {
    return (
        <ActionPlanProvider>
            <Routes>
                <Route path="/" element={<ActionPlans />} />
                <Route path="/:id" element={<Form />} />
            </Routes>
        </ActionPlanProvider>
    )
}

export { ActionPlanRoutes }
