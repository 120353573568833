import { TFunction } from "react-i18next";
import { Bar } from "recharts";

type FormatBarsPayloads = {
  id: string;
  objectOfKeys: Record<string, string>;
  objectsOfLabels: Record<string, string>
  color: string
  t: TFunction<"translation", undefined>
}
/**
 * 
 * @param payload 
 * @param payload.objectOfKeys object with keys gonna have the bars @example {"name": "name"}
 * @param payload.objectsOfLabels object with keys doing refer to the keys of objectOfKeys with the labels @example {"name": "Nombre"}
 * @return  "{ Bar[], }"
*/
const formatBars = (payload: FormatBarsPayloads) => {
  const arrayKeys = Object.values(payload.objectOfKeys)
  const objectsOfLabels = payload.objectsOfLabels
  const id = payload.id

  let barList: JSX.Element[] = []
  let count = 6
  for (const item of arrayKeys) {
    barList.push(<Bar
      maxBarSize={50}
      key={`${id}-${item}`}
      stackId={`${id}`}
      dataKey={`${id}-${item}`}
      name={payload.t(objectsOfLabels[item])}
      fill={`${payload.color}`}
    />)
    if (count > 1) { count -= 1 }
    else { count = 6 }
  }

  return {
    barList
  }
}

export { formatBars }
