import { useState, useEffect } from 'react'
import { Item } from '../../../../../../../utils/types/generals'
import { InputModel } from '../../../../../../../utils/types'
import { removeAllReference } from '../../../../../../../utils/methods/removedReference'

const useInput = ({ items }: { items?: { [x: string]: Item[] } }) => {
  const [input, setInput] = useState<{ [x: string]: InputModel }>({
    search: {
      label: 'schedule.filter.inputs.search',
      translate: true,
      name: 'search',
      // rest: {
      //   type: 'alphaNumeric',
      // },
      value: null,
    },
    from: {
      label: 'general.tags.from',
      translate: true,
      name: 'from',
      type: 'date',
      value: null,
      rest: {
        max: 'to',
      },
      size: {
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      },
    },
    to: {
      label: 'general.tags.to',
      translate: true,
      name: 'to',
      type: 'date',
      value: null,
      rest: {
        min: 'from',
      },
      size: {
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      },
    },
    areas: {
      label: 'general.tags.area',
      translate: true,
      name: 'areas',
      type: 'filter-select',
      items: items?.areas ?? [],
      value: null,
    },
    users: {
      label: 'general.tags.users',
      translate: true,
      name: 'users',
      type: 'filter-select',
      items: items?.users ?? [],
      value: null,
    },
  })

  const disabledInputs = (data: { inputs: string[]; value: boolean }) => {
    let copyInput: {
      [x: string]: InputModel
    } = removeAllReference(input)

    data?.inputs?.forEach((item) => {
      copyInput[item] = {
        ...copyInput[item],
        rest: {
          ...(copyInput[item]?.rest ?? {}),
          disabled: data.value,
        },
      }
    })

    setInput((prev) => ({
      ...prev,
      ...copyInput,
    }))
  }

  useEffect(() => {
    let body = {}
    Object.entries(input).forEach(([key, itemInputs]) => {
      body = { ...body, [key]: { ...itemInputs, items: items?.[key] } }
    })

    setInput(body)
    // eslint-disable-next-line
  }, [items])

  return { input, disabledInputs }
}

export { useInput }
