import { useEffect, useState } from 'react'
import {
  Item,
  ObjectGeneric,
  onChangeEvent,
} from '../../../../../utils/types/generals'
import { searchInModeLower } from '../../../../../utils/validate/validate'

const useFilter = ({
  lists,
  steps,
  selectSchedule,
  useScheduled,
}: {
  steps: string | number
  lists: Record<string, Record<string, string | number | undefined | null>[]>
  selectSchedule: Record<string, (string | number)[]>
  useScheduled: Record<string, ObjectGeneric>
}) => {
  const [filterData, setFilterData] = useState<Record<string, any>>({})
  const [userScheduledSent, setUserScheduledSent] = useState<string[]>([])
  const [disabledChecks, setDisabledChecks] = useState<
    Record<string, (number | string)[]>
  >({})
  const [row, setRow] = useState<unknown[]>([])
  const [counter, setCounter] = useState<Record<string, number | null>>({})

  const removeDisabled = ({ arr }: { arr: (number | string)[] }) => {
    if (!!arr?.length) {
      setDisabledChecks((prev) => ({
        ...prev,
        [steps]: prev[steps]?.filter(
          (item) => !arr.some((itemRemove) => itemRemove == item)
        ),
      }))
    }
  }

  const handledSent = ({ sent }: { sent: string[] }) => {
    setUserScheduledSent((prev) => [...prev, ...sent])
  }

  const handleFilter = (data?: {
    e?: onChangeEvent
    payload?: { clean: boolean }
  }) => {
    const e = data?.e,
      payload = data?.payload

    let name: string, value: any
    let body = {
      ...(filterData[steps] ?? {}),
    }

    if (e?.target) {
      name = e?.target.name
      value = e?.target.value
      body = {
        ...body,
        [name]: value,
      }
      setFilterData((prev) => ({
        ...prev,
        [steps]: {
          ...(prev?.[steps] ?? {}),
          [name]: value,
        },
      }))
    }

    if (!!payload?.clean) {
      body = {}
    }

    const filterLIst: Record<string, () => unknown[]> = {
      0: () => {
        const list = lists.users?.filter((item) =>
          !!(body?.userType?.length ? !!body?.userType?.some((itemUserType: Item) => itemUserType.value == item.userTypeId) : true) &&
          !!searchInModeLower(
            [
              item?.fullname ?? '',
              item?.curp ?? '',
              item?.userType ?? '',
              item?.email ?? '',
              item?.phone ?? '',
            ].join(' '),
            body.search ?? ''
          )
        )

        return list
      },
      1: () => {
        return lists.questionnaires?.filter((item) =>
          !!(body?.types?.length ? body?.types?.some((itemactionType: Item) => itemactionType.value == item.actionType) : true) &&
          searchInModeLower(
            [
              item?.questionnaireName ?? '',
              item?.askAmount ?? '',
              item?.actionPlan ?? '',
              item?.appAmount ?? '',
            ].join(' '),
            body.search
          )
        )
      },
      2: () => {
        const users = lists.users?.filter(
          (item) => !!selectSchedule?.[0]?.some((itemIds) => itemIds == item.id)
        )
        const questionnaires = lists.questionnaires?.filter(
          (item) => !!selectSchedule?.[1]?.some((itemIds) => itemIds == item.id)
        )
        let fullList: {
          id: string
          questionnaireId: string
          userId: string
          user: string | number | null | undefined
          curp: string | number | null | undefined
          questionnaireName: string | number | null | undefined
        }[] = []

        users?.forEach((itemUser) => {
          questionnaires?.forEach((itemQuestionnaire) => {
            const id = `${itemUser.id}-${itemQuestionnaire.id}`
            if (!userScheduledSent?.some((itemSent) => itemSent == id)) {
              const bodyList = {
                id: id,
                questionnaireId: (itemQuestionnaire.id as string) ?? '',
                userId: (itemUser.id as string) ?? '',
                user: itemUser.fullname,
                curp: itemUser.curp,
                questionnaireName: itemQuestionnaire.questionnaireName,
              }

              fullList.push(bodyList)
            }
          })
        })
        let selectedWithoutSchedule: (number | string)[] = []
        let listFiltered: {
          id: string
          questionnaireId: string
          userId: string
          user: string | number | null | undefined
          curp: string | number | null | undefined
          questionnaireName: string | number | null | undefined
        }[] = []

        fullList.forEach((item) => {
          const searched = searchInModeLower(
            [
              item?.questionnaireName ?? '',
              item?.curp ?? '',
              item?.user ?? '',
            ].join(' '),
            body.search
          )

          if (!!searched) {
            listFiltered.push(item)
          }
          const scheduled = useScheduled?.[item.id]
          const from = scheduled?.from
          const to = scheduled?.to

          if (!from && !to) {
            selectedWithoutSchedule.push(item.id)
          }
        })
        
        setDisabledChecks((prev) => ({
          ...(prev ?? {}),
          2: selectedWithoutSchedule ?? [],
        }))

        return listFiltered?.filter((item) =>
          !!(body?.questionnaires?.length
            ? body?.questionnaires?.some(
              (itemaQuestionnaire: Item) =>
                itemaQuestionnaire.value == item.questionnaireId
            )
            : true) && !!(body?.users?.length
              ? body?.users?.some(
                (itemUser: Item) => itemUser.value == item.userId
              )
              : true) &&
          searchInModeLower(
            [
              item?.questionnaireName ?? '',
              item?.curp ?? '',
              item?.user ?? '',
            ].join(' '),
            body.search
          )
        )
        // .sort((a, b) => parseInt(a.id?.split('-')?.[0]) - parseInt(b.id?.split('-')?.[0]))
      },
    }

    const listFiltered = filterLIst[steps]()

    setRow(listFiltered)

    return listFiltered
  }

  const handleCheanFilter = () => {
    setFilterData({})
    handleFilter({ payload: { clean: true } })
  }

  useEffect(() => {
    setCounter({})
    let count = 0
    for (let key in filterData[steps]) {
      if (filterData[steps][key] instanceof Array) {
        if (filterData[steps][key]?.length > 0) count++
      } else if (
        filterData[steps][key] != null &&
        filterData[steps][key] !== ''
      )
        count++
    }

    setCounter((prev) => ({
      ...prev,
      [steps]: count,
    }))
  }, [filterData])

  useEffect(() => {
    handleFilter()
  }, [steps, lists, userScheduledSent])

  return {
    filterData,
    row,
    counter: counter[steps],
    disabledChecks,
    removeDisabled,
    handleFilter,
    handleCheanFilter,
    handledSent,
  }
}

export { useFilter }
