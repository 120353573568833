import { TFunction } from 'react-i18next'
import { CRUD } from '../../../../utils/enums'
import { InputModel } from '../../../../utils/types'

export const initialFilterValues = {
  role: '',
  status: '',
  search: '',
}

export const useFilterDetail = (t: TFunction<"translation", undefined>) => {
  return {
    search: {
      label: t('division.filter.inputs.search'),
      name: 'search'
    },
    status: {
      label: t('division.filter.inputs.firstSelect'),
      name: 'status',
    }
  }
}

export const initialValues = {
  description: {
    label: 'general.tags.description',
    translate: true,
    name: 'description',
    validate: {
      required: true,
    },
    rest: {
      type: 'alphaNumeric',
      multiline: true,
      maxRows: 3,
      inputProps: {
        maxLength: 45
      }
    },
    value: null,
  },
  cveDivision: {
    label: 'general.tags.cveDivision',
    translate: true,
    name: 'cveDivision',
    rest: {
      type: 'alphaNumeric',
      multiline: true,
      maxRows: 3,
      inputProps: {
        maxLength: 45
      }
    },
    value: null,
  },
  status: {
    label: 'general.tags.status',
    translate: true,
    name: 'status',
    type: 'select-search',
    validate: {
      required: true,
    },
    value: null,
    size: {
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12
    }
  },
} as { [x: string]: InputModel }

export const initialData = {
  description: null,
  cveDivision: null,
  status: null,
}

export const initialTable = {
  column: [],
  row: [],
}

export const useMenuItems = (styles: any) => [
  {
    value: CRUD.EDIT,
    label: 'Editar división',
    // icon: <Icon sx={styles.menuIcons}>{icons('edit')}</Icon>,
  },
  {
    value: CRUD.DELETE,
    label: 'Eliminar división',
    // icon: <Icon sx={styles.menuIcons}>{icons('delete')}</Icon>,
  },
]

export const input = {
  search: {
    label: 'general.search',
    translate: true,
    name: 'search',
    type: 'text',
    value: null,
  },
  createYear: {
    label: 'general.filterDate.year',
    translate: true,
    name: 'createYear',
    type: 'filter-select',
    items: [],
    value: null,
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  month: {
    label: 'general.filterDate.month',
    translate: true,
    name: 'month',
    type: 'filter-select',
    items: [],
    value: null,
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  from: {
    label: 'general.tags.from',
    translate: true,
    name: 'from',
    type: 'date',
    value: null,
    rest: {
      max: 'to',
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  to: {
    label: 'general.tags.to',
    translate: true,
    name: 'to',
    type: 'date',
    value: null,
    rest: {
      min: 'from',
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  status: {
    label: 'general.tags.status',
    translate: true,
    name: 'status',
    type: 'filter-select',
    items: [],
    value: null,
  },
} as { [x: string]: InputModel }

