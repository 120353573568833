import { useContextState } from "../theme"

const useStyles = () => {
  const { colors } = useContextState()

  return {
    label: {
      '& .MuiFormControlLabel-label': {
        wordBreak: 'break-all',
      },
    },
    checkbox: {
      padding: '0px',
      marginRight: '10px',
      '& .MuiBox-root': {
        width: '15px',
        height: '15px',
      },
    },
    noSelected: {
      '&.MuiTypography-root': {
        fontSize: '12px',
        color: colors.color28,
        fontWeight: '400',
        marginBottom: '10px',
      },
    },
    drawer: {
      '& .MuiPaper-root': {
        '&:not(:last-child)': {
          marginBottom: '15px',
        },
        maxWidth: '500px',
        padding: '10px 20px',
      },
    },
    titleEvidences: {
      fontSize: '16px',
      color: colors.color13,
      fontWeight: '600',
      marginBottom: '10px',
    },
    labels: {
      '& .MuiFormControlLabel-label': {
        fontSize: '.9rem',
        color: colors.color13
      },
      '& .MuiTypography-root': {
        fontSize: '.9rem',
        color: colors.color13,
        fontWeight: '400'
      },
    }
  }
}

export { useStyles }
