import {
  BarChart,
  Brush,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { CustomTooltip, CustomizedAxisTick } from '../linearGrapgic'
import { BarProp, BarRef } from './types'
import { Box, Typography } from '@mui/material'
import { useContextState } from '../../../../../../hooks/theme'
import { useStyles } from './styles'
import { Fragment, Ref, forwardRef, useImperativeHandle, useMemo, useState } from 'react'
import { unitStatus } from '../../fragments/methods/statusByPercentage'
import { percentageColors } from '../../fragments/methods/statusColors'
import { percentage } from '../../../../../../utils/validate/validate'

const CustomTooltip2 = ({
  active,
  payload,
  label,
  separatorCustome,
  int,
}: any) => {
  const styles = useStyles()
  const { colors: colorsState } = useContextState()

  if (active && payload && payload.length) {
    return (
      <Box component="div" id="CustomTooltip" sx={styles.containerTooltip}>
        <Typography component="p" id="CustomTooltip" sx={styles.titleTooltip}>
          {label}
        </Typography>

        {Object.values(payload).map((item: any, index) => {
          const customeData = item.payload?.customeSettings
          const customeLabel = item?.payload?.customeLabels?.[item?.dataKey]
          let colors: { color: string; text: string } | undefined = undefined

          let dataKeySlice = item?.dataKey.split('-')[1]
          if (customeData && !customeData?.remove?.[dataKeySlice]) {
            const status = unitStatus(
              parseFloat(percentage({ a: item.value, b: customeData.total }))
            )
            let typeStatus = status.programming()
            if (customeData?.types?.[dataKeySlice] != '1') {
              typeStatus = status.noProgramming()
            }
            colors = percentageColors[typeStatus]
          }
          const splitName = (item.name as string).split(separatorCustome ?? '')
          return (
            <Fragment key={index}>
              {!!splitName[1] && (
                <Typography
                  component="p"
                  id="CustomTooltip"
                  sx={styles.mainName({
                    colors: (item.color.slice(0, 7) ?? colorsState.color13),
                  })}
                >
                  {splitName[1]}
                </Typography>
              )}
              <Box sx={styles.containerTitle}>
                <Typography
                  component="p"
                  id="CustomTooltip"
                  sx={styles.detail({
                    colors: colors?.color || !!customeData?.remove?.[dataKeySlice] ? colorsState.color13 : (item.color.slice(0, 7) ?? colorsState.color13),
                  })}
                >
                  {splitName[0]}:{' '}
                  {!!customeLabel
                    ? customeLabel
                    : !parseFloat(item.value) || !!int
                      ? item.value
                      : parseFloat(item.value).toFixed(2)}
                </Typography>

                {!!colors?.color && (
                  <Box
                    sx={styles.percentageStatus({
                      color: colors?.color ?? colorsState.color13,
                    })}
                  />
                )}
              </Box>
            </Fragment>
          )
        })}
      </Box>
    )
  }

  return null
}

const BarGraphic = forwardRef((props: BarProp, ref: Ref<BarRef>) => {
  const { colors } = useContextState()
  const [triggered, setTriggered] = useState<'hover' | 'click'>('hover')
  const tooltip = useMemo(() => {
    if (props.customeTooltip) return props.customeTooltip
    if (!!props?.simpleTooltip) {
      return <CustomTooltip
        int={props.int}
        percentage={props.percentage}
        separatorCustome={props?.separatorX}
      />
    }
    return <CustomTooltip2
      int={props.int}
      separatorCustome={props.separator}
    />
  }, [props.customeTooltip, props?.simpleTooltip, props.int, props.percentage, props?.separatorX, props.separator])

  const removeClick = () => {
    setTriggered('hover')
  }
  const addClick = () => {
    setTriggered('click')
  }
  useImperativeHandle(ref, () => ({
    removeClick,
    addClick
  }))
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        id="ResponsiveContainer-list"
        onClick={(payload) => {
          if (props.getItem && !!payload) {
            props.getItem(payload)
          }
          setTriggered('click')
        }}
        onMouseLeave={(e) => {setTriggered('hover')}}
        width={500}
        height={300}
        data={props?.data ?? []}
        margin={{
          top: 20,
          right: 70,
          left: 10,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="1 1" />
        <XAxis
          dataKey={props?.datakey ?? 'date'}
          interval={props?.data?.length < 3 ? 0 : 'preserveStartEnd'}
          tick={
            <CustomizedAxisTick
              headers={props.xHeader}
              separatorCustome={props?.separatorX}
              dataLength={props?.data?.length}
              customeX={props.customeX}
              removeTurn={
                !props.doesnotRemoveTurn ? props?.data?.length < 12 : false
              }
            />
          }
          height={60}
        />
        {!props.removeY && <YAxis />}
        <Tooltip
          trigger={triggered}
          
          wrapperStyle={{ pointerEvents: 'auto' }}
          content={tooltip}
        />
        {!!props?.legend && <Legend height={20} />}
        {!!props.brush && (
          <Brush
            travellerWidth={8}
            dataKey={props?.datakey ?? 'date'}
            height={15}
            stroke={colors.color13}
          />
        )}
        {props.indicators}
      </BarChart>
    </ResponsiveContainer>
  )
})

export { BarGraphic }
