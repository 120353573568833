import { useContextState } from "../../../../../../hooks/theme"

const useStyles = () => {
  const { colors } = useContextState()

  return {
    container: {
      width: '100%',
      height: 'fit-content',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      rowGap: '10px',
      boxShadow: `0px 0px 4px ${colors.color13}`,
      borderRadius: '5px',
      padding: '20px',
      position: 'relative'
    },
    refresh: {
      background: colors.orangeMedium,
      color: colors.color5,
      borderRadius: '5px',
      '&:hover': {
        background: `${colors.orangeMedium}ee`,
      },
      '& .MuiSvgIcon-root': {
        fontSize: '2.2rem',
      }
    },
    header: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    title: {
      lineHeight: '1rem',
      color: colors.color9,
      fontSize: '1.2rem',
      marginBottom: '5px',
      fontWeight: '500'
    },
    containerDetail: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '10px'
    },
    label: {
      fontSize: '.8rem',
      color: colors.color13,
      width: "40%"
    },
    containerLinear: {
      width: 'calc(60%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '20px'
    },
    detailContainer: {
      width: '100%',
      height: '100%',
      maxHeight: '200px',
      overflowY: 'auto',
      display: 'flex',
      flexWrap: "wrap",
      gap: "10px"
    },
    actionIndicator: {
      position: 'absolute',
      top: '0',
      right: '10px',
      '& .MuiIconButton-root': {
        fontSize: '1rem',
        '& span': {
          fontSize: '11px',
          marginRight: '2px',
        },
        '& .MuiSvgIcon-root': {
          fontSize: '1em',
        },
      },
    },
    noData: {
      width: '100%',
      textAlign: 'center',
      fontSize: '18px',
      color: `${colors.color13}`,
      marginTop: '20px'
    },
  }
}

export { useStyles }
