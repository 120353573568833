import { useEffect, useState } from 'react'
import { Item } from '../../../../../../../utils/types/generals'
import { RequestErrors, Roles } from '../../../../../../../utils/enums'
import { getRoles, getStore, getUserTypes, getUsers } from '../../../../../../../utils/methods/geServiceWithItemsFormat'
import { useNavigate, useParams } from 'react-router-dom'
import { departmentService } from '../../../../../../../utils/constants/callService'
import { modelsAccepted } from '../constants/equivalence'
import { User } from '../../../../../../../service/user/response'

const useGetData = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [extraData, setExtraData] = useState({ area: "" })
  const [items, setItems] = useState<Record<string, Item[]>>({})
  const [itemsSetting, setItemsSetting] = useState<Record<string, Item[]>>({})
  const [loadingDetail, setLoadingDetail] = useState(false)
  const [userTable, setUserTable] = useState<{ [x: string]: any }[]>([])
  const [loadingTable, setLoadingTable] = useState(false)
  const [dataEndpoint, setDataEndpoint] = useState<{
    name?: string
    email?: string
    phone?: string
    responsible?: {
      value: string
    } | null
    users?: {
      receive?: Record<string, Item[]> | undefined
      id: number
      status: boolean
      reassignFolio: boolean
    }[]
  }>({})

  const getData = () => {
    getUserTypeService()
    getRolesService()
    getStoresService()
    getDepartmentData()
  }

  const getUserServices = async (payload?: { responsible: Item }) => {
    setLoadingTable(true)
    const { items, all } = await getUsers({ skip: [Roles.ADMIN] })
    let itemsResponsible = structuredClone(items) as Item[]

    if (!!payload?.responsible && !items?.some(item => item.value == payload?.responsible?.value)) {
      itemsResponsible.push(payload.responsible)
    }

    setUserTable(
      (all as User[])?.map((itemUsers) => ({
        id: itemUsers.id,
        name: `${itemUsers.firstName} ${itemUsers.lastName}`,
        curp: itemUsers.curp,
        role: itemUsers.roleId,
        roleName: itemUsers.role,
        userType: itemUsers.userTypeId,
      }))
    )

    setItems((prev) => ({ ...prev, responsible: itemsResponsible }))
    setItemsSetting((prev) => ({ ...prev, users: items }))
    setLoadingTable(false)
  }

  const getUserTypeService = async () => {
    const { items } = await getUserTypes()
    setItems((prev) => ({ ...prev, usersTypes: items }))
    setItemsSetting((prev) => ({ ...prev, usersTypes: items }))
  }

  const getRolesService = async () => {
    const { items } = await getRoles({ skip: [Roles.ADMIN] })
    setItems((prev) => ({ ...prev, roles: items }))
  }

  const getStoresService = async () => {
    const { items } = await getStore()
    setItemsSetting((prev) => ({ ...prev, stores: items }))
  }
  const getDepartmentData = async () => {
    let responsible: any = {
      label: '',
      value: ''
    }
    if (id) {
      setLoadingDetail(true)
      const departmentDewtail = await departmentService.getDepartmentById(parseInt(id))
      if (!departmentDewtail?.errors) {
        setExtraData({ area: departmentDewtail?.area?.name ?? "" })
        responsible = departmentDewtail?.userManageId ? { label: `${departmentDewtail?.userManage?.firstName} ${departmentDewtail?.userManage?.lastName} - ${departmentDewtail?.userManage?.curp}`, value: departmentDewtail?.userManageId } : null

        setDataEndpoint({
          name: departmentDewtail?.name,
          email: departmentDewtail?.email,
          phone: departmentDewtail?.phone,
          responsible: departmentDewtail?.userManageId ? { value: departmentDewtail?.userManageId } : null,
          users: departmentDewtail?.departmentsUser?.map((itemUser) => {
            let receive: Record<string, Item[]> = {}

            if (!!itemUser?.responsible?.length) {
              itemUser?.responsible?.forEach((itemResponsable) => {
                const convert = modelsAccepted[itemResponsable.model]
                receive = {
                  ...receive,
                  [convert]: [...(receive?.[convert] || []), { value: itemResponsable.modelId, label: '' }],
                }
              })
            }

            return {
              id: itemUser.userId,
              status: !!itemUser?.allFolios,
              reassignFolio: itemUser.canReassign == 1,
              ...(!!itemUser?.responsible?.length && { receive }),
            }
          }),
        })
      } else {
        if (departmentDewtail?.errors[0]?.code === RequestErrors.ERR_NOT_FOUND) {
          navigate('/dashboard/organization-chart/departments/')
        }
      }
      setLoadingDetail(false)
    }

    getUserServices({ responsible })
  }

  useEffect(() => {
    getData()
    //eslint-disable-next-line
  }, [])
  return { items, itemsSetting, loadingTable, userTable, loadingDetail, dataEndpoint, extraData }
}

export { useGetData }
