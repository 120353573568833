import { Http } from '../../utils/types'
import { GetBlob } from '../../utils/types/generals'
import { User, UserTypePagination } from './response'

export const adminService = (client: Http) => ({
  getAllAdmins: async () => await client.get('admin'),
  getAllAdminsDetailed: async () => await client.get('admin/detailed'),
  getAdminById: async (id?: number):Promise<User> => await client.get(`admin/${id}`),
  createAdmin: async (body: any, multiPart?: boolean) => await client.post('admin', body, multiPart),
  updateAdmin: async (body: any, id: string, multiPart?: boolean) => await client.put(`admin/${id}`, body, multiPart),
  getIDAdmin: async (name: string) => await client.get(`users/username/${name}`),

  getAdminsByPagination: async (data?: { query?: string }): Promise<UserTypePagination> => await client.get(`admin/detailed${data?.query ? `?${data?.query}` : ''}`),
  downloadDocument: async (data: {
    type: `pdf` | 'word' | 'excel'
    query?: string
  }): Promise<GetBlob> =>
    await client.getBlob?.(
      `admin/generate-file?type=${data.type}${data?.query ? `${data?.query}` : ''
      }`
    ),
})
