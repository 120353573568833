import { useCallback, useEffect, useState } from 'react'
import { userScheduleServiceFetch } from '../../../../../utils/constants/callService'
import { getStatus } from '../../../../../hooks/status'
import { useContextState } from '../../../../../hooks/theme'
import { useTranslation } from 'react-i18next'
import { Roles, Status } from '../../../../../utils/enums'
import { format } from 'date-fns'
import { Constants } from '../../../../../utils/constants'
import { Item } from '../../../../../utils/types/generals'
import {
  getArea,
  getDepartments,
  getDivisions,
  getQuestionnaires,
  getRoles,
  getUserTypes,
  getUsers,
} from '../../../../../utils/methods/geServiceWithItemsFormat'
import { canAccess } from '../../../../../utils/validate/canaccess'
import { getPreviousYears, monthYear } from '../../../../../utils/constants/date_month'

const useGetData = () => {
  const { colors } = useContextState()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [items, setItems] = useState<{ [x: string]: Item[] }>({})
  const [departments, setDepartments] = useState<any[]>([])
  const [endpointDetail, setEndpointDetail] = useState({ total: 0 })
  const [row, setRow] = useState<
    {
      id: number
      division: string
      user: string
      questionnaire: string
      reportUrl: string
      available_to: string
      available_from: string
      status: {
        value: Status
        color: string
        text: string
      }
    }[]
  >([])

  const handleItemsByExternal = ({
    name,
    value,
  }: {
    name: string
    value: any
  }) => {
    setItems((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const getUserSchedule = async (payload?: { query: string }) => {
    setIsLoading(true)
    const response = await userScheduleServiceFetch.getList(payload)
    let newRow: {
      id: number
      division: string
      user: string
      questionnaire: string
      available_to: string
      available_from: string
      reportUrl: string
      status: {
        value: Status
        color: string
        text: string
      }
    }[] = []

    if (!response?.errors) {
      setEndpointDetail({
        total: response.totalPages,
      })

      newRow = response?.items?.map((item) => ({
        id: item.id,
        user: item?.userAssignFullname,
        reportUrl: '',
        division: item.division ?? '',
        available_to: item?.dateEnd
          ? format(
              new Date(item?.dateEnd).getTime(),
              Constants.dateFormatGeneral
            )
          : '',
          available_from: item?.dateStart
            ? format(
                new Date(item?.dateStart).getTime(),
                Constants.dateFormatGeneral
              )
            : '',
        questionnaire: item.questionnaireName ?? '',
        status: getStatus(item.statusId, colors, t),
      }))
    }

    setRow(newRow)
    setIsLoading(false)
  }

  const getRolesService = async () => {
    const responseRoles = await getRoles({ skip: [Roles.ADMIN] })

    setItems((prev) => ({
      ...prev,
      roles: responseRoles.items,
    }))
  }

  const getAreasService = async () => {
    const responseAreas = await getArea()

    setItems((prev) => ({
      ...prev,
      areas: responseAreas,
    }))
  }

  const getDepartmentService = async () => {
    const departments = await getDepartments()
    setDepartments(departments)
  }

  const getQuestionnairesService = async () => {
    const responseQuestionarie = await getQuestionnaires()

    setItems((prev) => ({
      ...prev,
      questionnaireId: responseQuestionarie.items,
    }))
  }

  const getUserTypesService = async () => {
    const { items } = await getUserTypes()

    setItems((prev) => ({
      ...prev,
      userTypeId: items,
    }))
  }

  const getUsersService = async () => {
    const { items } = await getUsers()

    setItems((prev) => ({
      ...prev,
      userId: items,
    }))
  }

  const getDivisionsService = async () => {
    const division = await getDivisions()

    setItems((prev) => ({
      ...prev,
      divisions: division.items,
    }))
  }

  const getItemStatus = async () => {
    const statusList = [
      Status.TO_BE_STARTED,
      Status.STARTED,
      Status.PAUSE,
      Status.EXPIRED,
      Status.INCOMPLETE,
      Status.FINISHED,
      Status.CANCEL,
      // Status.WAITING
    ]

    setItems((prev) => ({
      ...prev,
      status: statusList.map((item) => {
        const status = getStatus(item, colors, t)
        return {
          label: status.text,
          value: status.value,
        }
      }),
    }))
  }

  const getDates = () => {
    setItems((prev) => ({
      ...prev,
      createYear: getPreviousYears(),
      month: monthYear.map(item => ({
        ...item,
        value: item.value + 1
      })),
    }))
  }

  const getAllData = useCallback(() => {
    if (!canAccess([Roles.AGENT, Roles.AUDIT])) {
      if (!canAccess([Roles.STORE])) {
        getRolesService()
      }
      getUserTypesService()
      getUsersService()
      getDivisionsService()
    }
    getDates()
    getAreasService()
    getQuestionnairesService()
    getItemStatus()
    getDepartmentService()
  }, [])

  useEffect(() => {
    getAllData()
  }, [])

  return {
    row,
    isLoading,
    items,
    departments,
    endpointDetail,
    handleItemsByExternal,
    getUserSchedule,
  }
}

export { useGetData }
