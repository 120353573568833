import { ReactNode } from "react";
import { isValidateDate } from "../validate/validate";

interface Blur { blur: boolean }


const ValidateDateRange = ({ name, value, blur, from, to }: { name: string; value: any; blur: Blur | ReactNode; from: number; to: number }) => {
  let startDate = 0
  let endDate = 0

  if (['from', 'to'].includes(name) || from || to) {
    if (!(blur as { blur: boolean })?.blur) {
      startDate = name === 'from' ? value?.valueOf() : from?.valueOf()
      endDate = name === 'to' ? value?.valueOf() : to?.valueOf()
    } else {
      if (!isValidateDate(value)) {
        const splitValue = value?.split('/')?.reverse()?.join('-')
        const newValue = new Date(`${splitValue} ${name == 'from' ? '00:00:00' : '12:59:59'}`)?.getTime()
        startDate = name === 'from' ? newValue : from?.valueOf()
        endDate = name === 'to' ? newValue : to?.valueOf()
      }
    }
  }
  
  return { startDate, endDate: typeof endDate == "string" ? new Date(endDate)?.getTime() : endDate }
}

const validateDateTime = (dateTimeStr: string) => {
  const regex = /^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2}) (am|pm)$/i;
  const match = dateTimeStr.match(regex);

  if (match) {
    const [, , month, , hour, minute, second, ampm] = match;
    const parsedMonth = parseInt(month, 10);
    const parsedHour = parseInt(hour, 10);
    const parsedMinute = parseInt(minute, 10);
    const parsedSecond = parseInt(second, 10);

    if (
      parsedMonth >= 1 && parsedMonth <= 12 &&
      parsedHour >= 1 && parsedHour <= 12 &&
      parsedMinute >= 0 && parsedMinute <= 59 &&
      parsedSecond >= 0 && parsedSecond <= 59 &&
      (ampm.toLowerCase() === 'am' || ampm.toLowerCase() === 'pm')
    ) {
      return true;
    }
  }

  return false;
}

export { validateDateTime, ValidateDateRange }