import { useContextState } from "../../../../../../../../hooks/theme"
import { Status } from "../../../../../../../../utils/enums"

const useStatusStyles = (): Record<string, any> => {
    const { colors } = useContextState()
    return {
        containerStatus: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            width: '100%',
            height: '100%',
        },
        status: {
            fontWeight: '600',
            fontSize: '14px',
            width: '100px',
            textAlign: 'center',
        },
        [Status.ACTIVE]: {
            '&.MuiTypography-root': {
                color: colors.color19,
            },
        },
        [Status.REJECTED]: {
            '&.MuiTypography-root': {
                color: colors.color34,
            },
        },
        [Status.AWAIT_SOLUTION]: {
            '&.MuiTypography-root': {
                color: colors.color35,
            },
        },
        [Status.RESPONDED]: {
            '&.MuiTypography-root': {
                color: colors.color35,
            },
        },
        [Status.APPROVED]: {
            '&.MuiTypography-root': {
                color: colors.color8,
            },
        },
        [Status.CANCEL]: {
            '&.MuiTypography-root': {
                color: colors.color4,
            },
        },
    }
}

export { useStatusStyles }
