import { useContextState } from "../../../../../../hooks/theme"

export const useStyles = () => {
  const { colors: color } = useContextState()
  return {
    container: {
      position: 'relative',
      marginRight: '50px',
      '& .table-container': {
        // maxHeight: 'calc(100vh - 300px)',
        // height: 'calc(100vh - 300px)',
      },
      '& .MuiTableCell-root.MuiTableCell-root .MuiBox-root': {
        justifyContent: 'start',
        '& .MuiFormControlLabel-root': {
          marginLeft: '0px',
        },
      },
    },
    notFound: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      "& .notFoundData" : {
        fontSize: '20px',
        color: color.color13
      }
    },
    add: {
      '& .MuiSvgIcon-root': {
        fontSize: "2rem",
      },
      color: color.color4
    },
    modal: {
      position: 'relative',
      maxWidth: '1200px',
      width: '95vw',
      height: 'calc(90vh)',
      maxHeight: '800px',
      overflowY: 'auto'
    },
    separator: {
      borderRight: `2px solid ${color.color13}88`
    },
    modalContent: ({ open }: { open: boolean }) => ({
    }),
    content: {
      padding: '0 20px',
      paddingTop: '20px',
    },
    contentRight: {
      overflowY: 'auto',
      maxHeight: 'calc(90vh - 110px)'
    },
    createTitle: {
      fontSize: '1.4rem',
      paddingBottom: '0px',
      lineHeight: '1',
      padding: '20px 20px 0',
    },
    description: {
      fontSize: '.8rem',
      padding: '0 20px',
      width: '100%',
      '& span': {
        color: color.color16
      }
    },
    permissionTable: (payload: {loading:boolean}) => ({
      padding: '20px',
      paddingBottom: '0px',
      ...!!payload.loading && {
        position: 'relative',
        top: '25%'
      }
    }),
    centerTitle: {
      height: '90px',
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      alignItems: 'center'
    },
    headerTable: { fontSize: '.8rem', marginBottom: '10px' },
    separatorHeader: {
      paddingBottom: '20px',
      borderBottom: `1px solid ${color.color13}88`
    },
    footerBtn: {
      marginTop: "20px",
      paddingTop: '0px',
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: '0 20px 20px',
    },
    containerLoader: {
      display: 'flex',
      width: '100%',
      height: '100%'
    },
    checksTypes: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'start',
      width: '100px',
    },
    containerChecks: {
      padding: '35px 20px 0',
    },
    checksChildren: (payload?: { jc: string }) => ({
      width: '100%',
      display: 'flex',
      justifyContent: payload?.jc ?? 'start',
      columnGap: '40px',
      '& .MuiTypography-root': {
        width: '100%',
        textAlign: 'center',
        color: color.color5,
        fontSize: '12px',
      },
    }),
    label: {
      display: 'flex',
      justifyContent: 'space-around',
      '& .MuiTypography-root': {
        fontSize: '12px',
        fontWeight: '500',
        color: color.color9,
      },
      '& .MuiFormControlLabel-label': {
        fontSize: '12px',
        fontWeight: '400',
        color: color.color5,
      },
    },
    close: {
      position: 'absolute',
      top: '5px',
      right: "5px"
    },
    headerCell: {
      padding: '16px 0px',
    },
    delete: {
      position: "relative",
      bottom: '-15px',
      left: '-5px',
      fontSize: '1rem',
      '& .MuiSvgIcon-root': {
      fontSize: '1.2rem',
      },
      color: color.color19
    },
    header: {
      '&.MuiTableCell-head': {
        minWidth: '100px',
      },
      '&.MuiTableCell-root.MuiTableCell-root': {
        vertialAlign: 'middle',
      },
    },
    title: {
      fontSize: '20px',
      fontWeight: '500',
    },
    subtitle: {
      color: color.color19,
      fontSize: '.8rem'
    },
    titleQ: (payload?: { top?: number; left?: number }) => ({
      position: 'absolute',
      top: `${payload?.top ?? -15}px`,
      left: `${payload?.left ?? 40}%`,
      fontSize: '16px',
      fontWeight: '500',
      color: color.color4,
    }),
    detailChecks: (payload?: { bottom?: number }) => ({
      position: 'relative',
      bottom: payload?.bottom ?? '-20px',
      border: `2px solid ${color.color9}`,
      borderTopRightRadius: '8px',
      borderTopLeftRadius: '8px',
      padding: '8px 6px',
      columnGap: '30px',
      '&::before': {
        content: "''",
        width: '13px',
        height: '13px',
        background: '#ebebeb',
        borderLeft: `2px solid ${color.color9}`,
        borderTop: `2px solid ${color.color9}`,
        position: 'absolute',
        top: '-8px',
        left: 'calc(50% - 13px)',
        transform: 'rotate(45deg)',
      },
    }),
    goBack: {
      display: 'flex',
      justifyContent: 'flex-start',
      '& .MuiSvgIcon-root': {
        fontSize: '1.4rem',
        color: color.color4,
      },
    },
    alignItems: {
      alignItems: 'center'
    },
    buttonsContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .back': {
        width: '80px',
        height: '36px',
        textTransform: 'uppercase',
      },
      '& .next': {
        borderRadius: '4px',
        textTransform: 'uppercase',
      },
    },
    titleContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
    arrow: {
      display: 'flex',
      alignItems: 'center'
    }
  }
}
