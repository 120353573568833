const mapDefault: {
  id?: string | number
  center: {
    lat: number
    lng: number
  }
  zoom: number
} = {
  center: {
    lat: 19.42847,
    lng: -99.12766,
  },
  zoom: 15,
}

export { mapDefault }
