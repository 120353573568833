import { useContextState } from '../../hooks/theme'
import { Status } from '../../utils/enums'

export const useStyles = (status: Status) => {
  const { colors } = useContextState()
  let response = colors.color4

  switch (status) {
    case Status.BLOCKED:
      response = colors.color19
      break
    case Status.WITH_DRAW:
      response = colors.color13
      break
    default:
      response = colors.color4
  }

  return {
    container: {
      background: `${response}66`,
      display: 'flex',
      flexDirection: 'column',
      gap: '2px',
      width: '90%',
      borderLeft: `6px solid ${response}`,
      padding: '5px 0 5px 5px',
      '& .MuiTypography-root': {
        wordBreak: 'break-all',
        fontSize: '12px',
        color: response,
        fontWeight: '700',
      },
    },
  }
}
