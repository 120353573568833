import { useContextState } from '../../../../../../../../hooks/theme'

const useStyles = () => {
  const { colors } = useContextState()
  return {
    itemTitle: {
      width: 'calc(100% - 60px)',
      wordBreak: 'break-word',
    },
    items: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      gap: '10px',
      justifyContent: 'space-between',
      background: `${colors.color10}22`,
      padding: '15px',
      position: 'relative',
    },
    itemsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      gap: '10px',
    },
    delete: {
      color: colors.color19,
      fontSize: '20px',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    upload: {
      color: colors.color4,
      fontSize: '20px',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    canvas: {
      width: '100%',
      paddingBottom: '10px',
      '& canvas': {
        boxShadow: '0px 0px 0px #000, 0px 0px 3px #000',
      },
    },
    containerProgress: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      paddingLeft: '10px'
    },
    commentsContainer: {
      paddingBottom: '10px',
    },
    commentsP: {
      marginBottom: '10px',
    },
    wFull: {
      width: '100%',
    },
    flexWrap: {
      flexWrap: 'wrap',
    },
    noData: {
      width: '100%',
      textAlign: 'center',
      fontSize: '18px',
      color: `${colors.color13}`,
    },
    comments: {
      width: '100%',
      maxHeight: '300px',
      overflowY: 'auto',
    },
    imgActions: {
      position: 'absolute',
      top: '0px',
      right: '0px',
    },
    imgContainer: {
      position: 'relative',
    },
    sign: {
      width: '100%',
    },
    cancelUpload: {
      color: colors.color19,
      fontSize: '20px',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    progress: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: '0px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backdropFilter: 'blur(5px)',
    },
    iconContainer: {
      width: '50px',
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      '& .MuiIconButton-root': {
        '&.download': {
          color: colors.color4,
          width: '25px',
          height: '25px'
        },
        '&.delete': {
          color: colors.color19,
          width: '25px',
          height: '25px'
        },
        fontSize: '1.2rem',
        padding: '2px',
        '& .MuiSvgIcon-root': {
          fontSize: '1em',
        },
      },
    },
  }
}

export { useStyles }
