import { Typography, Box } from '@mui/material'
import { PieChart, Pie, Cell, Tooltip, TooltipProps } from 'recharts'
import { useContextState } from '../../../hooks/theme'

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({ cx, cy, midAngle, customeLabel, customeLabel2, innerRadius, outerRadius, percent, textColor, moveValue, value, currentValue, ...rest }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.3
  const x = cx + radius * Math.cos(-midAngle * RADIAN) * 1
  const y = cy + radius * Math.sin(-midAngle * RADIAN) * 1.1
  const auxCX = x
  
  return currentValue ? (
    <text style={{ outline: 'none' }} x={auxCX} y={y} fill={moveValue ? '#000000' : textColor} fontWeight={moveValue ? '600' : '400'} fontSize={16} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {value}
    </text>
  ) : value > 0 ? (
    <g>
      <text style={{ outline: 'none' }} x={auxCX} y={y} fill={moveValue ? '#000000' : textColor} fontWeight={moveValue ? '600' : '400'} fontSize={11} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {customeLabel ?? `${(percent * 100).toFixed(0)}`}
      </text>

      <text style={{ outline: 'none' }} x={auxCX} y={y + 15} fill={moveValue ? '#000000' : textColor} fontWeight={moveValue ? '600' : '400'} fontSize={11} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {customeLabel2 ?? `${(percent * 100).toFixed(0)}`}
      </text>
    </g>
  ) : null
}

export interface CakeProps {
  data: { name: string; value: number; customeLabel?: string; customeLabel2?: string; color: string; textColor: string; moveValue?: boolean; currentValue?: boolean }[]
  errorMessage?: string
  mode?: 'dark' | 'light'
  removeMB?: boolean
  tooltip?: boolean
  tooltipProps?: TooltipProps<any, any>
  cakeSize?: {
    w?: number
    h?: number
    cx?: number
    cy?: number
    noDataW?: string
    noDataH?: string
    outerRadius?: number
  }
}

const Cake = ({ data, errorMessage, cakeSize, mode, removeMB, ...rest }: CakeProps) => {
  const existValue = data?.every((item) => !item.value)
  const { colors } = useContextState()
  const styles = {
    noData: {
      width: cakeSize?.noDataW ?? '130px',
      height: cakeSize?.noDataH ?? '130px',
      borderRadius: '50%',
      background: mode === 'light' ? colors.color5 : colors.color10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: removeMB ? '0px' : '20px',
      padding: '10px',
      '& .MuiTypography-root': {
        fontSize: '12px',
        fontWeight: '500',
        width: 'fit-content',
        textAlign: 'center',
        color: mode === 'light' ? colors.color9 : colors.color5,
      },
    },
  }

  return !existValue ? (
    <PieChart style={{ outline: 'none' }} width={cakeSize?.w ?? 160} height={cakeSize?.h ?? 150} onClick={() => { }}>
      <Pie data={data} cx={cakeSize?.cx ?? 60} cy={cakeSize?.cy ?? 60} labelLine={false} label={renderCustomizedLabel} outerRadius={cakeSize?.outerRadius ?? 60} fill="#8884d8" dataKey="value" style={{ outline: 'none' }}>
        {data.map((entry, index) => (
          <Cell style={{ outline: 'none' }} key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>

      {!!rest.tooltip && <Tooltip {...rest.tooltipProps} />}
    </PieChart>
  ) : (
    <Box sx={styles.noData}>
      <Typography>{errorMessage ?? ''}</Typography>
    </Box>
  )
}

export default Cake
