import { Ref, forwardRef, useImperativeHandle, useMemo, useState } from "react"
import { StepManagementProps, StepManagementRef } from "./types"
import { Box, Button } from "@mui/material"
import { useStyles } from "./styles"
import { useTranslation } from "react-i18next"

const StepManagement = forwardRef((props: StepManagementProps, ref: Ref<StepManagementRef>) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const [steps, setSteps] = useState(0)
  const backComponent = useMemo(() => !props.customeBack ? <Button onClick={() => { changeStep(-1) }}>{t("general.return")}</Button> : props.customeBack({ disabled: steps <= 0, returnOneStep: () => { changeStep(-1) } }), [props.customeBack, steps])
  const [disabled, setDisabled] = useState<Record<string, boolean>>({
    0: true
  })
  const lastStep = props.lastStep.last - 1
  const isLastStep = steps == lastStep

  const handleDisabledStep = (value: boolean) => {
    setDisabled(prev => ({ ...prev, [steps]: value }))
  }

  const changeStep = (value: number) => {
    const newStep = steps + value
    const exisDisabled = disabled[newStep] == undefined
    if (exisDisabled) {
      setDisabled(prev => ({ ...prev, [newStep]: true }))
    }
    if ((newStep) <= lastStep) {
      setSteps(prev => prev + value)
      if (props.changeStep) {
        props.changeStep({ newValue: newStep, oldValue: steps })
      }
    }

    if (isLastStep && value > 0) {
      props.lastStep.finish()
    }
  }

  const restart = () => {
    setSteps(0)
    setDisabled({
      0: true
    })
  }

  useImperativeHandle(ref, () => ({
    restart,
    getToStep: (value) => { setSteps(value) },
    disableSteps: (payload) => { setDisabled(prev => ({ ...prev, ...payload })) }
  }))

  return (
    <Box>
      <Box sx={styles.header}>
        {(steps > 0 || !!props.customeBack) && (backComponent)}

        {(steps <= 0) && (<Box />)}

        <Button variant="contained" disabled={!!disabled[steps]} onClick={() => { changeStep(1) }}>{!isLastStep ? t("general.continue") : props.lastStep.label}</Button>
      </Box>

      {props.children({ steps, disabled, handleDisabledStep })}
    </Box>
  )
})

export { StepManagement }
