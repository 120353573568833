import { useTheme } from '@mui/material'
import { Color } from '../../utils/theme/types/color'

export const useStyles = (color: Color) => {
  const { breakpoints } = useTheme()
  return {
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '0 0 0 20px',
      flexDirection: 'column',
      position: 'relative',
      alignItems: 'center',
      width: 'calc(100%)',
      height: '100%',
      gap: '10px',
    },
    iconSvg: {
      width: '100%',
      height: '100%',
      '&.MuiIcon-root': {
        fontSize: '1.8em',
        '& .MuiSvgIcon-root': {
          fontSize: '1em',
        },
      },
    },
    img: {
      width: 200,
      height: 200,
      '& img': {
        objectFit: 'cover',
      },
      borderRadius: '50%',
      overflow: 'hidden',
      marginBottom: '20px',
      boxShadow: `0 0 3px 1px ${color.color10}`,
    },
    fullname: {
      fontSize: '25px',
      fontWeight: '500',
      color: color.color9,
    },
    label: {
      fontSize: '14px',
      fontWeight: '400',
      color: color.color10,
    },
    infContainer: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      gap: '10px',
      paddingTop: '5px',
    },
    title: {
      width: '100%',
      marginBottom: '10px',
      textAlign: 'center',
      color: color.color18,
      fontSize: '20px',
      fontWeight: '700',
      paddingRight: '20px',
      wordBreak: 'break-all',
    },
    header: {
      flex: 0.05,
      marginTop: '20px',
      // paddingRight: '30px'
    },
    body: {
      width: '100%',
      flex: 0.85,
      overflowY: 'auto',
      paddingBottom: '10px',
    },
    bodyContent: {
      paddingRight: '20px',
      marginTop: '20px',
      height: 'calc(100% - 20px)',
    },
    buttonContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flex: 0.05,
      position: 'relative',
      top: '10px',
    },
    buttonContained: {
      fontSize: '12px',
      // fontWeight: '500',
      height: 'fit-content',
      maxWidth: '230px',
      [breakpoints.between(1320, 1450)]: { maxWidth: '200px' },
      [breakpoints.between('xs', 1320)]: { maxWidth: '180px' },
      wordBreak: 'break-word',
      '& .MuiTypography-root': {},
    },
    buttonOutlined: {
      fontSize: '12px',
      fontWeight: '500',
      position: 'absolute',
      right: '20px',
      // bottom: '-15px',
    },
    imgContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    menu: {
      boxShadow: `0 0 2px 1px ${color.color17}`,
      width: 'fit-content',
      borderRadius: '5px',
      padding: '4px',
      position: 'absolute',
      top: '20px',
      right: '20px',
      color: color.color4,
    },
    containerDetail: {
      position: 'relative',
      paddingBottom: '80px',
    },
  }
}
