import { useEffect, useState } from 'react'
import { onChange } from '../../../../../../../utils/types'
import { Item } from '../../../../../../../utils/types/generals'
import { searchInModeLower } from '../../../../../../../utils/validate/validate'

const useFilter = () => {
  const [dataFilter, setDataFilter] = useState<Record<string, unknown>>({})
  const [counter, setCounter] = useState<number | null>(null)

  const filterChange: onChange = ({ target }) => {
    const { name, value } = target
    setDataFilter((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const filterRow = ({ value, list }: { value: Record<string, unknown>; list: any[] }) => {
    const filterApllied = list?.filter((itemFilter) => {
        const roles = value?.roles as Item[]
        const userTypes = value?.usersTypes as Item[]
        const search = `${itemFilter.name} ${itemFilter.curp}`

        const rolesExist = !!roles?.length ? roles?.some((itemRoles) => itemRoles.value == itemFilter.role) : true
        const userTypesExist = !!userTypes?.length ? userTypes?.some((itemUserType) => itemUserType.value == itemFilter.userType) : true
        const searchExist = searchInModeLower(search, value.search as string)
        
        return !!rolesExist && !!userTypesExist && !!searchExist
      })
      
    return filterApllied
  }

  const cleanFilter = () => {
    setDataFilter({})
  }

  useEffect(() => {
    setCounter(null)
    let count = 0
    for (let key in dataFilter) {
      if (dataFilter[key] instanceof Array) {
        if ((dataFilter[key] as any)?.length > 0) count++
      } else if (dataFilter[key] != null && dataFilter[key] != '') count++
    }

    setCounter(count)
  }, [dataFilter])
  return { dataFilter, counter, filterChange, filterRow, cleanFilter }
}

export { useFilter }
