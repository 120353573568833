import { useContextState } from "../../../../../hooks/theme"
import { TypeOfNotifications } from "./enums"

const useStyles = ({ type }: { type: TypeOfNotifications }) => {
  const { colors } = useContextState()
  const notifyColors = () => {
    switch (type) {
      case TypeOfNotifications.ERROR:
      case TypeOfNotifications.BLOCK:
        return colors.color19;
      case TypeOfNotifications.SUCCESS:
      case TypeOfNotifications.INFO:
        return colors.color16;
      case TypeOfNotifications.WARNING:
        return colors.color29;

    }
  }
  return {
    container: {
      width: '500px',
      minHeight: '100px',
      borderRadius: '10px',
      padding: '20px 10px 10px 60px',
      position: 'relative',
      overflow: 'hidden',
      boxShadow: `0px 0px 4px ${colors.color13}`,
    },
    statusRounded: {
      width: '100px',
      height: '100px',
      borderRadius: '100%',
      position: 'absolute',
      top: 'calc(50% - 50px)',
      left: '-70px',
      background: notifyColors()
    },
    containerActions: {
      position: 'absolute',
      top: '5px',
      right: '10px',
      display: 'flex',
      alignItems: 'center'
    },
    close: {
      fontSize: '.8rem',
      '& .MuiSvgIcon-root': {
        fontSize: '1rem',
      }
    },
    date: {
      fontWeight: '600',
      color: colors.color13,
      fontSize: '.7rem',
    },
    title: {
      marginTop: '10px',
      fontSize: '.8rem',
      color: notifyColors(),
      lineHeight: '1.1rem',
      fontWeight: '600',
      wordBreak: 'break-word',
    },
    description: {
      fontSize: '.8rem',
      color: colors.color13,
      wordBreak: 'break-word',
      lineHeight: '1.1rem',
    }
  }
}

export { useStyles }
