import { Roles } from '../../utils/enums'
import { Http } from '../../utils/types'
import { GetBlob, ObjectGeneric } from '../../utils/types/generals'
import { getUserRole } from '../auth'
import { AllCompanies, AssociateDivisionsToCompanies, GetCompanyByID, CreateCompanies, ResponseGetAllAgents, ResponseUpdateSettings, ResponseCompaniesPAgination } from './response'

export const companyService = (client: Http) => ({
  getAllCompanies: async (): Promise<AllCompanies> => {
    if(getUserRole() === Roles.ADMIN) {
      return await client.get('companies')
    } 
    return await {
      errors: {}
    } as AllCompanies
  }, 
  getPaginationCompanies: async (data?: { queary?: string }): Promise<ResponseCompaniesPAgination> => await client.get(`companies/detailed${data?.queary ? '?' + data?.queary : ''}`), 
  getDefaultStyles: async (): Promise<any> => await client.get('app-settings/default'), 
  getCompanyById: async (id:number): Promise<GetCompanyByID> => await client.get(`companies/${id}`),
  createCompanies: async (body: ObjectGeneric): Promise<CreateCompanies> => await client.post('companies', body),
  associateZonesCompanies: async (body: ObjectGeneric): Promise<AssociateDivisionsToCompanies> => client.post('companies/zones', body),
  updateCompanies: async (body: ObjectGeneric, id:number) => {
    try {
      return await client.put(`companies/${id}`, body)
    } catch (e) {
      return { errors: e }
    }
  },
  updateSettings: async (body: ObjectGeneric, id:number | string): Promise<ResponseUpdateSettings> => await client.put(`app-settings/${id}`, body, true),
  getAllAgents: async (): Promise<ResponseGetAllAgents> => await client.get(`companies/agents`),
  downloadTemplate: async (data: { type: `pdf` | 'word' | 'excel', query?: string }): Promise<GetBlob> => await client.getBlob?.(`companies/generate-file?type=${data.type}${data?.query ? `${data?.query}` :  ''}`)
})
