import { Box, Button, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { BaseInput, MassiveUpload, SnackSimple } from '../../../../components'
import { useContextState } from '../../../../hooks/theme'
import { RolesService, statusService, UserService } from '../../../../service'
import { CRUD, Status, Types, Roles, RequestErrors } from '../../../../utils/enums'
import { InformativeCard, InputModel } from '../../../../utils/types'
import { Item, ObjectGeneric, onChange, onChangeEvent, Table } from '../../../../utils/types/generals'
import { formData, formatFilter, initialFilterValues, initialTable, initialValues, input, storeHeaders, supervisorHeaders, useMenuItems } from './initialState'
import { canListUnits, filterRow, filterRowStore, formatAssociateHierarchyTable, formatAssociateStoreTable, getUserList, handleErrors, submit } from './methods'
import { useStyles } from './style'
import DetailCard from '../../../../components/detailCard'
import { getStatus } from '../../../../hooks/status'
import Form from './components/form'
import Loader from '../../../../components/loader'
import { format } from 'date-fns'
import { Constants } from '../../../../utils/constants'
import { generalDisabled } from '../../../../utils/validate/validate'
import { storesService } from '../../../../service/stores'
import { httpFetch } from '../../../../utils/crud'
import BaseModal from '../../../../components/modal/baseModal'
import ModalCrudTable from '../../../../components/modalTableWithFilter'
import { icons } from '../../../../utils/icons'
import General from '../../../../components/modules/general'
import { FiltersDrawer } from '../../../../components/filtersDrawer'
import { DinamicForm } from '../../../../components/dinamicForm'
import { UploadForm } from './components/uploadForm'
import useGlobalErrors from '../../../../hooks/erors'
import SimpleModal from '../../../../components/modal/simpleModal'
import { validate } from '../../../auth/utils/validate'
import { useFilter } from '../../../../hooks/filter'
import { returnQuery, returnQueryWithPagination } from '../../../../utils/methods/returnQuery'
import { useDownload } from '../../../../hooks/download/useDownload'
import { ModelDownloadButtons } from '../../../../components/donwloadFiles/model'
import { companiesServiceFetch, storeServices, userServiceFetch } from '../../../../utils/constants/callService'
import { StatusUsers } from '../../../../service/user/response'
import { getDivisions, getUserTypes } from '../../../../utils/methods/geServiceWithItemsFormat'
import { images } from '../../../../utils/images'
import { handleSee, openModalUpdateUserData, updatePassword, updateResponse, updateUserEmail } from './methods/index'
import { useRefs } from '../../../../hooks/useReactHookModified'
import { DetailKeyValuRef } from '../../../../components/detailKeyValue/types'

const User = () => {
  const { addRef, refsByKey} = useRefs<DetailKeyValuRef>()
  const { t } = useTranslation()
  const { colors, state } = useContextState()
  const styles = useStyles(colors)
  const [accountHistory, setAccountHistory] = useState<JSX.Element | null>(null)
  const [information, setInformation] = useState<InformativeCard | null>(null)
  const [data, setData] = useState<{ [x: string]: InputModel }>(initialValues)
  const [btnLoading, setBtnLoading] = useState(false)
  const [isTableStoreLoading, setIsTableStoreLoading] = useState(false)
  const [flag, setFlag] = useState(false)
  const [allErrors, setAllErrors] = useState<ObjectGeneric>({})
  const [table, setTable] = useState<Table>(initialTable)
  const [rolesList, setRolesList] = useState<any[]>([])
  const [roles, setRoles] = useState<any[]>([])
  const [storeList, setStoreList] = useState<Item[]>([])
  const [companiesList, setCompaniesList] = useState<any[]>([])
  const [divisionCompani, setdivisionCompani] = useState<any[]>([])
  const [userTypeList, setUserTypeList] = useState<any[]>([])
  const [agentList, setAgentList] = useState<Item[]>([])
  const [tableAssociateStore, setTableAssociateStore] = useState<Table>({ column: [], row: [] })
  const [manageButton, setManageButton] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [oldCup, setOldCurp] = useState('')
  const [curpExist, setCurpExist] = useState(false)
  const [dataFilter, setDataFilter] = useState<ObjectGeneric>({})
  const [drawerStatus, setDrawerStatus] = useState<any>()
  const [currentInfo, setCurrentInfo] = useState<any>()
  const [password, setPassword] = useState('')
  const [inputs, setInputs] = useState<{ [x: string]: InputModel }>(input)
  const [erros, setErrors] = useState<ObjectGeneric>({})
  const [generalLists, setGeneralLists] = useState<ObjectGeneric>({})
  const [loadMassiveActive, setLoadMassiveActive] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
    type: Types.SUCCESS as string,
  })
  const [openSnackbarSome, setOpenSnackbarSome] = useState({
    open: false,
    message: '',
    type: Types.SUCCESS as string,
  })

  const [openModalEdit, setOpenModalEdit] = useState<{
    open: boolean;
    title: string;
    description: string;
    name: string;
    label: string;
    value?: any
    id: string | null
    cancel?: () => void;
    comfirm?: (value: ObjectGeneric) => void;
  }>({
    open: false,
    description: '',
    label: '',
    name: '',
    title: '',
    id: null
  })
  const [isValid, setIsValid] = useState(false)
  const [isLoadingUpdateData, setIsLoadingUpdateData] = useState(false)
  const [email, setEmail] = useState<string | null>(null)
  const [errorsEmail, setErrorsEmail] = useState({})
  const [openModalUpdateEmail, setOpenModalUpdateEmail] = useState(false)
  const [updateEmail, setUpdateEmail] = useState(false)
  const [isAgentDown, setIsAgentDown] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [hierarchy, setHierarchy] = useState(true)
  const [tableDate, setTableDate] = useState({
    totalPages: 1,
    totalItems: 0,
    currentPages: 1,
  })
  const [row, setRow] = useState<Table['row']>([])
  const [userByStoreRole, setUserByStoreRole] = useState<Item[]>([])
  const [selected, setSelected] = useState<number>(-1)
  const [open, setOpen] = useState(false)
  const [crud, setCrud] = useState(false)
  const [loadingTable, setLoadingTable] = useState(true)
  const [drawerDetail, setDrawerDetail] = useState({
    title: '',
    accept: '',
    cancel: '',
  })

  const {
    filterData,
    query,
    counter,
    paginationModel,
    handlePagination,
    cleanFilter: handleCleanFilter,
    onChange: handleFilterChange,
    sortTable,
    filterString,
  } = useFilter({
    formatFilter
  })

  const {
    download,
    openSnackbar: snackbarDownloadModal,
    downloadCSV,
    downloadPDF,
    downloadWORD,
    closeOpenSnakbar,
  } = useDownload({
    docName: t('general.tags.users'),
    query: returnQueryWithPagination({ filter: filterString, sort: query.sort }),
    downloadFuntion: userServiceFetch.getRegister,
  })
  const downloadPass = useDownload({
    docName: t('general.tags.users'),
    query: returnQueryWithPagination({ filter: filterString, sort: query.sort }),
    downloadFuntion: userServiceFetch.getPasswords,
  })
  const [modalData, setModalData] = useState({
    open: false,
    header: '',
    body: '',
    status: 0,
  })
  const menuList = useMenuItems(styles)
  const [menu, setMenu] = useState<
    {
      label: string
      icon?: any
      value?: any
    }[]
  >(menuList)
  const isSupervisor = parseInt(information?.data?.find((item) => item.name === 'role')?.selected ?? '') === Roles.SUPERVISOR
  const { errors } = useGlobalErrors({ data, t, custome: { curp: allErrors?.curp } })

  const handleCloseSnackbar = (value: boolean) => {
    setOpenSnackbar({ ...openSnackbar, open: value })
    setOpenSnackbarSome({ ...openSnackbarSome, open: value })
  }


  const getDivisionsZones = async ({ companyId }: { companyId?: string; }) => {
    if (companyId) {
      const response = await UserService(httpFetch).getDivisionsZones(companyId)
      if (!response.errors) {
        setdivisionCompani(
          response?.divisions?.map((item: { description: any; id: any }) => ({
            label: item.description,
            value: item.id,
          }))
        )
      }
    } else {
      setdivisionCompani([])
    }
  }

  const getAgentsOrStores = async ({ companyId, role }: { companyId?: string; role?: number }) => {
    if (!!companyId) {
      if (!!role) {
        const permissions = canListUnits({ role })

        if (!permissions.store) {
          const responseUsers = await UserService(httpFetch).userHierarchyByIds(companyId, role.toString())
          setAgentList(
            responseUsers?.map((itemAgentsByCompany: any) => ({
              label: `${itemAgentsByCompany.firstName} ${itemAgentsByCompany.lastName} - ${itemAgentsByCompany.curp}`,
              value: itemAgentsByCompany?.id,
              roleId: itemAgentsByCompany?.roleId,
            }))
          )
        }

        if (permissions.isRoleStore) {
          const responseUsers = await UserService(httpFetch).getUsersPartnerByCompanyId({ companyId })
          const agentsPartnert = responseUsers?.map((itemAgentsByCompany: any) => ({
            label: `${itemAgentsByCompany.firstName} ${itemAgentsByCompany.lastName} - ${itemAgentsByCompany.curp}`,
            value: itemAgentsByCompany?.id,
            roleId: itemAgentsByCompany?.roleId,
          }))

          setAgentList(agentsPartnert)
        }

        if (permissions.store) {
          const responseAllStores = await storesService(httpFetch).getStoresByCompanies(companyId)
          setStoreList(
            responseAllStores?.map((itemStoresByCompany) => ({
              label: `${itemStoresByCompany?.keyCode} - ${itemStoresByCompany?.name}`,
              value: itemStoresByCompany?.id,
              roleId: null,
            }))
          )
        }
      }
    } else {
      setAgentList([])
      setStoreList([])
    }
  }

  const crudChange = async (e: onChangeEvent | string, extra: any): Promise<void> => {
    if (typeof e !== 'string') {
      const { name, value } = e?.target
      let existedCurp = curpExist ?? false
      let body = { ...data }

      if (name === 'role' && value !== data.role.value) {
        const permissions = canListUnits({ role: value })

        body = {
          ...data,
          [name]: {
            ...data[name],
            value,
          },
          partnerStore: {
            ...data.partnerStore,
            value: permissions.typeSelect === 'select' ? null : [],
          },
          agents: {
            ...data.agents,
            value: [],
          },
        }
        getAgentsOrStores({ companyId: data?.companyId?.value, role: value })
      } else {
        body = {
          ...data,
          [name]: {
            ...data[name],
            value: (name === 'partnerStore' && body.role.value === Roles.PARTNER) || ['userType', 'companyId'].includes(name) ? value?.value : value,
          },
        }
      }
      let response: {
        curpExists: boolean
      } = { curpExists: false }

      if (name === 'curp') {
        setCurpExist(false)
        existedCurp = false
        if (value?.length >= 10 && oldCup !== value && !!extra?.blur) {
          response = await UserService(httpFetch).curpExist(value)

          setCurpExist(!!response?.curpExists)
          existedCurp = !!response?.curpExists
        }
      }

      if (name === 'companyId') {
        body = {
          ...body,
          partnerStore: {
            ...body.partnerStore,
            value: [],
          },
          agents: {
            ...body.agents,
            value: [],
          },
          divisions: {
            ...body.divisions,
            value: null,
          },
        }
        setData((prev) => ({ ...prev, ...body }))
        getDivisionsZones({ companyId: value?.value })
        getAgentsOrStores({ companyId: value?.value, role: body?.role?.value })
      }

      setData((prev) => {
        const newBody = {
          ...prev,
          ...body,
        }
        const getErrors = handleErrors(newBody, t, existedCurp)
        setAllErrors(getErrors)
        return newBody
      })
    }
  }

  useEffect(() => {
    if (updateEmail) handleSubmit()
  }, [updateEmail])

  const handleSubmit = async () => {
    const email1 = currentInfo?.e?.email
    const email2 = data?.email?.value

    if (email1 != email2 && drawerStatus == 'EDIT') {
      setOpenModalUpdateEmail(true)
      return
    }

    setBtnLoading(true)
    const response = await submit(data, t, information?.id, oldCup)

    if (!response?.curpExist) {
      if (!response?.response?.errors) {
        if (updateEmail) {
          await handleConfirmEmail({ id: information?.id, value: email2, editAllUserData: true })
          getAllUsers({ queary: returnQuery(query, formData) })
        } else {
          if (response?.snackbarBody) {
            setOpenSnackbar(response?.snackbarBody)
          }
        }
        getAllUsers({ queary: returnQuery(query, formData) })
      } else {
        if (response?.snackbarBody) {
          setOpenSnackbar(response?.snackbarBody)
        }
      }

      setCurpExist(false)
      setOldCurp('')
    } else {
      setCurpExist(true)
      setAllErrors({
        curp: t('general.validations.existedCurp'),
      })
    }
    setDataFilter({})
    setErrors({})
    setBtnLoading(false)
    setUpdateEmail(false)
    setDrawerStatus(null)
  }

  const handleInformation = async (e: any, index: number) => {
    setBtnLoading(true)
    openDrawer(true)
    const response = await userServiceFetch.userById(e.id)
    setIsAgentDown(response?.role?.id >= 3 && response?.role?.id <= 7)
    setCurrentInfo({ e, index })
    setDrawerStatus(null)
    setSelected(index)
    setCurpExist(false)
    setOldCurp('')
    setManageButton(true)
    setAccountHistory(null)
    let data: any[] = [
      {
        label: t(`general.tags.curp`),
        value: response.curp,
        name: 'curp',
      },
      {
        label: t(`general.tags.firstName`),
        value: response.firstName,
        name: 'firstName',
      },
      {
        label: t(`general.tags.lastName`),
        value: response.lastName,
        name: 'lastName',
      },
      {
        label: t(`general.tags.gender`),
        value: t(`general.fields.gender.${response.gender + 1}`),
        name: 'gender',
      },
      {
        label: t(`general.tags.email`),
        value: response.email,
        name: 'email',
        copy: true,
        edit: true,
      },
      {
        label: t(`general.tags.password`),
        value: '********',
        name: 'password',
        edit: true,
        see: true,
      },
      {
        label: t(`general.tags.phone`),
        value: response.phone,
        name: 'phone',
      },
    ]
    setAllErrors({})
    if (e) {
      setMenu(menuList?.filter((item) => item?.value !== e.status?.value))
    }

    setInformation(
      e
        ? {
          data,
          tmpData: data,
          status: {
            color: e?.status?.color,
            text: e?.status?.text,
            value: e?.status?.value,
          },
          image: response?.picture ?? images.profile,
          id: e?.id,
        }
        : null
    )

    setData(initialValues)
    setBtnLoading(false)
  }




  const agentListByRole = () => {
    const permissions = canListUnits({ role: data?.role?.value })
    if (permissions.isRoleStore) {
      return [...agentList, ...userByStoreRole]
    }
    return agentList
  }

  const handleMenu = async (item: any) => {
    setDrawerStatus(item.value)

    setManageButton(false)
    if (![CRUD.EDIT, 'accountHistory'].includes(item.value)) {
      setModalData({
        header: `${t('general.messages.areYouSure')} ${t(`general.messages.status.${item.value}`)} ${t(`general.messages.thisUser`)}`,
        body: t(`general.messages.description.${item.value}`),
        status: item.value,
        open: true,
      })
    } else if (item.value === CRUD.EDIT) {
      // setIsAgent(false)
      setCrud(true)
      setBtnLoading(true)
      setCurpExist(false)
      getUserTypeList()
      getAllCompanies()

      const responseUser = await userServiceFetch.userById(information?.id ?? 0)
      let bodyUser = {} as any

      information?.tmpData?.forEach((element) => {
        let newValue = element?.selected ?? element.value

        if (element.name === 'phone') {
          const phoneValue = element.value
          if (!!phoneValue) {
            if (!!phoneValue?.includes('+52')) {
              newValue = phoneValue
            } else if (phoneValue[0] == '+') {
              const lengthPhone = phoneValue.length
              newValue = `+52${phoneValue.slice(3, lengthPhone)}`
            } else if (phoneValue?.[0] !== '+') {
              newValue = `+52${phoneValue}`
            }
          }
        }
        bodyUser = {
          ...bodyUser,
          [element.name as string]: {
            ...data[element.name as string],
            value: newValue,
          },
        }
      })
      const companyId = responseUser?.userCompanyUserType?.[0]?.company?.id
      getDivisionsZones({ companyId: companyId?.toString() })
      getAgentsOrStores({ companyId: companyId?.toString(), role: responseUser?.role?.id })

      const permissions = canListUnits({ role: responseUser.role.id })
      const responseAgentsOrStore = await UserService(httpFetch)[!permissions.store ? 'getAsociateUser' : 'getAsociateUserStores'](information?.id ?? 0)
      let responseAgents: Item[] = []

      if (permissions.isRoleStore) {
        const response = await UserService(httpFetch).getAsociateUser(information?.id ?? 0)
        responseAgents = response?.map((item) => ({
          label: `${item.firstName} ${item.lastName}`,
          value: item?.id,
          roleId: item.roleId,
        })) ?? []
        setUserByStoreRole(responseAgents)
      } else {
        setUserByStoreRole([])
      }

      const tableStore = formatAssociateStoreTable(responseAgentsOrStore, t, colors, isSupervisor)
      setTableAssociateStore(tableStore)
      setOldCurp(responseUser?.curp ?? '')
      setData((prev) => ({
        ...prev,
        picture: {
          ...prev.picture,
          value: responseUser.picture,
        },
        curp: {
          ...prev.curp,
          value: responseUser.curp,
        },
        firstName: {
          ...prev.firstName,
          value: responseUser.firstName,
        },
        lastName: {
          ...prev.lastName,
          value: responseUser.lastName,
        },
        email: {
          ...prev.email,
          value: responseUser.email,
        },
        username: {
          ...initialValues.username,
          value: '0',
        },
        partnerStore: {
          ...prev.partnerStore,
          value:
            permissions.typeSelect === 'select'
              ? responseAgentsOrStore[0]?.id
              : responseAgentsOrStore?.map((item: any) => ({
                label: !permissions.store ? `${item.firstName} ${item.lastName}` : `${item.keyCode} - ${item.name}`,
                value: item?.id,
                roleId: !permissions.store ? item.roleId : null,
              })),
        },
        agents: {
          ...prev.agents,
          value: responseAgents,
        },
        companyId: {
          ...prev.companyId,
          value: companyId ?? null,
        },
        divisions: {
          ...prev.divisions,
          value: responseUser?.divisionId ? { value: responseUser?.divisionId } : null,
        },
        userType: {
          ...prev.userType,
          value: responseUser?.userTypeId,
        },
        gender: {
          ...prev.gender,
          value: responseUser?.gender || responseUser?.gender == 0 ? (responseUser?.gender + 1).toString() : null,
        },
        role: {
          ...prev.role,
          value: responseUser?.roleId,
        },
        phone: {
          ...prev.phone,
          value: bodyUser?.phone?.value,
        },
        accessFolioReportGeneral: {
          ...prev.accessFolioReportGeneral,
          value: responseUser?.accessFolioReportGeneral ? responseUser?.accessFolioReportGeneral : true,
        },
      }))
      setBtnLoading(false)
    } else if (item.value === 'accountHistory') {
      setAccountHistory(<Loader />)

      let fullName = {
        label: t(`general.tags.fullname`),
        name: 'fullname',
        value: `${information?.tmpData?.[1]?.value} ${information?.tmpData?.[2]?.value}`,
      }
      setInformation({
        ...information,
        data: [fullName],
      })

      userServiceFetch
        .getAccountHistory(information?.id as number)
        .then((response) => {
          setAccountHistory(
            response?.length > 0 ? (
              <>
                <Typography sx={styles.text}>{t(`general.tags.accountHistory`)}</Typography>
                {response?.map((item, index: number) => {
                  const userManage = item.userManage
                  return (
                    <DetailCard
                      index={index}
                      status={item?.status?.id}
                      title={`${t('general.tags.user')} ${item.status.description.toLowerCase()} ${t('general.connectives.by')} ${userManage?.firstName || userManage?.lastName ? `${userManage?.firstName ?? ''} ${userManage?.lastName ?? ''}` : userManage?.email ?? ''
                        }`}
                      email={userManage?.email ?? ''}
                      date={item.updateTime ? format(new Date(item.updateTime), Constants.dateFormatGeneral) : ''}
                    />
                  )
                })}
              </>
            ) : (
              <Typography sx={styles.text}>{t(`general.notHistory`)}</Typography>
            )
          )
        })
        .catch((e) => {
          setAccountHistory(null)
          console.error(`account: ${e}`)
        })
    }
  }

  const handleConfirm = async () => {
    try {
      let response: StatusUsers
      const modalStatus = modalData.status as Status | CRUD
      switch (modalStatus) {
        case Status.ACTIVE:
          response = await userServiceFetch.activedUser(information?.id)
          break
        case Status.BLOCKED:
          response = await userServiceFetch.blockUser(information?.id)
          break
        case Status.WITH_DRAW:
          response = await userServiceFetch.withDrawUser(information?.id)
          break
        case CRUD.DELETE:
          response = await userServiceFetch.deleteUser(selected)
          break
        default:
          response = { errors: [{ code: RequestErrors.ERR_NOT_FOUND }] } as any
          break
      }

      if (!response.errors) {
        setOpenSnackbarSome({
          open: true,
          message: t(`user.modal.delete.success.${modalStatus}`),
          type: Types.SUCCESS as string,
        })
        getAllUsers({ queary: returnQuery(query, formData), donotClean: modalStatus != CRUD.DELETE })
        const status = response?.statusId
        setMenu(menuList?.filter((item) => item.value !== status))
        setInformation(prev => ({ ...prev, status: getStatus(status, colors, t) }))
      } else {
        setOpenSnackbarSome({
          open: true,
          message: t(`user.modal.delete.error.${modalStatus}`),
          type: Types.ERROR as string,
        })
      }
    } catch (e) {
      console.error(`UpdateStatus:  ${e}`)
    }
    setModalData({ ...modalData, open: false, status: 0 })
  }

  const getAllUsers = useCallback(
    async (data?: { queary: string; donotClean?: boolean; body?: ObjectGeneric }) => {
      if (!data?.donotClean) {
        resetValues()
      }
      setLoadingTable(true)
      const responseAllUsers = await getUserList(colors, t, { queary: returnQuery({ ...query, filter: query.filter?.replaceAll('+', '%2B') }, formData) })
      setTable(responseAllUsers)
      setRow(() => filterRow(data?.body ?? dataFilter, responseAllUsers.row))

      const { currentPage, totalItems, totalPages } = responseAllUsers
      setTableDate({
        currentPages: currentPage ?? 1,
        totalItems: totalItems ?? 0,
        totalPages: totalPages ?? 1,
      })

      setFlag(true)
      setData(initialValues)
      setLoadingTable(false)
    },
    [colors, t, query]
  )

  const getUserTypeList = async () => {
    const { items } = await getUserTypes()

    setUserTypeList(items)
    setInputs((prev) => ({
      ...prev,
      userType: {
        ...prev.userType,
        items: items,
      },
    }))
  }
  const getAllData = useCallback(async () => {
    getAllDivisions()
    if (!generalLists?.status) {
      // getAllUsers()
      const data = statusService()
        .statusList()
      let valideStatus: any[] = []

      data?.forEach((item: any) => {
        if ([Status.ACTIVE, Status.WITH_DRAW, Status.BLOCKED].includes(item?.id)) {
          valideStatus.push({
            value: item?.id,
            label: item.description,
          })
        }
      })
      setGeneralLists((prev) => ({
        ...prev,
        status: valideStatus,
      }))
      setInputs((prev) => ({
        ...prev,
        status: {
          ...prev.status,
          items: valideStatus,
        },
      }))

      if (!generalLists?.roles) {
        RolesService()
          .rolseList()
          .then((data) => {
            const roles =
              data?.roles
                ?.filter((item: any) => item?.id !== Roles.ADMIN)
                ?.map((item: any) => ({
                  value: item?.id,
                  label: item.description,
                })) ?? []
            setRolesList(roles)
            setGeneralLists((prev) => ({
              ...prev,
              roles,
            }))
            setInputs((prev) => ({
              ...prev,
              role: {
                ...prev.role,
                items: roles,
              },
            }))

            setRoles(
              data?.roles
                ?.filter((item: any) => item?.id !== Roles.ADMIN)
                ?.map((item: any) => ({
                  value: item?.id,
                  label: item.description,
                })) ?? []
            )
          })
          .catch((e) => {
            console.error('errorRoles', e)
          })
      }
      if (!generalLists?.userType) {
        const { items } = await getUserTypes()

        setInputs((prev) => ({
          ...prev,
          userType: {
            ...prev.userType,
            items: items,
          },
        }))
      }
    }

    storeServices.getAllStores()
      .then(allStores => {
        if (!allStores.errors) {
          let stores: Item[] = []
          allStores?.forEach((item: any) => {
            if (!stores.some((element) => element?.value === item?.id)) {
              stores = [
                ...stores,
                {
                  value: item?.id,
                  label: `${item?.keyCode} - ${item?.name}`,
                },
              ]
            }
          })
          setInputs((prev) => ({
            ...prev,
            stores: {
              ...prev.stores,
              items: stores,
            },
          }))
        }
      })
    // eslint-disable-next-line
  }, [getAllUsers])

  const getAllDivisions = async () => {
    const { items } = await getDivisions()

    setInputs((prev) => ({
      ...prev,
      division_ids: {
        ...prev.division_ids,
        items: items,
      },
    }))
  }

  useEffect(() => {
    if (!flag) getAllData()
  }, [getAllData, flag])

  const handleTableStore = (agent?: any, checkHierarchy?: boolean) => {
    setIsTableStoreLoading(true)
    if (checkHierarchy) {
      UserService(httpFetch)['getUserHierarchy'](information?.id ?? 0)
        .then((response: any) => {
          const tableStore = formatAssociateHierarchyTable(response, t, colors, agent)
          setTableAssociateStore(tableStore)
        })
        .finally(() => {
          setIsTableStoreLoading(false)
        })
    } else {
      UserService(httpFetch)
      ['getAsociateUserStores'](information?.id ?? 0)
        .then((response: any) => {
          const items = !response?.errors ? response : []
          const tableStore = formatAssociateStoreTable(items, t, colors, isSupervisor, agent)
          setTableAssociateStore(tableStore)
          setData((prev) => ({
            ...prev,
            partnerStore: {
              ...prev.partnerStore,
              value: items?.map((item: any) => ({
                label: isSupervisor ? `${item.agentUser.firstName} ${item.agentUser.lastName}` : item.name,
                value: isSupervisor ? item.agentId : item?.id,
              })),
            },
          }))
        })
        .finally(() => {
          setIsTableStoreLoading(false)
        })
    }
  }

  const getAllCompanies = async () => {
    const response = await companiesServiceFetch.getAllCompanies()
    if (!response.errors) {
      setCompaniesList(
        response
          .filter((item) => item.statusId === Status.ACTIVE)
          ?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
      )
    }
    return true
  }

  const filterChange: onChange = () => { }

  const onChange: onChange = (e, blur) => {
    handleFilterChange(e, blur)
  }

  const resetValues = () => {
    setCrud(false)
    setOpen(false)
    setData(initialValues)
    setBtnLoading(false)
    setSelected(-1)
  }
  const openDrawer = (edit?: boolean) => {
    setPassword('')
    setDrawerDetail({
      title: t(`user.informationCard.${edit ? 'edit' : 'create'}.title`),
      accept: t(`user.informationCard.buttons.${edit ? 'edit' : 'add'}`),
      cancel: t('userType.informationCard.buttons.cancel'),
    })
    if (!edit) {
      setData(initialValues)
    }
    setInformation(null)
    setAllErrors({})
    setCrud(!edit)
    setOpen(true)
  }

  const closeDrawer = () => {
    setOpen(false)
    setCrud(false)
    setSelected(-1)
    setPassword('')
  }

  const cleanFilter = () => {
    handleCleanFilter()
  }

  const handleCreate = async () => {
    setPassword('')
    setIsAgentDown(false)
    setSelected(-1)
    setDrawerStatus(null)
    setUserByStoreRole([])
    setBtnLoading(true)
    openDrawer()
    setManageButton(false)
    getAllCompanies()
    getUserTypeList()
    setBtnLoading(false)
    setData(initialValues)
    setAllErrors({})
    setCurpExist(false)
    setOldCurp('')
  }

  const goLoadMassive = () => {
    getAllCompanies()
    getUserTypeList()
    resetValues()
    setLoadMassiveActive(true)
  }

  const openModalUpdateUser = (id: any, value: any, payload: { name: string }) => {
    openModalUpdateUserData({
      confirmUpdatePassword,
      handleConfirmEmail,
      setOpenModalEdit,
      closeModalEdit,
      setEmail,
      t,
      name: payload.name,
      value,
      id,
    })
  }

  const closeModalEdit = () => {
    setOpenModalEdit(prev => ({ ...prev, open: false }))
  }

  const onChangeEmail = ({ target }: any) => {
    const { value, name } = target
    setOpenModalEdit(prev => ({
      ...prev,
      value
    }))
    const errors: Record<string, string> = validate(
      {
        [name]: {
          label: 'email',
          name: name,
          value,
          validate: {
            required: true,
            email: true,
          },
        },
      },
      t
    )
    setErrorsEmail(
      validate(
        {
          [name]: {
            label: 'email',
            name: name,
            value,
            validate: {
              required: true,
              email: true,
            },
          },
        },
        t
      )
    )

    setIsValid(!errors?.[name] && email !== value)
  }

  const confirmUpdatePassword = (param?: any) => {
    setIsLoadingUpdateData(true)
    updatePassword({
      id: param?.id?.toString() ?? "",
      password: param.value,
      setOpenSnackbar: setOpenSnackbar,
      updateResponse: (passwordWasUpdated) => {
        if (passwordWasUpdated) {
          setPassword('')
        }
        updateResponse({
          closeModalEdit: closeModalEdit,
          passwordWasUpdated,
          refPassword: refsByKey['password'],
          setInformation: setInformation,
          setIsLoadingUpdateData: setIsLoadingUpdateData,
        })
      }
    })
  }

  const handleConfirmEmail = (param?: any) => {
    updateUserEmail({
      closeModalEdit,
      setInformation,
      setOpenModalUpdateEmail,
      setIsLoadingUpdateData,
      setOpenSnackbar,
      setRow,
      setTable,
      t,
      id: param?.id,
      information,
      table,
      value: param?.value,
      editAllUserData: param.editAllUserData,
    })
  }

  useEffect(() => {
    getAllUsers()
    //eslint-disable-next-line
  }, [query.all])

  return (
    <>
      {!loadMassiveActive && (
        <>
          <SnackSimple close={handleCloseSnackbar} open={openSnackbar.open} type={openSnackbar.type} message={openSnackbar.message} />
          <SnackSimple close={closeOpenSnakbar} open={snackbarDownloadModal.open} type={snackbarDownloadModal.type} message={snackbarDownloadModal.message} />
          <General
            newHeader={
              <Box sx={styles.header}>
                <Typography sx={styles.title}>Usuarios</Typography>

                <Box sx={styles.flex}>
                  <ModelDownloadButtons
                    extraChildren={{
                      end: state.role == Roles.ADMIN ? (
                        <Tooltip title={'Descargar contraseñas'}>
                          <Button
                            variant='outlined'
                            color='primary'
                            disabled={downloadPass.download.excel}
                            onClick={() => {
                              downloadPass?.downloadCustome()
                            }}
                          >
                            <Typography variant='subtitle2'>Contraseñas</Typography> {icons('download')}
                          </Button>
                        </Tooltip>
                      ) : undefined
                    }}
                    csv={downloadCSV} pdf={downloadPDF} word={downloadWORD} disableCSV={download?.excel} disablePDF={download?.pdf} disableWORD={download?.word} />

                  <IconButton
                    sx={(styles.clean, (counter || 0) > 0 ? null : styles.hide)}
                    onClick={() => {
                      cleanFilter()
                    }}
                  >
                    {icons('clean')}
                  </IconButton>
                  <IconButton
                    sx={styles.clean}
                    onClick={() => {
                      handleCreate()
                    }}
                  >
                    {icons('add')}
                  </IconButton>
                  <IconButton
                    sx={styles.load}
                    onClick={() => {
                      goLoadMassive()
                    }}
                  >
                    {icons('list')}
                  </IconButton>
                </Box>
              </Box>
            }
            filtersDrawer={
              <>
                <FiltersDrawer cleanFilter={cleanFilter} count={counter} right={-50} top={0}>
                  <Box sx={styles.section}>
                    <DinamicForm errors={erros} input={Object.values(inputs)} values={filterData} onChange={onChange} filter={true} />
                  </Box>
                </FiltersDrawer>
              </>
            }
            filter={{
              filterData: filterData,
              onChange: filterChange,
              title: 'Usuarios',
              subTitle: t('user.filter.filterTitle'),
              add: {
                onClick: handleCreate,
                icon: icons('add'),
              },
              load: {
                onClick: goLoadMassive,
                icon: icons('list'),
              },
            }}
            open={open}
            rightDrawer={{
              ...information,
              addRef,
              loadindDetail: btnLoading,
              customFields: accountHistory,
              autoHeight: isAgentDown ? true : false,
              paddingBottom: isAgentDown ? '25px' : 'unset',
              auxiliar: !!manageButton
                ? {
                  title: t(isSupervisor ? 'user.checkHierarchy' : 'user.checkStores'),
                  onClick: () => {
                    let checkHierarchy;
                    if (isSupervisor) {
                      checkHierarchy = true;
                      setHierarchy(true)
                    } else {
                      checkHierarchy = false;
                      setHierarchy(false)
                    }
                    const agent = `${currentInfo?.e?.firstName ?? ''} ${currentInfo?.e?.lastName ?? ''}`
                    setShowMessage(true)
                    handleTableStore(agent, checkHierarchy)
                    setOpenModal(true)
                  },
                }
                : undefined,
              auxiliarRight:
                !!isAgentDown && drawerStatus != 'EDIT'
                  ? {
                    title: t('user.checkHierarchy'),
                    onClick: () => {
                      const agent = `${currentInfo?.e?.firstName ?? ''} ${currentInfo?.e?.lastName ?? ''}`
                      setHierarchy(true)
                      setShowMessage(false)
                      handleTableStore(agent, true)
                      setOpenModal(true)
                    },
                  }
                  : undefined,
              data: information?.data,
              showImage: true,
              form: (
                <>
                  <Form
                    btnLoading={btnLoading}
                    data={data}
                    crudChange={crudChange}
                    allErrors={errors}
                    companyList={companiesList}
                    userTypeList={userTypeList}
                    rolesList={rolesList}
                    storeList={storeList}
                    agentList={agentListByRole()}
                    divisionCompani={divisionCompani}
                  />
                </>
              ),
              menuItems: menu,
              crud,
              title: drawerDetail.title,
              accept: {
                title: drawerDetail.accept,
                disabled: generalDisabled(data.curp.value ? data : { ...data, curp: { ...data.curp, value: null } }) || curpExist,
                onClick: handleSubmit,
              },
              cancel: {
                title: drawerDetail.cancel,
                onClick:
                  drawerStatus != 'EDIT'
                    ? closeDrawer
                    : () => {
                      const { e, index } = currentInfo
                      handleInformation(e, index)
                    },
              },
              clickMenu: handleMenu,
              changeEmail: openModalUpdateUser,
              handleSee: (value) => {
                handleSee({
                  id: information?.id?.toString() ?? "",
                  tmpPassword: password,
                  value,
                  setInformation,
                  getResponse: (response) => { setPassword(response) },
                })
              }
            }}
            table={{
              loadingTable: loadingTable,
              column: table.column,
              row: row,
              title: t('user.filter.title'),
              onClick: handleInformation,
              selected,
              serverSidePagination: {
                page: (paginationModel.page ?? 0) + 1,
                size: paginationModel?.pageSize ?? 20,
                totalPages: tableDate.totalPages,
              },
              handleChange: (page: number, pageSize: number) => {
                handlePagination({ page, pageSize })
              },
              sortable: (field, sort, count) => {
                sortTable(field && sort ? { field: field, sort: sort, count } : undefined)
              },
              order: query?.sort && {
                count: query?.sort?.count,
                key: query?.sort?.field,
                order: query?.sort?.sort,
              }
            }}
          />
          <SimpleModal
            open={modalData.open}
            close={() => {
              setModalData({ ...modalData, open: false, status: 0 })
            }}
            title={modalData.header}
            description={modalData.body}
            buttonText={t('general.confirm')}
            cancelText={t('general.cancel')}
            color={colors}
            back={() => {
              setModalData({ ...modalData, open: false, status: 0 })
            }}
            next={() => {
              handleConfirm()
            }}
          />
          <SnackSimple close={handleCloseSnackbar} open={openSnackbarSome.open} type={openSnackbarSome.type} message={openSnackbarSome.message} />

          <BaseModal open={openModal} sx={{ width: '600px' }}>
            {isTableStoreLoading ? (
              <Loader />
            ) : (
              <ModalCrudTable
                t={t}
                filterRow={filterRowStore}
                color={colors}
                exportFiles
                table={tableAssociateStore}
                customeHeader={
                  !showMessage ? null : !!hierarchy ? storeHeaders({ t }) : supervisorHeaders({ t })
                }
                initialFilter={initialFilterValues}
                setInformation={(e) => { }}
                onClose={() => {
                  setOpenModal(false)
                  setTableAssociateStore({ column: [], row: [] })
                }}
                filter={
                  !!hierarchy
                    ? {
                      filterTitle: '',
                      label: t("user.modal.searchUserHiersrchy"),
                      name: 'search',
                      title: `${t(`user.modal.userHierarchy`)}: ${currentInfo?.e?.firstName ?? ''} ${currentInfo?.e?.lastName ?? ''}`,
                      type: 2,
                      sx: { width: "100%" },
                    } : {
                      filterTitle: '',
                      label: t(`user.modal.searchStore`),
                      name: 'search',
                      title: t(`user.modal.associateStore`),
                      type: 2,
                      sx: { width: "100%" }
                    }
                }
              />
            )}
          </BaseModal>
        </>
      )}
      {loadMassiveActive && (
        <MassiveUpload
          goBack={() => {
            setLoadMassiveActive(false)
          }}
          uploadDone={() => {
            getAllUsers()
          }}
          moduleName="usuarios"
          colors={colors}
          children={<UploadForm companies={companiesList} roles={roles} userType={userTypeList}></UploadForm>}
        />
      )}

      <SimpleModal
        sx={styles.simpleModal}
        open={openModalEdit.open}
        isLoading={isLoadingUpdateData}
        removeButtons={false}
        title={openModalEdit.title}
        loadingButton
        disableButton={!isValid}
        close={closeModalEdit}
        children={
          <Box>
            <Box>
              <p style={{ fontSize: '12px', marginTop: '-10px', padding: '0 0 8px 0', color: '#686868' }}>
                Esta acción va a afectar la información de la cuenta <br />
                ¿Desea continuar?
              </p>
            </Box>

            <Grid item lg={12} sx={{ padding: '20px 0' }}>
              <BaseInput
                name={openModalEdit.name}
                value={{
                  [openModalEdit.name]: {
                    value: openModalEdit.value,
                    label: openModalEdit.label,
                  },
                }}
                onChange={onChangeEmail}
                errors={errorsEmail}
                sx={{ width: '300px' }}
              />
            </Grid>
          </Box>
        }
        buttonText={t('general.confirm')}
        cancelText={t('general.cancel')}
        color={colors}
        back={openModalEdit.cancel}
        next={() => {
          if (openModalEdit.comfirm) {
            openModalEdit.comfirm(openModalEdit)
          }
        }}
      />

      <SimpleModal
        sx={styles.simpleModal}
        open={openModalUpdateEmail}
        isLoading={btnLoading}
        loadingButton
        removeButtons={false}
        title={'Edición de email'}
        close={() => {
          setOpenModalUpdateEmail(false)
        }}
        children={
          <Box>
            <Box>
              <p style={{ fontSize: '12px', marginTop: '-10px', padding: '10px 20px 20px 20px', color: '#686868', textAlign: 'center' }}>Se verificó un cambio en el correo. Esta acción va a afectar la información de la cuenta. ¿Desea continuar?</p>
            </Box>
          </Box>
        }
        buttonText={t('general.confirm')}
        cancelText={t('general.cancel')}
        color={colors}
        back={() => {
          setOpenModalUpdateEmail(false)
        }}
        next={() => {
          const email2 = data?.email?.value
          let obj = JSON.parse(JSON.stringify(currentInfo))
          obj.e.email = email2
          setCurrentInfo(obj)
          setUpdateEmail(true)
        }}
      />
    </>
  )
}

export default User
