import { useState } from "react"
import { DashboardReportFolioContext } from "../context"
import { FilterProps } from "../../../../../hooks/filter/typing"
import { useFilter } from "../../../../../hooks/filter"

const DashboardReportFolioProvider = (props: { children: JSX.Element }) => {
    const [filterPayload, setFilterPayload] = useState<FilterProps>({formatFilter: {}})
    const filterData = useFilter(filterPayload)
    return (
        <DashboardReportFolioContext.Provider
            value={{ filterData,setFilterPayload }}
        >{props.children}
        </DashboardReportFolioContext.Provider>
    )
}

export { DashboardReportFolioProvider }
