import { Constants } from '../../utils/constants'

export const initialFilterValues = {
  role: '',
  status: '',
  search: '',
}

export const filesValidate = {
  accept: `${Constants.acceptFile.xlsx}`,
  correctTypeFile: [Constants.fileType.xlsx],
}
