import { Box, Typography } from "@mui/material"
import { Tooltip2Props } from "./types"
import { useStyles } from "./styles"

const Tooltip2 = (props: Tooltip2Props) => {
    const styles = useStyles()

    return (
        <Box sx={styles.container}>
            <Box sx={styles.status({ type: props.type })} />

            <Box sx={styles.containerDescription}>
                <Typography sx={styles.subtitle}>{props.points}: ({props.percentage})</Typography>

                <Typography sx={styles.subtitle}>{props.label}</Typography>
            </Box>
        </Box>
    )
}

export { Tooltip2 }
