import { ItemMenu } from '../../../../../components/baseMenu'

const menuItems: ItemMenu[] = [
  {
    label: 'Editar',
    value: 1,
  },
  {
    label: 'Cerrar',
    value: 2,
  },
  {
    label: 'Ver reporte',
    value: 3,
  },
  {
    label: 'Reenviar reporte',
    value: 4,
  },
  {
    label: 'Ver detalle',
    value: 5,
  },
]

export { menuItems }
