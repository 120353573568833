import { Slider, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { onChange } from '../../../utils/types/generals'

interface Props {
  onChange: onChange
  values: any
}

const PercentageRange = ({ values, onChange }: Props) => {
  const minDistance = 1
  const { percentage } = values
  const [valueRange, setValueRange] = useState<number[]>(percentage ? percentage : [0, 100])

  const handleChangeRange = (event: Event, newValue: number | number[], activeThumb: number) => {
    if (!Array.isArray(newValue)) return
    let newValueRange = []
    if (activeThumb === 0) {
      newValueRange = [Math.min(newValue[0], valueRange[1] - minDistance), valueRange[1]]
    } else {
      newValueRange = [valueRange[0], Math.max(newValue[1], valueRange[0] + minDistance)]
    }
    
    setValueRange(newValueRange)
    onChange({
      target: {
        name: 'percentage',
        value: newValueRange,
      },
    })
  }

  useEffect(() => {
    if (!percentage) setValueRange([0, 100])
  }, [percentage])

  return (
    <>
      <Typography
        sx={{
          fontSize: '16px',
          color: '#707070',
          marginBottom: '40px',
        }}
      >
        Rango de resultados
      </Typography>

      <div style={{ width: '98%', margin: '0 auto' }}>
        <Slider value={valueRange} onChange={handleChangeRange} disableSwap valueLabelDisplay="on" />
      </div>
    </>
  )
}

export default PercentageRange
