const useStyles = () => {
  return {
    percentageStatus: ({ color }: { color: string }) => ({
      background: color,
      width: '10px',
      height: '10px',
      borderRadius: '50%',
    }),
    containerTitle: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: '5px'
    },
  }
}

export { useStyles }
