const draweWithOpen = 240
const draweWithClose = 120
const draweWithOpenPaddigLeft = 270
const draweWithClosePaddigLeft = 150
const draweWithClosePaddigLeftTable = 40
export { 
  draweWithOpen, 
  draweWithClose,
  draweWithOpenPaddigLeft,
  draweWithClosePaddigLeft,
  draweWithClosePaddigLeftTable
}