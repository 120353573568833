import { RolesService, UserService, UserType } from '../../service'
import { actionPlansServices } from '../../service/actionPlans'
import { adminService } from '../../service/admin'
import { areaService } from '../../service/area'
import { companyService } from '../../service/company'
import { dashboardServices } from '../../service/dashboard'
import { departmentService as departService } from '../../service/department'
import { divisionService as division } from '../../service/division'
import { questionarieServise } from '../../service/questionarie'
import { reportFolioServices as service } from '../../service/reportFolio'
import { storesService } from '../../service/stores'
import { UserServiceSchedule } from '../../service/userSchedule'
import { zipCodeService } from '../../service/zipcode'
import { zonesService } from '../../service/zones'
import { httpFetch } from '../crud'
import { OpenSearch } from '../crud/openSearch'

const dashboardService = dashboardServices(OpenSearch)

const rolesServices = RolesService()

const companiesServiceFetch = companyService(httpFetch)
const zipCodeServiceFetch = zipCodeService(httpFetch)
const userServiceFetch = UserService(httpFetch)
const adminServiceFetch = adminService(httpFetch)
const userTypeServiceFetch = UserType(httpFetch)
const actionPlanFetch = actionPlansServices(httpFetch)
const userScheduleServiceFetch = UserServiceSchedule(httpFetch)
const questionnaire = questionarieServise(httpFetch)
const departmentService = departService(httpFetch)
const reportFolioServices = service(httpFetch)
const storeServices = storesService(httpFetch)
const areaServices = areaService(httpFetch)
const divisionService = division(httpFetch)
const zoneService = zonesService(httpFetch)
const dashboardServiceFetch = dashboardServices(httpFetch)

export { dashboardService, dashboardServiceFetch, adminServiceFetch, zipCodeServiceFetch, actionPlanFetch, companiesServiceFetch, reportFolioServices, storeServices, userScheduleServiceFetch, areaServices, departmentService, divisionService, questionnaire, zoneService, rolesServices, userServiceFetch, userTypeServiceFetch}
