import { Storage } from 'aws-amplify'
import * as AWS from 'aws-sdk'
import { AwsCustomized, AwsDelete, AwsPut, AwsUploadFiles } from './typing'
import { MutableRefObject } from 'react'
import { typeFiles } from '../../utils/constants/typeFIles'

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESSS_KEY_ID,
  correctClockSkew: true
})

const bucket = new AWS.S3({
  params: { Bucket: process.env.REACT_APP_EVIDENCES },
  region: process.env.REACT_APP_REGION,
})

export const sharedAws: AwsCustomized = {
  put: async (body: AwsPut) => {
    const path = `${body.name}/${body.file.name}`.replace(/\s+/g, '')
    const localFile = new Blob([new Uint8Array(await body.file.arrayBuffer())], { type: body.file.type })
    const blob = await localFile
    const type = typeFiles[body.file.type] ?? body.file.type
    const params = {
      Body: blob,
      Bucket: body.otherBucket ?? process.env.REACT_APP_EVIDENCES ?? '',
      Key: path,
      ContentType: type,
    }

    const upload = bucket.putObject(params)

    const MIN_PROGRESS = 10
    let progressCache = 0

    upload.on('httpUploadProgress', (evt) => {
      const progress = Math.floor((evt.loaded / evt.total) * 100)
      if (progress >= progressCache + MIN_PROGRESS) {
        progressCache = progress
        body.progressCallback?.(progress - 1)
      }
    })

    upload.send(async (err) => {
      if (err) {
        body?.errorCallback?.(err)
      } else {
        const url = `https://${process.env.REACT_APP_EVIDENCES}.s3.amazonaws.com/${path}`
        body?.completeCallback?.(url)
      }
    })

    body.cancelUpload?.(upload)
  },
  delete: async (body: AwsDelete) => {
    var params = {
      Bucket: process.env.REACT_APP_EVIDENCES ?? '',
      Key: body.name,
    }

    bucket.deleteObject(params, function (err, data) {
      if (err) console.warn(err, err.stack) // an error occurred
      else console.warn(data) // successful response
    })
    return null
  },
  uploadAndGetUrl: async (body: AwsUploadFiles) => {
    const path = `${body?.name ?? new Date().getTime()}/${body.file.name}`.replace(/\s+/g, '')
    const localFile = new Blob([new Uint8Array(await body.file.arrayBuffer())], { type: body.file.type })
    const blob = await localFile
    const type = typeFiles[body.file.type] ?? body.file.type
    const params = {
      Body: blob,
      Bucket: body.otherBucket ?? process.env.REACT_APP_EVIDENCES ?? '',
      Key: path,
      ContentType: type,
    }

    const upload = await bucket.upload(params).promise()
    return upload.Location
  }
}

export const StorageAmplify = {
  sendFile: async (
    file: File,
    name?: string,
    otherBucket?: string,
    data?: {
      progress: (load: string) => void
      customPrefix?: {
        public: string
      }
      ref?: {
        ref: MutableRefObject<Record<string, any> | null>
        key: string
      }
    }
  ) => {
    const customName = name ? `${name}_${file.name}` : file.name

    const type = typeFiles[file.type] ?? file.type
    try {
      const response = Storage.put(
        customName.replace(/\s+/g, ''),
        new File([file], customName.replace(/\s+/g, ''), {
          type: file?.type,
          lastModified: file.lastModified,
        }),
        {
          bucket: otherBucket ?? process.env.REACT_APP_BUCKET,
          customPrefix: data?.customPrefix,
          contentType: type,
          progressCallback: (progress) => {
            data?.progress(parseFloat(((progress.loaded / progress.total) * 100)?.toString()).toFixed(2) ?? '')
          },
          errorCallback: (e) => {
            console.warn('error upload amplify', e)
          },
        }
      )

      let customeRef: Record<string, any> = {
        [data?.ref?.key ?? '']: () => {
          Storage.cancel(response)
        },
      }
      if (data?.ref?.ref) {
        data.ref.ref.current = {
          ...(data.ref.ref.current ?? {}),
          ...customeRef,
        }
      }
      const key = await response
      return (await Storage.get(key.key, { download: false, customPrefix: data?.customPrefix, bucket: otherBucket ?? process.env.REACT_APP_BUCKET }))?.split('?')?.[0]
    } catch (e) {
      console.error(`error to the send File: ${e}`)
      return null
    }
  },
  removeFile: async ({
    name,
    otherBucket,
    customPrefix,
  }: {
    name: string
    otherBucket?: string
    customPrefix?: {
      public: string
    }
  }) => {
    const response = await Storage.remove(name.replace(/\s+/g, ''), {
      bucket: otherBucket ?? process.env.REACT_APP_BUCKET,
      customPrefix: customPrefix,
    })
    return response?.$metadata?.httpStatusCode && response?.$metadata?.httpStatusCode <= 299
  },
  listFile: async ({
    customPrefix,
  }: {
    customPrefix?: {
      public: string
    }
  }) =>
    await Storage.list('', {
      customPrefix: customPrefix,
    }),
}
