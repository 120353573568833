import { useState } from 'react'
import { onChange } from '../../../../../../../utils/types'
import { Item } from '../../../../../../../utils/types/generals'

const useCreatEdit = () => {
  const [data, setData] = useState<Record<string, unknown>>({})
  const [dataSetting, setDataSetting] = useState<Record<string, Item[]>>({})

  const onChange: onChange = ({ target }) => {
    const { name, value } = target
    setData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const onChangeSetting: onChange = ({ target }) => {
    const { name, value } = target
    setDataSetting((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handledata = (data?: Record<string, unknown>) => {
    setData(data ?? {})
  }

  const handleFolioSetting = (data?: Record<string, Item[]>) => {
    setDataSetting(data ?? {})
  }

  return { data, dataSetting, onChange, onChangeSetting, handleFolioSetting, handledata }
}

export { useCreatEdit }
