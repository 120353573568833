import { TFunction } from 'react-i18next'
import { CRUD } from '../../../../utils/enums'
import { images } from '../../../../utils/images'
import { InputModel } from '../../../../utils/types'

export const initialFilterValues = {
  role: '',
  status: '',
  search: '',
}
export const formData: Record<string, string> = {
  search: "search",
  companyName: "company_name",
  "createYear": "year",
  "month": "month",
  "from":  "start_date",
  "to": "end_date",
  "status":"status_ids",
}
export const useFilterDetail = (t: TFunction<'translation', undefined>) => {
  return {
    search: {
      label: t('companie.filter.inputs.search'),
      name: 'search',
    },
    status: {
      label: t('companie.filter.inputs.firstSelect'),
      name: 'status',
    },
  }
}

export const initialValues = {
  logo: {
    label: 'Cargar imagen',
    translate: true,
    name: 'logo',
    type: 'picture',
    value: null,
    rest: {
      customImage: images.store,
    },
    size: {
      xl: 4,
      lg: 4,
      md: 6,
      sm: 6,
    },
  },
  name: {
    label: 'general.tags.name',
    translate: true,
    name: 'name',
    rest: {
      type: 'alphaNumeric',
    },
    validate: {
      required: true,
    },
    value: null,
  },
  companyName: {
    label: 'general.tags.company_name',
    translate: true,
    validate: {
      required: true,
    },
    rest: {
      type: 'alphaNumeric',
    },
    name: 'companyName',
    value: null,
  },
  phone: {
    label: 'general.tags.phone',
    translate: true,
    name: 'phone',
    type: 'phone',
    value: '',
    validate: {
      phone: true,
    },
  },
  states: {
    label: 'general.tags.states',
    translate: true,
    name: 'states',
    type: 'select-search',
    items: [],
    validate: {
      required: true,
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
    },
    value: null,
  },
  cities: {
    label: 'general.tags.cities',
    translate: true,
    name: 'cities',
    type: 'select-search',
    items: [],
    validate: {
      required: true,
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
    },
    value: null,
  },
  status: {
    label: 'general.tags.status',
    translate: true,
    name: 'status',
    type: 'select-search',
    validate: {
      required: true,
    },
    value: null,
    size: {
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
    },
  },
  address: {
    label: 'general.tags.address',
    translate: true,
    name: 'address',
    value: null,
    validate: {
      required: true,
    },
  },
  divisions: {
    label: 'general.tags.divisions',
    translate: true,
    name: 'divisions',
    type: 'multiSelect',
    items: [],
    value: null,
    size: {
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
    },
  },
  zones: {
    label: 'general.tags.zones',
    translate: true,
    name: 'zones',
    type: 'multiSelect',
    items: [],
    value: null,
    size: {
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
    },
  },
} as { [x: string]: InputModel }

export const initialData = {
  name: null,
  companyName: null,
  zipCode: null,
  status: null,
  logo: null,
  phone: null,
  address: null,
  cities: null,
  states: null,
}

export const initialTable = {
  column: [],
  row: [],
}

export const gender = [
  {
    value: '1',
    label: 'Masculino',
  },
  {
    value: '0',
    label: 'Femenino',
  },
]

export const username = [
  {
    value: '1',
    label: 'Teléfono',
  },
  {
    value: '0',
    label: 'Correo electrónico',
  },
]

export const useMenuItems = (styles: any) => [
  {
    value: CRUD.EDIT,
    label: 'Editar compañía',
    // icon: <Icon sx={styles.menuIcons}>{icons('edit')}</Icon>,
  },
  {
    value: CRUD.EDIT_BRANDLY,
    label: 'Personalización de la APP',
    // icon: <Icon sx={styles.menuIcons}>{icons('edit')}</Icon>,
  },
]

export const input = {
  search: {
    label: 'general.search',
    translate: true,
    name: 'search',
    type: 'text',
    value: null,
  },
  createYear: {
    label: 'general.filterDate.year',
    translate: true,
    name: 'createYear',
    type: 'filter-select',
    items: [],
    value: null,
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  month: {
    label: 'general.filterDate.month',
    translate: true,
    name: 'month',
    type: 'filter-select',
    items: [],
    value: null,
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  from: {
    label: 'general.tags.from',
    translate: true,
    name: 'from',
    type: 'date',
    value: null,
    rest: {
      max: 'to',
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  to: {
    label: 'general.tags.to',
    translate: true,
    name: 'to',
    type: 'date',
    value: null,
    rest: {
      min: 'from',
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  status: {
    label: 'general.tags.status',
    translate: true,
    name: 'status',
    type: 'filter-select',
    items: [],
    value: null,
  },
} as { [x: string]: InputModel }

export const accept = ['png', 'jpg', 'jpeg']
