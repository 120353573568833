import { EvidencesTypeEnum, EvidencesTypeValueEnum } from "../enums/evidences";

const transformEvidences:Record<EvidencesTypeEnum, EvidencesTypeValueEnum> = {
  [EvidencesTypeEnum.CAMERA]:EvidencesTypeValueEnum.CAMERA,
  [EvidencesTypeEnum.COMMENT]:EvidencesTypeValueEnum.COMMENT,
  [EvidencesTypeEnum.FILE]:EvidencesTypeValueEnum.DOCUMENT,
  [EvidencesTypeEnum.IMAGE]:EvidencesTypeValueEnum.IMAGE,
  [EvidencesTypeEnum.SIGN]:EvidencesTypeValueEnum.SIGN,
  [EvidencesTypeEnum.VIDEO]:EvidencesTypeValueEnum.VIDEO,
  [EvidencesTypeEnum.VIDEO_GALLERY]:EvidencesTypeValueEnum.VIDEO_GALLERY,
}


const transformEvidencesNumber:Record<EvidencesTypeValueEnum, EvidencesTypeEnum> = {
  [EvidencesTypeValueEnum.CAMERA]:EvidencesTypeEnum.CAMERA,
  [EvidencesTypeValueEnum.COMMENT]:EvidencesTypeEnum.COMMENT,
  [EvidencesTypeValueEnum.FILE]:EvidencesTypeEnum.FILE,
  [EvidencesTypeValueEnum.DOCUMENT]:EvidencesTypeEnum.FILE,
  [EvidencesTypeValueEnum.IMAGE]:EvidencesTypeEnum.IMAGE,
  [EvidencesTypeValueEnum.SIGN]:EvidencesTypeEnum.SIGN,
  [EvidencesTypeValueEnum.VIDEO]:EvidencesTypeEnum.VIDEO,
  [EvidencesTypeValueEnum.VIDEO_GALLERY]:EvidencesTypeEnum.VIDEO_GALLERY,
}
export {transformEvidences, transformEvidencesNumber }