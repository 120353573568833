import { Http } from '../../utils/types'
import { ObjectGeneric } from '../../utils/types/generals'
import { TableResponse } from './reponse'

export const OptionGroupService = (client: Http) => ({
  getOptionGroup: async ():Promise<TableResponse> => await client.get('option-group/detailed'),
  getOptionGroupById: async (id: number) => await client.get(`option-group/${id}`),
  deleteOptionGroupById: async (id: number) => await client.delete(`option-group/${id}`),
  createOptionGroup: async (body: ObjectGeneric) => await client.post('option-group', body),
  updateOptionGroup: async (body: ObjectGeneric, id: number) => await client.put(`option-group/${id}`, body),
})
