import { Box, Checkbox } from "@mui/material"
import { useTranslation } from "react-i18next"
import { CustomTable } from "../../../../../../../../components"
import { Ref, forwardRef, useImperativeHandle, useState } from "react"
import { StepManagementChildren } from "../../../../../../../../components/stepManagement/types"

type Step2Ref = {
  getPermissions: () => Record<string, boolean>
  restart: () => void
}

type Step2Props = {
  message?: JSX.Element
  permissons: Record<string, boolean>
  handleDisabled: StepManagementChildren['handleDisabledStep']
}
const Step2 = forwardRef((props: Step2Props, ref: Ref<Step2Ref>) => {
  const { t } = useTranslation()
  const [permissons, setPermissions] = useState<Record<string, boolean>>(props.permissons)
  const columnsPermissions = [
    {
      id: 'action',
      label: "",
      headerCell: {
        width: "110px"
      },
    },
    {
      id: 'access',
      label: t('Questionarie.manageUser.access'),
      contentColumn: {
        justifyContent: "center"
      },
      rowTdStyles: {
        textAlign: "center"
      },
      renderCell: (params: any) => {
        return <Checkbox
          checked={!!permissons[params.current]}
          onChange={(e, value) => {
            e.stopPropagation()
            permissionSelected({ name: params.current, value })
          }}
        />
      }
    },
    {
      id: 'report',
      label: t('Questionarie.manageUser.report'),
      contentColumn: {
        justifyContent: "center"
      },
      rowTdStyles: {
        textAlign: "center"
      },
      renderCell: (params: any) => {
        return <Checkbox
          checked={!!permissons[params.current]}
          onChange={(e, value) => {
            e.stopPropagation()
            permissionSelected({ name: params.current, value })
          }}
        />
      }
    },
    {
      id: 'emailNotification',
      label: t('Questionarie.manageUser.emailNotification'),
      contentColumn: {
        justifyContent: "center"
      },
      rowTdStyles: {
        textAlign: "center"
      },
      renderCell: (params: any) => {
        return <Checkbox
          checked={!!permissons[params.current]}
          onChange={(e, value) => {
            e.stopPropagation()
            permissionSelected({ name: params.current, value })
          }}
        />
      }
    },
  ]

  const rowPermissions = [
    {
      id: 'Q',
      action: t(`general.tags.questionnaire`),
      access: "accessQ",
      report: "reportQ",
      emailNotification: "emailNotificationQ"
    },
    {
      id: 'F',
      action: t(`general.tags.folio`),
      access: "accessF",
      report: "reportF",
      emailNotification: "emailNotificationF"
    },
  ]

  useImperativeHandle(ref, () => ({
    getPermissions: () => permissons,
    restart: () => {
      setPermissions({})
      props.handleDisabled(true)
    }
  }))

  const permissionSelected = ({ name, value }: { name: string, value: boolean }) => {
    props.handleDisabled(!Object.values({ ...permissons ?? {}, [name]: value })?.some(item => !!item))
    setPermissions(prev => ({ ...prev, [name]: value }))
  }

  return (
    <Box>
      <CustomTable
        name="Informe General"
        dontRefresh
        column={columnsPermissions}
        row={rowPermissions}
        selected={-1}
      />

      {props.message}
    </Box>
  )
})

export { Step2 }
export type { Step2Ref }