import { useContextState } from '../../../../../hooks/theme'

const useStyles = () => {
  const { colors } = useContextState()

  return {
    padding: {
      padding: '10px',
    },
    fontAwesome: {
      padding: '3px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      gap: '10px',
      alignItems: 'center',
      width: 'max-content',
      maxWidth: '200px',
      borderRadius: '5px',
      overflow: 'hidden',
      // padding: '10px',
      height: 'fit-content',
      background: colors.color5,
    },
    fullName: {
      fontSize: '16px',
      fontWeight: '600',
      width: '100%',
      color: colors.color20,
      marginBottom: '10px',
    },
    detail: {
      fontSize: '10px',
      fontWeight: '500',
      width: '100%',
      color: colors.color20,
      display: 'flex',
      alignItems: 'center',
      wordBreak: 'break-word',
      gap: '5px',
      marginBottom: '5px',
    },
    icon: {
      color: colors.color5,
      background: colors.color2,
      padding: '5px',
      borderRadius: '50%',
      fontSize: '11px',
      width: '22px',
      height: '22px',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    img: {
      width: '100%',
      height: '100px',
      padding: '10px',
      '& img': {
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        objectFit: 'cover',
      },
    },
    floatImg: {
      position: 'absolute',
      top: '0',
      // left: '0',
      // width: '18px',
      // height: '18px',
      // border: '2px solid #fff',
      // borderRadius: '100%',
      userSelect: 'none',
      transform: 'translate(-0%, -170%)',
      '&:hover': {
        zIndex: '1',
      },
      width: '50px',
      height: '50px',
      '&::before': {
        content: "''",
        width: '3px',
        height: '30px',
        background: colors.color2,
        position: 'absolute',
        top: '-8px',
        left: '50%',
        transform: 'translate(0px, 58px)',
      },
      '& img': {
        border: `2px solid ${colors.color2}`,
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        objectFit: 'cover',
      },
    },
    userPoint: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
    },
    location: {
      color: colors.color2,
      fontSize: '40px',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    background: {
      background: colors.color5,
      width: '100%',
      height: '100%',
    },
  }
}

export { useStyles }
