import { useMemo } from "react"
import BaseModal from "../../../../../../../../../components/modal/baseModal"
import { DetailModalProps } from "./props"
import { detalModelTypes } from "./consts"
import { AreaDetailByDate } from "../area"
import { AreaDetailProps } from "../area/types"
import { useStyles } from "./styles"
import { DepartmentDetail } from "../department"
import { DepartmentDetailProps } from "../department/types"

const DetailModal = (props: DetailModalProps) => {
  const styles = useStyles()
  const component = useMemo(() => {
    if (props.type == detalModelTypes.area) {
      return <AreaDetailByDate close={props.close} payload={props.payload as AreaDetailProps['payload']} />
    }
    if (props.type == detalModelTypes.department) {
      return <DepartmentDetail close={props.close} payload={props.payload as DepartmentDetailProps['payload']} />
    }
    return <></>
  }, [props.type])

  return (
    <BaseModal sx={styles.modal} open={props.open}>
      {component}
    </BaseModal>
  )
}

export { DetailModal }