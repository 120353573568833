import { Box, Typography } from "@mui/material"
import { handleStatus } from "../../../../utils"
import { useTranslation } from "react-i18next"
import { useStatusStyles } from "./statusStyles"

const StatusItem = (params: any) => {
    const { t } = useTranslation()
    const styles = useStatusStyles()
    if(!params?.current?.value) return <></>
    return (
      <Box sx={styles.containerStatus}>
        <Typography
          sx={[styles.status, styles[params.current.value]]}
        >
          {handleStatus({ status: params?.current?.value, t })
            ?.statusSelected?.label ?? ''}
        </Typography>
      </Box>
    )
  }

export { StatusItem }
