import { StorageAmplify } from "../../../../../../service/bucketS3/Storage"
import { ActionPlanBody, BodyQuestionnaire, OptionBody, QuestionBody, QuestionnaireSectionBody } from "../../../../../../service/questionarie/bodys"
import { questionnaire } from "../../../../../../utils/constants/callService"
import { RequestErrors } from "../../../../../../utils/enums"
import { getIndexs } from "../../../../../../utils/methods/getIndexOfString"
import { Item, ObjectGeneric, OptionDatum } from "../../../../../../utils/types/generals"
import { getActionPlans, getEvidenceData } from "../actionPlan"
import { validateData } from "../validate"
import { SubmitPayload } from "./type"

const handleSubmit = async ({
    setSubmitDisabled,
    setLoadings,
    navigate,
    closeModal,
    setErrors,
    nextQuestionnarie,
    setModal,
    t,
    reportFolioData,
    payload,
    id,
    permissionsData,
    currentSection,
    data,
    evidencesModal,
    models,
    order,
    refsByKey,
    totalPoint,
    dataHeader,
    restartCurrentSection,
    setData
}: SubmitPayload) => {
    let actionPlanGlobal: ActionPlanBody[] | undefined
    const { errors, existSomeError } = validateData({ data, models, currentSection, evidencesModal, order, refsByKey, t, totalPoint, dataHeader, reportFolio: reportFolioData })

    if (dataHeader?.option == '1') {
        actionPlanGlobal = getActionPlans({ data: evidencesModal?.data?.global, t }).data
    }

    if (!existSomeError) {
        let body: BodyQuestionnaire = {
            statusId: 1,
            actionPlanByItem: dataHeader?.option == '1' ? 0 : 1,
            totalPoints: dataHeader?.totalPoints as number ?? 1,
            departmentId: (dataHeader?.departments as Item)?.value,
            areaId: (dataHeader?.areas as Item)?.value,
            name: ((dataHeader?.quest_name ?? '') as string),
            description: ((dataHeader?.description ?? '') as string),
            instruction: ((dataHeader?.instruction ?? '') as string),
            ...dataHeader?.option == '1' && { actionPlan: !!actionPlanGlobal ? actionPlanGlobal : [] },
            questionnaireSections: []
        }
        const arrayModels = Object.entries(models ?? {})?.sort(([a], [b]) => (order.questions[a] ?? 0) - (order.questions[b] ?? 0))
        let questionnaireSections: BodyQuestionnaire['questionnaireSections'] = []
        for (const [key, value] of arrayModels) {
            const section = data?.[key]
            const arrayQuestions = Object.entries(value.questions ?? {})?.sort(([a], [b]) => (order.questions[`${key}-${a}`] ?? 0) - (order.questions[`${key}-${b}`] ?? 0))
            const sectionName = section?.sectionName
            // Section
            let itemQuestionnaireSection: QuestionnaireSectionBody = {
                id: key,
                title: ((sectionName ?? '') as string).trim(),
                description: ((section?.sectionDescription ?? '') as string)?.trim(),
                questions: [],
                statusId: 1
            }
            // Questions
        for (const indexQ in arrayQuestions) {
            const [keyQ, valueQ] = arrayQuestions[indexQ]
                const isGeneralEvidence = evidencesModal?.evidencesQuestionGeneralActive?.[keyQ]
                let question = section?.[keyQ]
                const option = question?.questionOption?.value
                const arrayAnswers = Object.entries(valueQ.answers ?? {}).slice(0, !!['3'].includes(option) ? 1 : undefined)
                const isTypeMultiple = option == 2
                question.helpText = question?.helpText?.trim() || null
                question.answerText = question?.answerText?.trim() || null
                let itemQuestion: QuestionBody = {
                    comments: 0,
                    required: 1,
                    title: question?.questionText?.trim() ?? '',
                    ...!!question?.helpText && { textHelp: question?.helpText?.trim() || null },
                    options: [],
                    typeQuestionId: option ?? 1,
                    ...!['3'].includes(option) && {
                        optionGroupId: question?.questionTemplate?.value,
                        description: (question?.answerText?.trim() || null),
                    },
                    ...!!['3'].includes(option) && {
                        description: (question?.answerText?.trim() || null),
                    },
                    ...!!isGeneralEvidence && {
                        evidenceRequire: 1, //esto depende de si la evidencia esta por pregunta o respuesta,
                        evidenceType: getEvidenceData({ evidenceType: evidencesModal?.evidences?.general?.[keyQ] }) //aqui van las evidencias
                    }
                }
                const someAnswerHaveActionPlanActive = Object.values(question?.answer ?? {})?.some((item: any) => !!item?.actionPlanActive)

                // Answers
                for (const indexA in arrayAnswers) {
                    const [keyA] = arrayAnswers[indexA]
                    const answers = question?.answer?.[keyA]
                    const reportFolio = reportFolioData?.[`${keyQ}-${isTypeMultiple ? "multiple" : keyA}`]
                    let actionPlan: { data: ActionPlanBody[], errors: ObjectGeneric } = { data: [], errors: {} }
                    if (dataHeader?.option == '0' && (!!answers?.actionPlanActive)) {
                        actionPlan = getActionPlans({ data: evidencesModal?.data?.[`${keyQ}-${isTypeMultiple ? "multiple" : keyA}`], t })
                    }
                    let itemOption: OptionBody = {
                        description: (option == '4' ? (answers?.answer?.text ?? '') : (answers?.answer ?? '')),
                        ...!!answers?.answer?.file && { iconUrl: answers?.answer?.file },
                        score: answers?.value ?? 0,
                        correctOption: !!answers?.switchOption,
                        actionPlanRequire: !!answers?.actionPlanActive ? 1 : 0,
                        ...(dataHeader?.option == '0' && (!!answers?.actionPlanActive || (!!someAnswerHaveActionPlanActive && isTypeMultiple)) && !!actionPlan.data?.length) && {
                            actionPlan: actionPlan.data
                        },
                        ...!isGeneralEvidence && {
                            evidenceRequire: 1, //esto depende de si la evidencia esta por pregunta o respuesta,
                            evidenceType: getEvidenceData({ evidenceType: evidencesModal?.evidences?.answers?.[keyQ]?.[isTypeMultiple ? "multiple" : keyA] }), //aqui van las evidencias
                        },
                        configReportFolio: !!answers?.reportFolio ? {
                            isAreaRequired: true,
                            isDepartmentRequired: true,
                            askForArea: true,
                            askForDepartment: true,
                            configActionPlanReportFolioEvidenceTypes: getEvidenceData({ evidenceType: reportFolio?.evidencesData?.evidencesData }),
                            enableRatingQuestion: !!reportFolio?.default?.rejectsFolio?.enableSurvey,
                            isRatingQuestionRequired: !!reportFolio?.default?.rejectsFolio?.enableSurveyRequired,
                            ...!!reportFolio?.default?.rejectsFolio?.enableSurvey && { ratingQuestion: (reportFolio?.default?.rejectsFolio?.satisfactionQuestion as string) },
                            ...!!reportFolio?.default?.rejectsFolio?.maxRejects && { maxRejects: (reportFolio?.default?.rejectsFolio?.maxRejectsAmount as string) },
                            ...!!reportFolio?.default?.limitFolios?.maxFolios && { max: (reportFolio?.default?.limitFolios?.maxFoliosAmount as string) }
                        } : null,
                    }

                    // Step Questions
                    const actionValue = answers?.action?.value
                    if (actionValue && !isTypeMultiple) {
                        const indexAnswer = arrayQuestions?.findIndex(item => item[0] == actionValue)
                        if (indexAnswer > parseInt(indexQ)) {
                            itemOption = {
                              ...itemOption,
                              stepId: indexAnswer.toString()
                            }
                          } else {
                            setData(prev => {
                              prev[key][keyQ].answer[keyA].action = null
                              return prev
                            })
                          }
                    }


                    itemQuestion.options.push(itemOption)
                }

                itemQuestionnaireSection.questions.push(itemQuestion)
            }

            questionnaireSections.push(itemQuestionnaireSection)
        }

        body = {
            ...body,
            questionnaireSections: questionnaireSections?.sort((a, b) => (order.sections[a.id ?? ''] ?? 0) - (order.sections[b.id ?? ''] ?? 0))?.map(item => {
              delete item.id
              return item
            })
        }

        let title = `Questionarie.modal.permission.${!!id ? 'update' : 'create'}`, description = `Questionarie.modal.permission.description`
        if (!!permissionsData?.usersWithoutPersmissions?.length) {
            title = "general.messages.errors.thereAreUsersWithoutPermissionsTitle"
            description = "general.messages.errors.thereAreUsersWithoutPermissionsDescription"
        }
        setModal({
            open: true,
            title: t(title),
            description: t(description),
            cancel: closeModal,
            confirm: async () => {
                setLoadings(prev => ({ ...prev, next: true }))
                const res = {
                    ...body,
                    questionnaireSections: await Promise.all(
                        body?.questionnaireSections.map(async (file) => {
                            return {
                                ...file,
                                questions: await Promise.all(
                                    file.questions.map(async (item) => {
                                        return {
                                            ...item,
                                            options: await Promise.all(
                                                item.options.map(async (itemOptions) => {
                                                    return {
                                                        ...itemOptions,
                                                        iconUrl: (itemOptions?.iconUrl as File)?.name ? await StorageAmplify.sendFile(itemOptions.iconUrl as File, `${new Date().getTime()}`, process.env.REACT_APP_BUCKET_ICON_QUESTIONNARIES) : itemOptions?.iconUrl,
                                                    }
                                                })
                                            ),
                                        }
                                    })
                                ),
                            }
                        })
                    ),
                }

                try {
                    setLoadings(prev => ({ ...prev, main: true }))
                    let data
                    if (!!id) {
                        data = await questionnaire.updateQuestionnarie(id, res)
                    } else {
                        data = await questionnaire.createQuestionnarie(res)
                    }

                    if (!data?.errors) {
                        restartCurrentSection()
                        const permission = payload ?? permissionsData
                        let permissionsResponse: any
                        if (!permission?.usersWithoutPersmissions?.length && (Object.values(permission?.checksUser ?? {})?.length || Object.values(permission?.usersOld ?? {})?.length) > 0 && !!permission?.thereAreChanged) {
                            permissionsResponse = await nextQuestionnarie(data?.id.toString(), { payload: permission })
                        }
                        if (!id) {
                            navigate(`/dashboard/questionnarie/edit/${data.id}`, { replace: true })
                        }
                        if (typeof permissionsResponse === 'boolean' || !permissionsResponse) {
                            setModal({
                                open: true,
                                title: t(`Questionarie.modal.submit.success.title`),
                                description: t(`Questionarie.modal.submit.success.description`),
                            })

                        } else {
                            const error: '00' | '01' = permissionsResponse?.errors?.[0]?.code
                            const errorMessages = {
                                '00': 'Questionarie.modal.permission.errors.00',
                                '01': 'Questionarie.modal.permission.errors.01',
                            }

                            setModal({
                                open: true,
                                title: t(`Questionarie.modal.permission.errors.title`),
                                description: t(errorMessages[`${error}`]),
                            })
                        }
                    } else {
                        let errorMessage = ''
                        let title = ''
                        const firstError = data.errors[0]
                        let endpointErrors: Record<string, any> = {}

                        firstError?.data?.questionnaireMain?.forEach((item) => {
                            ; (item.data as OptionDatum[])?.forEach((itemData) => {
                                endpointErrors = {
                                    ...endpointErrors,
                                    [itemData?.field ?? '']: true,
                                }
                            })
                        })

                        firstError?.data?.questionnaireSections?.forEach((item) => {
                            ; (item.data as OptionDatum[])?.forEach((itemData) => {
                                const indexSection = getIndexs({ value: itemData.path })?.map((itemIndex) => itemIndex.toString())
                                const tranform: Record<string, string> = {
                                    title: 'sectionName',
                                }
                                endpointErrors = {
                                    ...endpointErrors,
                                    [indexSection[0]]: {
                                        ...(endpointErrors[indexSection[0]] ?? {}),
                                        [tranform[itemData.field]]: true,
                                    },
                                }
                            })
                        })

                        firstError?.data?.options?.forEach((item) => {
                            ; (item.data as OptionDatum[])?.forEach((itemData) => {
                                const indexSection = getIndexs({ value: itemData.path })?.map((itemIndex) => itemIndex.toString())
                                const tranform: Record<string, string> = {
                                    score: 'value',
                                }
                                endpointErrors = {
                                    ...endpointErrors,
                                    [indexSection[0]]: {
                                        ...(endpointErrors[indexSection[0]] ?? {}),
                                        questions: {
                                            ...(endpointErrors[indexSection[0]]?.questions ?? {}),
                                            [indexSection[1]]: {
                                                ...(endpointErrors?.[indexSection[0]]?.questions?.[indexSection[1]] ?? {}),
                                                answers: {
                                                    ...(endpointErrors?.[indexSection[0]]?.questions?.[indexSection[1]]?.answers ?? {}),
                                                    [indexSection[2]]: {
                                                        ...(endpointErrors?.[indexSection[0]]?.questions?.[indexSection[1]]?.answers?.[indexSection[2]] ?? {}),
                                                        [tranform[itemData.field] ?? '']: true,
                                                    },
                                                },
                                            },
                                        },
                                    },
                                }
                            })
                        })

                        firstError?.data?.actionPlan?.forEach((item) => {
                            ; (item.data as OptionDatum[])?.forEach((itemData) => {
                                const indexSection = getIndexs({ value: itemData.path })?.map((itemIndex) => itemIndex.toString())
                                const tranform: Record<string, string> = {
                                    templateName: 'template_name',
                                    question: 'selectAnyThing',
                                }
                                const typeQuestion = res?.questionnaireSections?.[indexSection[0] as any]?.questions?.[indexSection[1] as any]?.typeQuestionId
                                endpointErrors = {
                                    ...endpointErrors,
                                    [indexSection[0]]: {
                                        ...(endpointErrors[indexSection[0]] ?? {}),
                                        questions: {
                                            ...(endpointErrors[indexSection[0]]?.questions ?? {}),
                                            [indexSection[1]]: {
                                                ...(endpointErrors?.[indexSection[0]]?.questions?.[indexSection[1]] ?? {}),
                                                answers: {
                                                    ...(typeQuestion != 2
                                                        ? {
                                                            ...(endpointErrors?.[indexSection[0]]?.questions?.[indexSection[1]]?.answers ?? {}),
                                                            [indexSection[2]]: {
                                                                ...(endpointErrors?.[indexSection[0]]?.questions?.[indexSection[1]]?.answers?.[indexSection[2]] ?? {}),
                                                                actionPlan: {
                                                                    ...(endpointErrors?.[indexSection[0]]?.questions?.[indexSection[1]]?.answers?.[indexSection[2]]?.actionPlan ?? {}),
                                                                    [tranform[itemData.field] ?? '']: true,
                                                                },
                                                            },
                                                        }
                                                        : {
                                                            actionPlan: {
                                                                [tranform[itemData.field] ?? '']: true,
                                                            },
                                                        }),
                                                },
                                            },
                                        },
                                    },
                                }
                            })
                        })
                        if (!!Object.values(endpointErrors)?.length) {
                            title = 'Se encontraron campos sin completar'
                            errorMessage = 'En el cuestionario se marcarán los campos con errores'
                        } else {
                            switch (firstError?.code) {
                                case RequestErrors.ERR_UPDATING:
                                    errorMessage = t(`Questionarie.errors.ERR_UPDATING`)
                                    break
                                default:
                            }
                        }
                        setModal({
                            open: true,
                            title: title || t(`Questionarie.modal.submit.error.title`),
                            description: errorMessage,
                        })
                    }

                } catch (e) {
                    console.error(`create questionnarie error ${e}`)
                    setModal({
                        open: true,
                        title: t(`Questionarie.modal.submit.error.title`),
                        description: t(`Questionarie.modal.submit.error.description`),
                    })
                } finally {
                    setSubmitDisabled(false)
                    setLoadings(prev => ({ ...prev, main: false }))
                    setLoadings(prev => ({ ...prev, next: false }))
                }
            }
        })

    } else {
        const title = 'Se encontraron campos sin completar'
        const errorMessage = 'En el cuestionario se marcarán los campos con errores'

        setModal({
            open: true,
            title: title,
            description: errorMessage,
        })

        setErrors(errors)
    }
}

export { handleSubmit }