import { SxProps } from '@mui/material'
import { Color } from '../../utils/theme/types/color'

interface Styles {
  container: SxProps
  file: SxProps
  simpleModal: SxProps
  uploadFile: SxProps
  wFull: SxProps
  link: SxProps
  infoTitle: SxProps
  infoText: SxProps
  left: any
  errorInfo: SxProps
  green: SxProps
  red: SxProps
  downloadIcon: SxProps
  download: SxProps
}

export const useStyles = (color: Color): Styles => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    padding: '30px 30px 30px 0',

    '& .title': {
      fontSize: '20px',
      fontWeight: '600',
      marginBottom: '20px',
      textAlign: 'center',
    },

    '& .section': {
      '& .subtitle': {
        textAlign: 'left',
        color: '#707070',
        fontSize: '16px',
      },

      '& .info': {
        textAlign: 'left',
        color: '#707070',
        fontSize: '12px',
        fontWeight: '400',
      },
    },
  },
  file: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    '& .MuiIconButton-root': {
      color: color.color4,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 0,
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '5px',
    },
  },
  simpleModal: {
    width: '430px',
    '& .container.container': {
      margin: '0',
      marginTop: '5px',
      width: '100%',
      padding: '0',
    },
    '& .title.title': {
      borderBottom: `1px solid ${color.color13}`,
      width: '100%',
      paddingBottom: '10px',
      color: color.color13,
      textAlign: 'start',
      paddingLeft: '30px',
    },
  },
  uploadFile: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'start',
    padding: '10px 30px 20px 30px',
  },
  wFull: {
    width: '100%',
    marginBottom: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '5px',
  },
  link: {
    textAlign: 'left !important',
    '& span': {
      color: color.color16,
      textDecoration: 'underline',
      '&.circle': {
        textDecoration: 'none',
        color: color.color13,
        fontSize: '6px',
        marginRight: '5px',
      },
    },
    color: color.color13,
    fontWeight: '500',
    fontSize: '12px',
    width: '100%',
  },
  infoTitle: {
    color: color.color6,
    fontWeight: '600',
    fontSize: '12px',
    '&.MuiTypography-root': {
      width: '100%',
    },
  },
  infoText: {
    color: color.color13,
    fontWeight: '500',
    fontSize: '12px',
    width: '100%',
  },
  left: {
    textAlign: 'left !important',
  },
  errorInfo: {
    display: 'flex',
  },
  green: {
    color: color.color8,
    paddingRight: '10px',
    borderRight: '1px solid #00000066',
    '& p': {
      fontWeight: '600',
    },
  },
  red: {
    color: color.color28,
    paddingLeft: '10px',
    fontWeight: '600',
    '& p': {
      fontWeight: '600',
    },
  },
  downloadIcon: {
    color: color.color4,
  },
  download: {
    color: color.color4,
    fontSize: '11px',
    fontWeight: 'bold',
  },
})
