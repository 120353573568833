export const useStyles = () => ({
  buttonsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .back': {
      width: '80px',
      height: '36px',
      textTransform: 'uppercase',
    },
    '& .next': {
      borderRadius: '4px',
      textTransform: 'uppercase',
    },
  },
})
