import { useBody } from "./body"
import { useStyles } from "./styles"
import { Box, IconButton, Typography } from "@mui/material"
import { FiltersDrawer } from "../../../../components/filtersDrawer"
import { icons } from "../../../../utils/icons"
import { CustomTable, SnackSimple } from "../../../../components"
import { DinamicForm } from "../../../../components/dinamicForm"
import QuestionarieModal from "../../components/questionarieModal"
import BaseModal from "../../../../components/modal/baseModal"
import SimpleModal from "../../../../components/modal/simpleModal"
import { useTranslation } from "react-i18next"
import CopyQuest from "../../components/copyQuest"
import { ModelDownloadButtons } from "../../../../components/donwloadFiles/model"

const Questionnaire = () => {
  const { loading, table, data, erros, counter, input, column, newQuestionnaire, inputCreate, openSnackbar, modal, closeModal, closeSnackbar, redirect, closeCreateModal, openCreateModal, cleanFilter, ...rest } = useBody()
  const styles = useStyles()
  const { t } = useTranslation()
  
  return (
    <Box sx={styles.container}>
      <Box sx={{
        marginTop: '20px',
        position: 'relative',
        paddingRight: '45px',
        '& .downloadFiles': {
          marginBottom: '5px',
        },
        '& .crud-filter-container.crud-filter-container': { paddingTop: '15px' },
        '& .filterContainer-main': { position: 'relative', bottom: '-10px', marginBottom: 0 },
      }}>

        <SnackSimple
          close={rest.downloadMain.closeOpenSnakbar}
          open={rest.downloadMain.openSnackbar.open}
          type={rest.downloadMain.openSnackbar.type}
          message={rest.downloadMain.openSnackbar.message}
        />

        <FiltersDrawer cleanFilter={rest.paginationMain.cleanFilter} count={rest.paginationMain.counter} right={0} top={0}>
          <Box sx={styles.section}>
            <DinamicForm errors={rest.paginationMain.errors} input={Object.values(input)} values={rest.paginationMain.filterData} onChange={rest.paginationMain.onChange} filter={true} legacyErrors />
          </Box>
        </FiltersDrawer>

        <Box sx={styles.header}>
          <Typography sx={styles.title}>{t('Questionarie.title')}</Typography>

          <Box sx={styles.flex}>
            <ModelDownloadButtons csv={rest.downloadMain.downloadCSV} pdf={rest.downloadMain.downloadPDF} word={rest.downloadMain.downloadWORD} disableCSV={rest.downloadMain.download?.excel} disablePDF={rest.downloadMain.download?.pdf} disableWORD={rest.downloadMain.download?.word} />

            <IconButton
              sx={(styles.clean, (rest.paginationMain.counter || 0) > 0 ? null : styles.hide)}
              onClick={() => {
                rest.paginationMain.cleanFilter()
              }}
            >
              {icons('clean')}
            </IconButton>

            <IconButton
              sx={styles.clean}
              onClick={() => {
                openCreateModal({ type: 'create' })
              }}
            >
              {icons('add')}
            </IconButton>
          </Box>
        </Box>

        <CustomTable
          dontRefresh
          column={column}
          row={table.row}
          selected={-1}
          onClick={(e) => { redirect('edit', { id: e.id }) }}
          loader={loading.table}
          order={
            rest.paginationMain?.query.sort && {
              count: rest.paginationMain.query?.sort?.count,
              key: rest.paginationMain.query?.sort?.field,
              order: rest.paginationMain.query?.sort?.sort,
            }
          }
          sortable={(field, sort, count) => {
            rest.paginationMain.sortTable(field && sort ? { field: field, sort: sort, count } : undefined)
          }}
          serverSidePagination={{
            page: (rest.paginationMain.paginationModel.page ?? 0) + 1,
            size: rest.paginationMain.paginationModel?.pageSize ?? 20,
            totalPages: rest.total,
          }}
          handleChange={(page: number, pageSize: number) => {
            rest.paginationMain.handlePagination({ page, pageSize })
          }}
        />

        <BaseModal
          sx={{ width: '750px', overflowY: 'auto' }}
          open={!!newQuestionnaire}
        >
          <>
            {
              !!(newQuestionnaire === 'create') && (
                <QuestionarieModal
                  icon={icons('simple-close')}
                  close={closeCreateModal}
                  formAnother={() => {
                    openCreateModal({ type: 'copy' })
                  }}
                  input={inputCreate}
                  getValues={(payload) => {
                    redirect('create', payload)
                  }}
                />
              )
            }

            {
              !!(newQuestionnaire === 'copy') && (
                <CopyQuest
                  t={t}
                  loading={rest.loadingCopy}
                  loadingTable={loading.copy}
                  table={{
                    row: table.rowCopy,
                    column: column?.slice(0, 4),
                  }}
                  onClose={closeCreateModal}
                  filter={{
                    filterTitle: t('Questionarie.modal.table.filterTitle'),
                    label: t('Questionarie.modal.table.search'),
                    name: 'search',
                    title: t('Questionarie.modal.title'),
                  }}
                  next={({ id }) => { redirect('copy', { id }) }}

                  cleanFilter={rest.paginationCopy.cleanFilter}
                  filterData={rest.paginationCopy.filterData}
                  onChange={rest.paginationCopy.onChange}
                  order={
                    rest.paginationCopy?.query.sort && {
                      count: rest.paginationCopy.query?.sort?.count,
                      key: rest.paginationCopy.query?.sort?.field,
                      order: rest.paginationCopy.query?.sort?.sort,
                    }
                  }
                  sortable={(field, sort, count) => {
                    rest.paginationCopy.sortTable(field && sort ? { field: field, sort: sort, count } : undefined)
                  }}
                  serverSidePagination={{
                    page: (rest.paginationCopy.paginationModel.page ?? 0) + 1,
                    size: rest.paginationCopy.paginationModel?.pageSize ?? 20,
                    totalPages: rest.totalCopy,
                  }}
                  handleChange={(page: number, pageSize: number) => {
                    rest.paginationCopy.handlePagination({ page, pageSize })
                  }}
                />
              )
            }
          </>
        </BaseModal>
      </Box>

      <SnackSimple
        close={closeSnackbar}
        open={openSnackbar.open}
        type={openSnackbar.type}
        message={openSnackbar.message}
      />

      <SimpleModal
        open={modal.open}
        close={() => { closeModal() }}
        title={modal.title}
        description={modal.description}
        buttonText={t('general.confirm')}
        cancelText={t('general.cancel')}
        back={modal?.cancel}
        next={() => { modal.confirm() }}
      />
    </Box>
  )
}

export { Questionnaire }
