import { Badge, Box, Drawer, DrawerProps, Grid, IconButton, Typography } from "@mui/material"
import { icons } from "../../../../utils/icons"
import { useTranslation } from "react-i18next"
import { useStyles } from "./styles"
import { DynamicFormRef } from "../../../../components/dynamicFormRef"
import { useBody } from "./body"
import { BaseMenu } from "../../../../components"
import { DrawerEvidences } from "../../../../hooks/evidences/DrawerEvidenceComponent"
import { EvidenceData } from "../../../../hooks/evidences/typyng"
import { useEffect } from "react"
import SimpleModal from "../../../../components/modal/simpleModal"
import { ReportFolio } from "../repotFolios"

interface ActionPlanDrawerProps {
  DrawerProps: DrawerProps
  arrowBack: (payload:
    {
      evidence: EvidenceData;
      data: Record<string, string | number | boolean>
      evidenceDataFolios?: EvidenceData;
      dataFolios?: Record<string, boolean | number | string>
    }) => void
  shrink: () => void
  validate?: boolean
  defaultValues?: {
    EvidenceData: EvidenceData
    data: Record<string, string | number | boolean>
    description: Record<string, string | number | boolean>
    evidenceFolio?: EvidenceData;
    limitFolio?: Record<string, string | number | boolean>;
    rejectsFolio?: Record<string, string | number | boolean>;
  }
  activeFolios?: boolean
  reportFolioOpen: boolean
  questName: string
  areThereSomeEvidence: boolean
}

const ActionPlanDrawer = (props: ActionPlanDrawerProps) => {
  const { t } = useTranslation()
  const {
    description,
    generalQuestions,
    evidencesOptions,
    areThereSomeCheck,
    refsByKey,
    setRef,
    handleActivedChecks,
    ...rest
  } = useBody({ defaultValues: props.defaultValues?.EvidenceData, data: props.defaultValues?.data, defaultValuesFolios: props.defaultValues?.evidenceFolio })
  const styles = useStyles()

  useEffect(() => {
    const body = Object.entries(props.defaultValues?.data ?? {})
    handleActivedChecks(!!body?.length && !!body?.some(([key, value]) => key.includes('check') && !!value))
  }, [props.defaultValues?.data])

  useEffect(() => {
    if (!!props.activeFolios) {
      rest.activeFoliosSection()
    } else {
      rest.cleanFolios()
    }

    rest.changeFolio({ type: 'limit', name: 'maxFolios', value: props.defaultValues?.limitFolio?.maxFolios ?? null })
    rest.changeFolio({ type: 'limit', name: 'maxFoliosAmount', value: props.defaultValues?.limitFolio?.maxFoliosAmount ?? null })
  }, [props.activeFolios])

  return (
    <Drawer {...props.DrawerProps} anchor="right">
      <Grid container sx={styles.userDetail({ open: rest.folioData.isOpen })}>
        <Grid sx={styles.borderBottom} item xs={12}>
          <Box sx={styles.arrowHeaderDrawerContainer}>
            <IconButton
              sx={styles.closeSettings}
              onClick={() => {
                const body = { ...refsByKey['generalQuestions']?.getValues(), ...refsByKey['description']?.getValues() } as Record<string, string | number | boolean>
                const body2 = { ...refsByKey['folioLimit']?.getValues(), ...refsByKey['folioReject']?.getValues() } as Record<string, string | number | boolean>
                const active = !!rest.folioData.active

                props.arrowBack({
                  evidence: evidencesOptions.evidencesData,
                  data: body,
                  evidenceDataFolios: active ? rest.evidencesFolios.evidencesData : undefined,
                  dataFolios: active ? body2 : undefined
                })
                rest.cleanActionPlanDrawer()
              }}
            >
              {icons('arrow-left-fill')}
            </IconButton>

            <Typography className="quest-name-header">{t('general.tags.quest_name')}</Typography>

            {!!rest.folioData.isOpen && (
              <IconButton
                sx={styles.shrink}
                onClick={() => {
                  rest.closeFolio()
                }}
              >
                {icons('shrink')}
              </IconButton>
            )}
            <Box sx={styles.arrowTitleDrawerContainer}>
              <Typography className="quest_name">{props.questName ?? ''}</Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={rest.folioData.isOpen ? 6 : 12} sx={styles.actionPlanDrawer({ open: rest.folioData.isOpen })}>
          <Typography className="quest_name_title padding_title">{t('Questionarie.settingActionPlan')}</Typography>

          {(!areThereSomeCheck && !evidencesOptions.evidencesCount) && (
            <Typography sx={styles.warning} className="padding_title">
              <span>*</span>
              {t('Questionarie.order.addOnceEvidence')}
            </Typography>
          )}

          <Box className="containerUserDetail">
            <DynamicFormRef
              ref={element => {
                setRef(element, 'description')
                return refsByKey['description']
              }}
              removeForm
              validateField={props.validate}
              defaultValues={props.defaultValues?.description}
              parentWrapper={({ children }) => <Box sx={{ display: 'flex', flexWrap: "wrap", gap: '16px' }} children={children} />}
              model={description}
            />

            <Typography sx={styles.fieldRequired} className="quest_name_title">
              {t('general.tags.fields')}
            </Typography>

            <Box sx={styles.alignGrid}>
              <DynamicFormRef
                ref={element => {
                  setRef(element, 'generalQuestions')
                  return refsByKey['generalQuestions']
                }}
                removeForm
                validateField={props.validate}
                onChange={() => {
                  const body = Object.entries(refsByKey['generalQuestions']?.getValues() ?? {})
                  handleActivedChecks(!!body?.length && !!body?.some(([key, value]) => key.includes('check') && !!value))
                }}
                model={generalQuestions}
                defaultValues={props.defaultValues?.data}
                parentWrapper={({ children }) => <Grid container alignItems="center" children={children} />}
              />
            </Box>

            <Box sx={[styles.containerBug, styles.evidencesType]}>
              <Badge badgeContent={evidencesOptions.evidencesCount} sx={styles.badge()}>
                <IconButton
                  onClick={(e) => {
                    evidencesOptions.openMenu({
                      anchor: e,
                      defaultValues: evidencesOptions?.evidencesData
                    },)
                  }}
                  className="p3"
                  sx={[styles.bug, styles.activeClip]}
                >
                  {icons('clip')}
                </IconButton>
              </Badge>

              <Typography className="quest_name_title evidences-type-clip">{t('Questionarie.sections.evidenceTypes3')}</Typography>
            </Box>

            <Box sx={[styles.containerBug, styles.evidencesType]}>
              <Badge max={999} badgeContent={rest.handleBadge()} sx={styles.badge(!rest.folioData?.data?.limit?.maxFolios)}>
                <IconButton
                  onClick={() => {
                    if (!rest.folioData.active) {
                      rest.openModal()
                    } else {
                      rest.openFolio()
                    }
                  }}
                  sx={[styles.bug, styles.activeBug({ open: rest.folioData.active })]}
                  className="p2"
                >
                  {icons('bug')}
                </IconButton>
              </Badge>

              <Box>
                <Typography className="quest_name_title evidences-type-clip">{t('Questionarie.reportFolio')}</Typography>
                {!!rest.folioData.errors && <Typography sx={styles.rejectError}>{t('Questionarie.configNotFound')}</Typography>}
              </Box>
            </Box>
          </Box>
        </Grid>

        {
          !!rest.folioData.isOpen && (
            <Grid item xs={6}>
              <ReportFolio
                evidencesCount={rest.evidencesFolios.evidencesCount}
                limitFoliosProps={{
                  ref: (element) => {
                    setRef(element, 'folioLimit')
                    return refsByKey['folioLimit']
                  },
                  removeForm: true,
                  styleSx: { width: '100%' },
                  defaultValues: props.defaultValues?.limitFolio,
                  parentWrapper: ({ children }) => <Grid spacing={2} alignItems='center' container children={children} />,
                  model: rest.folioModelReport,
                  onChange: (payload) => {
                    rest.handleErrors()
                    const name = payload?.current as string
                    rest.changeFolio({ type: 'limit', name: name, value: payload?.[name] ?? null })
                  }
                }}
                rejectsFolioProps={{
                  ref: element => {
                    setRef(element, 'folioReject')
                    return refsByKey['folioReject']
                  },
                  removeForm: true,
                  defaultValues: props.defaultValues?.rejectsFolio,
                  parentWrapper: ({ children }) => <Grid spacing={2} alignItems='center' container children={children} />,
                  model: rest.folioModel,
                  onChange: (payload) => {
                    rest.handleErrors()
                    const name = payload?.current as string
                    rest.changeFolio({ type: 'rejects', name: name, value: payload?.[name] ?? null })
                  }
                }}
                openMenu={rest.evidencesFolios.openMenu as any}
                removeFolios={rest.removeFolios}
                evidenceFolio={props.defaultValues?.evidenceFolio}
                baseMenuProps={{
                  anchorEl: rest.evidencesFolios.anchorEl,
                  setAnchorEl: () => { rest.evidencesFolios.closeMenu() },
                  menuItems: rest.evidencesFolios.menu,
                  click: (item) => { rest.evidencesFolios.clickOption({ item }) },
                }}
                drawerEvidencesProps={{
                  refsByKey: rest.evidencesFolios.refsByKey,
                  defaultValues: rest.evidencesFolios.evidencesData,
                  open: rest.evidencesFolios.openEvidenceDrawer,
                  setRef: rest.evidencesFolios.setRef,
                  save: rest.evidencesFolios.saveEvidence,
                  cancel: rest.evidencesFolios.cancelEvidence,
                }}
              />
            </Grid>
          )
        }
      </Grid>

      <BaseMenu
        sx={styles.border}
        anchorEl={evidencesOptions.anchorEl}
        setAnchorEl={() => { evidencesOptions.closeMenu() }}
        menuItems={evidencesOptions.menu}
        click={(item) => { evidencesOptions.clickOption({ item }) }}
      />

      <DrawerEvidences
        refsByKey={evidencesOptions.refsByKey}
        defaultValues={evidencesOptions.evidencesData}
        open={evidencesOptions.openEvidenceDrawer}
        setRef={evidencesOptions.setRef}
        save={evidencesOptions.saveEvidence}
        cancel={evidencesOptions.cancelEvidence}
      />

      <SimpleModal
        open={!!rest.modal.open}
        close={() => { rest.closeModal() }}
        title={rest.modal.title}
        description={rest.modal.description}
        cancelText={t('general.cancel')}
        buttonText={t('general.confirm')}
        back={() => { if (rest.modal.cancel) rest.modal.cancel() }}
        next={() => { if (rest.modal.confirm) rest.modal.confirm() }}
      />
    </Drawer>
  )
}

export { ActionPlanDrawer }
