import { Box, Icon, Typography } from '@mui/material'
import { MapMarkerPropsI } from '../typing'
import { Images } from '../../../../../components'
import { useStyles } from './styles'
import { icons } from '../../../../../utils/icons'
import { copyText } from '../../../../../utils/methods/copyText'
import { images } from '../../../../../utils/images'
import { useState } from 'react'

const MapMarker = (props: MapMarkerPropsI) => {
  const { img, fullName, email, phone, role, userType, handleCopy } = props
  const styles = useStyles()

  const copy = (data: { value: string; caption: string }) => {
    copyText(data.value ?? '')
    handleCopy?.(data)
  }

  const handleShow = () => !!img || !!fullName || !!email

  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <Box onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)}>
      {showTooltip && (
        <Box sx={styles.container}>
          <Images sx={styles.img} src={img ?? images.profile} />

          <Box sx={[styles.padding]}>
            {!!fullName && (
              <Typography
                onClick={() => {
                  copy({ value: fullName, caption: 'fullName' })
                }}
                sx={styles.fullName}
              >
                {fullName}
              </Typography>
            )}

            {!!email && (
              <Typography
                onClick={() => {
                  copy({ value: email, caption: 'email' })
                }}
                sx={styles.detail}
              >
                <Icon sx={styles.icon}>{icons('email')}</Icon>
                {email}
              </Typography>
            )}

            {!!phone && (
              <Typography
                onClick={() => {
                  copy({ value: phone, caption: 'phone' })
                }}
                sx={styles.detail}
              >
                <Icon sx={styles.icon}>{icons('phone')}</Icon>
                {phone}
              </Typography>
            )}

            {(!!role || !!userType) && (
              <Typography
                onClick={() => {
                  copy({ value: `${role}${!!(!!role && !!userType) && ' - '}${userType}`, caption: 'role-userType' })
                }}
                sx={styles.detail}
              >
                <Icon sx={[styles.icon, styles.fontAwesome]}>{icons('accountTie')}</Icon>
                {role} {!!(!!role && !!userType) && ' - '} {userType}
              </Typography>
            )}
          </Box>
        </Box>
      )}
      <Box sx={styles.userPoint}>{!!handleShow() && <Images sx={styles.floatImg} src={img ?? images.profile} />}</Box>
    </Box>
  )
}

export { MapMarker }
