import { Status } from '../../../../utils/enums'
import { Color } from '../../../../utils/theme/types/color'

export const useStyles = ({
  colors,
  status,
}: {
  colors: Color
  status?: {
    value: Status
    color: string
    text: string
  }
}) => {
  return {
    container: {
      width: '100%',
      height: '100%',
      padding: '50px 40px 15px 10px',
      overflowY: 'auto',
    },
    documentsContainer: {
      width: '100%',
      maxHeight: '200px',
      overflowY: 'auto',
      padding: '10px 20px',
      display: 'flex',
      flexWrap: 'wrap',
      rowGap: '10px',
    },
    documentItems: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      padding: '10px 10px 0px',
      boxShadow: `3px 3px ${colors.color13}30`,
      borderRadius: '5px',
      '&:hover': {
        backgroundColor: `${colors.color22}05`,
      },
      '& .MuiIcon-root': {
        color: colors.color1,
        height: '35px',
        width: '35px',
      },
    },
    documentName: {
      width: 'calc(100% - 50px)',
      wordBreak: 'break-word',
    },
    padding: {
      padding: '20px',
    },
    paddingComments: {
      padding: '20px 20px 20px 25px',
    },
    comments: {
      width: '100%',
      maxHeight: '300px',
      overflowY: 'auto',
    },
    noData: {
      width: '100%',
      textAlign: 'center',
      fontSize: '18px',
      color: `${colors.color13}`,
    },
    containerHeader: {},
    title: {
      color: colors.color9,
      fontWeight: '600',
      fontSize: '18px',
    },
    titleEvidences: {
      padding: '20px 20px 0px 25px',
      color: colors.color9,
      fontWeight: '600',
      fontSize: '18px',
      width: '100%',
    },
    date: {
      color: colors.color9,
      fontSize: '14px',
    },
    goBack: {
      color: colors.color16,
      fontSize: '1.5rem',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    goBackText: {
      fontSize: '16px',
      fontWeight: '500',
      marginLeft: '5px',
      color: colors.color16,
    },
    flex: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      alignItems: 'center',
    },
    buttonsContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'end',
      columnGap: '10px',
      '& .MuiButton-root': {
        color: colors.color5,
        fontSize: '12px',
        padding: '5px 20px',
      },
      '& .accept': {
        background: colors.primary,
        '&:hover': {
          background: colors.primary,
        },
      },
      '& .reject': {
        background: colors.color19,
        '&:hover': {
          background: colors.color19,
        },
      },
      position: 'absolute',
      bottom: '20px',
      right: '20px',
    },
    borders: {
      boxShadow: `0 0 4px ${colors.color13}`,
      borderRadius: '10px',
      width: '100%',
      height: '100%',
      padding: '20px 40px',
      position: 'relative',
    },
    paddingBottom: {
      paddingBottom: '80px',
    },
    rejectTitle: {
      color: colors.color13,
      fontWeight: '500',
      fontSize: '18px',
    },
    reject: {
      color: colors.color19,
    },
    containerRejection: {
      background: `${colors.color19}33`,
      padding: '8px',
      paddingBottom: '10px',
      borderRadius: '5px',
      width: '100%',
      marginTop: '5px',
      marginBottom: '5px',
      overflowWrap: 'break-word',
      whiteSpace: 'normal',
    },
    containerComment:{
      paddingLeft:'16px',
      width:'100%',
      overflowWrap: 'break-word',
      whiteSpace: 'normal',
      paddingTop: '2px',
    },
    auxBox: {
      width: '37px',
      height: '5px',
    },
    stepper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      position: 'relative',
      right: '-20px',
      '& .MuiIconButton-root': {
        padding: '0',
        fontSize: '1.2rem',
        '& .MuiSvgIcon-root': {
          fontSize: '1em',
        },
      },
    },
    containerModalButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      borderTop: `1px solid ${colors.color13}77`,
      padding: '0 20px',
      '& .cancel': {
        borderRight: `1px solid ${colors.color13}77`,
        color: colors.color19,
        width: '50%',
        padding: '10px 10px',
        paddingLeft: '20px',
        borderRadius: '0',
        '&:hover': {
          color: colors.color19,
        },
      },
      '& .confirm': {
        width: '50%',
        paddingRight: '20px',
        color: colors.color4,
        '&:hover': {
          color: colors.color4,
        },
      },
    },
    containerStatus: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      columnGap: '10px',
    },
    cancel: {
      fontSize: '12px',
      color: colors.color4,
      width: 'fit-content',
      cursor: 'pointer',
      textAlign: 'center',
    },
    response: {
      fontSize: '14px',
      color: colors.color16,
      width: 'fit-content',
      fontWeight: '600',
    },
    subTitle: {
      fontSize: '16px',
      color: colors.color13,
      fontWeight: '400',
    },
    standarTitle: {
      fontSize: '14px',
      color: colors.color13,
      fontWeight: '600',
      wordBreak: 'break-all',
    },
    notFound: {
      color: `${colors.color13}99`,
      fontSize: '16px',
      fontWeight: '500',
    },
    standarTitle2: {
      fontSize: '14px',
      color: colors.color13,
      fontWeight: '400',
      wordBreak: 'break-all',
    },
    status: {
      fontSize: '12px',
      background: status?.value ? status?.color : colors.color29,
      color: colors.color5,
      padding: '15px',
      textAlign: 'center',
      marginRight: '-40px',
      width: 'fit-content',
      borderTopLeftRadius: '40px',
      borderBottomLeftRadius: '40px',
    },
    noResponse: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      flexDirection: 'column',
      padding: '20px 50px',
      '& .MuiTypography-root': {
        width: '100%',
        textAlign: 'center',
      },
      '& .MuiIcon-root': {
        fontSize: '10rem',
        height: '1.2em',
        color: colors.color27,
        '& .MuiSvgIcon-root': {
          fontSize: '1em',
        },
      },
    },
    containerStep: {
      background: colors.color9,
      borderRadius: '50%',
      width: '30px',
      height: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    detailContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      columnGap: '20px',
    },
    question: {
      fontSize: '14px',
      color: colors.color13,
      fontWeight: '400',
    },
    borderTop: {
      marginTop: '20px',
      borderTop: `1px solid ${colors.color13}33`,
    },
    answer: {
      fontSize: '14px',
      color: colors.color13,
      fontWeight: '600',
    },
    videos: {
      width: '100%',
      height: '200px',
      '& video': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    images: {
      width: '100%',
      height: '200px',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    stepsIndicator: {
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      background: colors.color5,
      border: `1px solid ${colors.color13}`,
    },
    active: {
      background: colors.color22,
    },
    stepsIndicatorContainer: {
      marginTop: '10px',
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      columnGap: '5px',
    },
    step: {
      fontSize: '14px',
      color: colors.color5,
    },
    titleQuestion: {
      color: colors.color9,
      fontSize: '16px',
      width: '100%',
    },
    optionsContainer: {
      '& .MuiTypography-root': {
        fontSize: '16px',
        color: colors.color13,
      },
      '& .MuiCheckbox-root': {
        padding: '2px',
        '& .MuiBox-root': {
          width: '17px',
          height: '17px',
          marginRight: '10px',
        },
      },
    },
  }
}
