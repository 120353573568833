import { Box, Grid, Typography, IconButton } from '@mui/material'
import Loader from '../../../../../../components/loader'
import { FiltersDrawer } from '../../../../../../components/filtersDrawer'
import { DinamicForm } from '../../../../../../components/dinamicForm'
import { icons } from '../../../../../../utils/icons'
import { CustomTable, SnackSimple } from '../../../../../../components'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { Props } from './interface'
import { useBody } from './body'
import { useContextState } from '../../../../../../hooks/theme'
import { useEffect } from 'react'
import { LoadingButton } from '@mui/lab'
import SimpleModal from '../../../../../../components/modal/simpleModal'
import { AddUsersModal } from './components/modal'

const LinkingUsersToQuestionnaires = (props: Props) => {
  const body = useBody({ id: props.id?.toString(), itemsManage: props.itemsManage })
  const { t } = useTranslation()
  const { colors } = useContextState()
  const styles = useStyles()
  useEffect(() => {
    body.resetValues(props.data)
  }, [props.data])
  
  if (props?.loading) {
    return (
      <Box sx={styles.containerLoader}>
        <Loader />
      </Box>
    )
  }
  return (
    <Box sx={styles.container}>
      <SnackSimple close={body.closeSnackbar} open={body.snackbar.open} type={body.snackbar.type} message={body.snackbar.message} />

      <SimpleModal
        open={!!body.openModal?.open}
        close={() => { if (body.openModal?.cancel) { body.openModal?.cancel() } }}
        title={body.openModal?.title ?? ""}
        description={body.openModal?.description ?? ""}
        buttonText={t('general.confirm')}
        cancelText={t('general.cancel')}
        color={colors}
        back={() => { if (body.openModal?.cancel) { body.openModal?.cancel() } }}
        loadingButton
        next={() => { if (body.openModal?.confirm) { body.openModal?.confirm() } }}
      />

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container columnSpacing={1} alignItems="center">
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ position: 'relative' }}>
            <FiltersDrawer m={{ mt: 2 }} cleanFilter={body.filter.cleanFilter} count={body.filter.counter} right={-100} top={-15}>
              <Box>
                <DinamicForm input={Object.values(props.input ?? {})} values={body.filter.filterData ?? {}} onChange={body.filter.onChange} filter={true} legacyErrors />
              </Box>
            </FiltersDrawer>
          </Grid>
        </Grid>

        <Grid container columnSpacing={1} alignItems="end">
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box sx={styles.titleContainer}>
              <Box sx={styles.arrow}>
                <IconButton
                  sx={styles.goBack}
                  onClick={() => {
                    const changed = body.validateChange()
                    props.back({ checksUser: body.checksUser, ...body.usersToEndpoint, table: body.table.row, thereAreChanged: changed })
                  }}
                >
                  {icons('arrow-left-fill')}
                </IconButton>
                <Box>
                  <Typography sx={styles.title}>{t('Questionarie.manageUser.title')}</Typography>

                  {!!body.usersToEndpoint.usersWithoutPersmissions?.length && (<Typography sx={styles.subtitle}>{t("general.messages.errors.usersNeedAtLeastOnePermissopn")}</Typography>)}
                </Box>
              </Box>
              <Box>
                <LoadingButton
                  loading={!!props.loading || !!body.loadings?.table}
                  disabled={!!body.usersToEndpoint.usersWithoutPersmissions?.length || (!body.table.row?.length && !Object.values(body.usersToEndpoint?.usersOld)?.length)}
                  variant="contained"
                  onClick={() => {
                    const changed = body.validateChange()
                    props.submit.submit({ checksUser: body.checksUser, ...body.usersToEndpoint, table: body.table.row, thereAreChanged: changed })
                  }}
                >
                  {props.submit?.title}
                </LoadingButton>

                <IconButton onClick={() => { body.openModalAddUser() }} sx={styles.add}>{icons("add")}</IconButton>

                {(body.filter.counter ?? 0) > 0 && <IconButton onClick={body.filter.cleanFilter}>{icons('clean')}</IconButton>}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <AddUsersModal
          userList={body.modalLoad?.tmpUsuers as Record<string, string>[]}
          open={body.modalCreate.open}
          close={body.cancelSearchUsers}
          rigthItem={<DinamicForm input={Object.values(body.inputCreate ?? {})} onChange={body.createFilter.onChange} errors={body.createFilter.errors} values={body.createFilter.filterData} />}
          userAmount={body.modalLoad?.tmpUsuers?.length ?? 0}
          searchButton={{
            disabled: !body.createFilter.query?.filter,
            loading: !!body.loadings.search,
            onClick: body.searchUsers
          }}
          loadData={{
            loading: !!body.loadings.load,
            onClick: body.loadUsers
          }}
        />

        <CustomTable
          ref={body.refTable}
          name="Informe General"
          dontRefresh
          customeColumn={body.columns.customeColumn}
          column={body.columns.columnMain}
          row={body.rowContent({ list: body.table.row })}
          color={colors}
          selected={-1}
        />
      </Grid>
    </Box>
  )
}

export default LinkingUsersToQuestionnaires
