import { Box, Typography } from '@mui/material'
import { useStyles } from './styles'

const StatusComponent = ({ color, msg, size }: { color: string; msg: string; size?: 'small' | 'medium' | 'big' }) => {
  const styles = useStyles(color, size)

  return (
    <Box sx={styles.containerStatus} className="custom-status">
      <Box sx={styles.status} />
      <Typography sx={styles.statusText}>{msg}</Typography>
    </Box>
  )
}

export default StatusComponent
