import { Box, Grid, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { Color } from '../../utils/theme/types/color'
import { useStyles } from './styles'
import { Size } from '../../utils/types/generals'

interface Props {
  title?: string
  header?: JSX.Element
  headerContent?: (payload: {id:string}) => JSX.Element
  footerContent?: (payload: {id:string}) => JSX.Element
  size?: Size
  maxHeight?: number
  notFoundMessage:string
  list: {
    [x: string]:
    | string
    | {
      element: JSX.Element
      size?: Size
    }
  }[]
  colors: Color
  notCount?: boolean
}
const ListDetail = (props: Props) => {
  const styles = useStyles(props.colors)

  return (
    <Box>
      {!!props.title && (
        <Typography sx={styles.sectionTitle}>
          {props.title} {!props.notCount ? `(${props.list.length})` : ''}
        </Typography>
      )}

      {!!props.header && (props.header)}

      <Box sx={styles.containerDetail({ maxHeight: props.maxHeight })}>

        {
          !!props.list ? (
            props.list.map((itemList, indexList) => {
              const { id, ...rest } = itemList
              return (
                <Fragment key={`container-list-${indexList}`}>
                  {!!props.headerContent && (props.headerContent({id: id as string}))}
    
                  <Grid container sx={styles.detailSelected} rowSpacing={1} columnSpacing={2} mb={1}>
                    {Object.values(rest).map((itemDetail, indexDetail) => {
                      const element = itemDetail as {
                        element: JSX.Element
                        size?: Size
                      }
    
                      return (
                        <Grid
                          item
                          xl={element?.size?.xl ?? props?.size?.xl ?? 4}
                          lg={element?.size?.lg ?? props?.size?.lg ?? 4}
                          {...(element?.size ?? props?.size)}
                          key={`conten-list-${indexDetail}`}
                        >
                          {typeof itemDetail === 'string' ? (
                            <Typography
                              sx={[
                                styles.text,
                                indexDetail === 0 && styles.detailTitle,
                              ]}
                            >
                              {itemDetail}
                            </Typography>
                          ) : (
                            itemDetail.element
                          )}
                        </Grid>
                      )
                    })}
                  </Grid>
    
                  {!!props.footerContent && (props.footerContent({id: id as string}))}
                </Fragment>
              )
            })
          ): <Typography sx={[styles.text]}>{props.notFoundMessage ?? ""}</Typography>
        }

      </Box>
    </Box>
  )
}

export default ListDetail
