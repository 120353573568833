import { CustomTable } from "../../../../../../../../components"
import { useTranslation } from "react-i18next"
import { Ref, forwardRef, useImperativeHandle, useState } from "react"
import { StepManagementChildren } from "../../../../../../../../components/stepManagement/types"
import { Box } from "@mui/material"

type Step1Props = {
  row: Record<string, string>[]
  handleDisabled: StepManagementChildren['handleDisabledStep']
  selects: (number | string)[]
}

type Step1Ref = {
  getSelects: () => (number | string)[]
  restart: () => void
}

const Step1 = forwardRef((props: Step1Props, ref: Ref<Step1Ref>) => {
  const { t } = useTranslation()
  const [selects, setSelects] = useState<(number | string)[]>(props.selects)
  const columnsPermissions = [
    {
      id: 'users',
      label: t('general.tags.name'),
    },
    {
      id: 'curp',
      label: t('general.tags.curp'),
    },
    {
      id: 'role',
      label: t('general.tags.role'),
    },
    {
      id: 'userType',
      label: t('general.tags.userType'),
    },
    {
      id: 'division',
      label: t('general.tags.division'),
    },
  ]

  useImperativeHandle(ref, () => ({
    getSelects: () => selects,
    restart: () => { setSelects([]) }
  }))

  const handleSelects = (value: number[]) => {
    props.handleDisabled(!value?.length)
    setSelects(value)
  }

  return (
    <Box sx={{
      '& .table-container.table-container': {
        maxHeight: 'calc(90vh - 280px)'
      }
    }}>
      <CustomTable
        tableChecks
        defaulPagination={20}
        defaultChecks={selects}
        onClick={(e) => { handleSelects(e.checks ?? []) }}
        name="Informe General"
        dontRefresh
        column={columnsPermissions}
        row={props.row ?? []}
        selected={-1}
      />
    </Box>
  )
}
)

export type { Step1Ref }
export { Step1 }