import { useContextState } from "../../hooks/theme"

const useDateWithActionsStyles = () => {
    const { colors } = useContextState()
    return {
        text2: {
            fontSize: '.8rem',
            color: colors.color13,
        },
        containerActions: {
            height: '16px'
        },
        icons: {
            '& .MuiIconButton-root': {
                padding: '0',
                borderRadius: '10px',
                color: colors.color4,
                verticalAlign: 'initial'
            },
            '& .MuiSvgIcon-root': {
                marginRight: '5px',
            },
            '& .MuiTypography-root': {
                fontSize: '.8rem',
            },
        },
        containerIcons: {
            display: "flex",
            alignItems: "start",
            gap: '10px',
            justifyContent: 'space-between',
            '& .MuiIconButton-root': {
                padding: '0px',
                fontSize: '16px',
                '& .MuiSvgIcon-root': {
                    fontSize: 'inherit'
                }
            },
        },
    }
}

export { useDateWithActionsStyles }
