import { getWeek, parse, startOfYear } from "date-fns"
import { Constants } from "../constants"

export const ToDate = (date: Date | string): Date => {
  const newDate = new Date(date)
  newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset())
  return newDate
}

const getNumberWeek = (value: string | Date) => {
  const dayIsMonday = startOfYear(new Date()).getDay() == 1
  let week  = 0
  if (typeof value == 'string') {
    week = getWeek(new Date(parse(value, Constants.dateFormat, new Date())), { weekStartsOn: 1 })
  } else {
    week = getWeek(new Date(value), { weekStartsOn: 1 })
  }
  if (!dayIsMonday) { week = week - 1 }

  return week
}

const getActivedWeek = (value: number | Date | string) => getWeek(new Date(value), { weekStartsOn: 1 })

export { getNumberWeek, getActivedWeek }