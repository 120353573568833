import { Box, Button, Grid, IconButton, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React from 'react'
import { Props } from './props'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { DynamicFormRef } from '../../../../components/dynamicFormRef'
import { DynamicFormRefProps, DynamicRefRef } from '../../../../components/dynamicFormRef/types'
import { useRefs } from '../../../../hooks/useReactHookModified'

const QuestionarieModal = ({ icon, input, close, getValues, formAnother }: Props) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const { refsByKey, setRef } = useRefs<DynamicRefRef>()
  const [alignment, setAlignment] = React.useState('0')
  const handleChange = (_: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment)
    }
  }
  return (
    <Box sx={styles.container}>
      <IconButton sx={styles.close} onClick={() => {
        close()
      }}>
        {icon}
      </IconButton>
      <Box sx={styles.header}>
        <Typography className="title">{t('Questionarie.modal.title')}</Typography>
        <Typography className="another" onClick={() => formAnother()}>
          {t('Questionarie.modal.anotherQuestion')}
        </Typography>
      </Box>
      <Box sx={styles.body}>
        <DynamicFormRef
          ref={element => {
            setRef(element, '1')
            return refsByKey['1']
          }}
          model={input}
          parentWrapper={(parent) => <Grid container spacing={2} children={parent.children} />}
        />
      </Box>
      <Box sx={styles.footer}>
        <Box sx={styles.totalPoints}>
          <DynamicFormRef
            ref={element => {
              setRef(element, '2')
              return refsByKey['2']
            }}
            model={{
              totalPoints: {
                id: 'totalPoints',
                label: 'Puntos Totales',
                name: 'totalPoints',
                type: 'text',
                validations: {
                  min: {value: 1},
                  max: {value: 100},
                  required: { value: true },
                  decimal: { value: true },
                },
              }
            }}
          />
        </Box>

        <Box sx={styles.containerBtns}>
          <Typography className="title">{t('Questionarie.modal.footer.title')}</Typography>
          <ToggleButtonGroup sx={styles.toggleButton} color="primary" value={alignment} exclusive onChange={handleChange} aria-label="Platform">
            {[
              {
                label: 'byItem',
                value: '0',
              },
              {
                label: 'byQuest',
                value: '1',
              },
            ].map((item: { label: string; value: string }, index: number) => (
              <ToggleButton key={index} value={item.value}>
                {t(`general.toggle.${item.label}`)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
      </Box>

      <Button onClick={() => {
        const body: DynamicFormRefProps['defaultValues'] = {
          ...refsByKey?.['1']?.getValues?.(),
          ...refsByKey?.['2']?.getValues?.(),
          option: alignment
        }
        const first = !!refsByKey?.['1']?.validate()
        const second = !!refsByKey?.['2']?.validate()
        
        const existSomeError = first || second
        if(!existSomeError) {
          getValues(body)
        }
      }} color="primary" variant="contained" sx={styles.button}>
        {t('general.next')}
      </Button>
    </Box>
  )
}

export default QuestionarieModal
