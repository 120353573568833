import { useState } from 'react'
import { Box } from '@mui/material'
import { useContextState } from '../../../hooks/theme'
import AuthCard from '../components/authCard'
import img from '../../../assets/images/img1.png'
import AuthForm from '../components/authForm'
import { Images } from '../../../components/image'
import { Value } from '../types'
import { useNavigate } from 'react-router-dom'
import { onChangeEvent } from '../../../utils/types/generals'
import { useStyles } from '../utils/styles'
import { validate } from '../utils/validate'
import { Auth } from '../../../service/auth'
import { useInitialState } from './initialStates'
import { images } from '../../../utils/images'
import { httpFetch } from '../../../utils/crud'
export const ConfirmEmail = () => {
  const navigate = useNavigate()
  const { colors } = useContextState()
  const styles = useStyles(colors)
  const { value, form, t } = useInitialState()
  const [login, setLogin] = useState<Value>(value)
  const [errors, setErrors] = useState({})
  const isValid = () => Object.values(errors).length > 0 || !login?.email?.value
  async function sign() {
    try {
      const user = await Auth(httpFetch).requestCode({ body: { username: login.email.value } })
      if (user?.message) {
        navigate('/auth/reset-password', {
          state: {
            name: login.email.value,
          },
        })
      } else setErrors({ email: t('general.messages.errors.consultCode') })
    } catch (error) {
      console.error('error signing up:', error)
      setErrors({ email: t('general.messages.errors.consultCode') })
    }
  }
  const handleChange = ({ target }: onChangeEvent) => {
    const { name, value } = target

    const body = {
      ...login,
      [name]: {
        ...login[name],
        value,
      },
    }

    setErrors(validate(body, t))
    setLogin(body)
  }

  return (
    <Box sx={styles.container}>
      <AuthCard
        children={<Images src={img} />}
        secondChildren={
          <AuthForm
            errors={errors}
            logo={images.interception}
            title={form.title}
            description={form.description}
            button={form.button}
            value={login}
            onChange={handleChange}
            submit={sign}
            disabled={isValid()}
          />
        }
        footer={{
          label: form.footer,
          navigate: () => navigate('/auth/'),
        }}
      />
    </Box>
  )
}
