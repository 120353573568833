import { EvidenceTypeBody } from "../../../../../../service/questionarie/bodys"
import { EvidencesTypeEnum } from "../../../../../../utils/enums/evidences"
import { transformEvidences } from "../../../../../../utils/methods/evidences"
import { removeAllReference } from "../../../../../../utils/methods/removedReference"
import { Item } from "../../../../../../utils/types/generals"
import { fields } from "../../../../constants"
import { GetActionPlanPayload, GetActionPlanReturn, GetEvidencesPayload } from "./type"

const getEvidenceData = ({ evidenceType, }: GetEvidencesPayload): EvidenceTypeBody[] => {
    const evidences = Object.entries({ ...evidenceType ?? {} })

    const filterData: EvidenceTypeBody[] = evidences
      ?.filter(([_, item]) => !!item?.active)
      ?.map(([key, item]) => {
        const sizes = (item?.maxSize as Item)?.value ?? item?.maxCharacters
        const amounts = item?.maxAmount
        const required = item?.required
        const copyItem = removeAllReference(item)

        delete copyItem?.maxSize
        delete copyItem?.maxAmount
        delete copyItem?.active
        delete copyItem?.required
        delete copyItem?.maxCharacters
        const arrayTypes = Object.entries(copyItem ?? {})?.filter(([_, item]) => item)
        return {
          ...!!sizes && { sizes: sizes },
          ...!!arrayTypes?.length && { types: arrayTypes.map(([key]) => key) },
          amounts: (amounts ?? 1) as number,
          description: '',
          required: !!required,
          evidenceTypeId: transformEvidences[key as EvidencesTypeEnum],
        }
      }) ?? []

    return filterData
  }

const getActionPlans = ({ data, t }: GetActionPlanPayload):GetActionPlanReturn => {
    const title = data?.description?.title?.toString()
    const description = data?.description?.description?.toString()
    const maxRejections = data?.data?.maxReject?.toString()
    const sendEmail = !!data?.data?.sendEmail ? 1 : 0
    const actionPlanItems = fields?.filter(item => !!data?.data?.[`${item}-check`]).map(item => ({
        question: t(`general.questions.${item}`),
        required: !!data?.data?.[`${item}-switch`] ? 1 : 0
    }))
    const actionPlanEvidences = getEvidenceData({ evidenceType: data?.evidence })
    let count = 0

    if (!data?.description?.title) { count = count + 1 }
    if (maxRejections == "") { count = count + 1 }
    if (!actionPlanItems?.length && !actionPlanEvidences?.length) { count = count + 1 }
    return {
        data: [{
            templateName: (title ?? ''),
            description: (description ?? ''),
            maxRejections: (maxRejections ?? ''),
            sendEmail: sendEmail,
            statusId: 1,
            ...!!actionPlanItems?.length && { actionPlanItems: actionPlanItems },
            required: 1,
            subtractPoints: 1,
            ...!!actionPlanEvidences?.length && { actionPlanEvidences: actionPlanEvidences },
            configReportFolio: !!data?.activeFolios ? {
                isAreaRequired: true,
                isDepartmentRequired: true,
                askForArea: true,
                askForDepartment: true,
                configActionPlanReportFolioEvidenceTypes: getEvidenceData({ evidenceType: data?.evidenceFolio }),
                enableRatingQuestion: !!data?.rejectsFolio?.enableSurvey,
                isRatingQuestionRequired: !!data?.rejectsFolio?.enableSurveyRequired,
                ...!!data?.rejectsFolio?.enableSurvey && { ratingQuestion: (data?.rejectsFolio?.satisfactionQuestion as string) },
                ...!!data?.rejectsFolio?.maxRejects && { maxRejects: (data?.rejectsFolio?.maxRejectsAmount as string) },
                ...!!data?.limitFolio?.maxFolios && { max: (data?.limitFolio?.maxFoliosAmount as string) }
            } : null,
        }],
        errors: {
            title: !!data?.description?.title,
            maxRejections: !!maxRejections,
            optionals: !!actionPlanItems?.length || !!actionPlanEvidences?.length,
            count
        }
    }
}

export { getActionPlans, getEvidenceData }