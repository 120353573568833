import { useTheme } from "@mui/material"
import { useContextState } from "../../../../../../hooks/theme"

const useStyles = () => {
  const { colors } = useContextState()
  const { breakpoints } = useTheme()

  return {
    containerIndicatorsProgress: {
      boxShadow: `0 0 4px 0px ${colors.color13}`,
      padding: '20px 0 0',
      position: 'relative',
      borderRadius: '12px',
      height: '100%',
      '& .lg-first': {
        [breakpoints.down(1380)]: {
          display: 'none'
        },
        [breakpoints.down(1200)]: {
          display: 'block'
        }
      },
      '& .lg-second': {
        [breakpoints.up(1380)]: {
          display: 'none'
        },
        [breakpoints.down(1200)]: {
          display: 'none'
        }
      }
    },
    chipContainer: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      '& .show': {
        marginLeft: '10px'
      }
    },
    indicator: ({ color }: { color: string }) => ({
      width: '10px',
      height: '10px',
      background: color,
      borderRadius: '50%'
    }),
    legend: () => ({
      fontSize: '.7rem',
      lineHeight: '1',
    }),
    content: {
      width: "100%",
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    badge: (payload?: {}) => ({
      '& .MuiBadge-badge': {
        top: 0,
        right: '0px',
        background: colors.mediumDark,
        color: colors.color5
      },
    }),
    filter: {
      background: colors.color12,
      color: colors.color5,
      borderRadius: '5px',
      '&:hover': {
        background: `${colors.color12}ee`,
      },
      '& .MuiSvgIcon-root': {
        fontSize: '1.5rem',
      }
    },
    containerModal: {
      maxHeight: '600px',
      overflowY: 'auto',
      padding: '50px',
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px',
    },
    headerModal: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%'
    },
    headerModalTitle: {
      fontSize: '22px',
      fontWeight: '500',
      color: colors.color9
    },
    close: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      '&.MuiSvgIcon-root': {
        fontSize: '1rem'
      }
    },
    containerIcons: {
      display: 'flex',
      alignItems: 'center',
      alignSelf: "start",
      gap: '10px'
    },
    btnFilter: {
      fontSize: '16px',
      background: colors.color8,
      '&:hover': {
        background: `${colors.color8}ee`,
      },
      fontWeight: '500',
      padding: '5px 20px'
    },
    caption: {
      paddingLeft: '15px',
      marginTop: '4px',
      fontSize: '.6rem',
    },
    headerProgress: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      alignItems: 'start',
      justifyContent: 'space-between',
      gap: '10px',
      padding: '0px 20px',
      height: 'fit-content',
      marginBottom: '20px',
      minHeight: "100px",
      borderBottom: `1px solid ${colors.color13}`
    },
    title: {
      fontSize: '1.2rem',
      marginBottom: '5px',
      fontWeight: '500',
      color: colors.color13,
    },
    dateFooter: {
      fontSize: '10px',
      color: colors.color9,
      '& span': {
        color: colors.color13,
      },
    },
    refresh: {
      background: colors.orangeMedium,
      color: colors.color5,
      borderRadius: '5px',
      '&:hover': {
        background: `${colors.orangeMedium}ee`,
      },
      '& .MuiSvgIcon-root': {
        fontSize: '1.5rem',
      }
    },
    actionIndicator: {
      position: 'absolute',
      top: '0',
      right: '10px',
      '& .MuiIconButton-root': {
        fontSize: '1rem',
        '& span': {
          fontSize: '11px',
          marginRight: '2px'
        },
        '& .MuiSvgIcon-root': {
          fontSize: '1em',
        },
      },
    },
    header: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerInputs: {
      width: '200px',
    },
    subtitleContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: '20px'
    },
    subtitle: {
      fontSize: '1rem',
      color: colors.color4,
      fontWeight: '500'
    },
    lastUpdate: {
      lineHeight: '1rem',
      fontSize: '.8rem',
      '& span': {
        color: colors.color16,
      }
    },
    cleanText: {
      color: colors.color31,
      padding: '0px',
      width: "max-content"
    },
    footerText: {
      fontSize: '12px',
      color: colors.color9,
      '& span': {
        color: colors.color13,
      },
    },
    noData: {
      width: '100%',
      textAlign: 'center',
      fontSize: '18px',
      color: `${colors.color13}`,
    },
    containerProgress: {
      overflow: 'hidden',
      overflowY: 'auto',
      width: '100%',
      maxHeight: '300px',
      minWidth: '100px',
      display: 'flex',
      flexWrap: 'wrap',
      padding: '0 20px',
      scrollbarWidth: 'thin',
      scrollbarColor: `${colors.color13} ${colors.color32}`,
      // Webkit
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: colors.color32,
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: colors.color13,
      },
    },
    containerDetailProgress: {
      padding: '10px 0px',
      marginBottom: "10px",
      boxShadow: `0px 1px ${colors.color13}`,
      '&:last-child': {
        boxShadow: `0px 0px ${colors.color13}`,
      },
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '10px',
      width: '100%',
      height: 'fit-content',
      position: 'relative',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: `${colors.color7}10`
      }
    },
    statusContainer: {
      width: '100%',
      padding: "0px 20px 20px",
      borderBottom: `1px solid ${colors.color13}`,
      maxHeight: "400px",
      overflowY: "auto",
      marginBottom: "20px"
    },
    cardContainer: {
      width: '100%',
      marginBottom: "20px",
      maxHeight: "250px",
      overflowY: "auto",
      padding: "0px 20px 2px 0px",
      marginLeft: "0px"
    },
    cardContainer2: {
      width: '100%',
      marginBottom: "20px",
      overflowY: "auto",
      padding: "0px 20px 2px 0px",
      marginLeft: "0px"
    },
    download: {
      minWidth: 'auto',
      padding: '8px',
      borderRadius: '5px'
    },
    progress: {
      flex: .4,
      display: 'flex',
      gap: '10px',
      marginRight: '30px',
      alignSelf: 'start',
      marginTop: '5px',
      alignItems: 'center'
    },
    percentage: {
      color: colors.color5,
      flex: .1,
      alignSelf: 'center',
      fontSize: '.8rem',
    },
    fullW: {
      width: '100%'
    },
    label: {
      width: '100%',
      fontSize: '1rem',
      color: colors.color5,
    },
    percentageTitle: ({ percentage }: { percentage: number }) => ({
      color: colors.color5,
      position: 'relative',
      fontSize: '.8rem',
      left: `calc(${percentage < 85 ? percentage : 85}% - 10px)`
    }),
    arrow: {
      position: 'absolute',
      right: '-20px',
      top: 'calc(50% - 20px)',
      fontSize: '40px',
      transitionDuration: '.5s',
      '& .MuiSvgIcon-root': {
        color: colors.color13,
        fontSize: 'inherit',
        width: '40px',
        height: '40px'
      }
    },
  }
}

export { useStyles }
