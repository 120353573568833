import { UserPasword } from "../../../../../../service/user/response"
import { userServiceFetch } from "../../../../../../utils/constants/callService"
import { Types } from "../../../../../../utils/enums";
import { HandleSeePayload, UpdatePasswordPayload, UpdateResponsePayload } from "./types";

const handleSee = async (payload: HandleSeePayload) => {
  let response: UserPasword | undefined
  if (payload.value && !payload.tmpPassword) {
    response = await userServiceFetch.getUserPassword({ id: payload?.id?.toString() ?? '' })
    if(!response.errors) {
      payload.getResponse(response.password)
    }
  }
  payload.setInformation(prev => ({
    ...prev,
    data: prev?.data?.map(item => {
      if (item.name == 'password') {
        return {
          ...item,
          value: !!payload.value  ? response?.password ?? payload.tmpPassword : ('********')
        }
      }
      return item
    })
  }))
}

const updatePassword = async (payload: UpdatePasswordPayload) => {
  const response = await userServiceFetch.updateUserPassword({ id: payload?.id ?? '', body: { password: payload.password } })
  if(!response.errors) {
    payload.setOpenSnackbar({
      open: true,
      type: Types.SUCCESS,
      message: 'La constraseña se actualizó correctamente',
    })
  } else {
    payload.setOpenSnackbar({
      open: true,
      type: Types.ERROR,
      message: 'Ocurrió un error al actualizar',
    })
  }
  
  payload.updateResponse(!response.errors)
}

const updateResponse = (payload: UpdateResponsePayload) => {
  if (payload.passwordWasUpdated) {
    payload.closeModalEdit()
    if (payload.refPassword?.hidePassword) {
      payload.refPassword?.hidePassword()
    }
  }
  payload.setIsLoadingUpdateData(false)
}
export { updatePassword, handleSee, updateResponse }