import { useContextState } from "../../../../../../../../hooks/theme"

const useNumberOfFoliosStyles = () => {
    const { colors } = useContextState()
    return {
        containerStatus: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            width: '100%',
            height: '100%',
        },
        textItem: {
            fontSize: '12px',
            fontWeight: '400',
            width: '100%',
            color: colors.color10,
            wordBreak: 'break-word',
        },
    }
}

export { useNumberOfFoliosStyles }