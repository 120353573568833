import Cake from "../../../../../../components/graphics/cake"
import { CardCakeProps } from "./types"
import { CardShadow } from "../../../../../../components/Card"
import { useStyles } from "./styles"
import { Box, IconButton, Typography } from "@mui/material"
import { icons } from "../../../../../../utils/icons"

const CardCake = (props: CardCakeProps) => {
    const existValue = props.data?.every((item) => !item.value)
    const styles = useStyles()
    return (
        <CardShadow sx={styles.container({ isActive: !!props.isActive })}>
            <Box sx={styles.headerContainer}>
                <Box sx={styles.descriptionContainer()}>
                    <Typography sx={{ ...styles.label({ isActive: !!props.isActive }), }}>{props.label}</Typography>

                    <Typography sx={styles.subtitle({ isActive: !!props.isActive })}>Total de programaciones: {props.total}</Typography>
                </Box>

                {!!props.eye && (<IconButton sx={styles.eye({ isActive: !!props.isActive })} {...props.eyeProps}>{icons("visibility")}</IconButton>)}
            </Box>

            {!!existValue && (<Typography sx={styles.noData}>{props.errorMessage}</Typography>)}

            {
                !existValue && (
                    <Cake
                        mode={props.isActive ? 'light' : 'dark'}
                        removeMB
                        data={props.data}
                        cakeSize={props.cakeSize}
                        errorMessage={props.errorMessage}
                        tooltip={props.tooltip}
                        tooltipProps={props.tooltipProps}
                    />
                )
            }
        </CardShadow>
    )
}

export { CardCake }
