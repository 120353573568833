import { Box } from '@mui/material'
import { icons } from '../../icons'
import { Color } from '../types/color'

export const checkbox = (color: Color) => {
  const styles = {
    container: {
      margin: '5px',
      width: '20px',
      height: '20px',
      border: `2px solid ${color.color13}`,
      background: color.color5,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      '& .MuiSvgIcon-root': {
        width: '100%',
        height: '100%',
      },
    },
  }
  return {
    MuiCheckbox: {
      defaultProps: {
        icon: <Box sx={styles.container} />,
        checkedIcon: <Box sx={styles.container}>{icons('stop')}</Box>,
      },
      styleOverrides: {
        root: {
          ':checked': {
            background: color.color19,
          },
        },
      },
    },
  }
}
