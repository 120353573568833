import { format, parse } from "date-fns"
import { Constants } from "../../../../../../../utils/constants"
import { es } from "date-fns/locale"

const settingLabel = (intervale: boolean) => {
  return {
    y: intervale ? 15 : 0,
    x: 30,
    transform: '',
    component: ({ value }: { value: string }) => {
      return value ? format(parse(value, Constants.dateFormat, new Date()), Constants.humanFormatDiminutiveShort, { locale: es }) : ``
    }
  }
}

export { settingLabel }
