import { Http } from '../types'
import { ObjectGeneric } from '../types/generals'
import './interceptor'
const URL = process.env.REACT_APP_API_URL
const headers1 = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'X-Frame-Options': 'DENY'
}

const headers2 = {
  Accept: 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
  'X-Frame-Options': 'DENY'
}

export const getLocalStorage = (name?:string) => {
  const state = localStorage.getItem(name ?? 'state')

  return state ? JSON.parse(state) : {}
}

export const setLocalStorage = ({
  body,
  name,
}: {
  name: string
  body: ObjectGeneric
}) => {
  localStorage.setItem(name, JSON.stringify(body ?? {}))
}
export const httpFetch: Http = {
  get: async <T>(url: string) => {
    const response = await fetch(URL + url, {
      method: 'GET',
      headers: {
        ...headers1,
        Authorization: `Bearer ${getLocalStorage()?.token}`,
      },
    })
    const data = await response.json()
    return data as T
  },
  getBlob: async <T>(url: string) => {
    const response = await fetch(URL + url, {
      method: 'GET',
      headers: {
        ...headers1,
        Authorization: `Bearer ${getLocalStorage()?.token}`,
      },
    })
    if (response?.status <= 299) {
      const data = await response.blob()
      return data as T
    }
    return { errors: [{ code: 'DOWNLOAD_FILE' }] }
  },
  post: async <T>(
    url: string,
    body?: ObjectGeneric | FormData,
    multiPart: boolean = false,
    noHeaders?: boolean
  ) => {
    const headers = {
      ...(multiPart ? headers2 : headers1),
      ...(!noHeaders && {
        Authorization: `Bearer ${getLocalStorage()?.token}`,
      }),
    }
    const response = await fetch(URL + url, {
      method: 'POST',
      headers: headers,
      body: multiPart ? (body as FormData) : JSON.stringify(body),
    })
    const data = await response.json()
    return data as T
  },
  blob: async <T>(
    url: string,
    body: ObjectGeneric | FormData,
    multiPart: boolean = false,
    noHeaders?: boolean
  ) => {
    const headers = {
      ...(multiPart ? headers2 : headers1),
      ...(!noHeaders && {
        Authorization: `Bearer ${getLocalStorage()?.token}`,
      }),
    }
    const response = await fetch(URL + url, {
      method: 'POST',
      headers: headers,
      body: multiPart ? (body as FormData) : JSON.stringify(body),
    })
    const data = await response
    return data as T
  },
  put: async <T>(
    url: string,
    body?: ObjectGeneric | FormData,
    multiPart: boolean = false
  ) => {
    const headers = {
      ...(multiPart ? headers2 : headers1),
      Authorization: `Bearer ${getLocalStorage()?.token}`,
    }
    const response = await fetch(URL + url, {
      method: 'PUT',
      headers: headers,
      body: body ? (multiPart ? (body as FormData) : JSON.stringify(body)) : '',
    })
    const data = await response.json()
    return data as T
  },
  delete: async <T>(url: string) => {
    const headers = {
      ...headers1,
      Authorization: `Bearer ${getLocalStorage()?.token}`,
    }
    const response = await fetch(URL + url, {
      method: 'DELETE',
      headers: headers,
    })
    const data = await response.json()
    return data as T
  },
}
