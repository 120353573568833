import { Month } from '../enums/month'
export const monthYear = [
  { label: 'Enero', value: Month.ENERO },
  { label: 'Febrero', value: Month.FEBRERO },
  { label: 'Marzo', value: Month.MARZO },
  { label: 'Abril', value: Month.ABRIL },
  { label: 'Mayo', value: Month.MAYO },
  { label: 'Junio', value: Month.JUNIO },
  { label: 'Julio', value: Month.JULIO },
  { label: 'Agosto', value: Month.AGOSTO },
  { label: 'Septiembre', value: Month.SEPTIEMBRE },
  { label: 'Octubre', value: Month.OCTUBRE },
  { label: 'Noviembre', value: Month.NOVIEMBRE },
  { label: 'Diciembre', value: Month.DICIEMBRE },
]

const getPreviousYears = () => {
  const currenYear = new Date().getFullYear()
  return new Array(currenYear - 2021)
    .fill(1)
    ?.map((_, index) => ({ label: `${currenYear - index}`, value: currenYear - index }))
    ?.sort((a, b) => a.value - b.value)
}

export { getPreviousYears }
