import { Http } from "../../utils/types";
import { ObjectGeneric } from "../../utils/types/generals";
import { ActiveUnits, AreaActive, AverageActionPlan, History, IndicatorActionPlan, IndicatorRating, IndicatorResponseActionPlan, IndicatorScheduleStoreRating, IndicatorTop, IndicatorsAssigned, IndicatorsCreated, IndicatorsFoliosAreas, UnitsActiveData, indicatorAverage } from "./response";

const dashboardServices = (client: Http) => ({
  activeUnits: async ({ body }: { body: ObjectGeneric }): Promise<ActiveUnits> => await client.post(`${process.env.REACT_APP_UNITS_ACTIVE_INDEX ?? ''}/_search`, body),
  activeProgramming: async ({ body }: { body: ObjectGeneric }): Promise<ActiveUnits> => await client.post(`${process.env.REACT_APP_PROGRAMMING_ACTIVE_INDEX ?? ''}/_search`, body),
  activeActionPlan: async ({ body }: { body: ObjectGeneric }): Promise<ActiveUnits> => await client.post(`${process.env.REACT_APP_ACTION_PLAN_ACTIVE_INDEX ?? ''}/_search`, body),
  activeActionPlanWeek: async ({ body }: { body: ObjectGeneric }): Promise<History> => await client.post(`${process.env.REACT_APP_ACTION_PLAN_WEEK_INDEX ?? ''}/_search`, body),
  areaActive: async ({ body }: { body: ObjectGeneric }): Promise<AreaActive> => await client.post(`${process.env.REACT_APP_AREAS_ACTIVE_INDEX ?? ''}/_search`, body),
  areaFolios: async ({ body }: { body: ObjectGeneric }): Promise<IndicatorsFoliosAreas> => await client.post(`${process.env.REACT_APP_FOLIOS_ACTIVE_INDEX ?? ''}/_search`, body),
  activeUnitsHistory: async ({ body }: { body: ObjectGeneric }): Promise<History> => await client.post(`${process.env.REACT_APP_UNITS_HISTORIAL_INDEX ?? ''}/_search`, body),
  activeUnitsWeek: async ({ body }: { body: ObjectGeneric }): Promise<History> => await client.post(`${process.env.REACT_APP_UNITS_WEEK_INDEX ?? ''}/_search`, body),
  activeProgrammingHistory: async ({ body }: { body: ObjectGeneric }): Promise<History> => await client.post(`${process.env.REACT_APP_PROGRAMMING_HISTORIAL_INDEX ?? ''}/_search`, body),
  activeActionPlanHistory: async ({ body }: { body: ObjectGeneric }): Promise<History> => await client.post(`${process.env.REACT_APP_ACTION_PLAN_HISTORIAL_INDEX ?? ''}/_search`, body),
  areaHistory: async ({ body }: { body: ObjectGeneric }): Promise<History> => await client.post(`${process.env.REACT_APP_AREAS_HISTORIAL_INDEX ?? ''}/_search`, body),
  areaWeekHistory: async ({ body }: { body: ObjectGeneric }): Promise<History> => await client.post(`${process.env.REACT_APP_AREAS_WEEK_INDEX ?? ''}/_search`, body),
  areaFoliosHistory: async ({ body }: { body: ObjectGeneric }): Promise<History> => await client.post(`${process.env.REACT_APP_FOLIOS_HISTORIAL_INDEX ?? ''}/_search`, body),
  activeUnitsData: async ({ body }: { body: ObjectGeneric }): Promise<UnitsActiveData> => await client.post(`${process.env.REACT_APP_UNITS_ACTIVE_DATA ?? ''}/_search`, body),
  indicatorsctionPlanQuestionnaire: async (): Promise<IndicatorActionPlan> => await client.get('indicators/current-week/stores/questionnaires/action-plans'),
  indicatorsReportFoliosAssigned: async (): Promise<IndicatorsAssigned> => await client.get('indicators/current-week/report-folio/assigned'),
  indicatorsReportFoliosCreated: async (): Promise<IndicatorsCreated> => await client.get('indicators/current-week/report-folio/created'),
  indicatorsRatingAverage: async (): Promise<indicatorAverage> => await client.get('indicators/current-week/store/questionnaires/average-rating'),
  indicatorsTop: async (): Promise<AverageActionPlan> => await client.get('indicators/current-week/store/average-action-plan'),
  topBestStoresByStoreRole: async (): Promise<IndicatorTop> => await client.get('indicators/current-week/store/top'),
  ratingQuestionnaire: async (): Promise<IndicatorRating> => await client.get('indicators/scheduled-stores-rating'),
  responseActionPlan: async (): Promise<IndicatorResponseActionPlan> => await client.get('indicators/action-plans'),
  scheduledStoreQuestionnaire: async (): Promise<IndicatorScheduleStoreRating> => await client.get('indicators/scheduled-stores-rating'),
})

export { dashboardServices }
