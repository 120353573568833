import { Navigate, Route, Routes } from 'react-router-dom'
import { Questionnaire } from '../pages/main'
import { Detail } from '../pages/detail'

const QuestionnaireRoute = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="main" />} />
      <Route path="main" element={<Questionnaire />} />
      <Route path="create" element={<Detail />} />
      <Route path="edit/:id" element={<Detail />} />
    </Routes>
  )
}

export default QuestionnaireRoute
