export const useStyles = () => ({
  select: {
    '& .MuiSelect-select': {
      padding: '15px 14px',
    },
  },
  formControl: {
    width: '100%',
  },
})
