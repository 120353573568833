import { Box, BoxProps } from "@mui/material"

const MessageContainer = (props: { children: JSX.Element; BoxPropsSx?: BoxProps['sx'] }) => {
  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      rowGap: '20px',
      height: '300px',
      ...props.BoxPropsSx ?? {}
    }} >
      {props.children}
    </Box>
  )
}

export { MessageContainer }