import { Color } from "../types/color";

export const textfield = (color: Color) => ({
  MuiTextField: {
    styleOverrides: {
      root: {
        width: '100%',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        height: 20,
        fontSize: 16,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: 16,
      },
    },
  },
})
