import { Box, Grid, IconButton, Typography } from "@mui/material"
import { RedirectedIds } from "../../../../typing"
import { canAccess } from "../../../../../../utils/validate/canaccess"
import { Roles } from "../../../../../../utils/enums"
import { DinamicForm } from "../../../../../../components/dinamicForm"
import { icons } from "../../../../../../utils/icons"
import { CustomTable } from "../../../../../../components"
import { useTranslation } from "react-i18next"
import { useCallback, useLayoutEffect, useState } from "react"
import { useStyles } from "./styles"
import { useFilter } from "../../../../../../hooks/filter"
import { endOfWeek, format, startOfWeek } from "date-fns"
import { Constants } from "../../../../../../utils/constants"
import { indicatorsServices } from "../../../../../../service/indicators"
import { httpFetch } from "../../../../../../utils/crud"
import { datesTop } from "../../initial"
import { dataDefault } from "../../body"

const currentDate = `date_init=${format(
  startOfWeek(Date.now(), { weekStartsOn: 1 }),
  Constants.dateFormat3
)}&date_end=${format(
  endOfWeek(Date.now(), { weekStartsOn: 1 }),
  Constants.dateFormat3
)}`
const Top15 = () => {
  const { t } = useTranslation()
  const styles = useStyles()
  const { get15BestStores, get15WorstStoresStores } =
    indicatorsServices(httpFetch)
  const [bestStores, setBestStores] = useState<any[]>([])
  const [worstStores, setWorstStores] = useState<any[]>([])
  const [loadingBest, setIsLoadingBest] = useState(false)
  const [loadingWorst, setIsLoadingWorst] = useState(false)
  const { errors, filterData, onChange, query } = useFilter({
    formatFilter: {},
    defaultValues: {
      from: new Date(startOfWeek(Date.now(), { weekStartsOn: 1 })),
      to: new Date(endOfWeek(Date.now(), { weekStartsOn: 1 })),
    },
  })
  const column = [
    {
      id: 'keyCode',
      label: t('general.tags.id'),
      sortable: true,
      rowTdStyles: {
        maxWidth: '40px',
      },
      headerCell: {
        maxWidth: '40px',
      }
    },
    {
      id: 'stores',
      label: t('general.tags.store'),
      sortable: true,
    },
    {
      id: 'result',
      label: t('general.tags.result'),
      sortable: true,
      rowTdStyles: {
        maxWidth: '60px',
      },
      headerCell: {
        maxWidth: '60px',
      }
    },
  ]
  const handleWorstStores = useCallback(() => {
    const queryLocal =
      query.filter?.replace('from', 'date_init').replace('to', 'date_end') ??
      currentDate
    setIsLoadingWorst(true)
    setWorstStores([])
    get15WorstStoresStores({ query: queryLocal })
      .then((worstStoresResponse) => {
        if (!worstStoresResponse.errors) {
          setWorstStores(
            worstStoresResponse?.lowestStores?.map((item) => ({
              stores: item?.name,
              result: item?.score,
              keyCode: item?.key_code
            }))
          )
        }
      })
      .finally(() => {
        setIsLoadingWorst(false)
      })
    // eslint-disable-next-line
  }, [query.filter])

  const handleBestStores = useCallback(() => {
    const queryLocal =
      query.filter?.replace('from', 'date_init').replace('to', 'date_end') ??
      currentDate
    setIsLoadingBest(true)
    setBestStores([])
    get15BestStores({ query: queryLocal })
      .then((bestStoresResponse) => {
        if (!bestStoresResponse?.errors) {
          setBestStores(
            bestStoresResponse?.topStores?.map((item) => ({
              stores: item?.name,
              result: item?.score,
              keyCode: item?.key_code
            }))
          )
        }
      })
      .finally(() => {
        setIsLoadingBest(false)
      })
    // eslint-disable-next-line
  }, [query.filter])

  const improveResults = (
    <Grid key="improveResults" item xl={6} lg={6} md={12} sm={12} xs={12}>
      <Grid
        sx={styles.grid}
        className='improve'
        container
        alignItems="center"
      >
        <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
          {' '}
          <Typography sx={styles.unitTitle}>
            Unidades con Mejores Resultados{' '}
            <span >(Top 15)</span>
          </Typography>
        </Grid>

        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
          <IconButton
            onClick={() => {
              handleBestStores()
            }}
          >
            {icons('refresh')}
          </IconButton>
        </Grid>
      </Grid>
      <CustomTable
        loader={loadingBest}
        column={column}
        row={bestStores}
        onClick={() => { }}
        selected={-1}
      />
    </Grid>
  )
  const worseResults = (
    <Grid key="worseResults" item xl={6} lg={6} md={12} sm={12} xs={12}>
      <Grid
        sx={styles.grid}
        container
        className='worst'
        alignItems="center"
      >
        <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
          {' '}
          <Typography sx={styles.unitTitle}>
            Unidades con Peores Resultados <span >(Top 15)</span>
          </Typography>
        </Grid>
        <Grid item xl={1} lg={1} md={1} sm={1}>
          <IconButton
            onClick={() => {
              handleWorstStores()
            }}
          >
            {icons('refresh')}
          </IconButton>
        </Grid>
      </Grid>
      <CustomTable
        loader={loadingWorst}
        column={column}
        row={worstStores}
        onClick={() => { }}
        selected={-1}
      />
    </Grid>
  )

  useLayoutEffect(() => {
    handleBestStores()
    handleWorstStores()
  }, [])
  return (
    <Grid key={'top-15'} item xs={12}>
      <div id={RedirectedIds.TOP_15} />

      <Box sx={styles.card}>
        <Box sx={styles.headerInputs}>
          <Typography sx={styles.titleHeaders}>Top 15 unidades</Typography>

          {!!canAccess([Roles.ADMIN]) && (
            <Box
              sx={{
                width: {
                  xl: '400px',
                  lg: '400px',
                  md: '400px',
                  sm: '600px',
                  xs: '100%',
                },
              }}
            >
              <DinamicForm
                errors={errors}
                legacyErrors
                input={Object.values(datesTop)}
                values={filterData}
                onChange={(e, blur) => {
                  const { name, value } = e.target

                  if (!value) {
                    onChange(
                      {
                        target: {
                          name,
                          value: dataDefault[name],
                        },
                      },
                      blur
                    )
                  } else {
                    onChange(e, blur)
                  }
                }}
              />
            </Box>
          )}
        </Box>

        <Grid container spacing={3}>
          {improveResults}

          {worseResults}
        </Grid>
      </Box>
    </Grid>
  )
}

export { Top15 }
