import { Box, Icon, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { icons } from '../../../../../utils/icons'
import { useState } from 'react'
import { ObjectGeneric } from '../../../../../utils/types/generals'
import { useStyles } from '../styles'
import { format } from 'date-fns'
import { Constants } from '../../../../../utils/constants'
import { handleAllErrors } from '../../../../../utils/methods/handleErrors'
import { RequestErrors } from '../../../../../utils/enums'
import { userCannotSelectcUsers } from '../methods/validateByUser'
import { useContextState } from '../../../../../hooks/theme'

const useColumns = (data?: { useScheduled: Record<string, ObjectGeneric> }) => {
  const { t } = useTranslation()
  const { colors } = useContextState()
  const styles = useStyles()
  const [tableItemsHaveErrors, setTableItemHaveErrors] = useState<
    Record<string, string[]>
  >({})
  const [currentUser, setCurrentUser] = useState({
    questionnaire: "",
    user: ""
  })
  const handlTableItemsHaveErrors = ({
    id,
  }: {
    id: Record<string, string[]>
  }) => {
    setTableItemHaveErrors((prev) => ({
      ...prev,
      ...id,
    }))
  }
  const [userSelectes, setUseSelected] = useState<{
    id: string | number
  }>({
    id: '',
  })
  const columns = {
    column1: [
      {
        id: 'fullname',
        label: t('useScheduled.detail.tables.fullname'),
        sortable: true,
        minWidth: 120,
      },
      {
        id: 'curp',
        label: t('useScheduled.detail.tables.curp'),
        sortable: true,
        minWidth: 120,
      },
      {
        id: 'userType',
        label: t('useScheduled.detail.tables.userType'),
        sortable: true,
        minWidth: 120,
      },
      {
        id: 'email',
        label: t('useScheduled.detail.tables.email'),
        sortable: true,
        minWidth: 120,
      },
      {
        id: 'phone',
        label: t('useScheduled.detail.tables.phone'),
        sortable: true,
        minWidth: 120,
      },
    ],
    column2: [
      {
        id: 'questionnaireName',
        label: t('useScheduled.detail.tables.questionnaireName'),
        sortable: true,
        minWidth: 120,
      },
      {
        id: 'askAmount',
        label: t('useScheduled.detail.tables.askAmount'),
        sortable: true,
        minWidth: 120,
      },
      {
        id: 'actionPlan',
        label: t('useScheduled.detail.tables.actionPlan'),
        sortable: true,
        minWidth: 120,
      },
      {
        id: 'appAmount',
        label: t('useScheduled.detail.tables.appAmount'),
        sortable: true,
        minWidth: 120,
      },
    ],
    column3: [
      {
        id: 'user',
        label: t('useScheduled.detail.tables.user'),
        sortable: true,
        minWidth: 120,
        renderCell: (params: any) => {
          const { user, curp, id } = params.row

          return (
            <Box sx={styles.users}>
              <Typography>{user}</Typography>

              <Typography>
                {t('useScheduled.detail.tables.curp')}: {curp}
              </Typography>

              {tableItemsHaveErrors?.[id]?.map((item) => (
                <Typography sx={styles.errorsText}>- {handleAllErrors(item as RequestErrors, t)}</Typography>
              ))}
            </Box>
          )
        },
      },
      {
        id: 'questionnaireName',
        label: t('useScheduled.detail.tables.questionnaireName'),
        sortable: true,
        minWidth: 120,
      },
      {
        id: 'dateAndApplicated',
        label: t('useScheduled.detail.tables.dateAndApplicated'),
        rowTdStyles: {
          maxWidth: '100px',
        },
        minWidth: 120,
        renderCell: (params: any) => {
          const { row } = params
          const scheduled = data?.useScheduled?.[row.id]
          const from = scheduled?.from
          const to = scheduled?.to

          return (
            <Box sx={styles.flex} className="g-2 f-w wrap">
              <Typography sx={styles.tableText}>
                {from ? format(from, Constants.dateFormat) : 'DD/MM/YY'}
              </Typography>

              <span>-</span>

              <Typography sx={styles.tableText}>
                {to ? format(to, Constants.dateFormat) : 'DD/MM/YY'}
              </Typography>
            </Box>
          )
        },
      },
      {
        id: 'schedule',
        lablel: '',
        contentColumn: {
          justifyContent: 'center',
        },
        rowTdStyles: {
          maxWidth: '20px',
        },
        minWidth: 100,
        headerCell: {
          width: '70px'
        },
        column: () => {
          return (
            <Box>
              <IconButton
                sx={styles.scheduleIcon({
                  mark: false,
                  color: colors.color5
                })}
                onClick={(e) => {
                  e.stopPropagation()
                  handleScheduleUser({ id: 'all' })
                }}
              >
                {icons('calendar')}
              </IconButton>
            </Box>
          )
        },
        renderCell: (params: any) => {
          const { row } = params
          return (
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
              <IconButton
                sx={styles.scheduleIcon({
                  mark: !!data?.useScheduled?.[row.id],
                })}
                onClick={(e) => {
                  e.stopPropagation()
                  setCurrentUser({
                    questionnaire: row.questionnaireName ?? "",
                    user: row.user ?? ""
                  })
                  
                  handleScheduleUser({ id: row.id })
                }}
              >
                {icons('calendar')}

                {(!!data?.useScheduled?.[row.id]) && (
                  <Icon sx={styles.check}>{icons('check')}</Icon>
                )}
              </IconButton>
            </Box>
          )
        },
      },
    ],
  }

  const handleCloseScheduleUser = () => {
    setUseSelected((prev) => ({
      ...prev,
      id: '',
    }))
  }

  const handleScheduleUser = ({ id }: { id: string | number }) => {
    setUseSelected((prev) => ({
      ...prev,
      id,
    }))
  }

  return {
    columns,
    userSelectes,
    tableItemsHaveErrors,
    currentUser,
    handleCloseScheduleUser,
    handlTableItemsHaveErrors,
  }
}

const useSteps = (payload?: { id?: string }) => {
  const { t } = useTranslation()
  const listSteps = [
    { label: t('useScheduled.detail.steps.1'), step: 1 },
    { label: t('useScheduled.detail.steps.2'), step: 2 },
    { label: t('useScheduled.detail.steps.3'), step: 3 },
  ]

  return {
    stepsList: (userCannotSelectcUsers() || !!payload?.id) ? listSteps.slice(1).map(item => ({
      ...item,
      step: item.step - 1
    })) : listSteps,
    titles: {
      1: (
        <>
          Seleccione <span>los usuarios</span> para las que realiza esta
          planificación
        </>
      ),
      2: (
        <>
          Seleccione <span>los cuestionarios</span> que se deben aplicar en esta
          planificación
        </>
      ),
      3: <></>,
    } as Record<string, JSX.Element>,
    footers: {
      0: 'Debes seleccionar al menos un usuario para continuar',
      1: 'Debes seleccionar al menos un cuestionario para continuar',
    } as Record<string, string>,
  }
}

export { useColumns, useSteps }
