import { ReactNode, useState } from 'react'
import { BaseInput } from '../../../../../../../../components'
import { onChangeEvent } from '../../../../../../../../utils/types/generals'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { reportFolioServices } from '../../../main/constants/states'

type onChange = (e: onChangeEvent, child?: ReactNode | number | { blur: boolean }) => void

type params = {
  id?: string
  submitted: (response: any) => void
  cancel: () => void
  hasComments: boolean
}

export const CommentForm = ({ id, submitted, cancel, hasComments }: params) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)

  const [comment, setComment] = useState({
    label: '',
    value: null,
  })

  const crudChange: onChange = ({ target }) => {
    const { value } = target

    setComment({
      ...comment,
      value,
    })
  }

  const emitForm = async () => {
    setLoading(true)
    const body = { comment: comment.value }
    const idFolio = parseInt(id || '')
    const response = await reportFolioServices.saveComment({ id: idFolio, body })
    setLoading(false)

    submitted(response)
  }

  return (
    <>
      <BaseInput
        name={'comment'}
        value={{
          comment,
        }}
        type="alphaNumeric"
        onChange={crudChange}
        multiline
        rows={3}
        inputProps={{
          maxLength: 65535,
        }}
      />

      <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: '10px', gap: '10px' }}>
        <LoadingButton loading={loading} variant="contained" onClick={emitForm} disabled={!comment?.value}>
          {hasComments ? 'Guardar respuesta' : 'Guardar comentario'}
        </LoadingButton>

        <LoadingButton
          variant="text"
          onClick={() => {
            cancel()
          }}
        >
          {t('general.cancel')}
        </LoadingButton>
      </Box>
    </>
  )
}
