import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useStyles } from './style'
import { PropsModelDownloadButtons } from './typing'

const ModelDownloadButtons = (props: PropsModelDownloadButtons) => {
  const { t } = useTranslation()
  const styles = useStyles({ removeMargin: true })

  return (
    <Box sx={styles.container}>
      {props.extraChildren?.start}

      <Button
        disabled={props?.disableCSV}
        onClick={() => {
          props?.csv()
        }}
      >
        {t('general.download')} EXCEL
      </Button>
      <Button
        disabled={props?.disablePDF}
        onClick={() => {
          props?.pdf()
        }}
      >
        {t('general.download')} PDF
      </Button>
      <Button
        disabled={props?.disableWORD}
        onClick={() => {
          props?.word()
        }}
      >
        {t('general.download')} WORD
      </Button>

      {props.extraChildren?.end}
    </Box>
  )
}

export { ModelDownloadButtons }
