import { Box, IconButton, Typography, Pagination } from '@mui/material'
import { EvidencesTypeEnum, EvidencesTypeValueEnum } from '../../../../../../../../utils/enums/evidences'
import { icons } from '../../../../../../../../utils/icons'
import { useStyles } from './styles'
import SignatureCanvas from 'react-signature-canvas'
import { ChangeEvent, Fragment, useRef, useState } from 'react'
import SimpleButtons from '../../../../../../../../components/buttons/simples'
import { BaseInput } from '../../../../../../../../components'
import { ModelThree } from '../../../../components/details'
import { onChange } from '../../../../../../../../utils/types'
import { useTranslation } from 'react-i18next'
import { LinearProgressWithLabel } from '../../../../../../../../components/progress'

const TypesEvidences = (props: {
  evidencesType: string
  index: string | number
  customeKey: string
  seeFiles?: boolean
  isRequired?: boolean
  size?: number
  handleComments: (value: any) => void
  value?:
    | Record<
        string,
        {
          progress?: boolean | undefined
          progressAmount?: string | undefined
          upload?: boolean | undefined
          name: string
          url: string | File
          ref?: any
        }
      >
    | undefined
  files: {
    handleLoad: (data: { url: string | File; name: string; nameFIle?:string }) => void
    deleteEvidence: (data: { index: number | string; isDelete?: (value: boolean) => void; isString?: boolean }) => void
    handleCancelUpload: (data: { key: string }) => Promise<void>
    getEvidences: (data: { e: ChangeEvent<HTMLInputElement>; name?: string | undefined; isLoad?: (load: boolean) => void }) => Promise<void>
  }
}) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const refSign = useRef<SignatureCanvas | null>(null)
  const [isThereSomeThingPainted, setIsThereSomeThingPainted] = useState(true)
  const [ids, setIds] = useState({
    signature: new Date().getTime(),
    comments: new Date().getTime(),
  })
  const [evidencesText, setEvidencesText] = useState<Record<string, string | null>>({
    comments: null,
  })
  const [evidencesSteps, setEvidencesSteps] = useState({
    signature: 0,
  })

  const handleEvidencesText: onChange = ({ target }) => {
    const { name, value } = target

    setEvidencesText((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleChangePage = ({ page, type }: { page: number; type: string }) => {
    setEvidencesSteps((prev) => ({
      ...prev,
      [type]: page - 1,
    }))
  }

  const handleType = () => {
    const transform: Record<string | number, EvidencesTypeEnum> = {
      [EvidencesTypeValueEnum.IMAGE]: EvidencesTypeEnum.IMAGE,
      [EvidencesTypeValueEnum.CAMERA]: EvidencesTypeEnum.IMAGE,
      [EvidencesTypeValueEnum.VIDEO]: EvidencesTypeEnum.IMAGE,
      [EvidencesTypeValueEnum.VIDEO_GALLERY]: EvidencesTypeEnum.IMAGE,
      [EvidencesTypeValueEnum.DOCUMENT]: EvidencesTypeEnum.IMAGE,
      [EvidencesTypeValueEnum.SIGN]: EvidencesTypeEnum.SIGN,
      [EvidencesTypeValueEnum.COMMENT]: EvidencesTypeEnum.COMMENT,
    }

    const value = transform[props.evidencesType]

    const evidences: any = {
      [EvidencesTypeEnum.IMAGE]: () => {
        const allValues = Object.values(props?.value ?? {})
        return (
          <Box sx={styles.itemsContainer}>
            {!!allValues?.length ? (
              allValues?.map((itemValue, indexValue) => (
                <Box key={`${props.index}-${indexValue}`} sx={styles.items}>
                  <Typography sx={styles.itemTitle}>{itemValue?.name ?? ''}</Typography>

                  <Box sx={styles.iconContainer}>
                    <IconButton
                      onClick={() => {
                        props?.files.handleLoad({ url: itemValue?.url, name: props.evidencesType, nameFIle: itemValue?.name ?? '' })
                      }}
                      className="download"
                    >
                      {icons('download')}
                    </IconButton>

                    <IconButton
                      className="delete"
                      onClick={() => {
                        props?.files.deleteEvidence({ index: `${props.customeKey}-${itemValue?.name}` })
                      }}
                    >
                      {icons('delete')}
                    </IconButton>
                  </Box>

                  {!!itemValue?.progress && (
                    <Box sx={styles.progress}>
                      {!itemValue?.upload ? (
                        <Box sx={styles.containerProgress}>
                          <LinearProgressWithLabel value={itemValue?.progressAmount ? parseFloat(itemValue?.progressAmount) : 0} />

                          <IconButton
                            sx={styles.cancelUpload}
                            onClick={() => {
                              props?.files.handleCancelUpload({ key: `${props.customeKey}-${itemValue?.name}` })
                            }}
                          >
                            {icons('simple-close')}
                          </IconButton>
                        </Box>
                      ) : (
                        <>
                          <IconButton
                            sx={styles.upload}
                            onClick={() => {
                              props?.files.handleLoad({ url: itemValue?.url, name: props.evidencesType})
                            }}
                          >
                            {icons('upload')}
                          </IconButton>

                          <IconButton
                            sx={styles.delete}
                            className="delete"
                            onClick={() => {
                              props?.files.deleteEvidence({ index: `${props.customeKey}-${itemValue?.name}` })
                            }}
                          >
                            {icons('delete')}
                          </IconButton>
                        </>
                      )}
                    </Box>
                  )}
                </Box>
              ))
            ) : (
              <Typography sx={styles.noData}>{t('reportFolio.detail.general.noInformation')}</Typography>
            )}
          </Box>
        )
      },
      [EvidencesTypeEnum.COMMENT]: () => {
        const allValue = Object.values(props.value ?? {})

        const errorMesage = () => {
          let message = ''

          if (!!props?.isRequired && evidencesText.comments == '') {
            message = 'El campo comentario es requerido'
          } else if ((evidencesText.comments?.length ?? 0) > (props.size ?? 0)) {
            message = `El comentario exece los ${props.size ?? 0}`
          }

          return message
        }
        const saveComment = () => {
          const value = props.value
          let aux = ids.signature

          Object.entries(value ?? {}).forEach(([key]) => {
            const regex = /comments_(\d+)/
            const match = key.match(regex)
            if (match && match.length > 1) {
              const numero = parseInt(match[1])
              if (numero >= aux) {
                aux = numero + 1
                setIds((prev) => ({
                  ...prev,
                  comments: aux,
                }))
              }
            }
          })

          setEvidencesText((prev) => ({
            ...prev,
            comments: null,
          }))

          props.handleComments({ value: evidencesText.comments, name: `comments_${aux}`, type: props.evidencesType })
        }
        return (
          <Box sx={styles.commentsContainer}>
            {!props?.seeFiles ? (
              <>
                <BaseInput
                  sx={styles.commentsP}
                  name="comments"
                  onChange={handleEvidencesText}
                  inputProps={{ maxLength: props?.size }}
                  errors={{
                    comments: errorMesage(),
                  }}
                  value={{
                    comments: {
                      label: 'Comentario',
                      value: evidencesText.comments,
                    },
                  }}
                  type="alphaNumeric"
                  multiline
                  rows={5}
                />

                <Typography>
                  {evidencesText.comments?.length ?? 0} / {props.size ?? 0}
                </Typography>

                <SimpleButtons
                  accept={{
                    label: 'AGREGAR',
                    click: () => {
                      saveComment()
                    },
                    disabled: (evidencesText?.comments?.length ?? 0) < 3 || (evidencesText?.comments?.length ?? 0) > (props?.size ?? 0),
                  }}
                />
              </>
            ) : (
              <Box sx={[styles.comments]}>
                {allValue?.length > 0 ? (
                  allValue?.map((itemComments, indexComment) => (
                    <Fragment key={indexComment}>
                      <ModelThree
                        title={(itemComments.url as string) ?? ''}
                        delete={() => {
                          props?.files.deleteEvidence({
                            index: `${props.customeKey}-${itemComments?.name}`,
                            isString: true,
                          })
                        }}
                      />
                    </Fragment>
                  ))
                ) : (
                  <Typography sx={styles.noData}>{t('reportFolio.detail.general.noInformation')}</Typography>
                )}
              </Box>
            )}
          </Box>
        )
      },
      [EvidencesTypeEnum.SIGN]: () => {
        const allValue = Object.entries(props.value ?? {})
        const valueSignature = allValue?.[evidencesSteps.signature]?.[1]

        const handleSDignature = () => {
          if (refSign?.current) {
            const url = refSign?.current
              .getCanvas()
              .getContext('2d', {
                willReadFrequently: true,
              })
              ?.canvas.toDataURL('image/png')
            if (!!url) {
              fetch(url)
                .then((res) => res.blob())
                .then((url) => {
                  const value = props?.value
                  let aux = ids.signature
                  Object.entries(value ?? {}).forEach(([key]) => {
                    const regex = /firma_(\d+)\.png/
                    const match = key.match(regex)
                    if (match && match.length > 1) {
                      const numero = parseInt(match[1])
                      if (numero >= aux) {
                        aux = new Date().getTime() + numero + 1
                        setIds((prev) => ({
                          ...prev,
                          signature: aux,
                        }))
                      }
                    }
                  })
                  var file = new File([url], `firma_${aux}.png`, {
                    type: 'image/png',
                  })

                  props.files.getEvidences({
                    e: {
                      target: {
                        files: {
                          0: file,
                        },
                      },
                    } as any,
                    name: EvidencesTypeValueEnum.SIGN?.toString(),
                    isLoad: (load) => {
                      if (load) {
                        deleteSignature()
                      }
                    },
                  })
                })
            }
          }
        }

        const deleteSignature = () => {
          refSign?.current?.clear()
          setIsThereSomeThingPainted(true)
        }

        return (
          <Box sx={styles.canvas}>
            {!props?.seeFiles ? (
              <>
                {!props?.value?.[`firma_${ids.signature}.png`]?.progress ? (
                  <>
                    <Box sx={[styles.commentsP, styles.wFull]}>
                      <SignatureCanvas
                        onBegin={() => {
                          setIsThereSomeThingPainted(false)
                        }}
                        ref={refSign}
                        penColor="#000"
                        backgroundColor="#fff"
                        canvasProps={{ width: 320, height: 180, className: 'sigCanvas' }}
                      />
                    </Box>

                    <SimpleButtons
                      accept={{
                        label: 'AGREGAR',
                        disabled: isThereSomeThingPainted,
                        click: () => {
                          handleSDignature()
                        },
                      }}
                      cancel={{
                        click: () => {
                          deleteSignature()
                        },
                        label: 'Limpiar',
                        variant: 'text',
                      }}
                    />
                  </>
                ) : (
                  <Box sx={styles.progress}>
                    {!props.value?.[`firma_${ids.signature}.png`]?.upload ? (
                      <Box sx={styles.containerProgress}>
                        <LinearProgressWithLabel value={props.value?.[`firma_${ids.signature}.png`]?.progressAmount ? parseFloat(props.value?.[`firma_${ids.signature}.png`]?.progressAmount ?? '0') : 0} />

                        <IconButton
                          sx={styles.cancelUpload}
                          onClick={() => {
                            props?.files.handleCancelUpload({ key: `${props.customeKey}-${props.value?.[`firma_${ids.signature}.png`]?.name}` })
                          }}
                        >
                          {icons('simple-close')}
                        </IconButton>
                      </Box>
                    ) : (
                      <>
                        <IconButton
                          sx={styles.upload}
                          onClick={() => {
                            props?.files.handleLoad({ url: props.value?.[`firma_${ids.signature}.png`]?.url ?? '', name: props.evidencesType, })
                          }}
                        >
                          {icons('upload')}
                        </IconButton>

                        <IconButton
                          sx={styles.delete}
                          className="delete"
                          onClick={() => {
                            props?.files.deleteEvidence({
                              index: `${props.customeKey}-${valueSignature?.name}`,
                              isDelete: (value) => {
                                if (!!value) {
                                  handleChangePage({ page: 1, type: 'signature' })
                                }
                              },
                            })
                          }}
                        >
                          {icons('delete')}
                        </IconButton>
                      </>
                    )}
                  </Box>
                )}
              </>
            ) : !!allValue?.length ? (
              <Box sx={[styles.containerProgress, styles.flexWrap]}>
                {!!valueSignature?.url && typeof valueSignature?.url == 'string' && (
                  <Box sx={styles.imgContainer}>
                    <img style={styles.sign} src={valueSignature?.url as string} alt="" />

                    <Box sx={[styles.iconContainer, styles.imgActions]}>
                      <IconButton
                        onClick={() => {
                          props?.files.handleLoad({ url: valueSignature?.url, name: props.evidencesType, nameFIle: valueSignature?.name ?? '' })
                        }}
                        className="download"
                      >
                        {icons('download')}
                      </IconButton>

                      <IconButton
                        className="delete"
                        onClick={() => {
                          props?.files.deleteEvidence({
                            index: `${props.customeKey}-${valueSignature?.name}`,
                            isDelete: (value) => {
                              if (!!value) {
                                handleChangePage({ page: 1, type: 'signature' })
                              }
                            },
                          })
                        }}
                      >
                        {icons('delete')}
                      </IconButton>
                    </Box>
                  </Box>
                )}
                {allValue?.length > 1 && (
                  <Pagination
                    size="small"
                    siblingCount={0}
                    count={allValue?.length}
                    page={evidencesSteps.signature + 1}
                    onChange={(_: unknown, page: number) => {
                      handleChangePage({ page, type: 'signature' })
                    }}
                  />
                )}
              </Box>
            ) : (
              <Typography sx={styles.noData}>{t('reportFolio.detail.general.noInformation')}</Typography>
            )}
          </Box>
        )
      },
    }

    return evidences[value]()
  }

  return handleType()
}

export { TypesEvidences }
