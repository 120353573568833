import { Box, Typography, Button, Rating } from '@mui/material'
import { useContext, useState } from 'react'
import { useStyles } from './styles'
import { GlobalContext } from '../../../../../../../../contexts/Global'
import { StarBorder } from '@mui/icons-material'
import { BaseInput } from '../../../../../../../../components'
import { onChange } from '../../../../../../../../utils/types'

const ApproveReason = (props: { title: string; button: string; sendReason: (data?: { rate: number | null; reason?: string }) => void | Promise<void> }) => {
  const { colors } = useContext(GlobalContext)
  const styles = useStyles({ color: colors })
  const [lowRatingReason, setLowRatingReason] = useState(false)
  const [value, setValue] = useState<{
    rate: number | null
    input: {
      reason: {
        value: string
        label: string
      }
    }
  }>({
    rate: 0,
    input: {
      reason: {
        value: '',
        label: '',
      },
    },
  })

  const onChange: onChange = ({ target }) => {
    const { name, value } = target

    setValue((prev) => ({
      ...prev,
      input: {
        [name as 'reason']: {
          ...prev.input[name as 'reason'],
          value,
        },
      },
    }))
  }

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>{props.title}</Typography>

      {!lowRatingReason ? (
        <Rating
          name="simple-controlled"
          value={value.rate}
          onChange={(_, newValue) => {
            setValue((prev) => ({
              ...prev,
              rate: newValue,
            }))
          }}
          emptyIcon={<StarBorder fontSize="inherit" sx={styles.emptyStar} />}
        />
      ) : (
        <BaseInput fullWidth sx={styles.fullWidth} name="reason" onChange={onChange} value={value.input} type="alphaNumeric" multiline rows={10} />
      )}

      <Button
        disabled={!value.rate || (!!lowRatingReason && value.input.reason?.value?.length < 3)}
        onClick={() => {
          if ((!!value.rate && value.rate >= 3) || !!lowRatingReason) {
            setValue((prev) => ({
              ...prev,
              rate: 0,
              input: {
                reason: {
                  ...prev.input.reason,
                  value: '',
                },
              },
            }))
            props.sendReason({ rate: value.rate, reason: value.input.reason.value })
          } else {
            setLowRatingReason(true)
          }
        }}
        variant="contained"
        sx={[styles.buttons, styles.buttonColorB]}
      >
        {props.button}
      </Button>
    </Box>
  )
}
export { ApproveReason }
