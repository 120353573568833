import { EvidencesTypeValueEnum } from "../../../../../../../utils/enums/evidences";

const defaultConfig = [
  {
    "evidence": EvidencesTypeValueEnum.IMAGE,
    "amount": 10,
    "size": 20,
    "type": [
      "png",
      "jpg",
      "jpeg"
    ],
    "isRequired": false
  },
  {
    customeTitle: "Video",
    "evidence": EvidencesTypeValueEnum.VIDEO_GALLERY,
    "amount": 10,
    "size": 20,
    "type": ['wma', 'wmv', 'mp4'],
    "isRequired": false
  },
  {
    "evidence": EvidencesTypeValueEnum.SIGN,
    "amount": 10,
    "isRequired": false
  },
  {
    "evidence": EvidencesTypeValueEnum.COMMENT,
    "amount": 10,
    size: 255,
    "isRequired": false
  },
  {
    "evidence": EvidencesTypeValueEnum.DOCUMENT,
    "amount": 10,
    "size": 5,
    "type": [
      "pdf",
      "csv",
      "docx",
      "xlsx",
      "pptx",
      "txt"
    ],
    "isRequired": false
  }
]

export { defaultConfig }