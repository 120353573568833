export const useStyles = (color: string, size?: 'small' | 'medium' | 'big') => {
  let chosenSize = {
    status: {
      height: '8px',
      width: '26px',
    },
    text: {
      fontSize: '16px',
    },
  }

  switch (size) {
    case 'small':
      chosenSize = {
        status: {
          height: '6px',
          width: '18px',
        },
        text: {
          fontSize: '14px',
        },
      }
      break
    case 'big':
      chosenSize = {
        status: {
          height: '9px',
          width: '30px',
        },
        text: {
          fontSize: '18px',
        },
      }
      break
    default:
      break
  }

  return {
    containerStatus: {
      display: 'flex',
      alignItems: 'center',
      gap: 1,
    },
    status: {
      height: chosenSize.status.height,
      width: chosenSize.status.width,
      background: color,
      borderRadius: '40px',
    },
    statusText: {
      color: color,
      fontSize: chosenSize.text.fontSize,
    },
  }
}
