import { Navigate, Route, Routes } from "react-router-dom"
import { UserScheduled } from "../pages/main"
import { UserScheduleDetail } from "../pages/detail"

const UserScheduleRoutes = () => {
  return (
    <Routes>
      {/* DASHBOARD */}
      <Route index element={<Navigate to="list" />} />
      <Route path="list" element={<UserScheduled />} />
      <Route path="list/create" element={<UserScheduleDetail />} />
      <Route path="list/:id" element={<UserScheduleDetail />} />
    </Routes>
  )
}

export { UserScheduleRoutes }
