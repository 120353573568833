import { CRUD } from '../../../../../../utils/enums'
import { InputModel } from '../../../../../../utils/types'

export const initialFilterValues = {
  status: '',
  search: '',
}

export const useFilterDetail = () => {
  return {
    search: {
      label: 'Buscar departamento',
      name: 'search',
    },
    status: {
      label: 'Estado',
      name: 'status',
    },
  }
}

export const initialValues = {
  limitFolio: {
    label: 'reportFolio.setting.limitFolio',
    translate: true,
    name: 'limitFolio',
    type: 'switch',
    value: false,
    title: 'Cantidad máxima de folios de reporte',
  },
  maxRejections: {
    label: 'general.tags.maxRejections',
    translate: true,
    name: 'maxRejections',
    type: 'switch',
    value: false,
    title: 'Cantidad máxima de rechazos',
  },
  enableSurvey: {
    label: 'reportFolio.setting.enableSurvey',
    translate: true,
    name: 'enableSurvey',
    type: 'check',
    value: false,
  },
} as { [x: string]: InputModel }

export const initialData = {
  name: null,
  email: null,
  phone: null,
}

export const initialTable = {
  column: [],
  row: [],
}

export const useMenuItems = (styles: any) => [
  {
    value: CRUD.EDIT,
    label: 'Editar departamento',
  },
  {
    value: CRUD.DELETE,
    label: 'Eliminar departamento',
  },
]

export const input = {
  areas: {
    label: 'general.tags.area',
    translate: true,
    name: 'area',
    type: 'filter-select',
    items: [],
    value: null,
  },
  deparmets: {
    label: 'general.tags.department',
    translate: true,
    name: 'users',
    type: 'filter-select',
    items: [],
    value: null,
  },
} as { [x: string]: InputModel }

export const accept = ['png', 'jpg', 'jpeg']
