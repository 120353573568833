import { Badge, Box, Button, Grid, IconButton, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { DynamicFormRef } from "../../../../components/dynamicFormRef"
import { ReportFolioProps } from "./types"
import { useReportFoliosStyles } from "./reportFolioStyles"
import { icons } from "../../../../utils/icons"
import { BaseMenu } from "../../../../components"
import { DrawerEvidences } from "../../../../hooks/evidences/DrawerEvidenceComponent"
import { DynamicFormRefProps } from "../../../../components/dynamicFormRef/types"
import { ButtonsDrawer } from "../../../../components/buttons/buttonDrawer"

const ReportFolio = (props: ReportFolioProps) => {
    const { t } = useTranslation()
    const styles = useReportFoliosStyles()
    const folioModelReport: DynamicFormRefProps['model'] = {
        maxFolios: {
            id: 'maxFolios',
            label: t('reportFolio.setting.limitFolio'),
            name: 'maxFolios',
            type: 'switch',
            inputOptions: {
                child: {
                    childKey: 'maxFoliosAmount'
                }
            },
            wrapper: (payload) => (
                <Grid
                    item
                    xs={8}
                    children={(
                        <Box>
                            {payload.children}

                            <Typography sx={styles.rejectError}>{payload?.childrenData?.error ?? ''}</Typography>
                        </Box>
                    )}
                    key={payload.id}
                />
            )
        },
        maxFoliosAmount: {
            id: 'maxFoliosAmount',
            label: t('general.tags.maxReject'),
            name: 'maxFoliosAmount',
            type: 'text',
            validations: { required: { value: true, message: t('general.validations.minFolios') }, number: { value: true }, min: { value: 1 } },
            hiddenError: true,
            inputOptions: {
                parent: {
                    parentKey: 'maxFolios',
                    hidden: true
                }
            },
            wrapper: (payload) => (
                <Grid
                    item
                    xs={4}
                    children={payload.children}
                    key={payload.id}
                />
            )
        },
    }
    const folioModel: DynamicFormRefProps['model'] = {
        maxRejects: {
            id: 'maxRejects',
            label: t('reportFolio.setting.maxRejects'),
            name: 'maxRejects',
            type: 'switch',
            inputOptions: {
                child: {
                    childKey: 'maxRejectsAmount'
                }
            },
            wrapper: (payload) => (
                <Grid
                    item
                    xs={8}
                    children={(
                        <Box>
                            {payload.children}

                            <Typography sx={styles.rejectError}>{payload?.childrenData?.error ?? ''}</Typography>
                        </Box>
                    )}
                    key={payload.id}
                />
            )
        },
        maxRejectsAmount: {
            id: 'maxRejectsAmount',
            label: t('general.tags.maxReject'),
            name: 'maxRejectsAmount',
            type: 'text',
            hiddenError: true,
            validations: { required: { value: true, message: t('general.validations.minFolios') }, number: { value: true }, min: { value: 1 } },
            inputOptions: {
                parent: {
                    parentKey: 'maxRejects',
                    hidden: true
                }
            },
            wrapper: (payload) => (
                <Grid
                    item
                    xs={4}
                    children={payload.children}
                    key={payload.id}
                />
            )
        },
        enableSurvey: {
            id: 'enableSurvey',
            label: t('reportFolio.setting.enableSurvey'),
            name: 'enableSurvey',
            type: 'check',
            switchOptions: {
                child: {
                    childKey: `enableSurveyRequired`
                }
            },
            wrapper: (payload) => (
                <Grid
                    item
                    xs={8}
                    children={payload.children}
                    key={payload.id}
                />
            )
        },
        enableSurveyRequired: {
            id: 'enableSurveyRequired',
            label: t(`general.validations.obligatory`),
            name: 'enableSurveyRequired',
            type: 'switch',
            switchOptions: {
                parent: {
                    parentKey: `enableSurvey`
                }
            },
            wrapper: (payload) => (
                <Grid
                    item
                    xs={4}
                    children={payload.children}
                    key={payload.id}
                />
            )
        },
        satisfactionQuestion: {
            id: 'satisfactionQuestion',
            label: t(`general.questions.customerSatisfactionQuestion`),
            name: 'satisfactionQuestion',
            type: 'text',
            validations: { required: { value: true, message: t('general.validations.satisfationQuestionIsRequired') } },
            multiline: true,
            rows: 4,
            maxRows: 4,
            inputOptions: {
                parent: {
                    parentKey: 'enableSurvey',
                    hidden: true
                }
            },
            wrapper: (payload) => (
                <Grid
                    item
                    xs={12}
                    children={payload.children}
                    key={payload.id}
                />
            )
        },
    }
    return (
        <>
            <Box sx={styles.container}>
                <Typography className="quest_name_title padding_title">{t('Questionarie.settingFolio')}</Typography>

                <Typography sx={styles.warning} className="padding_title">
                    <span>*</span>
                    {t('Questionarie.order.areaAndDepartment')}
                </Typography>

                <Box className="containerUserDetail">
                    <Typography sx={styles.fieldRequired} className="quest_name_title mb2">
                        {t('Questionarie.maxAmountFolios')}
                    </Typography>

                    <DynamicFormRef {...props.limitFoliosProps} model={props.limitFoliosProps.model ?? folioModelReport} />

                    <Typography sx={styles.fieldRequired} className="quest_name_title mb2">
                        {t('Questionarie.maxAmountRejects')}
                    </Typography>

                    <DynamicFormRef {...props.rejectsFolioProps} model={props.rejectsFolioProps.model ?? folioModel} />

                    <Box sx={styles.containerClose}>
                        <Box sx={[styles.containerBug, styles.evidencesType]}>
                            <Badge badgeContent={props.evidencesCount} sx={styles.badge()}>
                                <IconButton
                                    onClick={(e) => {
                                        if (props.openMenu) {
                                            props.openMenu({
                                                anchor: e as any,
                                                defaultValues: props?.evidenceFolio
                                            })
                                        }
                                    }}
                                    className="p3"
                                    sx={[styles.bug, styles.activeClip]}
                                >
                                    {icons('clip')}
                                </IconButton>
                            </Badge>

                            <Typography className="quest_name_title evidences-type-clip">{t('Questionarie.sections.evidenceTypes3')}</Typography>
                        </Box>

                        {!props?.buttonsDown && <Button onClick={() => { props.removeFolios() }}>{t('general.cancel')}</Button>}
                    </Box>

                    {!!props.buttonsDown && (
                        <ButtonsDrawer
                            accept={{
                                title: t('general.save'),
                                onClick: () => { if (props?.save) props?.save() }
                            }}
                            cancel={{
                                title: t('general.cancel'),
                                onClick: () => { props.removeFolios() }
                            }}
                        />
                    )}
                </Box>
            </Box>

            <BaseMenu {...props.baseMenuProps} sx={styles.border} />

            <DrawerEvidences {...props.drawerEvidencesProps} />
        </>
    )
}

export { ReportFolio }
