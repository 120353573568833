import { useContextState } from "../../../../hooks/theme"

const useReportFoliosStyles = () => {
    const { colors } = useContextState()
    return {
        container: {
            '& .MuiTypography-root': {
                '&.quest-name-header': {
                    fontSize: '16px',
                    color: colors.color33,
                    fontWeight: '500',
                },
                '&.quest_name_title': {
                    fontSize: '16px',
                    color: colors.color33,
                    fontWeight: '600',
                },
                '& .containerUserDetail': {
                  padding: '20px 30px',
                  display: 'flex',
                  flexWrap: 'wrap',
                  rowGap: '10px',
                },
            },
            '& .padding_title': {
              padding: '20px 30px 5px',
            },
            '& .mb2': {
              marginBottom: '8px'
            },
            '& .evidences-type-clip': {
              maxWidth: '200px',
            },
        },
        warning: {
            fontSize: '12px',
            paddingBottom: '40px',
            fontStyle: 'italic',
            color: colors.color19,
            marginTop: '-20px',
            '& span': { paddingRight: '5px' },
        },

        fieldRequired: {
            marginTop: '10px',
            width: '100%',
        },
        containerClose: {
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        containerBug: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            columnGap: '20px',
        },
        evidencesType: {
            '& .evidences-type-clip': {
                maxWidth: '200px',
            },
            wordBreak: 'break-word',
        },
        badge: (noLimit?: boolean) => ({
            margin: '10px 0px',
            '& .MuiBadge-badge': {
                background: colors.color20,
                color: colors.color25,
                ...noLimit && {
                    padding: '15px',
                    top: '-10px',
                    right: '-10px',
                    width: 'max-content',
                }
            },
        }),
        bug: {
            color: colors.color5,
            borderRadius: '5px',
            boxShadow: `1px 1px 2px ${colors.color10}`,
            '& .MuiSvgIcon-root': {
                fontSize: '1.7rem',
            },
            marginBottom: '2px',
        },
        activeClip: {
            background: colors.color30,
            '&:hover': {
                background: colors.color30 + 'dd',
            },
        },
        border: {
          '& li:not(:last-child)': {
            borderBottom: `1px solid ${colors.color13}`,
          },
        },
        rejectError: {
          fontSize: '11px',
          color: colors.color19,
        },
    }
}

export { useReportFoliosStyles }