export const tooltip = () => ({
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ ownerState }: any) => {
        return ownerState.remove
          ? {
              backgroundColor: 'transparent',
            }
          : {}
      },
    },
  },
})
