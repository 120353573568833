import { Navigate, Route, Routes } from 'react-router-dom'
import { NewConfigFolio } from '../pages/setting/pages/create'
import Setting from '../pages/setting/pages/main'
import { DashboardReportFolioRoutes } from '../pages/dashboard/routes'

const ReportFolio = () => {
  return (
    <Routes>
      {/* DASHBOARD */}
      <Route index element={<Navigate to="/dashboard" />} />
      <Route path='dashboard/*' element={<DashboardReportFolioRoutes/>}/>
      <Route path="settings" element={<Setting />} />
      <Route path="settings/create" element={<NewConfigFolio />} />
      <Route path="settings/edit/:id" element={<NewConfigFolio />} />
    </Routes>
  )
}

export default ReportFolio
