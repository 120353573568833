import { Box } from '@mui/material'
import { SxProps, Theme } from '@mui/system'
interface Props {
  src: string
  alt?: string
  sx?: SxProps<Theme>
  style?: React.CSSProperties
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
}

export const Images = ({ src, alt, sx, style, onClick }: Props) => {
  return (
    <Box sx={sx ?? { width: '100%', height: '100%' }} onClick={onClick}>
      <img src={src} style={{ width: 'inherit', height: 'inherit', ...style }} alt={alt} />
    </Box>
  )
}
