
import { LinearProgress, linearProgressClasses } from '@mui/material';
import { styled } from '@mui/material';

const BorderLinearProgress = styled(LinearProgress)((props:{customecolor?: string, customesecondcolor?: string}) => {
  return ({
    height: 15,
    width: '100%',
    borderRadius: '5px',
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#eee',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: props.customecolor ?? '#308fe8',
    },
  })
}
)
export { BorderLinearProgress }