// import { isValid, parse } from 'date-fns'
import { InputModel } from '../types'
import { ObjectGeneric } from '../types/generals'
// import { enGB } from 'date-fns/locale'

export const isRequired = (value: any) => !value

export const isEmailSeparateToCome = (value: string) => /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,})[\W]*$/.test(value)

export const isEmail = (value: string) => new RegExp("^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$").test(value)

export const isPassword = (value: string) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])[\da-zA-Z0-9]+$/.test(value)

export const min = (value: string | [], number: number) => value?.length < number

export const max = (value: string | [], number: number) => value.length > number

export const isNumber = (value: string) => (new RegExp('^(-?)[0-9]+$').test(value) || value === '') && value !== '-'

export const isChordenada = (value: string) => new RegExp('^[0-9-nNeEoOsSWw°."\']+$').test(value) || value === ''

export const isDecimal = (value: string) => {
  if (value?.length > 1 && value?.[0] === '0' && value?.[1] !== '.') {
    return false
  } else {
    let expression = new RegExp('^([-]?)(?!00)[0-9]+([.]?)[0-9]{0,2}$').test(value) || value === ''
    return expression
  }
}

export const isPhoneNumber = (value?: string) => {
  if (typeof value === 'string' && value) {
    if (value[0] === '+') return new RegExp('^[+]+[0-9]{12}$').test(value)
  }
  return false
}
export const isCharacter = (value: string, ext: string = '', contain: string = '') => new RegExp(`^(?=.*[a-zA-Z${contain}])[a-zA-Z ${ext}]+$`).test(value) || value === ''

export const isAlphaNumeric = (value: string, ext: string = '', contain: string = '') => (new RegExp(`^(?=.*[a-zA-Z${contain}'"¿¡()<>])[-_a-zA-Z${ext} .,'"¿?¡!:;+=()<>\n]+$`).test(value) || value === '') && value?.[0] !== ' '

export const isOnlyNumberAndCharacters = (value: string) => new RegExp('^[0-9a-zA-Z]+$').test(value)
export const searchInModeLower = (value: string | any[], valueToFind: string | number) => {
  const copyValueToFind =
    valueToFind
      ?.toString()
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') ?? ''
  const copyValue = value
    ?.toString()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
  return ['string', 'number']?.includes(typeof copyValue) ? copyValue?.includes(copyValueToFind) : value?.includes(copyValueToFind)
}

export const disabled = (values: { [x: string]: InputModel }, ignore?: string[]) => Object.entries(values).find(([key, value]) => !value?.value && !ignore?.includes(key)) !== undefined

export const disableInputModelArray = (value: InputModel[], ignore?: string[]) => value.find((item) => generalDisabled({ [item.name]: item }) && !ignore?.includes(item.name)) !== undefined

export const isValidateDate = (value?: string) => {
  const splitDate = value?.split('/')
  const day = splitDate?.[0]
  const month = splitDate?.[1]
  const yeard = splitDate?.[2]
  return !yeard || yeard?.length < 4 || parseInt(yeard) < 1000 || (month && (parseInt(month) > 12 || parseInt(month) < 1)) || (day && (parseInt(day) > 31 || parseInt(day) < 1)) || (month && parseInt(month) === 2 && day && parseInt(day) > 29)
}
export function calcularPorcentajes(objetoValores: Record<string, number>) {
  // Obtén los valores del objeto
  const valores = Object.values(objetoValores);

  // Calcula la suma de los valores
  const sumaTotal = valores.reduce((suma, valor) => suma + valor, 0);

  // Verifica si la suma total es cero y devuelve 0 en ese caso
  if (sumaTotal === 0) {
    const resultadosCero: Record<string, number> = {};
    Object.keys(objetoValores).forEach(key => {
      resultadosCero[key] = 0;
    });
    return resultadosCero;
  }

  // Calcula los porcentajes y redondea cada valor
  const porcentajesRedondeados = valores.map(valor => Math.round((valor / sumaTotal) * 100));

  // Ajusta el último valor para asegurarse de que la suma sea igual al 100%
  const ajuste = 100 - porcentajesRedondeados.reduce((suma, valor) => suma + valor, 0);
  porcentajesRedondeados[porcentajesRedondeados.length - 1] += ajuste;

  // Crea un objeto donde las claves son las mismas que el objeto original y los valores son los porcentajes redondeados
  const resultados: Record<string, number> = {};
  Object.keys(objetoValores).forEach((key, index) => {
    resultados[key] = porcentajesRedondeados[index];
  });

  return resultados;
}

export const percentage = ({ a, b }: { a: number; b: number }) => !!b ? Math.round((a / b) * 100).toString() : '0'

export const generalDisabled = (value: { [x: string]: InputModel }, data?: ObjectGeneric) => {
  const findError = Object.values(value).find((item) => {
    let newValue = data?.[item.name] ?? item.value
    if (item?.validate?.phone && !newValue?.includes('+52') && newValue?.length == 10) {
      newValue = `+52${newValue}`
    }
    
    return (
      (item?.validate?.required && (!newValue && newValue != 0)) ||
      (item?.validate?.min && min(newValue, item?.rest?.min)) ||
      (item?.validate?.number && !(isNumber(newValue) && newValue !== '-')) ||
      (item?.validate?.decimal && !(isDecimal(newValue) && newValue !== '-')) ||
      (item?.validate?.password && (!isPassword(newValue) || min(data?.[item.name] ?? item.value, 8) || max(data?.[item.name] ?? item.value, 10))) ||
      (item?.validate?.email && !isEmail(newValue) && newValue !== '' && newValue !== null) ||
      (item?.validate?.phone && !isPhoneNumber(newValue) && (item?.validate?.required || newValue?.length > 3)) ||
      (item.name === 'email' && !item?.validate?.email && newValue && !isEmail(newValue))
    )
  })
  return !!findError
}
