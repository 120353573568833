import { Box, Button, IconButton, Typography } from '@mui/material'
import { Images } from '../image'
import { useStyles } from './styles'
import { useContextState } from '../../hooks/theme'
import DetailKeyValue from '../detailKeyValue'
import StatusComponent from '../status'
import { useState } from 'react'
import { icons } from '../../utils/icons'
import BaseMenu from '../baseMenu'
import { LoadingButton } from '@mui/lab'
import { InformativeCardProps } from '../../utils/types/informationCardProps'
import { images } from '../../utils/images'

const Buttons = ({ styles, accept, cancel, auxiliar, auxiliarRight }: any) => {
  if (accept || cancel)
    return (
      <>
        <Box sx={styles.buttonContainer}>
          {accept && (
            <LoadingButton loading={accept?.loading} sx={styles.buttonContained} variant={accept.type ?? 'contained'} onClick={() => accept?.onClick?.()} disabled={accept?.disabled}>
              {accept?.title}
            </LoadingButton>
          )}

          {auxiliar && (
            <Button sx={{ ...styles.buttonContained, ...(auxiliarRight && { width: '154px', textAlign: 'left' }) }} className="auxiliar-button" variant={auxiliar.type ?? 'text'} color="secondary" onClick={() => auxiliar?.onClick?.()}>
              {auxiliar?.title}
            </Button>
          )}

          {auxiliarRight && (
            <Button sx={{ ...styles.buttonOutlined, width: '90px', textAlign: 'left' }} className="auxiliar-button" variant={auxiliarRight.type ?? 'text'} color="secondary" onClick={() => auxiliarRight?.onClick?.()}>
              {auxiliarRight?.title}
            </Button>
          )}

          {cancel && !auxiliarRight && (
            <Button sx={styles.buttonOutlined} variant={cancel.type ?? 'text'} color="secondary" onClick={() => cancel?.onClick?.()}>
              {cancel?.title}
            </Button>
          )}
        </Box>

        {auxiliarRight && (
          <Box sx={{ ...styles.buttonContainer, padding: '15px 0 30px 0' }}>
            {cancel && (
              <Button sx={styles.buttonOutlined} variant={cancel.type ?? 'text'} color="secondary" onClick={() => cancel?.onClick?.()}>
                {cancel?.title}
              </Button>
            )}
          </Box>
        )}
      </>
    )
  else return null
}

const InformationCard = ({ data, id, status, image, crud, form, accept, cancel, auxiliar, auxiliarRight, title, menuItems, click, customFields, showImage, handleSee, changeEmail, autoHeight, paddingBottom, addRef }: InformativeCardProps) => {
  const { colors } = useContextState()
  const styles = useStyles(colors)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      <Box sx={{ ...styles.container, height: autoHeight ? 'auto' : '100%', paddingBottom: paddingBottom ? paddingBottom : 'unset' }}>
        {title && <Box sx={styles.header}>{<Typography sx={styles.title}>{crud && title}</Typography>}</Box>}
        {crud ? (
          <>
            <Box sx={styles.body}>
              <Box sx={styles.bodyContent}>{form}</Box>
            </Box>
          </>
        ) : (
          <>
            <Box sx={styles.body}>
              <Box sx={styles.infContainer}>
                {showImage && (
                  <Box sx={styles.imgContainer}>
                    <Images sx={styles.img} src={image ?? images.profileBtn} />
                  </Box>
                )}
                {status && <StatusComponent color={status.color} msg={status?.text ?? ''} />}

                {/* User detail */}
                {data?.map((item, index) => (
                  <DetailKeyValue
                    {...addRef && { ref: (element) => addRef({ element, name: item.name ?? '' }) }}
                    key={index}
                    value={item?.value}
                    caption={item?.label}
                    copy={item.copy}
                    edit={item.edit}
                    id={id?.toString()}
                    name={item.name ?? ''}
                    changeEmail={changeEmail}
                    see={item.see}
                    handleSee={handleSee}
                  />
                ))}
                {customFields ?? null}
              </Box>
            </Box>
          </>
        )}

        <Buttons styles={styles} auxiliar={auxiliar} auxiliarRight={auxiliarRight} accept={accept} cancel={cancel} />
      </Box>

      {!crud && menuItems && (
        <>
          <IconButton sx={styles.menu} onClick={handleClick}>
            {icons('menu')}
          </IconButton>
          <BaseMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} menuItems={menuItems} click={click} />
        </>
      )}
    </>
  )
}

export default InformationCard
