import { GridColDef } from '@mui/x-data-grid'
import { useCallback, useEffect, useRef, useState } from 'react'
import { dashboardService } from '../../../../utils/constants/callService'
import { useTranslation } from 'react-i18next'
import { FilterData, ActiveUnitsRowsProperties } from './typing/interfaces'
import { useFilter } from '../../../../hooks/filter'
import { InputModel } from '../../../../utils/types'
import {
  getArea,
  getDepartments,
  getQuestionnaires,
  getStore,
} from '../../../../utils/methods/geServiceWithItemsFormat'
import { Item } from '../../../../utils/types/generals'
import { FilterProps } from '../../../../hooks/filter/typing'
import { removeAllReference } from '../../../../utils/methods/removedReference'
import { getStatus } from '../../../../hooks/status'
import { useContextState } from '../../../../hooks/theme'
import { columsTranslate, useModelInput, statusList } from './constants'
import { UnitDataFilters, formatDataFiltered, formatResponsUnitData } from './methods'
import { TableData } from './typing/type'
import { useQuery } from '../../../../hooks/useQuery'
import { Constants } from '../../../../utils/constants'
import { endOfWeek, parse, startOfWeek } from 'date-fns'
import { currentWeek } from '../../../dashboard/pages/main/fragments/methods/calcDates'
import { intervaleList } from '../../../../utils/constants/intervaleList'
import { Status } from '../../../../utils/enums'

const defaultDates = {
  from: currentWeek().start.getTime(),
  to: currentWeek().end.getTime(),
}

const useBody = () => {
  const { t } = useTranslation()
  const { colors } = useContextState()
  const queryParams = useQuery()
  const [flat, setFlat] = useState(false)
  const refTime = useRef<NodeJS.Timeout | null>(null)
  const [inputsWithLegacy, setINputsWithLegacy] = useState<{
    childs: Item[]
    departments: Item[]
  }>({ childs: [], departments: [] })
  const externalValidation: FilterProps['externalValidation'] = ({
    name,
    value,
    body,
    setFilterData,
  }) => {
    let copyBody: Record<string, any> = removeAllReference(body)

   if (name === 'area') {
      const { area, department } = copyBody
      const newDepartments = department?.filter((e: any) =>
        area.find((e2: any) => e2.value == e.areaId)
      )
      let arrayChilds: any[] = []

      area?.forEach((e: any) => {
        const id = e.value
        const childs = inputsWithLegacy.departments.filter(
          (child) => child.areaId == id
        )
        arrayChilds.push(...childs)
      })

      setInput((prev) => ({
        ...prev,
        department: { ...prev.department, items: arrayChilds },
      }))

      setFilterData((prev) => ({
        ...prev,
        department: newDepartments,
      }))

      copyBody = {
        ...copyBody,
        department: newDepartments,
      }
    } else if(name == 'intervale' && !value) {
      copyBody['intervale'] = intervaleList[0].value
      setFilterData((prev) => ({
        ...prev,
        intervale: intervaleList[0].value,
      }))
    }
    return copyBody
  }
  const { filterData, query, counter, errors, cleanFilter, onChange } =
    useFilter({
      formatFilter: {},
      externalValidation,
      defaultValues: defaultDates,
    })
  const modelInput = useModelInput()
  const [loading, setLoading] = useState(false)
  const [input, setInput] = useState<{ [x: string]: InputModel }>(modelInput)
  const [row, setRow] = useState<ActiveUnitsRowsProperties[]>([])
  const columns: GridColDef[] = columsTranslate({ colors, t, intervale: filterData?.intervale?.value ?? '1d' })

  const cleanFIlters = () => {
    cleanFilter()
  }

  const tableData = async (payload?: TableData) => {
    setLoading(true)
    const { filters } = UnitDataFilters(payload)
    
    const body = {
      size: 10000,
      query: {
        bool: {
          filter: filters,
        },
      },
    }
    const response = await dashboardService[payload?.intervale == '1w' ? 'areaWeekHistory' : `areaHistory`]({ body })
    const { hits } = formatResponsUnitData(response, colors, t, payload?.intervale)

    setRow(hits)
    setLoading(false)
  }

  const getAllQuestionnaires = async () => {
    const { items } = await getQuestionnaires()
    setInput((prev) => ({
      ...prev,
      questionnaire: { ...prev.questionnaire, items: items },
    }))
  }
  const getItemStatus = async () => {
    const items = statusList.map((item) => {
      const newStatus = getStatus(item, colors, t)
      return {
        label: item == Status.WAITING ? 'En progreso' : newStatus.text,
        value: item,
        id: item.toString(),
      }
    })
    setInput((prev) => ({
      ...prev,
      questionnaireStatus: {
        ...prev.questionnaireStatus,
        items: items,
      },
    }))
  }

  const getAllAreas = async () => {
    const respons = await getArea()
    setInput((prev) => ({ ...prev, area: { ...prev.area, items: respons } }))
  }
  const getAllUnits = async () => {
    const respons = await getStore()
    setInput((prev) => ({ ...prev, unidad_id: { ...prev.unidad_id, items: respons.items } }))
  }

  const getAllDepartments = async () => {
    const respons = await getDepartments()
    setINputsWithLegacy((prev) => ({ ...prev, departments: respons }))

    let areas: string = queryParams?.get("areas") as string
    let arrayChilds: any[] = []
    if (areas) {
      areas.split(",")?.forEach((e: string) => {
        const id = e
        const childs = respons.filter(
          (child) => child.areaId == id
        )
        arrayChilds.push(...childs)
      })
      
      setInput((prev) => ({
        ...prev,
        department: { ...prev.department, items: arrayChilds },
      }))
    }
  }

  const refreshTable = useCallback(() => {
    if (refTime.current) {
      clearTimeout(refTime.current)
    }

    refTime.current = setTimeout(() => {
      const { data } = formatDataFiltered({ filterData: filterData as FilterData })

      tableData(data)
    }, 300)
  }, [filterData])

  useEffect(() => {
    if (!!flat) {
      refreshTable()
    }
    //eslint-disable-next-line
  }, [query.all, refreshTable])
  
  const formatQueryParams = () => {
    let start: string | Date | number = queryParams?.get("from") as string
    let end: string | Date | number = queryParams?.get("to") as string
    let intervale: string = queryParams?.get("intervale") as string ?? intervaleList[0].value
    let areas: (Item)[] | string = queryParams?.get("areas") as string
    let departments: (Item)[] | string = queryParams?.get("departments") as string

    if (start) { start = (parse(start, Constants.dateFormat, new Date())) }
    else { start = defaultDates.from }
    if (end) { end = (parse(end, Constants.dateFormat, new Date())).setHours(23, 59, 59, 0) }
    else { end = defaultDates.to }
    if (areas) { areas = areas.split(",").map(item => ({ label: "", value: item, id: item })) }
    if (departments) { departments = departments.split(",").map(item => ({ label: "", value: item, id: item })) }

    let newStart: number | Date = start
    let newEnd: number | Date = end
    if (!!intervale) { 
      onChange({ target: { name: "intervale", value: intervaleList.find(item => item.value == intervale) } }) 

      if(intervale == '1w') {
        newStart = startOfWeek(new Date(newStart), { weekStartsOn: 1 })
        newEnd = endOfWeek(new Date(newEnd), { weekStartsOn: 1 })
      }
    }
    
    onChange({ target: { name: "to", value: newEnd } })
    onChange({ target: { name: "from", value:newStart } })
    if (areas?.length) { onChange({ target: { name: "area", value: areas } }) }
    if (departments?.length) { onChange({ target: { name: "department", value: departments } }) }
  }
  const getData = useCallback(() => {
    if (!flat) {
      formatQueryParams()
      getItemStatus()
      getAllAreas()
      getAllUnits()
      getAllDepartments()
      getAllQuestionnaires()
      setFlat(true)
    }
  }, [flat])

  useEffect(() => {
    getData()
  }, [getData])
  return {
    loading,
    columns,
    row,
    filterData,
    counter,
    input,
    errors,
    cleanFIlters,
    onChange,
    refreshTable,
  }
}

export { useBody }
