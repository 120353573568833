import { TFunction } from "react-i18next";
import { ObjectGeneric } from "../../../../../utils/types/generals";

export const handleErrors = (data: ObjectGeneric, t:TFunction<"translation", undefined>) => {
    let error: ObjectGeneric = {}

    if(data?.digitalFenceMts !== null && !data?.digitalFenceMts) {
        error.digitalFenceMts = t('general.validations.required')
    }
    
    if(typeof data?.locale !== 'string' && data?.locale !== null && data?.locale?.type !== 'application/json') {
        error.locale = t('general.validations.wrongFile')
    }
    
    return error
}