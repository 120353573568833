import { MouseEvent, useCallback, useEffect, useState } from "react";
import { Evidence } from "../../service/reportFolio/response";
import { EvidencesTypeEnum, EvidencesTypeValueEnum } from "../../utils/enums/evidences";
import { ItemMenu } from "../../components/baseMenu";
import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { DynamicFormRefProps, DynamicFormRefValues, DynamicRefRef } from "../../components/dynamicFormRef/types";
import { Item } from "../../utils/types/generals";
import { useRefs } from "../useReactHookModified";
import { EvidenceData, EvidenceDataHook } from "./typyng";
import { removeAllReference } from "../../utils/methods/removedReference";


const useEvidences = (data: { evidencesResponse?: Evidence[] }) => {
  const [evidences, setEvidences] = useState<{
    [x: string]: { type: EvidencesTypeValueEnum; src: string; name?: string }[]
  }>({})
  const [pagination, setPagination] = useState({ image: 0, video: 0 })
  const [existEvidences, setExistEvidences] = useState(false)
  const handleChangePage = ({ page, type }: { page: number; type: 'image' | 'video' }) => {
    setPagination((prev) => ({
      ...prev,
      [type]: page - 1,
    }))
  }

  const separateEvidences = useCallback(() => {
    setPagination(prev => ({
      ...prev,
      image: 0, video: 0
    }))
    interface TypeEvidence {
      type: EvidencesTypeValueEnum
      src: string
      name?: string
    }
    let images: TypeEvidence[] = [],
      video: TypeEvidence[] = [],
      documents: TypeEvidence[] = [],
      comments: TypeEvidence[] = []

    if (!!data?.evidencesResponse?.length) {
      const newEvidiences = structuredClone(data?.evidencesResponse ?? [])
      newEvidiences?.forEach((itemEvidences) => {
        if (!!itemEvidences?.evidenceTypeId) {
          /* IMAGES */
          if ([EvidencesTypeValueEnum.CAMERA, EvidencesTypeValueEnum.IMAGE, EvidencesTypeValueEnum.SIGN].some(itemImg => itemImg == itemEvidences?.evidenceTypeId)) {
            images = [...images, { src: itemEvidences?.url ?? '', type: itemEvidences?.evidenceTypeId }]
          } else if ([EvidencesTypeValueEnum.VIDEO, EvidencesTypeValueEnum.VIDEO_GALLERY].some(itemVid => itemVid == itemEvidences?.evidenceTypeId)) {
            /* VIDEOS */
            video = [...video, { src: itemEvidences?.url ?? '', type: itemEvidences?.evidenceTypeId }]
          } else if ([EvidencesTypeValueEnum.FILE, EvidencesTypeValueEnum.DOCUMENT].some(itemFIle => itemFIle == itemEvidences?.evidenceTypeId)) {
            /* DOCUMENTS */
            documents = [...documents, { src: itemEvidences?.url ?? '', type: itemEvidences?.evidenceTypeId, name: itemEvidences?.originalname }]
          }
        } else {
          if (!!itemEvidences?.comments?.length) {
            comments = itemEvidences?.comments?.map((itemComments) => ({ type: EvidencesTypeValueEnum.COMMENT, src: '', name: itemComments }))
          }
        }
      })

      setExistEvidences(() => !!images?.length || !!video?.length || !!documents?.length || !!comments?.length)
      setEvidences(() => ({
        images: images?.sort((a) => (a.type === 3 ? -1 : 1)),
        video,
        documents,
        comments,
      }))
    }
  }, [data?.evidencesResponse])

  useEffect(() => {
    separateEvidences()
  }, [separateEvidences])
  return { evidences, pagination, existEvidences, handleChangePage }
}

const arraySizeFive = [
  { label: '1 Mb', value: 1, id: '1' },
  { label: '2 Mb', value: 2, id: '2' },
  { label: '3 Mb', value: 3, id: '3' },
  { label: '4 Mb', value: 4, id: '4' },
  { label: '5 Mb', value: 5, id: '5' },
]
const arrayBig = [
  { label: '10 Mb', value: 10, id: '10', },
  { label: '20 Mb', value: 20, id: '20' },
  { label: '30 Mb', value: 30, id: '30' },
  { label: '40 Mb', value: 40, id: '40' },
]
const arrayAllSizeImg = [
  ...arraySizeFive,
  { label: '6 Mb', value: 6, id: '6' },
  { label: '7 Mb', value: 7, id: '7' },
  { label: '8 Mb', value: 8, id: '8' },
  { label: '9 Mb', value: 9, id: '9' },
  { label: '10 Mb', value: 10, id: '10' },
  { label: '15 Mb', value: 15, id: '15' },
  { label: '20 Mb', value: 20, id: '20' },
]

const arrayImgCheck = [{ name: 'png' }, { name: 'jpg' }, { name: 'jpeg' }]
const arrayAudioCheck = [{ name: 'mp4' }, { name: 'wmv' }, { name: 'wma' }]
const arrayFileCheck = [
  { name: 'pdf' },
  { name: 'csv' },
  { name: 'docx' },
  { name: 'xlsx' },
  { name: 'pptx' },
  { name: 'txt' },
]
const evidencesList = [
  {
    name: EvidencesTypeEnum.IMAGE,
    amounts: 3,
    checks: arrayImgCheck,
    inputs: { maxSize: { value: true, size: arrayAllSizeImg }, maxAmount: { value: true } }
  },
  {
    name: EvidencesTypeEnum.CAMERA,
    amounts: 3,
    checks: arrayImgCheck,
    inputs: { maxSize: { value: true, size: arrayAllSizeImg }, maxAmount: { value: true } }
  },
  {
    name: EvidencesTypeEnum.VIDEO,
    inputs: {
      maxSize: {
        value: true, size: arrayBig
      }, maxAmount: { value: true }
    }
  },
  {
    name: EvidencesTypeEnum.VIDEO_GALLERY,
    amounts: 3,
    checks: arrayAudioCheck,
    inputs: {
      maxSize: {
        value: true, size: arrayBig
      }, maxAmount: { value: true }
    }
  },
  {
    name: EvidencesTypeEnum.SIGN,
    inputs: {
      maxAmount: { value: true }
    }
  },
  {
    name: EvidencesTypeEnum.COMMENT,
    inputs: {
      maxCharacters: { value: true },
      maxAmount: { value: true }
    }
  },
  {
    name: EvidencesTypeEnum.FILE,
    amounts: 4,
    checks: arrayFileCheck,
    inputs: {
      maxSize: {
        value: true, size: arraySizeFive
      }, maxAmount: { value: true }
    }
  },
]

const evidenceDefaultValues = ({ active }: { active: boolean }): Record<EvidencesTypeEnum, DynamicFormRefValues> => {

  return {
    image: {
      active: !!active,
      required: !!active,
      png: !!active,
      jpg: !!active,
      jpeg: !!active,
      maxSize: !!active ? arrayAllSizeImg[0] : null,
      maxAmount: active ? 1 : ''
    },
    camera: {
      active: !!active,
      required: !!active,
      png: !!active,
      jpg: !!active,
      jpeg: !!active,
      maxSize: !!active ? arrayAllSizeImg[0] : null,
      maxAmount: active ? 1 : ''
    },
    video: {
      active: !!active,
      required: !!active,
      maxSize: !!active ? arrayBig[0] : null,
      maxAmount: active ? 1 : ''
    },
    video_gallery: {
      active: !!active,
      required: !!active,
      mp4: !!active,
      wmv: !!active,
      wma: !!active,
      maxSize: !!active ? arrayBig[0] : null,
      maxAmount: active ? 1 : ''
    },
    sign: {
      active: !!active,
      required: !!active,
      maxAmount: active ? 1 : ''
    },
    comment: {
      active: !!active,
      required: !!active,
      maxCharacters: active ? 255 : '',
      maxAmount: active ? 1 : ''
    },
    file: {
      active: !!active,
      required: !!active,
      pdf: !!active,
      csv: !!active,
      docx: !!active,
      xlsx: !!active,
      pptx: !!active,
      txt: !!active,
      maxSize: !!active ? arraySizeFive[0] : null,
      maxAmount: active ? 1 : ''
    },
  }
}

const useDrawerInputs = () => {
  const { t } = useTranslation()
  const [model, setModel] = useState<Record<string, Record<string, DynamicFormRefProps['model']>>>({} as any)

  const inputs = (payload: { id: string; maxSize?: { value: boolean; sizes: Item[] }; maxAmount?: { value: boolean; }; maxCharacters?: { value: boolean; } }): DynamicFormRefProps['model'] => {
    let newModel: DynamicFormRefProps['model'] = {}

    if (payload?.maxSize?.value) {
      newModel = {
        ...newModel,
        maxSize: {
          id: `maxSize-${payload.id}`,
          label: t(`general.amount.maxSize`),
          name: 'maxSize',
          type: 'selectSimple',
          items: payload?.maxSize?.sizes,
          validations: { required: { value: true } },
          wrapper: (payload) => (
            <Grid
              item
              xs={6}
              children={payload.children}
              key={payload.id}
            />
          )
        }
      }
    }

    if (payload?.maxCharacters?.value) {
      newModel = {
        ...newModel,
        maxCharacters: {
          id: `maxCharacters-${payload.id}`,
          label: t(`general.amount.comment`),
          name: 'maxCharacters',
          type: 'text',
          validations: { required: { value: true }, min: { value: 1 }, number: { value: true } },
          wrapper: (payload) => (
            <Grid
              item
              xs={6}
              children={payload.children}
              key={payload.id}
            />
          )
        }
      }
    }


    if (payload?.maxAmount?.value) {
      newModel = {
        ...newModel,
        maxAmount: {
          id: `maxAmount-${payload.id}`,
          label: t(`general.amount.maxAmount`),
          name: 'maxAmount',
          type: 'text',
          validations: { required: { value: true }, min: { value: 1 }, number: { value: true } },
          wrapper: (payload) => (
            <Grid
              item
              xs={6}
              children={payload.children}
              key={payload.id}
            />
          )
        }
      }
    }

    return newModel
  }

  const checks = (payload: { values: { name: string }[]; amounts: number }): DynamicFormRefProps['model'] => {
    const size = 12 / payload.amounts
    let newModel: DynamicFormRefProps['model'] = {}

    for (const item of payload.values) {
      newModel = {
        ...newModel,
        [item.name]: {
          id: item.name,
          label: t(`general.formats.${item.name}`),
          name: item.name,
          type: 'check',
          wrapper: (payload) => (
            <Grid
              item
              xs={size}
              children={payload.children}
              key={payload.id}
            />
          )
        }
      }
    }

    return newModel
  }

  useEffect(() => {
    let newModel: Record<string, Record<string, DynamicFormRefProps['model']>> = {}

    for (const item of evidencesList) {
      newModel = {
        ...newModel,
        [item.name]: {
          header: {
            active: {
              id: 'active',
              label: t(`general.evidences.${item.name}`),
              name: 'active',
              type: 'switch',
              wrapper: (payload) => (
                <Grid
                  item
                  xs={7}
                  children={payload.children}
                  key={payload.id}
                />
              )
            },
            required: {
              id: 'required',
              switchOptions: {
                labelLeft: t(`general.required`)
              },
              label: '',
              name: 'required',
              type: 'radio',
              wrapper: (payload) => (
                <Grid
                  item

                  xs={5}
                  children={<Box sx={{ display: 'flex', justifyContent: "end" }} children={payload.children} />}
                  key={payload.id}
                />
              )
            }
          },
          check: {
            ...item.checks ? checks({ values: item.checks, amounts: item.amounts }) : {},
          },
          input: {
            ...inputs({ id: item.name, maxAmount: item.inputs.maxAmount, maxSize: item.inputs?.maxSize ? { value: !!item.inputs?.maxSize?.value, sizes: item.inputs?.maxSize?.size ?? [] } : undefined, maxCharacters: item.inputs.maxCharacters }),
          }
        }
      }
    }

    setModel(newModel)
  }, [])
  return { model }
}

const useEvidencesType = (payload: { defaultValues?: EvidenceData }): EvidenceDataHook => {
  const { refsByKey, setRef } = useRefs<DynamicRefRef>()
  const styles = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [evidencesCount, setEvidencesCount] = useState(Object.values(payload.defaultValues ?? {})?.filter(item => !!item?.active)?.length)
  const [menu, setMenu] = useState<ItemMenu[]>([])
  const [openEvidenceDrawer, setOpenEvidenceDrawer] = useState(false)
  const [evidencesData, setEvidencesData] = useState<EvidenceData | undefined>(removeAllReference(payload.defaultValues ?? {}))
  const [tmpEvidence, setTmpEvidence] = useState<string | null>(null)
  const { t } = useTranslation()
  const clickOption = ({ item }: { item: ItemMenu }) => {
    if (!evidencesData?.[item.value]) {
      setTmpEvidence(item?.value)
      const defaultValues = evidenceDefaultValues({ active: true })

      setEvidencesData(prev => ({
        ...prev,
        [item.value ?? ""]: defaultValues?.[item?.value as EvidencesTypeEnum]
      }))
    }
    setOpenEvidenceDrawer(true)
  }

  const saveEvidence = (data: EvidenceData | undefined, getCount?: (count: number) => void) => {
    const count = Object.values(data ?? {})?.filter(item => !!item?.active)?.length
    if (getCount) {
      getCount(count)
    }
    setEvidencesCount(count)
    setTmpEvidence(null)
    setEvidencesData(data)
    setOpenEvidenceDrawer(false)
    // cleanAll()
  }

  const cancelEvidence = () => {
    if (!!tmpEvidence) {
      delete evidencesData?.[tmpEvidence]
    }

    setOpenEvidenceDrawer(false)
    setTmpEvidence(null)
  }

  const closeMenu = () => {
    setAnchorEl(null)
    setMenu([])
  }

  const openMenu = ({ anchor, defaultValues }: { anchor: MouseEvent<HTMLButtonElement>; values?: Record<EvidencesTypeEnum, boolean>; defaultValues?: EvidenceData }) => {
    const localeValue = defaultValues
    setEvidencesData(localeValue)
    setEvidencesCount(Object.values(localeValue ?? {})?.filter(item => !!item?.active)?.length)

    const newMenu = Object.entries({
      camera: false,
      comment: false,
      file: false,
      image: false,
      sign: false,
      video: false,
      video_gallery: false
    })?.map(([key]) => ({
      value: key,
      children: (
        <FormControlLabel
          sx={styles.label}
          control={<Checkbox sx={styles.checkbox} name={key} checked={!!localeValue?.[key]?.active} defaultChecked />}
          label={t(`general.evidences.${key}`)}
        />),
    }))

    setMenu(newMenu)
    setAnchorEl(anchor.currentTarget)
  }
  const cleanAll = () => {
    setEvidencesData(undefined)
    setEvidencesCount(0)
    setTmpEvidence(null)
  }
  useEffect(() => {
    if (payload.defaultValues) {
      setEvidencesData(payload.defaultValues)
      setEvidencesCount(Object.values(payload.defaultValues ?? {})?.filter(item => !!item?.active)?.length)
    } else {
      setEvidencesData({})
      setEvidencesCount(0)
    }
  }, [payload.defaultValues])
  return {
    anchorEl,
    menu,
    openEvidenceDrawer,
    evidencesData,
    refsByKey,
    evidencesCount,
    cleanAll,
    setRef,
    closeMenu,
    saveEvidence,
    cancelEvidence,
    clickOption,
    openMenu
  }
}

export { useEvidences, useEvidencesType, useDrawerInputs, evidenceDefaultValues }
