import { Color } from "../../../utils/theme/types/color"


export const useStyles = (color: Color) => {
  return {
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // height: '100%',
      width: '100%',
      backgroundImage: `linear-gradient(${color.color1}, ${color.primary}, ${color.color2})`,
      height: '100vh',
      overflowY: 'auto'
    },
  }
}
