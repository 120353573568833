import { dashboardService } from '../../../../../../../utils/constants/callService'
import { percentage } from '../../../../../../../utils/validate/validate'
import { Item } from '../../../../../../../utils/types/generals'
import { format } from 'date-fns'
import { Constants } from '../../../../../../../utils/constants'

const divisions = (payload: { units: string[]; divisions: Item[]; divisions_id: string[]; zones_id?: string[]; class_id?: string[] }) => ({
  divisonsByUnits: async () => {
    try {
      let filter: {
        terms: Record<string, string[]>
      }[] = [
        {
          terms: {
            unidad_id: payload.units ?? [],
          },
        },
        {
          terms: {
            division_id: payload.divisions_id ?? [],
          },
        },
      ]

      if (!!payload.zones_id?.length) {
        filter.push({
          terms: {
            zona_id: payload.zones_id ?? [],
          },
        })
      }
      if (!!payload.class_id?.length) {
        filter.push({
          terms: {
            clase_id: payload.class_id ?? [],
          },
        })
      }
      const body = {
        size: 1,
        query: {
          bool: {
            filter,
          },
        },
        aggs: {
          divisiones: {
            terms: {
              field: 'division_id',
            },
            aggs: {
              total_unidades: {
                sum: {
                  field: 'total',
                },
              },
              total_programados: {
                sum: {
                  field: 'programados',
                },
              },
            },
          },
        },
      }

      const response = await dashboardService.activeUnits({ body })
      const aggregations = response.aggregations.divisiones.buckets
      const updateDate = response?.hits?.hits?.[0]?._source?.fecha ? format(new Date(response.hits.hits[0]._source.fecha), Constants.dateFormatGeneral) : ''
      const data = aggregations.map((item) => {
        const unitTotals = item.doc_count
        const unitProgrammed = item.total_programados.value
        const noProgrammed = unitTotals - unitProgrammed
        return {
          name: payload?.divisions.find((itemDivision) => itemDivision.value == item.key)?.label ?? 'SIN DIVISIÓN',
          customeLabels: {
            percentageProgrammed: `${unitProgrammed} (${percentage({
              a: unitProgrammed,
              b: unitTotals,
            })}%)`,
            percentageNoProgrammed: `${noProgrammed} (${percentage({
              a: noProgrammed,
              b: unitTotals,
            })}%)`,
          },
          programmed: unitProgrammed,
          noProgrammed: noProgrammed,
          percentageProgrammed: percentage({
            a: unitProgrammed,
            b: unitTotals,
          }),
          percentageNoProgrammed: percentage({
            a: noProgrammed,
            b: unitTotals,
          }),
        }
      })

      return await { data, updateDate }
    } catch (e) {
      return { data: [], updateDate: '' }
    }
  },
})

export { divisions }
