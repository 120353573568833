import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { SnackSimple } from '../../../../components'
import { useContextState } from '../../../../hooks/theme'
import { statusService } from '../../../../service'
import { CRUD, Field, Status, Types } from '../../../../utils/enums'
import { InformativeCard, InputModel } from '../../../../utils/types'
import {
  Item,
  onChangeEvent,
  Table,
} from '../../../../utils/types/generals'
import {
  formData,
  formatFilter,
  initialTable,
  initialValues,
  input,
  useMenuItems,
} from './initialState'
import { getUserList, handleErrors, submit } from './methods'
import { useStyles } from './style'
import Form from './components/form'
import { sharedAws } from '../../../../service/bucketS3/Storage'
import {
  generalDisabled,
} from '../../../../utils/validate/validate'
import General from '../../../../components/modules/general'
import { icons } from '../../../../utils/icons'
import { FiltersDrawer } from '../../../../components/filtersDrawer'
import { Box, IconButton, Typography } from '@mui/material'
import { DinamicForm } from '../../../../components/dinamicForm'
import { getPreviousYears, monthYear } from '../../../../utils/constants/date_month'
import useGlobalErrors from '../../../../hooks/erors'
import { useFilter } from '../../../../hooks/filter'
import { useDownload } from '../../../../hooks/download/useDownload'
import { returnQuery, returnQueryWithPagination } from '../../../../utils/methods/returnQuery'
import { adminServiceFetch, companiesServiceFetch, userServiceFetch } from '../../../../utils/constants/callService'
import { ModelDownloadButtons } from '../../../../components/donwloadFiles/model'
import { images } from '../../../../utils/images'
import { getUserTypes } from '../../../../utils/methods/geServiceWithItemsFormat'

const AdminUsers = () => {
  const { t } = useTranslation()
  const { colors, state } = useContextState()
  const styles = useStyles(colors)
  const [information, setInformation] = useState<InformativeCard | null>(null)
  const [disabledUserName, setDisabledUserName] = useState<string>('')
  const [data, setData] = useState<{ [x: string]: InputModel }>(initialValues)
  const [btnLoading, setBtnLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [flag, setFlag] = useState(false)
  const [allErrors, setAllErrors] = useState<any>({})
  const [table, setTable] = useState<Table>(initialTable)
  const [companiesList, setCompaniesList] = useState<any[]>([])
  const [statusList, setStatusList] = useState<any[]>([])
  const [oldCup, setOldCurp] = useState('')
  const [curpExist, setCurpExist] = useState(false)
  const [isAdminm, setIsAdmin] = useState(false)
  const [divisionCompani, setdivisionCompani] = useState<Item[]>([])
  const [userTypeList, setUserTypeList] = useState<Item[]>([])
  const { errors } = useGlobalErrors({
    data,
    t,
    custome: { curp: allErrors?.curp },
  })
  const [inputs, setInputs] = useState<{ [x: string]: InputModel }>(input)

  const [row, setRow] = useState<Table['row']>([])
  const [selected, setSelected] = useState<number>(-1)
  const [open, setOpen] = useState(false)
  const [crud, setCrud] = useState(false)
  const [drawerDetail, setDrawerDetail] = useState({
    title: '',
    accept: '',
    cancel: '',
  })

  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
    type: Types.SUCCESS as string,
  })
  const [totalPages, setTotalPages] = useState(0)
  const {
    filterData,
    query,
    counter,
    errors: errorsFilter,
    paginationModel,
    handlePagination,
    cleanFilter,
    onChange,
    sortTable,
    filterString,
  } = useFilter({
    formatFilter
  })

  const {
    download,
    openSnackbar: snackbarDownloadModal,
    downloadCSV,
    downloadPDF,
    downloadWORD,
    closeOpenSnakbar,
  } = useDownload({
    docName: 'Administradores',
    query: returnQueryWithPagination({ filter: filterString, sort: query.sort }),
    downloadFuntion: adminServiceFetch.downloadDocument,
  })

  const menuList = useMenuItems(styles)
  const [menu, setMenu] = useState<
    {
      label: string
      icon?: any
      value?: any
    }[]
  >(menuList)

  const handleCloseSnackbar = (value: boolean) =>
    setOpenSnackbar({ ...openSnackbar, open: value })
  const crudChange = async (
    e: onChangeEvent | string,
    extra: any
  ): Promise<void> => {
    if (typeof e !== 'string') {
      const { name, value } = e?.target
      
      let existedCurp = curpExist ?? false
      let body = {
        ...data,
        [name]: {
          ...data[name],
          value,
        },
      }
      let response: {
        curpExists: boolean
      } = { curpExists: false }

      if (name === 'curp') {
        setCurpExist(false)
        existedCurp = false
        if (value?.length >= 10 && oldCup !== value && !!extra?.blur) {
          response = await userServiceFetch.curpExist(value)

          setCurpExist(!!response?.curpExists)
          existedCurp = !!response?.curpExists
        }
      } else if(name === "companyId"){
        getDivisionsZones({companyId: value?.value})
      }
      setData(body)
      setAllErrors(handleErrors(body, t, existedCurp))
    }
  }

  const handleSubmit = async () => {
    setBtnLoading(true)
    let body = { ...data }

    if (data.picture?.value?.name) {
      body = {
        ...body,
        picture: {
          ...body.picture,
          value: await sharedAws.uploadAndGetUrl({file: data.picture.value, name: data.firstName.value, otherBucket: process.env.REACT_APP_BUCKET})
        },
      }
    }
    setData(body)
    const response = await submit(body, t, information?.id?.toString(), oldCup)
    if (!response?.curpExist) {
      if (!response?.response?.errors) {
        getAllUsers()
      }
      if (response?.snackbarBody) {
        setOpenSnackbar(response?.snackbarBody)
      }
      setOldCurp('')
      setCurpExist(false)
    } else {
      setCurpExist(true)
      setAllErrors({
        curp: t('general.validations.existedCurp'),
      })
    }
    setBtnLoading(false)
    cleanFilter()
  }

  const handleInformation = async (e: any, index: number) => {
    setSelected(index)
    openDrawer(true)
    setOldCurp('')
    setCurpExist(false)
    let data: any[] = []
    setAllErrors({})
    setBtnLoading(true)
    const response = await adminServiceFetch.getAdminById(e.id)

    data = [
      {
        label: t(`general.tags.curp`),
        name: 'curp',
        value: response.curp
      },
      {
        label: t(`general.tags.firstName`),
        name: 'firstName',
        value: response.firstName
      },
      {
        label: t(`general.tags.lastName`),
        name: 'lastName',
        value: response.lastName
      },
      {
        label: t(`general.tags.email`),
        name: 'email',
        value: response.email,
        copy: true
      },
      {
        label: t(`general.tags.phone`),
        name: 'phone',
        value: response.phone
      },
      {
        label: t(`general.tags.createTime`),
        name: 'createTime',
        value: e.createTime
      },
    ]

    setMenu(menuList?.filter((item) => item.value !== e.status?.value))
    setInformation(
      e
        ? {
          data,
          tmpData: data,
          status: {
            color: e.status.color,
            text: e.status.text,
            value: e.status?.value,
          },
          image: response.picture ?? images.profile,
          id: e.id,
        }
        : null
    )

    setBtnLoading(false)
    setData(initialValues)
  }

  const getUserTypeList = async () => {
    const {items} = await getUserTypes()

    setUserTypeList(items)
  }
  
  const getDivisionsZones = async ({ companyId }: { companyId?: string; }) => {
    setdivisionCompani([])
    
    if (companyId) {
      const response = await userServiceFetch.getDivisionsZones(companyId)
      if (!response.errors) {
        setdivisionCompani(
          response?.divisions?.map((item: { description: any; id: any }) => ({
            label: item.description,
            value: item.id,
          }))
        )
      }
    }
  }

  const handleMenu = async (item: any) => {
    if (item.value === CRUD.EDIT) {
      setCrud(true)
      setBtnLoading(true)
      setCurpExist(false)
      getAllCompanies()
      adminServiceFetch
        .getAdminById(information?.id as number)
        .then((responseAdmin) => {
          setDisabledUserName(Field.EMAIL)
          setIsAdmin(information?.id === state.id)
          setOldCurp(responseAdmin?.curp ?? '')
          let phone = responseAdmin.phone

          if (!!phone) {
            if (phone[0] == '+') {
              const lengthPhone = phone.length
              phone = `+52${phone.slice(3, lengthPhone)}`
            } else if (phone?.[0] !== '+') {
              phone = `+52${phone}`
            }
          }
          const companyId = responseAdmin?.userCompanyUserType?.[0]?.company?.id
          getDivisionsZones({companyId: companyId?.toString()})

          setData({
            curp: {
              ...initialValues.curp,
              label: t('general.tags.curp'),
              value: responseAdmin?.curp,
            },
            username: {
              ...initialValues.username,
              label: t('general.tags.userName'),
              value: '0',
            },
            divisions: {
              ...initialValues.divisions,
              label: t('general.tags.divisions'),
              value: responseAdmin?.division?.id,
            },
            userType: {
              ...initialValues.userType,
              label: t('general.tags.userType'),
              value: responseAdmin?.userType?.id,
            },
            firstName: {
              ...initialValues.firstName,
              label: t('general.tags.firstName'),
              value: responseAdmin?.firstName,
            },
            lastName: {
              ...initialValues.lastName,
              label: t('general.tags.lastName'),
              value: responseAdmin?.lastName,
            },
            gender: {
              ...initialValues.gender,
              value: responseAdmin?.gender?.toString() ?? '0',
            },
            email: {
              ...initialValues.email,
              label: t('general.tags.email'),
              value: responseAdmin?.email,
            },
            companyId: {
              ...initialValues.companyId,
              value:
              companyId ?? null,
            },
            phone: {
              ...initialValues?.phone,
              value: phone,
            },
            status: {
              ...initialValues?.status,
              value: responseAdmin?.statusId,
            },
            picture: {
              ...initialValues?.picture,
              value: responseAdmin?.picture,
            },
          })
        })
        .finally(() => {
          setBtnLoading(false)
        })
    }
  }

  const getAllCompanies = async () => {
    const response = await companiesServiceFetch.getAllCompanies()
    if (!response.errors) {
      setCompaniesList(
        response
          .filter((item) => item.statusId === Status.ACTIVE)
          ?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
      )
    }
    return true
  }
  const getAllUsers = useCallback(async () => {
    resetValues()
    setIsLoading(true)
    const { column, row, totalPages } = await getUserList({ colors, t, query: returnQuery({ ...query, filter: query.filter?.replaceAll('+', '%2B') }, formData) })
    setTable({ column, row })
    setRow(row)
    setIsLoading(false)
    setTotalPages(totalPages)
    setFlag(true)
  }, [colors, t, query.all])

  const getDates = () => {
    setInputs((prev) => ({
      ...prev,
      createYear: {
        ...prev.createYear,
        items: getPreviousYears(),
      },
      month: {
        ...prev.month,
        items: monthYear.map(item => ({...item, value: item.value + 1})),
      },
    }))
  }

  const getAllData = useCallback(async () => {
    getDates()
    getUserTypeList()
    const data = statusService().statusList()
    let valideStatus: any[] = []
    data?.forEach((item: any) => {
      if (
        [Status.ACTIVE, Status.WITH_DRAW, Status.BLOCKED].includes(
          item.id
        )
      ) {
        valideStatus.push({
          value: item.id,
          label: item.description,
        })
      }
    })

    setStatusList(valideStatus)

    setInputs((prev) => ({
      ...prev,
      status: {
        ...prev.status,
        items: valideStatus,
      },
    }))
  }, [getAllUsers])

  const resetValues = () => {
    setCrud(false)
    setOpen(false)
    setData(initialValues)
    setBtnLoading(false)
    setSelected(-1)
  }
  const openDrawer = (edit?: boolean) => {
    setDrawerDetail({
      title: t(`user.informationCard.${edit ? 'edit' : 'create'}.admin`),
      accept: t(
        `user.informationCard.buttons.${edit ? 'updateAdmin' : 'addAdmin'}`
      ),
      cancel: t('userType.informationCard.buttons.cancel'),
    })
    if (!edit) {
      setData(initialValues)
    }
    setInformation(null)
    setAllErrors({})
    setCrud(!edit)
    setOpen(true)
  }

  const closeDrawer = () => {
    setOpen(false)
    setCrud(false)
    setSelected(-1)
  }

  const handleCreate = async () => {
    setdivisionCompani([])
    setIsAdmin(false)
    setBtnLoading(true)
    openDrawer()
    getAllCompanies()
    setDisabledUserName('')
    setBtnLoading(false)
  }
  
  useEffect(() => {
    if (!flag) getAllData()
  }, [getAllData, flag])

  useEffect(() => {
    getAllUsers()
  }, [query.all])
  return (
    <>
      <SnackSimple close={closeOpenSnakbar} open={snackbarDownloadModal.open} type={snackbarDownloadModal.type} message={snackbarDownloadModal.message} />
      <SnackSimple
        close={handleCloseSnackbar}
        open={openSnackbar.open}
        type={openSnackbar.type}
        message={openSnackbar.message}
      />

      <General
        newHeader={
          <Box sx={styles.header}>
            <Typography sx={styles.title}>Administradores</Typography>

            <Box sx={styles.flex}>
              <ModelDownloadButtons csv={downloadCSV} pdf={downloadPDF} word={downloadWORD} disableCSV={download?.excel} disablePDF={download?.pdf} disableWORD={download?.word} />

              <IconButton
                sx={(styles.clean, (counter || 0) > 0 ? null : styles.hide)}
                onClick={() => {
                  cleanFilter()
                }}
              >
                {icons('clean')}
              </IconButton>
              <IconButton
                sx={styles.clean}
                onClick={() => {
                  handleCreate()
                }}
              >
                {icons('add')}
              </IconButton>
            </Box>
          </Box>
        }
        open={open}
        rightDrawer={{
          ...information,
          loadindDetail: btnLoading,
          data: information?.data,
          showImage: true,
          form: (
            <Form
              btnLoading={btnLoading}
              data={data}
              crudChange={crudChange}
              allErrors={errors}
              isAdminm={isAdminm}
              disabledUserName={disabledUserName}
              companyList={companiesList}
              divisionCompani={divisionCompani}
              userTypeList={userTypeList}
              statusList={statusList}
            />
          ),
          menuItems: menu,
          crud,
          title: drawerDetail.title,
          accept: {
            title: drawerDetail.accept,
            disabled:
              generalDisabled(
                data.curp.value
                  ? data
                  : { ...data, curp: { ...data.curp, value: null } }
              ) || curpExist,
            onClick: handleSubmit,
          },
          cancel: {
            title: drawerDetail.cancel,
            onClick: closeDrawer,
          },
          clickMenu: handleMenu,
        }}
        table={{
          column: table.column,
          row: row,
          title: t('general.tags.admin'),
          onClick: handleInformation,
          selected,
          loadingTable: isLoading,

          serverSidePagination: {
            page: (paginationModel.page ?? 0) + 1,
            size: paginationModel?.pageSize ?? 20,
            totalPages: totalPages,
          },
          handleChange: (page: number, pageSize: number) => {
            handlePagination({ page, pageSize })
          },
          sortable: (field, sort, count) => {
            sortTable(field && sort ? { field: field, sort: sort, count } : undefined)
          },
          order: query?.sort && {
            count: query?.sort?.count,
            key: query?.sort?.field,
            order: query?.sort?.sort,
          }
        }}
        filtersDrawer={
          <>
            <FiltersDrawer
              cleanFilter={cleanFilter}
              count={counter}
              right={-50}
              top={0}
            >
              <Box sx={styles.section}>
                <DinamicForm
                  errors={errorsFilter}
                  input={Object.values(inputs)}
                  values={filterData}
                  onChange={onChange}
                  filter={true}
                />
              </Box>
            </FiltersDrawer>
          </>
        }
      />
    </>
  )
}

export default AdminUsers
