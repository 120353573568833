import { SxProps } from '@mui/material'
import { Color } from '../../../../../../utils/theme/types/color'

export const useStyles = (color: Color): Record<string, SxProps> => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    padding: '50px 30px 30px 0',

    '& .title': {
      fontSize: '20px',
      fontWeight: '500',
      textAlign: 'left',
    },

    '& .section': {
      '& .subtitle': {
        textAlign: 'left',
        color: '#707070',
        fontSize: '16px',
      },
    },
  },
  notFound: {
    margin: '10px 0',
    color: `${color.color13}99`,
    fontSize: '14px',
    fontWeight: '500'
  },
  containerHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: '20px',
  },
  rowIcon: {
    fontSize: '20px',
    color: color.color4,
    '& .MuiSvgIcon-root': {
      fontSize: '1em',
    },
  },
  item: {
    border: '1px solid #d1d1d1',
    padding: '0 10px',
  },
  label: {
    backgroundColor: '#24A9E2',
    padding: '10px 10px 10px 25px',
    color: 'white',
  },
})
