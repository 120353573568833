const usePriorityStyles = () => {
    return {
        containerFolioAmount: {
            display: 'flex',
            columnGap: '10px',
            alignItems: 'center',
        },
        iconAlert: {
            '& .MuiIcon-root': {
                fontSize: '1.7rem',
            },
            '& img': {
                width: '100%',
            },
        },
    }
}

export { usePriorityStyles }
