import { Navigate, Route, Routes } from 'react-router-dom'
import { Data } from '../pages/data'
import { ReportPropgramationData } from '../pages/programation'

const ReportsRoutes = () => {
  return (
    <Routes>
      {/* DASHBOARD */}
      <Route index element={<Navigate to="/programmed" />} />
      <Route path="programmed" element={<Data />} />
      <Route path="programation" element={<ReportPropgramationData />} />
    </Routes>
  )
}

export default ReportsRoutes
