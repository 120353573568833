import { Box, Button, Typography } from "@mui/material"
import { useStyles } from "./styles"
import { useTranslation } from "react-i18next"
import { MessageContainer } from "../container"

type MessageUsersAddedProps = {
  usersAmmount?: number
  return: () => void
}
const MessageUsersAdded = (props: MessageUsersAddedProps) => {
  const styles = useStyles()
  const { t } = useTranslation()
  return (
    <MessageContainer>
      <>
        <Box sx={styles.information}>
          <Typography sx={styles.title}>{t(`general.${props.usersAmmount == 1 ? 'wasAdded' : 'wereAdded'}`)} <span>{props.usersAmmount}</span> {t(`general.tags.${props.usersAmmount == 1 ? 'user' : 'users'}`).toLowerCase()}.</Typography>

          <Typography sx={styles.description}>{t('general.doYouWantGoBackToAccessList')}</Typography>
        </Box>

        <Button onClick={() => { props.return() }} variant="contained">{t('general.confirm')}</Button>
      </>
    </MessageContainer>
  )
}

export { MessageUsersAdded }
