import { Icon, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useStyles } from "./styles"
import { MessageContainer } from "../container"
import { icons } from "../../../../../../../../../utils/icons"

const MessageUsersExisted = () => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <MessageContainer >
      <>
        <Icon sx={styles.warning}>{icons('info')}</Icon>

        <Typography sx={styles.text}>{t("general.messages.errors.allUsersExisted")}</Typography>
      </>
    </MessageContainer>
  )
}

export { MessageUsersExisted }
