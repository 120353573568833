import { Item } from "../../../../../../utils/types/generals"
import { percentage } from "../../../../../../utils/validate/validate"
import { dashboardColor } from "../../initial"
import { PercentageStatus } from "../indicators/typing/enums"
import { CakeProgressListProps } from "../indicatorsAreasAndDepartments/typing/types"

const unitStatus = (value: number) => ({
    programming: () => {
        if (value == 100) return PercentageStatus.GREEN
        if (value >= 84 && value < 100) return PercentageStatus.YELLOW
        if (value >= 50 && value < 84) return PercentageStatus.ORANGE
        if (value >= 1 && value < 50) return PercentageStatus.WINE_RED
        if (value < 1) return PercentageStatus.RED

        return PercentageStatus.GREEN
    },
    noProgramming: () => {
        if (value == 100) return PercentageStatus.RED
        if (value >= 84 && value < 100) return PercentageStatus.WINE_RED
        if (value >= 50 && value < 84) return PercentageStatus.ORANGE
        if (value >= 1 && value < 50) return PercentageStatus.YELLOW
        if (value < 1) return PercentageStatus.GREEN

        return PercentageStatus.GREEN
    },
})

const programmingStatus = (value: number) => ({
    paused: () => unitStatus(value).noProgramming(),
    noStarted: () => unitStatus(value).noProgramming(),
    finished: () => unitStatus(value).programming()
})

const actionPlanStatus = (value: number) => ({
    paused: () => unitStatus(value).noProgramming(),
    noStarted: () => unitStatus(value).noProgramming(),
    canceled: () => unitStatus(value).noProgramming(),
    finished: () => unitStatus(value).programming()
})
const porcentageAreasAndDepartments = (value: {
    progress: number
    noStarted: number
    finished: number
    incompleto: number
    expired: number
    canceled: number
    total: number
    status?: Item[]
}) => {
    let statusList: CakeProgressListProps[] = [
        {
            type: unitStatus(value.progress).noProgramming(),
            name: "En progreso",
            customeLabel: `${percentage({ a: value.progress, b: value.total })}%`,
            customeLabel2: "",
            color: dashboardColor[1],
            textColor: dashboardColor[1],
            value: value.progress,
        },
        {
            type: unitStatus(value.noStarted).noProgramming(),
            name: "No iniciado",
            customeLabel: `${percentage({ a: value.noStarted, b: value.total })}%`,
            customeLabel2: "",
            color: dashboardColor[2],
            textColor: dashboardColor[2],
            value: value.noStarted,
        },
        {
            type: unitStatus(value.finished).programming(),
            name: "Finalizado",
            customeLabel: `${percentage({ a: value.finished, b: value.total })}%`,
            customeLabel2: "",
            color: dashboardColor[3],
            textColor: dashboardColor[3],
            value: value.finished,
        },
        {
            type: unitStatus(value.incompleto).noProgramming(),
            name: "Incompleto",
            customeLabel: `${percentage({ a: value.incompleto, b: value.total })}%`,
            customeLabel2: "",
            color: dashboardColor[4],
            textColor: dashboardColor[4],
            value: value.incompleto,
        },
        {
            type: unitStatus(value.expired).noProgramming(),
            name: "Vencido",
            customeLabel: `${percentage({ a: value.expired, b: value.total })}%`,
            customeLabel2: "",
            color: dashboardColor[5],
            textColor: dashboardColor[5],
            value: value.expired,
        },
        {
            type: unitStatus(value.canceled).noProgramming(),
            name: "Cancelado",
            customeLabel: `${percentage({ a: value.canceled, b: value.total })}%`,
            customeLabel2: "",
            color: dashboardColor[6],
            textColor: dashboardColor[6],
            value: value.canceled,
        },
    ]
    
    return ({
        areasColors: () => {
            return statusList
        },
        departmentsColors: () => {
            return statusList
        },
    })
}

const percentageFoliosByAreas = (value: number) => ({
    areas: () => unitStatus(value).programming(),
    departments: () => unitStatus(value).programming(),
})

const porcentageAreasAndDepartmentsColors = (value: {
    progress: number
    noStarted: number
    finished: number
    incompleto: number
    expired: number
    canceled: number
    total: number
}) => ({
    progress: unitStatus(value.progress).noProgramming(),
    noStarted: unitStatus(value.noStarted).noProgramming(),
    finished: unitStatus(value.finished).programming(),
    incompleto: unitStatus(value.incompleto).noProgramming(),
    expired: unitStatus(value.expired).noProgramming(),
    canceled: unitStatus(value.canceled).noProgramming(),
    total: unitStatus(value.total).noProgramming(),
})

export { unitStatus, programmingStatus, actionPlanStatus, porcentageAreasAndDepartments, percentageFoliosByAreas, porcentageAreasAndDepartmentsColors }