import { useEffect, useState } from "react"
import { InputModel } from "../../../../../../../utils/types"
import { Item } from "../../../../../../../utils/types/generals"
import { inputsUnits } from "../constants/inputs"

const useInputs = ({ items }: { items: Record<string, Item[]> }) => {
  const [inputs, setInputs] = useState<Record<string, InputModel>>(inputsUnits)

  useEffect(() => {
    setInputs(prev => ({
      ...prev,
      store: {
        ...prev.store,
        items: items?.store ?? []
      },
      divisions: {
        ...prev.divisions,
        items: items?.divisions ?? []
      },
      zones: {
        ...prev.zones,
        items: items?.zones ?? []
      },
    }))
  }, [items])
  return { inputs }
}

export { useInputs }