import { Color } from "../../utils/theme/types/color";

export const useStyles = (color: Color) => ({
  containerSuccess: {
    width: '90%',
    margin: 'auto',
    padding: '10px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    '& .crud-filter-container': {
      paddingTop: '40px',
    },
    '& .table-container': {
      overflowY: 'auto',
      maxHeight: '420px'
    },
    '& .filterContainer': {
      width: 'fit-content'
    }
  },
  closeSuccess: {
    position: 'absolute',
    right: '-8px',
    top: '5px',
  },
  button: {
    borderRadius: 0,
    width: 'fit-content',
    alignSelf: 'end',
    marginTop: '20px'
  },
  filter: {
    width: '200px'
  },
  header: {
    width: 'fit-content',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    position: 'absolute',
    top: '65px',
    right: 0,
    '& .title': {
      fontSize: '18px',
      color: color.color9,
    },
    '& .another': {
      fontSize: '14px',
      color: color.color4,
      cursor: 'pointer',
    },
  },
})
