import { Roles } from '../../utils/enums'
import { Http } from '../../utils/types'
import { GetBlob, ObjectGeneric } from '../../utils/types/generals'
import { getUserRole } from '../auth'
import {
  AgetsBySupervisorResponse,
  AllLocalization,
  CreateUserResponse,
  Notify,
  User,
  UserListResponse,
  userHierarchy,
  userAsociateResponse,
  UsersByCompaniesAndRolesIDSResponse,
  StatusUsers,
  UserHistory,
  UserDetail,
  UserFilterResponse,
  UserPasword,
} from './response'

export const UserService = (client: Http) => ({
  usersByStatus: async (): Promise<UserListResponse | null> => {
    return await client.get('hierarchy/?onlySearchBelow=true')
  },
  userFilter: async (): Promise<UserListResponse> =>
    await client.get('report-folio/filters/users'),
  userList: async (rols?: any): Promise<UserListResponse> =>
    await client.get(`hierarchy${!!rols ? `?rols=${rols}` : ''}`),
  userListFilter: async (): Promise<UserListResponse> =>
    await client.get('report-folio/filters/users'),
  userDetail: async (data?: { query?: string }): Promise<UserListResponse> => {
    switch (getUserRole()) {
      case Roles.AGENT:
        return await client.get(
          `users/store-associate${data?.query ? '?' + data?.query : ''}`
        )
      case Roles.STORE:
      case Roles.PARTNER:
        return await client.get(
          `users/agents-store${data?.query ? '?' + data?.query : ''}`
        )
      default:
        return await client.get(
          `users/detailed${data?.query ? '?' + data?.query : ''}`
        )
    }
  },
  filterUsers: async (data?: { query?: string }): Promise<UserFilterResponse[]> => await client.get(`users/filters/detailed${data?.query ? '?' + data?.query : ''}`),
  importUsers: async (body: ObjectGeneric): Promise<Response> =>
    await client?.blob?.(`users/import`, body, true),
  generateTemplate: async (body: ObjectGeneric): Promise<Response> =>
    await client?.blob?.(`users/generate-template`, body),
  userById: async (id: number): Promise<UserDetail> =>
    await client.get(`users/${id}`),
  usersFolio: async (id: number): Promise<any> =>
    await client.get(`users/assigned-folio/${id}`),
  getSupervisors: async (id: number): Promise<{ user: User }> =>
    await client.get(`users/${id}/supervisors`),
  userByIds: async (companies: string, rols: string): Promise<UsersByCompaniesAndRolesIDSResponse> =>
    await client.get(`users/filters/template?companies=${companies}&rols=${rols}`),
  userHierarchyByIds: async (companies: string, rols: string): Promise<any> =>
    await client.get(
      `hierarchy/association?companyId=${companies}&roleId=${rols}`
    ),
  getUsersPartnerByCompanyId: async ({ companyId }: { companyId: string }) => {
    switch (getUserRole()) {
      case Roles.ADMIN:
        return await client.get(
          `hierarchy/collaboratos-without-parent?companyId=${companyId}`
        )
      default:
        return {}
    }
  },
  getDivisionsZones: async (companies: string): Promise<any> =>
    await client.get(`companies/zones-divisions?companies=${companies}`),
  createUser: async (
    body: any,
    multiPart?: boolean,
    user?: number
  ): Promise<CreateUserResponse> =>
    await client.post(`auth/signup`, body, multiPart),
  updateUser: async (
    body: any,
    id?: number,
    multiPart?: boolean,
  ) => {
    try {
      return id
        ? await client.put(`users/${id}`, body, multiPart)
        : { errors: 'error' }
    } catch (e) {
      return { errors: e }
    }
  },
  updateUserStatus: async (status: number, id?: number) => {
    try {
      return id
        ? await client.put(`users/${id}`, { statusId: status })
        : { errors: 'error' }
    } catch (e) {
      return { errors: e }
    }
  },
  getAccountHistory: async (id: number): Promise<UserHistory> => await client.get(`users/${id}/account-history`),
  blockUser: async (id?: number): Promise<StatusUsers> => await client.put(`users/${id}/status-locked`, {}),
  withDrawUser: async (id?: number): Promise<StatusUsers> => await client.put(`users/${id}/status-retired`, {}),
  activedUser: async (id?: number): Promise<StatusUsers> => await client.put(`users/${id}/status-actived`, {}),
  asociateUserStores: async (body: ObjectGeneric) => {
    try {
      return await client.post(`users/associate-user-stores`, body)
    } catch (e) {
      return { errors: e }
    }
  },

  asociateUser: async (body: ObjectGeneric) => {
    try {
      return await client.post(`hierarchy`, body)
    } catch (e) {
      return { errors: e }
    }
  },
  asociateUserAgents: async (body: ObjectGeneric) => {
    try {
      return await client.post(`users/associate-agent-supervisor`, body)
    } catch (e) {
      return { errors: e }
    }
  },
  getAsociateUserStores: async (id: number) => await client.get(`users/${id}/stores`),
  getAsociateUser: async (id: number): Promise<userAsociateResponse> =>
    await client.get(`hierarchy/userId/${id}/direct-childrens`),
  getAsociateUserAgents: async (
    id: number
  ): Promise<AgetsBySupervisorResponse> =>
    await client.get(`users/agents-by-supervisor/${id}`),

  getUserHierarchy: async (id: number): Promise<userHierarchy> =>
    await client.get(`hierarchy/userId/${id}?onlySearchBelow=true`),

  getAsociateUserAgentsBySupervisor: async (
    id: number
  ): Promise<AgetsBySupervisorResponse> =>
    await client.get(`hierarchy/?onlySearchBelow=true`),
  updateEmail: async (user: any, body: any) => {
    try {
      return await client.put(`users/${user}/update-email`, body)
    } catch (e) {
      return { errors: e }
    }
  },
  curpExist: async (curp: string): Promise<{ curpExists: boolean }> =>
    await client.get(`users/curp-exists/${curp}`),

  deleteUser: async (id: number) => await client.delete(`users/${id}`),
  getLocalization: async (query: { query: string }): Promise<AllLocalization> =>
    await client.get(
      `users/get-locations${query.query ? `?${query.query}` : ''}`
    ),
  getRegister: async (data: {
    type: `pdf` | 'word' | 'excel'
    query?: string
  }): Promise<GetBlob> =>
    await client.getBlob?.(
      `users/generate-file?type=${data.type}${data?.query ? `${data?.query}` : ''
      }`
    ),
  getPasswords: async (data: {
    type: `pdf` | 'word' | 'excel'
    query?: string
  }): Promise<GetBlob> =>
    await client.getBlob?.(
      `users/passwords/generate-file?type=${data.type}${data?.query ? `${data?.query}` : ''
      }`
    ),
  notifications: async (): Promise<Notify[]> =>
    await client.get('users/user_notifications'),
  deleteNotifications: async ({ body }: { body: { id: string[] } }) =>
    await client.post('users/user-notification-inactive', body),
  getUserPassword: async (payload: { id: string }):Promise<UserPasword> => await client.get(`users/${payload.id}/password`),
  updateUserPassword: async (payload: { id: string, body: {password:string} }):Promise<UserPasword> => await client.put(`users/${payload.id}/password`, payload.body)
})
