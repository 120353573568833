import { useContextState } from "../../hooks/theme"

const useDetailTypeOfResponse = () => {
    const { colors } = useContextState()

    return ({
        optionsContainer: {
            cursor: 'default',
            '& .MuiFormControlLabel-label': {
                cursor: 'default',
                wordBreak: "break-word",
                lineHeight: 1
            },
            '& .MuiButtonBase-root:hover': {
                cursor: 'default',
                backgroundColor: 'transparent',
            },
            '& .MuiTypography-root': {
                fontSize: '16px',
                color: colors.color13,
            },
            '& .MuiCheckbox-root': {
                padding: '2px',
                cursor: 'default',
                '& .MuiBox-root': {
                    width: '17px',
                    height: '17px',
                    marginRight: '10px',
                },
            },
        },
        iconContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            alignItems: 'center'
        },
        icon: {
            width: '30px'
        },
        free: {
            fontSize: '16px',
            color: colors.color13,
        }
    })
}

export { useDetailTypeOfResponse }