import { useContextState } from '../../../../hooks/theme'

const useStyles = () => {
  const { colors } = useContextState()
  return {
    container: {
      width: '100%',
      height: '100%',
      paddingRight: '50px',
      paddingTop: '40px',
      paddingBottom: '20px',
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'relative',
      '& .w-full': {
        width: '100%',
      },
    },
    resendModal: {
      borderRadius: 0,
      width: '500px',
    },
    sectionTitle: {
      margin: '10px 0',
      fontSize: '16px',
      color: colors.color10
    },
    containerAccordion: {
      width: "100%",
      display: 'flex',
      flexWrap: "wrap",
      rowGap: "10px",
      maxHeight: "600px",
      overflowY: "auto",
      '& .MuiAccordion-root': {
        '&.Mui-expanded': {
          margin: 0,
        },
        background: colors.color25
      },
      '& .MuiAccordionSummary-root.Mui-expanded': {
        minHeight: "fit-content"
      }
    },
    titleAccordion: {
      display: 'flex',
      width: '100%',
      justifyContent: "space-between",
      position: "relative",
      paddingRight: "40px"
    },
    titleAccordion2: {
      fontSize: '14px',
      color: colors.color13,
      fontWeight: '600'
    },
    containerIcons: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      '& .MuiIconButton-root': {
        padding: '2px',
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      fontSize: '20px',
      fontWeight: '600',
      color: colors.color13,
    },
    clean: {
      fontSize: '2rem',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    add: {
      fontSize: '2rem',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
        color: colors.color4,
      },
    },
    detail: {
      height: 'calc(100vh - 170px)',
      '& .detail-container': {
        paddingLeft: '0',
      },
    },
    close: {
      position: 'absolute',
      top: '25px',
      right: '5px',
    },
    content: {
      padding: "0 20px"
    },
    headerModal: {
      padding: "0 20px"
    },
    subTitle: {
      fontSize: '18px',
      fontWeight: '600',
      color: colors.color26,
      marginBottom: '10px',
    },
    icons: {
      marginTop: '-10px',
      textAlign: 'end',
      '& .MuiIconButton-root': {
        paddingLeft: '0',
        borderRadius: '10px',
        color: colors.color4,
      },
      '& .MuiSvgIcon-root': {
        marginRight: '5px',
      },
      '& .MuiTypography-root': {
        fontSize: '10px',
      },
    },
    baseMenu: {
      '& .MuiPopover-paper.MuiPopover-paper.MuiPopover-paper.MuiPopover-paper.MuiPopover-paper.MuiPopover-paper.MuiPopover-paper':
        {
          boxShadow: `1px 1px ${colors.color13}30`,
        },
    },
    containerStatus: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    hide: {
      visibility: 'hidden',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
      '&.flex-j-e': {
        justifyContent: 'end',
      },
    },
    detailTitle: {
      fontWeight: '700',
      '& span': {
        fontSize: '12px',
        fontWeight: '400',
      },
    },
    titleList: {
      fontSize: '12px',
      color: colors.color13,
      '&.width-50p': {
        width: '50px',
      },
      '&.width-full-50p': {
        width: 'calc(100% - 70px)',
        wordBreak: 'break-word'
      },
    },
    ulNull: {
      fontSize: '13px',
      color: colors.color9,
      fontWeight: '500',
      width: 'fit-content',
    },
    label: {
      fontSize: '12px',
      fontWeight: '400',
      color: colors.color10,
    },
    ul: {
      paddingLeft: '20px',
      fontSize: '13px',
      color: colors.color9,
      fontWeight: '500',
    },
    key: (width?:number) => ({
      fontSize: '12px',
      color: colors.color13,
      width: width ? `${width}px` : 'auto',
      wordBreak: 'break-word',
    }),
    value: (width?:number) => ({
      fontSize: '12px',
      color: colors.color13,
      width: width ? `calc(100% - ${width}px)` : 'auto',
      wordBreak: 'break-word',
    }),
    alignS: {
      alignItems: 'start'
    },
    modal: {
      borderRadius: 0,
      width: '500px',
    },
    modal2: {
      width: '600px',
      minHeight: '400px',
      padding: '30px 0',
      paddingTop:"40px"
    },
    text: {
      color: colors.color13,
      fontSize: '16px',
      fontWeight: '500',
    },
    text2: {
      fontSize: '.8rem',
      color: colors.color13,
    },
    dateContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    resendReportContainer: {
      position: 'relative',
      padding: '30px',
    },
    titleSend: {
      fontSize: '17px',
      fontWeight: '600',
      color: colors.color22,
      marginTop: '5px',
      marginBottom: '10px',
    },
    resendButton: {
      marginTop: '10px',
      display: 'flex',
      justifyContent: 'end',
      width: '100%',
    },
    button: {
      background: colors.primary,
      '&:hover': {
        background: `${colors.primary}d0`,
      },
    },
    section: {
      marginBottom: '32px',
    },
  }
}

export { useStyles }
