import { useContextState } from '../../../../hooks/theme'
import { useTheme } from '@mui/material'

const useStyles = () => {
  const { colors } = useContextState()
  const { breakpoints } = useTheme()
  return {
    container: {
      paddingTop: '40px',
      paddingRight: '50px',
      paddingLeft: '1px',
      paddingBottom: '15px',
      width: '100%',
      height: '100%',
      overflowY: 'auto',
    },
    section: {
      marginBottom: '32px',
    },
    clean: {
      fontSize: '2rem',
      padding: '6px',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    hide: {
      visibility: 'hidden',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
      '&.flex-j-e': {
        justifyContent: 'end',
      },
      '&.flex-j-sb': {
        justifyContent: 'space-between',
      },
      '&.wrap': {
        flexWrap: 'wrap',
      },
      '&.f-w': {
        width: '100%',
      },
      '&.g-1': {
        gap: '4px',
      },
      '&.g-2': {
        gap: '8px',
      },
    },
    tableText: {
      fontSize: '12px',
      color: colors.color13,
    },
    ul: {
      width: '100%',
      maxHeight: '400px',
      'overflow-y': 'auto',
      '& li .detail-container': {
        width: '100%',
      },
    },
    users: {
      '& .MuiTypography-root': {
        width: '100%',
        fontSize: '12px',
        color: colors.color13,
      },
    },
    containerUl: {
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
    },
    errorsText: {
      '&.MuiTypography-root': {
        width: '100%',
        color: colors.color19,
        fontSize: '10px',
      },
    },
    headerTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .MuiTypography-root': {
        fontSize: '18px',
        fontWeight: '500',
        color: colors.color13,
        '& span': {
          color: colors.color16,
        },
        marginBottom: '20px',
      },
    },
    scheduleIcon: ({ mark, color }: { mark: boolean, color?: string }) => {
      if (!!mark) {
        return {
          color: color ?? colors.color4,
          fontWeight: '600',
          textShadow: `0px 0px 10px ${colors.color4}`,
          position: 'relative',
        }
      }

      return {
        ...!!color && {color}
      }
    },
    check: {
      fontSize: '12px',
      color: colors.color8,
      position: 'absolute',
      top: '2px',
      right: '3px',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    headerLeft: {
      display: 'flex',
      width: 'fit-content',
      alignItems: 'center',
      '& .MuiIconButton-root': {
        color: colors.color4,
        fontWeight: '600',
      },
      '& .MuiTypography-root': {
        fontSize: '17px',
        fontWeight: '400',
      },
    },
    stepper: {
      width: {
        xl: '500px',
        lg: 'min-content',
        md: 'min-content',
        sm: 'min-content',
      },
      [breakpoints.between(1450, 1700)]: {
        width: '500px',
      },
    },
    body: {
      margin: '20px 0',
      width: '100%',
      minHeight: '10rem',
      boxShadow: `0 0 3px ${colors.color13}`,
      padding: '40px',
      position: 'relative',
    },
    buttonsContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .back': {
        width: '80px',
        height: '36px',
        textTransform: 'uppercase',
      },
      '& .next': {
        borderRadius: '4px',
        textTransform: 'uppercase',
      },
    },
  }
}

export { useStyles }
