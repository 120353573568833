import { Badge, Box, Button, Grid, Icon, IconButton, Tab, Typography } from "@mui/material"
import { useBody } from "./body"
import { useModels } from "./hooks/models"
import { DynamicFormRef } from "../../../../components/dynamicFormRef"
import { useStyles } from "./styles"
import { icons } from "../../../../utils/icons"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { useTranslation } from "react-i18next"
import { Model } from "./typing/types"
import { ActionPlanDrawer } from "../../components/actionPlanDrawer"
import { DinamicFormRefModel } from "../../../../components/dynamicFormRef/types"
import { BaseMenu, SnackSimple } from "../../../../components"
import { DrawerEvidences } from "../../../../hooks/evidences/DrawerEvidenceComponent"
import SimpleModal from "../../../../components/modal/simpleModal"
import OrderQuestionnarie from "../../components/reorder"
import LinkingUsersToQuestionnaires from "./fragments/linkingUsersToQuestionnaires"
import { useMemo } from "react"
import Loader from "../../../../components/loader"
import BaseModal from "../../../../components/modal/baseModal"
import { ReportFolioByAnswer } from "./fragments/reportFolioByAnswer"
import { Virtuoso } from "react-virtuoso"

const Detail = () => {
  const {
    items,
    totalSections,
    currentSection,
    sectionsLength,
    models,
    data,
    questionsList,
    groupOptionsItems,
    groupTypeItems,
    evidencesModal,
    defaultFoliosLimit,
    defaultFoliosReject,
    dataHeader,
    evidencesQuestions,
    modal,
    totalPoint,
    refsByKey,
    closeModal,
    openEvidenceDrawe,
    saveEvidences,
    handleHeaderData,
    setRef,
    closeEvidencesModal,
    openEvidencesModal,
    addData,
    addSections,
    changeSection,
    addQuestion,
    addAnswer,
    removeAnswerQuestionOrSection,
    ...rest
  } = useBody()
  const { t } = useTranslation()
  const { header } = useModels(items)
  const styles = useStyles()
  const id = evidencesModal?.id?.global ?? `${evidencesModal?.id?.questId}-${evidencesModal?.id?.answerId}`
  const evidencesModalShort = useMemo(() => evidencesModal.data?.[id], [evidencesModal?.id?.global, evidencesModal?.id?.questId, evidencesModal?.id?.answerId])
  const totalPointError = useMemo(() => !!rest.errors.totalPoints && (totalPoint.total as number < parseFloat((dataHeader?.totalPoints ?? '0') as string)), [rest.errors.totalPoints, totalPoint.total, dataHeader?.totalPoints])
  const reportFolioShorthand = useMemo(() => rest.reportFolioByAnswer?.data?.[rest?.reportFolioByAnswer?.id ?? ''], [rest?.reportFolioByAnswer?.id])

  if (!!rest.loadings.main) return <Loader />
  return (
    <Box sx={[styles.container, !!rest.loadings.main ? styles.overflowHidden : {}]}>
      {/* {
        !!rest.loadings.main && (
          <Box sx={styles.loadingContainer}>
            <Loader />
          </Box>
        )
      } */}
      {
        rest.mode != 'permissions' && (
          <Box sx={styles.header}>
            <IconButton className="icon back" onClick={() => { rest.goBack() }}>{icons('arrow-left-fill')}</IconButton>

            <Box className="header-main">
              <DynamicFormRef
                ref={element => {
                  setRef(element, `header`)
                  return refsByKey[`header`]
                }}
                model={header}
                defaultValues={dataHeader}
                onChange={(payload) => {
                  const name = payload?.current as string
                  handleHeaderData({ name: name, value: payload?.[name] as string ?? '' })
                }}
                parentWrapper={({ children }) => <Grid container spacing={2} children={children} />}
              />
            </Box>

            <Box>
              <IconButton className="icon order" onClick={() => { rest.handleOrderMode('order') }}> {icons('order')} </IconButton>

              {
                (!!rest.quetionnaireCanBeEdited || !rest.id) && (
                  <IconButton
                    sx={styles.checkIcon({ color: false })}
                    onClick={() => {
                      rest.submit()
                    }}
                  >
                    {icons('check')}
                  </IconButton>
                )
              }

              {
                !!rest.id && (
                  <IconButton
                    sx={styles.checkIcon({ color: true })}
                    onClick={() => {
                      rest.deleteItemTable()
                    }}
                  >
                    {icons('delete')}
                  </IconButton>
                )
              }
            </Box>
          </Box>
        )
      }

      {
        rest.mode != 'permissions' && (
          <TabContext value={currentSection}>
            <Box sx={styles.containerTabList}>
              <TabList sx={styles.tabContainer({})} onChange={(_, value) => { changeSection({ id: value }) }}>
                {totalSections?.sort((a, b) => (rest.order.sections[a.id] ?? 0) - (rest.order.sections[b.id] ?? 0)).map((item, index) => {
                  const sectionError = rest.errors?.sections?.[item.id]
                  return (
                    <Tab
                      key={item.id}
                      icon={
                        <>
                          {!!sectionError?.count && (
                            <Box sx={styles.errorSection}>
                              <Typography>{sectionError?.count}</Typography>
                            </Box>
                          )}

                          {sectionsLength > 1 ? (
                            <IconButton
                              sx={styles.removeSection}
                              onClick={(e) => {
                                e.stopPropagation()
                                removeAnswerQuestionOrSection({ idSection: item.id })
                              }}
                            >
                              {icons('simple-close')}
                            </IconButton>
                          ) : undefined}
                        </>
                      }
                      iconPosition="end"
                      label={item.name || `Sección ${index + 1}`}
                      value={item.id}
                    />
                  )
                })}
                <IconButton onClick={() => { addSections() }} sx={styles.addIcon}>
                  {icons('add-rounded')}
                </IconButton>
              </TabList>

              <Box sx={styles.totalPoints({ totalPointError })}>
                <Typography className={`${totalPointError ? 'total' : ''}`}>{totalPoint.total.toFixed(2)}{` / `}</Typography>

                <DynamicFormRef
                  ref={element => {
                    setRef(element, `totalPoints`)
                    return refsByKey[`totalPoints`]
                  }}
                  styleSx={{ width: '60px', marginLeft: '5px' }}
                  model={{
                    totalPoints: {
                      id: 'totalPoints',
                      label: '',
                      name: 'totalPoints',
                      type: 'text',
                      hiddenError: true,
                      validations: { decimal: { value: true }, min: { value: 0 }, max: { value: 100 } },
                      inputOptions: {
                        variant: 'standard'
                      }
                    }
                  }}
                  defaultValues={{ totalPoints: dataHeader?.totalPoints ?? null }}
                  onChange={(payload) => {
                    const name = payload?.current as string
                    handleHeaderData({ name: name, value: payload?.[name] as string ?? '' })
                  }}
                  onBlur={(payload) => {
                    const name = payload?.current as string
                    handleHeaderData({ name: name, value: payload?.[name] as string ?? '', blur: true })
                  }}
                />

                {
                  !!totalPointError && (
                    <Typography sx={styles.totalPointError}>{t('Questionarie.totalPoints')}</Typography>
                  )
                }
              </Box>
            </Box>

            <Box sx={styles.TabsContainer}>
              {totalSections?.sort((a, b) => (rest.order.sections[a.id] ?? 0) - (rest.order.sections[b.id] ?? 0)).map((item) => {
                const questionArrarys = Object.entries(models?.[item.id]?.questions ?? {})
                const sectionError = rest.errors?.sections?.[item.id]
                return (
                  <TabPanel key={item.id} value={`${item.id}`}>
                    {/* SECTION DESCRIPTION */}
                    <DynamicFormRef
                      ref={element => {
                        setRef(element, `${item.id}`)
                        return refsByKey[`${item.id}`]
                      }}
                      removeForm
                      onChange={(data) => {
                        const key = data?.current as string

                        addData({ name: key ?? '', value: data?.[key] as string })
                      }}
                      defaultValues={data[item.id] as Model}
                      model={(models[item.id]?.sectionDescription)}
                      parentWrapper={({ children }) => <Grid mb={2} container spacing={2} children={children} />}
                    />

                    {/* QUESTIONS */}

                    <Virtuoso
                      style={{ height: questionArrarys?.length == 1 ? 'calc(100vh - 400px)' : questionArrarys?.length > 2 ? '1200px' : '800px' }}
                      data={questionArrarys?.sort(([a], [b]) => (rest.order.questions[`${item.id}-${a}`] ?? 0) - (rest.order.questions[`${item.id}-${b}`] ?? 0))}
                      itemContent={(index, [key, value]) => {
                        const answersArray = Object.entries(value.answers ?? {})
                        const question = data?.[item.id]?.[key]
                        const option = question?.questionOption?.value?.toString()
                        const someAnswerHasActionPlanActive = Object.values(question?.answer ?? {}).some((item: any) => !!item?.actionPlanActive)
                        const someAnswerHasReportFolioActive = Object.values(question?.answer ?? {}).some((item: any) => !!item?.reportFolio)
                        const isFree = ['3'].includes(option)
                        const isAffective = ['4'].includes(option)
                        const isGeneralEvidence = evidencesModal.evidencesQuestionGeneralActive?.[key]
                        const answerErrors = sectionError?.question?.[key]?.answer

                        return (
                          <Box key={key} sx={styles.listAnswers}>
                            {
                              !!(questionArrarys?.length > 1) && (
                                <IconButton onClick={() => { removeAnswerQuestionOrSection({ idQuestion: key }) }} sx={styles.questionDelete}>
                                  {icons('delete')}
                                </IconButton>
                              )
                            }
                            {/* OPTIONS OFQUESTION TYPE */}
                            <Box sx={styles.options}>
                              <Typography>Nueva pregunta {index + 1}</Typography>

                              <Box sx={styles.containerOptions}>
                                <Box sx={[styles.containerBug({ disabled: isGeneralEvidence != undefined && !isGeneralEvidence }), styles.evidencesType]}>
                                  <Badge badgeContent={evidencesModal?.counts?.[key] ?? null} sx={styles.badge()}>
                                    <IconButton
                                      onClick={(e) => {
                                        openEvidenceDrawe({ questId: key, disabled: (isGeneralEvidence != undefined && !isGeneralEvidence) })

                                        if (!(isGeneralEvidence != undefined && !isGeneralEvidence)) {
                                          evidencesQuestions.openMenu({
                                            anchor: e,
                                            defaultValues: evidencesModal.evidences?.general?.[key]
                                          })
                                        }
                                      }}
                                      className="p3"
                                      sx={[styles.bug, styles.activeClip]}
                                    >
                                      {icons('clip')}
                                    </IconButton>
                                  </Badge>
                                </Box>
                                {/* {/* OPTIONS OF THE QUESTION */}
                                <DynamicFormRef
                                  ref={element => {
                                    setRef(element, `${item.id}-${key}-options`)
                                    return refsByKey[`${item.id}-${key}-options`]
                                  }}
                                  removeForm
                                  onChange={(data) => {
                                    const name = data?.current as string
                                    addData({ name: name ?? '', value: data?.[name] as string, idQuestion: key })
                                  }}
                                  defaultValues={question}
                                  model={{
                                    ...value?.options,
                                    questionOption: value?.options.questionOption ? {
                                      ...value?.options.questionOption,
                                      items: groupTypeItems,
                                      wrapper: (payload) => (
                                        <Grid
                                          item
                                          xs={!['3'].includes(option) ? 6 : 12}
                                          children={payload.children}
                                          key={payload.id}
                                        />
                                      )
                                    } : null,
                                    questionTemplate: value?.options.questionTemplate && !['3'].includes(option) ? {
                                      ...value?.options.questionTemplate,
                                      items: groupOptionsItems
                                    } : null
                                  }}
                                  parentWrapper={({ children }) => <Grid sx={{ width: !['3'].includes(option) ? '500px' : '250px' }} container spacing={2} children={children} />}
                                />
                              </Box>
                            </Box>
                            {/* DESCRIPTION OF THE QUESTION */}
                            <DynamicFormRef
                              ref={element => {
                                setRef(element, `${item.id}-${key}-description`)
                                return refsByKey[`${item.id}-${key}-description`]
                              }}
                              removeForm
                              onChange={(data) => {
                                const name = data?.current as string
                                addData({ name: name ?? '', value: data?.[name] as string, idQuestion: key })
                              }}
                              defaultValues={question as Model}
                              model={{
                                ...value?.questionDescription,
                                answerText: isFree ? value?.questionDescription?.answerText : null,
                              }}
                              parentWrapper={({ children }) => <Grid mb={2} container spacing={2} children={children} />}
                            />

                            {
                              answersArray.slice(0, !!isFree ? 1 : undefined).map(([keyAnswer, valueAnswers]) => {
                                const actionItems = questionsList?.[item.id]?.questions?.sort((a, b) => (rest.order.questions[`${item.id}-${a.value}`] ?? 0) - (rest.order.questions[`${item.id}-${b.value}`] ?? 0))?.slice(index + 1)
                                const actionPlanErrors = answerErrors?.[keyAnswer]?.actionPlan
                                const reportFolioErrors = answerErrors?.[keyAnswer]?.reportFolio
                                const defaultAnswer = data?.[item.id]?.[key]?.answer?.[keyAnswer]
                                const manageAnswer = (): DinamicFormRefModel | null => {
                                  if (!!isFree) { return null }
                                  if (!!valueAnswers?.answer) {
                                    if (!!isAffective) {
                                      return {
                                        ...valueAnswers?.answer,
                                        type: 'file'
                                      }
                                    }

                                  }
                                  return valueAnswers?.answer
                                }
                                // ANSWER OF THE QUESTIONS
                                return (
                                  <Box key={keyAnswer} sx={styles.answersContainer}>
                                    <Box >
                                      <DynamicFormRef
                                        ref={element => {
                                          setRef(element, `${item.id}-${key}-${keyAnswer}`)
                                          return refsByKey[`${item.id}-${key}-${keyAnswer}`]
                                        }}
                                        removeForm
                                        onChange={(data) => {
                                          const name = data?.current as string
                                          addData({ name: name ?? '', value: data?.[name] as string, idQuestion: key, idAnswers: keyAnswer })
                                        }}
                                        manageDefault={{
                                          selectSimple: true,
                                          text: true
                                        }}
                                        defaultValues={{
                                          ...defaultAnswer,
                                          action: actionItems?.find(item => item.value == defaultAnswer?.action?.value)
                                        } as Model}
                                        model={{
                                          ...valueAnswers,
                                          answer: manageAnswer(),
                                          action: (index < (questionArrarys?.length - 1) && (actionItems?.length > 0 && !!valueAnswers?.action) && !['2'].includes(option)) ? {
                                            ...valueAnswers?.action,
                                            items: actionItems
                                          } : null,
                                          actionPlanActive: (dataHeader?.option == '0') ? valueAnswers.actionPlanActive : null
                                        }}
                                        parentWrapper={({ children }) => <Grid mb={2} container spacing={2} children={children} />}
                                      />
                                    </Box>

                                    <Box sx={styles.answerActions}>
                                      {
                                        !!(!['2'].includes(option) && dataHeader?.option == '0') && (
                                          <>
                                            {
                                              !!question?.answer?.[keyAnswer]?.actionPlanActive && (
                                                <IconButton
                                                  onClick={() => { openEvidencesModal({ questId: key, answerId: keyAnswer }) }}
                                                  sx={[styles.setting, styles.boxShadow]}
                                                >
                                                  <>
                                                    {!!actionPlanErrors?.count && (
                                                      <Box sx={styles.errorActionPLan}>
                                                        <Typography>{actionPlanErrors?.count}</Typography>
                                                      </Box>
                                                    )}
                                                    {icons("setting")}
                                                  </>
                                                </IconButton>

                                              )
                                            }

                                            {
                                              !!question?.answer?.[keyAnswer]?.reportFolio && (
                                                <Badge max={999} /* badgeContent={2} */ sx={styles.bugBadge(true)}>
                                                  <IconButton
                                                    onClick={() => {
                                                      rest.openReportFolios({ questId: key, answerId: keyAnswer })
                                                    }}
                                                    sx={[styles.bug, styles.activeBug({ open: true })]}
                                                    className="p2"
                                                  >
                                                    <>

                                                      {!!reportFolioErrors?.count && (
                                                        <Box sx={styles.errorActionPLan}>
                                                          <Typography>{reportFolioErrors?.count}</Typography>
                                                        </Box>
                                                      )}
                                                      {icons('bug')}
                                                    </>
                                                  </IconButton>
                                                </Badge>

                                              )
                                            }
                                          </>
                                        )
                                      }
                                      {
                                        !['2'].includes(option) && (
                                          <Box sx={[styles.containerBug({ padding: 10, disabled: isGeneralEvidence != undefined && !!isGeneralEvidence }), styles.evidencesType]}>
                                            <Badge badgeContent={evidencesModal?.counts?.[`${key}-${keyAnswer}`] ?? null} sx={styles.badge()}>
                                              <IconButton
                                                onClick={(e) => {
                                                  openEvidenceDrawe({ questId: key, answerId: keyAnswer, disabled: (isGeneralEvidence != undefined && !!isGeneralEvidence) })
                                                  if (!(isGeneralEvidence != undefined && !!isGeneralEvidence)) {
                                                    evidencesQuestions.openMenu({
                                                      anchor: e,
                                                      defaultValues: evidencesModal.evidences?.answers?.[key]?.[keyAnswer]
                                                    })
                                                  }
                                                }}
                                                className="p3"
                                                sx={[styles.bug, styles.activeClip]}
                                              >
                                                {icons('clip')}
                                              </IconButton>
                                            </Badge>
                                          </Box>
                                        )
                                      }
                                      {
                                        !!(answersArray?.length > 2) && !valueAnswers?.answer?.disabled && (
                                          <IconButton onClick={() => { removeAnswerQuestionOrSection({ idAnswer: keyAnswer, idQuestion: key }) }} className="delete">
                                            {icons('delete')}
                                          </IconButton>
                                        )
                                      }
                                    </Box>
                                  </Box>
                                )
                              })
                            }

                            {
                              (!['3'].includes(option) && !question?.questionTemplate) && (
                                <Button
                                  onClick={() => {
                                    addAnswer({ idQuestion: key })
                                  }}
                                >
                                  {t('Questionarie.fields.addAnswer')}
                                </Button>
                              )
                            }

                            <Box sx={styles.settingMultiple}>
                              {
                                !!(!!someAnswerHasActionPlanActive && ['2'].includes(option) && dataHeader?.option == '0') && (
                                  <IconButton
                                    sx={[styles.setting, styles.boxShadow]}
                                    onClick={() => { openEvidencesModal({ questId: key, answerId: 'multiple' }) }}
                                  >
                                    <>
                                      {!!answerErrors?.actionPlan?.count && (
                                        <Box sx={styles.errorActionPLan}>
                                          <Typography>{answerErrors?.actionPlan?.count}</Typography>
                                        </Box>
                                      )}
                                      {icons("setting")}
                                    </>
                                  </IconButton>
                                )
                              }
                              {
                                (!!someAnswerHasReportFolioActive && !!['2'].includes(option) && dataHeader?.option == '0') && (
                                  <Badge max={999} /* badgeContent={2} */ sx={styles.bugBadge(true)}>
                                    <IconButton
                                      onClick={() => {
                                        rest.openReportFolios({ questId: key, answerId: 'multiple' })
                                      }}
                                      sx={[styles.bug, styles.activeBug({ open: true })]}
                                      className="p2"
                                    ><>

                                        {!!answerErrors?.reportFolio?.count && (
                                          <Box sx={styles.errorActionPLan}>
                                            <Typography>{answerErrors?.reportFolio?.count}</Typography>
                                          </Box>
                                        )}
                                        {icons('bug')}
                                      </>
                                    </IconButton>
                                  </Badge>

                                )
                              }
                              {
                                !!['2'].includes(option) && (
                                  <Box sx={[styles.containerBug({ padding: 10, disabled: isGeneralEvidence != undefined && !!isGeneralEvidence }), styles.evidencesType]}>
                                    <Badge badgeContent={evidencesModal?.counts?.[`${key}-multiple`] ?? null} sx={styles.badge()}>
                                      <IconButton
                                        onClick={(e) => {
                                          openEvidenceDrawe({ questId: key, answerId: 'multiple', disabled: (isGeneralEvidence != undefined && !!isGeneralEvidence) })
                                          if (!(isGeneralEvidence != undefined && !!isGeneralEvidence)) {
                                            evidencesQuestions.openMenu({
                                              anchor: e,
                                              defaultValues: evidencesModal.evidences?.answers?.[key]?.['multiple']
                                            })
                                          }
                                        }}
                                        className="p3"
                                        sx={[styles.bug, styles.activeClip]}
                                      >
                                        {icons('clip')}
                                      </IconButton>
                                    </Badge>
                                  </Box>
                                )
                              }
                            </Box>
                          </Box>
                        )
                      }}
                    />

                    <Box >
                      <Button
                        onClick={() => {
                          addQuestion()
                        }}
                        sx={styles.addAsk}
                      >
                        {t('Questionarie.fields.addQuestion')}
                      </Button>
                    </Box>
                  </TabPanel>
                )
              })}
            </Box>
          </TabContext>
        )
      }
      <>
        <BaseModal
          sx={{
            width: '800px',
          }}
          open={rest.mode === 'order'}
        >

          <>
            <Box sx={styles.headerOrder}>
              <Typography variant="h5" sx={styles.title}>
                {t('Questionarie.order.configOrder')}
              </Typography>

              <Typography variant="h5" sx={styles.warning}>
                <span>*</span>
                {t('Questionarie.order.warningOrder')}
              </Typography>

              <IconButton sx={styles.close} onClick={() => { rest.handleOrderMode('edit') }}>{icons('simple-close')}</IconButton>
            </Box>

            <OrderQuestionnarie ref={rest.ref} data={data} models={models} order={rest.order} />

            <Box sx={styles.footer}><Button onClick={() => {
              if (rest.ref.current?.getOrder) {
                rest.setOrder(rest.ref.current?.getOrder())
                rest.handleOrderMode('edit')
              }
            }} variant="contained">{t('Questionarie.order.save')}</Button></Box>
          </>
        </BaseModal>

        {
          rest.mode === 'permissions' && (
            <LinkingUsersToQuestionnaires
              input={rest.input}
              id={rest.id ? parseInt(rest.id) : undefined}
              data={rest.permissionsData}
              loading={rest.loadings.permission}
              back={(payload) => {
                rest.setPermissionsData(payload)
                rest.handleOrderMode('edit')
              }}
              itemsManage={rest.itemsManage}
              next={rest.handleOpenModalPermissions}
              submit={{
                disabled: rest.disabledPermissionUsers(),
                title: t(`Questionarie.manageUser.submit.${rest.id && !rest.quetionnaireCanBeEdited ? 'update' : 'create'}`),
                submit: (payload) => {
                  rest.setPermissionsData(payload)
                  rest.updatePermissions(payload)
                },
              }}
            />
          )
        }

        <BaseMenu
          sx={styles.border}
          anchorEl={evidencesQuestions.anchorEl}
          setAnchorEl={() => { evidencesQuestions.closeMenu() }}
          menuItems={evidencesQuestions.menu}
          click={(item) => { evidencesQuestions.clickOption({ item }) }}
        />
        <DrawerEvidences
          refsByKey={evidencesQuestions.refsByKey}
          defaultValues={evidencesQuestions.evidencesData}
          open={evidencesQuestions.openEvidenceDrawer}
          setRef={evidencesQuestions.setRef}
          save={saveEvidences}
          cancel={evidencesQuestions.cancelEvidence}
        />

        <ReportFolioByAnswer
          arrowBack={rest.getReportFolioByAnswer}
          id={rest.reportFolioByAnswer.id}
          default={reportFolioShorthand?.default}
          data={reportFolioShorthand?.evidencesData}
          cancel={rest.closeReportFolioByAnswer}
        />

        <ActionPlanDrawer
          areThereSomeEvidence={false}
          arrowBack={({ evidence, data, dataFolios, evidenceDataFolios }) => {
            closeEvidencesModal({ evidenceData: evidence, data, dataFolios, evidenceDataFolios })
          }}
          validate={!!rest.errors?.sections}
          DrawerProps={{
            open: !!evidencesModal?.id
          }}
          defaultValues={{
            EvidenceData: evidencesModalShort?.evidence,
            data: evidencesModalShort?.data,
            description: evidencesModalShort?.description,
            evidenceFolio: evidencesModalShort?.evidenceFolio,
            limitFolio: evidencesModalShort?.limitFolio ?? defaultFoliosLimit,
            rejectsFolio: evidencesModalShort?.rejectsFolio ?? defaultFoliosReject
          }}
          activeFolios={!!evidencesModalShort?.activeFolios}
          questName={dataHeader?.quest_name as string ?? ""}
          reportFolioOpen={false}
          shrink={() => { }}
        />

        <SnackSimple close={rest.closeSnackbar} open={rest.snackbar.open} type={rest.snackbar.type} message={rest.snackbar.message} />

        <SimpleModal
          icon={!!modal?.icon ? <Icon sx={styles.warningIcon}>{icons(modal.icon)}</Icon> : undefined}
          open={!!modal.open}
          close={() => {
            if (modal.cancel) { modal.cancel() }
            else {
              closeModal()
            }
          }}
          title={modal.title}
          removeClose={!modal.cancel}
          description={modal.description}
          cancelText={modal.cancelText ?? t('general.cancel')}
          buttonText={modal?.confirmText ?? t('general.confirm')}
          back={modal.cancel ? () => { if (modal.cancel) modal.cancel() } : undefined}
          loadingButton
          isLoading={!!rest.loadings.next}
          next={() => {
            if (modal.confirm) modal.confirm()
            else { closeModal() }
          }}
        />

      </>


      {
        rest.mode !== 'permissions' && (
          <IconButton
            onClick={() => {
              rest.goToLinkingUserToQuestionnaires()
            }}
            sx={[styles.floatButtons, styles.positionManage]}
          >
            {icons('manage-account')}
          </IconButton>
        )
      }
      {
        !!(dataHeader?.option == '1' && rest.mode !== 'permissions') && (
          <IconButton
            sx={[styles.setting, styles.boxShadow, styles.floatButtons, styles.positionSetting]}
            onClick={() => { openEvidencesModal({ global: true }) }}
          >
            <>
              {!!rest.errors?.actionPlan && (
                <Box sx={styles.errorActionPLan}>
                  <Typography>{rest.errors?.actionPlan}</Typography>
                </Box>
              )}
              {icons("setting")}
            </>
          </IconButton>
        )
      }
    </Box>
  )
}

export { Detail }
