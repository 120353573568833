import { Box, Chip, Typography } from "@mui/material";
import { Item } from "../../utils/types/generals";
import { useState } from "react";

const styles = {

  showMore: {
    fontSize: '13px',
    cursor: 'pointer',
    color: '#158bc8',
    marginTop: '10px',
    textAlign: 'right',
  },
  chipsContainer: {
    marginTop: '5px',
    display: 'flex',
    gap: '5px',
    flexWrap: 'wrap',
  },
  chipUnFocus: ({ color }: { color?: string }) => ({
    background: color ?? '#31c462',
    color: 'white',

    '&.MuiChip-clickable:hover': {
      backgroundColor: color ?? '#31c462',
    },
  }),
}
const ChipsList = (props: { value: Item[]; handleDelete: (option: any) => void; colorsByChips?: Record<string, string> }) => {
  const [showMore, setShowMore] = useState(false)
  const lengthData = props.value?.length > 5

  const onShowMore = () => {
    setShowMore(!showMore)
  }
  return (
    <>
      <Box sx={styles.chipsContainer}>
        {props.value?.slice(0, (!showMore && !!lengthData) ? 4 : undefined)?.map((option, index) => {
          return <Chip sx={styles.chipUnFocus({ color: option?.color ?? props.colorsByChips?.[option.value] })} variant="outlined" key={index} label={option.label} clickable onDelete={() => props.handleDelete(option)} />;
        })}
      </Box>

      {props.value && (props.value.length > 5) && (
        <Box className="show" sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography
            variant="body1"
            sx={styles.showMore}
            onClick={() => {
              onShowMore();
            }}
          >
            {!showMore ? "Mostrar más" : "Mostrar menos"}
          </Typography>
        </Box>
      )}
    </>
  )
}

export { ChipsList }
