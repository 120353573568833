import { Navigate, Route, Routes } from 'react-router-dom'
import User from '../pages/user'
import UserType from '../pages/userType'

const Users = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="register" />} />
      <Route path="register" element={<User />} />
      <Route path="user-type" element={<UserType />} />
    </Routes>
  )
}

export default Users
