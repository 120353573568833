import { useTheme } from '@mui/material'
import { Color } from '../../utils/theme/types/color'
import { draweWithClose, draweWithClosePaddigLeft, draweWithClosePaddigLeftTable, draweWithOpen, draweWithOpenPaddigLeft } from './constants'

export const useStyles = (color: Color, open: boolean) => {
  const { breakpoints } = useTheme()

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      width: '100vw'
    },
    header: {
      flex: 0.09,
    },
    body: {
      transition: open ? '.3s' : 0,
      paddingLeft: `${open ? draweWithOpenPaddigLeft : draweWithClosePaddigLeft}px`,
      [breakpoints.between(0, 1100)]: {
        paddingLeft: `${open ? draweWithOpenPaddigLeft : draweWithClosePaddigLeftTable}px`,
      },
      flex: 0.91,
      display: 'flex',
      flexDirection: 'row',
    },
    menuLg: {
      display: 'block',
      [breakpoints.between(0, 1100)]: {
        display: 'none'
      },
    },
    menuSmall: {
      display: 'none',
      [breakpoints.between(0, 1100)]: {
        display: 'block'
      },
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 'calc(100vh - 60px)',
      width: `calc(100vw - ${open ? draweWithOpen : draweWithClose}px)`,
      [breakpoints.between(0, 1100)]: {
        width: `calc(100vw - ${open ? draweWithOpen : 0}px)`,
      },
      overflowY: 'auto',
      overflowX: 'hidden',
      maxHeight: 'calc(100vh - 60px)',
    },
    showAside: {
      '& .MuiDrawer-paper': {
        left: open ? 0 : -300,
        transition: open ? '.3s' : 0,
      },
    },
  }
}
