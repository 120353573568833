import { Status } from '../../../../../utils/enums'
import { icons } from '../../../../../utils/icons'
import { images } from '../../../../../utils/images'
import { Priority } from '../typing'

const iconByPriority = {
  [Priority.URGENT]: {
    icon: images.fire,
  },
  [Priority.MEDIUM]: {
    icon: images.bellPriority,
  },
  [Priority.SUGGESTION]: {
    icon: images.loupe,
  },
}

const iconsByStatus = {
  [Status.APPROVED]: {
    icon: icons('check')
  },
  [Status.REJECTED]: {
    icon: icons('close')
  },
}
export { iconByPriority, iconsByStatus }
