import { light } from "../../../../../../utils/theme/colors/light";
import { PercentageStatus } from "../indicators/typing/enums";

const percentageColors = {
  [PercentageStatus.GREEN]: {
    color: light.color8,
    text: light.color5
  },
  [PercentageStatus.ORANGE]: {
    color: light.color29,
    text: light.color22
  },
  [PercentageStatus.YELLOW]: {
    color: light.secondary,
    text: light.color22
  },
  [PercentageStatus.WINE_RED]: {
    color: light.color37,
    text: light.color5
  },
  [PercentageStatus.RED]: {
    color: light.color19,
    text: light.color5
  },
}

export { percentageColors }