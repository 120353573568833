import { CalendarContext } from "../context"

const CalendarProvider = (props: {children: JSX.Element}) => {
    return (
        <CalendarContext.Provider
            value={{

            }}
        >
            {props.children}
        </CalendarContext.Provider>
    )
}

export { CalendarProvider }
