import { Box } from '@mui/material'
import { useContextState } from '../../hooks/theme'

const Loader = (props: {topAndLeft?: number; widthAndHeight?: number; size?:number}) => {
  const { colors } = useContextState()
  const topAndLeft = `${props?.topAndLeft ?? 36}px`
  const widthAndHeight = `${props?.widthAndHeight ?? 72}px`
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%'
    }}>
      <Box
        sx={{
          display: 'inline-block',
          position: 'relative',
          width: `${props?.size ?? 80}px`,
          height: `${props?.size ?? 80}px`,
          '& div': {
            position: 'absolute',
            border: `4px solid ${colors.color10}`,
            opacity: 1,
            borderRadius: '50%',
            animation: 'lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite',
          },
          '& div:nth-of-type(2)': {
            animationDelay: '-0.5s',
          },
          ' @keyframes lds-ripple': {
            '0%': {
              top: topAndLeft,
              left: topAndLeft,
              width: '0',
              height: '0',
              opacity: '0',
            },
            '4.9%': {
              top: topAndLeft,
              left: topAndLeft,
              width: '0',
              height: '0',
              opacity: '0',
            },
            '5%': {
              top: topAndLeft,
              left: topAndLeft,
              width: '0',
              height: '0',
              opacity: '1',
            },
            '100%': {
              top: '0px',
              left: '0px',
              width: widthAndHeight,
              height: widthAndHeight,
              opacity: '0',
            },
          },
        }}
      >
        <div></div>
        <div></div>
      </Box>
    </Box>
  )
}

export default Loader
