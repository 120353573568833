import { useBody } from "./body"
import { ScoreCard } from "../../components/scoreCard"

const GlobalScores = () => {
  const { data, loading, text, getData } = useBody()

  return (<ScoreCard loading={loading} refresh={getData} subtitle={text.subTitle} title={text.title} value={data.value} />)
}

export { GlobalScores }
