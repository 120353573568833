export const configureAws = {
  CognitoUserPool: {
    Default: {
      PoolId: process.env.REACT_APP_POOL_ID,
      AppClientId: process.env.REACT_APP_APP_CLIENT_ID,
      Region: process.env.REACT_APP_REGION,
    },
  },
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTIFY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    identityPoolRegion: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_APP_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    mandatorySignIn: false,
    oauth: {
      domain: process.env.REACT_APP_DOMAIN,
      scope: ['phone', 'email', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'http://localhost:3000/',
      redirectSignOut: 'http://localhost:3000/',
      responseType: 'code',
    },
  },
  
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_BUCKET,
      region: process.env.REACT_APP_REGION,
      maximumFileSize: 50,
      // level: '',
      // customPrefix: {
      //   public: '', // Set this to the desired public path (empty string or "/")
      // }
    },
  },
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_NAME,
        endpoint: process.env.REACT_APP_ENDPOINT,
      },
    ],
  },
}
