const formData: Record<string, string> = {
  available_to: 'end_date',
  available_from: 'start_date',
  store_name: 'store',
  keyCode: 'keycode',
  class: 'clase',
}

const formatFilter = {
  search: 'search',
  from: 'date_init',
  to: 'date_end',
  questionnaireId: 'questionnaire_ids',
  zoneId: 'zone_ids',
  childZone: 'clase_ids',
  userType: 'user_type_ids',
  userId: 'user_ids',
  keyCodeId: 'key_codes',
  userTypeId: 'user_type_ids',
  areas: 'areas_ids',
  status: 'status_ids',
  departments: 'departments_ids',
  createYear: 'year',
  month: 'month',
  page: 'page',
  size: 'size',
  divisions: 'division_ids',
  percentage: 'range_results',
  roles: 'role_ids',
  filterByCurrentWeek: 'current_week',
}
export { formData, formatFilter }