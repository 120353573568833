import { TFunction } from 'react-i18next'
import { Roles } from '../../../../utils/enums'
import { InputModel } from '../../../../utils/types'
import { Box, IconButton } from '@mui/material'
import { icons } from '../../../../utils/icons'
import { ItemMenu } from '../../../../components/baseMenu'
import { useState } from 'react'
import { Item } from '../../../../utils/types/generals'
import { Constants } from '../../../../utils/constants'
import { useStyles } from './styles'
import { actionPlanFetch } from '../../../../utils/constants/callService'
import { useNavigate } from 'react-router-dom'
import { menuItems } from './contants'
export interface ItemsInput {
  questionnaire?: any[]
  divisions?: any[]
  user?: any[]
  status?: any[]
  stores?: any[]
  zone?: any[]
  zonesClass?: any[]
  userType?: any[]
}
export const useInput = (items?: ItemsInput) => {
  return {
    search: {
      label: 'general.search',
      translate: true,
      name: 'search',
      type: 'text',
      value: null,
    },
    from: {
      label: 'general.tags.from',
      translate: true,
      name: 'from',
      type: 'date',
      value: null,
      rest: {
        max: 'to',
      },
      size: {
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
      },
    },
    to: {
      label: 'general.tags.to',
      translate: true,
      name: 'to',
      type: 'date',
      value: null,
      rest: {
        min: 'from',
      },
      size: {
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
      },
    },
    status: {
      label: 'general.tags.status',
      translate: true,
      name: 'status',
      type: 'filter-select',
      items: items?.status ?? [],
      value: null,
    },
    questionnaire: {
      label: 'general.tags.questionnaire',
      translate: true,
      name: 'questionnaire',
      type: 'filter-select',
      items: items?.questionnaire ?? [],
      value: null,
    },
    userType: {
      label: 'general.tags.userType',
      translate: true,
      name: 'userType',
      type: 'filter-select',
      items: items?.userType ?? [],
      value: null,
      notAllowedRoles: [Roles.STORE, Roles.PARTNER],
    },
    user: {
      label: 'general.tags.user',
      translate: true,
      name: 'user',
      type: 'filter-select',
      items: items?.user ?? [],
      value: null,
      notAllowedRoles: [Roles.STORE, Roles.PARTNER],
    },
    stores: {
      label: 'general.tags.store',
      translate: true,
      name: 'stores',
      type: 'filter-select',
      items: items?.stores ?? [],
      value: null,
      notAllowedRoles: [Roles.STORE, Roles.STORE, Roles.PARTNER],
    },
    divisions: {
      label: 'general.tags.division',
      translate: true,
      name: 'divisions',
      type: 'filter-select',
      items: items?.divisions ?? [],
      value: null,
      notAllowedRoles: [Roles.AGENT, Roles.STORE, Roles.PARTNER],
    },
    zone: {
      label: 'general.tags.zone',
      translate: true,
      name: 'zone',
      type: 'filter-select',
      items: items?.zone ?? [],
      value: null,
      notAllowedRoles: [Roles.STORE, Roles.PARTNER],
    },
    zonesClass: {
      label: 'general.tags.class',
      translate: true,
      name: 'zonesClass',
      type: 'filter-select',
      items: items?.zonesClass ?? [],
      value: null,
      notAllowedRoles: [Roles.STORE, Roles.PARTNER],
    },
  } as { [x: string]: InputModel }
}

export const customeHeader = (t: TFunction<'translation', undefined>) => [
  {
    id: 'year',
    label: t('general.tags.year'),
  },
  {
    id: 'month',
    label: t('general.tags.month'),
  },
  {
    id: 'weekYear',
    label: t('general.tags.weekYear'),
  },
  {
    id: 'idStore',
    label: t('general.tags.idUnit'),
  },
  {
    id: 'stores',
    label: t('general.tags.unitStore'),
  },
  {
    id: 'country',
    label: t('general.tags.country'),
  },
  {
    id: 'state',
    label: t('general.tags.status'),
  },
  {
    id: 'city',
    label: t('general.tags.city'),
  },
  {
    id: 'cve',
    label: t('general.tags.cveDivision'),
  },
  {
    id: 'zones',
    label: t('general.tags.zone'),
  },
  {
    id: 'class',
    label: t('general.tags.class'),
  },
  {
    id: 'divisions',
    label: t('general.tags.division'),
  },
  {
    id: 'role',
    label: t('general.tags.role'),
  },
  {
    id: 'userType',
    label: t('general.tags.userType'),
  },
  {
    id: 'curp',
    label: t('general.tags.curp'),
  },
  {
    id: 'user',
    label: t('general.tags.requestionUser'),
  },
  {
    id: 'statusActionPlan',
    label: t('general.tags.status'),
  },
  {
    id: 'questionnaire',
    label: t('general.tags.questionnaire'),
  },
  {
    id: 'section',
    label: t('general.tags.section'),
  },
  {
    id: 'question',
    label: t('general.tags.question'),
  },
  {
    id: 'requestDate',
    label: t('general.tags.requestDate'),
  },
  {
    id: 'requestHour',
    label: t('general.tags.requestHour'),
  },
  {
    id: 'deadlineDate',
    label: t('general.tags.deadlineDate'),
  },
  {
    id: 'deadlineHour',
    label: t('general.tags.deadlineHour'),
  },
  {
    id: 'soloveDate',
    label: t('general.tags.soloveDate'),
  },
  {
    id: 'soloveHour',
    label: t('general.tags.soloveHour'),
  },
  {
    id: 'elapsedDays',
    label: t('general.tags.elapsedDays'),
  },
  {
    id: 'elapsedHour',
    label: t('general.tags.elapsedHour'),
  },
  {
    id: 'rejects',
    label: t('general.tags.rejects'),
  },
]

export const useColumn = (t: TFunction<'translation', undefined>, rowLength: number) => {
  const styles = useStyles()
  const navigate = useNavigate()
  const [detail, setDetail] = useState<{ id: number; reportUrl: Record<string, unknown> | null; positionMenu: Record<string, unknown>; anchor: null | HTMLButtonElement; menu: ItemMenu[] }>({
    id: 0,
    reportUrl: null,
    positionMenu: {},
    anchor: null,
    menu: menuItems,
  })

  const closeMenu = () => {
    setDetail((prev) => ({
      ...prev,
      anchor: null,
    }))
  }

  const optionMenu = ({ item }: { item: Item }) => {
    switch (item.value) {
      case 1:
        if (!detail.reportUrl?.value) {
          actionPlanFetch
            .generateReport(detail.id)
            .then((reponseUrlReport) => {
              window.open(reponseUrlReport.urlToReport, 'target')
            })
        } else {
          window.open(detail?.reportUrl?.value as string, 'target')
        }
        break;
      case 2:
        navigate(`/dashboard/action-plan/${detail.id}`)
        break;
    }
  }
  return {
    detail, closeMenu, optionMenu,
    column: [
      {
        id: 'divisions',
        label: t('general.tags.division'),
        sortable: true,
        minWidth: 85,
      },
      {
        id: 'user',
        label: t('general.tags.user'),
        sortable: true,
        minWidth: 100,
      },
      {
        id: 'stores',
        label: t('general.tags.store'),
        sortable: true,
        minWidth: 100,
      },
      {
        id: 'questionnaire',
        label: t('general.tags.questionnaire'),
        sortable: true,
        minWidth: 100,
      },
      {
        id: 'item',
        label: t('general.tags.item'),
        minWidth: 40,
      },
      {
        id: 'dateRequestion',
        label: t('general.tags.dateRequestion'),
        sortable: true,
        minWidth: 100,
      },
      {
        id: 'deadline',
        label: t('general.tags.deadline'),
        sortable: true,
        minWidth: 100,
      },
      {
        id: 'status',
        label: t('general.tags.status'),
        minWidth: 100,
      },
      {
        id: 'action',
        label: t('general.tags.action'),
        minWidth: '50px',
        renderCell: (params: any) => {
          const id = params?.row?.id
          return (
            <Box sx={styles.containerStatus}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  let menu: ItemMenu[] = []
                  setDetail({
                    id,
                    reportUrl: params?.row?.reportUrl,
                    positionMenu:
                      rowLength < Constants.numberOfRows
                        ? {
                          top: 0,
                          left: '-30px',
                        }
                        : {
                          top: `${e.clientY}px`,
                          left: `${e.clientX - 80}px`,
                        },
                    anchor: e.currentTarget,
                    menu: menu,
                  })
                }}
              >
                {icons('moreHoriz')}
              </IconButton>
            </Box>
          )
        },
      },
    ]
  }
}

