import { Navigate, Route, Routes } from 'react-router-dom'
import Shop from '../pages/shop'
import Division from '../pages/division'
import Zones from '../pages/zones'

const Units = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="store" />} />
      <Route path="store" element={<Shop />} />
      <Route path="division" element={<Division />} />
      <Route path="zone" element={<Zones />} />
    </Routes>
  )
}

export default Units
