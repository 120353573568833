export const useStyles = (data?: { removeMargin?: boolean }) => {
  return {
    hidden: {
      display: 'none',
    },
    container: {
      width: '100%',
      display: 'flex',
      marginTop: !data?.removeMargin ? '-20px' : 0,
    },
  }
}
