import { useContextState } from '../../../../../hooks/theme'

const useStyles = () => {
  const { colors } = useContextState()
  
  return {
    scheduleTitle: {
      fontSize: '14px',
      fontWeight: '500',
      color: colors.color16,
    },
    subTitle: {
      fontSize: '16px',
      fontWeight: '600',
      color: colors.color26,
      margin: '10px 0',
    },
    formLabel: {
      '& .MuiFormControlLabel-label': {
        fontSize: '.8em'
      }
    },
    cancelBtn: {
      '& .container-btn': {
        gap: '10px',
        justifyContent: 'space-between'
      },
      '& .cancel-drawer': {
        position: 'relative',
        right: '0'
      },
    }
  }
}

export { useStyles }
