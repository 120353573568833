import { TFunction } from 'react-i18next'
import { getStatus } from '../../../../hooks/status'
import { sharedAws } from '../../../../service/bucketS3/Storage'
import { companyService } from '../../../../service/company'
import { divisionService } from '../../../../service/division'
import { httpFetch } from '../../../../utils/crud'
import { Types } from '../../../../utils/enums'
import { handleAllErrors } from '../../../../utils/methods/handleErrors'
import { Color } from '../../../../utils/theme/types/color'
import { ObjectGeneric, Table } from '../../../../utils/types/generals'
import { isPhoneNumber, isRequired, searchInModeLower } from '../../../../utils/validate/validate'
import { companiesServiceFetch } from '../../../../utils/constants/callService'

export const getCompaniesList = async ({ colors, queary, t }: { colors: Color; t: TFunction<'translation', undefined>; queary?: string }) => {
  const data = await companiesServiceFetch.getPaginationCompanies({ queary })

  const column: any[] = [
    {
      id: 'name',
      label: t('general.tags.name'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'companyName',
      label: t('general.tags.company_name'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'phone',
      label: t('general.tags.phone'),
      minWidth: 100,
    },
    {
      id: 'city',
      label: t('general.tags.city'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'state',
      label: t('general.tags.state'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'address',
      label: t('general.tags.address'),
      minWidth: 100,
    },
    {
      id: 'zipCodeCp',
      label: t('general.tags.zip_code'),
      minWidth: 100,
    },
    {
      id: 'status',
      label: t('general.tags.status'),
      minWidth: 100,
    },
  ]

  const row: any[] = !data?.errors
    ? data?.items?.map((item) => {
      return {
        id: item?.id,
        name: item?.name,
        companyName: item?.companyName,
        address: item?.address,
        zipCodeCp: (!!item.codOffice || !!item.settlement) ? `${item.codOffice ?? ''} - ${item.settlement}` : '',
        zipCode: item.zipCode,
        phone: item?.phone ?? '',
        status: getStatus(item?.statusId, colors, t),
        cities: item.zipCode,
        city: item.city,
        state: item.state,
        monthNumber: new Date(item.createTime).getMonth(),
        createTime: item.createTime,
      }
    })
    : []

  return { column, row, totalPages: data.totalPages, totalItems: data.totalItems, current: data.currentPage }
}

export const filterRow = (value: { [x: string]: any }, list: any[]) => {
  const from = value.from?.valueOf()
  const to = value.to?.valueOf()
  return list?.filter((item) => {
    const createTime = item?.createTime.split('T')[0]?.replaceAll('-', '/')

    return (
      (from ? new Date(from).setHours(0, 0, 0, 0) <= Date.parse(createTime) : true) &&
      (to ? new Date(to).setHours(23, 59, 59, 999) >= Date.parse(createTime) : true) &&
      (value?.month instanceof Array ? (value?.month.length > 0 ? value?.month.find((e: any) => e.value === item.monthNumber) : true) : true) &&
      (value?.createYear instanceof Array ? (value?.createYear.length > 0 ? value?.createYear.find((e: any) => e.value === item.createYear) : true) : true) &&
      (value?.status instanceof Array ? (value?.status.length > 0 ? value?.status.find((e: any) => e.value === item.status.value) : true) : true) &&
      (searchInModeLower(item?.name, value?.search) ||
        searchInModeLower(item?.companyName, value?.search) ||
        searchInModeLower(item?.city, value?.search) ||
        searchInModeLower(item?.state, value?.search) ||
        searchInModeLower(item?.status.text, value?.search) ||
        searchInModeLower(item?.address, value?.search) ||
        searchInModeLower(item?.phone, value?.search) ||
        searchInModeLower(item?.zipCodeCp, value?.search))
    )
  })
}

export const handleErrors = (values: any, t: any, ignore?: string[]) => {
  let error = {} as ObjectGeneric

  if (values?.name !== null) {
    if (isRequired(values?.name)) error.name = t('general.validations.required')
  }

  if (values?.companyName !== null) {
    if (isRequired(values?.companyName)) error.companyName = t('general.validations.required')
  }

  if (values?.address !== null) {
    if (isRequired(values?.address)) error.address = t('general.validations.required')
  }

  if (values?.zipCode !== null) {
    if (isRequired(values?.zipCode)) error.zipCode = t('general.validations.required')
  }

  if (values?.status !== null) {
    if (isRequired(values?.status)) error.status = t('general.validations.required')
  }

  if (values?.phone !== null) {
    /*  if (values?.phone.length < 3) error.phone = `${t('general.validations.required')}`
    else  */ if (values?.phone.length > 3 && !isPhoneNumber(values?.phone)) error.phone = `${t('general.validations.min')} 10 ${t('general.validations.character')}`
  }
  return error
}

export const submit = async (data: ObjectGeneric, t: TFunction<'translation', undefined>, id: number | undefined) => {
  let snackbarBody = {
    open: false,
    type: '',
    message: '',
  }
  let body = {} as any
  Object.entries(data).forEach(([key, value]) => {
    const dataToSubmit = ['name', 'companyName', 'states', 'cities', 'address', 'zipCode', 'city', 'state']
    if (dataToSubmit.includes(key)) {
      body = {
        ...body,
        [key]: value ?? '',
      }
    } else if (key === 'phone') {
      body = {
        ...body,
        [key]: isPhoneNumber(value) ? value : '',
      }
    }
  })

  let response: any

  body = {
    ...body,
    ...{ logo: data?.logo?.name ? await sharedAws.uploadAndGetUrl({ file: data.logo, otherBucket: process.env.REACT_APP_BUCKET }) : data?.logo },
    statusId: data.status,
  }
  delete body.states
  delete body.cities
  if (id) {
    if (!body.logo) body.logo = ''
    response = await companyService(httpFetch).updateCompanies(body, id)
  } else {
    response = await companyService(httpFetch).createCompanies(body)
  }

  if (response.errors) {
    snackbarBody = {
      open: true,
      type: Types.ERROR as string,
      message: t(`companie.snackbar.${id ? 'update' : 'create'}.error`),
    }
  } else {
    snackbarBody = {
      open: true,
      type: Types.SUCCESS as string,
      message: t(`companie.snackbar.${id ? 'update' : 'create'}.success`),
    }
    if (data?.divisions || data?.zones) {
      let responseAssociateZone
      let responseAssociateDivision

      if (data?.divisions) {
        responseAssociateDivision = await divisionService(httpFetch).associateDivisionToCompanie({
          companies: [response?.id],
          divisions: ((data.divisions ?? []) as Table['row']).map((item) => item.value),
        })
      }
      if (data.zones) {
        responseAssociateZone = await companyService(httpFetch).associateZonesCompanies({
          companies: [response?.id],
          zones: ((data.zones ?? []) as Table['row']).map((item) => item.value),
        })
      }
      const errorDivision = responseAssociateDivision?.errors
      if (responseAssociateZone?.errors && responseAssociateDivision?.errors) {
        snackbarBody = {
          open: true,
          type: Types.ERROR as string,
          message: handleAllErrors(errorDivision?.[0]?.code, t, `companie.snackbar.associateDivisionAndZone`),
        }
      } else if (responseAssociateDivision?.errors) {
        snackbarBody = {
          open: true,
          type: Types.ERROR as string,
          message: handleAllErrors(errorDivision?.[0]?.code, t, `companie.snackbar.associateDivision`),
        }
      } else if (responseAssociateZone?.errors) {
        snackbarBody = {
          open: true,
          type: Types.ERROR as string,
          message: handleAllErrors(responseAssociateZone?.errors?.[0]?.code, t, `companie.snackbar.associateZone`),
        }
      }
    }
  }

  return { snackbarBody, response }
}
