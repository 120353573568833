const progressAgregation = {
  en_progreso: {
    sum: {
      field: 'en_progreso',
    },
  },
  por_iniciar: {
    sum: {
      field: 'por_iniciar',
    },
  },
  finalizado: {
    sum: {
      field: 'finalizado',
    },
  },
  incompleto: {
    sum: {
      field: 'incompleto',
    },
  },
  vencido: {
    sum: {
      field: 'vencido',
    },
  },
  cancelado: {
    sum: {
      field: 'cancelado',
    },
  },
}


const keysProgramationGraphic = {
  canceled: "canceled",
  expired: "expired",
  finished: "finished",
  noStarted: "noStarted",
  progress: "progress",
  incompleto: "incompleto",
  total: "total",
} as const

const labelsAreaProgramationGraphic = {
  canceled: "dashboard.kpi.canceled",
  expired: "dashboard.kpi.expired",
  finished: "dashboard.kpi.finished",
  noStarted: "dashboard.kpi.noStarted",
  progress: "dashboard.kpi.inProgress",
  incompleto: "dashboard.kpi.inclomplete",
  total: "dashboard.kpi.total",
} as const

const degrant:Record<string, string> = {
  6: "ff",
  5: "dd",
  4: "bb",
  3: "99",
  2: "77",
  1: "55"
}
export { degrant, progressAgregation, keysProgramationGraphic, labelsAreaProgramationGraphic }