import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Types } from '../../utils/enums'
import { downloadBlob } from '../../utils/methods/downloadFiles'
import { GetBlob } from '../../utils/types/generals'

const useDownload = (data: { downloadFuntion: (data: { type: 'excel' | 'pdf' | 'word'; query?: string | undefined }) => Promise<GetBlob>; query?: string; docName: string; errorName?: string }) => {
  const { t } = useTranslation()
  const ref = useRef<NodeJS.Timeout | null>(null)
  const [download, setDownload] = useState({
    excel: false,
    pdf: false,
    word: false,
  })
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
    type: Types.SUCCESS as string,
  })

  const closeOpenSnakbar = () => {
    setOpenSnackbar((prev) => ({
      ...prev,
      open: false,
    }))
  }

  const clearSnackbar = () => {
    if (ref.current) {
      clearTimeout(ref.current)
    }
  }

  const handleDownload = ({ file }: { file: 'pdf' | 'word' | 'excel' }) => {
    setDownload((prev) => ({
      ...prev,
      [file]: true,
    }))

    setOpenSnackbar({
      message: t('general.waitAMoment'),
      open: true,
      type: Types.INFO,
    })

    clearSnackbar()

    ref.current = setTimeout(closeOpenSnakbar, 3000)

    data
      ?.downloadFuntion({ type: file, query: data?.query })
      .then((response) => {
        if (!response.errors) {
          downloadBlob(response, data?.docName)
        } else {
          clearSnackbar()

          setOpenSnackbar({
            message: data?.errorName ?? t('schedule.snackbar.download.error.pdf'),
            open: true,
            type: Types.ERROR,
          })
        }
      })
      .catch(() => {
        clearSnackbar()

        setOpenSnackbar({
          message: data?.errorName ?? t('schedule.snackbar.download.error.pdf'),
          open: true,
          type: Types.ERROR,
        })
      })
      .finally(() => {
        setDownload((prev) => ({
          ...prev,
          [file]: false,
        }))
      })
  }

  const downloadCSV = () => {
    handleDownload({ file: 'excel' })
  }

  const downloadPDF = () => {
    handleDownload({ file: 'pdf' })
  }

  const downloadWORD = () => {
    handleDownload({ file: 'word' })
  }

  const downloadCustome = (type?: 'excel' | 'pdf' | 'word') => {
    handleDownload({ file: type ?? 'excel' })
  }

  return { download, openSnackbar, downloadCSV, downloadPDF, downloadWORD, closeOpenSnakbar, downloadCustome }
}

export { useDownload }
