export const useStyles = () => ({
  container: {
    '& .MuiGrid-root': {
      justifyContent: 'center',
    },
  },
  loader: {
    height: 'calc(100% - 280px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
