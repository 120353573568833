import { format } from 'date-fns'
import { TFunction } from 'react-i18next'
import { getStatus } from '../../../../hooks/status'
import { UserService } from '../../../../service'
import { Constants } from '../../../../utils/constants'
import { httpFetch } from '../../../../utils/crud'
import { RequestErrors, Status, Types } from '../../../../utils/enums'
import { Color } from '../../../../utils/theme/types/color'
import { InputModel } from '../../../../utils/types'
import { isEmail, isPassword, max, min, searchInModeLower, isPhoneNumber } from '../../../../utils/validate/validate'
import { adminServiceFetch } from '../../../../utils/constants/callService'

export const getUserList = async ({ colors, t, query }: { colors: Color, t: TFunction<'translation', undefined>, query?: string }) => {
  const data = await adminServiceFetch.getAdminsByPagination({ query })

  const column: any[] = [
    {
      id: 'curp',
      label: t('general.tags.curp'),
      minWidth: 100,
      sortable: true,
    },
    {
      id: 'firstName',
      label: t('general.tags.name'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'lastName',
      label: t('general.tags.lastName'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'gender',
      label: t('general.tags.gender'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'email',
      label: t('general.tags.email'),
      minWidth: 100,
    },
    {
      id: 'phone',
      label: t('general.tags.phone'),
      minWidth: 100,
    },
    {
      id: 'createTime',
      label: t('general.tags.createTime'),
      minWidth: 100,
    },
    {
      id: 'status',
      label: t('general.tags.status'),
      minWidth: 100,
    },
  ]

  const row: any[] = !data?.errors
    ? data?.items?.map((item) => {
      let status: Status = item?.statusId

      return {
        id: item?.id,
        curp: item?.curp,
        firstName: item?.firstName,
        lastName: item?.lastName,
        gender: item?.gender >= 0 && {
          value: item?.gender,
          label: item?.gender >= 0 && t(`general.fields.gender.${item?.gender + 1}`),
        },
        email: item?.email,
        phone: item?.phone ?? '',
        createTime: item.createTime ? format(new Date(item.createTime), Constants.dateFormatGeneral) : 'N/A',
        status: getStatus(status, colors, t),
      }
    })
    : []

  return { column, row, totalPages: data.totalPages, totalItems: data.totalItems }
}

export const filterRow = (value: { [x: string]: any }, list: any[]) => {
  const from = value.from?.valueOf()
  const to = value.to?.valueOf()

  return list.filter((item) => {
    return (
      (from ? new Date(from).setHours(0, 0, 0, 0) <= item?.createTime?.value : true) &&
      (to ? new Date(to).setHours(23, 59, 59, 999) >= item?.createTime?.value : true) &&
      (value?.month instanceof Array ? (value?.month.length > 0 ? value?.month.find((e: any) => e.value === item.monthNumber) : true) : true) &&
      (value?.createYear instanceof Array ? (value?.createYear.length > 0 ? value?.createYear.find((e: any) => e.value == item.year) : true) : true) &&
      (value?.status instanceof Array ? (value?.status.length > 0 ? value?.status.find((e: any) => e.label == item.status.text) : true) : true) &&
      (searchInModeLower(item?.firstName, value?.search) ||
        searchInModeLower(item?.lastName, value?.search) ||
        searchInModeLower(item?.email, value?.search) ||
        searchInModeLower(item?.status.text, value?.search) ||
        searchInModeLower(item?.curp, value?.search) ||
        searchInModeLower(item?.phone, value?.search) ||
        searchInModeLower(item?.gender?.label, value?.search) ||
        searchInModeLower(item?.role?.label, value?.search))
    )
  })
}

export const handleErrors = (values: any, t: any, curpExist?: boolean) => {
  let error = {} as {
    curp: string
  }

  if (values?.curp?.value !== null) {
    if (values?.curp?.value?.length > 0 && min(values?.curp?.value, 10)) error.curp = `El campo CURP debe contener mínimo 10 ${t('general.validations.character')}`
    else if (curpExist) error.curp = t('general.validations.existedCurp')
  }

  return error
}

export const submit = async (data: { [x: string]: InputModel }, t: TFunction<'translation', undefined>, id: string | undefined, oldCurp?: string) => {
  const username = data?.username?.value
  let snackbarBody = {
    open: false,
    type: '',
    message: '',
  }
  const curp = data?.curp?.value
  
  let body = {
    userTypeId: data?.userType?.value?.value ?? data?.userType?.value ?? null,
    companyId: data?.companyId?.value?.value ?? data?.companyId?.value ?? null,
    curp: (!curp || curp?.length < 10) ? '0000000000' : curp,
    divisionId: data?.divisions?.value?.value ?? data?.divisions?.value ?? null,
    email: data?.email?.value,
    firstName: data?.firstName?.value,
    lastName: data?.lastName?.value,
    gender: data?.gender?.value,
    phone: isPhoneNumber(data?.phone?.value) ? data?.phone?.value : '',
    picture: data?.picture?.value ?? '',
    statusId: data?.status?.value ?? 1,
    username: data?.username?.value,
    password: data?.password?.value,
  }
  
  let response: any
  let responseCurp: { curpExists: boolean } = { curpExists: false }

  if (body?.curp !== oldCurp && body.curp != '0000000000') {
    responseCurp = await UserService(httpFetch).curpExist(body?.curp)
  }

  if (!responseCurp?.curpExists) {
    if (id) {
      delete body[username === '0' ? 'email' : 'phone']
      delete body.password
      
      response = await adminServiceFetch.updateAdmin(body, id)
    } else {
      body = {
        ...body,
        username: username === '0' ? data.email.value : data.phone.value,
      }
      response = await adminServiceFetch.createAdmin(body)
    }
    const errors = response.errors?.response?.data?.error ?? response.errors
    let message = ''

    switch (errors) {
      case RequestErrors.ERR_ALREADY_EXISTS:
        message = t('general.messages.errors.ERR_ALREADY_EXISTS')
        break
      default:
        message = t(`user.snackbar.${id ? 'update' : 'create'}.error`)
    }

    if (response?.errors && RequestErrors.ERR_EMAIL !== errors) {
      snackbarBody = {
        open: true,
        type: Types.ERROR as string,
        message,
      }
    } else {
      snackbarBody = {
        open: true,
        type: Types.SUCCESS as string,
        message: t(`user.snackbar.${id ? 'update' : 'create'}.success`),
      }
    }
    return {
      snackbarBody,
      response: response?.errors
        ? {
          errors: response.errors?.response?.data?.error ?? response.errors,
        }
        : response,
    }
  }
  return { curpExist: true }
}

export const disabled = (body: { [x: string]: InputModel }, isUserName?: string) => {
  let value = {
    ...body,
    password: {
      ...body.password,
      validate: {
        required: !isUserName,
        password: !isUserName,
      } as {
        [x: string]: boolean
      },
    },
  }

  return !!Object.values(value).find(
    (item) =>
      (!!item.validate?.required && !item.value) ||
      (!!item.validate?.password && (!isPassword(item.value) || min(item.value, 8) || max(item.value, 10))) ||
      (!!item.validate?.email && !isEmail(item.value)) ||
      (!!item.validate?.phone && min(item.value, 10)) ||
      (item.name === 'email' && !item?.validate?.email && item.value && !isEmail(item.value))
  )
}
