import { Box, IconButton, Typography } from "@mui/material"
import { useStyles } from "./styles"
import { BorderLinearProgress } from "../../../../../../components/linearProgressBar"
import { icons } from "../../../../../../utils/icons"
import Loader from "../../../../../../components/loader"
import { useTranslation } from "react-i18next"

const CardIndicatorProgress = (props: {
  title: string; refresh: () => void; loading: boolean; data: {
    label: string;
    total: number;
    completed: number;
  }[]
}) => {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <Box sx={styles.container}>
      {
        !props.loading ? (
          <>
            <Box sx={styles.header}>
              <Box>
                <Typography sx={styles.title}>{props.title}</Typography>
              </Box>

              <IconButton
                sx={styles.refresh}
                onClick={() => {
                  props.refresh()
                }}
              >
                {icons("refresh")}
              </IconButton>
            </Box>

            <Box sx={styles.detailContainer}>
              {
                !!props.data?.length ?
                  props.data?.map((item, index) => (
                    <Box key={index} sx={styles.containerDetail}>
                      <Typography sx={styles.label}>{item.label}</Typography>

                      <Box sx={styles.containerLinear}>
                        <BorderLinearProgress variant="determinate" value={(item.completed / item.total) * 100} />

                        <Typography sx={styles.label}>{item.completed}/{item.total}</Typography>
                      </Box>
                    </Box>
                  ))
                  : (
                    <Typography sx={styles.noData}>
                      {t('reportFolio.detail.general.noInformation')}
                    </Typography>
                  )
              }
            </Box>
          </>
        )
          :
          <Loader />
      }
    </Box>
  )
}

export { CardIndicatorProgress }