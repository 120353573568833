import { useState, useEffect } from 'react'
import { Item } from '../../../../../../../utils/types/generals'
import { InputModel } from '../../../../../../../utils/types'
import { removeAllReference } from '../../../../../../../utils/methods/removedReference'
import { createEdit, filterCrud, settingInputs } from '../constants/forms'

const useInput = ({ items, itemsSetting }: { items?: Record<string, Item[]>; itemsSetting?: Record<string, Item[]> }) => {
  const [input, setInput] = useState<{ [x: string]: InputModel }>(createEdit)
  const [inputFilter, setInputFilter] = useState<{ [x: string]: InputModel }>(filterCrud)
  const [settingInput, setSettingInput] = useState<{ [x: string]: InputModel }>(settingInputs)

  const disabledInputs = (data: { inputs: string[]; value: boolean }) => {
    let copyInput: {
      [x: string]: InputModel
    } = removeAllReference(input)

    data?.inputs?.forEach((item) => {
      copyInput[item] = {
        ...copyInput[item],
        rest: {
          ...(copyInput[item]?.rest ?? {}),
          disabled: data.value,
        },
      }
    })

    setInput((prev) => ({
      ...prev,
      ...copyInput,
    }))
  }

  useEffect(() => {
    let body = {}
    Object.entries(input).forEach(([key, itemInputs]) => {
      body = { ...body, [key]: { ...itemInputs, items: items?.[key] } }
    })

    setInput(body)
    // eslint-disable-next-line
  }, [items])

  useEffect(() => {
    let body = {}
    Object.entries(inputFilter).forEach(([key, itemInputs]) => {
      body = { ...body, [key]: { ...itemInputs, items: items?.[key] } }
    })

    setInputFilter(body)
    // eslint-disable-next-line
  }, [items])
  
  useEffect(() => {
    let body = {}
    Object.entries(settingInput).forEach(([key, itemInputs]) => {
      body = { ...body, [key]: { ...itemInputs, items: itemsSetting?.[key] } }
    })

    setSettingInput(body)
    // eslint-disable-next-line
  }, [itemsSetting])
  return { input, settingInput, inputFilter, disabledInputs }
}

export { useInput }
