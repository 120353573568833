import { useCallback, useEffect, useState } from 'react'
import {
  getUserTypes,
  getUsers,
} from '../../../../../utils/methods/geServiceWithItemsFormat'
import { Roles } from '../../../../../utils/enums'
import {
  questionnaire,
  userScheduleServiceFetch,
} from '../../../../../utils/constants/callService'
import { Item } from '../../../../../utils/types/generals'
import { getLocalStorage } from '../../../../../utils/crud'

const useGetDAta = (payload: { id?: string }) => {
  const state = getLocalStorage()
  const [list, setList] = useState<
    Record<string, Record<string, string | number | undefined | null>[]>
  >({})
  const [listItems, setListItems] = useState<Record<string, Item[]>>({})
  const [userAndQuestionnaireDetail, setUserAndQuestionnaireDetail] = useState<Record<string, string | number>>({})
  const [isLoadingEndpoints, setIsLoadingEndpoints] = useState<
    Record<string, boolean>
  >({})

  const getAllUsers = useCallback(async () => {
    setIsLoadingEndpoints((prev) => ({
      ...prev,
      users: true,
    }))
    const { all } = await getUsers({ skip: [Roles.ADMIN] })
    let items: Item[] = [

    ]
    let body: {
      id: number,
      fullname: string,
      curp: string,
      userType: string,
      userTypeId: number,
      email: string,
      phone: string,
    }[] = []

    if (Roles.ADMIN !== state.role) {
      body.push({
        id: state.id,
        fullname: state.fullName,
        curp: state.curp ?? "",
        userType: state.userType?.name,
        userTypeId: state.userType?.id,
        email: state.email,
        phone: state.phone,
      })
    }
    body = [
      ...body,
      ...all?.map((item) => {
        items.push({
          label: `${item.firstName ? `${item.firstName} ` : ''}${item.lastName}`,
          value: item.id,
        })
        return {
          id: item.id,
          fullname: `${item.firstName ? `${item.firstName} ` : ''}${item.lastName
            }`,
          curp: item.curp,
          userType: item.userType,
          userTypeId: item.userTypeId,
          email: item.email,
          phone: item.phone,
        }
      }) || []
    ]

    setIsLoadingEndpoints((prev) => ({
      ...prev,
      users: false,
    }))

    setListItems((prev) => ({
      ...prev,
      users: items,
    }))
    setList((prev) => ({
      ...prev,
      users: body ?? [],
    }))
  }, [])

  const getAllQuestionnaires = useCallback(async () => {
    setIsLoadingEndpoints((prev) => ({
      ...prev,
      questionnaires: true,
    }))
    const all = await questionnaire.getAllQuestionariesOld({ type: false })
    let body: any[] = []
    let items: Item[] = []

    if (!all.errors) {
      body = all.map((item) => {
        let action_plan = item.actionPlanByItem
        let actionType = item.actionPlanByItem === 'Por Item' ? '0' : '1'

        if (typeof item.actionPlanByItem === 'boolean') {
          action_plan = item.actionPlanByItem ? 'Por Item' : 'Por Cuestionario'
          actionType = !!item.actionPlanByItem ? '0' : '1'
        }

        items.push({
          label: item.name,
          value: item.id,
        })

        return {
          id: item.id,
          questionnaireName: item.name,
          askAmount: item.countQuestions,
          actionPlan: action_plan,
          actionType,
          appAmount: item.countQuestionnaireVisit,
        }
      })
    }
    setIsLoadingEndpoints((prev) => ({
      ...prev,
      questionnaires: false,
    }))
    setListItems((prev) => ({
      ...prev,
      questionnaires: items,
    }))
    setList((prev) => ({
      ...prev,
      questionnaires: body,
    }))
  }, [])

  const getUserType = useCallback(async () => {
    const { items } = await getUserTypes()

    setListItems((prev) => ({
      ...prev,
      userTypes: items,
    }))
  }, [])

  const getDetailtSchedule = useCallback(async () => {
    if (!!payload?.id) {
      const response = await userScheduleServiceFetch.getDetail({
        id: payload.id,
      })
      const body = {
        id: response.userAssign.id,
        fullname: `${response.userAssign.firstName ? `${response.userAssign.firstName} ` : ''}${response.userAssign.lastName
          }`,
        curp: response.userAssign.curp,
        userType: response.userAssign.userTypeId,
        userTypeId: response.userAssign.userTypeId,
        email: response.userAssign.email,
        phone: response.userAssign.phone,
      }

      setUserAndQuestionnaireDetail(prev => ({
        ...prev,
        user: response.userAssignId,
        questionnaire: response.questionnaireMainId,
        userAndQuestionnaire: JSON.stringify({
          from: response.dateStart,
          to: response.dateEnd,
          notifyAdmin: response.notifyAdmin,
          notified: response.notified
        })
      }))
      setList((prev) => ({
        ...prev,
        users: [body],
      }))
    }
  }, [payload?.id])

  const getData = useCallback(() => {
    if (!payload?.id) {
      getAllUsers()
    } else {
      getDetailtSchedule()
    }
    getUserType()
    getAllQuestionnaires()
  }, [
    getAllUsers,
    getAllQuestionnaires,
    getUserType,
    getDetailtSchedule,
    payload?.id,
  ])

  useEffect(() => {
    getData()
  }, [getData])

  return { list, isLoadingEndpoints, listItems, userAndQuestionnaireDetail }
}

export { useGetDAta }
