import { Color } from "../../utils/theme/types/color"


export const useStyles = (color: Color) => {
  return {
    detailSelected: {
      background: color.color25,
      // alignItems: 'center',
      padding: '10px 5px',
      marginTop: '-8px',
      borderBottom: `2px solid #00000022`,
      '&:last-child': {
        borderBottom: `none`,
      }
    },
    containerDetail: ({maxHeight}:{maxHeight?:number}) => ({
      overflowY: 'auto',
      maxHeight: maxHeight ? `${maxHeight}px` : '360px',
      position: 'relative'
    }),
    text: {
      fontSize: '.8rem',
      color: color.color13,
    },
    detailTitle: {
      fontWeight: '700',
    },
    title: {
        fontSize: '16px',
        fontWeight: '600',
        color: color.color4,
        marginTop: '20px'
    },
    subTitle: {
      fontSize: '16px',
      fontWeight: '600',
      color: color.color26,
      margin: '10px 0'
    },
    sectionTitle: {
        margin: '10px 0',
        fontSize: '16px',
        color: color.color10
    },
    close: {
      position: 'absolute',
      right: '10px',
      fontSize: '1rem',
      padding: '4px',
      color: color.color19,
      '& .MuiSvgIcon-root': {
        fontSize: '1em'
      }
    }
  }
}
