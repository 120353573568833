import { useContextState } from "../../../../../../../../hooks/theme"

const useUnitStyles = () => {
    const { colors } = useContextState()
    return {
        headetText: {
            color: colors.color5,
            fontWeigt: '800',
            fontSize: '12px',
        },

        containerUnit: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        unitTitle: {
            fontSize: '14px',
            fontWeight: '600',
            color: colors.color9,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
        },
        unitContent: {
            fontSize: '12px',
            color: colors.color10,
            width: '100%',
        },
        user: {
            fontSize: '12px',
            fontStyle: 'italic',
            color: colors.color10,
            width: '100%',
        },
    }
}

export { useUnitStyles }
