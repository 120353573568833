enum PercentageStatus {
    RED = "RED",
    WINE_RED = "WINE_RED",
    ORANGE = "ORANGE",
    YELLOW = "YELLOW",
    GREEN = "GREEN"
}

enum DivisionsDataHistorigram {
    ACTIVE_UNITS = 1
}

export { PercentageStatus, DivisionsDataHistorigram }
