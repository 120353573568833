import { Navigate, Route, Routes } from 'react-router-dom'
import Company from '../pages/company'
import AdminUsers from '../pages/adminUsers'
import OptionGroup from '../pages/optionGroup'
import BrandCustomization from '../pages/company/brandCustomization'
// import Area from '../pages/area'
// import Department from '../pages/departments'

const Setting = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="admin-users" />} />
      <Route path="admin-users" element={<AdminUsers />} />
      <Route path="companies" element={<Company />} />
      <Route path="companies/:id" element={<BrandCustomization />} />
      <Route path="option-group" element={<OptionGroup />} />
    </Routes>
  )
}

export default Setting
