export enum EvidencesTypeEnum {
  IMAGE = 'image',
  VIDEO = 'video',
  SIGN = 'sign',
  COMMENT = 'comment',
  CAMERA = 'camera',
  VIDEO_GALLERY = 'video_gallery',
  FILE = 'file',
}

export enum EvidencesTypeValueEnum {
  IMAGE = 1,
  VIDEO = 2,
  SIGN = 3,
  DOCUMENT = 4,
  COMMENT = 5,
  CAMERA = 6,
  VIDEO_GALLERY = 7,
  FILE = 8,
}

export type EvidencesString = 'image' | 'video' | 'sign' | 'comment' | 'camera' | 'video_gallery' | 'file'
