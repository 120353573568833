import { Color } from '../../../../../utils/theme/types/color'

export const useStyles = ({ color, show }: { color: Color; show?: boolean }) => {
  return {
    container: {
      padding: '50px 50px 30px 10px',
      width: '100%',
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    containerHeaderLeft: {
      display: 'flex',
      justifyContent: 'end'
    },
    generalText: {
      fontWeight: '600',
      color: color.color13,
      width: 'fit-content',
    },
    fW: {
      width: '100%',
    },
    companyTitle: {
      fontSize: '20px',
    },
    fit: {
      width: 'fit-content'
    },
    refresh: {
      fontSize: '13px',
      color: color.color4,
      cursor: 'pointer'
    },
    titleApp: {
      fontSize: '16px',
      marginTop: '1px'
    },
    companySubTitle: {
      fontSize: '13px',
      marginBottom: '10px',
    },
    containerCardModel: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      rowGap: '20px',
      flexWrap: 'wrap',
    },
    iconBack: {
      color: color.color4,
      fontWeight: '600',
    },
    cardModel: {
      width: '100%',
      borderRadius: '10px',
      boxShadow: `0 0 4px ${color.color13}`,
      padding: '50px',
    },
    flex: {
      display: 'flex',
      rowGap: '20px',
      flexWrap: 'wrap',
    },
    img: {
      borderRadius: '0',
      width: '30px',
      height: '30px',
      margin: '10px auto 0',
      objectFit: 'contain',
    },
    smallColors: {
      width: '100%',
      margin: '10px 0',
      height: 'fit-content',
    },
    containerColors: {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px"
    },
    description: {
      fontSize: '12px',
      fontWeight: '500',
      color: color.color13,
      marginTop: '5px',
    },
    between: {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    mainTitle: {
      fontSize: '16px',
      fontWeight: '600',
      color: color.color13,
      width: 'fit-content',
    },
    title: {
      fontSize: '14px',
      fontWeight: '600',
      color: color.color13,
      width: 'fit-content',
      textAlign: 'center',
      marginBottom: '20px',
    },
    normalTitle: {
      fontSize: '14px',
      fontWeight: '600',
      color: color.color13,
      width: 'fit-content',
      textAlign: 'center',
    },
    title2: {
      fontSize: '12px',
      fontWeight: '400',
      color: color.color13,
      width: 'fit-content',
    },
    link: {
      color: color.color16,
      textDecoration: 'underline',
      fontWeight: '600',
      fontSize: '14px',
      cursor: 'pointer',
      width: '100%',
    },
    containerFull: {
      width: '100%',
      '& .MuiSvgIcon-root': {
        color: color.color16,
      },
    },
    saveConfigurations: {
      display: 'flex',
      justifyContent: 'end',
      marginTop: '20px',
      // '& .MuiButton-root': {
      //   background: color.color4,
      //   color: color.color5
      // }
    },
    show: {
      paddingTop: !!show ? '0' : '16px',
    },
  }
}
