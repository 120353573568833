import { useContextState } from '../../../../hooks/theme'

const useStyles = () => {
  const { colors } = useContextState()

  return {
    container: {
      paddingRight: '50px',
      paddingTop: '50px',
      width: '100%',
      height: '100%',
      position: 'relative',
    },
    gridContainer: {
      height: 'calc(100% - 100px)',
      width: '100%',
      marginLeft: '-5px',
    },
    lastUpdate: {
      lineHeight: '1rem',
      paddingLeft: '10px',
      fontSize: '.8rem',
      '& span': {
        color: colors.color16,
      },
    },
    map: {
      height: 'calc(100%)',
      width: '100%',
      borderRadius: '10px',
      overflow: 'hidden',
      border: `1px solid ${colors.color13}55`,
    },
    userListContainer: {
      padding: '20px',
      height: 'calc(100%)',
      borderRadius: '10px',
      boxShadow: `0 0 4px ${colors.color13}`,
      '& .user-list-item': {
        marginTop: '10px',
        '&:first-child': {
          marginTop: 0,
        },
      },
    },
    headerActions: {
      display: 'flex',
      alignItems: 'center',
      flex: 'wrap',
      width: '100%',
      height: 'fit-content',
      // padding: '0px 25px 0px',
    },
    currentUser: {
      background: colors.color4,
      '& .detail-value': {
        color: colors.color5,
      },
      '& .detail-caption': {
        color: colors.color5,
      },
    },
    label: {
      '& .MuiFormControlLabel-label': {
        wordBreak: 'break-word',
        fontSize: '13px',
      },
    },
    select: {
      color: colors.color16,
      fontWeight: '500',
      fontSize: '11px',
      padding: '0px 4px',
      cursor: 'pointer',
    },
    notFound: {
      marginTop: '20px',
      color: `${colors.color13}99`,
      fontSize: '16px',
      fontWeight: '500'
    },
    line: {
      color: colors.color16,
      lineHeight: '2.5',
      fontSize: '13px',
    },
    userListItemsContainer: {
      width: '100%',
      padding: '10px 2px',
      overflowY: 'auto',
      height: 'calc(100vh - 320px)',
    },
    switch: {
      width: '100%',
    },
    userTitle: {
      fontSize: '13px',
      // fontWeight: '500',
    },
    userListItems: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      flex: 'wrap',
      width: 'calc(100%)',
      overflowX: 'hidden',
      padding: '5px 5px',
      // borderRadius: '10px',
      // boxShadow: `0 0 4px ${colors.color13}`,
      height: 'fit-content',
    },
    user: {
      width: 'calc(100% - 20px)',
      '& .detail-container': {
        padding: '0px',
      },
    },
    fullName: {
      fontSize: '12px',
    },
    check: {
      marginRight: '10px',
      display: 'flex',
      justifyContent: 'center',
      width: '20px',
      '& .MuiCheckbox-root': {
        padding: '0px',
      },
    },
    section: {
      marginBottom: '32px',
      display: 'flex',
      flexWrap: 'wrap',
      rowGap: '20px',
    },
    title: {
      fontSize: '20px',
      fontWeight: '600',
      color: colors.color13,
      paddingLeft: '10px',
    },
    clean: {
      fontSize: '2rem',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
      '&.flex-j-e': {
        justifyContent: 'end',
      },
    },
    containerCount: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '5px'
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '20px',
    },
    hide: {
      visibility: 'hidden',
    },
  }
}

export { useStyles }
