import { useTranslation } from "react-i18next";
import { DinamicFormRefModel } from "../../../../components/dynamicFormRef/types";
import { InputModel } from "../../../../utils/types";
import { Item } from "../../../../utils/types/generals";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { ItemsInput } from "./typing/types";


const formData: Record<string, string> = {
  id: 'id',
  quest_name: 'name',
  totalPoints: "total_points",
  action_plan: "action_plan_by_item",
  area: "area"
}

const formatFilter = {
  createYear: "year",
  from: "start_date",
  to: "end_date",
  areas: "areas_ids",
  departments: "departments_ids",
  actionPlanType: "action_plan_by_item"
}

const inputFormat = {
  search: {
    label: 'general.search',
    translate: true,
    name: 'search',
    type: 'text',
    value: null,
  },
  createYear: {
    label: 'general.filterDate.year',
    translate: true,
    name: 'createYear',
    type: 'filter-select',
    items: [],
    value: null,
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  month: {
    label: 'general.filterDate.month',
    translate: true,
    name: 'month',
    type: 'filter-select',
    items: [],
    value: null,
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  from: {
    label: 'general.tags.from',
    translate: true,
    name: 'from',
    type: 'date',
    value: null,
    rest: {
      max: 'to',
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
    },
  },
  to: {
    label: 'general.tags.to',
    translate: true,
    name: 'to',
    type: 'date',
    value: null,
    rest: {
      min: 'from',
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
    },
  },
  areas: {
    label: 'general.tags.area',
    translate: true,
    name: 'areas',
    type: 'filter-select',
    items: [],
    value: null,
    size: {
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
    },
  },
  departments: {
    label: 'general.tags.department',
    translate: true,
    name: 'departments',
    type: 'filter-select',
    items: [],
    value: null,
    size: {
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
    },
  },
  actionPlanType: {
    label: 'general.tags.actionPlanType',
    translate: true,
    name: 'actionPlanType',
    type: 'select-search',
    value: [],
    items: [
      { label: 'Por Cuestionario', value: 'Por Cuestionario' },
      { label: 'Por Ítem', value: 'Por Item' },
    ],
  },
} as Record<string, InputModel>

const useInputsCreate: (payload: Record<string, Item[]>) => Record<string, Record<string, DinamicFormRefModel>> = (payload) => {
  const { t } = useTranslation()
  const [inputCreate, setInputCreate] = useState<Record<string, DinamicFormRefModel>>({})

  useEffect(() => {
    setInputCreate({
      quest_name: {
        id: 'quest_name',
        label: t('Questionarie.fields.questionnarieName'),
        name: 'quest_name',
        type: 'text',
        validations: {
          required: { value: true },
        },
        inputOptions: {
          textFieldProps: {
            inputProps: {
              maxLength: 1024
            }
          }
        },
        wrapper: (payload) => <Grid item xs={12} children={payload.children} key={payload.id} />
      },
      description: {
        id: 'description',
        label: t('Questionarie.fields.description'),
        name: 'description',
        type: 'text',
        wrapper: (payload) => <Grid item xs={12} children={payload.children} key={payload.id} />
      },
      instruction: {
        id: 'instruction',
        label: t('Questionarie.fields.instructions'),
        name: 'instruction',
        type: 'text',
        rows: 4,
        maxRows: 4,
        multiline: true,
        wrapper: (payload) => <Grid item xs={12} children={payload.children} key={payload.id} />
      },
      areas: {
        id: 'areas',
        label: t('general.questions.area'),
        name: 'areas',
        items: payload?.areas,
        type: 'selectSimple',
        wrapper: (payload) => <Grid item xl={6} lg={6} md={6} sm={6} xs={6} children={payload.children} key={payload.id} />,
        selectOptions: {
          child: {
            childKey: 'departments'
          },
        }
      },
      departments: {
        id: 'departments',
        type: 'selectSimple',
        label: t('general.questions.state'),
        name: 'departments',
        items: payload?.departments,
        selectOptions: {
          parent: {
            parentId: "areaId",
            parentKey: "areas"
          }
        },
        wrapper: (payload) => <Grid item xl={6} lg={6} md={6} sm={6} xs={6} children={payload.children} key={payload.id} />
      },
    })
  }, [payload?.departments, payload?.areas])
  return {
    inputCreate
  }
}

const useInput = (items?: ItemsInput) => {
  return {
    search: {
      label: 'general.search',
      translate: true,
      name: 'search',
      type: 'text',
      value: null,
    },
    createYear: {
      label: 'general.filterDate.year',
      translate: true,
      name: 'createYear',
      type: 'filter-select',
      items: items?.createYear ?? [],
      value: null,
      size: {
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      },
    },
    month: {
      label: 'general.filterDate.month',
      translate: true,
      name: 'month',
      type: 'filter-select',
      items: items?.month ?? [],
      value: null,
      size: {
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      },
    },
    from: {
      label: 'general.tags.from',
      translate: true,
      name: 'from',
      type: 'date',
      value: null,
      rest: {
        max: 'to',
      },
      size: {
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
      },
    },
    to: {
      label: 'general.tags.to',
      translate: true,
      name: 'to',
      type: 'date',
      value: null,
      rest: {
        min: 'from',
      },
      size: {
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
      },
    },
    areas: {
      label: 'general.tags.area',
      translate: true,
      name: 'areas',
      type: 'filter-select',
      items: items?.areas ?? [],
      value: null,
      size: {
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
      },
    },
    departments: {
      label: 'general.tags.department',
      translate: true,
      name: 'departments',
      type: 'filter-select',
      items: items?.departments ?? [],
      value: null,
      size: {
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
      },
    },
    actionPlanType: {
      label: 'general.tags.actionPlanType',
      translate: true,
      name: 'actionPlanType',
      type: 'select-search',
      value: [],
      items: [
        { label: 'Por Cuestionario', value: 'false' },
        { label: 'Por Ítem', value: 'true' },
      ],
    },
  } as { [x: string]: InputModel }
}
export {
  inputFormat, formData,
  formatFilter, useInputsCreate, useInput
}
