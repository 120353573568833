import { useContextState } from "../../../../../../hooks/theme"

const useDetailModalStyles = () => {
    const { colors } = useContextState()
    return {
        containerIcons: {
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            '& .MuiIconButton-root': {
              padding: '2px',
            },
          },
          date: {
            fontSize: '.6rem',
            color: colors.color13,
          },
          containerDetails: {
            display: 'flex',
            justifyContent: 'space-between'
          },
          details: {
            alignItems: 'center',
            '& .detail-caption': {
              width: 'fit-content',
              fontWeight: '500'
            },
            '& .detail-value.detail-value': {
              width: 'fit-content'
            },
            padding: '0px'
          },
        icons: {
            marginTop: '-10px',
            textAlign: 'end',
            '& .MuiIconButton-root': {
              paddingLeft: '0',
              borderRadius: '10px',
              color: colors.color4,
            },
            '& .MuiSvgIcon-root': {
              marginRight: '5px',
            },
            '& .MuiTypography-root': {
              fontSize: '10px',
            },
          },
        text2: {
            fontSize: '.8rem',
            color: colors.color13,
          },
        titleAccordion2: {
            fontSize: '14px',
            color: colors.color13,
            fontWeight: '600'
          },
        titleAccordion: {
            display: 'flex',
            width: '100%',
            justifyContent: "space-between",
            position: "relative",
            paddingRight: "40px"
          },
        containerAccordion: {
            width: "100%",
            display: 'flex',
            flexWrap: "wrap",
            rowGap: "10px",
            maxHeight: "600px",
            overflowY: "auto",
            '& .MuiAccordion-root': {
              '&.Mui-expanded': {
                margin: 0,
              },
              background: colors.color25
            },
            '& .MuiAccordionSummary-root.Mui-expanded': {
              minHeight: "fit-content"
            }
          },
        sectionTitle: {
            margin: '10px 0',
            fontSize: '16px',
            color: colors.color10
          },
        modal2: {
            width: '600px',
            minHeight: '400px',
            padding: '30px 0',
            paddingTop:"40px"
          },
          headerModal: {
            padding: "0 20px"
          },
          subTitle: {
            fontSize: '18px',
            fontWeight: '600',
            color: colors.color26,
            marginBottom: '10px',
          },
          content: {
            padding: "0 20px"
          },
    }
}

export { useDetailModalStyles }