import { Status } from '../../../../../../utils/enums'
import { Color } from '../../../../../../utils/theme/types/color'

const handleStatus = ({ color }: { color: string }) => {
  return {
    color: color,
    '& img': {
      color: color,
    },
    '&.MuiIcon-root': {
      // height: 'max-content'
    },
    '& .MuiSvgIcon-root': {
      color: color,
      width: '18px',
    },
  }
}

const useStyles = ({ colors }: { colors: Color }) => {
  return {
    noData: {
      width: '100%',
      '&.MuiTypography-root': {
        textAlign: 'center',
        fontSize: '18px',
        color: `${colors.color13}`,
      },
    },
    TabList: {
      width: '100%',
      '& .MuiTab-root': {
        flex: 1,
        minHeight: '40px',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        backgroundColor: colors.color5,
        color: colors.color10,
        '&.Mui-selected': {
          backgroundColor: `${colors.color10}44`,
          color: colors.color10,
        },
      },
      '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
      },
    },
    documentsContainer: {
      width: '100%',
      maxHeight: '200px',
      overflowY: 'auto',
      padding: '10px 20px',
      display: 'flex',
      flexWrap: 'wrap',
      rowGap: '10px',
    },
    documentName: {
      width: 'calc(100% - 50px)',
      wordBreak: 'break-word',
    },
    documentItems: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      padding: '10px 10px 0px',
      boxShadow: `3px 3px ${colors.color13}30`,
      borderRadius: '5px',
      '&:hover': {
        backgroundColor: `${colors.color22}05`,
      },
      '& .MuiIcon-root': {
        color: colors.color1,
        height: '35px',
        width: '35px',
      },
    },

    paddingComments: {
      padding: '20px 20px 20px 25px',
    },
    comments: {
      width: '100%',
      maxHeight: '300px',
      overflowY: 'auto',
    },
    flex: {
      display: 'flex',
      rowGap: '10px',
      flexWrap: 'wrap',
    },
    title: {
      width: '100%',
      fontSize: '18px',
      fontWeight: '600',
      padding: '0px 20px',
    },
    container: {
      paddingBottom: '10px',
      '& .MuiTypography-root': {
        fontSize: '14px',
        color: colors.color22,
        wordWrap: 'break-word',
      },
      '& .bold': {
        fontWeight: '600',
      },
      '& .bold-blue': {
        fontWeight: '600',
        color: colors.color1,
      },
      '& .full-w': {
        width: '100%',
      },
      '&.border-bottom': {
        borderBottom: `1px solid ${colors.color13}`,
        marginBottom: '10px',
      },
    },
    containerThree: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      '&.border-bottom': {
        borderBottom: `1px solid ${colors.color13}`,
        marginBottom: '10px',
      },
    },
    deleteContainer: {
      width: 'calc(100% - 30px)',
    },
    delete: {
      width: '20px',
      height: '20px',
      fontSize: '20px',
      color: colors.color19,
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    titleAccordion: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    accordionSumary: {
      boxShadow: `3px 3px ${colors.color13}30`,
      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
    },
    accordionDetails: {
      boxShadow: `3px 3px ${colors.color13}30`,
      background: `${colors.color10}10`,
    },
    icon: {
      fontSize: '25px',
      '& img': {
        width: '100%',
      },
    },
    solution: {
      backgroundColor: colors.color1,
      '& .MuiTypography-root': {
        color: colors.color5,
      },
      '& .MuiSvgIcon-root': {
        color: colors.color5,
      },
    },
    [Status.ACTIVE]: handleStatus({ color: colors.color19 }),
    [Status.REJECTED]: handleStatus({ color: colors.color29 }),
    [Status.AWAIT_SOLUTION]: handleStatus({ color: colors.secondary }),
    [Status.RESPONDED]: handleStatus({ color: colors.secondary }),
    [Status.APPROVED]: handleStatus({ color: colors.color8 }),
    [Status.CANCEL]: handleStatus({ color: colors.color4 }),
  }
}

export { useStyles, handleStatus }
