import { Box, Grid, IconButton, Typography } from "@mui/material"
import { DynamicFormRef } from "../../../../../../../../../components/dynamicFormRef"
import { CustomTable } from "../../../../../../../../../components"
import { GeneralBodyProps } from "./types"
import { icons } from "../../../../../../../../../utils/icons"
import { useStyles } from "./styles"
import { forwardRef } from "react"
import { DinamicFormRefValues, DynamicRefRef } from "../../../../../../../../../components/dynamicFormRef/types"
import { useRefs } from "../../../../../../../../../hooks/useReactHookModified"
import { useTranslation } from "react-i18next"

const GeneralBody = forwardRef(<T,>(props: GeneralBodyProps<T>) => {
  const { refsByKey, addRef } = useRefs<DynamicRefRef>()
  const { t } = useTranslation()
  const styles = useStyles()
  return (
    <Grid container spacing={2} sx={styles.container}>
      <Grid item xs={12} className="header-separator">
        <Box sx={styles.header}>
          <Box sx={styles.descriptionContainer}>
            <Typography sx={styles.title}>{props.title}</Typography>
            {(!!props.intervale || props.subtitle) && <Typography sx={styles.intervale}>{props.intervale ?? ""}{props.subtitle ? ` - ${props.subtitle}` : ""}</Typography>}
          </Box>

          <IconButton onClick={() => { props.close() }} sx={styles.close} children={icons('simple-close')} />
        </Box>
      </Grid>

      <Grid item xs={4} className="left-separator">
        <Typography className="filter-title">{t("general.filters")}</Typography>

        <DynamicFormRef
          ref={(element) => addRef({ element, name: "data" })}
          onChange={() => {
            if (refsByKey?.data?.getValues) {
              props.getData(refsByKey?.data.getValues() as Record<string, DinamicFormRefValues>)
            }
          }}
          model={props.input}
          parentWrapper={(parent) => <Box sx={styles.formContainer} children={parent.children} />}
        />
      </Grid>

      <Grid item xs={8} className="table">
        <Box sx={styles.body({ item: props.row?.length })}>
          <CustomTable
            dontRefresh
            defaulPagination={20}
            column={props.colunm}
            row={props.row}
            selected={-1}
            loader={props.loading}
          />
        </Box>
      </Grid>
    </Grid>
  )
})

export { GeneralBody }