import { Color } from '../../../../../../../../utils/theme/types/color'

const useStyles = ({ color }: { color: Color }) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      rowGap: '20px',
      width: '80%',
      justifyContent: 'center',
      alignItems: 'center',
      '& .MuiOutlinedInput-root.MuiOutlinedInput-root': {
        padding: 0,
      },
      '& .MuiOutlinedInput-input.MuiOutlinedInput-input': {
        background: color.color5,
        borderRadius: '5px',
        padding: '10px'
      },
    },
    fullWidth: {
      width: '100%',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'end',
    },
    buttons: {
      padding: '10px',
    },
    buttonColorB: {
      backgroundColor: color.color1,
      color: color.color5,
      '&:hover': {
        backgroundColor: `${color.color1}ee`,
        color: color.color5,
      },
    },
    title: {
      width: '300px',
      color: color.color5,
      textAlign: 'center',
      fontSize: '16px',
      wordBreak: 'break-word',
    },
    emptyStar: {
      color: color.color5,
    },
  }
}

export { useStyles }
