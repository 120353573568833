import { useContextState } from "../../../../../../../../hooks/theme"

const useTimeElapsedStyles = () => {
    const { colors } = useContextState()
    return {
        unitContent: {
            fontSize: '12px',
            color: colors.color10,
            width: '100%',
        },
        unitTitle: {
            fontSize: '14px',
            fontWeight: '600',
            color: colors.color9,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
        },
    }
}

export { useTimeElapsedStyles }
