import { Http } from '../../utils/types'
import { ObjectGeneric } from '../../utils/types/generals'
import { SignIn } from './response'
import { Roles } from '../../utils/enums'

export const Auth = (client: Http) => ({
  singIn: async (body: any):Promise<SignIn> => {
    try {
      return await client.post('auth/signin', body, false, true)
    } catch (e: any) {
      return await { errors: e } as any
    }
  },
  decodeJWT: async (body: ObjectGeneric) => await client.post('auth/decode-jwt', body, false, true),
  resetPassword: async ({body}: {body: ObjectGeneric}) => await client.put('auth/change-password', body),
  requestCode: async ({body}: {body: ObjectGeneric}) => await client.post('auth/send-account-recover-code', body),
  confirmUser: async ({body}: {body: ObjectGeneric}) => await client.post('auth/validate-code', body),
  resendCode: async ({body}: {body: ObjectGeneric}) => await client.post('auth/resend-code', body),
})

export const getUserRole = (): Roles | null => {
  const state = localStorage.getItem('state')
  return state ? JSON.parse(state).role : null
}
