import { useState, useLayoutEffect } from 'react'
import { Box } from '@mui/material'
import { useContextState } from '../../../hooks/theme'
import AuthCard from '../components/authCard'
import img from '../../../assets/images/img1.png'
import AuthForm from '../components/authForm'
import { Images } from '../../../components/image'
import { Value } from '../types'
import { useLocation, useNavigate } from 'react-router-dom'
import { onChangeEvent } from '../../../utils/types/generals'
import { useStyles } from '../utils/styles'
import { validate } from '../utils/validate'
import { Auth } from '../../../service/auth'
import { useInitialState } from './initialStates'
import { images } from '../../../utils/images'
import { SnackSimple } from '../../../components'
import { RequestErrors, Types } from '../../../utils/enums'
import { httpFetch } from '../../../utils/crud'
import { handleAllErrors } from '../../../utils/methods/handleErrors'
export const ConfirmUser = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { colors } = useContextState()
  const styles = useStyles(colors)
  const { value, form, t } = useInitialState()
  const [login, setLogin] = useState<Value>(value)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [openSnackbar, setOpenSnackbar] = useState({
    message: '',
    open: false,
    type: Types.ERROR,
  })
  const isValid = () => Object.values(errors).length > 0 || Object.values(login).findIndex((item) => !item.value) >= 0

  const resendCode = async () => {
    try {
      const user = await Auth(httpFetch).requestCode({ body: { username: login.email.value } })
      if (user?.message) {
        navigate('/auth/reset-password', {
          state: {
            name: login.email.value,
            tmp: location?.state.tmp
          },
        })
      } else setErrors({ email: t('general.messages.errors.consultCode') })
    } catch (error) {
      console.error('error signing up:', error)
      setErrors({ email: t('general.messages.errors.consultCode') })
    }
  }

  async function sign() {
    try {
      setIsLoading(true)
      const user: any = await Auth(httpFetch).confirmUser({ body: { username: login.email.value, code: login.code.value } })
      const code = user.errors?.[0]?.code
      if (!code) {
        setOpenSnackbar(() => ({
          open: true,
          message: t(`general.messages.errors.${!!location?.state?.tmp || !!user.requireChangePassword? 'VALIDATED_ACCOUNT_PASSWORD' : 'VALIDATED_ACCOUNT'}`),
          type: Types.SUCCESS,
        }))
        setTimeout(() => {
          if (!!location?.state?.tmp || !!user.requireChangePassword) {
            navigate('/auth/reset-password', {
              state: {
                name: login.email.value,
                tmp: location?.state?.tmp
              },
            })
          } else {
            resendCode()
          }
        }, 3000)
      } else {
        if (code === RequestErrors.ACC_NO_REQUIRE_VALIDATE) {
          setOpenSnackbar(() => ({
            open: true,
            message: handleAllErrors(code, t, 'general.messages.errors.validateCode'),
            type: Types.INFO,
          }))
          setTimeout(() => {
            navigate('/auth/')
          }, 4000)
        } else {
          setErrors({ code: handleAllErrors(code, t, 'general.messages.errors.validateCode') })
          setIsLoading(false)
        }
      }
      // else setErrors({ code: t('general.messages.errors.validateCode') })
    } catch (error) {
      console.error('error signing up:', error)
      setErrors({ code: t('general.messages.errors.validateCode') })
    } finally {

      setTimeout(() => {
        setIsLoading(false)
      }, 4000)
    }
  }
  const handleChange = ({ target }: onChangeEvent) => {
    const { name, value } = target

    setIsLoading(false)
    const body = {
      ...login,
      [name]: {
        ...login[name],
        value,
      },
    }

    setErrors(validate(body, t))
    setLogin(body)
  }

  useLayoutEffect(() => {
    // if (!location?.state?.name) navigate('/auth/send-code')
  }, [location?.state?.name, navigate])

  return (
    <Box sx={styles.container}>
      <SnackSimple
        time={6000}
        close={() => {
          setOpenSnackbar({
            ...openSnackbar,
            open: false,
          })
        }}
        open={openSnackbar.open}
        type={openSnackbar.type}
        message={openSnackbar.message}
      />
      <AuthCard
        children={<Images src={img} />}
        secondChildren={
          <AuthForm
            errors={errors}
            logo={images.interception}
            title={form.title}
            description={form.description}
            button={form.button}
            value={login}
            onChange={handleChange}
            submit={sign}
            isLoading={isLoading}
            disabled={isValid()}
          />
        }
        footer={{
          label: form.footer,
          navigate: () => navigate('/auth'),
        }}
      />
    </Box>
  )
}
