import { Box, IconButton } from '@mui/material'
import { icons } from '../../utils/icons'
import CrudTableWithFilter from '../modules/crudTableWithFilter'
import { Props } from './props'
import { useStyles } from './styles'

const ModalCrudTable = ({ t, color, table, setInformation, initialFilter, onClose, filter, filterRow, exportFiles, customeHeader }: Props) => {
  const styles = useStyles(color)

  return (
    <Box sx={styles.containerSuccess}>
      <IconButton
        sx={styles.closeSuccess}
        onClick={() => {
          onClose()
        }}
      >
        {icons('simple-close')}
      </IconButton>
      <CrudTableWithFilter
        exportFiles={exportFiles}
        customeHeader={customeHeader}
        colors={color}
        tableData={table}
        filterComponent={{
          title: filter.title,
          filterTitle: filter.filterTitle,
          type: filter.type ?? 2,
          sx: filter.sx,
        }}
        filterDescription={{
          filter: {
            label: filter.label,
            name: 'search',
          },
          sx: styles.filter,
        }}
        filterRow={filterRow}
        setInformation={setInformation}
        initialFilterData={initialFilter}
        hidden={{
          add: true,
          clean: true,
          informativeCard: true,
        }}
      />
    </Box>
  )
}

export default ModalCrudTable
