import GoogleMapReact from 'google-map-react'
import { useEffect, useState } from 'react'
import { Box, Typography, IconButton, Grid, Checkbox } from '@mui/material'
import { MapMarker } from '../components'
import { useStyles } from './styles'
import { FiltersDrawer } from '../../../../components/filtersDrawer'
import { DinamicForm } from '../../../../components/dinamicForm'
import { useFilter } from '../../../../hooks/filter'
import { useInput } from './hooks/useInput'
import { useGetData } from './hooks/useGetData'
import { replaceQuery, returnQuery } from '../../../../utils/methods/returnQuery'
import { formatFilter, mapDefault } from './constants'
import { icons } from '../../../../utils/icons'
import { useTranslation } from 'react-i18next'
import { DetailKeyValue, SnackSimple } from '../../../../components'
import { Types } from '../../../../utils/enums'
import { useCurrentUser } from './hooks/useCurrentUsers'
import { sizes } from './constants/sizes'
import Loader from '../../../../components/loader'
import { FilterProps } from '../../../../hooks/filter/typing'
import { removeAllReference } from '../../../../utils/methods/removedReference'

const Main = () => {
  const styles = useStyles()
  const { t } = useTranslation()
  const [loadGoogleMap, setLoadGoogleMap] = useState(false)
  const { items, localization, loadingLocalizations, callEndpoint, cleanLocalization, departments, handleItemsByExternal, AmountUsersJerarquia } = useGetData()
  const { input } = useInput({ items })
  const [flag, setFlag] = useState(false)
  const externalValidation: FilterProps['externalValidation'] = ({ name, value, body, setFilterData }) => {
    let copyBody: Record<string, any> = removeAllReference(body)
    if (name === 'areas') {
      const { areas, departments: departmentsData } = copyBody
      const newDepartments = departmentsData?.filter((e: any) => areas.find((e2: any) => e2.value === e.areaId))
      let arrayChilds: any[] = []

      areas?.forEach((e: any) => {
        const id = e.value
        const childs = departments.filter((child) => child.areaId === id)
        arrayChilds.push(...childs)
      })

      handleItemsByExternal({ name: 'departments', value: arrayChilds })
      setFilterData((prev) => ({
        ...prev,
        departments: newDepartments,
      }))

      copyBody = {
        ...copyBody,
        departments: newDepartments,
      }
    }
    return copyBody
  }
  const { counter, errors, filterData, filterString, paginationModel, query: queryFilter, onChange, cleanFilter } = useFilter({ externalValidation, formatFilter })

  const { localLocalization, localizationCopy, selected, defaultMap, handleUsers, handleSelectAll, handleUnselectAll, lookForUser } = useCurrentUser({ localization })
  const [copy, setCopy] = useState({
    open: false,
    message: '',
  })

  const handleLocalization = () => {
    callEndpoint({ query: replaceQuery({ query: returnQuery({ filter: filterString }), page: paginationModel.page, size: paginationModel.pageSize }) })
  }

  const handleClean = () => {
    cleanLocalization()
    cleanFilter()
  }

  const handleCloseSnackbar = () => {
    setCopy((prev) => ({
      ...prev,
      open: false,
    }))
  }

  const handleCopy = ({ value, caption }: { value: string; caption: string }) => {
    // setCopy((prev) => ({
    //   ...prev,
    //   message: `${t('general.wasCopied')} ${t(`localization.snackbar.${caption}`)}`,
    //   open: true,
    // }))
  }

  useEffect(() => {
    if (flag) {
      handleLocalization()
    }

    // if(!filterString) {
    //   handleClean()
    // }
    setFlag(true)
    //eslint-disable-next-line
  }, [filterString])

  return (
    <Box style={styles.container as any}>
      {/* HEADER */}
      <Box sx={styles.header}>
        <Box>
          <Typography sx={styles.title}>{t('localization.main.title')}</Typography>
          <Typography sx={styles.lastUpdate}>{t('localization.main.subtitle')}</Typography>
        </Box>
        <Box sx={styles.flex}>
          <IconButton
            sx={(styles.clean, (counter || 0) > 0 ? null : styles.hide)}
            onClick={() => {
              handleClean()
            }}
          >
            {icons('clean')}
          </IconButton>

          {
            !!queryFilter?.filter && (
              <IconButton
                onClick={() => {
                  handleLocalization()
                }}
              >
                {icons('refresh')}
              </IconButton>
            )
          }
        </Box>
      </Box>

      {/* FLOAT */}

      <SnackSimple close={handleCloseSnackbar} open={copy.open} type={Types.INFO} message={copy.message} />

      <FiltersDrawer cleanFilter={cleanFilter} count={counter} right={0} top={50}>
        <Box sx={styles.section}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '600',
              color: '#707070',
            }}
          >
            Filtros
          </Typography>

          <DinamicForm errors={errors} input={Object.values(input)} values={filterData} onChange={onChange} />
        </Box>
      </FiltersDrawer>

      <Grid container columnSpacing={2} sx={styles.gridContainer}>
        <Grid item xs={3.2}>
          <Box sx={styles.userListContainer}>
            {!loadingLocalizations ? (
              <>
                <Box sx={styles.containerCount}>
                  <Typography sx={styles.userTitle}>Cantidad de usuarios encontrados:</Typography>

                  <Typography sx={styles.userTitle}>{AmountUsersJerarquia !== -1 ? `${localization?.length ?? 0} / ${AmountUsersJerarquia}` : `${localization?.length ?? 0} / 0`}</Typography>
                </Box>

                {!!localizationCopy?.length ? (
                  <>
                    <Box sx={styles.headerActions} className="user-list-item">
                      <Typography
                        sx={styles.select}
                        onClick={() => {
                          handleSelectAll()
                        }}
                      >
                        {t('schedule.buttons.addTruechecket')}
                      </Typography>

                      <Typography sx={styles.line}>|</Typography>

                      <Typography
                        sx={styles.select}
                        onClick={() => {
                          handleUnselectAll()
                        }}
                      >
                        {t('schedule.buttons.addFalsechecket')}
                      </Typography>
                    </Box>

                    <Box sx={styles.userListItemsContainer}>
                      {localizationCopy?.map((itemLocalization) => (
                        <Box
                          sx={[styles.userListItems, !!(defaultMap?.id == itemLocalization.id) && styles.currentUser]}
                          className="user-list-item"
                          onClick={() => {
                            lookForUser({ id: itemLocalization.id })
                          }}
                        >
                          <Box sx={styles.check}>
                            <Checkbox
                              onClick={(e) => {
                                e.stopPropagation()
                                handleUsers({ id: itemLocalization.id })
                              }}
                              name={itemLocalization.fullName ?? ''}
                              onChange={({ target }) => { }}
                              checked={!!selected[itemLocalization?.id?.toString() ?? '']}
                            />
                          </Box>

                          <Box sx={styles.user}>
                            <DetailKeyValue value={itemLocalization?.fullName ?? ''} caption={itemLocalization?.curp} captionSize={sizes.caption} valueSize={sizes.value} />
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </>
                ) : (
                  <Typography sx={styles.notFound}>No se encontraron usuarios</Typography>
                )}
              </>
            ) : (
              <Loader />
            )}
          </Box>
        </Grid>

        {/* MAP */}
        <Grid item xs={8.8}>
          <Box sx={styles.map}>
            {!loadingLocalizations ? (
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_MAP_APIKEY ?? '',
                }}
                onGoogleApiLoaded={(map) => {
                  setLoadGoogleMap(true)
                }}
                defaultCenter={mapDefault.center}
                zoom={defaultMap.zoom}
                center={loadGoogleMap ? defaultMap.center : undefined}
              >
                {!!localLocalization?.length ? localLocalization?.map((item, index) => <MapMarker.MapMarker key={index} handleCopy={handleCopy} {...item} />) : <MapMarker.MapMarker {...defaultMap.center} />}
              </GoogleMapReact>
            ) : (
              <Loader />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export { Main }
