import { Box, Typography } from "@mui/material"
import { useHeaderStyles } from "./headerStyles"

const HeaderSimple = (props: { value: string[] }) => {
    const styles = useHeaderStyles()
    return (
        <Box sx={styles.container}>
            {props.value.map((item, index) => <Typography key={index} sx={styles.headetText}>{item}</Typography>)}
        </Box>
    )
}

export { HeaderSimple }
