import { useTranslation } from 'react-i18next'

export const useInitialState = () => {
  const { t } = useTranslation()

  return {
    value: {
      email: {
        name: 'email',
        label: t('login.signin.form.username'),
        value: null,
      },
    },
    form: {
      title: t('login.sendCode.title'),
      description: t('login.sendCode.description'),
      button: t('login.signin.ressetPassword'),
      footer: t('login.signin.footerConfirm')
    },
    t
  }
}
