import { CRUD, Status } from '../../../../utils/enums'
import { InputModel } from '../../../../utils/types'

export const initialFilterValues = {
  status: '',
  search: '',
}

export const formatFilter = {
  createYear: 'year',
  month: "month",
  from: "start_date",
  to: "end_date",
  status: "status_ids"
}

export const formData = {
  createYear: 'year',
  month: "month",
  from: "start_date",
  to: "end_date",
  status: "status",
  curp: "curp",
  firstName: "first_name",
  lastName: "last_name",
  gender: "gender"
}

export const useFilterDetail = () => {
  return {
    search: {
      label: 'Buscar administrador',
      name: 'search',
    },
    status: {
      label: 'Estado de administrador',
      name: 'status',
    },
  }
}

export const initialValues = {
  curp: {
    label: 'CURP',
    validate: {
      min: true,
    },
    rest: {
      min: 10,
      type: 'alphaNumeric',
    },
    name: 'curp',
    value: null,
  },
  username: {
    label: 'Nombre de Usuario *',
    name: 'username',
    value: '0',
  },
  email: {
    label: 'Correo electrónico',
    name: 'email',
    validate: {
      required: true,
      email: true,
    },
    value: null,
  },
  password: {
    label: 'Contraseña *',
    name: 'password',
    value: null,
    customeErrors: {
      type: 2,
    },
    validate: {
      required: true,
      password: true,
    },
  },
  firstName: {
    label: 'Nombres *',
    name: 'firstName',
    value: null,
    validate: {
      required: true,
    },
    customeErrors: {
      required: 'general.validate.required.firstNames',
    },
  },
  lastName: {
    label: 'Apellidos *',
    name: 'lastName',
    value: null,
    validate: {
      required: true,
    },
    customeErrors: {
      required: 'general.validate.required.lastNames',
    },
  },
  gender: {
    label: 'Sexo *',
    name: 'gender',
    value: null,
    rest: {
      type: 'select-search',
    },
    validate: {
      required: true,
    },
  },
  status: {
    label: 'Estado *',
    name: 'status',
    value: 1,
    type: 'select',
    validate: {
      required: true,
    },
  },
  phone: {
    label: 'Teléfono',
    name: 'phone',
    value: '',
    validate: {
      phone: true,
    },
  },
  companyId: {
    label: 'Compañía *',
    name: 'companyId',
    validate: {
      required: true,
    },
    customeErrors: {
      type: 2,
    },
    items: [],
    value: null,
  },
  divisions: {
    label: 'División',
    name: 'divisions',
    items: [],
    value: null,
  },
  userType: {
    label: 'Tipo de usuario *',
    name: 'userType',
    validate: {
      required: true,
    },
    items: [],
    value: null,
  },
  picture: {
    label: 'Cargar imagen',
    name: 'picture',
    value: null,
  },
  partnerStore: {
    label: 'Unidades asociadas',
    name: 'partnerStore',
    value: null,
  },
} as { [x: string]: InputModel }

export const initialTable = {
  column: [],
  row: [],
}

export const gender = [
  {
    value: '1',
    label: 'Masculino',
  },
  {
    value: '0',
    label: 'Femenino',
  },
]

export const username = [
  // {
  //   value: '1',
  //   label: 'Teléfono',
  // },
  {
    value: '0',
    label: 'Correo electrónico',
  },
]

export const useMenuItems = (styles: any) => [
  // {
  //   value: Status.ACTIVE,
  //   label: 'Activar',
  // },
  // {
  //   value: Status.BLOCKED,
  //   label: 'Bloquear',
  // },
  // {
  //   value: Status.WITH_DRAW,
  //   label: 'Retirar',
  // },
  {
    value: CRUD.EDIT,
    label: 'Editar admin',
    // icon: <Icon sx={styles.menuIcons}>{icons('edit')}</Icon>,
  },
  // {
  //   value: 'accountHistory',
  //   label: 'Historial de cuenta',
  // },
]

export const historyList = [
  {
    status: Status.ACTIVE,
    title: 'Usuario creado por Juan Parra',
    email: 'jparra@mail.com',
    date: 'DD/MM/YYYY - HH:MM:SS',
  },
  {
    status: Status.BLOCKED,
    title: 'Usuario bloqueado por Juan Parra',
    email: 'jparra@mail.com',
    date: 'DD/MM/YYYY - HH:MM:SS',
  },
  {
    status: Status.WITH_DRAW,
    title: 'Usuario retirado por Juan Parra',
    email: 'jparra@mail.com',
    date: 'DD/MM/YYYY - HH:MM:SS',
  },
]

export const input = {
  search: {
    label: 'general.search',
    translate: true,
    name: 'search',
    type: 'text',
    value: null,
  },
  createYear: {
    label: 'general.filterDate.year',
    translate: true,
    name: 'createYear',
    type: 'filter-select',
    items: [],
    value: null,
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  month: {
    label: 'general.filterDate.month',
    translate: true,
    name: 'month',
    type: 'filter-select',
    items: [],
    value: null,
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  from: {
    label: 'general.tags.from',
    translate: true,
    name: 'from',
    type: 'date',
    value: null,
    rest: {
      max: 'to',
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  to: {
    label: 'general.tags.to',
    translate: true,
    name: 'to',
    type: 'date',
    value: null,
    rest: {
      min: 'from',
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  status: {
    label: 'general.tags.status',
    translate: true,
    name: 'status',
    type: 'filter-select',
    items: [],
    value: null,
  },
} as { [x: string]: InputModel }

export const accept = ['png', 'jpg', 'jpeg']
