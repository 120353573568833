import { useContextState } from "../../../../../../../hooks/theme"
import { useColorByStatus } from "../../../fragments/hooks/getColorByStatus"
import { PercentageStatus } from "../../../fragments/indicators/typing/enums"

const useStyles = () => {
  const { colors } = useContextState()
  const status = useColorByStatus()
  return {
    containerTooltip: ({itemAmount}:{itemAmount:number}) => {
      let maxWidth = 320

      if(itemAmount > 1) {
        maxWidth = 700 
      }

      return {
        background: `${colors.color14}`,
        boxShadow: `0px 0px 4px ${colors.color13}`,
        display: 'flex',
        flexWrap: 'wrap',
        rowGap: '5px',
        maxWidth: `${maxWidth}px`,
        padding: '20px',
        maxHeight: '600px',
        overflowY: 'auto',
      }
    },
    titleTooltip: {
      fontSize: '1rem',
      // width: '100%',
    },
    percentageStatus: ({ color }: { color: PercentageStatus }) => ({
      background: status.percentageColors[color].color,
      width: '10px',
      height: '10px',
      borderRadius: '50%',
    }),
    mainName: ({ colors }: { colors: string }) => ({
      fontSize: '.8rem',
      color: colors,
      width: '100%',
      fontWeight: '600',
      lineHeight: '1.5rem',
    }),
    containerTitle: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: '5px'
    },
    detail: () => ({
      fontSize: '.8rem',
      color: colors.color13,
      width: '100%',
      paddingLeft: '10px',
      lineHeight: '1.3rem',
    }),
    containerItems: {
      width: '100%',
    },
    header: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      '& .see-detail': {
        fontSize: ".8rem",
        color: colors.color4,
        width: "fit-content"
      },
      '& .close': {
        fontSize: "1.2rem",
        '& .MuiSvgIcon-root': {
          fontSize: "inherit"
        }
      }
    },
  }
}

export { useStyles }
