import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { SnackSimple } from '../../../../components'
import { CRUD, Status, Types } from '../../../../utils/enums'
import { InformativeCard, InputModel } from '../../../../utils/types'
import { ObjectGeneric, onChangeEvent, Table, ValueKey } from '../../../../utils/types/generals'
import { formData, initialData, initialTable, initialValues, input, useMenuItems } from './initialState'
import { getCompaniesList, handleErrors, submit } from './methods'
import { useStyles } from './style'
import Form from './components/form'
import { generalDisabled } from '../../../../utils/validate/validate'
import { statusService } from '../../../../service'
import { useContextState } from '../../../../hooks/theme'
import { images } from '../../../../utils/images'
import { useNavigate } from 'react-router-dom'
import General from '../../../../components/modules/general'
import { icons } from '../../../../utils/icons'
import { FiltersDrawer } from '../../../../components/filtersDrawer'
import { Box, IconButton, Typography } from '@mui/material'
import { DinamicForm } from '../../../../components/dinamicForm'
import { getPreviousYears, monthYear } from '../../../../utils/constants/date_month'
import { useFilter } from '../../../../hooks/filter'
import { ModelDownloadButtons } from '../../../../components/donwloadFiles/model'
import { returnQuery, returnQueryWithPagination } from '../../../../utils/methods/returnQuery'
import { companiesServiceFetch, zipCodeServiceFetch } from '../../../../utils/constants/callService'
import { useDownload } from '../../../../hooks/download/useDownload'
import { getDivisions, getZones } from '../../../../utils/methods/geServiceWithItemsFormat'

const Company = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { colors } = useContextState()
  const styles = useStyles(colors)
  const [inputValues, setInputValues] = useState(initialValues)
  const [information, setInformation] = useState<InformativeCard | null>(null)
  const [data, setData] = useState<ObjectGeneric>({})
  const [btnLoading, setBtnLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [flag, setFlag] = useState(false)
  const [allErrors, setAllErrors] = useState<any>({})
  const [table, setTable] = useState<Table>(initialTable)
  const detailToInclude = ['name', 'companyName', 'phone', 'city', 'state', 'address', 'zipCodeCp']
  const [inputs, setInputs] = useState<{ [x: string]: InputModel }>(input)
  const { counter, errors, filterData, paginationModel, query, filterString, sortTable, handlePagination, onChange, cleanFilter } = useFilter({
    formatFilter: {
      search: 'search',
      from: 'start_date',
      to: 'end_date',
      createYear: 'year',
      month: 'month',
      status: "status_ids",
    },
  })
  const [totalPages, setTotalPages] = useState(0)
  const {
    download,
    openSnackbar: snackbarDownloadModal,
    downloadCSV,
    downloadPDF,
    downloadWORD,
    closeOpenSnakbar,
  } = useDownload({
    docName: t('companie.filter.title'),
    query: returnQueryWithPagination({ filter: filterString, sort: query.sort }, formData),
    downloadFuntion: companiesServiceFetch.downloadTemplate,
  })
  const [open, setOpen] = useState(false)
  const [crud, setCrud] = useState(false)
  const [row, setRow] = useState<Table['row']>([])
  const [selected, setSelected] = useState<number>(-1)
  const [drawerDetail, setDrawerDetail] = useState({
    title: '',
    accept: '',
    cancel: '',
  })

  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
    type: Types.SUCCESS as string,
  })
  const menuList = useMenuItems(styles)
  const [menu, setMenu] = useState<
    {
      label: string
      icon?: any
      value?: any
    }[]
  >(menuList)

  const handleCloseSnackbar = (value: boolean) => setOpenSnackbar({ ...openSnackbar, open: value })

  const getCityByID = async (id?: number) => {
    const response = id ? await zipCodeServiceFetch.cityList(id) : []

    setInputValues((prev) => ({
      ...prev,
      cities: {
        ...prev.cities,
        items: !response?.errors
          ? response?.map((item) => ({
            label: item.country,
            value: item.id,
          }))
          : [],
      },
    }))
    return true
  }
  const crudChange = async (e: onChangeEvent | string): Promise<void> => {
    if (typeof e !== 'string') {
      const { name, value } = e?.target

      let body = {
        ...data,
        [name]: value?.value ?? value,
      }

      if (name === 'states') {
        const responseCitites = await getCityByID(value?.value)
        if (responseCitites) {
          body = { ...body, state: value?.label, zipCode: null, cities: null }
        }
      } else if (name === 'cities') {
        body = { ...body, city: value?.label, zipCode: value?.value }
      }

      setData(body)
      setAllErrors(handleErrors(body, t))
    }
  }

  const handleSubmit = async () => {
    setBtnLoading(true)
    const response = await submit(data, t, information?.id)
    if (!response?.response?.errors) getAllCompannies()
    setOpenSnackbar(response.snackbarBody)
    setBtnLoading(false)
    cleanFilter()
  }

  const handleInformation = async (e: any, index: number) => {
    setSelected(index)
    openDrawer(true)
    let data: Record<string, ValueKey> = {}
    setAllErrors({})
    if (e) {
      setBtnLoading(true)
      const response = await companiesServiceFetch.getCompanyById(e?.id)
      data = {
        ...data,
        name: {
          label: t(`general.tags.name`),
          value: response.name,
          name: "name",
        },
        companyName: {
          label: t(`general.tags.companyName`),
          value: response.companyName,
          name: "companyName",
        },
        address: {
          label: t(`general.tags.address`),
          value: response.address,
          name: "address",
        },
        phone: {
          label: t(`general.tags.phone`),
          value: response.phone,
          name: "phone",
        },
        city: {
          label: t(`general.tags.city`),
          value: response.city,
          name: "city",
        },
        state: {
          label: t(`general.tags.state`),
          value: response.state,
          name: "state",
        },
      }
      setMenu(menuList?.filter((item) => item.value !== e.status?.value))
      const arrayData = Object.values(data)
      setInformation({
        data: arrayData,
        tmpData: arrayData,
        status: {
          color: e.status.color,
          text: e.status.text,
          value: e.status?.value,
        },
        image: response.logo || images.store,
        id: e.id,
      }
      )
      setBtnLoading(false)
    } else {
      setInformation(null)
    }

    setData(initialData)
  }

  const handleMenu = async (item: any) => {
    setBtnLoading(true)
    if (item.value === CRUD.EDIT) {
      setCrud(true)
      setBtnLoading(true)
      listenCreateClick()
      if (information?.id) {
        companiesServiceFetch
          .getCompanyById(information?.id)
          .then((responseCompany) => {
            getCityByID(responseCompany?.zip?.codState)
            setData((prev) => ({
              ...prev,
              name: responseCompany.name,
              companyName: responseCompany.companyName,
              zipCode: responseCompany.zipCode,
              status: responseCompany.statusId,
              logo: responseCompany.logo,
              phone: responseCompany.phone,
              address: responseCompany.address,
              states: responseCompany?.zip?.codState,
              cities: responseCompany?.zip?.id
            }))
            setData((prev) => ({
              ...prev,
              divisions:
                responseCompany?.companysDivision?.map((item) => ({
                  label: item.division.description,
                  value: item.divisionId,
                })) ?? [],
              zones:
                responseCompany?.companysZone?.map((item) => ({
                  label: item.zone.name,
                  value: item.zoneId,
                })) ?? [],
            }))
          })
          .catch(() => {
            setCrud(false)
          })
          .finally(() => {
            setBtnLoading(false)
          })
      } else {
        setBtnLoading(false)
      }
    } else if (item.value === CRUD.EDIT_BRANDLY) {
      navigate(`/dashboard/setting/companies/${information?.id}`)
    }
  }

  const getAllCompannies = useCallback(async () => {
    resetValues()
    setIsLoading(true)
    const { column, row, totalPages } = await getCompaniesList({ colors, t, queary: returnQuery(query, formData) })
    setTotalPages(totalPages)
    setInputs((prev) => ({
      ...prev,
      createYear: {
        ...prev.createYear,
        items: getPreviousYears(),
      },
      status: {
        ...prev.status,
        items: [{
          label: t('general.status.active'),
          value: Status.ACTIVE
        },
        {
          label: t('general.status.inactive'),
          value: Status.INACTIVE
        }],
      },
      month: {
        ...prev.month,
        items: monthYear,
      },
    }))

    setRow(row)
    setTable({ column, row })
    setIsLoading(false)
  }, [colors, t, query.all])

  const getData = useCallback(async () => {
    setFlag(true)
    setIsLoading(true)
    getAllStatus()
    getAllZipCode()
  }, [getAllCompannies])

  const getAllStatus = async () => {
    const response = await statusService().statusList()
    setInputValues(prev => (
      {
        ...prev,
        status: {
          ...initialValues.status,
          items: response?.filter(item => [Status.ACTIVE, Status.INACTIVE].includes(item.id)).map(item => ({
            value: item.id,
            label: item.description,
          })),
        }
      }
    ))
  }

  const getAllZipCode = async () => {
    const response = await zipCodeServiceFetch.satesList()
    setInputValues(prev => (
      {
        ...prev,
        states: {
          ...initialValues.states,
          items: !response.errors
            ? response?.map((item: any) => ({
              label: item.state,
              value: item.codState,
            }))
            : [],
        }
      }
    ))
  }

  const getAllDivisions = async () => {
    const { items } = await getDivisions()
    setInputValues((prev) => ({
      ...prev,
      divisions: {
        ...prev.divisions,
        items: items,
      },
    }))
  }

  const getAllZones = async () => {
    const { all } = await getZones()
    setInputValues((prev) => ({
      ...prev,
      zones: {
        ...prev.zones,
        items: all?.map((item) => ({
          label: item.name,
          value: item.id,
        })) ?? [],
      },
    }))
  }
  const listenCreateClick = async () => {
    getAllDivisions()
    getAllZones()
    return true
  }

  const resetValues = () => {
    setCrud(false)
    setOpen(false)
    setData(initialData)
    setBtnLoading(false)
    setSelected(-1)
  }
  const openDrawer = (edit?: boolean) => {
    setDrawerDetail({
      title: t(`companie.informationCard.${edit ? 'edit' : 'create'}.title`),
      accept: t(`companie.informationCard.buttons.${edit ? 'edit' : 'add'}`),
      cancel: t('userType.informationCard.buttons.cancel'),
    })
    if (!edit) {
      setData(initialData)
    }
    setInformation(null)
    setAllErrors({})
    setCrud(!edit)
    setOpen(true)
  }

  const closeDrawer = () => {
    setOpen(false)
    setCrud(false)
    setSelected(-1)
  }

  const addButton = async () => {
    setBtnLoading(true)
    await listenCreateClick()
    openDrawer()
    setBtnLoading(false)
  }
  useEffect(() => {
    if (!flag) getData()
  }, [getData, flag])

  useEffect(() => {
    getAllCompannies()
  }, [getAllCompannies, query.all])
  return (
    <>
      <SnackSimple close={handleCloseSnackbar} open={openSnackbar.open} type={openSnackbar.type} message={openSnackbar.message} />
      <SnackSimple close={closeOpenSnakbar} open={snackbarDownloadModal.open} type={snackbarDownloadModal.type} message={snackbarDownloadModal.message} />

      <General
        newHeader={
          <Box sx={styles.header}>
            <Typography sx={styles.title}>{t('companie.filter.title')}</Typography>

            <Box sx={styles.flex}>
              <ModelDownloadButtons csv={downloadCSV} pdf={downloadPDF} word={downloadWORD} disableCSV={download?.excel} disablePDF={download?.pdf} disableWORD={download?.word} />


              <Box sx={styles.actions}>
                <IconButton
                  sx={(styles.clean, (counter || 0) > 0 ? null : styles.hide)}
                  onClick={() => {
                    cleanFilter()
                  }}
                >
                  {icons('clean')}
                </IconButton>

                <IconButton
                  className="add"
                  sx={styles.clean}
                  onClick={() => {
                    addButton()
                  }}
                >
                  {icons('add')}
                </IconButton>

              </Box>

            </Box>
          </Box>
        }
        open={open}
        rightDrawer={{
          ...information,
          loadindDetail: !!btnLoading,
          data: information?.data?.filter((item) => detailToInclude.includes(item?.name ?? '')),
          showImage: true,
          form: <Form btnLoading={btnLoading} data={data} input={Object.values(inputValues)} crudChange={crudChange} allErrors={allErrors} />,
          menuItems: menu,
          crud,
          title: drawerDetail.title,
          accept: {
            title: drawerDetail.accept,
            disabled: generalDisabled(inputValues, data) || btnLoading,
            onClick: handleSubmit,
          },
          cancel: {
            title: drawerDetail.cancel,
            onClick: closeDrawer,
          },
          clickMenu: handleMenu,
        }}
        table={{
          column: table.column,
          row: row,
          title: t('companie.filter.title'),
          onClick: handleInformation,
          loadingTable: isLoading,
          selected,
          document: {
            export: true,
            header: [
              {
                id: 'year',
                label: 'Año',
              },
              {
                id: 'month',
                label: 'Mes',
              },
              {
                id: 'week',
                label: 'Semana',
              },
              {
                id: 'name',
                label: 'Nombre',
              },
              {
                id: 'companyName',
                label: t('general.tags.company_name'),
              },
              {
                id: 'phone',
                label: t('general.tags.phone'),
              },
              {
                id: 'city',
                label: t('general.tags.city'),
              },
              {
                id: 'state',
                label: t('general.tags.state'),
              },
              {
                id: 'address',
                label: t('general.tags.address'),
              },
              {
                id: 'zipCodeCp',
                label: t('general.tags.zip_code'),
              },
              {
                id: 'status',
                label: t('general.tags.status'),
              },
            ],
          },
          order: query?.sort && {
            count: query?.sort?.count,
            key: query?.sort?.field,
            order: query?.sort?.sort,
          },
          sortable: (field, sort, count) => {
            sortTable(field && sort ? { field: field, sort: sort, count } : undefined)
          },
          serverSidePagination: { page: paginationModel.page + 1, size: paginationModel.pageSize, totalPages },
          handleChange: (page: number, pageSize: number) => {
            handlePagination({ page, pageSize })
          }
        }}
        filtersDrawer={
          <>
            <FiltersDrawer cleanFilter={cleanFilter} count={counter} right={-50} top={0}>
              <Box sx={styles.section}>
                <DinamicForm errors={errors} input={Object.values(inputs)} values={filterData} onChange={onChange} filter={true} />
              </Box>
            </FiltersDrawer>
          </>
        }
      />
    </>
  )
}

export default Company
