import { createTheme } from '@mui/material/styles'
import { colors } from './colors'
import { mode } from './types'
import { components } from './componentsConfig'

declare module '@mui/material/LinearProgress' {
  interface LinearProgressProps {
    customesecondcolor?: string
    customecolor?:string
  }
}
export const useTheme = (mode: mode = 'light') => {
  const color = colors(mode)

  return {
    theme: createTheme({
      palette: {
        primary: {
          main: color.color16,
        },
        secondary: {
          main: color.color4,
        },
      },
      components: {
        ...components(color),
        MuiLinearProgress: {
          defaultProps: {
            customesecondcolor: "",
            customecolor: "",
          }
        }
      },
      typography: {
        button: {
          textTransform: 'none',
        },
      },
    }),
    color,
  }
}
