import { useEffect, useState } from 'react'
import { reportFolioServices } from '../../main/constants/states'
import { format } from 'date-fns'
import { Constants } from '../../../../../../../utils/constants'
import { Evidence, Priority, Status } from '../../../../../../../service/reportFolio/response'
import { useTranslation } from 'react-i18next'
import { EvidencesTypeValueEnum } from '../../../../../../../utils/enums/evidences'
import { Status as StatusEnum, Types } from '../../../../../../../utils/enums'
import { useNavigate } from 'react-router-dom'
import { DetailTypeOfResponseProps } from '../../../../../../../components/detailTypeOfResponse/type'

const useGetData = ({ id }: { id?: string }) => {
  const { t } = useTranslation()
  const navigation = useNavigate()
  const [snackbarModal, setSnackbarModal] = useState<{
    message: string,
    description?: string
    type: Types,
    open: boolean,
    openSuuccess: boolean,
    cancel?: () => void
    next?: () => void
  }>({
    message: '',
    type: Types.ERROR,
    open: false,
    openSuuccess: false,
  })
  const [data, setData] = useState<{
    left?: {
      UserAssign: string
      bossAssigned: string
      id: number
      canReassign?: boolean
      canResponse?: boolean
      priority: Priority
      folio: string
      status: Status
      description: string
      answers: DetailTypeOfResponseProps & { question: string } | null
      rating?: {
        required?: boolean
        rating?: number | null
        ratingQuestion?: string | null
        ratingComment?: string | null
      }
      createBy: {
        user: string
        date: string
        id?: string | number
      }
      solveBy?: {
        user: string
        date: string
      } | null
      unit: string
      area: string
      department: string
      evidences: Evidence[]
      comments?: {
        description?: string
        user?: string
        date?: string
      }[]
      evidencesConfig?: {
        evidence: EvidencesTypeValueEnum
        amount: number
        size?: number
        type?: string[]
        isRequired?: boolean
      }[]
    }
    right?: {
      reportUrl: null
      answers: {
        description: string
        reasonOfRejection?: string
        status: Status
        createBy: {
          user: string
          date: string
        }
        evidences: Evidence[]
        evidencesRejection?: Evidence[]
      }[]
    }
  }>({})
  const [loading, setLoading] = useState(false)
  const closeSnackbarModal = () => {
    setSnackbarModal(prev => ({ ...prev, openSuuccess: false, cancel: undefined, next: undefined, description: undefined }))
  }
  const getData = async () => {
    if (!!id) {
      setLoading(true)
      const item = await reportFolioServices.reportFolioById({ id: id ? parseInt(id) : 1 })
      const isSolve = item.reportFolioAnswers
      const answerSolve = isSolve?.[isSolve?.length - 1]

      let iCanRespond = !!item.canResponse
      if (!!item.canResponse && item.statusId == StatusEnum.AWAIT_SOLUTION) {
        const response = await reportFolioServices.someOneIsGivingASolution(id)
        if (!!response.attending) {
          setSnackbarModal(prev => ({
            ...prev,
            openSuuccess: true,
            message: 'Advertencia',
            description: `¿El folio ya está siendo atendido por: ${response.fullname} ¿Deseas continuar?`,
            cancel: () => {
              navigation('/dashboard/report-folio/dashboard')
              closeSnackbarModal()
            },
            next: () => {
              closeSnackbarModal()
            }
          }))
        }
      }

      setData((prev) => ({
        ...prev,
        left: {
          canResponse: iCanRespond,
          answers: item.detailCreation ? {
            type: item.detailCreation.typeQuestionId,
            question: item.detailCreation?.question ?? '',
            answersList: item.detailCreation?.answers?.map(item => ({
              id: item.id?.toString() ?? '',
              label: item?.title ?? '',
              value: !!item?.selected,
              iconUrl: item.iconUrl ?? ''
            })) ?? []
          } : null,
          evidencesConfig: item?.configReportFolio?.configActionPlanReportFolioEvidenceTypes
            ?.map((item) => ({
              evidence: item.evidenceTypeId,
              amount: item.paramsConfiguration?.amounts,
              size: item.paramsConfiguration?.sizes,
              type: item?.paramsConfiguration?.types ?? Constants.acceptFile[item.evidenceTypeId.toString()],
              isRequired: !!item?.required,
            })),
          bossAssigned: item.userManageDepartment,
          UserAssign:
            item?.reportFolioUserAssign?.length > 0
              ? item?.reportFolioUserAssign
                ?.map((item: any) => {
                  return `${item.firstName} ${item.lastName}`
                })
                .join(', ')
              : t('reportFolio.detail.general.noUsersAssigned'),
          id: item.id,
          canReassign: !!item.canReassign,
          priority: item.priority,
          folio: item?.folio,
          status: item?.status,
          description: item?.description,
          rating: {
            required: item?.configReportFolio?.isRatingQuestionRequired,
            rating: item?.rating,
            ratingComment: item?.ratingComment,
            ratingQuestion: item?.configReportFolio?.ratingQuestion,
          },
          createBy: {
            user: `${item.createdByUser?.firstName} ${item.createdByUser?.lastName}`,
            date: item?.createTime && format(new Date(item?.createTime), Constants.dateFormatGeneral3),
            id: item.createdByUser?.id,
          },
          solveBy: !!answerSolve?.reviewerUser
            ? {
              user: `${answerSolve?.reviewerUser?.firstName} ${answerSolve?.reviewerUser?.lastName}`,
              date: answerSolve?.createTime && format(new Date(answerSolve?.createTime), Constants.dateFormatGeneral2),
            }
            : null,
          unit: item?.store?.name,
          area: item?.area?.name,
          department: item?.department?.name,
          evidences: item?.evidences,
          comments: item?.reportFolioComments
            ?.map((itemComments) => ({
              description: itemComments?.comment,
              user: `${itemComments?.createdByUser?.firstName} ${itemComments?.createdByUser?.lastName}`,
              date: itemComments?.createTime && format(new Date(itemComments?.createTime), Constants.dateFormatGeneral3),
            }))
            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
        },
        right: {
          reportUrl: item.reportUrl,
          answers: isSolve
            ?.map((itemAnswers) => ({
              status: itemAnswers?.status,
              description: itemAnswers?.description,
              reasonOfRejection: itemAnswers?.reasonOfRejection,
              createTime: new Date(itemAnswers?.createTime)?.getTime(),
              createBy: {
                user: `${itemAnswers.createdByUser?.firstName} ${itemAnswers.createdByUser?.lastName}`,
                date: itemAnswers?.createTime && format(new Date(itemAnswers?.createTime), Constants.dateFormatGeneral3),
              },
              evidences: itemAnswers.evidences,
              evidencesRejection: itemAnswers.evidencesRejection
            }))
            ?.sort((a, b) => b.createTime - a.createTime),
        },
      }))
      setLoading(false)
    }
  }

  const getComments = () => {
    reportFolioServices.reportFolioById({ id: id ? parseInt(id) : 1 }).then((item) => {
      setData((prev) => ({
        ...prev,
        ...(!!prev.left && {
          left: {
            ...prev.left,
            comments:
              item?.reportFolioComments
                ?.map((itemComments) => ({
                  description: itemComments?.comment,
                  user: `${itemComments?.createdByUser?.firstName} ${itemComments?.createdByUser?.lastName}`,
                  date: itemComments?.createTime && format(new Date(itemComments?.createTime), Constants.dateFormatGeneral3),
                }))
                ?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) ?? [],
          },
        }),
      }))
    })
  }

  useEffect(() => {
    getData()
    //eslint-disable-next-line
  }, [id])

  return { data, loading, snackbarModal, setSnackbarModal, getData, getComments }
}

export { useGetData }
