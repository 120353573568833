import { Box, BoxProps, IconButton, Snackbar, Typography } from '@mui/material'
import { useContextState } from '../../hooks/theme'
import { icons } from '../../utils/icons'
import { copyText } from '../../utils/methods/copyText'
import { useStyles } from './styles'
import { DetailKeyValuRef, Props } from './types'
import { Ref, forwardRef, useImperativeHandle, useState } from 'react'

const DetailKeyValue = forwardRef(({ value, caption, index, copy, edit, valueSize, captionSize, align, id, aux, changeEmail, sxContainer, name, ...rest }: Props & BoxProps, ref: Ref<DetailKeyValuRef>) => {
  const { colors } = useContextState()
  const styles = useStyles({ color: colors, copy, valueSize, captionSize, align })
  const [watch, setWatch] = useState(false)
  const [open, setOpen] = useState(false)

  const handleCopy = () => {
    copyText(value ?? '')
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleEdit = () => {
    changeEmail(id, value, { name })
  }

  useImperativeHandle(ref, () => ({
    hidePassword: () => {
      if (watch) {
        handleWatch()
      }
    }
  }))
  const handleWatch = () => {
    if (rest.handleSee) {
      rest.handleSee(!watch)
    }
    setWatch(prev => !prev)
  }
  return (
    <Box sx={[styles.container, sxContainer]} key={rest?.key} className="detail-container">
      <Snackbar autoHideDuration={2000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={open} onClose={handleClose} message={`Se copió el ${caption}`} key={'bottom center'} />
      <Box {...rest.containerValueCaption} sx={styles.valueContainer}>
        <Typography className="detail-value" sx={styles.fullname}>
          {value || 'N/A'}
        </Typography>

        <Box sx={styles.actions}>
          {rest.see && value ? (
            <IconButton sx={styles.icon} onClick={handleWatch}>
              {icons(!watch ? 'visibility-off' : 'visibility')}
            </IconButton>
          ) : null}

          {copy && value ? (
            <IconButton sx={styles.icon} onClick={handleCopy}>
              {icons('copy')}
            </IconButton>
          ) : null}

          {edit && value ? (
            <IconButton sx={styles.iconEdit} onClick={handleEdit}>
              {icons('edit')}
            </IconButton>
          ) : null}
        </Box>
      </Box>
      <Typography className="detail-caption" sx={styles.label}>
        {caption ?? 'N/A'}
      </Typography>
      {
        !!aux && (
          <Typography className="detail-aux" sx={styles.label}>
            {aux}
          </Typography>

        )
      }
    </Box>
  )
})

export default DetailKeyValue
