import { Box, Typography, Grid, IconButton } from '@mui/material'
import { useState, useCallback, useLayoutEffect, useMemo } from 'react'
import { DinamicForm } from '../../../../../components/dinamicForm'
import { useNavigate, useParams } from 'react-router-dom'
import { useContextState } from '../../../../../hooks/theme'
import { ObjectGeneric, onChange } from '../../../../../utils/types/generals'
import { imagesData, initialValues, inputColors, inputColors2, switchs } from './inital'
import { useStyles } from './styles'
import { companyService } from '../../../../../service/company'
import { httpFetch } from '../../../../../utils/crud'
import SimpleFile from '../../../../../components/dinamicForm/simpleFile'
import { IOSSwitch } from '../../../../../components/dinamicForm/switch'
import { useTranslation } from 'react-i18next'
import { BaseInput, Images } from '../../../../../components'
import { handleErrors } from './methods'
import AddImage from '../../../../../components/addImage'
import { images } from '../../../../../utils/images'
import Loader from '../../../../../components/loader'
import { LoadingButton } from '@mui/lab'
import { sharedAws } from '../../../../../service/bucketS3/Storage'
import { icons } from '../../../../../utils/icons'
import SimpleModal from '../../../../../components/modal/simpleModal'
import { DescriptionColor } from './components'

const BrandCustomization = () => {
  const naviate = useNavigate()
  const { colors } = useContextState()
  const { id } = useParams()
  const { t } = useTranslation()
  const [idSetting, setIdSetting] = useState<number | null>(null)
  const [data, setData] = useState<ObjectGeneric>(initialValues)
  const [detail, setDetail] = useState<ObjectGeneric>({})
  const styles = useStyles({ color: colors, show: !data?.locale })
  const [errors, setErrors] = useState<ObjectGeneric>({})
  const [existingDocument, setExistingDocument] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingButton, setLoadingButton] = useState(false)
  const [submitModal, setSubmitModal] = useState({
    open: false,
    title: '',
    description: '',
  })
  const sizeDocument = useMemo(
    () => ({
      xl: existingDocument ? 11 : 12,
      lg: existingDocument ? 11 : 12,
      md: existingDocument ? 11 : 12,
      sm: existingDocument ? 11 : 12,
      xs: existingDocument ? 10 : 12,
    }),
    [existingDocument]
  )

  const onChange: onChange = ({ target }) => {
    const { name, value } = target
    let body = { ...data }

    if (name.includes('img_')) {
      const newName = name.replace('img_', '')

      body = {
        ...body,
        img: {
          ...body.img,
          [newName]: value,
        },
      }
    } else if (name === 'gpsActive') {
      if (!body.digitalFence && !body.gpsRequired) {
        body = {
          ...body,
          [name]: value,
        }
      }
    } else if (name === 'gpsRequired') {
      if (!body.digitalFence) {
        body = {
          ...body,
          gpsActive: value ? true : body.gpsActive,
          [name]: value,
        }
      }
    } else if (name === 'digitalFence') {
      if (value) {
        body = {
          ...body,
          gpsActive: true,
          gpsRequired: true,
          [name]: value,
        }
      } else {
        body = {
          ...body,
          [name]: value,
        }
      }
    } else {
      body = {
        ...body,
        [name]: value,
      }
    }

    setErrors(handleErrors(body, t))

    if (name === 'locale' && value?.type !== 'application/json') {
      delete body.locale
    }
    setData(body)
  }

  const submitSettings = async () => {
    setLoadingButton(true)
    if (idSetting && id) {
      const formData = new FormData()
      let colors = {}

      Object.entries(data).forEach(([key, value]) => {
        if (key.includes('color')) {
          colors = {
            ...colors,
            [key]: value,
          }
        }
      })

      formData.append(
        'assets',
        JSON.stringify(
          await Promise.all(
            Object.entries(data.img).map(async ([key, item]: any) => {
              return {
                [key]: typeof item !== 'string' ?
                  await sharedAws.uploadAndGetUrl({ file: item, name: `${new Date().getTime()}`, otherBucket: process.env.REACT_APP_BUCKET_ICON_QUESTIONNARIES }) : item,
              }
            })
          )
        )
      )
      formData.append('styles', JSON.stringify(colors))
      formData.append('companyId', id)
      formData.append(typeof data.locale === 'string' ? 'pathLocale' : 'locale', data.locale)
      formData.append(
        'configLocations',
        JSON.stringify({
          gpsActive: data.gpsActive,
          gpsRequired: data.gpsRequired,
          digitalFence: data.digitalFence,
          digitalFenceMts: data.digitalFenceMts,
        })
      )

      companyService(httpFetch)
        .updateSettings(formData, idSetting)
        .then((response) => {
          if (!response.errors) {
            getCustomization()
            setSubmitModal({
              description: '',
              open: true,
              title: t('companie.brandCustomization.modal.title'),
            })
          }
        })
        .finally(() => {
          setLoadingButton(false)
        })
    }
  }

  const getStyles = () => {
    setLoading(true)
    companyService(httpFetch)
      .getDefaultStyles()
      .then((response) => {
        if (!response.errors) {
          let imagesResponse = {} as {
            appbar: string | null
            authformlogo: string | null
            backgroundbase: string | null
            nostores: string | null
            questionnairefinishedsuccessfully: string | null
            unlock: string | null
            addplanoverview: string | null
          }
            ; (typeof response?.assets === 'string' ? JSON.parse(response?.assets) : response?.assets).forEach((item: any) => {
              Object.entries(item).forEach(([key, value]) => {
                imagesResponse = {
                  ...imagesResponse,
                  [key]: value,
                }
              })
            })

          setData((prev) => ({
            ...prev,
            ...response.styles,
            gpsActive: response?.configLocations?.gps_active,
            gpsRequired: response?.configLocations?.gps_required,
            digitalFence: response?.configLocations?.digital_fence,
            digitalFenceMts: response?.configLocations?.digital_fence_mts,
            img: {
              appbar: imagesResponse.appbar,
              authformlogo: imagesResponse.authformlogo,
              backgroundbase: imagesResponse.backgroundbase,
              nostores: imagesResponse.nostores,
              questionnairefinishedsuccessfully: imagesResponse.questionnairefinishedsuccessfully,
              unlock: imagesResponse.unlock,
              addplanoverview: imagesResponse.addplanoverview,
            },
            locale: response.text,
          }))
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getCustomization = useCallback(() => {
    if (id) {
      setLoading(true)
      companyService(httpFetch)
        .getCompanyById(parseInt(id))
        .then((response) => {
          const appsSettings = response?.appsSettings?.[0]
          const colorList = appsSettings?.styles ? JSON.parse(appsSettings?.styles) : {}
          const gps = appsSettings?.configLocations ? JSON.parse(appsSettings.configLocations) : {}
          setIdSetting(appsSettings?.id)
          let imagesResponse = {} as {
            appbar: string | null
            authformlogo: string | null
            backgroundbase: string | null
            nostores: string | null
            questionnairefinishedsuccessfully: string | null
            unlock: string | null
            addplanoverview: string | null
          }

            ; (typeof appsSettings?.assets === 'string' ? JSON.parse(appsSettings?.assets) : appsSettings?.assets)?.forEach((item: any) => {
              Object.entries(item)?.forEach(([key, value]) => {
                imagesResponse = {
                  ...imagesResponse,
                  [key]: value,
                }
              })
            })

          setDetail({
            title: response.name,
            subtitle: response.companyName,
          })

          setExistingDocument(appsSettings?.text)
          setData((prev) => ({
            ...prev,
            ...colorList,
            gpsActive: gps?.gps_active ?? gps?.gpsActive,
            gpsRequired: gps?.gps_required ?? gps?.gpsRequired,
            digitalFence: gps?.digital_fence ?? gps?.digitalFence,
            digitalFenceMts: gps?.digital_fence_mts ?? gps?.digitalFenceMts,
            img: {
              appbar: imagesResponse.appbar,
              authformlogo: imagesResponse.authformlogo,
              backgroundbase: imagesResponse.backgroundbase,
              nostores: imagesResponse.nostores,
              questionnairefinishedsuccessfully: imagesResponse.questionnairefinishedsuccessfully,
              unlock: imagesResponse.unlock,
              addplanoverview: imagesResponse.addplanoverview,
            },
            locale: appsSettings?.text,
          }))
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [id])

  const downloadLocale = (viewDocument?: string) => {
    window.open(viewDocument ?? process.env.REACT_APP_MOCK, 'blank')
  }
  const handleDisabled = () => {
    return (
      Object.entries(data).some(([key, value]) => {
        if (!['img', 'gpsActive', 'gpsRequired', 'digitalFence', 'locale'].includes(key) && !key?.includes('color')) {
          if (!value) {
            return true
          }
        } else if (key === 'img') {
          return Object.values(value).some((itemV) => !itemV)
        } else if (key === 'locale') {
          if (value && typeof value !== 'string') {
            return value?.type !== 'application/json'
          } else if (!value) {
            return true
          }
          return false
        } else if (key?.includes('color')) {
          return !new RegExp('^#[0-9a-fA-F]{3,8}$').test(value)
        }

        return false
      }) || !data?.locale
    )
  }
  const handleCloseModal = () => {
    setSubmitModal((prev) => ({
      ...prev,
      open: false,
    }))
  }
  useLayoutEffect(() => getCustomization(), [getCustomization])

  return (
    <Box sx={styles.container}>
      <SimpleModal
        loadingButton
        isLoading={loadingButton}
        open={submitModal.open}
        close={() => {
          handleCloseModal()
        }}
        title={submitModal.title}
        description={submitModal.description}
        buttonText={t('general.accept')}
        color={colors}
        next={() => {
          handleCloseModal()
        }}
      />
      {!!loading ? (
        <Loader />
      ) : (
        <>
          <Grid container columnSpacing={2} mb={1}>
            <Grid item xs={0.5}>
              <IconButton
                sx={styles.iconBack}
                onClick={() => {
                  naviate('/dashboard/setting/companies')
                }}
              >
                {icons('arrow-left-fill')}
              </IconButton>
            </Grid>

            <Grid item xs={8.5}>
              <Typography sx={[styles.companyTitle, styles.generalText, styles.fW]}>{detail?.title ?? ''}</Typography>
              <Typography sx={[styles.companySubTitle, styles.generalText, styles.fW]}>{detail?.subtitle ?? ''}</Typography>
            </Grid>

            <Grid item xs={3}>
              <Box sx={styles.containerHeaderLeft}>
                <Box sx={styles.fit}>
                  <Typography textAlign="end" sx={[styles.titleApp, styles.generalText, styles.fW]}>
                    Personalización de la APP
                  </Typography>

                  <Typography
                    textAlign="end"
                    onClick={() => {
                      getStyles()
                    }}
                    sx={[styles.generalText, styles.refresh, styles.fW]}
                  >
                    Restablecer configuración por defecto
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container columnSpacing={4} rowSpacing={1}>
            <Grid item xl={6} lg={6} md={12} sm={12}>
              <Box sx={styles.containerCardModel}>
                <Box sx={styles.cardModel}>
                  <Typography sx={styles.title}>Seleccione a continuación los colores con los que se visualizarán las interfaces de su ambiente de Check+</Typography>

                  <DinamicForm input={Object.values(inputColors)} values={data} onChange={onChange} />

                  <Typography sx={styles.mainTitle}>Gradientes de móvil</Typography>

                  <Typography sx={styles.title2}>Contiene una combinación de colores denominada "Gradiente", la cual hace referencia a una mezcla gradual de colores seleccionados</Typography>

                  <Box sx={styles.smallColors}>
                    <Typography sx={{ ...styles.title2, marginBottom: "10px" }}>El primer y tercer tono del degradado se determinan a partir de los colores primario y secundario, respectivamente.</Typography>

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box sx={styles.containerColors}>
                          <DescriptionColor title='Color Primario' color={data.color15} />

                          <DescriptionColor title='Color Secundario' color={data.color16} />
                        </Box>
                      </Grid>

                      <Grid item xs={6}><DinamicForm input={Object.values(inputColors2)} values={data} onChange={onChange} /></Grid>
                    </Grid>

                  </Box>

                  <Typography sx={styles.title2}>Colores específicamente utilizados para el fondo y el Header o cabecera de la aplicación móvil.</Typography>
                </Box>

                <Box sx={[styles.cardModel, styles.flex]}>
                  <Typography sx={styles.normalTitle}>Adjunta un archivo JSON para la configuración de todo el texto de la aplicación (Títulos, subtítulos, párrafos, notificaciones y otros)</Typography>

                  <Box sx={styles.containerFull}>
                    <Grid container rowSpacing={2} alignItems="center">
                      <Grid item xl={sizeDocument.xl} lg={sizeDocument.lg} md={sizeDocument.md} sm={sizeDocument.sm} xs={sizeDocument.xs}>
                        <SimpleFile
                          errors={errors}
                          notImage
                          onChange={onChange}
                          item={{
                            label: 'general.tags.file2',
                            translate: true,
                            name: 'locale',
                            value: null,
                            validate: {
                              required: true,
                            },
                            rest: {
                              accept: '.json',
                              simpleFile: true,
                            },
                          }}
                          values={data?.locale}
                          customeIcon="add"
                        />
                      </Grid>
                      {!!existingDocument && (
                        <Grid item xl={1} lg={1} md={1} sm={1} xs={2}>
                          <IconButton
                            sx={styles.show}
                            onClick={() => {
                              downloadLocale(existingDocument)
                            }}
                          >
                            {icons('visibility')}
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>

                    <Typography
                      sx={styles.link}
                      onClick={() => {
                        downloadLocale()
                      }}
                    >
                      Descargar Plantilla
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xl={6} lg={6} md={12} sm={12}>
              <Box sx={styles.containerCardModel}>
                <Box sx={styles.cardModel}>
                  <Typography sx={styles.title}>Configura a continuación los permisos de la aplicación en cuanto a la localización o ubicación</Typography>
                  {Object.values(switchs).map((item) => (
                    <Box sx={[styles.flex, styles.between]}>
                      <Typography sx={styles.title2}>{t(item.label)}</Typography>
                      <IOSSwitch
                        name={item.name}
                        checked={!!data[item.name]}
                        onChange={({ target }) => {
                          onChange({
                            target: {
                              name: target.name,
                              value: target.checked,
                            },
                          })
                        }}
                        sx={{ m: 1 }}
                      />
                    </Box>
                  ))}
                  <Box sx={[styles.flex, styles.between]} mt={2}>
                    <Typography sx={styles.title2}>{t('companie.brandCustomization.gps.digitalFenceMts')}</Typography>
                    <BaseInput
                      name="digitalFenceMts"
                      type="number"
                      value={{
                        digitalFenceMts: {
                          label: `${t('general.mts')} *`,
                          value: data.digitalFenceMts,
                        },
                      }}
                      errors={errors}
                      min={1}
                      onChange={onChange}
                    />
                  </Box>
                </Box>

                <Box sx={styles.cardModel}>
                  <Typography sx={styles.title}>Realiza los ajustes necesarios en cuanto a imágenes que se visualizarán en la aplicación de Check+</Typography>

                  <Grid container spacing={3}>
                    {imagesData.map((item, index) => (
                      <Grid item xl={4} lg={4} md={6} sm={12} key={index}>
                        <AddImage
                          objectFit="contain"
                          icon={<Images sx={styles.img} src={data?.img?.[item.name] ?? images.camera} />}
                          image={data?.img?.[item.name] ?? null}
                          name={`img_${item.name}`}
                          label={item.label}
                          size={{
                            width: '120px',
                            height: '120px',
                          }}
                          removeRadius
                          getFile={onChange}
                        />

                        <Typography sx={styles.title2}>{item.description}</Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box sx={styles.saveConfigurations}>
            <LoadingButton
              loading={loadingButton}
              onClick={() => {
                submitSettings()
              }}
              disabled={handleDisabled()}
              variant="contained"
            >
              {t('general.saveConfigurations')}
            </LoadingButton>
          </Box>
        </>
      )}
    </Box>
  )
}

export default BrandCustomization
