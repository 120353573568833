const typeFiles: Record<string, string> = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'application/xlsx',
  'application/vnd.ms-excel': 'application/xls',
  'application/pdf': 'application/pdf',
  'application/msword': 'application/doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'application/docx',
  'application/vnd.ms-powerpoint': 'application/ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'application/pptx',
  'application/zip': 'application/zip',
  'text/plain': 'application/txt',
  'text/csv': 'application/csv',
  'video/x-matroska': 'video/mkv',
  'video/mp4': 'video/mp4',
  'video/x-ms-wmv': 'video/wmv',
  'audio/x-ms-wma': 'video/wma',
  'video/x-flv': 'video/flv',
  'video/quicktime': 'video/mov',
  'video/webm': 'video/webm',
}

const extentions = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xls: 'application/vnd.ms-excel',
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  zip: 'application/zip',
  txt: 'text/plain',
  csv: 'text/csv',
  mkv: 'video/x-matroska',
  mp4: 'video/mp4',
  wmv: 'video/x-ms-wmv',
  wma: 'audio/x-ms-wma',
  flv: 'video/x-flv',
  mov: 'video/quicktime',
  webm: 'video/webm',
}

export { extentions, typeFiles }
