import { TypeGlobalEnum } from '../../utils/enums/globalContextEnum'
import { StateGlobal } from './interface'
import { ActionGlobal } from './type'

export const globalReducer = (state: StateGlobal, action: ActionGlobal): StateGlobal => {
  switch (action.type) {
    case TypeGlobalEnum.SAVE_USER:
      return { ...state, user: action.payload }
    case TypeGlobalEnum.SAVE_ID:
      return { ...state, id: action.payload }
    case TypeGlobalEnum.SAVE_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
