import { useState, useEffect } from 'react'
import { ObjectGeneric } from '../../utils/types/generals'
import { InputModel } from '../../utils/types'
import { isEmail, isPassword, isPhoneNumber, isRequired } from '../../utils/validate/validate'
import { TFunction } from 'react-i18next'

const useGlobalErrors = ({ data, t, custome, values }: { data: { [x: string]: InputModel }; t: TFunction<'translation', undefined>; custome?: { [x: string]: string }; values?: any }) => {
  const [errors, setErrors] = useState<ObjectGeneric>({})
  useEffect(() => {
    const handleErrors = () => {
      let error: ObjectGeneric = {}

      Object.values(data ?? {})?.forEach((item) => {
        const validate = item?.validate
        const customeErrors = item?.customeErrors
        let value = values?.[item.name] ?? item.value
        const name = item.name
        const customLabel = item.customLabel
        const objValues = values ? values : {}
        
        if (item.items && item.value === undefined) value = ''
        if (item.type == 'select-search' && objValues?.[item.name] === undefined && item.name in objValues) value = ''

        const typeErrors = {
          type: {
            0: 'general.validate.template.theField',
            1: 'general.validate.template.theFields',
            2: 'general.validate.template.theField2',
            3: 'general.validate.template.theField3',
          },
          required: {
            0: 'general.validate.template.isRequired',
            1: 'general.validate.template.areRequired',
            2: 'general.validate.template.isRequired2',
            3: 'general.validate.template.areRequired2',
          },
        }

        if (!!validate) {
          if (!!custome) {
            Object.entries(custome ?? {})?.forEach(([key, value]) => {
              if (!!value) {
                error = {
                  ...error,
                  [key]: value,
                }
              }
            })
          }

          if (validate.required && value !== null && value !== undefined && isRequired(value)) {
            error = {
              ...error,
              [name]: customeErrors?.required
                ? t(customeErrors?.required)
                : `${t(customeErrors?.type ? typeErrors.type[customeErrors?.type] : 'general.validate.template.theField')} ${t(customLabel ? customLabel : `general.tags.${name}`)?.toLowerCase()} ${t(
                  customeErrors?.type ? typeErrors.required[customeErrors?.type] : 'general.validate.template.isRequired'
                )}`,
            }
          }
          else if (validate.minLenght && value?.trim()?.length < validate.minLenght) {
            error = {
              ...error,
              [name]: `${t(`general.validations.min`)} ${validate.minLenght} ${t('general.validations.character')}`,
            }
          }
          // EMAIL
          else if (validate?.email && !!value && !isEmail(value)) {
            error = {
              ...error,
              [name]: t(customeErrors?.email ?? 'general.validations.wrongEmail'),
            }
          }
          // PHONE
          else if (validate.phone) {
            if ((value?.length > 3) && !isPhoneNumber(value)) {
              error = {
                ...error,
                [name]: customeErrors?.phone ? t(customeErrors.phone) : `${t(`general.validations.${value?.length > 12 ? 'max' : 'min'}`)} 10 ${t('general.validations.character')}`,
              }
            }
          }
          // PASSWORD
          else if (validate.password) {
            if (value && !isPassword(value))
              error = {
                ...error,
                [name]: t('general.validations.wrongPassword'),
              }
          }
        }
      })

      setErrors(error)
    }

    const timeStap = setTimeout(handleErrors, 200)
    return () => clearTimeout(timeStap)
    // eslint-disable-next-line
  }, [data])

  return { errors }
}

export default useGlobalErrors
