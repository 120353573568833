import { CRUD } from '../../../../utils/enums'
import { InputModel } from '../../../../utils/types'

export const initialFilterValues = {
  status: '',
  search: '',
}

export const useFilterDetail = () => {
  return {
    search: {
      label: 'Buscar area',
      name: 'search',
    },
    status: {
      label: 'Estado',
      name: 'status',
    },
  }
}

export const initialValues = {
  name: {
    label: 'general.tags.name',
    translate: true,
    name: 'name',
    validate: {
      required: true,
    },
    rest: {
      type: 'alphaNumeric',
      inputProps: {
        maxLength: 45,
      },
    },
    value: null,
  },
  email: {
    label: 'Correo electrónico',
    name: 'email',
    validate: {
      email: true,
    },
    value: null,
  },
  phone: {
    label: 'general.tags.phone',
    translate: true,
    name: 'phone',
    type: 'phone',
    value: '',
    validate: {
      phone: true,
    },
  },
  responsible: {
    label: 'general.tags.responsible',
    translate: true,
    name: 'responsible',
    type: 'select-search',
    items: [],
    value: null,
    validate: {
      required: true,
    },
  },
  departments: {
    label: 'general.tags.states',
    translate: true,
    name: 'departments',
    type: 'filter-select',
    items: [],
    value: null,
  },
} as { [x: string]: InputModel }

export const initialData = {
  name: null,
  email: null,
  phone: null,
}

export const initialTable = {
  column: [],
  row: [],
}

export const useMenuItems = (styles: any) => [
  {
    value: CRUD.EDIT,
    label: 'Editar área',
  },
  {
    value: CRUD.DELETE,
    label: 'Eliminar área',
  },
]

export const input = {
  search: {
    label: 'general.search',
    translate: true,
    name: 'search',
    type: 'text',
    value: null,
  },
  from: {
    label: 'general.tags.from',
    translate: true,
    name: 'from',
    type: 'date',
    value: null,
    rest: {
      max: 'to',
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  to: {
    label: 'general.tags.to',
    translate: true,
    name: 'to',
    type: 'date',
    value: null,
    rest: {
      min: 'from',
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  states: {
    label: 'general.tags.state',
    translate: true,
    name: 'states',
    type: 'filter-select',
    items: [],
    value: null,
  },
  users: {
    label: 'general.tags.user',
    translate: true,
    name: 'users',
    type: 'filter-select',
    items: [],
    value: [],
  },
} as { [x: string]: InputModel }

export const accept = ['png', 'jpg', 'jpeg']
