import { useLayoutEffect, useState } from "react"
import { dashboardServiceFetch } from "../../../../../../utils/constants/callService";

const useBody = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{
    label: string;
    total: number;
    completed: number
  }[]>([])

  const getData = async () => {
    setLoading(true)
    const response = await dashboardServiceFetch.indicatorsctionPlanQuestionnaire()
    const actionPlanList = response?.map(item => ({
      label: item.nameQuestionnaire,
      total: item.actionPlans.total,
      completed: item.actionPlans.processed,
    }))

    setData(actionPlanList)

    setLoading(false)
  }

  useLayoutEffect(() => {
    getData()
  }, [])
  return { data, loading, getData }
}

export { useBody }
