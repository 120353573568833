import { useTheme } from '@mui/material'
import { Color } from '../../../../utils/theme/types/color'
import { useColorByStatus } from './fragments/hooks/getColorByStatus'
import { PercentageStatus } from './fragments/indicators/typing/enums'

export const useStyles = (color: Color) => {
  const { percentageColors } = useColorByStatus()
  const { breakpoints } = useTheme()

  return {
    tooltipListText: {
      lineHeight: '2',
      fontSize: '10px'
    },
    container: {
      width: '100%',
      height: '100%',
      padding: '50px 50px 50px 2px',
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'relative',
    },
    close: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      '&.MuiSvgIcon-root': {
        fontSize: '1rem'
      }
    },
    containerModal: {
      maxHeight: '600px',
      overflowY: 'auto',
      padding: '50px',
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px'
    },
    headerModal: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%'
    },
    headerModalTitle: {
      fontSize: '22px',
      fontWeight: '500',
      color: color.color9
    },
    badge: ({ isActive, }: { isActive?: boolean, }) => ({
      '& .MuiBadge-badge': {
        top: 0,
        right: '0px',
        background: !!isActive ? color.color5 : color.mediumDark,
        color: !!isActive ? color.mediumDark : color.color5
      },
    }),
    btnFilter: {
      fontSize: '16px',
      background: color.color8,
      '&:hover': {
        background: `${color.color8}ee`,
      },
      fontWeight: '500',
      padding: '5px 20px'
    },
    selected: {
      background: color.backgroundDark,
      width: '20px',
      height: '20px',
      top: '40px',
      position: 'absolute',
      right: '-20px',
    },
    lastUpdate: {
      width: '110%',
      padding: '0 30px',
      fontSize: '10px',
      position: 'absolute',
      bottom: '20px',
      left: '-10px',
      color: color.color9,
      '& span': {
        color: color.color13,
      },
    },
    containerFooter: {
      display: 'flex',
      alignItems: 'start',
      gap: '10px',
      marginTop: '10px'
    },
    cleanText: {
      color: color.color31,
      padding: '0px'
    },
    footerText: {
      fontSize: '10px',
      color: color.color9,
      '& span': {
        color: color.color13,
      },
    },
    links: {
      width: '660px',
      padding: '30px',
      '& a': {
        display: 'list-item',
        color: color.color5,
        textDecoration: 'none',
        fontSize: '.8rem',
        fontWeight: '500',
      }
    },
    menu: {
      background: color.color8,
      color: color.color5,
      borderRadius: '5px',
      padding: '15px',
      zIndex: "2000",
      '&:hover': {
        background: `${color.color8}ee`,
      },
      '& .MuiSvgIcon-root': {
        fontSize: '2rem'
      },
      position: 'fixed',
      top: '50%',
      right: '0'
    },
    title: {
      fontSize: '16px',
      fontWeight: '600',
      color: color.color13,
      '& span': {
        color: color.color16,
      },
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
    },
    titleGeneral: {
      width: '100%',
      fontSize: '16px',
      fontWeight: '600',
      color: color.color13,
      marginBottom: '20px',
      paddingLeft: '5px',
    },
    cake: {
      width: '100%',
      height: '100px',
      display: 'flex',
      justifyContent: 'center',
    },
    cardCake: {
      boxShadow: `0 0 4px 0px ${color.color13}`,
      padding: '10px 30px',
      width: '100%',
      minHeight: '19rem',
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      rowGap: '5px',
      position: 'relative',
    },
    headersCake: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .MuiTypography-root': {
        fontWeight: '600',
      },
      '& .percentage': {
        color: color.color16,
        fontSize: '30px',
      },
      '& .percentage-error': {
        color: color.color19,
      },
      '& .storage': {
        color: color.color13,
        fontSize: '16px',
      },
    },
    loader: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    dates: {
      display: 'flex',
      flexWrap: 'wrap',
      '& .MuiTypography-root': {
        color: color.color13,
        fontSize: '12px',
        width: '100%',
        textAlign: 'start',
      },
    },
    footerCake: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .MuiBox-root': {
        display: 'flex',
        alignItems: 'center',
        '&.active': {
          '& span': {
            fontSize: '25px',
            color: color.color16,
          },
        },
        '&.inactive': {
          '& span': {
            fontSize: '25px',
            color: color.color13,
          },
        },
      },
    },
    grid: {
      padding: '10px 20px',
      borderRadius: '5px',
      '& .MuiSvgIcon-root': {
        fontSize: '2rem',
        color: color.color5
      },
      '& .MuiTypography-root': {
        fontSize: '1.2rem',
        color: color.color5
      },
      '&.improve': {
        background: color.color8,
        '& .MuiTypography-root': {
        }
      },
      '&.worst': {
        background: color.color31,
        '& .MuiTypography-root': {
        }
      },
    },
    refreshCake: {
      position: 'absolute',
      top: '0',
      right: '10px',
      '& .MuiIconButton-root': {
        width: '20px',
        height: '20px',
        fontSize: '1rem',
        '& .MuiSvgIcon-root': {
          fontSize: '1em',
        },
      },
    },
    containerCard: {
      width: '100%',
      display: 'flex',
      gap: '20px',
    },
    containerData: {
      width: 'calc(100% - 65px)',
    },
    actionIndicator: ({ isActive, count }: { isActive?: boolean, count?: boolean }) => ({
      width: '45px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: !!isActive ? '15px' : '5px',
      '& .MuiIconButton-root': {
        color: color.color5,
        fontSize: '20px',
        '&.timeline': {
          background: !!isActive ? color.orangeMedium : 'transparent',
          color: !!isActive ? color.color5 : color.color9,
        },
        '&.filter': {
          background: !!count ? color.color31 : color.color5,
          color: !!count ? color.color5 : color.color9
        },
        '&.refresh': {
          color: color.color8,
          fontSize: '30px',
        },
        '&.load': {
          animation: `myEffect 1s linear infinite`,
        },
        "@keyframes myEffect": {
          "0%": {
            transform: 'rotate(0)',
          },
          "100%": {
            transform: 'rotate(360deg)',
          },
        },
        '& span': {
          fontSize: '11px',
          marginRight: '2px',
        },
        '& .MuiSvgIcon-root': {
          fontSize: '1em',
        },
      },
    }),
    unitTitle: {
      fontSize: '14px',
      fontWeight: '600',
      '& .blue': {
        color: color.color16,
        fontWeight: '700',
      },
      '& .red': {
        color: color.color19,
        fontWeight: '700',
      },
    },
    clean: {
      fontSize: '2rem',
      padding: '6px',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    section: {
      marginBottom: '32px',
    },
    hide: {
      visibility: 'hidden',
    },
    headerIndicator: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
      gap: '10px',
      '& .MuiTypography-root': {
        fontWeight: '600',
      },
      '& .percentage': {
        color: color.color9,
        fontSize: '40px',
        [breakpoints.between(1570, 1680)]: {
          fontSize: '35px',
        },
        [breakpoints.between(0, 1570)]: {
          fontSize: '30px',
        },
      },
      '& .storage': {
        color: color.color9,
        fontSize: '1.5rem',
        [breakpoints.between(1570, 1680)]: {
          fontSize: '20px',
        },
        [breakpoints.between(0, 1570)]: {
          fontSize: '18px',
        },
        lineHeight: '1.2em',
      },
    },
    percentage: {
      width: '130px',
      display: 'flex',
      justifyContent: 'end',
      gap: '10px',
    },
    indicatorFooter: {},
    containerTickets: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      gap: '10px',
      marginBottom: '5px',
      '& .ticket-title': {
        width: '120px',
        fontSize: '14px',
        [breakpoints.between(0, 1680)]: {
          fontSize: '12px',
        },
        color: color.color13,
        '& span': {
          color: color.color16,
        },
      },
    },
    ticket: ({
      colors,
    }: {
      colors: PercentageStatus
      type: 'dark' | 'light'
    }) => {
      return {
        width: '65px',
        [breakpoints.between(0, 1680)]: {
          width: '55px',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: '10px',
        '& .ticket-amount': {
          background: percentageColors[colors].color,
          fontSize: '14px',
          [breakpoints.between(0, 1680)]: {
            fontSize: '12px',
          },
          padding: '1px 10px',
          borderRadius: '2px',
          color:
            percentageColors[colors].text === 'dark'
              ? color.color20
              : color.color5,
        },
        '& .ticket-percentage': {
          fontSize: '12px',
        },
      }
    },
    cardIndicatorContainer: (payload: { isActive?: boolean }) => ({
      width: '100%',
      height: 'fit-content',
      [breakpoints.between(0, 1200)]: {
        height: '100%',
      },
      position: 'relative',
      boxShadow: `0 0 4px 0px ${color.color13}`,
      borderRadius: '12px',
      padding: '20px',
      transform: 'scale(1)',
      transitionDuration: '.5s',
      ...(!!payload?.isActive && {
        background: color.backgroundDark,
        transform: 'scale(1.01)',
        boxShadow: `-2px 3px 4px 1px ${color.color32}, 2px 3px 4px 1px ${color.color32}`,
        '& .MuiTypography-root.MuiTypography-root': {
          color: color.color5,
          '& span': {
            color: color.color5,
          }
        }
      }),
    }),
    card: {
      width: '100%',
      minHeight: '300px',
      height: '100%',
      padding: '20px',
      borderRadius: '12px',
      boxShadow: `0 0 4px 0px ${color.color13}`,
      position: 'relative',
    },
    headerInputs: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
    },
    titleHeaders: {
      lineHeight: '1rem',
      color: color.color9,
      fontSize: '1.4rem',
      marginBottom: '5px',
      fontWeight: '500'
    },
  }
}
