import { CRUD } from '../../../../utils/enums'
import { InputModel } from '../../../../utils/types'
import { Item } from '../../../../utils/types/generals'
import { InitialData, ModelOption } from './types'

export const initialFilterValues = {
  role: '',
  status: '',
  search: '',
}

export const initialValues = {
  title: {
    label: 'general.tags.title',
    translate: true,
    name: 'title',
    type: 'text',
    value: null,
    validate: {
      required: true,
    },
    size: {
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
    },
  },
} as { [x: string]: InputModel }

export const option = {
  description: {
    label: 'general.tags.description',
    translate: true,
    name: 'description',
    rest: {
      type: 'alphaNumeric',
      multiline: true,
      maxRows: 3,
    },
    validate: {
      required: true,
    },
    value: null,
    size: {
      xl: 8,
      lg: 8,
      md: 8,
      sm: 8,
    },
  },
  iconUrl: {
    label: 'general.tags.file',
    translate: true,
    name: 'iconUrl',
    type: 'simple-file',
    value: null,
    rest: {
      accept: '.png,.jpg,.jpeg',
      simpleFile: true,
    },
    size: {
      xl: 4,
      lg: 4,
      md: 4,
      sm: 4,
    },
  },
} as { [x: string]: InputModel }

export const modelOption: ModelOption = { id: 1, description: null, iconUrl: null }

export const initialData: InitialData = {
  title: null,
  options: [modelOption, { ...modelOption, id: 2 }],
  optionId: 2,
}

export const initialTable = {
  column: [],
  row: [],
}

export const gender = [
  {
    value: '1',
    label: 'Masculino',
  },
  {
    value: '0',
    label: 'Femenino',
  },
]

export const username = [
  {
    value: '1',
    label: 'Teléfono',
  },
  {
    value: '0',
    label: 'Correo electrónico',
  },
]

export const useMenuItems = (styles: any) => [
  {
    value: CRUD.EDIT,
    label: 'Editar compañía',
    // icon: <Icon sx={styles.menuIcons}>{icons('edit')}</Icon>,
  },
]

export const accept = ['png', 'jpg', 'jpeg']

export interface ItemsInput {
  status?: Item[]
  actionPlanType?: Item[]
  search?: string
}

export const input = {
    search: {
      label: 'general.search',
      translate: true,
      name: 'search',
      type: 'text',
      value: null,
    },
    createYear: {
      label: 'general.filterDate.year',
      translate: true,
      name: 'createYear',
      type: 'filter-select',
      items: [],
      value: null,
      size: {
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      },
    },
    month: {
      label: 'general.filterDate.month',
      translate: true,
      name: 'month',
      type: 'filter-select',
      items: [],
      value: null,
      size: {
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      },
    },
    from: {
      label: 'general.tags.from',
      translate: true,
      name: 'from',
      type: 'date',
      value: null,
      rest: {
        max: 'to',
      },
      size: {
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      },
    },
    to: {
      label: 'general.tags.to',
      translate: true,
      name: 'to',
      type: 'date',
      value: null,
      rest: {
        min: 'from',
      },
      size: {
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      },
    },
  } as { [x: string]: InputModel }
