import { Dispatch, SetStateAction, createContext } from "react";
import { FilterBody, FilterProps } from "../../../hooks/filter/typing";

const ActionPlanContext = createContext<{
    filterData: FilterBody
    actionPlanOfStores: boolean
    doNotHaveAcces: boolean
    setActionPlanOfStores: React.Dispatch<React.SetStateAction<boolean>>
    setFilterPayload: Dispatch<SetStateAction<FilterProps>>
}>(Object({}))

export { ActionPlanContext }