import { useEffect, useState } from 'react'
import { dashboardServiceFetch } from '../../../../../../utils/constants/callService'
import { percentage } from '../../../../../../utils/validate/validate'
import { IndicatorScheduleStoreRating } from '../../../../../../service/dashboard/response'

const useBody = () => {
  const [text, setText] = useState({
    subtitleQuestionnaire: '',
    subTitleProgrammed: '',
  })
  const [loading, setLoading] = useState({
    programmed: false,
    questionnaire: false,
  })
  const [data, setData] = useState<{
    valueProgrammed: number
    valueQuestionnaire: number
  }>({ valueProgrammed: 0, valueQuestionnaire: 0 })

  const refreshProgrammed = async (response: IndicatorScheduleStoreRating) => {
    setLoading((prev) => ({
      ...prev,
      programmed: true,
    }))
    const assignnedStores = response?.scheduledStores?.assignedStores
    const assignnedStoresTotal = response?.scheduledStores?.totalStores

    if (!response.errors) {
      setData((prev) => ({
        ...prev,
        valueProgrammed: parseFloat(
          percentage({ a: assignnedStores, b: assignnedStoresTotal })
        ),
      }))
    }

    setText((prev) => ({
      ...prev,
      subTitleProgrammed: `${assignnedStores || 0} de ${
        assignnedStoresTotal || 0
      } unidades programadas`,
    }))
    setLoading((prev) => ({
      ...prev,
      programmed: false,
    }))
  }

  const refreshQuestionnaire = async (
    response: IndicatorScheduleStoreRating
  ) => {
    setLoading((prev) => ({
      ...prev,
      questionnaire: true,
    }))

    const questionnairesExecuted =
      response?.executionOfQuestionnaires?.amountQuestionnairesFinalized
    const questionnairesExecutedTotal =
      response?.executionOfQuestionnaires?.amountQuestionnairesTotal

    if (!response.errors) {
      setData((prev) => ({
        ...prev,
        valueQuestionnaire: parseFloat(
          percentage({
            a: questionnairesExecuted,
            b: questionnairesExecutedTotal,
          })
        ),
      }))
    }

    setText((prev) => ({
      ...prev,
      subtitleQuestionnaire: `${questionnairesExecuted || 0} de ${
        questionnairesExecutedTotal || 0
      } cuestionarios completados`,
    }))
    setLoading((prev) => ({
      ...prev,
      questionnaire: false,
    }))
  }

  const getData = async ({
    programmed,
    questionnaire,
  }: {
    programmed?: boolean
    questionnaire?: boolean
  }) => {
    const response = await dashboardServiceFetch.scheduledStoreQuestionnaire()
    if (!programmed && !questionnaire) {
      refreshProgrammed(response)
      refreshQuestionnaire(response)
    } else if (!!programmed) {
      refreshProgrammed(response)
    } else if (!!questionnaire) {
      refreshQuestionnaire(response)
    }
  }

  useEffect(() => {
    getData({})
  }, [])
  return { data, loading, text, getData }
}

export { useBody }
