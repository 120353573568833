import { Roles } from "../enums";

const hierachy: Record<Roles, Roles[]> = {
  [Roles.ADMIN]: [Roles.GERENT, Roles.SUPERVISOR, Roles.AGENT, Roles.AUDIT, Roles.SUPPORT, Roles.STORE, Roles.PARTNER],
  [Roles.GERENT]: [Roles.SUPERVISOR],
  [Roles.SUPERVISOR]: [Roles.AGENT, Roles.AUDIT, Roles.SUPPORT],
  [Roles.AGENT]: [Roles.STORE],
  [Roles.AUDIT]: [Roles.STORE],
  [Roles.SUPPORT]: [Roles.STORE],
  [Roles.STORE]: [Roles.PARTNER],
  [Roles.PARTNER]: []
}

export { hierachy }