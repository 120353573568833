import { useContextState } from '../../../../../../hooks/theme'

const useStyles = () => {
  const { colors } = useContextState()
  return {
    graph: ({ flex }: { flex: boolean }) => ({
      width: '100%',
      minHeight: '400px',
      height: '100%',
      padding: '20px 0px',
      borderRadius: '12px',
      boxShadow: `0 0 4px 0px ${colors.color13}`,
      position: 'relative',
      ...(!!flex && {
        display: 'flex',
        flexWrap: 'wrap',
        paddingBottom: '60px',
      }),
    }),
    refresh: {
      background: colors.orangeMedium,
      color: colors.color5,
      borderRadius: '5px',
      '&:hover': {
        background: `${colors.orangeMedium}ee`,
      },
      '& .MuiSvgIcon-root': {
        fontSize: '2.2rem',
      }
    },
    header: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    headerProgress: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '10px',
      padding: '0px 20px',
    },
    title: {
      lineHeight: '1rem',
      color: colors.color9,
      fontSize: '1.4rem',
      marginBottom: '5px',
      fontWeight: '500'
    },
    titleGraph: {
      lineHeight: '1rem',
      color: colors.color13,
      fontSize: '1.2rem',
      fontWeight: '600',
      marginBottom: '5px',
      marginTop: '20px',
      paddingLeft: '50px'
    },
    headerInputs: {
      width: '100%',
      marginTop: '10px',
      '& .MuiGrid-container': {
        justifyContent: 'end',
      },
    },
    chipContainer: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginBottom: '10px',
      '& .show': {
        marginLeft: '10px',
      },
    },
    noData: {
      width: '100%',
      textAlign: 'center',
      fontSize: '18px',
      color: `${colors.color13}`,
    },
    containerGraph: ({ existData }: { existData: boolean }) => ({
      // width: '100%',
      height: !!existData ? '600px' : '100px',
      '& .recharts-brush-texts': {
        '& .recharts-text': {
          fontSize: '12px',
        },
      },
    }),
    actionIndicator: {
      position: 'absolute',
      top: '0',
      right: '10px',
      '& .MuiIconButton-root': {
        fontSize: '1rem',
        '& span': {
          fontSize: '11px',
          marginRight: '2px',
        },
        '& .MuiSvgIcon-root': {
          fontSize: '1em',
        },
      },
    },
  }
}

export { useStyles }
