export enum Status {
    ACTIVE = 1,
    INACTIVE = 2,
    PAUSE = 3,
    CLOSE = 4,
    CANCEL = 5,
    REAGENDED = 6,
    REASSIGNED = 7,
    FINISHED = 8,
    TO_BE_STARTED = 9,
    NOT_FINISHED = 10,
    WITH_DRAW = 11,
    WAITING = 12,
    REJECTED = 13,
    BLOCKED = 14,
    CREATED = 15,
    UPDATE = 16,
    STARTED = 17,
    RESPONDED = 18,
    APPROVED = 19,
    AWAIT_SOLUTION = 20,
    EXPIRED = 21,
    INCOMPLETE = 23,
}