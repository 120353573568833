import { departmentService } from '../../../../../../../utils/constants/callService'
import { Roles } from '../../../../../../../utils/enums'
import { Item } from '../../../../../../../utils/types/generals'

const submit = async ({
  data,
  userFolioSelected,
  userSelected,
  userTable,
  id,
}: {
  id?: number | string
  data: Record<string, any>
  userTable: {
    [x: string]: any;
  }[]
  userFolioSelected: Record<
    string,
    {
      status: boolean
      reassignFolio?: boolean
      settings?:
      | {
        users: Item[]
        usersTypes: Item[]
        stores: Item[]
      }
      | undefined
    }
  >
  userSelected: Record<string, boolean>
}) => {
  let body: {
    name: string
    phone: string
    email: string
    userManageId: number
    users: (
      | {
        userId: number
        allFolios: boolean
        receive: {
          model: string
          modelId: number
        }[]
      }
      | {
        userId: number
        allFolios: boolean
        receive?: undefined
      }
    )[]
  } = {
    name: data?.name || null,
    ...(data?.phone && { phone: data?.phone }),
    ...(data?.email && { email: data?.email }),
    userManageId: data?.responsible?.value,
    users: [],
  }

  if (!!Object.values(userFolioSelected)?.length) {
    const users = Object.entries(userFolioSelected)
    
    body = {
      ...body,
      users: users?.filter(([key]) => !!userSelected[key])?.map(([key, itemUser]) => {
        const settings = itemUser?.settings
        const reassing = itemUser?.reassignFolio === undefined ? userTable?.find(itemUserFound => itemUserFound?.id == key)?.role == (Roles.SUPERVISOR || Roles.GERENT): itemUser?.reassignFolio
        const stores = settings?.stores ?? []
        const users = settings?.users ?? []
        const userTypes = settings?.usersTypes ?? []

        const existSetting = !!stores?.length || !!users?.length || !!userTypes?.length

        return {
          userId: parseInt(key),
          canReassign: reassing,
          allFolios: !!itemUser?.status,
          ...(!itemUser?.status && existSetting
            ? {
              receive: [
                ...stores?.map((itemStores) => ({
                  model: 'store',
                  modelId: itemStores.value,
                })),
                ...users?.map((itemStores) => ({
                  model: 'user',
                  modelId: itemStores.value,
                })),
                ...userTypes?.map((itemStores) => ({
                  model: 'userType',
                  modelId: itemStores.value,
                })),
              ],
            }
            : {}),
        }
      }),
    }
  } else {
    body = {
      ...body,
      users: Object.entries(userSelected ?? {})
        ?.filter(([_, itemUser]) => !!itemUser)
        ?.map(([key]) => ({
          userId: parseInt(key),
          allFolios: true,
          canReassign :userTable?.find(itemUserFound => itemUserFound?.id == key)?.role == (Roles.SUPERVISOR || Roles.GERENT)
        })),
    }
  }
  // return true
  if (!!id) {
    return await departmentService.updateDepartment(body, id as number)
  }
  return await departmentService.createDepartment(body)
}

export { submit }
