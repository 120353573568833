import { Http } from '../../utils/types'
import { GetBlob, ObjectGeneric } from '../../utils/types/generals'
import { CloseScheduled, ScheduledById, ScheduledList, UploadScheduled } from './response'
import { AppliedScheduledResponse } from './responseScheduledApplied'

export const ScheduleService = (client: Http) => ({
  list: async (data?: { query?: string }): Promise<ScheduledList> => await client.get(`scheduled-visit${data?.query ? '?' + data?.query : ''}`),
  updateSchedule: async (body: ObjectGeneric, id: number): Promise<ScheduledById> => await client.put(`scheduled-visit/${id}`, body),
  createSchedule: async (body: ObjectGeneric): Promise<ScheduledById> => await client.post('scheduled-visit', body),
  closeSchedule: async (id: number): Promise<CloseScheduled> => await client.put(`scheduled-visit/close-scheduled-visit/${id}`),
  getScheduleById: async (id: number): Promise<ScheduledById> => await client.get(`scheduled-visit/list/${id}`),
  getAppliedSchedule: async (): Promise<AppliedScheduledResponse> => await client.get(`scheduled-visit/applied`),
  loadSchedule: async (body: ObjectGeneric): Promise<Response & UploadScheduled> => await client?.blob?.(`scheduled-visit/load-schedule`, body, true),
  getRegister: async (data: { type: `pdf` | 'word' | 'excel', query?: string }): Promise<GetBlob> => await client.getBlob?.(`scheduled-visit/generate-file?type=${data.type}${data?.query ? `${data?.query}` :  ''}`),
})
