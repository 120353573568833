import { Box, IconButton } from "@mui/material"
import { Constants } from "../../../../../../../../utils/constants"
import { icons } from "../../../../../../../../utils/icons"
import { useSettingsStyles } from "./settingsStyles"

const Settings = (props: any) => {
    const styles = useSettingsStyles()
    const id = props?.row?.id
    return (
        <Box sx={styles.containerStatus}>
            <IconButton
                onClick={(e) => {
                    props.setDetail({
                        id,
                        reportUrl: props?.row?.status?.reportUrl,
                        positionMenu:
                            props.rowLength < Constants.numberOfRows
                                ? {}
                                : {
                                    top: `${e.clientY}px`,
                                    left: `${e.clientX - 120}px`,
                                },
                    })

                    props.handleClick(e)
                }}
            >
                {icons('moreHoriz')}
            </IconButton>
        </Box>
    )
}

export { Settings }
