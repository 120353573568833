import { useCallback, useState, useContext } from 'react'
import { Item } from '../../../../../utils/types/generals'
import { getArea, getDepartments, getDivisions, getQuestionnaires, getRoles, getStore, getUserTypes, getUsers, getZones } from '../../../../../utils/methods/geServiceWithItemsFormat'
import { getPreviousYears, monthYear } from '../../../../../utils/constants/date_month'
import { useInput } from './useInput'
import { Roles, Status } from '../../../../../utils/enums'
import { getStatus } from '../../../../../hooks/status'
import { useTranslation } from 'react-i18next'
import { GlobalContext } from '../../../../../contexts/Global'
import { getUserRole } from '../../../../../service/auth'
import { canAccess } from '../../../../../utils/validate/canaccess'

const useGetData = () => {
  const [items, setItems] = useState<{ [x: string]: Item[] }>({})
  const [childZones, setChildZones] = useState<any[]>([])
  const [departments, setDepartments] = useState<any[]>([])
  const { input, disabledInputs } = useInput({ items })
  const { t } = useTranslation()
  const { colors } = useContext(GlobalContext)

  const handleItemsByExternal = ({ name, value }: { name: string; value: any }) => {
    setItems((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const getData = useCallback(async () => {
    if(!canAccess([Roles.AGENT, Roles.AUDIT])) {
      getRolesService()
      getUserTypesService()
      getDivisionsService()
      getUsersService()
    }
    getAreasService()
    getQuestionnairesService()
    getItemStatus()
    getDepartmentService()
    getZonesService()
    getStoreService()
    setItems((prev) => ({
      ...prev,
      createYear: getPreviousYears(),
      month: monthYear.map((e) => ({
        ...e,
        value: e.value + 1,
      })),
    }))

    // setLoadingTable(false)
  }, [])

  const getRolesService = async () => {
    const responseRoles = await getRoles({ skip: [Roles.ADMIN] })

    setItems((prev) => ({
      ...prev,
      roles: responseRoles.items,
    }))
  }

  const getAreasService = async () => {
    const responseAreas = await getArea()

    setItems((prev) => ({
      ...prev,
      areas: responseAreas,
    }))
  }

  const getDepartmentService = async () => {
    const departments = await getDepartments()
    setDepartments(departments)
  }

  const getZonesService = async () => {
    const { childZones, fatherZones } = await getZones()

    setChildZones(childZones)
    setItems((prev) => ({
      ...prev,
      zoneId: fatherZones,
    }))
  }

  const getQuestionnairesService = async () => {
    const responseQuestionarie = await getQuestionnaires()

    setItems((prev) => ({
      ...prev,
      questionnaireId: responseQuestionarie.items,
    }))
  }

  const getUserTypesService = async () => {
    const { items } = await getUserTypes()

    setItems((prev) => ({
      ...prev,
      userTypeId: items,
    }))
  }

  const getUsersService = async () => {

    const role = getUserRole()
    if (role === Roles.AGENT) return

    const { items } = await getUsers()

    setItems((prev) => ({
      ...prev,
      userId: items,
    }))
  }

  const getDivisionsService = async () => {
    const division = await getDivisions()

    setItems((prev) => ({
      ...prev,
      divisions: division.items,
    }))
  }

  const getItemStatus = async () => {
    const statusList = [Status.ACTIVE, Status.STARTED, Status.INCOMPLETE, Status.CLOSE, Status.FINISHED]

    setItems((prev) => ({
      ...prev,
      status: statusList.map((item) => {
        const status = getStatus(item, colors, t)
        return {
          label: status.text,
          value: status.value,
        }
      }),
    }))
  }

  const getStoreService = async () => {
    const { items } = await getStore()

    setItems((prev) => ({
      ...prev,
      keyCodeId: items,
    }))
  }

  return { items, departments, childZones, input, getData, handleItemsByExternal, disabledInputs }
}

export { useGetData }
