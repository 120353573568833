import { Controller, useForm } from "react-hook-form"
import { DinamicFormRefModel, DinamicFormRefValues, DynamicFormRefProps, DynamicFormRefType, DynamicFormRefValues, DynamicRefRef, FileWithTextProps, ValidateModel } from "./types"
import { TextField } from "@material-ui/core"
import { validateFields } from "./validations"
import { Autocomplete, Box, Checkbox, FormControlLabel, Radio, Typography } from "@mui/material"
import { CustomPaper } from "../CustomPaper"
import { Item, onChangeEvent } from "../../utils/types/generals"
import { Ref, forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from "react"
import { IOSSwitch } from "./switch"
import { useTranslation } from "react-i18next"
import { FileWithText } from "./fileWithText"
import { isDecimal, isNumber } from "../../utils/validate/validate"
import { MultiSelect } from "./multiSelect"

const filterItemsByParent = ({ items, parentId, parentKey, parentItem }:
  { items: Item[]; parentId?: string, parentKey?: string; parentItem?: Item }): Item[] => {
  if (!!parentId && !!parentKey) {
    return !parentItem ? [] : items?.filter(item => parentItem?.value == item?.[parentId]) ?? []
  }
  return items
}
const isEmpty = (value: DinamicFormRefValues | Item[]) => {
  return value !== undefined
}
const sencondWrapper = ({ children }: { children: JSX.Element }) => children
const timeUpdate = 0

const DynamicFormRef = forwardRef((props: DynamicFormRefProps, ref: Ref<DynamicRefRef>) => {
  const parentWrapper = props.parentWrapper ?? sencondWrapper
  const {
    control,
    formState: { errors },
    reset,
    setError,
    clearErrors,
    watch,
    getValues: getData,
    handleSubmit,
    setValue,
  } = useForm<DynamicFormRefValues>({
    defaultValues: props.defaultValues,
    mode: 'onChange'
  })
  const modelArray = useMemo(() => Object.values(props.model ?? {}), [props.model])
  const refTime = useRef<Record<string, NodeJS.Timeout> | null>(null)
  const { t } = useTranslation()
  const modelObject =
    ({ id, name, label, validations, items, selectOptions, inputOptions, switchOptions, disabled, hiddenError, ...rest }: DinamicFormRefModel): Record<DynamicFormRefType, JSX.Element> => {
      const validation = validateFields({ validate: validations, name })
      return {
        text: <Controller
          key={id}
          name={name}
          control={control}
          rules={validation}
          render={({ field: { onChange, value, ...ext } }) => {
            const parentKey = inputOptions?.parent?.parentKey
            const parentHidden = inputOptions?.parent?.hidden
            let hidden = false
            if (!!parentKey && !!parentHidden) {
              if (!watch(parentKey)) {
                hidden = true
              }
            }
            return (
              <TextField
              {...ext}
              {...inputOptions?.textFieldProps as any ?? {}}
              disabled={!!disabled}
              variant={inputOptions?.variant}
              label={`${label}${!!validations?.required?.value ? ' *' : ''}`}
              multiline={!!rest?.multiline}
              value={value ?? ''}
              onBlur={() => {
                if (value == undefined) {
                    setValue(name, '')
                    if (!!props.onChange) {
                      props.onChange({ [name]: "", current: name },)
                    }
                  }
                  if (!!props.onBlur) {
                    props.onBlur({ [name]: value, current: name },)
                  }
                }}
                onChange={(e) => {
                  const { name, value } = e.target
                  const regex = /^[\r\n\t\s].*/
                  if(!regex.test(value)) {
                    if ((!validations?.decimal && !validations?.number) ||
                      (!!validations?.decimal && (isDecimal(value) || value === '-')) ||
                      (!!validations?.number && (isNumber(value) || value === '-'))
                    ) {
                      onChange(e)
                      if (!!props.onChange) {
                        if (refTime.current?.text) { clearTimeout(refTime.current.text) }
  
                        const text = setTimeout(() => {
                          if (!!props.onChange) {
                            props.onChange({ [name]: value, current: name })
                          }
                        }, timeUpdate)
  
                        refTime.current = {
                          ...refTime.current,
                          text
                        }
                      }
                    }
                  }
                }}
                error={isEmpty(value) && !!errors[name]?.message}
                helperText={(!!(isEmpty(value) && !!errors[name]?.message) && !hiddenError) && errors[name]?.message}
                minRows={rest?.rows}
                maxRows={rest?.maxRows}
                sx={{ ...hidden && { display: 'none', }}}
              />)
          }
          }
        />
        ,
        file: <Controller
          key={id}
          name={name}
          control={control}
          rules={validation}
          render={({ field: { onChange, value, ...ext } }) => {
            const localValue = value as FileWithTextProps
            const parentKey = inputOptions?.parent?.parentKey
            const parentHidden = inputOptions?.parent?.hidden
            let hidden = false
            if (!!parentKey && !!parentHidden) {
              if (!watch(parentKey)) {
                hidden = true
              }
            }
            return (
              <FileWithText
                {...ext}
                onChangeFile={(e) => {
                  const value = e?.target?.files?.[0]
                  onChange({
                    ...e,
                    target: {
                      ...e.target,
                      value: { ...localValue, file: value ?? '', }
                    }
                  })

                  if (!!props.onChange) {
                    if (refTime.current?.text) { clearTimeout(refTime.current.text) }

                    const file = setTimeout(() => {
                      if (!!props.onChange) {
                        props.onChange({ [name]: { ...localValue, file: value ?? '', }, current: name })
                      }
                    }, timeUpdate)

                    refTime.current = {
                      ...refTime.current,
                      file
                    }
                  }
                }}
                removeImage={() => {
                  if (!localValue.text && (validation?.required as ValidateModel)?.message) {
                    setValue(name, null)
                    setError(name, { type: 'required', message: (validation?.required as ValidateModel)?.message ?? '' })
                    if (!!props.onChange) {
                      props.onChange({ [name]: null, current: name })
                    }
                  } else {
                    setValue(name, { ...localValue, file: undefined })
                    if (!!props.onChange) {
                      if (refTime.current?.text) { clearTimeout(refTime.current.text) }

                      const file = setTimeout(() => {
                        if (!!props.onChange) {
                          props.onChange({ [name]: { ...localValue, file: undefined, }, current: name })
                        }
                      }, timeUpdate)

                      refTime.current = {
                        ...refTime.current,
                        file
                      }
                    }
                  }
                }}
                value={localValue}
                disabled={!!disabled}
                sx={{ ...hidden && { display: 'none' } }}
                label={`${label}${!!validations?.required?.value ? ' *' : ''}`}
                multiline={!!rest?.multiline}
                onBlur={() => {
                  if (!localValue?.file && !localValue?.text) {
                    setValue(name, null)
                    if (!!props.onChange) {
                      props.onChange({ [name]: null, current: name })
                    }
                  }
                }}
                onChange={(e: onChangeEvent) => {
                  const { name, value } = e.target

                  onChange({
                    ...e,
                    target: {
                      ...e.target,
                      value: { ...localValue, text: value ?? '', }
                    }
                  })
                  if (!!props.onChange) {
                    if (refTime.current?.text) { clearTimeout(refTime.current.text) }

                    const file = setTimeout(() => {
                      if (!!props.onChange) {
                        props.onChange({ [name]: { ...localValue, text: value ?? '', }, current: name })
                      }
                    }, timeUpdate)

                    refTime.current = {
                      ...refTime.current,
                      file
                    }
                  }
                }}
                error={isEmpty(value) && !!errors[name]?.message}
                helperText={(!!(isEmpty(value) && !!errors[name]?.message) && !hiddenError) && errors[name]?.message}
                minRows={rest?.rows}
                maxRows={rest?.maxRows}

              />
            )
          }
          }
        />
        ,
        selectSimple:
          <Controller
            key={id}
            name={name}
            control={control}
            rules={{ required: { value: !!validations?.required?.value, message: `${t('general.validate.template.theField')} ${t(`general.tags.${name}`)} ${t('general.validate.template.isRequired')}` } }}
            render={({ field }) => {
              const parentId = selectOptions?.parent?.parentId
              const parentKey = selectOptions?.parent?.parentKey
              const childKey = selectOptions?.child?.childKey
              const isEmptySelectFirst = selectOptions?.isEmptySelectFirst

              let parentItem: Item | undefined
              if (!!parentId && !!parentKey) {
                parentItem = getData(parentKey) as Item
              }

              let valueAutoComplete: Item | null = field.value as Item ?? null

              if (!!isEmptySelectFirst && !valueAutoComplete) {
                valueAutoComplete = items?.[0] ?? null
              }

              let allItems = filterItemsByParent({
                items: items || [],
                parentItem,
                parentId,
                parentKey,
              })
              let newValue: Item | null = valueAutoComplete

              if (!newValue?.value || !allItems?.some(item => item.value == newValue?.value)) {
                newValue = null
              } else if (props?.manageDefault?.selectSimple && props?.defaultValues?.[name]) {
                newValue = props?.defaultValues?.[name] as Item ?? null
              }

              if (newValue?.value) {
                allItems = allItems.filter(item => item.value != newValue?.value)
              }
              return (
                <Autocomplete
                  {...field}
                  key={id}
                  noOptionsText={t('general.noOptions')?.toString()}
                  options={allItems}
                  disabled={!!disabled}
                  getOptionLabel={(option) => option.label ?? ''}
                  isOptionEqualToValue={(option, value) => option.id == value.id}
                  value={newValue}
                  // filterSelectedOptions
                  autoComplete={false}
                  onChange={(_, value) => {
                    field.onChange(value)
                    if (!!childKey) {
                      setValue(childKey, null)
                    }
                    if (!!props.onChange) {
                      if (refTime.current?.selectSimple) { clearTimeout(refTime.current.selectSimple) }

                      const selectSimple = setTimeout(() => {
                        if (!!props.onChange) {
                          props.onChange({ [name]: value, current: name })
                        }
                      }, timeUpdate)

                      refTime.current = {
                        ...refTime.current,
                        selectSimple
                      }
                    }
                  }}
                  onBlur={() => {
                    if (!!isEmptySelectFirst && !field.value) {
                      const firstItem = items?.[0]
                      setValue(name, firstItem ?? null)
                      if (!!props.onChange) { props.onChange({ [name]: firstItem ?? null, current: name }) }
                    } else {
                      if (field.value == undefined) {
                        setValue(name, null)
                        if (!!props.onChange) { props.onChange({ [name]: null, current: name }) }
                      }
                    }
                  }}
                  PaperComponent={CustomPaper}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.value}>
                        {option.label}
                      </li>
                    )
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${label}${!!validations?.required?.value ? ' *' : ''}`}
                      error={!!isEmpty(field?.value) && !!errors[name]?.message && !isEmptySelectFirst}
                      helperText={!!(isEmpty(field?.value) && !!errors[name]?.message && !hiddenError) && errors[name]?.message}
                    />
                  )}
                />)
            }
            }
          />,
        multiSelect: (
          <MultiSelect
            control={control}
            disabled={!!disabled}
            errors={errors}
            getData={getData}
            id={id}
            item={items ?? []}
            label={label}
            name={name}
            setValue={setValue}
            validations={validations}
            hiddenError={hiddenError}
            onChange={props.onChange}
            selectOptions={selectOptions}
          />
        ),
        switch: <Controller
          key={id}
          name={name}
          control={control}
          render={({ field: { onChange, value, ...rest } }) => (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }} key={name}>
              {!!switchOptions?.labelLeft && (
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: '600',
                    color: '#707070',
                  }}
                >
                  {switchOptions.labelLeft}
                </Typography>
              )}

              <FormControlLabel
                key={name}
                disabled={!!disabled}
                control={
                  <IOSSwitch
                    {...rest}
                    name={name}
                    checked={!!value as boolean}
                    onChange={(_, value) => {
                      const parentKey = switchOptions?.parent?.parentKey
                      const childKey = switchOptions?.child?.childKey
                      let parentItem: boolean | undefined
                      let childItem: boolean | undefined

                      if (!!parentKey) {
                        parentItem = getData(parentKey) as boolean
                      }
                      if (!parentItem && parentKey && value) {
                        setValue(parentKey, true)
                      }
                      if (!!childKey) {
                        childItem = getData(childKey) as boolean
                      }
                      if (!!childItem && childKey && !value) {
                        setValue(childKey, false)
                      }

                      onChange(value)
                      if (!!props.onChange) {
                        if (refTime.current?.switched) { clearTimeout(refTime.current.switched) }

                        const switched = setTimeout(() => {
                          if (!!props.onChange) {
                            props.onChange({ [name]: value, current: name })
                          }
                        }, timeUpdate)

                        refTime.current = {
                          ...refTime.current,
                          switched
                        }
                      }
                    }}
                    sx={{ m: 1 }}
                  />
                }
                label={`${label}${!!validations?.required?.value ? ' *' : ''}`}
              />
            </Box>
          )
          }
        />,
        check: <Controller
          key={id}
          name={name}
          control={control}
          render={({ field: { onChange, value, ...rest } }) => (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }} key={name}>
              {!!switchOptions?.labelLeft && (
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: '600',
                    color: '#707070',
                  }}
                >
                  {switchOptions.labelLeft}
                </Typography>
              )}

              <FormControlLabel
                key={name}
                disabled={!!disabled}
                control={
                  <Checkbox
                    {...rest}
                    style={{ margin: 0 }}
                    name={name}
                    checked={!!value as boolean}
                    onChange={(_, value) => {
                      const parentKey = switchOptions?.parent?.parentKey
                      const childKey = switchOptions?.child?.childKey
                      let parentItem: boolean | undefined
                      let childItem: boolean | undefined

                      if (!!parentKey) {
                        parentItem = getData(parentKey) as boolean
                      }
                      if (!parentItem && parentKey && value) {
                        setValue(parentKey, true)
                      }
                      if (!!childKey) {
                        childItem = getData(childKey) as boolean
                      }
                      if (!!childItem && childKey && !value) {
                        setValue(childKey, false)
                      }

                      onChange(value)
                      if (!!props.onChange) {
                        if (refTime.current?.check) { clearTimeout(refTime.current.check) }

                        const check = setTimeout(() => {
                          if (!!props.onChange) {
                            props.onChange({ [name]: value, current: name })
                          }
                        }, timeUpdate)

                        refTime.current = {
                          ...refTime.current,
                          check
                        }
                      }
                    }}
                    sx={{ m: 1 }}
                  />
                }
                label={`${label}${!!validations?.required?.value ? ' *' : ''}`}
              />
            </Box>
          )
          }
        />,
        radio: <Controller
          key={id}
          name={name}
          control={control}
          render={({ field: { onChange, value, ...rest } }) => (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }} key={name}>
              {!!switchOptions?.labelLeft && (
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: '600',
                    color: '#707070',
                  }}
                >
                  {switchOptions.labelLeft}
                </Typography>
              )}

              <FormControlLabel
                key={name}
                disabled={!!disabled}
                control={
                  <Radio
                    {...rest}
                    style={{ margin: 0 }}
                    name={name}
                    checked={!!value as boolean}
                    onClick={() => {
                      onChange(!value)
                      if (!!props.onChange) {
                        if (refTime.current?.radio) { clearTimeout(refTime.current.radio) }

                        const radio = setTimeout(() => {
                          if (!!props.onChange) {
                            props.onChange({ [name]: !value, current: name })
                          }
                        }, timeUpdate)

                        refTime.current = {
                          ...refTime.current,
                          radio
                        }
                      }
                    }}
                    sx={{ m: 1 }}
                  />
                }
                label={`${label}${!!validations?.required?.value ? ' *' : ''}`}
              />
            </Box>
          )
          }
        />
      }
    }

  const validate = () => {
    const data = getData()
    let existError = false
    for (const key in data) {
      const model = props.model[key]
      if ((!data[key] || (model?.type == 'file' && !(data[key] as FileWithTextProps)?.text && !(data[key] as FileWithTextProps)?.file)) && !!model?.validations?.required?.value) {
        let newValue = null
        if (model?.type == 'text') {
          newValue = ''
        }

        setValue(key, newValue)
        setError(key, {
          message: model?.validations?.required?.message ?? `${t('general.validate.template.theField')} ${t(`general.tags.${key}`)} ${t('general.validate.template.isRequired')}`,
          type: 'required'
        })
        existError = true
      }
    }

    return existError
  }

  useImperativeHandle(ref, () => ({
    setValues: ({ name, value }) => {
      setValue(name, value)
    },

    getValues: () => getData(),
    reset: (payload) => {
      reset(payload)
    },
    validate: validate,
    getErrors: () => errors,
    cleanError: (name) => {
      clearErrors(name)
    }
  }))

  useEffect(() => {
    const subscription = watch(data => {
      if (!!props.onChangeWatch) { props.onChangeWatch(data) }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const emptyFunction = (data: DynamicFormRefValues) => {
    if (props?.onSubmit) { props?.onSubmit(data) }
  }

  useEffect(() => {
    if (props.validateField) {
      validate()
    }
  }, [props.validateField, props.defaultValues])
  const children = () => {
    const newCHildren = <>
      {modelArray?.map(item => {
        if (!item) return null

        const childrenKey = item?.inputOptions?.child?.childKey
        let childName = childrenKey
        if (!!childrenKey) {
          if (!watch(item.name)) {
            childName = ''
          }
        }

        const localWrapper = item.wrapper ?? sencondWrapper

        return localWrapper({
          children: modelObject(item)[item.type],
          id: item.id,
          error: errors[item.name]?.message,
          childrenData: { error: childName ? errors?.[childName]?.message : undefined }
        })
      })}
    </>
    // if (props.isLoaded) {
    //   props.isLoaded(true)
    // }
    return newCHildren
  }
  if (!!props.removeForm) {
    return (
      <div style={props.styleSx} onBlur={handleSubmit(() => { })} onSubmit={handleSubmit(emptyFunction)}>
        {parentWrapper({ children: children() })}
      </div>
    )
  }

  return (
    <form style={props.styleSx} onBlur={handleSubmit(() => { })} onSubmit={handleSubmit(emptyFunction)}>
      {parentWrapper({ children: children() })}
    </form>
  )
})

export { DynamicFormRef }