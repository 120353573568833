import { LoadingButton } from '@mui/lab'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SnackSimple } from '../../../../../../components'
import { DinamicForm } from '../../../../../../components/dinamicForm'
import { UserService } from '../../../../../../service'
import { storesService } from '../../../../../../service/stores'
import { httpFetch } from '../../../../../../utils/crud'
import { Roles, Types } from '../../../../../../utils/enums'
import {
  Item,
  ObjectGeneric,
  onChange,
} from '../../../../../../utils/types/generals'
import { initialValues } from './initialState'
import { hierachy } from '../../../../../../utils/constants/hierachy'
import { getUserRole } from '../../../../../../service/auth'
import { getQuestionnaires } from '../../../../../../utils/methods/geServiceWithItemsFormat'

export interface Props {
  companies: any[]
  roles: any[]
  userType: any[]
}

export const UploadForm = ({ companies, roles, userType }: Props) => {
  const [inputValues, setInputValues] = useState(initialValues)
  const [data, setData] = useState<ObjectGeneric>({})
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
    type: Types.SUCCESS as string,
  })

  const handleCloseSnackbar = (value: boolean) =>
    setOpenSnackbar({ ...openSnackbar, open: value })

  const onChange: onChange = async ({ target }) => {
    const { name, value } = target
    let body = { [name]: value }

    if (name === 'companies') {
      const { roles } = data
      const idsCompanies = value.map((u: any) => u.value).join(',')
      const idsRoles = roles?.map((u: any) => u.value) ?? []

      getUnitsByCompany({ ids: idsCompanies })
      getUsersByRolesAndCompanies({ ids: idsCompanies, roles: idsRoles })
    }

    if (name === 'roles') {
      const { companies } = data
      const idsCompanies = companies?.map((u: any) => u.value).join(',')
      const idsRoles = value?.map((u: any) => u.value)
      if (!idsRoles?.length) {
        hideOrShowUser(false)
        removeQuestionnaires()
        removeUsers()
      }
      getUsersByRolesAndCompanies({ ids: idsCompanies, roles: idsRoles })
    }

    if (name === 'users') {
      const ids = value?.map((u: any) => u.value).join(',')
      if (!ids?.length) {
        // removeQuestionnaires()
        removeUsers()
      }
      // getQuestionnaires({ ids })
    }

    setData((prev) => ({
      ...prev,
      ...body,
    }))
  }

  const getUnitsByCompany = async ({ ids }: { ids: string }) => {
    let stores: Item[] = []

    if (!!ids) {
      const responseStores = await storesService(
        httpFetch
      ).getStoresByCompanies(ids)

      stores = responseStores?.map((item) => ({
        label: item.name,
        value: item.id,
        companyId: item.companyId,
      }))
    }

    const newStoresSelected =
      (data?.stores as Item[])?.filter(
        (item) => !!stores.some((itemStores) => itemStores.value == item.value)
      ) ?? []

    setData((prev) => ({
      ...prev,
      stores: newStoresSelected,
    }))

    setInputValues((prev) => ({
      ...prev,
      stores: {
        ...prev.stores,
        items: stores,
      },
    }))
  }

  const getUsersByRolesAndCompanies = async ({
    ids,
    roles,
  }: {
    ids: string
    roles: Roles[]
  }) => {
    let rolesQuery: Record<string, boolean> = {}

    let users: Item[] = []

    if (!!ids && !!roles?.length) {
      roles.forEach((item: Roles) => {
        const rolesByHierachy = hierachy[item]

        rolesByHierachy.forEach((itemHierachy) => {
          rolesQuery = {
            ...rolesQuery,
            [itemHierachy]: true,
          }
        })
      })
      if (!!Object.values(rolesQuery ?? {})?.length) {
        const responseUsers = await UserService(httpFetch).userByIds(
          ids,
          Object.keys(rolesQuery).join(',')
        )

        users = responseUsers?.map((item) => {
          const name =
            item.firstName && item.lastName
              ? `${item.firstName} ${item.lastName}`
              : item.firstName
                ? item.firstName
                : item.lastName
          return {
            label: `${name} (Rol: ${item.role?.description})`,
            value: item.id,
            companyId: item?.userCompanyUserType?.[0]?.companyId,
            role: item.roleId
          }
        })
        hideOrShowUser(false)
      } else {
        hideOrShowUser(true)
      }
    }

    const newUsersSelected =
      (data?.users as Item[])?.filter(
        (item) => !!users.some((itemUsers) => itemUsers.value == item.value)
      ) ?? []

    setData((prev) => ({
      ...prev,
      users: newUsersSelected,
    }))

    setInputValues((prev) => ({
      ...prev,
      users: {
        ...prev.users,
        items: users,
      },
    }))
  }

  const hideOrShowUser = (value: boolean) => {
    setInputValues((prev) => ({
      ...prev,
      users: {
        ...prev.users,
        notAllowedRoles: value ? [getUserRole() as Roles] : [],
      },
    }))
  }
  const getQuestionnairesall = useCallback(async () => {
    const { items } = await getQuestionnaires()
    setInputValues((prev) => ({
      ...prev,
      questionnaires: {
        ...prev.questionnaires,
        items: items,
      },
    }))
  },[])

  const removeUsers = () => {
    setData((prev) => ({
      ...prev,
      users: [],
    }))
    setInputValues((prev) => ({
      ...prev,
      users: {
        ...prev.users,
        items: [],
        value: [],
      },
    }))
  }

  const removeQuestionnaires = () => {
    setData((prev) => ({
      ...prev,
      questionnaires: [],
    }))

    setInputValues((prev) => ({
      ...prev,
      questionnaires: {
        ...prev.questionnaires,
        items: [],
      },
    }))
  }
  const onClick = async () => {
    const body = makeBody()

    // fakeDownload()

    setLoading(true)
    try {
      const responseTemplate = await UserService(httpFetch).generateTemplate(
        body
      )
      responseTemplate
        .blob()
        .then((blob) => download(blob, t('MassiveUpload.templateUser')))
    } catch (e) {
      const snackbarBody = {
        open: true,
        type: Types.ERROR as string,
        message: 'Ocurrió un error al descargar la plantilla',
      }
      setOpenSnackbar(snackbarBody)
    }
    setLoading(false)
  }

  const makeBody = () => {
    const { companies, stores, roles, users, userType, questionnaires } =
      data as Record<string, Item[]>
    let formatUserAndStoresByCompanies: Record<
      string,
      {
        id: string
        stores: string[]
        users: {
          id: string
          roleId: string
        }[]
      }
    > = {}

    companies?.forEach((item: Item) => {
      const companyId: string = item.value
      formatUserAndStoresByCompanies = {
        ...formatUserAndStoresByCompanies,
        [companyId]: {
          ...(formatUserAndStoresByCompanies?.[companyId] ?? {}),
          id: companyId,
          stores: stores
            ?.filter((itemStores) => itemStores.companyId == companyId)
            ?.map((itemStores) => itemStores.value),
          users: users
            ?.filter((itemUsers) => itemUsers.companyId == companyId)
            ?.map((itemUsers) => ({
              id: itemUsers.value,
              roleId: itemUsers.role
            })),
        },
      }
    })

    const body = {
      companies: Object.values(formatUserAndStoresByCompanies ?? {}) ?? [],
      rols: roles?.map((e: any) => e.value) ?? [],
      user_types: userType?.map((e: any) => e.value) ?? [],
      questionnaires: questionnaires?.map((e: any) => e.value) ?? [],
    }

    return body
  }

  const download = (blob: any, filename: any) => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }

  useEffect(() => {
    getQuestionnairesall()
  },[getQuestionnairesall])
  useEffect(() => {
    setInputValues((prev) => ({
      ...prev,
      companies: {
        ...prev.companies,
        items: companies,
      },
      roles: {
        ...prev.roles,
        items: roles,
      },
      userType: {
        ...prev.userType,
        items: userType,
      },
    }))
  }, [companies, roles, userType])

  return (
    <>
      <SnackSimple
        close={handleCloseSnackbar}
        open={openSnackbar.open}
        type={openSnackbar.type}
        message={openSnackbar.message}
      />
      <DinamicForm
        input={Object.values(inputValues)}
        values={data}
        onChange={onChange}
      />

      <LoadingButton
        loading={loading}
        sx={{ mt: 2, textTransform: 'uppercase' }}
        variant="contained"
        onClick={onClick}
      >
        {t('MassiveUpload.downloadTemplate')}
      </LoadingButton>
    </>
  )
}
