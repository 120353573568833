import { ActionPlanBody } from "../../../../../../service/questionarie/bodys"
import { ObjectGeneric } from "../../../../../../utils/types/generals"
import { ReportFolioData } from "../../typing/types"
import { getActionPlans } from "../actionPlan"
import { ValidateDataPayload, ValidateErrorsPayload } from "./type"

const validateErrors = ({ currentSection, models, refsByKey }: ValidateErrorsPayload) => {
    refsByKey[`${currentSection}`]?.validate()
    refsByKey[`header`]?.validate()
    const question = models?.[currentSection]?.questions
    const questionArray = Object.entries(question ?? {})
    for (const [key, item] of questionArray) {
        refsByKey[`${currentSection}-${key}-description`]?.validate()
        const answerArray = Object.keys(item.answers)

        for (const keyA of answerArray) {
            refsByKey[`${currentSection}-${key}-${keyA}`]?.validate()
        }
    }
}

const validateData = ({
    order,
    data,
    models,
    currentSection,
    refsByKey,
    dataHeader,
    evidencesModal,
    totalPoint,
    reportFolio,
    t
}: ValidateDataPayload) => {
    validateErrors({ models, currentSection, refsByKey })
    let existSomeError = false
    let errors: any = {
        name: !!dataHeader?.quest_name,
        totalPoints: !!dataHeader?.totalPoints,
    }

    if (dataHeader?.option == '1') {
        const count = getActionPlans({ data: evidencesModal?.data?.global, t }).errors.count ?? 0
        if (count > 0) {
            existSomeError = true
        }
        errors = {
            ...errors,
            actionPlan: count
        }
    }
    const arrayModels = Object.entries(models ?? {})?.sort(([a], [b]) => (order.questions[a] ?? 0) - (order.questions[b] ?? 0))

    if (!dataHeader?.quest_name || !dataHeader?.totalPoints || !totalPoint.total || totalPoint.total != dataHeader?.totalPoints) {
        existSomeError = true
    }

    for (const [key, value] of arrayModels) {
        const section = data?.[key]
        const arrayQuestions = Object.entries(value.questions ?? {})?.sort(([a], [b]) => (order.questions[`${key}-${a}`] ?? 0) - (order.questions[`${key}-${b}`] ?? 0))
        const sectionName = section?.sectionName
        let count = 0

        if (!sectionName) {
            count = count + 1
            existSomeError = true
        }
        errors = {
            ...errors,
            sections: {
                ...errors?.sections ?? {},
                [key]: {
                    sectionName: !!sectionName,
                    count
                }
            }
        }
        // Questions
        for (const [keyQ, valueQ] of arrayQuestions) {
            const question = section?.[keyQ]
            const option = question?.questionOption?.value ?? 1
            const arrayAnswers = Object.entries(valueQ.answers ?? {}).slice(0, !!['3'].includes(option.toString()) ? 1 : undefined)
            const isTypeMultiple = option == 2

            const someAnswerHaveActionPlanActive = Object.values(question?.answer ?? {})?.some((item: any) => !!item?.actionPlanActive)
            const someAnswerHaveReportFolioActive = Object.values(question?.answer ?? {})?.some((item: any) => !!item?.reportFolio)
            let actionPlanMultiple: { data: ActionPlanBody[], errors: ObjectGeneric } = { data: [], errors: {} }
            let reportFolioMultiple: { count: number, error: Record<string, string> } = { count: 0, error: {} }
            if ((!!someAnswerHaveActionPlanActive && isTypeMultiple)) {
                actionPlanMultiple = getActionPlans({ data: evidencesModal?.data?.[`${keyQ}-multiple`], t })
            }

            if ((!!someAnswerHaveReportFolioActive && isTypeMultiple)) {
                reportFolioMultiple = validateReportFolio({ reportFolios: reportFolio, id: `${keyQ}-multiple` })
            }
            let count = 0
            if (!question?.questionText) { count = count + 1 }
            if (!!['3'].includes(option.toString()) && !question?.answerText?.trim()) { 
                count = count + 1 
            }
            count = count + reportFolioMultiple.count
            if (count > 0) {
                existSomeError = true
            }
            errors = {
                ...errors,
                sections: {
                    ...errors?.sections ?? {},
                    [key]: {
                        ...errors?.sections?.[key] ?? {},
                        count: (errors?.sections?.[key].count ?? 0) + count + (actionPlanMultiple.errors?.count ?? 0),
                        question: {
                            ...errors?.sections?.[key]?.question ?? {},
                            [keyQ]: {
                                ...!!['3'].includes(option.toString()) && {
                                    description: !!question?.answerText,
                                },
                                questionText: !!question?.questionText,
                                count,
                                answer: {
                                    ...!!(!!someAnswerHaveActionPlanActive && isTypeMultiple) && {
                                        actionPlan: actionPlanMultiple.errors,
                                    },
                                    ...!!(!!someAnswerHaveReportFolioActive && isTypeMultiple) && {
                                        reportFolio: reportFolioMultiple
                                    },
                                }
                            }
                        }
                    }
                }
            }
            // Answers
            for (const indexA in arrayAnswers) {
                const [keyA] = arrayAnswers[indexA]
                const answers = question?.answer?.[keyA]
                let actionPlan: { data: ActionPlanBody[], errors: ObjectGeneric } = { data: [], errors: {} }
                let reportFolioByAnswer: { count: number, error: Record<string, string> } = { count: 0, error: {} }
                if ((!!answers?.actionPlanActive) && !isTypeMultiple && dataHeader?.option == '0') {
                    actionPlan = getActionPlans({ data: evidencesModal?.data?.[`${keyQ}-${keyA}`], t })
                }
                if ((!!answers?.reportFolio) && !isTypeMultiple && dataHeader?.option == '0') {
                    reportFolioByAnswer = validateReportFolio({ reportFolios: reportFolio, id: `${keyQ}-${keyA}` })
                }

                let count = 0
                count = count + reportFolioByAnswer.count
                if (!['4', '3'].includes(option.toString()) && !answers?.answer) {
                    count = count + 1
                }

                if (['4'].includes(option.toString()) && !answers?.answer?.text && !answers?.answer?.file) {
                    count = count + 1
                }

                if (!answers?.value && (parseInt(answers?.value?.toString()) != 0)) {
                    count = count + 1
                }
                let countSections = (errors?.sections?.[key].count ?? 0) + count + (actionPlan.errors?.count ?? 0)
                if (count > 0 || actionPlan.errors?.count > 0) {
                    existSomeError = true
                }
                errors = {
                    ...errors,
                    sections: {
                        ...errors?.sections ?? {},
                        [key]: {
                            ...errors?.sections?.[key] ?? {},
                            count: countSections,
                            question: {
                                ...errors?.sections?.[key]?.question ?? {},
                                [keyQ]: {
                                    ...errors?.sections?.[key]?.question?.[keyQ] ?? {},
                                    answer: {
                                        ...errors?.sections?.[key]?.question?.[keyQ]?.answer ?? {},
                                        [keyA]: {
                                            score: !!answers?.value,
                                            ...!['3'].includes(option.toString()) && {
                                                description: !!answers?.answer,
                                            },
                                            ...(!!answers?.actionPlanActive) && {
                                                actionPlan: actionPlan.errors
                                            },
                                            ...(!!answers?.reportFolio) && {
                                                reportFolio: reportFolioByAnswer
                                            },
                                            count
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return { existSomeError, errors }
}


const validateReportFolio = (
    { reportFolios, id }:
        { reportFolios: ReportFolioData['data']; id: string }) => {
    let error: Record<string, boolean> = {}
    let count: number = 0
    const value = reportFolios?.[id]?.default

    if (!!value?.limitFolios.maxFolios && !value?.limitFolios.maxFoliosAmount) {
        count += 1
        error = { ...error, maxFoliosAmount: true }
    }
    if (!!value?.rejectsFolio.maxRejects && !value?.rejectsFolio.maxRejectsAmount) {
        count += 1
        error = { ...error, maxRejectsAmount: true }
    }
    if (!!value?.rejectsFolio.enableSurvey && !value?.rejectsFolio.satisfactionQuestion) {
        count += 1
        error = { ...error, satisfactionQuestion: true }
    }

    return { count, error }
}

export { validateErrors, validateData }