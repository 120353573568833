import { format } from 'date-fns'
import { TFunction } from 'react-i18next'
import { getStatus } from '../../../../hooks/status'
import { sharedAws } from '../../../../service/bucketS3/Storage'
import { StoreListBaseResponse } from '../../../../service/stores/response'
import { Constants } from '../../../../utils/constants'
import { Types } from '../../../../utils/enums'
import { Color } from '../../../../utils/theme/types/color'
import { ObjectGeneric } from '../../../../utils/types/generals'
import { isEmail, isPhoneNumber, isRequired, searchInModeLower } from '../../../../utils/validate/validate'
import { storeServices } from '../../../../utils/constants/callService'

export const getStoresList = async (colors: Color, t: TFunction<'translation', undefined>, body?: { query?: string; reloadItems?: boolean }) => {
  const data = await storeServices.unitsList({
    query: body?.query ?? 'size=20&page=1',
  })

  const column: any[] = [
    {
      id: 'keyCode',
      label: t('general.tags.keyCode'),
      sortable: true,
      minWidth: 120,
    },
    {
      id: 'division',
      label: t('general.tags.division'),
      minWidth: 120,
    },
    {
      id: 'zone',
      label: t('general.tags.zone'),
      minWidth: 100,
    },
    {
      id: 'name',
      label: t('general.tags.store_name'),
      minWidth: 100,
    },
    {
      id: 'userManage',
      label: t('general.tags.userManage'),
      minWidth: 100,
    },
    {
      id: 'score',
      label: t('general.tags.score'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'createTime',
      label: t('general.tags.createTime'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'status',
      label: t('general.tags.status'),
      minWidth: 100,
    },
  ]

  const row = !data?.errors
    ? data?.items?.map((item) => {
      return {
        id: item?.id,
        keyCode: item.keyCode,
        division: item.division,
        zone: item.zone,
        name: item?.name,
        userManage: item?.userManage,
        score: item.score || '0',
        createDate: format(new Date(item.createTime), Constants.dateFormat2),
        createHour: format(new Date(item.createTime), Constants.hourFormat),
        status: getStatus(item?.statusId, colors, t),
        createTime: {
          label: item.createTime && format(new Date(item.createTime), Constants.dateFormatGeneral),
          value: item.createTime ? new Date(item.createTime).getTime() : 0,
          type: 'date',
        },
      }
    })
    : []

  return { column, row, totalPages: data.totalPages, totalItems: data?.totalItems, currentPAge: data?.currentPage }
}

export const filterRow = (value: { [x: string]: any }, list: any[]) => {
  const from = value.from?.valueOf()
  const to = value.to?.valueOf()

  return list.filter((item) => {
    return (
      (from ? new Date(from).setHours(0, 0, 0, 0) <= item?.createTime?.value : true) &&
      (to ? new Date(to).setHours(23, 59, 59, 999) >= item?.createTime?.value : true) &&
      (value?.month instanceof Array ? (value?.month.length > 0 ? value?.month.find((e: any) => e.value === item.monthNumber) : true) : true) &&
      (value?.createYear instanceof Array ? (value?.createYear.length > 0 ? value?.createYear.find((e: any) => e.value === item.createYear) : true) : true) &&
      (value?.zones instanceof Array ? (value?.zones.length > 0 ? value?.zones.find((e: any) => e.label == item.zone) : true) : true) &&
      (value?.divisions instanceof Array ? (value?.divisions.length > 0 ? value?.divisions.find((e: any) => e.label == item.division) : true) : true) &&
      (value?.users instanceof Array ? (value?.users.length > 0 ? value?.users.find((e: any) => e.label == item.userManage) : true) : true) &&
      (value?.stores instanceof Array ? (value?.stores.length > 0 ? value?.stores.find((e: any) => e.label?.toString().split(' - ')[0] == item.keyCode) : true) : true) &&
      (value?.stores instanceof Array ? (value?.stores.length > 0 ? value?.stores.find((e: any) => e.label?.toString().split(' - ')[1] == item.name) : true) : true) &&
      (value?.class instanceof Array ? (value?.class.length > 0 ? value?.class.find((e: any) => e.label == item.childZone) : true) : true) &&
      (searchInModeLower(item?.keyCode, value?.search) ||
        searchInModeLower(item?.division, value?.search) ||
        searchInModeLower(item?.zone, value?.search) ||
        searchInModeLower(item?.name, value?.search) ||
        searchInModeLower(item?.score, value?.search) ||
        searchInModeLower(item?.userManage, value?.search))
    )
  })
}

export const handleErrors = (values: any, t: any, ignore?: string[]) => {
  let error = {} as ObjectGeneric

  if (values?.name !== null) {
    if (isRequired(values?.name)) error.name = t('general.validations.required')
  }

  if (values?.score !== null) {
    if (isRequired(values?.score) || values?.score === '-') error.score = t('general.validations.required')
  }
  if (values?.longitude !== null) {
    if (isRequired(values?.longitude) || values?.longitude === '-' || values?.longitude === '') error.longitude = t('general.validations.required')
  }
  if (values?.latitude !== null) {
    if (isRequired(values?.latitude) || values?.latitude === '-' || values?.latitude === '') error.latitude = t('general.validations.required')
  }

  if (values?.companyId !== null) {
    if (isRequired(values?.companyId)) error.companyId = t('general.validations.required')
  }

  if (values?.email !== null) {
    if (isRequired(values?.email)) error.email = t('general.validations.required')
    else if (values?.email && !isEmail(values?.email)) error.email = t('general.validations.wrongEmail')
  }

  if (values?.zipCodeId !== null) {
    if (isRequired(values?.zipCodeId)) error.zipCodeId = t('general.validations.required')
  }
  if (values?.states !== null) {
    if (isRequired(values?.states)) error.states = t('general.validations.required')
  }
  if (values?.city !== null) {
    if (isRequired(values?.city)) error.city = t('general.validations.required')
  }

  if (values?.address !== null) {
    if (isRequired(values?.address)) error.address = t('general.validations.required')
  }

  if (values?.status !== null) {
    if (isRequired(values?.status)) error.status = t('general.validations.required')
  }

  if (values?.keyCode !== null) {
    if (isRequired(values?.keyCode)) error.keyCode = t('general.validations.required')
  }

  if (values?.phone !== null) {
    /* if (values?.phone.length < 3) error.phone = `${t('general.validations.required')}`
    else  */ if (values?.phone?.length > 3 && !isPhoneNumber(values?.phone)) error.phone = `${t('general.validations.min')} 10 ${t('general.validations.character')}`
  }

  return error
}

export const submit = async (data: ObjectGeneric, t: TFunction<'translation', undefined>, id: number | undefined) => {
  let snackbarBody = {
    open: false,
    type: '',
    message: '',
  }
  let body = {} as any
  Object.entries(data).forEach(([key, value]) => {
    if (value) {
      const dataToSubmit = ['keyCode', 'picture', 'name', 'email', 'companyId', 'userManageId', 'score', 'states', 'city', 'address', 'latitude', 'longitude', 'zipCodeId']
      if (dataToSubmit.includes(key) && value) {
        body = {
          ...body,
          [key]: value,
        }
      } else if (key === 'status') {
        body = {
          ...body,
          statusId: value,
        }
      } else if (key === 'phone') {
        body = {
          ...body,
          [key]: isPhoneNumber(value) ? value : '',
        }
      }
    }
  })

  let response: StoreListBaseResponse

  body = {
    ...body,
    ...(data?.picture?.name && { picture: await sharedAws.uploadAndGetUrl({ file: data.picture, otherBucket: process.env.REACT_APP_BUCKET }) }),
    zoneId: data.fatherZone ?? null,
    divisionId : data.divisions ?? null,
    classId: data?.class ?? null
  }
  delete body.states
  delete body.city
  if (id) {
    if (!body.picture) body.picture = ''
    if (!body.userManageId) body.userManageId = null
    response = await storeServices.updateStore(body, id)
  } else {
    response = await storeServices.createStore(body)
  }

  if (response.errors) {
    snackbarBody = {
      open: true,
      type: Types.ERROR as string,
      message: t(`stores.snackbar.${id ? 'update' : 'create'}.error`),
    }
  } else {
    snackbarBody = {
      open: true,
      type: Types.SUCCESS as string,
      message: t(`stores.snackbar.${id ? 'update' : 'create'}.success`),
    }
  }
  return { snackbarBody, response }
}

export const formatAssociateUserTable = async (t: TFunction<'translation', undefined>, id: number) => {
  const response = await storeServices.getUserAssociated({ id })
  const column = [
    {
      id: 'name',
      label: t('general.tags.name'),
      sortable: true,
    },
    {
      id: 'curp',
      label: t('general.tags.curp'),
      sortable: true,
    },
    {
      id: 'userType',
      label: t('general.tags.userType'),
      sortable: true,
    },
    {
      id: 'role',
      label: t('general.tags.role'),
      sortable: true,
    },
  ]

  return {
    column,
    row:
      response
        ?.map((item) => ({
          id: item.id,
          name: item.fullName,
          curp: item?.curp ?? '',
          role: item.role,
          userType: item.userType
        })) ?? [],
  }
}

export const filterRowUser = (value: { [x: string]: any }, list: any[]) => {
  return list?.filter((item) => searchInModeLower(item?.name, value?.search) || searchInModeLower(item?.curp, value?.search))
}
