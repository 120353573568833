import { useTranslation } from 'react-i18next'
import { ValidateDateRange } from '../../utils/methods/validateDateRange'
import { onChange } from '../../utils/types'
import { useEffect, useState } from 'react'
import { Item, ObjectGeneric } from '../../utils/types/generals'
import { Query } from '../../pages/reportFolio/pages/dashboard/typing'
import { format, parse } from 'date-fns'
import { returnQuery } from '../../utils/methods/returnQuery'
import { Constants } from '../../utils/constants'
import { GridPaginationModel } from '@mui/x-data-grid'
import { FilterBody, FilterProps } from './typing'

const useFilter = ({ formatFilter, defaultValues, defaultPagination, externalValidation }: FilterProps): FilterBody => {
  const { t } = useTranslation()
  const [filterData, setFilterData] = useState<ObjectGeneric>(defaultValues ?? {})
  const [errors, setErrors] = useState<ObjectGeneric>({})
  const [query, setQueary] = useState<Query>({
    pagination: { page: defaultPagination?.page ?? 0, pageSize: defaultPagination?.pageSize ?? 20 },
  })
  const [filterString, setFilterString] = useState('')
  const [wrongDate, setWrongDate] = useState(false)
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 20,
  })
  const [counter, setCounter] = useState<number | null>(null)

  const resetPagination = () => {
    handlePagination({ page: 1, pageSize: 20 })
  }

  const handleFilterByExternal = ({ name, value }: { name: string; value: any }) => {
    setFilterData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const onChange: onChange = ({ target }, blur) => {
    const { name, value: values } = target
    let value = values

    const datesName = ['from', 'to'].includes(name)
    if (!!datesName) {
      const day = new Date(value)?.getDay?.()
      value = !!day || day == 0 ? value : parse(value, Constants.dateFormat2, new Date())
    }
    const { endDate, startDate } = ValidateDateRange({ name, value, blur: blur as any, from: filterData?.from, to: filterData?.to })
    let body = { ...JSON.parse(JSON.stringify(filterData)) }

    if (!datesName || !(blur as { blur: boolean })?.blur) {
      body = {
        ...filterData,
        [name]: value,
      }

      setFilterData((prev) => ({
        ...prev,
        [name]: value,
      }))

      let externalBody = body

      if (!!externalValidation) {
        externalBody = externalValidation?.({ name, value, body, setFilterData })
      }

      if (startDate && endDate && startDate > endDate) {
        delete externalBody?.to
        delete externalBody?.from
      }
      let queryString = ''

      if (!datesName || !!(datesName && (!!externalBody?.[name]?.valueOf() || externalBody?.[name] === null))) {
        let queries = Object.entries(externalBody ?? {})?.filter(
          ([key, item]: any) => (item instanceof Array && item?.length > 0) || !!item?.value || (['string', 'boolean'].includes(typeof item) && !!item) || (!!item?.valueOf?.() && ['from', 'to'].includes(key))
        )

        queryString = queries
          ?.map(([key, item]: any) => {
            if (item instanceof Array) {
              return `${formatFilter?.[key] ?? key}=` + item?.map((items: Item) => (typeof items === 'object' ? items.value : items))
            } else if (['from', 'to'].includes(key)) {
              const day = new Date(item)?.getDay?.()
              return `${formatFilter[key] ?? key}=` + (!!day || day == 0 ? format(new Date(item?.valueOf?.()), Constants.dateFormat3) : item)
            } else if (typeof item === 'boolean') {
              return `${formatFilter[key] ?? key}=${item}`
            }
            return `${formatFilter[key] ?? key}=${item?.value || item?.trim()}`
          })
          ?.join('&')

        const dateValidate = (!startDate && JSON.stringify(startDate) !== 'null') || (!endDate && JSON.stringify(endDate) !== 'null') || (startDate && endDate && startDate <= endDate)

        if (dateValidate || !datesName) {
          if (!!queries && queryString !== query.filter) {
            setWrongDate(false)
            // setQueary((prev) => ({ ...prev, all: returnQuery({ ...query, filter: queryString }) }))
          } else setWrongDate(true)
        } else setWrongDate(true)

        let queryCopy = structuredClone(query)

        queryCopy = {
          ...queryCopy,
          pagination: {
            pageSize: queryCopy?.pagination?.pageSize ?? 20,
            page: 0,
          },
        }
        setPaginationModel((prev) => ({
          ...prev,
          page: 0,
        }))
        setFilterString(queryString)
        setQueary((prev) => ({ ...prev, ...queryCopy, filter: queryString, all: returnQuery({ ...queryCopy, filter: queryString }) }))
      }
    } else {
      if ((!startDate && name === 'from') || (!endDate && name === 'to')) {
        body = {
          ...body,
          [name]: '',
        }

        setFilterData((prev) => ({
          ...prev,
          [name]: '',
        }))
      }
    }

    if (datesName) {
      if (startDate && endDate) {
        if (startDate > endDate) {
          setErrors((prev) => ({
            ...prev,
            [name]: t('general.wrongDate'),
          }))
        } else {
          setErrors((prev) => ({
            ...prev,
            from: null,
            to: null,
          }))
        }
      } else {
        setErrors((prev) => ({
          ...prev,
          from: null,
          to: null,
        }))
      }
    }
  }

  const handlePagination = (data: GridPaginationModel) => {
    const newData = {
      page: data.page - 1,
      pageSize: data.pageSize,
    }

    setQueary((prev) => ({
      ...prev,
      pagination: data,
      all: returnQuery({ ...query, pagination: data }),
    }))

    setPaginationModel(newData)
  }

  const sortTable = (data?: { field: string; sort: 'ASC' | 'DESC'; count?: number }) => {
    setQueary((prev) => ({
      ...prev,
      sort: data,
      all: returnQuery({ ...query, sort: data }),
    }))
  }

  const cleanFilter = () => {
    setErrors({})
    setQueary({ pagination: query.pagination })
    setFilterData({})
    setFilterString('')
  }

  useEffect(() => {
    setCounter(null)
    let count = 0
    for (let key in filterData) {
      if (filterData[key] instanceof Array) {
        if (filterData[key]?.length > 0) count++
      } else if (filterData[key] != null && filterData[key] != '') count++
    }

    setCounter(count)
  }, [filterData])
  return {
    errors,
    filterData,
    query,
    paginationModel,
    counter,
    wrongDate,
    filterString,
    resetPagination,
    onChange,
    handlePagination,
    cleanFilter,
    sortTable,
    handleFilterByExternal
  }
}
export { useFilter }
