import { TFunction } from 'react-i18next'
import { ItemMenu } from '../../../../../../../components/baseMenu'
import { UserService } from '../../../../../../../service'
import { areaService } from '../../../../../../../service/area'
import { departmentService as departService } from '../../../../../../../service/department'
import { divisionService as dService } from '../../../../../../../service/division'
import { reportFolioServices as service } from '../../../../../../../service/reportFolio'
import { storesService } from '../../../../../../../service/stores'
import { httpFetch } from '../../../../../../../utils/crud'
import { InputModel } from '../../../../../../../utils/types'
import { Roles } from '../../../../../../../utils/enums'

const reportFolioServices = service(httpFetch)
const storeServices = storesService(httpFetch)
const areaServices = areaService(httpFetch)
const departmentService = departService(httpFetch)
const divisionService = dService(httpFetch)
const userServiceFetch = UserService(httpFetch)

const size = {
  xl: 6,
  lg: 6,
  md: 6,
  sm: 6,
  xs: 6,
}

const filterInputs: { [x: string]: InputModel } = {
  search: {
    label: 'general.search',
    translate: true,
    name: 'search',
    type: 'text',
    value: null,
  },
  createYear: {
    label: 'general.filterDate.year',
    translate: true,
    name: 'createYear',
    type: 'filter-select',
    items: [],
    value: null,
    size: size,
  },
  month: {
    label: 'general.filterDate.month',
    translate: true,
    name: 'month',
    type: 'filter-select',
    items: [],
    value: null,
    size: size,
  },
  from: {
    label: 'general.tags.from',
    translate: true,
    name: 'from',
    type: 'date',
    value: null,
    rest: {
      max: 'to',
    },
    size: size,
  },
  to: {
    label: 'general.tags.to',
    translate: true,
    name: 'to',
    type: 'date',
    value: null,
    rest: {
      min: 'from',
    },
    size: size,
  },
  canResponse: {
    label: 'reportFolio.folios',
    translate: true,
    name: 'canResponse',
    type: 'select-search',
    items: [],
    value: null,
    notAllowedRoles: [Roles.ADMIN],
  },
  area: {
    label: 'general.tags.areas',
    translate: true,
    name: 'area',
    type: 'filter-select',
    items: [],
    value: null,
    size: size,
  },
  department: {
    label: 'general.tags.departments',
    translate: true,
    name: 'department',
    type: 'filter-select',
    items: [],
    value: null,
    size: size,
  },
  unit: {
    label: 'general.tags.store',
    translate: true,
    name: 'unit',
    type: 'filter-select',
    items: [],
    value: null,
  },
  division: {
    label: 'general.tags.division',
    translate: true,
    name: 'division',
    type: 'filter-select',
    items: [],
    value: null,
  },
  user: {
    label: 'general.tags.user',
    translate: true,
    name: 'user',
    type: 'filter-select',
    items: [],
    value: null,
    notAllowedRoles: [Roles.STORE, Roles.AGENT , Roles.PARTNER,Roles.SUPPORT,Roles.AUDIT],
  },
  userType: {
    label: 'general.tags.userType',
    translate: true,
    name: 'userType',
    type: 'filter-select',
    items: [],
    value: null,
    notAllowedRoles: [Roles.STORE, Roles.AGENT , Roles.PARTNER,Roles.SUPPORT, Roles.AUDIT],
  },
  status: {
    label: 'general.tags.status',
    translate: true,
    name: 'status',
    type: 'filter-select',
    items: [],
    value: null,
  },
}

const menuItems: ItemMenu[] = [
  {
    label: 'Informe de avance',
    value: 1,
  },
  {
    label: 'Reenviar informe',
    value: 2,
  },
  {
    label: 'Ver detalle',
    value: 3,
  },
]

const downloadHeaders = ({ t }: { t: TFunction<'translation', undefined> }) => {
  return [
    // {
    //   id: 'id',
    //   label: t('reportFolio.dashboard.table.foliosAmount'),
    // },
    {
      id: 'priorityHeader',
      label: t('reportFolio.dashboard.table.priority'),
    },
    {
      id: 'foliosAmount',
      label: t('reportFolio.dashboard.table.foliosAmount'),
    },
    {
      id: 'unitHeader',
      label: t('reportFolio.dashboard.table.unit'),
    },
    {
      id: 'requestDate',
      label: t('reportFolio.dashboard.table.requestDate'),
    },
    {
      id: 'solveDate',
      label: t('reportFolio.dashboard.table.solveDate'),
    },
    {
      id: 'timeElapsed',
      label: t('reportFolio.dashboard.table.timeElapsed'),
    },
    {
      id: 'statusHeader',
      label: t('reportFolio.dashboard.table.status'),
    },
  ]
}

const resendReportForm = {
  sendCreatedBy: {
    label: `reportFolio.modal.sendReport.requestingUser`,
    name: 'sendCreatedBy',
    value: false,
    type: 'check',
    translate: true,
  },
  sendUserAssign: {
    label: `reportFolio.modal.sendReport.assignUser`,
    name: 'sendUserAssign',
    value: false,
    type: 'check',
    translate: true,
  },
  sendManageStore: {
    label: `reportFolio.modal.sendReport.agentStore`,
    name: 'sendManageStore',
    value: false,
    type: 'check',
    translate: true,
  },
} as { [x: string]: InputModel }

const resendReportFormEmails = {
  externalEmails: {
    label: ``,
    name: 'externalEmails',
    value: '',
    rest: {
      multiline: true,
      rows: 5,
    },
    translate: true,
  },
}

const formatFilter = {
  search: 'search',
  createYear: 'year',
  month: 'month',
  from: 'date_init',
  to: 'date_end',
  area: 'areas_ids',
  department: 'departments_ids',
  unit: 'stores_ids',
  division: 'division_ids',
  user: 'user_ids',
  userType: 'user_type_ids',
  status: 'status_ids',
  canResponse: 'can_response',
}
export { filterInputs, size, formatFilter, reportFolioServices, menuItems, storeServices, areaServices, departmentService, userServiceFetch, divisionService, resendReportForm, resendReportFormEmails, downloadHeaders }
