import { Color } from '../../utils/theme/types/color'

export const useStyles = (props: { color: Color; copy?: boolean; valueSize?: string; captionSize?: string; align?: string }) => ({
  container: {
    padding: '0 10px 10px 10px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    ...(props.align && {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: props.align,
    }),
    '& .MuiTypography-root': {
      wordBreak: 'break-word',
    },
  },
  fullname: {
    fontSize: props.valueSize ?? '16px',
    fontWeight: '500',
    color: props.color.color9,
    width: props.copy ? 'calc(100% - 40px)' : 'fit-content',
  },
  label: {
    fontSize: props.captionSize ?? '12px',
    fontWeight: '400',
    color: props.color.color10,
  },
  valueContainer: {
    display: 'flex',
    position: 'relative',
    gap: '4px',
    justifyContent: props.align ?? 'space-between',
  },
  actions: {
    display: 'flex',
    gap: '5px',
  },
  icon: {
    padding: '0px',
  },
  iconEdit: {
    padding: '0px',
  },
})
