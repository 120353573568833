import { format } from 'date-fns'
import { TFunction } from 'react-i18next'
import { departmentService } from '../../../../../../service/department'
import { ResponseDepartment } from '../../../../../../service/department/response'
import { Constants } from '../../../../../../utils/constants'
import { httpFetch } from '../../../../../../utils/crud'
import {  Types } from '../../../../../../utils/enums'
import { handleAllErrors } from '../../../../../../utils/methods/handleErrors'

import { Color } from '../../../../../../utils/theme/types/color'
import { ObjectGeneric } from '../../../../../../utils/types/generals'
import { isEmail, isRequired, isPhoneNumber } from '../../../../../../utils/validate/validate'
import { reportFolioServices } from '../../../../../../service/reportFolio'

export const getAllConfigs = async (colors: Color, t: TFunction<'translation', undefined>) => {
  const data = await reportFolioServices(httpFetch).getAllConfigs()

  const column: any[] = [
    {
      id: 'id',
      label: t('general.tags.id'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'area',
      label: t('general.tags.area'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'departments',
      label: t('reportFolio.setting.departments'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'createdBy',
      label: t('reportFolio.setting.updatedBy'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'createTime',
      label: t('general.tags.updateTime'),
      sortable: true,
      minWidth: 100,
    },
  ]

  let row: any[] = !data?.errors
    ? data?.map((item: any) => {
        let departments = 0
        item?.areasAndDepartments?.forEach((e: any) => {
          departments += e.departments?.length
        })
        
        return {
          id: item.id,
          createTime: {
            label: item?.createTime ? format(new Date(item.createTime), Constants.dateFormatGeneral) : 'N/A',
            value: item?.createTime ? new Date(item.createTime).getTime() : 0,
            type: 'date',
          },
          areas: item.areasAndDepartments,
          areasTable: item.areasAndDepartments?.map((itemAreas: any) => itemAreas.name)?.join(', '),
          departments,
          createdBy: `${item?.createdBy?.firstName ?? ''} ${item?.createdBy?.lastName ?? ''}`,
          createById: item?.createdBy?.id,
        }
      })
    : []

  return { column, row }
}

export const filterRow = (value: { [x: string]: any }, list: any[]) => {
  return list.filter((item) => {
    return (
      (!!value?.area.length ? value?.area.some((areaValue: any) => item?.areas?.some((itemAreas: any) => itemAreas?.id == areaValue?.value)) : true) &&
      (!!value?.deparmets.length ? value?.deparmets.find((e1: any) => item.users.find((e2: any) => `${e2.firstName} ${e2.lastName}` == e1.label)) : true)
    )
  })
}

export const handleErrors = (values: any, t: any, ignore?: string[]) => {
  let error = {} as ObjectGeneric

  if (values?.name !== null) {
    if (isRequired(values?.name)) error.name = t('general.validations.required')
  }

  if (values?.email !== null) {
    if (isRequired(values?.email)) error.email = t('general.validations.required')
    else if (values?.email && !isEmail(values?.email)) error.email = t('general.validations.wrongEmail')
  }

  if (values?.phone !== null) {
    if (values?.phone?.length > 3 && !isPhoneNumber(values?.phone)) error.phone = `${t('general.validations.min')} 10 ${t('general.validations.character')}`
  }

  return error
}

export const submit = async (data: ObjectGeneric, t: TFunction<'translation', undefined>, id: number | undefined) => {
  let snackbarBody = {
    open: false,
    type: '',
    message: '',
  }

  const { name, phone, email, users } = data
  let body = {} as any

  body = {
    name,
    description: null,
    phone,
    email,
    statusId: 1,
    users: users?.map((e: any) => e.value),
  }

  let response: ResponseDepartment

  if (id) {
    response = await departmentService(httpFetch).updateDepartment(body, id)
  } else {
    response = await departmentService(httpFetch).createDepartment(body)
  }

  if (response.errors) {
    snackbarBody = {
      open: true,
      type: Types.ERROR as string,
      message: handleAllErrors(response?.errors?.[0]?.code ?? '', t, `department.snackbar.${id ? 'update' : 'create'}.error`),
    }
  } else {
    snackbarBody = {
      open: true,
      type: Types.SUCCESS as string,
      message: t(`department.snackbar.${id ? 'update' : 'create'}.success`),
    }
  }

  return {
    snackbarBody,
    response,
  }
}

export const getConfigById = async (id: number) => {
  const data = await reportFolioServices(httpFetch).getConfigById(id)
  return data
}
