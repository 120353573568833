import { useEffect, useState, useCallback, FormEvent, useContext, useLayoutEffect } from 'react'
import { GridRowsProp } from '@mui/x-data-grid'
import {
  getArea,
  getData,
  getStore,
  getDepartments,
  getUserTypes,
  getUsers,
  getDivisions,
} from '../methods'
import { useTranslation } from 'react-i18next'
import { handleStatus } from '../../../utils'
import {
  Item,
  ObjectGeneric,
  onChange,
} from '../../../../../../../utils/types/generals'
import { downloadHeaders, filterInputs, formatFilter, reportFolioServices } from '../constants/states'
import {
  getPreviousYears,
  monthYear,
} from '../../../../../../../utils/constants/date_month'
import { Roles, Status, Types } from '../../../../../../../utils/enums'
import { isEmailSeparateToCome } from '../../../../../../../utils/validate/validate'
import { GlobalContext } from '../../../../../../../contexts/Global'
import { BodyUnit, DateItems, FoliosAmount, HeaderSimple, NumberOfFolio, PriorityItem, Settings, StatusItem, TimeElapsed, UserAssigned } from '../components'
import { useNavigate } from 'react-router'
import { DashboardReportFolioContext } from '../../../context'
import { removeAllReference } from '../../../../../../../utils/methods/removedReference'

const useGetData = () => {
  const { t } = useTranslation()
  const navigation = useNavigate()
  const { state } = useContext(GlobalContext)
  const { role } = state
  const [dependientItems, setDependientItems] = useState<Record<string, Item[]>>({})
  const [inputFilter, setInputFilter] = useState(filterInputs)
  const [headers, setHeaders] = useState<{ label: string; id: string }[]>([])
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState({ resendButton: true, resendButtonLoading: false, })
  const [snackbar, setSnarbar] = useState({ message: '', type: Types.SUCCESS, open: false, })
  const [resendReport, setResndReport] = useState<ObjectGeneric>({ open: false, })
  const [total, setTotal] = useState({ totalPages: 1, totalItems: 0, })
  /* TABLE DATA */
  const [detail, setDetail] = useState({ id: 0, reportUrl: '', positionMenu: {}, })
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => { setAnchorEl(event.currentTarget) }
  const { filterData, setFilterPayload } = useContext(DashboardReportFolioContext)
  const closeSnackbar = () => { setSnarbar((prev) => ({ ...prev, open: false, })) }

  const closeResendModal = () => {
    setResndReport({ open: false, })
    closeSnackbar()
    setDisabled((prev) => ({ ...prev, resendButtonLoading: false, resendButton: true, }))
  }

  const submitResendReport = (e?: FormEvent<HTMLFormElement>) => {
    setDisabled((prev) => ({ ...prev, resendButtonLoading: true, }))

    e?.preventDefault()
    const emails = resendReport?.externalEmails
      ?.split(',')
      ?.filter((item: string) => !!item)

    let body: ObjectGeneric = {
      sendCreatedBy: !!resendReport?.sendCreatedBy,
      sendUserAssign: !!resendReport?.sendUserAssign,
      sendManageStore: !!resendReport?.sendManageStore,
    }

    if (!!emails?.length) body.externalEmails = emails

    reportFolioServices
      .resendReportFolio({ id: detail.id, body })
      .then((response) => {
        if (!response?.errors) {
          closeResendModal()
          setSnarbar((prev) => ({
            ...prev,
            open: true,
            message: t('reportFolio.snackbar.sendReport.success'),
            type: Types.SUCCESS,
          }))
        } else {
          setSnarbar((prev) => ({
            ...prev,
            open: true,
            message: t('reportFolio.snackbar.sendReport.ERR_NOT_FOUND'),
            type: Types.ERROR,
          }))
        }
      })
      .finally(() => { setDisabled((prev) => ({ ...prev, resendButtonLoading: false, })) })
  }

  const handleSendReportData: onChange = ({ target }) => {
    const { name, value } = target
    const body = { ...structuredClone(resendReport), [name]: value, }
    /* START EMAIL VALIDATE */
    const emailValue = name === 'externalEmails' ? value : body?.externalEmails
    let emailIsSeparate = false
    emailIsSeparate = !(!emailValue || isEmailSeparateToCome(emailValue))
    /* END EMAIL VALIDATE */
    delete body?.open

    setDisabled((prev) => ({
      ...prev,
      resendButton:
        !Object.values(body ?? {})?.some((item) => !!item) || emailIsSeparate,
    }))

    setResndReport((prev) => ({
      ...prev,
      [name]:
        name === 'externalEmails'
          ? value?.replace(/(\r\n|\n|\r|\s)/gm, '')
          : value,
    }))
  }

  const optionMenu = ({ item }: { item: Item }) => {
    const optionMenuItems: { [x: string]: () => void } = {
      '1': () => {
        if (!!detail.reportUrl) window.open(detail.reportUrl, 'blank')
        else {
          reportFolioServices
            .generateReportFolio({ id: detail.id })
            .then((data) => {
              window.open(data.reportUrl, 'blank')
            })
        }
      },
      '2': () => {
        setResndReport({
          open: true,
        })
      },
      '3': () => {
        navigation(`/dashboard/report-folio/dashboard/detail/${detail.id}`)
      },
    }
    // setOption(item)
    optionMenuItems[item?.value]?.()
  }
  const [row, setRow] = useState<GridRowsProp>([])

  const columns: any[] = [
    {
      id: 'canResponse',
      label: '',
      renderCell: (params: any) => <NumberOfFolio {...{ ...params, role }} />,
    },
    {
      id: 'priority',
      label: '',
      sortable: true,
      renderCell: (params: any) => <PriorityItem {...params} />,
    },
    {
      id: 'foliosAmount',
      label: t('reportFolio.dashboard.table.foliosAmount'),
      sortable: true,
      renderCell: (params: any) => <FoliosAmount {...params}/>,
    },
    {
      id: 'unit',
      label: '',
      sortable: true,
      column: () => <HeaderSimple value={[t('reportFolio.dashboard.table.areaDepartment'), t('reportFolio.dashboard.table.unitUser')]} />,
      renderCell: (params: any) => <BodyUnit {...params} />,
    },
    {
      id: 'usersAssigned',
      label: t('reportFolio.dashboard.table.usersAssigned'),
      renderCell: (params: any) => <UserAssigned {...params} />,
    },
    {
      id: 'requestDate',
      label: t('reportFolio.dashboard.table.requestDate'),
      sortable: true,
      renderCell: (params: any) => <DateItems {...params} />,
    },
    {
      id: 'solveDate',
      label: t('reportFolio.dashboard.table.solveDate'),
      sortable: true,
      renderCell: (params: any) => <DateItems {...params} />,
    },
    {
      id: 'timeElapsed',
      label: '',
      sortable: true,
      headerCell: {
        '&.header-cell': {
          '& div': {
            width: '120px',
          },
        },
      },
      column: () => <HeaderSimple value={[t('reportFolio.dashboard.table.timeElapsed'), t('reportFolio.dashboard.table.daysElapsed')]} />,
      renderCell: (params: any) => <TimeElapsed {...params} />,
    },
    {
      id: 'status',
      label: t('reportFolio.dashboard.table.status'),
      rowTdStyles: {
        maxWidth: '80px',
      },
      renderCell: (params: any) => <StatusItem {...params} />,
    },
    {
      id: 'settings',
      label: t('general.tags.action'),
      minWidth: '50px',
      renderCell: (params: any) => <Settings {...{ ...params, setDetail, handleClick, rowLength: row?.length }} />,
    },
  ]

  const requestTable = useCallback(
    (data?: { query?: string | undefined }) => {
      setLoading(true)
      getData(data)
        .then((list) => {
          const totalPages = list?.totalPages ?? 1

          setTotal(() => ({
            totalItems: list?.totalItems ?? 0,
            totalPages: totalPages,
          }))

          setRow(
            list?.rows?.map((itemRows) => ({
              ...itemRows,
              statusHeader: handleStatus({ t, status: itemRows.status.value })
                ?.statusSelected?.label,
            }))
          )
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [t, total]
  )

  const getStatus = () => {
    let statusList = Object.values(handleStatus({ t, status: Status.ACTIVE }).listStatus)

    if (role == Roles.STORE) {
      statusList = statusList?.filter((item) => ![Status.ACTIVE, Status.CANCEL].includes(item.value))
    } else {
      statusList = statusList?.filter((item) => ![Status.ACTIVE, Status.CANCEL].includes(item.value))
    }

    setInputFilter(prev => ({
      ...prev,
      status: {
        ...prev.status,
        items: statusList ?? [],
      }
    }))
  }
  const getDate = () => {
    const monthList = monthYear.map((e) => ({
      ...e,
      value: e.value + 1,
    }))
    const listYears = getPreviousYears()
    const listResponse = [
      { label: t('reportFolio.requested'), value: 'false' },
      { label: t('reportFolio.toBeAttended'), value: 'true' },
    ]
    setInputFilter(prev => ({
      ...prev,
      createYear: {
        ...prev.createYear,
        items: listYears,
      },
      month: {
        ...prev.month,
        items: monthList,
      },

      canResponse: {
        ...prev.canResponse,
        items: listResponse ?? [],
      },
    }))
  }
  const getAllDivisions = async () => {
    const list = await getDivisions()
    setInputFilter(prev => ({
      ...prev,
      division: {
        ...prev.division,
        items: list ?? [],
      }
    }))
  }

  const getAllUserTypes = async () => {
    const list = await getUserTypes()
    setInputFilter(prev => ({
      ...prev,
      userType: {
        ...prev.userType,
        items: list ?? [],
      }
    }))
  }

  const getAllUsers = async () => {
    const list = await getUsers()

    setInputFilter(prev => ({
      ...prev,
      user: {
        ...prev.user,
        items: list ?? [],
      }
    }))
  }

  const getAllUnits = async () => {
    const list = await getStore()


    setInputFilter(prev => ({
      ...prev,
      unit: {
        ...prev.unit,
        items: list ?? [],
      }
    }))
  }

  const getAllAreas = async () => {
    const list = await getArea()

    setInputFilter(prev => ({
      ...prev,
      area: {
        ...prev.area,
        items: list ?? [],
      }
    }))
  }

  const getAllDepartments = async () => {
    const list = await getDepartments()
    setDependientItems((prev) => ({
      ...prev,
      department: list ?? [],
    }))
    const newDepartments = list?.filter((e: any) => filterData.filterData?.area?.find((e2: any) => e2.value == e.areaId))
  
    setInputFilter(prev => ({
      ...prev,
      department: {
        ...prev.department,
        items: newDepartments ?? [],
      }
    }))
  }
  useEffect(() => {
    setHeaders(downloadHeaders({ t }))
    getAllDivisions()
    getAllUserTypes()
    getAllUsers()
    getAllUnits()
    getAllAreas()
    getAllDepartments()
    getDate()
    getStatus()
    //eslint-disable-next-line
  }, [])

  

  useLayoutEffect(() => {
    setFilterPayload({
      formatFilter,
      externalValidation: ({ name, body, setFilterData, value }) => {
        let copyBody: Record<string, any> = removeAllReference(body)
        if (name === 'area') {
          const { department } = copyBody
          const newDepartments = department?.filter((e: any) => value.find((e2: any) => e2.value == e.areaId))
          let arrayChilds: any[] = []

          value?.forEach((e: any) => {
            const id = e.value
            const childs = dependientItems?.department?.filter((child) => child.areaId == id)
            arrayChilds.push(...childs)
          })

          setInputFilter(prev => ({
            ...prev,
            department: {
              ...prev.department,
              items: arrayChilds ?? [],
            }
          }))
          setFilterData((prev) => ({
            ...prev,
            department: newDepartments,
          }))

          copyBody = {
            ...copyBody,
            departments: newDepartments,
          }
        }

        return copyBody
      }
    })
  }, [dependientItems?.department])
  return {
    row: row as any[],
    columns,
    total,
    loading,
    headers,
    resendReport,
    disabled,
    snackbar,
    dependientItems,
    detail,
    anchorEl,
    inputFilter,
    filterData,
    setInputFilter,
    optionMenu,
    setAnchorEl,
    closeResendModal,
    handleSendReportData,
    submitResendReport,
    requestTable,
    closeSnackbar,
  }
}

export { useGetData }
