import { useEffect, useState } from "react"
import { dashboardServiceFetch } from "../../../../../../utils/constants/callService"
import { Roles } from "../../../../../../utils/enums"
import { getUserRole } from "../../../../../../service/auth"
import { percentage } from "../../../../../../utils/validate/validate"

const useBody = () => {
  const [text, setText] = useState({
    title: 'Calificación Global',
    subTitle: 'Global de los cuestionarios ejecutados'
  })
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{ title: string; subTitle: string; value: number }>({ title: '', subTitle: '', value: 0 })
  const getData = async () => {
    setLoading(true)
    const endpointsByRoles: Record<string, () => void> = {
      [Roles.PARTNER]: async () => {
        const response = await dashboardServiceFetch.indicatorsRatingAverage()
        setData(prev => ({
          ...prev,
          value: response.totalVisits ?? 0
        }))
      },
      [Roles.AUDIT]: async () => {
        setText({
          title: 'Calificación Promedio',
          subTitle: 'Global de los cuestionarios aplicados'
        })
        const response = await dashboardServiceFetch.ratingQuestionnaire()
        setData(prev => ({
          ...prev,
          value: parseFloat(percentage({ a: response.scheduledStores.assignedStores, b: response.scheduledStores.totalStores }))
        }))
      }
    }
    endpointsByRoles?.[getUserRole() ?? '']?.()
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])
  return { data, loading, text, getData }
}

export { useBody }
