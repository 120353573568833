import { useContextState } from "../../../../../../../../hooks/theme"

const useHeaderStyles = () => {
    const { colors } = useContextState()
    return {
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        headetText: {
            width: '100%',
            color: colors.color5,
            fontWeigt: '800',
            fontSize: '12px',
        },
    }
}

export { useHeaderStyles }
