import { Http } from '../../utils/types'
import { ObjectGeneric } from '../../utils/types/generals'
import { GetAllAreas, ResponseArea } from './response'

export const areaService = (client: Http) => ({
  getAllAreas: async (): Promise<GetAllAreas> => await client.get('area'),
  createArea: async (body: ObjectGeneric): Promise<ResponseArea> => await client.post('area', body),
  updateArea: async (body: ObjectGeneric, id: number): Promise<ResponseArea> => await client.put(`area/${id}`, body),
  deleteArea: async (id: number): Promise<ResponseArea> => await client.delete(`area/${id}`),
  getAreaById: async (id?: number) => await client.get(`area/${id}`),
  getAllDepartments: async (): Promise<any> => await client.get('department/without-area'),
})
