import { UserTypeFormProps, UserTypeFormRef } from "./types"
import { DinamicForm } from "../../../../../../components/dinamicForm"
import { Ref, forwardRef, useImperativeHandle, useState } from "react"
import { InputModel, onChange } from "../../../../../../utils/types"
import { ObjectGeneric } from "../../../../../../utils/types/generals"
import { useRefs } from "../../../../../../hooks/useReactHookModified"
import { RefDinamicForm } from "../../../../../../components/dinamicForm/filterSelect/props"

const UserTypeForm = forwardRef((props: UserTypeFormProps, ref: Ref<UserTypeFormRef>) => {
  const { refsByKey, setRef } = useRefs<RefDinamicForm>()
  const input = {
    name: {
      label: 'Tipo',
      name: 'name',
      value: null,
      customeErrors: {
        required: 'general.validate.required.type',
      },
      validate: {
        required: true,
      },
    },
    description: {
      label: 'Descripción',
      name: 'description',
      value: null,
      type: "textArea",
      validate: {
        required: true,
        minLenght: 3
      },
    },
    status: {
      label: 'Estado',
      name: 'status',
      value: null,
      type: 'select-search',
      items: props?.statusList ?? [],
      validate: {
        required: true,
      },
    },
  } as { [x: string]: InputModel }

  useImperativeHandle(ref, () => ({
    cleanData: () => { setData({}) },
    getData: () => data
  }))

  const [data, setData] = useState<ObjectGeneric>(props.defaultValues ?? {})
  const onChange: onChange = ({ target }) => {
    const { name, value } = target
    valdiate({ ...data, [name]: value })
    setData(prev => ({ ...prev, [name]: value }))
  }

  const valdiate = (data: ObjectGeneric) => {
    let existError = false
    const lengthDescription = (data.description as string)?.trim()?.length < 3
    if (!data.name || !data.description || !data.status || !!lengthDescription) { existError = true }

    props.isValidateForm(!existError)
  }

  return (
    <DinamicForm
      ref={(element) => {
        setRef(element, 'form')
        return refsByKey['form']
      }}
      input={Object.values(input)}
      onChange={onChange}
      values={data}
    />
  )
})
export { UserTypeForm }
