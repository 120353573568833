import { useCallback, useEffect, useState } from 'react'
import {
  getDivisions,
  getStore,
  getZones,
} from '../../../../utils/methods/geServiceWithItemsFormat'
import { Item } from '../../../../utils/types/generals'
import { useFilter } from '../../../../hooks/filter'
import { endOfWeek, startOfWeek, format } from 'date-fns'
import { Constants } from '../../../../utils/constants'
import { Roles } from '../../../../utils/enums'
import { getUserRole } from '../../../../service/auth'

const dataDefault: any = {
  from: startOfWeek(Date.now(), { weekStartsOn: 1 }),
  to: endOfWeek(Date.now(), { weekStartsOn: 1 }),
}

const useBody = () => {
  const currentDate = `date_init=${format(
    startOfWeek(Date.now(), { weekStartsOn: 1 }),
    Constants.dateFormat3
  )}&date_end=${format(
    endOfWeek(Date.now(), { weekStartsOn: 1 }),
    Constants.dateFormat3
  )}`
  const [hrefButton, setHrefButton] = useState(false)
  const [items, setItems] = useState<Record<string, Record<string, (string | Item)[] | boolean>>>({})
  const { errors, filterData, onChange, query } = useFilter({
    formatFilter: {},
    defaultValues: {
      from: new Date(startOfWeek(Date.now(), { weekStartsOn: 1 })),
      to: new Date(endOfWeek(Date.now(), { weekStartsOn: 1 })),
    },
  })

  const openHref = () => { setHrefButton(true) }
  const closeHref = () => { setHrefButton(false) }

  const getUnitsItems = useCallback(async () => {
    const { items } = await getStore()
    const idList = items.map((item) => item.value.toString())

    setItems(prev => ({
      ...prev,
      units: {
        all: idList,
        items,
        loading: true
      }
    }))
  }, [])

  const getDivisionsAll = useCallback(async () => {
    const { items } = await getDivisions()
    setItems(prev => ({
      ...prev,
      divisions: {
        items,
        loading: true
      }
    }))
  }, [])

  const getZonesAndClassAll = useCallback(async () => {
    const { fatherZones, childZones } = await getZones()
    setItems(prev => ({
      ...prev,
      zonesAndClass: {
        zones: fatherZones,
        class: childZones,
        loading: true
      }
    }))
  }, [])

  useEffect(() => {
    const methodsRoles: Record<Roles, () => void> = {
      [Roles.ADMIN]: () => {
        getUnitsItems()
        getDivisionsAll()
        getZonesAndClassAll()
      },
      [Roles.GERENT]: () => {
        getUnitsItems()
      },
      [Roles.SUPERVISOR]: () => {
        getUnitsItems()
      },
      [Roles.AGENT]: () => { },
      [Roles.SUPPORT]: () => { },
      [Roles.AUDIT]: () => { },
      [Roles.STORE]: () => { },
      [Roles.PARTNER]: () => { },
    }
    const role = getUserRole()
    if (!!role) {
      methodsRoles[role as Roles]()
    }
  }, [getUnitsItems, getDivisionsAll])

  return {
    unitIds: items.units,
    divisionsItems: items?.divisions,
    errors,
    filterData,
    zonesAndClass: items.zonesAndClass,
    query,
    currentDate,
    hrefButton,
    openHref,
    closeHref,
    onChange,
  }
}

export { useBody, dataDefault, }
