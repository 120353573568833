import { useEffect, useState, useCallback } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { SnackSimple } from '../../../../components'
import { useContextState } from '../../../../hooks/theme'
import { getTypeList } from './methods'
import { useTranslation } from 'react-i18next'
import { Table } from './types'
import { useStyles } from './style'
import { icons } from '../../../../utils/icons'
import { InformativeCard } from '../../../../utils/types/generals'
import { formData, formatFilter, initialTypeValues, input } from './initialState'
import { statusService } from '../../../../service'
import Loader from '../../../../components/loader'
import { Status, Types } from '../../../../utils/enums'
import { InputModel } from '../../../../utils/types'
import General from '../../../../components/modules/general'
import { DinamicForm } from '../../../../components/dinamicForm'
import { FiltersDrawer } from '../../../../components/filtersDrawer'
import { getPreviousYears, monthYear } from '../../../../utils/constants/date_month'
import SimpleModal from '../../../../components/modal/simpleModal'
import { useFilter } from '../../../../hooks/filter'
import { returnQuery, returnQueryWithPagination } from '../../../../utils/methods/returnQuery'
import { useDownload } from '../../../../hooks/download/useDownload'
import { userTypeServiceFetch } from '../../../../utils/constants/callService'
import { ModelDownloadButtons } from '../../../../components/donwloadFiles/model'
import { UserTypeForm } from './components/form'
import { useRefs } from '../../../../hooks/useReactHookModified'
import { UserTypeFormRef } from './components/form/types'

const UserType = () => {
  const { refsByKey, setRef } = useRefs<UserTypeFormRef>()
  const { t } = useTranslation()
  const { colors } = useContextState()
  const styles = useStyles(colors)
  const [table, setTable] = useState<Table>({ column: [], row: [] })
  const [information, setInformation] = useState<InformativeCard | null>(null)
  const [flag, setFlag] = useState(false)
  const [userTypeValues, setUserTypeValues] = useState<{ [x: string]: InputModel }>(initialTypeValues)
  const [btnLoading, setBtnLoading] = useState(false)
  const [disabledSubmit, setDisabledSubmit] = useState(false)
  const [statusList, setStatusList] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [totalPages, setTotalPages] = useState(0)
  const [inputs, setInputs] = useState<{ [x: string]: InputModel }>(input)
  const [open, setOpen] = useState(false)
  const [row, setRow] = useState<Table['row']>([])
  const [selected, setSelected] = useState<number>(-1)
  const [crud, setCrud] = useState(false)
  const {
    filterData,
    errors,
    query,
    counter,
    paginationModel,
    handlePagination,
    cleanFilter,
    onChange,
    sortTable,
    filterString,
  } = useFilter({
    formatFilter
  })

  const {
    download,
    openSnackbar,
    downloadCSV,
    downloadPDF,
    downloadWORD,
    closeOpenSnakbar,
  } = useDownload({
    docName: t('userType.filter.title'),
    query: returnQueryWithPagination({ filter: filterString, sort: query.sort }),
    downloadFuntion: userTypeServiceFetch.downloadDocument,
  })
  const [drawerDetail, setDrawerDetail] = useState({
    title: '',
    accept: '',
    cancel: '',
  })

  const [modalDataUserType, setModalDataUserType] = useState({
    open: false,
    status: 0,
  })

  const [openSnackbarSome, setOpenSnackbarSome] = useState({
    open: false,
    message: '',
    type: Types.SUCCESS as string,
  })

  const menuList = [
    {
      label: 'Editar tipo de usuario',
      value: 11,
    },
    {
      label: 'Eliminar tipo de usuario',
      value: 12,
    },
  ]

  const handleCloseSnackbar = (value: boolean) => {
    setOpenSnackbarSome({ ...openSnackbarSome, open: value })
  }

  const handleSubmit = async () => {
    setBtnLoading(true)
    let response: any = {}
    const data = refsByKey.userTypeForm?.getData()
    const body = {
      description: data?.description ?? '',
      name: data?.name ?? '',
      statusId: data?.status?.value?.value ?? data?.status?.value ?? 1,
    }
  
    if (information?.id) {
      response = await userTypeServiceFetch.updateUserType(body, information.id)
    } else {
      response = await userTypeServiceFetch.createUserType(body)
    }
    if (!response?.errors) {
      getAllUserType()
    } else {
      setBtnLoading(false)
    }
  }

  // Others
  const handleInformation = (e: any, index: number) => {
    setSelected(index)
    openDrawer(true)
    if (e) {
      setInformation({
        data: [
          {
            label: t('general.tags.type'),
            value: e.name,
          },
          {
            label: t('general.tags.startDate'),
            value: e.createTime,
          },
          {
            label: t('general.tags.description'),
            value: e.description,
          },
        ],
        status: {
          color: e.status.color,
          text: e.status.text,
          value: e.status?.value,
        },
        id: e?.id,
      })
    } else {
      setInformation(null)
    }
  }
  const onClickMenuItem = (item: any) => {
    setBtnLoading(true)
    switch (item.value) {
      case 11:
        setCrud(true)
        setUserTypeValues({
          name: {
            ...userTypeValues.name,
            value: information?.data?.[0]?.value,
          },
          description: {
            ...userTypeValues.description,
            value: information?.data?.[2]?.value,
          },
          status: {
            ...userTypeValues.status,
            value: information?.status?.value,
          },
        })
        break
      case 12:
        setModalDataUserType({
          status: selected,
          open: true,
        })
        break
      default:
        break
    }
    setBtnLoading(false)
  }

  const getAllUserType = useCallback(async () => {
    setIsLoading(true)
    const { column, row, totalPages } = await getTypeList({ colors, t, queary: returnQuery({ ...query, filter: query.filter?.replaceAll('+', '%2B') }, formData) })
    setTotalPages(totalPages)
    setRow(row)
    setTable({ column, row })
    setFlag(true)
    setIsLoading(false)
    setBtnLoading(false)
    resetValues()
  }, [colors, t, query.all])

  const getDates = () => {
    setInputs((prev) => ({
      ...prev,
      createYear: {
        ...prev.createYear,
        items: getPreviousYears(),
      },
      month: {
        ...prev.month,
        items: monthYear,
      },
    }))
  }
  const getData = useCallback(async () => {
    getDates()
    const statusResponse = await statusService().statusList()

    let valideStatus: any[] = []

    statusResponse?.forEach((item: any) => {
      if ([Status.ACTIVE, Status.INACTIVE].includes(item.id)) {
        valideStatus.push({
          value: item.id,
          label: item.description,
        })
      }
    })
    setStatusList(valideStatus)

    setInputs((prev) => ({
      ...prev,
      status: {
        ...prev.status,
        items: valideStatus,
      },
    }))
  }, [getAllUserType])

  const resetValues = () => {
    if (refsByKey.userTypeForm?.cleanData) {
      refsByKey.userTypeForm?.cleanData()
    }
    setCrud(false)
    setOpen(false)
    setUserTypeValues({})
    setBtnLoading(false)
    setSelected(-1)
  }

  const openDrawer = (edit?: boolean) => {
    setDrawerDetail({
      title: t(`userType.informationCard.${edit ? 'edit' : 'create'}.title`),
      accept: t(`userType.informationCard.buttons.${edit ? 'update' : 'add'}`),
      cancel: t('userType.informationCard.buttons.cancel'),
    })
    if (!edit) {
      setUserTypeValues({})
      if (refsByKey.userTypeForm?.cleanData) {
        refsByKey.userTypeForm?.cleanData()
      }
    }
    setDisabledSubmit(!edit)
    setInformation(null)
    setCrud(!edit)
    setOpen(true)
  }

  const closeDrawer = () => {
    setOpen(false)
    setCrud(false)
    setSelected(-1)
  }

  useEffect(() => {
    if (!flag) {
      getData()
    }
  }, [flag, getData])

  const deleteUserType = async (id?: number) => {
    if (id) {
      const response = await userTypeServiceFetch.deleteUserType(id)

      if (!response.errors) {
        getAllUserType()
        setOpenSnackbarSome({
          open: true,
          message: t(`userType.modal.delete.success.title`),
          type: Types.SUCCESS as string,
        })
      } else {
        setOpenSnackbarSome({
          open: true,
          message: t(`userType.modal.delete.error.title`),
          type: Types.ERROR as string,
        })
      }
    }
  }

  const isValidateForm = (value: boolean) => {
    setDisabledSubmit(!value)
  }
  useEffect(() => {
    getAllUserType()
    //eslint-disable-next-line
  }, [query.all])

  return (
    <>
      <SnackSimple close={closeOpenSnakbar} open={openSnackbar.open} type={openSnackbar.type} message={openSnackbar.message} />
      <General
        newHeader={
          <Box sx={styles.header}>
            <Typography sx={styles.title}>{t('userType.filter.title')}</Typography>

            <Box sx={styles.flex}>
              <ModelDownloadButtons csv={downloadCSV} pdf={downloadPDF} word={downloadWORD} disableCSV={download?.excel} disablePDF={download?.pdf} disableWORD={download?.word} />

              <IconButton
                sx={(styles.clean, (counter || 0) > 0 ? null : styles.hide)}
                onClick={() => {
                  cleanFilter()
                }}
              >
                {icons('clean')}
              </IconButton>
              <IconButton
                sx={styles.clean}
                onClick={() => {
                  openDrawer()
                }}
              >
                {icons('add')}
              </IconButton>
            </Box>
          </Box>
        }
        open={open}
        rightDrawer={{
          ...information,
          data: information?.data,
          form: btnLoading ? (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Loader />
            </Box>
          ) : (
            <UserTypeForm
              ref={(element) => {
                setRef(element, 'userTypeForm')

                return refsByKey.userTypeForm
              }}
              defaultValues={{
                name: userTypeValues?.name?.value ?? null,
                description: userTypeValues?.description?.value ?? null,
                status: userTypeValues?.status?.value ?? null,
              }}
              isValidateForm={isValidateForm}
              statusList={statusList}
            />
            // <Grid container rowSpacing={2.5}>
            //   <Grid item lg={12}>
            //     <BaseInput
            //       name={userTypeValues.name.name}
            //       value={{
            //         name: {
            //           label: `${userTypeValues.name.label}*`,
            //           value: userTypeValues.name.value,
            //         },
            //       }}
            //       onChange={crudChange}
            //       errors={errors}
            //       inputProps={{
            //         maxLength: 250,
            //       }}
            //     />
            //   </Grid>
            //   <Grid item lg={12}>
            //     <BaseInput
            //       name={userTypeValues.description.name}
            //       value={{
            //         description: {
            //           label: userTypeValues.description.label,
            //           value: userTypeValues.description.value,
            //         },
            //       }}
            //       onChange={crudChange}
            //       multiline
            //       rows={3}
            //       errors={errors}
            //       inputProps={{
            //         maxLength: 65535,
            //       }}
            //     />
            //   </Grid>
            //   <Grid item lg={12}>
            //     <MultiSelectSimple isRequired errors={errors} name={userTypeValues?.status?.name} label={`${userTypeValues.status.label}*`} onChange={crudChange} items={statusList} selected={userTypeValues.status.value} />
            //   </Grid>
            // </Grid>
          ),
          menuItems: menuList,
          crud,
          title: drawerDetail.title,
          accept: {
            title: drawerDetail.accept,
            disabled: disabledSubmit,
            onClick: handleSubmit,
          },
          cancel: {
            title: drawerDetail.cancel,
            onClick: closeDrawer,
          },
          clickMenu: onClickMenuItem,
        }}
        table={{
          column: table.column,
          row: row,
          title: t('userType.filter.title'),
          onClick: handleInformation,
          loadingTable: isLoading,
          selected,
          serverSidePagination: {
            page: (paginationModel.page ?? 0) + 1,
            size: paginationModel?.pageSize ?? 20,
            totalPages: totalPages,
          },
          handleChange: (page: number, pageSize: number) => {
            handlePagination({ page, pageSize })
          },
          sortable: (field, sort, count) => {
            sortTable(field && sort ? { field: field, sort: sort, count } : undefined)
          },
          order: query?.sort && {
            count: query?.sort?.count,
            key: query?.sort?.field,
            order: query?.sort?.sort,
          }
        }}
        filtersDrawer={
          <FiltersDrawer cleanFilter={cleanFilter} count={counter} right={-50} top={0}>
            <Box sx={styles.section}>
              <DinamicForm errors={errors} input={Object.values(inputs)} values={filterData} onChange={onChange} filter={true} />
            </Box>
          </FiltersDrawer>
        }
      />
      <SimpleModal
        open={modalDataUserType.open}
        close={() => {
          setModalDataUserType({ open: false, status: 0 });
        }}
        title={t('userType.modal.delete.title')}
        description={t('userType.modal.delete.description')}
        buttonText={t('general.confirm')}
        cancelText={t('general.cancel')}
        color={colors}
        back={() => {
          setModalDataUserType({ open: false, status: 0 })
        }}
        next={() => {
          deleteUserType(modalDataUserType.status)
          setModalDataUserType({ open: false, status: 0 })
        }}
      />

      <SnackSimple close={handleCloseSnackbar} open={openSnackbarSome.open} type={openSnackbarSome.type} message={openSnackbarSome.message} />

    </>
  )
}

export default UserType
