import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { RoutesPrivate } from './private'
import { RoutesPublic } from './public'

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<RoutesPrivate />} />

        <Route path="/auth/*" element={<RoutesPublic />} />
      </Routes>
    </BrowserRouter>
  )
}
