import { useEffect, useRef, useState } from 'react'
import { divisions } from './methods/callEndpoints'
import { Bar } from 'recharts'
import { Item } from '../../../../../../utils/types/generals'
import { useContextState } from '../../../../../../hooks/theme'
import { useFilter } from '../../../../../../hooks/filter'
import { FilterProps } from '../../../../../../hooks/filter/typing'
import { useInputs } from './hooks/inputs'

type General = Record<string, (string | Item)[] | boolean> 
const useBody = (payload: { units: Item[]; divisions: Item[]; zoneAndClass: General; loadings: {divisions:boolean; units:boolean} }) => {
  const [items, setItems] = useState<Record<string, Item[]>>({})
  const externalValidation: FilterProps['externalValidation'] = ({ name, value, body, setFilterData }) => {
    if (name === 'zones') {
      const { class: classes, } = body
      const newZonezClass = classes?.filter((itemClass: Item) => value?.some((itemZone: any) => itemZone?.value == itemClass?.father))

      setItems((prev) => ({
        ...prev,
        class: value?.length <= 0 ? [] : (payload.zoneAndClass.class as Item[])?.filter((itemClass) => value?.some((itemZone: any) => itemZone?.value == itemClass?.father)),
      }))

      setFilterData((prev) => ({
        ...prev,
        class: newZonezClass,
      }))

      body = {
        ...body,
        class: newZonezClass,
      }
    }

    return body
  }
  const { inputs } = useInputs({ items })
  const filter = useFilter({ formatFilter: {}, externalValidation })
  const refTime = useRef<NodeJS.Timeout | null>(null)
  const [data, setData] = useState<Record<string, string | number | Record<string, string>>[]>([])
  const [lastUpdate, setLastUpdate] = useState('')
  const { colors } = useContextState()
  const [loadings, setLoadings] = useState({
    divisions: false,
  })
  const indicatorsBars = [
    <Bar
      maxBarSize={50}
      key={`percentageProgrammed`}
      stackId={`a`}
      dataKey={`percentageProgrammed`}
      name={`Unidades programadas`}
      fill={colors.color16}
    />,
    <Bar
      maxBarSize={50}
      key={`percentageNoProgrammed`}
      stackId={`a`}
      dataKey={`percentageNoProgrammed`}
      name={`Unidades no programadas`}
      fill={colors.color19}
    />,
  ]
  
  const getData = async (actions?:{ clean?: boolean}) => {
    setLoadings((prev) => ({ ...prev, divisions: true }))
    if (refTime.current) {
      clearTimeout(refTime.current)
    }

    refTime.current = setTimeout(async () => {
      const filterUnits = filter.filterData.store?.map((item: Item) => item.value)
      const filterDivisions = filter.filterData.divisions?.map((item: Item) => item.value)
      const filterZones = filter.filterData.zones?.map((item: Item) => item.value)
      const filterClass = filter.filterData.class?.map((item: Item) => item.value)
      const { data, updateDate } = await divisions({
        units: !!filter.filterData.store?.length && !actions?.clean
          ? filterUnits
          : payload.units.map((item) => item.value),

        divisions_id: !!filterDivisions?.length && !actions?.clean ? filterDivisions : payload.divisions?.map((item) => item.value),
        zones_id: !!filterZones?.length && !actions?.clean ? filterZones : [],
        class_id: !!filterClass?.length && !actions?.clean ? filterClass : [],
        divisions: payload.divisions
      }).divisonsByUnits()
      setLastUpdate(updateDate)
      setData(data)
      setLoadings((prev) => ({ ...prev, divisions: false }))
    }, 300)
  }

  const [modal, setModal] = useState({
    open: false
  })

  const openFiltersModal = () => { setModal(prev => ({ ...prev, open: true })) }
  const closeFiltersModal = () => { setModal(prev => ({ ...prev, open: false })) }
  const cleanFilter = () => {
    filter.cleanFilter()
    getData({clean: true})
  }
  const filterButton = () => {
    getData()
    closeFiltersModal()
  }

  useEffect(() => {
    if (!!payload.loadings?.units && !!payload.loadings?.divisions) {
      setItems((prev) => ({
        ...prev,
        store: payload.units,
        zones: payload?.zoneAndClass?.zones as Item[],
        divisions: payload?.divisions as Item[],
      }))
      getData()
    }
  }, [payload.units, payload.divisions])
  return { indicatorsBars, data, loadings, filter, input: inputs, lastUpdate, items, modal, cleanFilter, openFiltersModal, closeFiltersModal, filterButton, getData }
}

export { useBody }
