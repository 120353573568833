import { Roles } from '../../utils/enums'
import { Http } from '../../utils/types'
import { ObjectGeneric } from '../../utils/types/generals'
import { getUserRole } from '../auth'
import {
  AssociateDivisionsToCompanies,
  GetAllDivision,
  ResponseDivision,
} from './response'

export const divisionService = (client: Http) => {
  return {
    getAllDivision: async (): Promise<GetAllDivision> => {
      switch (getUserRole()) {
        case Roles.AGENT:
        case Roles.SUPPORT:
        case Roles.GERENT:
        case Roles.AUDIT:
        case Roles.SUPERVISOR:
          return client.get('hierarchy/filters/division')
        case Roles.STORE:
        case Roles.PARTNER:
          return []
        default:
          return await client.get('division')
      }
    },
    getDivisionsFilter: async (): Promise<GetAllDivision> => {
      return await client.get('report-folio/filters/divisions')
    },
    getAllSupervisorDivision: async (): Promise<GetAllDivision> =>
      await client.get('hierarchy/filters/division'),
    createDivision: async (body: ObjectGeneric): Promise<ResponseDivision> =>
      await client.post('division', body),
    updateDivision: async (
      body: ObjectGeneric,
      id: number
    ): Promise<ResponseDivision> => await client.put(`division/${id}`, body),
    deleteDivision: async (id: number): Promise<ResponseDivision> =>
      await client.delete(`division/${id}`),
    associateDivisionToCompanie: async (
      body: ObjectGeneric
    ): Promise<AssociateDivisionsToCompanies> =>
      await client.post(`division/companies`, body),
    associateDivisionToStore: async (
      body: ObjectGeneric
    ): Promise<ResponseDivision> => await client.post(`division/stores`, body),
  }
}
