import { useContextState } from "../../../../hooks/theme"

const useStyles = () => {
  const { colors } = useContextState()
  return {
    container: {
      paddingTop: '30px',
      width: '100%',
      height: '100%'
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '20px',
    },
    containerArea: {
      display: 'flex',
    },
    headetText: {
      color: colors.color5,
      fontWeigt: '800',
      fontSize: '13px',
    },
    tableText: {
      fontSize: "12px",
      color: colors.color13
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
      '&.flex-j-e': {
        justifyContent: 'end',
      },
    },
    actionIcon: {
      fontSize: '1.3rem',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    delete: {
      color: colors.color19,
      padding: '5px',
      display: 'block',
      margin: 'auto',
    },
    info: {
      padding: '4px 50px 4px 4px',
      display: 'flex',
      gap: '20px',
      marginBottom: '-20px',
      minHeight: '175px',
    },
    title: {
      flexGrow: 1,
      fontSize: '20px',
      fontWeight: '500',
    },
    section: {
      marginBottom: '32px',
    },
    clean: {
      fontSize: '2.4rem',
      color: colors.color4,
      padding: '6px',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    hide: {
      visibility: 'hidden',
    },
  }
}

export { useStyles }
