import { useContextState } from "../../../hooks/theme"

const useLinearProgressWithLabelStyle = () => {
    const { colors } = useContextState()
    return {
        container: { display: 'flex', alignItems: 'center', width: '100%' },
        progressBarContainer: {
            width: '100%',
            mr: 1,
            '& .MuiLinearProgress-barColorPrimary': {
                backgroundColor: colors.primary
            }
        },
        progressBar: {
            width: 'calc(100%)',
            height: '8px',
            borderRadius: '40px',
            backgroundColor: `${colors.primary}66`,
        },
        value: { minWidth: 35 }
    }
}

export { useLinearProgressWithLabelStyle }