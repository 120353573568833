import { intervaleList } from "../../../../../../../utils/constants/intervaleList"
import { InputModel } from "../../../../../../../utils/types"
import { currentWeek } from "../../methods/calcDates"
const size4 = {
  xl: 4,
  lg: 4,
  md: 4,
  sm: 4,
}
const inputs: Record<string, InputModel> = {
  from: {
    label: 'general.tags.from',
    translate: true,
    name: 'from',
    type: 'date',
    rest: {
      max: 'to',
    },
    value: currentWeek().start.getTime(),
    size: size4,
  },
  to: {
    label: 'general.tags.to',
    translate: true,
    name: 'to',
    type: 'date',
    rest: {
      min: 'from',
    },
    value: currentWeek().end.getTime(),
    size: size4,
  },
  intervale: {
    label: 'general.tags.intervale',
    translate: true,
    name: 'intervale',
    type: 'select-search',
    items: intervaleList,
    value: { label: 'Diario', value: '1d' },
    size: size4,
  },
}

const defaultDates = {
  from: currentWeek().start.getTime(),
  to: currentWeek().end.getTime()
}
const inputsUnits: Record<string, InputModel> = {
  store: {
    label: 'general.tags.stores',
    translate: true,
    name: 'store',
    type: 'filter-select',
    items: [],
    value: null,
  },
  divisions: {
    label: 'general.tags.divisions',
    translate: true,
    name: 'divisions',
    type: 'filter-select',
    items: [],
    value: null,
  },
  zones: {
    label: 'general.tags.zones',
    translate: true,
    name: 'zones',
    type: 'filter-select',
    items: [],
    value: null,
  },
  class: {
    label: 'general.tags.childZone',
    translate: true,
    name: 'class',
    type: 'filter-select',
    items: [],
    value: null
  },
}

export { inputs, inputsUnits, defaultDates }
