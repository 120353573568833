import { TFunction } from 'react-i18next'
import { Constants } from '../../../../utils/constants'
import { InputModel } from '../../../../utils/types'
import { ItemMenu } from '../../../../components/baseMenu'

export const initialFilterValues = {
  role: '',
  status: '',
  search: '',
}

export const initialValues = {
  store_name: {
    label: 'general.tags.store_name',
    translate: true,
    name: 'store_name',
    type: 'picture',
    value: null,
    size: {
      lg: 4,
    },
  },
  quest_name: {
    label: 'general.tags.quest_name',
    translate: true,
    name: 'quest_name',
    validate: {
      required: true,
    },
    value: null,
  },
  agent: {
    label: 'general.tags.agent',
    translate: true,
    validate: {
      required: true,
    },
    name: 'agent',
    value: null,
  },
  available_from: {
    label: 'general.tags.available_from',
    translate: true,
    name: 'available_from',
    value: '+52',
  },
  available_to: {
    label: 'general.tags.available_to',
    translate: true,
    name: 'available_to',
    type: 'select',
    items: [
      {
        label: '1',
        value: 1,
      },
    ],
    validate: {
      required: true,
    },
    value: null,
    size: {
      lg: 6,
    },
  },
  status: {
    label: 'general.tags.status',
    translate: true,
    name: 'status',
    type: 'select',
    validate: {
      required: true,
    },
    value: null,
    size: {
      lg: 6,
    },
  },
  application_date: {
    label: 'general.tags.application_date',
    translate: true,
    name: 'application_date',
    value: null,
    validate: {
      required: true,
    },
  },
} as { [x: string]: InputModel }

export const initialData = {
  from: Date.now() + 60000,
  to: Date.now() + 1860000,
  frequency: null,
  title: null,
  notified: false,
  send: false,
}

export const useMenu = ({ t }: { t: TFunction<'translation', undefined> }) => {
  return [
    // {
    //   label: t('schedule.menu.byFile'),
    //   value: 1,
    // },
    {
      label: t('schedule.menu.byForm'),
      value: 2,
    },
  ]
}
export const initialTable = {
  column: [],
  row: [],
} as any

export const stepsArray = [
  {
    step: 'Paso 1',
    label: 'Unidades',
  },
  {
    step: 'Paso 2',
    label: 'Cuestionarios',
  },
  {
    step: 'Paso 3',
    label: 'Disponibilidad',
  },
]
export const stepsArrayEdit = [
  {
    step: 'Paso 1',
    label: 'Cuestionarios',
  },
  {
    step: 'Paso 2',
    label: 'Disponibilidad',
  },
]

export const stores = {
  list: [
    {
      title: 'Ejemplo de Nombre de Unidad',
      address: 'Avenida Landana #345 Lote 12, México D.F. CP 01234',
      phone: '+52 55 12345678',
    },
    {
      title: 'Ejemplo de Nombre de Unidad',
      address: 'Avenida Landana #345 Lote 12, México D.F. CP 01234',
      phone: '+52 55 12345678',
    },
    {
      title: 'Ejemplo de Nombre de Unidad',
      address: 'Avenida Landana #345 Lote 12, México D.F. CP 01234',
      phone: '+52 55 12345678',
    },
    {
      title: 'Ejemplo de Nombre de Unidad',
      address: 'Avenida Landana #345 Lote 12, México D.F. CP 01234',
      phone: '+52 55 12345678',
    },
    {
      title: 'Ejemplo de Nombre de Unidad',
      address: 'Avenida Landana #345 Lote 12, México D.F. CP 01234',
      phone: '+52 55 12345678',
    },
    {
      title: 'Ejemplo de Nombre de Unidad',
      address: 'Avenida Landana #345 Lote 12, México D.F. CP 01234',
      phone: '+52 55 12345678',
    },
    {
      title: 'Ejemplo de Nombre de Unidad',
      address: 'Avenida Landana #345 Lote 12, México D.F. CP 01234',
      phone: '+52 55 12345678',
    },
    {
      title: 'Ejemplo de Nombre de Unidad',
      address: 'Avenida Landana #345 Lote 12, México D.F. CP 01234',
      phone: '+52 55 12345678',
    },
    {
      title: 'Ejemplo de Nombre de Unidad',
      address: 'Avenida Landana #345 Lote 12, México D.F. CP 01234',
      phone: '+52 55 12345678',
    },
    {
      title: 'Ejemplo de Nombre de Unidad',
      address: 'Avenida Landana #345 Lote 12, México D.F. CP 01234',
      phone: '+52 55 12345678',
    },
  ],
  title: 'Unidades seleccionadas',
}

export const cuestionarie = {
  title: 'Cuestionarios a aplicar',
  list: [
    {
      title: 'Ejemplo de Nombre de Cuestionario Largo',
      questions: '20 Preguntas',
    },
  ],
  size: {
    lg: 6,
  },
}

export const inputModel = {
  title: {
    label: 'general.tags.title',
    translate: true,
    name: 'title',
    value: null,
    rest: {
      type: 'alphaNumeric',
    },
  },
  description: {
    label: 'general.tags.description',
    translate: true,
    name: 'description',
    value: null,
    rest: {
      multiline: true,
      rows: 3,
    },
  },
}
export const menuItems: ItemMenu[] = [
  {
    label: 'Editar',
    value: 1,
  },
  {
    label: 'Cerrar',
    value: 2,
  },
  {
    label: 'Ver reporte',
    value: 3,
  },
  {
    label: 'Reenviar',
    value: 4,
  },
  {
    label: 'Ver detalle',
    value: 5,
  },
]
// {
//   keyCode: keyCodeIds,
//   questionnaire: questionnaireIds,
//   userAssigned,
//   zone: zoneIds,
// }


export const resendReportForm = {
  user_assign: {
    label: `reportFolio.modal.sendReport.userWhoResponded`,
    name: 'user_assign',
    value: false,
    type: 'check',
    translate: true,
  },
  users_supervisors: {
    label: `reportFolio.modal.sendReport.userSupervisor`,
    name: 'users_supervisors',
    value: false,
    type: 'check',
    translate: true,
  },
  users_store: {
    label: `reportFolio.modal.sendReport.userStore`,
    name: 'users_store',
    value: false,
    type: 'check',
    translate: true,
  },
  user_manage_store: {
    label: `reportFolio.modal.sendReport.agentStore`,
    name: 'user_manage_store',
    value: false,
    type: 'check',
    translate: true,
  },
  email_store: {
    label: `reportFolio.modal.sendReport.emailStore`,
    name: 'email_store',
    value: false,
    type: 'check',
    translate: true,
  },
} as { [x: string]: InputModel }

export const resendReportFormEmails = {
  externalEmails: {
    label: ``,
    name: 'externalEmails',
    value: '',
    rest: {
      multiline: true,
      rows: 5,
    },
    translate: true,
  },
}

export const filesValidate = {
  accept: `${Constants.acceptFile.xlsx}`,
  correctTypeFile: [Constants.fileType.xlsx],
}
