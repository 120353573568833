import { Badge, Box, Button, Grid, IconButton, Typography } from '@mui/material'
import { useBody } from './body'
import { useStyles } from './styles'
import { BarGraphic } from '../../components/barGraphic'
import { Item } from '../../../../../../utils/types/generals'
import Loader from '../../../../../../components/loader'
import { DinamicForm } from '../../../../../../components/dinamicForm'
import { useTranslation } from 'react-i18next'
import { icons } from '../../../../../../utils/icons'
import { RedirectedIds } from '../../../../typing'
import BaseModal from '../../../../../../components/modal/baseModal'

const IndicatorDivisions = (props: {
  units: Item[]
  divisionsItems: Item[]
  zoneAndClass: Record<string, boolean | (string | Item)[]>
  loadings: {
    divisions: boolean;
    units: boolean;
  }

}) => {
  const { t } = useTranslation()
  const {
    data,
    indicatorsBars,
    loadings,
    input,
    lastUpdate,
    filter,
    getData,
    ...body
  } = useBody({
    units: props.units,
    divisions: props.divisionsItems,
    loadings: props.loadings,
    zoneAndClass: props.zoneAndClass
  })
  const styles = useStyles()
  return (
    <Grid item xs={12}>
      <div id={RedirectedIds.DIVISIONS} />
      <Box sx={styles.containerGraph}>
        <Box sx={styles.headerProgress}>
          <Box sx={styles.header}>
            <Box>
              <Typography sx={styles.title}>
                {t('dashboard.href.divisions')}
              </Typography>

              <Box sx={styles.containerIcons}>
                <Typography sx={styles.lastUpdate}>
                  Última actualización: <span>{lastUpdate}</span>
                </Typography>

              </Box>
            </Box>

            <Box sx={styles.containerIcons}>
                {!!filter.counter &&
                  <Button
                    onClick={() => {
                      body.cleanFilter()
                    }}
                    variant="text"
                    sx={[styles.footerText, styles.cleanText]}
                  >
                    {t('general.cleanFilters')}
                  </Button>
                }
              <Badge sx={styles.badge({})} badgeContent={filter.counter}>
                <IconButton
                  sx={styles.filter}
                  onClick={() => {
                    body.openFiltersModal()
                  }}
                >
                  {icons('filter')}
                </IconButton>
              </Badge>

              <IconButton
                sx={styles.refresh}
                onClick={() => {
                  getData()
                }}
              >
                {icons("refresh")}
              </IconButton>
            </Box>
          </Box>
        </Box>

        <BaseModal sx={{ borderRadius: '0px' }} open={body.modal.open}>
          <Box sx={styles.containerModal}>
            <Box sx={styles.headerModal}>
              <Typography sx={styles.headerModalTitle}>{t('dashboard.href.filterDivision')}</Typography>
            </Box>

            <IconButton onClick={() => { body.closeFiltersModal() }} sx={styles.close}>{icons('simple-close')}</IconButton>

            <DinamicForm
              legacyErrors
              input={Object.values({
                ...input ?? {},
                class: {
                  ...input?.class ?? {},
                  items: body.items?.class || []
                }
              })}
              values={filter.filterData}
              onChange={filter.onChange}
            />

            <Button
              variant="contained"
              onClick={() => {
                body.filterButton()
              }}
              sx={styles.btnFilter}>
              {t('general.filter')}
            </Button>
          </Box>
        </BaseModal>
        {!!loadings.divisions ? (
          <Loader />
        ) : (
          <Box sx={styles.graph({ existData: !!data?.length })}>
            {!!data?.length ? (
              <BarGraphic
                int
                percentage
                removeY
                datakey="name"
                data={data}
                indicators={indicatorsBars}
                simpleTooltip
              />
            ) : (
              <Typography sx={styles.noData}>
                {t('reportFolio.detail.general.noInformation')}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Grid>
  )
}

export { IndicatorDivisions }
