import { Box, IconButton, Typography } from '@mui/material'
import FooterButtons from '../../../../../components/buttons/footer'
import { DinamicForm } from '../../../../../components/dinamicForm'
import Loader from '../../../../../components/loader'
import { icons } from '../../../../../utils/icons'
import { ObjectGeneric } from '../../../../../utils/types/generals'
import { Props } from './props'
import { useStyles } from './styles'

const Form = (props: Props) => {
  const styles = useStyles(props.color)
  const optionsLength = props.data?.options?.length ?? 0

  return props.loading ? (
    <Box sx={styles.loader}>
      <Loader />
    </Box>
  ) : (
    <Box sx={styles.container}>
      <Box sx={styles.headerLeft}>
        <IconButton
          onClick={() => {
            props.goBack()
          }}
        >
          {icons('arrow-left-fill')}
        </IconButton>
        <Typography>{props.t(`optionGroup.informationCard.${!!props.id ? 'edit' : 'create'}.title`)}</Typography>
      </Box>
      <DinamicForm input={props.input} values={props.data} onChange={props.crudChange} errors={props.allErrors} />

      <Box sx={styles.optionsContainer}>
        <Box sx={styles.options}>
          {props.data?.options.map((item: ObjectGeneric, index: number) => (
            <Box sx={styles.containerItems}>
              <DinamicForm
                input={Object.values(props.options)}
                values={item}
                onChange={(e) => {
                  props.crudChange(e, item.id)
                }}
                errors={props?.allErrors?.options?.[index]}
              />
              <Box sx={styles.optionsAdd}>
                {!!(optionsLength > 2) && (
                  <IconButton
                    className="delete"
                    onClick={() => {
                      props.addOption(item.id)
                    }}
                  >
                    {icons('delete')}
                  </IconButton>
                )}
                {!!(index === optionsLength - 1) && (
                  <IconButton
                    className="add"
                    onClick={() => {
                      props.addOption()
                    }}
                  >
                    {icons('add')}
                  </IconButton>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={styles.footer}>
        <FooterButtons
          onClick={(send) => {
            props.submit(send)
          }}
          loading={props.btnLoading}
          disabled={props.disabled}
          rightButton={props.t(`optionGroup.informationCard.submit.${!!props.id ? 'edit' : 'create'}`)}
        />
      </Box>
    </Box>
  )
}

export default Form
