import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Box,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { images } from '../../utils/images'
import { DinamicFormProps, ObjectGeneric } from '../../utils/types/generals'
import AddImage from '../addImage'
import { Images } from '../image'
import BaseInput from './baseInput'
import PercentageRange from './percentage'
import InputFile from './files'
import CustomSelect from './select'
import { IOSSwitch } from './switch'
import MultiSelect from './multiSelect'
import FilterSelect from './filterSelect'
import { isNumber } from '../../utils/validate/validate'
import MultiSelectSimple from './multiSelectSimple'
import SimpleFile from './simpleFile'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { WrapperColors } from './colorSelector/wrapperColors'
import { InputModel } from '../../utils/types'
import { isBefore, isValid, parse } from 'date-fns'
import { enGB } from 'date-fns/locale'
import {
  Fragment,
  Ref,
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
} from 'react'
import useGlobalErrors from '../../hooks/erors'
import { useEffect, useState } from 'react'
import { GlobalContext } from '../../contexts/Global'
import { RefDinamicForm, RefFilterSelected } from './filterSelect/props'

const useStyles = () => ({
  img: {
    '& img': {
      objectFit: 'cover',
    },
    borderRadius: '50%',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
  description: {
    fontSize: '12px',
    color: '#707070',
  },
  label: {
    '& .MuiFormControlLabel-label': {
      wordBreak: 'break-word',
    },
  },
})
/**
 * @deprecated use the new component DinamicFormRefModel
 * @component DinamicFormRefModel
 */
export const DinamicForm = forwardRef(
  (
    {
      input,
      onChange,
      errors,
      values,
      variant,
      filter = false,
      spacing,
      legacyErrors,
      disabled,
      justifyContentLeft,
    }: DinamicFormProps,
    ref: Ref<RefDinamicForm>
  ) => {
    const refF = useRef<RefFilterSelected>(null)
    const { t } = useTranslation()
    const [data, setData] = useState<any>()
    const [localValue, setLocalValue] = useState<ObjectGeneric | undefined>(values)
    const { errors: error } = useGlobalErrors({ data, t, values: localValue })
    const styles = useStyles()
    const { state } = useContext(GlobalContext)
    const { role } = state
    
    useImperativeHandle(ref, () => ({
      filterSelected: refF,
      getError: () => error
    }))

    useEffect(() => {
      let copyValue = values
      if (!!copyValue?.phone) {
        let phoneValue = copyValue.phone
        let newValue = ''

        if (!!phoneValue?.includes('+52')) {
          newValue = phoneValue
        } else if (phoneValue[0] == '+') {
          const lengthPhone = phoneValue.length
          newValue = `+52${phoneValue.slice(3, lengthPhone)}`
        } else if (phoneValue?.[0] !== '+') {
          newValue = `+52${phoneValue}`
        }

        copyValue = {
          ...copyValue,
          phone: newValue
        }
      }
      setLocalValue(copyValue)
    }, [values])
    useEffect(() => {
      let data: { [x: string]: InputModel } = {}
      input?.forEach((item) => {
        if (item.type === 'phone') {
          let newValue = item.value
          const phoneValue = item.value
          if (!!phoneValue) {
            if (!!phoneValue?.includes('+52')) {
              newValue = phoneValue
            } else if (phoneValue[0] == '+') {
              const lengthPhone = phoneValue.length
              newValue = `+52${phoneValue.slice(3, lengthPhone)}`
            } else if (phoneValue?.[0] !== '+') {
              newValue = `+52${phoneValue}`
            }
          }
          data = {
            ...data,
            [item.name]: {
              ...item,
              value: newValue,
            },
          }
        } else {
          data = { ...data, [item.name]: item }
        }
      })

      setData(data)
    }, [input])

    const handleDescription = (item: InputModel): string => {
      if (item.rest?.description) return t(item.rest?.description)
      return ''
    }

    const filterByRole = () => {
      let array: InputModel[] = []
      input?.forEach((item) => {
        if (item.notAllowedRoles?.some((e) => e == role)) return

        if (item.type === 'phone') {
          let newValue = item.value
          const phoneValue = item.value
          if (!!phoneValue) {
            if (!!phoneValue?.includes('+52')) {
              newValue = phoneValue
            } else if (phoneValue[0] == '+') {
              const lengthPhone = phoneValue.length
              newValue = `+52${phoneValue.slice(3, lengthPhone)}`
            } else if (phoneValue?.[0] !== '+') {
              newValue = `+52${phoneValue}`
            }
          }
          array.push({
            ...item,
            value: newValue,
          })
        } else {
          array.push(item)
        }
      })
      return array
    }

    return (
      <Grid
        container
        rowSpacing={spacing?.row ?? 2}
        columnSpacing={spacing?.column ?? 1}
        sx={{
          justifyContent: justifyContentLeft ? 'left !important' : 'center',
        }}
      >
        {filterByRole()?.map((item, index) => {
          let children: JSX.Element

          switch (item.type) {
            case 'phone':
              children = (
                <BaseInput
                  disabled={disabled}
                  name={item.name}
                  value={{
                    phone: {
                      value: (localValue?.[item.name] ?? item.value) || '+52',
                      label: `${item?.translate ? t(item.label) : item.label} ${!!item.validate?.required ? '*' : ''
                        }`,
                    },
                  }}
                  onChange={({ target }) => {
                    const { name, value } = target
                    if (
                      isNumber(value.replace('+', '')) &&
                      value !== '-' &&
                      value > 0
                    ) {
                      if (value.includes('+52')) {
                        onChange({
                          target: {
                            name: name,
                            value: value.length <= 3 ? '+52' : value,
                          },
                        })
                      }
                    }
                  }}
                  errors={legacyErrors ? errors : error}
                />
              )
              break
            case 'picture':
              children = (
                <AddImage
                  icon={
                    <Images
                      sx={styles.img}
                      src={item?.rest?.customImage ?? images.profileBtn}
                    />
                  }
                  image={localValue?.[item.name] ?? item.value}
                  name={item.name}
                  label={`${item?.translate ? t(item.label) : item.label} ${!!item.validate?.required ? '*' : ''
                    }`}
                  size={{
                    width: item.rest?.imageSize ?? '120px',
                    height: item.rest?.imageSize ?? '120px',
                  }}
                  getFile={onChange}
                />
              )
              break
            case 'file':
              children = (
                <InputFile
                  input={[]}
                  item={item}
                  onChange={onChange}
                  errors={legacyErrors ? errors : error}
                  values={localValue?.[item.name] ?? item}
                />
              )
              break
            case 'date':
              let maxDate = localValue?.[item?.rest?.max]
              if (item?.rest?.maxToday)
                if (!isBefore(maxDate?.$d, new Date())) maxDate = new Date()

              children = (
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="es"
                  key={item.name}
                >
                  <DesktopDatePicker
                    {...item.rest}
                    label={`${item?.translate ? t(item.label) : item.label} ${!!item.validate?.required ? '*' : ''
                      }`}
                    value={localValue?.[item.name] || item?.value}
                    maxDate={maxDate}
                    minDate={localValue?.[item?.rest?.min]}
                    onChange={(newValue: any) => {
                      onChange({
                        target: {
                          name: item.name,
                          value: newValue,
                        },
                      })
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        {...item.rest?.textField ?? {}}
                        onBlur={({ target }) => {
                          const { value } = target
                          const parsedDate = parse(value, 'P', new Date(), {
                            locale: enGB,
                          })
                          const isValidDate = isValid(parsedDate)

                          if (isValidDate)
                            onChange(
                              {
                                target: {
                                  name: item.name,
                                  value: value,
                                },
                              },
                              { blur: true }
                            )
                          else
                            onChange({
                              target: {
                                name: item.name,
                                value: null,
                              },
                            })
                        }}
                        error={
                          !!item.rest?.disabled ? false : errors?.[item.name]
                        }
                        helperText={
                          !!item.rest?.disabled
                            ? false
                            : errors?.[item.name] ?? ''
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              )
              break
            case 'color':
              children = (
                <Box key={item.name}>
                  <WrapperColors
                    label={`${item?.translate ? t(item.label) : item.label}`}
                    onChange={onChange}
                    value={localValue?.[item.name] ?? item?.value}
                    name={item.name}
                    required={!!item.validate?.required}
                  />
                  <Typography sx={styles.description}>
                    {handleDescription(item)}
                  </Typography>
                </Box>
              )
              break
            case 'simple-file':
              children = (
                <SimpleFile
                  item={item}
                  onChange={onChange}
                  errors={legacyErrors ? errors : error}
                  values={localValue?.[item.name]}
                />
              )
              break
            case 'check':
              children = (
                <FormControlLabel
                  key={item.name}
                  sx={styles.label}
                  control={
                    <Checkbox
                      name={item.name}
                      disabled={disabled}
                      onChange={({ target }) => {
                        onChange({
                          target: {
                            name: target.name,
                            value: target.checked,
                          },
                        })
                      }}
                      checked={
                        localValue?.[item.name] ?? item?.value?.value ?? item.value
                      }
                    />
                  }
                  label={`${item?.translate ? t(item.label) : item.label} ${!!item.validate?.required ? '*' : ''
                    }`}
                />
              )
              break
            case 'select':
              children = (
                <CustomSelect
                  isRequired={!!item.validate?.required}
                  errors={legacyErrors ? errors : error}
                  name={item.name}
                  label={`${item?.translate ? t(item.label) : item.label} ${!!item.validate?.required ? '*' : ''
                    }`}
                  onChange={onChange}
                  items={item.items}
                  selected={localValue?.[item.name] ?? item.value}
                  {...item.rest}
                />
              )
              break
            case 'select-search':
              children = (
                <MultiSelectSimple
                  errors={legacyErrors ? errors : error}
                  name={item.name}
                  label={`${item?.translate ? t(item.label) : item.label} ${!!item.validate?.required ? '*' : ''
                    }`}
                  onChange={onChange}
                  items={item.items}
                  selected={localValue?.[item.name] ?? item.value}
                  disableClearable={item.rest?.disableClearable}
                  disabled={disabled}
                  {...item.rest}
                />
              )
              break
            case 'multiSelect':
              children = (
                <MultiSelect
                  name={item.name}
                  label={`${item?.translate ? t(item.label) : item.label} ${!!item.validate?.required ? '*' : ''
                    }`}
                  onChange={onChange}
                  items={item.items}
                  selected={localValue?.[item.name] ?? item.value}
                  {...item.rest}
                />
              )
              break
            case 'filter-select':
              children = (
                <FilterSelect
                  ref={refF}
                  errors={legacyErrors ? errors : error}
                  name={item.name}
                  label={`${item?.translate ? t(item.label) : item.label} ${!!item.validate?.required ? '*' : ''
                    }`}
                  onChange={onChange}
                  items={item.items}
                  selected={localValue?.[item.name] ?? item.value}
                  {...item.rest}
                />
              )
              break
            case 'switch':
              children = (
                <>
                  {item.title && (
                    <Typography
                      sx={{
                        fontSize: '15px',
                        fontWeight: '600',
                        color: '#707070',
                        marginBottom: '6px',
                      }}
                    >
                      {item.title}
                    </Typography>
                  )}
                  <FormControlLabel
                    key={item.name}
                    control={
                      <IOSSwitch
                        name={item.name}
                        disabled={disabled}
                        checked={localValue?.[item.name] ?? item.value}
                        onChange={({ target }) => {
                          onChange({
                            target: {
                              name: target.name,
                              value: target.checked,
                            },
                          })
                        }}
                        sx={{ m: 1 }}
                      />
                    }
                    label={`${item?.translate ? t(item.label) : item.label} ${!!item.validate?.required ? '*' : ''
                      }`}
                  />
                </>
              )
              break

            case 'percentage':
              children = (
                <PercentageRange
                  onChange={onChange}
                  values={localValue}
                ></PercentageRange>
              )
              break

            case 'textArea':
              children = (
                <BaseInput
                  disabled={disabled}
                  variant={variant}
                  name={item.name}
                  value={{
                    [item.name]: {
                      ...item,
                      value: localValue?.[item.name] ?? item.value,
                      label: item.label
                        ? `${item?.translate ? t(item.label) : item.label} ${!!item.validate?.required ? '*' : ''
                        }`
                        : '',
                    },
                  }}
                  pushError={item.pushError}
                  onChange={onChange}
                  errors={legacyErrors ? errors : error}
                  {...item.rest}
                  typeField={item?.rest?.typeField}
                  multiline
                  rows={2}
                  maxRows={4}
                />
              )
              break

            default:
              children = (
                <BaseInput
                  disabled={disabled}
                  variant={variant}
                  name={item.name}
                  value={{
                    [item.name]: {
                      ...item,
                      value: values?.[item.name] ?? item.value,
                      label: item.label
                        ? `${item?.translate ? t(item.label) : item.label} ${!!item.validate?.required ? '*' : ''
                        }`
                        : '',
                    },
                  }}
                  pushError={item.pushError}
                  onChange={onChange}
                  errors={legacyErrors ? errors : error}
                  {...item.rest}
                  typeField={item?.rest?.typeField}
                />
              )
              break
          }

          return (
            <Fragment key={`main-children-dynamicform-${index}`}>
              {filter && index === 1 && (
                <Grid xs={12} item key={'filter-dinamicform'}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: '600',
                      color: '#707070',
                    }}
                  >
                    Filtros
                  </Typography>
                </Grid>
              )}
              <Grid
                key={index}
                item
                {...{
                  xl: 12,
                  lg: 12,
                  md: 12,
                  sm: 12,
                  xs: 12,
                  ...(item?.size ?? {}),
                }}
                { ...item.itemChildren ?? {}}
              >
                {filter && index === 0 && (
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: '600',
                      color: '#707070',
                      marginBottom: '20px',
                    }}
                  >
                    Búsqueda General
                  </Typography>
                )}

                <Fragment key={`children-${index}-dynamicform`}>
                  {!item.wrapChildren ?
                    (
                      children
                    ) : (
                      item.wrapChildren({ children, id: `children-${index}-dynamicform` })
                    )
                  }
                </Fragment>
              </Grid>
            </Fragment>
          )
        })}
      </Grid>
    )
  }
)
