import {
  Brush,
  CartesianGrid,
  Legend,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { LinearGraphicProp } from './types'
import { Box, Typography } from '@mui/material'
import { useContextState } from '../../../../../../hooks/theme'
import { unitStatus } from '../../fragments/methods/statusByPercentage'
import { percentageColors } from '../../fragments/methods/statusColors'
import { percentage as per } from '../../../../../../utils/validate/validate'
import { useStyles } from './styles'
import { useMemo } from 'react'

const CustomizedLabel = (props: any) => {
  const { x, y, stroke, value } = props

  return (
    <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
      {value}
    </text>
  )
}

const CustomizedAxisTick = (props: any) => {
  const { x, y, payload } = props
  let value = payload.value
  if (props.separatorCustome) {
    value = value.split(props.separatorCustome)[0]
  }

  let header, customeX
  if (props.headers) {
    header = props.headers
  }

  if (props.customeX) {
    customeX = props.customeX
  }
  return (
    <g transform={`translate(${x},${y})`}>
      {!!header && header.component({ value })}

      <text
        x={customeX?.x ?? (!props.removeTurn ? 0 : 30)}
        y={customeX?.y ?? 0}
        dy={10}
        textAnchor="end"
        fill="#666"
        transform={customeX?.transform ?? `${!props.removeTurn ? 'rotate(-35)' : ''}`}
        fontSize={12}
      >
        {
          !!customeX ? customeX.component({ value }) :
            ((value as string)?.length > 20 && props?.dataLength > 6
              ? `${value?.slice(0, 15)}...`
              : value
            )
        }
      </text>
    </g>
  )
}

const CustomTooltip = ({
  active,
  payload,
  label,
  customeTitle,
  percentage,
  int,
  separatorCustome,
  ...rest
}: any) => {
  const { colors } = useContextState()
  const styles = useStyles()
  if (active && payload && payload.length) {
    let value = label
    if (separatorCustome) {
      value = value.split(separatorCustome)[1]
    }
    return (
      <Box
        component="div"
        id="CustomTooltip"
        sx={{
          background: `${colors.color14}`,
          boxShadow: `0px 0px 4px ${colors.color13}`,
          display: 'flex',
          flexWrap: 'wrap',
          rowGap: '5px',
          maxWidth: '300px',
          padding: '20px',
        }}
      >
        {customeTitle?.({ payload, value }) ?? (
          <Typography
            component="p"
            id="CustomTooltip"
            sx={{
              fontSize: '16px',
              width: '100%',
            }}
          >
            {value}
          </Typography>
        )}

        {payload.map((item: any) => {
          const customeData = item.payload?.customeSettings
          let colorsSettings: { color: string; text: string } | undefined =
            undefined
          if (customeData && !customeData?.remove?.[item?.dataKey]) {
            const status = unitStatus(
              parseFloat(per({ a: item.value, b: customeData.total }))
            )
            let typeStatus = status.programming()
            if (customeData?.types?.[item?.dataKey] != '1') {
              typeStatus = status.noProgramming()
            }
            colorsSettings = percentageColors[typeStatus]
          }
          if (item?.payload?.customeData?.[item?.dataKey]) {
            return item?.payload?.customeData?.[item?.dataKey]
          }
          return (
            <Box sx={styles.containerTitle} key={item.dataKey}>
              <Typography
                component="p"
                id="CustomTooltip"
                sx={{
                  color: !!colorsSettings?.color ? colors.color13 : (item.color),
                  width: '100%',
                }}
              >
                {item.name}:{' '}
                {item?.payload?.customeLabels?.[item?.dataKey]
                  ? item?.payload?.customeLabels?.[item?.dataKey]
                  : `${!parseFloat(item.value) || !!int
                    ? item.value
                    : parseFloat(item.value).toFixed(2)
                  }${!!percentage ? '%' : ''}`}
              </Typography>

              {!!colorsSettings?.color && (
                <Box
                  sx={styles.percentageStatus({
                    color: colorsSettings?.color ?? colors.color13,
                  })}
                />
              )}
            </Box>
          )
        })}
      </Box>
    )
  }

  return null
}

const LinearGraphic = (props: LinearGraphicProp) => {
  const { colors } = useContextState()

  const domain = useMemo(() => {
    const linearsKeys = props.linears?.map((item) => item?.key)

    if (!props.graphicHistory?.length || !props.linears?.length) {
      return [0, 5]
    }

    let max = 0
    linearsKeys.forEach((key) => {
      if (!key) return
      const maxKey = Math.max.apply(
        Math,
        props.graphicHistory?.map((item) => item?.[key] as number ?? 0)
      )
      if (maxKey > max) max = maxKey
    }
    )

    return [0, max + 5]

  }, [props.graphicHistory, props.linears])



  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={props.graphicHistory ?? []}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="1 1" />
        <YAxis type="number" domain={domain} />
        <XAxis
          dataKey="name"
          interval={'preserveStartEnd'}
          height={60}
          tick={<CustomizedAxisTick headers={props.xHeader} customeX={props.customeX} removeTurn={props.graphicHistory?.length < 12} />}
        />
        <Tooltip
          content={(content) =>
            CustomTooltip({
              ...content,
              customeTitle: props.customeTitle,
              int: props.int,
            })
          }
        />
        <Legend fontSize={1} />
        {!!props.brush && (
          <Brush
            travellerWidth={8}
            dataKey={props?.datakey ?? 'date'}
            height={15}
            stroke={colors.color13}
          />
        )}
        {props.linears}
      </LineChart>
    </ResponsiveContainer>
  )
}

export { LinearGraphic, CustomizedLabel, CustomizedAxisTick, CustomTooltip }
