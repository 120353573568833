import { GridColDef } from '@mui/x-data-grid'
import { useCallback, useEffect, useRef, useState } from 'react'
import { dashboardService } from '../../../../utils/constants/callService'
import { useTranslation } from 'react-i18next'
import { FilterData, RowsProperties } from './typing/interfaces'
import { useFilter } from '../../../../hooks/filter'
import { InputModel } from '../../../../utils/types'
import {
  getArea,
  getDepartments,
  getDivisions,
  getQuestionnaires,
  getRoles,
  getStore,
  getUserTypes,
  getUsers,
  getZones,
} from '../../../../utils/methods/geServiceWithItemsFormat'
import { Item } from '../../../../utils/types/generals'
import {
  getPreviousYears,
  monthYear,
} from '../../../../utils/constants/date_month'
import { FilterProps } from '../../../../hooks/filter/typing'
import { removeAllReference } from '../../../../utils/methods/removedReference'
import { getStatus } from '../../../../hooks/status'
import { useContextState } from '../../../../hooks/theme'
import { columsTranslate, useModelInput, statusList } from './constants'
import { UnitDataFilters, formatDataFiltered, formatResponsUnitData } from './methods'
import { TableData } from './typing/type'
import { useQuery } from '../../../../hooks/useQuery'
import { Constants } from '../../../../utils/constants'
import { parse } from 'date-fns'
import { currentWeek } from '../../../dashboard/pages/main/fragments/methods/calcDates'
import { intervaleList } from '../../../../utils/constants/intervaleList'

const defaultDates = {
  from: currentWeek().start.getTime(),
  to: currentWeek().end.getTime(),
}

const useBody = () => {
  const { t } = useTranslation()
  const { colors } = useContextState()
  const queryParams = useQuery()
  const [flat, setFlat] = useState(false)
  const refTime = useRef<NodeJS.Timeout | null>(null)
  const [inputsWithLegacy, setINputsWithLegacy] = useState<{
    childs: Item[]
    departments: Item[]
  }>({ childs: [], departments: [] })
  const externalValidation: FilterProps['externalValidation'] = ({
    name,
    value,
    body,
    setFilterData,
  }) => {
    let copyBody: Record<string, any> = removeAllReference(body)

    if (name === 'zone') {
      const newChild = copyBody?.child?.filter((e: any) =>
        value.find((e2: any) => e2.value == e.father)
      )

      setInput((prev) => ({
        ...prev,
        child: {
          ...prev.child,
          items:
            value?.length <= 0
              ? []
              : inputsWithLegacy.childs?.filter((itemClass) =>
                value?.some(
                  (itemZone: any) => itemZone?.value == itemClass?.father
                )
              ),
        },
      }))

      setFilterData((prev) => ({
        ...prev,
        child: newChild,
      }))

      copyBody = {
        ...copyBody,
        child: newChild,
      }
    } else if (name === 'area') {
      const { area, department } = copyBody
      const newDepartments = department?.filter((e: any) =>
        area.find((e2: any) => e2.value == e.areaId)
      )
      let arrayChilds: any[] = []

      area?.forEach((e: any) => {
        const id = e.value
        const childs = inputsWithLegacy.departments.filter(
          (child) => child.areaId == id
        )
        arrayChilds.push(...childs)
      })

      setInput((prev) => ({
        ...prev,
        department: { ...prev.department, items: arrayChilds },
      }))

      setFilterData((prev) => ({
        ...prev,
        department: newDepartments,
      }))

      copyBody = {
        ...copyBody,
        department: newDepartments,
      }
    } else if (name === 'percentage') {
      if (parseInt(value[0]) === 0 && parseInt(value[1]) === 100) {
        delete copyBody.percentage
      }
    }
    return copyBody
  }
  const { filterData, query, counter, errors, cleanFilter, onChange } =
    useFilter({
      formatFilter: {},
      externalValidation,
      defaultValues: defaultDates,
    })
  const modelInput = useModelInput()
  const [loading, setLoading] = useState(false)
  const [input, setInput] = useState<{ [x: string]: InputModel }>(modelInput)
  const [row, setRow] = useState<RowsProperties[]>([])
  const columns: GridColDef[] = columsTranslate({ colors, t })

  const cleanFIlters = () => {
    cleanFilter()
  }

  const tableData = async (payload?: TableData) => {
    setLoading(true)
    const { filters } = UnitDataFilters(payload)

    const body = {
      size: 10000,
      query: {
        bool: {
          filter: filters,
        },
      },
    }
    const response = await dashboardService.activeUnitsData({ body })
    const { hits } = formatResponsUnitData(response)

    setRow(hits)
    setLoading(false)
  }

  const getUnits = async () => {
    const { items } = await getStore()
    setInput((prev) => ({ ...prev, unit: { ...prev.unit, items } }))
  }

  const getAllZones = async () => {
    const { fatherZones, childZones } = await getZones()
    setINputsWithLegacy((prev) => ({ ...prev, childs: childZones }))
    setInput((prev) => ({
      ...prev,
      zone: { ...prev.zone, items: fatherZones },
    }))
  }

  const getAllDivisions = async () => {
    const { items } = await getDivisions()
    setInput((prev) => ({ ...prev, division: { ...prev.division, items } }))
  }

  const getAllRoles = async () => {
    const { items } = await getRoles()
    setInput((prev) => ({ ...prev, role: { ...prev.role, items } }))
  }

  const getAllUserTypes = async () => {
    const { items } = await getUserTypes()
    setInput((prev) => ({ ...prev, userType: { ...prev.userType, items } }))
  }

  const getAllUsers = async () => {
    const { items } = await getUsers()
    setInput((prev) => ({ ...prev, user: { ...prev.user, items } }))
  }

  const getAllQuestionnaires = async () => {
    const { items } = await getQuestionnaires()
    setInput((prev) => ({
      ...prev,
      questionnaire: { ...prev.questionnaire, items: items },
    }))
  }
  const getItemStatus = async () => {
    setInput((prev) => ({
      ...prev,
      questionnaireStatus: {
        ...prev.questionnaireStatus,
        items: statusList.map((item) => {
          const status = getStatus(item, colors, t)
          return {
            label: status.text,
            value: status.value,
          }
        }),
      },
    }))
  }

  const getAllAreas = async () => {
    const respons = await getArea()
    setInput((prev) => ({ ...prev, area: { ...prev.area, items: respons } }))
  }

  const getAllDepartments = async () => {
    const respons = await getDepartments()
    setINputsWithLegacy((prev) => ({ ...prev, departments: respons }))

    let areas: string = queryParams?.get("areas") as string
    let arrayChilds: any[] = []
    if (areas) {
      areas.split(",")?.forEach((e: string) => {
        const id = e
        const childs = respons.filter(
          (child) => child.areaId == id
        )
        arrayChilds.push(...childs)
      })
      
      setInput((prev) => ({
        ...prev,
        department: { ...prev.department, items: arrayChilds },
      }))
    }
  }

  const getDates = () => {
    setInput((prev) => ({
      ...prev,
      month: { ...prev.month, items: monthYear },
      createYear: { ...prev.createYear, items: getPreviousYears() },
    }))
  }

  const refreshTable = useCallback(() => {
    if (refTime.current) {
      clearTimeout(refTime.current)
    }

    refTime.current = setTimeout(() => {
      const { data } = formatDataFiltered({ filterData: filterData as FilterData })

      tableData(data)
    }, 300)
  }, [filterData])

  useEffect(() => {
    if (!!flat) {
      refreshTable()
    }
    //eslint-disable-next-line
  }, [query.all, refreshTable])

  const formatQueryParams = () => {
    let start: string | Date | number = queryParams?.get("from") as string
    let end: string | Date | number = queryParams?.get("to") as string
    let intervale: string = queryParams?.get("intervale") as string
    let areas: (Item)[] | string = queryParams?.get("areas") as string
    let departments: (Item)[] | string = queryParams?.get("departments") as string

    if (start) { start = (parse(start, Constants.dateFormat, new Date())) }
    else { start = defaultDates.from }
    if (end) { end = (parse(end, Constants.dateFormat, new Date())).setHours(23, 59, 59, 99) }
    else { end = defaultDates.to }
    if (areas) { areas = areas.split(",").map(item => ({ label: "", value: item, id: item })) }
    if (departments) { departments = departments.split(",").map(item => ({ label: "", value: item, id: item })) }
    if (!!intervale) { onChange({ target: { name: "intervale", value: intervaleList.find(item => item.value == intervale) } }) }

    onChange({ target: { name: "to", value: end } })
    onChange({ target: { name: "from", value: start } })
    if (areas?.length) { onChange({ target: { name: "area", value: areas } }) }
    if (departments?.length) { onChange({ target: { name: "department", value: departments } }) }
  }
  const getData = useCallback(() => {
    if (!flat) {
      formatQueryParams()
      getUnits()
      getItemStatus()
      getAllZones()
      getAllDivisions()
      getAllRoles()
      getAllUserTypes()
      getAllUsers()
      getAllAreas()
      getAllDepartments()
      getAllQuestionnaires()
      getDates()
      setFlat(true)
    }
  }, [flat])

  useEffect(() => {
    getData()
  }, [getData])
  return {
    loading,
    columns,
    row,
    filterData,
    counter,
    input,
    errors,
    cleanFIlters,
    onChange,
    refreshTable,
  }
}

export { useBody }
