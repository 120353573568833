import { Http } from "../types"
import { ObjectGeneric } from "../types/generals"
import './interceptor'
const URL = process.env.REACT_APP_INDICATORS_URL
const headers1 = {
  'Content-Type': 'application/json',
  'Accept-Encoding': "keep-alive",
  Accept: '*/*'
}

const headers2 = {
  Accept: 'multipart/form-data',
}

const OpenSearch: Http = {
  get: async () => await ({}),
  put: async () => await ({}),
  delete: async () => await ({}),
  post: async <T>(url: string, body?: ObjectGeneric | FormData, multiPart: boolean = false, noHeaders?: boolean) => {
    let username = process.env.REACT_APP_OPEN_SEARCH_USER
    let password = process.env.REACT_APP_OPEN_SEARCH_PASS
    let auth = btoa(`${username}:${password}`);

    const headers = {
      ...(multiPart ? headers2 : headers1),
      ...(!noHeaders && { Authorization: `Basic ${auth}` }),
    }
    const response = await fetch(URL + url, { method: 'POST', headers: headers, body: multiPart ? (body as FormData) : JSON.stringify(body) })
    const data = await response.json()
    return data as T
  },
}

export { OpenSearch }