import { InputModel } from '../../../../../../../utils/types'

const createEdit: Record<string, InputModel> = {
  name: {
    label: 'department.form.crud.name',
    translate: true,
    name: 'name',
    validate: {
      required: true,
    },
    customeErrors: {
      required: 'department.form.crud.departmentName',
    },
    rest: {
      type: 'alphaNumeric',
      inputProps: {
        maxLength: 45,
      },
    },
    value: null,
  },
  email: {
    label: 'general.tags.email',
    translate: true,
    name: 'email',
    validate: {
      email: true,
    },
    value: null,
  },
  phone: {
    label: 'general.tags.phone',
    translate: true,
    name: 'phone',
    type: 'phone',
    value: '',
    validate: {
      phone: true,
    },
  },
  responsible: {
    label: 'general.tags.responsible',
    translate: true,
    name: 'responsible',
    validate: {
      required: true,
    },
    type: 'select-search',
    items: [],
    value: null,
  },
}

const settingInputs: Record<string, InputModel> = {
  usersTypes: {
    label: 'department.form.settings.crud.userType',
    translate: true,
    name: 'usersTypes',
    type: 'filter-select',
    items: [],
    value: null,
  },
  users: {
    label: 'department.form.settings.crud.user',
    translate: true,
    name: 'users',
    type: 'filter-select',
    items: [],
    value: null,
  },
  stores: {
    label: 'department.form.settings.crud.store',
    translate: true,
    name: 'stores',
    type: 'filter-select',
    items: [],
    value: null,
  },
}

const filterCrud: Record<string, InputModel> = {
  search: {
    label: 'general.search',
    translate: true,
    name: 'search',
    type: 'text',
    value: null,
  },
  roles: {
    label: 'general.tags.roles',
    translate: true,
    name: 'roles',
    type: 'filter-select',
    items: [],
    value: null,
  },
  usersTypes: {
    label: 'department.form.settings.crud.userType',
    translate: true,
    name: 'usersTypes',
    type: 'filter-select',
    items: [],
    value: null,
  },
}
export { createEdit, settingInputs, filterCrud }
