import { userServiceFetch } from "../../../../../../utils/constants/callService";
import { Types } from "../../../../../../utils/enums";
import { handleAllErrors } from "../../../../../../utils/methods/handleErrors";
import { UpdateUserEmail } from "./types";

const updateUserEmail = async (payload: UpdateUserEmail) => {
  payload.setIsLoadingUpdateData(true)
  const email = payload?.value
  const id = payload?.id

  const response = await userServiceFetch.updateEmail(id, { email, })
  payload.setIsLoadingUpdateData(false)
  payload.setOpenModalUpdateEmail(false)
  if (!response?.errors) {
    payload.closeModalEdit()
    if (!payload.editAllUserData) {
      payload.setOpenSnackbar({
        open: true,
        type: Types.SUCCESS,
        message: payload.t('general.emailUpdated'),
      })
    } else {
      payload.setOpenSnackbar({
        open: true,
        type: Types.SUCCESS,
        message: payload.t('general.userUpdated'),
      })
    }

    let info = { ...payload.information }
    info.data = info?.data?.map((e) => {
      if (e && e.name == 'email') {
        e.value = email || ''
        e.selected = email || ''
      }
      return {
        ...e,
      }
    })
    payload.setInformation(info)

    let tableData = { ...payload.table }
    tableData.row = tableData.row?.map((e) => {
      if (e && e.id == id) {
        e.email = email || ''
      }
      return {
        ...e,
      }
    })
    payload.setTable(tableData)
    payload.setRow(() => tableData.row)
  } else {
    if (!payload.editAllUserData)
      payload.setOpenSnackbar({
        open: true,
        type: Types.ERROR,
        message: handleAllErrors(response?.errors?.[0]?.code, payload.t, 'general.messages.errors.emailError'),
      })
    else
      payload.setOpenSnackbar({
        open: true,
        type: Types.ERROR,
        message: payload.t('general.userUpdated'),
      })
  }
}

export { updateUserEmail }