import { useContextState } from '../../../../hooks/theme'

export const useStyles = () => {
  const { colors: color } = useContextState()
  return ({
    container: {
      width: '90%',
      margin: 'auto',
      padding: '20px 0',
    },
    close: {
      position: 'absolute',
      right: '10px',
      top: '5px',
    },
    header: {
      width: 'calc(100%)',
      display: 'flex',
      flexWrap: 'wrap',
      padding: '20px 0',
      justifyContent: 'space-between',
      '& .title': {
        fontSize: '18px',
        color: color.color9,
      },
      '& .another': {
        fontSize: '14px',
        color: color.color4,
        cursor: 'pointer',
      },
    },
    body: {},
    footer: {
      marginTop: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .title': {
        fontSize: '14px',
        fontWeight: '700',
        color: color.color9,
        marginBottom: '10px',
      },
    },
    totalPoints: {
      marginTop: '20px',
      width: '200px',
      '& .MuiGrid-root': {
        maxWidth: '200px',
      },
    },
    toggleButton: {
      '&.MuiToggleButtonGroup-root': {
        '& .MuiToggleButton-root.Mui-selected': {
          color: color.color5,
          backgroundColor: color.color24,
        },
        '& .MuiToggleButton-root': {
          borderRadius: 0,
          padding: '4px 10px',
          color: color.color13,
          borderColor: color.color13,
        },
      },
    },
    containerBtns: {
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      borderRadius: 0,
      float: 'right',
      margin: '20px 0'
    },
  })
}
