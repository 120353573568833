import { ParamsConfiguration, QuestionEvidence } from "../../../service/questionarie/response";
import { EvidencesString, EvidencesTypeEnum } from "../../../utils/enums/evidences";
import { InputModel } from "../../../utils/types";
import { Item } from "../../../utils/types/generals";
export interface Evidence {
  id?: number
  status: boolean
  required: boolean
  title: string
  checks?: { [x: string]: InputModel }
  inputs?: { [x: string]: InputModel }
  disabled?: boolean
}
export interface InitialInputsEvidencesProps {
  size?: Item[]
  noSize?: boolean
  config?: ParamsConfiguration
  label?: string
  amountProps?: {
    min?: number
    max?: number
    defaultValue?: number
  }
  sizeProps?: {
    min?: number
    max?: number
    defaultValue?: number
  }
}
export const handleSetEvidence = ({ list, splitNumber, value, name, isBigger, evidenceArray }: { list: any; splitNumber: string[]; name: string; value: any; isBigger: boolean; evidenceArray: InputModel[] }) => {
  const deleteEvidences = splitNumber[1] === 'status' && !value

  return {
    ...list,
    evidenceType: {
      ...list.evidenceType,
      [splitNumber[0]]: !deleteEvidences
        ? {
          ...list.evidenceType?.[splitNumber[0]],
          [name]: isBigger
            ? {
              ...list.evidenceType?.[splitNumber[0]]?.[splitNumber[2]],
              [splitNumber[1]]: {
                ...list.evidenceType?.[splitNumber[0]]?.[splitNumber[2]]?.[splitNumber[1]],
                value,
              },
            }
            : value,
        }
        : initialEvidences({ evidenceArray })[splitNumber[0]],
    },
  }
}

export const initialEvidences = ({ evidenceArray, evidenceData }: { evidenceArray: InputModel[]; evidenceData?: QuestionEvidence[] }): { [x: string]: Evidence } => {
  let newEvidence: { [x: string]: Evidence } = {}

  const arraySizeImg = [
    { label: '1 Mb', value: 1 },
    { label: '2 Mb', value: 2 },
    { label: '3 Mb', value: 3 },
    { label: '4 Mb', value: 4 },
    { label: '5 Mb', value: 5 },
    { label: '6 Mb', value: 6 },
    { label: '7 Mb', value: 7 },
    { label: '8 Mb', value: 8 },
    { label: '9 Mb', value: 9 },
    { label: '10 Mb', value: 10 },
    { label: '15 Mb', value: 15 },
    { label: '20 Mb', value: 20 },
  ]

  const inputs = ({ size, noSize, config, label, amountProps, sizeProps }: InitialInputsEvidencesProps): { [x: string]: InputModel } => ({
    ...(!noSize
      ? {
          sizes: {
            label: `general.amount.${label ?? 'maxSize'}`,
            translate: true,
            name: 'sizes',
            customeErrors: {
              required: 'general.validate.required.sizes',
            },
            validate: { required: true, number: !size },
            type: !size ? 'text' : 'select-search',
            rest: {
              type: 'number',
              min: 1,
              disableClearable: true,
            },
            value: !size ? (config?.sizes ? config?.sizes?.toString() : sizeProps?.defaultValue ?? '1') : config?.sizes ? size?.find((item) => item.value === config?.sizes) : size[0],
            items: size,
            error: 'general.validations.required',
            size: { xl: 6, lg: 6, md: 6, sm: 6, xs: 6 },
          },
        }
      : {}),
    amounts: {
      label: 'general.amount.maxAmount',
      translate: true,
      validate: { required: true, number: !size },
      name: 'amounts',
      customeErrors: {
        required: 'general.validate.required.amount',
      },
      pushError: true,
      rest: {
        type: 'number',
        ...(amountProps ?? {}),
        min: amountProps?.min ?? 1,
      },
      value: config?.amounts ? config?.amounts?.toString() : amountProps?.defaultValue ?? '1',
      error: 'general.validations.required',
      size: { xl: 6, lg: 6, md: 6, sm: 6, xs: 6 },
    },
  })

  const checks = ({
    checks,
    existValue,
  }: {
    checks: {
      name: string
      size?: {
        xs: number
        xl: number
        lg: number
        md: number
        sm: number
      }
    }[]
    existValue?: string[]
  }) => {
    let newChecks: { [x: string]: InputModel } = {}

    checks?.forEach(({ name, size }) => {
      const sizeCheck = 12 / checks?.length
      newChecks = {
        ...newChecks,
        [name]: {
          label: `general.formats.${name}`,
          name: name,
          value: existValue ? existValue?.includes(name) : true,
          type: 'check',
          translate: true,
          size: size ?? { xl: sizeCheck, lg: sizeCheck, md: sizeCheck, sm: sizeCheck, xs: sizeCheck },
        },
      }
    })
    return newChecks
  }
  evidenceArray?.forEach((item) => {
    const data = evidenceData?.find((itemData) => (itemData?.evidenteTypeId ?? itemData?.evidenceTypeId) === item?.rest?.id)

    switch (item.name) {
      case EvidencesTypeEnum.CAMERA:
        newEvidence = {
          ...newEvidence,
          [EvidencesTypeEnum.CAMERA]: {
            status: !!data,
            required: data?.required ? data.required : false,
            title: 'general.evidences.camera',
            checks: checks({ checks: [{ name: 'png' }, { name: 'jpg' }, { name: 'jpeg' }], existValue: data?.paramsConfiguration?.types }),
            inputs: inputs({
              size: arraySizeImg,
              config: data?.paramsConfiguration,
            }),
            id: item?.rest?.id,
          },
        }
        break
      case EvidencesTypeEnum.IMAGE:
        newEvidence = {
          ...newEvidence,
          [EvidencesTypeEnum.IMAGE]: {
            status: !!data,
            required: data?.required ? data.required : false,
            title: 'general.evidences.image',
            inputs: inputs({
              size: arraySizeImg,
              config: data?.paramsConfiguration,
            }),
            checks: checks({ checks: [{ name: 'png' }, { name: 'jpg' }, { name: 'jpeg' }], existValue: data?.paramsConfiguration?.types }),
            id: item?.rest?.id,
          },
        }
        break
      case EvidencesTypeEnum.VIDEO:
        newEvidence = {
          ...newEvidence,
          [EvidencesTypeEnum.VIDEO]: {
            status: !!data,
            required: data?.required ? data.required : false,
            title: 'general.evidences.video',
            // checks: checks({ checks: [{ name: 'mp4' }, { name: 'wmv' }, { name: 'wma' }], existValue: data?.paramsConfiguration?.types }),
            inputs: inputs({
              size: [
                { label: '10 Mb', value: 10 },
                { label: '20 Mb', value: 20 },
                { label: '30 Mb', value: 30 },
                { label: '40 Mb', value: 40 },
              ],
              config: data?.paramsConfiguration,
            }),
            id: item?.rest?.id,
          },
        }
        break
      case EvidencesTypeEnum.FILE:
        const checksSize = {
          xl: 3,
          lg: 3,
          md: 3,
          sm: 3,
          xs: 3,
        }
        newEvidence = {
          ...newEvidence,
          [EvidencesTypeEnum.FILE]: {
            status: !!data,
            required: data?.required ? data.required : false,
            checks: checks({
              checks: [
                { name: 'pdf', size: checksSize },
                { name: 'csv', size: checksSize },
                { name: 'docx', size: checksSize },
                { name: 'xlsx', size: checksSize },
                { name: 'pptx', size: checksSize },
                { name: 'txt', size: checksSize },
              ],
              existValue: data?.paramsConfiguration?.types,
            }),
            title: 'general.evidences.file',
            inputs: inputs({
              size: [
                { label: '1 Mb', value: 1 },
                { label: '2 Mb', value: 2 },
                { label: '3 Mb', value: 3 },
                { label: '4 Mb', value: 4 },
                { label: '5 Mb', value: 5 },
              ],
              config: data?.paramsConfiguration,
            }),
            id: item?.rest?.id,
          },
        }
        break
      case EvidencesTypeEnum.VIDEO_GALLERY:
        newEvidence = {
          ...newEvidence,
          [EvidencesTypeEnum.VIDEO_GALLERY]: {
            status: !!data,
            required: data?.required ? data.required : false,
            title: 'general.evidences.video_gallery',
            inputs: inputs({
              size: [
                { label: '10 Mb', value: 10 },
                { label: '20 Mb', value: 20 },
                { label: '30 Mb', value: 30 },
                { label: '40 Mb', value: 40 },
              ],
              config: data?.paramsConfiguration,
            }),
            checks: checks({ checks: [{ name: 'mp4' }, { name: 'wmv' }, { name: 'wma' }], existValue: data?.paramsConfiguration?.types }),
            id: item?.rest?.id,
          },
        }
        break
      case EvidencesTypeEnum.SIGN:
        newEvidence = {
          ...newEvidence,
          [EvidencesTypeEnum.SIGN]: {
            status: !!data,
            required: data?.required ? data.required : false,
            title: 'general.evidences.sign',
            inputs: inputs({ noSize: true, config: data?.paramsConfiguration }),
            id: item?.rest?.id,
          },
        }
        break
      case EvidencesTypeEnum.COMMENT:
        newEvidence = {
          ...newEvidence,
          [EvidencesTypeEnum.COMMENT]: {
            status: !!data,
            required: data?.required ? data.required : false,
            title: 'general.evidences.comment',
            inputs: inputs({ config: data?.paramsConfiguration, label: 'comment', sizeProps: { defaultValue: 255 } }),
            id: item?.rest?.id,
          },
        }
        break
      default:
        break
    }
  })
  const codeEvidences = {
    image: 1,
    camera: 2,
    video: 3,
    video_gallery: 4,
    sign: 5,
    comment: 6,
    file: 7,
  }
  let sortObjectEvidences = {}
  const sortedEvidences = Object.entries(newEvidence).sort((a, b) => {
    const aString = a[0] as EvidencesString
    const bString = b[0] as EvidencesString

    if (codeEvidences[aString] < codeEvidences[bString]) {
      return -1
    }
    if (codeEvidences[aString] > codeEvidences[bString]) {
      return 1
    }
    return 0
  })

  sortedEvidences.forEach((item) => {
    sortObjectEvidences = {
      ...sortObjectEvidences,
      [item[0]]: item[1],
    }
  })

  return sortObjectEvidences
}