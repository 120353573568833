import { Color } from "../types/color";

export const linearProgress = (color: Color) => ({
    MuiLinearProgress: {
      styleOverrides: {
        root:{
          height: '6px',
          borderRadius: '40px',
          backgroundColor: color.color23
        }
      }
    }
})