import { useContextState } from "../../../../../../../../../hooks/theme"

const useStyles = () => {
  const { colors } = useContextState()

  return {
    title: {
      fontSize: '1.2rem',
      '& span': {
        color: colors.color4
      }
    },
    description: {
      fontSize: '1rem',
    },
    information: {
      width: '100%',
      display: 'flex',
      alignItems: "center",
      flexDirection: "column",
    },
  }
}

export { useStyles }
