import { Autocomplete, TextField } from '@mui/material'
import { Props } from './props'
import { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomPaper } from '../../CustomPaper'
import { Item } from '../../../utils/types/generals'

const MultiSelectSimple = (props: Props) => {
  const { t } = useTranslation()
  const handleChange = (_: SyntheticEvent<Element, Event>, value: Item | null) => {
    props.onChange({
      target: {
        name: props.name,
        value: value || undefined,
      },
    })
  }
  return (
    <Autocomplete
      key={props.name}
      sx={props.sx}
      disableClearable={props?.disableClearable}
      disabled={props.disabled}
      noOptionsText={t('general.noOptions')}
      options={props.items || []}
      getOptionLabel={(option) => option.label ?? ''}
      value={props.items?.find((item) => item.value === (props.selected?.value ?? props.selected)) ?? null}
      filterSelectedOptions
      autoComplete={false}
      onChange={handleChange}
      PaperComponent={CustomPaper}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={props.name}
          label={props.label}
          helperText={(props?.errors?.[props?.name]?.translate ? t(props?.errors?.[props?.name]?.translate) : props?.errors?.[props?.name]) ?? ''}
          error={props?.errors?.[props?.name]?.translate ?? props?.errors?.[props?.name]}
        />
      )}
    />
  )
}

export default MultiSelectSimple
