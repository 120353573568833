import { useContextState } from '../../../../hooks/theme'

interface Styles {
  [key: string]: any
}

export const useStyles = (): Styles => {
  const { colors: color } = useContextState()

  return ({
    container: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      maxHeight: '70vh',
      overflowY: 'auto',
      position: 'relative',
      borderBottomRightRadius: '20px'
    },
    fieldRequired: {
      width: '100%',
    },
    closeSettings: {
      fontSize: '1.2rem',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    quetionnaireCannotBeEdited: {
      color: color.color19,
      fontSize: '12px'
    },
    manageAccount: {
      position: 'absolute',
      right: '0px',
      top: '40px',
      paddingRight: '10px',
      color: color.color5,
      borderRadius: '5px',
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0',
      background: color.color31,
      zIndex: 1000,
      '&:hover': {
        background: color.color31 + 'DD',
      },
    },
    table: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '30px',
      width: '100%',
      transition: '.3s',
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'relative',
    },
    addAsk: {
      width: 'fit-content',
      marginTop: '20px',
    },
    alignGrid: {
      '& .MuiGrid-root': {
        alignItems: 'center',
      },
    },
    userDetail: {
      flex: 0,
      height: '100%',
      boxShadow: `0 0 2px ${color.color10}`,
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'relative',
      transition: '.3s',
      '& .containerUserDetail': {
        padding: '20px',
        display: 'flex',
        flexWrap: 'wrap',
        rowGap: '10px',
        '& .quest_name_title': {
          fontSize: '14px',
          color: color.color13,
        },
        '& .quest_name': {
          width: '100%',
          fontSize: '18px',
          color: color.color13,
          fontWeight: '500',
        },
      },
    },
    checkIcon: {
      '& .MuiSvgIcon-root': {
        color: color.color8,
      },
      '&.Mui-disabled': {
        '& .MuiSvgIcon-root': {
          color: color.color13,
        },
      },
    },
    removeIcon: {
      '& .MuiSvgIcon-root': {
        color: color.color19,
      },
    },
    tabsWidth: {},
    boxSetting: {
      height: '10px',
      width: '10px',
    },
    rejectsNumber: {
      width: '100%',
    },
    rejectTitle: {
      fontSize: '15px',
    },
    rejectError: {
      fontSize: '11px',
      color: color.color19,
    },
    tabContainer: {
      '& .MuiTabs-flexContainer': {
        overflowX: 'auto',
        transition: '.3s',
        width: 'calc(100% - 160px)',
        columnGap: '2px',
        paddingLeft: '20px',
        paddingTop: '10px',
        '& .MuiTab-root': {
          minHeight: '40px',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          boxShadow: `0 0 2px ${color.color13}`,
          backgroundColor: `${color.color10}44`,
          // paddingRight: '5px',
          color: color.color10,
          '&.Mui-selected': {
            backgroundColor: color.color5,
          },
        },
      },
      '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
      },
    },
    addIcon: {
      '& .MuiSvgIcon-root': {
        color: color.color4,
      },
    },
    TabsContainer: {
      boxShadow: `0 0 3px ${color.color13}`,
      borderRadius: '5px',
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& .MuiSvgIcon-root': {
        fontSize: '1.4rem',
      },
    },
    totalPoint: {
      width: '50px',
      '& input': {
        padding: '4px 0 0 0',
      },
    },
    pointsContainer: {
      position: 'relative',
      top: '-45px',
      height: '20px',
      '& .points': {
        '& .MuiGrid-root': {
          width: '50px',
          marginTop: '-10px',
        },
        position: 'absolute',
        right: '20px',
        top: '10px',
        width: '110px',
        display: 'flex',
        alignItems: 'center',
        columnGap: '10px',
      },
    },
    containerFooter: {
      width: '100%',
      marginTop: '20px',
      position: 'relative',
    },
    generalActionPlan: {
      position: 'absolute',
      right: '0',
      bottom: '10px',
    },
    goBack: {
      display: 'flex',
      justifyContent: 'flex-start',
      '& .MuiSvgIcon-root': {
        fontSize: '1.4rem',
        color: color.color4,
      },
    },
    optionQuest: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      alignItems: 'center',
      marginTop: '25px',
      marginBottom: '20px',
    },
    answers: {
      margin: '25px 0',
      display: 'flex',
      alignItems: 'start',
      '& .MuiGrid-root': {
        alignItems: 'center',
        width: 'calc(100% - 150px)',
      },
    },
    containerButton: {
      width: '150px',
      display: 'flex',
      justifyContent: 'flex-end',
      '& .MuiIconButton-root': {
        width: '50px',
      },
    },
    listAnswers: {
      margin: '20px 0',
      boxShadow: `0 0 3px ${color.color13}`,
      padding: '10px 30px',
      borderRadius: '5px',
      position: 'relative',
      // maxHeight: '315px',
      overflowY: 'auto',

      '& .questionRow': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
    boxShadow: {
      '&.MuiIconButton-root': {
        boxShadow: `1px 1px 2px ${color.color10}`,
        padding: '5px',
        cursor: 'pointer',
      },
    },
    setting: {
      width: '5%',
      '&.MuiIconButton-root': {
        borderRadius: '5px',
        cursor: 'default',
        padding: '0px',
      },
    },
    delete: {
      width: '5%',
      '& .MuiSvgIcon-root': {
        color: color.color19,
      },
    },
    titleOptions: {
      width: 'fit-content',
      flexGrow: 1,
    },
    containeroptions: {
      width: '80%',
      '& .MuiGrid-root': {
        justifyContent: 'flex-end',
        alignItems: 'center',
      },
    },
    removeSection: {
      '&.MuiIconButton-root': {
        padding: '5px',
        '& .MuiSvgIcon-root': {
          width: '15px',
          height: '15px',
        },
      },
    },
    question: {
      position: 'absolute',
      right: '20px',
      top: '0px',
    },
    submit: {
      marginTop: '20px',
      borderRadius: 0,
    },
    buttonAction: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    sectionName: {
      color: color.color4,
      fontSize: '0.9rem',
    },
    sectionDesc: {
      color: color.color4,
      fontSize: '0.75rem',
    },

    indexSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '& .indexBox': {
        border: '1px solid',
        padding: '3px',
        fontSize: '14px',
        textAlign: 'center',
      },

      '& .icon': {
        padding: '0px',
        '& .MuiSvgIcon-root': {
          fontSize: '36px',
          margin: '-12px 0px',
        },
        '&.disabled': { pointerEvents: 'none', color: '#bdbdbd' },
      },
    },

  })
}
