import { Color } from "../../../../../../../../utils/theme/types/color"

const useStyles = ({color}: {color: Color}) => {
    return {
        // OPTIONS
    buttonOptions: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        rowGap: '20px',
        width: '100%',
      },
      optionTitle: {
        color: color.color5,
        fontSize: '16px',
        width: '200px',
        textAlign: 'center',
        wordBreak: 'break-word',
      },
      conteinrButtons: {
        display: 'flex',
        justifyContent: 'center',
        columnGap: '20px',
        width: '100%',
      },
      buttons: {
        padding: '10px 20px',
      },
      buttonColorB: {
        backgroundColor: color.color1,
        color: color.color5,
        '&:hover': {
          backgroundColor: `${color.color1}ee`,
          color: color.color5,
        },
      },
      buttonColorR: {
        backgroundColor: color.color19,
        color: color.color5,
        '&:hover': {
          backgroundColor: `${color.color19}ee`,
          color: color.color5,
        },
      },
    }
}

export { useStyles }
