import { useEffect, useRef, useState } from "react"

const useDimensions = () => {
  const [dimensions, setDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const handleResize = () => {
    setDimension({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  return { dimensions }
}

const useDimensionsOfADiv = () => {
  const [dimensions, setDimension] = useState({
    width: 0,
    height: 0
  })
  const miDivRef = useRef<HTMLDivElement | null>(null);
  const miContenedorRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Función para medir el ancho del div y actualizar el estado.
    const actualizarAnchoDiv = () => {
      if (miDivRef.current) {
        const nuevoAncho: number = miDivRef.current.offsetWidth;
        setDimension(prev => ({
          ...prev,
          width: nuevoAncho
        }));
      }
    };

    // Observador de mutaciones para detectar cambios en el tamaño del contenedor.
    const observador = new MutationObserver(actualizarAnchoDiv);

    if (miContenedorRef.current) {
      observador.observe(miContenedorRef.current, {
        attributes: true,  // Observar cambios en los atributos del contenedor
        attributeFilter: ['style'],  // Solo observar cambios en el estilo del contenedor
      });
    }

    // Llama a la función para medir el ancho cuando el componente se monta
    actualizarAnchoDiv();

    return () => {
      observador.disconnect();  // Detiene el observador cuando el componente se desmonta
    };
  }, []);

  return { dimensions, miDivRef, miContenedorRef }
}

export { useDimensions, useDimensionsOfADiv }