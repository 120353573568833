import { Box, IconButton, Typography } from "@mui/material"
import { useStyles } from "./styles"
import { BarGraphic } from "../../components/barGraphic"
import Loader from "../../../../../../components/loader"
import { useBody } from "./body"
import { useTranslation } from "react-i18next"
import { icons } from "../../../../../../utils/icons"

const FoliosIndicators = () => {
  const { t } = useTranslation()
  const { loading, data, indicatorsBars, separator, getData } = useBody()
  const styles = useStyles()

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Box>
          <Typography sx={styles.title}>Indicador de folios de reporte</Typography>
        </Box>

        <IconButton
          sx={styles.refresh}
          onClick={() => {
            getData()
          }}
        >
          {icons("refresh")}
        </IconButton>
      </Box>

      <Box sx={styles.detail}>
        {!!loading ? (
          <Loader />
        ) : (
          <Box sx={styles.graph({ existData: !!data?.length })}>
            {!!data?.length ? (
              <BarGraphic
                int
                doesnotRemoveTurn
                separatorX={separator}
                datakey="name"
                data={data}
                indicators={indicatorsBars}
                simpleTooltip
              />
            ) : (
              <Typography sx={styles.noData}>
                {t('reportFolio.detail.general.noInformation')}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export { FoliosIndicators }
