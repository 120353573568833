import { Box } from '@mui/material'
import { DinamicForm } from '../../../../../components/dinamicForm'
import Loader from '../../../../../components/loader'
import { useStyles } from './styles'

const Form = ({ btnLoading, data, input, crudChange }: any) => {
  const styles = useStyles()

  return btnLoading ? (
    <Box sx={styles.loader}>
      <Loader />
    </Box>
  ) : (
    <Box sx={styles.container}>
      <DinamicForm input={input} values={data} onChange={crudChange} />
    </Box>
  )
}

export default Form
