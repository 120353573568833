import { useContextState } from '../../../../../../../../hooks/theme'
import { Color } from '../../../../../../../../utils/theme/types/color'

const useStyles = ({ color }: { color: Color }) => {
  const { colors } = useContextState()
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      rowGap: '20px',
      width: '80%',
      '& .MuiOutlinedInput-root.MuiOutlinedInput-root': {
        padding: 0,
      },
      '& .MuiOutlinedInput-input.MuiOutlinedInput-input': {
        background: colors.color5,
        borderRadius: '5px',
        padding: '10px',
      },
    },
    badge: {
      '& .MuiBadge-badge': {
        background: colors.color20,
        color: colors.color25,
      },
    },
    see: {
      color: colors.color4,
    },
    titleModal: {
      textAlign: 'center',
      fontSize: '16px',
      color: color.color9,
      fontWeight: '800',
    },
    containerSee: {
      display: 'flex',
      alignItems: 'center',
      width: '120px',
      justifyContent: 'end',
      gap: '5px',
    },
    containerTitle: {
      width: 'calc(100% - 120px)',
      wordBreak: 'break-word',
      '& span': {
        color: colors.color19,
      },
    },
    amountContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
    },
    errorSection: ({ open }: { open: boolean }) => {
      return {
        width: '18px',
        height: '18px',
        background: colors.color12,
        borderRadius: '50px',
        position: 'absolute',
        top: '-5px',
        right: '-5px',
        zIndex: !open ? '10000' : 0,
        textAlign: 'center',
        '& .MuiTypography-root': {
          color: color.color5,
          fontWeight: '500',
          fontSize: '12px',
        },
      }
    },
    amount: {
      fontSize: '11px',
    },
    flex: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    progress: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: '0px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backdropFilter: 'blur(5px)',
    },
    cancelUpload: {
      color: color.color19,
      fontSize: '20px',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    containerProgress: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    closeSettings: {
      fontSize: '1.2rem',
      color: colors.color4,
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    itemsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      gap: '10px',
    },
    files: {
      display: 'none',
    },
    add: {
      color: colors.color4,
    },
    containerUl: {
      // width: '100%',
      // padding: '0px 40px',
      '& li': {
        fontSize: '12px',
      },
    },
    errorsInput: {
      '& .MuiFormHelperText-root.Mui-error': {
        color: colors.color5,
      },
    },
    containerErrors: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      gap: '20px',
      justifyContent: 'center',
    },
    items: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      gap: '10px',
      justifyContent: 'space-between',
      background: `${colors.color10}22`,
      padding: '15px',
      position: 'relative',
    },
    iconContainer: {
      width: '50px',
      display: 'flex',
      gap: '5px',
      '& .MuiIconButton-root': {
        '&.download': {
          color: colors.color4,
        },
        '&.delete': {
          color: colors.color19,
        },
        fontSize: '1.2rem',
        padding: '2px',
        '& .MuiSvgIcon-root': {
          fontSize: '1em',
        },
      },
    },
    itemTitle: {
      width: 'calc(100% - 60px)',
      wordBreak: 'break-word',
    },
    drawerContainer: {
      padding: '50px 20px 0px',
      '& .MuiPaper-root': {
        width: '100%',
      },
    },
    arrowHeaderDrawerContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      paddingBottom: '20px',
    },
    drawer: {
      position: 'relative',
      // padding: '50px 0px 10px',
      '& .MuiPaper-root': {
        '&:not(:last-child)': {
          marginBottom: '15px',
        },
        width: '400px',
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'end',
    },
    buttons: {
      padding: '10px',
    },
    buttonColorB: {
      backgroundColor: color.color1,
      color: color.color5,
      '&:hover': {
        backgroundColor: `${color.color1}ee`,
        color: color.color5,
      },
    },
    buttonArrow: {
      marginLeft: '10px',
    },
    clip: {
      backgroundColor: color.color30,
      color: color.color5,
      '& svg': {
        fontSize: '20px',
      },
      '&:hover': {
        backgroundColor: `${color.color30}ee`,
        color: color.color5,
      },
    },
    title: {
      width: '300px',
      color: color.color5,
      fontSize: '16px',
      wordBreak: 'break-word',
    },
    titleErrors: {
      '&.MuiTypography-root': {
        width: '100%',
      },
      fontSize: '16px',
      color: color.color9,
      fontWeight: '800',
    },
  }
}

export { useStyles }
