import { Box, Modal, SxProps } from '@mui/material'
import { useStyles } from './styles'

const BaseModal = (props: {open:boolean, children: JSX.Element | null, sx?:SxProps}) => {
  const styles = useStyles(props.sx)
  return (
    <Modal open={props.open}>
      <Box sx={styles.modal}>
        {props.children}
      </Box>
    </Modal>
  )
}

export default BaseModal
