import { LoadingButton } from '@mui/lab'
import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, Checkbox, Drawer, FormControlLabel, Grid, IconButton, Radio, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BaseMenu, { ItemMenu } from '../../../../../components/baseMenu'
import { DinamicForm } from '../../../../../components/dinamicForm'
import { IOSSwitch } from '../../../../../components/dinamicForm/switch'
import Loader from '../../../../../components/loader'
import { useContextState } from '../../../../../hooks/theme'
import { questionarieServise } from '../../../../../service/questionarie'
import { httpFetch } from '../../../../../utils/crud'
import { icons } from '../../../../../utils/icons'
import { InputModel } from '../../../../../utils/types'
import { onChangeEvent } from '../../../../../utils/types/generals'
import { useStyles } from './styles'
import { Constants } from '../../../../../utils/constants'
import { format } from 'date-fns'
import { ButtonsDrawer } from '../../../../../components/buttons/buttonDrawer'
import useGlobalErrors from '../../../../../hooks/erors'
import { Evidence, handleSetEvidence, initialEvidences } from '../../../models'

const Form = ({ btnLoading, data, input, crudChange, cancel, hideInfo, info, submit, deleteConfig, canSubmit, isEditing, evidences, isLoading }: any) => {
  const { colors } = useContextState()
  const { t } = useTranslation()
  const styles = useStyles(colors)
  const [arrayInfo, setArrayInfo] = useState<any>([])
  const [evidenceArray, setEvidenceArray] = useState()

  const [dataError, setDataError] = useState<any>()
  const { errors } = useGlobalErrors({ data: dataError, t, values: data })

  useEffect(() => {
    let data: { [x: string]: InputModel } = {}
    input?.forEach((item: any) => {
      data = { ...data, [item.name]: item }
    })
    setDataError(data)
  }, [input])

  useEffect(() => {
    if (info && !info?.errors) {
      const createdBy = `${info.createdBy?.firstName ?? ''} ${info.createdBy?.lastName ?? ''}`
      const createTime = format(new Date(info.createTime), Constants.dateFormat2)
      setArrayInfo([
        { label: 'ID', value: info.id || 'N/A' },
        { label: 'Áreas', value: info.areas || 'N/A' },
        { label: 'Departamentos', value: info.departments || 'N/A' },
        { label: '# Usuarios Activos', value: info.users },
        { label: 'Actualizado por', value: createdBy || 'N/A' },
        { label: 'Actualizado el', value: createTime || 'N/A' },
      ])
    }
  }, [info])

  useEffect(() => {
    const evidenceType = initialEvidences({ evidenceArray: evidenceArray ?? [], evidenceData: evidences })

    let expands: string[] = []
    Object.entries(evidenceType)?.forEach(([key, itemValues]) => {
      if (!!itemValues.status) {
        expands = [...expands, key]
      }
    })
    setExpandedEvidence(expands)
    setEvidenceType(evidenceType)
    setNewEvidence(evidenceType)
    setNewEvidenceCopy(evidenceType)
  }, [evidences, evidenceArray])

  const [itHasErrorsCheck, setItHasErrorsCheck] = React.useState<{ [x: string]: boolean }>({})
  const [expandedEvdence, setExpandedEvidence] = React.useState<string[]>([])
  const [itHasErrors, setItHasErrors] = React.useState<{ [x: string]: boolean }>({})
  const [openEvidences, setOpenEvidences] = React.useState<{
    open: boolean
    anchorEl: null | HTMLElement
    menuItem: ItemMenu[]
    dataEvidences?: { [x: string]: Evidence } | null
    indexEvidence?: string
    isGeneral: boolean
    splitIndex: number[]
    openModal?: boolean
    actionPlan?: boolean
    type?: string
  }>({
    open: false,
    anchorEl: null,
    menuItem: [],
    dataEvidences: null,
    indexEvidence: '',
    isGeneral: false,
    splitIndex: [],
  })

  const [evidenceType, setEvidenceType] = useState<
    | {
        [x: string]: Evidence
      }
    | undefined
  >()

  const [newEvidence, setNewEvidence] = React.useState<
    | {
        [x: string]: Evidence
      }
    | undefined
  >()
  const [newEvidenceCopy, setNewEvidenceCopy] = React.useState<
    | {
        [x: string]: Evidence
      }
    | undefined
  >()

  const handleErrors = ({
    values,
  }: {
    values?: {
      [x: string]: InputModel
    }
  }) => {
    if (values) {
      let errors = {}

      Object.entries(values).forEach(([key, item]) => {
        if (!item.value) {
          errors = {
            ...errors,
            [key]: t(`general.validate.required.${key}`),
          }
        }
      })

      return errors
    }
    return {}
  }

  const handleMenu = (item: ItemMenu) => {
    const name: string = item?.children?.props?.control?.props?.name
    let expands: string[] = []

    const body = {
      ...newEvidence,
      [name]: {
        ...(newEvidence as { [x: string]: Evidence })[name],
        status: true,
      },
    }
    setNewEvidence(body)

    Object.entries(body)?.forEach(([key, itemValues]) => {
      if (!!itemValues.status) {
        expands = [...expands, key]
      }
    })
    setExpandedEvidence(expands)
    setOpenEvidences((prev) => ({
      ...prev,
      open: true,
      dataEvidences: item.value,
    }))
  }

  const handleMenuCustom = () => {
    setNewEvidence(evidenceType)
    setOpenEvidences((prev) => ({
      ...prev,
      open: true,
      dataEvidences: null,
    }))
  }

  const closeMenu = () => {
    setOpenEvidences((prev) => ({
      ...prev,
      anchorEl: null,
      menuItem: [],
      openModal: false,
    }))
  }

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const evidenceTypeResponse = await questionarieServise(httpFetch).getEvidenceType()

    const evidenceArray = !evidenceTypeResponse.errors
      ? evidenceTypeResponse?.map((item: any) => ({
          label: item.description,
          name: item.key,
          type: 'switch',
          value: false,
          rest: {
            id: item.id,
          },
          size: {
            lg: 12,
          },
        }))
      : []

    setEvidenceArray(evidenceArray)
    setEvidenceType(initialEvidences({ evidenceArray: evidenceArray ?? [] }))
  }

  const handleEvidenceChange = ({ e: { target } }: { e: onChangeEvent }) => {
    const splitKey = target.name?.split('-')
    const isBigger = splitKey.length > 2
    const inName = isBigger ? splitKey[2] : splitKey[1]
    const evidenceObj = handleSetEvidence({ evidenceArray: evidenceArray ?? [], isBigger, name: inName, splitNumber: splitKey, value: target.value, list: { evidenceType: newEvidence } })

    if (splitKey[0]) {
      const currentEvidence = evidenceObj.evidenceType[splitKey[0]]
      let canItPass = false

      if (currentEvidence?.checks) {
        canItPass = !Object.values(currentEvidence?.checks as { [x: string]: InputModel })?.some((itemChecks) => itemChecks?.value)
      }
      setItHasErrorsCheck((prev) => ({
        ...prev,
        [splitKey[0]]: canItPass,
      }))

      setItHasErrors((prev) => ({
        ...prev,
        [splitKey[0]]: JSON.stringify(handleErrors({ values: currentEvidence?.inputs })) !== '{}' || canItPass,
      }))
    }
    setNewEvidence(evidenceObj.evidenceType)
  }

  interface OpenEvidencesParams {
    event: React.MouseEvent<HTMLButtonElement>
    evidenceType?:
      | {
          [x: string]: Evidence
        }
      | undefined
  }

  const handleOpenEvidences = ({ event }: OpenEvidencesParams) => {
    setOpenEvidences((prev) => ({
      ...prev,
      anchorEl: event.currentTarget,
      menuItem: evidenceType
        ? Object.entries(evidenceType)?.map(([key, item]) => ({
            value: evidenceType,
            children: <FormControlLabel sx={styles.label} control={<Checkbox sx={styles.checkbox} name={key} checked={item?.status} defaultChecked />} label={t(item?.title)} />,
          }))
        : [],
    }))

    if (!newEvidence) {
      setNewEvidence(evidenceType)
      setNewEvidenceCopy(evidenceType)
    }
  }

  const handleCloseDrawer = () => {
    setItHasErrorsCheck({})
    setOpenEvidences((prev) => ({
      ...prev,
      open: false,
    }))
  }

  const getEvidenceData = ({ evidenceType }: { evidenceType?: { [x: string]: Evidence } }) => {
    const evidences = Object.values(evidenceType ?? {})

    const filterData = evidences
      ?.filter((itemEvidenceType) => !!itemEvidenceType.status)
      .map((itemEvidenceType) => {
        const checks = Object.values(itemEvidenceType?.checks ?? {})
        return {
          evidenceTypeId: itemEvidenceType.id ?? 1,
          ...(checks?.length > 0 ? { types: checks?.filter((itemChecks) => itemChecks.value)?.map((itemCheccks) => t?.(itemCheccks.label)?.toLocaleLowerCase()) } : {}),
          ...extractInputValues({ inputs: itemEvidenceType.inputs }),
          required: itemEvidenceType.required,
          description: '',
        }
      })
    if (filterData?.length > 0) {
      return {
        evidenceRequire: 1,
        evidenceType: filterData,
      }
    }
  }

  const extractInputValues = ({ inputs }: { inputs?: { [x: string]: InputModel } }) => {
    if (!!inputs) {
      let obj: { size?: number; amount?: number } = {}

      Object.entries(inputs).forEach(([key, item]) => {
        obj = {
          ...obj,
          [key]: parseInt(item.value?.value ?? item.value),
        }
      })
      return obj
    }
    return {}
  }

  const emitForm = () => {
    const { limitFoliosAmount, maxRejectionsAmount, mandatorySurvey, satisfactionQuestion, enableSurvey, limitFolio, maxRejections } = data
    const evidenceData = getEvidenceData({ evidenceType: newEvidence })?.evidenceType

    submit({
      askForArea: true,
      isAreaRequired: true,
      askForDepartment: true,
      isDepartmentRequired: true,
      reportFolioGeneral: true,
      max: limitFolio ? limitFoliosAmount ?? '1' : null,
      maxRejects: maxRejections ? maxRejectionsAmount ?? '1' : null,
      ...(!!enableSurvey && { ratingQuestion: satisfactionQuestion, isRatingQuestionRequired: !!mandatorySurvey }),
      enableRatingQuestion: enableSurvey,
      configActionPlanReportFolioEvidenceTypes: evidenceData ?? [],
    })
  }

  return btnLoading ? (
    <Box sx={styles.loader}>
      <Loader />
    </Box>
  ) : (
    <>
      <Box sx={styles.container}>
        {!hideInfo && (
          <>
            <Typography
              sx={{
                fontSize: '17px',
                fontWeight: '600',
                color: '#707070',
                marginBottom: '20px',
                // textAlign: 'center',
              }}
            >
              Configuración de Folio de Reporte
            </Typography>
            <Box sx={{ marginBottom: 2, fontSize: '14px' }}>
              {arrayInfo?.map((item: any) => (
                <Box sx={styles.info}>
                  <Box sx={styles.left}>{item.label}</Box>
                  <Box sx={styles.right}>{item.value}</Box>
                </Box>
              ))}
            </Box>
          </>
        )}

        <DinamicForm input={input} values={data} onChange={crudChange} disabled={info} justifyContentLeft />

        <Box sx={styles.evidences}>
          <Badge sx={styles.badge} badgeContent={Object.values(newEvidence ?? {}).filter((itemEvidence) => itemEvidence.status)?.length}>
            {info && (
              <IconButton
                onClick={(e) => {
                  handleMenuCustom()
                }}
                sx={[styles.clip]}
                disabled={false}
              >
                {icons('clip')}
              </IconButton>
            )}
            {!info && (
              <IconButton
                onClick={(e) => {
                  handleOpenEvidences({ event: e })
                }}
                sx={[styles.clip]}
                disabled={false}
              >
                {icons('clip')}
              </IconButton>
            )}
          </Badge>

          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: '600',
              color: '#707070',
            }}
          >
            Tipos de evidencias requeridas
          </Typography>
        </Box>

        {!isEditing && (
          <Box sx={styles.buttons}>
            <LoadingButton loading={isLoading} sx={{ mt: 2, textTransform: 'uppercase' }} variant="contained" onClick={emitForm} disabled={Object.keys(errors).length > 0 || !canSubmit}>
              CREAR CONFIGURACIÓN
            </LoadingButton>

            {/* <LoadingButton sx={styles.btnCancel} variant="contained" onClick={cancel}>
              cancelar
            </LoadingButton> */}
          </Box>
        )}

        {isEditing && (
          <Box sx={styles.buttons}>
            <LoadingButton loading={isLoading} sx={{ mt: 2, textTransform: 'uppercase' }} variant="contained" onClick={emitForm} disabled={(Object.keys(errors).length > 0 || !canSubmit) && !info}>
              EDITAR CONFIGURACIÓN
            </LoadingButton>

            <LoadingButton sx={styles.btnCancel} variant="contained" onClick={deleteConfig}>
              eliminar
            </LoadingButton>
          </Box>
        )}
      </Box>

      <BaseMenu sx={styles.border} anchorEl={openEvidences.anchorEl} setAnchorEl={() => closeMenu()} menuItems={openEvidences.menuItem} click={handleMenu} />

      <Drawer sx={styles.drawer} anchor="right" open={openEvidences.open}>
        <Box sx={styles.containerEvidences}>
          <Typography sx={styles.titleEvidences}>Configuración de evidencias adjuntas</Typography>

          {!!(Object.entries(newEvidence ?? {})?.length > 0) &&
            Object.entries(newEvidence ?? {})?.map(([key, item]) => (
              <>
                <Accordion key={key} expanded={expandedEvdence.includes(key)}>
                  <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={6}>
                        <FormControlLabel
                          sx={styles.formLabel}
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                          control={
                            <IOSSwitch
                              name={key}
                              size="medium"
                              checked={item.status}
                              onChange={({ target }) => {
                                if (info) return

                                setExpandedEvidence((prev) => (prev.includes(key) ? prev.filter((itemExpand) => itemExpand !== key) : [...prev, key]))
                                handleEvidenceChange({
                                  e: {
                                    target: {
                                      name: `${target.name}-status`,
                                      value: target.checked,
                                    },
                                  },
                                })
                              }}
                              sx={{ m: 1 }}
                            />
                          }
                          label={t(item.title)}
                        />
                      </Grid>
                      {!!item.status && (
                        <Grid item xs={6} textAlign="end">
                          <FormControlLabel
                            sx={styles.formLabel}
                            onClick={(e) => {
                              e.stopPropagation()
                            }}
                            control={
                              <Radio
                                onClick={() => {
                                  if (info) return

                                  handleEvidenceChange({
                                    e: {
                                      target: {
                                        name: `${key}-required`,
                                        value: !item.required,
                                      },
                                    },
                                  })
                                }}
                                checked={item.required}
                              />
                            }
                            label={t('general.required')}
                            labelPlacement="start"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </AccordionSummary>

                  <AccordionDetails sx={[styles.formLabel, styles.accordionDetail]}>
                    <Grid container rowSpacing={2}>
                      {!!item.checks && (
                        <Grid item xs={12} sx={styles.checks}>
                          {!!itHasErrorsCheck?.[key] && (
                            <Typography sx={styles.noSelected}>
                              <span>*</span> {t('general.validations.selectACheck')}
                            </Typography>
                          )}
                          <DinamicForm
                            disabled={info}
                            legacyErrors
                            input={Object.values(item.checks)}
                            onChange={({ target }) => {
                              if (info) return

                              handleEvidenceChange({
                                e: {
                                  target: {
                                    name: `${key}-${target.name}-checks`,
                                    value: target.value,
                                  },
                                },
                              })
                            }}
                          />
                        </Grid>
                      )}

                      {!!item.inputs && (
                        <Grid item xs={12} sx={styles.formLabel}>
                          <DinamicForm
                            disabled={info}
                            legacyErrors
                            input={Object.values(item.inputs)}
                            errors={handleErrors({ values: item.inputs })}
                            onChange={({ target }) => {
                              if (info) return

                              handleEvidenceChange({
                                e: {
                                  target: {
                                    name: `${key}-${target.name}-inputs`,
                                    value: target.value,
                                  },
                                },
                              })
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </>
            ))}
          <ButtonsDrawer
            accept={
              !info
                ? {
                    title: 'GUARDAR',
                    disabled: Object.values(itHasErrors ?? {})?.some((item) => item),
                    onClick: () => {
                      setEvidenceType(newEvidence)
                      setNewEvidenceCopy(newEvidence)
                      handleCloseDrawer()
                    },
                  }
                : undefined
            }
            cancel={{
              title: t('general.cancel'),
              onClick: () => {
                setNewEvidence(newEvidenceCopy)
                handleCloseDrawer()
              },
            }}
          />
        </Box>
      </Drawer>
    </>
  )
}

export default Form
