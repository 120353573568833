import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { Box, Button, ButtonProps } from '@mui/material'
import { Button as ButtonCustomeProps } from '../../../utils/types/informationCardProps'

const styles = {
  buttonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'flex-start',
    flex: 0.05,
    position: 'relative',
    top: '10px',
  },
  buttonContained: {
    fontSize: '12px',
    height: 'fit-content',
    maxWidth: '150px',
    wordBreak: 'break-word',
    '& .MuiTypography-root': {},
  },
  buttonOutlined: {
    fontSize: '10px',
    fontWeight: '500',
    position: 'absolute',
    right: '20px',
    '&.cancel-drawer': {
      fontSize: '12px',
    },
    paddingBottom: '15px',
  },
}
export const ButtonsDrawer = ({ accept, cancel, auxiliar, acceptProps, cancelProps }: { acceptProps?: LoadingButtonProps; cancelProps?: ButtonProps; accept?: ButtonCustomeProps; cancel?: ButtonCustomeProps; auxiliar?: ButtonCustomeProps }) => {
  
  if (accept || cancel)
    return (
      <Box sx={styles.buttonContainer} className='container-btn'>
        {accept && (
          <LoadingButton loading={accept?.loading} sx={[styles.buttonContained,]} variant={accept.type ?? 'contained'} onClick={() => accept?.onClick?.()} disabled={accept?.disabled} {...acceptProps ?? {}}>
            {accept?.title}
          </LoadingButton>
        )}

        {auxiliar && (
          <Button sx={styles.buttonContained} className="auxiliar-button" variant={auxiliar.type ?? 'text'} color="secondary" onClick={() => auxiliar?.onClick?.()}>
            {auxiliar?.title}
          </Button>
        )}

        {cancel && (
          <Button sx={styles.buttonOutlined} className="cancel-drawer" color="secondary" onClick={() => cancel?.onClick?.()} {...cancelProps ?? {}} >
            {cancel?.title}
          </Button>
        )}
      </Box>
    )
  else return null
}
