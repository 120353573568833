import { Box, Typography } from "@mui/material"
import { light } from "../../../../../../../utils/theme/colors/light"

const formatUnits = (payload: {
  styles?: Record<string, any>
  name: string;
  total: string;
  values: {
    total: string
  }
  percentages: {
    programming: string
    noProgramming: string
    total: string

  }
  programmed: string;
  noProgrammed: string
  colorProgrammed: {
    color: string;
    text: string;
  }
  customeLabels: {
    noProgramming: string
    programming?: string
  }
  colorNoProgrammed: {
    color: string;
    text: string;
  }
}) => {
  return {
    name: payload.name,
    customeData: {
      programming: (
        <Box sx={{ width: '100%' }}>
          <Typography
            sx={{
              color: light.color10,
              width: '100%',
              lineHeight: '2',
            }}
          >
            {payload.total}
          </Typography>

          <Box sx={payload.styles?.containerTitle}>
            <Typography
              sx={{
                color: light.color13,
                width: '100%',
              }}
            >
              {payload.programmed}
            </Typography>

            <Box
              sx={payload.styles?.percentageStatus?.({
                color: payload.colorProgrammed.color,
              })}
            />
          </Box>

          <Box sx={payload.styles?.containerTitle}>
            <Typography
              sx={{
                color: light.color13,
                width: '100%',
              }}
            >
              {payload.noProgrammed}
            </Typography>

            <Box
              sx={payload.styles?.percentageStatus({
                color: payload.colorNoProgrammed?.color,
              })}
            />
          </Box>
        </Box>
      ),
      total: <></>,
    },
    customeSettings: {
      total: payload.values.total,
      types: {
        programming: '1',
        noProgramming: '2',
      },
      remove: {
        total: true,
      },
    },
    customeLabels: {
      noProgramming: payload.customeLabels.noProgramming,
      programming: payload.customeLabels.programming,
    },
    programming: payload.percentages.programming,
    noProgramming: payload.percentages.noProgramming,
    total: payload.percentages.total,
  }
}

export { formatUnits }