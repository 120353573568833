import { useState, useEffect } from 'react'
import { Item } from '../../../../../utils/types/generals';
import { InputModel } from '../../../../../utils/types';
import { Roles } from '../../../../../utils/enums';
import { removeAllReference } from '../../../../../utils/methods/removedReference';

const useInput = ({ items }: { items?: { [x: string]: Item[] } }) => {
  const [input, setInput] = useState<{ [x: string]: InputModel }>({
    search: {
      label: 'schedule.filter.inputs.search',
      translate: true,
      name: 'search',
      rest: {
        type: 'alphaNumeric',
      },
      value: null,
    },
    createYear: {
      label: 'general.filterDate.year',
      translate: true,
      name: 'createYear',
      type: 'filter-select',
      items: items?.createYears ?? [],
      value: null,
      size: {
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      },
    },
    month: {
      label: 'general.filterDate.month',
      translate: true,
      name: 'month',
      type: 'filter-select',
      items: items?.monthYear ?? [],
      value: null,
      size: {
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      },
    },
    from: {
      label: 'general.tags.from',
      translate: true,
      name: 'from',
      type: 'date',
      value: null,
      rest: {
        max: 'to',
      },
      size: {
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      },
    },
    to: {
      label: 'general.tags.to',
      translate: true,
      name: 'to',
      type: 'date',
      value: null,
      rest: {
        min: 'from',
      },
      size: {
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      },
    },
    filterByCurrentWeek: {
      label: 'general.tags.filterByCurrentWeek',
      translate: true,
      name: 'filterByCurrentWeek',
      value: false,
      type: 'switch',
    },
    status: {
      label: 'general.tags.status',
      translate: true,
      name: 'status',
      type: 'filter-select',
      items: items?.status ?? [],
      value: null,
      size: {
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
      },
    },
    areas: {
      label: 'general.tags.area',
      translate: true,
      name: 'areas',
      type: 'filter-select',
      items: items?.areas ?? [],
      value: null,
      size: {
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
      },
    },
    departments: {
      label: 'general.tags.department',
      translate: true,
      name: 'departments',
      type: 'filter-select',
      items: items?.departments ?? [],
      value: null,
      size: {
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
      },
    },
    questionnaireId: {
      label: 'general.tags.questionnaire',
      translate: true,
      name: 'questionnaireId',
      type: 'filter-select',
      items: items?.questionnaireId ?? [],
      value: [],
    },
    percentage: {
      label: 'general.tags.percentage',
      translate: true,
      name: 'percentage',
      type: 'percentage',
      value: null,
    },
    userTypeId: {
      label: 'general.tags.userType',
      translate: true,
      name: 'userTypeId',
      type: 'filter-select',
      items: items?.userTypeId ?? [],
      value: [],
      notAllowedRoles: [Roles.AUDIT],
    },
    userId: {
      label: 'general.tags.user',
      translate: true,
      name: 'userId',
      type: 'filter-select',
      items: items?.userId ?? [],
      value: [],
      notAllowedRoles: [Roles.AUDIT],
    },
    roles: {
      label: 'general.tags.role',
      translate: true,
      name: 'roles',
      type: 'filter-select',
      items: items?.roles ?? [],
      value: null,
      notAllowedRoles: [Roles.STORE, Roles.AUDIT],
    },
    divisions: {
      label: 'general.tags.division',
      translate: true,
      name: 'divisions',
      type: 'filter-select',
      items: items?.divisions ?? [],
      value: null,
      notAllowedRoles: [Roles.AUDIT],
    },
  })

  const disabledInputs = (data: { inputs: string[]; value: boolean }) => {
    let copyInput: {
      [x: string]: InputModel
    } = removeAllReference(input)

    data?.inputs?.forEach((item) => {
      copyInput[item] = {
        ...copyInput[item],
        rest: {
          ...(copyInput[item]?.rest ?? {}),
          disabled: data.value,
        },
      }
    })

    setInput((prev) => ({
      ...prev,
      ...copyInput,
    }))
  }

  useEffect(() => {
    let body = {}
    Object.entries(input).forEach(([key, itemInputs]) => {
      body = { ...body, [key]: { ...itemInputs, items: items?.[key] } }
    })

    setInput(body)
    // eslint-disable-next-line
  }, [items])

  return { input, disabledInputs }
}

export { useInput }
