import { TFunction } from 'react-i18next'
import { getStatus } from '../../../../hooks/status'
import { divisionService } from '../../../../service/division'
import { ResponseDivision } from '../../../../service/division/response'
import { httpFetch } from '../../../../utils/crud'
import { Types } from '../../../../utils/enums'
import { handleAllErrors } from '../../../../utils/methods/handleErrors'
import { Color } from '../../../../utils/theme/types/color'
import { ObjectGeneric } from '../../../../utils/types/generals'
import { isRequired, searchInModeLower } from '../../../../utils/validate/validate'
import { format, getYear } from 'date-fns'
import { Constants } from '../../../../utils/constants'
import { es } from 'date-fns/locale'

export const getList = async (colors: Color, t: TFunction<'translation', undefined>) => {
  const data = await divisionService(httpFetch).getAllDivision()

  const column: any[] = [
    {
      id: 'description',
      label: t('general.tags.description'),
      sortable: true,
      minWidth:100

    },
    {
      id: 'cveDivision',
      label: t('general.tags.cveDivision'),
      sortable: true,
      minWidth:100

    },
    {
      id: 'createTime',
      label: t('general.tags.createTime'),
      sortable: true,
      minWidth:100

    },
    {
      id: 'status',
      label: t('general.tags.status'),
      minWidth:100

    },
  ]

  const row: any[] = !data?.errors
    ? data?.map((item) => {
        return {
          id: item?.id,
          description: item.description,
          cveDivision: item.cveDivision,
          status: getStatus(item?.statusId, colors, t),
          createTime: {
            label: item.create_time && format(new Date(item.create_time), Constants.dateFormatGeneral),
            value: item.create_time ? new Date(item.create_time).getTime() : 0,
            type: 'date',
          },
          create_time: item.create_time,
          createYear: getYear(new Date(item.create_time)),
          month: format(new Date(item.create_time), 'LLLL', { locale: es }),
          monthNumber: new Date(item.create_time).getMonth(),
          createDate: format(new Date(item.create_time), Constants.dateFormat2),
          createHour: format(new Date(item.create_time), Constants.hourFormat),
          udpateDate: format(new Date(item.update_time), Constants.dateFormat2),
          udpateHour: format(new Date(item.update_time), Constants.hourFormat),
        }
      })
    : []

  return { column, row }
}

export const filterRow = (value: { [x: string]: any }, list: any[]) => {
  const from = value.from?.valueOf()
  const to = value.to?.valueOf()
  return list?.filter((item) => {
    return (
      (from ? new Date(from).setHours(0, 0, 0, 0) <= Date.parse(item?.create_time) : true) &&
      (to ? new Date(to).setHours(23, 59, 59, 999) >= Date.parse(item?.create_time) : true) &&
      (value?.month instanceof Array ? (value?.month.length > 0 ? value?.month.find((e: any) => e.value === item.monthNumber) : true) : true) &&
      (value?.createYear instanceof Array ? (value?.createYear.length > 0 ? value?.createYear.find((e: any) => e.value === item.createYear) : true) : true) &&
      (value?.status instanceof Array ? (value?.status.length > 0 ? value?.status.find((e: any) => e.value === item.status.value) : true) : true) &&
      ((value?.search ? searchInModeLower(item?.description, value?.search) : true) || (value?.search ? searchInModeLower(item?.cveDivision, value?.search) : true))
    )
  })
}

export const handleErrors = (values: any, t: any, ignore?: string[]) => {
  let error = {} as ObjectGeneric

  if (values?.description !== null) {
    if (isRequired(values?.description)) error.description = t('general.validations.required')
  }
  if (values?.division !== null) {
    if (isRequired(values?.division)) error.division = t('general.validations.required')
  }

  if (values?.status !== null) {
    if (isRequired(values?.status)) error.status = t('general.validations.required')
  }
  return error
}

export const submit = async (data: ObjectGeneric, t: TFunction<'translation', undefined>, id: number | undefined) => {
  let snackbarBody = {
    open: false,
    type: '',
    message: '',
  }
  let body = {} as any
  Object.entries(data).forEach(([key, value]) => {
    const dataToSubmit = ['description', 'cveDivision', 'status']
    if (dataToSubmit.includes(key)) {
      body = {
        ...body,
        [key]: value ?? '',
      }
    }
  })

  let response: ResponseDivision

  body = {
    ...body,
    statusId: body.status || 1,
  }
  delete body.status
  if (id) {
    response = await divisionService(httpFetch).updateDivision(body, id)
  } else {
    response = await divisionService(httpFetch).createDivision(body)
  }

  if (response.errors) {
    snackbarBody = {
      open: true,
      type: Types.ERROR as string,
      message: handleAllErrors(response?.errors?.[0]?.code ?? '', t, `division.snackbar.${id ? 'update' : 'create'}.error`),
    }
  } else {
    snackbarBody = {
      open: true,
      type: Types.SUCCESS as string,
      message: t(`division.snackbar.${id ? 'update' : 'create'}.success`),
    }
  }

  return {
    snackbarBody,
    response,
  }
}
