import { Roles, Status } from '../../utils/enums'
import { Http } from '../../utils/types'
import { GetBlob, ObjectGeneric } from '../../utils/types/generals'
import { getUserRole } from '../auth'
import {
  StoreAllListResponse,
  StoreListBaseResponse,
  StoreListRWithError,
  UitsByPagination,
  UsersAssociated,
} from './response'

export const storesService = (client: Http) => {
  return {
    unitsList: async (data?: { query?: string }):Promise<UitsByPagination> => await client.get(`stores/detailed${data?.query ? '?' + data?.query : ''}`),
    getAllStores: async (): Promise<StoreAllListResponse> => {
      switch (getUserRole()) {
        case Roles.AGENT:
        case Roles.SUPPORT:
          return await client.get('companies/stores-associated')
        case Roles.STORE:
        case Roles.PARTNER:
          return []
        default:
          return await client.get('stores/filters/hierarchy')
      }
    },
    getUserAssociated: async ({ id }: {id:string | number}):Promise<UsersAssociated[]> => await client.get(`stores/${id}/users`),
    getStoresFilter: async (): Promise<StoreAllListResponse> => {
      return await client.get('report-folio/filters/stores')
    },
    getStoresById: async (id: number): Promise<StoreListBaseResponse> =>
      await client.get(`stores/${id}`),
    getStoresByCompanies: async (id: string): Promise<StoreAllListResponse> =>
      await client.get(`stores?companies=${id}&status=${Status.ACTIVE}`),
    getAllStoresWithAgents: async (): Promise<StoreListRWithError> => {
      switch (getUserRole()) {
        case Roles.AGENT:
        case Roles.AUDIT:
          return await client.get('companies/stores-associated')
        default:
          return await client.get('stores/users')
      }
    },
    createStore: async (body: ObjectGeneric): Promise<StoreListBaseResponse> =>
      await client.post('stores', body),
    importStores: async (body: ObjectGeneric): Promise<Response> =>
      await client?.blob?.(`stores/import`, body, true),
    generateTemplate: async (body: ObjectGeneric): Promise<Response> =>
      await client?.blob?.(`stores/generate-template`, body),
    updateStore: async (
      body: ObjectGeneric,
      id: number
    ): Promise<StoreListBaseResponse> => await client.put(`stores/${id}`, body),
    associateZonesToStores: async (body: ObjectGeneric) =>
      await client.post('stores/zones', body),
    deleteShop: async (id: number) => await client.delete(`stores/${id}`),
    downloadTemplate: async (data: { type: `pdf` | 'word' | 'excel', query?: string }): Promise<GetBlob> => await client.getBlob?.(`stores/generate-file?type=${data.type}${data?.query ? `${data?.query}` :  ''}`)
  }
}
