import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { SnackSimple } from '../../../../../../components'
import { useContextState } from '../../../../../../hooks/theme'
import { Types } from '../../../../../../utils/enums'
import { ObjectGeneric, onChange, onChangeEvent, Table } from '../../../../../../utils/types/generals'
import { initialData, initialFilterValues, initialTable, initialValues } from './initialState'
import { filterRow, getAllConfigs, getConfigById, handleErrors } from './methods'
import { useStyles } from './style'
import General from '../../../../../../components/modules/general'
import { icons } from '../../../../../../utils/icons'
import { Box, Button, Grid, Icon, IconButton, Tooltip, Typography } from '@mui/material'
// import { DinamicForm } from '../../../../../../components/dinamicForm'
import { useNavigate } from 'react-router-dom'
import Form from './../../components/form'
import Loader from '../../../../../../components/loader'
import SimpleModal from '../../../../../../components/modal/simpleModal'
import { reportFolioServices } from '../../../dashboard/pages/main/constants/states'
import DownloadFiles from '../../../../../../components/donwloadFiles'

const Setting = () => {
  const { t } = useTranslation()
  const { colors } = useContextState()
  const styles = useStyles(colors)
  const [data, setData] = useState<ObjectGeneric>({})
  const [btnLoading, setBtnLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [loadingDrawer, setIsloadingDrawer] = useState(false)
  const [flag, setFlag] = useState(false)
  const [allErrors, setAllErrors] = useState<any>({})
  const [table, setTable] = useState<Table>(initialTable)
  const [filterData, setFilterData] = useState(initialFilterValues)
  const [inputValues, setInputValues] = useState(initialValues)
  const navigate = useNavigate()
  const [evidences, setEvidences] = useState<any>()
  const [openModalDelete, setOpenModalDelete] = useState(false)
  // const [dataFilter, setDataFilter] = useState<ObjectGeneric>({})

  const [info, setInfo] = useState<any>()

  const [row, setRow] = useState<Table['row']>([])
  const [selected, setSelected] = useState<number>(-1)
  const [open, setOpen] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
    type: Types.SUCCESS as string,
  })

  const handleCloseSnackbar = (value: boolean) => setOpenSnackbar({ ...openSnackbar, open: value })

  const crudChange = async (e: onChangeEvent | string, extra: any): Promise<void> => {
    if (typeof e !== 'string') {
      const { name, value } = e?.target

      let body = {
        ...data,
        [name]: value?.value ?? value,
      }

      setData((prev) => ({ ...prev, ...body }))
      setAllErrors(handleErrors(body, t))
    }
  }

  const handleEdit = () => {
    navigate(`/dashboard/report-folio/settings/edit/${selected}`, { state: { evidences, inputValues, data, info, id: selected } })
  }

  const handleDelete = async () => {
    setOpenModalDelete(false)
    setBtnLoading(true)
    const response = await reportFolioServices.deleteConfig(selected)
    if (!response?.errors) {
      setOpenSnackbar((prev) => ({
        ...prev,
        open: true,
        message: 'La configuración se eliminó exitosamente',
        type: Types.SUCCESS,
      }))
    } else {
      setOpenSnackbar((prev) => ({
        ...prev,
        open: true,
        message: 'Ocurrió un error al eliminar la configuración',
        type: Types.SUCCESS,
      }))
    }
    setBtnLoading(false)
    getAllData()
  }

  const handleInformation = async (e: any, index: number) => {
    setSelected(index)
    openDrawer(true)
    setIsloadingDrawer(true)
    const response = await getConfigById(e.id)
    setIsloadingDrawer(false)
    setInfo(response)
    setEvidences(response.configActionPlanReportFolioEvidenceTypes ?? [])

    let body = {
      limitFolio: response?.max,
      maxRejections: response?.maxRejects,
      enableSurvey: response?.enableRatingQuestion,
      mandatorySurvey: response?.isRatingQuestionRequired,
      satisfactionQuestion: response?.ratingQuestion ?? '¿Cómo le pareció la gestión de este folio de reporte?',
    }

    let obj = { ...inputValues }

    if (body.limitFolio) {
      let keyValues = Object.entries(obj)
      const i = keyValues.findIndex((e) => e[0] == 'limitFolio')

      keyValues.splice(i + 1, 0, [
        'limitFoliosAmount',
        {
          label: `general.tags.quantity`,
          name: `limitFoliosAmount`,
          translate: true,
          value: response.max,
          validate: {
            required: true,
            number: true,
          },
          rest: {
            type: 'number',
            min: 1,
          },
          size: {
            xl: 4,
            lg: 4,
            md: 4,
            sm: 4,
            xs: 4,
          },
          customeErrors: {
            required: 'El campo cantidad es requerido',
          },
        },
      ])
      obj = Object.fromEntries(keyValues)
    } else {
      delete obj['limitFoliosAmount']
    }

    if (body.maxRejections) {
      let keyValues = Object.entries(obj)
      const i = keyValues.findIndex((e) => e[0] == 'maxRejections')

      keyValues.splice(i + 1, 0, [
        'maxRejectionsAmount',
        {
          label: `general.tags.quantity`,
          name: `maxRejectionsAmount`,
          translate: true,

          value: response.maxRejects,
          validate: {
            required: true,
            number: true,
          },
          rest: {
            type: 'number',
            min: 1,
          },
          size: {
            xl: 4,
            lg: 4,
            md: 4,
            sm: 4,
            xs: 4,
          },
          customeErrors: {
            required: 'El campo cantidad es requerido',
          },
        },
      ])

      obj = Object.fromEntries(keyValues)
    } else {
      delete obj['maxRejectionsAmount']
    }

    if (body.enableSurvey) {
      let keyValues = Object.entries(obj)
      const i = keyValues.findIndex((e) => e[0] == 'enableSurvey')

      keyValues.splice(i + 1, 0, [
        'mandatorySurvey',
        {
          label: 'reportFolio.setting.mandatorySurvey',
          translate: true,
          name: 'mandatorySurvey',
          type: 'switch',
          value: false,
        },
      ])
      keyValues.splice(i + 2, 0, [
        'satisfactionQuestion',
        {
          label: 'reportFolio.setting.satisfactionQuestion',
          translate: true,
          name: 'satisfactionQuestion',
          type: 'textArea',
          value: null,
          validate: {
            required: true,
          },
          customeErrors: {
            required: 'El campo pregunta de satisfacción al cliente es requerido',
          },
        },
      ])

      obj = Object.fromEntries(keyValues)
    } else {
      delete obj['mandatorySurvey']
      delete obj['satisfactionQuestion']
    }

    setInputValues(obj)
    setData((prev) => ({ ...prev, ...body }))
  }

  const getConfigList = useCallback(async () => {
    resetValues()
    const reponse = await getAllConfigs(colors, t)
    const { column, row } = reponse

    const newRow = row.map((item, i) => {
      return {
        ...item,
        area: {
          start: true,
          children: !!item?.areas ? (
            <Grid
              container
              justifyContent="left"
              spacing={1}
              onClick={() => {
                handleInformation(item, item.id)
              }}
            >
              <Grid item sx={{ color: '#828282' }}>
                <Typography sx={styles.textItem}>{item?.areas.length ? <span>{item?.areas[0].name}</span> : null}</Typography>
              </Grid>

              {!!(item?.areas.length && item?.areas.length > 1) && (
                <Grid sx={styles.containerIconTooltip} item xl={1} lg={1} md={1} sm={1} xs={1}>
                  <Tooltip
                    title={
                      <Box>
                        <Box sx={styles.containerTooltipAsk}>
                          <Box sx={styles.listAnswers}>
                            {item?.areas?.map((itemO: any) => (
                              <Typography sx={styles.textItem}>{itemO?.name}</Typography>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    }
                  >
                    <Icon sx={styles.viewMore}>{icons('add')}</Icon>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          ) : (
            <Typography sx={styles.bodyText}>{t('general.no')}</Typography>
          ),
        },
      }
    })

    setTable({ column, row: newRow })
    setRow(newRow)
    // getAreasService()
    // getDeparmetsService()
    setIsLoading(false)
    setFlag(true)
  }, [colors, t])

  const getAllData = useCallback(async () => {
    setIsLoading(true)
    getConfigList()
  }, [getConfigList])

  const filterChange: onChange = ({ target }) => {
    const { name, value } = target
    const body = { ...filterData, [name]: value }
    setFilterData(body)
    setRow(filterRow(body, table.row))
  }

  const deleteConfig = () => setOpenModalDelete(true)

  const resetValues = () => {
    setOpen(false)
    setData(initialData)
    setBtnLoading(false)
    setSelected(-1)
    setFilterData(initialFilterValues)
  }
  const openDrawer = (edit?: boolean) => {
    if (!edit) {
      setData(initialData)
    }
    setOpen(true)
  }

  const closeDrawer = () => {
    setOpen(false)
    setSelected(-1)
  }

  const handleCreate = () => {
    navigate('/dashboard/report-folio/settings/create')
  }

  useEffect(() => {
    if (!flag) {
      getAllData()
    }
  }, [getAllData, flag])

  return (
    <>
      <SnackSimple close={handleCloseSnackbar} open={openSnackbar.open} type={openSnackbar.type} message={openSnackbar.message} />

      <General
        newHeader={
          <Box sx={styles.header}>
            <Typography sx={styles.title}>Configuración de Folios de Reporte</Typography>

            <Box sx={styles.flex}>
              <DownloadFiles
                row={row}
                column={table.column}
                name={'Configuración de Folios de Reporte'}
                format={{
                  orientation: 'landscape',
                  size: 'a4',
                }}
                csvText={'EXCEL'}
                removeMargin
                customeHeader={[
                  {
                    id: 'areasTable',
                    label: t('general.tags.area'),
                  },
                  {
                    id: 'departments',
                    label: t('reportFolio.setting.departments'),
                  },
                  {
                    id: 'createdBy',
                    label: t('reportFolio.setting.updatedBy'),
                  },
                  {
                    id: 'createTime',
                    label: t('general.tags.updateTime'),
                  },
                ]}
              />

              <IconButton
                sx={styles.clean}
                onClick={() => {
                  handleCreate()
                }}
              >
                {icons('add')}
              </IconButton>
            </Box>
          </Box>
        }
        filter={{
          filterData: filterData,
          onChange: filterChange,
          title: 'Configuración de Folios de Reporte',
          subTitle: t('user.filter.filterTitle'),
          add: {
            onClick: handleCreate,
            icon: icons('add'),
          },
        }}
        open={open}
        rightDrawer={{
          form: (
            <>
              {!loadingDrawer && (
                <Form
                  btnLoading={btnLoading}
                  data={data}
                  info={info}
                  input={Object.values(inputValues)}
                  crudChange={crudChange}
                  allErrors={allErrors}
                  cancel={closeDrawer}
                  handleEdit={handleEdit}
                  evidences={evidences}
                  isEditing
                  submit={handleEdit}
                  deleteConfig={deleteConfig}
                />
              )}
              {loadingDrawer && <Loader />}

              <Box
                sx={{
                  textAlign: 'right',
                  paddingTop: '10px',
                }}
              >
                <Button variant="text" sx={{ fontSize: '12px' }} onClick={closeDrawer}>
                  Cancelar
                </Button>
              </Box>
            </>
          ),
          crud: true,
          clickMenu: () => {},
          title: '',
          autoHeight: true,
        }}
        table={{
          column: table.column,
          row: row,
          title: 'Configuración de Folios de Reporte',
          onClick: handleInformation,
          loadingTable: isLoading,
          selected,
          document: {
            export: true,
            header: [
              {
                id: 'id',
                label: t('general.tags.year'),
              },
              {
                id: 'areasTable',
                label: t('general.tags.area'),
              },
              {
                id: 'departments',
                label: t('general.tags.departments'),
              },
              {
                id: 'createdBy',
                label: t('reportFolio.setting.updatedBy'),
              },
              {
                id: 'createTime',
                label: t('general.tags.updateTime'),
              },
            ],
          },
        }}
      />

      <SimpleModal
        loadingButton
        isLoading={btnLoading}
        open={openModalDelete}
        close={() => {
          setOpenModalDelete(false)
        }}
        title={'¿Está seguro que desea eliminar esta configuración?'}
        description={''}
        buttonText={t('general.confirm')}
        cancelText={t('general.cancel')}
        color={colors}
        back={() => {
          setOpenModalDelete(false)
        }}
        next={handleDelete}
      />
    </>
  )
}

export default Setting
