import { Navigate, Route, Routes } from 'react-router-dom'
import Area from '../pages/area'
import { FormDepartment } from '../pages/departments/pages/form'
import Department from '../pages/departments/pages/main'

const OrganizationChart = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="area" />} />
      <Route path="area" element={<Area />} />
      <Route path="departments" element={<Department />} />
      <Route path="departments/create" element={<FormDepartment />} />
      <Route path="departments/edit/:id" element={<FormDepartment />} />
    </Routes>
  )
}

export default OrganizationChart
