import {
  FilterAltOff,
  Cached,
  Search,
  NotificationsOutlined,
  FaceOutlined,
  HomeOutlined,
  BarChartOutlined,
  DescriptionOutlined,
  PeopleAltOutlined,
  EventNoteOutlined,
  SettingsOutlined,
  ArrowDropDown,
  ArrowDropUp,
  AddCircleOutlined,
  Remove,
  Menu,
  Edit,
  HighlightOff,
  ContentCopy,
  ArrowForward,
  ArrowForwardIos,
  Close,
  ArrowRight,
  ExpandMore,
  Delete,
  CheckCircle,
  AddBoxRounded,
  Folder,
  AdminPanelSettings,
  ArrowBack,
  CorporateFare,
  Store,
  Visibility,
  VisibilityOff,
  Block,
  Stop,
  FileDownload,
  FileUpload,
  FormatColorFill,
  QuestionMark,
  ErrorOutline,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  ForwardToInbox,
  EventAvailable,
  FilterAlt,
  AppRegistration,
  ImportExport,
  PostAdd,
  FormatListBulleted,
  ManageAccounts,
  WarningAmber,
  PestControl,
  Source,
  MoreHoriz,
  NotificationsRounded,
  AccountTree,
  LocationOn,
  Email,
  Phone,
  AddCircleOutline,
  PermContactCalendar,
  Timeline,
  ErrorOutlineOutlined,
  PieChart
} from '@mui/icons-material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartArea, faDownLeftAndUpRightToCenter, faMapLocationDot, faPaperclip, faTable, faUserTie } from '@fortawesome/free-solid-svg-icons'
import { faFilePdf } from '@fortawesome/free-regular-svg-icons'
import Check from './components/check'

const generalIconsObject: Record<string, JSX.Element> = {
  pie: <PieChart />,
  location: <LocationOn />,
  info: <ErrorOutlineOutlined />,
  email: <Email />,
  phone: <Phone />,
  tree: <AccountTree />,
  moreHoriz: <MoreHoriz />,
  source: <Source />,
  generatePdf: <i className="fa fa-file"></i>,
  pdf: <FontAwesomeIcon icon={faFilePdf} />,
  table: <FontAwesomeIcon icon={faTable} size="sm" />,
  accountTie: <FontAwesomeIcon icon={faUserTie} size="sm" />,
  map: <FontAwesomeIcon icon={faMapLocationDot} size="sm" vertAdvY={0} />,
  bug: <PestControl />,
  warning: <WarningAmber />,
  clip: <FontAwesomeIcon icon={faPaperclip} rotation={270} size="sm" />,
  chart: <FontAwesomeIcon icon={faChartArea} />,
  shrink: <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} size="sm" />,
  schedule: <EventAvailable />,
  error: <ErrorOutline />,
  paint: <FormatColorFill />,
  download: <FileDownload />,
  upload: <FileUpload />,
  stop: <Stop />,
  block: <Block />,
  visibility: <Visibility />,
  store: <Store />,
  company: <CorporateFare />,
  admin: <AdminPanelSettings />,
  folder: <Folder />,
  check: <CheckCircle />,
  delete: <Delete />,
  expand: <ExpandMore />,
  copy: <ContentCopy />,
  close: <HighlightOff />,
  edit: <Edit />,
  menu: <Menu />,
  clean: <FilterAltOff />,
  add: <AddCircleOutlined />,
  refresh: <Cached />,
  search: <Search />,
  bell: <NotificationsOutlined />,
  home: <HomeOutlined />,
  paper: <DescriptionOutlined />,
  user: <PeopleAltOutlined />,
  calendar: <EventNoteOutlined />,
  setting: <SettingsOutlined />,
  filter: <FilterAlt />,
  order: <AppRegistration />,
  upDown: <ImportExport />,
  postAdd: <PostAdd />,
  list: <FormatListBulleted />,
  timeline: <Timeline />,
  'schedule-user': <PermContactCalendar />,
  'check-status': <Check />,
  'bell-rounded': <NotificationsRounded />,
  'action-plan': <QuestionMark />,
  'send-email': <ForwardToInbox />,
  'manage-account': <ManageAccounts />,
  'arrow-r': <KeyboardArrowRight />,
  'arrow-l': <KeyboardArrowLeft />,
  'arrow-double-r': <KeyboardDoubleArrowRight />,
  'arrow-double-l': <KeyboardDoubleArrowLeft />,
  'visibility-off': <VisibilityOff />,
  'add-rounded': <AddBoxRounded />,
  'arrow-right-simple': <ArrowForwardIos />,
  'arrow-right': <ArrowForward />,
  'arrow-right-fill': <ArrowRight />,
  'arrow-left-fill': <ArrowBack />,
  'simple-close': <Close />,
  'horizontal-line': <Remove />,
  'arrow-down': <ArrowDropDown />,
  'arrow-up': <ArrowDropUp />,
  'man-face': <FaceOutlined />,
  'bar-chart': <BarChartOutlined />,
  'add-outline': <AddCircleOutline />,
}

export const icons = (name: string) => generalIconsObject[name]
