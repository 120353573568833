import { Roles as Role } from '../../utils/enums'
import { getUserRole } from '../auth'
import { Roles } from './response'

const listRoles = [
  {
    "id": 1,
    "description": "Administrador"
  },
  {
    "id": 5,
    "description": "Gerente"
  },
  {
    "id": 2,
    "description": "Supervisor"
  },
  {
    "id": 3,
    "description": "Agente"
  },
  {
    "id": 6,
    "description": "Soporte"
  },
  {
    "id": 7,
    "description": "Auditor"
  },
  {
    "id": 4,
    "description": "Tienda"
  },
  {
    "id": 8,
    "description": "Colaborador"
  }
]
export const RolesService = () => ({
  rolseList: async (): Promise<Roles> => {
    const role = getUserRole()
    const roles = listRoles

    switch (role) {
      case Role.GERENT:
        return { roles: (roles as Roles['roles']).filter((item) => ![Role.ADMIN, Role.GERENT].includes(item.id)) }
      case Role.SUPERVISOR:
        return { roles: (roles as Roles['roles']).filter((item) => ![Role.ADMIN, Role.GERENT, Role.SUPERVISOR].includes(item.id)) }
      case Role.AGENT:
      case Role.SUPPORT:
      case Role.AUDIT:
        return { roles: (roles as Roles['roles']).filter((item) => ![Role.ADMIN, Role.GERENT, Role.SUPERVISOR, Role.AGENT, Role.SUPPORT, Role.AUDIT].includes(item.id)) }
      case Role.STORE:
        return { roles: (roles as Roles['roles']).filter((item) => ![Role.ADMIN, Role.GERENT, Role.SUPERVISOR, Role.AGENT, Role.SUPPORT, Role.AUDIT, Role.STORE].includes(item.id)) }
      case Role.PARTNER:
        return {
          roles: [{
            id: Role.PARTNER,
            "description": "Colaborador"
          }]
        }
      default:
        return { roles }
    }
  },
})
