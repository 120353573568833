import { useState, useLayoutEffect, useEffect } from 'react'
import { Box } from '@mui/material'
import Header from './header'
import Aside from './aside'
import { useStyles } from './styles'
import { useContextState } from '../../hooks/theme'
import { useListItem } from './aside/items'
import { Props } from './types'
import { useLocation, useNavigate } from 'react-router-dom'
import { getLocalStorage, httpFetch, setLocalStorage } from '../../utils/crud'
import Loader from '../loader'
import { Auth } from '../../service'
import { canAccess } from '../../utils/validate/canaccess'
import { Items } from './aside/types'
import { getUserRole } from '../../service/auth'
import { Roles } from '../../utils/enums'
import { QuestionnaireLocale } from '../../pages/questionnaire/pages/main/typing/enums'
import { userServiceFetch } from '../../utils/constants/callService'
import { TypeGlobalEnum } from '../../utils/enums/globalContextEnum'

const state = getLocalStorage(QuestionnaireLocale.QUESTIONNAIRE)
const Layout = ({ children }: Props) => {
  const { dispatch, state: stateContext, colors } = useContextState()
  const role = getUserRole()
  const location = useLocation()
  const [open, setOpen] = useState(true)
  const [show, setShow] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)
  const listItems = useListItem()
  const navigate = useNavigate()
  // const isSupervisor = canAccess([Roles.SUPERVISOR])
  const listAccess = listItems?.filter((item) => canAccess(item.access ?? []))
  const path = location.pathname.replace('/', '')
  const getPath = listAccess.findIndex((item) => {
    if (item.children) {
      return item.children.findIndex((child) => path.includes(child.path)) >= 0
    } else {
      if (path === item.path) {
        return path === item.path
      } else {
        return item.extraPaths?.some((item) => path.includes(item))
      }
    }
  })
  
  const allowedRoute = () => {
    let finded = false
    listAccess.forEach((item) => {
      if (item.children) {
        //eslint-disable-next-line
        item.children.find((e) => {
          const { notAllowedRoles } = e
          if (path.includes(e.path) && !notAllowedRoles?.find((e) => e == role)) finded = true
        })
      } else if (path.includes(item.path)) finded = true
    })
    return finded
  }

  const [position, setPosition] = useState(getPath >= 0 ? getPath : 0)

  const verifyToken = (pathName: string, index: number) => {
    if (pathName) {
      if (path !== pathName) {
        setIsLoading(true)
        Auth(httpFetch)
          .decodeJWT({ token: getLocalStorage()?.token ?? '' })
          .then(() => { setIsLoading(false) })
          .catch(() => { })
      }
      setPosition(index)
      navigate(pathName)
    } else {
      setOpen(true)
    }
  }

  const filterSubMenu = (menu: Items[]) => {
    let newMenu = [...menu]
    newMenu.forEach((e) => {
      const { children } = e
      let newChildren: Items[] = []
      if (children) {
        children.forEach((child) => {
          const { notAllowedRoles } = child
          if (!(notAllowedRoles && notAllowedRoles.includes(role as Roles))) newChildren.push(child)
        })
        e.children = newChildren
      }
    })

    return newMenu
  }

  const styles = useStyles(colors, open)

  useLayoutEffect(() => {
    setIsLoading(true)

    Auth(httpFetch)
      .decodeJWT({ token: getLocalStorage()?.token ?? '' })
      .then((user) => {
        setIsLoading(false)
        
        const state = getLocalStorage('state')
        if (!state.in) {
          userServiceFetch.userById(user?.data?.data?.userId)
            .then((response) => {
              const body = {
                curp: response.curp,
                role: response.roleId,
                picture: response.picture,
                userType: response.userType
              }
              setLocalStorage({
                name: 'state',
                body: {
                  ...state ?? {},
                  ...body
                }
              })

              dispatch({
                type: TypeGlobalEnum.SAVE_STATE, payload: {
                  ...stateContext,
                  ...body
                }
              })
            })
        } else {
          setLocalStorage({
            name: 'state',
            body: {
              ...state ?? {},
              in: false
            }
          })
        }
      })
  }, [])

  useEffect(() => {
    if (!allowedRoute()) {
      if (!!listAccess?.[0]?.path) {
        navigate(listAccess[0].path)
      }
    }
  }, [])

  useEffect(() => {
    setPosition(getPath >= 0 ? getPath : 0)
    if (!location.pathname.includes('questionnarie') && !!state) {
      localStorage.removeItem(QuestionnaireLocale.QUESTIONNAIRE)
    }
    // eslint-disable-next-line
  }, [location.pathname])
  return (
    <Box sx={styles.container}>
      {/* Header */}

      <Box sx={styles.header}>
        <Header
          openMenu={() => {
            setOpen(!open)
          }}
        />
      </Box>
      {/* Body */}
      <Box sx={styles.body} >
        <Box sx={styles.content}>{isLoading ? <Loader /> : children}</Box>
      </Box>
      {/* Drawer */}
      <Box sx={styles.menuSmall}>
        <Aside handleChange={verifyToken} open={open} setOpen={setOpen} pathList={filterSubMenu(listAccess)} position={position} show={show} setShow={setShow} variant="persistent" />
      </Box>
      <Box sx={styles.menuLg}>
        <Aside handleChange={verifyToken} open={false} setOpen={setOpen} pathList={filterSubMenu(listAccess)} position={position} show={show} setShow={setShow} />

        <Box sx={styles.showAside}>
          <Aside handleChange={verifyToken} open={true} setOpen={setOpen} pathList={filterSubMenu(listAccess)} position={position} show={show} setShow={setShow} />
        </Box>
      </Box>
    </Box>
  )
}

export default Layout
