import { EvidencesTypeValueEnum } from "../enums/evidences";

const newOrederEvidences: Record<string, any> = {
    [EvidencesTypeValueEnum.IMAGE]: 1,
    [EvidencesTypeValueEnum.CAMERA]: 2,
    [EvidencesTypeValueEnum.VIDEO]: 3,
    [EvidencesTypeValueEnum.VIDEO_GALLERY]: 4,
    [EvidencesTypeValueEnum.DOCUMENT]: 5,
    [EvidencesTypeValueEnum.SIGN]: 6,
    [EvidencesTypeValueEnum.COMMENT]: 7,
}

export { newOrederEvidences }