import { useState, useEffect, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { initialData, initialFilterValues, initialTable, initialValues, input, useMenuItems } from './initialState'
import { getStoresList, submit, formatAssociateUserTable, filterRowUser } from './methods'
import { useStyles } from './style'
import Form from './components/form'
import { useContextState } from '../../../../hooks/theme'
import { InformativeCard, InputModel } from '../../../../utils/types'
import { Item, ObjectGeneric, onChangeEvent, Table } from '../../../../utils/types/generals'
import { CRUD, Roles, Status, Types } from '../../../../utils/enums'
import { statusService, UserService } from '../../../../service'
import { MassiveUpload, SnackSimple } from '../../../../components'
import { generalDisabled } from '../../../../utils/validate/validate'
import { httpFetch } from '../../../../utils/crud'
import { zipCodeService } from '../../../../service/zipcode'
import { images } from '../../../../utils/images'
import { storesService } from '../../../../service/stores'
import General from '../../../../components/modules/general'
import { icons } from '../../../../utils/icons'
import { UploadForm } from './components/uploadForm'
import { FiltersDrawer } from '../../../../components/filtersDrawer'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { DinamicForm } from '../../../../components/dinamicForm'
import { getPreviousYears, monthYear } from '../../../../utils/constants/date_month'
import SimpleModal from '../../../../components/modal/simpleModal'

import ModalCrudTable from '../../../../components/modalTableWithFilter'
import BaseModal from '../../../../components/modal/baseModal'
import { GlobalContext } from '../../../../contexts/Global'
import { getDivisions, getZones } from '../../../../utils/methods/geServiceWithItemsFormat'
import { useFilter } from '../../../../hooks/filter'
import { ModelDownloadButtons } from '../../../../components/donwloadFiles/model'
import { useDownload } from '../../../../hooks/download/useDownload'
import { returnQuery, returnQueryWithPagination } from '../../../../utils/methods/returnQuery'
import { companiesServiceFetch, storeServices, userServiceFetch } from '../../../../utils/constants/callService'
import { formData } from './constants'
import { removeAllReference } from '../../../../utils/methods/removedReference'
import { FilterProps } from '../../../../hooks/filter/typing'
import Loader from '../../../../components/loader'

const Shop = () => {
  const { state } = useContext(GlobalContext)
  const { role } = state
  const { t } = useTranslation()
  const { colors } = useContextState()
  const styles = useStyles(colors)
  const [inputValues, setInputValues] = useState(initialValues)
  const [loadMassiveActive, setLoadMassiveActive] = useState(false)
  const [information, setInformation] = useState<InformativeCard | null>(null)
  const [data, setData] = useState<ObjectGeneric>({})
  const [btnLoading, setBtnLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [flag, setFlag] = useState(false)
  const [table, setTable] = useState<Table>(initialTable)
  const [row, setRow] = useState<Table['row']>([])
  const [fatherList, setFatherList] = useState<any[]>([])
  const [loadindDetail, setLoadindDetail] = useState(false)
  const [drawerStatus, setDrawerStatus] = useState<any>()
  const [selected, setSelected] = useState<number>(-1)
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
    type: Types.SUCCESS as string,
  })

  const [totalPages, setTotalPages] = useState(0)
  const externalValidation: FilterProps['externalValidation'] = ({ name, value, body, setFilterData }) => {
    let copyBody: Record<string, any> = removeAllReference(body)
    if (name === 'zones') {
      const newChildZone = copyBody?.class?.filter((e: any) => value.find((e2: any) => e2.value == e.father))

      const classes = childZones?.filter((itemClass) => value?.some((itemZone: any) => itemZone?.value == itemClass?.father))
      setInputs((prev) => ({
        ...prev,
        class: {
          ...prev.class,
          items: classes,
        },
      }))
      setFilterData((prev) => ({
        ...prev,
        class: newChildZone,
      }))

      copyBody = {
        ...copyBody,
        class: newChildZone,
      }
    }

    const currentWeekValue = name === 'filterByCurrentWeek' ? value : body?.filterByCurrentWeek

    if (!!currentWeekValue) {
      delete copyBody.createYear
      delete copyBody.month
      delete copyBody.from
      delete copyBody.to
    }
    return copyBody
  }

  const { counter, errors, filterData, paginationModel, query, filterString, sortTable, handlePagination, onChange, cleanFilter } = useFilter({
    formatFilter: {
      search: 'search',
      from: 'start_date',
      to: 'end_date',
      createYear: 'year',
      month: 'month',
      zones: 'zone_ids',
      divisions: 'division_ids',
      class: 'class_ids',
      users: 'user_manage_ids',
    },
    externalValidation
  })

  const {
    download,
    openSnackbar: snackbarDownloadModal,
    downloadCSV,
    downloadPDF,
    downloadWORD,
    closeOpenSnakbar,
  } = useDownload({
    docName: t('stores.filter.title'),
    query: returnQueryWithPagination({ filter: filterString, sort: query.sort }, formData),
    downloadFuntion: storeServices.downloadTemplate,
  })

  const [manageButton, setManageButton] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [tableAssociateUser, setTableAssociateUser] = useState<Table>({ column: [], row: [] })
  const [isLoadingUserAssociated, setIsLoadingUserAssociated] = useState(false)
  const menuList = useMenuItems(styles)
  const [menu, setMenu] = useState<
    {
      label: string
      icon?: any
      value?: any
    }[]
  >(menuList)
  const [childZones, setChildZones] = useState<any[]>([])

  // const { search, status } = useFilterDetail(t)
  const [open, setOpen] = useState(false)
  const [crud, setCrud] = useState(false)
  const [drawerDetail, setDrawerDetail] = useState({
    title: '',
    accept: '',
    cancel: '',
  })

  const [modalDataShop, setModalDataShop] = useState({
    open: false,
    status: 0,
  })
  const [openSnackbarSome, setOpenSnackbarSome] = useState({
    open: false,
    message: '',
    type: Types.SUCCESS as string,
  })
  const [inputs, setInputs] = useState<{ [x: string]: InputModel }>(input)

  const handleCloseSnackbar = (value: boolean) => {
    setOpenSnackbar({ ...openSnackbar, open: value })
    setOpenSnackbarSome({ ...openSnackbarSome, open: value })
  }

  const getCityByID = async (id?: number) => {
    const response = id ? await zipCodeService(httpFetch).cityList(id) : []
    const existCity = response?.find(item => item.id == data.city)
    if(!existCity) {
      setData(prev => ({...prev, city: undefined}))
    }
    setInputValues((prev) => ({
      ...prev,
      city: {
        ...prev.city,
        items: !response?.errors
          ? response?.map((item) => ({
            label: item.country,
            value: item.id,
          }))
          : [],
      },
    }))
    return true
  }

  const handleSubmit = async () => {
    setBtnLoading(true)
    setDrawerStatus(null)
    const response = await submit(data, t, information?.id)
    setOpenSnackbar(response.snackbarBody)
    if (!response?.response?.errors) {
      getAllUsers()
    } else {
      setBtnLoading(false)
    }
  }

  const handleTableStore = async () => {
    setIsLoadingUserAssociated(true)
    if (!!information?.id) {
      const tableStore = await formatAssociateUserTable(t, information?.id)

      setTableAssociateUser(tableStore)
    }
    setIsLoadingUserAssociated(false)
  }
  const handleInformation = async (e: any, index: number) => {
    setSelected(index)
    openDrawer(true)
    setManageButton(true)
    setDrawerStatus(null)
    setLoadindDetail(true)
    const responseUnitDetail = await storeServices.getStoresById(e?.id)

    const
      name = responseUnitDetail.name,
      email = responseUnitDetail.email,
      userMAnage = responseUnitDetail?.userManage,
      phone = responseUnitDetail.phone,
      company = responseUnitDetail?.company,
      address = responseUnitDetail.address,
      latitude = responseUnitDetail.latitude,
      longitude = responseUnitDetail.longitude,
      zipCode = responseUnitDetail.zipCode,
      keyCode = responseUnitDetail?.keyCode,
      divisionId = responseUnitDetail.divisionId,
      divisionName = responseUnitDetail.divisionName,
      score = responseUnitDetail.score,
      picture = responseUnitDetail.picture

    const
      zoneFather = responseUnitDetail?.storeZone,
      zoneClass = responseUnitDetail?.storeClass

    let data: any[] = [
      {
        label: t(`general.tags.store_name`),
        value: name,
        name: 'store_name',
      },
      {
        label: t(`general.tags.email`),
        value: email,
        name: 'email',
        copy: true
      },
      {
        label: t(`general.tags.userManage`),
        value: userMAnage ? `${userMAnage?.firstName} ${userMAnage?.lastName}` : null,
        name: 'userManage',
      },
      {
        label: t(`general.tags.phone`),
        value: phone,
        name: 'phone',
      },
      {
        label: t(`general.tags.company`),
        value: company?.name,
        name: 'company',
      },
      {
        label: t(`general.tags.address`),
        value: address,
        name: 'address',
      },
      {
        label: t(`general.tags.latitude`),
        value: latitude,
        name: 'latitude',
      },
      {
        label: t(`general.tags.longitude`),
        value: longitude,
        name: 'longitude',
      },
      {
        label: t(`general.tags.zipCode`),
        value: zipCode ? `${zipCode?.cp} - ${zipCode?.settlement}` : null,
        name: 'zipCode',
      },
      {
        label: t(`general.tags.id`),
        value: keyCode,
        name: 'id',
      },
      {
        label: t(`general.tags.division`),
        value: divisionName,
        name: 'division',
      },
      {
        label: t(`general.tags.zone`),
        value: zoneFather?.name,
        name: 'zone',
      },
      {
        label: t(`general.tags.class`),
        value: zoneClass?.name,
        name: 'class',
      },
    ]

    setData((prev) => ({
      ...prev,
      picture,
      userManageId: userMAnage?.id,
      name,
      email,
      companyId: company?.id,
      score: score?.toString(),
      phone,
      states: zipCode?.codState,
      city: zipCode?.id,
      status: responseUnitDetail?.statusId,
      latitude,
      longitude,
      address,
      keyCode,
      divisions: divisionId,
      fatherZone: zoneFather?.id,
      class: zoneClass?.id
    }))

    if (e) {
      setMenu(menuList?.filter((item) => item.value !== e.status?.value))
      setInformation({
        data,
        tmpData: data,
        ...!!e?.status ? {
          status: {
            color: e.status.color,
            text: e.status.text,
            value: e.status?.value,
          }
        } : {},
        image: responseUnitDetail?.picture || images.store,
        id: e.id,
      })
    }

    setLoadindDetail(false)
  }

  const openDrawer = (edit?: boolean) => {
    setManageButton(false)
    setDrawerStatus(null)
    setDrawerDetail({
      title: t(`stores.informationCard.${edit ? 'edit' : 'create'}.title`),
      accept: t(`stores.informationCard.buttons.${edit ? 'edit' : 'add'}`),
      cancel: t('userType.informationCard.buttons.cancel'),
    })
    if (!edit) {
      setData(initialData)
    }
    setInputValues(prev => ({
      ...prev,
      class: {
        ...prev.class,
        items: [],
      },
      fatherZone: {
        ...prev.fatherZone,
        items: [],
      },
    }))
    setInformation(null)
    setCrud(!edit)
    setOpen(true)
  }

  const goLoadMassive = () => {
    setLoadMassiveActive(true)
  }

  const closeDrawer = () => {
    setOpen(false)
    setCrud(false)
    setSelected(-1)
  }

  const handleMenu = async (item: any) => {
    setDrawerStatus(item.value)
    setManageButton(false)
    switch (item.value) {
      case CRUD.EDIT:
        if (information?.id) {
          setCrud(true)
          setBtnLoading(true)

          if (data.states) {
            getCityByID(data.states)
          }

          getCompanyById(data.companyId, data?.fatherZone)
        }
        break
      case CRUD.DELETE:
        setModalDataShop({
          status: selected,
          open: true,
        })
        break
      default:
        break
    }
  }
  const crudChange = async (e: onChangeEvent | string): Promise<void> => {
    if (typeof e !== 'string') {
      const { name, value } = e?.target

      let body = {
        ...data,
        [name]: name === 'score' && value === '-' ? '0' : value?.value ?? value,
      }

      if (name === 'states') {
        const responseCitites = await getCityByID(value?.value)
        if (responseCitites) {
          body = { ...body, zipCodeId: null, city: null }
        }
      } else if (name === 'city') {
        body = { ...body, zipCodeId: value?.value }
      } else if (name === 'companyId') {
        body = {
          ...body,
          divisions: null,
          fatherZone: null,
          class: null,
          userManageId: null,
        }
        getCompanyById(value?.value)
      } else if (name === 'fatherZone') {
        body = {
          ...body,
          [name]: value?.value,
          class: null,
        }

        const inpustFatherZone = fatherList?.map?.((item) => item.value)
        setInputValues((prev) => ({
          ...prev,
          class: {
            ...prev.class,
            items:
              childZones
                .filter((item) => item.father === value?.value && !!inpustFatherZone?.includes(item.value))
          },
        }))
      }

      setData((prev) => ({ ...prev, ...body }))
    }
  }

  const getCompanyById = async (id?: number | null, fatherZone?: number) => {
    if (id) {
      const responseStoreManagers = await UserService(httpFetch).userByIds(id.toString(), Roles.STORE.toString())
      const responseCompany = await companiesServiceFetch.getCompanyById(id)
      let fatherL: any[] = []
      if (!responseStoreManagers.errors) {
        setInputValues((prev) => ({
          ...prev,
          userManageId: {
            ...prev.userManageId,
            items:
              responseStoreManagers?.length > 0
                ? responseStoreManagers?.map((item: any) => ({
                  label: `${item.firstName ?? ''} ${item.lastName ?? ''}${item.curp ? ' / ' + item.curp : ''}`,
                  value: item.id,
                }))
                : [],
          },
        }))
      }
      if (!responseCompany.errors) {
        fatherL =
          responseCompany?.companysZone
            ?.filter((item) => item.zone)
            ?.map((item) => ({
              label: item?.zone?.name,
              value: item?.zoneId,
            })) ?? []
        setFatherList(fatherL)
        setInputValues((prev) => ({
          ...prev,
          divisions: {
            ...prev.divisions,
            items:
              responseCompany?.companysDivision
                ?.filter((item) => item.division)
                ?.map((item) => ({
                  label: item?.division?.description,
                  value: item?.divisionId,
                })) ?? [],
          },
          fatherZone: {
            ...prev.fatherZone,
            items:
              responseCompany?.companysZone
                ?.filter((item) => item.zone?.zoneInitId === null)
                ?.map((item) => ({
                  label: item?.zone?.name,
                  value: item?.zoneId,
                })) ?? [],
          },
        }))
      }

      if (!!fatherZone) {
        setInputValues((prev) => ({
          ...prev,
          class: {
            ...prev.class,
            items:
              childZones
                .filter((item) => item.father === fatherZone && !!fatherL?.map?.((item) => item.value)?.includes(item.value))
          },
        }))
      }
    } else {
      setInputValues((prev) => ({
        ...prev,
        divisions: {
          ...prev.divisions,
          items: [],
        },
        fatherZone: {
          ...prev.fatherZone,
          items: [],
        },
        class: {
          ...prev.class,
          items: [],
        },
        userManageId: {
          ...prev.userManageId,
          items: [],
        },
      }))
    }
    setBtnLoading(false)
  }

  const getAllUsers = useCallback(async () => {
    setIsLoading(true)
    const responseStores = await getStoresList(colors, t, { query: returnQuery(query, formData) })
    setTotalPages(responseStores.totalPages)
    setRow(responseStores.row)
    setTable(responseStores)
    setIsLoading(false)
    setCrud(false)
    setOpen(false)
    setData(initialData)
    setBtnLoading(false)
    setSelected(-1)

    let createYears: Item[] = []
    responseStores?.row?.forEach((item: any) => {
      if (!createYears.some((el) => el?.value === item?.createYear)) {
        createYears = [
          ...createYears,
          {
            label: item?.createYear,
            value: item?.createYear,
          },
        ]
      }
    })
    setInputs((prev) => ({
      ...prev,
      createYear: {
        ...prev.createYear,
        items: getPreviousYears(),
      },
      month: {
        ...prev.month,
        items: monthYear.map(item => ({
          ...item,
          value: item.value + 1
        })),
      },
    }))
    // eslint-disable-next-line
  }, [colors, t, query])

  const getStatus = async () => {
    const responseStatus = await statusService().statusList()
    let valideStatus: any[] = []

    responseStatus?.forEach((item: any) => {
      if ([Status.ACTIVE, Status.INACTIVE].includes(item.id)) {
        valideStatus.push({
          value: item.id,
          label: item.description,
        })
      }
    })

    setInputValues(prev => ({
      ...prev,
      status: {
        ...initialValues.status,
        items: valideStatus,
      },
    }))
  }
  const getZonesAll = async () => {
    const { fatherZones, childZones } = await getZones()
    if (!!fatherZones?.length) {
      setInputs((prev) => ({
        ...prev,
        zones: {
          ...prev.zones,
          items: fatherZones,
        },
      }))
      setChildZones(childZones)
    }
  }

  const getUserManagersList = async () => {
    const response = await userServiceFetch.userList(`${Roles.STORE}`)
    setInputs((prev) => ({
      ...prev,
      users: {
        ...inputs.users,
        items: response?.map(item => ({
          label: `${item.curp} - ${item.firstName} ${item.lastName}`,
          value: item.id
        })),
      },
    }))
  }

  const getDivisionsService = async () => {
    const division = await getDivisions()

    setInputs((prev) => ({
      ...prev,
      divisions: {
        ...inputs.divisions,
        items: division.items,
      },
    }))
  }
  const getCompannies = async () => {
    const responseCompanies = await companiesServiceFetch.getAllCompanies()

    const companies = responseCompanies?.map((item) => ({
      label: item.name,
      value: item.id,
    }))

    setInputValues((prev) => ({
      ...prev,
      companyId: {
        ...initialValues.companyId,
        items: companies,
      },
    }))
  }

  const getZipCode = async () => {
    const responseStates = await zipCodeService(httpFetch).satesList()

    if (!responseStates?.errors) {
      const states = responseStates?.map((item: any) => ({
        label: item.state,
        value: item.codState,
      }))
      setInputValues(prev => ({
        ...prev,
        states: {
          ...initialValues.states,
          items: states,
        },
      }))
    }
  }

  const getData = useCallback(async () => {
    setFlag(true)
    setIsLoading(true)
    getDivisionsService()
    getUserManagersList()
    getStatus()
    getZonesAll()
    getCompannies()
    getZipCode()
  }, [])

  useEffect(() => {
    if (!flag) getData()
  }, [getData, flag])

  useEffect(() => {
    getAllUsers()
  }, [getAllUsers, query.all])

  const deleteShop = async (id?: number) => {
    if (id) {
      const response = await storesService(httpFetch).deleteShop(id)

      if (!response.errors) {
        cleanFilter()
        getAllUsers()
        setOpenSnackbarSome({
          open: true,
          message: t(`stores.modal.delete.success.title`),
          type: Types.SUCCESS as string,
        })
      } else {
        setOpenSnackbarSome({
          open: true,
          message: t(`stores.modal.delete.error.title`),
          type: Types.ERROR as string,
        })
      }
    }
  }
  const roleIsNumber = typeof role === 'number';
  return (
    <>
      <SnackSimple close={closeOpenSnakbar} open={snackbarDownloadModal.open} type={snackbarDownloadModal.type} message={snackbarDownloadModal.message} />
      <SnackSimple close={handleCloseSnackbar} open={openSnackbar.open} type={openSnackbar.type} message={openSnackbar.message} />
      {!loadMassiveActive && (
        <General
          newHeader={
            <Box sx={styles.header}>
              <Typography sx={styles.title}>{t('stores.filter.title')}</Typography>

              <Box sx={styles.flex}>
                <ModelDownloadButtons csv={downloadCSV} pdf={downloadPDF} word={downloadWORD} disableCSV={download?.excel} disablePDF={download?.pdf} disableWORD={download?.word} />

                {
                  !!(roleIsNumber && ![Roles.SUPPORT, Roles.AUDIT].includes(role)) && (
                    <Box sx={styles.actions}>
                      <IconButton
                        sx={(styles.clean, (counter || 0) > 0 ? null : styles.hide)}
                        onClick={() => {
                          cleanFilter()
                        }}
                      >
                        {icons('clean')}
                      </IconButton>

                      <IconButton
                        className="add"
                        sx={styles.clean}
                        onClick={() => {
                          openDrawer()
                        }}
                      >
                        {icons('add')}
                      </IconButton>

                      <IconButton
                        className="openModal"
                        sx={styles.clean}
                        onClick={() => {
                          goLoadMassive()
                        }}
                      >
                        {icons('list')}
                      </IconButton>
                    </Box>
                  )
                }
              </Box>
            </Box>
          }
          open={open}
          rightDrawer={{
            ...information,
            loadindDetail: loadindDetail,
            data: information?.data,
            showImage: true,
            autoHeight: drawerStatus == CRUD.EDIT ? true : false,
            paddingBottom: drawerStatus == CRUD.EDIT ? '25px' : 'unset',
            form: (
              <>
                <Form btnLoading={btnLoading} data={data} input={Object.values(inputValues)} crudChange={crudChange} />

                {!!(drawerStatus == CRUD.EDIT) && (
                  <Box
                    sx={{
                      textAlign: 'left',
                      paddingTop: '10px',
                      marginBottom: '-10px',
                    }}
                  >
                    <Button
                      variant="text"
                      sx={{ fontSize: '12px' }}
                      onClick={() => {
                        handleTableStore()
                        setOpenModal(true)
                      }}
                    >
                      {t('stores.informationCard.checkUser')}
                    </Button>
                  </Box>
                )}
              </>
            ),
            ...((roleIsNumber && ![Roles.SUPPORT, Roles.AUDIT].includes(role)) && {
              menuItems: menu,
            }),
            crud,
            title: drawerDetail.title,
            accept: {
              title: drawerDetail.accept,
              disabled: generalDisabled(inputValues, data) || btnLoading,
              onClick: handleSubmit,
            },
            cancel: {
              title: drawerDetail.cancel,
              onClick: closeDrawer,
            },
            clickMenu: handleMenu,
            auxiliar: !!manageButton
              ? {
                title: t('stores.informationCard.checkUser'),
                onClick: () => {
                  handleTableStore()
                  setOpenModal(true)
                },
              }
              : undefined,
          }}
          table={{
            column: table.column,
            row: row,
            title: t('stores.filter.title'),
            onClick: handleInformation,
            loadingTable: isLoading,
            selected,
            order: query?.sort && {
              count: query?.sort?.count,
              key: query?.sort?.field,
              order: query?.sort?.sort,
            },
            sortable: (field, sort, count) => {
              sortTable(field && sort ? { field: field, sort: sort, count } : undefined)
            },
            serverSidePagination: { page: paginationModel.page + 1, size: paginationModel.pageSize, totalPages },
            handleChange: (page: number, pageSize: number) => {
              handlePagination({ page, pageSize })
            }
          }}
          filtersDrawer={
            <>
              <FiltersDrawer cleanFilter={cleanFilter} count={counter} right={-50} top={0}>
                <Box sx={styles.section}>
                  <DinamicForm errors={errors} input={Object.values(inputs)} values={filterData} onChange={onChange} filter={true} />
                </Box>
              </FiltersDrawer>
            </>
          }
        />
      )}

      {loadMassiveActive && (
        <MassiveUpload
          moduleName="unidades"
          colors={colors}
          goBack={() => {
            setLoadMassiveActive(false)
          }}
          children={<UploadForm></UploadForm>}
        />
      )}
      <SimpleModal
        open={modalDataShop.open}
        close={() => {
          setModalDataShop({ open: false, status: 0 })
        }}
        title={t('stores.modal.delete.title')}
        description={t('stores.modal.delete.description')}
        buttonText={t('general.confirm')}
        cancelText={t('general.cancel')}
        color={colors}
        back={() => {
          setModalDataShop({ open: false, status: 0 })
        }}
        next={() => {
          deleteShop(modalDataShop.status)
          setModalDataShop({ open: false, status: 0 })
        }}
      />
      <SnackSimple close={handleCloseSnackbar} open={openSnackbarSome.open} type={openSnackbarSome.type} message={openSnackbarSome.message} />

      <BaseModal open={openModal} sx={{ width: '600px' }}>
        {
          !!isLoadingUserAssociated ? (
            <Loader />
          ) : (
            <ModalCrudTable
              t={t}
              filterRow={filterRowUser}
              color={colors}
              table={tableAssociateUser}
              initialFilter={initialFilterValues as any}
              setInformation={(e) => { }}
              onClose={() => {
                setOpenModal(false)
                setTableAssociateUser({ column: [], row: [] })
              }}
              filter={{
                filterTitle: '',
                label: t('stores.modalTypeAgent.searchAgent'),
                name: 'search',
                title: t('stores.modalTypeAgent.assignedUsers'),
                type: 2,
                sx: { width: "100%" },
              }}
            />

          )
        }
      </BaseModal>
    </>
  )
}

export default Shop
