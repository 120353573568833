import React from "react"
import { ActionPlanContext } from "../context"
import { FilterProps } from "../../../hooks/filter/typing"
import { useFilter } from "../../../hooks/filter"
import { canAccess } from "../../../utils/validate/canaccess"
import { Roles } from "../../../utils/enums"

const ActionPlanProvider = (props: { children: JSX.Element }) => {
    const doNotHaveAcces = !canAccess([Roles.SUPPORT])
    const [filterPayload, setFilterPayload] = React.useState<FilterProps>({ formatFilter: {} })
    const filterData = useFilter(filterPayload)
    const [actionPlanOfStores, setActionPlanOfStores] = React.useState(!!doNotHaveAcces)

    return (
        <ActionPlanContext.Provider
            value={{
                filterData,
                actionPlanOfStores,
                doNotHaveAcces,
                setActionPlanOfStores,
                setFilterPayload
            }}
        >
            {props.children}
        </ActionPlanContext.Provider>
    )
}

export { ActionPlanProvider }
