import { useNavigate, useParams } from 'react-router-dom'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, Grid, Typography, Tab, Drawer, IconButton } from '@mui/material'
import { useStyles } from './styles'
import { DinamicForm } from '../../../../../../components/dinamicForm'
import { useCreatEdit } from './hooks/useForms'
import { useGetData } from './hooks/useGetData'
import { useInput } from './hooks/useInputs'
import { useTranslation } from 'react-i18next'
import { CustomTable, SnackSimple } from '../../../../../../components'
import Loader from '../../../../../../components/loader'
import { useContextState } from '../../../../../../hooks/theme'
import { useTables } from './hooks/useTable'
import { useTabs } from '../../../../../../hooks/tabs/useTabs'
import { useEffect, useState } from 'react'
import { TabDepartment } from '../../typing/enums'
import { icons } from '../../../../../../utils/icons'
import SimpleModal from '../../../../../../components/modal/simpleModal'
import { generalDisabled } from '../../../../../../utils/validate/validate'
import { createEdit } from './constants/forms'
import { submit } from './methods'
import { Item } from '../../../../../../utils/types/generals'
import { Types } from '../../../../../../utils/enums'
import { FiltersDrawer } from '../../../../../../components/filtersDrawer'
import { useFilter } from './hooks/useFilter'

const FormDepartment = () => {
  const styles = useStyles()
  const { t } = useTranslation()
  const { colors } = useContextState()
  const { id } = useParams()
  const navigate = useNavigate()
  const { items, itemsSetting, loadingTable, userTable, loadingDetail, dataEndpoint, extraData } = useGetData()
  const { input, settingInput, inputFilter } = useInput({ items, itemsSetting })
  const { data, dataSetting, onChange, onChangeSetting, handleFolioSetting, handledata } = useCreatEdit()
  const { value, handleChange } = useTabs()
  const {
    columnUser,
    column: columnFolio,
    row: rowFolio,
    openDrawer,
    openModal,
    userFolioSelected,
    userSelected,
    closeDrawer,
    closeModal,
    setSettings,
    handleSelect,
    handleSelectUser,
    setAllUsers,
    setAllSettingsFolioUser,
  } = useTables({ tab: value, userTable, handleFolioSetting })
  const { dataFilter, counter, filterChange, cleanFilter, filterRow } = useFilter()
  const [submitLoading, setSubmitLoading] = useState(false)
  const [userTableLocale, setUserTableLocale] = useState(userTable)
  const [submitModal, setSubmitModal] = useState<{
    open: boolean
    openSnackbar: boolean
    title: string
    description: string
    id: string | number
    type: Types
  }>({
    title: '',
    description: '',
    id: '',
    open: false,
    openSnackbar: false,
    type: Types.SUCCESS,
  })

  const [backModal, setBackModal] = useState<{
    open: boolean
    title: string
    type: Types
  }>({
    open: false,
    title:t('general.exit'),
    type: Types.SUCCESS,
  })
  
  const getSwitchValues = ({ value }: { value: boolean }) => {
    if (!id && !!userTable?.length) {
      let body: Record<string, boolean> = {}

      userTable?.forEach((itemUsers) => {
        body = {
          ...body,
          [itemUsers.id]: value,
        }
      })
      setUserTableLocale(userTable)
      setAllUsers({ newSelected: body })
    } else {
      if (!!id) {
        let body: Record<string, boolean> = {}
        let bodyFolio: Record<
          string,
          {
            status: boolean
            reassignFolio:boolean
            settings?:
              | {
                  users: Item[]
                  usersTypes: Item[]
                  stores: Item[]
                }
              | undefined
          }
        > = {}

        dataEndpoint.users?.forEach((itemUsers) => {
          let newReceive: Record<string, Item[]> = {}

          Object.entries(itemUsers?.receive ?? {}).forEach(([key, itemReceive]) => {
            let newValueSetting: Item[] = []
            itemReceive.forEach((itemTy) => {
              const item = itemsSetting?.[key]?.find((itemA) => itemA?.value === itemTy?.value)
              if (!!item) {
                newValueSetting = [...newValueSetting, item]
              }
            })
            newReceive = {
              ...newReceive,
              [key]: newValueSetting,
            }
          })
          
          bodyFolio = {
            ...bodyFolio,
            [itemUsers.id]: {
              ...bodyFolio[itemUsers.id],
              status: !!itemUsers?.status,
              reassignFolio: !!itemUsers?.reassignFolio,
              settings: !!Object.values(newReceive)?.length ? newReceive : undefined,
            },
          }

          body = {
            ...body,
            [itemUsers.id]: true,
          }
        })
        const userTableSort = userTable?.sort((a) => {
          if (!!body[a.id]) return -1
          return 1
        })

        setUserTableLocale(
          userTableSort?.sort((a) => {
            if (!!body[a.id]) return -1
            return 1
          })
        )
        
        setAllSettingsFolioUser({ userFolio: bodyFolio, skip: true })

        handledata({ email: dataEndpoint.email, phone: dataEndpoint.phone, name: dataEndpoint.name, responsible: dataEndpoint.responsible })
        if (!!Object.values(body)?.length) {
          setAllUsers({ newSelected: body })
        }
      }
    }
  }

  const handleDeleteSettings = () => {
    if (openModal.id?.toString() === 'all') {
      if (value === TabDepartment.USER) {
        const { body } = unSelectOrSelectAllUsersManage({ value: false })
        setAllUsers({ newSelected: body, skip: true })
      }
      const { body } = unselectOrSelectAllUserFoliosManage({ value: true })
      setAllSettingsFolioUser({ userFolio: body, skip: true })
    } else {
      setSettings({ id: openModal.id })
      if (value === TabDepartment.USER) {
        handleSelectUser({ id: openModal.id?.toString(), value: false, skipCondition: true })
      } else {
        handleSelect({ id: openModal.id?.toString(), value: true, skipCondition: true })
      }
    }
    closeModal()
  }

  const handleSubmit = () => {
    setSubmitLoading(true)
    submit({ data, userFolioSelected: userFolioSelected, userSelected, userTable, id })
      .then((response) => {
        if (!response?.errors) {
          setSubmitModal((prev) => ({
            ...prev,
            open: true,
            openSnackbar: false,
            title: t(`department.form.modal.${!id ? 'create' : 'edit'}.success.title`),
            description: t(`department.form.modal.${!id ? 'create' : 'edit'}.success.description`),
            type: Types.SUCCESS,
          }))
        } else {
          setSubmitModal((prev) => ({
            ...prev,
            open: false,
            openSnackbar: true,
            title: t(`department.form.modal.${!id ? 'create' : 'edit'}.errors.title`),
            description: t(`department.form.modal.${!id ? 'create' : 'edit'}.errors.description`),
            type: Types.ERROR,
          }))
        }
      })
      .finally(() => {
        setSubmitLoading(false)
      })
  }

  const closeSubmitModal = () => {
    setSubmitModal((prev) => ({
      ...prev,
      open: false,
      openSnackbar: false,
    }))
  }
  const closeBackModal = () => {
    setBackModal((prev) => ({
      ...prev,
      open: false,
    }))
  }

  const returnList = () => {
    navigate('/dashboard/organization-chart/departments')
  }

  const handleSaveSettings = () => {
    const settings =
      !!dataSetting.stores?.length || !!dataSetting.users?.length || !!dataSetting.usersTypes?.length
        ? {
            stores: dataSetting.stores || [],
            users: dataSetting.users || [],
            usersTypes: dataSetting.usersTypes || [],
          }
        : undefined

    setSettings({
      id: openDrawer?.user?.id,
      settings,
    })
    closeDrawer()
  }

  const unSelectOrSelectAllUsersManage = ({ value }: { value: boolean }) => {
    let body: Record<string, boolean> = structuredClone(userSelected)

    const filter = filterRow({ list: userTableLocale, value: dataFilter })
    filter?.forEach((item) => {
      body = {
        ...body,
        [item.id?.toString() ?? '']: value,
      }
    })

    return { body, filter }
  }
  const unSelectOrSelectAllUsers = ({ value }: { value: boolean }) => {
    const { body, filter } = unSelectOrSelectAllUsersManage({ value })

    setAllUsers({ newSelected: body, filter, skip: !!value, unmark: true })
  }

  const unselectOrSelectAllUserFoliosManage = ({ value }: { value: boolean }) => {
    let body: Record<
      string,
      {
        status: boolean
        reassignFolio?:boolean
        settings?:
          | {
              users: Item[]
              usersTypes: Item[]
              stores: Item[]
            }
          | undefined
      }
    > = structuredClone(userFolioSelected)

    const filter = filterRow({ list: rowFolio, value: dataFilter })
    
    filter?.forEach((item) => {
      body = {
        ...body,
        [item.id?.toString() ?? '']: {
          ...userFolioSelected[item.id?.toString() ?? ''],
          status: value,
          reassignFolio:value,
          ...(!!value && { settings: undefined }),
        },
      }
    })

    return { body, filter }
  }
  const unSelectOrSelectAllUsersFolios = ({ value }: { value: boolean }) => {
    const { body, filter } = unselectOrSelectAllUserFoliosManage({ value })

    setAllSettingsFolioUser({ userFolio: body, skip: !value, filter })
  }

  useEffect(() => {
    getSwitchValues({ value: false })
    //eslint-disable-next-line
  }, [id, userTable?.length, dataEndpoint.users?.length, itemsSetting])

  const backModalOpen = () => {
      setBackModal((prev) => ({
        ...prev,
        open: true,
        type: Types.SUCCESS,
      }))
  }

  if (!!submitLoading || !!loadingDetail) return <Loader />
  return (
    <Box sx={styles.container}>
      {/* HEADER */}
      <Box sx={styles.header}>
        <Box sx={styles.headerLeft}>
            <Box sx={styles.arrow}>
                <IconButton onClick={() => { backModalOpen() }}>{icons('arrow-left-fill')}</IconButton>
            </Box>
            <Typography sx={styles.title}>{t(`department.form.${!!id ? 'updateTitle' : 'createTitle'}`)}</Typography>
        </Box>

        <Box sx={[styles.flex, styles.btnFinish]}>
          <IconButton
            sx={(styles.clean, (counter || 0) > 0 ? null : styles.hide)}
            onClick={() => {
              cleanFilter()
            }}
          >
            {icons('clean')}
          </IconButton>

          <Button
            disabled={generalDisabled(createEdit, data)}
            onClick={() => {
              if (!generalDisabled(createEdit, data)) {
                handleSubmit()
              }
            }}
            variant="contained"
          >
            {t('department.form.buttons.finish')}
          </Button>
        </Box>
      </Box>

      <Grid container columnSpacing={4}>
        <Grid item xs={4}>
          <Box sx={[styles.gridItems, styles.padding]}>
            <Typography sx={{...styles.subTitleArea, marginBottom: !!extraData?.area ? '5px' : '10px'}}>{t('department.form.crud.title')}</Typography>
            {!!extraData?.area && <Typography sx={[styles.subTitleAreaSub]}><span>{t('department.form.crud.areaAssigned')}:</span> {extraData.area}</Typography>}

            <DinamicForm input={Object.values(input)} onChange={onChange} values={data} />
          </Box>
        </Grid>

        <Grid item xs={8}>
          <Box sx={[styles.tabsContainer]}>
            <TabContext value={value}>
              <TabList sx={styles.TabList} onChange={handleChange} centered>
                <Tab label={t('department.form.tabs.1').toUpperCase()} value={TabDepartment.USER} />

                <Tab disabled={!userTable?.length || !userTable?.length} label={t('department.form.tabs.2').toUpperCase()} value={TabDepartment.FOLIO} />
              </TabList>

              <TabPanel value={TabDepartment.USER} sx={styles.TabPanel}>
                <Typography sx={styles.subTitle}>{t('department.form.tabs.title')}</Typography>

                <Box sx={styles.headerActions} className="user-list-item">
                  <Typography
                    sx={styles.select}
                    onClick={() => {
                      unSelectOrSelectAllUsers({ value: true })
                    }}
                  >
                    {t('schedule.buttons.addTruechecket')}
                  </Typography>

                  <Typography sx={styles.line}>|</Typography>

                  <Typography
                    sx={styles.select}
                    onClick={() => {
                      unSelectOrSelectAllUsers({ value: false })
                    }}
                  >
                    {t('schedule.buttons.addFalsechecket')}
                  </Typography>
                </Box>

                {/* TABLE */}
                {!loadingTable ? <CustomTable dontRefresh name={t('general.tags.departments')} column={columnUser} row={filterRow({ list: userTableLocale, value: dataFilter })} color={colors} selected={-1} /> : <Loader />}
              </TabPanel>

              <TabPanel value={TabDepartment.FOLIO} sx={styles.TabPanel}>
                <Typography sx={styles.subTitle}>{t('department.form.tabs.titleFolio')}</Typography>

                <Box sx={styles.headerActions} className="user-list-item">
                  <Typography
                    sx={styles.select}
                    onClick={() => {
                      unSelectOrSelectAllUsersFolios({ value: true })
                    }}
                  >
                    {t('schedule.buttons.addTruechecket')}
                  </Typography>

                  <Typography sx={styles.line}>|</Typography>

                  <Typography
                    sx={styles.select}
                    onClick={() => {
                      unSelectOrSelectAllUsersFolios({ value: false })
                    }}
                  >
                    {t('schedule.buttons.addFalsechecket')}
                  </Typography>
                </Box>

                {/* TABLE */}
                {!loadingTable ? <CustomTable dontRefresh name={t('general.tags.departments')} column={columnFolio} row={filterRow({ list: rowFolio, value: dataFilter })} color={colors} selected={-1} /> : <Loader />}
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </Grid>

      {/* FLOATS */}
      <FiltersDrawer cleanFilter={cleanFilter} count={counter} right={0} top={48}>
        <Box sx={styles.section}>
          <DinamicForm input={Object.values(inputFilter)} values={dataFilter} onChange={filterChange} filter={true} />
        </Box>
      </FiltersDrawer>

      <SnackSimple close={closeSubmitModal} open={submitModal.openSnackbar} type={submitModal.type} message={submitModal.title} />

      <SimpleModal
        open={submitModal.open}
        close={() => {
          closeSubmitModal()
          returnList()
        }}
        title={submitModal.title}
        description={submitModal.description}
        buttonText={t('general.confirm')}
        color={colors}
        next={() => {
          handleDeleteSettings()
          returnList()
        }}
      />
        <SimpleModal
        open={backModal.open}
        close={() => {
          closeBackModal()
        }}
        title={backModal.title}
        buttonText={t('general.confirm')}
        color={colors}
        next={() => {
          closeBackModal()
          returnList()
        }}
      />

      <SimpleModal
        open={openModal.open}
        close={() => {
          closeModal()
        }}
        title={openModal.title}
        description={openModal.description}
        buttonText={t('general.confirm')}
        cancelText={t('general.cancel')}
        color={colors}
        back={() => {
          closeModal()
        }}
        next={() => {
          handleDeleteSettings()
        }}
      />

      <Drawer sx={styles.drawer} anchor="right" open={openDrawer.setting}>
        <IconButton
          sx={styles.iconClose}
          onClick={() => {
            closeDrawer()
          }}
        >
          {icons('simple-close')}
        </IconButton>

        <Box sx={styles.body}>
          <Typography sx={styles.drawerTitle}>{t('department.form.settings.title')}</Typography>

          <Typography sx={styles.drawerUser}>{t('department.form.settings.user')}</Typography>

          <Typography sx={[styles.title, styles.drawerSpacing]}>{openDrawer?.user?.name ?? ''}</Typography>

          <DinamicForm input={Object.values(settingInput)} onChange={onChangeSetting} values={dataSetting} />
        </Box>

        <Box sx={styles.containerFooter}>
          <Button
            onClick={() => {
              handleSaveSettings()
            }}
            variant="contained"
          >
            {t('department.form.settings.accept')}
          </Button>
        </Box>
      </Drawer>
    </Box>
  )
}

export { FormDepartment }
