
import { Box, IconButton, SxProps, Typography } from '@mui/material'
import { ModelDownloadButtons } from '../../../../components/donwloadFiles/model'
import { icons } from '../../../../utils/icons'
import {
  BaseMenu,
  CustomTable,
  SnackSimple,
} from '../../../../components'
import { DinamicForm } from '../../../../components/dinamicForm'
import { FiltersDrawer } from '../../../../components/filtersDrawer'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { useDownload } from '../../../../hooks/download/useDownload'
import {
  returnQuery,
  returnQueryWithPagination,
} from '../../../../utils/methods/returnQuery'
import { useFilter } from '../../../../hooks/filter'
import { useEffect, useState } from 'react'
import { useContextState } from '../../../../hooks/theme'
import { useTable } from './hooks/table'
import { useNavigate } from 'react-router-dom'
import { useGetData } from './hooks/getData'
import { Item, ObjectGeneric, Size, onChange } from '../../../../utils/types/generals'
import { useInput } from './hooks/useInput'
import { removeAllReference } from '../../../../utils/methods/removedReference'
import { FilterProps } from '../../../../hooks/filter/typing'
import { formOrder, formatFilter } from './constants/filters'
import BaseModal from '../../../../components/modal/baseModal'
import { actionPlanFetch, userScheduleServiceFetch } from '../../../../utils/constants/callService'
import StatusComponent from '../../../../components/status'
import { getStatus } from '../../../../hooks/status'
import { Constants } from '../../../../utils/constants'
import { format } from 'date-fns'
import SimpleModal from '../../../../components/modal/simpleModal'
import { Status, Types } from '../../../../utils/enums'
import { handleAllErrors } from '../../../../utils/methods/handleErrors'
import { LoadingButton } from '@mui/lab'
import { resendReportForm, resendReportFormEmails } from './constants/general'
import { isEmailSeparateToCome } from '../../../../utils/validate/validate'
import { DetailModal } from './components/detailModal'

const UserScheduled = () => {
  const navigate = useNavigate()
  const styles = useStyles()
  const { t } = useTranslation()
  const { colors } = useContextState()
  const {
    row,
    isLoading,
    departments,
    items,
    endpointDetail,
    getUserSchedule,
    handleItemsByExternal,
  } = useGetData()

  const [disabledModal, setDisabledModal] = useState({
    resendButton: true,
    resendButtonLoading: false,
  })
  const [resendReport, setResendReport] = useState<{
    title: string
    open: boolean
    close: () => void,
    send: (data: { data: ObjectGeneric }) => void,
    values: ObjectGeneric
  }>({
    title: t('reportFolio.modal.sendReport.titleVisit'),
    open: false,
    close: () => { },
    send: () => { },
    values: {}
  })
  const { input, disabledInputs } = useInput({ items: items })
  const { columns, detail, closeMenu, addReportUrl } = useTable({ row })
  const [openSnackbarReport, setOpenSnackbarReport] = useState({
    open: false,
    message: '',
    type: Types.SUCCESS as string,
  })
  const [openPreview, setOpenPreview] = useState<{
    open: boolean
    userAssigned?: {
      [x: string]: string | { element: JSX.Element; size?: Size }
    }
    questionnaire?: {
      [x: string]: string | null | number | { element: JSX.Element; size?: Size; value?: string | Status }
    }
    directParents?: {
      [x: string]: string | { element: JSX.Element; size?: Size }
    }[]
    createdByUser?: {
      [x: string]: string | { element: JSX.Element; size?: Size }
    }[]
  }>({
    open: false,
  })
  const externalValidation: FilterProps['externalValidation'] = ({
    name,
    value,
    body,
    setFilterData,
  }) => {
    let copyBody: Record<string, any> = removeAllReference(body)

    if (name === 'areas') {
      const { areas, departments: departmentsData } = copyBody
      const newDepartments = departmentsData?.filter((e: any) =>
        areas.find((e2: any) => e2.value == e.areaId)
      )
      let arrayChilds: any[] = []

      areas?.forEach((e: any) => {
        const id = e.value
        const childs = departments.filter((child) => child.areaId == id)
        arrayChilds.push(...childs)
      })

      handleItemsByExternal({ name: 'departments', value: arrayChilds })
      setFilterData((prev) => ({
        ...prev,
        departments: newDepartments,
      }))

      copyBody = {
        ...copyBody,
        departments: newDepartments,
      }
    } else if (name === 'percentage') {
      if (parseInt(value[0]) === 0 && parseInt(value[1]) === 100) {
        delete copyBody.percentage
      }
    } else if (name === 'filterByCurrentWeek') {
      disabledInputs({ inputs: ['createYear', 'month', 'from', 'to'], value })
    }

    const currentWeekValue =
      name === 'filterByCurrentWeek' ? value : body?.filterByCurrentWeek

    if (!!currentWeekValue) {
      delete copyBody.createYear
      delete copyBody.month
      delete copyBody.from
      delete copyBody.to
    }
    return copyBody
  }

  const {
    counter,
    errors,
    filterData,
    paginationModel,
    query,
    filterString,
    sortTable,
    handlePagination,
    onChange,
    cleanFilter,
  } = useFilter({
    externalValidation,
    formatFilter,
  })
  const {
    download,
    openSnackbar,
    downloadCSV,
    downloadPDF,
    downloadWORD,
    closeOpenSnakbar,
  } = useDownload({
    docName: t('useScheduled.detail.snackbar.docName'),
    query: returnQueryWithPagination({
      filter: filterString,
      sort: query.sort,
    }),
    downloadFuntion: userScheduleServiceFetch.downloadReport,
  })
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false)
  const [openSnackbarMain, setOpenSnackbarMain] = useState({
    message: '',
    type: Types.SUCCESS,
    open: false,
  })
  const [openModal, setOpenModal] = useState<{
    open: boolean
    title: string
    description: string
    submit?: boolean
    id?: number
    isClose?: boolean
    method: () => void
  }>({
    submit: true,
    open: false,
    title: t('schedule.modal.submit.title'),
    description: t('schedule.modal.submit.description'),
    method: () => { },
  })

  const handleCheanFilter = () => {
    disabledInputs({
      inputs: ['createYear', 'month', 'from', 'to'],
      value: false,
    })
    cleanFilter()
  }

  const handleCloseModal = () => {
    setOpenModal((prev) => ({
      ...prev,
      open: false,
    }))
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbarMain((prev) => ({
      ...prev,
      open: false,
    }))
  }
  const handleSendReportData: onChange = ({ target }) => {
    const { name, value } = target

    const body: ObjectGeneric = {
      ...structuredClone(resendReport.values),
      [name]: value,
    }

    /* START EMAIL VALIDATE */
    const emailValue = name === 'externalEmails' ? value : body?.externalEmails
    let emailIsSeparate = false
    emailIsSeparate = !(!emailValue || isEmailSeparateToCome(emailValue))
    /* END EMAIL VALIDATE */


    setDisabledModal((prev) => ({
      ...prev,
      resendButton:
        !Object.values(body ?? {})?.some((item) => !!item) || emailIsSeparate,
    }))

    setResendReport((prev) => ({
      ...prev,
      values: {
        ...prev?.values ?? {},
        [name]:
          name === 'externalEmails'
            ? value?.replace(/(\r\n|\n|\r|\s)/gm, '')
            : value,
      }
    }))
  }
  const optionMenu = ({ item }: { item: Item }) => {
    const menu: { [x: string]: () => void } = {
      '1': () => {
        window.open(`/dashboard/schedule-user/list/${detail.id}`)
      },
      '2': () => {
        setOpenModal({
          ...openModal,
          title: t('schedule.modal.wantClose'),
          description: t('useScheduled.main.snackbars.descriptionClose'),
          id: detail.id,
          submit: false,
          open: true,
          isClose: true,
          method: async () => {
            if (detail.id) {
              setIsLoadingConfirm(true)
              const response = await userScheduleServiceFetch.closeUserSchedule(
                {
                  id: detail.id.toString(),
                }
              )
              if (!response.errors) {
                setOpenSnackbarMain((prev) => ({
                  ...prev,
                  message: t('useScheduled.main.snackbars.closeSuccess'),
                  type: Types.SUCCESS,
                }))

                getUserSchedule({ query: returnQuery(query, formOrder) })
              } else {
                setOpenSnackbarMain((prev) => ({
                  ...prev,
                  message: handleAllErrors(
                    response.errors,
                    t,
                    'useScheduled.main.snackbars.itWasAError'
                  ),
                  type: Types.ERROR,
                }))
              }
            }
            setIsLoadingConfirm(false)
            handleCloseModal()
          },
        })
      },
      '3': () => {
        if (detail?.reportUrl) {
          window.open(detail.reportUrl)
        }
      },
      '4': () => {
        const title = t(`reportFolio.modal.sendReport.titleVisit`)
        setResendReport(prev => ({
          ...prev,
          title,
          open: true,
          close: () => {
            setDisabledModal((prev) => ({
              ...prev,
              resendButton: true,
              resendButtonLoading: false,
            }))
            closeResendModal()
          },
          send: ({ data }: { data: ObjectGeneric }) => {
            submitResendReport({ id: detail.id.toString(), data, questionnaireOption: { id: item?.questionnaireId } })
          }
        }))
      },
      '5': () => {
        getItem()
      },
    }

    menu[item?.value]?.()
  }

  const closeResendModal = () => {
    setResendReport(prev => ({ ...prev, open: false, values: {}, close: () => { }, send: () => { } }))
  }

  const handleCLoseOpenSnackbar = () => { setOpenSnackbarReport(prev => ({ ...prev, open: false })) }
  const submitResendReport = ({ id, data, questionnaireOption }: { id: string; data: ObjectGeneric; questionnaireOption?: { id?: string } }) => {
    setDisabledModal((prev) => ({
      ...prev,
      resendButtonLoading: true,
    }))

    const emails = data?.externalEmails
      ?.split(',')
      ?.filter((item: string) => !!item)

    let body: ObjectGeneric = {
      users_direct_parents: !!data?.users_direct_parents,
      user_creator: !!data?.user_creator,
      user_assign: !!data?.user_assign,
    }

    if (!!emails?.length) body['external_emails'] = emails

    const localeId = questionnaireOption?.id ?? id
    const endpointResend = actionPlanFetch.sendReportScheduleUser

    endpointResend(localeId, body)
      .then((response) => {
        if (!response?.errors) {
          closeResendModal()
          setOpenSnackbarReport({
            open: true,
            type: Types.SUCCESS,
            message: t('general.reportSuccess'),
          })
        } else {
          setOpenSnackbarReport({
            open: true,
            type: Types.ERROR,
            message: handleAllErrors(response?.errors[0].code, t, t('general.reportError')),
          })
        }
      })
      .finally(() => {
        setDisabledModal((prev) => ({
          ...prev,
          resendButton: true,
          resendButtonLoading: false,
        }))
      })
  }

  const closeModal = () => {
    setOpenPreview((prev) => ({
      ...prev,
      open: false,
    }))
  }

  const getItem = async () => {
    const size = {
      5: {
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      },
    }
    const response = await userScheduleServiceFetch.getDetail({
      id: detail.id.toString(),
    })
    const user = response.userAssign
    const directParents = response.directParent
    const createdByUser = [response.createdByUser]
    const questionnaire = response.questionnaireMain
    const questionnaireStatus = getStatus(response.statusId, colors, t)

    const startDate = response?.dateStart ? format(new Date(response?.dateStart), Constants.dateFormatGeneral) : ''
    const endDate = response?.dateEnd ? format(new Date(response?.dateEnd), Constants.dateFormatGeneral) : ''
    const reportStartDate = response?.reportDate ? format(new Date(response?.reportDate), Constants.dateFormatGeneral) : ''
    const lastReportDate = response?.lastDateReportFile ? format(new Date(response?.lastDateReportFile), Constants.dateFormatGeneral) : ''
    addReportUrl({ reportUrl: response.reportUrl })
    const percentage: string[] | undefined = response.resultQuestionnaire?.split?.('/')
    const resultQuestionnaire = percentage ? (parseInt(percentage?.[0]) / parseInt(percentage?.[1])) * 100 : null
    setOpenPreview((prev) => ({
      ...prev,
      open: true,
      userAssigned: {
        title: {
          element: (
            <Typography sx={[styles.titleList, styles.detailTitle]}>
              {`${user.firstName} ${user.lastName}`}
            </Typography>
          ),
          size: size[5],
        },
        curp: {
          element: (
            <Typography sx={[styles.titleList]}>{`Curp:: ${user.curp ?? 'N/A'
              }`}</Typography>
          ),
          size: size[5],
        },
        userType: {
          element: (
            <Typography sx={[styles.titleList]}>{`${t(
              'general.tags.userType'
            )}: ${user.userType?.name ?? 'N/A'}`}</Typography>
          ),
          size: size[5],
        },
        rol: {
          element: (
            <Typography sx={[styles.titleList]}>{`${t('general.tags.role')}: ${user.role?.description ?? 'N/A'
              }`}</Typography>
          ),
          size: size[5],
        },
        email: {
          element: (
            <Box sx={[styles.flex, styles.alignS]}>
              <Typography sx={[styles.titleList]} className="width-50p">
                Correo:{' '}
              </Typography>

              <Typography sx={[styles.titleList]} className="width-full-50p">
                {user.email ?? 'N/A'}
              </Typography>
            </Box>
          ),
          size: size[5],
        },
        phone: {
          element: (
            <Typography sx={[styles.titleList]}>{`Telf:: ${user.phone ?? 'N/A'
              }`}</Typography>
          ),
          size: size[5],
        },
      },
      questionnaire: {
        title: questionnaire.name,
        status: {
          element: (
            <StatusComponent
              msg={questionnaireStatus.text}
              color={questionnaireStatus.color}
            />
          ),
          value: questionnaireStatus.value
        },
        ask: questionnaire?.sumQuestions ? `${questionnaire?.sumQuestions} ${t('general.asks')}` : 'N/A',
        id: response.questionnaireMainId?.toString() ?? '',
        percentage: resultQuestionnaire || resultQuestionnaire == 0 ? `${resultQuestionnaire}%` : 'Sin resultado',
        from: startDate,
        to: endDate,
        reportDate: reportStartDate,
        section: questionnaire?.sectionCount,
        amountSection: response.amountSectionsCompleted,
        amountQuestionAnswers: response.amountQuestionsAnswered,
        amountQuestion: questionnaire?.sumQuestions?.toString() ?? 0,
        lastReportDate,
        reportUrl: response.reportUrl?.toString() ?? "",
        lastReportUrl: response.lastReportFile?.toString() ?? "",
        date: {
          element: (
            <Box sx={styles.dateContainer}>
              <Typography sx={styles.text2}>{`${t('general.startDate')}: ${startDate}`}</Typography>
              <Typography sx={styles.text2}>{`${t('general.endDate2')}: ${endDate}`}</Typography>
              <Typography sx={styles.text2}>{`${t('general.reportDate')}: ${reportStartDate}`}</Typography>
            </Box>
          ),
        },
      },
      directParents: directParents?.map(itemParents => ({
        id: itemParents.id.toString(),

        title: {
          element: (
            <Typography sx={[styles.titleList, styles.detailTitle]}>
              {`${itemParents.firstName} ${itemParents.lastName}`}
            </Typography>
          ),
          size: size[5],
        },
        curp: {
          element: (
            <Typography sx={[styles.titleList]}>{`Curp:: ${itemParents.curp ?? 'N/A'
              }`}</Typography>
          ),
          size: size[5],
        },
        userType: {
          element: (
            <Typography sx={[styles.titleList]}>{`${t(
              'general.tags.userType'
            )}: ${itemParents.userType ?? 'N/A'}`}</Typography>
          ),
          size: size[5],
        },
        rol: {
          element: (
            <Typography sx={[styles.titleList]}>{`${t('general.tags.role')}: ${itemParents.role ?? 'N/A'
              }`}</Typography>
          ),
          size: size[5],
        },
        email: {
          element: (
            <Box sx={[styles.flex, styles.alignS]}>
              <Typography sx={[styles.titleList]} className="width-50p">
                Correo:{' '}
              </Typography>

              <Typography sx={[styles.titleList]} className="width-full-50p">
                {itemParents.email ?? 'N/A'}
              </Typography>
            </Box>
          ),
          size: size[5],
        },
        phone: {
          element: (
            <Typography sx={[styles.titleList]}>{`Telf:: ${itemParents.phone ?? 'N/A'
              }`}</Typography>
          ),
          size: size[5],
        },
      })) ?? [],
      createdByUser: createdByUser?.map(itemParents => ({
        id: itemParents.id.toString(),

        title: {
          element: (
            <Typography sx={[styles.titleList, styles.detailTitle]}>
              {`${itemParents.firstName} ${itemParents.lastName}`}
            </Typography>
          ),
          size: size[5],
        },
        curp: {
          element: (
            <Typography sx={[styles.titleList]}>{`Curp:: ${itemParents.curp ?? 'N/A'
              }`}</Typography>
          ),
          size: size[5],
        },
        userType: {
          element: (
            <Typography sx={[styles.titleList]}>{`${t(
              'general.tags.userType'
            )}: ${itemParents.userType?.name ?? 'N/A'}`}</Typography>
          ),
          size: size[5],
        },
        rol: {
          element: (
            <Typography sx={[styles.titleList]}>{`${t('general.tags.role')}: ${itemParents.role?.description ?? 'N/A'
              }`}</Typography>
          ),
          size: size[5],
        },
        email: {
          element: (
            <Box sx={[styles.flex, styles.alignS]}>
              <Typography sx={[styles.titleList]} className="width-50p">
                Correo:{' '}
              </Typography>

              <Typography sx={[styles.titleList]} className="width-full-50p">
                {itemParents.email ?? 'N/A'}
              </Typography>
            </Box>
          ),
          size: size[5],
        },
        phone: {
          element: (
            <Typography sx={[styles.titleList]}>{`Telf:: ${itemParents?.phone ?? 'N/A'
              }`}</Typography>
          ),
          size: size[5],
        },
      })) ?? [],
    }))
  }

  useEffect(() => {
    getUserSchedule({ query: returnQuery(query, formOrder) })
    //eslint-disable-next-line
  }, [query.all])

  return (
    <Box key={'dashboard-reportFolio'} sx={styles.container}>
      {/* HEADER */}
      <Box sx={styles.header}>
        <Typography sx={styles.title}>
          {t('useScheduled.main.title')}
        </Typography>

        <Box sx={styles.flex}>
          <ModelDownloadButtons
            csv={downloadCSV}
            pdf={downloadPDF}
            word={downloadWORD}
            disableCSV={download?.excel}
            disablePDF={download?.pdf}
            disableWORD={download?.word}
          />

          <IconButton
            sx={(styles.clean, (counter || 0) > 0 ? null : styles.hide)}
            onClick={() => {
              handleCheanFilter()
            }}
          >
            {icons('clean')}
          </IconButton>

          <IconButton
            sx={styles.add}
            onClick={() => {
              navigate('create')
            }}
          >
            {icons('add')}
          </IconButton>
        </Box>
      </Box>

      {/* MAIN COMPONENTS */}
      <CustomTable
        loader={isLoading}
        order={
          query.sort && {
            count: query.sort?.count,
            key: query.sort?.field,
            order: query.sort?.sort,
          }
        }
        sortable={(field, sort, count) => {
          sortTable(
            field && sort ? { field: field, sort: sort, count } : undefined
          )
        }}
        handleChange={(page: number, pageSize: number) => {
          handlePagination({ page, pageSize })
        }}
        serverSidePagination={{
          page: paginationModel.page + 1,
          size: paginationModel.pageSize,
          totalPages: endpointDetail?.total ?? 0,
        }}
        name={'Agenda de usuarios'}
        column={columns}
        row={row}
        color={colors}
        onClick={() => { }}
        selected={-1}
        setSelected={() => { }}
      />

      <FiltersDrawer
        cleanFilter={handleCheanFilter}
        count={counter}
        right={0}
        top={50}
      >
        <Box sx={styles.section}>
          <DinamicForm
            errors={errors}
            input={Object.values(input)}
            values={filterData}
            onChange={(e) => {
              handlePagination({ page: 1, pageSize: paginationModel.pageSize })
              onChange(e)
            }}
            filter={true}
          />
        </Box>
      </FiltersDrawer>

      <BaseMenu
        sx={[styles.baseMenu, detail.positionMenu]}
        anchorEl={detail.anchor}
        setAnchorEl={closeMenu}
        menuItems={detail.menu}
        click={(item) => {
          optionMenu({ item: item as Item })
        }}
      />
      <SnackSimple
        close={handleCloseSnackbar}
        open={openSnackbarMain.open}
        type={openSnackbarMain.type}
        message={openSnackbarMain.message}
      />

      <SnackSimple
        close={handleCLoseOpenSnackbar}
        open={openSnackbarReport.open}
        type={openSnackbarReport.type}
        message={openSnackbarReport.message}
      />
      <SnackSimple
        close={closeOpenSnakbar}
        open={openSnackbar.open}
        type={openSnackbar.type}
        message={openSnackbar.message}
      />

      <DetailModal
        closeModal={closeModal}
        optionMenu={optionMenu}
        detail={detail}
        open={openPreview.open}
        createdByUser={openPreview.createdByUser}
        directParents={openPreview.directParents}
        questionnaire={openPreview?.questionnaire}
        userAssigned={openPreview?.userAssigned}
      />

      <BaseModal sx={styles.resendModal as SxProps} open={resendReport?.open}>
        <Box style={styles.resendReportContainer as any}>
          <IconButton
            sx={styles.close}
            onClick={() => {
              resendReport.close()
            }}
          >
            {icons('simple-close')}
          </IconButton>

          <Typography sx={styles.titleSend}>{resendReport.title}</Typography>

          <DinamicForm input={Object.values(resendReportForm)} values={resendReport.values} onChange={handleSendReportData} spacing={{ row: 0 }} />

          <Typography sx={styles.titleSend}>{t('reportFolio.modal.sendReport.emails')}</Typography>

          <DinamicForm input={Object.values(resendReportFormEmails)} values={resendReport.values} onChange={handleSendReportData} />

          <Box sx={styles.resendButton}>
            <LoadingButton
              loading={!!disabledModal.resendButtonLoading}
              disabled={!!disabledModal?.resendButton}
              type="submit"
              onClick={() => {
                resendReport.send({ data: resendReport.values })
              }}
              sx={styles.button}
              variant="contained"
            >
              {t('reportFolio.buttons.resend')}
            </LoadingButton>
          </Box>
        </Box>
      </BaseModal>
      <SimpleModal
        open={openModal.open}
        close={handleCloseModal}
        title={openModal.title}
        description={openModal.description}
        buttonText={t('general.confirm')}
        cancelText={t('general.cancel')}
        color={colors}
        back={handleCloseModal}
        loadingButton
        isLoading={isLoadingConfirm}
        next={() => {
          openModal.method()
        }}
      />
    </Box>
  )
}

export { UserScheduled }
