import { useState, useContext, MouseEvent, useRef, ChangeEvent, useEffect } from 'react'
import { Button, Box, Typography, Drawer, IconButton, Badge } from '@mui/material'
import { BaseInput, SnackSimple } from '../../../../../../../../components'
import { onChange } from '../../../../../../../../utils/types'
import { icons } from '../../../../../../../../utils/icons'
import { useStyles } from './styles'
import { GlobalContext } from '../../../../../../../../contexts/Global'
import CustomAccordion from '../../../../../../../../components/accordion'
import { EvidencesTypeValueEnum } from '../../../../../../../../utils/enums/evidences'
import { Types } from '../../../../../../../../utils/enums'
import SimpleModal from '../../../../../../../../components/modal/simpleModal'
import { useTranslation } from 'react-i18next'
import { sharedAws } from '../../../../../../../../service/bucketS3/Storage'
import { TypesEvidences } from '../typesEvidences'
import { newOrederEvidences } from '../../../../../../../../utils/constants/orderEvidencesType'
import { PropsDetailReportFolio } from '../../../../typing'
import { saveAs } from 'file-saver'

const galleryAndComment = [EvidencesTypeValueEnum.DOCUMENT?.toString(), EvidencesTypeValueEnum.IMAGE?.toString(), EvidencesTypeValueEnum.CAMERA?.toString(), EvidencesTypeValueEnum.VIDEO_GALLERY?.toString(), EvidencesTypeValueEnum?.VIDEO.toString()]

const RejectReason = (props: PropsDetailReportFolio) => {
  const { evidencesDrawer, setEvidencesDrawer, value, setValue } = props
  const { colors } = useContext(GlobalContext)
  const [evidencesRequiredAreLoad, setEvidencesRequiredAreLoad] = useState<Record<string, boolean>>({})
  const { t } = useTranslation()
  const [flag, setFlag] = useState(false)
  const valueRef = useRef<Record<string, any> | null>(null)
  const fileRef = useRef<HTMLInputElement | null>(null)
  const styles = useStyles({ color: colors })
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false)
  const [count, setCount] = useState<number | null>(null)
  const [openModal, setOpenModal] = useState<{
    open: boolean
    title: string
    description?: string
    children?: JSX.Element
    removeCancel?: boolean
    method?: () => void
  }>({
    open: false,
    title: t('reportFolio.snackbar.areYouSureonDeleteThisFile'),
    description: '',
  })
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
    type: Types.SUCCESS,
  })

  const addEvidences = ({ e, name, accept }: { e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>; name: string; accept?: string }) => {
    e.stopPropagation()
    if (fileRef?.current) {
      setEvidencesDrawer((prev) => ({
        ...prev,
        type: {
          ...prev?.type,
          equivalence: ['image/png', 'image/jpeg'],
          accept: accept ?? '',
        },
        name,
      }))
      setTimeout(() => {
        fileRef?.current?.click?.()
      }, 100)
    }
  }

  const handleExpanded = (key: string, value: boolean) => {
    setEvidencesDrawer((prev) => ({
      ...prev,
      value: {
        ...prev.value,
        [key]: {
          ...(prev.value?.[key] ?? {}),
          expanded: value,
        },
      },
    }))
  }

  const getEvidences = async ({ e, name, isUpload, isLoad }: { e: ChangeEvent<HTMLInputElement>; name?: string; isLoad?: (load: boolean) => void; isUpload?: boolean }) => {
    const { files } = e.target
    const fileLength = Object.values(files ?? {})?.length
    const evidencesDrawerCoopy = structuredClone(evidencesDrawer)
    const evidencesLength = Object.values(evidencesDrawerCoopy?.value?.[name ?? '']?.value ?? {})?.length
    const minAmount = evidencesDrawerCoopy?.value?.[name ?? '']?.minAmount ?? 0
    const totalFiles = (evidencesLength ?? 0) + fileLength
    const maxCount = evidencesDrawerCoopy?.value?.[name ?? '']?.maxCount ?? 0
    const size = evidencesDrawerCoopy?.value?.[name ?? '']?.size ?? 0
    const isEquealToMaximus = totalFiles == maxCount

    if (fileLength > 0 && (totalFiles <= maxCount || !!isUpload)) {
      let obj_unidos = evidencesDrawerCoopy?.value?.[name as string]?.value ?? {}
      let titleFilesDontUpload = `Los siguientes archivos superan el tamaño máximo permitido:`
      let titleEvidencesEithTheSameNAme = `Ya existe un archivo con este nombre:`
      let filesDontUpload: string[] = []
      let evidencesEithTheSameNAme: string[] = []

      Object.values(files ?? {})?.forEach((item) => {
        if (!size || item.size < size * 1024000) {
          if (!obj_unidos[item.name]) {
            obj_unidos = {
              ...obj_unidos,
              [item.name]: {
                name: item?.name,
                url: item,
                progress: true,
                progressAmount: '0',
              },
            }
          } else {
            evidencesEithTheSameNAme = [...(evidencesEithTheSameNAme ?? []), item?.name ?? '']
          }
        }
      })

      setEvidencesDrawer((prev) => ({
        ...prev,
        value: {
          ...(prev?.value ?? {}),
          [name ?? '']: {
            ...(prev?.value?.[name as string] ?? {}),
            value: obj_unidos,
            expanded: true,
          },
        },
      }))

      Object.values(files ?? {})?.forEach(async (item) => {
        if ((!size || item.size < size * 1024000)) {
          sharedAws.put({
            file: item,
            name: `folios/${props.folio}`,
            otherBucket: process.env.REACT_APP_EVIDENCES,
            ref: { ref: valueRef, key: item.name },
            progressCallback: (load) => {
              setEvidencesDrawer((prev) => ({
                ...prev,
                value: {
                  ...(prev?.value ?? {}),
                  [name ?? '']: {
                    ...(prev?.value?.[name as string] ?? {}),
                    value: {
                      ...prev?.value?.[name as string]?.value,
                      [item?.name]: {
                        ...(prev?.value?.[name as string]?.value?.[item?.name] ?? item),
                        progressAmount: load.toString(),
                        url: item,
                        upload: false,
                      },
                    },
                  },
                },
              }))
            },
            errorCallback: () => {
              isLoad?.(false)
              delete valueRef?.current?.[`${name ?? ''}-${item?.name ?? ''}`]
              setEvidencesDrawer((prev) => ({
                ...prev,
                value: {
                  ...(prev?.value ?? {}),
                  [name ?? '']: {
                    ...(prev?.value?.[name as string] ?? {}),
                    value: {
                      ...(prev?.value?.[name ?? '']?.value ?? obj_unidos),
                      [item.name ?? '']: {
                        ...(prev?.value?.[name ?? '']?.value?.[item.name ?? ''] ?? obj_unidos[item.name]),
                        progress: true,
                        upload: true,
                        progressAmount: undefined,
                      },
                    },
                  },
                },
              }))
            },
            cancelUpload: (ref) => {
              valueRef.current = {
                ...valueRef.current ?? {},
                [`${name ?? ''}-${item?.name ?? ''}`]: ref
              }
            },
            completeCallback: (url) => {
              isLoad?.(true)
              if (typeof url === 'string' && !!url) {
                setEvidencesRequiredAreLoad((prev) => ({
                  ...prev,
                  [name ?? '']: !minAmount || (!!minAmount && totalFiles > 0),
                }))
                delete valueRef?.current?.[`${name ?? ''}-${item?.name ?? ''}`]
                setEvidencesDrawer((prev) => ({
                  ...prev,
                  value: {
                    ...(prev?.value ?? {}),
                    [name ?? '']: {
                      ...(prev?.value?.[name as string] ?? {}),
                      value: {
                        ...(prev?.value?.[name ?? '']?.value ?? obj_unidos),
                        [item.name ?? '']: {
                          ...(prev?.value?.[name ?? '']?.value?.[item.name ?? ''] ?? obj_unidos[item.name]),
                          progressAmount: '100',
                        },
                      },
                    },
                  },
                }))
                const setEvidencesDrawerBind = setEvidencesDrawer
                setTimeout(() => {
                  setEvidencesDrawerBind((prev) => ({
                    ...prev,
                    value: {
                      ...(prev?.value ?? {}),
                      [name ?? '']: {
                        ...(prev?.value?.[name as string] ?? {}),
                        seeFiles: isEquealToMaximus && [EvidencesTypeValueEnum.SIGN.toString()].includes(name ?? ''),
                        value: {
                          ...(prev?.value?.[name ?? '']?.value ?? obj_unidos),
                          [item.name ?? '']: {
                            ...(prev?.value?.[name ?? '']?.value?.[item.name ?? ''] ?? obj_unidos[item.name]),
                            url: url,
                            progress: typeof url !== 'string',
                            upload: typeof url !== 'string',
                            progressAmount: undefined,
                          },
                        },
                      },
                    },
                  }))
                }, 200)
              } else {
                isLoad?.(false)
                delete valueRef?.current?.[`${name ?? ''}-${item?.name ?? ''}`]
                setEvidencesDrawer((prev) => ({
                  ...prev,
                  value: {
                    ...(prev?.value ?? {}),
                    [name ?? '']: {
                      ...(prev?.value?.[name as string] ?? {}),
                      value: {
                        ...(prev?.value?.[name ?? '']?.value ?? obj_unidos),
                        [item.name ?? '']: {
                          ...(prev?.value?.[name ?? '']?.value?.[item.name ?? ''] ?? obj_unidos[item.name]),
                          progress: true,
                          upload: true,
                          progressAmount: undefined,
                        },
                      },
                    },
                  },
                }))
              }
            }
          })
        } else {
          filesDontUpload = [...(filesDontUpload ?? []), item?.name ?? '']
        }
      })

      if ((!!filesDontUpload?.length || !!evidencesEithTheSameNAme?.length) && !isUpload) {
        setOpenModal((prev) => ({
          ...prev,
          title: '',
          description: undefined,
          children: (
            <Box sx={styles.containerUl}>
              {
                !!filesDontUpload?.length && (
                  <Box sx={styles.containerErrors}>
                    <Typography sx={styles.titleModal}>{titleFilesDontUpload}</Typography>

                    <ul>
                      {filesDontUpload.map((item) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  </Box>)
              }

              {
                !!evidencesEithTheSameNAme?.length && (
                  <Box sx={styles.containerErrors}>
                    <Typography sx={styles.titleModal}>{titleEvidencesEithTheSameNAme}</Typography>

                    <ul>
                      {evidencesEithTheSameNAme.map((item) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  </Box>)
              }
            </Box>
          ),
          open: true,
          removeCancel: true,
          method: () => {
            setOpenModal((prev) => ({
              ...prev,
              open: false,
              children: undefined,
              description: undefined
            }))
          },
        }))
      }
    } else {
      if (!!files && fileLength > 0) {
        setOpenSnackbar((prev) => ({
          ...prev,
          open: true,
          type: Types.ERROR,
          message: 'Se superó el máximo de archivos',
        }))
      }
    }
    if (fileRef?.current) {
      fileRef.current.value = ''
    }
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar((prev) => ({
      ...prev,
      open: false,
    }))
  }

  const seeFiles = ({ name, value }: { name: string; value: boolean }) => {
    setEvidencesDrawer((prev) => ({
      ...prev,
      value: {
        ...prev.value,
        ...(!!prev?.value?.[name]
          ? {
            [name]: {
              ...prev.value[name],
              seeFiles: value,
            },
          }
          : {}),
      },
    }))
  }
  const handleReason = () => {
    if (value?.reason?.value !== null) {
      if (value?.reason?.value == '') {
        return !props?.responded ? t('reportFolio.detail.general.mustAddedARejectReason') : t('reportFolio.detail.general.mustAddedARespondedReason')
      } else if ((value?.reason?.value?.length ?? 0) < 3) {
        return t('reportFolio.detail.general.rejectReasonMustHaveThreeCharacters')
      }
    }
    else {
      return null
    }
  }

  const deleteEvidence = ({ index, isDelete, isString }: { index: number | string; isString?: boolean; isDelete?: (value: boolean) => void }) => {
    const str = index.toString().split('-')[0]
    let title = ''
    switch (str) {
      case EvidencesTypeValueEnum.IMAGE.toString():
      case EvidencesTypeValueEnum.CAMERA.toString():
        title = t('reportFolio.snackbar.deleteImage')
        break
      case EvidencesTypeValueEnum.COMMENT.toString():
        title = t('reportFolio.snackbar.deleteComment')
        break
      case EvidencesTypeValueEnum.DOCUMENT.toString():
      case EvidencesTypeValueEnum.FILE.toString():
        title = t('reportFolio.snackbar.deleteDocument')
        break
      case EvidencesTypeValueEnum.SIGN.toString():
        title = t('reportFolio.snackbar.deleteSign')
        break
      case EvidencesTypeValueEnum.VIDEO_GALLERY.toString():
      case EvidencesTypeValueEnum.VIDEO.toString():
        title = t('reportFolio.snackbar.deleteVideo')
        break
      default: title = t('reportFolio.snackbar.areYouSureonDeleteThisFile')
        break
    }

    setOpenModal((prev) => ({
      ...prev,
      open: true,
      title,
      description: '',
      children: undefined,
      removeCancel: false,
      method: async () => {
        setIsLoadingConfirm(true)
        const splitIndex = index?.toString()?.split('-')
        const keyName = splitIndex?.splice(1).join('-')
        let evidenceCopy = structuredClone(evidencesDrawer?.value)
        if (evidenceCopy?.[splitIndex[0]]?.value) {
          delete evidenceCopy[splitIndex[0]].value?.[keyName]
        }
        const valueLength = Object.values(evidenceCopy?.[splitIndex[0]]?.value ?? {})?.length
        const minAmount = evidenceCopy?.[splitIndex[0]]?.minAmount ?? 0

        if (!isString) {
          const name = `folios/${props.folio}/${keyName}`
          const response = await sharedAws.delete({ name:name })
          if (!!response) {
            isDelete?.(true)
            setEvidencesDrawer((prev) => ({
              ...prev,
              value: evidenceCopy,
            }))
            if (minAmount > 0) {
              setEvidencesRequiredAreLoad((prev) => ({
                ...prev,
                [splitIndex[0] ?? '']: !minAmount || (!!minAmount && valueLength > 0),
              }))
            }
            handleCloseModal()
          } else {
            isDelete?.(false)
            setIsLoadingConfirm(false)

            setOpenSnackbar((prev) => ({
              ...prev,
              open: true,
              type: Types.ERROR,
              message: 'Ocurrio un error al eliminar el archivo',
            }))
          }
        } else {
          if (minAmount > 0) {
            setEvidencesRequiredAreLoad((prev) => ({
              ...prev,
              [splitIndex[0] ?? '']: !minAmount || (!!minAmount && valueLength > 0),
            }))
          }

          if (!!splitIndex?.[0] && evidenceCopy?.[splitIndex[0]]) {
            evidenceCopy = {
              ...evidenceCopy,
              [splitIndex?.[0] ?? '']: {
                ...evidenceCopy[splitIndex[0]],
                seeFiles: valueLength > 0,
              },
            }
          }
          setEvidencesDrawer((prev) => ({
            ...prev,
            value: evidenceCopy,
          }))

          handleCloseModal()
        }
      },
    }))
  }

  const handleCloseModal = () => {
    setIsLoadingConfirm(false)
    setOpenModal((prev) => ({
      ...prev,
      open: false,
    }))
  }

  const handleLoad = async ({ url, name, nameFIle }: { url: string | File; name: string; nameFIle?: string }) => {
    if (typeof url === 'string') {
      var image = await fetch(url)
      var imageBlog = await image.blob()
      saveAs(imageBlog, nameFIle)

    } else {
      getEvidences({
        e: {
          target: {
            files: { 0: url } as any,
          } as any,
        } as any,
        name,
        isUpload: true,
      })
    }
  }

  const handleCancelUpload = async ({ key }: { key: string }) => {
    if (!!key && valueRef?.current?.[key]) {
      const splitKey = key.split('-')
      const keyName = splitKey?.splice(1).join('-')
      setEvidencesDrawer((prev) => ({
        ...prev,
        value: {
          ...(prev.value ?? {}),
          [splitKey[0]]: {
            ...(prev?.value?.[splitKey[0]] ?? {}),
            value: {
              ...(prev?.value?.[splitKey[0]]?.value ?? {}),
              ...(!!splitKey[0] &&
                !!keyName &&
                !!prev?.value?.[splitKey[0]]?.value?.[keyName] && {
                [keyName]: {
                  ...prev.value[splitKey[0]].value?.[keyName],
                  progress: true,
                  upload: true,
                  progressAmount: '0',
                } as any,
              }),
            },
          },
        },
      }))
      const refCopy = await valueRef?.current?.[key]
      refCopy?.abort()
    }
  }

  const handleOpenDrawer = ({ value }: { value: boolean }) => {
    setEvidencesDrawer((prev) => ({
      ...prev,
      open: value,
    }))
  }

  const handleDisabled = () => {
    const reason = (value.reason?.value?.length ?? 0) < 3
    let lackOfEvidences = false
    let evidencesNeedEvidences: string[] = []

    Object.entries(evidencesRequiredAreLoad ?? {})
      ?.forEach(([key, value]) => {
        if (!value && (evidencesDrawer.value?.[key]?.minAmount ?? 0) > 0) {
          evidencesNeedEvidences.push(t(`general.evidences.${key}`))

          if (!lackOfEvidences) {
            lackOfEvidences = true
          }
        }

      })
    return { reason, value: reason || !!lackOfEvidences, evidencesTypes: evidencesNeedEvidences.join(',') }
  }

  const handleComments = (payload: { value: string; name: string; type: string }) => {
    const { name, type, value } = payload
    const totalLength = Object.values(evidencesDrawer?.value?.[type]?.value ?? {})?.length + 1
    const minAmount = evidencesDrawer?.value?.[type]?.minAmount ?? 0

    setEvidencesRequiredAreLoad((prev) => ({
      ...prev,
      [type]: !minAmount || (!!minAmount && totalLength > 0),
    }))
    setEvidencesDrawer((prev) => ({
      ...prev,
      value: {
        ...(prev.value ?? {}),
        [type]: {
          ...(prev.value?.[type] ?? {}),
          seeFiles: totalLength == prev.value?.[type]?.maxCount,
          value: {
            ...(prev.value?.[type].value ?? {}),
            [name]: {
              name,
              url: value,
            },
          },
        },
      },
    }))
  }

  const onChange: onChange = ({ target }) => {
    const { name, value } = target

    setValue((prev) => ({
      ...prev,
      [name]: {
        ...prev[name as 'reason'],
        value,
      },
    }))
  }

  useEffect(() => {
    if (!flag && !Object.values(evidencesDrawer.value ?? {})?.length) {
      let newEvidences: any = {}

      props?.evidencesConfig?.forEach((item) => {
        if (!!item?.isRequired) {
          setEvidencesRequiredAreLoad((prev) => ({
            ...prev,
            [item.evidence]: false,
          }))
        }

        if ([EvidencesTypeValueEnum?.IMAGE, EvidencesTypeValueEnum?.CAMERA].includes(item.evidence)) {
          if (item.evidence == EvidencesTypeValueEnum?.IMAGE || (item.evidence == EvidencesTypeValueEnum?.CAMERA && !!item?.isRequired)) {
            const imageValue = newEvidences?.[item.evidence]
            newEvidences = {
              ...newEvidences,
              [item.evidence]: {
                minAmount: !!item?.isRequired ? 1 : 0,
                accept: imageValue?.accept ? `${imageValue?.accept},${item.type?.join(',.')}` : `.${item.type?.join(',.')}`,
                maxCount: (imageValue?.maxCount ?? 0) + item.amount,
                size: item.size,
                typeEvidence: {
                  ...(imageValue?.typeEvidence ?? {}),
                  [item.evidence]: {
                    isRequired: !!item?.isRequired,
                    type: item.evidence,
                    amount: item.amount,
                  },
                },
              },
            }
          }
        } else if ([EvidencesTypeValueEnum?.VIDEO, EvidencesTypeValueEnum?.VIDEO_GALLERY].includes(item.evidence)) {
          if (item.evidence == EvidencesTypeValueEnum?.VIDEO_GALLERY || (item.evidence == EvidencesTypeValueEnum?.VIDEO && !!item?.isRequired)) {
            const videoValue = newEvidences?.[item.evidence]

            newEvidences = {
              ...newEvidences,
              [item.evidence]: {
                customeTitle: item.customeTitle,
                minAmount: !!item?.isRequired ? 1 : 0,
                accept: videoValue?.accept ? `${videoValue?.accept},${item.type?.join(',.')}` : `.${item.type?.join(',.')}`,
                maxCount: (videoValue?.maxCount ?? 0) + item.amount,
                size: item.size,
                typeEvidence: {
                  ...(videoValue?.typeEvidence ?? {}),
                  [item.evidence]: {
                    isRequired: !!item?.isRequired,
                    type: item.evidence,
                    amount: item.amount,
                  },
                },
              },
            }
          }
        } else {
          newEvidences = {
            ...newEvidences,
            [item.evidence]: {
              minAmount: !!item?.isRequired ? 1 : 0,
              accept: !!item.type?.length ? `.${item.type?.join(',.')}` : undefined,
              maxCount: (newEvidences?.[item.evidence ?? '']?.maxCount ?? 0) + item.amount,
              size: item.size,
              typeEvidence: {
                [item.evidence]: {
                  isRequired: !!item?.isRequired,
                  type: item.evidence,
                  amount: item.amount,
                },
              },
            },
          }
        }
      })
      setEvidencesDrawer((prev) => ({
        ...prev,
        value: newEvidences,
      }))
      setFlag(true)
    }
  }, [flag])


  useEffect(() => {
    let newCount: number | null = null

    Object.values(evidencesDrawer?.value ?? {})
      ?.forEach(item => {
        const value = item.value

        newCount = (newCount ?? 0) + Object.values(value ?? {})?.length
      })

    setCount(newCount)
  }, [evidencesDrawer.value])

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>{props.title}</Typography>

      <BaseInput sx={styles.errorsInput} name="reason" errors={{ reason: handleReason() }} onChange={onChange} value={value} type="alphaNumeric" multiline rows={10} />

      <Box sx={styles.buttonContainer}>
        {!!Object.entries(evidencesDrawer?.value ?? {})?.length && (
          <Badge sx={styles.badge} badgeContent={count}>
            <Button
              onClick={() => {
                handleOpenDrawer({ value: true })
              }}
              variant="contained"
              sx={[styles.buttons, styles.clip]}
            >
              {icons('clip')}
            </Button>
          </Badge>
        )}

        <Button
          onClick={() => {
            const { value: valueDisable, evidencesTypes } = handleDisabled()

            const arrevidencesTypes = evidencesTypes?.split(',')?.filter(item => !!item)

            if (valueDisable) {
              if (!!arrevidencesTypes?.length) {
                setOpenModal((prev) => ({
                  ...prev,
                  open: true,
                  title: ``,
                  description: undefined,
                  children: <Box sx={styles.containerUl}>
                    {
                      !!arrevidencesTypes?.length && (
                        <Box sx={styles.containerErrors}>
                          <Typography sx={styles.titleErrors}>{t('reportFolio.snackbar.lackOfEvidences')}</Typography>

                          <ul>
                            {arrevidencesTypes.map((item) => (
                              <li>{item}</li>
                            ))}
                          </ul>
                        </Box>)
                    }
                  </Box>,
                  removeCancel: true,
                  method: () => {
                    handleCloseModal()
                  },
                }))

              }
            } else {
              let body: {
                evidences: {
                  url: string
                  originalname: string
                  evidenceTypeId: number
                }[]
                comments: string[]
              } = { evidences: [], comments: [] }
              Object.entries(evidencesDrawer.value ?? {}).forEach(([key, value]) => {
                if (key == EvidencesTypeValueEnum.COMMENT?.toString()) {
                  body = {
                    ...body,
                    comments: Object.values(value?.value ?? {})?.map((item) => item.url as string),
                  }
                } else {
                  let evidences: any = []
                  Object.values(value?.value ?? {})?.forEach((item) => {
                    evidences = [
                      ...evidences,
                      {
                        url: item.url,
                        originalname: item.name,
                        evidenceTypeId: key,
                      },
                    ]
                  })
                  body = {
                    ...body,
                    evidences: [...body.evidences, ...evidences],
                  }
                }
              })

              setValue((prev) => ({
                ...prev,
                reason: {
                  ...prev.reason,
                  value: null,
                },
              }))
              props.sendReason({ reason: value.reason?.value ?? '', body })
            }
          }}
          variant="contained"
          sx={[styles.buttons, styles.buttonColorB, styles.buttonArrow]}
        >
          {icons('arrow-right')}
        </Button>
      </Box>

      <Drawer sx={styles.drawer} anchor="right" open={evidencesDrawer.open}>
        <Box sx={styles.drawerContainer}>
          <Box sx={styles.arrowHeaderDrawerContainer}>
            <IconButton
              sx={styles.closeSettings}
              onClick={() => {
                handleOpenDrawer({ value: false })
              }}
            >
              {icons('arrow-left-fill')}
            </IconButton>

            <Typography>{t('reportFolio.detail.general.loadEvidences')}</Typography>
          </Box>

          {Object.entries(evidencesDrawer?.value ?? {})
            ?.sort((a, b) => newOrederEvidences[a[0]] - newOrederEvidences[b[0]])
            ?.map(([key, item], index) => {
              return (
                <CustomAccordion
                  expanded={!!item?.expanded}
                  onChange={(ex) => {
                    seeFiles({ name: key, value: true })
                    handleExpanded(key, ex)
                  }}
                  key={index}
                  title={
                    <Box sx={[styles.flex]} mr={1}>
                      <Typography sx={styles.containerTitle}>
                        {item.customeTitle ?? t(`general.evidences.${key ?? ''}`)}
                        {!!((item?.minAmount ?? 0) > 0) ? <span> *</span> : ''}
                      </Typography>

                      <Box sx={styles.containerSee}>
                        <Box sx={styles.amountContainer}>
                          <Typography sx={styles.amount}>{![EvidencesTypeValueEnum.SIGN.toString(), EvidencesTypeValueEnum.COMMENT.toString()].includes(key) ? `Max: ${item.size} mb` : ''}</Typography>

                          <Typography sx={styles.amount}>
                            {Object.values(item.value ?? {})?.length} / {item.maxCount ?? 0}
                          </Typography>
                        </Box>

                        {[EvidencesTypeValueEnum.SIGN.toString(), EvidencesTypeValueEnum.COMMENT.toString()].includes(key) && Object.values(item.value ?? {})?.length > 0 && (
                          <IconButton
                            onClick={(e) => {
                              if (!!item.expanded) {
                                e.stopPropagation()
                              }
                              if (!!item.expanded && !!item.seeFiles) {
                                handleExpanded(key, false)
                              }
                              seeFiles({ name: key, value: true })
                            }}
                            sx={styles.see}
                          >
                            {icons('visibility')}
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  }
                  description={
                    <TypesEvidences
                      customeKey={key}
                      index={index}
                      handleComments={handleComments}
                      key={key}
                      seeFiles={item?.seeFiles}
                      isRequired={(item?.minAmount ?? 0) > 0}
                      value={item.value}
                      evidencesType={key}
                      size={item.size}
                      files={{
                        deleteEvidence,
                        handleCancelUpload,
                        handleLoad,
                        getEvidences,
                      }}
                    />
                  }
                  expandIcon={
                    (Object.values(item?.value ?? {})?.length ?? 0) < (item?.maxCount ?? 0) ? (
                      <IconButton
                        sx={styles.add}
                        onClick={(e) => {
                          e.stopPropagation()
                          handleExpanded(key, true)

                          seeFiles({ name: key, value: false })
                          if (galleryAndComment.includes(key)) {
                            addEvidences({ e, name: key, accept: item.accept })
                          }
                        }}
                      >
                        {icons('add-outline')}
                      </IconButton>
                    ) : (
                      <></>
                    )
                  }
                />
              )
            })}

          <input
            accept={evidencesDrawer.type.accept}
            ref={fileRef}
            multiple
            style={styles.files}
            type="file"
            onChange={(e) => {
              getEvidences({ e, name: evidencesDrawer?.name })
            }}
          />
        </Box>
      </Drawer>

      <SnackSimple close={handleCloseSnackbar} open={openSnackbar.open} type={openSnackbar.type} message={openSnackbar.message} />

      <SimpleModal
        open={openModal.open}
        close={handleCloseModal}
        title={openModal.title}
        description={openModal.description}
        children={openModal.children}
        buttonText={t('general.confirm')}
        cancelText={!!openModal?.removeCancel ? undefined : t('general.cancel')}
        color={colors}
        back={!!openModal?.removeCancel ? undefined : handleCloseModal}
        loadingButton
        isLoading={isLoadingConfirm}
        next={() => {
          openModal?.method?.()
        }}
      />
    </Box>
  )
}

export { RejectReason }
