import { Box, IconButton } from '@mui/material'
import { ChangeEvent, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { icons } from '../../../utils/icons'
import { Images } from '../../image'
import BaseInput from '../baseInput'
import { useRef } from 'react'
import { useStyles } from './styles'
import { Props } from './props'
import { useContextState } from '../../../hooks/theme'

const SimpleFile = ({ item, values, errors, onChange, customeIcon, notImage }: Props) => {
  const { colors } = useContextState()
  const styles = useStyles({errors: errors?.[item.name], colors})
  const { t } = useTranslation()
  let file = useRef<HTMLInputElement | null>(null)
  const imageIsString = typeof values !== 'string' && !!values
  const images = imageIsString ? URL.createObjectURL(values) : values
  const imageName = !imageIsString ? images?.split("_").slice(1).join('_') || images : ''
  
  const handleChange = (e: any) => {
      onChange(e)
    if (file?.current) file.current.value = ''
  }
  
  const removeImage = (e?:MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    e?.stopPropagation()
    onChange({target: {name: item?.name ?? '', value: null}})
  }
  return (
    <Box sx={styles.containerPreview} key={item.name}>
      <Box /* className={classes.containerInputImage} */>
        <Box >
          <BaseInput
            pushError={item.pushError}
            name={item?.name ?? ''}
            value={{
              [item?.name ?? '']: {
                ...item,
                value: (values as File)?.name ?? imageName,
                label: `${item?.translate ? t(item?.label) : item?.label} ${!!item?.validate?.required ? '*' : ''}`,
              },
            }}
            onChange={(e) => {
              handleChange({
                target: {
                  name: item?.name,
                  value: item?.rest?.disabled
                    ? e.target.value
                    : values,
                },
              })
            }}
            errors={errors}
            {...item.rest}
            disabled={true}
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={styles.icon}
                  onClick={() => {
                    if (!item?.rest?.disabled && file?.current) {
                      file.current.click()
                    }
                  }}
                >
                  {images && !notImage ? <Box><IconButton onClick={(e) => {removeImage(e)}} sx={styles.close}>{icons('close')}</IconButton><Images src={images} sx={styles.image} /></Box> : icons(customeIcon ?? 'folder')}
                </IconButton>
              ),
            }}
          />
        </Box>
      </Box>

      <input
        name={item?.name ?? ''}
        type="file"
        onChange={(e: ChangeEvent<HTMLInputElement>) =>{
          e?.target?.files?.[0] &&
          handleChange({
            target: {
              name: item?.name,
              value: !item?.rest?.disabled
              ? e.target.files[0]
              : values,
            },
          })
        }}
        hidden
        ref={file}
        accept={item?.rest?.accept ?? ".png"}
      />
    </Box>
  )
}

export default SimpleFile
