import { useContextState } from "../../../../hooks/theme"

const useStyles = () => {
  const { colors } = useContextState()

  return {
    container: {
      width: '100%',
      height: '100%',
      padding: '50px 50px 20px 10px',
      position: 'relative'
    },
    overflowHidden: {
      overflow: 'hidden'
    },
    checkIcon: ({ color }: { color: boolean }) => ({
      '& .MuiSvgIcon-root': {
        color: !!color ? colors.color19 : colors.color8,
      },
      '&.Mui-disabled': {
        '& .MuiSvgIcon-root': {
          color: !!color ? colors.color19 : colors.color13,
        },
      },
    }),
    bugBadge: (noLimit?: boolean) => ({
      '& .MuiBadge-badge': {
        background: colors.color20,
        color: colors.color25,
        ...noLimit && {
          padding: '15px',
          top: '-10px',
          right: '-10px',
          width: 'max-content',
        }
      },
    }),
    activeBug: ({ open }: { open: boolean }) => ({
      background: open ? colors.color4 : colors.color13,
      '&.MuiIconButton-root': {
        padding: '6px'
      },
      '&:hover': {
        background: `${open ? colors.color4 : colors.color13}ee`
      }
    }),
    containerReportFolio: {
      width: '500px',
      padding: '0px 20px'
    },
    containerBug: ({ padding, disabled }: { padding?: number; disabled: boolean }) => ({
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      columnGap: '20px',
      cursor: 'pointer',
      '& .MuiIconButton-root': {
        padding: padding ? `${padding}px` : '14px',
        ...!!disabled && {
          background: colors.color10,
          color: colors.color5,
          '&:hover': {
            background: colors.color10 + 'dd',
          },
        },
      },
    }),
    evidencesType: {
      '& .evidences-type-clip': {
        maxWidth: '200px',
      },
      wordBreak: 'break-word',
    },
    badge: (noLimit?: boolean) => ({
      margin: '10px 0px',
      '& .MuiBadge-badge': {
        background: colors.color20,
        color: colors.color25,
        ...noLimit && {
          padding: '15px',
          top: '-10px',
          right: '-10px',
          width: 'max-content',
        }
      },
    }),
    containerOptions: {
      display: 'flex',
      gap: '20px',
      alignItems: 'center',
      marginBottom: '16px'
    },
    border: {
      '& li:not(:last-child)': {
        borderBottom: `1px solid ${colors.color13}`,
      },
    },
    bug: {
      color: colors.color5,
      borderRadius: '5px',
      boxShadow: `1px 1px 2px ${colors.color10}`,
      '& .MuiSvgIcon-root': {
        fontSize: '1.7rem',
      },
      marginBottom: '2px',
    },
    activeClip: {
      background: colors.color30,
      '&:hover': {
        background: colors.color30 + 'dd',
      },
    },
    warning: {
      fontSize: '12px',
      paddingTop: '5px',
      fontStyle: 'italic',
      color: '#828282',
      '& span': { paddingRight: '5px', color: 'red' },
    },

    warningIcon: {
      color: colors.color19,
      width: '40px',
      height: '40px',
      '& .MuiSvgIcon-root': {
        fontSize: '40px',
      },
    },
    title: {
      textAlign: 'left',
      width: '100%',
    },
    header: {
      width: '100%',
      display: 'flex',
      justifyContent: "space-between",
      alignItems: "end",
      columnGap: "20px",
      marginBottom: "20px",
      "& .back": {
        color: colors.color4,
      },
      "& .icon": {
        width: '40px',
        height: '40px'
      },
      "& .header-main": {
        width: 'calc(100% - 200px)'
      }
    },
    floatButtons: {
      paddingRight: '10px',
      borderRadius: '5px',
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0',
      zIndex: 1000,
    },
    positionManage: {
      background: colors.color31,
      '&:hover': {
        background: colors.color31 + 'DD',
      },
      color: colors.color5,
      position: 'absolute',
      right: '0px',
      top: '40px',
    },
    totalPointError: {
      // width: '100%',
      fontSize: '12px',
      color: colors.color19,
    },
    positionSetting: {
      background: colors.color32,
      '&:hover': {
        background: colors.color32 + 'DD',
      },
      color: colors.color5,
      position: 'absolute',
      right: '0px',
      top: '90px',
    },
    // TABS
    containerTabList: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      gap: '20px',
    },
    close: {
      position: 'absolute',
      top: '20px',
      right: '30px'
    },
    footer: {
      borderTop: `1px solid ${colors.color13}66`,
      padding: '20px 30px',
      width: '100%',
      display: 'flex',
      justifyContent: 'end'
    },
    headerOrder: {
      borderBottom: `1px solid ${colors.color13}66`,
      padding: '30px',
      position: 'relative'
    },
    totalPoints: ({ totalPointError }: { totalPointError: boolean }) => ({
      width: totalPointError ? '350px' : '150px',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'end',
      paddingBottom: '5px',
      '& .total': {
        color: colors.color19,
      }
    }),
    tabContainer: ({ error }: { error?: boolean }) => {
      return {
        '&.MuiTabs-root': {
          width: '100%'
        },
        '& .MuiTabs-scroller': {
          position: 'initial'
        },
        '& .MuiTabs-flexContainer': {
          overflowX: 'auto',
          transition: '.3s',
          columnGap: '2px',
          paddingLeft: '20px',
          paddingTop: '10px',
          '& .MuiTab-root': {
            minHeight: '40px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            boxShadow: `0 0 2px ${colors.color13}`,
            backgroundColor: `${colors.color10}44`,
            overflow: 'initial',
            // paddingRight: '5px',
            color: colors.color10,
            '&.Mui-selected': {
              backgroundColor: colors.color5,
            },
          },
        },
        '& .MuiTabs-indicator': {
          backgroundColor: 'transparent',
        },
      }
    },
    loadingContainer: {
      background: colors.color5,
      zIndex: 10000,
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: '0px',
      left: '0px',
    },
    errorSection: {
      width: '18px',
      height: '18px',
      background: colors.color12,
      borderRadius: '50px',
      position: 'absolute',
      top: '-5px',
      right: '-5px',
      '& .MuiTypography-root': {
        color: colors.color5,
        fontWeight: '500',
        fontSize: '12px',
      },
    },
    removeSection: {
      '&.MuiIconButton-root': {
        padding: '5px',
        '& .MuiSvgIcon-root': {
          width: '15px',
          height: '15px',
        },
      },
    },
    answersContainer: {
      display: 'flex',
      alignItems: "start",
      width: "100%",
    },
    answerActions: {
      width: "fit-content",
      display: 'flex',
      alignItems: "center",
      justifyContent: "end",
      gap: '10px',
      "& .delete": {
        color: colors.color19
      }
    },
    settingMultiple: {
      position: "absolute",
      bottom: '20px',
      right: "30px",
      display: 'flex',
      alignItems: 'center',
      gap: '20px'
    },
    setting: {
      width: '40px',
      height: '40px',
      position: "relative",
      '&.MuiIconButton-root': {
        borderRadius: '5px',
        cursor: 'default',
        padding: '0px',
      },
    },
    errorActionPLan: {
      width: '18px',
      height: '18px',
      background: colors.color12,
      borderRadius: '50px',
      position: 'absolute',
      top: '-5px',
      left: '-5px',
      '& .MuiTypography-root': {
        color: colors.color5,
        fontWeight: '500',
        fontSize: '12px',
      },
    },
    boxShadow: {
      '&.MuiIconButton-root': {
        boxShadow: `1px 1px 2px ${colors.color10}`,
        padding: '5px',
        cursor: 'pointer',
      },
    },
    addIcon: {
      '& .MuiSvgIcon-root': {
        color: colors.color4,
      },
    },
    options: {
      width: "100%",
      display: 'flex',
      justifyContent: "space-between",
    },
    addAsk: {
      width: 'fit-content',
      marginTop: '20px',
    },
    listAnswers: {
      margin: '20px 2px',
      boxShadow: `0 0 3px ${colors.color13}`,
      padding: '20px 30px',
      paddingTop: '50px',
      borderRadius: '5px',
      position: 'relative',
    },
    questionDelete: {
      position: "absolute",
      top: '0px',
      right: '15px',
      color: colors.color19,
    },
    TabsContainer: {
      boxShadow: `0 0 3px ${colors.color13}`,
      borderRadius: '5px',
    },
  }
}

export { useStyles } 
