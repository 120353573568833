import { useTranslation } from "react-i18next";
import { useStyles } from "../progressList/styles";
import { Box, Grid, Typography } from "@mui/material";
import { useDimensions } from "../../../../../../hooks/dimensions";
import { TypeStatusList } from "../progressList/typing/interfaces";
import { useMemo } from "react";
import { Status } from "../../../../../../utils/enums";
import { getStatus } from "../../../../../../hooks/status";
import { useContextState } from "../../../../../../hooks/theme";

const ColorItemList = ({ colorStatus }: { colorStatus: TypeStatusList[] }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { colors } = useContextState()
  const statusList = useMemo(() => { return colorStatus.map(item => ({ ...item, label: item.status == Status.WAITING ? "En progreso" : getStatus(item.status, colors, t).text })) }, [])
  const { dimensions: { width } } = useDimensions()
  const lg = width < 1380 && width > 1180
  if (!colorStatus?.length) { return null }
  return (
    <Grid sx={styles.statusContainer} container rowGap={2}>
      {
        statusList?.map((item, index) => (
          <Grid item lg={lg ? 3 : 2} md={2} sm={4} xs={6} key={index}>
            <Box sx={styles.content}>
              <Box sx={styles.indicator({ color: item.color })} />

              <Typography
                className="indicator"
                sx={styles.legend()}
              >
                {item.label}
              </Typography>
            </Box>

          </Grid>
        ))
      }
    </Grid>
  )
}

export { ColorItemList }