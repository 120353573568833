import { Box, IconButton, IconButtonProps, Tooltip, Typography } from "@mui/material"
import { icons } from "../../utils/icons"
import { useTranslation } from "react-i18next"
import { useDateWithActionsStyles } from "./dateWithActionsStyles";

const DateWithActions = (props: {
    date: Date; title: string; url: string;
    visibilityProps?:IconButtonProps
    resendProps?:IconButtonProps
    resendMail?: boolean
    id?: string
}) => {
    const styles = useDateWithActionsStyles()
    const { t } = useTranslation()
    return (
        <Box sx={[styles.icons, styles.containerIcons]}>
            <Typography sx={styles.text2}>{`${props.title}: ${props.date}`}</Typography>

            <Box sx={styles.containerActions}>
                {
                    !!props.resendMail && (
                        <Tooltip title={t('schedule.modal.resendQuestionnaireReport')}>
                            <IconButton
                                {...props.resendProps}
                            >
                                {icons('send-email')}
                            </IconButton>
                        </Tooltip>
                    )
                }

                <Tooltip title={t('schedule.modal.displayQuestionnaireReport')}>
                    <IconButton
                        disabled={!props?.url}
                        {...props.visibilityProps}
                    >
                        {icons('visibility')}
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    )
}

export { DateWithActions }
