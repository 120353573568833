import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { SnackSimple } from '../../../../components'
import { CRUD, Status, Types } from '../../../../utils/enums'
import { InformativeCard } from '../../../../utils/types'
import { Item, ObjectGeneric, onChange, onChangeEvent, Table } from '../../../../utils/types/generals'
import { initialData, initialTable, initialValues, useMenuItems, input } from './initialState'
import { filterRow, getList, handleErrors, submit } from './methods'
import { useStyles } from './style'
import Form from './components/form'
import { generalDisabled } from '../../../../utils/validate/validate'
import { statusService } from '../../../../service'
import { useContextState } from '../../../../hooks/theme'
import { divisionService } from '../../../../service/division'
import { httpFetch } from '../../../../utils/crud'
import SimpleModal from '../../../../components/modal/simpleModal'
import General from '../../../../components/modules/general'
import { icons } from '../../../../utils/icons'
import { FiltersDrawer } from '../../../../components/filtersDrawer'
import { InputModel } from '../../../../utils/types'
import { Box, IconButton, Typography } from '@mui/material'
import { DinamicForm } from '../../../../components/dinamicForm'
import { monthYear } from '../../../../utils/constants/date_month'
import { ValidateDateRange } from '../../../../utils/methods/validateDateRange'
import DownloadFiles from '../../../../components/donwloadFiles'

const Division = () => {
  const { t } = useTranslation()
  const { colors } = useContextState()
  const styles = useStyles(colors)
  const [inputValues, setInputValues] = useState(initialValues)
  const [information, setInformation] = useState<InformativeCard | null>(null)
  const [data, setData] = useState<ObjectGeneric>({})
  const [btnLoading, setBtnLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [flag, setFlag] = useState(false)
  const [allErrors, setAllErrors] = useState<any>({})
  const [table, setTable] = useState<Table>(initialTable)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [dataFilter, setDataFilter] = useState<ObjectGeneric>({})

  const [counter, setCounter] = useState<number | null>(null)
  const [inputs, setInputs] = useState<{ [x: string]: InputModel }>(input)
  const [erros, setErrors] = useState<ObjectGeneric>({})

  const detailToInclude = ['description', 'cveDivision', 'createTime']
  const [open, setOpen] = useState(false)
  const [row, setRow] = useState<Table['row']>([])
  const [selected, setSelected] = useState<number>(-1)
  const [crud, setCrud] = useState(false)
  const [drawerDetail, setDrawerDetail] = useState({
    title: '',
    accept: '',
    cancel: '',
  })

  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
    type: Types.SUCCESS as string,
  })
  const menuList = useMenuItems(styles)
  const [menu, setMenu] = useState<
    {
      label: string
      icon?: any
      value?: any
    }[]
  >(menuList)

  const handleCloseSnackbar = (value: boolean) => setOpenSnackbar({ ...openSnackbar, open: value })

  const crudChange = async (e: onChangeEvent | string): Promise<void> => {
    if (typeof e !== 'string') {
      const { name, value } = e?.target

      let body = {
        ...data,
        [name]: value?.value ?? value,
      }

      setData(body)
      setAllErrors(handleErrors(body, t))
    }
  }

  const handleSubmit = async () => {
    setBtnLoading(true)
    const response = await submit(data, t, information?.id)
    if (!response?.response?.errors) getAllDivisions()
    setOpenSnackbar(response.snackbarBody)
    setBtnLoading(false)
  }

  const handleInformation = (e: any, index: number) => {
    setSelected(index)
    openDrawer(true)
    let data: any[] = []
    setAllErrors({})
    if (e) {
      Object.entries(e ?? {}).forEach(([key, value]: any) => {
        if (!['status', 'id'].includes(key)) {
          data = [
            ...data,
            {
              label: t(`general.tags.${key}`),
              value: value?.label ?? value,
              selected: value?.value ?? value,
              name: key,
            },
          ]
        }
      })
      setMenu(menuList?.filter((item) => item.value !== e.status?.value))
    }

    setInformation(
      e
        ? {
          data,
          tmpData: data,
          status: {
            color: e.status.color,
            text: e.status.text,
            value: e.status?.value,
          },
          id: e.id,
        }
        : null
    )

    setData(initialData)
  }

  const handleMenu = (item: any) => {
    setBtnLoading(true)
    if (item.value === CRUD.EDIT) {
      setCrud(true)
      let body = {
        status: information?.status?.value,
      } as ObjectGeneric
      information?.tmpData?.forEach((element) => {
        body = {
          ...body,
          [element.name as string]: element?.selected ?? element.value,
        }
      })
      setData(body)
      setBtnLoading(false)
    } else if (item.value === CRUD.DELETE) {
      setOpenModalDelete(true)
    }
    setBtnLoading(false)
  }

  const resetValues = () => {
    setCrud(false)
    setOpen(false)
    setData(initialData)
    setBtnLoading(false)
    setSelected(-1)
  }
  const getAllDivisions = useCallback(async () => {
    try {
      const responseDivision = await getList(colors, t)
      let createYears: Item[] = []
      let status: Item[] = []
      responseDivision?.row?.forEach((item: any) => {
        if (!createYears.some((el) => el?.value === item?.createYear)) {
          createYears = [
            ...createYears,
            {
              label: item?.createYear,
              value: item?.createYear,
            },
          ]
        }
        if (!status.some((el) => el?.value === item?.status?.value)) {
          status = [
            ...status,
            {
              label: item?.status?.text,
              value: item?.status?.value,
            },
          ]
        }
      })
      setInputs((prev) => ({
        ...prev,
        createYear: {
          ...prev.createYear,
          items: createYears,
        },
        status: {
          ...prev.status,
          items: status,
        },
        month: {
          ...prev.month,
          items: monthYear,
        },
      }))

      setRow(responseDivision.row)
      setTable(responseDivision)
      resetValues()
    } catch (e) {
      setTable({
        column: [],
        row: [],
      })
    } finally {
      setIsLoading(false)
      setData(initialData)
      setDataFilter({})
    }
  }, [colors, t])

  const handleDelete = () => {
    if (information?.id) {
      setBtnLoading(true)
      divisionService(httpFetch)
        .deleteDivision(information.id)
        .then((response) => {
          if (!response.errors) {
            getAllDivisions()
            setOpenSnackbar({
              open: true,
              type: Types.SUCCESS as string,
              message: t(`division.snackbar.delete.success`),
            })
          } else {
            setOpenSnackbar({
              open: true,
              type: Types.ERROR as string,
              message: t(`division.snackbar.delete.error`),
            })
          }
        })
        .catch(() => {
          setOpenSnackbar({
            open: true,
            type: Types.ERROR as string,
            message: t(`division.snackbar.delete.error`),
          })
        })
        .finally(() => {
          setBtnLoading(false)
          setOpenModalDelete(false)
        })
      return true
    }
  }

  const getData = useCallback(async () => {
    setFlag(true)
    setIsLoading(true)
    getAllDivisions()
    const [responseStatus] = await Promise.all([statusService().statusList()])
    let valideStatus: any[] = []

    responseStatus?.forEach((item: any) => {
      if ([Status.ACTIVE, Status.INACTIVE].includes(item.id)) {
        valideStatus.push({
          value: item.id,
          label: item.description,
        })
      }
    })

    setInputValues({
      ...initialValues,
      status: {
        ...initialValues.status,
        items: valideStatus,
      },
    })
    // setStatusList(valideStatus)
  }, [getAllDivisions])


  const openDrawer = (edit?: boolean) => {
    setDrawerDetail({
      title: t(`division.informationCard.${edit ? 'edit' : 'create'}.title`),
      accept: t(`division.informationCard.buttons.${edit ? 'edit' : 'add'}`),
      cancel: t('userType.informationCard.buttons.cancel'),
    })
    if (!edit) {
      setData(initialData)
    }
    setInformation(null)
    setAllErrors({})
    setCrud(!edit)
    setOpen(true)
  }

  const closeDrawer = () => {
    setOpen(false)
    setCrud(false)
    setSelected(-1)
  }
  const cleanFilter = () => {
    // setFilterData(initialFilterValues)
    setDataFilter({})
    setErrors({})
    setRow(table.row)
  }
  const onChange: onChange = ({ target }, blur) => {
    const { name, value } = target

    const { endDate, startDate } = ValidateDateRange({ name, value, blur, from: dataFilter?.from, to: dataFilter?.to })

    if (!['from', 'to'].includes(name) || !(blur as { blur: boolean })?.blur) {
      const body = {
        ...dataFilter,
        [name]: value,
      }

      setDataFilter(body)
      setRow(filterRow(body, table.row))
    }

    if (startDate && endDate) {
      if (startDate > endDate) {
        setErrors((prev) => ({
          ...prev,
          [name]: t('general.wrongDate'),
        }))
      } else {
        setErrors((prev) => ({
          ...prev,
          from: null,
          to: null,
        }))
      }
    }

    if (!value) {
      setErrors((prev) => ({
        ...prev,
        [name]: null,
      }))
    }
  }

  useEffect(() => {
    setCounter(null)
    let count = 0
    for (let key in dataFilter) {
      if (dataFilter[key] instanceof Array) {
        if (dataFilter[key]?.length > 0) count++
      } else if (dataFilter[key] != null && dataFilter[key] !== '') count++
    }
    setCounter(count)
  }, [dataFilter])

  useEffect(() => {
    if (!flag) getData()
  }, [getData, flag])
  // if (isLoading) return <Loader />
  return (
    <>
      <SnackSimple close={handleCloseSnackbar} open={openSnackbar.open} type={openSnackbar.type} message={openSnackbar.message} />
      <SimpleModal
        loadingButton
        isLoading={btnLoading}
        open={openModalDelete}
        close={() => {
          setOpenModalDelete(false)
        }}
        title={t('division.modal.delete.title')}
        description={''}
        buttonText={t('general.confirm')}
        cancelText={t('general.cancel')}
        color={colors}
        back={() => {
          setOpenModalDelete(false)
        }}
        next={handleDelete}
      />

      <General
        newHeader={
          <Box sx={styles.header}>
            <Typography sx={styles.title}>{t('division.filter.title')}</Typography>

            <Box sx={styles.flex}>
              <DownloadFiles
                row={row}
                column={table.column}
                name={t('division.filter.title')}
                csvText={'EXCEL'}
                removeMargin
              />

              <IconButton
                sx={(styles.clean, (counter || 0) > 0 ? null : styles.hide)}
                onClick={() => {
                  cleanFilter()
                }}
              >
                {icons('clean')}
              </IconButton>
              
              <IconButton
                sx={styles.clean}
                className='add'
                onClick={() => {
                  openDrawer()
                }}
              >
                {icons('add')}
              </IconButton>
            </Box>
          </Box>
        }
        open={open}
        rightDrawer={{
          ...information,
          data: information?.data?.filter((item) => detailToInclude.includes(item?.name ?? '')),
          form: <Form btnLoading={btnLoading} data={data} input={Object.values(inputValues)} crudChange={crudChange} allErrors={allErrors} />,
          menuItems: menu,
          crud,
          title: drawerDetail.title,
          accept: {
            title: drawerDetail.accept,
            disabled: generalDisabled(inputValues, data) || btnLoading,
            onClick: handleSubmit,
          },
          cancel: {
            title: drawerDetail.cancel,
            onClick: closeDrawer,
          },
          clickMenu: handleMenu,
        }}
        table={{
          column: table.column,
          row: row,
          title: t('division.filter.title'),
          loadingTable: isLoading,
          onClick: handleInformation,
          document: {
            export: true,
          },
          selected,
        }}
        filtersDrawer={
          <>
            <FiltersDrawer cleanFilter={cleanFilter} count={counter} right={-50} top={0}>
              <Box sx={styles.section}>
                <DinamicForm errors={erros} input={Object.values(inputs)} values={dataFilter} onChange={onChange} filter={true} />
              </Box>
            </FiltersDrawer>

            <Box sx={{ position: 'absolute', right: '40px', top: '60px' }}>
              <IconButton
                sx={(styles.clean, (counter || 0) > 0 ? null : styles.hide)}
                onClick={() => {
                  cleanFilter()
                }}
              >
                {icons('clean')}
              </IconButton>
            </Box>
          </>
        }
      />
    </>
  )
}

export default Division
