import { InputModel } from '../../../../../utils/types'

const usersInput: Record<string, InputModel> = {
  search: {
    label: 'general.search',
    translate: true,
    name: 'search',
    type: 'text',
    value: null,
  },
  userType: {
    label: 'general.tags.userType',
    translate: true,
    name: 'userType',
    type: 'filter-select',
    items: [],
    value: null,
  },
}

const questionnairesInput: Record<string, InputModel> = {
  search: {
    label: 'general.search',
    translate: true,
    name: 'search',
    type: 'text',
    value: null,
  },
  types: {
    label: 'general.tags.actionPlanType',
    translate: true,
    name: 'types',
    type: 'filter-select',
    items: [
      {
        label: 'Por item',
        value: '0',
      },
      {
        label: 'Por Cuestionario',
        value: '1',
      },
    ],
    value: null,
  },
}

const scheduleInput: Record<string, InputModel> = {
  search: {
    label: 'general.search',
    translate: true,
    name: 'search',
    type: 'text',
    value: null,
  },
  users: {
    label: 'general.tags.users',
    translate: true,
    name: 'users',
    type: 'filter-select',
    items: [],
    value: null,
  },
  questionnaires: {
    label: 'general.tags.questionnaires',
    translate: true,
    name: 'questionnaires',
    type: 'filter-select',
    items: [],
    value: null,
  },
}

export { usersInput, questionnairesInput, scheduleInput }
