import { useContextState } from "../../../../../../hooks/theme"

const useDetailModalStyles = () => {
  const { colors: color } = useContextState()

  return {
    modal: {
      width: '900px',
      minHeight: '400px',
      maxHeight: 'calc(100vh - 100px)',
      overflowY: 'auto',
      padding: '30px 0px 30px 30px',
    },
    close: {
      position: 'absolute',
      top: '5px',
      right: '5px',
    },
    sectionTitle: {
      margin: '10px 0',
      fontSize: '16px',
      color: color.color10
    },
    containerAccordion: {
      width: "100%",
      display: 'flex',
      flexWrap: "wrap",
      rowGap: "10px",
      maxHeight: "600px",
      overflowY: "auto",
      '& .MuiAccordion-root': {
        '&.Mui-expanded': {
          margin: 0,
        },
        background: color.color25
      }
    },
    containerIconsHeader: {
      width: 'calc(100% - 16px)',
      display: 'flex',
      justifyContent: 'space-between',
    },
    titleAccordion2: {
      fontSize: '14px',
      color: color.color13,
      fontWeight: '600'
    },
    titleAccordion: {
      display: 'flex',
      width: '100%',
      justifyContent: "space-between",
      position: "relative",
      paddingRight: "40px"
    },
    text2: {
      fontSize: '.8rem',
      color: color.color13,
    },
    icons: {
      '& .MuiIconButton-root': {
        padding: '0',
        borderRadius: '10px',
        color: color.color4,
      },
      '& .MuiSvgIcon-root': {
        marginRight: '5px',
      },
      '& .MuiTypography-root': {
        fontSize: '.8rem',
      },
    },
    containerIcons: {
      display: "flex",
      alignItems: "start",
      '& .MuiIconButton-root': {
        padding: '2px',
        fontSize: '20px',
        '& .MuiSvgIcon-root': {
          fontSize: 'inherit'
        }
      },
    },
    detail: {
      height: 'calc(100vh - 170px)',
      '& .detail-container': {
        paddingLeft: '0',
      },
    },
    subTitle: {
      fontSize: '14px',
      fontWeight: '600',
      color: color.color26,
      marginBottom: '10px',
    },
    ul: {
      paddingLeft: '20px',
      fontSize: '13px',
      color: color.color9,
      fontWeight: '500',
    },
    ulNull: {
      fontSize: '13px',
      color: color.color9,
      fontWeight: '500',
      width: 'fit-content',
    },
    label: {
      fontSize: '12px',
      fontWeight: '400',
      color: color.color10,
    },
    containerDetails: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    details: {
      alignItems: 'center',
      '& .detail-caption': {
        width: 'fit-content',
        fontWeight: '500'
      },
      '& .detail-value.detail-value': {
        width: 'fit-content'
      },
      padding: '0px'
    },
    date: {
      fontSize: '.6rem',
      color: color.color13,
    }
  }
}

export { useDetailModalStyles }
