import { InputModel } from '../../../../../utils/types'

export const inputColors = {
  color15: {
    label: 'general.tags.color0',
    translate: true,
    name: 'color15',
    type: 'color',
    validate: {
      required: true,
    },
    rest: {
      description: 'companie.brandCustomization.description.color0',
    },
    value: null,
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
    },
  },
  color16: {
    label: 'general.tags.color1',
    translate: true,
    name: 'color16',
    type: 'color',
    validate: {
      required: true,
    },
    rest: {
      description: 'companie.brandCustomization.description.color1',
    },
    value: null,
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
    },
  },
  color17: {
    label: 'general.tags.color2',
    translate: true,
    name: 'color17',
    type: 'color',
    validate: {
      required: true,
    },
    rest: {
      description: 'companie.brandCustomization.description.color2',
    },
    value: null,
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
    },
  },
  color09: {
    label: 'general.tags.color3',
    translate: true,
    name: 'color09',
    type: 'color',
    validate: {
      required: true,
    },
    rest: {
      description: 'companie.brandCustomization.description.color3',
    },
    value: null,
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
    },
  },
  color06: {
    label: 'general.tags.color4',
    translate: true,
    name: 'color06',
    type: 'color',
    validate: {
      required: true,
    },
    rest: {
      description: 'companie.brandCustomization.description.color4',
    },
    value: null,
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
    },
  },
  color01: {
    label: 'general.tags.color5',
    translate: true,
    name: 'color01',
    type: 'color',
    validate: {
      required: true,
    },
    rest: {
      description: 'companie.brandCustomization.description.color5',
    },
    value: null,
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
    },
  },
} as { [x: string]: InputModel }

export const inputColors2 = {
  color21: {
    label: 'general.tags.color7',
    translate: true,
    name: 'color21',
    type: 'color',
    validate: {
      required: true,
    },
    value: null,
  },
} as { [x: string]: InputModel }

export const switchs = {
  gpsActive: {
    label: `companie.brandCustomization.gps.gpsActive`,
    name: `gpsActive`,
  },
  gpsRequired: {
    label: `companie.brandCustomization.gps.gpsRequired`,
    name: `gpsRequired`,
  },
  digitalFence: {
    label: `companie.brandCustomization.gps.digitalFence`,
    name: `digitalFence`,
  },
} as { [x: string]: InputModel }

export const imagesData = [
  {
    name: 'appbar',
    label: 'Logo APP',
    description: 'Esta imagen se visualiza en el header o la cabecera de la aplicación',
  },
  {
    name: 'nostores',
    description: 'Imagen utilizada para indicar que no se tiene datos de la información solicitada',
    label: 'No se tiene información',
  },
  {
    name: 'questionnairefinishedsuccessfully',
    description: 'Imagen utilizada para indicar que el cuestionario se realizó de forma exitosa',
    label: 'Proceso exitoso',
  },
  {
    name: 'addplanoverview',
    description: 'Imagen que se muestra al momento que se crea una planificación',
    label: 'Crear planificación',
  },
]

export const initialValues = {
  color01: null,
  color02: null,
  color03: null,
  color04: null,
  color05: null,
  color06: null,
  color15: null,
  color16: null,
  color21: null,
  gpsActive: false,
  gpsRequired: false,
  digitalFence: false,
  digitalFenceMts: 1,
  img: {
    appbar: null,
    authformlogo: null,
    backgroundbase: null,
    nostores: null,
    questionnairefinishedsuccessfully: null,
    unlock: null,
    addplanoverview: null,
  },
  locale: null,
}
