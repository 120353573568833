import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import { DynamicFormRefProps } from "../../../../../components/dynamicFormRef/types"
import { Item } from "../../../../../utils/types/generals"
import CustomAccordion from "../../../../../components/accordion"

const useModels = (payload: Record<string, Item[]>) => {
  const { t } = useTranslation()

  const header: DynamicFormRefProps['model'] = {
    quest_name: {
      id: 'quest_name',
      label: t('Questionarie.fields.questionnarieName'),
      name: 'quest_name',
      type: 'text',
      validations: {
        required: { value: true },
      },
      inputOptions: {
        textFieldProps: {
          inputProps: {
            maxLength: 1024
          }
        }
      },
      wrapper: (payload) => <Grid item xs={12} children={payload.children} key={payload.id} />
    },
    description: {
      id: 'description',
      label: '',
      name: 'description',
      type: 'text',
      maxRows: 4,
      multiline: true,
      inputOptions: {
        variant: 'standard'
      },
      wrapper: (payload) => (
        <Grid
          item
          xs={6}
          children={(
            <CustomAccordion
              title={t('Questionarie.sections.questDescription')}
              description={payload.children}
            />
          )}
          key={payload.id}
        />
      )
    },
    instruction: {
      id: 'instruction',
      label: '',
      name: 'instruction',
      type: 'text',
      maxRows: 4,
      multiline: true,
      inputOptions: {
        variant: 'standard'
      },
      wrapper: (payload) => (
        <Grid
          item
          xs={6}
          children={(
            <CustomAccordion
              title={t('Questionarie.sections.questInstruction')}
              description={payload.children}
            />
          )}
          key={payload.id}
        />
      )
    },
    areas: {
      id: 'areas',
      label: t('general.questions.area'),
      name: 'areas',
      items: payload?.areas,
      type: 'selectSimple',
      wrapper: (payload) => <Grid item xs={6} children={payload.children} key={payload.id} />,
      selectOptions: {
        child: {
          childKey: 'departments'
        },
      }
    },
    departments: {
      id: 'departments',
      type: 'selectSimple',
      label: t('general.questions.state'),
      name: 'departments',
      items: payload?.departments,
      selectOptions: {
        parent: {
          parentId: "areaId",
          parentKey: "areas"
        }
      },
      wrapper: (payload) => <Grid item xs={6} children={payload.children} key={payload.id} />
    },
  }

  return { header }
}

export { useModels }