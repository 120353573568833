import { useContextState } from "../../../../../../../../hooks/theme"

const useFolioAmountStyles = () => {
    const { colors } = useContextState()
    return {
        containerFolioAmount: {
            display: 'flex',
            columnGap: '10px',
            alignItems: 'center',
        },
        foliosAmount: {
            maxWidth: '100px',
            fontSize: '14px',
            color: colors.color9,
            fontWeight: '600',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
        },
    }
}

export { useFolioAmountStyles }