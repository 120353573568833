import { Http } from '../../utils/types'
import { CitiesListResponse, StateListResponse } from './response'

export const zipCodeService = (client: Http) => ({
  zipCodeList: async () => {
    try {
      return await client.get('zip-code')
    } catch (e) {
      return { errors: e }
    }
  },
  satesList: async (): Promise<StateListResponse> => await client.get('zip-code/states'),
  cityList: async (id: number): Promise<CitiesListResponse> => await client.get(`zip-code/states/${id}/cities`),
  getCities: async (ids: string): Promise<CitiesListResponse> => await client.get(`zip-code/states/cities?states=${ids}`),
})
