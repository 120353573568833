import { TFunction } from 'react-i18next'
import { Status } from '../../../../../utils/enums'

const handleStatus = ({ t, status }: { t: TFunction<'translation', undefined>; status: Status }) => {
  const typeStatus: { [x: string]: any } = {
    [Status.ACTIVE]: {
      label: t('reportFolio.dashboard.status.1'),
      value: Status.ACTIVE
    },
    [Status.CANCEL]: {
      label: t('reportFolio.dashboard.status.5'),
      value: Status.CANCEL
    },
    [Status.REJECTED]: {
      label: t('reportFolio.dashboard.status.13'),
      value: Status.REJECTED
    },
    [Status.AWAIT_SOLUTION]: {
      label: t('reportFolio.dashboard.status.20'),
      value: Status.AWAIT_SOLUTION
    },
    [Status.RESPONDED]: {
      label: t('reportFolio.dashboard.status.18'),
      value: Status.RESPONDED
    },
    [Status.APPROVED]: {
      label: t('reportFolio.dashboard.status.19'),
      value: Status.APPROVED
    },
  }

  return {statusSelected: typeStatus?.[status] ?? typeStatus[Status.ACTIVE], listStatus: typeStatus}
}


export { handleStatus }
