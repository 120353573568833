const formatFilter = {
  userType: 'user_type_ids',
  roles: 'role_ids',
  divisions: 'division_ids',
  users: 'user_ids',
  companies: 'company_ids',
  areas: 'area_ids',
  departments:'department_ids',
}

export { formatFilter }
