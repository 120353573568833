import { useState, SyntheticEvent } from 'react'
const useTabs = (data?: { defaultValue?: string }) => {
  const [value, setValue] = useState(data?.defaultValue ?? '1')

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return { handleChange, value }
}

export { useTabs }
