import { TFunction } from 'react-i18next'
import { Status } from '../../../../utils/enums'
import { InputModel } from '../../../../utils/types'
import { endOfWeek, startOfWeek } from 'date-fns'
import { RedirectedIds } from '../../typing'

export const input = {
  search: {
    label: 'general.search',
    translate: true,
    name: 'search',
    type: 'text',
    value: null,
  },
  createYear: {
    label: 'general.filterDate.year',
    translate: true,
    name: 'createYear',
    type: 'filter-select',
    items: [],
    value: null,
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  month: {
    label: 'general.filterDate.month',
    translate: true,
    name: 'month',
    type: 'filter-select',
    items: [],
    value: null,
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  from: {
    label: 'general.tags.from',
    translate: true,
    name: 'from',
    type: 'date',
    value: null,
    rest: {
      max: 'to',
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  to: {
    label: 'general.tags.to',
    translate: true,
    name: 'to',
    type: 'date',
    value: null,
    rest: {
      min: 'from',
    },
    size: {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    },
  },
  area: {
    label: 'general.tags.area',
    translate: true,
    name: 'area',
    type: 'filter-select',
    items: [],
    value: null,
  },
  department: {
    label: 'general.tags.department',
    translate: true,
    name: 'department',
    type: 'filter-select',
    items: [],
    value: null,
  },
  status: {
    label: 'general.tags.status',
    translate: true,
    name: 'status',
    type: 'filter-select',
    items: [],
    value: null,
  },
  userType: {
    label: 'general.tags.userType',
    translate: true,
    name: 'userType',
    type: 'filter-select',
    items: [],
    value: null,
  },
  roles: {
    label: 'general.tags.role',
    translate: true,
    name: 'roles',
    type: 'filter-select',
    items: [],
    value: null,
  },
  user: {
    label: 'general.tags.user',
    translate: true,
    name: 'user',
    type: 'filter-select',
    items: [],
    value: null,
  },
  questionnaire: {
    label: 'general.tags.questionnaire',
    translate: true,
    name: 'questionnaire',
    type: 'filter-select',
    items: [],
    value: null,
  },
  percentage: {
    label: 'general.tags.percentage',
    translate: true,
    name: 'percentage',
    type: 'percentage',
    value: null,
  },
  division: {
    label: 'general.tags.division',
    translate: true,
    name: 'division',
    type: 'filter-select',
    items: [],
    value: null,
  },
  stores: {
    label: 'general.tags.store',
    translate: true,
    name: 'stores',
    type: 'filter-select',
    items: [],
    value: null,
  },
  zones: {
    label: 'general.tags.zone',
    translate: true,
    name: 'zones',
    type: 'filter-select',
    items: [],
    value: null,
  },
  childZone: {
    label: 'general.tags.class',
    translate: true,
    name: 'childZone',
    type: 'filter-select',
    items: [],
    value: null,
    size: {
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
    },
  },
} as { [x: string]: InputModel }


const listRedirectLeft: RedirectedIds[] = [
  RedirectedIds.INDICATORS_UNITS,
  RedirectedIds.INDICATORS_PROGRAMATIONS,
  RedirectedIds.INDICATORS_ACTION_PLAN,
  RedirectedIds.DIVISIONS,
]

const listRedirectRight = [
  RedirectedIds.PROGRESS_INDICATORS,
  RedirectedIds.Area_Histogram,
  RedirectedIds.Department_Histogram,
  // RedirectedIds.FOLIOS_AREAS,
  // RedirectedIds.FOLIOS_DEPARTMENTS,
  RedirectedIds.TOP_15,
]
const statusList = ({ t }: { t: TFunction<'translation', undefined> }) => {
  return [
    {
      label: t('general.status.cancel'),
      value: Status.CANCEL,
    },
    {
      label: t('general.status.toBeStatarted'),
      value: Status.TO_BE_STARTED,
    },
    {
      label: t('general.status.expired'),
      value: Status.EXPIRED,
    },
    {
      label: t('general.status.incomplete'),
      value: Status.INCOMPLETE,
    },
  ]
}

const datesTop: Record<string, InputModel> = {
  from: {
    label: 'general.tags.from',
    translate: true,
    name: 'from',
    type: 'date',
    rest: {
      max: 'to',
    },
    value: new Date(startOfWeek(Date.now(), { weekStartsOn: 1 })),
    size: {
      lg: 6,
      md: 6,
      sm: 6,
      xl: 6,
      xs: 6,
    }
  },
  to: {
    label: 'general.tags.to',
    translate: true,
    name: 'to',
    type: 'date',
    rest: {
      min: 'from',
    },
    value: new Date(endOfWeek(Date.now(), { weekStartsOn: 1 })),
    size: {
      lg: 6,
      md: 6,
      sm: 6,
      xl: 6,
      xs: 6,
    }
  },
}

const dashboardColor = {
  1: '#00cc33',
  2: '#00ccff',
  3: '#6600ff',
  4: '#9900ff',
  5: '#cc00ff',
  6: '#cc6600',
}
export {
  statusList, 
  datesTop, 
  listRedirectLeft,
  listRedirectRight,
  dashboardColor
}
