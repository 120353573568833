import { InputModel } from "../../../../../utils/types"

const sizes = {
  value: '13px',
  caption: '12px',
}
const resendReportForm = {
  users_direct_parents: {
    label: `reportFolio.modal.sendReport.usersDirectParents`,
    name: 'users_direct_parents',
    value: false,
    type: 'check',
    translate: true,
  },
  user_creator: {
    label: `reportFolio.modal.sendReport.userCreator`,
    name: 'user_creator',
    value: false,
    type: 'check',
    translate: true,
  },
  user_assign: {
    label: `reportFolio.modal.sendReport.userAssign`,
    name: 'user_assign',
    value: false,
    type: 'check',
    translate: true,
  },
} as { [x: string]: InputModel }

const resendReportFormEmails = {
  externalEmails: {
    label: ``,
    name: 'externalEmails',
    value: '',
    rest: {
      multiline: true,
      rows: 5,
    },
    translate: true,
  },
}

export { sizes, resendReportForm, resendReportFormEmails }
