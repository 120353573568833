import { Grid, Typography } from '@mui/material'
import { useContext } from 'react'
import { GlobalContext } from '../../../../../../contexts/Global'
import { useStyles } from './styles'

const ModelTwo = (props: { title: string; description: string }) => {
  const { colors } = useContext(GlobalContext)
  const styles = useStyles({ colors })

  return (
    <Grid container sx={[styles.container]}>
      <Grid item xs={4}>
        <Typography>{props.title}</Typography>
      </Grid>

      <Grid item xs={8}>
        <Typography className="bold">{props.description}</Typography>
      </Grid>
    </Grid>
  )
}

export { ModelTwo }
