import { useState, useLayoutEffect, useEffect } from 'react'
import { Box } from '@mui/material'
import { useContextState } from '../../../hooks/theme'
import AuthCard from '../components/authCard'
import img from '../../../assets/images/img1.png'
import AuthForm from '../components/authForm'
import { Images } from '../../../components/image'
import { Value } from '../types'
import { useLocation, useNavigate } from 'react-router-dom'
import { onChangeEvent } from '../../../utils/types/generals'
import { useStyles } from '../utils/styles'
import { validate } from '../utils/validate'
import { Auth } from '../../../service/auth'
import { useInitialState } from './initialStates'
import { images } from '../../../utils/images'
import { httpFetch, setLocalStorage } from '../../../utils/crud'
import { RequestErrors, Roles, Types } from '../../../utils/enums'
import { handleAllErrors } from '../../../utils/methods/handleErrors'
import { SnackSimple } from '../../../components'
export const ChangePassword = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { colors } = useContextState()
  const styles = useStyles(colors)
  const { value, form, t } = useInitialState({ tmp: location?.state?.tmp })
  const [login, setLogin] = useState<Value>(value)
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const isValid = () =>
    Object.values(errors).length > 0 ||
    Object.values(login).findIndex((item) => !item.value) >= 0
  const [openSnackbar, setOpenSnackbar] = useState({
    message: '',
    open: false,
    type: Types.ERROR,
  })

  async function logIn() {
    const authFetch = await Auth(httpFetch).singIn({
      username: location?.state?.name,
      password: login.password.value,
    })
    let role = authFetch.roleId
    // role = Roles.AGENT
    if (!authFetch.errors) {
      if ([Roles.SUPERVISOR, Roles.ADMIN, Roles.AGENT, Roles.STORE, Roles.GERENT, Roles.SUPPORT, Roles.AUDIT, Roles.PARTNER].includes(authFetch?.roleId)) {
        setLocalStorage({
          body: {
            in: true,
            token: authFetch.token,
            role: role,
            id: authFetch?.id,
            picture: authFetch.picture,
            curp: authFetch.curp ?? '',
            email: authFetch.email,
            phone: authFetch.phone,
            userType: authFetch.userType,
            fullName: `${authFetch.firstName ?? ''} ${authFetch.lastName ?? ''}`,
            firstName: authFetch.firstName,
            lastName: authFetch.lastName
          },
          name: 'state',
        })

        if (role == Roles.AGENT) navigate('/dashboard/calendar')
        else if (role == Roles.STORE) navigate('/dashboard/action-plan')
        else navigate('/')
      } else {
        setOpenSnackbar({
          message: t('general.messages.errors.userNotHavePermissionToLogIn'),
          open: true,
          type: Types.ERROR,
        })
      }
    } else {
      if (authFetch?.errors?.[0]?.code === RequestErrors.REQUIRE_VALIDATE) {
        setOpenSnackbar({
          message: t('general.messages.errors.REQUIRE_VALIDATE'),
          open: true,
          type: Types.ERROR,
        })
        setTimeout(() => {
          navigate('/auth/confirm-user')
        }, 5000)
      } else {
        setErrors({
          password: handleAllErrors(
            authFetch?.errors?.[0]?.code,
            t,
            'general.messages.errors.logError'
          ),
        })
      }
    }
    setIsLoading(false)
  }

  async function sign() {
    setIsLoading(true)
    try {
      // location?.state?.name, login.code.value, login.password.value
      const user: any = await Auth(httpFetch).resetPassword({
        body: {
          username: location?.state?.name,
          ...(location?.state.tmp
            ? { password: login.currentPassword.value }
            : { code: login.code.value }),
          newPassword: login.password.value,
          newConfirmPassword: login.password.value,
        },
      })
      const code = user.errors?.[0]?.code
      if (!!code) {
        if (code === RequestErrors.REQUIRE_VALIDATE) {
          setOpenSnackbar(() => ({
            open: true,
            message: handleAllErrors(
              code,
              t,
              'general.messages.errors.validateCode'
            ),
            type: Types.ERROR,
          }))
          setTimeout(() => {
            navigate('/auth/confirm-user')
          }, 3000)
        } else {
          setErrors({
            code: handleAllErrors(
              code,
              t,
              'general.messages.errors.validateCode'
            ),
          })
        }
        setIsLoading(false)
      } else {
        setOpenSnackbar(() => ({
          open: true,
          message: 'La contraseña se cambió exitosamente',
          type: Types.SUCCESS,
        }))
        logIn()
      }
    } catch (error) {
      console.error('error signing up:', error)
      setErrors({ code: t('general.messages.errors.validateCode') })
    }
  }
  const handleChange = ({ target }: onChangeEvent) => {
    const { name, value } = target

    const body = {
      ...login,
      [name]: {
        ...login[name],
        value,
      },
    }

    setErrors(validate(body, t))
    setLogin(body)
  }

  const resendCode = async () => {
    const response = await Auth(httpFetch).resendCode({
      body: { username: location?.state?.name },
    })
    if (!response?.errors) {
      setOpenSnackbar(() => ({
        open: true,
        message: 'Se envió un código a su correo electrónico',
        type: Types.SUCCESS,
      }))
    } else {
      setOpenSnackbar(() => ({
        open: true,
        message: 'Ocurrió un error y no se pudo enviar el código',
        type: Types.ERROR,
      }))
    }
  }

  useLayoutEffect(() => {
    if (!location?.state?.name) navigate('/auth/send-code')
  }, [location?.state?.name, navigate])

  useEffect(() => {
    setLogin(value)
  }, [value])

  return (
    <Box sx={styles.container}>
      <SnackSimple
        time={5000}
        close={() => {
          setOpenSnackbar({
            ...openSnackbar,
            open: false,
          })
        }}
        open={openSnackbar.open}
        type={openSnackbar?.type}
        message={openSnackbar.message}
      />
      <AuthCard
        children={<Images src={img} />}
        secondChildren={
          <AuthForm
            cancel={
              !location?.state?.tmp
                ? {
                  label: 'Reenviar código',
                  click: resendCode,
                  variant: 'text',
                }
                : undefined
            }
            errors={errors}
            logo={images.interception}
            description={form.description}
            button={form.button}
            value={login}
            onChange={handleChange}
            submit={sign}
            disabled={isValid()}
            isLoading={isLoading}
          />
        }
        footer={{
          label: form.footer,
          navigate: () => navigate('/auth'),
        }}
      />
    </Box>
  )
}
