import { AppBar, Badge, Box, Button, IconButton, Typography } from '@mui/material'
import { useStyles } from './styles'
import { useContextState } from '../../../hooks/theme'
import { Images } from '../../image'
import BaseMenu from '../../baseMenu'
import { menuList } from './menu'
import { useNavigate } from 'react-router-dom'
import { icons } from '../../../utils/icons'
import { useBody } from './body'
import { FloatingList } from '../../floatList'
import SnackSimple from '../../snackbars/simple'
import { images } from '../../../utils/images'

const Header = ({ openMenu }: { openMenu: () => void }) => {
  const { anchorEl, notifications, loading, snackbar, listLoadingNotifications, closeSnackbar, handleClick, refreshNotifications, removeNotifications } = useBody()
  const navigate = useNavigate()
  const { colors, state } = useContextState()
  const styles = useStyles(colors)

  const onClick = (_: any) => {
    navigate('/auth/logOut')
  }

  const header = (
    <Box sx={styles.header}>
      <Box sx={styles.containerTitle}>
        <IconButton onClick={() => { refreshNotifications() }} sx={styles.refresh}>{icons('refresh')}</IconButton>

        <Typography sx={styles.headerTitle}>Notificaciones</Typography>
      </Box>

      <Button onClick={() => { removeNotifications({ all: true }) }} sx={styles.headerButton} variant='text'>Descartar todas</Button>
    </Box>
  )

  return (
    <AppBar elevation={0} component="nav" sx={styles.navBar}>
      <SnackSimple close={closeSnackbar} open={snackbar.open} type={snackbar.type} message={snackbar.message} />
      <IconButton onClick={() => openMenu()} sx={styles.menu}>{icons('menu')}</IconButton>
      <Box sx={styles.flexGrow} />
      <Box sx={styles.leftTools}>
        <Badge badgeContent={!!notifications?.length ? notifications?.length : null} sx={styles.badge}>
          <IconButton onClick={(e) => { handleClick({ event: e, type: 'bell' }) }} sx={styles.bell}>{icons('bell-rounded')}</IconButton>
        </Badge>
    
        <IconButton onClick={(e) => { handleClick({ event: e, type: 'profile' }) }} ><Images src={state?.picture ?? images.profileHeader} sx={styles.flag} /></IconButton>

        <FloatingList listLoadingNotifications={listLoadingNotifications} refresh={refreshNotifications} header={header} isLoad={loading.notification} close={() => { handleClick({ event: null, type: 'bell' }) }} data={notifications} anchorEL={anchorEl.bell} />

        <BaseMenu anchorEl={anchorEl.profile} setAnchorEl={() => { handleClick({ event: null, type: 'profile' }) }} menuItems={menuList} click={onClick} />
      </Box>
    </AppBar>
  )
}

export default Header
