import { Color } from '../../../../../utils/theme/types/color'

export const useStyles = (color: Color) => {
  const sharedStyles = {
    squareButton: {
      color: color.color5,
      borderRadius: '5px',
      boxShadow: `1px 1px 2px ${color.color10}`,
      padding: '12px',
      background: color.color30,
      '&:hover': {
        background: color.color30 + 'dd',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '1.7rem',
      },
      marginBottom: '2px',
    },
    flexBasic: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    errorAmount: {
      fontSize: '11px',
      color: color.color19,
    },
  }

  return {
    container: {
      '& .MuiGrid-root': {
        justifyContent: 'center',
      },
    },
    info: {
      display: 'flex',
    },
    left: {
      width: '50%',
      color: color.color9,
    },
    right: { width: '50%', wordBreak: 'break-all' },
    loader: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    btnCancel: {
      mt: 2,
      textTransform: 'uppercase',
      backgroundColor: color.color28,

      '&:hover': {
        backgroundColor: color.color28,
      },
    },
    buttons: {
      display: 'flex',
      justifyContent: 'right',
      gap: '10px',
      marginBottom: '10px',
    },
    evidences: {
      margin: '20px 0 5px 0',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    badge: {
      '& .MuiBadge-badge': {
        background: color.color20,
        color: color.color25,
      },
    },
    clip: {
      ...sharedStyles.squareButton,
    },
    activeClip: {
      background: color.color30,
      '&:hover': {
        background: color.color30 + 'dd',
      },
    },
    label: {
      '& .MuiFormControlLabel-label': {
        wordBreak: 'break-all',
      },
    },
    checkbox: {
      padding: '0px',
      marginRight: '10px',
      '& .MuiBox-root': {
        width: '15px',
        height: '15px',
      },
    },
    border: {
      '& li:not(:last-child)': {
        borderBottom: `1px solid ${color.color13}`,
      },
    },
    drawer: {
      '& .MuiPaper-root': {
        '&:not(:last-child)': {
          marginBottom: '15px',
        },
        maxWidth: '500px',
        padding: '10px 20px',
      },
    },
    containerEvidences: {
      padding: '10px 0px 20px',
    },
    titleEvidences: {
      fontSize: '16px',
      color: color.color13,
      fontWeight: '600',
      marginBottom: '10px',
    },
    formLabel: {
      '& .MuiTypography-root': {
        fontSize: '14px',
        color: color.color13,
        fontWeight: '500',
      },
      '& .MuiRadio-root.Mui-checked': {
        color: '#65C466',
      },
    },
    accordionDetail: {
      padding: '5px 10px 20px',
      '& .MuiFormControlLabel-root .MuiTypography-root': {
        fontSize: '13px',
      },
    },
    checks: {
      '& .MuiGrid-item': {
        paddingTop: '0',
      },
    },
    noSelected: {
      '&.MuiTypography-root': {
        fontSize: '12px',
        color: color.color28,
        fontWeight: '400',
        marginBottom: '10px',
      },
    },
  }
}
