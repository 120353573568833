import { useTranslation } from 'react-i18next'
import { Value } from '../types'

export const useInitialState = () => {
  const { t } = useTranslation()

  return {
    value: {
      email: {
        name: 'email',
        label: t('login.signin.form.email'),
        value: null,
        type: 'email'
      },
      code: {
        name: 'code',
        label: t('login.signin.form.code'),
        value: null,
      },
    } as Value,
    form: {
      title: t('login.confirm.title'),
      description: t('login.confirm.description'),
      button: t('login.signin.confirm'),
      footer: t('login.signin.footerConfirm')
    },
    t
  }
}
