import i18next from "i18next";
import { Validate, ValidateModel } from "./types";

const regByType = {
  string: /^[a-zA-Z]+$/,
  phone: /^[+]+[0-9]{12}$/,
  number: /^[0-9]+$/,
  decimal: /^([-]?)(?!00)[0-9]+([.]?)[0-9]{0,2}$/,
  coordinate: /^[0-9-nNeEoOsSWw°."']+$/,
  alphaNumeric: /^(?=.*[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ'"¿¡()<>])[-_a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ .,'"¿?¡!:;+=()<>\n]+$/,
  email: /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/
}

const validateFields = ({ validate, name }: { validate?: Validate; name: string }): Record<string, ValidateModel | boolean> => {
  const { t } = i18next
  let isPattern = validate?.text
  const tagName = t(`general.tags.${name}`).toLowerCase()
  let typeReg: {
    reg: RegExp
    message: string
  } = {
    reg: regByType.string,
    message: ''
  }

  if (validate?.text?.value) {
    isPattern = validate.text
    typeReg = {
      reg: regByType.string,
      message: `${t('general.validate.template.theField')} ${tagName} solo permite letras`
    }
  }
  if (validate?.number?.value) {
    isPattern = validate.number
    typeReg = {
      reg: regByType.number,
      message: t('general.validations.itIsNotNumber')
    }
  }
  if (validate?.coordinate?.value) {
    isPattern = validate.coordinate
    typeReg = {
      reg: regByType.coordinate,
      message: t('general.validations.coordinate')
    }
  }
  if (validate?.decimal?.value) {
    isPattern = validate.decimal
    typeReg = {
      reg: regByType.decimal,
      message: t('general.validations.decimal')
    }
  }
  if (validate?.email?.value) {
    isPattern = validate.email
    typeReg = {
      reg: regByType.email,
      message: t('general.validations.wrongEmail')
    }
  }
  if (validate?.phone?.value) {
    isPattern = validate.phone
    typeReg = {
      reg: regByType.phone,
      message: t('general.validations.wrongPhoneNumber')
    }
  }
  if (validate?.alphaNumeric?.value) {
    isPattern = validate.alphaNumeric
    typeReg = {
      reg: regByType.alphaNumeric,
      message: t('general.validations.alphaNumeric')
    }
  }

  return {
    pattern: !!isPattern?.value ? {
      value: isPattern?.reg ?? typeReg.reg,
      message: isPattern?.message ?? typeReg.message
    } : false,
    required: !!validate?.required ? {
      value: !!validate?.required?.value,
      message: validate?.required?.message ?? `${t('general.validate.template.theField')} ${tagName} ${t('general.validate.template.isRequired')}`
    } : false,
    ...!!validate?.min?.value && {
      min: {
        value: validate?.min?.value,
        message: validate?.min?.message ?? `${t('general.validations.min')} ${validate?.min?.value} ${t('general.validations.character')}`
      }
    },
    ...!!validate?.max?.value && {
      max: {
        value: validate?.max?.value,
        message: validate?.max?.message ?? `${t('general.validations.max')} ${validate?.max?.value} ${t('general.validations.character')}`
      }
    },
  }
}

export { validateFields }