import { useState, useEffect } from 'react'
import { Item } from '../../../../../utils/types/generals'
import { getCompanies, getDivisions, getRoles, getSupervisorDivisions, getUserTypes, getUserTypesSupervisor, getUsers, getUsersOfTheSupervisor, getArea, getDepartments, getUserJerarquia } from '../../../../../utils/methods/geServiceWithItemsFormat'
import { Roles } from '../../../../../utils/enums'
import { userServiceFetch } from '../../../../../utils/constants/callService'
import { getLocalStorage } from '../../../../../utils/crud'
import { canAccess } from '../../../../../utils/validate/canaccess'

const useGetData = () => {
  const state = getLocalStorage()
  const [loadingLocalizations, setLoadingLocalizations] = useState(false)
  const [departments, setDepartments] = useState<any[]>([])
  const [AmountUsersJerarquia, setAmountUsersJerarquia] = useState(-1)
  const [items, setItems] = useState<Record<string, Item[]>>({})
  const [localization, setLocalization] = useState<
    {
      id?: number | string
      fullName?: string | null
      email?: string | null
      phone?: string | null
      role?: string | null
      curp: string
      userType?: string | null
      img?: string | null
      lat: number
      lng: number
    }[]
  >([])

  const handleItemsByExternal = ({ name, value }: { name: string; value: any }) => {
    setItems((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const cleanLocalization = () => {
    setLocalization([])
  }

  const getData = () => {
    callEndpoint({ query: '' })
    getRolesService()
    getUserService()
    getUserTypeService()
    getDivisionService()
    getCompaniesService()
    getAreaService()
    getDepartmentsService()
  }

  const callEndpoint = async ({ query }: { query: string }) => {
    setLoadingLocalizations(true)
    getUserJerarquiaService({ query: query.replace('&', '') })
    if (!!query) {
      const response = await userServiceFetch.getLocalization({ query: query.replace('&', '') })

      if (!response.errors) {
        const local = response.map((item) => {
          const user = item?.user
          const lat = parseFloat(item.latitude)
          const lng = parseFloat(item.longitude)

          return {
            id: user.id,
            fullName: `${user.firstName} ${user.lastName}`,
            email: user?.email,
            curp: user.curp,
            phone: user?.phone,
            role: user.role.description,
            userType: user?.userType?.name,
            img: user.picture ?? null,
            lat,
            lng,
          }
        })
        setLocalization(local)
      }
    } else { setLocalization([])}
    setLoadingLocalizations(false)
  }

  const getRolesService = async () => {
    if (canAccess([Roles.ADMIN, Roles.SUPERVISOR])) {
      const roles = await getRoles({ skip: [Roles.ADMIN] })

      setItems((prev) => ({ ...prev, roles: roles.items }))
    }
  }

  const getUserService = async () => {
    let users: Item[] = []

    if (state?.role == Roles.SUPERVISOR) {
      const { items } = await getUsersOfTheSupervisor({ id: state.id })

      users = items
    } else {
      const { items } = await getUsers({ skip: [Roles.ADMIN] })

      users = items
    }

    setItems((prev) => ({ ...prev, users: users }))
  }

  const getUserTypeService = async () => {
    let userType: Item[] = []

    if (state?.role == Roles.SUPERVISOR) {
      const { items } = await getUserTypesSupervisor()
      userType = items
    } else {
      const { items } = await getUserTypes()
      userType = items
    }

    setItems((prev) => ({ ...prev, userType }))
  }

  const getDivisionService = async () => {
    let divisions: Item[] = []

    const { items } = await getSupervisorDivisions()
    if (state?.role == Roles.SUPERVISOR) {
      divisions = items
    } else {
      const { items } = await getDivisions()
      divisions = items
    }

    setItems((prev) => ({ ...prev, divisions }))
  }

  const getCompaniesService = async () => {
    const companies = await getCompanies()
    setItems((prev) => ({ ...prev, companies }))
  }

  const getAreaService = async () => {
    const responseAreas = await getArea()
    setItems((prev) => ({
      ...prev,
      areas: responseAreas,
    }))
  }

  const getDepartmentsService = async () => {
    const departments = await getDepartments()
    setDepartments(departments)
  }

  const getUserJerarquiaService = async (query: { query: string }) => {
    const amountUsers = await getUserJerarquia(query)
    setAmountUsersJerarquia(amountUsers)
  }
  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])
  return { items, localization, loadingLocalizations, callEndpoint, cleanLocalization, departments, setItems, handleItemsByExternal, AmountUsersJerarquia }
}

export { useGetData }
