import { Badge, Box, Button, IconButton, Typography } from "@mui/material"
import { icons } from "../../../../../../utils/icons"
import { useStyles } from "../../styles"
import { useContextState } from "../../../../../../hooks/theme"
import { useDimensions } from "../../../../../../hooks/dimensions"
import { CardIndicatorProps, CardIndicatorRef, dataIndicator } from "./types"
import { useBody } from "./body"
import BaseModal from "../../../../../../components/modal/baseModal"
import { DinamicForm } from "../../../../../../components/dinamicForm"
import { useTranslation } from "react-i18next"
import { Ref, forwardRef, useImperativeHandle } from "react"


const CardIndicator = forwardRef((props: CardIndicatorProps, ref: Ref<CardIndicatorRef>) => {
    const body = useBody({ items: props.items })
    const { t } = useTranslation()
    const { colors } = useContextState()
    const styles = useStyles(colors)
    const { dimensions } = useDimensions()

    useImperativeHandle(ref, () => ({
        cleanFilter: () => { body.filters.cleanFilter() }
    }))

    return (
        <Box sx={styles.cardIndicatorContainer({ isActive: props.isActive })}>
            <Box sx={styles.containerCard}>
                <Box sx={styles.containerData}>
                    <Box sx={styles.headerIndicator}>
                        <Typography className="storage">{props.title}</Typography>

                        <Typography className={`percentage`}>{props.value ?? '0'}</Typography>
                    </Box>

                    {
                        props.list.map(item => (
                            <Box key={item.id} sx={styles.containerTickets}>
                                <Typography className="ticket-title">{item.title}</Typography>

                                <Box sx={styles.percentage}>
                                    <Typography>{item.amount.toLocaleString('en-US')}</Typography>

                                    <Box sx={styles.ticket({ colors: item.color, type: item.type })}>
                                        <Typography className="ticket-amount">{item.percentage}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        ))
                    }
                </Box>

                <Box sx={styles.actionIndicator({ isActive: props.isActive, count: !!body.filters.counter })}>
                    <IconButton
                        className="timeline"
                        onClick={() => {
                            props.clicTimeline?.()
                        }}
                    >
                        {icons('chart')}
                    </IconButton>

                    <Badge sx={styles.badge({ isActive: props.isActive })} badgeContent={body.filters.counter}>
                        <IconButton
                            className="filter"
                            onClick={() => {
                                body.openFiltersModal()
                            }}
                        >
                            {icons('filter')}
                        </IconButton>
                    </Badge>

                    <IconButton
                        className={`refresh ${!!props.isLoading && 'load'}`}
                        onClick={() => {
                            props.refresh?.()
                        }}
                    >
                        {icons('refresh')}
                    </IconButton>
                </Box>
            </Box>
            
            <Box sx={styles.containerFooter}>
                {!!props.lastUpdate && <Typography sx={styles.footerText}>Última actualización: <span>{props.lastUpdate}</span></Typography>}

                {!!body.filters.counter &&
                    <Button
                        onClick={() => {
                            props.getFilterData({ store: [], divisions: [], zones: [], class: [] })
                            body.filters.cleanFilter()
                        }}
                        variant="text"
                        sx={[styles.footerText, styles.cleanText]}
                    >
                        {t('general.cleanFilters')}
                    </Button>
                }
            </Box>

            {(!!props.isActive && dimensions.width > 1200) && <Box sx={styles.selected} />}

            <BaseModal sx={{ borderRadius: '0px' }} open={body.modal.open}>
                <Box sx={styles.containerModal}>
                    <Box sx={styles.headerModal}>
                        <Typography sx={styles.headerModalTitle}>{props.titleModal}</Typography>
                    </Box>

                    <IconButton onClick={() => { body.filterButton() }} sx={styles.close}>{icons('simple-close')}</IconButton>

                    <DinamicForm
                        legacyErrors
                        input={Object.values({
                            ...props.inputs ?? {},
                            class: {
                                ...props.inputs.class ?? {},
                                items: body.items.class
                            }
                        })}
                        values={body.filters.filterData}
                        onChange={body.filters.onChange}
                    />

                    <Button
                        variant="contained"
                        onClick={() => {
                            props.getFilterData(body.filters.filterData)
                            body.filterButton()
                        }}
                        sx={styles.btnFilter}>
                        {t('general.filter')}
                    </Button>
                </Box>
            </BaseModal>
        </Box>
    )
})

export type { CardIndicatorProps, dataIndicator }
export { CardIndicator }
