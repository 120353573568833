import { createRef, useEffect, useState } from "react"

import { returnQuery, returnQueryWithPagination } from "../../../../../../utils/methods/returnQuery"
import { useTranslation } from "react-i18next"
import { Box, Checkbox, IconButton, Typography } from "@mui/material"
import { useStyles } from "./styles"
import { Item, ObjectGeneric } from "../../../../../../utils/types/generals"
import { InputModel } from "../../../../../../utils/types"
import { Roles, Types } from '../../../../../../utils/enums'
import { getRoles, } from "../../../../../../utils/methods/geServiceWithItemsFormat"
import { FilterProps } from "../../../../../../hooks/filter/typing"
import { removeAllReference } from "../../../../../../utils/methods/removedReference"
import { questionnaire, userServiceFetch } from "../../../../../../utils/constants/callService"
import { RefTable } from "../../../../../../components/table/types"
import { icons } from "../../../../../../utils/icons"
import { PermissionsData } from "./interface"
import { searchInModeLower } from "../../../../../../utils/validate/validate"
import { useFilter } from "../../../../../../hooks/filter"
import { useDownload } from "../../../../../../hooks/download/useDownload"
import { LoadData } from "./components/modal/types"
import { IOSSwitch } from "../../../../../../components/dinamicForm/switch"

const checksTypes = {
  questionnaire: ['accessQ', 'reportQ', 'emailNotificationQ'],
  folio: ['accessF', 'reportF', 'emailNotificationF'],
}

const allChecks = [...checksTypes.questionnaire, ...checksTypes.folio]

const formatFilter = {
  from: "start_date",
  to: 'end_date',
  status: "status_ids",
  role: "role_ids",
  stores: "store_ids",
  userType: "user_type_ids",
  area: "areas_ids",
  department: "departments_ids"
}

const useBody = ({ id, itemsManage }: {
  id?: string;
  itemsManage: Record<string, Item[]>
}) => {
  const [oldChecks, setOldChecks] = useState('')
  const [thereAreChanged, setThereAreChanged] = useState(false)
  const refTable = createRef<RefTable>()
  const [table, setTable] = useState<{ row: any[]; }>({ row: [] })
  const styles = useStyles()
  const [snackbar, setSnackbar] = useState<{ open: boolean; type: Types; message: string }>({
    open: false,
    message: '',
    type: Types.SUCCESS,
  })
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<{
    open: boolean
    title?: string
    description?: string
    cancel?: () => void
    confirm?: () => void
  }>()
  const [modalCreate, setModalCreate] = useState({ open: false })
  const [modalLoad, setModalLoad] = useState<{ open: boolean; tmpUsuers?: Record<string, string | boolean>[] }>({ open: false })
  const [listDeleteUsers, setListDeleteUsers] = useState<Record<string, boolean>>({})
  const [checksUser, setChecksUser] = useState<ObjectGeneric>({})
  const [inputCreate, setInputCreate] = useState({
    search: {
      label: 'general.searchUsers',
      translate: true,
      name: 'search',
      value: null,
    },
    from: {
      label: 'general.tags.from',
      translate: true,
      name: 'from',
      type: 'date',
      value: null,
      rest: {
        max: 'to',
      },
      size: {
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      },
    },
    to: {
      label: 'general.tags.to',
      translate: true,
      name: 'to',
      type: 'date',
      value: null,
      rest: {
        min: 'from',
      },
      size: {
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      },
    },
    role: {
      label: 'general.tags.role',
      translate: true,
      name: 'role',
      type: 'filter-select',
      items: [],
      value: null,
    },
    stores: {
      label: 'general.tags.store',
      translate: true,
      name: 'stores',
      type: 'filter-select',
      items: [],
      value: null,
    },
    division_ids: {
      label: 'general.tags.divisions',
      translate: true,
      name: 'division_ids',
      type: 'filter-select',
      items: [],
      value: null,
    },
    userType: {
      label: 'general.tags.userType',
      translate: true,
      name: 'userType',
      type: 'filter-select',
      items: [],
      value: null,
    },
    area: {
      label: 'general.tags.area',
      translate: true,
      name: 'area',
      type: 'filter-select',
      items: [],
      value: null,
    },
    department: {
      label: 'general.tags.department',
      translate: true,
      name: 'department',
      type: 'filter-select',
      items: [],
      value: null,
    },
  } as { [x: string]: InputModel })
  const [loadings, setLoadings] = useState({ search: false, load: false, table: false })
  const [allDepartments, setAllDepartments] = useState<Item[]>([])
  const filter = useFilter({
    formatFilter,
  })
  const downloadUsers = useDownload({
    docName: t('Questionarie.title'),
    query: returnQueryWithPagination({
      filter: filter.filterString,
      sort: filter.query.sort,
    }),
    downloadFuntion: userServiceFetch.getRegister,
  })
  const [usersToEndpoint, setUsersToEndpoint] = useState<{
    users: Record<string, boolean>
    usersOld: Record<string, boolean>
    usersWithoutPersmissions: string[]
  }>({ users: {}, usersOld: {}, usersWithoutPersmissions: [] })
  const externalValidation: FilterProps['externalValidation'] = ({ name, body, setFilterData }) => {
    let copyBody: Record<string, any> = removeAllReference(body)

    if (name === 'area') {
      const { area, department } = copyBody
      const newDepartments = department?.filter((e: any) => area.find((e2: any) => e2.value == e.areaId))
      let arrayChilds: any[] = []

      area?.forEach((e: any) => {
        const id = e.value
        const childs = allDepartments.filter((child) => child.areaId == id)
        arrayChilds.push(...childs)
      })

      setFilterData((prev) => ({
        ...prev,
        department: newDepartments,
      }))

      setInputCreate(prev => ({ ...prev, department: { ...prev.department, items: arrayChilds } }))
      copyBody = {
        ...copyBody,
        department: newDepartments,
      }
    }

    return copyBody
  }
  const createFilter = useFilter({
    formatFilter,
    externalValidation,
    defaultPagination: { pageSize: 500 }
  })
  const validateChecks = (checksUser: ObjectGeneric, row: any[]) => {
    if (!!row?.length) {
      let accessQ = true, reportQ = true, emailNotificationQ = true, accessF = true, reportF = true, emailNotificationF = true

      for (const item of row) {
        if (!accessQ && !reportQ && !emailNotificationQ && !accessF && !reportF && !emailNotificationF) { break; }

        if (!!accessQ && !checksUser[item.id].accessQ) { accessQ = false }
        if (!!accessF && !checksUser[item.id].accessF) { accessF = false }
        if (!!reportQ && !checksUser[item.id].reportQ) { reportQ = false }
        if (!!reportF && !checksUser[item.id].reportF) { reportF = false }
        if (!!emailNotificationQ && !checksUser[item.id].emailNotificationQ) { emailNotificationQ = false }
        if (!!emailNotificationF && !checksUser[item.id].emailNotificationF) { emailNotificationF = false }
      }


      setChecksUser(prev => ({
        ...prev,
        0: {
          accessQ,
          reportQ,
          emailNotificationQ,
          accessF,
          reportF,
          emailNotificationF
        }
      }))
    }
  }

  const clickChenck = ({ id, name }: { id: number; name: string }) => {
    const localTable = rowContent({ list: table.row })
    if (id == 0) {
      let body: ObjectGeneric = { ...checksUser }
      let existedSomeCheck = false
      let users: Record<string, boolean> = {}

      localTable.forEach(item => {
        if (!checksUser?.[item.id]?.[name]) {
          if (!existedSomeCheck) {
            existedSomeCheck = true
          }
        }
        users = {
          ...users,
          [item.id]: true
        }
      })
      for (const row of localTable) {
        body = {
          ...body,
          [row.id]: {
            ...body?.[row.id] ?? {},
            [name]: existedSomeCheck,
          }
        }
      }
      let usersWithoutPersmissions: string[] = []
      localTable.forEach(item => {
        if (!allChecks?.some(itemCheck => !!body?.[item.id]?.[itemCheck])) {
          usersWithoutPersmissions = [...usersWithoutPersmissions, item.id]
        }
      })
      setUsersToEndpoint(prev => ({
        ...prev,
        users: {
          ...prev.users,
          ...users
        },
        usersWithoutPersmissions
      }))
      setChecksUser(prev => ({
        ...body,
        0: {
          ...prev[0] ?? {},
          [name]: existedSomeCheck,
        },
      }))
    } else {
      let body: ObjectGeneric = {
        ...checksUser,
        [id]: {
          ...(checksUser?.[id] ?? {}),
          [name]: !checksUser?.[id]?.[name],
        },
      }
      if (!!usersToEndpoint.usersOld[id]) {
        setUsersToEndpoint(prev => ({
          ...prev, users: {
            ...prev.users,
            [id]: true
          }
        }))
      }
      if (checksUser?.[id]?.[name]) {
        if (!allChecks?.some(item => !!body?.[id]?.[item])) {
          setUsersToEndpoint(prev => ({
            ...prev,
            usersWithoutPersmissions: [...prev.usersWithoutPersmissions, id.toString()]
          }))
        }
      } else {
        setUsersToEndpoint(prev => ({
          ...prev,
          usersWithoutPersmissions: usersToEndpoint.usersWithoutPersmissions?.filter(item => item != id.toString())
        }))
      }

      if (checksUser?.[id]?.[name]) {
        body = {
          ...body,
          0: {
            ...(body?.['0'] ?? {}),
            [name]: false,
          },
        }
      } else {
        const existedSomeCheck = !!localTable.some(item => !body?.[item.id]?.[name])

        if (!existedSomeCheck) {
          body = {
            ...body,
            0: {
              ...(body?.['0'] ?? {}),
              [name]: true,
            },
          }
        }
      }
      setChecksUser(body)
    }

  }

  const columns = {
    columnMain: [
      {
        id: 'actions',
        label: '',
        headerCell: {
          '&.MuiTableCell-head': {
            width: '30px'
          }
        },
        column: () => {
          return (
            <Box sx={styles?.label}>
              <Checkbox
                onChange={(e, checked) => {
                  e.stopPropagation()
                  selectUserToRemove({ id: '0', value: checked })
                }}
                checked={!!listDeleteUsers?.['0']}
              />
            </Box>
          )
        },
        renderCell: (params: any) => {
          return (
            <Checkbox
              checked={!!listDeleteUsers[params?.row?.id]}
              onChange={(e, checked) => {
                e.stopPropagation()
                if (params?.row?.id) {
                  selectUserToRemove({ id: params?.row?.id, value: checked })
                }
              }}
            />)
        }
      },
      {
        id: 'users',
        label: t('general.tags.users'),
        headerCell: styles?.header,
      },
      {
        id: 'userType',
        label: t('general.tags.userType'),
        headerCell: styles?.header,
      },
      {
        id: 'role',
        label: t('general.tags.role'),
        headerCell: styles?.header,
      },
      ...Object.entries(checksTypes).map((itemTypes) => ({
        id: itemTypes[0],
        label: t(`general.tags.${itemTypes[0]}`),
        column: () => {
          return (
            <Box sx={styles?.checksTypes ?? {}}>
              <Box sx={styles?.label}>
                <Box sx={styles?.checksChildren()}>
                  {itemTypes[1].map((item) => (
                    <IOSSwitch
                      size="medium"
                      key={item}
                      onChange={(e) => {
                        e.stopPropagation()
                        clickChenck({ id: 0, name: item })
                      }}
                      checked={!!checksUser?.[0]?.[item]}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          )
        },
        renderCell: (params: any) => {
          const row = params.row
          return (
            <Box sx={styles?.checksTypes ?? {}}>
              <Box sx={styles?.label}>
                <Box sx={styles?.checksChildren()}>
                  {itemTypes[1].map((item) => (
                    <IOSSwitch
                      size="medium"
                      key={item}
                      onChange={(e) => {
                        e.stopPropagation()
                        clickChenck({ id: row.id, name: item })
                      }}
                      checked={!!checksUser?.[row.id]?.[item]}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          )
        },
      })),
    ],
    customeColumn: [
      {
        id: 'actions',
        headerCell: {
          '&.MuiTableCell-head': {
            width: '30px'
          }
        },
        span: 3,
        column: () => {
          if (!Object.values(listDeleteUsers ?? {})?.some(item => !!item)) return <></>
          return (
            <IconButton
              sx={styles.delete}
              onClick={() => {
                setOpenModal(prev => ({
                  ...prev,
                  open: true,
                  title: t("general.messages.sureWantDeleteTheUser"),
                  description: t("general.messages.loseThePermissions"),
                  confirm: () => {
                    setOpenModal(prev => ({ ...prev, open: false }))
                    removeUserSelecteds()
                  },
                  cancel: () => { setOpenModal(prev => ({ ...prev, open: false })) }
                }))
              }}
            >
              {icons('delete')}

              <span>{t('general.deleteSelectedUsers')}</span>
            </IconButton>
          )
        }
      },
      {
        id: 'role',
        headerCell: styles?.header,
      },
      ...Object.entries(checksTypes).map((itemTypes) => ({
        id: itemTypes[0],
        label: t(`general.tags.${itemTypes[0]}`),
        start: true,
        headerCell: styles?.headerCell,
        column: () => {
          const lastLetter = itemTypes[1][0][itemTypes[1][0]?.length - 1]
          return (
            <Box sx={styles?.checksTypes ?? {}}>
              <Typography sx={styles?.titleQ()}>{t(`general.tags.${itemTypes[0]}`)}</Typography>

              <Box sx={[styles?.label, styles?.detailChecks()]}>
                {itemTypes[1].map((item) => (
                  <Typography>{t(`Questionarie.manageUser.${item?.replace(lastLetter, '')}`)}</Typography>
                ))}
              </Box>
            </Box>
          )
        },
      })),
    ],
  }

  const validateAllUsersAreSelected = ({ list, listDeleteUsers }: { list: any[]; listDeleteUsers: Record<string, boolean> }) => {
    const areThereAnyUserUnSelected = !!list?.length ? !!list?.some(item => !listDeleteUsers[item.id]) : true

    setListDeleteUsers(prev => ({ ...prev, 0: !areThereAnyUserUnSelected }))
  }
  const selectUserToRemove = ({ id, value }: { id: string; value: boolean }) => {
    if (id == '0') {
      if (!!table?.row?.length) {
        let newListDeleteUsers = { 0: value }

        for (const item of table.row) {
          newListDeleteUsers = {
            ...newListDeleteUsers,
            [item.id]: value
          }
        }

        setListDeleteUsers(newListDeleteUsers)
      }
    } else {
      const newListDeleteUsers = { ...listDeleteUsers, [id]: value }
      setListDeleteUsers(newListDeleteUsers)

      if (!!value) {
        validateAllUsersAreSelected({ list: rowContent({ list: table.row }), listDeleteUsers: newListDeleteUsers })
      } else {
        setListDeleteUsers(prev => ({ ...prev, 0: false }))
      }
    }
  }

  const validateChange = () => {
    const copyCheckUsers = { ...checksUser }
    delete copyCheckUsers[0]
    const changed = Object.values(usersToEndpoint.usersOld ?? {})?.some(item => !item) || oldChecks != JSON.stringify(copyCheckUsers)
    setThereAreChanged(!!changed)

    return !!changed
  }

  const getUserList = async () => {
    let allUsers: Record<string, string | boolean>[] = []
    let usersOld = {}
    let newCheckUser = {}
    if (id) {
      const response = await questionnaire.getPermissionsOfTheQuestionaries({ id: parseInt(id) })
      if (!response?.errors) {
        allUsers = response?.map(item => {
          const responseId = item.userId.toString()
          usersOld = {
            ...usersOld,
            [responseId]: true
          }
          newCheckUser = {
            ...newCheckUser,
            [responseId]: {
              accessQ: item.accessQuestionnaire == 1,
              reportQ: item.reportQuestionnaire == 1,
              emailNotificationQ: item.emailNotificationQuestionnaire == 1,
              accessF: item.accessReportFolio == 1,
              reportF: item.reportReportFolio == 1,
              emailNotificationF: item.emailNotificationReportFolio == 1,
            }
          }
          return ({
            id: responseId,
            users: item.fullName,
            userType: item.userType ?? '',
            role: item.role ?? ''
          })
        })
      }
    }
    setChecksUser(newCheckUser)
    setUsersToEndpoint(prev => ({
      ...prev,
      users: {},
      usersOld,
    }))
    setThereAreChanged(true)
    setOldChecks(JSON.stringify({
      ...newCheckUser,
    }))
    setTable((prev) => ({
      ...prev,
      row: allUsers,
    }))
    validateChecks(newCheckUser, allUsers)
  }

  const getAllUnits = () => {
    const items = itemsManage.stores ?? []
    setInputCreate(prev => ({ ...prev, stores: { ...prev.stores, items } }))
  }

  const getAllUserTypes = () => {
    const items = itemsManage.userType ?? []
    setInputCreate(prev => ({ ...prev, userType: { ...prev.userType, items } }))
  }

  const getAllRoles = async () => {
    const { items } = await getRoles({ skip: [Roles.ADMIN] })

    setInputCreate(prev => ({ ...prev, role: { ...prev.role, items } }))
  }

  const getAlDivisions = () => {
    const items = itemsManage.divisions ?? []
    setInputCreate(prev => ({ ...prev, division_ids: { ...prev.division_ids, items } }))
  }

  const getAllAreas = () => {
    const items = itemsManage.area ?? []
    setInputCreate(prev => ({ ...prev, area: { ...prev.area, items: items } }))
  }

  const getAllDepartments = () => {
    const items = itemsManage.departments ?? []
    setAllDepartments(items)

  }

  const getData = () => {
    getAllUnits()
    getAllUserTypes()
    getAllRoles()
    getAllAreas()
    getAllDepartments()
    getAlDivisions()
  }

  const openModalAddUser = () => {
    setModalCreate(prev => ({ ...prev, open: true }))
  }

  const closeSnackbar = () => { setSnackbar(prev => ({ ...prev, open: false })) }

  const searchUsers = async () => {
    try {
      setLoadings(prev => ({ ...prev, search: true }))
      const response = await userServiceFetch.filterUsers({ query: `status_ids=1&${returnQuery(createFilter.query, {})}` })
      let allUsers: Record<string, string | boolean>[] = response?.map(item => ({
        id: item.id.toString(),
        users: item.fullName,
        userType: item.userType ?? '',
        division: item.division,
        role: item.role ?? '',
        curp: item.curp ?? ''
      })) ?? []

      if (!!allUsers?.length) {
        setModalLoad(prev => ({ ...prev, open: true, tmpUsuers: allUsers }))
      } else {
        setModalLoad(prev => ({ ...prev, open: true, tmpUsuers: [] }))
        setSnackbar(prev => ({ ...prev, open: true, message: t("general.messages.errors.noUsers"), type: Types.ERROR }))
      }
    } catch (e) {
      setModalLoad(prev => ({ ...prev, open: true, tmpUsuers: [] }))
      setSnackbar(prev => ({ ...prev, open: true, message: t("general.messages.errors.noUsers"), type: Types.ERROR }))
    }
    finally {
      setLoadings(prev => ({ ...prev, search: false }))
    }
  }

  const loadUsers: LoadData = (listUsers: Record<string, string | boolean>[], permissons: Record<string, boolean>, success) => {
    setLoadings(prev => ({ ...prev, load: true }))

    const allUsers = listUsers?.filter(item => !table.row.some(itemRow => itemRow.id == item.id)) ?? []
    if (!!allUsers?.length) {
      let newUsers = {}
      let oldUsers = { ...usersToEndpoint.usersOld }
      let newCheckUsers = { ...checksUser }
      for (const item of allUsers) {
        const id: string = item.id as string

        if (usersToEndpoint?.usersOld?.[id] !== undefined) {
          oldUsers = {
            ...oldUsers,
            [id]: true
          }
        } else {
          newUsers = {
            ...newUsers,
            [id as string]: true
          }
        }

        newCheckUsers = {
          ...newCheckUsers,
          [id as string]: {
            ...newCheckUsers?.[id as string] ?? {},
            accessQ: !!permissons.accessQ,
            reportQ: !!permissons.reportQ,
            emailNotificationQ: !!permissons.emailNotificationQ,
            accessF: !!permissons.accessF,
            reportF: !!permissons.reportF,
            emailNotificationF: !!permissons.emailNotificationF,
          }
        }
      }

      const tableLocal = allUsers
      setChecksUser(prev => ({ ...prev, ...newCheckUsers }))
      validateChecks(newCheckUsers, [...table.row, ...(tableLocal ?? [])])
      validateAllUsersAreSelected({ list: rowContent({ list: [...table.row, ...(tableLocal ?? [])] }), listDeleteUsers })
      setTable((prev) => ({
        ...prev,
        row: [...(tableLocal ?? []), ...prev.row,],
      }))
      setUsersToEndpoint(prev => ({
        ...prev,
        users: {
          ...prev.users,
          ...newUsers
        },
        usersOld: oldUsers
      }))
      setModalLoad(prev => ({ ...prev, open: false }))
      success({ loadSuccess: true, amountOfUserAdded: allUsers?.length })
    } else {
      // setSnackbar(prev => ({ ...prev, open: true, message: t("general.messages.errors.allUsersExisted"), type: Types.ERROR }))
      success({ loadSuccess: false })
    }
    setLoadings(prev => ({ ...prev, load: false }))
  }

  const rowContent = ({ list }: {
    list?: {
      id: string,
      users: string,
      userType: string,
      role: string
    }[]
  }) => {
    const formatRowPermissions = list?.filter(
      (itemUsers) =>
        (filter.filterData?.role instanceof Array ? (filter.filterData?.role.length > 0 ? filter.filterData?.role?.find((e: any) => e?.label === itemUsers?.role) : true) : true) &&
        (filter.filterData?.userType instanceof Array ? (filter.filterData?.userType?.length > 0 ? filter.filterData?.userType?.find((e: any) => e?.label === itemUsers?.userType) : true) : true) &&
        (filter.filterData?.search
          ? searchInModeLower(`${itemUsers?.role} ${itemUsers?.userType} ${itemUsers.users}`, filter.filterData.search)
          : true)
    ) ?? []

    return formatRowPermissions
  }

  const removeUserSelecteds = () => {
    const copyCheckUsers = { ...checksUser }
    const copyUser = { ...usersToEndpoint.users }
    const listUsersSelecteds = Object.entries(listDeleteUsers ?? {})
    let newUsersOld: Record<string, boolean> = { ...usersToEndpoint.usersOld }
    let listIds: string[] = []
    for (const [key, item] of listUsersSelecteds) {
      if (!!item) {
        listIds.push(key)
        delete copyCheckUsers[key]

        const existedInNewUser = usersToEndpoint.users[key] !== undefined

        if (existedInNewUser) {
          delete copyUser[key]
        }

        newUsersOld = {
          ...newUsersOld,
          [key]: false
        }
      }
    }

    setUsersToEndpoint(prev => ({
      ...prev,
      users: copyUser,
      usersOld: newUsersOld,
      usersWithoutPersmissions: usersToEndpoint.usersWithoutPersmissions?.filter(item => !listIds.includes(item))
    }))
    setTable((prev) => ({
      ...prev,
      row: prev.row.filter(item => !listIds.includes(item.id)),
    }))
    setChecksUser(copyCheckUsers)

    setListDeleteUsers({})
  }

  const cancelSearchUsers = () => {
    createFilter.cleanFilter()
    setModalLoad(prev => ({ ...prev, open: false, tmpUsuers: [] }))
    setModalCreate(prev => ({ ...prev, open: false }))
    setLoadings(prev => ({ ...prev, search: false }))
  }

  const resetValues = (payload?: PermissionsData) => {
    setLoadings(prev => ({ ...prev, table: true }))
    if (!payload?.table?.length && !Object.values(payload?.usersOld ?? {})?.length) {
      getUserList()
    } else {
      setTable(prev => ({ ...prev, row: payload?.table ?? [] }))
      setChecksUser(prev => ({ ...prev, ...payload?.checksUser ?? {} }))
      setUsersToEndpoint(prev => ({
        ...prev,
        users: payload?.users ?? {},
        usersOld: payload?.usersOld ?? {},
        usersWithoutPersmissions: payload?.usersWithoutPersmissions ?? []
      }))
      validateChecks(payload?.checksUser ?? {}, payload?.table ?? [])
      setThereAreChanged(!!payload?.thereAreChanged)
      validateAllUsersAreSelected({ list: rowContent({ list: table.row }), listDeleteUsers })
    }
    setLoadings(prev => ({ ...prev, table: false }))
  }

  useEffect(() => {
    validateChecks(checksUser, rowContent({ list: table.row }))
    validateAllUsersAreSelected({ list: rowContent({ list: table.row }), listDeleteUsers })
  }, [filter.query.all])

  useEffect(() => {
    getData()
  }, [itemsManage])
  return {
    table,
    filter,
    columns,
    modalCreate,
    createFilter,
    inputCreate,
    modalLoad,
    snackbar,
    checksTypes,
    refTable,
    usersToEndpoint,
    checksUser,
    loadings,
    openModal,
    thereAreChanged,
    downloadUsers,
    validateChange,
    rowContent,
    resetValues,
    cancelSearchUsers,
    closeSnackbar,
    searchUsers,
    loadUsers,
    openModalAddUser,
  }
}

export { useBody }
