import { GridColDef, GridPaginationModel, GridValidRowModel } from '@mui/x-data-grid'
import { Color } from '../../../../../../../../utils/theme/types/color'
import { ForwardedRef } from 'react'
import { CustomTable } from '../../../../../../../../components'
import { Query } from '../../../../typing'

interface Props {
  sortTable: (data?: { field: string; sort: 'ASC' | 'DESC'; count?: number }) => void
  handlePagination: (data: GridPaginationModel) => void
  sortModel?: Query['sort']
  paginationModel: GridPaginationModel
  total: {
    totalPages: number
    totalItems: number
  }
  color: Color
  table: { row: GridValidRowModel[]; column: GridColDef[] }
  loading: boolean
  ref?: ForwardedRef<HTMLButtonElement>
  name: string
  headers?: { label: string; id: string }[]
}

const List = (props: Props) => {
  return (
    <>
      <CustomTable
        loader={props?.loading}
        order={
          props?.sortModel && {
            count: props?.sortModel?.count,
            key: props?.sortModel?.field,
            order: props?.sortModel?.sort,
          }
        }
        sortable={(field, sort, count) => {
          props?.sortTable(field && sort ? { field: field, sort: sort, count } : undefined)
        }}
        handleChange={(page: number, pageSize:number) => {
          props?.handlePagination({ page, pageSize })
        }}
        serverSidePagination={{ page: props?.paginationModel.page + 1, size: props?.paginationModel.pageSize, totalPages: props?.total.totalPages }}
        name={props?.name}
        column={props?.table?.column}
        row={props?.table?.row}
        color={props?.color}
        onClick={() => {}}
        selected={-1}
        setSelected={() => {}}
      />
    </>
  )
}

export default List
