import { LoadingButton } from '@mui/lab'
import { Box, Grid, Icon, Typography, Button, IconButton } from '@mui/material'
import { format } from 'date-fns'
import { useLayoutEffect, useState, Fragment, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { BaseInput, DetailKeyValue, SnackSimple } from '../../../../components'
import Loader from '../../../../components/loader'
import BaseModal from '../../../../components/modal/baseModal'
import SimpleModal from '../../../../components/modal/simpleModal'
import { GlobalContext } from '../../../../contexts/Global'
import { getStatus } from '../../../../hooks/status'
import { useContextState } from '../../../../hooks/theme'
import { actionPlansServices } from '../../../../service/actionPlans'
import { ActionPlanByID } from '../../../../service/actionPlans/response'
import { Constants } from '../../../../utils/constants'
import { getLocalStorage, httpFetch } from '../../../../utils/crud'
import { Roles, Status, Types } from '../../../../utils/enums'
import { icons } from '../../../../utils/icons'
import { onChange } from '../../../../utils/types'
import { ObjectGeneric } from '../../../../utils/types/generals'
import { useStyles } from './styles'
import { ModelThree } from '../../../reportFolio/pages/dashboard/components/details'
import { EvidenceCard } from '../../../reportFolio/pages/dashboard/components'
import { useEvidences } from '../../../../hooks/evidences/useEvidences'
import { EvidencesTypeValueEnum } from '../../../../utils/enums/evidences'
import { Evidence } from '../../../../service/reportFolio/response'
import { getDirectManagement } from '../../../../utils/methods/getDirectMangement'
import { getUserRole } from '../../../../service/auth'
import { DetailTypeOfResponse } from '../../../../components/detailTypeOfResponse'
import { DetailTypeOfResponseProps } from '../../../../components/detailTypeOfResponse/type'

const Form = () => {
  const { state } = useContext(GlobalContext)
  const stateLocale = getLocalStorage()
  const { role } = state

  const { id } = useParams()
  const { colors } = useContextState()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [modalWarning, setModalWarning] = useState({
    open: false,
    message: '',
  })
  const [btnLoading, setBtnLoading] = useState(false)
  const [allData, setAllData] = useState<ActionPlanByID & {
    evidences: {
      status: {
        value: Status;
        color: string;
        text: string;
      }
    }
  } | null>(null)
  const [showMore, setShowMore] = useState({
    detail: false,
    reject: false,
  })
  const questionnaireMain = allData?.assignedQuestionnaire?.questionnaireMain
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false)
  const [detail, setDetail] = useState(allData?.items)
  const [data, setData] = useState<ObjectGeneric>({ reject: '' })
  const [answers, setAnswer] = useState<DetailTypeOfResponseProps & { question: string } | null>()
  const [errors, setErrors] = useState<ObjectGeneric>({ reject: '' })
  const [step, setStep] = useState(0)
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    type: Types.SUCCESS,
    message: '',
  })
  const [storeCurrent, setStoreCurrent] = useState<Evidence[] | undefined>([])

  const answer = allData?.answersActionPlan?.[step]
  const statusStore = getStatus(answer?.statusId as Status, colors, t, true)
  const styles = useStyles({ colors, status: allData?.evidences?.status })
  const answersLength = allData?.answersActionPlan?.length ?? 0
  const onChange: onChange = ({ target }) => {
    const { name, value } = target

    setData((prev) => ({
      ...prev,
      [name]: value,
    }))

    setErrors((prev) => ({
      ...prev,
      [name]: !value ? t('general.validations.required') : '',
    }))
  }
  const { evidences: evidenceLeft, existEvidences, pagination, handleChangePage: changePagination } = useEvidences({ evidencesResponse: allData?.actionPlanEvidence })
  const { evidences: evidenceRight, existEvidences: existEvidencesRight, pagination: paginationRight, handleChangePage: changePaginationRight } = useEvidences({ evidencesResponse: storeCurrent })

  const getActionPlanById = () => {
    setIsLoading(true)
    if (id) {
      actionPlansServices(httpFetch)
        .getById(parseInt(id))
        .then((response) => {
          let stores = response?.answersActionPlan?.[0]?.evidencesAnswer
          const StoreShorthand = stores

          setStoreCurrent(StoreShorthand)
          setDetail(response?.items?.slice(0, 4))
          setAnswer({
            type: response.question?.typeQuestion?.id,
            answersList: response?.question?.answersQuestion?.map(item => ({
              id: item.id?.toString(),
              label: item.description ?? '',
              value: !!response?.generatedByOption?.some((itemAnswer) => itemAnswer?.selectedOption === item.id),
              iconUrl: item.iconUrl ?? '',
            })) || null,
            question: response?.question?.question?.title ?? ''
          })
          setAllData({
            ...response,
            evidences: {
              status: getStatus(response.statusId as Status, colors, t),
            },
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }

  const handleShowMore = (key: 'detail') => {
    if (key === 'detail') {
      const items = allData?.items
      setDetail(!showMore.detail ? items : items?.slice(0, 4))
    }

    setShowMore((prev) => ({
      ...prev,
      [key]: !prev[key],
    }))
  }

  const showCancel = () => {
    if (![Roles.ADMIN, Roles.AGENT, Roles.SUPERVISOR, Roles.GERENT].includes(role as Roles)) return true
    return (
      !!answer ||
      (allData && [Status.APPROVED, Status.REJECTED, Status.CANCEL, Status.INACTIVE, Status.EXPIRED]?.includes(allData?.statusId)) ||
      (allData?.answersActionPlan && allData?.answersActionPlan?.length > 1) ||
      (allData?.finishDate && new Date(allData?.finishDate).getTime() <= Date.now())
    )
  }

  const showRejected = ({ status, rejects }: { status?: number; rejects: number }) => {
    const myRole = getUserRole() as Roles
    const iAmLevelUp = myRole === Roles.ADMIN || getDirectManagement(allData?.userAssignIdActionPlan?.roleId as Roles)?.includes(myRole)
    const answerActionPlan = allData?.answersActionPlan
    const isAStatusAvailable = ![Status.APPROVED, Status.REJECTED, Status.AWAIT_SOLUTION].includes(status as Status)
    const theDateIsNotExpired = (allData?.finishDate && new Date(allData?.finishDate).getTime() <= Date.now())
    const theMaxOfRejectsDidnotBeCatched = !!answerActionPlan && answerActionPlan?.filter((item) => item.statusId == Status.REJECTED)?.length >= rejects
    const isRespondedStatus = status == Status.RESPONDED
    const isCreatedByMySelf = (allData?.userAssign?.id ?? allData?.assignedQuestionnaire?.userAssign.id) == stateLocale?.id

    return (!!iAmLevelUp || !!isCreatedByMySelf) && ((!!isAStatusAvailable && !theDateIsNotExpired) && !!(!theMaxOfRejectsDidnotBeCatched && isRespondedStatus))
  }

  const handleSnackbar = (status: Types, name: string, type: string) => {
    setOpenSnackbar({
      open: true,
      type: status,
      message: t(`actionPlan.snackbar.${name}.${type}`),
    })
  }

  const approvedActionPlan = async (id: number) => {
    const response = await actionPlansServices(httpFetch).approve([{ id, reason: '' }])
    if (!response.errors) {
      getActionPlanById()
      handleSnackbar(Types.SUCCESS, 'approved', 'success')
    } else {
      handleSnackbar(Types.ERROR, 'approved', 'error')
    }
  }
  const cancelActionPlan = async () => {
    if (id) {
      setBtnLoading(true)
      const response = await actionPlansServices(httpFetch).cancel({ actionPlanImplementedId: parseInt(id) })
      if (!response.errors) {
        getActionPlanById()
        handleSnackbar(Types.SUCCESS, 'cancel', 'success')
      } else {
        handleSnackbar(Types.ERROR, 'cancel', 'error')
      }
      setBtnLoading(false)
      closeModal(true)
    }
  }
  const rejectActionPlan = async () => {
    if (id) {
      setIsLoadingConfirm(true)
      const response = await actionPlansServices(httpFetch).reject({ id: parseInt(id), reasonRejection: data.reject })
      if (!response.errors) {
        getActionPlanById()
        handleSnackbar(Types.SUCCESS, 'reject', 'success')
      } else {
        handleSnackbar(Types.ERROR, 'reject', 'error')
      }
      setIsLoadingConfirm(false)
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    setShowMore((prev) => ({
      ...prev,
      reject: false,
    }))

    setData({})
    setErrors({})
  }

  const newArrayEvidences = (number: number) => {
    const newEvidences = allData?.answersActionPlan?.[number]?.evidencesAnswer

    setStoreCurrent(newEvidences)
  }

  const handleEvidencesSteps = ({ number }: { number: number }) => {
    setStep(number)
    newArrayEvidences(number)
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar((prev) => ({
      ...prev,
      open: false,
    }))
  }

  const closeModal = (close?: boolean) => {
    setModalWarning({
      open: !close,
      message: t('actionPlan.modal.cancel'),
    })
  }
  function responseRejectionBy() {
    if (answer?.create_time) {
      const date = format(new Date(answer?.update_time), Constants.dateFormatGeneral2)
      const user = answer?.userReview ?? allData?.userAssign ?? allData?.assignedQuestionnaire?.userAssign;
      return `${date} por ${user?.firstName ?? ''} ${user?.lastName ?? ''}`;
    } else {
      return '';
    }
  }
  function responseSentBy() {
    if (answer?.create_time) {
      const date = format(new Date(answer?.create_time), Constants.dateFormatGeneral2)
      const user = answer?.userResponse
      return `${date} por ${user?.firstName ?? ''} ${user?.lastName ?? ''}`;
    } else {
      return '';
    }
  }
  useLayoutEffect(() => {
    getActionPlanById()
    // eslint-disable-next-line
  }, [])

  return (
    <Box sx={styles.container}>
      <SnackSimple close={handleCloseSnackbar} open={openSnackbar.open} type={openSnackbar.type} message={openSnackbar.message} />

      <SimpleModal
        open={modalWarning.open}
        description=""
        close={() => {
          closeModal(true)
        }}
        title={modalWarning.message}
        buttonText={t('general.confirm')}
        cancelText={t('general.cancel')}
        color={colors}
        back={() => {
          closeModal(true)
        }}
        loadingButton
        isLoading={btnLoading}
        next={() => {
          cancelActionPlan()
        }}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box sx={styles.flex} mb={2}>
            <IconButton
              sx={styles.goBack}
              onClick={() => {
                navigate('/dashboard/action-plan/')
              }}
            >
              {icons('arrow-left-fill')}
            </IconButton>

            <Typography sx={styles.goBackText}>{t('general.goBackToTheList')}</Typography>
          </Box>

          <Grid container columnSpacing={3}>
            <Grid item xl={7} lg={7} md={7} sm={7}>
              <Box sx={styles.borders}>
                <Grid container columnSpacing={2}>
                  <Grid item xl={7} lg={7} md={7} sm={7}>
                    <Box sx={styles.containerHeader}>
                      <Typography sx={styles.title}>{questionnaireMain?.name ?? ''}</Typography>

                      <Typography sx={styles.date}>
                        Generado el {allData?.create_time ? format(new Date(allData?.create_time), Constants.dateFormatGeneral2) : ''} por {allData?.userAssign?.firstName ?? allData?.assignedQuestionnaire?.userAssign?.firstName ?? ''} {allData?.userAssign?.lastName ?? allData?.assignedQuestionnaire?.userAssign?.lastName ?? ''}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xl={5} lg={5} md={5} sm={5}>
                    <Box sx={styles.containerStatus}>
                      {!showCancel() && (
                        <Typography
                          onClick={() => {
                            closeModal()
                          }}
                          sx={styles.cancel}
                        >
                          Cancelar plan de acción
                        </Typography>
                      )}

                      <Typography sx={styles.status}>{allData?.evidences?.status?.text ?? ''}</Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={2} mt={2}>
                  {allData?.question?.answersQuestion?.length && !!allData?.isActionPlanByItem && (
                    <Grid item xl={12} lg={12} md={12} sm={12}>
                        <DetailKeyValue sxContainer={{padding: '0px 0px 10px'}} value={answers?.question} caption='Pregunta del cuestionario' />

                        <DetailTypeOfResponse answersList={answers?.answersList || []} type={answers?.type} />
                    </Grid>
                  )}

                  {!!detail && detail?.length > 0 && (
                    <>
                      <Grid sx={styles.borderTop} item xl={12} lg={12} md={12} sm={12}>
                        <Typography sx={styles.subTitle}>Detalle de Plan de Acción</Typography>
                      </Grid>

                      {detail?.map((item) => (
                        <Grid key={item.id} item xl={6} lg={6} md={6} sm={6}>
                          <Grid container columnSpacing={2}>
                            <Grid item xl={3} lg={3} md={3} sm={3}>
                              <Typography sx={styles.standarTitle2}>{item.question}</Typography>
                            </Grid>
                            <Grid item xl={9} lg={9} md={9} sm={9}>
                              <Typography sx={styles.standarTitle}>{item.agentAnswer}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                      <Grid item xl={12} lg={12} md={12} sm={12}>
                        {allData?.items && allData?.items?.length > 6 && (
                          <Box
                            onClick={() => {
                              handleShowMore('detail')
                            }}
                          >
                            <Typography sx={styles.cancel}>Mostrar {showMore.detail ? 'menos' : 'más'}</Typography>
                          </Box>
                        )}
                      </Grid>
                    </>
                  )}
                  {!!existEvidences ? (
                    <>
                      <Grid sx={styles.borderTop} item xl={12} lg={12} md={12} sm={12}>
                        <Typography sx={styles.subTitle}>Evidencias</Typography>
                      </Grid>


                      <Grid item xl={12} lg={12} md={12} sm={12}>
                        <Grid container columnSpacing={2}>
                          {!!evidenceLeft?.images?.length && <EvidenceCard evidences={evidenceLeft?.images} handleChangePage={changePagination} pagination={pagination.image} type={EvidencesTypeValueEnum.IMAGE} />}

                          {!!evidenceLeft?.video?.length && <EvidenceCard evidences={evidenceLeft?.video} handleChangePage={changePagination} pagination={pagination.video} type={EvidencesTypeValueEnum.VIDEO} />}

                          {!!evidenceLeft?.documents?.length && (
                            <>
                              <Typography sx={styles.titleEvidences}>{t('reportFolio.detail.general.documents')}</Typography>

                              <Box sx={[styles.documentsContainer]}>
                                {evidenceLeft?.documents?.map((itemDocuments, index) => (
                                  <Box
                                    key={index}
                                    sx={styles.documentItems}
                                    onClick={() => {
                                      window.open(itemDocuments?.src, 'blank')
                                    }}
                                  >
                                    <Typography sx={styles.documentName}>{itemDocuments?.name}</Typography>

                                    <Icon>{icons('visibility')}</Icon>
                                  </Box>
                                ))}
                              </Box>
                            </>
                          )}
                          {!!evidenceLeft?.comments?.length && (
                            <>
                              <Typography sx={styles.titleEvidences}>{t('reportFolio.detail.general.comments')}</Typography>

                              <Box sx={[styles.comments, styles.paddingComments]}>
                                {evidenceLeft?.comments?.map((itemComments, index) => (
                                  <Fragment key={index}>
                                    <ModelThree title={itemComments.name ?? ''} description={''} date={''} />
                                  </Fragment>
                                ))}
                              </Box>
                            </>
                          )}
                        </Grid>

                      </Grid>
                    </>
                  ) : (
                    <Typography sx={styles.noData}>{t('reportFolio.detail.general.noInformation')}</Typography>
                  )}
                </Grid>
              </Box>
            </Grid>

            <Grid item xl={5} lg={5} md={5} sm={5}>
              <Box sx={[styles.borders, styles.paddingBottom]}>
                {!!allData?.answersActionPlan?.length ? (
                  <Box>
                    <Grid container spacing={2}>
                      <Fragment>
                        <Grid item xl={12} lg={12} md={12} sm={12}>
                          <Grid container columnSpacing={2}>
                            <Grid item xl={answersLength > 1 ? 9 : 12} lg={answersLength > 1 ? 9 : 12} md={answersLength > 1 ? 9 : 12} sm={answersLength > 1 ? 9 : 12}>
                              <Typography sx={[styles.response, statusStore.value === Status.REJECTED && styles.reject]}>Respuesta {statusStore?.text}:</Typography>

                              <Typography sx={[styles.date, statusStore.value === Status.REJECTED && styles.reject]}>
                                {responseRejectionBy()}
                              </Typography>

                              {statusStore.value === Status.REJECTED && (
                                <Box sx={styles.containerRejection}>
                                  <Typography sx={[styles.response, styles.reject]}>Motivo de Rechazo</Typography>

                                  <Typography sx={[styles.date, styles.reject]}>{allData?.answersActionPlan[step]?.reasonRejection ?? ''}</Typography>
                                </Box>
                              )}

                              <Typography sx={styles.standarTitle}>Respuesta enviada:</Typography>

                              <Typography sx={styles.date}>
                                {responseSentBy()}
                              </Typography>
                            </Grid>

                            {answersLength > 1 && (
                              <Grid item xl={3} lg={3} md={3} sm={3}>
                                <Box sx={styles.stepper}>
                                  {step > 0 && (
                                    <>
                                      <IconButton onClick={() => handleEvidencesSteps({ number: 0 })}>{icons('arrow-double-l')}</IconButton>
                                      <IconButton onClick={() => handleEvidencesSteps({ number: step - 1 })}>{icons('arrow-l')}</IconButton>
                                    </>
                                  )}
                                  <Typography sx={styles.title}>
                                    {step + 1}/{allData?.answersActionPlan?.length ?? 0}
                                  </Typography>
                                  {step < allData?.answersActionPlan?.length - 1 ? (
                                    <>
                                      <IconButton onClick={() => handleEvidencesSteps({ number: step + 1 })}>{icons('arrow-r')}</IconButton>
                                      <IconButton onClick={() => handleEvidencesSteps({ number: allData?.answersActionPlan?.length - 1 })}>{icons('arrow-double-r')}</IconButton>
                                    </>
                                  ) : (
                                    <Box sx={styles.auxBox} />
                                  )}
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        <Box sx={styles.containerComment}>
                          <Typography sx={styles.standarTitle}>Comentario</Typography>

                          <Typography sx={styles.date}>{answer?.comment}</Typography>
                        </Box>

                        {!!existEvidencesRight ? (
                          <>
                            <Grid sx={styles.borderTop} item xl={12} lg={12} md={12} sm={12}>
                              <Typography sx={styles.subTitle}>Evidencias</Typography>
                            </Grid>

                            <Grid item xl={12} lg={12} md={12} sm={12} >
                              <Grid container columnSpacing={2}>
                                {!!evidenceRight?.images?.length && <EvidenceCard evidences={evidenceRight?.images} handleChangePage={changePaginationRight} pagination={paginationRight.image} type={EvidencesTypeValueEnum.IMAGE} />}

                                {!!evidenceRight?.video?.length && <EvidenceCard evidences={evidenceRight?.video} handleChangePage={changePaginationRight} pagination={paginationRight.video} type={EvidencesTypeValueEnum.VIDEO} />}

                                {!!evidenceRight?.documents?.length && (
                                  <>
                                    <Typography sx={styles.titleEvidences}>{t('reportFolio.detail.general.documents')}</Typography>

                                    <Box sx={[styles.documentsContainer]}>
                                      {evidenceRight?.documents?.map((itemDocuments, index) => (
                                        <Box
                                          key={index}
                                          sx={styles.documentItems}
                                          onClick={() => {
                                            window.open(itemDocuments?.src, 'blank')
                                          }}
                                        >
                                          <Typography sx={styles.documentName}>{itemDocuments?.name}</Typography>

                                          <Icon>{icons('visibility')}</Icon>
                                        </Box>
                                      ))}
                                    </Box>
                                  </>
                                )}
                                {!!evidenceRight?.comments?.length && (
                                  <>
                                    <Typography sx={styles.titleEvidences}>{t('reportFolio.detail.general.comments')}</Typography>

                                    <Box sx={[styles.comments, styles.paddingComments]}>
                                      {evidenceRight?.comments?.map((itemComments, index) => (
                                        <Fragment key={index}>
                                          <ModelThree title={itemComments.name ?? ''} description={''} date={''} />
                                        </Fragment>
                                      ))}
                                    </Box>
                                  </>
                                )}
                              </Grid>

                            </Grid>
                          </>
                        ) : (
                          <Typography sx={styles.noData}>{t('reportFolio.detail.general.noInformation')}</Typography>
                        )}
                      </Fragment>
                    </Grid>
                    {!!(showRejected({ status: allData?.statusId, rejects: allData.maxRejections }) && step == 0) && (
                      <Box sx={styles.buttonsContainer}>
                        {allData?.statusId !== Status.APPROVED && (
                          <Button
                            onClick={() => {
                              if (id) {
                                approvedActionPlan(parseInt(id))
                              }
                            }}
                            className="accept"
                          >
                            Aprobar
                          </Button>
                        )}
                        <Button
                          onClick={() => {
                            setShowMore((prev) => ({
                              ...prev,
                              reject: true,
                            }))
                          }}
                          className="reject"
                        >
                          Rechazar
                        </Button>
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box sx={styles.noResponse}>
                    <Icon>{icons('error')}</Icon>

                    <Typography sx={styles.notFound}>Aún no se han recibido respuestas de este plan de acción</Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      )}
      <BaseModal open={showMore.reject}>
        <>
          <Grid container rowSpacing={2} p={5}>
            <Grid item xl={12} lg={12} md={12} sm={12}>
              <Typography sx={styles.rejectTitle}>{t('actionPlan.modal.title')}</Typography>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12}>
              <BaseInput
                name="reject"
                value={{
                  reject: {
                    label: t('actionPlan.modal.label'),
                    value: data?.reject,
                  },
                }}
                onChange={onChange}
                errors={errors}
                multiline
                maxRows={3}
              />
            </Grid>
          </Grid>
          <Box sx={styles.containerModalButtons}>
            <Button
              className="cancel"
              onClick={() => {
                handleCloseModal()
              }}
            >
              {t('general.cancel')}
            </Button>
            <LoadingButton
              disabled={!data.reject}
              onClick={() => {
                rejectActionPlan()
              }}
              loading={isLoadingConfirm}
              className="confirm"
            >
              {t('general.confirm')}
            </LoadingButton>
          </Box>
        </>
      </BaseModal>
    </Box>
  )
}

export default Form
