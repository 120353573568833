import { Roles } from '../../utils/enums'
import { Http } from '../../utils/types'
import { GetBlob, ObjectGeneric } from '../../utils/types/generals'
import { getUserRole } from '../auth'
import {
  OptionGroupResponse,
  PermissionQuestionnaireResponse,
  QuestionTypesResponse,
  QuestionnaireByIdResponse,
  QuestionnaireListOld,
  QuestionnaireListResponse,
} from './response'

export const questionarieServise = (client: Http) => {
  return {
    getAllQuestionaries: async (): Promise<QuestionnaireListOld> => {
      switch (getUserRole()) {
        case Roles.ADMIN:
          return await client.get(`questionnaires/filters`)
        default:
          return await client.get('questionnaires/?is_scheduled=false')
      }
    },
    getTableQuestionnaires: async ({ query }: { query?: string }): Promise<QuestionnaireListResponse> => await client.get(`questionnaires/detailed${query ? '?' + query : ''}`),
    getQuestionariesByUser: async (ids: number | string) =>
      await client.get(`questionnaires/users?users=${ids}`),
    getAllQuestionariesOld: async ({ type }: { type: boolean }): Promise<QuestionnaireListOld> => {
      switch (getUserRole()) {
        case Roles.ADMIN:
          return await client.get('questionnaires')
        default:
          return await client.get(`questionnaires?is_scheduled=${type}`)
      }
    },
    downloadTemplate: async (data: {
      type: `pdf` | 'word' | 'excel'
      query?: string
    }): Promise<GetBlob> =>
      await client.getBlob?.(
        `questionnaires/generate-file?type=${data.type}${data?.query ? `${data?.query}` : ''
        }`
      ),
    getQuestionarieById: async (
      id: number | string
    ): Promise<QuestionnaireByIdResponse> =>
      await client.get(`questionnaires/${id}`),
    getNextApply: async () =>
      await client.get('questionnaires/questionnaires-next-apply'),
    copyByOtherQuestionnarie: async (body: ObjectGeneric | FormData) =>
      await client.post('questionnaires/copy-questionnaire', body),
    getOptionGroup: async (): Promise<OptionGroupResponse> => await client.get('option-group'),
    getQuestionType: async ():Promise<QuestionTypesResponse> => await client.get('question-type'),
    getEvidenceType: async () => await client.get('evidence-type'),
    createQuestionnarie: async (
      body: ObjectGeneric
    ): Promise<QuestionnaireByIdResponse> =>
      await client.post('questionnaires', body),
    updateQuestionnarie: async (
      id: string,
      body: ObjectGeneric
    ): Promise<QuestionnaireByIdResponse> =>
      await client.put(`questionnaires/${id}`, body),
    deleteQuestionnarie: async (id: string) =>
      await client.delete(`questionnaires/${id}`),
    getPermissionsOfTheQuestionaries: async ({
      id,
    }: {
      id: number
    }): Promise<PermissionQuestionnaireResponse> =>
      await client.get(`permissions/questionnaire/${id}`),
    createPermissions: async (body: ObjectGeneric) =>
      await client.post(`permissions`, body),
  }
}
