import { useContextState } from '../../../../../../hooks/theme'

const useStyles = () => {
  const { colors } = useContextState()
  return {
    containerGraph: {
      minHeight: '300px',
      boxShadow: `0 0 4px 0px ${colors.color13}`,
      padding: '20px',
      paddingBottom: '0px',
      position: 'relative',
      borderRadius: '12px',
    },
    headerInputs: {
      width: '300px',
      '& .MuiGrid-container': {
        justifyContent: 'end',
      },
    },
    close: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      '&.MuiSvgIcon-root': {
        fontSize: '1rem'
      }
    },
    containerModal: {
      maxHeight: '600px',
      overflowY: 'auto',
      padding: '50px',
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px'
    },
    headerModal: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%'
    },
    headerModalTitle: {
      fontSize: '22px',
      fontWeight: '500',
      color: colors.color9
    },
    btnFilter: {
      fontSize: '16px',
      background: colors.color8,
      '&:hover': {
        background: `${colors.color8}ee`,
      },
      fontWeight: '500',
      padding: '5px 20px'
    },
    containerIcons: {
      display: 'flex',
      alignItems: 'center',
      gap: '20px'
    },
    badge: (payload?: { }) => ({
      '& .MuiBadge-badge': {
        top: 0,
        right: '0px',
        background: colors.mediumDark,
        color: colors.color5
      },
    }),
    header: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    refresh: {
      background: colors.orangeMedium,
      color: colors.color5,
      borderRadius: '5px',
      '&:hover': {
        background: `${colors.orangeMedium}ee`,
      },
      '& .MuiSvgIcon-root': {
        fontSize: '2.2rem',
      }
    },
    cleanText: {
      color: colors.color31,
      padding: '0px'
    },
    footerText: {
      fontSize: '14px',
      color: colors.color9,
      '& span': {
        color: colors.color13,
      },
    },
    filter: {
      background: colors.color12,
      color: colors.color5,
      borderRadius: '5px',
      '&:hover': {
        background: `${colors.color12}ee`,
      },
      '& .MuiSvgIcon-root': {
        fontSize: '2.2rem',
      }
    },
    actionIndicator: {
      position: 'absolute',
      top: '0',
      right: '10px',
      '& .MuiIconButton-root': {
        fontSize: '1rem',
        '& span': {
          fontSize: '11px',
          marginRight: '2px',
        },
        '& .MuiSvgIcon-root': {
          fontSize: '1em',
        },
      },
    },
    lastUpdate: {
      lineHeight: '1rem',
      fontSize: '.8rem',
      '& span': {
        color: colors.color16,
      },
    },
    chipContainer: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginBottom: '10px',
      '& .show': {
        marginLeft: '10px',
      },
    },
    noData: {
      width: '100%',
      textAlign: 'center',
      fontSize: '18px',
      color: `${colors.color13}`,
      marginTop: '20px',
    },
    headerProgress: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '10px',
    },
    title: {
      lineHeight: '1rem',
      color: colors.color9,
      fontSize: '1.4rem',
      marginBottom: '5px',
      fontWeight: '500'
    },
    graph: ({ existData }: { existData?: boolean }) => ({
      widht: '100%',
      height: existData ? '400px' : '100px',
    }),
  }
}

export { useStyles }
