const useStyles = () => {
  return {
    header: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '20px'
    },
  }
}

export { useStyles }
