import { useTranslation } from "react-i18next"
import { getNumberWeek } from "../../../../../../utils/methods/toDate"

const WeekLabel = ({ value }: { value: string }) => {
  const { t } = useTranslation()
  const week = getNumberWeek(value)

  return (
    <text
      x={25}
      y={10}
      textAnchor="end"
      fill="#666"
      fontSize={10}
    >
      {t('dashboard.data.week')}{`: `}{week}
    </text>
  )
}

export { WeekLabel }
