import { TFunction } from "react-i18next";
import { RequestErrors } from "../enums";

export const handleAllErrors = (error:RequestErrors | undefined, t: TFunction<'translation', undefined>, customMessage?: string, extra?: {name?: string}) => {
    switch(error) {
        case RequestErrors.ERR_NOT_USER_MANAGES: return t('general.messages.errors.ERR_NOT_USER_MANAGES')
        case RequestErrors.ERR_NOT_USER_ASSINGS: return t('general.messages.errors.ERR_NOT_USER_ASSINGS')
        case RequestErrors.ERR_CANNOT_CREATE_VISITS: return t('general.messages.errors.ERR_CANNOT_CREATE_VISITS')
        case RequestErrors.ERR_OPTION_GROUP_IN_USE: return t('general.messages.errors.ERR_OPTION_GROUP_IN_USE')
        case RequestErrors.ERR_NOT_FINALIZED: return t('general.messages.errors.ERR_NOT_FINALIZED')
        case RequestErrors.ERR_NO_ACTIVE_USER: return t('general.messages.errors.ERR_NO_ACTIVE_USER')
        case RequestErrors.ERR_SOME_QUESTIONNAIRE_IS_STARTED: return t('general.messages.errors.ERR_SOME_QUESTIONNAIRE_IS_STARTED')
        case RequestErrors.ERR_USER_WITHOUT_PERMISSIONS: return t('general.messages.errors.ERR_USER_WITHOUT_PERMISSIONS')
        case RequestErrors.REQUIRE_VALIDATE: return t('general.messages.errors.REQUIRE_VALIDATE') 
        case RequestErrors.ERR_VALIDATION_CODE: return t('general.messages.errors.ERR_VALIDATION_CODE') 
        case RequestErrors.ACC_NO_REQUIRE_VALIDATE: return t('general.messages.errors.ACC_NO_REQUIRE_VALIDATE') 
        case RequestErrors.ERR_DELETE_QUESTIONNAIRE_MAIN: return t('department.snackbar.delete.errorCode.ERR_DELETE_QUESTIONNAIRE_MAIN') 
        case RequestErrors.VALIDATION_ERROR: return t('general.messages.errors.VALIDATION_ERROR')
        case RequestErrors.ERR_NOT_ANSWERED: return t('general.messages.errors.ERR_NOT_ANSWERED')
        case RequestErrors.ERR_REPORT_FOLIO_REJECTED: return t('general.messages.errors.ERR_REPORT_FOLIO_REJECTED')
        case RequestErrors.ERR_VALIDATION_FILES: return t('general.messages.errors.ERR_VALIDATION_FILES')
        case RequestErrors.ERR_NOT_FOUND: return t('general.messages.errors.ERR_NOT_FOUND')
        case RequestErrors.ERR_NO_PERMISSIONS: return t('general.messages.errors.ERR_NO_PERMISSIONS')
        case RequestErrors.ERR_VALID_DATE_START: return t('general.messages.errors.ERR_VALID_DATE_START')
        case RequestErrors.ERR_USER_NOT_ALLOWED_REASSIGN: return t('general.messages.errors.ERR_USER_NOT_ALLOWED_REASSIGN')
        case RequestErrors.ERR_ALREADY_ANSWERING: return `${t('general.messages.errors.ERR_ALREADY_ANSWERING')}: ${extra?.name ?? ''}`
        case RequestErrors.ERR_ALREADY_ANSWERED: return `${t('general.messages.errors.ERR_ALREADY_ANSWERED')}`
        case RequestErrors.ERR_ALREADY_EXISTS: return `${t('general.messages.errors.ERR_ALREADY_EXISTS')}`
        case RequestErrors.ERR_EMAIL: return `${t('general.messages.errors.ERR_EMAIL')}`
        case RequestErrors.ERR_USERNAME_EXIST: return `${t('general.messages.errors.ERR_USERNAME_EXIST')}`
        case RequestErrors.INVALID_USERNAME_TYPE: return `${t('general.messages.errors.INVALID_USERNAME_TYPE')}`
        default: return customMessage ? t(customMessage) : ''
    }
}