import { useContextState } from "../../../../../../hooks/theme"
import { PercentageStatus } from "../indicators/typing/enums"

const useColorByStatus = () => {
  const { colors } = useContextState()

  const percentageColors = {
    [PercentageStatus.GREEN]: {
      color: colors.color8,
      text: 'light'
    },
    [PercentageStatus.ORANGE]: {
      color: colors.color29,
      text: 'dark'
    },
    [PercentageStatus.YELLOW]: {
      color: colors.secondary,
      text: 'dark'
    },
    [PercentageStatus.WINE_RED]: {
      color: colors.color37,
      text: 'light'
    },
    [PercentageStatus.RED]: {
      color: colors.red,
      text: 'light'
    },
  }

  return { percentageColors }
}


export { useColorByStatus }