import { useContextState } from '../../../../hooks/theme'

const useStyles = () => {
  const { colors } = useContextState()
  return {
    container: {
      width: '100%',
      height: '100%',
      paddingRight: '50px',
      paddingLeft: '0px',
      paddingTop: '50px',
      paddingBottom: '20px',
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'relative',
    },
    section: {
      marginBottom: '32px',
      marginTop: '20px',
    },
    header: {
      marginBottom: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    headerTitle: {
      lineHeight: '1rem',
      color: colors.color13,
      fontSize: '1.5rem',
      fontWeight: '600',
      marginBottom: '5px',
      textAlign: 'start',
    },
    hide: {
      visibility: 'hidden',
    },
    clean: {
      '&.MuiIconButton-root': {
        fontSize: '1rem',
        '& .MuiSvgIcon-root': {
          fontSize: '1em',
        },
      },
    },
  }
}

export { useStyles }
