import { Box, Typography } from "@mui/material"
import { useUnitStyles } from "./unitStyles"

const BodyUnit = (props: any) => {
    const unit = JSON.parse(props.current)
    const styles = useUnitStyles()

    return (
        <Box sx={styles.containerUnit}>
            {!!unit?.title ||
                !!unit?.department ||
                !!unit?.content ||
                !!unit?.user ? (
                <>
                    {!!unit?.title && (
                        <Typography sx={styles.unitTitle} className="w-full">
                            {unit?.title ?? ''}
                        </Typography>
                    )}

                    {!!unit?.department && (
                        <Typography sx={styles.unitTitle} className="w-full">
                            {unit?.department ?? ''}
                        </Typography>
                    )}

                    {!!unit?.content && (
                        <Typography sx={styles.unitContent} className="w-full">
                            {unit?.content ?? ''}
                        </Typography>
                    )}

                    {!!unit?.user && (
                        <Typography sx={styles.user} className="w-full">
                            {unit?.user ?? ''}
                        </Typography>
                    )}
                </>
            ) : (
                <Typography sx={styles.user} className="w-full">
                    'N/A'
                </Typography>
            )}
        </Box>
    )
}
export { BodyUnit }