import { LoadingButton } from '@mui/lab'
import { Box, Button } from '@mui/material'
import { useStyles } from './styles'

interface Props {
  accept?: {
    label: string
    click: () => void
    disabled?: boolean
  }
  cancel?: {
    label: string
    click: () => void
    disabled?: boolean
    variant?: "text" | "contained" | "outlined"
  }
  customeStyles?: boolean
  isLoading?: boolean;
  autoHeight?: boolean
}

const SimpleButtons = ({ accept, cancel, isLoading, customeStyles, autoHeight }: Props) => {
  const styles = useStyles()
  return (
    <Box sx={styles.container({ autoHeight: !!autoHeight })}>
      {!!cancel ? (
        <Button onClick={() => cancel?.click()} sx={customeStyles ? styles.customeBack : styles.Cbutton} variant={cancel?.variant ?? 'contained'} disabled={cancel?.disabled}>
          {cancel?.label}
        </Button>
      ) : <Box />}

      {!!accept && (
        <LoadingButton loading={!!isLoading} onClick={() => accept?.click()} sx={customeStyles ? styles.customeSend : styles.Abutton} color='primary' variant="contained" disabled={accept?.disabled}>
          {accept.label}
        </LoadingButton>
      )}
    </Box>
  )
}

export default SimpleButtons
