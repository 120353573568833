import { useContextState } from "../../../../../../hooks/theme"

const useStyles = () => {
  const { colors } = useContextState()

  return {
    container: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      boxShadow: `0px 0px 4px ${colors.color13}`,
      borderRadius: '5px',
      padding: '20px',
      paddingBottom: '0',
      height: '100%',
      position: 'relative'
    },
    refresh: {
      background: colors.orangeMedium,
      color: colors.color5,
      borderRadius: '5px',
      '&:hover': {
        background: `${colors.orangeMedium}ee`,
      },
      '& .MuiSvgIcon-root': {
        fontSize: '2.2rem',
      }
    },
    header: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    title: {
      lineHeight: '1rem',
      color: colors.color9,
      fontSize: '1.4rem',
      marginBottom: '5px',
      fontWeight: '500',
      marginLeft: '50px'
    },
    detail: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    separator: {
      height: '50px',
      width: '2px',
      background: colors.color13
    },
    graph: ({ existData }: { existData?: boolean }) => ({
      width: '100%',
      height: existData ? '320px' : '100px'
    }),
    noData: {
      width: '100%',
      textAlign: 'center',
      fontSize: '18px',
      color: `${colors.color13}`,
      marginTop: '20px'
    },
    actionIndicator: {
      position: 'absolute',
      top: '0',
      right: '10px',
      '& .MuiIconButton-root': {
        fontSize: '1rem',
        '& span': {
          fontSize: '11px',
          marginRight: '2px',
        },
        '& .MuiSvgIcon-root': {
          fontSize: '1em',
        },
      },
    },
  }
}

export { useStyles }
