import { Query } from '../../pages/reportFolio/pages/dashboard/typing'

const returnQuery = (data: Query, customeEquivalence?: Record<string, string>): string => {
  const equivalence: Record<string, string> = customeEquivalence ?? {
    foliosAmount: 'folio',
    unit: 'store',
    requestDate: 'create',
    solveDate: 'solutionDate',
    timeElapsed: 'solutionTime',
  }

  return `page=${data?.pagination?.page || 1}&size=${data?.pagination?.pageSize || 20}${data?.sort ? `&order=${data?.sort?.sort?.toUpperCase()}&column=${equivalence[data?.sort?.field] ?? data?.sort?.field}` : ''}${data.filter ? `&${data.filter}` : ''}`
}

const returnQueryWithPagination = (data: Query, customeEquivalence?: Record<string, string>): string => {
  const equivalence: Record<string, string> = customeEquivalence ?? {
    foliosAmount: 'folio',
    unit: 'store',
    requestDate: 'create',
    solveDate: 'solutionDate',
    timeElapsed: 'solutionTime',
  }

  return `${data?.sort ? `&order=${data?.sort?.sort?.toUpperCase()}&column=${equivalence[data?.sort?.field] ?? data?.sort?.field}` : ''}${data.filter ? `&${data.filter}` : ''}`
}

const replaceQuery = ({page,query,size}:{query:string;page:number;size:number}) => {
  return query?.replace(`page=${page || 1}&size=${size || 20}`, '')
}
export { returnQuery, replaceQuery, returnQueryWithPagination }
