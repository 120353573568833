import { useEffect, useState } from "react"
import { dashboardServiceFetch } from "../../../../../../utils/constants/callService"

const useBody = () => {
  const [data, setData] = useState<{
    label: string;
    total: number;
    completed: number;
  }[]>([])
  const [loading, setLoading] = useState(false)

  const getData = async () => {
    setLoading(true)
    const response = await dashboardServiceFetch.topBestStoresByStoreRole()

    const stores = !response?.errors ? response?.map(item => ({
      label: item.name,
      total: 100,
      completed: item.score,
    })) : []

    setData(stores)
    setLoading(false)
  }

  useEffect(() => { getData() }, [])
  return { data, loading, getData }
}

export { useBody }
