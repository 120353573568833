import { Box, CircularProgress, CircularProgressProps } from "@mui/material";
import { CircularProgressWithLabel } from "../../../../../../components/graphics/circularProgress";

function FacebookCircularProgress(props: CircularProgressProps) {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <Box sx={{ position: 'absolute', left: '0px', top: 0 }}>
        <CircularProgressWithLabel
          variant="indeterminate"
          disableShrink
          thickness={4}
          {...props}
        />
      </Box>
    </Box>
  );
}

export { FacebookCircularProgress }