import { Box } from '@mui/material'
import { useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../components/loader'

const LogOut = () => {
  const navigate = useNavigate()
  useLayoutEffect(() => {
    const logout = () => {
      localStorage.clear() 
      setTimeout(() => {navigate('/auth')}, 1000)
    }
    logout()
  }, [navigate])
  return (
    <Box sx={{ height: '100vh' }}>
      <Loader />
    </Box>
  )
}

export default LogOut
