import { SxProps } from "@mui/material";

export const useStyles = (sx?: SxProps) => ({
    modal: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '20px',
        ...sx as any ?? {},
        maxHeight: '90vh',
        overflowY: 'auto'
      },
})