import { TFunction } from 'react-i18next'
import { getStatus } from '../../../../hooks/status'
import { Status } from '../../../../utils/enums'
import { Color } from '../../../../utils/theme/types/color'
import { searchInModeLower } from '../../../../utils/validate/validate'
import { format } from 'date-fns'
import { Constants } from '../../../../utils/constants'
import { userTypeServiceFetch } from '../../../../utils/constants/callService'

export const getTypeList = async ({ colors, t, queary }: { colors: Color, t: TFunction<'translation', undefined>; queary?: string }) => {
  const response = await userTypeServiceFetch.userTypePagination({ query: queary })

  const column: any[] = [
    {
      id: 'name',
      label: t('general.tags.type'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'description',
      label: t('general.tags.description'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'createTime',
      label: t('general.tags.createTime'),
      minWidth: 100,
    },
    {
      id: 'status',
      label: t('general.tags.status'),
      sortable: true,
      minWidth: 100,
    },
  ]

  const row = !response?.errors
    ? response?.items?.map((item) => {
      let status: Status = item?.statusId

      return {
        id: item.id,
        name: item.name,
        description: item.description,
        createTime: item.createTime && format(new Date(item.createTime), Constants.dateFormatGeneral),
        status: getStatus(status, colors, t),
        createDate: format(new Date(item.createTime), Constants.dateFormat2),
        createHour: format(new Date(item.createTime), Constants.hourFormat),
      }
    })
    : []

  return { column, row, totalPages: response.totalPages, totalItems: response.totalItems }
}

export const filterRow = (value: { [x: string]: any }, list: any[]) => {
  const from = value.from?.valueOf()
  const to = value.to?.valueOf()

  return list.filter((item) => {
    return (
      (from ? new Date(from).setHours(0, 0, 0, 0) <= item?.createTime?.value : true) &&
      (to ? new Date(to).setHours(23, 59, 59, 999) >= item?.createTime?.value : true) &&
      (value?.month instanceof Array ? (value?.month.length > 0 ? value?.month.find((e: any) => e.value === item.monthNumber) : true) : true) &&
      (value?.createYear instanceof Array ? (value?.createYear.length > 0 ? value?.createYear.find((e: any) => e.value == item.year) : true) : true) &&
      (value?.status instanceof Array ? (value?.status.length > 0 ? value?.status.find((e: any) => e.label == item.status.text) : true) : true) &&
      (searchInModeLower(item.name, value.search) || searchInModeLower(item.createTime?.label, value.search) || searchInModeLower(item.description, value.search) || searchInModeLower(item.status.text, value.search))
    )
  })
}

