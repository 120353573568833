import { InputModel } from "../../../../../../../utils/types";


const inputsUnits: Record<string, InputModel> = {
  store: {
    label: 'general.tags.stores',
    translate: true,
    name: 'store',
    type: 'filter-select',
    items: [],
    value: null,
  },
  divisions: {
    label: 'general.tags.divisions',
    translate: true,
    name: 'divisions',
    type: 'filter-select',
    items: [],
    value: null,
  },
  zones: {
    label: 'general.tags.zones',
    translate: true,
    name: 'zones',
    type: 'filter-select',
    items: [],
    value: null,
  },
  class: {
    label: 'general.tags.childZone',
    translate: true,
    name: 'class',
    type: 'filter-select',
    items: [],
    value: null
  },
}
export { inputsUnits }