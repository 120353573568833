import { Box, IconButton, Typography } from '@mui/material'
import { CustomTable } from '../../../../components'
import { icons } from '../../../../utils/icons'
import { Props } from './props'
import { useStyles } from './styles'
import { LoadingButton } from '@mui/lab'
import { DynamicFormRef } from '../../../../components/dynamicFormRef'
import { useState } from 'react'

const CopyQuest = ({ t, table, onClose, filter, next, disabled, loading, ...props }: Props) => {
  const styles = useStyles()
  const [selected, setSelected] = useState(-1)
  const [id, setId] = useState('')

  return (
    <Box sx={styles.containerSuccess({ isSelected: selected > 0 })}>
      <Box sx={styles.container}>
        <IconButton
          sx={styles.closeSuccess}
          onClick={() => {
            onClose()
          }}
        >
          {icons('simple-close')}
        </IconButton>

        <Box sx={styles.header}>
          <Typography sx={styles.title}>{filter.title}</Typography>

          <Box sx={styles.search}>
            <DynamicFormRef
              onChange={(data) => {
                props.onChange({
                  target: {
                    name: data?.current as string ?? '',
                    value: data?.[data?.current as string] ?? ''
                  }
                })
              }}
              model={{
                search: {
                  id: 'search',
                  label: filter.label,
                  name: filter.name,
                  type: 'text'
                }
              }}
            />
          </Box>
        </Box>

        <CustomTable
          arrow={selected > 0}
          dontRefresh
          column={table.column || []}
          row={table.row}
          onClick={(e, index) => {
            setId(e.id)
            setSelected(index as number)
          }}
          selected={selected}
          order={props.order}
          sortable={props.sortable}
          serverSidePagination={props.serverSidePagination}
          handleChange={props.handleChange}
        />

        <LoadingButton loading={loading} disabled={disabled || !id} onClick={() => next({ id: id })} color="primary" variant="contained" sx={styles.button}>{t('general.next')}</LoadingButton>
      </Box>
    </Box>
  )
}

export default CopyQuest
