import { Box, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../styles'
import { ItemMenu } from '../../../../../components/baseMenu'
import { Status } from '../../../../../utils/enums'
import { useState } from 'react'
import { menuItems } from '../constants/menu'
import { icons } from '../../../../../utils/icons'
import { Constants } from '../../../../../utils/constants'

const useTable = (payload: {
  row: {
    division: string
    user: string
    questionnaire: string
    available_to: string
    reportUrl: string
    status: {
      value: Status
      color: string
      text: string
    }
  }[]
}) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const [detail, setDetail] = useState<{
    id: number
    reportUrl: string | null
    positionMenu: Record<string, unknown>
    anchor: null | HTMLButtonElement
    menu: ItemMenu[]
  }>({
    id: 0,
    reportUrl: '',
    positionMenu: {},
    anchor: null,
    menu: menuItems,
  })

  const closeMenu = () => {
    setDetail((prev) => ({
      ...prev,
      anchor: null,
    }))
  }

  const addReportUrl = ({ reportUrl }: { reportUrl: string | null }) => {
    setDetail(prev => ({
      ...prev,
      reportUrl
    }))
  }

  const columns: any[] = [
    {
      id: 'division',
      label: t('general.tags.division'),
      sortable: true,
      minWidth: 50,
    },
    {
      id: 'user',
      label: t('general.tags.assignedUser'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'questionnaire',
      label: t('general.tags.questionnaire'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'available_from',
      label: t('general.tags.available_from'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'available_to',
      label: t('general.tags.available_to'),
      sortable: true,
      minWidth: 100,
    },
    {
      id: 'status',
      label: t('general.tags.status'),
      minWidth: 100,
    },
    {
      id: 'action',
      label: t('general.tags.action'),
      minWidth: 50,
      renderCell: (params: any) => {
        const id = params?.row?.id
        return (
          <Box sx={styles.containerStatus}>
            <IconButton
              onClick={(e) => {
                let menu: ItemMenu[] = []
                const status = params?.row?.status?.value
                if (
                  [Status.FINISHED, Status.PAUSE, Status.STARTED].includes(
                    status
                  )
                ) {
                  const customeMenuItems = !!params?.row?.reportUrl ? [3, 4, 5] : [4, 5]
                  if (status == Status.PAUSE) {
                    customeMenuItems.push(2)
                  }
                  menu = menuItems?.filter((itemMenu) =>
                    customeMenuItems.includes(itemMenu.value)
                  )
                } else if (status == Status.CLOSE) {
                  menu = menuItems?.filter((itemMenu) =>
                    [5].includes(itemMenu.value)
                  )
                } else if (status == Status.INCOMPLETE) {
                  menu = menuItems?.filter((itemMenu) =>
                    [2, 5].includes(itemMenu.value)
                  )
                } else {
                  menu = menuItems?.filter(
                    (itemMenu) => ![2, 3, 4].includes(itemMenu.value)
                  )
                }

                setDetail({
                  id,
                  reportUrl: params?.row?.reportUrl,
                  positionMenu:
                    payload?.row?.length < Constants.numberOfRows
                      ? {
                        top: 0,
                        left: '-30px',
                      }
                      : {
                        top: `${e.clientY}px`,
                        left: `${e.clientX - 80}px`,
                      },
                  anchor: e.currentTarget,
                  menu: menu,
                })
              }}
            >
              {icons('moreHoriz')}
            </IconButton>
          </Box>
        )
      },
    },
  ]

  return { columns, detail, closeMenu, addReportUrl }
}

export { useTable }
