const useStyles = () => {
  return {
    container: {
      width: "100%",
      display: "flex",
      gap: "5px"
    },
    color: ({ color }: { color: string }) => ({
      width: "20px",
      height: "20px",
      background: color,
      borderRadius: "50%"
    }),
    textColor: ({color}: {color:string}) => ({
      // color: color,
    })
  }
}

export { useStyles }
