import { useLayoutEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Layout from '../components/layout'
import Setting from '../pages/setting/routes'
import { useContextState } from '../hooks/theme'
import { TypeGlobalEnum } from '../utils/enums/globalContextEnum'
import { canAccess } from '../utils/validate/canaccess'
import { Roles } from '../utils/enums'
import { getLocalStorage } from '../utils/crud'
import ReportFolio from '../pages/reportFolio/routes'
import OrganizationChart from '../pages/organizationChart/routes'
import Users from '../pages/users/routes'
import Units from '../pages/units/routes'
import Localization from '../pages/localization/routes'
import { images } from '../utils/images'
import { UserScheduleRoutes } from '../pages/userSchedule/routes'
import ReportsRoutes from '../pages/reports/routes'
import Dasboard from '../pages/dashboard/pages/main'
import QuestionnaireRoute from '../pages/questionnaire/routes'
import { ActionPlanRoutes } from '../pages/actionPlans/routes'
import { CalendarRoutes } from '../pages/calendar/routes'
export function RoutesPrivate() {
  const location = useLocation()
  const navigate = useNavigate()
  const { dispatch } = useContextState()

  useLayoutEffect(() => {
    const hasAccess = canAccess([Roles.SUPERVISOR, Roles.ADMIN, Roles.AGENT, Roles.STORE, Roles.PARTNER, Roles.AUDIT, Roles.GERENT, Roles.SUPPORT])
    if (!hasAccess) {
      navigate('/auth/logOut')
    } else {
      const state = getLocalStorage()
      dispatch({
        type: TypeGlobalEnum.SAVE_STATE,
        payload: {
          user: state.email,
          id: state?.id,
          role: state?.role,
          picture: state?.picture ?? images.profileHeader
        },
      })
    }
  }, [location.pathname, navigate, dispatch])

  return (
    <Layout>
      <Routes>
        <Route path="dashboard" element={<Dasboard />} />
        <Route path="dashboard/reports/*" element={<ReportsRoutes />} />
        <Route path="dashboard/action-plan/*" element={<ActionPlanRoutes />} />
        <Route path="dashboard/report-folio/*" element={<ReportFolio />} />
        <Route path="dashboard/localization/*" element={<Localization />} />
        <Route path="dashboard/schedule-user/*" element={<UserScheduleRoutes />} />

        <Route path="dashboard/organization-chart/*" element={<OrganizationChart />} />
        <Route path="dashboard/user/*" element={<Users />} />
        <Route path="dashboard/unit/*" element={<Units />} />
        <Route path="dashboard/questionnarie/*" element={<QuestionnaireRoute />} />
        <Route path="dashboard/calendar/*" element={<CalendarRoutes/>} />
        <Route path="dashboard/setting/*" element={<Setting />} />
      </Routes>
    </Layout>
  )
}
