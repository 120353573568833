import { Box, Typography } from '@mui/material'
import { FLoatingListProp } from './types'
import { useEffect, useState } from 'react'
import { useStyles } from './styles'
import Loader from '../loader'
import { Virtuoso } from 'react-virtuoso'

const FloatingList = (props: FLoatingListProp) => {
  const length = props?.data?.length
  const styles = useStyles({ length, loading: !!props.isLoad })
  const [position, setPosition] = useState<{
    left: number
    top: number
  } | null>(null)

  useEffect(() => {
    const bounding = props.anchorEL?.getBoundingClientRect()

    if (!!bounding) {
      setPosition((prev) => ({
        ...prev,
        top: bounding.top - 30,
        left: bounding.left,
      }))
    }
  }, [props.anchorEL])

  useEffect(() => {
    const handleBodyClick = (e: any) => {
      if (!!position) {
        props.close(null)
        setPosition(null)
      }
    }
    document.body.addEventListener('click', handleBodyClick)

    return () => {
      document.body.removeEventListener('click', handleBodyClick)
    }
  }, [position])

  if (!position) return null
  return (
    <Box
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      sx={styles.container({
        top: (position.top ?? 0) - 30,
        left: (position.left ?? 0) - 530,
      })}
    >
      {!!props.isLoad ? (
        <Loader />
      ) : (
        <>
          {props.header}

          {!length ? (
            <Typography sx={styles.noData}>No hay notificaciones</Typography>
          ) : (
            <Virtuoso
              style={styles.list as any}
              totalCount={length}
              itemContent={(index) => {
                const item = props.data?.[index]
                return (
                  <Box sx={{ marginTop: '10px' }}>
                    {!!props.listLoadingNotifications[item.id] ? (
                      <Box sx={styles.loader}>
                        <Loader />
                      </Box>
                    ) : (
                      item.children
                    )}
                  </Box>
                )
              }}
            />
          )}
        </>
      )}
    </Box>
  )
}

export { FloatingList }
